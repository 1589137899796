import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CurrentUserResolve } from './shared/resolvers/current-user.resolve';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'audits',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'audits',
    loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule),
    resolve: {
      currentUser: CurrentUserResolve
    }
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    resolve: {
      currentUser: CurrentUserResolve
    }
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: '**',
    redirectTo: 'audits',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'corrected' })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Modules
import { SharedModule } from '../shared/shared.module';
import { AuthenticationRoutingModule } from './authentication.routing.module';

// Components
import { LoginPageComponent } from './login-page/login-page.component';
import { StatusPageComponent } from './status-page/status-page.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

// Resolvers
import { UserResolve } from './resolvers/user.resolve';

@NgModule({
  imports: [CommonModule, SharedModule, AuthenticationRoutingModule],
  declarations: [LoginPageComponent, UserProfileComponent, StatusPageComponent],
  providers: [UserResolve]
})
export class AuthenticationModule {}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
// Models
import { FindingType } from './../../../../shared/models/finding-type.model';
// Services
import { FindingService } from './../../../../shared/services/finding.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gw-add-finding-type',
  templateUrl: './add-finding-type.component.html',
  styleUrls: ['./add-finding-type.component.scss'],
  exportAs: 'gwAddFindingType'
})
export class AddFindingTypeComponent implements OnInit, OnDestroy {
  @Input() findingType: FindingType;
  addFindingTypeForm: FormGroup;
  submitted = false;
  translateSubscription: Subscription;
  errors = {
    connectionTitle: '',
    createFindingType: '',
    updateFindingType: ''
  };
  success = {
    createFindingTypeTitle: '',
    createFindingTypeText: '',
    updateFindingTypeTitle: '',
    updateFindingTypeText: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private findingService: FindingService
  ) { }

  ngOnInit() {
    this.translateSubscription = this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'ADMIN_SETTINGS.FINDING_TYPES.ERROR.CREATE_FINDING_TYPE',
      'ADMIN_SETTINGS.FINDING_TYPES.ERROR.UPDATE_FINDING_TYPE',
      'ADMIN_SETTINGS.FINDING_TYPES.SUCCESS.CREATE_FINDING_TYPE_TITLE',
      'ADMIN_SETTINGS.FINDING_TYPES.SUCCESS.CREATE_FINDING_TYPE_TEXT',
      'ADMIN_SETTINGS.FINDING_TYPES.SUCCESS.UPDATE_FINDING_TYPE_TITLE',
      'ADMIN_SETTINGS.FINDING_TYPES.SUCCESS.UPDATE_FINDING_TYPE_TEXT'
      ])
      .subscribe((translation: any) => {
        this.errors.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.errors.createFindingType = translation['ADMIN_SETTINGS.FINDING_TYPES.ERROR.CREATE_FINDING_TYPE'];
        this.errors.updateFindingType = translation['ADMIN_SETTINGS.FINDING_TYPES.ERROR.UPDATE_FINDING_TYPE'];
        this.success.createFindingTypeTitle = translation['ADMIN_SETTINGS.FINDING_TYPES.SUCCESS.CREATE_FINDING_TYPE_TITLE'];
        this.success.createFindingTypeText = translation['ADMIN_SETTINGS.FINDING_TYPES.SUCCESS.CREATE_FINDING_TYPE_TEXT'];
        this.success.updateFindingTypeTitle = translation['ADMIN_SETTINGS.FINDING_TYPES.SUCCESS.UPDATE_FINDING_TYPE_TITLE'];
        this.success.updateFindingTypeText = translation['ADMIN_SETTINGS.FINDING_TYPES.SUCCESS.UPDATE_FINDING_TYPE_TEXT'];
    });

    this.addFindingTypeForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['']
    });
    if (this.findingType) {
      this.setControlValue(this.findingType.name, 'name');
      this.setControlValue(this.findingType.description, 'description');
    }
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
  }

  resetForm() {
    if (this.findingType) {
      this.setControlValue(this.findingType.name, 'name');
      this.setControlValue(this.findingType.description, 'description');
    } else {
      this.setControlValue('', 'name');
      this.setControlValue('', 'description');
    }
  }

  setControlValue(data: any, key: string): void {
    this.addFindingTypeForm.controls[key].setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  createFindingType(): Promise<any> {
    const findingTypeToCreate = new FindingType(this.addFindingTypeForm.value);
    return this.findingService.createFindingType(findingTypeToCreate)
      .then(findingType => {
        this.resetForm();
        this.submitted = false;
        this.notificationsService.success(this.success.createFindingTypeTitle, this.success.createFindingTypeText);
        return {
          status: 'create',
          findingType
        };
      })
      .catch(error => {
        this.notificationsService.error(this.errors.connectionTitle, this.errors.createFindingType);
        throw error;
      });
  }

  updateFindingType(): Promise<any> {
    const findingTypeToUpdate = new FindingType(this.addFindingTypeForm.value);
    findingTypeToUpdate.id = this.findingType.id;
    return this.findingService.updateFindingType(findingTypeToUpdate)
      .then(findingType => {
        this.resetForm();
        this.submitted = false;
        this.notificationsService.success(this.success.updateFindingTypeTitle, this.success.updateFindingTypeText);
        return {
          status: 'update',
          findingType
        };
      })
      .catch(error => {
        this.notificationsService.error(this.errors.connectionTitle, this.errors.updateFindingType);
        throw error;
      });
  }

  submitAddFindingTypeForm(): Promise<any> {
    this.submitted = true;
    if (this.addFindingTypeForm.valid) {
      if (this.findingType) {
        return this.updateFindingType();
      } else {
        return this.createFindingType();
      }
    } else {
      return new Promise((resolve, reject) => reject({ invalid: true }));
    }
  }
}

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { TreeFilterOption } from '../../models/tree-filter-option.model';

import { FlatTreeNode } from '../../interfaces/flat-tree-node.interface';

import { TreeNodesIds } from '../../types/tree-nodes-ids.type';

import { FLAT_TREE_DEFAULT_HEIGHT } from '../../constants/flat-tree-height.constants';

@Component({
  selector: 'gw-flat-tree',
  templateUrl: 'flat-tree.component.html',
  styleUrls: ['flat-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlatTreeComponent),
      multi: true
    }
  ]
})
export class FlatTreeComponent {
  @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScrollViewPort: CdkVirtualScrollViewport;

  @ContentChild(TemplateRef) controlTemplateRef: TemplateRef<any>;

  @Input() markSelectedChildren?: boolean;
  @Input() height = FLAT_TREE_DEFAULT_HEIGHT;
  @Input() tooltipTemplateRef?: TemplateRef<any>;
  @Input() showConnectedElements?: boolean;
  @Input() set data(data: Array<TreeFilterOption>) {
    this._data = data;
    this.dataList = this.selectNodesInTree(this.convertTreeToList(data, this.dataList), this.selectedNodesIds);
    this.virtualScroll = this.isVirtualScrollNeeded();
    this.setTreeHeight();
  }
  get data(): Array<TreeFilterOption> {
    return this._data;
  }
  _data: Array<TreeFilterOption>;

  @Input() lastNodeMargin?: boolean;
  @Input() set selectedNodesIds(selectedNodesIds: TreeNodesIds) {
    this._selectedNodesIds = selectedNodesIds;
    this.dataList = this.selectNodesInTree(this.dataList, this.selectedNodesIds);
  }
  get selectedNodesIds(): TreeNodesIds {
    return this._selectedNodesIds;
  }
  _selectedNodesIds?: TreeNodesIds;

  dataList: Array<FlatTreeNode>;
  treeHeight: number;
  virtualScroll = true;

  readonly NODE_HEIGHT = 27;

  isVirtualScrollNeeded(): boolean {
    return this.dataList.length * this.NODE_HEIGHT > this.height;
  }

  setTreeHeight(): void {
    const collapsedTreeHeight = this.data.length * this.NODE_HEIGHT;
    this.treeHeight = collapsedTreeHeight > this.height ? this.height : collapsedTreeHeight;
  }

  onToggleNode(node: FlatTreeNode): void {
    node.isExpanded = !node.isExpanded;
    this.dataList = this.dataList.map(nodeInList => ({ ...nodeInList, isVisible: this.shouldRender(nodeInList) }));
  }

  getParentNode(node: FlatTreeNode): FlatTreeNode {
    const nodeIndex = this.dataList.indexOf(node);
    for (let i = nodeIndex - 1; i >= 0; i--) {
      if (this.dataList[i].level === node.level - 1) {
        return this.dataList[i];
      }
    }
    return null;
  }

  shouldRender(node: FlatTreeNode): boolean {
    let parent = this.getParentNode(node);
    while (parent) {
      if (!parent.isExpanded) {
        return false;
      }
      parent = this.getParentNode(parent);
    }
    return true;
  }

  getChildrenIndexes(tree: Array<FlatTreeNode>, parentIndex: number): { start: number; end: number } {
    const lastChildIndex = this.getLastChildIndex(tree, parentIndex);
    return parentIndex !== lastChildIndex ? { start: parentIndex + 1, end: lastChildIndex } : undefined;
  }

  getLastChildIndex(tree: Array<FlatTreeNode>, currentIndex: number): number {
    const currentNode = tree[currentIndex];
    const nextSiblingNodeIndex = tree.slice(currentIndex + 1).findIndex(node => node.level <= currentNode.level);
    if (nextSiblingNodeIndex !== -1) {
      const lastChildIndex = currentIndex + nextSiblingNodeIndex - 1;
      return lastChildIndex;
    } else {
      return tree.length - 1;
    }
  }

  convertTreeToList(tree: Array<TreeFilterOption>, previousFlatTree?: Array<FlatTreeNode>): Array<FlatTreeNode> {
    const convertTreeNodes = (
      treeNodes: Array<TreeFilterOption>,
      level = 0,
      path: Array<string> = [],
      isParentOpened?: boolean
    ): { list: Array<FlatTreeNode>; hasFilteredNodes?: boolean } => {
      const list: Array<FlatTreeNode> = [];
      let hasFilteredNodes = false;

      treeNodes
        ?.filter(node => !node.hidden)
        .forEach(node => {
          const previousNode = previousFlatTree?.find(previousNode => previousNode.id === node.id);
          const hasChildren = !!node.children?.length;
          const children = hasChildren
            ? convertTreeNodes(node.children, level + 1, [...path, node.id as string], node.isOpened)
            : { list: [] };
          if (children.hasFilteredNodes) {
            children.list = children.list.map(child => ({
              ...child,
              isVisible: true
            }));
          }

          hasFilteredNodes = hasFilteredNodes || node.isFiltered || children.hasFilteredNodes;
          const isVisible =
            previousNode?.isVisible || level === 0 || children.hasFilteredNodes || node.isFiltered || isParentOpened;

          list.push(
            {
              id: node.id as string,
              name: node.name,
              expandable: hasChildren,
              isVisible,
              isFiltered: node.isFiltered,
              isBlocked: node.isBlocked,
              isExpanded: node.isOpened || previousNode?.isExpanded || children.hasFilteredNodes,
              question: node.question,
              path,
              level,
              ...(node?.connectedElements && { connectedElements: node.connectedElements }),
              ...(node?.chosenElements && { chosenElements: node.chosenElements })
            },
            ...children.list
          );
        });

      return { list, hasFilteredNodes };
    };

    return convertTreeNodes(tree).list;
  }

  selectNodesInTree(tree: Array<FlatTreeNode>, selectedNodesIds?: TreeNodesIds): Array<FlatTreeNode> {
    if (selectedNodesIds && tree?.length) {
      let lastSelectedNodeChildrenIndexes: { start: number; end: number };
      return tree.map((node, index) => {
        const isSelected = !!selectedNodesIds[node.id];
        if (isSelected) {
          lastSelectedNodeChildrenIndexes = this.getChildrenIndexes(tree, index);
        }
        const isParentSelected =
          this.markSelectedChildren &&
          lastSelectedNodeChildrenIndexes &&
          index >= lastSelectedNodeChildrenIndexes.start &&
          index <= lastSelectedNodeChildrenIndexes.end;
        return { ...node, isSelected, isParentSelected };
      });
    }
    return tree;
  }
}

import moment from 'moment';

import { ApiDecodedToken, DecodedToken } from '../interfaces/decoded-token.interface';

export const decodeJWTToken = (token: string): DecodedToken => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(character => {
        return '%' + ('00' + character.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  const decodedToken = JSON.parse(jsonPayload) as ApiDecodedToken;
  return {
    expirationDate: moment.unix(decodedToken.exp),
    initDate: moment.unix(decodedToken.iat),
    user: {
      id: decodedToken.data.id,
      email: decodedToken.data.email
    }
  };
};

import moment from 'moment';

import { AttachmentLC } from './attachment-lc.model';
import { CommentActivity } from './comment-activity.model';
import { DefaultList } from './default-list.model';
import { Employee } from './employee.model';
import { ExtraFieldSimple } from './extra-field-simple.model';
import { LegalContainer } from './legal-container.model';
import { Link } from './link.model';
import { Product } from './product.model';
import { SingleChange } from './single-change.model';
import { AssignedStatusLCConsultant } from './status-lc-consultant.model';

import { History } from '../interfaces/history.interface';
import { Interval } from '../interfaces/interval.interface';
import { NestedElementsToPreview } from '../interfaces/nested-elements-to-preview.interface';
import { RelatedLaws } from '../interfaces/related-law.interface';

export type REQUIREMENT_TYPE = 'Requirement';

export class Requirement {
  readonly _type: REQUIREMENT_TYPE = 'Requirement';

  attachments?: Array<AttachmentLC>;
  deadline?: moment.Moment | '';
  displayName: string;
  employee: Employee;
  explanation: string;
  extraFields?: DefaultList<ExtraFieldSimple>;
  containerId?: string;
  history?: History;
  id: string;
  inactive?: boolean;
  interval?: Interval;
  isCustom = false;
  isNewest?: boolean;
  issuedOn?: moment.Moment;
  lastComment?: CommentActivity;
  lastUpdate: moment.Moment;
  legalCategories: NestedElementsToPreview;
  legalChange?: SingleChange;
  legalContainer?: LegalContainer;
  links?: Array<Link>;
  numericId: string;
  previousVersion?: Requirement;
  products?: Array<Product>;
  relatedLaws: RelatedLaws;
  remark: string;
  status: AssignedStatusLCConsultant;
  summary: string;
  transitionPeriod?: moment.Moment | '';
  validity?: {
    startDate: moment.Moment;
    endDate: moment.Moment;
  };
  versionId: string;
  versions?: Array<Requirement>;
  versionsAmount?: number;

  constructor(requirement: Partial<Requirement>) {
    Object.assign(this, requirement);
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';

// Gutwin Shared Library
import { DialogService, ModalService } from 'gutwin-shared';

// Models
import { Questionnaire } from '../../../shared/models/questionnaire.model';

// Components
import { AddReferenceDocumentComponent } from './add-reference-document/add-reference-document.component';
import { ImportReferenceDocumentComponent } from './import-reference-document/import-reference-document.component';

// Services
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';

@Component({
  selector: 'gw-reference-documents',
  templateUrl: './reference-documents.component.html',
  styleUrls: ['./reference-documents.component.scss']
})
export class ReferenceDocumentsComponent implements OnInit {
  @ViewChild('addReferenceDocument') addReferenceDocument: AddReferenceDocumentComponent;
  @ViewChild('importReferenceDocument') importReferenceDocument: ImportReferenceDocumentComponent;
  questionnaires: Array<Questionnaire>;
  translation = {
    notify: {
      error: {
        connectionTitle: '',
        removeQuestionnaire: '',
        fetchQuestionnaires: '',
        exportQuestionnaire: ''
      },
      success: {
        removeQuestionnaireTitle: '',
        removeQuestionnaireText: ''
      }
    },
    removeDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    }
  };

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private questionnaireService: QuestionnaireService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.questionnaires = this.route.snapshot.data['questionnaires'];
    this.fetchTranslations();
  }

  appendQuestionnaire(questionnaire: Questionnaire): void {
    this.questionnaires.push(questionnaire);
  }

  updateQuestionnaire(questionnaire: Questionnaire): void {
    this.spliceQuestionnaire(questionnaire);
    this.appendQuestionnaire(questionnaire);
  }

  fetchQuestionnaires(): void {
    this.questionnaireService
      .getQuestionnaires()
      .then((questionnaires: Array<Questionnaire>) => {
        this.questionnaires = questionnaires;
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.fetchQuestionnaires
        );
      });
  }

  spliceQuestionnaire(questionnaire: Questionnaire): void {
    const index = _.findIndex(this.questionnaires, { id: questionnaire.id });
    if (~index) {
      this.questionnaires.splice(index, 1);
    }
  }

  showUpdateQuestionnaireModal(questionnaire: Questionnaire): void {
    this.modalService.open('addQuestionnaireModal', questionnaire);
  }

  saveQuestionnaire(): void {
    this.addReferenceDocument
      .submitAddQuestionnaireForm()
      .then(questionnaireResponse => {
        if (questionnaireResponse.status === 'create') {
          this.appendQuestionnaire(questionnaireResponse.questionnaire);
        } else if (questionnaireResponse.status === 'update') {
          this.updateQuestionnaire(questionnaireResponse.questionnaire);
        }
        this.modalService.close('addQuestionnaireModal');
      })
      .catch(error => {});
  }

  showRemoveModal(questionnaire: Questionnaire): void {
    this.dialogService
      .confirm(
        this.translation.removeDialog.header,
        this.translation.removeDialog.content,
        this.translation.removeDialog.cancel,
        this.translation.removeDialog.confirm
      )
      .then(() => {
        this.removeQuestionnaire(questionnaire);
      })
      .catch(() => {});
  }

  removeQuestionnaire(questionnaire: Questionnaire): void {
    this.questionnaireService
      .archiveQuestionnaire(questionnaire)
      .then(() => {
        this.spliceQuestionnaire(questionnaire);
        this.notificationsService.success(
          this.translation.notify.success.removeQuestionnaireTitle,
          this.translation.notify.success.removeQuestionnaireText
        );
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.removeQuestionnaire
        );
      });
  }

  afterImportQuestionnaire(): void {
    this.fetchQuestionnaires();
    this.modalService.close('importQuestionnaireModal');
  }

  exportQuestionnaire(questionnaire: Questionnaire): void {
    this.questionnaireService.exportQuestionnaire(questionnaire).catch(error => {
      this.notificationsService.error(
        this.translation.notify.error.connectionTitle,
        this.translation.notify.error.exportQuestionnaire
      );
    });
  }

  async fetchTranslations(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.REMOVE_REFERENCE_DOCUMENT',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.LOAD_QUESTIONNAIRES',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.EXPORT_QUESTIONNAIRE',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_REFERENCE_DOCUMENT_TITLE',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_REFERENCE_DOCUMENT_TEXT',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_QUESTIONNAIRE_MODAL.HEADER',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_QUESTIONNAIRE_MODAL.CONTENT'
      ])
      .toPromise();
    this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.removeQuestionnaire =
      translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.REMOVE_REFERENCE_DOCUMENT'];
    this.translation.notify.error.fetchQuestionnaires =
      translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.LOAD_QUESTIONNAIRES'];
    this.translation.notify.error.exportQuestionnaire =
      translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.EXPORT_QUESTIONNAIRE'];
    this.translation.notify.success.removeQuestionnaireTitle =
      translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_REFERENCE_DOCUMENT_TITLE'];
    this.translation.notify.success.removeQuestionnaireText =
      translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_REFERENCE_DOCUMENT_TEXT'];
    this.translation.removeDialog.header =
      translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_QUESTIONNAIRE_MODAL.HEADER'];
    this.translation.removeDialog.content =
      translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_QUESTIONNAIRE_MODAL.CONTENT'];
    this.translation.removeDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
  }
}

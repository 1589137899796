import { Component, OnInit } from '@angular/core';
// Services
import { AuditNavigationService } from './../../services/audit-navigation.service';

@Component({
  selector: 'gw-findings-list',
  templateUrl: './findings-list.component.html',
  styleUrls: ['./findings-list.component.scss']
})
export class FindingsListComponent implements OnInit {
  filtersOpened = false;

  constructor(public auditNavigationService: AuditNavigationService) { }

  ngOnInit() { }

  toggleFilters(): void {
    this.filtersOpened = !this.filtersOpened;
  }
}

import {
  ChangeCompanyUnitParentAttributes,
  CreateCompanyUnitAttributes,
  UpdateCompanyUnitAttributes
} from '../../interfaces/company-structure.interface';

import { AttachmentFile } from '../../types/attachment-file.type';

export class GetCompanyStructure {
  static readonly type = '[CompanyStructure] Get company structure';
}

export class GetRootCompanyUnits {
  static readonly type = '[CompanyStructure] Get root company units';
}

export class GetCompanyUnitsTypes {
  static readonly type = '[CompanyStructure] Get company units types';
}

export class ArchiveCompanyUnit {
  static readonly type = '[CompanyStructure] Archive company unit';
  constructor(public id: string) {}
}

export class CreateCompanyUnit {
  static readonly type = '[CompanyStructure] Create company unit';
  constructor(public attributes: CreateCompanyUnitAttributes) {}
}

export class UpdateCompanyUnit {
  static readonly type = '[CompanyStructure] Update company unit';
  constructor(public attributes: UpdateCompanyUnitAttributes) {}
}

export class ChangeCompanyUnitParent {
  static readonly type = '[CompanyStructure] Change company unit parent';
  constructor(public attributes: ChangeCompanyUnitParentAttributes) {}
}

export class UploadCompanyUnitBackgroundImage {
  static readonly type = '[CompanyStructure] Upload company unit background image';
  constructor(public backgroundImage: AttachmentFile) {}
}

export class ClearCompanyUnitBackgroundImage {
  static readonly type = '[CompanyStructure] Clear company unit background image';
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NavigationGroupToDisplay, NavigationLinkToDisplay } from '../../interfaces/navigation-elements-to-display.interface';
import { NavigationGroups, NavigationLinks } from '../../interfaces/navigation.interface';

import { NavigationElementsToDisplay, NavigationElementToDisplay } from '../../types/navigation-groups-to-display.type';

import { RouterService } from '../../services/router.service';
import { StorageService, StoragesNames } from '../../services/storage.service';

@Component({
  selector: 'gw-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('expand', [
      state('out', style({ height: 0 })),
      state('in', style({ height: '*' })),
      transition('in <=> out', [animate('0.3s ease-in')])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Input() set navigationGroups(navigationGroups: NavigationGroups) {
    this.initNavigationElements(navigationGroups);
  }

  data: NavigationElementsToDisplay;

  destroy$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private routerService: RouterService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.observeCurrentPath();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  observeCurrentPath(): void {
    this.routerService.currentPath$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.markNavigationElements();
      this.changeDetector.markForCheck();
    });
  }

  initNavigationElements(navigationGroups: NavigationGroups): void {
    this.data = this.initNavigationProperties(navigationGroups);
    this.addTokenToExternalLinks();
    this.markNavigationElements();
  }

  initNavigationProperties(
    links: NavigationGroups | NavigationLinks,
    level = 0,
    path = []
  ): NavigationElementsToDisplay {
    return Object.values(links).map(link => {
      const links = link?.links ? this.initNavigationProperties(link.links, level + 1, [...path, link.id]) : [];
      return { ...link, active: false, level, path, links };
    });
  }

  onToggleLink(link: NavigationGroupToDisplay): void {
    link.isExpanded = !link.isExpanded;
  }

  markNavigationElements(): void {
    const activeLink = this.getActiveLink(this.data)?.[0];
    this.data = this.data.map(link => this.markSingleNavigationElement(link, activeLink));
  }

  markSingleNavigationElement(
    link: NavigationElementToDisplay,
    activeLink: NavigationElementToDisplay
  ): NavigationElementToDisplay {
    const { openOnInit, isExpanded } = link as NavigationGroupToDisplay;
    const expanded = openOnInit || isExpanded || activeLink?.path.includes(link.id);
    const active = link.id === activeLink?.id;
    const links = link.links?.map(link => this.markSingleNavigationElement(link, activeLink));

    return { ...link, active, ...(link.links?.length && { isExpanded: expanded }), links };
  }

  getActiveLink(data: NavigationElementsToDisplay): NavigationElementsToDisplay {
    const activeLink = [];
    data?.forEach(link => {
      if (!link?.links?.length && this.isLinkActive(link)) activeLink.push(link);
      activeLink.push(...this.getActiveLink(link.links));
    });

    return activeLink;
  }

  addTokenToExternalLinks(): void {
    const token = this.storageService.getLocalStorage(StoragesNames.token);
    this.data = this.data.map(link => this.addTokenToSingleExternalLink(link, token));
  }

  addTokenToSingleExternalLink(link, token): NavigationElementToDisplay {
    const shouldAddToken = link.external && link?.url;
    return {
      ...link,
      ...(shouldAddToken && { url: this.getLinkToken(link.url, token) }),
      links: link.links?.map(link => this.addTokenToSingleExternalLink(link, token))
    };
  }

  getLinkToken(url: string, token: string): string {
    return (url += (~url.indexOf('?') ? '&' : '?') + `${StoragesNames.token}=${token}`);
  }

  isLinkActive(link: NavigationLinkToDisplay, strongCompare = false): boolean {
    const isLinkActive = this.routerService.isLinkActive(link.url, strongCompare);
    const isSiblingLinkActive = this.routerService.isLinkActive(link.siblingUrl, strongCompare);
    return isLinkActive || isSiblingLinkActive;
  }
}


<gw-audit-top-bar></gw-audit-top-bar>
<gw-page-header>
  <ng-container page-header-left="page-header-left">
    <ul class="header__menu header__menu--left">
      <li class="header__item"><a class="button button--link header__button--transparent header__button--menu" [routerLink]="getBackNavigation()"><i class="button__icon gw-arrow--left"></i></a></li>
      <li class="header__item header__item--title">{{ header }}</li>
    </ul>
  </ng-container>
</gw-page-header>
<div class="audit-creation">
  <div class="audit-step" [class.audit-step--dimmed]="storedAudit.currentStep !== 0">
    <div class="row audit-step__wrapper">
      <div class="columns audit-step__content">
        <gw-audit-creation-header [title]="'AUDIT_CREATION.S_DEFINE_AUDIT.STEP_TITLE' | translate" [heading]="'AUDIT_CREATION.S_DEFINE_AUDIT.STEP_HEADER' | translate" [id]="0" [storedAudit]="storedAudit" (goToStep)="goToStep($event)"></gw-audit-creation-header>
        <gw-step-1-define-audit *ngIf="storedAudit.currentStep === 0" (submitStep)="submitStep($event)" (updateAudit)="updateAudit($event)" (updateStorage)="updateStorage($event)" [audit]="audit" [auditObservable]="auditObservable" [auditTypes]="auditTypes" [employees]="employees"></gw-step-1-define-audit>
      </div>
    </div>
  </div>
  <div class="audit-step" [class.audit-step--dimmed]="storedAudit.currentStep !== 1">
    <div class="row audit-step__wrapper">
      <div class="columns audit-step__content">
        <gw-audit-creation-header [title]="'AUDIT_CREATION.S_AUDIT_PLAN.STEP_TITLE' | translate" [heading]="'AUDIT_CREATION.S_AUDIT_PLAN.STEP_HEADER' | translate" [id]="1" [storedAudit]="storedAudit" (goToStep)="goToStep($event)"></gw-audit-creation-header>
        <gw-step-2-audit-plan *ngIf="storedAudit.currentStep === 1" (submitStep)="submitStep($event)" (updateAudit)="updateAudit($event)" (updateStorage)="updateStorage($event)" (init)="initStep2()" [audit]="audit" [auditObservable]="auditObservable" [storedAudit]="storedAudit" [employees]="employees" [facilities]="facilities"></gw-step-2-audit-plan>
      </div>
    </div>
  </div>
  <div class="audit-step" [class.audit-step--dimmed]="storedAudit.currentStep !== 2">
    <div class="row audit-step__wrapper">
      <div class="columns audit-step__content">
        <gw-audit-creation-header [title]="'AUDIT_CREATION.S_SCHEDULE_AUDIT.STEP_TITLE' | translate" [heading]="'AUDIT_CREATION.S_SCHEDULE_AUDIT.STEP_HEADER' | translate" [id]="2" [storedAudit]="storedAudit" (goToStep)="goToStep($event)"></gw-audit-creation-header>
        <gw-step-3-schedule-audit *ngIf="storedAudit.currentStep === 2" (submitStep)="submitStep($event)" (updateAudit)="updateAudit($event)" (updateStorage)="updateStorage($event)" [audit]="audit" [auditObservable]="auditObservable"></gw-step-3-schedule-audit>
      </div>
    </div>
  </div>
  <div class="audit-step" [class.audit-step--dimmed]="storedAudit.currentStep !== 3">
    <div class="row audit-step__wrapper">
      <div class="columns audit-step__content">
        <gw-audit-creation-header [title]="'AUDIT_CREATION.S_AUDIT_QUESTIONS.STEP_TITLE' | translate" [heading]="'AUDIT_CREATION.S_AUDIT_QUESTIONS.STEP_HEADER' | translate" [id]="3" [storedAudit]="storedAudit" (goToStep)="goToStep($event)"></gw-audit-creation-header>
        <gw-step-4-audit-questions *ngIf="storedAudit.currentStep === 3" (submitStep)="submitStep($event)" (updateAudit)="updateAudit($event)" (updateStorage)="updateStorage($event)" [audit]="audit" [auditObservable]="auditObservable"></gw-step-4-audit-questions>
      </div>
    </div>
  </div>
</div>
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DropdownService } from '../services/dropdown.service';

import { HelperUtil } from '../utils/helper.util';

@Directive({
  selector: '[gwSimpleDropdown]',
  exportAs: 'simpleDropdown'
})
export class SimpleDropdownDirective implements OnInit, OnDestroy, AfterContentChecked {
  @Input() stopPropagation = false;
  @Input() disabled: boolean;
  @Input() fixOverflowPosition: boolean;
  @Input() handleOffclick = true;
  @Input() handleKeydown = true;
  @Input() forceCloseAllOnClick = false;
  @Input() handleHover = false;
  @Input() parentTopRect?: number;

  @Output() dropdownOpened = new EventEmitter();
  @Output() dropdownClosed = new EventEmitter();

  dropdownElement: HTMLElement;
  contentElement: HTMLElement;
  labelElement: HTMLElement;
  openedWithDelay = false;
  opened = false;
  fullyOpened = false;
  firstDrop = true;
  isRightOverflow = false;
  isLeftOverflow = false;
  isTopOverflow = false;
  isBottomOverflow = false;
  bottomOverflowValue: number;
  leftOverflowValue: number;
  checkOverflow: boolean;
  isMouseLeaveDisabled = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public element: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private dropdownService: DropdownService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.dropdownElement = this.element.nativeElement;
    this.contentElement = this.element.nativeElement.querySelector('.jsDropdownContent');

    this.observeCloseAllEvent();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngAfterContentChecked(): void {
    if (this.fixOverflowPosition && this.checkOverflow && (this.openedWithDelay || this.opened)) {
      this.checkOverflow = false;
      if (!this.isTopOverflow) {
        this.isTopOverflow = this.isTopOverflowWindow();
      }
      if (!this.isBottomOverflow) {
        this.isBottomOverflow = this.isBottomOverflowWindow();
        if (this.isBottomOverflow) this.bottomOverflowValue = this.countBottomOverflowWindow();
      }
      if (!this.isRightOverflow) {
        this.isRightOverflow = this.isRightOverflowWindow();
      }
      if (!this.isLeftOverflow) {
        this.isLeftOverflow = this.isLeftOverflowWindow();
        this.leftOverflowValue = this.countLeftOverflowWindow();
      }
      if (this.isTopOverflow || this.isBottomOverflow || this.isRightOverflow || this.isLeftOverflow) {
        this.fixContentPosition();
      } else {
        this.clearContentPosition();
      }
      this.changeDetector.detectChanges();
    }
  }

  focusOnDropdown(): void {
    this.element.nativeElement.focus();
  }

  openDropdown(): void {
    if (!this.disabled) {
      this.opened = true;
      this.firstDrop = false;
      this.openedWithDelay = true;
      this.checkOverflow = true;
      setTimeout(() => {
        this.fullyOpened = true;
      }, 300);
      this.dropdownOpened.emit();
    }
  }

  closeDropdown(): void {
    this.opened = false;
    this.fullyOpened = false;
    this.changeDetector.markForCheck();
    setTimeout(() => {
      this.openedWithDelay = false;
      this.clearContentPosition();
    }, 300);
    this.dropdownClosed.emit();
  }

  isRightOverflowWindow(): boolean {
    const dropdownRightPosition =
      this.dropdownElement.offsetLeft + this.contentElement.offsetLeft + this.contentElement.clientWidth;
    const agentWidth = HelperUtil.isMobileOrTablet() ? screen.width : window.innerWidth;
    return dropdownRightPosition > agentWidth;
  }

  isLeftOverflowWindow(): boolean {
    const dropdownLeftPosition = this.dropdownElement.offsetLeft + this.contentElement.offsetLeft;
    return dropdownLeftPosition < 0;
  }

  isTopOverflowWindow(): boolean {
    if (this.parentTopRect) return false;
    const dropdownContentTopPosition = this.contentElement.getBoundingClientRect().top;
    return dropdownContentTopPosition < 0;
  }

  isBottomOverflowWindow(): boolean {
    if (this.parentTopRect) {
      const contentElementHeight = this.contentElement.clientHeight;
      const distanceBetweenParentAndDropdown = this.dropdownElement.getBoundingClientRect().top - this.parentTopRect;
      return distanceBetweenParentAndDropdown > contentElementHeight;
    }

    const viewportHeight = window.innerHeight;
    const dropdownContentBottomPosition = this.contentElement.getBoundingClientRect().bottom;
    return dropdownContentBottomPosition > viewportHeight;
  }

  countBottomOverflowWindow(): number {
    const contentElementHeight = this.contentElement.clientHeight;
    const distanceBetweenContentAndDropdown = this.contentElement.offsetTop - this.dropdownElement.clientHeight;
    const dropdownBottomPosition = -(contentElementHeight + distanceBetweenContentAndDropdown);
    return dropdownBottomPosition;
  }

  countLeftOverflowWindow(): number {
    const dropdownLeftPosition = this.dropdownElement.offsetLeft + this.contentElement.offsetLeft;
    return dropdownLeftPosition;
  }

  fixContentPosition(): void {
    if (this.isRightOverflow) {
      this.renderer.setStyle(this.contentElement, 'right', this.isLeftOverflow ? this.leftOverflowValue + 'px' : 0);
      this.renderer.setStyle(this.contentElement, 'left', 'auto');
    }
    if (this.isTopOverflow) {
      this.renderer.setStyle(this.contentElement, 'top', '100%');
      this.renderer.setStyle(this.contentElement, 'bottom', 'unset');
    } else if (this.isBottomOverflow) {
      this.renderer.setStyle(this.contentElement, 'top', this.bottomOverflowValue + 'px');
    }
  }

  clearContentPosition(): void {
    this.isRightOverflow = false;
    this.isLeftOverflow = false;
    this.isTopOverflow = false;
    this.isBottomOverflow = false;
    this.renderer.removeStyle(this.contentElement, 'right');
    this.renderer.removeStyle(this.contentElement, 'left');
    this.renderer.removeStyle(this.contentElement, 'top');
    this.renderer.removeStyle(this.contentElement, 'bottom');
  }

  observeCloseAllEvent(): void {
    this.dropdownService.closeAll$.pipe(takeUntil(this.destroy$)).subscribe(() => this.closeDropdown());
  }

  setDisableMouseLeaveFlag(disabled: boolean): void {
    this.isMouseLeaveDisabled = disabled;
  }

  @HostListener('click', ['$event'])
  elementClick(event: any): void {
    this.labelElement = this.element.nativeElement.querySelector('.jsDropdownLabel');

    if (this.forceCloseAllOnClick) {
      this.dropdownService.closeAll();
    }

    if (this.stopPropagation) {
      event.stopPropagation();
    }

    if (
      !(this.contentElement.contains(event.target) || (this.labelElement && this.labelElement.contains(event.target)))
    ) {
      if (this.opened || this.openedWithDelay) {
        if (this.handleOffclick) this.closeDropdown();
      } else {
        this.openDropdown();
      }
    }
  }

  @HostListener('mouseenter', ['$event'])
  onMouseEnter(e: MouseEvent): void {
    if (this.handleHover) {
      this.openDropdown();
    }
  }

  @HostListener('mouseleave', ['$event'])
  onMouseLeave(e: MouseEvent): void {
    if (this.handleHover && !this.isMouseLeaveDisabled) {
      this.closeDropdown();
    }
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (this.handleOffclick && this.fullyOpened && !this.dropdownElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  @HostListener('keydown', ['$event'])
  keydown(event: any): boolean {
    if (this.handleKeydown) {
      switch (event.key) {
        case 'Tab':
          if (this.openedWithDelay) {
            this.closeDropdown();
          }
          break;
        case 'Enter':
          event.preventDefault();
          if (this.openedWithDelay) {
            this.closeDropdown();
          } else {
            this.openDropdown();
          }
          return false;
      }
    }
  }
}


<gw-modal id="findingDuplicationsModal" #findingDuplicationsModal="gwModal">
  <ng-container modal-header="modal-header">{{ 'AUDIT_CONDUCT.QUESTION.DUPLICATIONS_MODAL.HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content">
    <p>{{ 'AUDIT_CONDUCT.QUESTION.DUPLICATIONS_MODAL.LEAD' | translate }}:</p>
    <div class="duplicated-attachments">
      <ng-container *ngFor="let attachment of duplications">
        <gw-attachment class="attachment-container" *ngIf="!attachment.toRemove &amp;&amp; attachment.isImage" [attachment]="attachment" [viewOnly]="!attachment.url" (removed)="removeAttachment(attachment)"></gw-attachment>
      </ng-container>
    </div>
    <div class="duplicated-attachments">
      <ng-container *ngFor="let attachment of duplications">
        <gw-attachment class="attachment-container" *ngIf="!attachment.toRemove &amp;&amp; !attachment.isImage" [attachment]="attachment" [viewOnly]="!attachment.url" (removed)="removeAttachment(attachment)"></gw-attachment>
      </ng-container>
    </div>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="button--primary" type="button" (click)="findingDuplicationsModal.close()">{{ 'GLOBAL.ACTION.CONFIRM' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="add-finding">
  <form class="add-finding__form" *ngIf="addFindingForm" [formGroup]="addFindingForm" (ngSubmit)="submitAddFindingForm()" novalidate="novalidate" autocomplete="off">
    <div class="add-finding__fieldset">
      <div class="row add-finding__fields">
        <div class="columns small-12 small-centered">
          <div class="add-finding__field" [class.form__field--error]="isFieldInvalid(addFindingForm.controls['type'])">
            <label class="label add-finding__field-label" for="type" (click)="typeSelect.focusDropdown()">{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.LABEL.TYPE' | translate }}</label>
            <div class="form-field__error">{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.ERROR.TYPE_EMPTY' | translate }}</div>
            <gw-select #typeSelect="gwSelect" [formControl]="addFindingForm.controls['type']" [tabindex]="1" [placeholder]="'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.PLACEHOLDER.SELECT_TYPE' | translate" [data]="findingTypes" [display]="['name']" [uniqueKey]="'id'"></gw-select>
          </div>
          <div class="add-finding__field" [class.form__field--error]="isFieldInvalid(addFindingForm.controls['findable'])">
            <label class="label add-finding__field-label" for="findable" (click)="findableSelect.focusDropdown()">{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.LABEL.FINDABLE' | translate }}</label>
            <div class="form-field__error">{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.ERROR.FINDABLE_EMPTY' | translate }}</div>
            <gw-select #findableSelect="gwSelect" [formControl]="addFindingForm.controls['findable']" [tabindex]="2" [placeholder]="'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.PLACEHOLDER.SELECT_FINDABLE' | translate" [data]="findableData" [display]="['name', 'content']" [uniqueKey]="'id'" [nestedValue]="'questions'"></gw-select>
          </div>
          <div class="add-finding__field" [class.form__field--error]="isFieldInvalid(addFindingForm.controls['problem'])">
            <label class="label add-finding__field-label add-finding__field-label--with-icon" for="problem"><i class="gw-warning label-icon--before add-finding__field-label-icon--problem"></i>{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.LABEL.PROBLEM' | translate }}</label>
            <div class="form-field__error">{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.ERROR.PROBLEM_EMPTY' | translate }}</div>
            <div class="textarea-container">
              <textarea class="textarea--small textarea--autosize" gwAutosize="gwAutosize" rows="1" placeholder="{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.PLACEHOLDER.PROBLEM' | translate }}" id="problem" name="problem" tabindex="3" [formControl]="addFindingForm.controls['problem']"></textarea>
              <div class="textarea-border"></div>
            </div>
          </div>
          <div class="add-finding__field">
            <label class="label add-finding__field-label add-finding__field-label--with-icon" for="cause"><i class="gw-questionmark label-icon--before add-finding__field-label-icon--cause"></i>{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.LABEL.CAUSE' | translate }}</label>
            <div class="textarea-container">
              <textarea class="textarea--small textarea--autosize" gwAutosize="gwAutosize" rows="1" placeholder="{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.PLACEHOLDER.CAUSE' | translate }}" id="cause" name="cause" tabindex="4" [formControl]="addFindingForm.controls['cause']"></textarea>
              <div class="textarea-border"></div>
            </div>
          </div>
          <div class="add-finding__field">
            <label class="label add-finding__field-label add-finding__field-label--with-icon" for="solution"><i class="gw-info label-icon--before add-finding__field-label-icon--solution"></i>{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.LABEL.SOLUTION' | translate }}</label>
            <div class="textarea-container">
              <textarea class="textarea--small textarea--autosize" gwAutosize="gwAutosize" rows="1" placeholder="{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.PLACEHOLDER.SOLUTION' | translate }}" id="solution" name="solution" tabindex="5" [formControl]="addFindingForm.controls['solution']"></textarea>
              <div class="textarea-border"></div>
            </div>
          </div>
          <div class="add-finding__field">
            <label class="label add-finding__field-label" *ngIf="attachmentsAmount">{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.LABEL.ATTACHMENTS' | translate }} ({{ attachmentsAmount }})</label>
            <div class="add-finding__attachments">
              <ng-container *ngFor="let attachment of attachments">
                <gw-attachment class="attachment-container" *ngIf="!attachment.toRemove &amp;&amp; attachment.isImage" [attachment]="attachment" (removed)="removeAttachment(attachment)"></gw-attachment>
              </ng-container>
            </div>
            <div class="add-finding__attachments">
              <ng-container *ngFor="let attachment of attachments">
                <gw-attachment class="attachment-container" *ngIf="!attachment.toRemove &amp;&amp; !attachment.isImage" [attachment]="attachment" (removed)="removeAttachment(attachment)"></gw-attachment>
              </ng-container>
            </div>
          </div>
          <div class="add-finding__field">
            <label class="button--tertiary button--small button--start-case add-finding__field-button" for="upload" tabindex="6" (keydown)="keyDownAttachmentControl($event, fileInput)"><i class="gw-attach button__icon--before"></i>{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.LABEL.FILE' | translate }}
              <input class="hide" #fileInput="" type="file" multiple="multiple" id="upload" placeholder="Upload file" (change)="attachFiles($event)"/>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
import { Injectable } from '@angular/core';

import { LoginService } from '../../../../services/login.service';
import { StorageService, StoragesNames } from '../../../../services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class SsoService {
  validOrigins = [];

  constructor(protected loginService: LoginService, protected storageService: StorageService) {}

  getToken(): string {
    return this.storageService.getLocalStorage(StoragesNames.token);
  }

  // Override this function in app, so it will validate token in API
  validateToken(token: string): Promise<boolean> {
    return Promise.resolve(false);
  }

  // Override this function in app, so it will revoke token in API
  revokeToken(token: string): Promise<void> {
    return Promise.reject();
  }

  async logOut(redirect = true, redirectUrl?: string, externalAuthUrl?: string, fromExternal?: boolean): Promise<void> {
    const token = this.getToken();
    await this.revokeToken(token);
    this.loginService.logOut(redirect, redirectUrl, externalAuthUrl, fromExternal);
  }

  isOriginValid(redirectUrl: string): boolean {
    return redirectUrl && this.validOrigins.some(origin => redirectUrl.startsWith(origin));
  }
}

import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';

import { TreeFilterOption } from '../../models/tree-filter-option.model';

import { ExpandBoxComponent } from '../expand-box/expand-box.component';

@Component({
  selector: 'gw-tree-control',
  templateUrl: './tree-control.component.html',
  styleUrls: ['./tree-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'gwTreeControl'
})
export class TreeControlComponent {
  @ViewChild('nodeExpandBox') nodeExpandBox: ExpandBoxComponent;

  @Input() node: TreeFilterOption;
  @Input() opened = false;
  @Input() markSelectedChildren = false;
  @Input() isTextBold = false;
  @Input() isParentSelected = false;
  @Input() hasParent = false;
  @Input() isParentFullyClosed?: boolean;
  @Input() animationsDisabled?: boolean;
}

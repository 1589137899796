import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showPagination'
})
export class ShowPaginationPipe implements PipeTransform {
  transform(isPagination: boolean, showIfLessThanPageLimit: boolean, total: number, pageLimit: number): boolean {
    return isPagination && ((showIfLessThanPageLimit && !!total) || total > pageLimit);
  }
}


<gw-modal id="ratingScaleModal" #ratingScaleModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="ratingScaleModal.data">{{ 'ADMIN_SETTINGS.RATING_SCALES.MODAL.HEADER.EDIT' | translate }}</ng-container>
    <ng-container *ngIf="!ratingScaleModal.data">{{ 'ADMIN_SETTINGS.RATING_SCALES.MODAL.HEADER.ADD' | translate }}</ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="ratingScaleModal.opened">
    <gw-add-rating-scale #addRatingScale="gwAddRatingScale" [ratingScale]="ratingScaleModal.data" [auditTypes]="auditTypes"></gw-add-rating-scale>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="ratingScaleModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="saveRatingScale()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="row rating-scales">
  <div class="columns">
    <div class="row rating-scales__header">
      <div class="columns small-12 large-8 rating-scales-header__title-container">
        <h1 class="rating-scales-header__title">{{ 'ADMIN_SETTINGS.RATING_SCALES.HEADER' | translate }}</h1>
      </div>
      <div class="columns small-12 large-4 rating-scales-header__button-container">
        <button class="button--primary rating-scales-header__button" (click)="ratingScaleModal.open()">{{ 'ADMIN_SETTINGS.RATING_SCALES.ACTION.NEW_SCALE' | translate }}</button>
      </div>
    </div>
    <div class="rating-scales__content">
      <div class="flex-table rating-scales-table">
        <div class="flex-table__row">
          <div class="flex-table__cell--th rating-scales-table__col--1">{{ 'GLOBAL.TABLE_LABEL.NAME' | translate }}</div>
          <div class="flex-table__cell--th rating-scales-table__col--2">{{ 'GLOBAL.TABLE_LABEL.AUDIT_TYPES' | translate }}</div>
          <div class="flex-table__cell--th rating-scales-table__col--3"></div>
        </div>
        <div class="flex-table__row rating-scales-table__row--content" *ngFor="let ratingScale of ratingScales; even as evenRatingScale" [class.flex-table__row--even]="evenRatingScale" (click)="showRatingScaleDetails(ratingScale)">
          <div class="flex-table__cell rating-scales-table__col--1" [class.rating-scales-table__default-scale-name]="ratingScale.default">{{ ratingScale.name }}</div>
          <div class="flex-table__cell rating-scales-table__col--2">
            <ng-container *ngIf="!ratingScale.default">
              <ng-container *ngFor="let auditType of ratingScale.auditTypes; last as isLast">{{ auditType.title }}{{ isLast ? '' : ', ' }}</ng-container>
            </ng-container>
          </div>
          <div class="flex-table__cell rating-scales-table__col--3" gwStopPropagation="gwStopPropagation"><span class="dropdown dropdown--menu" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
              <button class="rating-scales-table__option" type="button"><i class="gw-more"></i></button>
              <div class="dropdown__content jsDropdownContent">
                <ul class="dropdown-menu__list">
                  <li class="dropdown-menu__item">
                    <button class="link dropdown-menu__link" type="button" (click)="showUpdateRatingScaleModal(ratingScale)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                  </li>
                  <li class="dropdown-menu__item" *ngIf="!ratingScale.default">
                    <button class="link dropdown-menu__link" type="button" (click)="setRatingScaleAsDefault(ratingScale)">{{ 'ADMIN_SETTINGS.RATING_SCALES.ACTION.SET_AS_DEFAULT' | translate }}</button>
                  </li>
                  <li class="dropdown-menu__item" *ngIf="!ratingScale.default">
                    <button class="link dropdown-menu__link" type="button" (click)="showArchiveModal(ratingScale)">{{ 'GLOBAL.ACTION.ARCHIVE' | translate }}</button>
                  </li>
                </ul>
              </div></span></div>
        </div>
      </div>
    </div>
  </div>
</div>
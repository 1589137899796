import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { noop } from 'rxjs';

import { Attachment } from '../../models/attachment.model';
import { LegalContainer } from '../../models/legal-container.model';

import { UserType } from '../../enums/user-type.enum';

import { ExpandBoxComponent } from '../expand-box/expand-box.component';

import { DialogService } from '../../services/dialog.service';
import { LightboxService } from '../../services/lightbox.service';

@Component({
  selector: 'gw-container-box',
  templateUrl: './container-box.component.html',
  styleUrls: ['./container-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerBoxComponent implements OnInit, OnChanges {
  @ViewChild('containerExpandBox', { static: true }) expandBox: ExpandBoxComponent;

  @Input() container: LegalContainer;
  @Input() detailsRoute = '/laws/changes';
  @Input() isExpanded: boolean;
  @Input() selectedContainer: LegalContainer;
  @Input() isFirst: boolean;
  @Input() isEven: boolean;
  @Input() isNameThin: boolean;
  @Input() isRemovable = true;
  @Input() selectable = false;
  @Input() userType: UserType;
  @Input() defaultCategoryColor?: string;

  @Output() archive = new EventEmitter<string>();
  @Output() selectContainer = new EventEmitter<LegalContainer>();
  @Output() goToDetails = new EventEmitter<LegalContainer>();

  constructor(
    private dialogService: DialogService,
    private lightboxService: LightboxService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (this.isExpanded) {
      this.toggle(true);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isExpanded) {
      this.toggle(changes.isExpanded.currentValue);
    }
  }

  toggle(open: boolean): void {
    if (this.expandBox) {
      if (open && !this.expandBox.opened) {
        this.expandBox.toggle();
      } else if (!open && this.expandBox.opened) {
        this.expandBox.toggle();
      }
    }
  }

  showArchiveContainerModal(): void {
    const header = this.translateService.instant('GUTWIN_SHARED.LABEL.WARNING');
    const content = this.translateService.instant('GUTWIN_SHARED.CHANGES_TABLE.WARNING.REMOVE_DIALOG');
    const cancelButton = this.translateService.instant('GUTWIN_SHARED.ACTION.CANCEL');
    const confirmButton = this.translateService.instant('GUTWIN_SHARED.ACTION.CONFIRM');

    this.dialogService
      .confirm(header, content, cancelButton, confirmButton)
      .then(() => {
        this.archiveContainer();
      })
      .catch(noop);
  }

  archiveContainer(): void {
    this.archive.emit(this.container.id);
  }

  onSelectContainer(): void {
    this.selectContainer.emit(this.container);
  }

  toggleDetails(event: Event): void {
    if (this.selectable) {
      event.stopPropagation();
      this.expandBox.toggle();
    }
  }

  showFlowChartPreview(): void {
    const attachmentPreview = new Attachment({
      ...this.container.flowChart.original,
      size: NaN
    });
    this.lightboxService.show(attachmentPreview);
  }

  showDetails(): void {
    this.goToDetails.emit(this.container);
  }
}

import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
// Gutwin Shared Library
import { Employee, LoginService } from 'gutwin-shared';
import { AuthenticationService } from '@gutwin-audit/shared/services/authentication.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';
// Services

@Component({
  selector: 'gw-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private loginService: LoginService,
    private userService: UserService
  ) {}

  separatePath(url: string): string {
    const index = url.indexOf('?');
    if (~index) {
      return url.slice(0, index);
    }
    return url;
  }

  separateQueryParams(url: string): any {
    const index = url.indexOf('?');
    if (~index) {
      const params = url.slice(index + 1);
      return JSON.parse('{"' + params.replace(/&/g, '","').replace(/=/g, '":"') + '"}', (key, value) =>
        key === '' ? value : decodeURIComponent(value)
      );
    }
  }

  onLogIn(user: Partial<Employee>): void {
    const queryParams = this.route.queryParams['value'];
    let url = '/audits';
    let navigationExtras: NavigationExtras;
    if (queryParams.redirect_url) {
      url = this.separatePath(queryParams.redirect_url);
      navigationExtras = {
        queryParams: this.separateQueryParams(queryParams.redirect_url)
      };
    }

    this.authenticationService.getToken(user).then(token => {
      if (this.loginService.isLoggedIn()) {
        this.userService.getCurrentUser(true);
        this.router.navigate([url], navigationExtras);
      }
    });
  }
}


<div class="add-report">
  <form class="add-report__form" [formGroup]="addReportForm" novalidate="novalidate" autocomplete="off">
    <div class="add-report__fieldset">
      <div class="row add-report__fields">
        <div class="columns small-12 small-centered">
          <div class="form__field--lower add-report__field" [class.form__field--error]="isFieldInvalid(addReportForm.controls['name'])">
            <label class="label add-report__field-label" for="name">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.LABEL.NAME' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.ERROR.NAME_EMPTY' | translate }}</div>
            <input class="input" placeholder="{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.PLACEHOLDER.NAME' | translate }}" type="text" id="name" name="name" tabindex="1" [formControl]="addReportForm.controls['name']"/>
          </div>
          <div class="form__field--lower add-report__field" *ngIf="!report?.default" [class.form__field--error]="isFieldInvalid(addReportForm.controls['auditTypes'])">
            <label class="label" for="auditTypes" (click)="auditTypesSelect.focusDropdown()">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.LABEL.AUDIT_TYPES' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.ERROR.AUDIT_TYPES' | translate }}</div>
            <div class="add-report__multiselect-wrapper">
              <gw-multiselect #auditTypesSelect="gwMultiselect" [formControl]="addReportForm.controls['auditTypes']" [tabindex]="2" [placeholder]="'ADMIN_SETTINGS.REPORTS.MODAL.FORM.PLACEHOLDER.AUDIT_TYPES' | translate" [data]="auditTypes" [display]="['title']" [splitter]="', '" [uniqueKey]="'id'" (focus)="auditTypesSelect.focusDropdown()"></gw-multiselect>
            </div>
          </div>
          <div class="form__field--lower add-report__field" [class.form__field--error]="isFieldInvalid(addReportForm.controls['document'])">
            <label class="label" for="document">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.LABEL.DOCUMENT' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.ERROR.DOCUMENT' | translate }}</div>
            <div class="add-report__file-preview"><a class="link--underline" target="_blank" *ngIf="report?.document || documentPreview" [href]="documentPreview?.url || report.document?.url">{{ documentPreview?.name || report.document?.name }}</a></div>
            <div>
              <label class="import-questionnaire-modal__action button--tertiary" for="document">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.PLACEHOLDER.DOCUMENT' | translate }}
                <input class="hide" name="document" type="file" id="document" tabindex="3" (change)="fileAdded($event)" accept=".docx"/>
              </label>
            </div>
          </div>
          <div class="info-box"><i class="gw-icon gw-warning info-box__icon"></i>
            <div class="info-box__content">
              <div class="info-box__section">
                <div class="info-box__row">
                  <div class="info-box__title">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.WARNING.WARNIG_HEADER' | translate }}</div>
                </div>
                <div class="info-box__row">
                  <div class="info-box__text">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.WARNING.WARNING_TEXT' | translate }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="form__field--lower add-report__field" [class.form__field--error]="isFieldInvalid(addReportForm.controls['companyLogoWidth'])">
            <label class="label add-report__field-label" for="companyLogoWidth">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.LABEL.LOGO_WIDTH' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.ERROR.LOGO_WIDTH_EMPTY' | translate }}</div>
            <input class="input" placeholder="{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.PLACEHOLDER.VALUE' | translate }}" type="number" id="logoWidth" name="logoWidth" tabindex="4" [formControl]="addReportForm.controls['companyLogoWidth']"/>
          </div>
          <div class="form__field--lower add-report__field" [class.form__field--error]="isFieldInvalid(addReportForm.controls['questionPhotoWidth'])">
            <label class="label add-report__field-label" for="questionPhotoWidth">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.LABEL.QUESTION_WIDTH' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.ERROR.QUESTION_WIDTH_EMPTY' | translate }}</div>
            <input class="input" placeholder="{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.PLACEHOLDER.VALUE' | translate }}" type="number" id="questionPhotoWidth" name="questionPhotoWidth" tabindex="5" [formControl]="addReportForm.controls['questionPhotoWidth']"/>
          </div>
          <div class="form__field--lower add-report__field" [class.form__field--error]="isFieldInvalid(addReportForm.controls['findingPhotoWidth'])">
            <label class="label add-report__field-label" for="findingPhotoWidth">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.LABEL.FINDING_WIDTH' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.ERROR.FINDING_WIDTH_EMPTY' | translate }}</div>
            <input class="input" placeholder="{{ 'ADMIN_SETTINGS.REPORTS.MODAL.FORM.PLACEHOLDER.VALUE' | translate }}" type="number" id="findingPhotoWidth" name="findingPhotoWidth" tabindex="6" [formControl]="addReportForm.controls['findingPhotoWidth']"/>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
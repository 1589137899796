
<div class="finding-view-details__section">
  <div class="finding__item finding__item--type"><span class="finding-item__label">{{ 'FINDING_VIEW.DETAIL.LABEL.TYPE' | translate }}:</span>
    <div class="finding-view-details__text" *ngIf="!canManageFinding">{{ finding.relationships.findingType.name }}</div>
    <ng-container *ngIf="canManageFinding">
      <button class="finding-view-details__link" type="button" *ngIf="!editing.findingType" (click)="toggleFindingTypeEdition()">{{ finding.relationships.findingType.name }}<i class="gw-pen finding-view-details__icon"></i></button>
      <div class="finding-view-details__edition-wrapper"><span class="finding-view-details__edition" [class.finding-view-details__edition--visible]="editing.findingType">
          <gw-select class="finding-view-details__select" *ngIf="editing.findingType" [ngModel]="finding.relationships.findingType" [small]="true" [placeholder]="'FINDING_VIEW.DETAIL.PLACEHOLDER.SELECT_TYPE' | translate" [data]="findingTypes" [display]="['name']" [uniqueKey]="'id'" [noWrapHeader]="true" (ngModelChange)="setFindingType($event)"></gw-select>
          <button class="link link--tiny finding-view-details__action" type="button" (click)="cancelFindingTypeEdition()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
          <button class="button--primary button--tiny finding-view-details__action" type="button" (click)="submitFindingType()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button></span></div>
    </ng-container>
  </div>
  <div class="finding__item finding__item--question" *ngIf="finding.relationships?.question"><span class="finding-item__label">{{ 'FINDING_VIEW.DETAIL.LABEL.QUESTION' | translate }}:</span>
    <div class="finding-view-details__text" *ngIf="!canManageFinding">{{ finding.relationships.question }}</div>
    <ng-container *ngIf="canManageFinding">
      <button class="finding-view-details__link" type="button" *ngIf="!editing.question" (click)="toggleQuestionEdition()">{{ finding.relationships.question }}<i class="gw-pen finding-view-details__icon"></i></button>
      <div class="finding-view-details__edition-wrapper"><span class="finding-view-details__edition" [class.finding-view-details__edition--visible]="editing.question">
          <gw-select class="finding-view-details__select" *ngIf="editing.question" [ngModel]="finding.relationships.findable" [small]="true" [placeholder]="'FINDING_VIEW.DETAIL.PLACEHOLDER.SELECT_QUESTION' | translate" [data]="sections" [display]="['name', 'content']" [uniqueKey]="'id'" [nestedValue]="'questions'" [noWrapHeader]="true" (ngModelChange)="setQuestion($event)"></gw-select>
          <button class="link link--tiny finding-view-details__action" type="button" (click)="cancelQuestionEdition()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
          <button class="button--primary button--tiny finding-view-details__action" type="button" (click)="submitQuestion()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button></span></div>
    </ng-container>
  </div>
  <div class="finding__item finding__item--problem">
    <div class="finding-view-details__text" *ngIf="!canManageFinding">{{ finding.problem }}</div>
    <ng-container *ngIf="canManageFinding">
      <button class="finding-view-details__link--problem" type="button" *ngIf="!editing.problem" (click)="toggleEdition('problem')">{{ finding.problem }}<i class="gw-pen finding-view-details__icon"></i></button>
      <div class="finding-view-details__edition-wrapper finding-view-details__edition-wrapper--standalone">
        <form class="finding-view-details__edition" #problemForm="ngForm" novalidate="novalidate" [class.finding-view-details__edition--visible]="editing.problem" (ngSubmit)="submitForm(problemForm, 'problem')">
          <input class="input-classic input-classic--small finding-view-details__input" type="text" [ngModel]="finding.problem" name="problem" [class.input--error]="!problemForm.valid" #problemControl="" required="required"/>
          <button class="link link--tiny finding-view-details__action" type="button" (click)="cancelEdition(problemForm, 'problem')">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
          <button class="button--primary button--tiny finding-view-details__action" type="submit">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
        </form>
      </div>
    </ng-container>
  </div>
  <div class="finding__item finding__item--cause"><i class="gw-questionmark finding-icon finding-item__icon"></i><span class="finding-item__label">{{ 'FINDING_VIEW.DETAIL.LABEL.CAUSE' | translate }}:</span>
    <div class="finding-view-details__text" *ngIf="!canManageFinding &amp;&amp; !canManageCauseAndSolution">{{ finding.cause ? finding.cause : '-' }}</div>
    <ng-container *ngIf="canManageFinding || canManageCauseAndSolution">
      <button class="finding-view-details__link" type="button" *ngIf="finding.cause &amp;&amp; !editing.cause" (click)="toggleEdition('cause')">{{ finding.cause }}<i class="gw-pen finding-view-details__icon"></i></button>
      <div class="finding-view-details__edition-wrapper">
        <form class="finding-view-details__edition" [class.finding-view-details__edition--visible]="editing.cause" #causeForm="ngForm" (ngSubmit)="submitForm(causeForm, 'cause')" novalidate="novalidate">
          <input class="input-classic input-classic--small finding-view-details__input" type="text" [ngModel]="finding.cause" name="cause" #causeControl=""/>
          <button class="link link--tiny finding-view-details__action" type="button" (click)="cancelEdition(causeForm, 'cause')">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
          <button class="button--primary button--tiny finding-view-details__action" type="submit">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
        </form>
        <button class="button--secondary button--tiny finding-view-details__action--edit finding-view-details__action--no-margin" type="button" *ngIf="!finding.cause &amp;&amp; !editing.cause" (click)="toggleEdition('cause')">{{ 'FINDING_VIEW.DETAIL.ACTION.TYPE_CAUSE' | translate }}</button>
      </div>
    </ng-container>
  </div>
  <div class="finding__item finding__item--solution"><i class="gw-info finding-icon finding-item__icon"></i><span class="finding-item__label">{{ 'FINDING_VIEW.DETAIL.LABEL.SOLUTION' | translate }}:</span>
    <div class="finding-view-details__text" *ngIf="!canManageFinding &amp;&amp; !canManageCauseAndSolution">{{ finding.solution ? finding.solution : '-' }}</div>
    <ng-container *ngIf="canManageFinding || canManageCauseAndSolution">
      <button class="finding-view-details__link" type="button" *ngIf="finding.solution &amp;&amp; !editing.solution" (click)="toggleEdition('solution')">{{ finding.solution }}<i class="gw-pen finding-view-details__icon"></i></button>
      <div class="finding-view-details__edition-wrapper">
        <form class="finding-view-details__edition" [class.finding-view-details__edition--visible]="editing.solution" #solutionForm="ngForm" (ngSubmit)="submitForm(solutionForm, 'solution')" novalidate="novalidate">
          <input class="input-classic input-classic--small finding-view-details__input" type="text" [ngModel]="finding.solution" name="solution" #solutionControl=""/>
          <button class="link link--tiny finding-view-details__action" type="button" (click)="cancelEdition(solutionForm, 'solution')">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
          <button class="button--primary button--tiny finding-view-details__action" type="submit">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
        </form>
        <button class="button--secondary button--tiny finding-view-details__action--edit finding-view-details__action--no-margin" type="button" *ngIf="!finding.solution &amp;&amp; !editing.solution" (click)="toggleEdition('solution')">{{ 'FINDING_VIEW.DETAIL.ACTION.TYPE_SOLUTION' | translate }}</button>
      </div>
    </ng-container>
  </div>
</div>
<div class="finding__item"><i class="gw-calendar finding-icon finding-item__icon"></i><span class="finding-item__label">{{ 'FINDING_VIEW.DETAIL.LABEL.DEADLINE' | translate }}:</span>
  <div class="finding-view-details__text" *ngIf="!canManageFinding">
    <ng-container *ngIf="finding.deadline">{{ finding.deadline | dateFormat: 'D MMMM YYYY' }}</ng-container>
    <ng-container *ngIf="!finding.deadline">-</ng-container>
  </div>
  <ng-container *ngIf="canManageFinding">
    <div class="dropdown dropdown--single-date-picker finding-view-details__dropdown" gwSimpleDropdown="gwSimpleDropdown" #deadlineDropdown="simpleDropdown" [class.dropdown--opened]="deadlineDropdown.opened" [class.dropdown--closed]="!deadlineDropdown.opened &amp;&amp; !deadlineDropdown.firstDrop">
      <button class="button--secondary button--tiny finding-view-details__action finding-view-details__action--no-margin finding-view-details__edition--visible" *ngIf="!finding.deadline" type="button">{{ 'FINDING_VIEW.DETAIL.ACTION.CHOOSE_DEADLINE' | translate }}</button>
      <button class="finding-view-details__link" *ngIf="finding.deadline" type="button" [class.finding-view-details__link--active]="deadlineDropdown.opened" (click)="toggleDeadlineEdition()">{{ finding.deadline | dateFormat: 'D MMMM YYYY' }}<i class="gw-pen finding-view-details__icon"></i></button>
      <div class="dropdown__content jsDropdownContent finding-view-details__dropdown-content">
        <gw-date-picker *ngIf="deadlineDropdown.openedWithDelay" [after]="nowDate" [date]="getDateForDatepicker()" (setDate)="setDeadline($event)"></gw-date-picker>
        <div class="dropdown__actions button-group">
          <button class="link" type="button" (click)="deadlineDropdown.closeDropdown()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
          <button class="button--primary button--small" type="button" (click)="submitDeadline(); deadlineDropdown.closeDropdown();">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="finding__item"><i class="gw-employee finding-icon finding-item__icon"></i><span class="finding-item__label">{{ 'FINDING_VIEW.DETAIL.LABEL.REPONSIBLE_PERSON' | translate }}:</span>
  <div class="finding-view-details__text" *ngIf="!canManageFinding">
    <ng-container *ngIf="finding.relationships.employee">{{ finding.relationships.employee.lastname }}, {{ finding.relationships.employee.name }}</ng-container>
    <ng-container *ngIf="!finding.relationships.employee">-</ng-container>
  </div>
  <ng-container *ngIf="canManageFinding">
    <button class="finding-view-details__link" type="button" *ngIf="finding.relationships.employee &amp;&amp; !editing.responsiblePerson" (click)="toggleResponsiblePersonEdition()">{{ finding.relationships.employee?.lastname }}, {{ finding.relationships.employee?.name }}<i class="gw-pen finding-view-details__icon"></i></button>
    <div class="finding-view-details__edition-wrapper"><span class="finding-view-details__edition" [class.finding-view-details__edition--visible]="editing.responsiblePerson">
        <gw-select class="finding-view-details__select" *ngIf="editing.responsiblePerson" [ngModel]="finding.relationships.employee" [small]="true" [search]="true" [placeholder]="'FINDING_VIEW.DETAIL.PLACEHOLDER.CHOOSE_REPONSIBLE_PERSON' | translate" [data]="employees" [display]="['lastname', 'name']" [splitter]="', '" [uniqueKey]="'id'" (ngModelChange)="setResponsiblePerson($event)"></gw-select>
        <button class="link link--tiny finding-view-details__action" type="button" (click)="cancelResponsiblePersonEdition()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
        <button class="button--primary button--tiny finding-view-details__action" type="button" (click)="submitResponsiblePerson()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button></span>
      <button class="button--secondary button--tiny finding-view-details__action--edit finding-view-details__action--no-margin" *ngIf="!finding.relationships.employee &amp;&amp; !editing.responsiblePerson" type="button" (click)="toggleResponsiblePersonEdition()">{{ 'FINDING_VIEW.DETAIL.ACTION.CHOOSE_REPONSIBLE_PERSON' | translate }}</button>
    </div>
  </ng-container>
</div>
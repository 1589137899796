import { FilterType } from '../enums/filter-type.enum';
import { LcFilterListName } from '../enums/lc-filter-list.enum';
import { UserType } from '../enums/user-type.enum';

import { ShowFiltersConditions } from '../types/show-filters-conditions.type';

export const SHOW_FILTERS_CONDITIONS: ShowFiltersConditions = {
  [FilterType.relevanceRatings]: {
    [UserType.client]: [
      LcFilterListName.legalRegister,
      LcFilterListName.requirements,
      LcFilterListName.unitRequirements
    ]
  },
  [FilterType.complianceRatings]: {
    [UserType.client]: [LcFilterListName.requirements, LcFilterListName.unitRequirements]
  },
  [FilterType.consultants]: {
    [UserType.consultant]: Object.keys(LcFilterListName) as Array<LcFilterListName>
  },
  [FilterType.dateOfIssue]: {
    [UserType.client]: [LcFilterListName.permits]
  },
  [FilterType.deadline]: {
    [UserType.consultant]: Object.keys(LcFilterListName) as Array<LcFilterListName>
  },
  [FilterType.effectiveUntil]: {
    [UserType.client]: [LcFilterListName.permits]
  },
  [FilterType.issuedOn]: {
    [UserType.client]: [LcFilterListName.legalChanges]
  },
  [FilterType.laws]: {
    [UserType.client]: [
      LcFilterListName.legalChanges,
      LcFilterListName.legalQuestions,
      LcFilterListName.requirements,
      LcFilterListName.unitRequirements
    ],
    [UserType.consultant]: [
      LcFilterListName.legalChanges,
      LcFilterListName.legalQuestions,
      LcFilterListName.requirements
    ]
  },
  [FilterType.intervals]: {
    [UserType.client]: [LcFilterListName.requirements, LcFilterListName.unitRequirements],
    [UserType.consultant]: [LcFilterListName.requirements]
  },
  [FilterType.inactive]: {
    [UserType.client]: [
      LcFilterListName.legalRegister,
      LcFilterListName.legalQuestions,
      LcFilterListName.requirements,
      LcFilterListName.unitRequirements,
      LcFilterListName.permits
    ],
    [UserType.consultant]: [
      LcFilterListName.legalRegister,
      LcFilterListName.legalQuestions,
      LcFilterListName.permits,
      LcFilterListName.requirements
    ]
  },
  [FilterType.legalCategories]: {
    [UserType.client]: [LcFilterListName.permits, LcFilterListName.unitRequirements],
    [UserType.consultant]: Object.keys(LcFilterListName) as Array<LcFilterListName>
  },
  [FilterType.legalOrigins]: {
    [UserType.client]: [
      LcFilterListName.legalChanges,
      LcFilterListName.legalRegister,
      LcFilterListName.legalQuestions,
      LcFilterListName.requirements,
      LcFilterListName.unitRequirements
    ],
    [UserType.consultant]: Object.keys(LcFilterListName) as Array<LcFilterListName>
  },
  [FilterType.products]: {
    [UserType.client]: [
      LcFilterListName.legalChanges,
      LcFilterListName.legalRegister,
      LcFilterListName.legalQuestions,
      LcFilterListName.requirements,
      LcFilterListName.unitRequirements
    ],
    [UserType.consultant]: Object.keys(LcFilterListName) as Array<LcFilterListName>
  },
  [FilterType.publication]: {
    [UserType.consultant]: [LcFilterListName.legalChanges]
  },
  [FilterType.statuses]: {
    [UserType.consultant]: Object.keys(LcFilterListName) as Array<LcFilterListName>
  },
  [FilterType.submittedOn]: {
    [UserType.client]: [LcFilterListName.permits]
  },
  [FilterType.units]: {
    [UserType.client]: [LcFilterListName.requirements]
  },
  [FilterType.validFrom]: {
    [UserType.client]: [LcFilterListName.legalChanges],
    [UserType.consultant]: [LcFilterListName.legalChanges]
  }
};

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CompanySubmodulesNames } from '../../enums/company-submodules.enum';

import { getSubmoduleTranslationKeys } from '../../utils/license.util';

@Component({
  selector: 'gw-not-licensed-module-tooltip',
  templateUrl: './not-licensed-module-tooltip.component.html',
  styleUrls: ['./not-licensed-module-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotLicensedModuleTooltipComponent {
  @Input() set submodule(submodule: CompanySubmodulesNames) {
    this.initTooltipText(submodule);
  }
  @Input() showOnTheRight?: boolean;

  titleKey: string;
  contentKey: string;

  initTooltipText(submodule: CompanySubmodulesNames): void {
    const { titleKey, contentKey } = getSubmoduleTranslationKeys(submodule);
    this.titleKey = titleKey;
    this.contentKey = contentKey;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { UserProfile } from '../models/old-api-user-profile.model';

import { ApiUserProfile } from '../interfaces/old-api-user-profile.interface';

import { ApiUrl } from '../enums/old-api-url.enum';

import { I18nService } from './i18n.service';
import { StorageService, StoragesNames } from './storage.service';

@Injectable()
export class UserProfileOldApiService {
  ApiUrl = ApiUrl;

  constructor(
    protected http: HttpClient,
    protected storageService: StorageService,
    protected i18nService: I18nService
  ) {}

  fetchUserProfile(customerId?: number): Promise<UserProfile> {
    return this.http
      .get(`${this.ApiUrl.userApi}/profile`)
      .toPromise()
      .then((userProfile: ApiUserProfile) => {
        const convertedUserProfile = this.convertUserToGet(userProfile, customerId);
        this.setCurrentUserProfile(convertedUserProfile);
        return convertedUserProfile;
      })
      .catch(async error => {
        throw error;
      });
  }

  setCurrentUserProfile(userProfile: UserProfile): void {
    this.storageService.setLocalStorage(StoragesNames.currentUser, userProfile);
  }

  getCurrentUserProfile(): UserProfile {
    return this.storageService.getLocalStorage(StoragesNames.currentUser);
  }

  deleteCurrentUserProfile(): void {
    this.storageService.removeFromLocalStorage(StoragesNames.currentUser);
  }

  convertUserToGet(user: ApiUserProfile, customerId?: number): UserProfile {
    return {
      username: user.Username,
      firstname: user.Firstname,
      lastname: user.Lastname,
      language: this.i18nService.convertLanguageCode(user.CultureName),
      customerId: customerId || null
    };
  }
}

import moment from 'moment';
// Gutwin Shared Library
import { Attachment, Employee } from 'gutwin-shared';
// Models
import { Audit } from './audit.model';
import { AuditedArea } from './audited-area.model';
import { FindingType } from './finding-type.model';
import { Question } from './question.model';

export enum FindingStatus {
  inProgress = 'in_progress',
  notAssigned = 'not_assigned',
  overdue = 'overdue',
  solved = 'solved'
}

export class FindingRelationships {
  audit: Audit;
  auditedArea: AuditedArea;
  employee: Employee;
  findable: AuditedArea | Question;
  oldFindable: AuditedArea | Question;
  findingType: FindingType;
  question: string;
  section: string;

  constructor(findingRelationships: any) {
    this.audit = findingRelationships.audit ? new Audit(findingRelationships.audit) : undefined;
    this.auditedArea = findingRelationships.auditedArea ? new AuditedArea(findingRelationships.auditedArea) : undefined;
    this.employee = findingRelationships.employee ? new Employee(findingRelationships.employee) : undefined;
    this.findable = this.createFindable(findingRelationships.findable);
    this.oldFindable = this.createFindable(findingRelationships.oldFindable);
    this.findingType = findingRelationships.findingType ? new FindingType(findingRelationships.findingType) : undefined;
    this.question = findingRelationships.question;
    this.section = findingRelationships.section;
  }

  private createFindable(findable: AuditedArea | Question): AuditedArea | Question {
    if (findable) {
      switch (findable._type) {
        case 'AuditedArea':
          return new AuditedArea(findable);
        case 'Question':
          return new Question(findable);
      }
    }
  }
}

export class Finding {
  id: string;
  problem: string;
  cause: string;
  solution: string;
  status: string;
  oldStatus: string;
  attachments: Array<Attachment>;
  deadline: moment.Moment;
  oldDeadline: moment.Moment;
  relationships: FindingRelationships;
  offlineRequest: Finding;
  temporaryFinding: Finding;
  action: {
    create?: boolean;
    update?: boolean;
    remove?: boolean;
    changeSource?: boolean;
  };
  changes: {
    employee?: boolean;
    deadline?: boolean;
  };

  _type = 'Finding';

  constructor(finding?: any) {
    if (finding) {
      this.id = finding.id;
      this.problem = finding.problem;
      this.cause = finding.cause;
      this.solution = finding.solution;
      this.status = finding.status || FindingStatus.notAssigned;
      this.oldStatus = finding.oldStatus;
      this.attachments = this.generateAttachments(finding.attachments);
      if (finding.deadline) {
        this.deadline = moment(finding.deadline);
      }
      if (finding.oldDeadline) {
        this.oldDeadline = moment(finding.oldDeadline);
      }
      this.relationships = new FindingRelationships(finding.relationships || {});
      this.action = {
        create: finding.action ? finding.action.create : false,
        update: finding.action ? finding.action.update : false,
        remove: finding.action ? finding.action.remove : false,
        changeSource: finding.action ? finding.action.changeSource : false
      };
      this.changes = {
        employee: finding.changes ? finding.changes.employee : false,
        deadline: finding.changes ? finding.changes.deadline : false
      };
      if (finding.offlineRequest) {
        this.offlineRequest = new Finding(finding.offlineRequest);
      }
      if (finding.temporaryFinding) {
        this.temporaryFinding = new Finding(finding.temporaryFinding);
      }
    }
  }

  generateAttachments(attachmentsRequest: Array<any>): Array<Attachment> {
    const attachments = new Array<Attachment>();
    if (attachmentsRequest) {
      attachmentsRequest.forEach(attachment => {
        attachments.push(new Attachment(attachment));
      });
    }
    return attachments;
  }

  getLatestValue(key: string): any {
    const temporaryValue = this.temporaryFinding ? this.resolveValue(key, this.temporaryFinding) : undefined;
    const offlineValue = this.offlineRequest ? this.resolveValue(key, this.offlineRequest) : undefined;
    const currentValue = this.resolveValue(key, this);
    return temporaryValue !== undefined ? temporaryValue : (offlineValue !== undefined ? offlineValue : currentValue);
  }

  resolveValue(key: string, finding: Finding): any {
    return key.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : undefined;
    }, finding);
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

import { Role } from 'gutwin-shared';

import { RoleService } from '@gutwin-audit/shared/services/role.service';

@Injectable()
export class RolesResolve implements Resolve<Array<Role>> {
  constructor(
    private roleService: RoleService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Array<Role>> {
    return this.roleService.getRoles().catch(error => {
      this.fetchErrorTranslation().then((translation: any) => {
        this.notificationsService.error(translation.title, translation.text);
      });
      return [];
    });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService
      .get(['GLOBAL.ERROR.CONNECTION', 'ADMIN_SETTINGS.ROLES.ERROR.LOAD_ROLES'])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['ADMIN_SETTINGS.ROLES.ERROR.LOAD_ROLES']
        };
      });
  }
}

import { Component, HostListener, Input, OnInit } from '@angular/core';

// Gutwin Shared Library
import { SimpleDropdownDirective } from 'gutwin-shared';

import { FilterComponent } from '@gutwin-audit/shared/enums/filter-component.enum';

// Services
import { FilterModuleService } from '../../../../../shared/services/filter-module.service';

@Component({
  selector: 'gw-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
  exportAs: 'findingSearchFilter'
})
export class SearchFilterComponent implements OnInit {
  @Input() dropdown: SimpleDropdownDirective;
  findingSearchQuery: string;

  constructor(private filterService: FilterModuleService) {}

  ngOnInit() {
    if (this.filterService.findingsFilters?.search) {
      this.findingSearchQuery = this.filterService.findingsFilters.search;
    }
  }

  submitFindingSearchFilter() {
    this.filterService.setFilter('search', this.findingSearchQuery, true, FilterComponent.findings);
    this.dropdown.closeDropdown();
  }

  @HostListener('keydown', ['$event'])
  keydown(event) {
    if (event.key === 'Enter' && this.dropdown.openedWithDelay) {
      event.preventDefault();
      this.dropdown.closeDropdown();
      this.submitFindingSearchFilter();
      return false;
    }
  }
}

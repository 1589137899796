import { RouteItem } from '../../interfaces/route-item.interface';

export class AddPreviousRoute {
  static readonly type = '[Router] Add previous route';
  constructor(public previousRoute?: RouteItem) {}
}

export class PopPreviousRoute {
  static readonly type = '[Router] Pop previous route';
  constructor() {}
}

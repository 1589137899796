import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Subscription } from 'rxjs';

import { EmployeeExtended, FileService } from 'gutwin-shared';

import {
  Progress,
  ProgressGroup,
  ProgressService,
  ProgressStatus,
  ProgressType
} from '@gutwin-audit/shared/services/progress.service';
import { QuestionnaireService } from '@gutwin-audit/shared/services/questionnaire.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';
import { WebSocketService } from '@gutwin-audit/shared/services/web-socket.service';

@Component({
  selector: 'gw-import-reference-document',
  templateUrl: './import-reference-document.component.html',
  styleUrls: ['./import-reference-document.component.scss'],
  exportAs: 'gwImportReferenceDocument'
})
export class ImportReferenceDocumentComponent implements OnInit, OnDestroy {
  @ViewChild('questionnaireFileInput') questionnaireFileInput: ElementRef;
  @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef;
  @Output() importQuestionnaire = new EventEmitter();
  videoSource = 'https://s3.eu-central-1.amazonaws.com/gutwin-frontend/de/import_questionnaire_de.mp4';
  isVideoEnded = true;
  isVideoPlaying = false;
  progress: ProgressGroup;
  webSocketSubscription: Subscription;
  webSocketChannel = 'ImportJobChannel';
  notify = {
    error: {
      connectionTitle: '',
      questionnaireImportTitle: '',
      questionnaireImportText: ''
    },
    success: {
      questionnaireImportTitle: '',
      questionnaireImportText: ''
    }
  };
  questionnaireImportLabel: string;

  constructor(
    private fileService: FileService,
    private notificationsService: NotificationsService,
    private progressService: ProgressService,
    private questionnaireService: QuestionnaireService,
    private translateService: TranslateService,
    private userService: UserService,
    private webSocketService: WebSocketService
  ) {}

  ngOnInit(): void {
    this.initWebSocket();
    this.fetchTranslations();
  }

  ngOnDestroy(): void {
    this.unsubscribeWebSocket();
  }

  onVideoPlay(): void {
    this.isVideoPlaying = true;
    this.isVideoEnded = false;
  }

  onVideoPause(): void {
    this.isVideoPlaying = false;
  }

  onVideoEnd(): void {
    this.isVideoPlaying = false;
    this.isVideoEnded = true;
  }

  playVideo(): void {
    this.videoPlayer.nativeElement.play();
  }

  pauseVideo(): void {
    this.videoPlayer.nativeElement.pause();
  }

  initWebSocket(): void {
    const promises = new Array<Promise<any>>();
    let currentUser: EmployeeExtended;
    let webSocketToken: string;

    promises.push(
      this.userService.getCurrentUser().then((user: EmployeeExtended) => {
        currentUser = user;
      })
    );

    promises.push(
      this.webSocketService.getSocketToken().then((token: string) => {
        webSocketToken = token;
      })
    );

    Promise.all(promises).then(() => {
      this.webSocketService.createConsumer(currentUser, webSocketToken);
      this.subscribeWebSocket();
    });
  }

  unsubscribeWebSocket(): void {
    this.webSocketService.removeSubscriptionToWebSocket(this.webSocketChannel);
    if (this.webSocketSubscription) {
      this.webSocketSubscription.unsubscribe();
    }
  }

  uploadQuestionnaire(event: Event): void {
    const fileList: FileList = event.target['files'];
    const file: File = fileList[0];

    if (file) {
      this.addProgressGroup();
      this.questionnaireService.importQuestionnaire(file).catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.questionnaireImportText);
        this.progress.status = ProgressStatus.error;
        this.finishWebSocket();
      });
    }
  }

  addProgressGroup(): void {
    this.progress = {
      id: 'importQuestionnaire',
      progress: new Progress({ done: 0, total: 100 }),
      status: ProgressStatus.inProgress,
      action: 'upload',
      name: this.questionnaireImportLabel,
      type: ProgressType.questionnaire
    };
    this.progressService.updateProgressGroup(this.progress);
    this.progressService.checkProgressStatus();
    this.progressService.countProgress();
  }

  hideProgressBar(): void {
    setTimeout(() => {
      this.progressService.removeProgressGroup('importQuestionnaire');
      this.progressService.countProgress();
    }, 2000);
  }

  subscribeWebSocket(): void {
    this.webSocketSubscription = this.webSocketService.subscribeWebSocket(this.webSocketChannel).subscribe(message => {
      this.progress.progress.done = message.progress;
      this.progressService.updateProgressGroup(this.progress);
      this.progressService.countProgress();

      switch (message.status) {
        case 'processed':
          this.progress.status = ProgressStatus.success;
          this.notificationsService.success(
            this.notify.success.questionnaireImportTitle,
            this.notify.success.questionnaireImportText
          );
          this.importQuestionnaire.emit();
          break;
        case 'failed':
          this.progress.status = ProgressStatus.error;
          this.notificationsService.error(this.notify.error.questionnaireImportTitle, message.message);
          break;
      }

      if (message.status === 'processed' || message.status === 'failed') {
        this.finishWebSocket();
        this.fileService.resetFileInput(this.questionnaireFileInput);
      }
    });
  }

  finishWebSocket(): void {
    this.progressService.updateProgressGroup(this.progress);
    this.progressService.checkProgressStatus();
    this.hideProgressBar();
  }

  resetQuestionnaireFileInput(): void {
    if (this.questionnaireFileInput) {
      const input = this.questionnaireFileInput.nativeElement;
      input.value = '';
      if (!/safari/i.test(navigator.userAgent)) {
        input.type = '';
        input.type = 'file';
      }
    }
  }

  async fetchTranslations(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.QUESTIONNAIRE_IMPORT_TITLE',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.QUESTIONNAIRE_IMPORT_TEXT',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.QUESTIONNAIRE_IMPORT_TITLE',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.QUESTIONNAIRE_IMPORT_TEXT',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.QUESTIONNAIRE_IMPORT'
      ])
      .toPromise();
    this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.notify.error.questionnaireImportTitle =
      translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.QUESTIONNAIRE_IMPORT_TITLE'];
    this.notify.error.questionnaireImportText =
      translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.QUESTIONNAIRE_IMPORT_TEXT'];
    this.notify.success.questionnaireImportTitle =
      translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.QUESTIONNAIRE_IMPORT_TITLE'];
    this.notify.success.questionnaireImportText =
      translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.QUESTIONNAIRE_IMPORT_TEXT'];
    this.questionnaireImportLabel = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.QUESTIONNAIRE_IMPORT'];
  }
}

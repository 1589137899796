import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { sortBy } from 'lodash';
import { Subject } from 'rxjs';

import { CompanyUnit } from '../../models/company-unit.model';
import { Country } from '../../models/country.model';
import { Employee } from '../../models/employee.model';
import { ExtraField } from '../../models/extra-field.model';
import { FilterOption } from '../../models/filter-option.model';
import { LegalCategory } from '../../models/legal-category.model';
import { LegalOrigin } from '../../models/legal-origin.model';
import { Product } from '../../models/product.model';
import { SharedLaw } from '../../models/shared-law.model';
import { StatusLCConsultant } from '../../models/status-lc-consultant.model';
import { TreeFilterOption } from '../../models/tree-filter-option.model';

import { DateRangeFilter } from '../../interfaces/date-range.interface';
import { ExtraFieldWithFilterOptions } from '../../interfaces/extra-field-filter.interface';
import { FilterNestedArray, ListTreeFilter } from '../../interfaces/filter.interface';
import { InactiveFilter } from '../../interfaces/inactive-filter.interface';
import { Interval } from '../../interfaces/interval.interface';
import { ListFiltersValues } from '../../interfaces/list-filters-values.interface';
import { NestedObjectOfIds } from '../../interfaces/nested-object-of-ids.interface';

import { ComplianceRatingId } from '../../enums/compliance-rating-id.enum';
import { FilterType } from '../../enums/filter-type.enum';
import { LcFilterListName } from '../../enums/lc-filter-list.enum';
import { RelevanceRatingId } from '../../enums/relevance-rating-id.enum';
import { UserType } from '../../enums/user-type.enum';

import { convertStaticFilterToFilterOption } from '../../converters/static-filter.convert';

@Component({
  selector: 'gw-list-filters',
  templateUrl: './list-filters.component.html',
  styleUrls: ['./list-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListFiltersComponent<C extends { id: string; name: string; color: string }> implements OnInit, OnDestroy {
  @Input() filtersGroup: LcFilterListName;
  @Input() userType: UserType;

  @Input() set allLegalCategories(allLegalCategories: Array<LegalCategory>) {
    this._allLegalCategories = allLegalCategories;
    this.legalCategories = this.mapTreeFilterElements<LegalCategory>(allLegalCategories);
  }
  get allLegalCategories(): Array<LegalCategory> {
    return this._allLegalCategories;
  }
  _allLegalCategories: Array<LegalCategory>;
  @Input() set allUnits(allUnits: Array<CompanyUnit>) {
    this._allUnits = allUnits;
    this.units = this.mapTreeFilterElements<CompanyUnit>(allUnits);
  }
  get allUnits(): Array<CompanyUnit> {
    return this._allUnits;
  }
  _allUnits: Array<CompanyUnit>;

  @Input() set allLegalOrigins(allLegalOrigins: Array<LegalOrigin>) {
    this._allLegalOrigins = allLegalOrigins;
    this.mapLegalOrigins();
  }
  get allLegalOrigins(): Array<LegalOrigin> {
    return this._allLegalOrigins;
  }
  _allLegalOrigins: Array<LegalOrigin>;

  @Input() set allProducts(allProducts: Array<Product>) {
    this._allProducts = allProducts;
    this.mapProducts();
  }
  get allProducts(): Array<Product> {
    return this._allProducts;
  }
  _allProducts: Array<Product>;

  @Input() set allLaws(allLaws: Array<SharedLaw>) {
    this._allLaws = allLaws;
    this.mapLaws();
  }
  get allLaws(): Array<SharedLaw> {
    return this._allLaws;
  }
  _allLaws: Array<SharedLaw>;

  @Input() set allUsers(allUsers: Array<Employee>) {
    this._allUsers = allUsers;
    this.mapUsers();
  }
  get allUsers(): Array<Employee> {
    return this._allUsers;
  }
  _allUsers: Array<Employee>;

  @Input() set allLawStatuses(allLawStatuses: Array<StatusLCConsultant>) {
    this._allLawStatuses = allLawStatuses;
    this.mapStatuses();
  }
  get allLawStatuses(): Array<StatusLCConsultant> {
    return this._allLawStatuses;
  }
  _allLawStatuses: Array<StatusLCConsultant>;

  @Input() set allRequirementStatuses(allRequirementStatuses: Array<StatusLCConsultant>) {
    this._allRequirementStatuses = allRequirementStatuses;
    this.mapStatuses();
  }
  get allRequirementStatuses(): Array<StatusLCConsultant> {
    return this._allRequirementStatuses;
  }
  _allRequirementStatuses: Array<StatusLCConsultant>;

  @Input() set allIntervals(allIntervals: Array<Interval>) {
    this._allIntervals = allIntervals;
    this.mapIntervals();
  }
  get allIntervals(): Array<Interval> {
    return this._allIntervals;
  }
  _allIntervals: Array<Interval>;

  @Input() set allInactive(allInactive: Array<InactiveFilter>) {
    this._allInactive = allInactive;
    this.mapInactive();
  }
  get allInactive(): Array<InactiveFilter> {
    return this._allInactive;
  }
  _allInactive: Array<InactiveFilter>;

  @Input() set allExtraFields(allExtraFields: Array<ExtraField>) {
    this._allExtraFields = allExtraFields;
    this.mapExtraFields();
  }
  get allExtraFields(): Array<ExtraField> {
    return this._allExtraFields;
  }
  _allExtraFields: Array<ExtraField>;

  @Input() set complianceRatingsFilter(complianceRatingsFilter: Array<ComplianceRatingId>) {
    this._complianceRatingsFilter = complianceRatingsFilter;
    this.selectComplianceRatings();
  }
  get complianceRatingsFilter(): Array<ComplianceRatingId> {
    return this._complianceRatingsFilter;
  }
  _complianceRatingsFilter: Array<ComplianceRatingId>;

  @Input() set allComplianceRatings(complianceRatings: Array<C>) {
    this.mapComplianceRatings(complianceRatings);
  }

  @Input() set allRelevanceRatings(relevanceRatings: Array<C>) {
    this.mapRelevanceRatings(relevanceRatings);
  }

  @Input() set countriesFilter(countriesFilter: Array<string>) {
    this._countriesFilter = countriesFilter;
    this.mapLegalOrigins();
  }
  get countriesFilter(): Array<string> {
    return this._countriesFilter;
  }
  _countriesFilter: Array<string>;

  @Input() set legalOriginsFilter(legalOriginsFilter: Array<string>) {
    this._legalOriginsFilter = legalOriginsFilter;
    this.selectLegalOrigins();
  }
  get legalOriginsFilter(): Array<string> {
    return this._legalOriginsFilter;
  }
  _legalOriginsFilter: Array<string>;

  @Input() set productsFilter(productsFilter: Array<string>) {
    this._productsFilter = productsFilter;
    this.selectProducts();
  }
  get productsFilter(): Array<string> {
    return this._productsFilter;
  }
  _productsFilter: Array<string>;

  @Input() set lawsFilter(lawsFilter: Array<string>) {
    this._lawsFilter = lawsFilter;
    this.selectLaws();
  }
  get lawsFilter(): Array<string> {
    return this._lawsFilter;
  }
  _lawsFilter: Array<string>;

  @Input() set usersFilter(usersFilter: Array<string>) {
    this._usersFilter = usersFilter;
    this.selectUsers();
  }
  get usersFilter(): Array<string> {
    return this._usersFilter;
  }
  _usersFilter: Array<string>;

  @Input() set statusesFilter(statusesFilter: Array<string>) {
    this._statusesFilter = statusesFilter;
    this.selectStatuses();
  }
  get statusesFilter(): Array<string> {
    return this._statusesFilter;
  }
  _statusesFilter: Array<string>;

  @Input() set intervalsFilter(intervalsFilter: Array<string>) {
    this._intervalsFilter = intervalsFilter;
    this.selectIntervals();
  }
  get intervalsFilter(): Array<string> {
    return this._intervalsFilter;
  }
  _intervalsFilter: Array<string>;

  @Input() set inactiveFilter(inactiveFilter: Array<string>) {
    this._inactiveFilter = inactiveFilter;
    this.selectInactive();
  }
  get inactiveFilter(): Array<string> {
    return this._inactiveFilter;
  }
  _inactiveFilter: Array<string>;

  @Input() set relevanceRatingsFilter(relevanceRatingsFilter: Array<RelevanceRatingId>) {
    this._relevanceRatingsFilter = relevanceRatingsFilter;
    this.selectRelevanceRatings();
  }
  get relevanceRatingsFilter(): Array<RelevanceRatingId> {
    return this._relevanceRatingsFilter;
  }
  _relevanceRatingsFilter: Array<RelevanceRatingId>;

  @Input() set extraFieldsFilter(extraFieldsFilter: Array<FilterNestedArray>) {
    this._extraFieldsFilter = extraFieldsFilter;
    this.selectExtraFields();
  }
  get extraFieldsFilter(): Array<FilterNestedArray> {
    return this._extraFieldsFilter;
  }
  _extraFieldsFilter: Array<FilterNestedArray>;

  @Input() set legalCategoriesFilter(legalCategoriesFilter: ListTreeFilter) {
    this._legalCategoriesFilter = legalCategoriesFilter;
    if (!this.isCategoryFilterTree) this.selectLegalCategories();
  }
  get legalCategoriesFilter(): ListTreeFilter {
    return this._legalCategoriesFilter;
  }
  _legalCategoriesFilter: ListTreeFilter;
  @Input() unitsFilter: ListTreeFilter;
  @Input() dateOfIssueFilter: DateRangeFilter;
  @Input() deadlineFilter: DateRangeFilter;
  @Input() effectiveUntilFilter: DateRangeFilter;
  @Input() issuedOnFilter: DateRangeFilter;
  @Input() publicationFilter: DateRangeFilter;
  @Input() submittedOnFilter: DateRangeFilter;
  @Input() validFromFilter: DateRangeFilter;
  @Input() validIndefinitelyFilter: boolean;

  @Output() updateFilter = new EventEmitter<ListFiltersValues>();
  @Output() updateExtraFieldFilter = new EventEmitter<{ extraField: ExtraField; extraFieldValue: Array<string> }>();

  legalCategories: Array<TreeFilterOption>;
  units: Array<TreeFilterOption>;
  legalOrigins: Array<FilterOption>;
  complianceRatings: Array<FilterOption>;
  relevanceRatings: Array<FilterOption>;
  laws: Array<FilterOption>;
  products: Array<FilterOption>;
  users: Array<FilterOption>;
  statuses: Array<FilterOption>;
  deadline: DateRangeFilter;
  intervals: Array<FilterOption>;
  inactive: Array<FilterOption>;
  extraFields: Array<ExtraFieldWithFilterOptions>;
  isCategoryFilterTree = true;

  destroy$: Subject<boolean> = new Subject<boolean>();

  readonly LC_FILTER_LIST_NAME = LcFilterListName;
  readonly USER_TYPE_CLIENT = UserType.client;
  readonly FILTER_TYPE = FilterType;

  ngOnInit(): void {
    this.initCategoryFilterTreeFlag();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initCategoryFilterTreeFlag(): void {
    const isUserTypeConsultant = this.userType === UserType.consultant;
    const isLawsOrLegalChangesList = [LcFilterListName.legalRegister, LcFilterListName.legalChanges].includes(
      this.filtersGroup
    );
    this.isCategoryFilterTree = !(isLawsOrLegalChangesList && isUserTypeConsultant);
  }

  onUpdateFilter(filter: ListFiltersValues): void {
    const shouldConvertFilter = !this.isCategoryFilterTree && filter.name === FilterType.legalCategories;
    if (shouldConvertFilter) {
      filter = {
        ...filter,
        value: { value: this.convertArrayToTreeNodesIds(filter.value as Array<string>) }
      };
    }
    this.updateFilter.emit(filter);
  }

  onUpdateExtraFieldFilter(extraField: ExtraField, extraFieldValue: Array<string>): void {
    this.updateExtraFieldFilter.emit({ extraField, extraFieldValue });
  }

  convertArrayToTreeNodesIds(array: Array<string>): NestedObjectOfIds {
    let treeNodesIds = {};
    array.forEach(item => (treeNodesIds = { ...treeNodesIds, [item]: '' }));
    return treeNodesIds;
  }

  mapLegalCategories(): void {
    const mapLegalCategory = (legalCategory: LegalCategory): TreeFilterOption => {
      return new TreeFilterOption({
        id: legalCategory.id,
        name: legalCategory.name,
        children: legalCategory.children
          ? legalCategory.children.map(legalCategory => mapLegalCategory(legalCategory))
          : [],
        isSelected: false
      });
    };

    this.legalCategories = this.allLegalCategories?.map(legalCategory => mapLegalCategory(legalCategory)) ?? [];
  }

  mapTreeFilterElements<T extends { id: string; name: string; children?: Array<T> }>(
    allElements: Array<T>
  ): Array<TreeFilterOption> {
    const mapTreeFilterElement = (treeFilterElement: T): TreeFilterOption => {
      return new TreeFilterOption({
        id: treeFilterElement.id,
        name: treeFilterElement.name,
        children: treeFilterElement?.children
          ? treeFilterElement.children.map(treeFilterElement => mapTreeFilterElement(treeFilterElement))
          : [],
        isSelected: false
      });
    };

    return allElements?.map(treeFilterElement => mapTreeFilterElement(treeFilterElement)) ?? [];
  }

  mapLegalOrigins(): void {
    this.legalOrigins = this.allLegalOrigins?.map(legalOrigin => {
      return {
        id: legalOrigin.id,
        name: legalOrigin.name,
        isSelected: false,
        abbreviations:
          legalOrigin.countries &&
          legalOrigin.countries
            .filter(country => this.checkIfCountryExistInList(country, this.countriesFilter))
            .map((country: Country) => country.abbreviation)
      };
    });
    this.selectLegalOrigins();
  }

  checkIfCountryExistInList(country: Country, countriesList: Array<string>): boolean {
    return !!countriesList?.includes(country.id);
  }

  mapLaws(): void {
    this.laws = this.allLaws?.map(law => {
      return {
        id: law.id,
        name: law.displayName,
        isSelected: false
      };
    });
    this.selectLaws();
  }

  mapRatings(ratings: Array<C>): Array<FilterOption> {
    return ratings?.map(rating => ({
      ...rating,
      isSelected: false
    }));
  }

  mapRelevanceRatings(ratings: Array<C>): void {
    this.relevanceRatings = ratings?.map(rating => ({
      ...rating,
      isSelected: false
    }));
    this.selectRelevanceRatings();
  }

  mapComplianceRatings(ratings: Array<C>): void {
    this.complianceRatings = ratings?.map(rating => ({
      ...rating,
      isSelected: false
    }));
    this.selectComplianceRatings();
  }

  mapProducts(): void {
    const convertedProducts = this.allProducts?.map(product => {
      return {
        id: product.id,
        name: product.name,
        isSelected: false,
        abbreviations: [product.country.abbreviation]
      };
    });
    this.products = this.userType === this.USER_TYPE_CLIENT ? sortBy(convertedProducts, 'name') : convertedProducts;
    this.selectProducts();
  }

  mapUsers(): void {
    this.users = this.allUsers?.map(user => {
      return {
        id: user.id,
        name: `${user.lastname}, ${user.name}`,
        isSelected: false
      };
    });
    this.selectUsers();
  }

  mapStatuses(): void {
    const statuses =
      this.filtersGroup === LcFilterListName.requirements ? this.allRequirementStatuses : this.allLawStatuses;
    this.statuses = statuses?.map(status => {
      return {
        id: status.id,
        name: status.name,
        isSelected: false
      };
    });
    this.selectStatuses();
  }

  mapIntervals(): void {
    this.intervals = this.allIntervals?.map((interval: Interval) => convertStaticFilterToFilterOption(interval));
    this.selectIntervals();
  }

  mapInactive(): void {
    this.inactive = this.allInactive?.map((inactive: InactiveFilter) => convertStaticFilterToFilterOption(inactive));
    this.selectInactive();
  }

  mapExtraFields(): void {
    this.extraFields = this.allExtraFields?.map((extraField: ExtraField) => {
      return {
        id: extraField.id,
        name: extraField.name,
        options: extraField.values?.map(value => ({
          id: value.id,
          name: value.label,
          isSelected: false
        }))
      };
    });
    this.selectExtraFields();
  }

  selectMultiselectOptions(collection: Array<FilterOption>, filter: Array<string>): Array<FilterOption> {
    return collection?.map(option => ({
      ...option,
      isSelected: filter?.includes(option.id as string)
    }));
  }

  selectLegalCategories(): void {
    if (this.legalCategoriesFilter?.value) {
      const legalCategoriesFilter = Object.keys(this.legalCategoriesFilter.value);
      this.legalCategories = this.selectMultiselectOptions(this.legalCategories, legalCategoriesFilter);
    }
  }

  selectLegalOrigins(): void {
    this.legalOrigins = this.selectMultiselectOptions(this.legalOrigins, this.legalOriginsFilter);
  }

  selectLaws(): void {
    this.laws = this.selectMultiselectOptions(this.laws, this.lawsFilter ?? []);
  }

  selectProducts(): void {
    this.products = this.selectMultiselectOptions(this.products, this.productsFilter || []);
  }

  selectUsers(): void {
    this.users = this.selectMultiselectOptions(this.users, this.usersFilter);
  }

  selectStatuses(): void {
    this.statuses = this.selectMultiselectOptions(this.statuses, this.statusesFilter);
  }

  selectIntervals(): void {
    this.intervals = this.selectMultiselectOptions(this.intervals, this.intervalsFilter);
  }

  selectInactive(): void {
    this.inactive = this.selectMultiselectOptions(this.inactive, this.inactiveFilter);
  }

  selectRelevanceRatings(): void {
    this.relevanceRatings = this.selectMultiselectOptions(this.relevanceRatings, this.relevanceRatingsFilter);
  }

  selectComplianceRatings(): void {
    this.complianceRatings = this.selectMultiselectOptions(this.complianceRatings, this.complianceRatingsFilter);
  }

  selectExtraFields(): void {
    if (this.extraFields && this.extraFieldsFilter) {
      this.selectExtraFieldValues();
    } else {
      this.unSelectAllExtraFieldValues();
    }
  }

  unSelectAllExtraFieldValues(): void {
    this.extraFields?.forEach((extraField: ExtraFieldWithFilterOptions) => {
      extraField.options.forEach((extraFieldOption: FilterOption) => (extraFieldOption.isSelected = false));
    });
  }

  selectExtraFieldValues(): void {
    this.extraFields = this.extraFields.map(extraField => {
      return {
        ...extraField,
        options: extraField.options.map(option => {
          return {
            ...option,
            isSelected: !!this.extraFieldsFilter.find(({ values }) => values[0] === option.id)
          };
        })
      };
    });
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// Gutwin Shared Library
import { LoginService } from 'gutwin-shared';

@Injectable()
export class LogoutGuard implements CanActivate {

  constructor(private loginService: LoginService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.loginService.isLoggedIn()) {
      return true;
    }

    this.router.navigate(['audits']);
    return false;
  }

}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
// Models
import { AuditsFilters } from '@gutwin-audit/shared/models/filters-audits.model';
// Services
import { AuditService } from '../../shared/services/audit.service';
import { FilterModuleService } from '../../shared/services/filter-module.service';

@Injectable()
export class AuditsDraftsResolve implements Resolve<any> {
  constructor(
    private auditService: AuditService,
    private filterService: FilterModuleService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) { }

  resolve (route: ActivatedRouteSnapshot) {
    this.filterService.getQueryParams(route.queryParams);

    const page = this.filterService.page;
    const limit = this.filterService.limit;
    const filters = this.filterService.filters;

    return this.auditService.getAuditsDrafts(page, filters as AuditsFilters, limit)
      .catch(error => {
        this.fetchErrorTranslation()
          .then((translation: any) => {
            this.notificationsService.error(translation.title, translation.text);
          });
        return [];
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'AUDITS_LIST.ERROR.LOAD_DRAFTS'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['AUDITS_LIST.ERROR.LOAD_DRAFTS']
        };
      });
  }
}

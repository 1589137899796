export class ResetOpenedEdits {
  static readonly type = '[DetailsPage] Reset opened edits';
}

export class IncreaseOpenedEdits {
  static readonly type = '[DetailsPage] Increase opened edits';
}

export class DecreaseOpenedEdits {
  static readonly type = '[DetailsPage] Decrease opened edits';
}

export class SetEditMode {
  static readonly type = '[DetailsPage] Set edit mode';
  constructor(public value: boolean) {}
}

export class SetEditSection {
  static readonly type = '[DetailsPage] Set edit section';
}

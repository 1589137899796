import { FacilitiesFilter } from './facilities-filter.model';

export class AuditsSharedFilters {
  types: Array<string>;
  leadAuditors: Array<string>;
  coAuditors: Array<string>;
  location: string;
  facilities: FacilitiesFilter;
  usersSearch: string;
  component: string;

  constructor(filters?: Partial<AuditsSharedFilters>) {
    if (filters) {
      this.types = filters.types;
      this.leadAuditors = filters.leadAuditors;
      this.coAuditors = filters.coAuditors;
      this.location = filters.location;
      this.facilities = filters.facilities;
      this.usersSearch = filters.usersSearch;
      this.component = filters.component;
    }
  }
}

export class AuditsFilters extends AuditsSharedFilters {
  name: string;
  templateName: string;
  dateFrom: string;
  dateTo: string;

  constructor(filters?: Partial<AuditsFilters>) {
    super(filters);
    if (filters) {
      this.name = filters.name;
      this.templateName = filters.templateName;
      this.dateFrom = filters.dateFrom;
      this.dateTo = filters.dateTo;
    }
  }
}

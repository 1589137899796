import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { LegalQuestion } from '../../models/legal-question.model';

@Component({
  selector: 'gw-legal-question-box',
  templateUrl: './legal-question-box.component.html',
  styleUrls: ['./legal-question-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalQuestionBoxComponent implements OnInit {
  @Input() legalQuestion: LegalQuestion;
  @Input() open?: boolean;

  wasActiveChanged: boolean;
  isDescriptionEmpty: boolean;

  ngOnInit(): void {
    this.checkIfActiveWasChanged();
    this.checkIfDescriptionEmpty();
  }

  checkIfActiveWasChanged(): void {
    const { previousVersion, active } = this.legalQuestion;
    this.wasActiveChanged = previousVersion && previousVersion.active !== active;
  }

  checkIfDescriptionEmpty(): void {
    const { previousVersion, description } = this.legalQuestion;
    this.isDescriptionEmpty = !(previousVersion?.description || description);
  }
}

import { Injectable } from '@angular/core';

import { UserSettings } from '../models/user-settings.model';

import { StorageService, StoragesNames } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  constructor(private storageService: StorageService) {}

  get currentUserId(): number {
    const currentUser = this.storageService.getLocalStorage(StoragesNames.currentUser);
    return currentUser?.customerId || currentUser?.id;
  }

  get userSettings(): UserSettings {
    return this.storageService.getLocalStorage(StoragesNames.userSettings);
  }

  get currentUserSettings(): UserSettings {
    if (this.currentUserId) {
      const currentUserSettings = this.userSettings?.[this.currentUserId];
      return currentUserSettings;
    }
  }

  setCurrentUserSettings(key: string, value: any): void {
    if (this.currentUserId) {
      this.storageService.setLocalStorage(StoragesNames.userSettings, {
        ...(this.userSettings || {}),
        [this.currentUserId]: { ...this.currentUserSettings, [key]: value }
      });
    }
  }
}

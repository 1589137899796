import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';

import { RelatedLaw } from '../../interfaces/related-law.interface';

import { ExpandBoxComponent } from '../expand-box/expand-box.component';

@Component({
  selector: 'gw-requirement-sidebar-related-law-box',
  templateUrl: './requirement-sidebar-related-law-box.component.html',
  styleUrls: ['./requirement-sidebar-related-law-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequirementSidebarRelatedLawBoxComponent {
  @ViewChild(ExpandBoxComponent) expandBoxComponent: ExpandBoxComponent;
  @HostBinding('class.related-law-box--last-in-list') @Input() isLast = false;
  @Input() relatedLaw: RelatedLaw;
  @Input() isEditMode: boolean;
  @Input() isUserTypeClient: boolean;
  @Input() isRemovable = true;
  @Input() set expanded(isExpanded: boolean) {
    if (isExpanded !== undefined && isExpanded !== this.isExpanded) {
      this.expandBoxComponent.toggle();
      this.isExpanded = isExpanded;
    }
  }
  @Output() removeRelatedLaw = new EventEmitter<RelatedLaw>();
  @Output() select = new EventEmitter<void>();
  isExpanded: boolean;

  onRemoveRelatedLaw(): void {
    this.removeRelatedLaw.emit(this.relatedLaw);
  }

  onSelect(): void {
    if (this.isEditMode) {
      this.select.emit();
    }
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { find, findIndex } from 'lodash';

import { Attachment, HelperUtil } from 'gutwin-shared';

import { Audit } from '../models/audit.model';
import { QuestionMedia } from '../models/question-media.model';

import { BatchResponse } from '../interfaces/batch-response.interface';

import { ApiUrlService } from './api-url.service';
import { AttachmentService, QuestionMediaAttachmentRequest } from './attachment.service';
import { SectionsResponse } from './section.service';
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';

import { getAttachmentsWithFile } from '@gutwin-audit/shared/converters/attachment.convert';

export interface QuestionMediaResponse {
  id: string;
  comment?: string;
  attachments?: Array<Attachment>;
  audited_area_id: string;
  question_id: string;
}

@Injectable()
export class QuestionMediaService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private attachmentService: AttachmentService,
    private storageService: StorageModuleService
  ) {}

  fetchAuditedAreaMedia(auditedAreaId: string): Promise<Array<QuestionMedia>> {
    let params = new HttpParams();
    params = params.set('audited_area_id', auditedAreaId);

    return this.http
      .get(this.apiUrlService.questionMediaApi, { params })
      .toPromise()
      .then((data: Array<QuestionMediaResponse>) => {
        this.updateQuestionsMediaInOfflineStore(auditedAreaId, data);
        return this.convertQuestionsMediaToGet(data);
      })
      .catch(error => {
        console.error('Error while getting media', error);
        throw error;
      });
  }

  fetchMedia(auditedAreaId: string, questionMediaId: string): Promise<QuestionMedia> {
    return this.http
      .get(`${this.apiUrlService.questionMediaApi}/${questionMediaId}`)
      .toPromise()
      .then((data: QuestionMediaResponse) => this.convertQuestionMediaToGet(data))
      .catch(error => {
        if (error.status === 0) {
          return this.getQuestionMediaFromOfflineStore(auditedAreaId, questionMediaId).then(data => {
            if (!data) {
              console.error('Error while getting media', error);
              throw error;
            }
            return this.convertQuestionMediaToGet(data);
          });
        }
        console.error('Error while getting media', error);
        throw error;
      });
  }

  createMedia(questionMedia: QuestionMedia): Promise<any> {
    return this.http
      .post(this.apiUrlService.questionMediaApi, this.convertQuestionMediaToPost(questionMedia))
      .toPromise()
      .then((data: QuestionMediaResponse) => {
        this.updateQuestionMediaInOfflineStore(data);
        const questionMediaResponse = this.convertQuestionMediaToGet(data, questionMedia);
        this.removeQuestionMediaRequestFromOfflineStore(questionMediaResponse);
        return { data: questionMediaResponse };
      })
      .catch(error => {
        if (error.status === 0) {
          questionMedia.attachments.forEach(attachment => {
            if (attachment.file || attachment.toRemove) {
              attachment.offline = true;
            }
          });
          this.updateQuestionMediaRequestInOfflineStore(questionMedia);
          return { data: questionMedia, offline: true };
        }
        console.error('Error while updating media', error);
        throw error;
      });
  }

  updateMedia(questionMedia: QuestionMedia): Promise<any> {
    return this.http
      .put(`${this.apiUrlService.questionMediaApi}/${questionMedia.id}`, this.convertQuestionMediaToPut(questionMedia))
      .toPromise()
      .then((data: QuestionMediaResponse) => {
        this.updateQuestionMediaInOfflineStore(data);
        const questionMediaResponse = this.convertQuestionMediaToGet(data, questionMedia);
        this.removeQuestionMediaRequestFromOfflineStore(questionMediaResponse);
        return { data: questionMediaResponse };
      })
      .catch(error => {
        if (error.status === 0) {
          questionMedia.attachments.forEach(attachment => {
            if (attachment.file || attachment.toRemove) {
              attachment.offline = true;
            }
          });
          this.updateQuestionMediaRequestInOfflineStore(questionMedia);
          return { data: questionMedia, offline: true };
        }
        console.error('Error while updating media', error);
        return new Promise((resolve, reject) => reject(error));
      });
  }

  updateQuestionsMedia(auditedAreaId: string, questionsMedia: Array<QuestionMedia>): Promise<void> {
    if (questionsMedia && questionsMedia.length) {
      return this.http
        .post(
          `${this.apiUrlService.questionMediaApi}/batch_update`,
          this.convertQuestionsMediaToPost(auditedAreaId, questionsMedia)
        )
        .toPromise()
        .then((data: BatchResponse) => {
          if (data.invalid && data.invalid.count > 0) {
            console.error('Error while updating media', data.invalid.errors);
            throw new Error(data.invalid.count + '');
          } else {
            const questionsMediaRequests = this.convertQuestionsMediaToSaveOffline(questionsMedia);
            this.updateQuestionsMediaInOfflineStore(auditedAreaId, questionsMediaRequests);
            return this.removeQuestionsMediaRequestsFromOfflineStore(auditedAreaId);
          }
        })
        .catch(error => {
          console.error('Error while updating media', error);
          throw error;
        });
    }
    return Promise.resolve();
  }

  removeMedia(questionMedia: QuestionMedia): Promise<any> {
    return this.http
      .delete(`${this.apiUrlService.questionMediaApi}/${questionMedia.id}`)
      .toPromise()
      .then(() => {
        this.removeQuestionMediaFromOfflineStore(questionMedia);
        this.removeQuestionMediaRequestFromOfflineStore(questionMedia);
        return {};
      })
      .catch(error => {
        if (error.status === 0) {
          this.updateQuestionMediaRequestInOfflineStore(questionMedia);
          return { data: questionMedia, offline: true };
        }
        console.error('Error while removing media', error);
        throw error;
      });
  }

  private convertQuestionsMediaToGet(data: Array<QuestionMediaResponse>): Array<QuestionMedia> {
    const questionsMedia = new Array<QuestionMedia>();
    if (data) {
      data.forEach(media => {
        questionsMedia.push(this.convertQuestionMediaToGet(media));
      });
    }
    return questionsMedia;
  }

  private convertQuestionMediaToGet(
    questionMedia: QuestionMediaResponse,
    questionMediaRequest?: QuestionMedia
  ): QuestionMedia {
    return new QuestionMedia({
      id: questionMedia.id,
      comment: questionMedia.comment,
      attachments: questionMediaRequest?.attachments
        ? getAttachmentsWithFile(questionMedia.attachments, questionMediaRequest.attachments)
        : questionMedia.attachments,
      auditedAreaId: questionMedia.audited_area_id,
      questionId: questionMedia.question_id
    });
  }

  private convertQuestionMediaToPost(questionMedia: QuestionMedia): FormData {
    const formData = new FormData();
    formData.append('id', questionMedia.id);
    formData.append('comment', questionMedia.comment);
    formData.append('audited_area_id', questionMedia.auditedAreaId);
    formData.append('question_id', questionMedia.questionId);
    if (questionMedia.attachments) {
      questionMedia.attachments.forEach(attachment => {
        if (attachment.file) {
          formData.append('attachments[][file]', attachment.file, attachment.offline ? attachment.name : undefined);
          formData.append('attachments[][checksum]', attachment.checksum);
        }
        if (attachment.toRemove) {
          formData.append('attachments[][file]', attachment.name);
          formData.append('attachments[][checksum]', attachment.checksum);
        }
      });
    }
    return formData;
  }

  private convertQuestionsMediaToPost(auditedAreaId: string, questionsMedia: Array<QuestionMedia>): FormData {
    const formData = new FormData();
    formData.append('audited_area_id', auditedAreaId);
    if (questionsMedia) {
      questionsMedia.forEach(questionMedia => {
        formData.append('question_response_media[][question_id]', questionMedia.questionId);
        formData.append('question_response_media[][comment]', questionMedia.comment);
        if (questionMedia.attachments) {
          questionMedia.attachments.forEach(attachment => {
            if (attachment.file) {
              formData.append('question_response_media[][attachments[][file]]', attachment.file, attachment.name);
              formData.append('question_response_media[][attachments[][checksum]]', attachment.checksum);
            }
          });
        }
      });
    }
    return formData;
  }

  private convertQuestionMediaToPut(questionMedia: QuestionMedia): FormData {
    const formData = new FormData();
    formData.append('id', questionMedia.id);
    formData.append('comment', questionMedia.comment);
    if (questionMedia.attachments) {
      questionMedia.attachments.forEach(attachment => {
        if (attachment.file && !attachment.url) {
          formData.append('attachments[][file]', attachment.file, attachment.offline ? attachment.name : undefined);
          formData.append('attachments[][checksum]', attachment.checksum);
        }
        if (attachment.toRemove) {
          formData.append('attachments[][file]', attachment.name);
          formData.append('attachments[][checksum]', attachment.checksum);
        }
      });
    }
    return formData;
  }

  private convertQuestionsMediaToSaveOffline(questionsMedia: Array<QuestionMedia>): Array<QuestionMediaResponse> {
    if (questionsMedia) {
      return questionsMedia.map(questionMedia => this.convertQuestionMediaToSaveOffline(questionMedia));
    }
  }

  private convertQuestionMediaToSaveOffline(questionMedia: QuestionMedia): QuestionMediaResponse {
    const questionMediaRequest = {
      id: questionMedia.id,
      audited_area_id: questionMedia.auditedAreaId,
      question_id: questionMedia.questionId,
      comment: questionMedia.comment,
      attachments: undefined
    };
    return questionMediaRequest;
  }

  private getQuestionMediaFromOfflineStore(
    auditedAreaId: string,
    questionMediaId: string
  ): Promise<QuestionMediaResponse> {
    const getSingleQuestionMedia = (data: Array<QuestionMediaResponse>): QuestionMediaResponse => {
      for (const questionMedia of data) {
        if (questionMedia.id === questionMediaId) {
          return questionMedia;
        }
      }
    };

    return this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaQuestionMedia + auditedAreaId)
      .then(data => {
        if (data) {
          return getSingleQuestionMedia(data);
        }
      });
  }

  private updateQuestionMediaInOfflineStoreData(
    data: Array<QuestionMediaResponse>,
    questionMedia: QuestionMediaResponse
  ): Array<QuestionMediaResponse> {
    if (!data) {
      data = new Array<QuestionMediaResponse>();
    }
    const index = findIndex(data, { id: questionMedia.id });
    if (index !== -1) {
      data[index] = questionMedia;
    } else {
      data.push(questionMedia);
    }
    return data;
  }

  private async updateQuestionMediaInOfflineStore(questionMedia: QuestionMediaResponse): Promise<void> {
    await this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaQuestionMedia + questionMedia.audited_area_id)
      .then((data: Array<QuestionMediaResponse>) => {
        const updatedData = this.updateQuestionMediaInOfflineStoreData(data, questionMedia);
        this.storageService.setOfflineStore(
          StoragesNamesModule.auditedAreaQuestionMedia + questionMedia.audited_area_id,
          updatedData
        );
      });
    await this.attachmentService.updateAttachmentsFilesInOfflineStore(questionMedia.attachments);
    await this.updateQuestionMediaInSectionsOfflineStore(questionMedia);
  }

  private async updateQuestionsMediaInOfflineStore(
    auditedAreaId: string,
    questionsMedia: Array<QuestionMediaResponse>
  ): Promise<void> {
    await this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaQuestionMedia + auditedAreaId)
      .then(async (data: Array<QuestionMediaResponse>) => {
        let updatedData = data;
        if (questionsMedia) {
          for (const questionMedia of questionsMedia) {
            updatedData = this.updateQuestionMediaInOfflineStoreData(updatedData, questionMedia);
            await this.attachmentService.updateAttachmentsFilesInOfflineStore(questionMedia.attachments);
          }
        }
        this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaQuestionMedia + auditedAreaId, updatedData);
      });
    await this.updateQuestionsMediaInSectionsOfflineStore(auditedAreaId, questionsMedia);
  }

  removeAuditQuestionsMediaFromOfflineStore(audit: Audit): Promise<any> {
    const promises = new Array<Promise<any>>();
    if (audit.auditedAreas) {
      audit.auditedAreas.forEach(auditedArea => {
        const removeAuditedAreaQuestionsMediaPromise = this.removeAuditedAreaQuestionsMediaFromOfflineStore(
          auditedArea.id
        );
        promises.push(removeAuditedAreaQuestionsMediaPromise);
      });
    }
    return Promise.all(promises);
  }

  async removeAuditedAreaQuestionsMediaFromOfflineStore(auditedAreaId: string): Promise<void> {
    const removeQuestionsMediaAttachments = async (data: Array<QuestionMediaResponse>): Promise<void> => {
      if (data) {
        for (const questionMedia of data) {
          await this.attachmentService.removeAttachmentsFilesFromOfflineStore(questionMedia.attachments);
        }
      }
    };

    await this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaQuestionMedia + auditedAreaId)
      .then(async (data: Array<QuestionMediaResponse>) => {
        if (data) {
          await removeQuestionsMediaAttachments(data);
        }
      });
    await this.storageService.removeFromOfflineStore(StoragesNamesModule.auditedAreaQuestionMedia + auditedAreaId);
  }

  private async removeQuestionMediaFromOfflineStore(questionMedia: QuestionMedia): Promise<void> {
    const removeQuestionMediaFromOfflineStoreData = (
      data: Array<QuestionMediaResponse>
    ): Array<QuestionMediaResponse> => {
      if (data) {
        const index = findIndex(data, { id: questionMedia.id });
        if (~index) {
          data.splice(index, 1);
        }
      }
      return data;
    };

    await this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaQuestionMedia + questionMedia.auditedAreaId)
      .then((data: Array<QuestionMediaResponse>) => {
        const updatedData = removeQuestionMediaFromOfflineStoreData(data);
        this.storageService.setOfflineStore(
          StoragesNamesModule.auditedAreaQuestionMedia + questionMedia.auditedAreaId,
          updatedData
        );
      });
    await this.attachmentService.removeAttachmentsFilesFromOfflineStore(questionMedia.attachments);
    await this.removeQuestionMediaFromSectionsOfflineStore({
      id: questionMedia.id,
      audited_area_id: questionMedia.auditedAreaId,
      question_id: questionMedia.questionId
    });
  }

  private updateQuestionMediaInSectionsOfflineStoreData(
    data: SectionsResponse,
    questionId: string,
    questionMediaId: string
  ): SectionsResponse {
    for (const section of data.ordered_sections) {
      if (section.ordered_questions) {
        for (const question of section.ordered_questions) {
          if (question.id === questionId) {
            question.response_media_id = questionMediaId;
            return data;
          }
        }
      }
    }
  }

  private updateQuestionMediaInSectionsOfflineStore(questionMedia: QuestionMediaResponse): void {
    this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaSections + questionMedia.audited_area_id)
      .then((data: SectionsResponse) => {
        if (data) {
          const updatedData = this.updateQuestionMediaInSectionsOfflineStoreData(
            data,
            questionMedia.question_id,
            questionMedia.id
          );
          this.storageService.setOfflineStore(
            StoragesNamesModule.auditedAreaSections + questionMedia.audited_area_id,
            updatedData
          );
        }
      });
  }

  private updateQuestionsMediaInSectionsOfflineStore(
    auditedAreaId: string,
    questionsMedia: Array<QuestionMediaResponse>
  ): void {
    this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedAreaId)
      .then((data: SectionsResponse) => {
        if (data) {
          let updatedData = data;
          if (questionsMedia) {
            questionsMedia.forEach(questionMedia => {
              updatedData = this.updateQuestionMediaInSectionsOfflineStoreData(
                updatedData,
                questionMedia.question_id,
                questionMedia.id
              );
            });
          }
          this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + auditedAreaId, updatedData);
        }
      });
  }

  private removeQuestionMediaFromSectionsOfflineStore(questionMedia: QuestionMediaResponse): void {
    const removeQuestionMediaFromSectionsOfflineStoreData = (data: SectionsResponse): SectionsResponse => {
      for (const section of data.ordered_sections) {
        if (section.ordered_questions) {
          for (const question of section.ordered_questions) {
            if (question.id === questionMedia.question_id) {
              question.response_media_id = undefined;
              return data;
            }
          }
        }
      }
    };

    this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaSections + questionMedia.audited_area_id)
      .then((data: SectionsResponse) => {
        if (data) {
          const updatedData = removeQuestionMediaFromSectionsOfflineStoreData(data);
          this.storageService.setOfflineStore(
            StoragesNamesModule.auditedAreaSections + questionMedia.audited_area_id,
            updatedData
          );
        }
      });
  }

  getQuestionsMediaRequestsFromOfflineStore(auditedAreaId: string): Promise<Array<QuestionMedia>> {
    const promises = new Array<Promise<Array<QuestionMedia>>>();
    promises.push(
      this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaQuestionMediaRequests + auditedAreaId)
    );
    promises.push(
      this.attachmentService.getQuestionsMediaAttachmentsRequestsFromOfflineStore(auditedAreaId).then(data => {
        const questionsMedia = new Array<QuestionMedia>();
        if (data) {
          data.forEach((questionMediaRequest: QuestionMediaAttachmentRequest) => {
            const questionMedia = find(questionsMedia, { questionId: questionMediaRequest.questionId });
            if (questionMedia) {
              questionMedia.attachments.push(questionMediaRequest.attachment);
            } else {
              questionsMedia.push(
                new QuestionMedia({
                  id: questionMediaRequest.questionMediaId,
                  questionId: questionMediaRequest.questionId,
                  attachments: [questionMediaRequest.attachment]
                })
              );
            }
          });
        }
        return questionsMedia;
      })
    );

    return Promise.all(promises).then((values: Array<Array<QuestionMedia>>) => {
      const questionsMedia = new Array<QuestionMedia>();
      values.forEach(data => {
        if (data && questionsMedia.length === 0) {
          questionsMedia.push(...data);
        } else if (data) {
          data.forEach(questionMedia => {
            const index = findIndex(questionsMedia, { questionId: questionMedia.questionId });
            if (~index) {
              questionsMedia[index] = new QuestionMedia(HelperUtil.mergeObjects(questionsMedia[index], questionMedia));
            } else {
              questionsMedia.push(questionMedia);
            }
          });
        }
      });
      return questionsMedia;
    });
  }

  getQuestionMediaRequestFromOfflineStore(auditedAreaId: string, questionId: string): Promise<QuestionMedia> {
    const getSingleQuestionMedia = (data: Array<QuestionMedia>): QuestionMedia => {
      return find(data, { questionId });
    };

    return this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaQuestionMediaRequests + auditedAreaId)
      .then((data: Array<QuestionMedia>) => {
        if (data) {
          const questionMedia = getSingleQuestionMedia(data);
          return this.attachmentService
            .getQuestionMediaAttachmentsRequestFromOfflineStore(auditedAreaId, questionId)
            .then(attachments => {
              questionMedia.attachments = attachments;
              return questionMedia;
            })
            .catch(() => questionMedia);
        }
      });
  }

  private updateQuestionMediaRequestInOfflineStore(questionMedia: QuestionMedia): void {
    const updateQuestionMedia = (data: Array<QuestionMedia>): Array<QuestionMedia> => {
      const index = findIndex(data, { questionId: questionMedia.questionId });
      questionMedia.commentOffline = questionMedia.comment;
      if (index !== -1) {
        data[index] = questionMedia;
      } else {
        data.push(questionMedia);
      }
      return data;
    };

    this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaQuestionMediaRequests + questionMedia.auditedAreaId)
      .then((data: Array<QuestionMedia>) => {
        if (!data) {
          data = new Array<QuestionMedia>();
        }
        const updatedData = updateQuestionMedia(data);
        this.storageService.setOfflineStore(
          StoragesNamesModule.auditedAreaQuestionMediaRequests + questionMedia.auditedAreaId,
          updatedData
        );
      });

    if (questionMedia.attachments && questionMedia.attachments.length) {
      this.attachmentService.addQuestionMediaAttachmentsRequestsToOfflineStore(
        questionMedia.attachments,
        questionMedia.auditedAreaId,
        questionMedia.questionId,
        questionMedia.id
      );
    }
  }

  private removeQuestionMediaRequestFromOfflineStore(questionMedia: QuestionMedia): void {
    const removeQuestionMedia = (data: Array<QuestionMedia>): Array<QuestionMedia> => {
      const index = findIndex(data, { questionId: questionMedia.questionId });
      if (index !== -1) {
        data.splice(index, 1);
      }
      return data;
    };

    this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaQuestionMediaRequests + questionMedia.auditedAreaId)
      .then((data: Array<QuestionMedia>) => {
        if (data !== null) {
          const updatedData = removeQuestionMedia(data);
          this.storageService.setOfflineStore(
            StoragesNamesModule.auditedAreaQuestionMediaRequests + questionMedia.auditedAreaId,
            updatedData
          );
        }
      });

    if (questionMedia.attachments && questionMedia.attachments.length) {
      this.attachmentService.removeQuestionMediaAttachmentsRequestsFromOfflineStore(
        questionMedia.auditedAreaId,
        questionMedia.questionId
      );
    }
  }

  removeAuditQuestionsMediaRequestsFromOfflineStore(audit: Audit): void {
    if (audit.auditedAreas) {
      audit.auditedAreas.forEach(auditedArea => {
        this.removeQuestionsMediaRequestsFromOfflineStore(auditedArea.id);
      });
    }
  }

  removeQuestionsMediaRequestsFromOfflineStore(auditedAreaId: string): Promise<any> {
    const promises = new Array<Promise<any>>();
    promises.push(this.attachmentService.removeQuestionsMediaAttachmentsRequestsFromOfflineStore(auditedAreaId));
    promises.push(
      this.storageService.removeFromOfflineStore(StoragesNamesModule.auditedAreaQuestionMediaRequests + auditedAreaId)
    );
    return Promise.all(promises);
  }
}

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { noop } from 'rxjs';

import { BodyFreezerService } from '../../services/body-freezer.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'gw-modal',
  exportAs: 'gwModal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @ViewChild('modalContent') modalContent: ElementRef;

  @Input() id: string;
  /**
   * Elements of modal that should be visible
   *
   * @required
   */
  @Input() elements = {
    header: true,
    content: true,
    actions: true
  };
  /**
   * Fit height of modal to window size
   */
  @Input() fullHeight = false;
  /**
   * Use theme for info modal
   */
  @Input() info = false;
  /**
   * Show as off canvas modal
   */
  @Input() offCanvas = false;
  /**
   * Use smaller version of modal
   */
  @Input() small = false;
  /**
   * Remove side paddings for modal's content
   */
  @Input() noPaddingContent = false;
  /**
   * Use color alabaster as a background
   */
  @Input() backgroundAlabaster = false;
  /**
   * Use bigger padding in modal
   */
  @Input() biggerPadding = false;
  /**
   * Center modal vertically in window
   */
  @Input() centered = false;
  /**
   * Callback to run before closing modal
   */
  @Input() beforeClose = (): Promise<void> => Promise.resolve();

  /**
   * Callback to run after modal is closed
   */
  @Output() modalClose: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Any kind of data to pass inside modal
   */
  data: any;
  /**
   * Is modal opened
   */
  opened = false;
  hidden = false;

  constructor(public modalService: ModalService, protected bodyFreezer: BodyFreezerService) {}

  ngOnInit(): void {
    this.modalService.add(this);
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    if (this.opened) {
      this.bodyFreezer.unfreezeBody();
    }
  }

  open(data?: any): void {
    this.data = data;
    this.opened = true;
    this.bodyFreezer.freezeBody();
  }

  async close(data?: any): Promise<void> {
    const closeModal = () => {
      this.opened = false;
      this.bodyFreezer.unfreezeBody();
      this.modalClose.emit(data);
    };

    if (this.beforeClose) {
      await this.beforeClose().catch(noop);
    }
    closeModal();
  }

  show(data?: any): void {
    if (data) {
      this.data = data;
    }
    this.hidden = false;
    this.bodyFreezer.freezeBody();
  }

  hide(): void {
    this.hidden = true;
    this.bodyFreezer.unfreezeBody();
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Company } from '../../models/company.model';
import { Employee } from '../../models/employee.model';

import { TopBarLink } from '../../interfaces/top-bar-link.interface';

@Component({
  selector: 'gw-client-top-bar',
  templateUrl: './client-top-bar.component.html',
  styleUrls: ['./client-top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientTopBarComponent {
  /**
   * Currently logged user
   *
   * @required
   */
  @Input() currentUser: Employee;
  /**
   * List of companies to which current user has access
   *
   * @required
   */
  @Input() companies: Array<Company>;
  /**
   * Currently selected company
   */
  @Input() selectedCompany?: Company;
  /**
   * Currently visited page/component.
   * Links with this component will not be visible in dropdown.
   */
  @Input() component?: 'profile' | 'dashboard';
  /**
   * Links in dropdown
   */
  @Input() links?: { [name: string]: TopBarLink };

  /**
   * Callback to logout user
   */
  @Output() logout = new EventEmitter<void>();
  /**
   * Callback to change selected company
   */
  @Output() updateCompanyContext = new EventEmitter<Company>();

  logOut(): void {
    this.logout.emit();
  }

  onChangeSelectedCompany(selectedCompany: Company): void {
    this.updateCompanyContext.emit(selectedCompany);
  }
}

import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { InactiveFilter } from '../interfaces/inactive-filter.interface';

import { InactiveId } from '../enums/inactive-id.enum';

export const InactiveFiltersList: Array<InactiveFilter> = [
  {
    id: InactiveId.inactive,
    translationKey: marker('GUTWIN_SHARED.INACTIVE_FILTERS.INACTIVE')
  },
  {
    id: InactiveId.all,
    translationKey: marker('GUTWIN_SHARED.INACTIVE_FILTERS.BOTH')
  }
];


<gw-modal id="solvingDuplicationsModal" #solvingDuplicationsModal="gwModal">
  <ng-container modal-header="modal-header">{{ 'AUDIT_CONDUCT.QUESTION.DUPLICATIONS_MODAL.HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content">
    <p>{{ 'AUDIT_CONDUCT.QUESTION.DUPLICATIONS_MODAL.LEAD' | translate }}:</p>
    <div class="solving-attachments">
      <ng-container *ngFor="let attachment of duplications">
        <gw-attachment class="attachment-container" *ngIf="!attachment.toRemove &amp;&amp; attachment.isImage" [attachment]="attachment" [viewOnly]="!attachment.url" (removed)="removeAttachment(attachment)"></gw-attachment>
      </ng-container>
    </div>
    <div class="solving-attachments">
      <ng-container *ngFor="let attachment of duplications">
        <gw-attachment class="attachment-container" *ngIf="!attachment.toRemove &amp;&amp; !attachment.isImage" [attachment]="attachment" [viewOnly]="!attachment.url" (removed)="removeAttachment(attachment)"></gw-attachment>
      </ng-container>
    </div>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="button--primary" type="button" (click)="solvingDuplicationsModal.close()">{{ 'GLOBAL.ACTION.CONFIRM' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="solve-finding">
  <h4 class="solve-finding__header">{{ 'FINDING_VIEW.SOLVE_FINDING.HEADER' | translate }}</h4>
  <form [formGroup]="solveFindingForm" (ngSubmit)="submitFindingForm(solveFindingForm)" novalidate="novalidate" autocomplete="off">
    <div class="row">
      <div class="columns small-6 large-4">
        <div class="form__field--lower" [class.form__field--error]="isFieldInvalid(solveFindingForm.controls['employee'])">
          <label class="label" (click)="employeeSelect.focusDropdown()">{{ 'FINDING_VIEW.SOLVE_FINDING.LABEL.SOLVED_BY' | translate }}</label>
          <div class="form-field__error">{{ 'FINDING_VIEW.SOLVE_FINDING.ERROR.SOLVED_BY_EMPTY' | translate }}</div>
          <gw-select class="solve-finding__select" #employeeSelect="gwSelect" [formControl]="solveFindingForm.controls['employee']" [tabindex]="1" [search]="true" [placeholder]="'FINDING_VIEW.SOLVE_FINDING.PLACEHOLDER.SOLVED_BY' | translate" [data]="employees" [display]="['lastname', 'name']" [splitter]="', '" [uniqueKey]="'id'"></gw-select>
        </div>
      </div>
      <div class="columns small-6 large-4">
        <div class="form__field--lower" [class.form__field--error]="isFieldInvalid(solveFindingForm.controls['date'])">
          <label class="label" (click)="dateDropdown.focusOnDropdown()">{{ 'FINDING_VIEW.SOLVE_FINDING.LABEL.DATE_OF_SOLVING' | translate }}</label>
          <div class="form-field__error">{{ 'FINDING_VIEW.SOLVE_FINDING.ERROR.DATE_OF_SOLVING_EMPTY' | translate }}</div>
          <div class="dropdown dropdown--single-date-picker solve-finding__dropdown" gwSimpleDropdown="gwSimpleDropdown" tabindex="2" #dateDropdown="simpleDropdown" [class.dropdown--opened]="dateDropdown.opened" [class.dropdown--closed]="!dateDropdown.opened &amp;&amp; !dateDropdown.firstDrop" (keydown)="keyDownDatePicker($event)"><span class="input-classic" [class.input--placeholder]="!solveFindingForm.controls['date'].value">{{ solveFindingForm.controls['date'].value | dateFormat: 'D MMMM YYYY' }}<span *ngIf="!solveFindingForm.controls['date'].value">{{ 'FINDING_VIEW.SOLVE_FINDING.PLACEHOLDER.DATE_OF_SOLVING' | translate }}</span></span>
            <div class="dropdown__content jsDropdownContent solve-finding__dropdown-content" [class.dropdown__content--horizontal-overflow]="dateDropdown.isRightOverflow">
              <gw-date-picker *ngIf="dateDropdown.openedWithDelay" #datePicker="gwDatePicker" [date]="solveFindingForm.controls['date'].value" (setDate)="setControlValue($event, 'date'); dateDropdown.closeDropdown();"></gw-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="columns small-12">
        <div class="form__field" [class.form__field--lower]="attachments.length">
          <label class="label" for="note">{{ 'FINDING_VIEW.SOLVE_FINDING.LABEL.NOTE' | translate }}</label>
          <div class="textarea-container">
            <textarea class="textarea-classic textarea--autosize" gwAutosize="gwAutosize" rows="1" id="note" name="note" placeholder="{{ 'FINDING_VIEW.SOLVE_FINDING.PLACEHOLDER.NOTE' | translate }}" tabindex="3" [formControl]="solveFindingForm.controls['note']"></textarea>
            <div class="textarea-border"></div>
          </div>
        </div>
      </div>
      <div class="columns small-12 solve-finding__attachments" *ngIf="attachments.length">
        <div class="form__field">
          <label class="label">{{ 'FINDING_VIEW.SOLVE_FINDING.LABEL.ATTACHMENTS' | translate }}</label>
          <div class="solving-attachments">
            <ng-container *ngFor="let attachment of attachments">
              <gw-attachment class="attachment-container" *ngIf="attachment.isImage" [attachment]="attachment" (removed)="removeAttachment(attachment)"></gw-attachment>
            </ng-container>
          </div>
          <div class="solving-attachments">
            <ng-container *ngFor="let attachment of attachments">
              <gw-attachment class="attachment-container" *ngIf="!attachment.isImage" [attachment]="attachment" (removed)="removeAttachment(attachment)"></gw-attachment>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="columns small-12 medium-6">
        <label class="button--tertiary" for="upload2" tabindex="4" (keydown)="keyDownAttachmentControl($event, fileInput)"><i class="gw-attach button__icon--before"></i>{{ 'FINDING_VIEW.SOLVE_FINDING.ACTION.ADD_AN_ATTACHMENT' | translate }}</label>
        <input class="hide" #fileInput="" type="file" multiple="multiple" id="upload2" placeholder="Upload file" (change)="attachFiles($event)"/>
      </div>
      <div class="columns small-12 medium-6 text-right button-group solve-finding__button-group">
        <button class="button--secondary" type="button" tabindex="5" (click)="cancel()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
        <button class="button--primary" type="submit" tabindex="6" [disabled]="savingSolving"><i class="button__icon--before button__icon--spinner" *ngIf="savingSolving">
            <div class="spinner"></div></i>{{ 'FINDING_VIEW.SOLVE_FINDING.ACTION.SOLVE_FINDING' | translate }}</button>
      </div>
    </div>
  </form>
</div>
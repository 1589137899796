import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';

import { Subject, Subscription } from 'rxjs';

import { RouteItem } from '../interfaces/route-item.interface';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  previousRoute: RouteItem;
  currentRoute: RouteItem;
  currentPath: string;
  currentPath$: Subject<string> = new Subject<string>();
  routerSubscription: Subscription;

  constructor(protected router: Router, private route: ActivatedRoute) {
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousRoute = this.currentRoute;
        this.currentPath = this.getCurrentPath();
        this.currentRoute = this.getCurrentRoute();
        this.currentPath$.next(this.currentPath);
      }
    });
  }

  getCurrentPath(): string {
    const queryIndex = this.router.url.indexOf('?');
    return queryIndex !== -1 ? this.router.url.slice(0, queryIndex) : this.router.url;
  }

  getQueryParams(): { [key: string]: any } {
    const queryParams: { [key: string]: any } = {};
    this.route.snapshot.queryParamMap.keys.forEach(key => {
      queryParams[key] = this.route.snapshot.queryParamMap.get(key);
    });
    return queryParams;
  }

  getCurrentRoute(): RouteItem {
    return {
      url: this.currentPath,
      queryParams: this.getQueryParams()
    };
  }

  isLinkActive(url: string, strongCompare = true): boolean {
    return strongCompare ? this.currentPath === url : this.currentPath?.includes(url);
  }

  isRouteActive(url: string): boolean {
    return this.currentPath?.startsWith(`/${url}`);
  }

  openInNewTab(route: Array<string>, navigationExtras?: NavigationExtras): void {
    const url = this.router.serializeUrl(this.router.createUrlTree(route, navigationExtras));
    window.open(url, '_blank');
  }
}

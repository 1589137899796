import { Pipe, PipeTransform } from '@angular/core';
import { HelperUtil } from '../utils/helper.util';

@Pipe({
  name: 'countryAbbreviation'
})
export class CountryAbbreviationPipe implements PipeTransform {
  transform(value: string, args: Array<string>): string {
    const filteredArgs = args ? args.filter(item => !HelperUtil.isNullable(item)) : [];
    const countryAbbreviations = filteredArgs.length ? ` - ${filteredArgs.join(', ')}` : '';
    return `${value} ${countryAbbreviations}`;
  }
}

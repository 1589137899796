import { HttpErrorResponse } from '@angular/common/http';

import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular-link-http';

export const handleApolloError = ({ data, errors }: { data?: any; errors?: any }) => {
  if (errors) {
    throw new HttpErrorResponse({ error: errors });
  }
  return { data };
};

export const getApolloOptions = (httpLink: HttpLink, apiUrl: string): any => {
  const httpLinkInstance = httpLink.create({
    uri: apiUrl
  });

  return {
    cache: new InMemoryCache(),
    link: httpLinkInstance,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    }
  };
};

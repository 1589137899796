import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Product } from '../../models/product.model';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-sidebar-product',
  templateUrl: './sidebar-product.component.html'
})
export class SidebarProductComponent {
  @Input() isEditMode: boolean;
  @Input() allProducts: Array<Product>;
  @Input() chosenProducts: Array<Product>;
  @Input() userType: UserType;
  @Output() changeProduct = new EventEmitter<Product[]>();
  @Output() increaseOpenedEdits = new EventEmitter<void>();
  @Output() decreaseOpenedEdits = new EventEmitter<void>();

  readonly USER_TYPE = UserType;

  onIncreaseOpenedEdits(): void {
    this.increaseOpenedEdits.emit();
  }

  onDecreaseOpenedEdits(): void {
    this.decreaseOpenedEdits.emit();
  }

  onSubmit(products: Product[]): void {
    this.changeProduct.emit(products);
  }
}

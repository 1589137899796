import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

import { Company } from '@gutwin-audit/shared/models/company.model';

import { CompanyService } from '@gutwin-audit/shared/services/company.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';

@Injectable()
export class CompanyResolve implements Resolve<Company> {
  constructor(
    private companyService: CompanyService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<Company> {
    try {
      const currentUser = await this.userService.getCurrentUser();
      return this.companyService.getCompany(currentUser.companyId);
    } catch (error) {
      this.fetchErrorTranslation().then((translation: any) => {
        this.notificationsService.error(translation.title, translation.text);
      });
      return undefined;
    }
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService
      .get(['GLOBAL.ERROR.CONNECTION', 'ADMIN_SETTINGS.COMPANY_SETTINGS.ERROR.LOAD_COMPANY'])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['ADMIN_SETTINGS.COMPANY_SETTINGS.ERROR.LOAD_COMPANY']
        };
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
// Interfaces
import { SectionsResponse } from './section.service';
// Models
import { Audit } from '../models/audit.model';
import { AuditedArea } from './../models/audited-area.model';
import { Section } from './../models/section.model';
import { Question } from './../models/question.model';
import { Questionnaire } from './../models/questionnaire.model';
// Providers
import { ApiUrlService } from './api-url.service';
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';

export interface QuestionResponse {
  comments: string;
  content: string;
  id: string;
  rating_id: string;
  section_id: string;
  archived: boolean;
  is_custom: boolean;
  checked: boolean;
  has_findings: boolean;
  response_media_id: string;
  belongs_to_auditable: boolean;
}

@Injectable()
export class QuestionService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private storageService: StorageModuleService
  ) { }

  getQuestions(composable: Audit | AuditedArea | Questionnaire, sectionId?: string): Promise<Array<Question>> {
    let params = new HttpParams();
    params = params.set('composable_id', composable.id);
    params = params.set('composable_type', composable._type);
    if (sectionId) {
      params = params.set('section_id', sectionId);
    }

    return this.http.get(this.apiUrlService.questionApi, { params: params })
      .toPromise()
      .then(data => {
        const questions = new Array<Question>();
        const questionsResponse = new Array<QuestionResponse>();
        const questionsTypes = ['ordered_questions', 'questionnaires_questions', 'custom_questions'];
        questionsTypes.forEach(questionsType => {
          if (data[questionsType]) {
            data[questionsType].forEach((question: QuestionResponse) => {
              question.is_custom = questionsType === 'custom_questions';
              questionsResponse.push(question);
              questions.push(this.convertQuestionToGet(question));
            });
          }
        });
        this.updateQuestionsInOfflineStore(composable, questionsResponse);
        return questions;
      })
      .catch(error => {
        console.error('Error while getting questions', error);
        throw error;
      });
  }

  reorderQuestions(
    questions: Array<Question>,
    composable: Audit | AuditedArea | Questionnaire,
    sectionId: string
  ): Promise<any> {
    const requestBody = convertQuestionsToReorder();
    return this.http.put(this.apiUrlService.questionReorderApi, requestBody)
      .toPromise()
      .then(data => {
        this.reorderQuestionsInOfflineStore(composable, sectionId, requestBody.ids_in_order);
        return data;
      })
      .catch(error => {
        console.error('Error while reordering questions', error);
        throw error;
      });

    function convertQuestionsToReorder(): any {
      const questionsIds = new Array<string>();
      for (const question of questions) {
        questionsIds.push(question.id);
      }
      return {
        composable_id: composable.id,
        composable_type: composable._type,
        section_id: sectionId,
        ids_in_order: questionsIds
      };
    }
  }

  createQuestion(question: Question, section: Section, composable: Audit | AuditedArea | Questionnaire): Promise<Question> {
    return this.http.post(this.apiUrlService.questionApi, this.convertQuestionToPost(question, section, composable))
      .toPromise()
      .then((data: any) => {
        if (composable) {
          this.updateQuestionInOfflineStore(composable, data, section.id);
        }
        return this.convertQuestionToGet(data);
      })
      .catch(error => {
        console.error('Error while creating question', error);
        throw error;
      });
  }

  updateQuestion(question: Question, section: Section, composable: Audit | AuditedArea | Questionnaire): Promise<Question> {
    return this.http.put(`${this.apiUrlService.questionApi}/${question.id}`, this.convertQuestionToPut(question, composable))
      .toPromise()
      .then((data: any) => {
        if (section && composable) {
          this.updateQuestionInOfflineStore(composable, data, section.id);
        }
        return this.convertQuestionToGet(data);
      })
      .catch(error => {
        console.error('Error while updating question', error);
        throw error;
      });
  }

  archiveQuestion(question: Question, composable: Audit | AuditedArea | Questionnaire, archive = true): Promise<any> {
    return this.http.put(`${this.apiUrlService.questionApi}/${question.id}/archive`, this.convertQuestionToArchive(question, composable, archive))
      .toPromise()
      .then((data: any) => this.convertQuestionToGet(data))
      .catch(error => {
        console.error('Error while archiving question', error);
        throw error;
      });
  }

  removeQuestion(question: Question, sectionId: string, composable: Audit | AuditedArea | Questionnaire): Promise<any> {
    return this.http.delete(
      `${this.apiUrlService.questionApi}/${question.id}`,
      { params: this.convertQuestionToDelete(question, composable) }
    )
      .toPromise()
      .then(data => {
        if (sectionId && composable) {
          this.removeQuestionFromOfflineStore(composable, question.id, sectionId);
        }
        return data;
      })
      .catch(error => {
        console.error('Error while removing question', error);
        throw error;
      });
  }

  checkAuditablesQuestion(auditableId: string, auditableType: string, questionId: string): Promise<any> {
    return this.http.post(`${this.apiUrlService.questionApi}/add_to_auditable`, {
      auditable_id: auditableId,
      auditable_type: auditableType,
      question_id: questionId
    })
      .toPromise()
      .then(data => data)
      .catch(error => {
        console.error('Error while checking question', error);
        throw error;
      });
  }

  uncheckAuditablesQuestion(auditableId: string, auditableType: string, questionId: string): Promise<any> {
    return this.http.post(`${this.apiUrlService.questionApi}/remove_from_auditable`, {
      auditable_id: auditableId,
      auditable_type: auditableType,
      question_id: questionId
    })
      .toPromise()
      .then(data => data)
      .catch(error => {
        console.error('Error while unchecking question', error);
        throw error;
      });
  }

  getQuestionsFromOfflineStore(auditable: Audit | AuditedArea): Promise<Array<Question>> {
    switch (auditable._type) {
      case 'Audit':
        const audit = auditable as Audit;
        return this.getAuditQuestionsFromOfflineStore(audit);
      case 'AuditedArea':
        return this.getAuditedAreaQuestionsFromOfflineStore(auditable.id);
    }
    return new Promise((resolve, reject) => reject());
  }

  private getAuditQuestionsFromOfflineStore(audit: Audit): Promise<Array<Question>> {
    if (audit.auditedAreas) {
      const promises = new Array<Promise<Array<Question>>>();
      audit.auditedAreas.forEach((auditedArea: AuditedArea) => {
        promises.push(this.getAuditedAreaQuestionsFromOfflineStore(auditedArea.id));
      });
      return Promise.all(promises)
        .then(values => {
          const questions = new Array<Question>();
          if (values) {
            values.forEach(auditedAreaQuestions => {
              if (auditedAreaQuestions) {
                questions.push(...auditedAreaQuestions);
              }
            });
          }
          return questions;
        });
    }
    return new Promise((resolve, reject) => resolve([]));
  }

  private getAuditedAreaQuestionsFromOfflineStore(auditedAreaId: string): Promise<Array<Question>> {
    const getQuestions = (data: SectionsResponse): Array<Question> => {
      const questions = new Array<Question>();
      if (data.ordered_sections) {
        data.ordered_sections.forEach(section => {
          if (section.ordered_questions) {
            const sectionsQuestions = section.ordered_questions.map(questionResponse => this.convertQuestionToGet(questionResponse));
            questions.push(...sectionsQuestions);
          }
        });
      }
      return questions;
    };

    return this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedAreaId)
      .then((data: SectionsResponse) => {
        if (data) {
          return getQuestions(data);
        }
      });
  }

  private updateQuestionsInOfflineStore(
    composable: Audit | AuditedArea | Questionnaire,
    questions: Array<QuestionResponse>
  ): void {
    switch (composable._type) {
      case 'Audit':
        const audit = new Audit(composable);
        if (audit.auditedAreas) {
          audit.auditedAreas.forEach((auditedArea: AuditedArea) => {
            this.updateAuditedAreaQuestionsInOfflineStore(auditedArea, questions);
          });
        }
        break;
      case 'AuditedArea':
        const auditedArea = new AuditedArea(composable);
        this.updateAuditedAreaQuestionsInOfflineStore(auditedArea, questions);
        break;
    }
  }

  private updateAuditedAreaQuestionsInOfflineStore(auditedArea: AuditedArea, questions: Array<QuestionResponse>): void {
    const updateQuestions = (data: SectionsResponse): any => {
      const sections = data.ordered_sections;
      questions.forEach(question => {
        const section = _.find(sections, {id: question.section_id});
        if (!section.ordered_questions) {
          section.ordered_questions = new Array<QuestionResponse>();
        }
        const questionIndex = _.findIndex(section.ordered_questions, {id: question.id});
        if (~questionIndex) {
          section.ordered_questions[questionIndex] = question;
        } else {
          section.ordered_questions.push(question);
          section.total_questions = section.ordered_questions.length;
        }
      });
      return data;
    };

    this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id)
      .then((data: SectionsResponse) => {
        if (data !== null) {
          const updatedData = updateQuestions(data);
          this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id, updatedData);
        }
      });
  }

  private updateQuestionInOfflineStore(
    composable: Audit | AuditedArea | Questionnaire,
    question: QuestionResponse,
    sectionId: string
  ): void {
    switch (composable._type) {
      case 'Audit':
        const audit = new Audit(composable);
        if (audit.auditedAreas) {
          audit.auditedAreas.forEach((auditedArea: AuditedArea) => {
            this.updateAuditedAreaQuestionInOfflineStore(auditedArea, question, sectionId);
          });
        }
        break;
      case 'AuditedArea':
        const auditedArea = new AuditedArea(composable);
        this.updateAuditedAreaQuestionInOfflineStore(auditedArea, question, sectionId);
        break;
    }
  }

  private updateAuditedAreaQuestionInOfflineStore(auditedArea: AuditedArea, question: QuestionResponse, sectionId: string): void {
    const updateQuestion = (data: SectionsResponse): any => {
      for (const section of data.ordered_sections) {
        if (section.id === sectionId) {
          if (!section.ordered_questions) {
            section.ordered_questions = new Array<QuestionResponse>();
          }
          const questionIndex = _.findIndex(section.ordered_questions, {id: question.id});
          if (~questionIndex) {
            section.ordered_questions[questionIndex] = question;
          } else {
            section.ordered_questions.push(question);
            section.total_questions = section.ordered_questions.length;
          }
          return data;
        }
      }
    };

    this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id)
      .then((data: SectionsResponse) => {
        if (data !== null) {
          const updatedData = updateQuestion(data);
          this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id, updatedData);
        }
      });
  }

  private removeQuestionFromOfflineStore(
    composable: Audit | AuditedArea | Questionnaire,
    questionId: string,
    sectionId: string
  ): void {
    switch (composable._type) {
      case 'Audit':
        const audit = new Audit(composable);
        if (audit.auditedAreas) {
          audit.auditedAreas.forEach((auditedArea: AuditedArea) => {
            this.removeAuditedAreaQuestionFromOfflineStore(auditedArea, questionId, sectionId);
          });
        }
        break;
      case 'AuditedArea':
        const auditedArea = new AuditedArea(composable);
        this.removeAuditedAreaQuestionFromOfflineStore(auditedArea, questionId, sectionId);
        break;
    }
  }

  private removeAuditedAreaQuestionFromOfflineStore(auditedArea: AuditedArea, questionId: string, sectionId: string): void {
    const updateQuestion = (data: SectionsResponse): any => {
      for (const section of data.ordered_sections) {
        if (section.id === sectionId) {
          if (!section.ordered_questions) {
            section.ordered_questions = new Array<QuestionResponse>();
          }
          const questionIndex = _.findIndex(section.ordered_questions, {id: questionId});
          if (~questionIndex) {
            section.ordered_questions.splice(questionIndex, 1);
          }
          return data;
        }
      }
    };

    this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id)
      .then((data: SectionsResponse) => {
        if (data !== null) {
          const updatedData = updateQuestion(data);
          this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id, updatedData);
        }
      });
  }

  private reorderQuestionsInOfflineStore(
    composable: Audit | AuditedArea | Questionnaire,
    sectionId: string,
    questionsIds: Array<string>
  ): void {
    const reorderQuestions = (data: SectionsResponse): any => {
      const sectionIndex = _.findIndex(data.ordered_sections, {id: sectionId});
      if (~sectionIndex) {
        const section = data.ordered_sections[sectionIndex];
        const newQuestions = new Array<QuestionResponse>();
        section.ordered_questions.forEach(question => {
          const index = questionsIds.indexOf(question.id);
          newQuestions[index] = question;
        });
        section.ordered_questions = newQuestions;
      }
      return data;
    };

    switch (composable._type) {
      case 'AuditedArea':
        this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaSections + composable.id)
          .then((data: SectionsResponse) => {
            if (data !== null) {
              const updatedData = reorderQuestions(data);
              this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + composable.id, updatedData);
            }
          });
      break;
    }
  }

  convertQuestionToGet(question: QuestionResponse): Question {
    const questionResponse = {
      id: question.id,
      content: question.content,
      description: question.comments,
      checked: question.checked,
      ratingId: question.rating_id,
      archived: question.archived,
      custom: question.is_custom,
      sectionId: question.section_id,
      hasFindings: question.has_findings,
      responseMediaId: question.response_media_id,
      addedToParent: question.belongs_to_auditable === false
    };
    return new Question(questionResponse);
  }

  private convertQuestionToPost(question: Question, section: Section, composable: Audit | AuditedArea | Questionnaire): any {
    const questionRequest = {
      section_id: section.id,
      content: question.content,
      comments: question.description,
      composable_id: composable.id,
      composable_type: composable._type
    };
    return questionRequest;
  }

  private convertQuestionToPut(question: Question, composable: Audit | AuditedArea | Questionnaire): any {
    const questionRequest = {
      id: question.id,
      content: question.content,
      comments: question.description,
      composable_id: composable.id,
      composable_type: composable._type
    };
    return questionRequest;
  }

  private convertQuestionToArchive(question: Question, composable: Audit | AuditedArea | Questionnaire, archive: boolean): any {
    const questionRequest = {
      id: question.id,
      archive: archive ? 'true' : 'false',
      composable_id: composable.id,
      composable_type: composable._type
    };
    return questionRequest;
  }

  private convertQuestionToDelete(question: Question, composable: Audit | AuditedArea | Questionnaire): HttpParams {
    let sectionRequest = new HttpParams();
    sectionRequest = sectionRequest.set('id', question.id);
    sectionRequest = sectionRequest.set('composable_id', composable.id);
    sectionRequest = sectionRequest.set('composable_type', composable._type);
    return sectionRequest;
  }
}

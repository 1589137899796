import { FormGroup } from '@angular/forms';

import moment from 'moment';

export class DateValidator {
  static isCorrectDateOrder(startTimeControlKey: string, endTimeControlKey: string) {
    return (group: FormGroup): {[key: string]: any} => {
      const start = group.get(startTimeControlKey).value;
      const end = group.get(endTimeControlKey).value;

      if (!moment(end).isAfter(moment(start))) {
        return {
          incorrectDateOrder: true
        };
      }
    };
  }
}

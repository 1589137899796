import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { AttachmentLC } from '../../models/attachment-lc.model';
import { Attachment as LightboxAttachment } from '../../models/attachment.model';

import { LightboxService } from '../../services/lightbox.service';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-edit-attachment',
  templateUrl: './edit-attachment.component.html',
  styleUrls: ['./edit-attachment.component.scss'],
  exportAs: 'gwEditAttachment'
})
export class EditAttachmentComponent implements OnInit {
  @Input() attachment: AttachmentLC;
  @Input() convertSizeToMB?: boolean;
  @Input() alignRight = true;
  @Input() isDocument?: boolean;
  @Input() isEditMode: boolean;
  @Input() isUserTypeClient: boolean;
  @Input() showSize?: boolean;
  @Input() showMimeType?: boolean;
  @Input() removeFileExtension?: boolean;

  @Output() submitForm = new EventEmitter<AttachmentLC>();
  @Output() togglePopoverStatus = new EventEmitter<void>();

  warningDialog: {
    header: '';
    content: '';
    confirm: '';
  };
  form: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private lightboxService: LightboxService, private store: Store) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      displayName: ['', Validators.required]
    });
  }

  resetForm(): void {
    this.form.get('displayName').setValue(this.attachment.displayName || '');
    this.submitted = false;
  }

  setControlValue(data: any, key: string): void {
    this.form.get(key).setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  onSubmitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      const attachment = new AttachmentLC({
        ...this.attachment,
        action: 'change_display_name',
        displayName: this.form.value.displayName
      });
      this.submitForm.emit(attachment);
    }
  }

  increaseOpenedEdits(): void {
    this.togglePopoverStatus.emit();
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.togglePopoverStatus.emit();
    this.store.dispatch(new DecreaseOpenedEdits());
  }

  showImagePreview(): void {
    const attachmentPreview = new LightboxAttachment({
      ...this.attachment,
      size: NaN
    });
    this.lightboxService.show(attachmentPreview);
  }
}

import { Injectable } from '@angular/core';

import localforage from 'localforage';

import { EmployeeExtended, StorageService, StoragesNamesClass } from 'gutwin-shared';

export class StoragesNamesModuleClass extends StoragesNamesClass {
  auditCreation = 'audit-create';
  findingCreation = 'finding';
  auditSummaryRedirect = 'audit-summary-redirect';

  // Offline store
  // Store array with audited area's sections (id of audited area in name)
  auditedAreaSections = 'audited-area-sections-';
  // Store array with audits ids that are saved offline
  auditsIdsOffline = 'audits-ids-offline';
  // Store array with audits
  audits = 'audits';
  // Store audit with audited areas (id in name)
  audit = 'audit-';
  // Store audits and audited areas requests
  auditsRequests = 'audits-requests-';
  // Store audited area's findings (id in name)
  auditedAreaFindings = 'audited-area-findings-';
  // Store requests for audited area's findings (id in name)
  auditedAreaFindingsRequests = 'audited-area-findings-requests-';
  // Store requests for audited area's questions ratings (id in name)
  auditedAreaRatings = 'audited-area-ratings-';
  // Store audited area's questions media (id in name)
  auditedAreaQuestionMedia = 'audited-area-question-media-';
  // Store requests for audited area's questions media (id in name)
  auditedAreaQuestionMediaRequests = 'audited-area-question-media-requests-';
  // Store requests for attachments for question media in audited area (id in name)
  attachmentsQuestionMediaRequests = 'audited-area-question-media-attachments-';
  // Store requests for attachments for findings in audited area (id in name)
  attachmentsFindingsRequests = 'audited-area-findings-attachments-';
  // Store finding types
  findingTypes = 'finding-types';
  // Store ratings
  ratings = 'ratings';
  // Store rating scale
  ratingScale = 'rating-scale-';
  // Store employees
  employees = 'employees';
}
export const StoragesNamesModule = new StoragesNamesModuleClass();

@Injectable()
export class StorageModuleService extends StorageService {
  userOfflineStore: LocalForage;
  globalOfflineStore = localforage.createInstance({
    name: '_GLOBAL'
  });

  constructor() {
    super();
  }

  initUserOfflineStore(): Promise<void> {
    return this.getOfflineStore(StoragesNamesModule.currentUser, true).then((currentUser: EmployeeExtended) => {
      if (currentUser) {
        this.setUserOfflineStore(currentUser.id);
      }
    });
  }

  setUserOfflineStore(name: string): void {
    this.userOfflineStore = localforage.createInstance({
      name: `_USER_${name}`
    });
  }

  async setOfflineStore(storeName: string, data: any, global?: boolean): Promise<void> {
    this.storesChanged.push(storeName);
    this.storesChangedSubject.next(this.storesChanged);
    if (!(this.userOfflineStore || global)) {
      await this.initUserOfflineStore();
    }
    const offlineStore = global ? this.globalOfflineStore : this.userOfflineStore;
    if (offlineStore) {
      return offlineStore.setItem(storeName, data);
    }
    return new Promise((resolve, reject) => reject());
  }

  async getOfflineStore(storeName: string, global?: boolean): Promise<any> {
    if (!(this.userOfflineStore || global)) {
      await this.initUserOfflineStore();
    }
    const offlineStore = global ? this.globalOfflineStore : this.userOfflineStore;
    if (offlineStore) {
      return offlineStore.getItem(storeName);
    }
    return new Promise((resolve, reject) => reject());
  }

  async removeFromOfflineStore(storeName: string, global?: boolean): Promise<void> {
    const index = this.storesChanged.indexOf(storeName);
    this.storesChanged.splice(index, 1);
    this.storesChangedSubject.next(this.storesChanged);
    if (!(this.userOfflineStore || global)) {
      await this.initUserOfflineStore();
    }
    const offlineStore = global ? this.globalOfflineStore : this.userOfflineStore;
    if (offlineStore) {
      return offlineStore.removeItem(storeName);
    }
    return new Promise((resolve, reject) => reject());
  }
}

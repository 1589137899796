import { Component, OnInit, Input, Output, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import moment from 'moment';
// Models
import { Audit } from './../../../../shared/models/audit.model';
import { AuditedArea } from './../../../../shared/models/audited-area.model';

@Component({
  selector: 'gw-subaudits-group',
  templateUrl: './subaudits-group.component.html',
  styleUrls: ['./subaudits-group.component.scss']
})
export class SubauditsGroupComponent implements OnInit {
  @Input() audit: Audit;
  @Input() subaudits: Array<AuditedArea>;
  @Input() subauditsDisabled: Array<AuditedArea>;
  @Input() isFirst = false;
  @Input() isTemplate = false;
  @Input() isEven = false;
  date: moment.Moment;

  constructor() { }

  ngOnInit() {
    this.getDate();
  }

  getDate(): void {
    if (this.subaudits && this.subaudits.length) {
      this.date = this.subaudits[0].startTime;
    }
  }
}

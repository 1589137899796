
<gw-lightbox id="lightboxModal" #lightboxModal="gwLightbox"></gw-lightbox>
<gw-page-header>
  <ng-container page-header-left="page-header-left">
    <ul class="header__menu header__menu--left">
      <li class="header__item"><a class="button button--link header__button--transparent header__button--menu" (click)="auditNavigationService.toggleNavigation()"><i class="gw-hamburger button__icon"></i></a></li>
      <li class="header__item header__item--title">{{ 'FINDINGS_LIST.PAGE_HEADER' | translate }}</li>
    </ul>
  </ng-container>
  <ng-container page-header-right="page-header-right">
    <ul class="header__menu header__menu--right">
      <li class="header__item">
        <button class="button--tertiary header__button" type="button" (click)="toggleFilters()" [class.header__button--active]="filtersOpened"><i class="gw-filter button__icon"></i></button>
      </li>
    </ul>
  </ng-container>
</gw-page-header>
<gw-findings-tabs></gw-findings-tabs>
<gw-findings-filters class="filters-container" [class.filters-container--opened]="filtersOpened"></gw-findings-filters>
<router-outlet></router-outlet>
import { ExtraFieldValue } from '../interfaces/extra-field-value.interface';

export class ExtraFieldSimple {
  id?: string;
  name: string;
  values?: Array<ExtraFieldValue>;

  constructor(extraFieldWithValuesOnly: Partial<ExtraFieldSimple>) {
    this.id = extraFieldWithValuesOnly ? extraFieldWithValuesOnly.id : undefined;
    this.name = extraFieldWithValuesOnly ? extraFieldWithValuesOnly.name : undefined;
    this.values = extraFieldWithValuesOnly ? extraFieldWithValuesOnly.values : undefined;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// Gutwin Shared Library
import { ExpandBoxComponent } from 'gutwin-shared';

// Models
import { FacilityFilter } from '../../models/facility-filter.model';

@Component({
  selector: 'gw-facility-tree-view',
  templateUrl: './facility-tree-view.component.html',
  styleUrls: ['./facility-tree-view.component.scss']
})
export class FacilityTreeViewComponent implements OnInit {
  @Input() facilityTree: Array<FacilityFilter>;
  @Input() selectedFacility: any;
  @Input() multiSelect: boolean;
  @Input() subFolders: boolean;
  @Input() parentChecked: boolean;
  @Input() small: boolean;
  @Input() parentFacility: FacilityFilter;
  @Input() parentExpandBox: ExpandBoxComponent;
  @Output() changeSelectedFacility = new EventEmitter();
  @Output() selectChildFacility = new EventEmitter();
  @Output() openExpandBox = new EventEmitter();

  constructor() {}

  ngOnInit() {
    let openExpandBox = false;
    this.facilityTree.forEach(facility => {
      if (this.isFacilityChecked(facility)) {
        openExpandBox = true;
      }
    });
    if (openExpandBox) {
      this.onOpenExpandBox();
    }
  }

  isFacilityChecked(facility: FacilityFilter): boolean {
    return (!this.multiSelect && facility.id === this.selectedFacility.id) || (this.multiSelect && facility.selected);
  }

  onChangeSelectedFacility(facility: any): void {
    this.selectedFacility = facility;
    this.parentChecked = false;
    this.changeSelectedFacility.emit(this.selectedFacility);
  }

  selectFacility(value: boolean, facility: FacilityFilter): void {
    facility.selected = value;
    this.unselectChildren(facility);
    if (this.parentChecked) {
      this.parentChecked = false;
      this.childSelected();
    }
  }

  unselectChildren(facility: FacilityFilter): void {
    if (facility.children && facility.children.length) {
      facility.children.forEach(facilityChild => {
        facilityChild.selected = false;
        this.unselectChildren(facilityChild);
      });
    }
  }

  childSelected(): void {
    this.parentChecked = false;
    if (this.parentFacility) {
      this.parentFacility.selected = false;
      this.selectChildFacility.emit();
    }
  }

  onOpenExpandBox(): void {
    if (this.parentExpandBox && !this.parentExpandBox.opened) {
      this.parentExpandBox.toggle();
    }
    this.openExpandBox.emit();
  }
}


<div class="chart__content">
  <div class="chart__svg chart__svg--bar">
    <div class="bar-chart" #barChart=""></div>
  </div>
  <div class="chart__legend">
    <ul class="chart-legend__list" [style.column-count]="columns">
      <li class="chart-legend__item" *ngFor="let finding of chartDataset; let i = index"><span class="chart-legend__badge" [style.background-color]="colors[i % colors.length]"></span>
        <ng-container *ngIf="totalCount">{{ finding.count / totalCount * 100 | number: '1.0-0' }}%</ng-container>
        <ng-container *ngIf="!totalCount">0%</ng-container><span class="chart-legend__label">{{ finding.count }} {{ finding.name }}</span>
      </li>
    </ul>
  </div>
</div>
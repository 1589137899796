import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
// Gutwin Shared Library
import { DialogService, Employee } from 'gutwin-shared';
// Models
import { Audit } from './../../../shared/models/audit.model';
// Interfaces
import { EmployeesGroups } from './../../../shared/interfaces/employeesGroups.interface';
// Services
import { EmailService } from './../../../shared/services/email.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gw-step-3-schedule-audit',
  templateUrl: './step-3-schedule-audit.component.html',
  styleUrls: ['./step-3-schedule-audit.component.scss']
})
export class Step3ScheduleAuditComponent implements OnInit, OnDestroy {
  @Output() submitStep = new EventEmitter<any>();
  @Output() updateAudit = new EventEmitter<any>();
  @Output() updateStorage = new EventEmitter<any>();
  @Input() audit: Audit;
  @Input() auditObservable: Observable<Audit>;
  groupedEmployees: EmployeesGroups;
  employeesToSend: EmployeesGroups;
  scheduleAuditForm: FormGroup;
  sending: boolean;
  isEmployeeSelected: boolean;
  translateSubscription: Subscription;
  scheduleAuditFormSubscription: Subscription;
  sendEmailsDialog = {
    header: '',
    content: '',
    cancel: '',
    submit: ''
  };
  notify = {
    error: {
      connection: '',
      sendEmails: '',
    },
    success: {
      sendEmailsTitle: '',
      sendEmailsText: ''
    }
  };
  translation = {
    auditRole: {
      auditors: '',
      auditees: '',
      creators: '',
      organizers: ''
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private emailService: EmailService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService

  ) { }

  ngOnInit(): void {
    this.fetchTranslation();
    this.groupedEmployees = this.getChosenEmployees();
    this.initForm();
    this.isEmployeeSelected = this.checkEmployeeSelected();
    this.auditObservable.subscribe(audit => {
      this.audit = audit;
      this.groupedEmployees = this.getChosenEmployees();
      this.initForm();
      this.isEmployeeSelected = this.checkEmployeeSelected();
    });
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
    if (this.scheduleAuditFormSubscription) {
      this.scheduleAuditFormSubscription.unsubscribe();
    }
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.TABLE_LABEL.AUDITORS',
      'GLOBAL.TABLE_LABEL.AUDITEES',
      'GLOBAL.TABLE_LABEL.CREATORS',
      'GLOBAL.TABLE_LABEL.ORGANIZERS',
      'AUDIT_CREATION.S_SCHEDULE_AUDIT.ERROR.SEND_EMAILS',
      'AUDIT_CREATION.S_SCHEDULE_AUDIT.SUCCESS.SEND_EMAILS_TITLE',
      'AUDIT_CREATION.S_SCHEDULE_AUDIT.SUCCESS.SEND_EMAILS_TEXT',
      'AUDIT_CREATION.S_SCHEDULE_AUDIT.SEND_EMAILS_DIALOG.HEADER',
      'AUDIT_CREATION.S_SCHEDULE_AUDIT.SEND_EMAILS_DIALOG.CONTENT',
      'AUDIT_CREATION.S_SCHEDULE_AUDIT.SEND_EMAILS_DIALOG.CANCEL',
      'AUDIT_CREATION.S_SCHEDULE_AUDIT.SEND_EMAILS_DIALOG.SUBMIT',
    ]).subscribe(translation => {
      this.notify.error.connection = translation['GLOBAL.ERROR.CONNECTION'];
      this.notify.error.sendEmails = translation['AUDIT_CREATION.S_SCHEDULE_AUDIT.ERROR.SEND_EMAILS'];
      this.notify.success.sendEmailsTitle = translation['AUDIT_CREATION.S_SCHEDULE_AUDIT.SUCCESS.SEND_EMAILS_TITLE'];
      this.notify.success.sendEmailsText = translation['AUDIT_CREATION.S_SCHEDULE_AUDIT.SUCCESS.SEND_EMAILS_TEXT'];
      this.sendEmailsDialog.header = translation['AUDIT_CREATION.S_SCHEDULE_AUDIT.SEND_EMAILS_DIALOG.HEADER'];
      this.sendEmailsDialog.content = translation['AUDIT_CREATION.S_SCHEDULE_AUDIT.SEND_EMAILS_DIALOG.CONTENT'];
      this.sendEmailsDialog.cancel = translation['AUDIT_CREATION.S_SCHEDULE_AUDIT.SEND_EMAILS_DIALOG.CANCEL'];
      this.sendEmailsDialog.submit = translation['AUDIT_CREATION.S_SCHEDULE_AUDIT.SEND_EMAILS_DIALOG.SUBMIT'];
      this.translation.auditRole.auditors = translation['GLOBAL.TABLE_LABEL.AUDITORS'];
      this.translation.auditRole.auditees = translation['GLOBAL.TABLE_LABEL.AUDITEES'];
      this.translation.auditRole.creators = translation['GLOBAL.TABLE_LABEL.CREATORS'];
      this.translation.auditRole.organizers = translation['GLOBAL.TABLE_LABEL.ORGANIZERS'];
    });
  }

  initForm(): void {
    this.scheduleAuditForm = this.formBuilder.group({
      auditor: this.generateControls('auditor'),
      creator: this.generateControls('creator'),
      organizer: this.generateControls('organizer'),
      auditee: this.generateControls('auditee')
    });
    this.scheduleAuditFormSubscription = this.scheduleAuditForm.valueChanges.subscribe(data => {
      this.isEmployeeSelected = this.checkEmployeeSelected();
    });
  }

  generateControls(role: string): FormGroup {
    const employeesControls = {};
    if (this.groupedEmployees && this.groupedEmployees[role]) {
      this.groupedEmployees[role].forEach(employee => {
        employeesControls[employee.id] = this.formBuilder.group({
          employee: employee,
          isIncluded: false
        });
      });
    }
    return this.formBuilder.group(employeesControls);
  }

  getChosenEmployees(): EmployeesGroups {
    const employees = {
      creator: new Array<Employee>(),
      organizer: new Array<Employee>(),
      auditor: new Array<Employee>(),
      auditee: new Array<Employee>()
    };
    if (this.audit) {
      if (this.audit.employees.creator) {
        this.audit.employees.creator.forEach(auditCreator => {
          this.addToGroup(employees, auditCreator, 'creator');
        });
      }
      if (this.audit.employees.organizer) {
        this.audit.employees.organizer.forEach(auditOrganizer => {
          this.addToGroup(employees, auditOrganizer, 'organizer');
        });
      }
      if (this.audit.auditedAreas) {
        this.audit.auditedAreas.forEach(auditedArea => {
          if (auditedArea.employees.auditor) {
            auditedArea.employees.auditor.forEach(auditAuditor => {
              this.addToGroup(employees, auditAuditor, 'auditor');
            });
          }
          if (auditedArea.employees.auditee) {
            auditedArea.employees.auditee.forEach(auditAuditee => {
              this.addToGroup(employees, auditAuditee, 'auditee');
            });
          }
        });
      }
    }
    return employees;
  }

  addToGroup(employees: EmployeesGroups, employee: Employee, auditRole: string): void {
    const employeeIndex = _.findIndex(employees[auditRole], (employeeObject: Employee) => {
      return employeeObject.id === employee.id;
    });
    if (employeeIndex === -1) {
      employees[auditRole].push(employee);
    }
  }

  clickGroupCheckbox(roleControlsValue: FormGroup): void {
    this.setAllValues(roleControlsValue, !this.hasAllChecked(roleControlsValue));
  }

  setValue(formControl: FormControl, value: boolean): void {
    formControl['controls'].isIncluded.setValue(value);
  }

  setAllValues(roleControlsValue: FormGroup, value: boolean): void {
    for (const userId in roleControlsValue.controls) {
      if (roleControlsValue.controls.hasOwnProperty(userId)) {
        roleControlsValue.controls[userId]['controls'].isIncluded.setValue(value);
      }
    }
  }

  isChecked(formControl: FormControl): boolean {
    return formControl['controls'].isIncluded.value;
  }

  hasAllChecked(formGroup: FormGroup): boolean {
    let valid = true;
    for (const control in formGroup.controls) {
      if (formGroup.controls[control]['controls'].isIncluded.value === false) {
        valid = false;
      }
    }
    return valid;
  }

  getRoleName(role: string): string {
    switch (role) {
      case 'auditee':
        return this.translation.auditRole.auditees || 'Auditees';
      case 'auditor':
        return this.translation.auditRole.auditors || 'Auditors';
      case 'creator':
        return this.translation.auditRole.creators || 'Creators';
      case 'organizer':
        return this.translation.auditRole.organizers || 'Organizers';
      default:
        return '';
    }
  }

  checkEmployeeSelected(): boolean {
    if (this.scheduleAuditForm.value) {
      for (const role in this.scheduleAuditForm.value) {
        if (this.scheduleAuditForm.value.hasOwnProperty(role)) {
          const employeesRole = this.scheduleAuditForm.value[role];
          for (const employeeId in employeesRole) {
            if (employeesRole[employeeId].isIncluded) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  areEmployeesDifferent(employeesToSend: EmployeesGroups): boolean {
    return JSON.stringify(this.employeesToSend) !== JSON.stringify(employeesToSend);
  }

  convertEmployeesToSend(): EmployeesGroups {
    const employees = {
      auditor: new Array<Employee>(),
      creator: new Array<Employee>(),
      organizer: new Array<Employee>(),
      auditee: new Array<Employee>(),
    };
    if (this.scheduleAuditForm.value) {
      for (const role in this.scheduleAuditForm.value) {
        if (this.scheduleAuditForm.value.hasOwnProperty(role)) {
          const employeesRole = this.scheduleAuditForm.value[role];
          for (const employeeId in employeesRole) {
            if (employeesRole[employeeId].isIncluded) {
              employees[role].push(employeesRole[employeeId].employee);
            }
          }
        }
      }
    }
    return employees;
  }

  showSendEmailsDialog(employeesToSend: EmployeesGroups): Promise<void> {
    return this.dialogService.confirm(
      this.sendEmailsDialog.header,
      this.sendEmailsDialog.content,
      this.sendEmailsDialog.cancel,
      this.sendEmailsDialog.submit
    )
      .then(() => this.sendEmails(employeesToSend, true))
      .catch((error) => {
        if (error) {
          throw error;
        }
      });
  }

  async sendEmails(employeesToSend?: EmployeesGroups, throwError?: boolean): Promise<void> {
    this.sending = true;
    if (!employeesToSend) {
      employeesToSend = this.convertEmployeesToSend();
    }
    await this.emailService.sendEmailsForConductAudit(this.audit, employeesToSend)
      .then(response => {
        this.employeesToSend = employeesToSend;
        this.notificationsService.success(this.notify.success.sendEmailsTitle, this.notify.success.sendEmailsText);
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connection, this.notify.error.sendEmails);
        if (throwError) {
          throw error;
        }
      });
    this.sending = false;
  }

  submitAuditForm(formData): void {
    if (this.scheduleAuditForm.valid) {
      const employeesToSend = this.convertEmployeesToSend();
      if (this.isEmployeeSelected && this.areEmployeesDifferent(employeesToSend)) {
        this.showSendEmailsDialog(employeesToSend)
          .then(() => {
            this.submitStep.emit({
              'goToStepId': 3
            });
          })
          .catch(error => {});
      } else {
        this.submitStep.emit({
          'goToStepId': 3
        });
      }
    }
  }

  getObjectSize(object): number {
    return Object.keys(object).length;
  }
}

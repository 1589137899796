import { ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gw-check-box',
  exportAs: 'gwCheckBox',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckBoxComponent),
      multi: true
    }
  ]
})
export class CheckBoxComponent implements OnInit, ControlValueAccessor {
  @Input() disabled = false;
  @Input() connectedState: boolean;
  @Input() light: boolean;
  @Input() blueBackground: boolean;
  @Input() parentChecked: boolean;
  @Input() standalone: boolean;
  @Input() tabindex: number;
  @Input() blocked?: boolean;

  value: boolean;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {}

  onChange: (_: boolean) => void = () => {};

  writeValue(value: boolean) {
    if (value !== undefined) {
      this.value = value;
      this.changeDetector.detectChanges();
    }
  }

  registerOnChange(onChange: (_: boolean) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(): void {}

  onClick(): void {
    if (!this.disabled) {
      this.value = !this.value;
      this.onChange(this.value);
    }
  }
}

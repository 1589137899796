import gql from 'graphql-tag';

import { CurrentUserGraphQLActionType } from '../../enums/user-actions.enum';

import { LicenseFragment } from '../fragments/license.fragment';

export const LicensesQuery = gql`
  query ${CurrentUserGraphQLActionType.profile} {
    ${CurrentUserGraphQLActionType.profile} {
      company {
        license {
          ...LicenseFragment
        }
      }
    }
  }
  ${LicenseFragment}
`;

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FlatTreeNode } from '../../interfaces/flat-tree-node.interface';

@Component({
  selector: 'gw-flat-tree-node',
  templateUrl: 'flat-tree-node.component.html',
  styleUrls: ['flat-tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatTreeNodeComponent {
  @Input() node: FlatTreeNode;
  @Input() showConnectedElements?: boolean;

  @Output() toggleNode = new EventEmitter<FlatTreeNode>();

  readonly MAX_TOOLTIP_DISTANCE_FROM_TOP = 400;

  onToggleNode(): void {
    this.toggleNode.emit(this.node);
  }
}

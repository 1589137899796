import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

// Models
import { TreeFilterOption } from '../../models/tree-filter-option.model';

@Component({
  selector: 'gw-tree-multiselect',
  templateUrl: './tree-multiselect.component.html',
  styleUrls: ['./tree-multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TreeMultiselectComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeMultiselectComponent {
  @Input() opened = false;
  @Input() markSelectedChildren = false;
  @Input() isParentSelected = false;
  @Input() hasParent = false;
  @Input() isParentFullyClosed?: boolean;

  nodes: Array<TreeFilterOption>;

  constructor(private changeDetector: ChangeDetectorRef) {}

  onChange: (_: Array<TreeFilterOption>) => void = () => {};

  writeValue(value: Array<TreeFilterOption>): void {
    if (value) {
      this.nodes = value;
      this.changeDetector.detectChanges();
    }
  }

  registerOnChange(fn: (_: Array<TreeFilterOption>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  setChildrenValue(children: Array<TreeFilterOption>, value: boolean): void {
    if (children) {
      for (const child of children) {
        child.isSelected = false;
        this.setChildrenValue(child.children, value);
      }
      this.changeDetector.markForCheck();
    }
  }

  updateParentValue(parent: TreeFilterOption): void {
    if (this.markSelectedChildren) {
      parent.isSelected = false;
    }
    this.onChange(this.nodes);
    this.changeDetector.markForCheck();
  }

  selectNode(option: TreeFilterOption): void {
    if (this.markSelectedChildren) {
      this.setChildrenValue(option.children, option.isSelected);
    }
    this.onChange(this.nodes);
  }
}

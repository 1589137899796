import { DateRangeFilter } from '../interfaces/date-range.interface';

import { FilterType } from '../enums/filter-type.enum';
import { InactiveId } from '../enums/inactive-id.enum';

export class PermitFilters {
  [FilterType.legalCategories]?: Array<string>;
  [FilterType.inactive]?: InactiveId;
  [FilterType.includeSubunits]?: boolean;
  [FilterType.dateOfIssue]?: DateRangeFilter;
  [FilterType.effectiveUntil]?: DateRangeFilter;
  [FilterType.validIndefinitely]?: boolean;
  [FilterType.submittedOn]?: DateRangeFilter;
  [FilterType.units]?: Array<string>;

  constructor(permitFilters?: Partial<PermitFilters>) {
    Object.assign(this, permitFilters);
  }
}


<gw-modal id="findingTypeModal" #findingTypeModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="findingTypeModal.data">{{ 'ADMIN_SETTINGS.FINDING_TYPES.MODAL.HEADER.EDIT' | translate }}</ng-container>
    <ng-container *ngIf="!findingTypeModal.data">{{ 'ADMIN_SETTINGS.FINDING_TYPES.MODAL.HEADER.ADD' | translate }}</ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="findingTypeModal.opened">
    <gw-add-finding-type #addFindingType="gwAddFindingType" [findingType]="findingTypeModal.data"></gw-add-finding-type>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="findingTypeModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="saveFindingType()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="row finding-types">
  <div class="columns">
    <div class="row finding-types__header">
      <div class="columns small-12 large-8 finding-types-header__title-container">
        <h1 class="finding-types-header__title">{{ 'ADMIN_SETTINGS.FINDING_TYPES.HEADER' | translate }}</h1>
      </div>
      <div class="columns small-12 large-4 finding-types-header__button-container">
        <button class="button--primary finding-types-header__button" (click)="findingTypeModal.open()">{{ 'ADMIN_SETTINGS.FINDING_TYPES.ACTION.ADD_FINDING_TYPE' | translate }}</button>
      </div>
    </div>
    <div class="finding-types__content">
      <div class="flex-table finding-types-table">
        <div class="flex-table__row">
          <div class="flex-table__cell--th finding-types-table__col--1">{{ 'GLOBAL.TABLE_LABEL.NAME' | translate }}</div>
          <div class="flex-table__cell--th finding-types-table__col--2">{{ 'GLOBAL.TABLE_LABEL.DESCRIPTION' | translate }}</div>
          <div class="flex-table__cell--th finding-types-table__col--3"></div>
        </div>
        <div class="flex-table__row finding-types-table__row--content" *ngFor="let findingType of findingTypes; even as evenFindingType" [class.flex-table__row--even]="evenFindingType">
          <div class="flex-table__cell finding-types-table__col--1">{{ findingType.name }}</div>
          <div class="flex-table__cell finding-types-table__col--2">{{ findingType.description }}</div>
          <div class="flex-table__cell finding-types-table__col--3"><span class="dropdown dropdown--menu" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
              <button class="finding-types-table__option" type="button"><i class="gw-more"></i></button>
              <div class="dropdown__content jsDropdownContent">
                <ul class="dropdown-menu__list">
                  <li class="dropdown-menu__item">
                    <button class="link dropdown-menu__link" type="button" (click)="showUpdateFindingTypeModal(findingType)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                  </li>
                  <li class="dropdown-menu__item">
                    <button class="link dropdown-menu__link" type="button" (click)="showRemoveModal(findingType)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                  </li>
                </ul>
              </div></span></div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
// Gutwin Shared Library
import { DialogService } from 'gutwin-shared';
// Models
import { Audit } from './../../../shared/models/audit.model';
import { AuditedArea } from './../../../shared/models/audited-area.model';
import { FindingsStats } from './../../../shared/models/findings-stats.model';
import { QuestionsStats } from './../../../shared/models/questions-stats.model';
// Services
import { AuditService, AuditSyncData } from './../../../shared/services/audit.service';
import { AuditOfflineService, AuditId } from './../../../shared/services/audit.offline.service';
import { AuditSummaryService } from './../../../shared/services/audit-summary.service';
import { PermissionService } from './../../../shared/services/permission.service';
import { UserService } from './../../../shared/services/user.service';

@Component({
  selector: 'gw-audit-summary',
  templateUrl: './audit-summary.component.html',
  styleUrls: ['./audit-summary.component.scss']
})
export class AuditSummaryComponent implements OnInit, OnDestroy {
  audit: Audit;
  auditedArea: AuditedArea;
  auditable: Audit | AuditedArea;
  questionsStats: QuestionsStats;
  findingsStats: FindingsStats;
  finishDialog = {
    header: '',
    content: '',
    cancel: '',
    confirm: ''
  };
  notify = {
    error: {
      connectionTitle: '',
      finishAudit: '',
      loadQuestionsStats: '',
      loadFindingsStats: ''
    },
    success: {
      finishAuditTitle: '',
      finishAuditText: ''
    }
  };
  translateSubscription: Subscription;
  paramsSubscription: Subscription;
  syncedAuditSubscription: Subscription;
  auditsIdsOfflineSubscription: Subscription;
  userSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auditService: AuditService,
    private auditOfflineService: AuditOfflineService,
    private auditSummaryService: AuditSummaryService,
    private dialogService: DialogService,
    private notificationsService: NotificationsService,
    private permissionService: PermissionService,
    private translateService: TranslateService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.initData();
    });
    this.syncedAuditSubscription = this.auditOfflineService.syncedAuditObservable.subscribe((auditSyncData: AuditSyncData) => {
      if (this.audit.id === auditSyncData.audit.id) {
        this.initData(auditSyncData);
      }
    });
    this.auditsIdsOfflineSubscription = this.auditOfflineService.auditsIdsOfflineObservable.subscribe(async(auditsIds) => {
      this.markAuditOffline(auditsIds);
      this.questionsStats = await this.getQuestionsStats(this.auditable, this.audit.offline && !this.audit.disabled);
      this.findingsStats = await this.getFindingsStats(this.auditable, this.audit.offline && !this.audit.disabled);
    });
    this.userSubscription = this.userService.storedUserObservable.subscribe(() => {
      this.markAuditDisabled();
    });
    this.fetchTranslation();
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
    this.paramsSubscription.unsubscribe();
    this.auditsIdsOfflineSubscription.unsubscribe();
    this.syncedAuditSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  async initData(auditSyncData?: AuditSyncData): Promise<void> {
    this.audit = auditSyncData ? auditSyncData.audit : this.route.snapshot.parent.data['audit'];
    if (this.route.snapshot.params['auditedAreaId']) {
      this.auditedArea = this.getAuditedArea(this.route.snapshot.params['auditedAreaId']);
      this.auditable = this.auditedArea;
    } else {
      this.auditedArea = undefined;
      this.auditable = this.audit;
    }
    this.auditable = this.auditedArea || this.audit;
    this.markAuditDisabled();

    await this.checkAuditOffline();
    this.questionsStats = await this.getQuestionsStats(this.auditable, this.audit.offline && !this.audit.disabled);
    this.findingsStats = await this.getFindingsStats(this.auditable, this.audit.offline && !this.audit.disabled);
  }

  async checkAuditOffline(): Promise<void> {
    const auditsIds = await this.auditOfflineService.getAuditsIdsFromOfflineStore();
    this.markAuditOffline(auditsIds);
  }

  markAuditOffline(auditsIds: Array<AuditId>): void {
    if (auditsIds && this.audit) {
      const auditIdOffline = _.find(auditsIds, {id: this.audit.id});
      if (auditIdOffline) {
        this.audit.offline = true;
        this.audit.offlineDate = auditIdOffline.date;
      }
    }
  }

  markAuditDisabled(): void {
    this.audit.disabled = !this.permissionService.canAuditBeManaged(this.audit, this.userService.storedUser);
  }

  getAuditedArea(auditedAreaId: string): AuditedArea {
    if (this.audit && this.audit.auditedAreas) {
      return _.find(this.audit.auditedAreas, { id: auditedAreaId });
    }
  }

  getQuestionsStats(auditable: Audit | AuditedArea, withRequests?: boolean): Promise<QuestionsStats> {
    return this.auditSummaryService.getQuestionsStats(auditable, withRequests)
      .catch(() => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.loadQuestionsStats);
        return undefined;
      });
  }

  getFindingsStats(auditable: Audit | AuditedArea, withRequests?: boolean): Promise<FindingsStats> {
    return this.auditSummaryService.getFindingsStats(auditable, withRequests)
      .catch(() => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.loadFindingsStats);
        return undefined;
      });
  }

  updateFindingsStats(): Promise<FindingsStats> {
    const auditable = this.auditedArea ? this.auditedArea : this.audit;

    return this.getFindingsStats(auditable, this.audit.offline && !this.audit.disabled)
      .then(stats => {
        this.findingsStats = stats;
        return stats;
      });
  }

  showFinishDialog(): void {
    this.dialogService.confirm(
      this.finishDialog.header,
      this.finishDialog.content,
      this.finishDialog.cancel,
      this.finishDialog.confirm
    )
      .then(() => this.finishAudit())
      .catch(() => {});
  }

  finishAudit(): void {
    this.auditService.updateAuditState(this.audit, 'finished')
      .then(audit => {
        this.router.navigate(['/audits']);
        this.notificationsService.success(this.notify.success.finishAuditTitle, this.notify.success.finishAuditText);
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.finishAudit);
      });
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService.get([
      'GLOBAL.ACTION.CANCEL',
      'GLOBAL.ERROR.CONNECTION',
      'AUDIT_SUMMARY.ACTION.FINISH_AUDIT',
      'AUDIT_SUMMARY.ERROR.FINISH_AUDIT',
      'AUDIT_SUMMARY.ERROR.LOAD_QUESTIONS_STATS',
      'AUDIT_SUMMARY.ERROR.LOAD_FINDINGS_STATS',
      'AUDIT_SUMMARY.SUCCESS.FINISH_AUDIT_TITLE',
      'AUDIT_SUMMARY.SUCCESS.FINISH_AUDIT_TEXT',
      'AUDIT_SUMMARY.FINISH_AUDIT_DIALOG.HEADER',
      'AUDIT_SUMMARY.FINISH_AUDIT_DIALOG.CONTENT'
    ])
    .subscribe((translation: any) => {
      this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
      this.notify.error.finishAudit = translation['AUDIT_SUMMARY.ERROR.FINISH_AUDIT'];
      this.notify.error.loadQuestionsStats = translation['AUDIT_SUMMARY.ERROR.LOAD_QUESTIONS_STATS'];
      this.notify.error.loadFindingsStats = translation['AUDIT_SUMMARY.ERROR.LOAD_FINDINGS_STATS'];
      this.notify.success.finishAuditText = translation['AUDIT_SUMMARY.SUCCESS.FINISH_AUDIT_TEXT'];
      this.finishDialog.header = translation['AUDIT_SUMMARY.FINISH_AUDIT_DIALOG.HEADER'];
      this.finishDialog.content = translation['AUDIT_SUMMARY.FINISH_AUDIT_DIALOG.CONTENT'];
      this.finishDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
      this.finishDialog.confirm = translation['AUDIT_SUMMARY.ACTION.FINISH_AUDIT'];
    });
  }
}

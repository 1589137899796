import { Role } from '../../../../models/role.model';

import { ApiRole } from '../interfaces/roles.interface';

import { convertSimpleEmployeeToGet } from './user.convert';

export const convertRoleToGet = (data: ApiRole): Role => {
  return new Role({
    id: data.id,
    name: data.name,
    color: data.color,
    usersCount: data.employeesCount,
    users: data?.employees?.map(employee => convertSimpleEmployeeToGet(employee))
  });
};

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
// Models
import { Audit } from './../../models/audit.model';
import { AuditedArea } from './../../models/audited-area.model';
import { Questionnaire } from './../../models/questionnaire.model';
import { Section } from './../../models/section.model';
// Services
import { SectionService } from './../../services/section.service';

@Component({
  selector: 'gw-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.scss'],
  exportAs: 'gwAddSection'
})
export class AddSectionComponent implements OnInit, OnDestroy {
  @Input() composable: Audit | AuditedArea | Questionnaire;
  @Input() section: Section;
  addSectionForm: FormGroup;
  addAnother = false;
  submitted = false;
  translateSubscription: Subscription;
  notify = {
    error: {
      connectionTitle: '',
      createSection: '',
      updateSection: ''
    },
    success: {
      createSectionTitle: '',
      createSectionText: '',
      updateSectionTitle: '',
      updateSectionText: ''
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private sectionService: SectionService
  ) { }

  ngOnInit() {
    this.translateSubscription = this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.CREATE_SECTION',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.UPDATE_SECTION',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_SECTION_TITLE',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_SECTION_TEXT',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_SECTION_TITLE',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_SECTION_TEXT'
      ])
      .subscribe((translation: any) => {
        this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.createSection = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.CREATE_SECTION'];
        this.notify.error.updateSection = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.UPDATE_SECTION'];
        this.notify.success.createSectionTitle = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_SECTION_TITLE'];
        this.notify.success.createSectionText = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_SECTION_TEXT'];
        this.notify.success.updateSectionTitle = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_SECTION_TITLE'];
        this.notify.success.updateSectionText = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_SECTION_TEXT'];
    });

    this.addSectionForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
    if (this.section) {
      this.setControlValue(this.section.name, 'name');
    }
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
  }

  resetForm(): void {
    if (this.section) {
      this.setControlValue(this.section.name, 'name');
    } else {
      this.setControlValue('', 'name');
    }
  }

  clearComponent(): void {
    this.section = undefined;
    this.resetForm();
  }

  setControlValue(data: any, key: string): void {
    this.addSectionForm.controls[key].setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  createSection(): Promise<any> {
    const sectionToCreate = new Section(this.addSectionForm.value);
    return this.sectionService.createSection(sectionToCreate, this.composable)
      .then(section => {
        section.custom = true;
        if (this.addAnother) {
          this.clearComponent();
        } else {
          this.resetForm();
        }
        this.submitted = false;
        this.notificationsService.success(
          this.notify.success.createSectionTitle,
          this.notify.success.createSectionText
        );
        return {
          status: 'create',
          section,
          addAnother: this.addAnother
        };
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.createSection);
        throw error;
      });
  }

  updateSection(): Promise<any> {
    const sectionToUpdate = new Section(this.addSectionForm.value);
    sectionToUpdate.id = this.section.id;
    return this.sectionService.updateSection(sectionToUpdate, this.composable)
      .then(section => {
        section.custom = true;
        if (this.addAnother) {
          this.clearComponent();
        } else {
          this.resetForm();
        }
        this.submitted = false;
        this.notificationsService.success(
          this.notify.success.updateSectionTitle,
          this.notify.success.updateSectionText
        );
        return {
          status: 'update',
          section,
          addAnother: this.addAnother
        };
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.updateSection);
        throw error;
      });
  }

  submitAddSectionForm(): Promise<any> {
    this.submitted = true;
    if (this.addSectionForm.valid) {
      if (this.section) {
        return this.updateSection();
      } else {
        return this.createSection();
      }
    } else {
      return new Promise((resolve, reject) => reject({ invalid: true }));
    }
  }
}

import { ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gw-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleSwitchComponent),
      multi: true
    }
  ],
  exportAs: 'gwToggleSwitch'
})
export class ToggleSwitchComponent implements ControlValueAccessor {
  @ViewChild('toggleSwitchControl', { static: true }) toggleSwitchControl: ElementRef;

  /**
   * Show and set left label of toggle switch
   */
  @Input() labelLeft?: string;
  /**
   * Show and set right label of toggle switch
   */
  @Input() labelRight?: string;
  /**
   * Show smaller toggle switch
   */
  @Input() small?: boolean;
  /**
   * Show light theme of toggle switch
   */
  @Input() light?: boolean;
  /**
   * Tab index of toggle switch
   */
  @Input() tabindex?: number;

  /**
   * Is toggle switch disabled
   */
  disabled: boolean;
  /**
   * Value of toggle switch
   */
  value: boolean;

  constructor(private changeDetector: ChangeDetectorRef) {}

  onChange: (value: boolean) => void = () => {};

  writeValue(value: boolean): void {
    if (value !== undefined) {
      this.value = value;
      this.changeDetector.markForCheck();
    }
  }

  registerOnChange(onChange: (value: boolean) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(): void {}

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  toggle(): void {
    if (!this.disabled) {
      this.value = !this.value;
      this.onChange(this.value);
    }
  }

  click(value: boolean): void {
    if (!this.disabled) {
      this.value = value;
      this.onChange(this.value);
    }
  }

  keydown(event: any): void {
    if (event.key === 'Enter') {
      this.toggle();
    }
  }

  focus(): void {
    this.toggleSwitchControl.nativeElement.focus();
  }
}

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { SimpleDropdownDirective } from '../../directives/simple-dropdown.directive';

import { PopoverService } from '../../services/popover.service';
import { WindowRefService } from '../../services/window-ref.service';

@Component({
  selector: 'gw-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  exportAs: 'gwPopover'
})
export class PopoverComponent implements OnInit, OnDestroy {
  @ViewChild('dropdownPopover', { static: true }) dropdownPopover: SimpleDropdownDirective;

  @Input() id: string;
  @Input() disabled: boolean;
  @Input() action: 'save' | 'add' = 'save';
  @Input() saving?: boolean;
  @Input() top?: number;
  @Input() alignRight: boolean;
  @Input() minHorizontalSpace = 160;
  @Input() customContent = false;
  @Input() handleOffclick = false;
  @Input() overElement = true;
  @Input() aboveElement = false;
  @Input() client = false;
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();

  alignTop = false;
  minPopoverHeight = 250;
  position: DOMRect;

  constructor(
    private popoverService: PopoverService,
    private elementRef: ElementRef,
    private windowRefService: WindowRefService
  ) {}

  ngOnInit(): void {
    this.popoverService.add(this);
  }

  ngOnDestroy(): void {
    this.popoverService.remove(this.id);
  }

  setPopoverAlign(): void {
    this.position = this.elementRef.nativeElement.getBoundingClientRect();
    this.setHorizontalAlign();
    this.setVerticalAlign();
  }

  setHorizontalAlign(): void {
    if (this.alignRight && !this.spaceOnLeft()) {
      this.alignLeft();
    } else if (!this.alignRight && !this.spaceOnRight()) {
      this.alignRight = true;
    }
  }

  setVerticalAlign(): void {
    this.alignTop = this.spaceOnTop() && !this.spaceOnBottom();
  }

  spaceOnRight(): boolean {
    return this.position.right + this.minHorizontalSpace < this.windowRefService.nativeWindow.innerWidth;
  }

  spaceOnLeft(): boolean {
    return this.position.left > this.minHorizontalSpace;
  }

  spaceOnBottom(): boolean {
    return this.windowRefService.nativeWindow.innerHeight - this.position.bottom > this.minPopoverHeight;
  }

  spaceOnTop(): boolean {
    return this.position.top > this.minPopoverHeight;
  }

  alignLeft(): void {
    this.alignRight = false;
  }

  open(): void {
    this.dropdownPopover.openDropdown();
  }

  close(): void {
    this.dropdownPopover.closeDropdown();
  }

  onOpen(): void {
    this.setPopoverAlign();
    this.opened.emit();
  }

  onClose(): void {
    this.closed.emit();
  }
}

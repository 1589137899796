import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutGuard implements CanActivate {
  constructor(private loginService: LoginService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const redirectRoute = route.data.redirectURL || 'dashboard';
    if (!this.loginService.isLoggedIn()) {
      return true;
    }

    this.router.navigate([redirectRoute]);
    return false;
  }
}

import { Injectable } from '@angular/core';

import { BehaviorSubject, Subject } from 'rxjs';

import { UserSettingsService } from './user-settings.service';

import { UsersSettings } from '../enums/users-settings.enum';

@Injectable({
  providedIn: 'root'
})
export class SidePanelService {
  _opened = true;

  get opened(): boolean {
    const currentUserOpened = this.userSettingsService?.currentUserSettings?.sidePanelOpened;
    if (currentUserOpened !== undefined) {
      this._opened = currentUserOpened;
    }
    return this._opened;
  }

  set opened(opened: boolean) {
    this._opened = opened;
    this.userSettingsService?.setCurrentUserSettings(UsersSettings.sidePanelOpened, opened);
    this.openedSource.next(opened);
  }

  openedSource = new Subject<boolean>();
  openedObservable = this.openedSource.asObservable();

  _mobileWidth?: number;
  get mobileWidth(): number {
    return this._mobileWidth;
  }

  set mobileWidth(mobileWidth: number) {
    this._mobileWidth = mobileWidth;
    this.mobileWidthSource.next(mobileWidth);
  }

  mobileWidthSource = new Subject<number>();
  mobileWidthObservable = this.mobileWidthSource.asObservable();

  _widthLimit?: number;
  get widthLimit(): number {
    return this._widthLimit;
  }

  set widthLimit(widthLimit: number) {
    this._widthLimit = widthLimit;
    this.widthLimitSource.next(widthLimit);
  }

  widthLimitSource = new Subject<number>();
  widthLimitObservable = this.widthLimitSource.asObservable();

  _width = 300;
  get width(): number {
    return this._width;
  }

  set width(width: number) {
    this._width = width;
    this.widthSource.next(width);
  }

  widthSource = new Subject<number>();
  widthObservable = this.widthSource.asObservable();

  _maxWidth = 900;
  get maxWidth(): number {
    return this._maxWidth;
  }

  set maxWidth(maxWidth: number) {
    this._maxWidth = maxWidth;
    this.maxWidthSource.next(maxWidth);
  }

  maxWidthSource = new Subject<number>();
  maxWidthObservable = this.maxWidthSource.asObservable();

  disabledAnimation$ = new BehaviorSubject<boolean>(false);

  constructor(private userSettingsService: UserSettingsService) {}

  closeWithoutAnimation(): void {
    this.disabledAnimation$.next(true);
    this.opened = false;
  }

  enableAnimation(): void {
    this.disabledAnimation$.next(false);
  }
}


<div class="chart__content">
  <div class="chart__svg">
    <div class="circle-chart" #circleChart=""></div>
    <div class="chart-svg__summary">{{ totalCount }}<small>{{ 'AUDIT_SUMMARY.TOTAL_QUESTIONS' | translate }}</small></div>
  </div>
  <div class="chart__legend">
    <ul class="chart-legend__list" [style.column-count]="columns">
      <li class="chart-legend__item" *ngFor="let rating of chartDataset"><span class="chart-legend__badge" [style.background-color]="rating.color"></span>
        <ng-container *ngIf="totalCount">{{ rating.count / totalCount * 100 | number: '1.0-0' }}%</ng-container>
        <ng-container *ngIf="!totalCount">0%</ng-container><span class="chart-legend__label">{{ rating.count }} {{ rating.name }}</span>
      </li>
    </ul>
  </div>
</div>

<h1 class="finding-solved__heading"><i class="gw-check check-icon"></i>{{ 'FINDING_VIEW.SOLVED.HEADER' | translate }}</h1>
<div class="finding-solved__content">
  <ng-container *ngIf="findingSolvingData?.note?.length &gt; 0">
    <h4>{{ 'FINDING_VIEW.SOLVED.SOLVING_NOTE' | translate }}</h4>
    <p class="finding-solved__paragraph">{{ findingSolvingData?.note }}</p>
  </ng-container>
  <div class="finding-solved__attachements" *ngIf="findingSolvingData?.attachments?.length">
    <ng-container *ngFor="let attachment of attachmentsGroups.images">
      <gw-attachment class="attachment-container finding-solved__attachement finding-solved__attachement--image" [attachment]="attachment" [gallery]="attachmentsGroups.images" [viewOnly]="true"></gw-attachment>
    </ng-container>
    <ng-container *ngFor="let attachment of attachmentsGroups.others">
      <gw-attachment class="attachment-container finding-solved__attachement" [attachment]="attachment" [viewOnly]="true"></gw-attachment>
    </ng-container>
  </div>
  <p class="finding-solved__paragraph"><i class="gw-employee finding-icon"></i><strong>{{ 'FINDING_VIEW.SOLVED.SOLVED_BY' | translate }}:&nbsp;</strong>{{ findingSolvingData?.solvedBy }},
    {{ findingSolvingData?.solvedAt | dateFormat: 'D MMMM YYYY' }}
  </p>
</div>
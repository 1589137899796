import { ApiComplianceRatingsProgress } from '../interfaces/compliance-rating-progress.interface';
import { RatingProgressData } from '../interfaces/rating-progress-data.interface';
import { ApiRelevanceRatingsProgress } from '../interfaces/relevance-rating-progress.interface';

import { ComplianceRatingColor } from '../enums/compliance-rating-color.enum';
import { ComplianceRatingId } from '../enums/compliance-rating-id.enum';
import { RelevanceRatingColor } from '../enums/relevance-rating-color.enum';
import { RelevanceRatingId } from '../enums/relevance-rating-id.enum';

export const convertComplianceRatingsProgress = (
  complianceRatingsProgress: ApiComplianceRatingsProgress
): Array<RatingProgressData<ComplianceRatingId>> => {
  return [
    convertComplianceRatingProgress('compliantRequirementsCount', complianceRatingsProgress),
    convertComplianceRatingProgress('partlyCompliantRequirementsCount', complianceRatingsProgress),
    convertComplianceRatingProgress('notCompliantRequirementsCount', complianceRatingsProgress),
    convertComplianceRatingProgress('notRatedRequirementsCount', complianceRatingsProgress)
  ];
};

export const convertComplianceRatingProgress = (
  complianceRatingProgressId: keyof ApiComplianceRatingsProgress,
  complianceRatingsProgress: ApiComplianceRatingsProgress
): RatingProgressData<ComplianceRatingId> => {
  const complianceRatingProgressIds: Partial<Record<keyof ApiComplianceRatingsProgress, ComplianceRatingId>> = {
    compliantRequirementsCount: ComplianceRatingId.compliant,
    notCompliantRequirementsCount: ComplianceRatingId.notCompliant,
    notRatedRequirementsCount: ComplianceRatingId.notRated,
    partlyCompliantRequirementsCount: ComplianceRatingId.partlyCompliant
  };
  const complianceRatingId = complianceRatingProgressIds[complianceRatingProgressId];
  return {
    id: complianceRatingId,
    value: complianceRatingsProgress ? complianceRatingsProgress[complianceRatingProgressId] : 0,
    color: getComplianceColorBasedOnRating(complianceRatingId)
  };
};

export const getComplianceColorBasedOnRating = (rating: ComplianceRatingId): string => {
  return {
    [ComplianceRatingId.compliant]: ComplianceRatingColor.compliant,
    [ComplianceRatingId.partlyCompliant]: ComplianceRatingColor.partlyCompliant,
    [ComplianceRatingId.notCompliant]: ComplianceRatingColor.notCompliant,
    [ComplianceRatingId.notRated]: ComplianceRatingColor.notRated
  }[rating];
};

export const convertRelevanceRatingsProgress = (
  relevanceRatingsProgress: ApiRelevanceRatingsProgress
): Array<RatingProgressData<RelevanceRatingId>> => {
  return [
    convertRelevanceRatingProgress('relevantRequirementsCount', relevanceRatingsProgress),
    convertRelevanceRatingProgress('notRelevantRequirementsCount', relevanceRatingsProgress),
    convertRelevanceRatingProgress('toClarifyRequirementsCount', relevanceRatingsProgress),
    convertRelevanceRatingProgress('toMonitorRequirementsCount', relevanceRatingsProgress),
    convertRelevanceRatingProgress('notRatedRequirementsCount', relevanceRatingsProgress)
  ];
};

export const convertRelevanceRatingProgress = (
  relevanceRatingProgressId: keyof ApiRelevanceRatingsProgress,
  relevanceRatingsProgress: ApiRelevanceRatingsProgress
): RatingProgressData<RelevanceRatingId> => {
  const relevanceRatingProgressIds: Partial<Record<keyof ApiRelevanceRatingsProgress, RelevanceRatingId>> = {
    relevantRequirementsCount: RelevanceRatingId.relevant,
    notRelevantRequirementsCount: RelevanceRatingId.notRelevant,
    notRatedRequirementsCount: RelevanceRatingId.notRated,
    toClarifyRequirementsCount: RelevanceRatingId.toClarify,
    toMonitorRequirementsCount: RelevanceRatingId.toMonitor
  };
  const relevanceRatingId = relevanceRatingProgressIds[relevanceRatingProgressId];
  return {
    id: relevanceRatingId,
    value: relevanceRatingsProgress ? relevanceRatingsProgress[relevanceRatingProgressId] : 0,
    color: getRelevanceColorBasedOnRating(relevanceRatingId)
  };
};

export const getRelevanceColorBasedOnRating = (rating: RelevanceRatingId): string => {
  return {
    [RelevanceRatingId.relevant]: RelevanceRatingColor.relevant,
    [RelevanceRatingId.notRelevant]: RelevanceRatingColor.notRelevant,
    [RelevanceRatingId.notRated]: RelevanceRatingColor.notRated,
    [RelevanceRatingId.toClarify]: RelevanceRatingColor.toClarify,
    [RelevanceRatingId.toMonitor]: RelevanceRatingColor.toMonitor
  }[rating];
};

export class AuditsAmount {
  totalInProgress: number;
  filteredInProgress: number;
  totalDrafts: number;
  filteredDrafts: number;
  totalFinished: number;
  filteredFinished: number;

  constructor(auditsAmount?: any) {
    this.totalInProgress = auditsAmount ? auditsAmount.totalInProgress || 0 : 0;
    this.filteredInProgress = auditsAmount ? auditsAmount.filteredInProgress || 0 : 0;
    this.totalDrafts = auditsAmount ? auditsAmount.totalDrafts || 0 : 0;
    this.filteredDrafts = auditsAmount ? auditsAmount.filteredDrafts || 0 : 0;
    this.totalFinished = auditsAmount ? auditsAmount.totalFinished || 0 : 0;
    this.filteredFinished = auditsAmount ? auditsAmount.filteredFinished || 0 : 0;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { Employee } from '../models/employee.model';

import { displayUserName } from '../utils/user.util';

@Pipe({
  name: 'displayUserName',
  pure: true
})
export class DisplayUserNamePipe implements PipeTransform {
  transform(user: Employee): string {
    return displayUserName(user);
  }
}

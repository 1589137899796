import { Pipe, PipeTransform } from '@angular/core';

import { Country } from '../models/country.model';

@Pipe({
  name: 'getCountriesAbbreviations'
})
export class GetCountriesAbbreviationsPipe implements PipeTransform {
  transform(value: Array<Country>): Array<string> {
    if (value) {
      return value.map(country => country.abbreviation);
    } else {
      return [];
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { TreeFilterOption } from '../../models/tree-filter-option.model';

import { ListTreeFilter, ListTreeFlatFilter } from '../../interfaces/filter.interface';

import { ExpandBoxComponent } from '../../components/expand-box/expand-box.component';

import { TreeService } from '../../services/tree.service';

import { getSelectedNodeIds } from '../../utils/tree.util';

@Component({
  selector: 'gw-tree-filter',
  templateUrl: './tree-filter.component.html',
  styleUrls: ['./tree-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeFilterComponent implements OnInit {
  @ViewChild('expandBox', { static: true }) expandBox: ExpandBoxComponent;
  @Input() label: string;
  @Input() isMultiSelect?: boolean;
  @Input() subnodesButtonLabel?: string;
  @Input() multiSelectLabel?: string;
  @Input() isParentFullyClosed?: boolean;
  @Input() options: Array<TreeFilterOption>;
  @Input() isFilterArray: boolean;
  @Input() set filter(filter: ListTreeFilter | ListTreeFlatFilter) {
    this._filter = filter ?? {
      value: undefined,
      includeSubnodes: false
    };
    this.initOptions();
  }
  get filter(): ListTreeFilter | ListTreeFlatFilter {
    return this._filter;
  }
  _filter: ListTreeFilter | ListTreeFlatFilter = {
    value: undefined,
    includeSubnodes: false
  };

  @Output() updateFilter = new EventEmitter<ListTreeFilter | ListTreeFlatFilter>();

  selectedOptionId?: string;

  constructor(private treeService: TreeService, private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {}

  initOptions(): void {
    if (this.isMultiSelect) {
      this.initMultiSelectOptions();
    } else {
      this.initSingleSelectOptions();
    }
    this.changeDetector.detectChanges();
  }

  initMultiSelectOptions(): void {
    this.options = this.treeService.markSelectedNodes(this.options, this.filter?.value);
    this.selectedOptionId = undefined;
  }

  initSingleSelectOptions(): void {
    this.options = this.treeService.clearNodes(this.options, ['isSelected']);
    this.selectedOptionId =
      this.filter?.value && (this.isFilterArray ? this.filter.value[0] : Object.keys(this.filter.value)[0]);
  }

  updateSingleSelectedOption(selectedOptionId: string): void {
    const value = this.isFilterArray ? [selectedOptionId] : { [selectedOptionId]: [] };

    this.updateFilter.emit(({
      value,
      includeSubnodes: this.filter?.includeSubnodes
    } as unknown) as ListTreeFilter | ListTreeFlatFilter);
    this.changeDetector.detectChanges();
  }

  updateMultiSelectedOptions(options: Array<TreeFilterOption>): void {
    const selectedNodesIds = getSelectedNodeIds(options, 'isSelected') as {
      [key: string]: Array<string>;
    };
    const value = this.isFilterArray ? Object.keys(selectedNodesIds) : selectedNodesIds;
    this.updateFilter.emit(({
      value,
      includeSubnodes: this.filter?.includeSubnodes
    } as unknown) as ListTreeFilter | ListTreeFlatFilter);
  }

  updateSubnodesFilter(includeSubnodes: boolean): void {
    this.updateFilter.emit({
      ...((this.filter as unknown) as ListTreeFilter | ListTreeFlatFilter),
      includeSubnodes
    });
  }
}

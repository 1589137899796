
<gw-expand-box class="audited-area-form" #auditedAreaExpandBox="gwExpandBox" hasShadow="always" [opened]="true">
  <ng-container expand-box-header="expand-box-header">
    <div class="copy-audit__header" (click)="auditedAreaExpandBox.toggle()">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREA' | translate }} {{ formIndex + 1 }}<i class="gw-angle--down h__icon--after expand-box__toggle-icon"></i></div>
  </ng-container>
  <ng-container expand-box-content="expand-box-content">
    <div class="copy-audit__fieldset" [formGroup]="auditedArea">
      <div class="row audited-area-recurring__row" *ngIf="isRecurring &amp;&amp; recurringType === 'monthly'">
        <div class="recurring__type columns small-12 large-6" [class.recurring__type--enabled]="isSelectedRecurringMonthDayType('dayOfMonth')">
          <label class="label label--without-margin">
            <gw-radio-button [tabindex]="1 | calcTabIndex : isRecurring : formIndex" [value]="'dayOfMonth'" [ngModel]="auditedArea.get('recurringMonthDayType').value" [ngModelOptions]="{ standalone: true }" (ngModelChange)="setRecurringMonthDayType('dayOfMonth')"></gw-radio-button><span class="recurring__type-label--very-small">&nbsp; {{ 'AUDIT_CREATION.FORM.LABEL.ON' | translate }}</span>
            <input class="input input--small recurring__input" [tabindex]="2 | calcTabIndex : isRecurring : formIndex " type="number" id="dayOfMonth" name="dayOfMonth" formControlName="dayOfMonth"/><span class="recurring__type-label">{{ 'AUDIT_CREATION.FORM.LABEL.DAY_OF_MONTH' | translate }}</span>
          </label>
          <div class="recurring__errors form__field--error">
            <div class="recurring__error">
              <div class="form-field__error" *ngIf="isFieldEmpty(auditedArea.get('dayOfMonth'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.DAY_OF_MONTH_EMPTY' | translate }}</div>
            </div>
            <div class="recurring__error">
              <div class="form-field__error" *ngIf="isFieldMinNumberInvalid(auditedArea.get('dayOfMonth'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.DAY_OF_MONTH_MIN_NUMBER' | translate }}</div>
            </div>
            <div class="recurring__error">
              <div class="form-field__error" *ngIf="isFieldMaxNumberInvalid(auditedArea.get('dayOfMonth'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.DAY_OF_MONTH_MAX_NUMBER' | translate }}</div>
            </div>
          </div>
        </div>
        <div class="recurring__type columns small-12 large-6" [class.recurring__type--enabled]="isSelectedRecurringMonthDayType('weekDayOfMonth')">
          <label class="label label--without-margin" gwStopPropagation="gwStopPropagation">
            <gw-radio-button [tabindex]="3 | calcTabIndex : isRecurring : formIndex" [value]="'weekDayOfMonth'" [ngModel]="auditedArea.get('recurringMonthDayType').value" [ngModelOptions]="{ standalone: true }" (ngModelChange)="setRecurringMonthDayType('weekDayOfMonth')"></gw-radio-button><span class="recurring__type-label--very-small">&nbsp; {{ 'AUDIT_CREATION.FORM.LABEL.ON' | translate }}</span>
            <input class="input input--small recurring__input" type="number" id="numberWeekOfMonth" name="numberWeekOfMonth" formControlName="numberWeekOfMonth" [tabindex]="4 | calcTabIndex : isRecurring : formIndex "/>
            <gw-select class="recurring__select" [tabindex]="5 | calcTabIndex : isRecurring : formIndex" [small]="true" [data]="weekDays" [display]="['name']" [uniqueKey]="'id'" [ngModel]="auditedArea.get('weekDayOfMonth').value" [ngModelOptions]="{ standalone: true }" (ngModelChange)="setRecurringWeekDay($event)"></gw-select><span class="recurring__type-label">&nbsp; {{ 'AUDIT_CREATION.FORM.LABEL.OF_MONTH' | translate }}</span>
          </label>
          <div class="recurring__errors form__field--error">
            <div class="recurring__error">
              <div class="form-field__error" *ngIf="isFieldEmpty(auditedArea.get('numberWeekOfMonth'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.NUMBER_WEEK_OF_MONTH_EMPTY' | translate }}</div>
            </div>
            <div class="recurring__error">
              <div class="form-field__error" *ngIf="isFieldMinNumberInvalid(auditedArea.get('numberWeekOfMonth'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.NUMBER_WEEK_OF_MONTH_MIN_NUMBER' | translate }}</div>
            </div>
            <div class="recurring__error">
              <div class="form-field__error" *ngIf="isFieldMaxNumberInvalid(auditedArea.get('numberWeekOfMonth'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.NUMBER_WEEK_OF_MONTH_MAX_NUMBER' | translate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row audited-area-recurring__row" *ngIf="isRecurring &amp;&amp; recurringType === 'weekly'">
        <div class="recurring__type recurring__type--enabled columns small-12">
          <label class="label"><span class="recurring__type-label--very-small">{{ 'AUDIT_CREATION.FORM.LABEL.ON' | translate }}</span>
            <gw-select class="recurring__select" gwStopPropagation="gwStopPropagation" [tabindex]="1 | calcTabIndex : isRecurring : formIndex" [small]="true" [data]="weekDays" [display]="['name']" [uniqueKey]="'id'" [ngModel]="auditedArea.get('weeklyDayOfMonth').value" [ngModelOptions]="{ standalone: true }" (ngModelChange)="setRecurringWeeklyDay($event)"></gw-select>
          </label>
        </div>
      </div>
      <div class="row copy-audit__fields">
        <div class="columns small-12 medium-6 form__field copy-audit__field" [class.form__field--error]="isFieldInvalid(auditedArea.get('startTime')) || !!auditedArea.errors?.incorrectDateOrder &amp;&amp; submitted">
          <label class="label" for="startTime" (click)="firstDatePicker.focusOnDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.START_DATE' | translate }}</label>
          <div class="form-field__error">
            <ng-container *ngIf="auditedArea.get('startTime').errors?.required">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.START_DATE_EMPTY' | translate }}</ng-container>
            <ng-container *ngIf="!auditedArea.get('startTime').errors?.required">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.WRONG_DATE' | translate }}</ng-container>
          </div>
          <div class="dropdown dropdown--date-time-picker" gwSimpleDropdown="gwSimpleDropdown" #firstDatePicker="simpleDropdown" [tabindex]="6 | calcTabIndex : isRecurring : formIndex : 1" [class.dropdown--opened]="firstDatePicker.opened" [class.dropdown--closed]="!firstDatePicker.opened &amp;&amp; !firstDatePicker.firstDrop" (keydown)="keyDownFirstDatePicker($event)"><span class="input" [class.input--placeholder]="!auditedArea.get('startTime').value">{{ auditedArea.get('startTime').value | dateFormat: 'D MMMM YYYY, HH:mm' }}<span *ngIf="!auditedArea.get('startTime').value">{{ 'AUDIT_CREATION.FORM.PLACEHOLDER.START_DATE' | translate }}</span></span>
            <div class="dropdown__content jsDropdownContent" [class.dropdown__content--horizontal-overflow]="firstDatePicker.isRightOverflow">
              <gw-date-time-picker *ngIf="firstDatePicker.opened" #startTimePicker="gwDateTimePicker" [date]="auditedArea.get('startTime').value" [label]="'AUDIT_CREATION.FORM.LABEL.START_DATE' | translate" (applyDate)="applyStartTime($event)"></gw-date-time-picker>
            </div>
          </div>
        </div>
        <div class="columns small-12 medium-6 form__field copy-audit__field" [class.form__field--error]="isFieldInvalid(auditedArea.get('endTime')) || !!auditedArea.errors?.incorrectDateOrder &amp;&amp; submitted">
          <label class="label" for="endTime" (click)="secondDatePicker.focusOnDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.END_DATE' | translate }}</label>
          <div class="form-field__error">
            <ng-container *ngIf="auditedArea.get('endTime').errors?.required">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.END_DATE_EMPTY' | translate }}</ng-container>
            <ng-container *ngIf="!auditedArea.get('endTime').errors?.required">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.WRONG_DATE' | translate }}</ng-container>
          </div>
          <div class="dropdown dropdown--date-time-picker" gwSimpleDropdown="gwSimpleDropdown" #secondDatePicker="simpleDropdown" [tabindex]="7 | calcTabIndex : isRecurring : formIndex : 2" [class.dropdown--opened]="secondDatePicker.opened" [class.dropdown--closed]="!secondDatePicker.opened &amp;&amp; !secondDatePicker.firstDrop" (keydown)="keyDownSecondDatePicker($event)"><span class="input" [class.input--placeholder]="!auditedArea.get('endTime').value">{{ auditedArea.get('endTime').value | dateFormat: 'D MMMM YYYY, HH:mm' }}<span *ngIf="!auditedArea.get('endTime').value">{{ 'AUDIT_CREATION.FORM.PLACEHOLDER.END_DATE' | translate }}</span></span>
            <div class="dropdown__content jsDropdownContent" [class.dropdown__content--horizontal-overflow]="secondDatePicker.isRightOverflow">
              <gw-date-time-picker *ngIf="secondDatePicker.opened" #endTimePicker="gwDateTimePicker" [date]="auditedArea.get('endTime').value" [label]="'AUDIT_CREATION.FORM.LABEL.END_DATE' | translate" (applyDate)="applyEndTime($event)"></gw-date-time-picker>
            </div>
          </div>
        </div>
        <div class="columns small-12 medium-6 form__field copy-audit__field" [class.form__field--error]="isFieldInvalid(auditedArea.get('facility'))">
          <label class="label" for="facility" (click)="facilityElement.focus()">{{ 'AUDIT_CREATION.FORM.LABEL.AUDITED_AREA' | translate }}</label>
          <div class="form-field__error">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.AUDITED_AREA_EMPTY' | translate }}</div>
          <div class="copy-audit__input-container" #facilityElement="" [tabindex]="8 | calcTabIndex : isRecurring : formIndex : 3" (click)="showFacilityModal()" (keydown)="keyDownFacilityControl($event)"><span class="input copy-audit__input--with-action" [class.copy-audit__input--placeholder]="!auditedArea.get('facility').value">{{ auditedArea.get('facility').value.name }}
              <ng-container *ngIf="!auditedArea.get('facility').value">{{ 'AUDIT_CREATION.FORM.PLACEHOLDER.AUDITED_AREA' | translate }}</ng-container></span>
            <button class="button--secondary button--small copy-audit__form-action" *ngIf="auditedArea.get('facility').value" type="button">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ACTION.AUDITED_AREA_EDIT' | translate }}</button>
          </div>
        </div>
        <div class="columns small-12 medium-6 form__field copy-audit__field">
          <label class="label" for="location">{{ 'AUDIT_CREATION.FORM.LABEL.LOCATION' | translate }}</label>
          <input class="input" placeholder="{{ 'AUDIT_CREATION.FORM.PLACEHOLDER.LOCATION' | translate }}" type="text" id="location" name="location" [tabindex]="9 | calcTabIndex : isRecurring : formIndex : 4" [formControl]="auditedArea.get('location')"/>
        </div>
        <div class="columns small-12 medium-6 form__field copy-audit__field" [class.form__field--error]="isFieldInvalid(auditedArea.get('auditor'))">
          <label class="label" (click)="auditedAreaAuditorsSelect.focusDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.AUDITOR' | translate }}</label>
          <div class="form-field__error">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.AUDITOR_EMPTY' | translate }}</div>
          <gw-multiselect #auditedAreaAuditorsSelect="gwMultiselect" formControlName="auditor" uniqueKey="id" [tabindex]="10 | calcTabIndex : isRecurring : formIndex : 5" [placeholder]="'AUDIT_CREATION.FORM.PLACEHOLDER.AUDITOR' | translate" [data]="auditors" [display]="['lastname', 'name']" [splitter]="', '" [separator]="'; '" (focus)="auditedAreaAuditorsSelect.focusDropdown()"></gw-multiselect>
        </div>
        <div class="columns small-12 medium-6 form__field copy-audit__field" [class.form__field--error]="isFieldInvalid(auditedArea.get('auditee'))">
          <label class="label" (click)="auditeeAreaAuditorsSelect.focusDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.AUDITEES' | translate }}</label>
          <div class="form-field__error">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.AUDITEES_EMPTY' | translate }}</div>
          <gw-multiselect #auditeeAreaAuditorsSelect="gwMultiselect" formControlName="auditee" uniqueKey="id" [tabindex]="11 | calcTabIndex : isRecurring : formIndex : 6" [placeholder]="'AUDIT_CREATION.FORM.PLACEHOLDER.AUDITEES' | translate" [data]="employees" [display]="['lastname', 'name']" [splitter]="', '" [separator]="'; '" (focus)="auditeeAreaAuditorsSelect.focusDropdown()"></gw-multiselect>
        </div>
      </div>
    </div>
  </ng-container>
</gw-expand-box>
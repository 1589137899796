import gql from 'graphql-tag';

import { FileFragment } from './file.fragment';

export const UnitTypeFragment = gql`
  fragment UnitTypeFragment on UnitType {
    icon {
      ...FileFragment
    }
    id
    name
  }
  ${FileFragment}
`;

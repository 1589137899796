export enum FilterType {
  complianceRatings = 'complianceRatings',
  consultants = 'consultants',
  dateOfIssue = 'dateOfIssue',
  deadline = 'deadline',
  effectiveUntil = 'effectiveUntil',
  extraFields = 'extraFields',
  hideDone = 'hideDone',
  hideNotRelevant = 'hideNotRelevant',
  inactive = 'inactive',
  includeSubnodes = 'includeSubnodes',
  includeSubunits = 'includeSubunits',
  intervals = 'intervals',
  issuedOn = 'issuedOn',
  laws = 'laws',
  legalCategories = 'legalCategories',
  legalOrigins = 'legalOrigins',
  products = 'products',
  publication = 'publication',
  ratings = 'ratings',
  relevanceRatings = 'relevanceRatings',
  search = 'search',
  statuses = 'statuses',
  submittedOn = 'submittedOn',
  units = 'units',
  validFrom = 'validFrom',
  validIndefinitely = 'validIndefinitely'
}

// Gutwin Shared Library
import { Attachment } from 'gutwin-shared';
// Models
import { Finding } from './finding.model';

export const QUESTION_TYPE = 'Question';

export class Question {
  id: string;
  content: string;
  description: string;
  checked: boolean;
  ratingId: string;
  ratingIdOffline: string;
  findings: Array<Finding>;
  attachments: Array<Attachment>;
  archived: boolean;
  custom: boolean;
  sectionId: string;
  added: boolean;
  addedToParent: boolean;
  hasFindings: boolean;
  hasFindingsOffline: { all: boolean, toRemove: boolean };
  responseMediaId: string;
  responseMediaOffline: boolean;
  offline: boolean;

  _newContent: string;
  _type = QUESTION_TYPE;

  constructor(question: any) {
    this.id = question.id;
    this.content = question.content;
    this.description = question.description;
    this.checked = question.checked;
    this.ratingId = question.ratingId;
    this.ratingIdOffline = question.ratingIdOffline;
    this.findings = question.findings;
    this.attachments = question.attachments;
    this.archived = question.archived;
    this.custom = question.custom;
    this.sectionId = question.sectionId;
    this.added = question.added;
    this.addedToParent = question.addedToParent;
    this.hasFindings = question.hasFindings;
    this.responseMediaId = question.responseMediaId;
    this.responseMediaOffline = question.responseMediaOffline;
    this.hasFindingsOffline = question.hasFindingsOffline || { all: false, toRemove: false };
    this.offline = question.offline;
  }

  get newContent(): string {
    return this._newContent !== undefined ? this._newContent : this.content;
  }

  set newContent(value: string) {
    this._newContent = value;
  }

  isOffline(): boolean {
    return !!this.ratingIdOffline || this.responseMediaOffline || this.hasFindingsOffline.all;
  }
}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
// Gutwin Shared Library
import { Employee } from 'gutwin-shared';
// Providers
import { UserService } from './../services/user.service';
import { StorageModuleService } from './../services/storage-module.service';

@Injectable()
export class CurrentUserResolve implements Resolve<any> {
  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private storageService: StorageModuleService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Promise<Employee> {
    return this.userService.getCurrentUser()
      .then((data: Employee) => {
        return data;
      })
      .catch(error => {
        this.fetchErrorTranslation()
          .then((translation: any) => {
            this.notificationsService.error(translation.title, translation.text);
          });
        return undefined;
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ERROR.LOAD_CURRENT_USER'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['GLOBAL.ERROR.LOAD_CURRENT_USER']
        };
      });
  }
}

import { Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment';
// Models
import { Audit } from './../../../shared/models/audit.model';
import { AuditedArea } from './../../../shared/models/audited-area.model';

@Component({
  selector: 'gw-choose-audited-area',
  templateUrl: './choose-audited-area.component.html',
  styleUrls: ['./choose-audited-area.component.scss'],
  exportAs: 'gwChooseAuditedArea'
})
export class ChooseAuditedAreaComponent implements OnInit, OnChanges {
  @Input() audit: Audit;
  @Input() currentAuditedArea: AuditedArea;
  @Output() chooseAuditedArea = new EventEmitter();
  auditedAreaGroups = new Array<Array<AuditedArea>>();

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init();
  }

  init(): void {
    if (this.audit) {
      this.auditedAreaGroups = this.groupByDate(this.filterAuditedArea(this.audit.auditedAreas));
    }
  }

  filterAuditedArea(auditedAreas: Array<AuditedArea>): Array<AuditedArea> {
    if (this.currentAuditedArea) {
      return auditedAreas.filter(auditedArea => {
        return auditedArea.id !== this.currentAuditedArea.id;
      });
    } else {
      return auditedAreas;
    }
  }

  groupByDate(auditedAreas: Array<AuditedArea>): Array<Array<AuditedArea>> {
    const groupsObject = _.values(_.groupBy(auditedAreas, (auditedArea: AuditedArea) => {
      return moment(auditedArea.startTime).format('YYYY-MM-DD'); // ISO format;
    }));
    return groupsObject;
  }

  goToAuditedArea(auditedArea: AuditedArea): void {
    this.chooseAuditedArea.emit(auditedArea);
  }
}

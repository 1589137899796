export const NOT_ANSWERED_ID = 'NOT_ANSWERED';
export const NOT_ANSWERED_COLOR = '#9932CC';

export interface QuestionStatsRating {
  id: string;
  name: string;
  count: number;
  color: string;
}

export class QuestionsStats {
  totalQuestions: number;
  ratings: Array<QuestionStatsRating>;
  offline?: boolean;

  constructor(questionsStats?: QuestionsStats) {
    this.totalQuestions = questionsStats ? questionsStats.totalQuestions : 0;
    this.ratings = questionsStats ? questionsStats.ratings : [];
    this.offline = questionsStats ? questionsStats.offline : false;
  }
}

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { TreeFilterOption } from '../../../models/tree-filter-option.model';

import { ExpandBoxComponent } from '../../expand-box/expand-box.component';

@Component({
  selector: 'gw-multiselect-filter',
  templateUrl: './multiselect-filter.component.html',
  styleUrls: ['./multiselect-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiselectFilterComponent {
  @ViewChild('sectionExpandBox', { static: true }) expandBox: ExpandBoxComponent;
  @ViewChild('scrollComponent') scrollComponent: ElementRef;
  @ViewChild('virtualScrollComponent') virtualScrollComponent: CdkVirtualScrollViewport;

  @Input() label: string;
  @Input() search = false;
  @Input() translateOption = false;
  @Input() virtualScroll = true;
  @Input() withDot = false;
  @Input() withSearchMargin = false;
  @Input() set options(options: Array<TreeFilterOption>) {
    this._options = options;
    this.updateFilteredOptions();
    this.setVirtualScroll();
  }
  get options(): Array<TreeFilterOption> {
    return this._options;
  }
  _options: Array<TreeFilterOption>;

  @Output() updateFilter = new EventEmitter();

  filteredOptions: Array<TreeFilterOption> = this.options;
  searchQuery = '';
  useVirtualScroll: boolean;

  readonly ITEM_HEIGHT = 26;

  updateFilters(options: Array<TreeFilterOption>): void {
    this.updateFilter.emit(this.convertSelectedOptionsForFilters(options));
  }

  onSearch(searchQuery: string): void {
    this.filteredOptions = this.options?.filter((option: TreeFilterOption) =>
      option.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    this.searchQuery = searchQuery;
  }

  convertSelectedOptionsForFilters(options: Array<TreeFilterOption>): Array<number | string> {
    return options?.filter(option => option.isSelected).map(option => option.id);
  }

  updateFilteredOptions(): Array<TreeFilterOption> {
    if (this.searchQuery) {
      return (this.filteredOptions = this.options?.filter((option: TreeFilterOption) =>
        option.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      ));
    }
    this.filteredOptions = this.options;
  }

  refreshVirtualScroll(): void {
    if (this.useVirtualScroll) {
      this.virtualScrollComponent?.scrollToIndex(0);
    } else {
      this.scrollComponent.nativeElement.scroll(0, 0);
    }
  }

  setVirtualScroll(): void {
    this.useVirtualScroll = this.virtualScroll && this.options?.length > 10;
  }
}

import gql from 'graphql-tag';

import { SessionGraphQLActionType } from '../../enums/session-graphql.enum';

export const RefreshTokenMutation = gql`
  mutation ${SessionGraphQLActionType.refreshAuthToken} {
    ${SessionGraphQLActionType.refreshAuthToken} {
      token
    }
  }
`;

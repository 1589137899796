import { Component, Input } from '@angular/core';

@Component({
  selector: 'gw-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./../../../scss/components/_page-header.scss']
})
export class PageHeaderComponent {
  @Input() transparent?: boolean;
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Permission, PermissionCategory } from 'gutwin-shared';

import { PermissionCategoryResponse } from '@gutwin-audit/shared/interfaces/permission-category.interface';
import { PermissionResponse } from '@gutwin-audit/shared/interfaces/permission.interface';

import { ApiUrlService } from '@gutwin-audit/shared/services/api-url.service';
import { PermissionService } from '@gutwin-audit/shared/services/permission.service';

@Injectable()
export class PermissionCategoryService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private permissionService: PermissionService
  ) {}

  getPermissionCategory(id: string, rolesIds: Array<string>): Promise<PermissionCategory> {
    let params = new HttpParams();
    params = params.set('id', id);
    rolesIds.forEach(roleId => {
      params = params.append('roles_ids[]', roleId);
    });

    return this.http
      .get(`${this.apiUrlService.permissionCategoryApi}/${id}`, { params })
      .toPromise()
      .then((data: PermissionCategoryResponse) => this.convertPermissionCategoryToGet(data))
      .catch(error => {
        console.error('Error while getting permission category', error);
        throw error;
      });
  }

  convertPermissionCategoryToGet(
    permissionCategory: PermissionCategoryResponse,
    allPermissionsAdded?: boolean
  ): PermissionCategory {
    const generatePermissions = (permissions: Array<PermissionResponse>): Array<Permission> => {
      if (permissions) {
        return permissions.map(permission =>
          this.permissionService.convertPermissionToGet(permission, allPermissionsAdded)
        );
      }
    };

    return new PermissionCategory({
      id: permissionCategory.id,
      name: permissionCategory.name,
      title: permissionCategory.title,
      description: permissionCategory.description,
      totalPermissionsCount: permissionCategory.total_permissions_count || permissionCategory.permissions.length,
      permissions: generatePermissions(permissionCategory.permissions)
    });
  }
}

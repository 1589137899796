import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

// Providers
import { AuditService } from '../../shared/services/audit.service';

@Injectable()
export class AuditsAmountResolve implements Resolve<any> {
  errors = {
    connection: '',
    getData: ''
  };

  constructor(
    private auditService: AuditService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) { }

  resolve (route: ActivatedRouteSnapshot) {
    return this.auditService.getAuditsAmount();
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { fromEvent as observableFromEvent, of as observableOf, merge as observableMerge } from 'rxjs';
import { mapTo } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  online: boolean;
  onlineObservable: Observable<boolean>;
  serviceWorkerOn: boolean;

  constructor() {
    this.online = navigator.onLine;
    this.onlineObservable = observableMerge(
      observableOf(navigator.onLine),
      observableFromEvent(window, 'online').pipe(mapTo(true)),
      observableFromEvent(window, 'offline').pipe(mapTo(false))
    );
    this.onlineObservable.subscribe(online => {
      this.online = online;
    });
    this.serviceWorkerOn = 'serviceWorker' in navigator;
  }
}

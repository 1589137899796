
<form *ngIf="audit" novalidate="novalidate" autocomplete="off" [formGroup]="scheduleAuditForm" (ngSubmit)="submitAuditForm(scheduleAuditForm.value)">
  <div class="row">
    <div class="columns small-12 large-10">
      <table class="table audit-employees">
        <thead>
          <tr>
            <th class="audit-employees__group">{{ 'GLOBAL.TABLE_LABEL.ROLE' | translate }}</th>
            <th class="table__cell--bordered audit-employees__name">{{ 'GLOBAL.TABLE_LABEL.NAME' | translate }}</th>
            <th class="show-for-medium">{{ 'GLOBAL.TABLE_LABEL.EMAIL' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let roleControls of scheduleAuditForm.controls | keys">
            <tr *ngFor="let employeeControl of roleControls.value.controls | keys, let i = index" [formGroup]="employeeControl.value">
              <td class="table__cell--clear table__cell--bordered audit-employees__group audit-employees__group--body" [attr.rowspan]="getObjectSize(roleControls.value.controls)" *ngIf="i === 0">
                <label>
                  <gw-check-box class="audit-employees__check-box" [ngModel]="hasAllChecked(roleControls.value)" [ngModelOptions]="{standalone: true}" (click)="clickGroupCheckbox(roleControls.value)"></gw-check-box>{{ getRoleName(roleControls.key) }}
                </label>
              </td>
              <td class="table__cell--bordered flex-table__cell--avatar audit-employees__name">
                <label>
                  <gw-check-box class="audit-employees__check-box" #isIncludedControl="gwCheckBox" formControlName="isIncluded"></gw-check-box>
                  <gw-avatar class="auditor__avatar auditors-cell__avatar" [employee]="employeeControl.value.controls.employee.value"></gw-avatar><span class="auditors-cell__name audit-employees__auditor-name">{{ employeeControl.value.controls.employee.value.lastname }}, {{ employeeControl.value.controls.employee.value.name }}</span><span class="auditor__email--one-line hide-for-medium">{{ employeeControl.value.controls.employee.value.email }}</span>
                </label>
              </td>
              <td class="show-for-medium">{{ employeeControl.value.controls.employee.value.email }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="button-group audit-step__button-group schedule-audit-step__button-group">
    <button class="button button--tertiary audit-step__button schedule-audit-step__button" type="button" [disabled]="sending || !isEmployeeSelected" (click)="sendEmails()">{{ 'AUDIT_CREATION.S_SCHEDULE_AUDIT.ACTION.SEND_EMAIL' | translate }}</button>
    <button class="button button--primary audit-step__button" type="submit">{{ 'AUDIT_CREATION.S_SCHEDULE_AUDIT.ACTION.GO_TO_NEXT_STEP' | translate }}<i class="gw-icon gw-arrow--right button__icon--after"></i></button>
  </div>
</form>
<div class="blankslate" *ngIf="!audit">{{ 'AUDIT_CREATION.ACTION.LOADING_AUDIT' | translate }}</div>
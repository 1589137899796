import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

import { Employee } from 'gutwin-shared';

import { FilterModuleService } from '@gutwin-audit/shared/services/filter-module.service';
import { UserService } from '../../shared/services/user.service';

@Injectable()
export class EmployeesResolve implements Resolve<any> {
  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private filterService: FilterModuleService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Promise<Array<Employee>> {
    this.filterService.getQueryParams(route.queryParams);

    const page = this.filterService.page;
    const limit = this.filterService.limit;
    const filters = this.filterService.auditsFilters;

    return this.userService
      .getEmployeesExtended(page, filters, limit)
      .then(res => {
        this.userService.totalEmployees = res.total;
        return res.list;
      })
      .catch(error => {
        this.fetchErrorTranslation().then((translation: any) => {
          this.notificationsService.error(translation.title, translation.text);
        });
        return [];
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService
      .get(['GLOBAL.ERROR.CONNECTION', 'ADMIN_SETTINGS.USERS.ERROR.LOAD_USERS'])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['ADMIN_SETTINGS.USERS.ERROR.LOAD_USERS']
        };
      });
  }
}

import { AttachmentLC } from '../models/attachment-lc.model';
import { Attachment } from '../models/attachment.model';

import { ApiAttachment } from '../interfaces/attachment.interface';
import { FullAttachment } from '../interfaces/full-attachment.interface';

export const convertAttachmentToGet = (attachment: ApiAttachment): AttachmentLC => {
  return new AttachmentLC({
    id: attachment.id,
    name: attachment.name,
    displayName: attachment.name,
    url: attachment.url,
    mimeType: attachment.mimeType,
    size: attachment.size
  });
};

export const convertFullAttachmentToGet = (attachment: ApiAttachment): FullAttachment => {
  if (attachment) {
    const convertedAttachment = convertAttachmentToGet(attachment);
    return {
      original: convertedAttachment,
      thumbnail: convertedAttachment
    };
  }
};

export const convertAvatarToGet = (attachment: ApiAttachment): Attachment => {
  return new Attachment({
    name: attachment.name,
    url: attachment.url,
    mimeType: attachment.mimeType
  });
};

export const convertAttachmentToSharedAttachment = (attachment: AttachmentLC): Attachment => {
  return new Attachment({ ...attachment, size: Number(attachment.size) });
};

export const convertSharedAttachmentToAttachment = (sharedAttachment: Attachment): AttachmentLC => {
  return new AttachmentLC({ ...sharedAttachment, size: sharedAttachment.size.toString() });
};

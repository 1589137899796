import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TreeNodeZip } from '../../models/tree-node.model';

import { SelectedCategoriesIdPath } from '../../interfaces/selected-categories-id-path.interface';
import { SelectedCategories } from '../../interfaces/selected-categories.interface';
import { Titles } from '../../interfaces/titles.interface';

import { UserType } from '../../enums/user-type.enum';

import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'gw-law-details-main-part',
  templateUrl: './law-details-main-part.component.html',
  styleUrls: ['./law-details-main-part.component.scss']
})
export class LawDetailsMainPartComponent<L extends { id: string }, C> {
  @Input() allLegalCategories: Array<C>;
  @Input() selectedCategories: SelectedCategories;
  @Input() isEditMode: boolean;
  @Input() isRemarkSavedSuccessfully: boolean;
  @Input() openedEdits: number;
  @Input() rootCategoryColor: string;
  @Input() law: L;
  @Input() userType: UserType;

  @Output() updateCategories = new EventEmitter<{ legalCategoriesIds: SelectedCategoriesIdPath; popoverId: string }>();
  @Output() updateTitles = new EventEmitter<{ law: { displayName: string; fullname: string }; popoverId: string }>();
  @Output() updateLawNumber = new EventEmitter<{ law: { number: string; version: string }; popoverId: string }>();
  @Output() toggleEditMode = new EventEmitter<void>();

  firstCategory: TreeNodeZip;

  constructor(private modalService: ModalService) {}

  onToggleEditMode(): void {
    this.toggleEditMode.emit();
  }

  openModal(name: string): void {
    this.modalService.open(name);
  }

  closeModal(name: string): void {
    this.modalService.close(name);
  }

  onUpdateCategories(legalCategoriesIds: SelectedCategoriesIdPath): void {
    this.updateCategories.emit({ legalCategoriesIds, popoverId: 'editLawCategoriesPopover' });
  }

  onUpdateTitles(titles: Titles): void {
    this.updateTitles.emit({
      law: {
        displayName: titles.firstTitle,
        fullname: titles.secondTitle
      },
      popoverId: 'editTitlesPopover'
    });
  }

  onUpdateLawNumber(lawNumbers: { number: string; version: string }): void {
    this.updateLawNumber.emit({ law: lawNumbers, popoverId: 'editLawNumberPopover' });
  }
}

import { Component, OnInit, Input, AfterContentChecked, EventEmitter, Output, OnChanges } from '@angular/core';
// Gutwin Shared Library
import { Attachment } from 'gutwin-shared';
// Models
import { Solving } from './../../../shared/models/solving.model';

@Component({
  selector: 'gw-solving',
  templateUrl: './solving.component.html',
  styleUrls: ['./solving.component.scss']
})
export class SolvingComponent implements OnInit, OnChanges, AfterContentChecked {
  @Input() findingSolvingData: Solving;
  @Output() afterContentChecked = new EventEmitter();
  attachmentsGroups = {
    images: new Array<Attachment>(),
    others: new Array<Attachment>()
  };

  constructor() { }

  ngOnInit() { }

  ngOnChanges() {
    if (this.findingSolvingData) {
      this.fillAttachmentsGroups(this.findingSolvingData.attachments);
    }
  }

  ngAfterContentChecked() {
    this.afterContentChecked.emit();
  }

  fillAttachmentsGroups(attachments: Array<Attachment>): void {
    const convertAttachments = (): Array<Attachment> => {
      return attachments && attachments.length ? attachments.map(attachment => new Attachment(attachment)) : new Array<Attachment>();
    };

    this.attachmentsGroups.images = convertAttachments().filter(attachment => attachment.isImage);
    this.attachmentsGroups.others = convertAttachments().filter(attachment => !attachment.isImage);
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { PermissionCategory } from '../../models/permission-category.model';

@Component({
  selector: 'gw-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {
  @Input() permissionCategory: PermissionCategory;
  hasPermissionsToShow: boolean;

  ngOnInit(): void {
    this.hasPermissionsToShow = this.checkIfHasPermissionsToShow();
  }

  checkIfHasPermissionsToShow(): boolean {
    return !!(this.permissionCategory.selectedPermissionsCount || this.permissionCategory.permissionsToRemoveCount);
  }
}

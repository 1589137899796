import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gw-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminHeaderComponent {
  @Input() header: string;
  @Input() preheader?: string;
  @Input() addButtonLabel: string;
  @Input() transparent?: boolean;
  @Input() smallMarginTop?: boolean;
  @Output() addButtonClick = new EventEmitter<void>();

  onAddButtonClick(): void {
    this.addButtonClick.emit();
  }
}

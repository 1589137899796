import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ProgressBarComponent } from '../progress-bar/progress-bar.component';

import { ProgressGroup, ProgressStatus } from '../../services/progress.service';

@Component({
  selector: 'gw-progress-bar-shared',
  templateUrl: './progress-bar-shared.component.html',
  styleUrls: ['./progress-bar-shared.component.scss']
})
export class ProgressBarSharedComponent extends ProgressBarComponent {
  @Input() action: string;
  @Input() status: ProgressStatus;
  @Input() label: string;
  @Input() groups: Array<ProgressGroup>;
  @Input() close = true;

  @Output() syncGroups = new EventEmitter<void>();
  @Output() closeProgressBar = new EventEmitter<void>();

  onSyncGroups(): void {
    this.syncGroups.emit();
  }

  onCloseProgressBar(): void {
    this.closeProgressBar.emit();
  }
}

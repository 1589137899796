import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputWithLabelType } from '../../enums/input-with-label-type.enum';

@Component({
  selector: 'gw-input-with-label',
  templateUrl: './input-with-label.component.html',
  styleUrls: ['./input-with-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWithLabelComponent),
      multi: true
    }
  ]
})
export class InputWithLabelComponent implements ControlValueAccessor {
  @Input() light: boolean;
  @Input() blueBackground: boolean;
  @Input() blueLabel: boolean;
  @Input() label: string;
  @Input() semiboldLabel = false;
  @Input() smallLabel: boolean;
  @Input() standalone = true;
  @Input() type = InputWithLabelType.checkbox;

  value: boolean;

  readonly INPUT_TYPE = InputWithLabelType;

  constructor(private changeDetector: ChangeDetectorRef) {}

  onChange: (value: boolean) => void = () => {};

  writeValue(value: boolean): void {
    if (value !== undefined) {
      this.value = value;
      this.changeDetector.detectChanges();
    }
  }

  registerOnChange(onChange: (value: boolean) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(): void {}
}

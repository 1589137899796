import { Component } from '@angular/core';

import { ModalDialogTheme } from '../../interfaces/modal-dialog-theme.interface';

import { ModalComponent } from '../modal/modal.component';

import { BodyFreezerService } from '../../services/body-freezer.service';
import { DialogService } from '../../services/dialog.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'gw-dialog',
  exportAs: 'gwDialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent extends ModalComponent {
  /**
   * Title of the dialog modal
   *
   * @required
   */
  title: string;
  /**
   * Content of the dialog modal
   *
   * @required
   */
  content: string;
  /**
   * Text in button to cancel action
   *
   * @required
   */
  cancelText: string;
  /**
   * Text in button to confirm action
   *
   * @required
   */
  confirmText: string;
  /**
   * Show button to close dialog modal
   */
  withCloseButton = true;
  /**
   * Class of the icon to show in dialog modal
   */
  icon: string;
  /**
   * Theme of dialog modal
   * titleClass - set class for title element
   * iconColor - set icon's color
   */
  theme: ModalDialogTheme = {
    titleClass: 'h2--light'
  };

  constructor(
    public modalService: ModalService,
    public dialogService: DialogService,
    protected bodyFreezer: BodyFreezerService
  ) {
    super(modalService, bodyFreezer);
  }

  confirm(): void {
    this.dialogService.dialogConfirmation();
    this.close();
  }

  cancel(): void {
    this.dialogService.dialogRejection();
    this.close();
  }
}

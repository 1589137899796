import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { RecurringType } from '@gutwin-audit/shared/types';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import moment from 'moment';

import { Employee, TabInterface } from 'gutwin-shared';

import { Audit } from '@gutwin-audit/shared/models/audit.model';
import { AuditedArea } from '@gutwin-audit/shared/models/audited-area.model';
import { FacilityFilter } from '@gutwin-audit/shared/models/facility-filter.model';

import { AuditedAreaCopyForm } from '@gutwin-audit/shared/interfaces/audited-area-copy-form.interface';
import { WeekDay } from '@gutwin-audit/shared/interfaces/week-day.interface';

import { SelectFacilityComponent } from '@gutwin-audit/audit/audit-creation/select-facility/select-facility.component';

import { AuditService } from '@gutwin-audit/shared/services/audit.service';
import { FacilityService } from '@gutwin-audit/shared/services/facility.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';

import { DateValidator } from '@gutwin-audit/shared/validators/date.validator';

@Component({
  selector: 'gw-copy-audit',
  templateUrl: './copy-audit.component.html',
  styleUrls: ['./copy-audit.component.scss'],
  exportAs: 'gwCopyAudit'
})
export class CopyAuditComponent implements OnInit {
  @ViewChild('selectFacility') selectFacility: SelectFacilityComponent;

  @Input() audit: Audit;
  @Input() auditName: string;

  auditForm: FormGroup;
  submitted: boolean;
  employees: Array<Employee>;
  facilities: Array<FacilityFilter>;
  auditorsList: Array<Employee>;
  coAuditorsVisible = false;
  isRecurring: boolean;
  selectedTab: TabInterface;
  selectedRecurringType: RecurringType;
  weekDays: Array<WeekDay> = [
    { id: 'monday', name: '' },
    { id: 'tuesday', name: '' },
    { id: 'wednesday', name: '' },
    { id: 'thursday', name: '' },
    { id: 'friday', name: '' },
    { id: 'saturday', name: '' },
    { id: 'sunday', name: '' }
  ];
  translation = {
    notify: {
      error: {
        title: '',
        loadEmployees: '',
        loadFacilities: '',
        createAudit: ''
      },
      success: {
        title: '',
        createAudit: ''
      }
    }
  };
  tabs: Array<TabInterface> = [
    {
      tabTitle: '',
      id: 'oneTime'
    },
    {
      tabTitle: '',
      id: 'recurring'
    }
  ];

  constructor(
    private auditService: AuditService,
    private facilityService: FacilityService,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.fetchTranslation();
    this.fetchEmployees();
    this.fetchFacilities();
    this.initForm();
    this.auditorsChange();
    this.auditForm
      .get('audit')
      .get('leadAuditor')
      .valueChanges.subscribe(value => {
        this.auditorsChange();
      });
    this.auditForm
      .get('audit')
      .get('coAuditor')
      .valueChanges.subscribe(value => {
        this.auditorsChange();
      });
    this.auditForm
      .get('recurring')
      .get('type')
      .valueChanges.subscribe(() => {
        this.updateValidatorsForRecurring();
      });
    this.setSelectedTab(this.tabs[0]);
    this.setSelectedRecurringType('monthly');
  }

  updateValidatorsForRecurring(): void {
    const recurringType = this.auditForm.get('recurring').get('type').value;
    const validators = [Validators.required, Validators.min(1), Validators.max(99)];
    const months = this.auditForm.get('recurring').get('months');
    const monthsDates = this.auditForm.get('recurring').get('monthsDates');
    const weeks = this.auditForm.get('recurring').get('weeks');
    const weeksDates = this.auditForm.get('recurring').get('weeksDates');

    const clearMonthValidators = () => {
      months.clearValidators();
      months.updateValueAndValidity();

      monthsDates.clearValidators();
      monthsDates.updateValueAndValidity();
    };

    const clearWeekValidators = () => {
      weeks.clearValidators();
      weeks.updateValueAndValidity();

      weeksDates.clearValidators();
      weeksDates.updateValueAndValidity();
    };

    if (this.isRecurring) {
      if (recurringType === 'monthly') {
        months.setValidators(validators);
        months.updateValueAndValidity();

        monthsDates.setValidators(validators);
        monthsDates.updateValueAndValidity();

        clearWeekValidators();
      } else if (recurringType === 'weekly') {
        clearMonthValidators();

        weeks.setValidators(validators);
        weeks.updateValueAndValidity();

        weeksDates.setValidators(validators);
        weeksDates.updateValueAndValidity();
      }
    } else {
      clearMonthValidators();
      clearWeekValidators();
    }
  }

  initForm(): void {
    this.auditForm = this.formBuilder.group({
      audit: this.formBuilder.group({
        name: [this.auditName ? this.auditName : this.audit.name, Validators.required],
        leadAuditor: [this.audit.employees.leadAuditor, Validators.required],
        coAuditor: [this.audit.employees.coAuditor, []]
      }),
      recurring: this.formBuilder.group({
        type: [null],
        months: [1],
        monthsDates: [12],
        weeks: [1],
        weeksDates: [12]
      }),
      auditedAreas: this.formBuilder.array(this.initAuditedAreasForms())
    });
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  isFieldEmpty(field: AbstractControl): boolean {
    return this.submitted && field.errors && field.errors.required;
  }

  isFieldMinNumberInvalid(field: AbstractControl): boolean {
    return this.submitted && field.errors && field.errors.min;
  }

  isFieldMaxNumberInvalid(field: AbstractControl): boolean {
    return this.submitted && field.errors && field.errors.max;
  }

  isSelectedRecurringType(type: RecurringType): boolean {
    return this.auditForm.get('recurring.type').value === type;
  }

  auditorsChange(): void {
    this.auditorsList = [
      ...this.auditForm.get('audit').get('leadAuditor').value,
      ...this.auditForm.get('audit').get('coAuditor').value
    ];
  }

  initAuditedAreasForms(): Array<FormGroup> {
    if (this.audit.auditedAreas) {
      return this.audit.auditedAreas.map(auditedArea =>
        this.formBuilder.group(
          {
            id: auditedArea.id,
            recurringMonthDayType: ['dayOfMonth'],
            dayOfMonth: [1],
            numberWeekOfMonth: [1],
            weekDayOfMonth: [this.weekDays[0]],
            weeklyDayOfMonth: [this.weekDays[0]],
            startTime: ['', Validators.required],
            endTime: ['', Validators.required],
            facility: [auditedArea.facility || '', Validators.required],
            location: [auditedArea.location || ''],
            auditor: [auditedArea.employees.auditor || [], Validators.required],
            auditee: [auditedArea.employees.auditee || [], Validators.required]
          },
          { validator: DateValidator.isCorrectDateOrder('startTime', 'endTime') }
        )
      );
    } else {
      return [];
    }
  }

  covertTimeForDayOfMonth(
    auditedArea: AuditedAreaCopyForm,
    auditDate: moment.Moment,
    multiplier: number
  ): moment.Moment {
    const months = this.auditForm.get('recurring').get('months').value;
    const monthsDates = this.auditForm.get('recurring').get('monthsDates').value;
    let auditMonth = auditDate.get('month');

    const computeRecurringDate = (monthToSet = auditMonth, monthMultiplier = multiplier) => {
      const recurringDate = moment()
        .set('year', auditDate.get('year'))
        .set('month', monthToSet)
        .set('date', auditedArea.dayOfMonth)
        .set('hour', auditDate.get('hour'))
        .set('minute', auditDate.get('minute'))
        .set('second', auditDate.get('second'))
        .add(months * monthMultiplier, 'month');

      if (auditedArea.dayOfMonth === 31) {
        recurringDate.add(-1, 'day');
      }

      return recurringDate;
    };

    // If first recurringDate is computed before auditDate, then start from next month
    if (auditDate.diff(computeRecurringDate(auditMonth, 0), 'minutes') > 0) {
      auditMonth = auditMonth - 1;
    }

    // If first recurringDate is computed before auditDate, then add extra month date instead
    if (auditDate.diff(computeRecurringDate(), 'minutes') > 0) {
      return computeRecurringDate(auditMonth + months * monthsDates);
    } else {
      return computeRecurringDate();
    }
  }

  convertTimeForWeekDayOfMonth(
    auditedArea: AuditedAreaCopyForm,
    auditDate: moment.Moment,
    multiplier: number
  ): moment.Moment {
    const months = this.auditForm.get('recurring').get('months').value;
    const monthsDates = this.auditForm.get('recurring').get('monthsDates').value;
    let auditMonth = auditDate.get('month');

    const computeRecurringDate = (monthToSet = auditMonth, monthMultiplier = multiplier) => {
      const targetMonth = moment()
        .set('year', auditDate.get('year'))
        .set('month', monthToSet)
        .set('date', 1)
        .set('hour', auditDate.get('hour'))
        .set('minute', auditDate.get('minute'))
        .set('second', auditDate.get('second'))
        .add(months * monthMultiplier, 'month');

      // Compute first target weekDay of target month
      let recurringDate = targetMonth.clone().day(auditedArea.weekDayOfMonth.id);

      // Info: There is case in moment.js when you want get date of for example first Monday of target month
      // and if this month is not starts from Monday (in the first week of target months there is no Monday)
      // then momemt returns Monday from previous month (e.g. 31/05 instead of 08/06)
      // So there is added work around this case with add +7 days
      if (recurringDate.get('month') !== targetMonth.get('month')) {
        recurringDate = recurringDate.add(7, 'days');
      }

      // Set week number
      return recurringDate.add(auditedArea.numberWeekOfMonth - 1, 'weeks');
    };

    // If first recurringDate is computed before auditDate, then start from next month
    if (auditDate.diff(computeRecurringDate(auditMonth, 0), 'minutes') > 0) {
      auditMonth = auditMonth - 1;
    }

    // If first recurringDate is computed before auditDate, then add extra month date instead
    if (auditDate.diff(computeRecurringDate(), 'minutes') > 0) {
      return computeRecurringDate(auditMonth + months * monthsDates);
    } else {
      return computeRecurringDate();
    }
  }

  convertTimeForDayOfWeek(
    auditedArea: AuditedAreaCopyForm,
    auditDate: moment.Moment,
    multiplier: number
  ): moment.Moment {
    const weeksDates = this.auditForm.get('recurring').get('weeksDates').value;
    const weeks = this.auditForm.get('recurring').get('weeks').value;
    let auditWeek = auditDate.get('week');

    const computeRecurringDate = (weekToSet = auditWeek, weekMultiplier = multiplier) => {
      const recurringDate = moment()
        .set('year', auditDate.get('year'))
        .set('month', auditDate.get('month'))
        .set('date', auditDate.get('date'))
        .set('week', weekToSet)
        .startOf('week')
        .day(auditedArea.weeklyDayOfMonth.id)
        .set('hour', auditDate.get('hour'))
        .set('minute', auditDate.get('minute'))
        .set('second', auditDate.get('second'))
        .add(weeks * weekMultiplier, 'week');

      return recurringDate;
    };

    // If first recurringDate is computed before auditDate, then start from next week
    if (auditDate.diff(computeRecurringDate(auditWeek, 0), 'minutes') > 0) {
      auditWeek = auditWeek - 1;
    }

    // If first recurringDate is computed before auditDate, then add extra week date instead
    if (auditDate.diff(computeRecurringDate(), 'minutes') > 0) {
      return computeRecurringDate(auditWeek + weeks * weeksDates);
    } else {
      return computeRecurringDate();
    }
  }

  createAudit(): Promise<any> {
    const promiseArray = [];

    const convertTime = (auditedArea: AuditedAreaCopyForm, timeKey: string, i: number): moment.Moment => {
      const auditDate = moment(auditedArea[timeKey]);
      const multiplier = i - 1;

      if (this.selectedRecurringType === 'monthly') {
        if (auditedArea.recurringMonthDayType === 'dayOfMonth') {
          return this.covertTimeForDayOfMonth(auditedArea, auditDate, multiplier);
        } else if (auditedArea.recurringMonthDayType === 'weekDayOfMonth') {
          return this.convertTimeForWeekDayOfMonth(auditedArea, auditDate, multiplier);
        }
      } else if (this.selectedRecurringType === 'weekly') {
        return this.convertTimeForDayOfWeek(auditedArea, auditDate, multiplier);
      }
    };

    const convertAuditFromForm = (i = 0): Audit => {
      const auditToCreate = new Audit({
        id: this.audit.id,
        name: this.auditForm.value.audit.name,
        employees: {
          leadAuditor: this.auditForm.value.audit.leadAuditor,
          coAuditor: this.auditForm.value.audit.coAuditor
        }
      });
      auditToCreate.auditedAreas = this.auditForm.value.auditedAreas.map(auditedArea => {
        return new AuditedArea({
          id: auditedArea.id,
          startTime: this.isRecurring && i ? convertTime(auditedArea, 'startTime', i) : auditedArea.startTime,
          endTime: this.isRecurring && i ? convertTime(auditedArea, 'endTime', i) : auditedArea.endTime,
          facility: auditedArea.facility,
          location: auditedArea.location,
          employees: {
            auditor: auditedArea.auditor,
            auditee: auditedArea.auditee
          }
        });
      });
      return auditToCreate;
    };

    if (this.isRecurring) {
      if (this.selectedRecurringType === 'monthly') {
        const monthsDates = this.auditForm.get('recurring').get('monthsDates').value;

        for (let i = 1; i <= monthsDates; i++) {
          promiseArray.push(this.auditService.createAuditFromTemplate(convertAuditFromForm(i)));
        }
      } else if (this.selectedRecurringType === 'weekly') {
        const weeksDates = this.auditForm.get('recurring').get('weeksDates').value;

        for (let i = 1; i <= weeksDates; i++) {
          promiseArray.push(this.auditService.createAuditFromTemplate(convertAuditFromForm(i)));
        }
      }
    } else {
      promiseArray.push(this.auditService.createAuditFromTemplate(convertAuditFromForm()));
    }

    return Promise.all(promiseArray)
      .then(audit => {
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.createAudit
        );
        return audit;
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.createAudit);
        throw error;
      });
  }

  submitAuditForm(): Promise<Audit> {
    this.submitted = true;
    if (this.auditForm.valid) {
      return this.createAudit().then(audit => {
        this.submitted = false;
        return audit;
      });
    } else {
      return Promise.reject({ invalid: true });
    }
  }

  fetchEmployees(): void {
    this.userService
      .getEmployees()
      .then(employees => {
        this.employees = employees;
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.title,
          this.translation.notify.error.loadEmployees
        );
      });
  }

  fetchFacilities(): void {
    this.facilityService
      .getFacilitiesForFilter()
      .then(facilities => {
        this.facilities = facilities;
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.title,
          this.translation.notify.error.loadFacilities
        );
        throw error;
      });
  }

  chooseFacility(auditedAreaForm: FormGroup, selectedFacility: FacilityFilter, selectElement: ElementRef): void {
    auditedAreaForm.get('facility').setValue(selectedFacility);
    if (selectElement) {
      selectElement.nativeElement.focus();
    }
  }

  keyDownFacilityControl(event: any): void {
    this.selectFacility.keydown(event);
  }

  showCoAuditors(): void {
    this.coAuditorsVisible = true;
  }

  setSelectedTab(tab: TabInterface): void {
    this.selectedTab = tab;
    this.isRecurring = tab.id === 'recurring';
    this.updateValidatorsForRecurring();
  }

  setSelectedRecurringType(type: RecurringType): void {
    this.selectedRecurringType = type;
    this.auditForm
      .get('recurring')
      .get('type')
      .setValue(type);
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ERROR.LOAD_EMPLOYEES',
        'GLOBAL.ACTION.SUCCESS',
        'AUDIT_CREATION.ERROR.LOAD_FACILITIES',
        'AUDITS_LIST.ERROR.CREATE_AUDIT_FROM_TEMPLATE',
        'AUDITS_LIST.SUCCESS.CREATE_AUDIT_FROM_TEMPLATE',
        'AUDITS_LIST.COPY_AUDIT_MODAL.ONE_TIME',
        'AUDITS_LIST.COPY_AUDIT_MODAL.RECURRING',
        'AUDIT_CREATION.FORM.LABEL.MONDAY',
        'AUDIT_CREATION.FORM.LABEL.TUESDAY',
        'AUDIT_CREATION.FORM.LABEL.WEDNESDAY',
        'AUDIT_CREATION.FORM.LABEL.THURSDAY',
        'AUDIT_CREATION.FORM.LABEL.FRIDAY',
        'AUDIT_CREATION.FORM.LABEL.SATURDAY',
        'AUDIT_CREATION.FORM.LABEL.SUNDAY'
      ])
      .toPromise();
    this.translation.notify.error.title = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.loadEmployees = translation['GLOBAL.ERROR.LOAD_EMPLOYEES'];
    this.translation.notify.error.loadFacilities = translation['AUDIT_CREATION.ERROR.LOAD_FACILITIES'];
    this.translation.notify.error.createAudit = translation['AUDITS_LIST.ERROR.CREATE_AUDIT_FROM_TEMPLATE'];
    this.translation.notify.success.title = translation['GLOBAL.ACTION.SUCCESS'];
    this.translation.notify.success.createAudit = translation['AUDITS_LIST.SUCCESS.CREATE_AUDIT_FROM_TEMPLATE'];
    this.tabs[0].tabTitle = translation['AUDITS_LIST.COPY_AUDIT_MODAL.ONE_TIME'];
    this.tabs[1].tabTitle = translation['AUDITS_LIST.COPY_AUDIT_MODAL.RECURRING'];
    this.weekDays[0].name = translation['AUDIT_CREATION.FORM.LABEL.MONDAY'];
    this.weekDays[1].name = translation['AUDIT_CREATION.FORM.LABEL.TUESDAY'];
    this.weekDays[2].name = translation['AUDIT_CREATION.FORM.LABEL.WEDNESDAY'];
    this.weekDays[3].name = translation['AUDIT_CREATION.FORM.LABEL.THURSDAY'];
    this.weekDays[4].name = translation['AUDIT_CREATION.FORM.LABEL.FRIDAY'];
    this.weekDays[5].name = translation['AUDIT_CREATION.FORM.LABEL.SATURDAY'];
    this.weekDays[6].name = translation['AUDIT_CREATION.FORM.LABEL.SUNDAY'];
  }
}

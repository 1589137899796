import { Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

// Gutwin Shared Library
import { Employee } from 'gutwin-shared';

// Models
import { AuditType } from '../../../../shared/models/audit-type.model';
import { Facility } from '../../../../shared/models/facility.model';
import { FindingsFilters } from '../../../../shared/models/filters-findings.model';
import { FindingType } from '../../../../shared/models/finding-type.model';

import { FilterComponent } from '@gutwin-audit/shared/enums/filter-component.enum';

// Providers
import { AuditTypeService } from '../../../../shared/services/audit-type.service';
import { FacilityService } from '../../../../shared/services/facility.service';
import { FilterModuleService } from '../../../../shared/services/filter-module.service';
import { FindingService } from '../../../../shared/services/finding.service';
import { UserService } from '../../../../shared/services/user.service';

@Component({
  selector: 'gw-findings-filters',
  templateUrl: './findings-filters.component.html',
  styleUrls: ['./findings-filters.component.scss']
})
export class FindingsFiltersComponent implements OnInit, OnDestroy {
  filterSubscription: Subscription;
  assignedFilters: FindingsFilters;
  assignedFindingTypes: Array<FindingType>;
  assignedAuditTypes: Array<AuditType>;
  assignedResponsiblePersons: Array<Employee>;
  assignedFacilities: Array<Facility>;
  hasFilters: boolean;
  findingTypes: Array<FindingType>;
  auditTypes: Array<AuditType>;
  employees: Array<Employee>;
  facilities: Array<Facility>;
  errors = {
    connectionTitle: '',
    fetchFindingTypesText: '',
    fetchAuditTypesText: '',
    fetchEmployeesText: '',
    fetchFacilitiesText: ''
  };

  constructor(
    private auditTypeService: AuditTypeService,
    private filterService: FilterModuleService,
    private findingService: FindingService,
    private userService: UserService,
    private facilityService: FacilityService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.initFilters(this.filterService.findingsFilters);
    this.fetchFindingTypes();
    this.fetchAuditTypes();
    this.fetchEmployees();
    this.fetchFacilities();
    this.fetchTranslations();
    this.filterSubscription = this.filterService.filterObservable.subscribe(params => {
      this.initFilters(params.filters);
      this.assignedFindingTypes = this.convertFindingTypes(this.findingTypes);
      this.assignedAuditTypes = this.convertAuditTypes(this.auditTypes);
      this.assignedResponsiblePersons = this.convertResponsiblePersons(this.employees);
      this.assignedFacilities = this.convertFacilities(this.facilities);
    });
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  initFilters(filters: FindingsFilters): void {
    this.assignedFilters = filters;
    this.hasFilters = this.filterService.isAnyFilter();
  }

  fetchFindingTypes(onInit = false): void {
    if ((this.isFindingTypeFilterAssigned() || onInit) && !this.findingTypes) {
      this.findingService
        .getFindingTypes()
        .then((findingTypes: Array<FindingType>) => {
          this.findingTypes = findingTypes;
          this.assignedFindingTypes = this.convertFindingTypes(findingTypes);
        })
        .catch(() => {
          this.notificationsService.error(this.errors.connectionTitle, this.errors.fetchFindingTypesText);
        });
    }
  }

  fetchAuditTypes(onInit = false): void {
    if ((this.isAuditTypeFilterAssigned() || onInit) && !this.auditTypes) {
      this.auditTypeService
        .getAuditTypes()
        .then((auditTypes: Array<AuditType>) => {
          this.auditTypes = auditTypes;
          this.assignedAuditTypes = this.convertAuditTypes(auditTypes);
        })
        .catch(() => {
          this.notificationsService.error(this.errors.connectionTitle, this.errors.fetchAuditTypesText);
        });
    }
  }

  fetchEmployees(onInit = false): void {
    if ((this.isResponsiblePersonFilterAssigned() || onInit) && !this.employees) {
      this.userService
        .getEmployees()
        .then((employees: Array<Employee>) => {
          this.employees = employees;
          this.assignedResponsiblePersons = this.convertResponsiblePersons(employees);
        })
        .catch(() => {
          this.notificationsService.error(this.errors.connectionTitle, this.errors.fetchEmployeesText);
        });
    }
  }

  fetchFacilities(onInit = false): void {
    if ((this.isFacilitiesFilterAssigned() || onInit) && !this.facilities) {
      this.facilityService
        .getFacilitiesForFilter()
        .then((facilities: Array<Facility>) => {
          this.facilities = facilities;
          this.assignedFacilities = this.convertFacilities(facilities);
        })
        .catch(() => {
          this.notificationsService.error(this.errors.connectionTitle, this.errors.fetchFacilitiesText);
        });
    }
  }

  isFindingTypeFilterAssigned(): boolean {
    return this.assignedFilters && this.assignedFilters.types && !!this.assignedFilters.types.length;
  }

  isAuditTypeFilterAssigned(): boolean {
    return this.assignedFilters && this.assignedFilters.auditTypes && !!this.assignedFilters.auditTypes.length;
  }

  isResponsiblePersonFilterAssigned(): boolean {
    return this.assignedFilters && !!this.assignedFilters.responsiblePersons;
  }

  isFacilitiesFilterAssigned(): boolean {
    return this.assignedFilters && this.assignedFilters.facilities && !!this.assignedFilters.facilities.facilities;
  }

  convertFindingTypes(findingTypes: Array<FindingType>): Array<FindingType> {
    const assignedFindingTypes = new Array<FindingType>();
    if (this.isFindingTypeFilterAssigned()) {
      this.assignedFilters.types.forEach(typeId => {
        assignedFindingTypes.push(_.find(findingTypes, type => type.id === typeId));
      });
    }
    return assignedFindingTypes;
  }

  convertAuditTypes(auditTypes: Array<AuditType>): Array<AuditType> {
    const assignedAuditTypes = new Array<AuditType>();
    if (this.isAuditTypeFilterAssigned()) {
      this.assignedFilters.auditTypes.forEach(typeId => {
        assignedAuditTypes.push(_.find(auditTypes, type => type.id === typeId));
      });
    }
    return assignedAuditTypes;
  }

  convertResponsiblePersons(employees: Array<Employee>): Array<Employee> {
    const assignedResponsiblePersons = new Array<Employee>();
    if (this.isResponsiblePersonFilterAssigned() && employees) {
      this.assignedFilters.responsiblePersons.forEach(assignedPerson => {
        assignedResponsiblePersons.push(_.find(employees, employee => employee.id === assignedPerson));
      });
    }
    return assignedResponsiblePersons;
  }

  convertFacilities(facilities: Array<Facility>): Array<Facility> {
    const assignedFacilities = new Array<Facility>();
    if (this.isFacilitiesFilterAssigned()) {
      this.assignedFilters.facilities.facilities.forEach(facilityId => {
        const facility = this.findFacility(facilities, facilityId);
        if (facility) {
          assignedFacilities.push(facility);
        }
      });
    }
    return assignedFacilities;
  }

  findFacility(facilities: Array<Facility>, facilityId: string): Facility {
    for (const facility of facilities) {
      if (facility.id === facilityId) {
        return facility;
      } else if (facility.children && facility.children.length) {
        const childrenFacility = this.findFacility(facility.children, facilityId);
        if (childrenFacility) {
          return childrenFacility;
        }
      }
    }
    return undefined;
  }

  removeSearchFilter(): void {
    this.filterService.setFilter('search', '', true, FilterComponent.findings);
  }

  removeDateFilter(): void {
    this.filterService.setFilter('dateFrom', '', false, FilterComponent.findings);
    this.filterService.setFilter('dateTo', '', true, FilterComponent.findings);
  }

  deselectType(index: number): void {
    this.assignedFilters.types.splice(index, 1);
    this.filterService.setFilter('types', this.assignedFilters.types, true, FilterComponent.findings);
  }

  deselectAuditType(index: number): void {
    this.assignedFilters.auditTypes.splice(index, 1);
    this.filterService.setFilter('auditTypes', this.assignedFilters.auditTypes, true, FilterComponent.findings);
  }

  deselectResponsiblePerson(index: number): void {
    this.assignedFilters.responsiblePersons.splice(index, 1);
    this.filterService.setFilter(
      'responsiblePersons',
      this.assignedFilters.responsiblePersons,
      true,
      FilterComponent.findings
    );
  }

  deselectFacility(index: number): void {
    this.assignedFilters.facilities.facilities.splice(index, 1);
    this.filterService.setFilter(
      'facilities',
      {
        multiSelect: this.assignedFilters.facilities.multiSelect,
        subFolders: this.assignedFilters.facilities.subFolders,
        facilities: this.assignedFilters.facilities.facilities
      },
      true,
      FilterComponent.findings
    );
  }

  async fetchTranslations(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'FINDINGS_LIST.FILTER.ERROR.FETCH_FINDING_TYPES',
        'FINDINGS_LIST.FILTER.ERROR.FETCH_AUDIT_TYPES',
        'FINDINGS_LIST.FILTER.ERROR.FETCH_EMPLOYEES',
        'FINDINGS_LIST.FILTER.ERROR.FETCH_FACILITIES'
      ])
      .toPromise();
    this.errors.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.errors.fetchFindingTypesText = translation['FINDINGS_LIST.FILTER.ERROR.FETCH_FINDING_TYPES'];
    this.errors.fetchAuditTypesText = translation['FINDINGS_LIST.FILTER.ERROR.FETCH_AUDIT_TYPES'];
    this.errors.fetchEmployeesText = translation['FINDINGS_LIST.FILTER.ERROR.FETCH_EMPLOYEES'];
    this.errors.fetchFacilitiesText = translation['FINDINGS_LIST.FILTER.ERROR.FETCH_FACILITIES'];
  }
}

import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';
import * as _ from 'lodash';

// Models
import { FindingsStats } from '../../../shared/models/findings-stats.model';

// Services
import { ChartService } from '../../../shared/services/chart.service';

@Component({
  selector: 'gw-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  exportAs: 'gwBarChart'
})
export class BarChartComponent implements OnInit, OnChanges {
  @ViewChild('barChart', { static: true }) barChart: ElementRef;
  @Input() chartDataset: Array<FindingsStats> | any;
  @Input() totalCount: number;
  colors: Array<string>;
  firstrun = true;
  columns = 1;

  constructor(
    private chartService: ChartService,
    private translateService: TranslateService,
    public element: ElementRef
  ) {}

  ngOnInit(): void {
    this.colors = this.chartService.colors;
  }

  ngOnChanges(): void {
    if (!this.firstrun) {
      this.removeChart();
    }
    if (this.chartDataset && this.totalCount) {
      this.generateBarChart();
      this.columns = Math.floor((this.chartDataset.length - 1) / 5) + 1;
      this.firstrun = false;
    }
  }

  removeChart(selector = this.barChart.nativeElement): void {
    d3.select(selector)
      .select('svg')
      .remove();
  }

  generateBarChart(selector = this.barChart.nativeElement, chartDataset = this.chartDataset): void {
    const chart = d3.select(selector);
    this.chartService.generateBarChart(chart, chartDataset);
  }
}


<div class="tabs">
  <div class="row">
    <div class="columns">
      <ul class="tabs__menu">
        <li class="tabs__item" *ngFor="let tab of tabsArray"><a class="tabs__link" [routerLink]="[tab.url]" [queryParams]="{ page: 1 }" queryParamsHandling="merge" [class.tabs__link--active]="isLinkActive(tab.url)">
            <div class="tabs__link-content">{{ tab.title }}<span class="tabs__badge">({{ hasFilters ? counters[tab.amountId.filtered] + '/' + counters[tab.amountId.total] : counters[tab.amountId.total] }})</span></div></a></li>
      </ul>
    </div>
  </div>
</div>
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';

import moment from 'moment';

import { FilterService, LIMIT, RouterService } from 'gutwin-shared';

import { DashboardFilters } from '../models/dashboard-filters.model';
import { FacilitiesFilter } from '../models/facilities-filter.model';
import { AuditsFilters } from '../models/filters-audits.model';
import { FindingsFilters } from '../models/filters-findings.model';

import { FilterComponent } from '@gutwin-audit/shared/enums/filter-component.enum';

@Injectable()
export class FilterModuleService extends FilterService {
  filters: AuditsFilters | FindingsFilters | DashboardFilters;
  get auditsFilters(): AuditsFilters {
    return this.filters as AuditsFilters;
  }
  get findingsFilters(): FindingsFilters {
    return this.filters as FindingsFilters;
  }
  get dashboardFilters(): DashboardFilters {
    return this.filters as DashboardFilters;
  }

  constructor(
    protected ngZone: NgZone,
    protected route: ActivatedRoute,
    protected router: Router,
    protected routerService: RouterService
  ) {
    super(ngZone, route, router, routerService);
  }

  observeQueryParams(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.getQueryParams(params);
    });
  }

  getQueryParams(params: Params): { page: number; limit: number; filters: any } {
    let filters: AuditsFilters | FindingsFilters | DashboardFilters;
    switch (params['component']) {
      case FilterComponent.dashboard:
        filters = this.createDashboardFilters(params);
        break;
      case FilterComponent.findings:
        filters = this.createFindingsFilters(params);
        break;
      default:
        filters = this.createAuditsFilters(params);
    }

    const page = params['page'] && params['page'] > 0 ? parseInt(params['page'], 10) - 1 : 0;
    const limit = params['limit'] ? parseInt(params['limit'], 10) : LIMIT;

    this.setFilters(page, filters, limit, false);
    return { page, limit, filters };
  }

  createFindingsFilters(params: Params): FindingsFilters {
    return new FindingsFilters({
      search: params['search'],
      dateFrom: params['dateFrom'],
      dateTo: params['dateTo'],
      types: params['types'] ? JSON.parse(params['types']) : undefined,
      auditTypes: params['auditTypes'] ? JSON.parse(params['auditTypes']) : undefined,
      facilities: this.getFacilitiesFormRoute(params),
      responsiblePersons: params['responsiblePersons'] ? JSON.parse(params['responsiblePersons']) : undefined,
      component: params['component']
    });
  }

  createAuditsFilters(params: Params): AuditsFilters {
    return new AuditsFilters({
      name: params['name'],
      templateName: params['templateName'],
      dateFrom: params['dateFrom'],
      dateTo: params['dateTo'],
      types: params['types'] ? JSON.parse(params['types']) : undefined,
      location: params['location'],
      leadAuditors: params['leadAuditors'] ? JSON.parse(params['leadAuditors']) : undefined,
      coAuditors: params['coAuditors'] ? JSON.parse(params['coAuditors']) : undefined,
      facilities: this.getFacilitiesFormRoute(params),
      component: params['component']
    });
  }

  createDashboardFilters(params: Params): DashboardFilters {
    return new DashboardFilters({
      facilities: params['facilities'],
      dateFrom: params['dateFrom'],
      dateTo: params['dateTo'],
      auditTypes: params['auditTypes'],
      auditors: params['auditors']
    });
  }

  buildUrl(component?: FilterComponent): NavigationExtras {
    const navigationExtras = super.buildUrl(component);
    this.addFilterToQueryParams(navigationExtras.queryParams, 'name');
    this.addFilterToQueryParams(navigationExtras.queryParams, 'templateName');
    this.addFilterToQueryParams(navigationExtras.queryParams, 'search');
    this.addFilterToQueryParams(navigationExtras.queryParams, 'dateFrom');
    this.addFilterToQueryParams(navigationExtras.queryParams, 'dateTo');
    this.addFilterToQueryParams(navigationExtras.queryParams, 'types');
    this.addFilterToQueryParams(navigationExtras.queryParams, 'auditTypes');
    this.addFilterToQueryParams(navigationExtras.queryParams, 'location');
    this.addFilterToQueryParams(navigationExtras.queryParams, 'leadAuditors');
    this.addFilterToQueryParams(navigationExtras.queryParams, 'auditors');
    this.addFilterToQueryParams(navigationExtras.queryParams, 'coAuditors');
    this.addFilterToQueryParams(navigationExtras.queryParams, 'responsiblePersons');
    this.addFacilitiesToQueryParams(navigationExtras.queryParams);
    return navigationExtras;
  }

  addFacilitiesToQueryParams(queryParams: Params): void {
    if (this.filters?.facilities?.facilities?.length) {
      this.addObjectToQueryParams(queryParams, 'facilities');
    }
  }

  getFacilitiesFormRoute(params): FacilitiesFilter {
    let facilitiesFilter: FacilitiesFilter;
    if (params['facilities-facilities'] && JSON.parse(params['facilities-facilities']).length) {
      facilitiesFilter = new FacilitiesFilter({
        facilities: JSON.parse(params['facilities-facilities']),
        multiSelect: params['facilities-multiSelect'] === 'true',
        subFolders: params['facilities-subFolders'] === 'true'
      });
    }
    return facilitiesFilter;
  }

  setFilter(key: string, value: any, changeUrl = true, component?: FilterComponent): void {
    if (!this.filters) {
      this.filters = component === FilterComponent.findings ? new FindingsFilters() : new AuditsFilters();
    }
    super.setFilter(key, value, changeUrl, component);
  }

  setDashboardFilter(key: string, value: any): void {
    if (!this.filters || !(this.filters instanceof DashboardFilters)) {
      this.filters = new DashboardFilters();
    }
    super.setFilter(key, value, false);
  }

  getCurrentQueryParams(component?: FilterComponent): Params {
    const { queryParams } = this.buildUrl();
    switch (component) {
      case FilterComponent.findings:
      case FilterComponent.audits:
        const missingQueryParams = {
          dateTo: this.getDateQueryParam(queryParams, 'dateTo'),
          dateFrom: this.getDateQueryParam(queryParams, 'dateFrom')
        };

        return {
          ...queryParams,
          ...missingQueryParams,
          component
        };
      default:
        return {
          ...queryParams,
          component
        };
    }
  }

  getDateQueryParam(queryParams: Params, key: 'dateTo' | 'dateFrom'): string {
    const oppositeKey = key === 'dateTo' ? 'dateFrom' : 'dateTo';
    const yearsAmount = key === 'dateTo' ? 10 : -10;
    return queryParams[key]
      ? queryParams[key]
      : queryParams[oppositeKey]
      ? moment(queryParams[oppositeKey])
          .add(yearsAmount, 'year')
          .toString()
      : undefined;
  }
}

import { AttachmentLC } from '../models/attachment-lc.model';

import { RatingProgressData } from '../interfaces/rating-progress-data.interface';
import { UnitType } from '../interfaces/unit-type.interface';

import { ComplianceRatingId } from '../enums/compliance-rating-id.enum';
import { RelevanceRatingId } from '../enums/relevance-rating-id.enum';

export class CompanyUnit<T = any> {
  id: string;
  name: string;
  path?: Array<string>;
  type?: UnitType;
  backgroundImage?: AttachmentLC;
  linkedRequirementsCount?: number;
  children?: Array<CompanyUnit>;
  complianceRatingProgress?: Array<RatingProgressData<ComplianceRatingId>>;
  relevanceRatingProgress?: Array<RatingProgressData<RelevanceRatingId>>;
  descendants?: Array<CompanyUnit>;
  question?: T;
  ancestorsIds?: Array<string>;
  isOpened?: boolean;

  constructor(companyUnit: Partial<CompanyUnit>) {
    Object.assign(this, companyUnit);
  }
}

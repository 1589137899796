import { Pipe, PipeTransform } from '@angular/core';

import { TabInterface } from '../interfaces/tab.interface';

import { RouterService } from '../services/router.service';

@Pipe({
  name: 'isTabActive',
  pure: false
})
export class IsTabActivePipe implements PipeTransform {
  constructor(private routerService: RouterService) {}

  public transform(tab: TabInterface, selectedTab: TabInterface, routerTabs?: boolean): boolean {
    if (tab.shouldBeActive) {
      return tab.shouldBeActive();
    } else if (routerTabs) {
      return this.routerService.isLinkActive(tab.path, false);
    } else if (tab.id) {
      return tab.id === selectedTab?.id;
    }
  }
}

export class TreeNode {
  id: string | number;
  name?: string;
  children?: Array<TreeNode>;
  highlight?: boolean;
  openOnInit?: boolean;
  isOpened?: boolean;
  color?: string;
  type?: TreeNodeType;

  constructor(treeNode: Partial<TreeNode>) {
    Object.assign(this, treeNode);
  }
}

export interface TreeNodeZip {
  id: string | number;
  name: string;
  path: Array<string>;
  color?: string;
  ancestors?: Array<TreeNodeZip>;
}

export interface TreeNodeType {
  id: string;
  name: string;
  iconUrl?: string;
}

import { Component, Input, OnInit } from '@angular/core';

import { SimpleDropdownDirective } from 'gutwin-shared';

import { FilterComponent } from '@gutwin-audit/shared/enums/filter-component.enum';

import { FilterModuleService } from '../../services/filter-module.service';

@Component({
  selector: 'gw-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss'],
  exportAs: 'selectFilter'
})
export class SelectFilterComponent implements OnInit {
  @Input() dropdown: SimpleDropdownDirective;
  @Input() dataCollection: Array<any>;
  @Input() display: Array<string>;
  @Input() filterName: string;
  @Input() component: FilterComponent;
  @Input() splitter = ' ';
  filteredDataCollection = [];
  filterSearchQuery: string;

  constructor(private filterService: FilterModuleService) {}

  ngOnInit() {
    this.filterItems();
    this.selectChoosenFilters();
  }

  selectChoosenFilters(): void {
    for (const data of this.dataCollection) {
      let selected = false;
      if (this.filterService.filters && this.filterService.filters[this.filterName]) {
        for (const id of this.filterService.filters[this.filterName]) {
          if (id === data.id) {
            selected = true;
          }
        }
      }
      data.selected = selected;
    }
  }

  filterItems(query = ''): void {
    if (this.dataCollection) {
      this.filteredDataCollection = this.dataCollection.filter((item: any) => {
        const itemValueToDisplay = this.getValueToDisplay(item);
        return itemValueToDisplay.toLowerCase().includes(query.toLowerCase());
      });
    }
  }

  getValueToDisplay(item: any): string {
    let searchKey = '';
    for (const key of this.display) {
      searchKey = searchKey.concat(item[key], this.splitter);
    }
    return searchKey;
  }

  applyFilter(): void {
    const filterIds = new Array<string>();
    this.dataCollection.forEach((item: any) => {
      if (item.selected) {
        filterIds.push(item.id);
      }
    });
    this.filterService.setFilter(this.filterName, filterIds, true, this.component);
    this.dropdown.closeDropdown();
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gw-audits',
  templateUrl: './audits.component.html',
  styleUrls: ['./audits.component.scss']
})
export class AuditsComponent implements OnInit {

  constructor() { }

  ngOnInit() { }
}

import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';

import { ChosenFilter, ChosenFilters, displayUserName, Employee, TreeService } from 'gutwin-shared';

import { AuditType } from '@gutwin-audit/shared/models/audit-type.model';
import { DashboardFilters as DashboardFiltersModel } from '@gutwin-audit/shared/models/dashboard-filters.model';
import { Facility } from '@gutwin-audit/shared/models/facility.model';

import { DashboardFiltersType } from '@gutwin-audit/dashboard/shared/enums/dashboard-filters-type.enum';

@Injectable()
export class DashboardChosenFiltersService {
  constructor(private translateService: TranslateService, private treeService: TreeService) {
    this.fetchTranslation();
  }

  chosenFilters$: Subject<ChosenFilters> = new Subject<ChosenFilters>();
  chosenFiltersSub: Observable<ChosenFilters> = this.chosenFilters$.asObservable();

  private translation = {
    filterAreaLabel: '',
    filterDateLabel: '',
    filterDateFromLabel: '',
    filterDateToLabel: '',
    filterAuditorLabel: '',
    filterTypeLabel: '',
    facilityWithSubfolderLabel: ''
  };

  setChosenFilters(chosenFilters: ChosenFilters): void {
    this.chosenFilters$.next(chosenFilters);
  }

  prepareChosenFilters(
    filters: DashboardFiltersModel,
    users: Array<Employee>,
    types: Array<AuditType>,
    areas: Array<Facility>
  ): ChosenFilters {
    const { facilities, auditors, auditTypes, dateFrom, dateTo } = filters;
    const chosenFacilities: Array<ChosenFilter> = this.getChosenFacilitiesById(
      facilities?.facilities,
      areas,
      facilities?.subFolders
    );
    const chosenUsers: Array<ChosenFilter> = this.getChosenUsersById(auditors, users);
    const chosenAuditTypes: Array<ChosenFilter> = this.getChosenAuditTypeById(auditTypes, types);
    const list = [...chosenFacilities, ...chosenUsers, ...chosenAuditTypes];
    if (dateFrom || dateTo) {
      list.push(this.getChosenDateRange(dateFrom, dateTo));
    }
    return {
      list
    };
  }

  private getChosenItemsById<T extends { id?: string }>(ids: Array<string>, items: Array<T>): Array<T> {
    if (!ids) {
      return [];
    }
    return items.filter(item => ids.includes(item.id));
  }

  private convertFacilitiesToFilter(areas: Array<Facility>, includeSubnodes?: boolean): Array<ChosenFilter> {
    const subfolderLabel = includeSubnodes ? ` (${this.translation.facilityWithSubfolderLabel})` : '';
    return areas.map(area => ({
      name: this.translation.filterAreaLabel,
      id: area.id,
      type: DashboardFiltersType.facilities,
      value: `${area.name}${subfolderLabel}`
    }));
  }

  private convertUsersToFilter(users: Array<Employee>): Array<ChosenFilter> {
    return users.map(user => ({
      name: this.translation.filterAuditorLabel,
      id: user.id,
      type: DashboardFiltersType.auditors,
      value: displayUserName(user)
    }));
  }

  private convertAuditTypeToFilter(types: Array<AuditType>): Array<ChosenFilter> {
    return types.map(type => ({
      name: this.translation.filterTypeLabel,
      id: type.id,
      type: DashboardFiltersType.auditTypes,
      value: type.title
    }));
  }

  private getChosenFacilitiesById(
    ids: Array<string>,
    facilities: Array<Facility>,
    includeSubnodes?: boolean
  ): Array<ChosenFilter> {
    const chosenAreas = this.treeService.getNodesByIds<Facility>(ids, facilities);
    return this.convertFacilitiesToFilter(chosenAreas, includeSubnodes);
  }

  private getChosenUsersById(ids: Array<string>, users: Array<Employee>): Array<ChosenFilter> {
    const chosenUsers = this.getChosenItemsById<Employee>(ids, users);
    return this.convertUsersToFilter(chosenUsers);
  }

  private getChosenAuditTypeById(ids: Array<string>, types: Array<AuditType>): Array<ChosenFilter> {
    const chosenTypes = this.getChosenItemsById<AuditType>(ids, types);
    return this.convertAuditTypeToFilter(chosenTypes);
  }

  private getChosenDateRange(dateFrom: string, dateTo: string): ChosenFilter {
    const getDateRangeFilterName = (dateFrom: string, dateTo: string): string => {
      if (dateFrom && dateTo) {
        return this.translation.filterDateLabel;
      } else if (dateFrom) {
        return this.translation.filterDateFromLabel;
      } else if (dateTo) {
        return this.translation.filterDateToLabel;
      }
    };

    const getDateRangeFilterValue = (dateFrom: string, dateTo: string): string => {
      const startDate = dateFrom ? moment(dateFrom).format('DD MMMM YYYY') : '';
      const endDate = dateTo ? moment(dateTo).format('DD MMMM YYYY') : '';

      if (startDate && endDate) {
        return `${startDate} - ${endDate}`;
      } else if (startDate && !endDate) {
        return `${startDate}`;
      } else if (!startDate && endDate) {
        return `${endDate}`;
      }
    };

    return {
      name: getDateRangeFilterName(dateFrom, dateTo),
      type: DashboardFiltersType.datesRange,
      value: getDateRangeFilterValue(dateFrom, dateTo)
    };
  }

  private async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'DASHBOARD.FILTERS.FILTERS.AREA',
        'DASHBOARD.FILTERS.FILTERS.AUDITOR',
        'DASHBOARD.FILTERS.FILTERS.AUDIT_TYPE',
        'DASHBOARD.FILTERS.FILTERS.DATE',
        'DASHBOARD.FILTERS.FILTERS.DATE_FROM',
        'DASHBOARD.FILTERS.FILTERS.DATE_TO',
        'GLOBAL.LABEL.FACILITY_WITH_SUBFOLDERS'
      ])
      .toPromise();
    this.translation.filterAreaLabel = translation['DASHBOARD.FILTERS.FILTERS.AREA'];
    this.translation.filterAuditorLabel = translation['DASHBOARD.FILTERS.FILTERS.AUDITOR'];
    this.translation.filterTypeLabel = translation['DASHBOARD.FILTERS.FILTERS.AUDIT_TYPE'];
    this.translation.filterDateLabel = translation['DASHBOARD.FILTERS.FILTERS.DATE'];
    this.translation.filterDateFromLabel = translation['DASHBOARD.FILTERS.FILTERS.DATE_FROM'];
    this.translation.filterDateToLabel = translation['DASHBOARD.FILTERS.FILTERS.DATE_TO'];
    this.translation.facilityWithSubfolderLabel = translation['GLOBAL.LABEL.FACILITY_WITH_SUBFOLDERS'];
  }
}

import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import moment from 'moment';
// Gutwin Shared Library
import { LIMIT } from 'gutwin-shared';
// Models
import { AuditsAmount } from './../models/audits-amount.model';
import { AuditState } from './../models/audit.model';
// Services
import { AuditSyncData } from './audit.service';
import { AuditResponse, AuditsResponse } from './audit.convert.service';
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';

export interface AuditId {
  id: string;
  date: moment.Moment;
}

export interface AuditRequest {
  auditId: string;
  summary?: string;
  auditedAreas?:  Array<AuditedAreaRequest>;
}

export interface AuditedAreaRequest {
  auditedAreaId: string;
  summary: string;
}

@Injectable()
export class AuditOfflineService {
  auditsIdsOffline: Array<AuditId>;
  auditsIdsOfflineSource = new Subject<Array<AuditId>>();
  auditsIdsOfflineObservable = this.auditsIdsOfflineSource.asObservable();
  syncedAudit: AuditSyncData;
  syncedAuditSource = new Subject<AuditSyncData>();
  syncedAuditObservable = this.syncedAuditSource.asObservable();

  constructor(
    private storageService: StorageModuleService
  ) {}

  getAuditsIdsFromOfflineStore(): Promise<Array<AuditId>> {
    return this.storageService.getOfflineStore(StoragesNamesModule.auditsIdsOffline)
      .then(auditsIdsOffline => {
        this.auditsIdsOffline = JSON.parse(auditsIdsOffline);
        return this.auditsIdsOffline;
      });
  }

  getAuditsFromOfflineStore(state?: string, page = 0, limit = LIMIT): Promise<AuditsResponse> {
    const countAmount = (audits: Array<AuditResponse>): AuditsAmount => {
      const auditsAmount = new AuditsAmount();
      audits.forEach(audit => {
        switch (audit.state) {
          case AuditState.inProgress:
            auditsAmount.totalInProgress++;
            auditsAmount.filteredInProgress++;
            break;
          case AuditState.draft:
            auditsAmount.totalDrafts++;
            auditsAmount.filteredDrafts++;
            break;
          case AuditState.finished:
            auditsAmount.totalFinished++;
            auditsAmount.filteredFinished++;
            break;
        }
      });
      return auditsAmount;
    };

    const filterAudits = (audits: Array<AuditResponse>): Array<AuditResponse> => {
      let filteredAudits = audits;
      if (state) {
        filteredAudits = filteredAudits.filter((audit: AuditResponse) => {
          return audit.state === state;
        });
      }
      const index = {
        start: page * limit,
        end: page * limit + limit
      };
      filteredAudits = filteredAudits.slice(index.start, index.end);
      return filteredAudits;
    };

    const sortAudits = (audits: Array<AuditResponse>): Array<AuditResponse> => {
      const sortedAudits = audits.slice(0);
      sortedAudits.sort((audit1: AuditResponse, audit2: AuditResponse): number => {
        return moment(audit1.created_at).isSameOrBefore(moment(audit2.created_at)) ? 1 : -1;
      });
      return sortedAudits;
    };

    const removeAuditsPartlySaved = (audits: Array<AuditResponse>, auditsIds: Array<{ id: string }>): Array<AuditResponse> => {
      if (auditsIds) {
        const filteredAudits = audits.filter(audit => {
          const auditId = _.find(auditsIds, {id: audit.id});
          return auditId ? true : false;
        });
        return filteredAudits;
      } else {
        return [];
      }
    };

    return this.getAuditsIdsFromOfflineStore()
      .then(auditsIds => {
        return this.storageService.getOfflineStore(StoragesNamesModule.audits)
          .then((data: Array<AuditResponse>) => {
            if (data !== null) {
              const filteredAudits = removeAuditsPartlySaved(data, auditsIds);
              return {
                counters: countAmount(filteredAudits),
                audits: sortAudits(filterAudits(filteredAudits)),
                page
              };
            }
            return undefined;
          });
      });
  }

  getAuditFromOfflineStore(auditId: string): Promise<AuditResponse> {
    return this.storageService.getOfflineStore(StoragesNamesModule.audit + auditId);
  }

  appendAuditToAuditsIdsInOfflineStore(auditId: string): void {
    this.storageService.getOfflineStore(StoragesNamesModule.auditsIdsOffline)
      .then((data: any) => {
        data = JSON.parse(data);
        if (!data) {
          data = new Array<AuditId>();
        }
        const index = _.findIndex(data, {id: auditId});
        if (~index) {
          data[index] = {
            id: auditId,
            date: moment()
          };
        } else {
          data.push({
            id: auditId,
            date: moment()
          });
        }
        this.auditsIdsOffline = data;
        this.auditsIdsOfflineSource.next(this.auditsIdsOffline);
        this.storageService.setOfflineStore(StoragesNamesModule.auditsIdsOffline, JSON.stringify(data));
      });
  }

  updateAuditInOfflineStore(audit: AuditResponse): void {
    const updateAudit = (data: Array<AuditResponse>): Array<AuditResponse> => {
      const index = _.findIndex(data, {id: audit.id});
      ~index ? data[index] = audit : data.push(audit);
      return data;
    };

    this.storageService.setOfflineStore(StoragesNamesModule.audit + audit.id, audit);
    this.storageService.getOfflineStore(StoragesNamesModule.audits)
      .then((data: Array<AuditResponse>) => {
        if (!data) {
          data = new Array<AuditResponse>();
        }
        const updatedData = updateAudit(data);
        this.storageService.setOfflineStore(StoragesNamesModule.audits, updatedData);
      });
  }

  updateAuditFieldInOfflineStore(auditId: string, field: {key: string, value: any}): void {
    const updateAudit = (data: Array<AuditResponse>): Array<AuditResponse> => {
      const index = _.findIndex(data, {id: auditId});
      if (~index) {
        data[index][field.key] = field.value;
      }
      return data;
    };

    this.storageService.getOfflineStore(StoragesNamesModule.audit + auditId)
      .then((audit: AuditResponse) => {
        if (audit) {
          audit[field.key] = field.value;
          this.storageService.setOfflineStore(StoragesNamesModule.audit + auditId, audit);
        }
      });
    this.storageService.getOfflineStore(StoragesNamesModule.audits)
      .then((data: Array<AuditResponse>) => {
        if (!data) {
          data = new Array<AuditResponse>();
        }
        const updatedData = updateAudit(data);
        this.storageService.setOfflineStore(StoragesNamesModule.audits, updatedData);
      });
  }

  removeAuditFromOfflineStore(auditId: string): void {
    const removeAudit = (data: Array<AuditResponse>): Array<AuditResponse> => {
      const index = _.findIndex(data, {id: auditId});
      if (~index) {
        data.splice(index, 1);
      }
      return data;
    };

    this.storageService.removeFromOfflineStore(StoragesNamesModule.audit + auditId);
    this.storageService.getOfflineStore(StoragesNamesModule.audits)
      .then((data: Array<AuditResponse>) => {
        if (data !== null) {
          const updatedData = removeAudit(data);
          this.storageService.setOfflineStore(StoragesNamesModule.audits, updatedData);
        }
      });
    this.storageService.getOfflineStore(StoragesNamesModule.auditsIdsOffline)
      .then((data: any) => {
        data = JSON.parse(data);
        if (data) {
          const index = _.findIndex(data, {id: auditId});
          if (~index) {
            data.splice(index, 1);
          }
          this.storageService.setOfflineStore(StoragesNamesModule.auditsIdsOffline, JSON.stringify(data));
        }
      });
  }

  /**
   * Offline Requests
   */

  setSyncedAudit(auditSyncData: AuditSyncData): void {
    this.syncedAudit = auditSyncData;
    this.syncedAuditSource.next(this.syncedAudit);
  }

  getAuditRequestFromOfflineStore(auditId: string): Promise<AuditRequest> {
    return this.storageService.getOfflineStore(StoragesNamesModule.auditsRequests)
      .then((data: Array<AuditRequest>) => {
        if (data) {
          return _.find(data, {auditId: auditId});
        }
        return;
      });
  }

  getAuditedAreaRequestFromOfflineStore(auditId: string, auditedAreaId: string): Promise<AuditedAreaRequest> {
    const getAuditedAreaRequest = (data: Array<AuditRequest>): AuditedAreaRequest => {
      const audit = _.find(data, {auditId});
      if (audit && audit.auditedAreas) {
        const auditedArea = _.find(audit.auditedAreas, {auditedAreaId});
        return auditedArea;
      }
    };

    return this.storageService.getOfflineStore(StoragesNamesModule.auditsRequests)
      .then((data: Array<AuditRequest>) => {
        if (data) {
          return getAuditedAreaRequest(data);
        }
      });
  }


  updateAuditSummaryRequestInOfflineStore(auditId: string, summary: string): Promise<any> {
    const updateAuditRequest = (data: Array<AuditRequest>): Array<AuditRequest> => {
      const index = _.findIndex(data, {auditId: auditId});
      const newRequest = {
        auditId,
        summary,
        auditedAreas: ~index ? data[index].auditedAreas : undefined
      };
      ~index ? data[index] = newRequest : data.push(newRequest);
      return data;
    };

    return this.storageService.getOfflineStore(StoragesNamesModule.auditsRequests)
      .then((data: Array<AuditRequest>) => {
        if (!data) {
          data = new Array<AuditRequest>();
        }
        data = updateAuditRequest(data);
        return this.storageService.setOfflineStore(StoragesNamesModule.auditsRequests, data);
      });
  }

  updateAuditedAreaSummaryRequestInOfflineStore(auditId: string, auditedAreaId: string, summary: string): Promise<any> {
    const updateAuditedAreaRequest = (data: Array<AuditRequest>): Array<AuditRequest> => {
      const auditIndex = _.findIndex(data, {auditId: auditId});
      const audit: AuditRequest = ~auditIndex ? data[auditIndex] : {auditId};
      const auditedAreaRequest = {auditedAreaId, summary};
      if (audit.auditedAreas) {
        const index = _.findIndex(audit.auditedAreas, {auditedAreaId: auditedAreaId});
        ~index ? audit.auditedAreas[index] = auditedAreaRequest : audit.auditedAreas.push(auditedAreaRequest);
      } else {
        audit.auditedAreas = [auditedAreaRequest];
      }
      ~auditIndex ? data[auditIndex] = audit : data.push(audit);
      return data;
    };

    return this.storageService.getOfflineStore(StoragesNamesModule.auditsRequests)
      .then((data: Array<AuditRequest>) => {
        if (!data) {
          data = new Array<AuditRequest>();
        }
        data = updateAuditedAreaRequest(data);
        return this.storageService.setOfflineStore(StoragesNamesModule.auditsRequests, data);
      });
  }

  removeAuditAndAuditedAreasRequestsFromOfflineStore(auditId: string): Promise<any> {
    const removeAuditRequest = (data: Array<AuditRequest>): Array<AuditRequest> => {
      const index = _.findIndex(data, {auditId: auditId});
      if (~index) {
        data.splice(index, 1);
      }
      return data;
    };

    return this.storageService.getOfflineStore(StoragesNamesModule.auditsRequests)
      .then((data: Array<AuditRequest>) => {
        if (data) {
          data = removeAuditRequest(data);
          return this.storageService.setOfflineStore(StoragesNamesModule.auditsRequests, data);
        }
        return;
      });
  }

  removeAuditRequestFromOfflineStore(auditId: string): Promise<any> {
    const removeAuditRequest = (data: Array<AuditRequest>): Array<AuditRequest> => {
      const index = _.findIndex(data, {auditId: auditId});
      if (~index && data[index].auditedAreas && data[index].auditedAreas.length) {
        data[index].summary = undefined;
      } else if (~index) {
        data.splice(index, 1);
      }
      return data;
    };

    return this.storageService.getOfflineStore(StoragesNamesModule.auditsRequests)
      .then((data: Array<AuditRequest>) => {
        if (data) {
          data = removeAuditRequest(data);
          return this.storageService.setOfflineStore(StoragesNamesModule.auditsRequests, data);
        }
        return;
      });
  }

  removeAuditedAreaRequestFromOfflineStore(auditId: string, auditedAreaId: string): Promise<any> {
    const removeAuditedAreaRequest = (data: Array<AuditRequest>): Array<AuditRequest> => {
      const auditIndex = _.findIndex(data, {auditId: auditId});
      if (~auditIndex) {
        const audit = data[auditIndex];
        if (audit.auditedAreas) {
          const index = _.findIndex(audit.auditedAreas, {auditedAreaId: auditedAreaId});
          if (~index) {
            audit.auditedAreas.splice(index, 1);
          }
        }
        if (!(audit.auditedAreas && audit.auditedAreas.length) && !audit.summary) {
          data.splice(auditIndex, 1);
        }
      }
      return data;
    };

    return this.storageService.getOfflineStore(StoragesNamesModule.auditsRequests)
      .then((data: Array<AuditRequest>) => {
        if (!data) {
          data = new Array<AuditRequest>();
        }
        data = removeAuditedAreaRequest(data);
        return this.storageService.setOfflineStore(StoragesNamesModule.auditsRequests, data);
      });
  }
}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import moment from 'moment';

import { Day, Month, Year } from '../../interfaces/date-picker.interface';

@Component({
  selector: 'gw-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  exportAs: 'gwDatePicker'
})
export class DatePickerComponent implements OnChanges {
  @Input() date: moment.Moment;
  @Input() before: moment.Moment;
  @Input() after: moment.Moment;
  @Input() clearButton: boolean;
  @Output() setDate = new EventEmitter<moment.Moment>();

  years: Array<Year> = [];
  year: Year;
  month: Month;
  start: moment.Moment;

  ngOnChanges(): void {
    this.setup();
    this.prepare();
  }

  select(day: Day): void {
    if (!day.disabled) {
      this.date = day.date;
      this.setDate.emit(this.date);
      return this.prepare();
    }
  }

  clearDate(): void {
    this.date = undefined;
    this.setDate.emit(this.date);
  }

  selectYear(year: Year): void {
    const yearStep = year.id - this.start.year();
    this.start.add(yearStep, 'year');
    this.prepare();
  }

  moveMonth(step: number): void {
    this.start.add(step, 'month');
    this.prepare();
  }

  private setup(): void {
    this.start = this.date ? moment(this.date) : this.start ?? moment().startOf('day');
    this.generateYears();
  }

  private prepare(): void {
    this.year = this.buildYear(this.start);
    this.month = this.buildMonth(moment(this.start));
  }

  private generateYears(): void {
    const year = moment().year();
    this.years = [];
    for (let currentYear = year + 5; currentYear >= year - 100; currentYear--) {
      this.years.push({
        id: currentYear,
        name: `${currentYear}`
      });
    }
  }

  private buildYear(time: moment.Moment): Year {
    const year = time.get('year');
    return {
      id: year,
      name: `${year}`
    };
  }

  private buildMonth(time: moment.Moment): Month {
    const weeksInMonth = 5;
    const start = time.startOf('month');
    const weeks = (() => {
      const results = [];
      for (let currentWeek = 0; currentWeek <= weeksInMonth; currentWeek++) {
        results.push(this.buildWeek(moment(start).add(currentWeek, 'weeks'), moment(start).month()));
      }
      return results;
    })();
    return {
      weeks,
      name: time.format('MMMM')
    };
  }

  private buildWeek(time: moment.Moment, month: number): Array<Day> {
    const start = time.startOf('isoWeek');
    const days = [0, 1, 2, 3, 4, 5, 6].map(d => {
      const day = moment(start).add(d, 'days');
      const withinMonth = day.month() === month;
      const withinLimits = this.withinLimits(day);
      return {
        date: day,
        selected: this.isSelected(day) && withinMonth,
        disabled: !(withinMonth && withinLimits)
      };
    });
    return days;
  }

  private withinLimits(day: moment.Moment): boolean {
    let withinLimits = true;
    if (this.before) {
      withinLimits = withinLimits && day.isSameOrBefore(this.before, 'day');
    }
    if (this.after) {
      withinLimits = withinLimits && day.isSameOrAfter(this.after, 'day');
    }
    return withinLimits;
  }

  private isSelected(day: moment.Moment): boolean {
    return this.date && day.isSame(this.date, 'day');
  }
}

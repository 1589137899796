import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { Titles } from '../../interfaces/titles.interface';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-edit-titles',
  templateUrl: './edit-titles.component.html',
  styleUrls: ['./edit-titles.component.scss'],
  exportAs: 'gwEditTitles'
})
export class EditTitlesComponent implements OnInit {
  @Input() firstTitle: string;
  @Input() secondTitle: string;
  @Input() isInactive = false;
  @Input() isEditMode: boolean;
  @Input() isUserTypeClient = false;
  @Input() small: boolean;

  @Output() togglePopoverStatus = new EventEmitter<void>();
  @Output() submitChange = new EventEmitter<Titles>();

  form: FormGroup;
  submitted = false;
  isSecondTitleVisible = false;

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.isSecondTitleVisible = this.secondTitle !== undefined;
    this.initForm();
    this.resetForm();
  }

  initForm(): void {
    const formControls = {
      firstTitle: ['', Validators.required]
    };

    if (this.isSecondTitleVisible) {
      formControls['secondTitle'] = [''];
    }

    this.form = this.formBuilder.group(formControls);
  }

  resetForm(): void {
    this.form.controls['firstTitle'].setValue(this.firstTitle || '');

    if (this.isSecondTitleVisible) {
      this.form.controls['secondTitle'].setValue(this.secondTitle || '');
    }
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  submitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.submitChange.emit(this.form.value);
    }
  }

  increaseOpenedEdits(): void {
    this.store.dispatch(new IncreaseOpenedEdits());
    this.togglePopoverStatus.emit();
  }

  decreaseOpenedEdits(): void {
    this.store.dispatch(new DecreaseOpenedEdits());
    this.togglePopoverStatus.emit();
  }
}

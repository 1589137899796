import { ErrorHandler } from '@angular/core';

import * as Sentry from '@sentry/browser';

class SentryErrorHandler implements ErrorHandler {
  constructor(private dsn: string) {
    Sentry.init({ dsn: this.dsn });
  }

  handleError(error): void {
    Sentry.captureException(error.originalError || error);
  }
}

export function getErrorHandler(dsn: string, prod?: boolean): ErrorHandler {
  return prod ? new SentryErrorHandler(dsn) : new ErrorHandler();
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ExtendedScroll } from '../interfaces/extended-scroll.interface';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {
  constructor(private route: Router) {}

  navigateWithoutScrollTop(customScroll: ExtendedScroll, lastScrollPos: number): void {
    this.route.navigate(customScroll.path, customScroll.extras).then(() => {
      window.scrollTo({ top: lastScrollPos });
    });
  }
}

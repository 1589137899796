import { CompanySubmoduleValue } from '../interfaces/company-submodule-value.interface';

import { CompanySubmodulesNames } from '../enums/company-submodules.enum';

export class LegalComplianceLicenses {
  submodules: Record<CompanySubmodulesNames, CompanySubmoduleValue>;

  constructor(legalComplianceLicenses?: Partial<LegalComplianceLicenses>) {
    this.submodules = legalComplianceLicenses.submodules;
  }
}

import gql from 'graphql-tag';

import { EmployeeFragment } from './employee.fragment';

export const ProfileFragment = gql`
  fragment ProfileFragment on Profile {
    email
    language
    employees {
      ...EmployeeFragment
    }
    company {
      id
      name
    }
  }
  ${EmployeeFragment}
`;

import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

// Services
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

import { AuditType } from '@gutwin-audit/shared/models/audit-type.model';
// Models
import { RatingScale } from '@gutwin-audit/shared/models/rating-scale.model';

import { RatingScalesService } from '@gutwin-audit/shared/services/raiting-scales.service';

@Component({
  selector: 'gw-add-rating-scale',
  templateUrl: './add-rating-scale.component.html',
  styleUrls: ['./add-rating-scale.component.scss'],
  exportAs: 'gwAddRatingScale'
})
export class AddRatingScaleComponent implements OnInit {
  @Input() ratingScale: RatingScale;
  @Input() auditTypes: Array<AuditType>;
  addRatingScaleForm: FormGroup;
  submitted = false;
  errors = {
    connectionTitle: '',
    createRatingScale: '',
    updateRatingScale: ''
  };
  success = {
    createRatingScaleTitle: '',
    createRatingScaleText: '',
    updateRatingScaleTitle: '',
    updateRatingScaleText: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private ratingScalesService: RatingScalesService
  ) {}

  ngOnInit(): void {
    this.fetchTranslations();
    this.initForm();
    if (this.ratingScale) {
      this.initValues();
    }
  }

  initForm(): void {
    const auditTypesValidator = this.ratingScale && this.ratingScale.default ? null : Validators.required;

    this.addRatingScaleForm = this.formBuilder.group({
      name: ['', Validators.required],
      auditTypes: [[], auditTypesValidator]
    });
  }

  initValues(): void {
    this.setControlValue(this.ratingScale.name, 'name');
    this.setControlValue(this.ratingScale.auditTypes, 'auditTypes');
  }

  setControlValue(data: any, key: string): void {
    this.addRatingScaleForm.controls[key].setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  createRatingScale(): Promise<any> {
    const ratingScaleToCreate = new RatingScale(this.addRatingScaleForm.value);
    return this.ratingScalesService
      .createRatingScale(ratingScaleToCreate)
      .then(ratingScale => {
        this.notificationsService.success(this.success.createRatingScaleTitle, this.success.createRatingScaleText);
        return {
          status: 'create',
          ratingScale
        };
      })
      .catch(error => {
        this.notificationsService.error(this.errors.connectionTitle, this.errors.createRatingScale);
        throw error;
      });
  }

  updateRatingScale(): Promise<any> {
    const ratingScaleToUpdate = new RatingScale({ ...this.ratingScale, ...this.addRatingScaleForm.value });
    return this.ratingScalesService
      .updateRatingScale(ratingScaleToUpdate)
      .then(ratingScale => {
        this.notificationsService.success(this.success.updateRatingScaleTitle, this.success.updateRatingScaleText);
        return {
          status: 'update',
          ratingScale
        };
      })
      .catch(error => {
        this.notificationsService.error(this.errors.connectionTitle, this.errors.updateRatingScale);
        throw error;
      });
  }

  submitAddRatingScaleForm(): Promise<any> {
    this.submitted = true;
    if (this.addRatingScaleForm.valid) {
      if (this.ratingScale) {
        return this.updateRatingScale();
      } else {
        return this.createRatingScale();
      }
    } else {
      return new Promise((resolve, reject) => reject({ invalid: true }));
    }
  }

  async fetchTranslations(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'ADMIN_SETTINGS.RATING_SCALES.SUCCESS.CREATE_RATING_SCALE_TITLE',
        'ADMIN_SETTINGS.RATING_SCALES.SUCCESS.CREATE_RATING_SCALE_TEXT',
        'ADMIN_SETTINGS.RATING_SCALES.ERROR.CREATE_RATING_SCALE',
        'ADMIN_SETTINGS.RATING_SCALES.ERROR.UPDATE_RATING_SCALE',
        'ADMIN_SETTINGS.RATING_SCALES.SUCCESS.UPDATE_RATING_SCALE_TITLE',
        'ADMIN_SETTINGS.RATING_SCALES.SUCCESS.UPDATE_RATING_SCALE_TEXT'
      ])
      .toPromise();
    this.errors.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.errors.createRatingScale = translation['ADMIN_SETTINGS.RATING_SCALES.ERROR.CREATE_RATING_SCALE'];
    this.errors.updateRatingScale = translation['ADMIN_SETTINGS.RATING_SCALES.ERROR.UPDATE_RATING_SCALE'];
    this.success.createRatingScaleTitle = translation['ADMIN_SETTINGS.RATING_SCALES.SUCCESS.CREATE_RATING_SCALE_TITLE'];
    this.success.createRatingScaleText = translation['ADMIN_SETTINGS.RATING_SCALES.SUCCESS.CREATE_RATING_SCALE_TEXT'];
    this.success.updateRatingScaleTitle = translation['ADMIN_SETTINGS.RATING_SCALES.SUCCESS.UPDATE_RATING_SCALE_TITLE'];
    this.success.updateRatingScaleText = translation['ADMIN_SETTINGS.RATING_SCALES.SUCCESS.UPDATE_RATING_SCALE_TEXT'];
  }
}

import 'froala-editor/js/languages/de.js';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FroalaEditorOptions } from '../../interfaces/froala-editor-options.interface';

import { DEFAULT_FROALA_OPTIONS } from '../../constants/froala.constants';

@Component({
  selector: 'gw-froala-editor',
  templateUrl: './froala-editor.component.html',
  styleUrls: ['./froala-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FroalaEditorComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FroalaEditorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() id?: number;
  @Input() set froalaOptions(froalaOptions: FroalaEditorOptions) {
    if (froalaOptions) this.froalaEditorOptions = froalaOptions;
  }
  @Input() name?: number;
  @Input() placeholderText?: string;
  @Input() tabindex?: number;

  text: string;
  disabled: boolean;
  froalaEditorOptions: FroalaEditorOptions = DEFAULT_FROALA_OPTIONS;
  placeholder: string;
  translation = {
    placeholder: {
      noDataProvided: '',
      enterData: ''
    }
  };

  destroy$ = new Subject<void>();

  constructor(private changeDetector: ChangeDetectorRef, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.fetchTranslation();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onChange: (_: string) => void = () => {};

  writeValue(value: string): void {
    this.text = value;
    this.changeDetector.markForCheck();
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.placeholder = this.disabled
      ? this.translation.placeholder.noDataProvided
      : this.translation.placeholder.enterData;

    this.changeDetector.markForCheck();
  }

  async initFroala(editor: any): Promise<void> {
    const translation = await this.translateService.get(['GUTWIN_SHARED.ACTION.ENTER_DATA']).toPromise();
    this.froalaEditorOptions.placeholderText = this.placeholderText || translation['GUTWIN_SHARED.ACTION.ENTER_DATA'];
    editor.initialize();
  }

  changeText(text: string): void {
    this.onChange(text);
  }

  fetchTranslation(): void {
    this.translateService
      .stream(['GUTWIN_SHARED.ACTION.ENTER_DATA', 'GUTWIN_SHARED.PLACEHOLDER.NO_DATA_PROVIDED'])
      .pipe(takeUntil(this.destroy$))
      .subscribe(translation => {
        this.translation.placeholder.enterData = translation['GUTWIN_SHARED.ACTION.ENTER_DATA'];
        this.translation.placeholder.noDataProvided = translation['GUTWIN_SHARED.PLACEHOLDER.NO_DATA_PROVIDED'];
        this.placeholder = this.disabled
          ? this.translation.placeholder.noDataProvided
          : this.translation.placeholder.enterData;
        this.froalaEditorOptions.placeholderText = this.placeholderText || this.translation.placeholder.enterData;
        this.changeDetector.markForCheck();
      });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { NavigationGroupToDisplay } from '../interfaces/navigation-elements-to-display.interface';

@Pipe({
  name: 'countNavigationGroupPadding'
})
export class CountNavigationGroupPaddingPipe implements PipeTransform {
  public transform(element: NavigationGroupToDisplay): string {
    return `${element.active ? element.level * 0.7 + 'rem' : element.level * 1 + 'rem'}`;
  }
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// Gutwin Shared Library
import { HelperUtil } from 'gutwin-shared';

import { RatingScale } from '@gutwin-audit/shared/models/rating-scale.model';
// Models
import { Rating } from '../../../shared/models/rating.model';

import { RatingScalesService } from '@gutwin-audit/shared/services/raiting-scales.service';
// Services
import { RatingsService } from '../../../shared/services/ratings.service';

@Component({
  selector: 'gw-single-rating-scale',
  templateUrl: './single-rating-scale.component.html',
  styleUrls: ['./single-rating-scale.component.scss']
})
export class SingleRatingScaleComponent implements OnInit, OnDestroy {
  @ViewChild('ratingForm', { static: true }) ratingForm: FormGroup;
  ratings: Array<Rating>;
  ratingScale: RatingScale;
  ratingsToEdit: Array<Rating>;
  choosenRating: Rating;
  scaleRatingsForm: FormGroup;
  scaleRatingsValidity: {
    all: number;
    numeric: number;
    percentage: number;
  };
  ratingValueRange = {
    max: 999999.99,
    min: 0
  };
  submitted = false;
  notify = {
    error: {
      connection: '',
      updateRatingTitle: '',
      updateRatingText: '',
      createRatingTitle: '',
      createRatingText: '',
      removeRatingTitle: '',
      removeRatingText: '',
      removeUsedRatingTitle: '',
      removeUsedRatingText: '',
      updateScaleRatings: ''
    },
    success: {
      title: '',
      updateRatingTitle: '',
      updateRatingText: '',
      createRatingTitle: '',
      createRatingText: '',
      removeRatingTitle: '',
      removeRatingText: '',
      updateScaleRatings: ''
    }
  };
  scaleRatingsFormSubscription: Subscription;

  constructor(
    private ratingScalesService: RatingScalesService,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private ratingsService: RatingsService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.ratingScale = this.route.snapshot.data['ratingScale'];
    this.ratings = this.ratingScale.ratings;
    this.updateScaleRatingsValidity();
    this.initScaleRatingsForm();
    this.ratingsToEdit = this.createRatingsToEdit(this.ratings);
    if (this.ratingsToEdit.length === 0) {
      this.addNewRating(false);
    }
    this.selectRating(false);
    this.fetchTranslation();
  }

  ngOnDestroy(): void {
    if (this.scaleRatingsFormSubscription) this.scaleRatingsFormSubscription.unsubscribe();
  }

  initScaleRatingsForm(): void {
    this.scaleRatingsForm = this.formBuilder.group({
      usesNumericAverages: [
        { value: this.ratingScale.usesNumericAverages, disabled: !this.canActivateAverage('numeric') }
      ],
      usesPercentageAverages: [
        { value: this.ratingScale.usesPercentageAverages, disabled: !this.canActivateAverage('percentage') }
      ]
    });

    this.scaleRatingsFormSubscription = this.scaleRatingsForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((formValue: any) => {
        this.submitScaleRatings(formValue);
      });
  }

  canActivateAverage(ratingsAverageName: string): boolean {
    return this.scaleRatingsValidity[ratingsAverageName] === this.scaleRatingsValidity.all;
  }

  updateScaleRatingsFormValues(): void {
    const getControlValue = (ratingsAverageName: string, scaleValueName: string): boolean => {
      return this.canActivateAverage(ratingsAverageName) ? this.ratingScale[scaleValueName] : false;
    };

    this.updateScaleRatingsValidity();

    this.scaleRatingsForm.get('usesNumericAverages').setValue(getControlValue('numeric', 'usesNumericAverages'));
    this.canActivateAverage('numeric')
      ? this.scaleRatingsForm.get('usesNumericAverages').enable()
      : this.scaleRatingsForm.get('usesNumericAverages').disable();

    this.scaleRatingsForm
      .get('usesPercentageAverages')
      .setValue(getControlValue('percentage', 'usesPercentageAverages'));
    this.canActivateAverage('percentage')
      ? this.scaleRatingsForm.get('usesPercentageAverages').enable()
      : this.scaleRatingsForm.get('usesPercentageAverages').disable();

    this.scaleRatingsForm.updateValueAndValidity();
  }

  fetchRatings(): Promise<void> {
    return this.ratingScalesService.getRatingScale(this.ratingScale.id).then((ratingScale: RatingScale) => {
      this.ratings = ratingScale.ratings;
      this.ratingsToEdit = this.createRatingsToEdit(ratingScale.ratings);
    });
  }

  createRatingsToEdit(ratings: Array<Rating>): Array<Rating> {
    const ratingsToEdit = [];
    ratings.forEach((rating: Rating) => {
      const copy = Object.assign({}, rating);
      ratingsToEdit.push(new Rating(copy));
    });
    return ratingsToEdit;
  }

  selectRating(withCheck = false, ratingId?: string): void {
    let rating = this.ratingsToEdit[0];
    if (ratingId) {
      rating = this.findOriginalRating(ratingId);
    }
    if (withCheck && this.choosenRating && this.isRatingChanged(this.choosenRating)) {
      return;
    }
    this.choosenRating = Object.assign({}, rating);
    this.submitted = false;
  }

  findOriginalRating(choosenRatingId: string): Rating {
    return this.ratingsToEdit.find(elem => {
      return elem.id === choosenRatingId;
    });
  }

  isRatingChanged(choosenRating): boolean {
    const rating = this.findOriginalRating(choosenRating.id);
    const isNotSame = this.ratingsToEdit.length !== this.ratings.length;
    const isChanged = JSON.stringify(rating) !== JSON.stringify(choosenRating);
    return isChanged || isNotSame;
  }

  addNewRating(withCheck = false): void {
    if (withCheck && this.choosenRating && this.isRatingChanged(this.choosenRating)) {
      return;
    }
    const newRating = { id: 'newRating' };
    this.ratingsToEdit.push(new Rating(newRating));
    this.choosenRating = Object.assign({}, this.findOriginalRating('newRating'));
    this.submitted = false;
  }

  resetForm(choosenRatingId: string): void {
    if (choosenRatingId === 'newRating') {
      this.choosenRating = Object.assign({}, this.ratingsToEdit[0]);
      this.ratingsToEdit.pop();
    } else {
      this.choosenRating = Object.assign({}, this.findOriginalRating(choosenRatingId));
    }
    this.submitted = false;
  }

  convertFormToRating(form: FormGroup): Rating {
    return new Rating({
      id: form.value.rateId,
      name: form.value.rateName,
      abbreviation: form.value.rateAbbreviation,
      color: form.value.rateColor,
      skipForAverage: form.value.rateSkipAverage,
      numericValue: form.value.rateNumericValue,
      percentageValue: form.value.ratePercentageValue
    });
  }

  submitRating(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      const shouldCreateRating = form.value.rateId === 'newRating';
      const rating = this.convertFormToRating(form);
      const promise = shouldCreateRating
        ? this.ratingsService.createRating(rating, this.ratingScale)
        : this.ratingsService.updateRating(rating, this.ratingScale);
      promise
        .then(async (ratingResponse: Rating) => {
          await this.fetchRatings();
          this.updateScaleRatingsFormValues();
          this.choosenRating = new Rating(ratingResponse);
          const notify = {
            title: shouldCreateRating ? this.notify.success.createRatingTitle : this.notify.success.updateRatingTitle,
            content: shouldCreateRating ? this.notify.success.createRatingText : this.notify.success.updateRatingText
          };
          this.notificationsService.success(notify.title, notify.content);
        })
        .catch(() => {
          const notify = {
            title: shouldCreateRating ? this.notify.error.createRatingTitle : this.notify.error.updateRatingTitle,
            content: shouldCreateRating ? this.notify.error.createRatingText : this.notify.error.updateRatingText
          };
          this.notificationsService.error(notify.title, notify.content);
        });
    }
  }

  removeRating(form: FormGroup): void {
    this.ratingsService
      .removeRating(form.value.rateId)
      .then(async () => {
        await this.fetchRatings().then(() => {
          if (this.ratingsToEdit.length === 0) {
            this.addNewRating(false);
          }
          this.selectRating(false);
        });
        this.updateScaleRatingsFormValues();
        this.notificationsService.success(this.notify.success.removeRatingTitle, this.notify.success.removeRatingText);
      })
      .catch(error => {
        if (error && error.error && error.error.errors === 'Cannot delete a used Rating') {
          this.notificationsService.error(
            this.notify.error.removeUsedRatingTitle,
            this.notify.error.removeUsedRatingText
          );
        } else {
          this.notificationsService.error(this.notify.error.removeRatingTitle, this.notify.error.removeRatingText);
        }
      });
  }

  submitScaleRatings(formValue: { usesNumericAverages: boolean; usesPercentageAverages: boolean }): void {
    const hasValuesChanged = (): boolean => {
      return (
        this.ratingScale.usesNumericAverages !== formValue.usesNumericAverages ||
        this.ratingScale.usesPercentageAverages !== formValue.usesPercentageAverages
      );
    };

    if (hasValuesChanged()) {
      const scaleToUpdate = Object.assign(this.ratingScale, formValue);
      this.ratingScalesService
        .updateRatingScale(scaleToUpdate)
        .then((ratingScale: RatingScale) => {
          this.ratingScale = ratingScale;
          this.notificationsService.success(this.notify.success.title, this.notify.success.updateScaleRatings);
        })
        .catch(() => {
          this.notificationsService.error(this.notify.error.connection, this.notify.error.updateScaleRatings);
        });
    }
  }

  updateScaleRatingsValidity(): void {
    this.scaleRatingsValidity = {
      all: this.getAllRatingsForAverage().length,
      numeric: this.getValidRatingsForAverage('numericValue').length,
      percentage: this.getValidRatingsForAverage('percentageValue').length
    };
  }

  getAllRatingsForAverage(): Array<Rating> {
    return this.ratings.filter(rating => !rating.skipForAverage);
  }

  getValidRatingsForAverage(attributeName: string): Array<Rating> {
    return this.ratings.filter(rating => !rating.skipForAverage && !HelperUtil.isNullable(rating[attributeName]));
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ACTION.SUCCESS',
        'ADMIN_SETTINGS.RATING_SCALE.ERROR.UPDATE_RATING_TITLE',
        'ADMIN_SETTINGS.RATING_SCALE.ERROR.UPDATE_RATING_TEXT',
        'ADMIN_SETTINGS.RATING_SCALE.ERROR.CREATE_RATING_TITLE',
        'ADMIN_SETTINGS.RATING_SCALE.ERROR.CREATE_RATING_TEXT',
        'ADMIN_SETTINGS.RATING_SCALE.ERROR.REMOVE_RATING_TITLE',
        'ADMIN_SETTINGS.RATING_SCALE.ERROR.REMOVE_RATING_TEXT',
        'ADMIN_SETTINGS.RATING_SCALE.ERROR.REMOVE_USED_RATING_TITLE',
        'ADMIN_SETTINGS.RATING_SCALE.ERROR.REMOVE_USED_RATING_TEXT',
        'ADMIN_SETTINGS.RATING_SCALE.ERROR.UPDATE_SCALE_RATINGS',
        'ADMIN_SETTINGS.RATING_SCALE.SUCCESS.UPDATE_RATING_TITLE',
        'ADMIN_SETTINGS.RATING_SCALE.SUCCESS.UPDATE_RATING_TEXT',
        'ADMIN_SETTINGS.RATING_SCALE.SUCCESS.CREATE_RATING_TITLE',
        'ADMIN_SETTINGS.RATING_SCALE.SUCCESS.CREATE_RATING_TEXT',
        'ADMIN_SETTINGS.RATING_SCALE.SUCCESS.REMOVE_RATING_TITLE',
        'ADMIN_SETTINGS.RATING_SCALE.SUCCESS.REMOVE_RATING_TEXT',
        'ADMIN_SETTINGS.RATING_SCALE.SUCCESS.UPDATE_SCALE_RATINGS'
      ])
      .toPromise();
    this.notify.error.connection = translation['GLOBAL.ERROR.CONNECTION'];
    this.notify.error.updateRatingTitle = translation['ADMIN_SETTINGS.RATING_SCALE.ERROR.UPDATE_RATING_TITLE'];
    this.notify.error.updateRatingText = translation['ADMIN_SETTINGS.RATING_SCALE.ERROR.UPDATE_RATING_TEXT'];
    this.notify.error.createRatingTitle = translation['ADMIN_SETTINGS.RATING_SCALE.ERROR.CREATE_RATING_TITLE'];
    this.notify.error.createRatingText = translation['ADMIN_SETTINGS.RATING_SCALE.ERROR.CREATE_RATING_TEXT'];
    this.notify.error.removeRatingTitle = translation['ADMIN_SETTINGS.RATING_SCALE.ERROR.REMOVE_RATING_TITLE'];
    this.notify.error.removeRatingText = translation['ADMIN_SETTINGS.RATING_SCALE.ERROR.REMOVE_RATING_TEXT'];
    this.notify.error.removeUsedRatingTitle = translation['ADMIN_SETTINGS.RATING_SCALE.ERROR.REMOVE_USED_RATING_TITLE'];
    this.notify.error.removeUsedRatingText = translation['ADMIN_SETTINGS.RATING_SCALE.ERROR.REMOVE_USED_RATING_TEXT'];
    this.notify.error.updateScaleRatings = translation['ADMIN_SETTINGS.RATING_SCALE.ERROR.UPDATE_SCALE_RATINGS'];
    this.notify.success.title = translation['GLOBAL.ACTION.SUCCESS'];
    this.notify.success.updateRatingTitle = translation['ADMIN_SETTINGS.RATING_SCALE.SUCCESS.UPDATE_RATING_TITLE'];
    this.notify.success.updateRatingText = translation['ADMIN_SETTINGS.RATING_SCALE.SUCCESS.UPDATE_RATING_TEXT'];
    this.notify.success.createRatingTitle = translation['ADMIN_SETTINGS.RATING_SCALE.SUCCESS.CREATE_RATING_TITLE'];
    this.notify.success.createRatingText = translation['ADMIN_SETTINGS.RATING_SCALE.SUCCESS.CREATE_RATING_TEXT'];
    this.notify.success.removeRatingTitle = translation['ADMIN_SETTINGS.RATING_SCALE.SUCCESS.REMOVE_RATING_TITLE'];
    this.notify.success.removeRatingText = translation['ADMIN_SETTINGS.RATING_SCALE.SUCCESS.REMOVE_RATING_TEXT'];
    this.notify.success.updateScaleRatings = translation['ADMIN_SETTINGS.RATING_SCALE.SUCCESS.UPDATE_SCALE_RATINGS'];
  }
}

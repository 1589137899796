import { Component, EventEmitter, Input, Output } from '@angular/core';

import moment from 'moment';

import { DecisionDatesPopoverId } from '../../enums/decision-dates-popover-id.enum';
import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-sidebar-decision-dates',
  templateUrl: './sidebar-decision-dates.component.html'
})
export class SidebarDecisionDatesComponent {
  @Input() isEditMode: boolean;
  @Input() decisionDate: moment.Moment;
  @Input() issuedOn: moment.Moment;
  @Input() validFrom: moment.Moment;
  @Input() userType: UserType;
  @Output() changeDecisionDate = new EventEmitter<moment.Moment>();
  @Output() changeIssuedOn = new EventEmitter<moment.Moment>();
  @Output() changeValidFrom = new EventEmitter<moment.Moment>();

  readonly USER_TYPE = UserType;
  readonly DECISION_DATES_POPOVER_ID = DecisionDatesPopoverId;

  onChangeDecisionDate(date: moment.Moment): void {
    this.changeDecisionDate.emit(date);
  }

  onChangeIssuedOn(date: moment.Moment): void {
    this.changeIssuedOn.emit(date);
  }

  onChangeValidFrom(date: moment.Moment): void {
    this.changeValidFrom.emit(date);
  }
}


// Gutwin Shared Library
import { Attachment, Employee, Language } from 'gutwin-shared';

export class Company {
  id?: string;
  name: string;
  description: string;
  subdomain: string;
  language: Language;
  employee: Employee;
  logo?: Attachment;
  hasNumericAverages: boolean;
  hasPercentageAverages: boolean;

  constructor(company: Partial<Company>) {
    this.id = company.id;
    this.name = company.name;
    this.description = company.description;
    this.subdomain = company.subdomain;
    this.language = company.language;
    this.employee = company.employee;
    this.logo = company.logo ? new Attachment(company.logo) : undefined;
    this.hasNumericAverages = company.hasNumericAverages;
    this.hasPercentageAverages = company.hasPercentageAverages;
  }
}

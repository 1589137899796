
<gw-page-header>
  <ng-container page-header-left="page-header-left">
    <ul class="header__menu header__menu--left">
      <li class="header__item"><a class="button button--link header__button--transparent header__button--menu" (click)="auditNavigationService.toggleNavigation()"><i class="gw-hamburger button__icon"></i></a></li>
      <li class="header__item header__item--title">{{ 'AUDITS_LIST.PAGE_HEADER' | translate }}</li>
    </ul>
  </ng-container>
  <ng-container page-header-right="page-header-right">
    <ul class="header__menu header__menu--right">
      <li class="header__item">
        <button class="button--tertiary header__button" type="button" (click)="toggleFilters()" [class.header__button--active]="filtersOpened"><i class="gw-filter button__icon"></i></button>
      </li>
      <li class="header__item" *ngIf="canCreateAudit &amp;&amp; offlineService.online"><a class="button--primary header__button--custom" [routerLink]="['/audits/create-audit']" (click)="clearAuditStorage()"><span class="show-for-medium">{{ 'AUDITS_LIST.ACTION.CREATE_AUDIT' | translate }}</span><i class="gw-plus button__icon hide-for-medium"></i></a></li>
    </ul>
  </ng-container>
</gw-page-header>
<gw-audits-tabs></gw-audits-tabs>
<gw-audits-filters class="filters-container" [class.filters-container--opened]="filtersOpened"></gw-audits-filters>
<div class="content audits-list">
  <router-outlet></router-outlet>
</div>
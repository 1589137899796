import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

import moment from 'moment';

import { DateRange, DateRangeFilter } from '../../../interfaces/date-range.interface';

import { ExpandBoxComponent } from '../../expand-box/expand-box.component';

@Component({
  selector: 'gw-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeFilterComponent {
  @ViewChild('sectionExpandBox', { static: true }) expandBox: ExpandBoxComponent;

  @Input() label: string;
  @Input() checkboxLabel: string;
  @Input() checkboxValue: boolean;
  @Input() disabled: boolean;
  @Input() set dates(dates: DateRangeFilter) {
    this.momentDates = this.convertDatesForMoment(dates);
    this.changeDetector.detectChanges();
  }
  @Input() visibleContent = false;
  @Input() useTimezone = true;

  @Output() updateFilter = new EventEmitter<DateRangeFilter>();
  @Output() updateCheckboxValue = new EventEmitter<boolean>();

  momentDates: DateRange;

  constructor(private changeDetector: ChangeDetectorRef) {}

  updateFilters(dates?: DateRange): void {
    this.updateFilter.emit(this.convertDatesForFilters(dates));
  }

  convertDatesForFilters(dates: DateRange): DateRangeFilter {
    return {
      startDate: dates?.startDate ? dates.startDate.format(this.useTimezone ? '' : 'YYYY-MM-DDTHH:mm:ss') : '',
      endDate: dates?.endDate ? dates.endDate.format(this.useTimezone ? '' : 'YYYY-MM-DDTHH:mm:ss') : ''
    };
  }

  convertDatesForMoment(dates: DateRangeFilter): DateRange {
    return {
      startDate: dates?.startDate ? moment(dates.startDate) : undefined,
      endDate: dates?.endDate ? moment(dates.endDate) : undefined
    };
  }
}

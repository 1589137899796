import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { OfflineService, PermissionsNames } from 'gutwin-shared';

import { AuditNavigationService } from '@gutwin-audit/audit/services/audit-navigation.service';
import { StorageModuleService, StoragesNamesModule } from '@gutwin-audit/shared/services/storage-module.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';

@Component({
  selector: 'gw-audits-list',
  templateUrl: './audits-list.component.html',
  styleUrls: ['./audits-list.component.scss']
})
export class AuditsListComponent implements OnInit, OnDestroy {
  filtersOpened = false;
  canCreateAudit = false;
  userSubscription: Subscription;

  constructor(
    private storageService: StorageModuleService,
    private userService: UserService,
    public auditNavigationService: AuditNavigationService,
    public offlineService: OfflineService
  ) {}

  ngOnInit(): void {
    this.markCreateAuditPremission();
    this.userSubscription = this.userService.storedUserObservable.subscribe(() => {
      this.markCreateAuditPremission();
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  markCreateAuditPremission(): void {
    this.canCreateAudit = this.userService.storedUser.hasPermission(PermissionsNames.createAudits);
  }

  toggleFilters(): void {
    this.filtersOpened = !this.filtersOpened;
  }

  clearAuditStorage(): void {
    this.storageService.removeFromStorage(StoragesNamesModule.auditCreation);
  }
}

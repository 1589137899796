import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { findIndex } from 'lodash';
import { Subject } from 'rxjs';

import {
  Attachment,
  DefaultList,
  Employee,
  EmployeeExtended,
  EmployeePermissions,
  I18nService,
  LanguageCode,
  Permissions,
  Role
} from 'gutwin-shared';

import { AuditsFilters } from '../models/filters-audits.model';

import { RoleResponse } from '@gutwin-audit/shared/interfaces/role.interface';

import { ApiUrlService } from '@gutwin-audit/shared/services/api-url.service';
import { ProgressService, ProgressStatus } from '@gutwin-audit/shared/services/progress.service';
import { RoleService } from '@gutwin-audit/shared/services/role.service';
import { StorageModuleService, StoragesNamesModule } from '@gutwin-audit/shared/services/storage-module.service';

export interface EmployeeResponse {
  id: string;
  name: string;
  lastname: string;
  email: string;
  avatar: Attachment;
  access_to_system: boolean;
  language: LanguageCode;
  user_id: string;
  permissions: EmployeePermissionsResponse;
  roles: Array<RoleResponse>;
  company_id: string;
}

export interface EmployeeListResponse {
  employees: Array<EmployeeResponse>;
  page: number;
  counters: {
    total: number;
    filtered: number;
  };
  total_employees_count: number;
}

export interface EmployeePermissionsResponse {
  access_to_admin_panel: boolean;
  owned_permissions: Array<PermissionsResponse>;
}

export enum PermissionsResponse {
  manage_questionnaires = 'manage_questionnaires',
  manage_users = 'manage_users',
  manage_facilities = 'manage_facilities',
  manage_audit_types = 'manage_audit_types',
  manage_finding_types = 'manage_finding_types',
  manage_question_ratings = 'manage_question_ratings',
  manage_user_roles = 'manage_user_roles',
  manage_company = 'manage_company',
  company_creation = 'company_creation',
  create_audits = 'create_audits',
  access_all_audits = 'access_all_audits',
  manage_finished_audits = 'manage_finished_audits',
  manage_solved_findings = 'manage_solved_findings',
  manage_report_templates = 'manage_report_templates',
  manage_cause_and_solution = 'edit_cause_assigned_findings'
}

@Injectable()
export class UserService {
  storedUser = new EmployeeExtended();
  storedUserSource = new Subject<EmployeeExtended>();
  storedUserObservable = this.storedUserSource.asObservable();
  totalEmployees: number;

  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private i18nService: I18nService,
    private progressService: ProgressService,
    private roleService: RoleService,
    private storageService: StorageModuleService
  ) {}

  async getCurrentUser(onLogin = false): Promise<EmployeeExtended> {
    if (onLogin || !this.storedUser || !this.storedUser.id) {
      const currentUser = await this.fetchCurrentUser();
      this.setCurrentUser(currentUser);
    }
    return this.storedUser;
  }

  fetchCurrentUser(): Promise<EmployeeExtended> {
    return this.http
      .get(this.apiUrlService.currentUserApi)
      .toPromise()
      .then((data: EmployeeResponse) => {
        this.storageService.setOfflineStore(StoragesNamesModule.currentUser, data, true);
        return this.convertEmployeeExtendedToGet(data);
      })
      .catch(async error => {
        if (error.status === 0) {
          const currentOfflineUser = await this.fetchCurrentUserFromOfflineStore();
          if (currentOfflineUser) {
            return currentOfflineUser;
          }
        }
        console.error('Error while getting current user', error);
        throw error;
      });
  }

  fetchCurrentUserFromOfflineStore(): Promise<EmployeeExtended> {
    return this.storageService.getOfflineStore(StoragesNamesModule.currentUser, true).then(data => {
      if (data) {
        return this.convertEmployeeExtendedToGet(data);
      }
    });
  }

  setCurrentUser(employee: EmployeeExtended): void {
    this.storedUser = employee;
    if (this.storedUser.language) {
      this.i18nService.setLanguage(this.storedUser.language.id);
    }
    this.storageService.setUserOfflineStore(this.storedUser.id);
    this.storedUserSource.next(employee);
  }

  getUsers(): Promise<Array<Employee>> {
    return this.http
      .get(this.apiUrlService.userApi)
      .toPromise()
      .then((data: any) => {
        const employees = new Array<Employee>();
        data.users.forEach(employee => {
          employees.push(this.convertEmployeeToGet(employee));
        });
        return employees;
      })
      .catch(error => {
        console.error('Error while getting users', error);
        throw error;
      });
  }

  getEmployees(saveOffline?: boolean): Promise<Array<Employee>> {
    const mapResponse = (data: Array<EmployeeResponse>): Array<Employee> => {
      let employees = new Array<Employee>();
      if (data) {
        employees = data.map(employee => this.convertEmployeeToGet(employee));
      }
      return employees;
    };

    return this.http
      .get(this.apiUrlService.employeeApi)
      .toPromise()
      .then(async (data: Array<EmployeeResponse>) => {
        if (saveOffline) {
          await this.saveEmployeesInOfflineStore(data);
        }
        return mapResponse(data);
      })
      .catch(async error => {
        if (error.status === 0 && !saveOffline) {
          const data = await this.getEmployeesFromOfflineStore();
          if (data) {
            return mapResponse(data);
          }
        }
        console.error('Error while getting employees', error);
        throw error;
      });
  }

  getEmployeesExtended(page = 0, filters?: AuditsFilters, limit?: number): Promise<DefaultList<EmployeeExtended>> {
    let params = new HttpParams();

    if (page < 0) {
      page = 0;
    }

    if (filters?.usersSearch) {
      params = params.set('name', filters.usersSearch.toString());
    }

    params = params.set('page', page.toString());
    params = params.set('limit', limit.toString());

    const mapResponse = (data: Array<EmployeeResponse>): Array<EmployeeExtended> => {
      let employees = new Array<EmployeeExtended>();
      if (data) {
        employees = data.map(employee => this.convertEmployeeExtendedToGet(employee));
      }
      return employees;
    };

    return this.http
      .get(`${this.apiUrlService.employeeApi}/with_roles_and_access`, { params })
      .toPromise()
      .then((data: EmployeeListResponse) => {
        const employees = mapResponse(data.employees);
        return {
          total: data.counters.filtered,
          list: employees
        };
      })
      .catch(error => {
        console.error('Error while getting employees', error);
        throw error;
      });
  }

  getEmployee(employeeId: string): Promise<Employee> {
    return this.http
      .get(`${this.apiUrlService.employeeApi}/${employeeId}`)
      .toPromise()
      .then((data: EmployeeResponse) => this.convertEmployeeToGet(data))
      .catch(error => {
        console.error('Error while getting users', error);
        throw error;
      });
  }

  createEmployee(employee: Employee): Promise<Employee> {
    return this.http
      .post(this.apiUrlService.employeeApi, this.convertEmployeeToPost(employee))
      .toPromise()
      .then((data: EmployeeResponse) => this.convertEmployeeToGet(data))
      .catch(error => {
        console.error('Error while adding employee', error);
        throw error;
      });
  }

  updateEmployee(employee: Employee): Promise<Employee> {
    return this.http
      .put(this.apiUrlService.employeeApi + '/' + employee.id, this.convertEmployeeToPost(employee))
      .toPromise()
      .then((data: EmployeeResponse) => this.convertEmployeeToGet(data))
      .catch(error => {
        console.error('Error while updating employee', error);
        throw error;
      });
  }

  archiveEmployee(employee: Employee): Promise<void> {
    return this.http
      .put(`${this.apiUrlService.employeeApi}/${employee.id}/archive`, {})
      .toPromise()
      .then(() => {})
      .catch(error => {
        console.error('Error while archiving employee', error);
        throw error;
      });
  }

  removeEmployee(employee: Employee): Promise<any> {
    return this.http
      .delete(this.apiUrlService.employeeApi + '/' + employee.id)
      .toPromise()
      .then(data => data)
      .catch(error => {
        console.error('Error while removing employee', error);
        throw error;
      });
  }

  resetPassword(employee: Employee): Promise<any> {
    return this.http
      .put(this.apiUrlService.userApi + '/reset_password', this.convertEmployeeToResetPassword(employee))
      .toPromise()
      .then(data => data)
      .catch(error => {
        console.error('Error while reseting password for employee', error);
        throw error;
      });
  }

  getEmployeeRequestToConvert(
    employee: EmployeeResponse,
    isExtended = false
  ): Partial<Employee> | Partial<EmployeeExtended> {
    const isPermissionExists = (permission: PermissionsResponse, permissions: Array<PermissionsResponse>): boolean => {
      if (permissions) {
        return !!~permissions.indexOf(permission);
      }
      return false;
    };

    const convertPermissions = (permissions: Array<PermissionsResponse>): Permissions => {
      return new Permissions({
        manageQuestionnaires: isPermissionExists(PermissionsResponse.manage_questionnaires, permissions),
        manageUsers: isPermissionExists(PermissionsResponse.manage_users, permissions),
        manageFacilities: isPermissionExists(PermissionsResponse.manage_facilities, permissions),
        manageAuditTypes: isPermissionExists(PermissionsResponse.manage_audit_types, permissions),
        manageFindingTypes: isPermissionExists(PermissionsResponse.manage_finding_types, permissions),
        manageQuestionRatings: isPermissionExists(PermissionsResponse.manage_question_ratings, permissions),
        manageUserRoles: isPermissionExists(PermissionsResponse.manage_user_roles, permissions),
        manageCompany: isPermissionExists(PermissionsResponse.manage_company, permissions),
        companyCreation: isPermissionExists(PermissionsResponse.company_creation, permissions),
        createAudits: isPermissionExists(PermissionsResponse.create_audits, permissions),
        accessAllAudits: isPermissionExists(PermissionsResponse.access_all_audits, permissions),
        manageFinishedAudits: isPermissionExists(PermissionsResponse.manage_finished_audits, permissions),
        manageSolvedFindings: isPermissionExists(PermissionsResponse.manage_solved_findings, permissions),
        manageReportTemplates: isPermissionExists(PermissionsResponse.manage_report_templates, permissions),
        manageCauseAndSolution: isPermissionExists(PermissionsResponse.manage_cause_and_solution, permissions)
      });
    };

    const convertEmployeePermissions = (permissions: EmployeePermissionsResponse): EmployeePermissions => {
      return new EmployeePermissions({
        accessToAdminPanel: permissions ? permissions.access_to_admin_panel : false,
        ownedPermissions: permissions ? convertPermissions(permissions.owned_permissions) : undefined
      });
    };

    const convertRoles = (roles: Array<RoleResponse>): Array<Role> => {
      if (roles) {
        return roles.map(role => this.roleService.convertRoleToGet(role));
      }
    };

    const employeeRequest = {
      id: employee.id,
      name: employee.name,
      lastname: employee.lastname,
      email: employee.email,
      avatar: employee.avatar,
      access: employee.access_to_system,
      language: employee.language ? this.i18nService.getLanguage(employee.language) : undefined,
      userId: employee.user_id,
      permissions: isExtended ? convertEmployeePermissions(employee.permissions) : undefined,
      roles: isExtended ? convertRoles(employee.roles) : undefined,
      companyId: employee.company_id
    };
    return employeeRequest;
  }

  convertEmployeeToGet(employee: EmployeeResponse): Employee {
    const employeeRequest = this.getEmployeeRequestToConvert(employee);
    return new Employee(employeeRequest);
  }

  convertEmployeeExtendedToGet(employee: EmployeeResponse): EmployeeExtended {
    const employeeRequest = this.getEmployeeRequestToConvert(employee, true);
    return new EmployeeExtended(employeeRequest);
  }

  convertEmployeeToPost(employee: Employee): FormData {
    const formData = new FormData();

    formData.append('id', employee.id);
    formData.append('name', employee.name);
    formData.append('lastname', employee.lastname);
    if (employee.email && employee.email.length > 0) {
      formData.append('email', employee.email);
    }
    if (~[true, false].indexOf(employee.access)) {
      formData.append('access_to_system', employee.access.toString());
    }
    if (employee.language) {
      formData.append('language', employee.language.id);
    }
    if (employee.avatar === null || (employee.avatar && employee.avatar.file)) {
      formData.append('avatar', employee.avatar ? employee.avatar.file : '');
    }
    if (employee.password) {
      formData.append('password', employee.password);
    }

    return formData;
  }

  convertEmployeeToArchive(employee: Employee, archive: boolean): FormData {
    const formData = new FormData();
    formData.append('id', employee.id);
    formData.append('archived', archive ? 'true' : 'false');
    return formData;
  }

  convertEmployeeToResetPassword(employee: Employee): FormData {
    const formData = new FormData();
    formData.append('user_id', employee.userId);
    return formData;
  }

  /**
   * Offline support
   */

  async saveEmployeesInOfflineStore(data: Array<EmployeeResponse>): Promise<any> {
    if (data) {
      await this.storageService.setOfflineStore(StoragesNamesModule.employees, data);
      return data;
    }
  }

  getEmployeesFromOfflineStore(): Promise<Array<EmployeeResponse>> {
    return this.storageService.getOfflineStore(StoragesNamesModule.employees);
  }

  updateEmployeeInOfflineStore(employee: EmployeeResponse): void {
    const updateEmployee = (data: Array<EmployeeResponse>): Array<any> => {
      const index = findIndex(data, { id: employee.id });
      if (index !== -1) {
        data[index] = employee;
      } else {
        data.push(employee);
      }
      return data;
    };

    this.storageService.getOfflineStore(StoragesNamesModule.employees).then((data: Array<EmployeeResponse>) => {
      if (data) {
        data = updateEmployee(data);
        this.storageService.setOfflineStore(StoragesNamesModule.employees, data);
      }
    });
  }

  removeEmployeeFromOfflineStore(employeeId: string): void {
    const removeEmployee = (data: Array<EmployeeResponse>): Array<EmployeeResponse> => {
      const index = findIndex(data, { id: employeeId });
      if (~index) {
        data.splice(index, 1);
      }
      return data;
    };

    this.storageService.getOfflineStore(StoragesNamesModule.employees).then((data: Array<EmployeeResponse>) => {
      if (data) {
        data = removeEmployee(data);
        this.storageService.setOfflineStore(StoragesNamesModule.employees, data);
      }
    });
  }

  async saveEmployeesOffline(): Promise<any> {
    if (!this.progressService.hasRunningEmployeesGroup()) {
      await this.progressService.updateEmployeesProgressGroup(undefined, undefined, true);
      return this.getEmployees(true)
        .then(async employees => {
          await this.progressService.updateEmployeesProgressGroup(ProgressStatus.success);
          this.progressService.countProgress();
          this.progressService.checkProgressStatus();
          return employees;
        })
        .catch(async error => {
          await this.progressService.updateEmployeesProgressGroup(ProgressStatus.error);
          this.progressService.countProgress();
          this.progressService.checkProgressStatus();
          throw error;
        });
    } else {
      return Promise.resolve();
    }
  }

  getAppVersion(): Promise<string> {
    return this.http
      .get(this.apiUrlService.versionApi)
      .toPromise()
      .then((version: { version: string }) => version.version)
      .catch(error => {
        console.error('Error while getting app version', error);
        throw error;
      });
  }
}


<gw-modal id="reportModal" #reportModal="gwModal">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="reportModal.data">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.HEADER.EDIT' | translate }}</ng-container>
    <ng-container *ngIf="!reportModal.data">{{ 'ADMIN_SETTINGS.REPORTS.MODAL.HEADER.ADD' | translate }}</ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="reportModal.opened">
    <gw-add-report #addReport="gwAddReport" [report]="reportModal.data"></gw-add-report>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="reportModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="saveReport()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="row reports">
  <div class="columns">
    <div class="row reports__header">
      <div class="columns small-12 large-8 reports-header__title-container">
        <h1 class="reports-header__title">{{ 'ADMIN_SETTINGS.REPORTS.HEADER' | translate }}</h1>
      </div>
      <div class="columns small-12 large-4 reports-header__button-container">
        <button class="button--primary reports-header__button" (click)="reportModal.open()">{{ 'ADMIN_SETTINGS.REPORTS.ACTION.NEW_REPORT' | translate }}</button>
      </div>
    </div>
    <div class="info-box"><i class="gw-icon gw-analysis info-box__icon"></i>
      <div class="info-box__content">
        <div class="info-box__section">
          <div class="info-box__row">
            <div class="info-box__column">
              <div class="info-box__title">{{ 'ADMIN_SETTINGS.REPORTS.INFO.INFO_HEADER' | translate }}</div>
            </div>
          </div>
          <div class="info-box__row">
            <div class="info-box__column"><span class="info-box__text info-box__text--pre">{{ 'ADMIN_SETTINGS.REPORTS.INFO.INFO_WARNING' | translate }}: </span><span class="info-box__text">{{ 'ADMIN_SETTINGS.REPORTS.INFO.INFO_TEXT' | translate }}</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="reports__content">
      <div class="flex-table reports-table">
        <div class="flex-table__row">
          <div class="flex-table__cell--th reports-table__col--1">{{ 'GLOBAL.TABLE_LABEL.NAME' | translate }}</div>
          <div class="flex-table__cell--th reports-table__col--2">{{ 'GLOBAL.TABLE_LABEL.AUDIT_TYPES' | translate }}</div>
          <div class="flex-table__cell--th reports-table__col--3">{{ 'GLOBAL.TABLE_LABEL.TEMPLATE' | translate }}</div>
          <div class="flex-table__cell--th reports-table__col--4"></div>
        </div>
        <div class="flex-table__row reports-table__row--content" *ngFor="let report of reports; even as evenReport" [class.flex-table__row--even]="evenReport">
          <div class="flex-table__cell reports-table__col--1" [class.reports-table__default-report-name]="report.default">{{ report.name }}</div>
          <div class="flex-table__cell reports-table__col--2">
            <ng-container *ngFor="let auditType of report.auditTypes; last as isLast">{{ auditType.title }}{{ isLast ? '' : ', ' }}</ng-container>
          </div>
          <div class="flex-table__cell reports-table__col--3"><a class="link--underline" target="_blank" [href]="report.document?.url">{{report.document?.name}}</a></div>
          <div class="flex-table__cell reports-table__col--4"><span class="dropdown dropdown--menu" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
              <button class="reports-table__option" type="button"><i class="gw-more"></i></button>
              <div class="dropdown__content jsDropdownContent">
                <ul class="dropdown-menu__list">
                  <li class="dropdown-menu__item">
                    <button class="link dropdown-menu__link" type="button" (click)="showUpdateReportModal(report)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                  </li>
                  <li class="dropdown-menu__item" *ngIf="!report.default">
                    <button class="link dropdown-menu__link" type="button" (click)="setReportAsDefault(report)">{{ 'ADMIN_SETTINGS.REPORTS.ACTION.SET_AS_DEFAULT' | translate }}</button>
                  </li>
                  <li class="dropdown-menu__item" *ngIf="!report.default">
                    <button class="link dropdown-menu__link" type="button" (click)="showRemoveModal(report)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                  </li>
                </ul>
              </div></span></div>
        </div>
      </div>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';

import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { reject } from 'lodash';
import { DEFAULT_FILTER_STATE_VALUES } from '../../constants/default-filter-state-values.constant';

import { LawFilters } from '../../../../../models/law-filters.model';
import { LegalChangeFilters } from '../../../../../models/legal-change-filters.model';
import { PermitFilters } from '../../../../../models/permit-filters.model';
import { RequirementFilters } from '../../../../../models/requirements-filters.model';
import { FiltersParamsModel, PaginationParams } from '../../models/filters.model';
import { LegalQuestionFilters } from '../../models/legal-question-filters.model';

import { GlobalFilters } from '../../../../../interfaces/filter.interface';
import { ListFiltersValues } from '../../../../../interfaces/list-filters-values.interface';
import { FiltersParamsSections } from '../../interfaces/filters-params-sections.interface';

import { FilterType } from '../../../../../enums/filter-type.enum';
import { LcFilterListName } from '../../../../../enums/lc-filter-list.enum';
import { LegalChangesSection } from '../../../../../enums/legal-changes-section.enum';

import { ListFiltersKeysType, ListFiltersType, ListFiltersValueType } from '../../types/list-filters.type';

import { FilterService } from '../../../../../services/filter.service';

import {
  RemoveLCListAllFilters,
  RemoveLCListFilter,
  SetGlobalFilter,
  SetLCAllSectionsPagination,
  SetLCListFilter,
  SetLCListFilters,
  SetLCListPagination
} from './filters.actions';

export interface FiltersModel {
  shared: GlobalFilters;
  includeSubnodes?: boolean;
  lcLists: {
    [LcFilterListName.legalQuestions]: FiltersParamsModel<LegalQuestionFilters>;
    [LcFilterListName.legalRegister]: FiltersParamsModel<LawFilters>;
    [LcFilterListName.requirements]: FiltersParamsModel<RequirementFilters>;
    [LcFilterListName.topicRequirements]: FiltersParamsModel<RequirementFilters>;
    [LcFilterListName.legalChanges]: FiltersParamsSections<LegalChangeFilters, LegalChangesSection>;
    [LcFilterListName.permits]: FiltersParamsModel<PermitFilters>;
    [LcFilterListName.users]: PaginationParams;
  };
}

@State<FiltersModel>({
  name: 'filters',
  defaults: {
    shared: {
      companies: []
    },
    lcLists: {
      legalQuestions: DEFAULT_FILTER_STATE_VALUES[LcFilterListName.legalQuestions],
      legalRegister: DEFAULT_FILTER_STATE_VALUES[LcFilterListName.legalRegister],
      requirements: DEFAULT_FILTER_STATE_VALUES[LcFilterListName.requirements],
      topicRequirements: DEFAULT_FILTER_STATE_VALUES[LcFilterListName.topicRequirements],
      legalChanges: DEFAULT_FILTER_STATE_VALUES[LcFilterListName.legalChanges],
      permits: DEFAULT_FILTER_STATE_VALUES[LcFilterListName.permits],
      users: DEFAULT_FILTER_STATE_VALUES[LcFilterListName.users]
    }
  }
})
@Injectable()
export class FiltersState {
  constructor(private filterService: FilterService, private store: Store) {}

  @Selector()
  static globalFilters(state: FiltersModel): GlobalFilters {
    return state.shared;
  }

  @Selector()
  static includeSubnodes(state: FiltersModel): boolean {
    return state.includeSubnodes;
  }

  @Selector()
  static selectedCompanies(state: FiltersModel): Array<string> {
    return state.shared.companies;
  }

  static lcListParams(
    listName: LcFilterListName,
    noFilters?: boolean
  ): (state: FiltersModel) => FiltersParamsModel<GlobalFilters & ListFiltersType> {
    const getLcListParams = (state: FiltersModel): FiltersParamsModel<GlobalFilters & ListFiltersType> => {
      return {
        ...(!noFilters && { filters: this.getLcListFilters(state, listName) }),
        pagination: state.lcLists[listName].pagination
      };
    };

    return createSelector([FiltersState], getLcListParams);
  }

  static lcListSectionsParams(
    listName: LcFilterListName
  ): (state: FiltersModel) => FiltersParamsSections<ListFiltersType, LegalChangesSection> {
    const getLcListSectionParams = (
      state: FiltersModel
    ): FiltersParamsSections<ListFiltersType, LegalChangesSection> => {
      const listSections = state.lcLists[listName].sections;
      let updatedListSections = {} as Record<LegalChangesSection, PaginationParams>;
      for (const section in listSections) {
        if (section) {
          updatedListSections = {
            ...listSections,
            [section]: {
              ...listSections[section],
              pagination: {
                ...listSections[section].pagination
              }
            }
          };
        }
      }
      return { sections: updatedListSections, filters: this.getLcListFilters(state, listName) };
    };

    return createSelector([FiltersState], getLcListSectionParams);
  }

  static lcListSectionParams<T extends GlobalFilters & ListFiltersType>(
    listName: LcFilterListName,
    section?: LegalChangesSection
  ): (state: FiltersModel) => FiltersParamsModel<T> {
    const getLcListSectionParams = (state: FiltersModel): FiltersParamsModel<T> => {
      const listParams = state.lcLists[listName];
      const sectionParams = section ? listParams.sections[section] : undefined;
      return {
        pagination: { ...listParams.pagination, ...(sectionParams?.pagination || {}) },
        filters: { ...this.getLcListFilters(state, listName), ...(sectionParams?.filters || {}) }
      };
    };

    return createSelector([FiltersState], getLcListSectionParams);
  }

  static getLcListFilters(state: FiltersModel, listName: LcFilterListName): GlobalFilters & ListFiltersType {
    const groupFilters = state.lcLists[listName].filters;
    const filters = {
      ...state.shared,
      ...groupFilters
    };
    return filters;
  }

  @Action(SetGlobalFilter)
  setGlobalFilter(context: StateContext<FiltersModel>, action: SetGlobalFilter): void {
    const filters = context.getState();
    context.patchState({
      shared: {
        ...filters?.shared,
        [action.key]: action.value as Array<string>
      }
    });
  }

  @Action(SetLCListFilter)
  setLCListFilter(context: StateContext<FiltersModel>, { listName, filter }: SetLCListFilter): void {
    const lcLists = context.getState().lcLists;
    context.patchState({
      lcLists: {
        ...lcLists,
        [listName]: {
          ...lcLists[listName],
          filters: {
            ...lcLists[listName].filters,
            [filter.name]: filter.value
          }
        }
      }
    });
  }

  @Action(SetLCListFilters)
  setLCListFilters(context: StateContext<FiltersModel>, { listName, filters }: SetLCListFilters): void {
    const convertFiltersArrayToObject = (
      filters: Array<ListFiltersValues<ListFiltersKeysType>>
    ): { [key in ListFiltersKeysType]?: ListFiltersValueType } => {
      const convertedFilters = {};
      filters.forEach(({ name, value }) => {
        convertedFilters[name] = value;
      });
      return convertedFilters;
    };

    const lcLists = context.getState().lcLists;
    context.patchState({
      lcLists: {
        ...lcLists,
        [listName]: {
          ...lcLists[listName],
          filters: {
            ...lcLists[listName].filters,
            ...convertFiltersArrayToObject(filters)
          }
        }
      }
    });
  }

  @Action(SetLCListPagination)
  setListPagination(
    context: StateContext<FiltersModel>,
    { listName, pagination, updateUrl, section }: SetLCListPagination
  ): void {
    const lcLists = context.getState().lcLists;
    const updatedList = section
      ? {
          ...lcLists,
          [listName]: {
            ...lcLists[listName],
            sections: {
              ...lcLists[listName].sections,
              [section]: {
                ...lcLists[listName][section],
                pagination
              }
            }
          }
        }
      : {
          ...lcLists,
          [listName]: {
            ...lcLists[listName],
            pagination
          }
        };

    context.patchState({
      lcLists: updatedList
    });
    if (updateUrl) {
      this.filterService.setUrl(null, null, pagination);
    }
  }

  @Action(SetLCAllSectionsPagination)
  setAllSectionsListPagination(
    context: StateContext<FiltersModel>,
    { listName, sections }: SetLCAllSectionsPagination
  ): void {
    const lcLists = context.getState().lcLists;

    context.patchState({
      lcLists: {
        ...lcLists,
        [listName]: {
          ...lcLists[listName],
          sections
        }
      }
    });
  }

  @Action(RemoveLCListFilter)
  removeLCListFilter(context: StateContext<FiltersModel>, { listName, filter }: RemoveLCListFilter): void {
    const { name, value, extraFieldValueId } = filter;
    const filterValues = context.getState().lcLists[listName].filters[name];

    let updatedFilters;
    switch (name) {
      case FilterType.extraFields:
        const matchedExtraField = filterValues?.find(
          extraField => extraField.id === value && extraField.values[0] === extraFieldValueId
        );
        updatedFilters = reject(filterValues, matchedExtraField);
        break;
      case FilterType.effectiveUntil:
      case FilterType.inactive:
      case FilterType.issuedOn:
      case FilterType.publication:
      case FilterType.validFrom:
      case FilterType.dateOfIssue:
      case FilterType.submittedOn:
        updatedFilters = null;
        break;
      case FilterType.validIndefinitely:
        updatedFilters = false;
        break;
      default:
        updatedFilters = filterValues?.filter(addedFilter => addedFilter !== value);
    }

    this.store.dispatch(new SetLCListFilter(listName, { name, value: updatedFilters }));
  }

  @Action(RemoveLCListAllFilters)
  removeLCListAllFilters(
    context: StateContext<FiltersModel>,
    { listName, filterToKeep, filterToKeepValue }: RemoveLCListAllFilters
  ): void {
    const lcLists = context.getState().lcLists;
    context.patchState({
      lcLists: {
        ...lcLists,
        [listName]: {
          ...lcLists[listName],
          filters: {
            ...DEFAULT_FILTER_STATE_VALUES[listName],
            ...(filterToKeep && { [filterToKeep]: filterToKeepValue || lcLists[listName].filters[filterToKeep] })
          }
        }
      }
    });
  }
}

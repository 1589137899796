import { Country } from './country.model';

export class LegalOrigin {
  id: string;
  name: string;
  description: string;
  archived: boolean;
  countries?: Array<Country>;

  constructor(origin: Partial<LegalOrigin>) {
    Object.assign(this, origin);
  }
}


<gw-modal id="addSectionModal" #sectionModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="sectionModal.data">
      {{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_SECTION_MODAL.HEADER.EDIT' | translate }}
      "{{ questionnaire.name }}"
    </ng-container>
    <ng-container *ngIf="!sectionModal.data">
      {{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_SECTION_MODAL.HEADER.ADD' | translate }}
      "{{ questionnaire.name }}"
    </ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="sectionModal.opened">
    <gw-add-section #addSection="gwAddSection" [composable]="questionnaire" [section]="sectionModal.data"></gw-add-section>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="sectionModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="saveSection()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<gw-modal id="addQuestionModal" #questionModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="questionModal.data?.question">
      {{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.HEADER.EDIT' | translate }}
      "{{ questionnaire.name }}"
    </ng-container>
    <ng-container *ngIf="!questionModal.data?.question">
      {{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.HEADER.ADD' | translate }}
      "{{ questionnaire.name }}"
    </ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="questionModal.opened">
    <gw-add-question #addQuestion="gwAddQuestion" [composable]="questionnaire" [sections]="questionnaire.sections" [section]="questionModal.data?.section" [question]="questionModal.data?.question"></gw-add-question>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" tabindex="5" (click)="questionModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" tabindex="6" (click)="saveQuestion()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="row single-reference-document">
  <div class="columns">
    <div class="row single-reference-document__header">
      <div class="columns small-2 medium-1 single-reference-document-header__icon-container"><i class="gw-arrow--left single-reference-document-header__icon" [routerLink]="['/admin/questionnaires']"></i></div>
      <div class="columns small-10 medium-6 large-5 single-reference-document-header__title-container">
        <h1 class="single-reference-document-header__title">{{ questionnaire.name }}</h1>
      </div>
      <div class="columns small-12 medium-5 large-3 single-reference-document-header__counters-container">
        <div class="single-reference-document-header__counters">
          <div class="single-reference-document-header__counter">
            {{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.LABEL.CHAPTERS' | translate }}:
            {{ questionnaire.totalSections }}
          </div>
          <div class="single-reference-document-header__counter">
            {{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.LABEL.QUESTIONS' | translate }}:
            {{ questionnaire.totalQuestions }}
          </div>
        </div>
      </div>
      <div class="columns small-12 large-3 single-reference-document-header__button-container">
        <gw-button-dropdown class="single-reference-document-header__button" #buttonDropdown="buttonDropdown" [title]="'GLOBAL.ACTION.ADD' | translate">
          <ng-container button-dropdown-list="button-dropdown-list">
            <li class="button-dropdown-list__item" (click)="buttonDropdown.closeDropdown(); sectionModal.open();">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.LABEL.CHAPTER' | translate }}</li>
            <li class="button-dropdown-list__item" (click)="buttonDropdown.closeDropdown(); questionModal.open();">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.LABEL.QUESTION' | translate }}</li>
          </ng-container>
        </gw-button-dropdown>
      </div>
    </div>
    <div class="single-reference-document__content" (dragenter)="$event.preventDefault()" (dragover)="dragFacility($event)" (touchover)="dragFacility($event)">
      <ul class="reference-documents__list reference-documents__list--level-2">
        <li class="reference-documents-list__item--action">
          <div class="reference-documents-list-item__content">
            <button class="button--secondary button--tiny" (click)="sectionModal.open()">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ACTION.ADD_CHAPTER' | translate }}</button>
          </div>
        </li>
        <li class="reference-documents-list__item" *ngFor="let section of questionnaire.sections; let i = index">
          <div class="reference-documents-list__drop-position" *ngIf="i === 0" [class.reference-documents-list__drop-position--over]="draggedSection?.dragging &amp;&amp; dropPosition === i" [class.reference-documents-list__drop-position--visible]="draggedSection?.dragging" (drop)="drop(i)" (dragenter)="dropPosition = i" (dragleave)="dropPosition = undefined" (dragover)="$event.preventDefault()" (touchenter)="dropPosition = i" (touchleave)="dropPosition = undefined" (touchover)="$event.preventDefault()"></div>
          <gw-expand-box #sectionExpandBox="gwExpandBox">
            <ng-container expand-box-header="expand-box-header">
              <div class="reference-documents-list-item__content reference-documents-list-item__content--link">
                <div class="reference-documents-list-item__group reference-documents-list-item__group--link" #sectionElement="" draggable="true" [class.reference-documents-list-item__content--opened]="sectionExpandBox.opened" (dragstart)="dragStart($event, sectionElement, section)" (dragend)="dragEnd()" (touchstart)="dragStart($event, sectionElement, section)" (touchend)="dragEnd(); drop(dropPosition);" (click)="toggleQuestions(section, sectionExpandBox)">
                  <div class="reference-documents-list-item__icon-container"><i class="gw-caret--down expand-box__toggle-icon reference-documents-list-item__icon"></i></div>
                  <div class="reference-documents-list-item__name">{{ section.name }}</div>
                  <div class="reference-documents-list-item__counters">
                    <div class="reference-documents-list-item__counter">
                      {{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.LABEL.CHAPTER' | translate }}
                      {{ i + 1 }}
                    </div>
                    <div class="reference-documents-list-item__counter">
                      {{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.LABEL.QUESTIONS' | translate }}:
                      {{ section.totalQuestions }}
                    </div>
                  </div>
                </div>
                <div class="reference-documents-list-item__options"><span class="dropdown dropdown--menu reference-documents-list-item__option--dropdown" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
                    <button class="reference-documents-list-item__option reference-documents-list-item__option--more" type="button"><i class="gw-more"></i></button>
                    <div class="dropdown__content jsDropdownContent">
                      <ul class="dropdown-menu__list">
                        <li class="dropdown-menu__item">
                          <button class="link dropdown-menu__link" type="button" (click)="showModalForSection(section)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                        </li>
                        <li class="dropdown-menu__item">
                          <button class="link dropdown-menu__link" type="button" (click)="showRemoveSectionModal(section)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                        </li>
                      </ul>
                    </div></span></div>
              </div>
            </ng-container>
            <ng-container expand-box-content="expand-box-content">
              <ul class="reference-documents__list reference-documents__list--level-3" [class.reference-documents__list--bordered]="draggedQuestion?.dragging &amp;&amp; draggedQuestion?.section?.id === section.id">
                <li class="reference-documents-list__item--action">
                  <div class="reference-documents-list-item__content">
                    <button class="button--secondary button--tiny" (click)="showModalForQuestion(section)">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ACTION.ADD_QUESTION' | translate }}</button>
                  </div>
                </li>
                <li class="reference-documents-list__item" *ngFor="let question of section.questions; let q = index">
                  <div class="reference-documents-list__drop-position" *ngIf="q === 0" [class.reference-documents-list__drop-position--over]="draggedQuestion?.dragging &amp;&amp; draggedQuestion?.section?.id === section.id &amp;&amp; dropPosition === q" [class.reference-documents-list__drop-position--visible]="draggedQuestion?.dragging &amp;&amp; draggedQuestion?.section?.id === section.id" (drop)="drop(q, true)" (dragenter)="dropPosition = q" (dragleave)="dropPosition = undefined" (dragover)="$event.preventDefault()" (touchenter)="dropPosition = q" (touchleave)="dropPosition = undefined" (touchover)="$event.preventDefault()"></div>
                  <div class="reference-documents-list-item__content">
                    <div class="reference-documents-list-item__name" #questionElement="" draggable="true" (dragstart)="dragStart($event, questionElement, section, question)" (dragend)="dragEnd()" (touchstart)="dragStart($event, questionElement, section, question)" (touchend)="dragEnd(); drop(dropPosition, true);">{{ question.content }}</div>
                    <div class="reference-documents-list-item__options"><span class="dropdown dropdown--menu reference-documents-list-item__option--dropdown" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
                        <button class="reference-documents-list-item__option reference-documents-list-item__option--more" type="button"><i class="gw-more"></i></button>
                        <div class="dropdown__content jsDropdownContent">
                          <ul class="dropdown-menu__list">
                            <li class="dropdown-menu__item">
                              <button class="link dropdown-menu__link" type="button" (click)="showModalForQuestion(section, question)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                            </li>
                            <li class="dropdown-menu__item">
                              <button class="link dropdown-menu__link" type="button" (click)="showRemoveQuestionModal(section, question)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                            </li>
                          </ul>
                        </div></span></div>
                  </div>
                  <div class="reference-documents-list__drop-position" [class.reference-documents-list__drop-position--over]="draggedQuestion?.dragging &amp;&amp; draggedQuestion?.section?.id === section.id &amp;&amp; dropPosition === q + 1" [class.reference-documents-list__drop-position--visible]="draggedQuestion?.dragging &amp;&amp; draggedQuestion?.section?.id === section.id" (drop)="drop(q + 1, true)" (dragenter)="dropPosition = q + 1" (dragleave)="dropPosition = undefined" (dragover)="$event.preventDefault()" (touchenter)="dropPosition = q + 1" (touchleave)="dropPosition = undefined" (touchover)="$event.preventDefault()"></div>
                </li>
              </ul>
            </ng-container>
          </gw-expand-box>
          <div class="reference-documents-list__drop-position" [class.reference-documents-list__drop-position--over]="draggedSection?.dragging &amp;&amp; dropPosition === i + 1" [class.reference-documents-list__drop-position--visible]="draggedSection?.dragging" (drop)="drop(i + 1)" (dragenter)="dropPosition = i + 1" (dragleave)="dropPosition = undefined" (dragover)="$event.preventDefault()" (touchenter)="dropPosition = i + 1" (touchleave)="dropPosition = undefined" (touchover)="$event.preventDefault()"></div>
        </li>
      </ul>
    </div>
  </div>
</div>
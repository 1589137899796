import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';
import moment from 'moment';

import { DatePickerComponent } from '../date-picker/date-picker.component';

import { SimpleDropdownDirective } from '../../directives/simple-dropdown.directive';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-edit-single-date',
  templateUrl: './edit-single-date.component.html',
  styleUrls: ['./edit-single-date.component.scss'],
  exportAs: 'gwEditSingleDate'
})
export class EditSingleDateComponent implements OnInit {
  @Input() date: moment.Moment;
  @Input() label: string;
  @Input() isRequired = false;
  @Input() isEditMode: boolean;
  @Input() isUserTypeClient = false;
  @Input() customPopoverId: string;
  @Input() isCardItem = true;
  @Input() showClearButton = true;

  @Output() submitDate = new EventEmitter<moment.Moment>();
  @Output() togglePopoverStatus = new EventEmitter<void>();

  @ViewChild('singleDatePicker') singleDatePicker: DatePickerComponent;
  @ViewChild('singleDateDropdown', { static: true }) singleDateDropdown: SimpleDropdownDirective;

  form: FormGroup;
  submitted = false;
  todayDate = moment();

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      date: this.isRequired ? ['', Validators.required] : ['']
    });
  }

  resetForm(): void {
    this.form.get('date').setValue(this.date || '');
  }

  setControlValue(data: any, key: string): void {
    this.form.get(key).setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  submitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.submitDate.emit(this.form.value.date);
    }
  }

  increaseOpenedEdits(): void {
    this.togglePopoverStatus.emit();
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.togglePopoverStatus.emit();
    this.store.dispatch(new DecreaseOpenedEdits());
  }

  keyDownDatePicker(event: any): void {
    switch (event.key) {
      case 'Enter':
        if (this.singleDatePicker && this.singleDateDropdown.openedWithDelay) {
          this.setControlValue(this.singleDatePicker.date, 'date');
        }
    }
  }
}

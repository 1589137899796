import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { TreeFilterOption } from '../../models/tree-filter-option.model';

import { ListTreeFilter, ListTreeFlatFilter } from '../../interfaces/filter.interface';

import { TreeNodesIds } from '../../types/tree-nodes-ids.type';

import { ExpandBoxComponent } from '../../components/expand-box/expand-box.component';

import { TreeService } from '../../services/tree.service';

import { convertArrayToNestedIdsObject, filterNodesByName } from '../../utils/tree.util';

@Component({
  selector: 'gw-flat-tree-filter',
  templateUrl: './flat-tree-filter.component.html',
  styleUrls: ['./flat-tree-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatTreeFilterComponent implements OnInit, OnDestroy {
  @ViewChild('expandBox', { static: true }) expandBox: ExpandBoxComponent;

  @Input() label: string;
  @Input() isMultiSelect?: boolean;
  @Input() subnodesButtonLabel?: string;
  @Input() multiSelectLabel?: string;
  @Input() isParentFullyClosed?: boolean;
  @Input() isFilterArray: boolean;
  @Input() hasSearch = false;
  @Input() set options(options: Array<TreeFilterOption>) {
    this._options = options;
    this.filteredOptions = this.hasSearch ? this.getFilteredOptions() : this.options;
  }
  get options(): Array<TreeFilterOption> {
    return this._options;
  }
  _options: Array<TreeFilterOption>;
  @Input() set filter(filter: ListTreeFilter | ListTreeFlatFilter) {
    this._filter = this.convertFilter(filter);
  }
  get filter(): ListTreeFilter | ListTreeFlatFilter {
    return this._filter;
  }
  _filter: ListTreeFilter = this.getDefaultFilterValue();

  @Output() updateFilter = new EventEmitter<ListTreeFilter | ListTreeFlatFilter>();

  filteredOptions: Array<TreeFilterOption>;
  search = '';
  searchChanged = new Subject<string>();

  destroy$: Subject<boolean> = new Subject<boolean>();

  readonly FLAT_TREE_FILTER_HEIGHT = 300;

  constructor(private changeDetector: ChangeDetectorRef, private treeService: TreeService) {}

  ngOnInit(): void {
    this.observeSearch();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getDefaultFilterValue(): ListTreeFilter {
    return {
      value: undefined,
      includeSubnodes: false
    };
  }

  convertFilter(filter: ListTreeFilter | ListTreeFlatFilter): ListTreeFilter {
    if (filter) {
      return this.isFilterArray
        ? {
            includeSubnodes: filter.includeSubnodes,
            value: convertArrayToNestedIdsObject(filter.value as Array<string>)
          }
        : (filter as ListTreeFilter);
    }
    return this.getDefaultFilterValue();
  }

  updateSelectedOption(selectedNodesIds: TreeNodesIds): void {
    const value = this.isFilterArray ? Object.keys(selectedNodesIds) : selectedNodesIds;
    this.updateFilter.emit({
      value,
      includeSubnodes: this.filter?.includeSubnodes
    } as ListTreeFilter | ListTreeFlatFilter);
  }

  updateSubnodesFilter(includeSubnodes: boolean): void {
    const value = this.isFilterArray ? Object.keys(this.filter.value) : this.filter.value;
    this.updateFilter.emit({
      value,
      includeSubnodes
    } as ListTreeFilter | ListTreeFlatFilter);
  }

  getFilteredOptions(): Array<TreeFilterOption> {
    if (this.search) {
      const { filteredNodes, filteredNodesIds } = filterNodesByName(this.search, this.options);
      return this.treeService.markNodes<TreeFilterOption>(filteredNodes, filteredNodesIds, 'isFiltered');
    }
    return this.options;
  }

  onSearchChange(search: string): void {
    this.searchChanged.next(search);
  }

  observeSearch(): Subscription {
    return this.searchChanged
      .pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(search => {
        this.search = search;
        this.filteredOptions = this.getFilteredOptions();
        this.changeDetector.markForCheck();
      });
  }
}

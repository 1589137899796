import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';

// Gutwin Shared Library
import { DialogService, ExpandBoxComponent, HelperUtil, ModalService } from 'gutwin-shared';

import { Question } from '../../../shared/models/question.model';
import { Questionnaire } from '../../../shared/models/questionnaire.model';
// Models
import { Section } from '../../../shared/models/section.model';

import { AddQuestionComponent } from '../../../shared/components/add-question/add-question.component';
// Components
import { AddSectionComponent } from '../../../shared/components/add-section/add-section.component';

// Services
import { QuestionService } from '../../../shared/services/question.service';
import { SectionService } from '../../../shared/services/section.service';

@Component({
  selector: 'gw-single-reference-document',
  templateUrl: './single-reference-document.component.html',
  styleUrls: ['./single-reference-document.component.scss']
})
export class SingleReferenceDocumentComponent implements OnInit {
  @ViewChild('addSection') addSection: AddSectionComponent;
  @ViewChild('addQuestion') addQuestion: AddQuestionComponent;
  questionnaire: Questionnaire;
  scrolling: boolean;
  translation = {
    notify: {
      error: {
        connectionTitle: '',
        loadQuestions: '',
        removeSection: '',
        reorderSections: '',
        removeQuestion: '',
        reorderQuestions: ''
      },
      success: {
        removeSectionTitle: '',
        removeSectionText: '',
        removeQuestionTitle: '',
        removeQuestionText: ''
      }
    },
    removeQuestionDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    removeSectionDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    }
  };
  draggedSection = {
    dragging: false,
    section: undefined
  };
  draggedQuestion = {
    dragging: false,
    section: undefined,
    question: undefined
  };
  dropPosition: number;

  constructor(
    private route: ActivatedRoute,
    private questionService: QuestionService,
    private sectionService: SectionService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private modalService: ModalService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.questionnaire = this.route.snapshot.data['questionnaire'];
    this.questionnaire.sections = this.route.snapshot.data['sections'];
    this.fetchTranslation();
  }

  toggleQuestions(section: Section, expandBox: ExpandBoxComponent): void {
    if (!(section.questions && section.questions.length)) {
      this.loadQuestions(section).then(() => {
        expandBox.toggle();
      });
    } else {
      expandBox.toggle();
    }
  }

  loadQuestions(section: Section): Promise<any> {
    return this.questionService
      .getQuestions(this.questionnaire, section.id)
      .then((questions: Array<Question>) => {
        section.questions = questions;
      })
      .catch(error => {
        this.notificationsService.remove();
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.loadQuestions
        );
        throw error;
      });
  }

  appendSection(section: Section, questionnaire = this.questionnaire): void {
    questionnaire.totalSections++;
    questionnaire.sections.push(section);
  }

  insertSection(index: number, section: Section, questionnaire = this.questionnaire): void {
    questionnaire.totalSections++;
    questionnaire.sections.splice(index, 0, section);
  }

  updateSection(section: Section): void {
    const realSection = _.find(this.questionnaire.sections, { id: section.id });
    if (realSection) {
      realSection.name = section.name;
    }
  }

  spliceSection(section: Section, questionnaire = this.questionnaire): void {
    const index = this.getSectionIndex(section);
    if (~index) {
      questionnaire.totalSections--;
      questionnaire.sections.splice(index, 1);
      questionnaire.totalQuestions -= section.totalQuestions;
    }
  }

  getSectionIndex(section: Section, sections = this.questionnaire.sections): number {
    return _.findIndex(sections, { id: section.id });
  }

  appendQuestion(section: Section, question: Question, questionnaire = this.questionnaire): void {
    section.totalQuestions++;
    questionnaire.totalQuestions++;
    section.questions.push(question);
  }

  insertQuestion(index: number, section: Section, question: Question, questionnaire = this.questionnaire): void {
    section.totalQuestions++;
    questionnaire.totalQuestions++;
    section.questions.splice(index, 0, question);
  }

  updateQuestion(section: Section, question: Question): void {
    const realQuestion = _.find(section.questions, { id: question.id });
    if (realQuestion) {
      realQuestion.content = question.content;
      realQuestion.description = question.description;
    }
  }

  spliceQuestion(section: Section, question: Question, questionnaire = this.questionnaire): void {
    const index = _.findIndex(section.questions, { id: question.id });
    if (~index) {
      section.totalQuestions--;
      questionnaire.totalQuestions--;
      section.questions.splice(index, 1);
    }
  }

  saveSection(): void {
    this.addSection
      .submitAddSectionForm()
      .then(sectionResponse => {
        if (!sectionResponse.addAnother) {
          this.modalService.close('addSectionModal');
        }
        if (sectionResponse.status === 'create') {
          this.appendSection(sectionResponse.section);
        } else if (sectionResponse.status === 'update') {
          this.updateSection(sectionResponse.section);
        }
      })
      .catch(error => { });
  }

  saveQuestion(): void {
    this.addQuestion
      .submitAddQuestionForm()
      .then(questionResponse => {
        if (!questionResponse.addAnother) {
          this.modalService.close('addQuestionModal');
        }
        const sectionIndex = this.getSectionIndex(questionResponse.section);
        if (questionResponse.status === 'create') {
          this.appendQuestion(this.questionnaire.sections[sectionIndex], questionResponse.question);
        } else if (questionResponse.status === 'update') {
          this.updateQuestion(this.questionnaire.sections[sectionIndex], questionResponse.question);
        }
      })
      .catch(error => { });
  }

  showModalForSection(section: Section): void {
    this.modalService.open('addSectionModal', section);
  }

  showModalForQuestion(section: Section, question?: Question): void {
    this.modalService.open('addQuestionModal', {
      question,
      section
    });
  }

  showRemoveSectionModal(section: Section): void {
    this.dialogService
      .confirm(
        this.translation.removeSectionDialog.header,
        this.translation.removeSectionDialog.content,
        this.translation.removeSectionDialog.cancel,
        this.translation.removeSectionDialog.confirm
      )
      .then(() => {
        this.removeSection(section);
      })
      .catch(() => { });
  }

  showRemoveQuestionModal(section: Section, question: Question): void {
    this.dialogService
      .confirm(
        this.translation.removeQuestionDialog.header,
        this.translation.removeQuestionDialog.content,
        this.translation.removeQuestionDialog.cancel,
        this.translation.removeQuestionDialog.confirm
      )
      .then(() => {
        this.removeQuestion(section, question);
      })
      .catch(() => { });
  }

  removeSection(section: Section): void {
    this.sectionService
      .archiveSection(section, this.questionnaire)
      .then(() => {
        this.spliceSection(section);
        this.notificationsService.success(
          this.translation.notify.success.removeSectionTitle,
          this.translation.notify.success.removeSectionText
        );
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.removeSection
        );
      });
  }

  removeQuestion(section: Section, question: Question): void {
    this.questionService
      .archiveQuestion(question, this.questionnaire)
      .then(() => {
        this.spliceQuestion(section, question);
        this.notificationsService.success(
          this.translation.notify.success.removeQuestionTitle,
          this.translation.notify.success.removeQuestionText
        );
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.removeQuestion
        );
      });
  }

  reorderSections(index: number, section: Section, questionnaire = this.questionnaire): void {
    const oldIndex = this.getSectionIndex(section);
    if (oldIndex !== index) {
      this.spliceSection(section, questionnaire);
      if (oldIndex < index) {
        index--;
      }
      this.insertSection(index, section, questionnaire);
    }
  }

  changeSectionOrder(index: number, section: Section): void {
    const tempQuestionnaire = Object.assign({}, this.questionnaire);
    tempQuestionnaire.sections = tempQuestionnaire.sections.slice(0);
    this.reorderSections(index, section, tempQuestionnaire);
    this.sectionService
      .reorderSections(tempQuestionnaire.sections, this.questionnaire)
      .then(() => {
        this.reorderSections(index, section);
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.reorderSections
        );
      });
  }

  reorderQuestions(index: number, section: Section, question: Question, questionnaire = this.questionnaire): void {
    const oldIndex = _.findIndex(section.questions, { id: question.id });
    if (oldIndex !== index) {
      this.spliceQuestion(section, question, questionnaire);
      if (oldIndex < index) {
        index--;
      }
      this.insertQuestion(index, section, question, questionnaire);
    }
  }

  changeQuestionOrder(index: number, section: Section, question: Question): void {
    const tempQuestionnaire = Object.assign({}, this.questionnaire);
    const tempSection = Object.assign({}, section);
    tempSection.questions = tempSection.questions.slice(0);
    this.reorderQuestions(index, tempSection, question, tempQuestionnaire);
    this.questionService
      .reorderQuestions(tempSection.questions, this.questionnaire, section.id)
      .then(() => {
        section = _.find(this.questionnaire.sections, { id: section.id });
        this.reorderQuestions(index, section, question);
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.reorderQuestions
        );
      });
  }

  dragStart(event: any, element: any, section: Section, question?: Question): void {
    event.dataTransfer.setData('text', event.target.id);
    if (event.dataTransfer.setDragImage) {
      event.dataTransfer.setDragImage(element, event.offsetX, event.offsetY);
    }
    if (question) {
      this.draggedQuestion = {
        dragging: true,
        section: section,
        question: question
      };
    } else {
      this.draggedSection = {
        dragging: true,
        section: section
      };
    }
  }

  dragEnd(): void {
    this.draggedSection.dragging = false;
    this.draggedQuestion.dragging = false;
  }

  drop(index: number, dropQuestion?: boolean): void {
    if (index !== undefined) {
      this.dropPosition = undefined;
      if (dropQuestion) {
        const question = new Question(this.draggedQuestion.question);
        const section = new Section(this.draggedQuestion.section);
        this.changeQuestionOrder(index, section, question);
        this.draggedQuestion = {
          dragging: false,
          section: undefined,
          question: undefined
        };
      } else {
        const section = new Section(this.draggedSection.section);
        this.changeSectionOrder(index, section);
        this.draggedSection = {
          dragging: false,
          section: undefined
        };
      }
    }
  }

  dragFacility(event: any): void {
    if (!this.scrolling) {
      if (event.pageY > HelperUtil.windowScrollY + window.innerHeight - 50) {
        this.scrollFacilities('down');
      } else if (event.pageY < HelperUtil.windowScrollY + 50) {
        this.scrollFacilities('up');
      }
    }
  }

  scrollFacilities(direction: string): void {
    this.scrolling = true;
    HelperUtil.scrollView(direction).then(() => {
      this.scrolling = false;
    });
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.LOAD_QUESTIONS',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.REMOVE_SECTION',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.REORDER_SECTIONS',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.REMOVE_QUESTION',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.REORDER_QUESTIONS',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_SECTION_TITLE',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_SECTION_TEXT',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_QUESTION_TITLE',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_QUESTION_TEXT',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_SECTION_MODAL.HEADER',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_SECTION_MODAL.CONTENT',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_QUESTION_MODAL.HEADER',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_QUESTION_MODAL.CONTENT'
      ])
      .subscribe((translation: any) => {
        this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.translation.notify.error.loadQuestions =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.LOAD_QUESTIONS'];
        this.translation.notify.error.removeSection =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.REMOVE_SECTION'];
        this.translation.notify.error.reorderSections =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.REORDER_SECTIONS'];
        this.translation.notify.error.removeQuestion =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.REMOVE_QUESTION'];
        this.translation.notify.error.reorderQuestions =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.REORDER_QUESTIONS'];
        this.translation.notify.success.removeSectionTitle =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_SECTION_TITLE'];
        this.translation.notify.success.removeSectionText =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_SECTION_TEXT'];
        this.translation.notify.success.removeQuestionTitle =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_QUESTION_TITLE'];
        this.translation.notify.success.removeQuestionText =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.REMOVE_QUESTION_TEXT'];
        this.translation.removeQuestionDialog.header =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_QUESTION_MODAL.HEADER'];
        this.translation.removeQuestionDialog.content =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_QUESTION_MODAL.CONTENT'];
        this.translation.removeQuestionDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
        this.translation.removeQuestionDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
        this.translation.removeSectionDialog.header =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_SECTION_MODAL.HEADER'];
        this.translation.removeSectionDialog.content =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.REMOVE_SECTION_MODAL.CONTENT'];
        this.translation.removeSectionDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
        this.translation.removeSectionDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
      });
  }
}

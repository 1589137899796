
<gw-modal id="facilityModal" #facilityModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="facilityModal.data?.facility">{{ 'ADMIN_SETTINGS.FACILITIES.MODAL.HEADER.EDIT' | translate }}</ng-container>
    <ng-container *ngIf="!facilityModal.data?.facility">{{ 'ADMIN_SETTINGS.FACILITIES.MODAL.HEADER.ADD' | translate }}</ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="facilityModal.opened">
    <gw-add-facility #addFacility="gwAddFacility" [facility]="facilityModal.data?.facility" [parent]="facilityModal.data?.parent"></gw-add-facility>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="facilityModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="saveFacility()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="row facilities">
  <div class="columns">
    <div class="row facilities__header">
      <div class="columns small-12 large-8 facilities-header__title-container">
        <h1 class="facilities-header__title">{{ 'ADMIN_SETTINGS.FACILITIES.HEADER' | translate }}</h1>
      </div>
    </div>
    <div class="facilities__content" (dragenter)="$event.preventDefault()" (dragover)="dragFacility($event)" (touchover)="dragFacility($event)">
      <div class="blankslate" *ngIf="!facilities || facilities.length === 0">{{ 'ADMIN_SETTINGS.FACILITIES.BLANKSLATE' | translate }}
        <button class="tree-node__action" type="button" (click)="showAddModal(node)"><i class="gw-plus"></i></button>
      </div>
      <gw-tree *ngIf="facilities &amp;&amp; facilities.length &gt; 0" [nodes]="facilities" (moveNode)="moveFacility($event)" (addNode)="showAddModal($event)" (updateNode)="showUpdateModal($event)" (removeNode)="showRemoveModal($event)"></gw-tree>
    </div>
  </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TabInterface } from '../../interfaces/tab.interface';

import { TabsComponentTheme } from '../../enums/tabs-component-theme.enum';

import { ScrollService } from '../../services/scroll.service';
import { WindowRefService } from '../../services/window-ref.service';

@Component({
  selector: 'gw-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  /**
   * Pass data for tabs
   *
   * @required
   */
  @Input() tabs: Array<TabInterface>;
  /**
   * Use tabs with routing
   */
  @Input() routerTabs?: boolean;
  /**
   * Set tabs theme
   */
  @Input() theme?: TabsComponentTheme = TabsComponentTheme.gray;
  /**
   * Hide line under tabs
   */
  @Input() noBorderBottom?: boolean;
  /**
   * Reset margin of tabs
   */
  @Input() noMargin?: boolean;
  /**
   * Add box-shadow to the tabs
   */
  @Input() withBoxShadow?: boolean;

  /**
   * Callback to run after tab is changed
   */
  @Output() changeTab = new EventEmitter<TabInterface>();

  /**
   * Currently selected tab
   */
  selectedTab: TabInterface;

  ngOnInit(): void {
    this.setSelectedTab(this.tabs[0]);
  }

  constructor(
    private scrollService: ScrollService,
    private activatedRoute: ActivatedRoute,
    private windowRefService: WindowRefService
  ) {}

  onChangeTab(tab: TabInterface): void {
    if (tab.disabled) return;
    if (this.routerTabs) {
      this.scrollService.navigateWithoutScrollTop(
        {
          path: [tab.path],
          extras: { relativeTo: this.activatedRoute, state: history.state }
        },
        this.windowRefService.nativeWindow.pageYOffset
      );
    } else {
      this.setSelectedTab(tab);
    }
    this.changeTab.emit(tab);
  }

  setSelectedTab(tab: TabInterface): void {
    this.selectedTab = tab;
  }
}


<gw-modal id="addTemplateModal" #addTemplateModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">{{ 'AUDITS_LIST.ADD_TEMPLATE_MODAL.MODAL_HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content" *ngIf="addTemplateModal.opened">
    <gw-add-template #addTemplate="gwAddTemplate" [audit]="addTemplateModal.data?.audit"></gw-add-template>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" tabindex="2" (click)="addTemplateModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" tabindex="3" [disabled]="savingTemplate" (click)="saveTemplate()"><i class="button__icon--before button__icon--spinner" *ngIf="savingTemplate">
        <div class="spinner"></div></i>{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<gw-modal id="copyAuditModal" #copyAuditModal="gwModal">
  <ng-container modal-header="modal-header">{{ 'AUDITS_LIST.COPY_AUDIT_MODAL.HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content" *ngIf="copyAuditModal.opened">
    <gw-copy-audit #copyAudit="gwCopyAudit" [audit]="copyAuditModal.data?.audit"></gw-copy-audit>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" tabindex="{{ copyAuditModal.data?.lastTabindex + 1 }}" (click)="copyAuditModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" tabindex="{{ copyAuditModal.data?.lastTabindex + 2 }}" [disabled]="savingAudit" (click)="saveAudit()"><i class="button__icon--before button__icon--spinner" *ngIf="savingAudit">
        <div class="spinner"></div></i>{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="audits-group">
  <div class="flex-table audits-group__header">
    <div class="flex-table__row audit-table__row">
      <div class="flex-table__group audit-table__col-group--1">
        <div class="flex-table__section audit-table__col-subgroup--1">
          <div class="flex-table__cell--th audit-table__col--1" *ngIf="!isTemplate">{{ 'GLOBAL.TABLE_LABEL.STATUS' | translate }}</div>
        </div>
        <div class="flex-table__section audit-table__col-subgroup--2">
          <div class="flex-table__cell--th audit-table__col--2">{{ 'GLOBAL.TABLE_LABEL.NAME' | translate }}</div>
          <div class="flex-table__cell--th audit-table__col--3">{{ 'GLOBAL.TABLE_LABEL.DATE' | translate }}</div>
          <div class="flex-table__cell--th audit-table__col--4">{{ 'GLOBAL.TABLE_LABEL.TYPE' | translate }}</div>
        </div>
        <div class="flex-table__section audit-table__col-subgroup--3">
          <div class="flex-table__cell--th audit-table__col--5">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREAS' | translate }}</div>
          <div class="flex-table__cell--th audit-table__col--6">{{ 'GLOBAL.TABLE_LABEL.AUDITOR' | translate }}</div>
        </div>
      </div>
      <div class="flex-table__group audit-table__col-group--2">
        <div class="flex-table__section audit-table__col-subgroup--4">
          <div class="flex-table__cell--th audit-table__col--7">&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
  <gw-loader id="auditsLoader"></gw-loader>
  <div class="blankslate flex-table__blankslate" *ngIf="!audits || audits?.length === 0">{{ 'AUDITS_LIST.BLANKSLATE' | translate }}.</div>
  <gw-expand-box class="audits-group__row" *ngFor="let audit of audits; even as evenAudit" #auditExpandBox="gwExpandBox" [hasMask]="true">
    <ng-container expand-box-header="expand-box-header">
      <div class="flex-table">
        <div class="flex-table__row flex-table__row--indirect flex-table__row--independent audit-table__row" [class.flex-table__row--even]="evenAudit">
          <div class="flex-table__group audit-table__col-group--1 audit-table__col-group--1" (click)="auditExpandBox.toggle()">
            <div class="flex-table__section audit-table__col-subgroup--1">
              <div class="flex-table__cell audit-table__col--1 audit-table__status audit-table__status--in-progress" *ngIf="audit.status &amp;&amp; !isTemplate">
                <pie class="audit-table__pie" [percent]="audit.status"></pie><span class="audit-table__status-value font-weight--semibold">{{ audit.status }}%</span>
              </div>
              <div class="flex-table__cell audit-table__col--1 audit-table__status" *ngIf="!audit.status &amp;&amp; !isTemplate"><span class="audit-table__status-label">{{ 'GLOBAL.TABLE_LABEL.STARTS_AT' | translate }}:&nbsp;</span>{{ audit.startTime | dateFormat: 'HH:mm' }}<i class="gw-clock audit-table__status-icon"></i></div>
            </div>
            <div class="flex-table__section audit-table__col-subgroup--2">
              <div class="flex-table__cell audit-table__col--2"><span class="audit-table__label">{{ 'GLOBAL.TABLE_LABEL.NAME' | translate }}:&nbsp;</span>{{ audit.name }}</div>
              <div class="flex-table__cell audit-table__col--3"><span class="audit-table__label">{{ 'GLOBAL.TABLE_LABEL.DATE' | translate }}:&nbsp;</span>{{ audit.startTime | dateFormat: 'D.MM.YYYY' }} — {{ audit.endTime | dateFormat: 'D.MM.YYYY' }}</div>
              <div class="flex-table__cell audit-table__col--4"><span class="audit-table__label">{{ 'GLOBAL.TABLE_LABEL.TYPE' | translate }}:&nbsp;</span>
                <ng-container *ngIf="audit.type">{{ audit.type.title }}</ng-container>
              </div>
            </div>
            <div class="flex-table__section audit-table__col-subgroup--3">
              <div class="flex-table__cell audit-table__col--5" [class.show-for-large]="!(audit.auditedAreas &amp;&amp; audit.auditedAreas.length)"><span class="audit-table__label">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREAS' | translate }}:&nbsp;</span>
                <ng-container *ngIf="audit.auditedAreas &amp;&amp; audit.auditedAreas.length"><span>{{ audit.auditedAreas[0].facility.name }}<span *ngIf="audit.auditedAreas.length &gt; 1">&nbsp;+ {{ audit.auditedAreas.length - 1 }} {{ 'GLOBAL.ACTION.MORE' | translate }}...</span></span></ng-container>
              </div>
              <div class="flex-table__cell audit-table__col--6 flex-table__cell--avatar"><span class="audit-table__label">{{ 'GLOBAL.TABLE_LABEL.AUDITOR' | translate }}:&nbsp;</span>
                <ng-container *ngIf="audit.employees.leadAuditor?.length">
                  <gw-avatar class="auditors-cell__avatar" [employee]="audit.employees.leadAuditor[0]"></gw-avatar><span class="auditors-cell__name"><span class="text--nowrap">{{ audit.employees.leadAuditor[0] | displayUserName }}
                      <ng-container *ngIf="(audit.employees.leadAuditor.length + audit.employees.coAuditor.length) &gt; 1">&nbsp;+ {{ audit.employees.leadAuditor.length + audit.employees.coAuditor.length  - 1 }} {{ 'GLOBAL.ACTION.MORE' | translate }}...</ng-container></span></span>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="flex-table__group audit-table__col-group--2">
            <div class="flex-table__section audit-table__col-subgroup--4">
              <div class="flex-table__cell audit-table__col--7 flex-table__options" [class.flex-table__options--big-content]="audit.offline">
                <div class="flex-table__options-content"><span class="flex-table__option offline-icon" *ngIf="audit.offline"><i class="gw-cloud--off offline-icon__icon"></i></span>
                  <button class="flex-table__option flex-table__option--slide" type="button" (click)="auditExpandBox.toggle()"><i class="gw-angle--down expand-mask__toggle-icon"></i></button><span class="dropdown dropdown--menu flex-table__option flex-table__option--dropdown" *ngIf="!audit.disabled" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
                    <button class="flex-table__option flex-table__option--more" type="button"><i class="gw-more"></i></button>
                    <div class="dropdown__content jsDropdownContent">
                      <ul class="dropdown-menu__list" *ngIf="offlineService.online">
                        <li class="dropdown-menu__item"><a class="link dropdown-menu__link" [routerLink]="['/edit-audit', audit.id]" (click)="clearAuditStorage()">{{ 'GLOBAL.ACTION.EDIT' | translate }}</a></li>
                        <li class="dropdown-menu__item">
                          <button class="link dropdown-menu__link" type="button" (click)="showRemoveAuditDialog(audit)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                        </li>
                        <li class="dropdown-menu__item" *ngIf="!audit.isTemplate">
                          <button class="link dropdown-menu__link" type="button" (click)="showMarkAsTemplateModal(audit)">{{ 'AUDITS_LIST.ACTION.MARK_AS_TEMPLATE' | translate }}</button>
                        </li>
                        <li class="dropdown-menu__item" *ngIf="audit.isTemplate">
                          <button class="link dropdown-menu__link" type="button" [disabled]="true">{{ 'AUDITS_LIST.ACTION.MARKED_AS_TEMPLATE' | translate }}</button>
                        </li>
                        <li class="dropdown-menu__item">
                          <button class="link dropdown-menu__link" type="button" (click)="showCopyAuditModal(audit)">{{ 'AUDITS_LIST.ACTION.COPY_AUDIT' | translate }}</button>
                        </li>
                      </ul>
                      <ul class="dropdown-menu__list" *ngIf="!audit.offline">
                        <li class="dropdown-menu__item">
                          <button class="link dropdown-menu__link" type="button" [disabled]="!offlineService.online" (click)="makeOffline(audit)">{{ 'GLOBAL.ACTION.MAKE_OFFLINE' | translate }}</button>
                        </li>
                      </ul>
                      <ul class="dropdown-menu__list" *ngIf="audit.offline">
                        <li class="dropdown-menu__item">
                          <button class="link dropdown-menu__link" type="button" [disabled]="!offlineService.online" (click)="syncAudit(audit)">{{ 'GLOBAL.ACTION.SYNCHRONIZE' | translate }}</button>
                        </li>
                        <li class="dropdown-menu__item">
                          <button class="link dropdown-menu__link" type="button" (click)="removeOffline(audit)">{{ 'GLOBAL.ACTION.REMOVE_LOCAL_DATA' | translate }}</button>
                        </li>
                      </ul>
                    </div></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container expand-box-content="expand-box-content">
      <div class="audit-table__subcontent" [class.audit-table__subcontent--even]="evenAudit">
        <div class="audit-table__auditors" *ngIf="audit.employees.leadAuditor.length || audit.employees.coAuditor.length">
          <div class="audit-table-auditors__lead"><span class="audit-table-auditors__title">{{ 'GLOBAL.TABLE_LABEL.LEAD_AUDITOR' | translate }}: </span>
            <ng-container *ngFor="let employee of audit.employees.leadAuditor, last as lastEmployee">{{ employee | displayUserName }}{{ lastEmployee ? '' : '; ' }}</ng-container>
          </div>
          <div class="audit-table-auditors__co" *ngIf="audit.employees.coAuditor.length"><span class="audit-table-auditors__title">{{ 'GLOBAL.TABLE_LABEL.CO_AUDITOR' | translate }}: </span>
            <ng-container *ngFor="let employee of audit.employees.coAuditor, last as lastEmployee">{{ employee | displayUserName }}{{ lastEmployee ? '' : '; ' }}</ng-container>
          </div>
        </div>
        <gw-subaudits-group class="audit-table__subaudit" *ngFor="let auditedAreas of auditedAreaGroups[audit.id], first as firstGroup" [subaudits]="auditedAreas" [audit]="audit" [isFirst]="firstGroup" [isTemplate]="isTemplate" [isEven]="evenAudit"></gw-subaudits-group>
      </div>
    </ng-container>
  </gw-expand-box>
</div>
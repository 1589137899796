import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { AuditType } from '@gutwin-audit/shared/models/audit-type.model';
import { Audit } from '@gutwin-audit/shared/models/audit.model';
// Models
import { RatingScale } from '@gutwin-audit/shared/models/rating-scale.model';
import { Rating } from '@gutwin-audit/shared/models/rating.model';

// Providers
import { ApiUrlService } from './api-url.service';
import { ProgressGroup, ProgressService, ProgressStatus, ProgressType } from './progress.service';
import { RatingResponse, RatingsService } from './ratings.service';
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';

export interface ApiRatingScaleResponse {
  id: string;
  name: string;
  default: boolean;
  created_at: string;
  updated_at: string;
  uses_numeric_averages: boolean;
  uses_percentage_averages: boolean;
  audit_types: Array<AuditType>;
  ratings: Array<RatingResponse>;
}

export interface ApiRatingScaleToArchive {
  rating_scale_id: string;
}

export interface ApiRatingScaleToDefault {
  id: string;
  default: string;
}

export interface ApiRatingScaleToPost {
  id: string;
  name: string;
  default: string;
  uses_numeric_averages: string;
  uses_percentage_averages: string;
  audit_type_ids: Array<string>;
}

export interface ApiRatingScaleToUpdate {
  id: string;
  name: string;
  default: string;
  uses_numeric_averages: string;
  uses_percentage_averages: string;
  audit_type_ids: Array<string>;
}

@Injectable()
export class RatingScalesService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private ratingsService: RatingsService,
    private progressService: ProgressService,
    private storageService: StorageModuleService
  ) {}

  getRatingScales(): Promise<Array<RatingScale>> {
    return this.http
      .get(this.apiUrlService.ratingScalesApi)
      .toPromise()
      .then((data: Array<ApiRatingScaleResponse>) => {
        const ratingScales = new Array<RatingScale>();
        data.forEach(ratingScale => {
          ratingScales.push(this.convertRatingScaleToGet(ratingScale));
        });
        return ratingScales;
      })
      .catch(error => {
        console.error('Error while getting rating scales', error);
        throw error;
      });
  }

  getRatingScale(id: string, saveOffline?: boolean): Promise<RatingScale> {
    const handleResponse = (data: ApiRatingScaleResponse): RatingScale => {
      return this.convertRatingScaleToGet(data);
    };

    return this.http
      .get(`${this.apiUrlService.ratingScalesApi}/${id}`)
      .toPromise()
      .then((data: ApiRatingScaleResponse) => {
        this.storageService.setOfflineStore(StoragesNamesModule.ratingScale + id, data);
        return handleResponse(data);
      })
      .catch(async error => {
        if (error.status === 0 && !saveOffline) {
          const data = await this.storageService.getOfflineStore(StoragesNamesModule.ratingScale + id);
          if (data) {
            return handleResponse(data);
          }
        }
        console.error('Error while getting rating scale', error);
        throw error;
      });
  }

  createRatingScale(ratingScale: RatingScale): Promise<RatingScale> {
    return this.http
      .post(this.apiUrlService.ratingScalesApi, this.convertRatingScaleToPost(ratingScale))
      .toPromise()
      .then((data: ApiRatingScaleResponse) => this.convertRatingScaleToGet(data))
      .catch(error => {
        console.error('Error while creating rating scale', error);
        throw error;
      });
  }

  updateRatingScale(ratingScale: RatingScale): Promise<RatingScale> {
    return this.http
      .put(`${this.apiUrlService.ratingScalesApi}/${ratingScale.id}`, this.convertRatingScaleToUpdate(ratingScale))
      .toPromise()
      .then((data: ApiRatingScaleResponse) => this.convertRatingScaleToGet(data))
      .catch(error => {
        console.error('Error while updating rating scale', error);
        throw error;
      });
  }

  archiveRatingScale(ratingScale: RatingScale): Promise<RatingScale> {
    return this.http
      .put(
        `${this.apiUrlService.ratingScalesApi}/${ratingScale.id}/archive`,
        this.convertRatingScaleToArchive(ratingScale)
      )
      .toPromise()
      .then((data: ApiRatingScaleResponse) => this.convertRatingScaleToGet(data))
      .catch(error => {
        console.error('Error while archiving rating scale', error);
        throw error;
      });
  }

  setRatingScaleAsDefault(ratingScale: RatingScale): Promise<RatingScale> {
    return this.http
      .put(`${this.apiUrlService.ratingScalesApi}/${ratingScale.id}`, this.convertRatingScaleToDefault(ratingScale))
      .toPromise()
      .then((data: ApiRatingScaleResponse) => this.convertRatingScaleToGet(data))
      .catch(error => {
        console.error('Error while setting rating scale to default', error);
        throw error;
      });
  }

  async saveRatingScaleOffline(audit: Audit): Promise<RatingScale | void> {
    if (!this.progressService.hasRunningRatingScaleGroup()) {
      await this.progressService.updateRatingScaleProgressGroup(undefined, undefined, true);
      return this.getRatingScale(audit.ratingScale.id, true)
        .then(async ratings => {
          await this.progressService.updateRatingScaleProgressGroup(ProgressStatus.success);
          this.progressService.countProgress();
          this.progressService.checkProgressStatus();
          return ratings;
        })
        .catch(async error => {
          await this.progressService.updateRatingsProgressGroup(ProgressStatus.error);
          this.progressService.countProgress();
          this.progressService.checkProgressStatus();
          throw error;
        });
    } else {
      return new Promise((resolve, reject) => resolve());
    }
  }

  private convertRatingScaleToGet(ratingScale: ApiRatingScaleResponse): RatingScale {
    const ratings = new Array<Rating>();
    if (ratingScale.ratings) {
      ratingScale.ratings.forEach(rating => {
        ratings.push(this.ratingsService.convertRatingToGet(rating));
      });
    }
    const ratingScalesRequest = {
      id: ratingScale.id,
      name: ratingScale.name,
      default: ratingScale.default,
      usesNumericAverages: ratingScale.uses_numeric_averages,
      usesPercentageAverages: ratingScale.uses_percentage_averages,
      ratings,
      auditTypes: ratingScale.audit_types
    };
    return new RatingScale(ratingScalesRequest);
  }

  private convertRatingScaleToPost(ratingScale: RatingScale): ApiRatingScaleToPost {
    const ratingScaleRequest = {
      id: ratingScale.id,
      name: ratingScale.name,
      default: 'false',
      uses_numeric_averages: ratingScale.usesNumericAverages ? 'true' : 'false',
      uses_percentage_averages: ratingScale.usesPercentageAverages ? 'true' : 'false',
      audit_type_ids: ratingScale.auditTypes.map(scale => scale.id)
    };
    return ratingScaleRequest;
  }

  private convertRatingScaleToDefault(ratingScale: RatingScale): ApiRatingScaleToDefault {
    const ratingScaleRequest = {
      id: ratingScale.id,
      default: 'true'
    };
    return ratingScaleRequest;
  }

  private convertRatingScaleToUpdate(ratingScale: RatingScale): ApiRatingScaleToUpdate {
    const ratingScaleRequest = {
      id: ratingScale.id,
      name: ratingScale.name,
      default: ratingScale.default ? 'true' : 'false',
      uses_numeric_averages: ratingScale.usesNumericAverages ? 'true' : 'false',
      uses_percentage_averages: ratingScale.usesPercentageAverages ? 'true' : 'false',
      audit_type_ids: ratingScale.auditTypes.map(scale => scale.id)
    };
    return ratingScaleRequest;
  }

  private convertRatingScaleToArchive(ratingScale: RatingScale): ApiRatingScaleToArchive {
    const ratingScaleRequest = {
      rating_scale_id: ratingScale.id
    };
    return ratingScaleRequest;
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DashboardFilters } from '@gutwin-audit/shared/models/dashboard-filters.model';
import { DashboardModel } from '@gutwin-audit/shared/models/dashboard.model';

import { ApiDashboardData } from '@gutwin-audit/shared/interfaces/dashboard-data.interface';

import { ApiUrlService } from '@gutwin-audit/shared/services/api-url.service';

import { convertDashboardDataToGet } from '@gutwin-audit/shared/converters/dashboard.convert';

@Injectable()
export class DashboardService {
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService) {}

  getDashboardData(filters?: DashboardFilters): Promise<DashboardModel> {
    const params = filters ? this.getDashboardFiltersParams(filters) : new HttpParams();
    return this.http
      .get<ApiDashboardData>(this.apiUrlService.dashboardApi, { params })
      .toPromise()
      .then((data: ApiDashboardData) => {
        return convertDashboardDataToGet(data);
      })
      .catch(error => {
        console.error('Error while getting dashboard data', error);
        return error;
      });
  }

  getFilteredDashboardData(filters: DashboardFilters): Observable<DashboardModel> {
    const params = filters ? this.getDashboardFiltersParams(filters) : new HttpParams();
    return this.http
      .get<ApiDashboardData>(this.apiUrlService.dashboardApi, { params })
      .pipe(map(data => convertDashboardDataToGet(data)));
  }

  getDashboardFiltersParams(filters: DashboardFilters): HttpParams {
    let params = new HttpParams();
    if (filters.auditTypes) {
      for (const type of filters.auditTypes) {
        params = params.append('audit_type_id[]', type);
      }
    }
    if (filters.auditors) {
      for (const auditor of filters.auditors) {
        params = params.append('auditor_id[]', auditor);
      }
    }
    if (filters.facilities) {
      filters.facilities.facilities?.forEach(facility => {
        params = params.append('facility_id[]', facility);
      });
      params = params.append('include_children_facilities', filters.facilities.subFolders?.toString() || 'false');
    }
    if (filters.dateFrom) {
      params = params.append('date_from', filters.dateFrom);
    }
    if (filters.dateTo) {
      params = params.append('date_to', filters.dateTo);
    }
    return params;
  }
}

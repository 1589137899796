import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Store } from '@ngxs/store';

import { Interval } from '../../interfaces/interval.interface';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-edit-interval',
  templateUrl: './edit-interval.component.html',
  styleUrls: ['./edit-interval.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditIntervalComponent implements OnInit {
  @Input() intervals: Array<Interval>;
  @Input() selectedInterval?: Interval;
  @Input() isEditMode?: boolean;
  @Input() isUserTypeClient: boolean;
  @Output() updateInterval = new EventEmitter<Interval>();
  form: FormGroup;
  submitted = false;

  constructor(private store: Store, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      interval: [this.selectedInterval ? this.selectedInterval : '']
    });
  }

  resetForm(): void {
    if (this.selectedInterval) {
      this.form.reset({ interval: this.selectedInterval ? this.selectedInterval : '' });
    }

    this.submitted = false;
  }

  submitForm(): void {
    this.submitted = true;
    this.updateInterval.emit(this.form.value.interval);
  }

  increaseOpenedEdits(): void {
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.store.dispatch(new DecreaseOpenedEdits());
  }
}

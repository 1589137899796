import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import moment from 'moment';

import { CompanyLicense } from '../../../../models/company-license.model';
import { EmployeeExtended } from '../../../../models/employee-extended.model';
import { CurrentUser } from '../models/current-user.model';

import { ApiData } from '../../../../interfaces/api.interface';
import {
  ApiCurrentUserData,
  ApiUpdateCompanyContextResponse,
  ApiUpdateCurrentUserEmailResponse,
  ApiUpdateCurrentUserPasswordResponse,
  ApiUpdateProfileResponse,
  ApiUpdateProfileVariables,
  CurrentUserAction
} from '../interfaces/current-user.interface';
import { ApiRefreshTokenResponse } from '../interfaces/session.interface';

import { CurrentUserGraphQLActionType } from '../enums/user-actions.enum';

import { StorageService, StoragesNames } from '../../../../services/storage.service';
import { UserConverterService } from './user.convert.service';

import { convertLicensesToGet } from '../converters/licenses.convert';
import { convertVariablesToUpdateUserProfile } from '../converters/user.convert';

import { decodeJWTToken } from '../utils/session.util';

import { LicensesQuery } from '../graphql/queries/licenses.query';
import { LoggedUserQuery } from '../graphql/queries/user.query';

import { RefreshTokenMutation } from '../graphql/mutations/session.mutation';
import {
  UpdateCompanyContext,
  UpdateCurrentUserEmail,
  UpdateCurrentUserPassword,
  UpdateProfile
} from '../graphql/mutations/user.mutation';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  isRefreshingTokenInProgress = false;

  constructor(
    private apollo: Apollo,
    private userConverterService: UserConverterService,
    private storageService: StorageService
  ) {}

  refreshToken(): Promise<string> {
    this.isRefreshingTokenInProgress = true;
    return this.apollo
      .mutate<ApiRefreshTokenResponse>({
        mutation: RefreshTokenMutation
      })
      .toPromise()
      .then(({ data }: ApiData<ApiRefreshTokenResponse>) => {
        const { token } = data.refreshAuthToken;
        this.storageService.setLocalStorage(StoragesNames.token, token);
        return token;
      })
      .catch(error => {
        console.error('Error while refreshing token', error);
        throw error;
      })
      .finally(() => {
        this.isRefreshingTokenInProgress = false;
      });
  }

  shouldRefreshToken = (token: string): boolean => {
    if (token && !this.isRefreshingTokenInProgress) {
      const decodedToken = decodeJWTToken(token);
      const minutesToExpire = decodedToken.expirationDate.diff(moment(), 'minutes');
      // Token should be refreshed 5 minutes before it expires
      return minutesToExpire > 0 && minutesToExpire <= 5;
    }
    return false;
  };

  fetchLoggedUser(): Promise<CurrentUser> {
    return this.apollo
      .query<ApiCurrentUserData>({
        query: LoggedUserQuery
      })
      .toPromise()
      .then(({ data }: ApiData<ApiCurrentUserData>) => {
        return this.userConverterService.convertCurrentUserToGet(data.profile);
      })
      .catch(error => {
        console.error('Error while getting current user', error);
        throw error;
      });
  }

  updateLoggedUserEmail(email: string): Promise<CurrentUser> {
    return this.apollo
      .mutate<CurrentUserAction<CurrentUserGraphQLActionType.updateLoginEmail>>({
        mutation: UpdateCurrentUserEmail,
        variables: {
          email
        }
      })
      .toPromise()
      .then(({ data }: ApiUpdateCurrentUserEmailResponse) => {
        return this.userConverterService.convertCurrentUserToGet(data.updateLoginEmail.profile);
      })
      .catch(error => {
        console.error('Error while updating user email', error);
        throw error;
      });
  }

  updateLoggedUserPassword(password: string, passwordConfirmation: string): Promise<CurrentUser> {
    return this.apollo
      .mutate<CurrentUserAction<CurrentUserGraphQLActionType.updateLoginPassword>>({
        mutation: UpdateCurrentUserPassword,
        variables: {
          password,
          passwordConfirmation
        }
      })
      .toPromise()
      .then(({ data }: ApiUpdateCurrentUserPasswordResponse) => {
        return this.userConverterService.convertCurrentUserToGet(data.updateLoginPassword.profile);
      })
      .catch(error => {
        console.error('Error while updating user password', error);
        throw error;
      });
  }

  updateCompanyContext(companyId: string): Promise<CurrentUser> {
    return this.apollo
      .mutate<CurrentUserAction<CurrentUserGraphQLActionType.selectCurrentCompany>>({
        mutation: UpdateCompanyContext,
        variables: {
          companyId
        }
      })
      .toPromise()
      .then(({ data }: ApiUpdateCompanyContextResponse) => {
        return this.userConverterService.convertCurrentUserToGet(data.selectCurrentCompany.profile);
      })
      .catch(error => {
        console.error('Error while updating company context', error);
        throw error;
      });
  }

  updateUserProfile(employee: EmployeeExtended): Promise<CurrentUser> {
    return this.apollo
      .mutate<CurrentUserAction<CurrentUserGraphQLActionType.updateProfile>, ApiUpdateProfileVariables>({
        mutation: UpdateProfile,
        variables: convertVariablesToUpdateUserProfile(employee)
      })
      .toPromise()
      .then(({ data }: ApiUpdateProfileResponse) => {
        return this.userConverterService.convertCurrentUserToGet(data.updateProfile.profile);
      })
      .catch(error => {
        console.error('Error while updating user profile', error);
        throw error;
      });
  }

  getLicenses(): Promise<CompanyLicense> {
    return this.apollo
      .query<ApiCurrentUserData>({
        query: LicensesQuery
      })
      .toPromise()
      .then(({ data }: ApiData<ApiCurrentUserData>) => convertLicensesToGet(data.profile.company.license))
      .catch(error => {
        console.error('Error while getting licenses', error);
        throw error;
      });
  }
}

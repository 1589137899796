
<div class="filters">
  <div class="row">
    <div class="columns filters__content">
      <div class="filter-dropdown" *ngIf="!isTemplates" gwSimpleDropdown="gwSimpleDropdown" #auditNameDropdown="simpleDropdown" [fixOverflowPosition]="true" [class.filter-dropdown--opened]="auditNameDropdown.opened" [class.filter-dropdown--closed]="!auditNameDropdown.opened &amp;&amp; !auditNameDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!auditNameDropdown.openedWithDelay">
          <gw-name-filter *ngIf="auditNameDropdown.openedWithDelay" #auditNameFilter="nameFilter" [dropdown]="auditNameDropdown"></gw-name-filter>
        </div>
        <button class="filter-dropdown__button" type="button"><span class="filter-dropdown__button-text">{{ 'AUDITS_LIST.FILTER.NAME_OF_AUDIT' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
      <div class="filter-dropdown" *ngIf="isTemplates" gwSimpleDropdown="gwSimpleDropdown" #templateNameDropdown="simpleDropdown" [fixOverflowPosition]="true" [class.filter-dropdown--opened]="templateNameDropdown.opened" [class.filter-dropdown--closed]="!templateNameDropdown.opened &amp;&amp; !templateNameDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!templateNameDropdown.openedWithDelay">
          <gw-name-filter *ngIf="templateNameDropdown.openedWithDelay" #templateNameFilter="nameFilter" [dropdown]="templateNameDropdown" filterKey="templateName"></gw-name-filter>
        </div>
        <button class="filter-dropdown__button" type="button"><span class="filter-dropdown__button-text">{{ 'AUDITS_LIST.FILTER.NAME_OF_TEMPLATE' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
      <div class="filter-dropdown" *ngIf="!isTemplates" gwSimpleDropdown="gwSimpleDropdown" #auditDateDropdown="simpleDropdown" [fixOverflowPosition]="true" [class.filter-dropdown--opened]="auditDateDropdown.opened" [class.filter-dropdown--closed]="!auditDateDropdown.opened &amp;&amp; !auditDateDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!auditDateDropdown.openedWithDelay">
          <gw-date-filter *ngIf="auditDateDropdown.openedWithDelay" #auditDateFilter="auditDateFilter" [dropdown]="auditDateDropdown"></gw-date-filter>
        </div>
        <button class="filter-dropdown__button" type="button"><span class="filter-dropdown__button-text">{{ 'AUDITS_LIST.FILTER.DATE' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
      <div class="filter-dropdown" gwSimpleDropdown="gwSimpleDropdown" #auditTypeDropdown="simpleDropdown" [fixOverflowPosition]="true" [class.filter-dropdown--opened]="auditTypeDropdown.opened" [class.filter-dropdown--closed]="!auditTypeDropdown.opened &amp;&amp; !auditTypeDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!auditTypeDropdown.openedWithDelay">
          <gw-select-filter *ngIf="auditTypeDropdown.openedWithDelay &amp;&amp; auditTypes" [dropdown]="auditTypeDropdown" [dataCollection]="auditTypes" [display]="['title']" [filterName]="'types'"></gw-select-filter>
        </div>
        <button class="filter-dropdown__button" type="button" (click)="fetchAuditTypes(true)"><span class="filter-dropdown__button-text">{{ 'AUDITS_LIST.FILTER.TYPE' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
      <div class="filter-dropdown" gwSimpleDropdown="gwSimpleDropdown" #auditAreaDropdown="simpleDropdown" [fixOverflowPosition]="true" [class.filter-dropdown--opened]="auditAreaDropdown.opened" [class.filter-dropdown--closed]="!auditAreaDropdown.opened &amp;&amp; !auditAreaDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!auditAreaDropdown.openedWithDelay">
          <gw-area-filter *ngIf="auditAreaDropdown.openedWithDelay &amp;&amp; facilities" #auditAreaFilters="auditAreaFilters" [dropdown]="auditAreaDropdown" [facilities]="facilities"></gw-area-filter>
        </div>
        <button class="filter-dropdown__button" type="button" (click)="fetchFacilities(true)"><span class="filter-dropdown__button-text">{{ 'AUDITS_LIST.FILTER.AUDITED_AREA' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
      <div class="filter-dropdown" gwSimpleDropdown="gwSimpleDropdown" #auditLocationDropdown="simpleDropdown" [fixOverflowPosition]="true" [class.filter-dropdown--opened]="auditLocationDropdown.opened" [class.filter-dropdown--closed]="!auditLocationDropdown.opened &amp;&amp; !auditLocationDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!auditLocationDropdown.openedWithDelay">
          <gw-location-filter *ngIf="auditLocationDropdown.openedWithDelay" #auditLocationFilter="auditLocationFilter" [dropdown]="auditLocationDropdown"></gw-location-filter>
        </div>
        <button class="filter-dropdown__button" type="button"><span class="filter-dropdown__button-text">{{ 'AUDITS_LIST.FILTER.LOCATION' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
      <div class="filter-dropdown" gwSimpleDropdown="gwSimpleDropdown" #auditAuditorDropdown="simpleDropdown" [fixOverflowPosition]="true" [class.filter-dropdown--opened]="auditAuditorDropdown.opened" [class.filter-dropdown--closed]="!auditAuditorDropdown.opened &amp;&amp; !auditAuditorDropdown.firstDrop">
        <form class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!auditAuditorDropdown.openedWithDelay">
          <gw-auditor-filter *ngIf="auditAuditorDropdown.openedWithDelay &amp;&amp; users" [dropdown]="auditAuditorDropdown" [dataCollection]="users" [display]="['lastname', 'name']" [splitter]="[', ']"></gw-auditor-filter>
        </form>
        <button class="filter-dropdown__button" type="button" (click)="fetchEmployees(true)"><span class="filter-dropdown__button-text">{{ 'AUDITS_LIST.FILTER.AUDITOR.TITLE' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
    </div>
  </div>
</div>
<div class="chosen-filters" *ngIf="hasFilters">
  <div class="row">
    <div class="columns"><span class="chosen-filters__label">{{ 'AUDITS_LIST.FILTER.FILTER_BY' | translate }}:</span><span class="chosen-filters__filter" *ngIf="assignedFilters.name &amp;&amp; !isTemplates">{{ 'AUDITS_LIST.FILTER.NAME_OF_AUDIT' | translate }}:&nbsp;<span class="chosen-filter__value">{{ assignedFilters.name }}</span>
        <button class="chosen-filter__action" type="button" (click)="removeNameFilter()">⨯</button></span><span class="chosen-filters__filter" *ngIf="assignedFilters.templateName &amp;&amp; isTemplates">{{ 'AUDITS_LIST.FILTER.NAME_OF_TEMPLATE' | translate }}:&nbsp;<span class="chosen-filter__value">{{ assignedFilters.templateName }}</span>
        <button class="chosen-filter__action" type="button" (click)="removeTemplateNameFilter()">⨯</button></span><span class="chosen-filters__filter" *ngIf="assignedFilters.dateFrom &amp;&amp; assignedFilters.dateTo &amp;&amp; !isTemplates">{{ 'AUDITS_LIST.FILTER.DATE' | translate }}:&nbsp;<span class="chosen-filter__value">{{ assignedFilters.dateFrom | dateFormat: 'D MMMM YYYY' }} – {{ assignedFilters.dateTo | dateFormat: 'D MMMM YYYY' }}</span>
        <button class="chosen-filter__action" type="button" (click)="removeDateFilter()">⨯</button></span><span class="chosen-filters__filter" *ngFor="let type of assignedAuditTypes; let i = index">{{ 'AUDITS_LIST.FILTER.TYPE' | translate }}:&nbsp;<span class="chosen-filter__value">{{ type.title }}</span>
        <button class="chosen-filter__action" type="button" (click)="deselectType(i)">⨯</button></span><span class="chosen-filters__filter" *ngFor="let facility of assignedFacilities; let i = index">{{ 'AUDITS_LIST.FILTER.AREA_FILTER.FACILITY' | translate }}:&nbsp;<span class="chosen-filter__value">{{ facility.name }}
          <ng-container *ngIf="assignedFilters.facilities.subFolders">&nbsp;({{ 'GLOBAL.LABEL.FACILITY_WITH_SUBFOLDERS' | translate }})</ng-container></span>
        <button class="chosen-filter__action" type="button" (click)="deselectFacility(i)">⨯</button></span><span class="chosen-filters__filter" *ngIf="assignedFilters.location">{{ 'AUDITS_LIST.FILTER.LOCATION' | translate }}:&nbsp;<span class="chosen-filter__value">{{ assignedFilters.location }}</span>
        <button class="chosen-filter__action" type="button" (click)="removeLocationFilter()">⨯</button></span><span class="chosen-filters__filter" *ngFor="let auditor of assignedAuditors; let i = index">
        <ng-container *ngIf="assignedFilters.leadAuditors &amp;&amp; assignedFilters.coAuditors">{{ 'AUDITS_LIST.FILTER.AUDITOR.TITLE' | translate }}:&nbsp;</ng-container>
        <ng-container *ngIf="assignedFilters.coAuditors &amp;&amp; !assignedFilters.leadAuditors">{{ 'AUDITS_LIST.FILTER.AUDITOR.CO' | translate }}:&nbsp;</ng-container>
        <ng-container *ngIf="assignedFilters.leadAuditors &amp;&amp; !assignedFilters.coAuditors">{{ 'AUDITS_LIST.FILTER.AUDITOR.LEAD' | translate }}:&nbsp;</ng-container><span class="chosen-filter__value">{{ auditor.lastname }}, {{ auditor.name }}</span>
        <button class="chosen-filter__action" type="button" (click)="deselectAuditor(i)">⨯</button></span></div>
  </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trackByProperty',
  pure: true
})
export class TrackByPropertyPipe implements PipeTransform {
  public transform<T>(propertyName: string): (index: number, item: T) => any {
    const trackByProperty = (index: number, item: T): any => {
      return item?.[propertyName];
    };
    return trackByProperty;
  }
}

import { Component, Input, OnInit } from '@angular/core';

import moment from 'moment';

import { SimpleDropdownDirective } from 'gutwin-shared';

import { FilterComponent } from '@gutwin-audit/shared/enums/filter-component.enum';

import { FilterModuleService } from '../../../../../shared/services/filter-module.service';

@Component({
  selector: 'gw-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  exportAs: 'auditDateFilter'
})
export class DateFilterComponent implements OnInit {
  @Input() dropdown: SimpleDropdownDirective;
  @Input() component: FilterComponent;
  dateRangeFrom = moment();
  dateRangeTo = moment();

  constructor(private filterService: FilterModuleService) {}

  ngOnInit() {
    this.dateRangeFrom =
      this.filterService.filters && this.filterService.filters.dateFrom
        ? moment(this.filterService.filters.dateFrom)
        : moment();
    this.dateRangeTo =
      this.filterService.filters && this.filterService.filters.dateTo
        ? moment(this.filterService.filters.dateTo)
        : moment();
  }

  setDateFrom(dateFrom: moment.Moment): void {
    this.dateRangeFrom = dateFrom;

    if (moment(dateFrom).isAfter(this.dateRangeTo)) {
      this.dateRangeTo = moment(dateFrom);
    }
  }

  setDateTo(dateTo: moment.Moment): void {
    this.dateRangeTo = dateTo;
  }

  applyDateFilter(dateRangeFrom: moment.Moment, dateRangeTo: moment.Moment): void {
    this.filterService.setFilter('dateFrom', moment(dateRangeFrom).format('YYYY-MM-DD'), false, this.component); // ISO format
    this.filterService.setFilter('dateTo', moment(dateRangeTo).format('YYYY-MM-DD'), true, this.component); // ISO format
    this.dropdown.closeDropdown();
  }
}


<gw-modal id="removeRatingModal" #removeRatingModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">{{ 'ADMIN_SETTINGS.RATING_SCALE.REMOVE_MODAL.HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content" *ngIf="removeRatingModal.opened">{{ 'ADMIN_SETTINGS.RATING_SCALE.REMOVE_MODAL.CONTENT' | translate }}</ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="removeRatingModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="removeRating(ratingForm); removeRatingModal.close()">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="row">
  <div class="columns small-2 medium-1 rating-scale__header"><i class="gw-arrow--left rating-scale-header__icon" [routerLink]="['/admin/rating-scales']"></i></div>
  <div class="columns small-10 medium-5 rating-scale-header__title">
    <h1>{{ ratingScale.name }}</h1>
  </div>
  <div class="columns small-12 medium-6 rating-scale-header__audit-types">
    <ng-container *ngIf="ratingScale.default">{{ 'ADMIN_SETTINGS.RATING_SCALE.DEFAULT_TEXT' | translate }}</ng-container>
    <ng-container *ngIf="!ratingScale.default"> <span>{{ 'GLOBAL.TABLE_LABEL.AUDIT_TYPES' | translate }}: </span>
      <ng-container *ngFor="let auditType of ratingScale.auditTypes; last as isLast">{{ auditType.title }}{{ isLast ? '' : ', ' }}</ng-container>
    </ng-container>
  </div>
</div>
<form class="rating-scale__common" *ngIf="scaleRatingsForm" [formGroup]="scaleRatingsForm" novalidate="novalidate" autocomplete="off">
  <div class="rating-scale__form-fields">
    <div class="row form__field rating-scale__form-field">
      <label class="columns small-5 medium-4 large-3 rating-scale__label" for="numericAverageFlag" (click)="usesNumericAveragesControl.focus()">{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.ACTIVATE_NUMERIC_AVERAGES' | translate }}</label>
      <div class="columns small-2 large-1 text-right form__control rating-scale__control">
        <gw-toggle-switch class="rating-scale__toggle-switch" #usesNumericAveragesControl="gwToggleSwitch" [light]="true" [tabindex]="1" formControlName="usesNumericAverages"></gw-toggle-switch>
      </div>
      <div class="columns small-5 medium-6 large-8 rating-scale__label"><i class="gw-info rating-scale__label-icon"></i><span class="rating-scale__label-text">{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.NUMERIC_AVERAGES_PROVIDED_VALUES' | translate: { all: scaleRatingsValidity.all, valid: scaleRatingsValidity.numeric } }}.</span><span class="rating-scale__label-text" *ngIf="scaleRatingsForm.get('usesNumericAverages').disabled">&nbsp;{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.CANNOT_ACTIVATE_AVERAGE' | translate }}.</span></div>
    </div>
    <div class="row rating-scale__form-field">
      <label class="columns small-5 medium-4 large-3 rating-scale__label" for="numericAverageFlag" (click)="usesPercentageAveragesControl.focus()">{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.ACTIVATE_PERCENTAGE_AVERAGES' | translate }}</label>
      <div class="columns small-2 large-1 text-right rating-scale__control">
        <gw-toggle-switch class="rating-scale__toggle-switch" #usesPercentageAveragesControl="gwToggleSwitch" [light]="true" [tabindex]="2" formControlName="usesPercentageAverages"></gw-toggle-switch>
      </div>
      <div class="columns small-5 medium-6 large-8 rating-scale__label"><i class="gw-info rating-scale__label-icon"></i><span class="rating-scale__label-text">{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.PERCENTAGE_AVERAGES_PROVIDED_VALUES' | translate: { all: scaleRatingsValidity.all, valid: scaleRatingsValidity.percentage } }}.</span><span class="rating-scale__label-text" *ngIf="scaleRatingsForm.get('usesPercentageAverages').disabled">&nbsp;{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.CANNOT_ACTIVATE_AVERAGE' | translate }}.</span></div>
    </div>
  </div>
</form>
<div class="rating-scale__group">
  <ng-container *ngFor="let rating of ratingsToEdit; let i = index">
    <button class="rating-scale__button" type="button" tabindex="{{ i + 3 }}" (click)="selectRating(true, rating.id)" [class.rating-scale__button--active]="choosenRating.id === rating.id" [disabled]="isRatingChanged(choosenRating) &amp;&amp; choosenRating.id !== rating.id"><span class="rating-button__short-name" *ngIf="rating.abbreviation">{{ rating.abbreviation }}</span><span class="rating-button__raiting-color" [style.background-color]="rating.color" [style.border-color]="rating.color"></span></button>
  </ng-container>
</div>
<button class="button--secondary rating-scale__add" type="button" tabindex="{{ ratingsToEdit.length + 3 }}" (click)="addNewRating(true)" [disabled]="isRatingChanged(choosenRating) || ratingsToEdit.length &gt;= 6">{{ 'ADMIN_SETTINGS.RATING_SCALE.ACTION.ADD_NEW' | translate }}</button>
<form class="row rating-scale__form" #ratingForm="ngForm" (submit)="submitRating(ratingForm)" novalidate="novalidate">
  <div class="column small-10 medium-7 large-5">
    <div class="form__field" [class.form__field--error]="rateNameField.invalid &amp;&amp; submitted">
      <label class="label" for="rateName">{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.RATE_NAME' | translate }}</label>
      <div class="form-field__error">{{ 'ADMIN_SETTINGS.RATING_SCALE.ERROR.RATE_NAME_EMPTY' | translate }}</div>
      <input type="hidden" name="rateId" id="rateId" [(ngModel)]="choosenRating.id"/>
      <input class="input" #rateNameField="ngModel" placeholder="{{ 'ADMIN_SETTINGS.RATING_SCALE.PLACEHOLDER.RATE_NAME' | translate }}" type="text" name="rateName" id="rateName" tabindex="{{ ratingsToEdit.length + 4 }}" [(ngModel)]="choosenRating.name" required="required"/>
    </div>
    <div class="form__field" [class.form__field--error]="rateAbbreviationField.invalid &amp;&amp; submitted">
      <label class="label" for="rateAbbreviation">{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.SHORT_NAME' | translate }}</label>
      <div class="form-field__error">{{ 'ADMIN_SETTINGS.RATING_SCALE.ERROR.SHORT_NAME_EMPTY' | translate }}</div>
      <input class="input" #rateAbbreviationField="ngModel" placeholder="{{ 'ADMIN_SETTINGS.RATING_SCALE.PLACEHOLDER.SHORT_NAME' | translate }}" type="text" name="rateAbbreviation" id="rateAbbreviation" tabindex="{{ ratingsToEdit.length + 5 }}" [(ngModel)]="choosenRating.abbreviation" required="required"/>
    </div>
    <div class="form__field" [class.form__field--error]="rateColorField.invalid &amp;&amp; submitted">
      <label class="label" for="rateColor" (click)="colorPicker.focusDropdown()">{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.COLOR' | translate }}</label>
      <div class="form-field__error">{{ 'ADMIN_SETTINGS.RATING_SCALE.ERROR.COLOR_EMPTY' | translate }}</div>
      <gw-color-picker #rateColorField="ngModel" #colorPicker="gwColorPicker" name="rateColor" id="rateColor" required="required" [(ngModel)]="choosenRating.color" [hasLabel]="true" [tabindex]="ratingsToEdit.length + 6" (focus)="colorPicker.focusDropdown()"></gw-color-picker>
    </div>
    <div class="form__field">
      <label class="rating-scale__label">
        <gw-check-box class="rating-scale__check-box" #rateExcludeAverageField="ngModel" name="rateSkipAverage" id="rateSkipAverage" [(ngModel)]="choosenRating.skipForAverage" [tabindex]="ratingsToEdit.length + 7"></gw-check-box><span>{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.SKIP_RATING_FOR_AVERAGE' | translate }}</span>
      </label>
    </div>
    <div class="form__field" [class.form__field--error]="rateNumericValueField.invalid &amp;&amp; submitted">
      <label class="label" for="rateNumericValue" [class.label--disabled]="choosenRating.skipForAverage">{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.NUMERIC_AVERAGE' | translate }}</label>
      <div class="form-field__error" *ngIf="rateNumericValueField.errors?.min">{{ 'ADMIN_SETTINGS.RATING_SCALE.ERROR.RATE_NUMERIC_AVERAGE_RANGE' | translate }}</div>
      <div class="form-field__error" *ngIf="!rateNumericValueField.errors?.min &amp;&amp; rateNumericValueField.errors?.pattern">{{ 'ADMIN_SETTINGS.RATING_SCALE.ERROR.RATE_NUMERIC_AVERAGE_PATTERN' | translate }}</div>
      <input class="input" #rateNumericValueField="ngModel" placeholder="{{ 'ADMIN_SETTINGS.RATING_SCALE.PLACEHOLDER.NUMERIC_AVERAGE' | translate }}" type="number" name="rateNumericValue" id="rateNumericValue" tabindex="{{ ratingsToEdit.length + 8 }}" [max]="ratingValueRange.max" [min]="ratingValueRange.min" [(ngModel)]="choosenRating.numericValue" [disabled]="choosenRating.skipForAverage"/>
    </div>
    <div class="form__field" [class.form__field--error]="ratePercentageValueField.invalid &amp;&amp; submitted">
      <label class="label" for="ratePercentageValue" [class.label--disabled]="choosenRating.skipForAverage">{{ 'ADMIN_SETTINGS.RATING_SCALE.LABEL.PERCENTAGE_AVERAGE' | translate }} (%)</label>
      <div class="form-field__error" *ngIf="ratePercentageValueField.errors?.min">{{ 'ADMIN_SETTINGS.RATING_SCALE.ERROR.RATE_PERCENTAGE_AVERAGE_RANGE' | translate }}</div>
      <div class="form-field__error" *ngIf="!ratePercentageValueField.errors?.min &amp;&amp; ratePercentageValueField.errors?.pattern">{{ 'ADMIN_SETTINGS.RATING_SCALE.ERROR.RATE_PERCENTAGE_AVERAGE_PATTERN' | translate }}</div>
      <input class="input" #ratePercentageValueField="ngModel" placeholder="{{ 'ADMIN_SETTINGS.RATING_SCALE.PLACEHOLDER.PERCENTAGE_AVERAGE' | translate }}" type="number" name="ratePercentageValue" id="ratePercentageValue" tabindex="{{ ratingsToEdit.length + 9 }}" [max]="ratingValueRange.max" [min]="ratingValueRange.min" [(ngModel)]="choosenRating.percentageValue" [disabled]="choosenRating.skipForAverage"/>
    </div>
  </div>
  <div class="column small-12">
    <div class="form__field button-group">
      <button class="button--link" type="button" tabindex="{{ ratingsToEdit.length + 10 }}" (click)="resetForm(choosenRating.id)">{{ 'GLOBAL.ACTION.RESET' | translate }}</button>
      <button class="button--secondary" *ngIf="choosenRating.id !== 'newRating'" type="button" tabindex="{{ ratingsToEdit.length + 11 }}" (click)="removeRatingModal.open()">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
      <button class="button--primary" type="submit" tabindex="{{ ratingsToEdit.length + 12 }}">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
    </div>
  </div>
</form>
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SidePanelResizeControl } from '../../models/side-panel-resize-control.model';

import { SlideAnimationParams } from '../../interfaces/slide-animation-params.interface';

import { BodyFreezerService } from '../../services/body-freezer.service';
import { SidePanelService } from '../../services/side-panel.service';

import { HelperUtil } from '../../utils/helper.util';

@Component({
  selector: 'gw-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  exportAs: 'gwSidePanel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      state(
        'out',
        style({
          width: 0,
          'min-width': 0,
          padding: 0,
          overflow: 'hidden'
        })
      ),
      state(
        'in',
        style({
          width: '{{ width }}',
          'min-width': '*',
          padding: '*',
          overflow: 'visible'
        }),
        { params: { width: '300px' } }
      ),
      transition('in => out', [animate('0.3s ease-in')]),
      transition('out => in', [animate('0.3s ease-in')])
    ])
  ]
})
export class SidePanelComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('gutter', { static: true }) gutterRef: ElementRef;
  @ViewChild('gutterButton', { static: true }) gutterButtonRef: ElementRef;

  set opened(opened: boolean) {
    this._opened = opened;
    this.initAnimationParams();
  }
  get opened(): boolean {
    return this._opened;
  }
  _opened: boolean;

  animationParams: SlideAnimationParams;
  disabledAnimation = false;
  minHeightStyle: string;
  maxWidthStyle: string;
  widthStyle: string;
  width = 300;
  widthLimit?: number = 900;
  mobileWidth?: number;
  gutterParams = {
    top: '50vh',
    height: 0,
    gutterTop: 0
  };
  resizeControl = new SidePanelResizeControl();

  destroy$ = new Subject<void>();

  constructor(
    private bodyFreezerService: BodyFreezerService,
    private changeDetector: ChangeDetectorRef,
    private elementRef: ElementRef,
    private sidePanelService: SidePanelService
  ) {}

  ngOnInit(): void {
    this.observeOpenFlag();
    this.observeDisabledAnimation();
    setTimeout(() => {
      // It has to be in timeout, because of ExpressionChangedAfterItHasBeenCheckedError
      this.observeMobileWidth();
      this.observeWidthLimit();
      this.observeMaxWidth();
      if (this.opened) this.setWidth(this.width);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  ngAfterContentChecked(): void {
    this.initGutterParams();
    this.initMinHeight();
  }

  initAnimationParams(): void {
    this.animationParams = {
      value: this.opened ? 'in' : 'out',
      params: { width: this.widthStyle }
    };
  }

  animationFinished(): void {
    this.sidePanelService.enableAnimation();
  }

  observeDisabledAnimation(): void {
    this.sidePanelService.disabledAnimation$.pipe(takeUntil(this.destroy$)).subscribe((disableAnimation: boolean) => {
      this.disabledAnimation = disableAnimation;
      this.changeDetector.markForCheck();
    });
  }

  observeOpenFlag(): void {
    this.opened = this.sidePanelService.opened;
    this.sidePanelService.openedObservable.pipe(takeUntil(this.destroy$)).subscribe((opened: boolean) => {
      this.opened = opened;
      this.changeDetector.markForCheck();
    });
  }

  observeMaxWidth(): void {
    this.maxWidthStyle = `${this.sidePanelService.maxWidth}px`;
    this.sidePanelService.maxWidthObservable.pipe(takeUntil(this.destroy$)).subscribe((maxWidth: number) => {
      if (this.widthLimit && maxWidth >= this.widthLimit) {
        this.maxWidthStyle = `${this.widthLimit}px`;
      } else {
        this.maxWidthStyle = `${maxWidth}px`;
      }
      this.changeDetector.markForCheck();
    });
  }

  observeWidthLimit(): void {
    this.widthLimit = this.sidePanelService.widthLimit;
    this.sidePanelService.widthLimitObservable.pipe(takeUntil(this.destroy$)).subscribe((widthLimit: number) => {
      this.widthLimit = widthLimit;
      this.changeDetector.markForCheck();
    });
  }

  observeMobileWidth(): void {
    this.mobileWidth = this.sidePanelService.mobileWidth;
    this.sidePanelService.mobileWidthObservable.pipe(takeUntil(this.destroy$)).subscribe((mobileWidth: number) => {
      this.mobileWidth = mobileWidth;
      this.changeDetector.markForCheck();
    });
  }

  initGutterParams(): void {
    const gutterRect = this.gutterRef.nativeElement.getBoundingClientRect();
    this.gutterParams.gutterTop = gutterRect.top;
    const gutterButtonRect = this.gutterButtonRef.nativeElement.getBoundingClientRect();
    this.gutterParams.height = gutterButtonRect.height;
  }

  initMinHeight(): void {
    const sidePanelRect = this.gutterRef.nativeElement.getBoundingClientRect();
    const sidePanelY = sidePanelRect.top + HelperUtil.windowScrollY;
    this.minHeightStyle = `calc(100vh - ${sidePanelY}px)`;
  }

  toggle(): void {
    if (this.opened) this.close();
    else this.open();
  }

  open(): void {
    this.setOpenedFlag(true);
  }

  close(): void {
    this.setWidth(this.elementRef.nativeElement.clientWidth);
    this.setOpenedFlag(false);
  }

  setOpenedFlag(opened: boolean): void {
    this.opened = opened;
    this.sidePanelService.opened = opened;
  }

  setWidth(width: number): void {
    if (this.widthLimit && width >= this.widthLimit) {
      this.width = this.widthLimit;
    } else if (this.mobileWidth) {
      this.width = this.mobileWidth;
    } else {
      this.width = width;
    }
    this.sidePanelService.width = this.width;
    this.widthStyle = `${this.width}px`;
  }

  startResizing(positionX: number): void {
    this.resizeControl.isResizing = true;
    this.resizeControl.lastX = positionX;
    this.bodyFreezerService.disableUserSelect();
  }

  endResizing(): void {
    this.resizeControl.isResizing = false;
    this.bodyFreezerService.enableUserSelect();
  }

  changeGutterButtonPosition(positionY: number): void {
    let gutterButtonTop = positionY - this.gutterParams.gutterTop - this.gutterParams.height / 2;
    gutterButtonTop = Math.max(gutterButtonTop, 0);
    this.gutterParams.top = `${gutterButtonTop}px`;
  }

  resize(positionX: number): void {
    if (this.resizeControl.isResizing) {
      const widthChange = positionX - this.resizeControl.lastX;
      this.resizeControl.lastX = positionX;
      this.setWidth(this.width + widthChange);
    }
  }

  startResizingByMouse(event: MouseEvent): void {
    this.startResizing(event.clientX);
  }

  @HostListener('document:mouseup', ['$event'])
  endResizingByMouse(event: MouseEvent): void {
    this.endResizing();
  }

  @HostListener('document:mousemove', ['$event'])
  resizeByMouse(event: MouseEvent): void {
    this.resize(event.clientX);
    this.changeGutterButtonPosition(event.clientY);
  }

  startResizingByTouch(event: TouchEvent | any): void {
    this.startResizing(event.touches[0].clientX);
  }

  @HostListener('document:touchend', ['$event'])
  endResizingByTouch(event: TouchEvent | any): void {
    this.endResizing();
  }

  @HostListener('document:touchmove', ['$event'])
  resizeByTouch(event: TouchEvent | any): void {
    this.resize(event.touches[0].clientX);
    this.changeGutterButtonPosition(event.touches[0].clientY);
  }
}

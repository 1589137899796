import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
// Models
import { Report, ApiReportResponse, ApiDocumentResponse, ApiReportToPost, ApiReportToUpdate, ApiReportToDefault } from '@gutwin-audit/shared/models/report.model';
// Providers
import { ApiUrlService } from './api-url.service';


@Injectable()
export class ReportsService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService
  ) { }

  getReports(): Promise<Array<Report>> {
    return this.http.get(this.apiUrlService.reportApi)
      .toPromise()
      .then((data: Array<ApiReportResponse>) => {
        const reports = new Array<Report>();
        data.forEach(report => {
          reports.push(this.convertReportToGet(report));          
        })
        return reports
      })
      .catch(error => {
        console.error('Error while getting reports', error);
        throw error;
      });
  }

  createReport(report: Report, reportFile: File): Promise<Report> {    
    return this.http.post(this.apiUrlService.reportApi, this.convertReportToPost(report))
      .toPromise()
      .then((reportResponse: ApiReportResponse) => {
        return this.convertReportToGet(reportResponse)
      })
      .then((report: Report) => {
        
        return this.uploadReportFile(reportFile, report)
          .then((document: ApiDocumentResponse) => {
            report.document = this.convertDocumentToGet(document)
            return report
          })
      })
      .catch(error => {
        console.error('Error while creating report', error);
        throw error;
      });
  }

  updateReport(report: Report, reportFile?: File): Promise<Report> {
    return this.http.put(`${this.apiUrlService.reportApi}/${report.id}`, this.convertReportToUpdate(report))
      .toPromise()
      .then((reportResponse: ApiReportResponse) => {
        return this.convertReportToGet(reportResponse)
      })
      .then((report: Report) => {
        if (reportFile) {
          return this.uploadReportFile(reportFile, report)
            .then((document: ApiDocumentResponse) => {
              report.document = this.convertDocumentToGet(document)
              return report
            })
        }
        return report
      })
      .catch(error => {
        console.error('Error while updating report', error);
        throw error;
      });
  }

  uploadReportFile(reportFile: File, report: Report): Promise<ApiDocumentResponse> {    
    return this.http.put(`${this.apiUrlService.reportApi}/update_custom_report`, convertDataToPost(reportFile), { withCredentials: true })
      .toPromise()
      .then((data: ApiDocumentResponse) => data)
      .catch(error => {
        console.error('Error while uploading document', error);
        return new Promise((resolve, reject) => reject(error));
      });

    function convertDataToPost(file) {
      const formData = new FormData();
      formData.append('document', file);
      formData.append('id', report.id);
      return formData;
    }
  }

  removeReport(report: Report): Promise<any> {
    return this.http.delete(`${this.apiUrlService.reportApi}/${report.id}`)
      .toPromise()
      .then((data) => data)
      .catch(error => {
        console.error('Error while removing report', error);
        throw error;
      });
  }

  setReportAsDefault(report: Report): Promise<Report> {
    return this.http.put(`${this.apiUrlService.reportApi}/${report.id}`, this.convertReportToDefault(report))
      .toPromise()
      .then((reportResponse: ApiReportResponse) => {
        return this.convertReportToGet(reportResponse)
      })
      .catch(error => {
        console.error('Error while setting report as default', error);
        throw error;
      });
  }

  private convertReportToGet(report: ApiReportResponse): Report {    
    const reportRequest = {
      id: report.id,
      name: report.name,
      default: report.default,
      companyLogoWidth: parseFloat(report.company_logo_width),
      questionPhotoWidth: parseFloat(report.question_photo_width),
      findingPhotoWidth: parseFloat(report.finding_photo_width),
      auditTypes: report.audit_types,
      document: report.document
    };
    return new Report(reportRequest);
  }

  private convertReportToPost(report: Report): ApiReportToPost {
    const reportRequest = {
      name: report.name,
      default: report.default ? "true" : "false",
      company_logo_width: '' + report.companyLogoWidth,
      question_photo_width: '' + report.questionPhotoWidth,
      finding_photo_width: '' + report.findingPhotoWidth,
      audit_type_ids: report.auditTypes.map(type => type.id),
      document: {...report.document}
    };
    return reportRequest;
  }

  private convertReportToUpdate(report: Report): ApiReportToUpdate {
    const reportRequest = {
      name: report.name,
      default: report.default ? "true" : "false",
      company_logo_width: '' + report.companyLogoWidth,
      question_photo_width: '' + report.questionPhotoWidth,
      finding_photo_width: '' + report.findingPhotoWidth,
      audit_type_ids: report.auditTypes.map(type => type.id),
      document: {...report.document}
    };
    return reportRequest;
  }

  private convertReportToDefault(report: Report): ApiReportToDefault {
    const reportRequest = {
      id: report.id,
      default: "true",
    };
    return reportRequest;
  }

  private convertDocumentToGet(document: ApiDocumentResponse) {
    const documentRequest = {
      name: document.name,
      url: document.url
    };
    return documentRequest;
  }
}

<gw-modal id="auditTypeModal" #auditTypeModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="auditTypeModal.data">{{ 'ADMIN_SETTINGS.AUDIT_TYPES.ADD_MODAL.HEADER.EDIT' | translate }}</ng-container>
    <ng-container *ngIf="!auditTypeModal.data">{{ 'ADMIN_SETTINGS.AUDIT_TYPES.ADD_MODAL.HEADER.ADD' | translate }}</ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="auditTypeModal.opened">
    <gw-add-audit-type #addAuditType="gwAddAuditType" [auditType]="auditTypeModal.data"></gw-add-audit-type>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="auditTypeModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="saveAuditType()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="row audit-types">
  <div class="columns">
    <div class="row audit-types__header">
      <div class="columns small-12 large-8 audit-types-header__title-container">
        <h1 class="audit-types-header__title">{{ 'ADMIN_SETTINGS.AUDIT_TYPES.HEADER' | translate }}</h1>
      </div>
      <div class="columns small-12 large-4 audit-types-header__button-container">
        <button class="button--primary audit-types-header__button" (click)="auditTypeModal.open()">{{ 'ADMIN_SETTINGS.AUDIT_TYPES.ACTION.ADD_AUDIT_TYPE' | translate }}</button>
      </div>
    </div>
    <div class="audit-types__content">
      <div class="flex-table audit-types-table">
        <div class="flex-table__row">
          <div class="flex-table__cell--th audit-types-table__col--1">{{ 'GLOBAL.TABLE_LABEL.NAME' | translate }}</div>
          <div class="flex-table__cell--th audit-types-table__col--2">{{ 'GLOBAL.TABLE_LABEL.DESCRIPTION' | translate }}</div>
          <div class="flex-table__cell--th audit-types-table__col--3"></div>
        </div>
        <div class="flex-table__row audit-types-table__row--content" *ngFor="let auditType of auditTypes; even as evenAuditType" [class.flex-table__row--even]="evenAuditType">
          <div class="flex-table__cell audit-types-table__col--1">{{ auditType.title }}</div>
          <div class="flex-table__cell audit-types-table__col--2">{{ auditType.description }}</div>
          <div class="flex-table__cell audit-types-table__col--3"><span class="dropdown dropdown--menu" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
              <button class="audit-types-table__option" type="button"><i class="gw-more"></i></button>
              <div class="dropdown__content jsDropdownContent">
                <ul class="dropdown-menu__list">
                  <li class="dropdown-menu__item">
                    <button class="link dropdown-menu__link" type="button" (click)="showUpdateAuditTypeModal(auditType)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                  </li>
                  <li class="dropdown-menu__item">
                    <button class="link dropdown-menu__link" type="button" (click)="showRemoveModal(auditType)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                  </li>
                </ul>
              </div></span></div>
        </div>
      </div>
    </div>
  </div>
</div>
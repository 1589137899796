import { LawFilters } from '../../../../models/law-filters.model';
import { LegalChangeFilters } from '../../../../models/legal-change-filters.model';
import { PermitFilters } from '../../../../models/permit-filters.model';
import { RequirementFilters } from '../../../../models/requirements-filters.model';
import { FiltersParamsModel, PaginationParams } from '../models/filters.model';
import { LegalQuestionFilters } from '../models/legal-question-filters.model';

import { FiltersParamsSections } from '../interfaces/filters-params-sections.interface';

import { LcFilterListName } from '../../../../enums/lc-filter-list.enum';
import { LegalChangesSection } from '../../../../enums/legal-changes-section.enum';

export const DEFAULT_FILTER_STATE_VALUES = {
  [LcFilterListName.legalQuestions]: new FiltersParamsModel<LegalQuestionFilters>(LegalQuestionFilters),
  [LcFilterListName.legalRegister]: new FiltersParamsModel<LawFilters>(LawFilters),
  [LcFilterListName.requirements]: new FiltersParamsModel<RequirementFilters>(RequirementFilters),
  [LcFilterListName.topicRequirements]: new FiltersParamsModel<RequirementFilters>(RequirementFilters),
  [LcFilterListName.permits]: new FiltersParamsModel<PermitFilters>(PermitFilters),
  [LcFilterListName.users]: new PaginationParams(),
  [LcFilterListName.legalChanges]: new FiltersParamsSections<LegalChangeFilters, LegalChangesSection>(
    LegalChangeFilters,
    undefined,
    {
      [LegalChangesSection.major]: new PaginationParams(),
      [LegalChangesSection.minor]: new PaginationParams(),
      [LegalChangesSection.new]: new PaginationParams(),
      [LegalChangesSection.notRelevant]: new PaginationParams()
    }
  )
};

import {
  ContentChild,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Input,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

import { isEmpty } from 'lodash';

@Directive({
  selector: '[gwSimpleTooltipDirective]'
})
export class SimpleTooltipDirective {
  @ContentChild('tooltipTemplate') private tooltipTemplateRef: TemplateRef<any>;

  @Input('gwSimpleTooltipDirective') maxDistanceFromLeft?: number;
  @Input() maxDistanceFromTop?: number;
  @Input() hideTooltip = false;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef
  ) {}

  setViewInputs(): Record<string, boolean> {
    const clientRect = this.elementRef.nativeElement.getBoundingClientRect();
    const inputs: Record<string, boolean> = {
      ...(this.maxDistanceFromTop && { isTopHidden: clientRect.top < this.maxDistanceFromTop }),
      ...(this.maxDistanceFromLeft && { isLeftHidden: clientRect.left < this.maxDistanceFromLeft })
    };

    return isEmpty(inputs) ? undefined : inputs;
  }

  createEmbeddedView(condition?: Record<string, boolean>): EmbeddedViewRef<ViewContainerRef> {
    return this.viewContainerRef.createEmbeddedView(this.tooltipTemplateRef, condition);
  }

  @HostListener('mouseenter') onMouseEnter(): void {
    if (this.hideTooltip) return;
    const view = this.createEmbeddedView(this.setViewInputs());
    view.rootNodes.forEach(node => this.renderer.appendChild(this.elementRef.nativeElement, node));
  }

  @HostListener('mouseleave') onMouseLeave(): void {
    if (!this.hideTooltip && this.viewContainerRef) {
      this.viewContainerRef.clear();
    }
  }
}

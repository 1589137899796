import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AccessData } from '../../interfaces/access-data.interface';

import { EmailValidator } from '../../validators/email.validator';

@Component({
  selector: 'gw-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements OnInit {
  @Input() forgotPasswordLabel = false;
  @Output() logIn = new EventEmitter<AccessData>();
  @Output() forgotPassword = new EventEmitter<void>();
  loginForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, EmailValidator.extendedEmailValidator]],
      password: ['', Validators.required]
    });
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  isEmailInvalid(field: AbstractControl): boolean {
    return field.errors && field.errors.email;
  }

  isFieldEmpty(field: AbstractControl): boolean {
    return field.errors && field.errors.required;
  }

  onLogIn(): void {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.logIn.emit(this.loginForm.value);
    }
  }

  onForgotPassword(): void {
    this.forgotPassword.emit();
  }
}

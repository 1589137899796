
<div class="row users">
  <div class="columns">
    <gw-admin-header [header]="'ADMIN_SETTINGS.USERS.HEADER' | translate" [addButtonLabel]="'ADMIN_SETTINGS.USERS.ACTION.ADD_USER' | translate" [transparent]="true" [smallMarginTop]="true" (addButtonClick)="onAddUser()"></gw-admin-header>
    <div class="users__search">
      <gw-search [theme]="SEARCH_THEME_STRONG_BORDER" [ngModel]="searchQuery" (ngModelChange)="onSearch($event)"></gw-search>
    </div>
    <div class="users__content">
      <div class="users">
        <gw-users-list [employees]="employees" (editUser)="onEditUser($event)" (removeUser)="onRemoveUser($event)" (resetPassword)="onResetPassword($event)" (editUserRoles)="onEditUserRoles($event)"></gw-users-list>
      </div>
    </div>
    <div class="users__pagination">
      <gw-pagination *ngIf="totalEmployees" [totalElementsAmount]="totalEmployees" [currentPage]="filterService?.page" [currentLimit]="filterService?.limit" (changePage)="changePage($event)" (changeLimit)="changeLimit($event)"></gw-pagination>
    </div>
  </div>
</div>
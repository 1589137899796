import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { LegalOrigin } from '../../models/legal-origin.model';

import { UserType } from '../../enums/user-type.enum';

import { MultiselectComponent } from '../multiselect/multiselect.component';
import { SelectComponent } from '../select/select.component';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-edit-legal-origin',
  templateUrl: './edit-legal-origin.component.html',
  styleUrls: ['./edit-legal-origin.component.scss'],
  exportAs: 'gwEditLegalOrigins'
})
export class EditLegalOriginComponent implements OnInit {
  @ViewChild('legalOriginSelect') legalOriginSelect: SelectComponent;
  @ViewChild('legalOriginMultiSelect') legalOriginMultiSelect: MultiselectComponent;

  @Input() set chosenLegalOrigins(chosenLegalOrigins: Array<LegalOrigin>) {
    this._chosenLegalOrigins = chosenLegalOrigins;
    this.resetForm();
  }
  get chosenLegalOrigins(): Array<LegalOrigin> {
    return this._chosenLegalOrigins;
  }
  @Input() legalOrigins: Array<LegalOrigin>;
  @Input() isEditMode: boolean;
  @Input() required = true;
  @Input() multiSelect = false;
  @Input() buttonPlaceholder = false;
  @Input() alignPopoverRight = true;
  @Input() userType: UserType;
  @Output() submit = new EventEmitter<Array<LegalOrigin>>();
  form: FormGroup;
  _chosenLegalOrigins: Array<LegalOrigin>;
  submitted = false;

  readonly USER_TYPE = UserType;

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      legalOrigin: ['', this.required ? Validators.required : null]
    });
  }

  resetForm(): void {
    if (this.form) {
      const controlValue = this.multiSelect ? this.chosenLegalOrigins : this.chosenLegalOrigins[0];
      this.form.controls['legalOrigin'].setValue(controlValue || '');
    }
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  submitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.submit.emit(this.form.value.legalOrigin);
    }
  }

  increaseOpenedEdits(): void {
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.store.dispatch(new DecreaseOpenedEdits());
  }

  focusOnLegalOriginControl(): void {
    if (this.multiSelect) {
      this.legalOriginMultiSelect.focusDropdown();
    } else {
      this.legalOriginSelect.focusDropdown();
    }
  }
}

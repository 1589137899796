import { FacilitiesFilter } from './facilities-filter.model';

export class FindingsFilters {
  search: string;
  dateFrom: string;
  dateTo: string;
  types: Array<string>;
  auditTypes: Array<string>;
  facilities: FacilitiesFilter;
  responsiblePersons: Array<string>;
  component: string;

  constructor(filters?: Partial<FindingsFilters>) {
    if (filters) {
      this.search = filters.search;
      this.dateFrom = filters.dateFrom;
      this.dateTo = filters.dateTo;
      this.types = filters.types;
      this.auditTypes = filters.auditTypes;
      this.facilities = filters.facilities;
      this.responsiblePersons = filters.responsiblePersons;
      this.component = filters.component;
    }
  }
}

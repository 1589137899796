import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getSidePanelWidth',
  pure: true,
})
export class GetSidePanelWidthPipe implements PipeTransform {
  transform(width: number, opened: boolean): string {
    return opened && width ? `${width}px` : '0px';
  }
}

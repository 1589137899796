
<div class="filter-content__search">
  <input class="input-classic input-classic--small" type="text" placeholder="{{ 'GLOBAL.FORM.PLACEHOLDER.SEARCH' | translate }}" [(ngModel)]="filterSearchQuery" [ngModelOptions]="{standalone: true}" (input)="filterItems(filterSearchQuery)"/>
</div>
<div class="filter-content__scroll">
  <div class="blankslate" *ngIf="!filteredDataCollection?.length">{{ 'GLOBAL.ERROR.SEARCH_NO_RESULTS' | translate }}.</div>
  <label class="filter-content__label" *ngFor="let item of filteredDataCollection">
    <gw-check-box class="filter-content__checkbox" [attr.id]="item.id" name="{{ 'filter' + item.id }}" [(ngModel)]="item.selected" [ngModelOptions]="{standalone: true}"></gw-check-box>
    <ng-container *ngFor="let key of display; let i = index">{{ item[key] }}{{ i !== display.length - 1 ? splitter : '' }}</ng-container>
  </label>
</div>
<div class="filter-content__action">
  <button class="button--secondary button--small button--start-case filter-content__button" type="button" (click)="applyFilter()">{{ 'GLOBAL.ACTION.CONFIRM' | translate }}</button>
</div>

<gw-lightbox id="lightboxModal" #lightboxModal="gwLightbox"></gw-lightbox>
<gw-audit-top-bar></gw-audit-top-bar>
<gw-page-header>
  <ng-container page-header-left="page-header-left">
    <ul class="header__menu header__menu--left">
      <li class="header__item"><a class="button button--link header__button--transparent header__button--menu" [routerLink]="getBackNavigation()"><i class="button__icon gw-arrow--left"></i></a></li>
      <li class="header__item header__item--title">{{ 'AUDIT_SUMMARY.PAGE_HEADER' | translate }}</li>
      <li class="header__item header__item--offline" *ngIf="audit?.offline &amp;&amp; !audit?.disabled"><span class="offline-icon show-for-medium"><i class="gw-cloud--off offline-icon__icon"></i></span>
        <div class="dropdown header__dropdown hide-for-medium" gwSimpleDropdown="gwSimpleDropdown" #dropdownOfflineIcon="simpleDropdown" [class.dropdown--opened]="dropdownOfflineIcon.opened" [class.dropdown--closed]="!dropdownOfflineIcon.opened &amp;&amp; !dropdownOfflineIcon.firstDrop"><span class="offline-icon"><i class="gw-cloud--off offline-icon__icon"></i></span>
          <div class="dropdown__content jsDropdownContent header-dropdown__content" [class.dropdown__content--hidden]="!dropdownOfflineIcon.openedWithDelay" [style.white-space]="dropdownOfflineIcon.isRightOverflow ? 'normal' : 'nowrap'">
            {{ 'AUDIT_CONDUCT.HEADER.LAST_SYNCED' | translate }}:&nbsp;
            {{ audit.offlineDate | dateFormat: 'MMM D, h:mm a' }}
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="!audit.disabled" page-header-right="page-header-right">
    <ul class="header__menu header__menu--right header__menu--medium-horizontal">
      <li class="header__item header__item--text" *ngIf="audit?.offline"><span class="show-for-medium">
          {{ 'AUDIT_CONDUCT.HEADER.LAST_SYNCED' | translate }}:&nbsp;
          {{ audit.offlineDate | dateFormat: 'MMM D, h:mm a' }}</span>
        <button class="header__button--with-label" type="button" (click)="syncAudit(audit)" [disabled]="syncingAuditOffline || !offlineService.online" [class.button--primary]="offlineService.online" [class.header__button--custom]="offlineService.online" [class.button--tertiary]="!offlineService.online" [class.header__button]="!offlineService.online"><i class="gw-cloud--sync button__icon"></i><i class="button__icon--after button__icon--spinner" *ngIf="syncingAuditOffline">
            <div class="spinner"></div></i></button>
      </li>
    </ul>
  </ng-container>
</gw-page-header>
<gw-summary-tabs *ngIf="audit" [audit]="audit"></gw-summary-tabs>
<router-outlet></router-outlet>
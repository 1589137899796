import { Injectable } from '@angular/core';

import { Company } from '../../../../models/company.model';
import { EmployeeExtended } from '../../../../models/employee-extended.model';
import { CurrentUser } from '../models/current-user.model';

import { ApiCurrentUser } from '../interfaces/current-user.interface';
import { ApiGraphQLEmployeeResponse } from '../interfaces/user.interface';

import { I18nService } from '../../../../services/i18n.service';

import { convertAvatarToGet } from '../../../../converters/attachment.convert';
import { convertLanguageCodeToGet } from '../converters/language.convert';
import { convertRoleToGet } from '../converters/role.convert';

@Injectable({
  providedIn: 'root'
})
export class UserConverterService {
  constructor(private i18nService: I18nService) {}

  convertCurrentUserToGet(currentUser: ApiCurrentUser): CurrentUser {
    const currentEmployee = currentUser.employees?.find(employee => employee.company.id === currentUser.company.id);
    return new CurrentUser({
      id: currentUser.id,
      email: currentUser.email,
      name: currentEmployee?.name,
      lastname: currentEmployee?.lastName,
      avatar: currentEmployee?.avatar ? convertAvatarToGet(currentEmployee.avatar) : undefined,
      employees: currentUser.employees
        ? currentUser.employees.map(employee => this.convertEmployeeToGet(employee))
        : [],
      company: new Company(currentUser.company),
      language: this.i18nService.getLanguage(convertLanguageCodeToGet(currentUser.language))
    });
  }

  convertEmployeeToGet(employee: ApiGraphQLEmployeeResponse): EmployeeExtended {
    return new EmployeeExtended({
      access: employee.access,
      avatar: employee.avatar ? convertAvatarToGet(employee.avatar) : undefined,
      company: new Company(employee.company),
      email: employee.email,
      id: employee.id,
      lastname: employee.lastName,
      name: employee.name,
      roles: employee.roles ? employee.roles.map(role => convertRoleToGet(role)) : [],
      language: this.i18nService.getLanguage(convertLanguageCodeToGet(employee.language))
    });
  }
}

import { LanguageCode } from '../enums/language-code.enum';

export class UserProfile {
  username: string;
  firstname: string;
  lastname: string;
  language: LanguageCode;
  customerId?: number;

  constructor(userProfile: UserProfile) {
    this.username = userProfile.username;
    this.firstname = userProfile.firstname;
    this.lastname = userProfile.lastname;
    this.language = userProfile.language;
    this.customerId = userProfile.customerId;
  }
}

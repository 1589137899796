import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
// Gutwin Shared Library
import { Employee } from 'gutwin-shared';
// Providers
import { UserService } from './../../shared/services/user.service';
import { PageStatusService } from './../../shared/services/page-status.service';

@Injectable()
export class UserResolve implements Resolve<any> {
  constructor(
    private userService: UserService,
    private pageStatusService: PageStatusService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) { }

  resolve (route: ActivatedRouteSnapshot): Promise<Employee> {
    return this.userService.getEmployee(route.params['userId'])
      .catch(error => {
        if (error.status === 404 || error.status === 400) {
          this.fetchMessage404Translation()
            .then((translation: string) => {
              this.pageStatusService.goTo404(translation);
            });
        }
        this.fetchErrorTranslation()
          .then((translation: any) => {
            this.notificationsService.error(translation.title, translation.text);
          });
        return undefined;
      });
  }

  fetchMessage404Translation(): Promise<string> {
    return this.translateService.get('GLOBAL.ERROR.NOT_FOUND.USER')
      .toPromise()
      .then((translation: string) => {
        return translation;
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ERROR.LOAD_USER'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['GLOBAL.ERROR.LOAD_USER']
        };
      });
  }
}


<div class="add-question">
  <form class="add-question__form" [formGroup]="addQuestionForm" novalidate="novalidate" autocomplete="off">
    <div class="add-question__fieldset">
      <div class="row add-question__fields">
        <div class="columns small-12 small-centered">
          <div class="form__field--lower add-question__field" [class.form__field--error]="isFieldInvalid(addQuestionForm.controls['section'])">
            <label class="label add-question__field-label" for="section" (click)="focusSectionSelect()">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.FORM.LABEL.CHAPTER' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.FORM.ERROR.CHAPTER_EMPTY' | translate }}</div>
            <gw-select *ngIf="!addSection" #sectionSelect="gwSelect" [formControl]="addQuestionForm.controls['section']" [tabindex]="1" [placeholder]="'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.FORM.PLACEHOLDER.CHAPTER' | translate" [data]="sections" [display]="['name']" [uniqueKey]="'id'" [disabled]="sections?.length === 1 || question"></gw-select>
            <input class="input" *ngIf="addSection" placeholder="{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.FORM.PLACEHOLDER.CHAPTER' | translate }}" type="text" id="section" name="section" tabindex="1" [formControl]="addQuestionForm.controls['section']"/>
          </div>
          <div class="form__field--lower add-question__field" [class.form__field--error]="isFieldInvalid(addQuestionForm.controls['content'])">
            <label class="label add-question__field-label" for="content">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.FORM.LABEL.QUESTION' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.FORM.ERROR.QUESTION_EMPTY' | translate }}</div>
            <div class="textarea-container">
              <textarea class="textarea--small textarea--autosize" gwAutosize="gwAutosize" rows="1" id="content" name="content" tabindex="2" placeholder="{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.FORM.PLACEHOLDER.QUESTION' | translate }}" [formControl]="addQuestionForm.controls['content']"></textarea>
              <div class="textarea-border"></div>
            </div>
          </div>
          <div class="form__field--lower add-question__field" [class.form__field--error]="isFieldInvalid(addQuestionForm.controls['description'])">
            <label class="label add-question__field-label" for="description">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.FORM.LABEL.DESCRIPTION' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.FORM.ERROR.DESCRIPTION_EMPTY' | translate }}</div>
            <div class="textarea-container">
              <textarea class="textarea--small textarea--autosize" gwAutosize="gwAutosize" rows="1" id="description" name="description" tabindex="3" placeholder="{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.FORM.PLACEHOLDER.DESCRIPTION' | translate }}" [formControl]="addQuestionForm.controls['description']"></textarea>
              <div class="textarea-border"></div>
            </div>
          </div>
          <div class="form__field--lower add-question__field">
            <label class="label add-question__field-label">
              <gw-check-box class="add-question__check-box" [(ngModel)]="addAnother" [ngModelOptions]="{standalone: true}" [tabindex]="4"></gw-check-box><span class="link add-question__check-box-label">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTION_MODAL.FORM.LABEL.ADD_ANOTHER' | translate }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
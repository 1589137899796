import { DecimalPipe } from '@angular/common';
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { Subscription } from 'rxjs';

import { Attachment } from '../../models/attachment.model';

import { FileService } from '../../services/file.service';
import { LightboxOption, LightboxService } from '../../services/lightbox.service';
import { OfflineService } from '../../services/offline.service';

export class RotationStyles {
  'transform-origin': string;
  'transform': string;
  'height': string;
  'width': string;
  'left': string;
  'top': string;
}

@Component({
  selector: 'gw-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
  providers: [DecimalPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentComponent implements OnInit, OnChanges, AfterContentChecked {
  @ViewChild('fileInput') fileInput: ElementRef;

  @HostBinding('class.attachment-container--box') isBox: boolean;
  @HostBinding('class.attachment-container--image') isImage: boolean;

  @Input() attachment?: Attachment;
  @Input() viewOnly = false;
  @Input() gallery: Array<Attachment>;
  @Input() imageSize = 'cover';
  @Input() coverText?: string;
  @Input() lightboxOptions?: Array<LightboxOption>;
  @Input() enableUpload = false;
  @Input() previewLabel = false;
  @Input() uploadLabel?: string;
  @Input() inputName = 'addAttachment';
  @Input() isUserTypeClient?: boolean;
  @Input() acceptedFileTypes = 'image/*';

  @Output() removed = new EventEmitter<void>();
  @Output() submitAttachment = new EventEmitter<Attachment>();

  imageTag: HTMLElement;
  imgSrc: string;
  rotationStyles = new RotationStyles();
  emptyImageBackground = false;
  isImageAttaching = false;
  offlineSubscription: Subscription;

  constructor(
    public element: ElementRef,
    private fileService: FileService,
    private numberPipe: DecimalPipe,
    private lightboxService: LightboxService,
    private changeDetector: ChangeDetectorRef,
    public offlineService: OfflineService
  ) {}

  ngOnInit(): void {
    this.init();
    this.offlineService.onlineObservable.subscribe(() => {
      this.changeDetector.markForCheck();
    });
  }

  ngAfterContentChecked(): void {
    this.imageTag = this.element.nativeElement;
    const rotation = this.attachment?.rotation;

    if (rotation && rotation !== 0) {
      this.rotationStyles['transform'] = `rotate(${rotation}deg)`;
      this.rotationStyles['left'] = rotation === 90 ? '100%' : '';
      this.rotationStyles['top'] = rotation === 270 ? '100%' : '';
      if (rotation === 90 || rotation === 270) {
        this.rotationStyles['height'] = `${this.imageTag.offsetWidth - 2}px`;
        this.rotationStyles['width'] = `${this.imageTag.offsetHeight - 2}px`;
        this.rotationStyles['transform-origin'] = '0 0';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['attachment']) {
      this.init();
    }
  }

  init(): void {
    if (this.attachment) {
      this.isBox = this.attachment.isImage;
      this.isImage = this.attachment.isImage;
      this.generateTemporaryImage();
    }
  }

  removeAttachment(): void {
    this.removed.emit();
  }

  fullName(file: File): string {
    return file.name + '.' + file.type;
  }

  sizeWithUnit(size: any): string {
    let sizeUnit: string;
    if (size >= 1000000) {
      size = this.numberPipe.transform(size / 1000000, '1.0-1');
      sizeUnit = 'MB';
    } else if (size >= 1000) {
      size = this.numberPipe.transform(size / 1000, '1.0-0');
      sizeUnit = 'kB';
    } else {
      sizeUnit = 'B';
    }
    return size + ' ' + sizeUnit;
  }

  generateTemporaryImage(urlIsNotWorking?: boolean): void {
    if (this.isImage) {
      if (this.attachment.url && !urlIsNotWorking) {
        this.imgSrc = this.attachment.url;
      } else if (this.attachment.file) {
        this.fileService.generateImagePreview(this.attachment.file).then(image => {
          this.attachment.preview = image;
          this.imgSrc = image;
          this.changeDetector.markForCheck();
        });
      }
    }
  }

  showLightbox(): void {
    if (this.isImage) {
      this.lightboxService.show(this.attachment, this.gallery, this.lightboxOptions);
    }
  }

  imageLoaded(): void {
    this.emptyImageBackground = true;
  }

  imageNotLoaded(): void {
    this.generateTemporaryImage(true);
  }

  async uploadFile(event: Event): Promise<Attachment> {
    const fileList: FileList = event.target['files'];
    const file: File = fileList[0];
    const { contentType } = await this.fileService.readFile(file);
    const url = await this.fileService.generateImagePreview(file);

    return new Attachment({
      file,
      url,
      name: file.name,
      mimeType: contentType,
      isImage: true,
      size: file.size
    });
  }

  async attachFiles(event: Event): Promise<void> {
    this.isImageAttaching = true;
    const image = await this.uploadFile(event);
    this.isImageAttaching = false;
    this.submitAttachment.emit(image);
    this.fileService.resetFileInput(this.fileInput);
  }
}

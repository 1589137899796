import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gw-requirement-content-tab',
  templateUrl: './requirement-content-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequirementContentTabComponent {
  @Input() greyHeader = false;
}

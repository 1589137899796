import { Injectable } from '@angular/core';

import { reject } from 'lodash';

import { TreeFilterOption } from '../models/tree-filter-option.model';
import { TreeNode } from '../models/tree-node.model';

import { NestedObjectOfIds } from '../interfaces/nested-object-of-ids.interface';

import { HelperUtil } from '../utils/helper.util';

@Injectable({
  providedIn: 'root'
})
export class TreeService {
  constructor() {}

  appendNode<T extends TreeNode = TreeNode>(nodes: Array<T>, nodeToAppend: T, parent?: T): Array<T> {
    if (!parent) {
      const updatedNodes = [...nodes, nodeToAppend];
      this.sortNodes(updatedNodes);
      return updatedNodes;
    }

    return nodes?.map(node => {
      if (node.id === parent.id) {
        const children = [...(node.children ?? []), nodeToAppend];
        this.sortNodes(children);
        return {
          ...node,
          children
        };
      } else if (node.children) {
        return {
          ...node,
          children: this.appendNode<T>(node.children as Array<T>, nodeToAppend, parent)
        };
      }
    });
  }

  updateNode<T extends TreeNode = TreeNode>(nodes: Array<T>, nodeToUpdate: T, keysToUpdate: Array<keyof T>): Array<T> {
    const updatedNodes = nodes?.map(node => {
      if (node.id === nodeToUpdate.id) {
        return {
          ...node,
          ...HelperUtil.getFieldsFromObject(nodeToUpdate, keysToUpdate)
        };
      } else if (node.children) {
        return {
          ...node,
          children: this.updateNode<T>(node.children as Array<T>, nodeToUpdate, keysToUpdate)
        };
      } else {
        return node;
      }
    });
    this.sortNodes(updatedNodes);
    return updatedNodes;
  }

  spliceNode<T extends TreeNode = TreeNode>(nodes: Array<T>, nodeToSplice: T): Array<T> {
    return nodes
      ?.filter(node => node.id !== nodeToSplice.id)
      .map(node => ({
        ...node,
        children: this.spliceNode(node.children, nodeToSplice)
      }));
  }

  replaceNode<T extends TreeNode = TreeNode>(nodes: Array<T>, nodeToReplace: T, newParent?: T): Array<T> {
    let updatedNodes = this.spliceNode(nodes, nodeToReplace);
    updatedNodes = this.appendNode(updatedNodes, nodeToReplace, newParent);
    return updatedNodes;
  }

  sortNodes(nodes: Array<TreeNode>): void {
    nodes.sort((nodeA: TreeNode, nodeB: TreeNode) => {
      return nodeA.name > nodeB.name ? 1 : -1;
    });
  }

  cloneTree<T>(tree: Array<T>): Array<T> {
    tree = tree || [];
    const treeClone = tree.map(node => {
      return Object.assign({}, node, { children: this.cloneTree<T>(node['children']) });
    });
    return treeClone;
  }

  clearNodes<T extends TreeNode = TreeNode>(allNodes: Array<T>, flagKeys: Array<keyof T>): Array<T> {
    return allNodes?.map(node => {
      const updatedNode: T = {
        ...node,
        children: this.clearNodes(node.children as Array<T>, flagKeys)
      };
      flagKeys.forEach(flagKey => {
        delete updatedNode[flagKey];
      });

      return updatedNode;
    });
  }

  markNodes<T extends TreeNode = TreeNode>(
    allNodes: Array<T>,
    selectedNodesIds: NestedObjectOfIds | Array<string>,
    flagName: keyof T,
    pathFlagName?: keyof T,
    childrenFlagName?: keyof T
  ): Array<T> {
    const markPathToNode = (nodes: Array<T>, filteredId: string, path: Array<string>): Array<T> => {
      return nodes?.map(node => {
        if (node.id === path[0]) {
          path = path.slice(1);
          return {
            ...node,
            [pathFlagName]: true,
            children: markPathAndNode(node.children as Array<T>, filteredId, path)
          };
        }
        return node;
      });
    };

    const markSelectedNode = (nodes: Array<T>, selectedId: string): Array<T> => {
      return nodes?.map(node => {
        if (node.id === selectedId) {
          return markNodeAsSelected(node);
        }
        return node;
      });
    };

    const markNodeAsSelected = (node: T): T => {
      const children = childrenFlagName ? markChildrenNodes(node.children as Array<T>) : node.children;
      return {
        ...node,
        [flagName]: true,
        children
      };
    };

    const markChildrenNodes = (children: Array<T>): Array<T> => {
      return children?.map(child => ({
        ...child,
        [childrenFlagName]: true,
        children: markChildrenNodes(child.children as Array<T>)
      }));
    };

    const markPathAndNode = (nodes: Array<T>, filteredId: string, path: Array<string>): Array<T> => {
      if (path.length) {
        return markPathToNode(nodes, filteredId, path);
      } else {
        return markSelectedNode(nodes, filteredId);
      }
    };

    const markNodesFromObject = (nodes: Array<T>, selectedNodesIds: NestedObjectOfIds): Array<T> => {
      let updatedNodes = nodes;
      for (const key in selectedNodesIds) {
        if (selectedNodesIds.hasOwnProperty(key)) {
          updatedNodes = markPathAndNode(updatedNodes, key, selectedNodesIds[key]);
        }
      }
      return updatedNodes;
    };

    const markNodesFromArray = (nodes: Array<T>, selectedNodesIds: Array<string>): Array<T> => {
      return nodes?.map(node => {
        const selectedNode = selectedNodesIds.includes(node.id as string) ? markNodeAsSelected(node) : node;
        return {
          ...selectedNode,
          children: markNodesFromArray(selectedNode.children as Array<T>, selectedNodesIds)
        };
      });
    };

    if (allNodes && selectedNodesIds) {
      return Array.isArray(selectedNodesIds)
        ? markNodesFromArray(allNodes, selectedNodesIds as Array<string>)
        : markNodesFromObject(allNodes, selectedNodesIds);
    }
    return allNodes;
  }

  markSelectedNodes<T extends TreeFilterOption = TreeFilterOption>(
    nodes: Array<T>,
    selectedNodesIds: NestedObjectOfIds | Array<string>
  ): Array<T> {
    const cleanedNodes = this.clearNodes<T>(nodes, ['isSelected']);
    return this.markNodes<T>(cleanedNodes, selectedNodesIds, 'isSelected');
  }

  getNodesPaths(nodes: Array<TreeNode>, selectedNodesIds: NestedObjectOfIds): Array<string> {
    if (nodes && selectedNodesIds) {
      const paths = [];
      for (const id in selectedNodesIds) {
        if (selectedNodesIds.hasOwnProperty(id)) {
          paths.push(this.getNodePath(nodes, id, selectedNodesIds[id]));
        }
      }
      return paths;
    }
  }

  getNodePath(nodes: Array<TreeNode>, nodeId: string, idsOnPath: Array<string>): string {
    const getNode = (id: string): TreeNode => {
      return nodes.find(node => node.id === id);
    };

    if (idsOnPath.length) {
      const currentNode = getNode(idsOnPath[0]);
      if (currentNode) {
        idsOnPath = idsOnPath.slice(1);
        if (currentNode.children) {
          const path = this.getNodePath(currentNode.children, nodeId, idsOnPath);
          return `${currentNode.name}/${path}`;
        }
      }
    } else {
      const currentNode = getNode(nodeId);
      return currentNode ? currentNode.name : '';
    }
  }

  getNodesByIds<T extends { id: string | number; children?: Array<T> } = TreeNode>(
    ids: Array<string | number>,
    nodes: Array<T>
  ): Array<T> {
    const chosenNodes = [];
    if (ids) {
      nodes?.forEach(node => {
        if (ids.includes(node.id)) {
          chosenNodes.push(node);
        }
        chosenNodes.push(...this.getNodesByIds(ids, node.children));
      });
    }
    return chosenNodes;
  }

  removeNodeUnderCondition<T extends TreeNode = TreeNode>(nodes: Array<T>, condition: (...args) => boolean): Array<T> {
    if (!nodes?.length) return nodes;

    return reject(nodes, condition).map(node => ({
      ...node,
      children: this.removeNodeUnderCondition((node?.children as Array<T>) || [], condition)
    }));
  }
}

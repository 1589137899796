import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Subscription } from 'rxjs';

// Gutwin Shared Library
import { SelectComponent } from 'gutwin-shared';

// Models
import { Audit } from '../../models/audit.model';
import { AuditedArea } from '../../models/audited-area.model';
import { Question } from '../../models/question.model';
import { Questionnaire } from '../../models/questionnaire.model';
import { Section } from '../../models/section.model';

// Services
import { QuestionService } from '../../services/question.service';
import { SectionService } from '../../services/section.service';

@Component({
  selector: 'gw-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss'],
  exportAs: 'gwAddQuestion'
})
export class AddQuestionComponent implements OnInit, OnDestroy {
  @ViewChild('sectionSelect') sectionSelect: SelectComponent;
  @Input() composable: Audit | AuditedArea | Questionnaire;
  @Input() sections: Array<Section>;
  @Input() section: Section;
  @Input() question: Question;
  addQuestionForm: FormGroup;
  addAnother = false;
  addSection = false;
  submitted = false;
  translateSubscription: Subscription;
  notify = {
    error: {
      connectionTitle: '',
      createSection: '',
      createQuestion: '',
      updateQuestion: ''
    },
    success: {
      createSectionTitle: '',
      createSectionText: '',
      createQuestionTitle: '',
      createQuestionText: '',
      updateQuestionTitle: '',
      updateQuestionText: ''
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private questionService: QuestionService,
    private sectionService: SectionService
  ) {}

  ngOnInit(): void {
    this.translateSubscription = this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.CREATE_SECTION',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.CREATE_QUESTION',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.UPDATE_QUESTION',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_SECTION_TITLE',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_SECTION_TEXT',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_QUESTION_TITLE',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_QUESTION_TEXT',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_QUESTION_TITLE',
        'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_QUESTION_TEXT'
      ])
      .subscribe((translation: any) => {
        this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.createSection = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.CREATE_SECTION'];
        this.notify.error.createQuestion = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.CREATE_QUESTION'];
        this.notify.error.updateQuestion = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.UPDATE_QUESTION'];
        this.notify.success.createSectionTitle =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_SECTION_TITLE'];
        this.notify.success.createSectionText =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_SECTION_TEXT'];
        this.notify.success.createQuestionTitle =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_QUESTION_TITLE'];
        this.notify.success.createQuestionText =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_QUESTION_TEXT'];
        this.notify.success.updateQuestionTitle =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_QUESTION_TITLE'];
        this.notify.success.updateQuestionText =
          translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_QUESTION_TEXT'];
      });

    this.addQuestionForm = this.formBuilder.group({
      section: [this.getSectionControlValue() || '', Validators.required],
      content: ['', Validators.required],
      description: ['']
    });
    if (this.question) {
      this.setControlValue(this.question.content, 'content');
      this.setControlValue(this.question.description, 'description');
    }

    if (!(this.sections && this.sections.length > 0)) {
      this.addSection = true;
    }
  }

  ngOnDestroy(): void {
    this.translateSubscription.unsubscribe();
  }

  resetForm(): void {
    this.setControlValue(this.getSectionControlValue(), 'section');
    if (this.question) {
      this.setControlValue(this.question.content, 'content');
      this.setControlValue(this.question.description, 'description');
    } else {
      this.setControlValue('', 'content');
      this.setControlValue('', 'description');
    }
  }

  clearComponent(): void {
    this.question = undefined;
    this.resetForm();
  }

  setControlValue(data: any, key: string): void {
    this.addQuestionForm.controls[key].setValue(data);
  }

  getSectionControlValue(): Section {
    if (this.section) {
      return this.section;
    } else if (this.sections && this.sections.length === 1) {
      return this.sections[0];
    }
    return;
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return (!field || !field.valid) && this.submitted;
  }

  focusSectionSelect(): void {
    if (this.sectionSelect) {
      this.sectionSelect.focusDropdown();
    }
  }

  createSection(): Promise<Section | any> {
    const sectionToCreate = new Section({ name: this.addQuestionForm.value.section });
    return this.sectionService
      .createSection(sectionToCreate, this.composable)
      .then(section => {
        section.custom = true;
        this.notificationsService.success(
          this.notify.success.createSectionTitle,
          this.notify.success.createSectionText
        );
        return section;
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.createSection);
        throw error;
      });
  }

  createQuestion(): Promise<any> {
    const questionToCreate = new Question(this.addQuestionForm.value);
    const sectionToCreate = new Section(this.addQuestionForm.value.section);
    return this.questionService
      .createQuestion(questionToCreate, sectionToCreate, this.composable)
      .then(question => {
        question.custom = true;
        this.notificationsService.success(
          this.notify.success.createQuestionTitle,
          this.notify.success.createQuestionText
        );
        return {
          status: 'create',
          question,
          section: sectionToCreate,
          addAnother: this.addAnother
        };
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.createQuestion);
        throw error;
      });
  }

  updateQuestion(): Promise<any> {
    const questionToUpdate = new Question(this.addQuestionForm.value);
    questionToUpdate.id = this.question.id;
    const sectionToUpdate = new Section(this.addQuestionForm.value.section);
    return this.questionService
      .updateQuestion(questionToUpdate, sectionToUpdate, this.composable)
      .then(question => {
        question.custom = true;
        this.notificationsService.success(
          this.notify.success.updateQuestionTitle,
          this.notify.success.updateQuestionText
        );
        return {
          status: 'update',
          question,
          section: sectionToUpdate,
          addAnother: this.addAnother
        };
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.updateQuestion);
        throw error;
      });
  }

  submitAddQuestionForm(): Promise<any> {
    this.submitted = true;
    if (this.addQuestionForm.valid) {
      if (this.addSection) {
        return this.createSection()
          .then(section => {
            this.addSection = false;
            if (!this.sections) {
              this.sections = new Array<Section>();
            }
            this.sections.push(section);
            this.section = section;
            this.setControlValue(section, 'section');
            return this.submitQuestion();
          })
          .catch(error => new Promise((resolve, reject) => reject(error)));
      } else {
        return this.submitQuestion();
      }
    } else {
      return new Promise((resolve, reject) => reject({ invalid: true }));
    }
  }

  submitQuestion(): Promise<any> {
    const questionPromise = this.question ? this.updateQuestion() : this.createQuestion();
    return questionPromise
      .then(response => {
        this.section = this.addQuestionForm.value.section;
        this.addAnother ? this.clearComponent() : this.resetForm();
        this.submitted = false;
        return response;
      })
      .catch(error => {
        throw error;
      });
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TreeFilterOption } from '../../models/tree-filter-option.model';
import { TreeNodeZip } from '../../models/tree-node.model';

import { MultiselectTheme } from '../../enums/multiselect-theme.enum';
import { SelectIconTheme } from '../../enums/select-icon-theme.enum';

import { TreeNodesIds } from '../../types/tree-nodes-ids.type';

import { SearchableFlatTreeMultiselectComponent } from '../searchable-flat-tree-multiselect/searchable-flat-tree-multiselect.component';

import { SimpleDropdownDirective } from '../../directives/simple-dropdown.directive';

import { TreeService } from '../../services/tree.service';

import { getZipNodes } from '../../utils/tree.util';

@Component({
  selector: 'gw-flat-tree-multiselect-input',
  templateUrl: './flat-tree-multiselect-input.component.html',
  styleUrls: ['./flat-tree-multiselect-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlatTreeMultiselectInputComponent),
      multi: true
    }
  ],
  exportAs: 'gwFlatTreeMultiselectInput',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatTreeMultiselectInputComponent implements ControlValueAccessor {
  @ViewChild('selectDropdown', { static: true }) selectDropdown: SimpleDropdownDirective;
  @ViewChild(SearchableFlatTreeMultiselectComponent)
  searchableFlatTreeMultiselect: SearchableFlatTreeMultiselectComponent;

  @Input() data: Array<any>;
  @Input() disabled?: boolean;
  @Input() display: Array<string>;
  @Input() uniqueKey: string;
  @Input() placeholder: string;
  @Input() tabindex: number;
  @Input() splitter = ' ';
  @Input() small = false;
  @Input() containWidth = true;
  @Input() markSelectedChildren = false;
  @Input() theme?: MultiselectTheme;
  @Input() iconTheme?: SelectIconTheme;

  selected: TreeNodesIds;
  selectedNodes: Array<TreeNodeZip>;

  readonly MULTISELECT_THEME = MultiselectTheme;
  readonly MULTISELECT_ICON_THEME = SelectIconTheme;

  constructor(private changeDetector: ChangeDetectorRef, private treeService: TreeService) {}

  writeValue(value: TreeNodesIds): void {
    this.initSelectedData(value);
  }

  onChange: (_: TreeNodesIds) => void = () => {};

  registerOnChange(fn: (_: TreeNodesIds) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  initSelectedData(selected: TreeNodesIds): void {
    this.selected = selected || {};
    this.selectedNodes = getZipNodes(this.data, this.selected);
    this.changeDetector.markForCheck();
  }

  openDropdown(): void {
    this.selectDropdown.openDropdown();
  }

  focusDropdown(): void {
    this.selectDropdown.focusOnDropdown();
  }

  onUpdateSelectedNodes(selectedNodes: Array<TreeNodeZip>): void {
    this.selectedNodes = selectedNodes;
  }

  onSubmitSelected(): void {
    this.onChange(this.selected);
  }

  onChangeSelected(value: TreeNodesIds): void {
    this.initSelectedData(value);
    this.onSubmitSelected();
  }
}

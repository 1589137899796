import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// Models
import { RatingScale } from '@gutwin-audit/shared/models/rating-scale.model';
// Providers
import { RatingScalesService } from '@gutwin-audit/shared/services/raiting-scales.service';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class RatingScalesResolve implements Resolve<any> {
  constructor(
    private translateService: TranslateService,
    private ratingScalesService: RatingScalesService,
    private notificationsService: NotificationsService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Promise<Array<RatingScale>> {
    return this.ratingScalesService.getRatingScales()
      .catch(error => {
        this.fetchErrorTranslation()
          .then((translation: any) => {
            this.notificationsService.error(translation.title, translation.text);
          });
        return [];
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'ADMIN_SETTINGS.RATING_SCALES.ERROR.LOAD_RATING_SCALES'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['ADMIN_SETTINGS.RATING_SCALES.ERROR.LOAD_RATING_SCALES']
        };
      });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { countBy, orderBy } from 'lodash';

import { MultiselectError } from '../../interfaces/multiselect-error.interface';
import { MultiselectItem } from '../../interfaces/multiselect-item.interface';
import { ValueLimit } from '../../interfaces/value-limit.interface';

import { MultiselectTheme } from '../../enums/multiselect-theme.enum';
import { SelectIconTheme } from '../../enums/select-icon-theme.enum';
import { UserType } from '../../enums/user-type.enum';

import { SimpleDropdownDirective } from '../../directives/simple-dropdown.directive';

import { HelperUtil } from '../../utils/helper.util';

@Component({
  selector: 'gw-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true
    }
  ],
  exportAs: 'gwMultiselect',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiselectComponent implements OnInit, ControlValueAccessor {
  @ViewChild('selectDropdown', { static: true }) selectDropdown: SimpleDropdownDirective;
  @ViewChild('scrollView') scrollView: ElementRef;
  @ViewChildren('itemElement') itemsElements: QueryList<ElementRef>;

  @Input() set data(data: Array<MultiselectItem>) {
    this._data = data;
    this.init();
  }
  get data(): Array<MultiselectItem> {
    return this._data;
  }
  _data: Array<MultiselectItem>;
  @Input() display: Array<string> = ['name'];
  @Input() uniqueKey = 'id';
  @Input() orderBy: Array<{ key: string; order?: 'asc' | 'desc' }>;
  @Input() limit: { min: ValueLimit; max: ValueLimit };
  @Input() placeholder: string;
  @Input() tabindex: number;
  @Input() splitter = ' ';
  @Input() separator = ', ';
  @Input() shortPreview = false;
  @Input() small = false;
  @Input() wide = false;
  @Input() theme?: MultiselectTheme;
  @Input() iconTheme?: SelectIconTheme;
  @Input() containWidth = true;
  @Input() showSelectedCount = false;
  @Input() virtualScroll = true;
  @Input() alignRight = false;
  @Input() inline = false;
  @Input() search = true;
  @Input() autoSave = false;
  @Input() userType: UserType;
  @Input() set open(open: boolean) {
    if (open) this.openDropdown();
    else this.closeDropdown();
  }

  @Output() opened = new EventEmitter<boolean>();

  options: Array<MultiselectItem> = [];
  selected: Array<MultiselectItem> = [];
  disabled: boolean;
  searchQuery: string;
  filteredData: Array<MultiselectItem> = [];
  focusedItem: MultiselectItem;
  errors: { min?: boolean; max?: boolean }; // Workaround: Cannot have type MultiselectError, because of template compliator in ng-packagr
  useVirtualScroll: boolean;

  readonly ITEM_HEIGHT = {
    small: 28,
    normal: 30
  };
  readonly MULTISELECT_THEME = MultiselectTheme;
  readonly MULTISELECT_ICON_THEME = SelectIconTheme;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.init();
  }

  onChange: (_: Array<any>) => void = () => {};

  writeValue(selected: Array<any>): void {
    this.selected = selected;
    this.init();
  }

  registerOnChange(fn: (_: Array<any>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  init(): void {
    if (this.data) {
      this.options = this.initData(this.data);
      this.addMissingSelectedData(this.selected);
      this.checkSelected();
      this.checkValidation();
      this.filterItem(this.searchQuery, this.options);
      this.setVirtualScroll();
    }
    this.changeDetector.detectChanges();
  }

  setVirtualScroll(): void {
    this.useVirtualScroll = this.virtualScroll && this.options.length >= 5;
  }

  initData(data: Array<any>): Array<MultiselectItem> {
    return data.map(item => ({ ...item, selected: false }));
  }

  addMissingSelectedData(selected: Array<MultiselectItem>): void {
    if (this.options && selected) {
      const missingSelectedData = selected
        .map(item => ({ ...item, selected: true }))
        .filter(selectedItem => !this.options.find(item => item[this.uniqueKey] === selectedItem[this.uniqueKey]));
      this.options.unshift(...missingSelectedData);
    }
  }

  sortData(data: Array<any>): Array<any> {
    if (this.orderBy && this.orderBy.length) {
      const keys = this.orderBy.map(item => item.key);
      const order = this.orderBy.map(item => (item.order ? item.order : 'asc'));
      return orderBy(data, keys, order);
    }
    return data;
  }

  checkSelected(): void {
    this.selected = this.selected || [];
    this.options.forEach(item => (item.selected = this.isItemSelected(item, this.selected)));
  }

  isItemSelected(item: MultiselectItem, selected: Array<any>): boolean {
    return !!selected.find(selectedItem => selectedItem && selectedItem[this.uniqueKey] === item[this.uniqueKey]);
  }

  chooseFocusedItem(): any {
    if (this.filteredData && this.filteredData.length) {
      return this.filteredData[0];
    }
  }

  filterItem(query = '', data: Array<MultiselectItem>): void {
    query = query.toLowerCase();
    this.filteredData = data.filter(item => {
      const searchKey = this.getDisplay(item).toLowerCase();
      return ~searchKey.indexOf(query);
    });
    this.filteredData = this.sortData(this.filteredData);
  }

  checkValidation(): void {
    const appendError = (errors: MultiselectError, key: string): MultiselectError => {
      if (errors) {
        errors[key] = true;
      } else {
        errors = { [key]: true };
      }
      return errors;
    };

    this.errors = undefined;
    if (this.limit) {
      const selectedAmount = countBy(this.options, 'selected')['true'] || 0;
      if (this.limit.min && this.limit.min.value > selectedAmount) {
        this.errors = appendError(this.errors, 'min');
      }
      if (this.limit.max && this.limit.max.value < selectedAmount) {
        this.errors = appendError(this.errors, 'max');
      }
    }
  }

  isFocused(item: any): boolean {
    return this.focusedItem && this.focusedItem[this.uniqueKey] === item[this.uniqueKey];
  }

  getDisplay(item: any): string {
    return HelperUtil.getNestedAttributesToDisplay(this.display, item, this.splitter);
  }

  openDropdown(): void {
    this.init();
    this.selectDropdown.openDropdown();
  }

  closeDropdown(): void {
    this.selectDropdown.closeDropdown();
  }

  focusDropdown(): void {
    this.selectDropdown.focusOnDropdown();
  }

  scrollToItem(item: any): void {
    const index = this.filteredData.findIndex(filteredItem => filteredItem[this.uniqueKey] === item[this.uniqueKey]);
    const filteredElement = this.itemsElements.toArray()[index];
    this.scrollView.nativeElement.scrollTop =
      filteredElement.nativeElement.offsetTop - this.scrollView.nativeElement.offsetTop;
  }

  onSelectItem(): void {
    this.checkValidation();
    if (this.autoSave) {
      this.submitSelected(this.options);
    }
  }

  submitSelected(data: Array<any>): void {
    if (!this.errors) {
      const selected = data.filter(item => item.selected);
      selected.map(item => {
        const selectedItem = Object.assign({}, item);
        delete selectedItem.selected;
        return selectedItem;
      });
      this.selected = selected;
      this.onChange(this.selected);
    }
  }

  getAnotherItem(direction: string, currentItem: any, items: Array<any> = this.filteredData): any {
    const chooseAnotherOption = (index: number): any => {
      let focusedItem;
      const moveUp = direction === 'previous';
      const anotherIndex = moveUp ? index - 1 : index + 1;
      if ((moveUp && anotherIndex >= 0) || (!moveUp && anotherIndex < items.length)) {
        focusedItem = items[anotherIndex];
      } else {
        const loopIndex = moveUp ? items.length - 1 : 0;
        focusedItem = items[loopIndex];
      }
      return focusedItem;
    };

    // TODO: Fix for cdkVirtualFor
    if (items) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.id === currentItem.id) {
          return chooseAnotherOption(i);
        }
      }
    }
  }

  onToggleMultiselect(isOpened: boolean): void {
    this.opened.emit(isOpened);
  }

  @HostListener('keydown', ['$event'])
  keydown(event): boolean {
    switch (event.key) {
      case 'Tab':
        if (this.selectDropdown.openedWithDelay) {
          this.selectDropdown.closeDropdown();
        }
        break;
      case 'Enter':
        if (this.selectDropdown.openedWithDelay) {
          event.preventDefault();
          this.submitSelected(this.options);
          this.selectDropdown.closeDropdown();
          return false;
        }
        break;
      case ' ':
        if (this.selectDropdown.openedWithDelay && this.focusedItem) {
          event.preventDefault();
          this.focusedItem.selected = !this.focusedItem.selected;
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (this.selectDropdown.openedWithDelay) {
          this.focusedItem = this.focusedItem
            ? this.getAnotherItem('previous', this.focusedItem)
            : this.chooseFocusedItem();
          this.scrollToItem(this.focusedItem);
        } else {
          this.openDropdown();
        }
        return false;
      case 'ArrowDown':
        event.preventDefault();
        if (this.selectDropdown.openedWithDelay) {
          this.focusedItem = this.focusedItem
            ? this.getAnotherItem('next', this.focusedItem)
            : this.chooseFocusedItem();
          this.scrollToItem(this.focusedItem);
        } else {
          this.openDropdown();
        }
        return false;
    }
  }
}

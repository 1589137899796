export class AuditeAreaMock {
  getAuditedArea() {
    return {
      'id': '5329ba14-6324-4bf3-896f-3d03d556b488',
      'audit_id': '491a163e-131f-413e-9c69-1ef8ceca0436',
      'created_at': '2017-04-11T17:25:08.906Z',
      'updated_at': '2017-04-11T17:25:08.906Z',
      'location': 'Some location',
      'start_time': '2017-04-11T17:12:55.000Z',
      'end_time': '2017-04-12T17:13:15.000Z',
      'roles': [
        {
          'role': 'auditor',
          'employee': {
            'id': '4abf0764-a3e1-475b-8c3c-127f23c35fc7',
            'email': 'Luella_Ritchie@example.com',
            'name': 'Luella',
            'lastname': 'Ritchie',
            'created_at': '2017-03-09T16: 49: 03.364Z',
            'updated_at': '2017-03-09T16: 49: 03.364Z'
          }
        }
      ],
      'facility': {
        'id': '2f2621bf-bde5-40f6-89da-a3d465a95ff7',
        'name': 'Baby & Industrial',
        'description': null,
        'created_at': '2017-04-11T17:09:29.743Z',
        'updated_at': '2017-04-11T17:09:29.743Z'
      }
    };
  }
}

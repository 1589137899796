
<div class="findings-browser">
  <div class="findings-browser__box">
    <div class="form__field">
      <ng-content select="[findings-browser-nav]"></ng-content>
    </div>
    <div class="form__field">
      <label class="label" for="facility" (click)="facilitySelect.focusDropdown()">{{ 'AUDIT_CONDUCT.FINDINGS_BROWSER.FORM.LABEL.AUDITED_AREA' | translate }}</label>
      <gw-select #facilitySelect="gwSelect" [ngModel]="currentAuditable" [placeholder]="'AUDIT_CONDUCT.FINDINGS_BROWSER.FORM.PLACEHOLDER.AUDITED_AREA' | translate" [data]="displayedAuditedAreas" [display]="['facility.name']" [uniqueKey]="'id'" (ngModelChange)="changeAuditable($event)"></gw-select>
    </div>
  </div>
  <div class="findings-browser__box" *ngIf="summaryForm">
    <div class="offline-icon offline-icon--small findings-browser__offline-mark" *ngIf="currentAuditable.summaryOffline !== undefined"><i class="gw-cloud--off offline-icon__icon"></i></div>
    <div class="form__field">
      <label class="label" for="summary">{{ 'AUDIT_CONDUCT.SUMMARY.FORM.LABEL.SUMMARY' | translate }}</label>
      <div class="textarea-container" *ngIf="!isAuditDisabled">
        <textarea class="textarea--small textarea--autosize" gwAutosize="gwAutosize" [rows]="1" placeholder="{{ 'AUDIT_CONDUCT.SUMMARY.FORM.PLACEHOLDER.SUMMARY' | translate }}" id="summary" name="summary" [formControl]="summaryForm.controls['summary']" (blur)="summaryFieldBlur()"></textarea>
        <div class="textarea-border"></div>
      </div>
      <div class="findings-browser-box__text" *ngIf="isAuditDisabled">{{ currentAuditable.summary ? currentAuditable.summary : '-' }}</div>
    </div>
  </div>
</div>
import { TreeFilterOption } from 'gutwin-shared';

import { FacilityFilter } from '@gutwin-audit/shared/models/facility-filter.model';
import { Facility } from '@gutwin-audit/shared/models/facility.model';

import {
  ApiFacility,
  ApiFacilityToArchive,
  ApiFacilityToCreate,
  ApiFacilityToMove,
  ApiFacilityToUpdate
} from '@gutwin-audit/shared/interfaces/facility.interface';

export const convertFacilityToGet = (facility: ApiFacility): Facility => {
  return new Facility({
    id: facility.id,
    name: facility.name,
    parentFacility: facility.facility_id ? { id: facility.facility_id } : undefined,
    children: facility.children?.map(child => convertFacilityToGet(child)),
    archived: facility.archived
  });
};

export const convertFacilityToFilter = (facility: ApiFacility): FacilityFilter => {
  return new FacilityFilter({
    id: facility.id,
    name: facility.name,
    children: facility.children?.map(child => convertFacilityToFilter(child)),
    selected: false
  });
};

export const convertFacilityFilterToTreeFilterOption = (facility: FacilityFilter): TreeFilterOption => {
  return new TreeFilterOption({
    id: facility.id,
    name: facility.name,
    children: facility.children?.map(child => convertFacilityFilterToTreeFilterOption(child)),
    isSelected: facility.selected
  });
};

export const convertFacilityToCreate = (facility: Facility): ApiFacilityToCreate => {
  return {
    facility_id: facility.parentFacility ? facility.parentFacility.id : undefined,
    name: facility.name
  };
};

export const convertFacilityToUpdate = (facility: Facility): ApiFacilityToUpdate => {
  return {
    id: facility.id,
    name: facility.name
  };
};

export const convertFacilityToMove = (facility: Facility, parentFacility?: Facility): ApiFacilityToMove => {
  return {
    id: facility.id,
    facility_id: parentFacility?.id || ''
  };
};

export const convertFacilityToArchive = (facility: Facility, archive: boolean): ApiFacilityToArchive => {
  return {
    id: facility.id,
    archive: archive ? 'true' : 'false'
  };
};

export class SelectGroup {
  name: string;
  data: Array<any>;

  _type = 'SelectGroup';

  constructor(selectGroup: any) {
    this.name = selectGroup.name;
    this.data = selectGroup.data;
  }
}

export enum ApiPermissionNames {
  accessAllAudits = 'access_all_audits',
  createAudits = 'create_audits',
  manageAuditTypes = 'manage_audit_types',
  manageCompany = 'manage_company',
  manageFacilities = 'manage_facilities',
  manageFindingTypes = 'manage_finding_types',
  manageFinishedAudits = 'manage_finished_audits',
  manageQuestionnaires = 'manage_questionnaires',
  manageQuestionRatings = 'manage_question_ratings',
  manageReportTemplates = 'manage_report_templates',
  manageSolvedFindings = 'manage_solved_findings',
  manageUserRoles = 'manage_user_roles',
  manageUsers = 'manage_users',
  manageCauseAndSolution = 'edit_cause_assigned_findings'
}

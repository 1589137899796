
<h1 class="finding-source__header"><i class="gw-related finding-source__header-icon"></i>{{ 'FINDING_VIEW.FINDING_SOURCE.HEADER' | translate }}</h1>
<div class="finding-source__content">
  <h4 class="finding-source__label">{{ 'FINDING_VIEW.FINDING_SOURCE.LABEL.AUDIT' | translate }}:</h4>
  <gw-expand-box class="finding-source__box" #sourceExpandBox="gwExpandBox">
    <ng-container expand-box-header="expand-box-header">
      <button class="finding-source-box__row" type="button" (click)="sourceExpandBox.toggle()">
        <div class="finding-source-box__column"><span class="finding-source-box__label">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREA' | translate }}:</span><span class="finding-source-box__value">{{ finding.relationships.auditedArea?.facility?.name }}</span></div>
        <div class="finding-source-box__column">
          <div class="icon finding-source-box__icon"><i class="gw-angle--down expand-box__toggle-icon"></i></div>
        </div>
      </button>
    </ng-container>
    <ng-container expand-box-content="expand-box-content">
      <ng-container>
        <div class="finding-source-box__row">
          <div class="finding-source-box__column"><span class="finding-source-box__label">{{ 'GLOBAL.TABLE_LABEL.TYPE' | translate }}:</span><span class="finding-source-box__value">{{ finding.relationships.audit?.type?.title }}</span></div>
        </div>
        <div class="finding-source-box__row">
          <div class="finding-source-box__column"><span class="finding-source-box__label">{{ 'GLOBAL.TABLE_LABEL.DATE' | translate }}:</span><span class="finding-source-box__value">{{ finding.relationships.auditedArea?.startTime | dateFormat: 'DD.MM.YYYY' }}&nbsp;
              <ng-container *ngIf="!isAuditedAreaInOneDay()">- {{ finding.relationships.auditedArea?.endTime | dateFormat: 'DD.MM.YYYY' }}</ng-container></span></div>
        </div>
        <div class="finding-source-box__row">
          <div class="finding-source-box__column"><span class="finding-source-box__label">{{ 'GLOBAL.TABLE_LABEL.LEAD_AUDITOR' | translate }}:</span><span class="finding-source-box__value">
              <ng-container *ngFor="let auditor of finding.relationships.audit?.employees?.leadAuditor; last as lastEmployee">{{ auditor.lastname }}, {{ auditor.name }}{{ !lastEmployee ? ';&nbsp;' : '' }}</ng-container></span></div>
        </div>
        <div class="finding-source-box__row" *ngIf="finding.relationships.audit?.employees?.coAuditor?.length">
          <div class="finding-source-box__column"><span class="finding-source-box__label">{{ 'GLOBAL.TABLE_LABEL.CO_AUDITOR' | translate }}:</span><span class="finding-source-box__value">
              <ng-container *ngFor="let auditor of finding.relationships.audit?.employees?.coAuditor; last as lastEmployee">{{ auditor.lastname }}, {{ auditor.name }}{{ !lastEmployee ? ';&nbsp;' : '' }}</ng-container></span></div>
        </div>
        <div class="finding-source-box__row">
          <div class="finding-source-box__column"><span class="finding-source-box__label">{{ 'GLOBAL.TABLE_LABEL.ORGANIZER' | translate }}:</span><span class="finding-source-box__value">
              <ng-container *ngFor="let organizer of finding.relationships.audit?.employees?.organizer; last as lastEmployee">{{ organizer.lastname }}, {{ organizer.name }}{{ !lastEmployee ? ';&nbsp;' : '' }}</ng-container></span></div>
        </div>
      </ng-container>
    </ng-container>
  </gw-expand-box>
</div>
import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { RouteItem } from '../../interfaces/route-item.interface';

import { RouterService } from '../../services/router.service';

import { AddPreviousRoute, PopPreviousRoute } from './route.actions';

export interface RouteModel {
  previousRoute?: RouteItem;
  routesHistory: Array<RouteItem>;
}

@State<RouteModel>({
  name: 'route',
  defaults: {
    routesHistory: []
  }
})
@Injectable()
export class RouteState {
  constructor(private routerService: RouterService) {}

  @Selector()
  static previousRoute(state: RouteModel): RouteItem {
    return state.previousRoute;
  }

  @Action(AddPreviousRoute)
  addPreviousRoute(context: StateContext<RouteModel>, action: AddPreviousRoute): void {
    const previousRoute = context.getState().previousRoute;
    const routesHistory = [...context.getState().routesHistory, previousRoute];
    const newPreviouseRoute = action.previousRoute || this.routerService.getCurrentRoute();

    if (previousRoute?.url !== newPreviouseRoute?.url) {
      context.patchState({
        previousRoute: newPreviouseRoute,
        routesHistory
      });
    }
  }

  @Action(PopPreviousRoute)
  popPreviousRoute(context: StateContext<RouteModel>): void {
    const routesHistory = [...context.getState().routesHistory];
    const previousRoute = routesHistory.pop();

    context.patchState({
      previousRoute,
      routesHistory
    });
  }
}

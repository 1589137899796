import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ListControls } from '../../interfaces/list-controls.interface';
import { SortOption } from '../../interfaces/sort-option.interface';

import { InputWithLabelType } from '../../enums/input-with-label-type.enum';
import { SearchTheme } from '../../enums/search-theme.enum';
import { SelectTheme } from '../../enums/select-theme.enum';
import { Sort } from '../../enums/sort.enum';
import { UserType } from '../../enums/user-type.enum';

import { Side } from '../../types/side.type';

@Component({
  selector: 'gw-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.scss']
})
export class ListHeaderComponent implements OnInit {
  @ViewChild('list', { static: true }) list: ElementRef;

  @Input() classicSearch?: boolean;
  @Input() count = 0;
  @Input() hideHeader = false;
  @Input() hideMatchingCount = false;
  @Input() heading?: string;
  @Input() boldedHeading?: string;
  @Input() bigHeading = false;
  @Input() greyHeading = false;
  @Input() hideElementsLabel: string;
  @Input() set areElementsHidden(areHidden: boolean) {
    this.hideElementsInputValue = areHidden;
  }
  @Input() noTopMargin = false;
  @Input() noBottomMargin = false;
  @Input() buttonLabel: string;
  @Input() secondButtonLabel: string;
  @Input() buttonStyle: string;
  @Input() secondButtonStyle: string;
  @Input() sortOptions?: Array<SortOption>;
  @Input() selectedSortOption?: SortOption;
  @Input() searchValue?: string;
  @Input() sortOrder?: Sort;
  @Input() userType: UserType;
  @Input() withLightToggleSwitch = true;
  @Input() set controls(controls: ListControls) {
    this._controls = controls;
    this.initControlsFlags();
  }
  get controls(): ListControls {
    return this._controls;
  }
  _controls: ListControls;

  @Output() toggleExpandAll = new EventEmitter<boolean>();
  @Output() clickButton = new EventEmitter<void>();
  @Output() clickSecondButton = new EventEmitter<void>();
  @Output() toggleSortOrder = new EventEmitter<Sort>();
  @Output() changeSortOption = new EventEmitter<SortOption>();
  @Output() scrollToTopOfList = new EventEmitter<void>();
  @Output() selectMultiple = new EventEmitter<boolean>();
  @Output() hideElements = new EventEmitter<boolean>();
  @Output() updateSearch = new EventEmitter<string>();

  areAllExpanded = false;
  buttonsClasses = {
    firstButtonClass: 'button--tertiary',
    secondButtonClass: 'button--tertiary'
  };
  hasControls = {
    left: false,
    right: false
  };
  isSelectMultipleMarked = false;
  hideElementsInputValue = true;

  readonly TOGGLE_SWITCH_INPUT_TYPE = InputWithLabelType.toggleSwitch;
  readonly TRANSPARENT_SELECT_THEME = SelectTheme.transparent;
  readonly BLACK_TRANSPARENT_SELECT_THEME = SelectTheme.blackTransparent;
  readonly SEARCH_THEME_NO_BORDER = SearchTheme.NoBorder;
  readonly SEARCH_THEME_STRONG_BORDER = SearchTheme.StrongBorder;

  ngOnInit(): void {
    this.initButtonsClasses();
  }

  initButtonsClasses(): void {
    this.buttonsClasses.firstButtonClass = this.setButtonClass(this.buttonStyle);
    this.buttonsClasses.secondButtonClass = this.setButtonClass(this.secondButtonStyle);
  }

  initControlsFlags(): void {
    const hasControls = (side: Side): boolean => {
      return Object.keys(this.controls)
        .map((key: string): boolean => this.controls[key] === side)
        .reduce((previous: boolean, current: boolean): boolean => previous || current);
    };

    this.hasControls = {
      left: this.controls ? hasControls('left') : false,
      right: this.controls ? hasControls('right') : false
    };
  }

  onToggleExpandAll(value: boolean): void {
    this.areAllExpanded = value;
    this.toggleExpandAll.emit(value);
  }

  onScrollToTopOfList(): void {
    this.list.nativeElement.scrollIntoView({ behavior: 'smooth' });
    this.scrollToTopOfList.emit();
  }

  setButtonClass(buttonStyle?: string): string {
    return buttonStyle ? `button--${buttonStyle}` : 'button--tertiary';
  }

  onChangeSortOption(sortOption: SortOption): void {
    this.changeSortOption.emit(sortOption);
  }

  onToggleSortOrder(sortOrder: Sort): void {
    this.toggleSortOrder.emit(sortOrder);
  }

  onSelectMultiple(selectMultiple: boolean): void {
    this.isSelectMultipleMarked = selectMultiple;
    this.selectMultiple.emit(selectMultiple);
  }

  onClickButton(): void {
    this.clickButton.emit();
  }

  onClickSecondButton(): void {
    this.clickSecondButton.emit();
  }

  onHideElements(value: boolean): void {
    this.hideElementsInputValue = value;
    this.hideElements.emit(value);
  }
}

import { AbstractControl } from '@angular/forms';

export class ColorValidator {
  static colorValidator(control: AbstractControl): { [key: string]: any } {
    if (!control.value || control.value.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/)) {
      return null;
    } else {
      return { color: true };
    }
  }
}

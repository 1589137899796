
<ul class="facility-tree" [class.facility-tree--small]="small">
  <li class="facility-tree__item" *ngFor="let facility of facilityTree" [class.facility-tree__item--leaf]="!facility.children?.length">
    <gw-expand-box #expandBox="gwExpandBox" [isParentFullyClosed]="parentExpandBox?.fullyClosed">
      <ng-container expand-box-header="expand-box-header">
        <button class="facility-tree__toggle" type="button" [class.facility-tree__toggle--opened]="expandBox.opened" (click)="expandBox.toggle()"><i class="gw-caret--right facility-tree__toggle-icon"></i></button>
        <label class="facility-tree__label" [class.facility-tree__label--checked]="parentChecked &amp;&amp; subFolders || isFacilityChecked(facility)">
          <gw-radio-button class="facility-tree__radio" *ngIf="!multiSelect" [attr.id]="facility.id" name="facility" [ngModel]="selectedFacility.id" (ngModelChange)="onChangeSelectedFacility(facility)" [value]="facility.id" [empty]="true" [parentChecked]="parentChecked &amp;&amp; subFolders"></gw-radio-button>
          <gw-check-box class="facility-tree__checkbox" *ngIf="multiSelect" [attr.id]="facility.id" name="{{ 'facility' + facility.id }}" [ngModel]="facility.selected" (ngModelChange)="selectFacility($event, facility)" [ngModelOptions]="{standalone: true}" [parentChecked]="parentChecked &amp;&amp; subFolders"></gw-check-box>{{ facility.name }}
        </label>
      </ng-container>
      <ng-container expand-box-content="expand-box-content">
        <gw-facility-tree-view *ngIf="facility.children?.length" [facilityTree]="facility.children" [selectedFacility]="selectedFacility" [multiSelect]="multiSelect" [subFolders]="subFolders" [small]="small" [parentChecked]="parentChecked || isFacilityChecked(facility)" [parentFacility]="facility" [parentExpandBox]="expandBox" (changeSelectedFacility)="onChangeSelectedFacility($event)" (selectChildFacility)="childSelected()" (openExpandBox)="onOpenExpandBox()"></gw-facility-tree-view>
      </ng-container>
    </gw-expand-box>
  </li>
</ul>
// Gutwin Shared Library
import { Attachment } from 'gutwin-shared';

export class QuestionMedia {
  id: string;
  comment: string;
  commentOffline: string;
  attachments: Array<Attachment>;
  auditedAreaId: string;
  questionId: string;


  constructor(questionMedia?: any) {
    if (questionMedia) {
      this.id = questionMedia.id;
      this.comment = questionMedia.comment;
      this.commentOffline = questionMedia.commentOffline;
      this.attachments = this.generateAttachments(questionMedia.attachments);
      this.auditedAreaId = questionMedia.auditedAreaId;
      this.questionId = questionMedia.questionId;
    } else {
      this.attachments = this.generateAttachments([]);
    }
  }

  generateAttachments(attachmentsRequest: Array<any>): Array<Attachment> {
    const attachments = new Array<Attachment>();
    if (attachmentsRequest) {
      attachmentsRequest.forEach(attachment => {
        attachments.push(new Attachment(attachment));
      });
    }
    return attachments;
  }
}

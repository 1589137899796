import { Injectable } from '@angular/core';
// Services
import { BodyFreezerService } from 'gutwin-shared';

@Injectable()
export class AuditNavigationService {
  opened = false;

  constructor(private bodyFreezer: BodyFreezerService) { }

  toggleNavigation(): void {
    if (!this.opened) {
      this.opened = true;
      this.bodyFreezer.freezeBody();
    } else {
      this.opened = false;
      this.bodyFreezer.unfreezeBody();
    }
  }
}

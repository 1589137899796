import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

import { Store } from '@ngxs/store';
import moment from 'moment';

import { Validity } from '../../interfaces/validity.interface';

import { DatePickerComponent } from '../date-picker/date-picker.component';

import { SimpleDropdownDirective } from '../../directives/simple-dropdown.directive';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-edit-validity',
  templateUrl: './edit-validity.component.html',
  styleUrls: ['./edit-validity.component.scss'],
  exportAs: 'gwEditValidity'
})
export class EditValidityComponent implements OnInit {
  @Input() validity: Validity;
  @Input() isEditMode: boolean;
  @Input() isUserTypeClient: boolean;

  @Output() submitForm = new EventEmitter<Validity>();

  @ViewChild('startDatePicker') startDatePicker: DatePickerComponent;
  @ViewChild('startDateDropdown') startDateDropdown: SimpleDropdownDirective;

  @ViewChild('endDatePicker') endDatePicker: DatePickerComponent;
  @ViewChild('endDateDropdown') endDateDropdown: SimpleDropdownDirective;

  form: FormGroup;
  submitted = false;
  todayDate = moment();

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      startDate: [''],
      endDate: ['']
    });
  }

  resetForm(): void {
    this.form.get('startDate').setValue(this.validity && this.validity.startDate ? this.validity.startDate : '');
    this.form.get('endDate').setValue(this.validity && this.validity.endDate ? this.validity.endDate : '');
  }

  setControlValue(data: any, key: string): void {
    this.form.get(key).setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  onSubmitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.submitForm.emit({
        startDate: this.form.value.startDate,
        endDate: this.form.value.endDate
      });
    }
  }

  increaseOpenedEdits(): void {
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.store.dispatch(new DecreaseOpenedEdits());
  }

  keyDownStartDatePicker(event: any): void {
    switch (event.key) {
      case 'Enter':
        if (this.startDatePicker && this.startDateDropdown.openedWithDelay) {
          this.setControlValue(this.startDatePicker.date, 'startDate');
        }
    }
  }

  keyDownEndDatePicker(event: any): void {
    switch (event.key) {
      case 'Enter':
        if (this.endDatePicker && this.endDateDropdown.openedWithDelay) {
          this.setControlValue(this.endDatePicker.date, 'endDate');
        }
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Employee } from '../../models/employee.model';

import { TopBarLink } from '../../interfaces/top-bar-link.interface';

@Component({
  selector: 'gw-top-bar-profile',
  templateUrl: './top-bar-profile.component.html',
  styleUrls: ['./top-bar-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopBarProfileComponent {
  /**
   * Show customer logo insted of gutwin if passed
   */
  @Input() customerLogo = '';
  /**
   * Currently logged user
   *
   * @required
   */
  @Input() currentUser: Employee;
  /**
   * Links in dropdown
   */
  @Input() links?: { [name: string]: TopBarLink };
  /**
   * Currently visited page/component.
   * Links with this component will not be visible in dropdown.
   */
  @Input() component?: string;
  /**
   * Display icons next to links
   */
  @Input() icons?: boolean;
  /**
   * Callback to logout user
   */
  @Output() logout = new EventEmitter<void>();

  logOut(): void {
    this.logout.emit();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import { noop } from 'rxjs';

// Gutwin Shared Library
import { DialogService, ModalService } from 'gutwin-shared';

import { AuditType } from '@gutwin-audit/shared/models/audit-type.model';
// Models
import { RatingScale } from '@gutwin-audit/shared/models/rating-scale.model';

// Components
import { AddRatingScaleComponent } from './add-rating-scale/add-rating-scale.component';

// Services
import { RatingScalesService } from '@gutwin-audit/shared/services/raiting-scales.service';

@Component({
  selector: 'gw-rating-scales',
  templateUrl: './rating-scales.component.html',
  styleUrls: ['./rating-scales.component.scss']
})
export class RatingScalesComponent implements OnInit {
  @ViewChild('addRatingScale') addRatingScale: AddRatingScaleComponent;
  ratingScales: Array<RatingScale>;
  auditTypes: Array<AuditType>;

  translation = {
    removeDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    archiveDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: '',
      successTitle: '',
      successText: '',
      errorTitle: '',
      errorText: ''
    },
    setAsDefault: {
      successHeader: '',
      successText: '',
      errorText: '',
      errorTitle: ''
    }
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private notificationsService: NotificationsService,
    private ratingScalesService: RatingScalesService
  ) {}

  ngOnInit(): void {
    this.ratingScales = this.route.snapshot.data['ratingScales'];
    this.auditTypes = this.route.snapshot.data['auditTypes'];
    this.fetchTranslation();
  }

  spliceRatingScale(ratingScale: RatingScale): void {
    const index = _.findIndex(this.ratingScales, { id: ratingScale.id });
    if (~index) {
      this.ratingScales.splice(index, 1);
    }
  }

  fetchRatingScales(): Promise<Array<RatingScale>> {
    return this.ratingScalesService.getRatingScales().then((scales: Array<RatingScale>) => {
      return (this.ratingScales = scales);
    });
  }

  saveRatingScale(): void {
    this.addRatingScale
      .submitAddRatingScaleForm()
      .then(ratingScaleResponse => {
        this.fetchRatingScales().then(() => {
          this.modalService.close('ratingScaleModal');
        });
      })
      .catch(error => {});
  }

  showArchiveModal(ratingScale: RatingScale): void {
    this.dialogService
      .confirm(
        this.translation.archiveDialog.header,
        this.translation.archiveDialog.content,
        this.translation.archiveDialog.cancel,
        this.translation.archiveDialog.confirm
      )
      .then(() => {
        this.archiveRatingScale(ratingScale);
      })
      .catch(noop);
  }

  archiveRatingScale(ratingScale: RatingScale): Promise<void> {
    return this.ratingScalesService
      .archiveRatingScale(ratingScale)
      .then(() => {
        this.fetchRatingScales();
        this.notificationsService.success(
          this.translation.archiveDialog.successTitle,
          this.translation.archiveDialog.successText
        );
      })
      .catch(() => {
        this.notificationsService.error(
          this.translation.archiveDialog.errorTitle,
          this.translation.archiveDialog.errorText
        );
      });
  }

  showRatingScaleDetails(ratingScale: RatingScale): void {
    this.router.navigate(['/rating-scales', ratingScale.id]);
  }

  showUpdateRatingScaleModal(ratingScale: RatingScale): void {
    this.modalService.open('ratingScaleModal', ratingScale);
  }

  setRatingScaleAsDefault(ratingScale: RatingScale): void {
    const ratingScaleToUpdate = ratingScale;
    ratingScaleToUpdate.default = true;
    this.ratingScalesService
      .setRatingScaleAsDefault(ratingScaleToUpdate)
      .then(() => {
        this.fetchRatingScales();
        this.notificationsService.success(
          this.translation.setAsDefault.successHeader,
          this.translation.setAsDefault.successText
        );
      })
      .catch(() => {
        this.notificationsService.error(
          this.translation.setAsDefault.errorTitle,
          this.translation.setAsDefault.errorText
        );
      });
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'GLOBAL.ACTION.ARCHIVE',
        'GLOBAL.ERROR.CONNECTION',
        'ADMIN_SETTINGS.RATING_SCALES.REMOVE_MODAL.HEADER',
        'ADMIN_SETTINGS.RATING_SCALES.REMOVE_MODAL.CONTENT',
        'ADMIN_SETTINGS.RATING_SCALES.ARCHIVE_MODAL.HEADER',
        'ADMIN_SETTINGS.RATING_SCALES.ARCHIVE_MODAL.CONTENT',
        'ADMIN_SETTINGS.RATING_SCALES.SUCCESS.ARCHIVE_RATING_SCALE_TITLE',
        'ADMIN_SETTINGS.RATING_SCALES.SUCCESS.ARCHIVE_RATING_SCALE_TEXT',
        'ADMIN_SETTINGS.RATING_SCALES.ERROR.ARCHIVE_RATING_SCALE',
        'ADMIN_SETTINGS.RATING_SCALES.SET_AS_DEFAULT.ERROR_TEXT',
        'ADMIN_SETTINGS.RATING_SCALES.SET_AS_DEFAULT.SUCCESS_TEXT',
        'ADMIN_SETTINGS.RATING_SCALES.SET_AS_DEFAULT.SUCCESS_HEADER'
      ])
      .toPromise();
    this.translation.removeDialog.header = translation['ADMIN_SETTINGS.RATING_SCALES.REMOVE_MODAL.HEADER'];
    this.translation.removeDialog.content = translation['ADMIN_SETTINGS.RATING_SCALES.REMOVE_MODAL.CONTENT'];
    this.translation.removeDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
    this.translation.archiveDialog.header = translation['ADMIN_SETTINGS.RATING_SCALES.ARCHIVE_MODAL.HEADER'];
    this.translation.archiveDialog.content = translation['ADMIN_SETTINGS.RATING_SCALES.ARCHIVE_MODAL.CONTENT'];
    this.translation.archiveDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.archiveDialog.confirm = translation['GLOBAL.ACTION.ARCHIVE'];
    this.translation.archiveDialog.successTitle =
      translation['ADMIN_SETTINGS.RATING_SCALES.SUCCESS.ARCHIVE_RATING_SCALE_TITLE'];
    this.translation.archiveDialog.successText =
      translation['ADMIN_SETTINGS.RATING_SCALES.SUCCESS.ARCHIVE_RATING_SCALE_TEXT'];
    this.translation.archiveDialog.errorTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.archiveDialog.errorText = translation['ADMIN_SETTINGS.RATING_SCALES.ERROR.ARCHIVE_RATING_SCALE'];
    this.translation.setAsDefault.successHeader =
      translation['ADMIN_SETTINGS.RATING_SCALES.SET_AS_DEFAULT.SUCCESS_HEADER'];
    this.translation.setAsDefault.successText = translation['ADMIN_SETTINGS.RATING_SCALES.SET_AS_DEFAULT.SUCCESS_TEXT'];
    this.translation.setAsDefault.errorText = translation['ADMIN_SETTINGS.RATING_SCALES.SET_AS_DEFAULT.ERROR_TEXT'];
    this.translation.setAsDefault.errorTitle = translation['GLOBAL.ERROR.CONNECTION'];
  }
}

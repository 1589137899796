import { LegalChangeFilters } from '../../../../../models/legal-change-filters.model';

import { GlobalFilters } from '../../../../../interfaces/filter.interface';
import { LcFilterValue } from '../../../../../interfaces/lc-filter-value.interface';
import { PageParams } from '../../../../../interfaces/page-params.interface';
import { FiltersParamsSections } from '../../interfaces/filters-params-sections.interface';

import { FilterType } from '../../../../../enums/filter-type.enum';
import { LcFilterListName } from '../../../../../enums/lc-filter-list.enum';
import { LegalChangesSection } from '../../../../../enums/legal-changes-section.enum';

import { ListFiltersValueType } from '../../types/list-filters.type';

export class SetGlobalFilter {
  static readonly type = '[Filters] Set global filter';
  constructor(public key: keyof GlobalFilters, public value: ListFiltersValueType) {}
}

export class SetLCListFilter {
  static readonly type = '[Filters] Set legal-compliance list filter';
  constructor(public listName: LcFilterListName, public filter: LcFilterValue) {}
}

export class SetLCListFilters {
  static readonly type = '[Filters] Set legal-compliance list filters';
  constructor(public listName: LcFilterListName, public filters: Array<LcFilterValue>) {}
}

export class SetLCListPagination {
  static readonly type = '[Filters] Set legal-compliance list pagination';
  constructor(
    public listName: LcFilterListName,
    public pagination: PageParams,
    public updateUrl = true,
    public section?: LegalChangesSection
  ) {}
}

export class SetLCAllSectionsPagination {
  static readonly type = '[Filters] Set all legal-compliance sections pagination';
  constructor(
    public listName: LcFilterListName,
    public sections: FiltersParamsSections<LegalChangeFilters, LegalChangesSection>
  ) {}
}

export class RemoveLCListFilter {
  static readonly type = '[Filters] Remove legal-compliance list filter';
  constructor(public listName: LcFilterListName, public filter: LcFilterValue) {}
}

export class RemoveLCListAllFilters {
  static readonly type = '[Filters] Remove all legal-compliance list filters';
  constructor(
    public listName: LcFilterListName,
    public filterToKeep?: FilterType,
    public filterToKeepValue?: ListFiltersValueType
  ) {}
}

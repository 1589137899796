import { Facility } from './facility.model';

export class FacilityFilter extends Facility {
  selected: boolean;
  children: Array<FacilityFilter>;

  constructor(facility: any) {
    super(facility);
    this.selected = facility.selected;
    if (facility.children) {
      this.children = this.generateChildFicilities(facility.children);
    }
  }

  generateChildFicilities(facilities: Array<any>): Array<FacilityFilter> {
    const newFacilities = new Array<FacilityFilter>();
    facilities.forEach(facility => {
      newFacilities.push(new FacilityFilter(facility));
    });
    return newFacilities;
  }
}

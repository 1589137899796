export class LegalQuestion {
  active?: boolean;
  description?: string;
  id: string;
  legalCategoryId?: string;
  question: string;
  previousVersion?: LegalQuestion;

  constructor(question: Partial<LegalQuestion>) {
    Object.assign(this, question);
  }
}

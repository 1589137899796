import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DialogOverlayRef } from '../overlay/dialog-overlay-ref';

import { RoleModalData, RoleModalResponse } from '../../interfaces/role-modal.interface';

import { ModalIds } from '../../enums/modal-ids.enum';
import { UserType } from '../../enums/user-type.enum';

import { RoleFormComponent } from '../role-form/role-form.component';

import { OverlayService } from '../../services/overlay.service';

@Component({
  selector: 'gw-role-modal',
  templateUrl: './role-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleModalComponent implements OnInit, OnDestroy {
  @ViewChild('roleFormComponent', { static: true }) roleFormComponent: RoleFormComponent;

  isEditMode = false;
  wasAnythingSaved = false;
  savingRole: boolean;
  userType: UserType;
  destroy$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private overlayService: OverlayService,
    public dialogOverlayRef: DialogOverlayRef<boolean, RoleModalData, RoleModalResponse>
  ) {}

  ngOnInit(): void {
    this.setEditMode();
    this.observeConfirmingSavingUser();
    this.observeDiscardingSavingUser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  submitRoleForm(): Promise<void> {
    this.savingRole = true;
    return this.roleFormComponent
      .submitRoleForm()
      .then((response: RoleModalResponse) => {
        this.submit(response);
      })
      .catch(() => {
        this.savingRole = false;
        this.changeDetector.detectChanges();
      });
  }

  observeConfirmingSavingUser(): void {
    this.overlayService.confirm$
      .pipe(
        takeUntil(this.destroy$),
        filter(id => id === ModalIds.roleModal)
      )
      .subscribe(() => {
        this.savingRole = false;
        this.wasAnythingSaved = true;
        if (this.roleFormComponent.addAnother) {
          this.isEditMode = false;
          this.roleFormComponent.clearComponent();
          this.changeDetector.detectChanges();
        } else {
          this.close();
        }
      });
  }

  observeDiscardingSavingUser(): void {
    this.overlayService.discard$
      .pipe(
        takeUntil(this.destroy$),
        filter(id => id === ModalIds.roleModal)
      )
      .subscribe(() => {
        this.savingRole = false;
        this.changeDetector.detectChanges();
      });
  }

  setEditMode(): void {
    this.isEditMode = !!this.dialogOverlayRef.data.role.id;
  }

  submit(response: RoleModalResponse): void {
    this.dialogOverlayRef.submit(response);
  }

  close(): void {
    this.dialogOverlayRef.close(this.wasAnythingSaved);
  }

  onHideModal(): void {
    this.dialogOverlayRef.hide();
  }

  onShowModal(): void {
    this.dialogOverlayRef.show();
  }
}

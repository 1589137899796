import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Titles } from '../../interfaces/titles.interface';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-details-page-header',
  templateUrl: './details-page-header.component.html',
  styleUrls: ['./details-page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailsPageHeaderComponent<T> {
  @Input() detailsData: T;
  @Input() userType: UserType;
  @Input() isEditMode: boolean;
  @Input() titleWithMarginBottom?: boolean;

  @Output() updateTitles = new EventEmitter<Titles>();
}

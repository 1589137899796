
<gw-top-bar>
  <ng-container top-bar-row="top-bar-row">
    <div class="columns small-3 top-bar__left" [class.large-5]="!online" [class.large-3]="online"><a routerLink="/"><img class="top-bar__logo" src="/assets/images/logo_gutwin_2x.png"/></a><span class="top-bar__admin" *ngIf="component === 'admin'">{{ 'GLOBAL.LABEL.ADMIN' | translate }}</span></div>
    <div class="columns small-3 medium-2 top-bar__center" *ngIf="!online"><span class="top-bar__offline">{{ 'GLOBAL.LABEL.OFFLINE' | translate }}</span></div>
    <div class="columns top-bar__right" [ngClass]="{ 'small-9 medium-9': online, 'small-6 medium-7 large-5': !online }">
      <gw-progress-bar-shared class="top-bar__progress-bar" [big]="true" [total]="progressBar?.progress.total" [progress]="progressBar?.progress.done" [action]="progressBar?.action" [status]="progressBar?.status" [label]="progressBar?.label" [groups]="progressBar?.groups" [class.top-bar__progress-bar--small]="!online" (syncGroups)="onSyncGroups()" (closeProgressBar)="onCloseProgressBar()"></gw-progress-bar-shared>
      <div class="dropdown dropdown--menu top-bar__user-dropdown" *ngIf="currentUser" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
        <button class="user-dropdown" type="button">
          <gw-avatar *ngIf="currentUser" [employee]="currentUser"></gw-avatar><span class="user-dropdown__name show-for-medium">{{ currentUser?.lastname }}, {{ currentUser?.name }}</span><i class="gw-caret--down user-dropdown__arrow dropdown__icon"></i>
        </button>
        <div class="dropdown__content user-dropdown__content jsDropdownContent">
          <ul class="dropdown-menu__list">
            <ng-container *ngIf="online">
              <ng-container *ngFor="let link of (links | objectToArray)">
                <li class="dropdown-menu__item" *ngIf="component !== link.component"><a class="link dropdown-menu__link" [routerLink]="link.url" (click)="dropdownMenu.closeDropdown()">{{ link.title }}</a></li>
              </ng-container>
              <li class="dropdown-menu__item">
                <div class="link dropdown-menu__link" (click)="onOpenVersionModal(); dropdownMenu.closeDropdown()">{{ 'GLOBAL.ACTION.ABOUT_GUTWIN' | translate }}</div>
              </li>
            </ng-container>
            <li class="dropdown-menu__item">
              <button class="link dropdown-menu__link" type="button" (click)="onLogOut()">{{ 'GLOBAL.ACTION.LOGOUT' | translate }}</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>
</gw-top-bar>
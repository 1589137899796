import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { InactiveFiltersList } from '../../constants/inactive-filters.constant';
import { IntervalList } from '../../constants/interval-list.constant';

import { InactiveFilter } from '../../interfaces/inactive-filter.interface';
import { Interval } from '../../interfaces/interval.interface';

import { TranslateStaticFilters } from './static-filters.actions';

export interface StaticFiltersModel {
  intervals?: Array<Interval>;
  inactive?: Array<InactiveFilter>;
}

@State<StaticFiltersModel>({
  name: 'staticFilters',
  defaults: {
    intervals: IntervalList,
    inactive: InactiveFiltersList
  }
})
@Injectable()
export class StaticFiltersState {
  constructor(private translateService: TranslateService) {}

  @Selector()
  static intervals(state: StaticFiltersModel): Array<Interval> {
    return state.intervals;
  }

  @Selector()
  static inactive(state: StaticFiltersModel): Array<InactiveFilter> {
    return state.inactive;
  }

  @Action(TranslateStaticFilters)
  async translateStaticFilters(context: StateContext<StaticFiltersModel>): Promise<void> {
    const staticFilters = context.getState();
    let translatedStaticFilters: StaticFiltersModel = {};
    for (const key of Object.keys(staticFilters)) {
      const translationKeys: string[] = staticFilters[key].map(
        (item: Interval | InactiveFilter) => item.translationKey
      );
      const translations: string[] = await this.translateService.get(translationKeys).toPromise();
      const translatedFilters = staticFilters[key].map((item: Interval | InactiveFilter) => {
        return { ...item, translatedName: translations[item.translationKey] };
      });

      translatedStaticFilters = { ...translatedStaticFilters, [key]: translatedFilters };
    }
    context.patchState(translatedStaticFilters);
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TreeFilterOption } from '../../models/tree-filter-option.model';

import { FlatTreeNode } from '../../interfaces/flat-tree-node.interface';

import { TreeNodesIds } from '../../types/tree-nodes-ids.type';

import { FLAT_TREE_DEFAULT_HEIGHT } from '../../constants/flat-tree-height.constants';

@Component({
  selector: 'gw-flat-tree-multiselect',
  templateUrl: 'flat-tree-multiselect.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlatTreeMultiselectComponent),
      multi: true
    }
  ]
})
export class FlatTreeMultiselectComponent implements ControlValueAccessor {
  @Input() markSelectedChildren?: boolean;
  @Input() data: Array<TreeFilterOption>;
  @Input() tooltipTemplateRef?: TemplateRef<any>;
  @Input() height = FLAT_TREE_DEFAULT_HEIGHT;
  @Input() lastNodeMargin?: boolean;
  @Input() showConnectedElements?: boolean;

  selectedNodesIds?: TreeNodesIds;

  constructor(private changeDetector: ChangeDetectorRef) {}

  onChange: (_: TreeNodesIds) => void = () => {};

  writeValue(value: TreeNodesIds): void {
    this.selectedNodesIds = value;
    this.changeDetector.markForCheck();
  }

  registerOnChange(fn: (_: TreeNodesIds) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  onSelectNode(node: FlatTreeNode, selected: boolean): void {
    const selectedNodesIds = { ...this.selectedNodesIds };
    if (selected) {
      selectedNodesIds[node.id] = node.path;

      if (this.markSelectedChildren) {
        this.cleanSelectedNodesIdsForNode(selectedNodesIds, node);
      }
    } else {
      delete selectedNodesIds[node.id];
    }
    this.selectedNodesIds = selectedNodesIds;
    this.onChange(selectedNodesIds);
  }

  cleanSelectedNodesIdsForNode(selectedNodesIds: TreeNodesIds, node: FlatTreeNode): void {
    this.cleanParentsOfSelectedNode(selectedNodesIds, node);
    this.cleanChildrenOfSelectedNode(selectedNodesIds, node);
  }

  cleanParentsOfSelectedNode(selectedNodesIds: TreeNodesIds, node: FlatTreeNode): void {
    if (node.path?.length) {
      node.path.forEach(parentId => delete selectedNodesIds[parentId]);
    }
  }

  cleanChildrenOfSelectedNode(selectedNodesIds: TreeNodesIds, node: FlatTreeNode): void {
    Object.entries(selectedNodesIds).forEach(([id, path]) => {
      if (path?.includes(node.id)) {
        delete selectedNodesIds[id];
      }
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import moment from 'moment';

import { Validity } from '../../interfaces/validity.interface';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-sidebar-validity-and-transition-period',
  templateUrl: './sidebar-validity-and-transition-period.component.html'
})
export class SidebarValidityAndTransitionPeriodComponent {
  @Input() isEditMode: boolean;
  @Input() hideTransitionPeriod?: boolean;
  @Input() validity: Validity;
  @Input() transitionPeriod?: moment.Moment;
  @Input() userType: UserType;
  @Output() changeValidity = new EventEmitter<Validity>();
  @Output() changeTransitionPeriod = new EventEmitter<moment.Moment>();

  onEditValidity(validity: Validity): void {
    this.changeValidity.emit(validity);
  }

  onEditSingleDate(date: moment.Moment): void {
    this.changeTransitionPeriod.emit(date);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gw-edit-remove-item',
  templateUrl: './edit-remove-item.component.html',
  styleUrls: ['./edit-remove-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditRemoveItemComponent {
  @Input() label: string;
  @Output() edit = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  onEdit(): void {
    this.edit.emit();
  }

  onRemove(): void {
    this.remove.emit();
  }
}

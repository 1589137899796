import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { AttachmentLC } from '../../models/attachment-lc.model';

import { DuplicationsModalComponent } from '../duplications-modal/duplications-modal.component';

import { FileService } from '../../services/file.service';
import { OverlayService } from '../../services/overlay.service';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.scss'],
  exportAs: 'gwAddAttachment'
})
export class AddAttachmentComponent implements OnInit {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  @Input() acceptedFileTypes?: string;
  @Input() alignRight = true;
  @Input() attachments = new Array<AttachmentLC>();
  @Input() isUserTypeClient?: boolean;

  @Output() submitForm = new EventEmitter<AttachmentLC[]>();
  @Output() togglePopoverStatus = new EventEmitter<void>();

  form: FormGroup;
  submitted = false;
  attaching = false;
  saving = false;

  constructor(
    private fileService: FileService,
    private formBuilder: FormBuilder,
    private store: Store,
    private overlayService: OverlayService,
    private changeDetector: ChangeDetectorRef
  ) {}

  get attachementsToBeUploaded(): AttachmentLC[] {
    return this.form.get('attachments').value;
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      attachments: [[], Validators.required]
    });
  }

  resetForm(): void {
    this.form.get('attachments').setValue([]);
    this.submitted = false;
  }

  setControlValue(data: any, key: string): void {
    const newData = [...this.form.get(key).value, ...data];
    this.form.get(key).setValue(newData);
    this.changeDetector.markForCheck();
  }

  isFieldInvalid(controlName: string): boolean {
    return !this.form.get(controlName).valid && this.submitted;
  }

  async onSubmitForm(): Promise<void> {
    this.submitted = true;
    if (this.form.valid) {
      this.saving = true;
      const attachments: Array<AttachmentLC> = this.form.value.attachments.map((attachment: AttachmentLC) => {
        return new AttachmentLC({ ...attachment, action: 'add', displayName: attachment.name });
      });
      this.submitForm.emit(attachments);
    }
  }

  isDuplication(attachment: AttachmentLC, attachments: Array<AttachmentLC>): boolean {
    return attachments && !!attachments.find(existingAttachment => existingAttachment.checksum === attachment.checksum);
  }

  uploadFiles(event: Event): Promise<Array<AttachmentLC>> {
    const newAttachments = new Array<AttachmentLC>();
    const duplications = new Array<AttachmentLC>();
    const fileList: FileList = event.target['files'];
    let fileCounter = 0;
    return new Promise(resolve => {
      if (fileList) {
        for (const file of Array.from(fileList)) {
          this.fileService.readFile(file).then(({ base64, hash, rotation }) => {
            const attachment = new AttachmentLC({
              file,
              url: base64,
              name: file.name,
              checksum: hash,
              rotation
            });
            fileCounter++;
            if (
              this.isDuplication(attachment, this.attachments) ||
              this.isDuplication(attachment, newAttachments) ||
              this.isDuplication(attachment, this.attachementsToBeUploaded)
            ) {
              duplications.push(attachment);
            } else {
              newAttachments.push(attachment);
            }

            if (fileCounter === fileList.length) {
              if (duplications.length) {
                this.overlayService.open(DuplicationsModalComponent, duplications);
              }
              resolve(newAttachments);
            }
          });
        }
      } else {
        resolve(newAttachments);
      }
    });
  }

  async attachFiles(event: Event): Promise<void> {
    this.attaching = true;
    const attachments = await this.uploadFiles(event);
    this.attaching = false;
    this.setControlValue(attachments, 'attachments');
    this.fileService.resetFileInput(this.fileInput);
  }

  onPopoverOpen(): void {
    this.increaseOpenedEdits();
    this.resetForm();
  }

  onPopoverClose(): void {
    this.decreaseOpenedEdits();
    this.saving = false;
  }

  increaseOpenedEdits(): void {
    this.togglePopoverStatus.emit();
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.togglePopoverStatus.emit();
    this.store.dispatch(new DecreaseOpenedEdits());
  }

  keyDownAttachmentControl(event: any, fileInput: HTMLElement): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      fileInput.click();
    }
  }
}

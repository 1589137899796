import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import dataURLtoBlob from 'blueimp-canvas-to-blob';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Attachment } from '../../models/attachment.model';
import { EmployeeExtended } from '../../models/employee-extended.model';
import { PermissionCategory } from '../../models/permission-category.model';
import { Role } from '../../models/role.model';

import { ImageCropperModalData } from '../../interfaces/image-cropper-modal.interface';
import { UserModalResponse } from '../../interfaces/user-modal.interface';
import { UserRolesModalData, UserRolesModalResponse } from '../../interfaces/user-roles-modal.interface';

import { UserType } from '../../enums/user-type.enum';

import { ImageCropperModalComponent } from '../image-cropper-modal/image-cropper-modal.component';
import { UserRolesModalComponent } from '../user-roles-modal/user-roles-modal.component';

import { FileService } from '../../services/file.service';
import { OverlayService } from '../../services/overlay.service';

import { EmailValidator } from '../../validators/email.validator';
import { noWhitespaceValidator } from '../../validators/no-whitespace.validator';

@Component({
  selector: 'gw-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  exportAs: 'gwUserForm',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFormComponent implements OnInit, OnDestroy {
  @Input() employee: EmployeeExtended;
  @Input() isEditMode?: boolean;
  @Input() roles: Array<Role>;
  @Input() permissionCategories: Array<PermissionCategory>;
  @Input() userType?: UserType;
  @Input() showMultiselect?: boolean;

  @Output() hideModal = new EventEmitter<void>();
  @Output() showModal = new EventEmitter<void>();

  form: FormGroup;
  addAnother = false;
  submitted = false;
  uploadedImage = { preview: '' };
  avatar: File;
  avatarSourceFile: File;

  destroy$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fileService: FileService,
    private formBuilder: FormBuilder,
    private overlayService: OverlayService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.observeTakenEmailError();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initForm(): void {
    this.form = this.formBuilder.group(
      {
        avatar: [this.employee?.avatar],
        name: [this.employee?.name ?? '', [Validators.required, noWhitespaceValidator]],
        lastname: [this.employee?.lastname ?? '', [Validators.required, noWhitespaceValidator]],
        email: [this.employee?.email ?? '', [Validators.required, EmailValidator.extendedEmailValidator]],
        access: [this.employee?.access ?? false],
        roles: [this.copyRoles(this.employee?.roles)]
      },
      { validator: EmailValidator.requiredEmailValidator() }
    );
    this.uploadedImage = { preview: '' };
    this.changeDetector.detectChanges();
  }

  observeTakenEmailError(): void {
    this.overlayService.showError$
      .pipe(
        takeUntil(this.destroy$),
        filter(value => value)
      )
      .subscribe(() => {
        this.form.controls.email.setErrors({ taken: true });
        this.changeDetector.markForCheck();
      });
  }

  resetForm(): void {
    this.submitted = false;
    this.form.reset({
      avatar: this.employee?.avatar,
      name: this.employee?.name ?? '',
      lastname: this.employee?.lastname ?? '',
      email: this.employee?.email ?? '',
      access: this.employee?.access ?? false,
      roles: this.copyRoles(this.employee?.roles)
    });
    this.uploadedImage = { preview: '' };
    this.changeDetector.detectChanges();
  }

  clearComponent(): void {
    this.employee = undefined;
    this.resetForm();
  }

  setControlValue(data: any, key: string): void {
    this.form.controls[key].setValue(data);
  }

  copyRoles(roles: Array<Role>): Array<Role> {
    if (roles) {
      return roles.map(role => new Role(role));
    }
    return [];
  }

  attachFiles(event: Event): void {
    this.fileService.attachFiles(event).then((base64: Array<string>) => {
      this.showCropperImageModal(base64[0]);
      this.changeDetector.detectChanges();
    });
    this.avatarSourceFile = event.target['files'][0];
  }

  showCropperImageModal(base64: string): void {
    this.overlayService
      .open<ImageCropperModalData, ImageCropperModalData>(
        ImageCropperModalComponent,
        {
          base64
        },
        {
          small: false
        }
      )
      .afterClosed$.pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.data?.base64) {
          this.acceptImage(response.data.base64);
        }
      });
  }

  acceptImage(image: string): void {
    this.uploadedImage.preview = image;
    const imageBlob = dataURLtoBlob(image);
    imageBlob.name = this.avatarSourceFile.name;
    this.setControlValue(new Attachment({ file: imageBlob }), 'avatar');
    this.changeDetector.markForCheck();
  }

  removeAvatar(): void {
    this.uploadedImage = { preview: '' };
    this.setControlValue(null, 'avatar');
    this.changeDetector.markForCheck();
  }

  convertUserToCreate(): UserModalResponse {
    const employeeToCreate = new EmployeeExtended(this.form.value);
    return {
      status: 'create',
      employee: employeeToCreate
    };
  }

  convertUserToUpdate(): UserModalResponse {
    const employeeToUpdate = new EmployeeExtended(this.form.value);
    employeeToUpdate.id = this.employee.id;
    return {
      status: 'update',
      employee: employeeToUpdate
    };
  }

  async submitForm(): Promise<UserModalResponse> {
    this.submitted = true;
    if (this.form.valid) {
      if (this.employee) {
        return this.convertUserToUpdate();
      } else {
        return this.convertUserToCreate();
      }
    } else {
      this.changeDetector.detectChanges();
      return Promise.reject({ invalid: true });
    }
  }

  showUserRolesModal(): void {
    const employee = new EmployeeExtended(this.form.value);

    this.hideModal.emit();
    this.overlayService
      .open<UserRolesModalResponse, UserRolesModalData, UserRolesModalResponse>(
        UserRolesModalComponent,
        {
          employee,
          roles: this.roles,
          permissionCategories: this.permissionCategories,
          selectedRoles: employee.roles,
          standalone: false,
          showMultiselect: this.showMultiselect
        },
        {
          small: true
        }
      )
      .afterClosed$.pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.data?.roles) {
          this.setControlValue(response.data.roles, 'roles');
        }
        this.showModal.emit();
      });
  }

  keyDownAvatarControl(event: any, fileInput: any): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      fileInput.click(event);
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiUrl } from '../../enums/old-api-url.enum';

import { LoginOldApiService } from '../../services/login-old-api.service';
import { UserProfileOldApiService } from '../../services/user-profile-old-api.service';

@Component({
  selector: 'gw-login-old-api',
  templateUrl: './login-old-api.component.html',
  styleUrls: ['./login-old-api.component.scss']
})
export class LoginOldApiComponent implements OnInit {
  redirectUrl: string;
  projectName: string;
  loginForm: FormGroup;
  submitted = false;
  readonly PASSWORD_RESET_URL = ApiUrl.passwordRessetApi;

  constructor(
    private authenticationService: LoginOldApiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserProfileOldApiService
  ) {}

  ngOnInit() {
    this.projectName = this.route.snapshot.data['projectName'];
    // try auth users from old app
    this.userService.fetchUserProfile().then(() => {
      this.router.navigate(['/esh']);
    });

    this.initForm();
  }

  initForm(): void {
    this.loginForm = this.formBuilder.group({
      clientId: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  isFieldError(field: AbstractControl): boolean {
    return field.errors?.required;
  }

  isClientIdValid(field: AbstractControl): boolean {
    return isNaN(field.value);
  }

  logIn(loginForm: FormGroup): void {
    this.submitted = true;
    if (loginForm.valid) {
      this.authenticationService.logIn(loginForm.value).then(() => {
        if (this.authenticationService.isLoggedIn()) {
          this.router.navigate(['/esh']);
        }
      });
    }
  }
}

import moment from 'moment';
// Gutwin Shared Library
import { Attachment } from 'gutwin-shared';

export class Solving {
  findingId: string;
  solvedBy: string;
  solvedAt: moment.Moment;
  note: string;
  attachments: Array<Attachment>;

  constructor(findingSolve: any) {
    this.findingId = findingSolve.findingId;
    this.solvedBy = findingSolve.solvedBy;
    this.solvedAt = findingSolve.solvedAt;
    this.note = findingSolve.note;
    this.attachments = this.generateAttachments(findingSolve.attachments);
  }

  generateAttachments(attachmentsRequest: Array<any>): Array<Attachment> {
    const attachments = new Array<Attachment>();
    if (attachmentsRequest) {
      attachmentsRequest.forEach(attachment => {
        attachments.push(new Attachment(attachment));
      });
    }
    return attachments;
  }
}


<div class="findings-list__details" [class.findings-list__details--even]="isEven">
  <div class="finding-details__header">
    <div class="finding-details__header--left">
      <p class="finding-detail--problem">{{ finding.problem }}</p>
    </div>
    <div class="finding-details__header--right"><a class="button--tertiary button--small" [routerLink]="['/finding', finding.id]">{{ 'FINDINGS_LIST.ACTION.SHOW_DETAILS' | translate }}</a></div>
  </div>
  <div class="finding-details__cause">
    <div class="finding-icon__label-container"><i class="gw-questionmark finding-icon"></i><span class="finding-detail__label">{{ 'FINDINGS_LIST.LABEL.CAUSE' | translate }}:&nbsp;</span></div>{{ finding.cause ? finding.cause : '–' }}
  </div>
  <div class="finding-details__solution">
    <div class="finding-icon__label-container"><i class="gw-info finding-icon"></i><span class="finding-detail__label">{{ 'FINDINGS_LIST.LABEL.SOLUTION' | translate }}:&nbsp;</span></div>{{ finding.solution ? finding.solution : '–' }}
  </div>
  <div class="finding-details__attachments" *ngIf="finding.attachments?.length">
    <gw-attachment *ngFor="let attachment of attachments.boxes" [attachment]="attachment" [gallery]="gallery" [viewOnly]="true"></gw-attachment>
    <gw-attachment class="attachment-container--doc" *ngFor="let attachment of attachments.documents" [attachment]="attachment" [viewOnly]="true"></gw-attachment>
  </div>
</div>
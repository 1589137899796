import { Injectable } from '@angular/core';

import { Company } from '../../../../models/company.model';
import { CurrentUser } from '../models/current-user.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  getAvailableCompaniesFromCurrentUser(currentUser: CurrentUser): Array<Company> {
    return currentUser.employees?.map(employee => employee.company);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DefaultList } from '../../models/default-list.model';
import { LegalOrigin } from '../../models/legal-origin.model';
import { LegalType } from '../../models/legal-type.model';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-sidebar-legal-type-and-origin',
  templateUrl: './legal-type-and-origin.component.html'
})
export class SidebarLegalTypeAndOriginComponent {
  @Input() legalOrigins: DefaultList<LegalOrigin>;
  @Input() legalTypes: DefaultList<LegalType>;
  @Input() isEditMode: boolean;
  @Input() chosenLegalType: LegalType;
  @Input() chosenLegalOrigin: LegalOrigin;
  @Input() userType: UserType;

  @Output() changeLegalType = new EventEmitter();
  @Output() changeLegalOrigin = new EventEmitter();

  readonly USER_TYPE = UserType;
}

export class SelectItem {
  key: any;
  name: string;
  data: any;
  disabled?: boolean;
  iconUrl?: boolean;
  _type = 'SelectItem';

  constructor(selectGroup: Partial<SelectItem>) {
    this.key = selectGroup.key;
    this.name = selectGroup.name;
    this.data = selectGroup.data;
    this.disabled = selectGroup.disabled;
    this.iconUrl = selectGroup.iconUrl;
  }
}

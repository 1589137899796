import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'translateMultiple'
})
export class TranslateMultiplePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(keysToTranslate: Array<string>, splitter = ' '): Observable<string> {
    return this.translateService.stream(keysToTranslate).pipe(
      map(translations => {
        return keysToTranslate.map(key => translations[key]).join(splitter);
      })
    );
  }
}

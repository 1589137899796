import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
// Models
import { Audit } from '../models/audit.model';
import { AuditedArea } from './../models/audited-area.model';
import { Section } from './../models/section.model';
import { Question } from './../models/question.model';
import { Questionnaire } from './../models/questionnaire.model';
// Providers
import { ApiUrlService } from './api-url.service';
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';
import { QuestionResponse, QuestionService } from './question.service';

export interface SectionsResponse {
  total_sections: number;
  ordered_sections: Array<SectionResponse>;
}

export interface SectionResponse {
  id: string;
  name: string;
  ordered_questions: Array<QuestionResponse>;
  total_questions: number;
  archived: boolean;
  is_custom: boolean;
  belongs_to_auditable: boolean;
}

@Injectable()
export class SectionService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private storageService: StorageModuleService,
    private questionService: QuestionService
  ) { }

  getSectionsWithQuestions(auditedAreaId: string, saveOffline?: boolean): Promise<Array<Section>> {
    const handleResponse = (data: SectionsResponse): Array<Section> => {
      const sections = new Array<Section>();
      data.ordered_sections.forEach((section: SectionResponse) => {
        sections.push(this.convertSectionToGet(section));
      });
      return sections;
    };

    let params = new HttpParams();
    params = params.set('audited_area_id', auditedAreaId);

    return this.http.get(this.apiUrlService.sectionWithQuestionsApi, { params: params })
      .toPromise()
      .then((data: any) => {
        this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + auditedAreaId, data);
        return handleResponse(data);
      })
      .catch(error => {
        if (error.status === 0 && !saveOffline) {
          return this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedAreaId)
            .then(data => {
              if (data === null) {
                console.error('Error while getting sections', error);
                throw error;
              }
              return handleResponse(data);
            });
        }
        console.error('Error while getting sections', error);
        throw error;
      });
  }

  getSelectedSectionsWithQuestions(auditable: Audit | AuditedArea): Promise<Array<Section>> {
    const promises = new Array<Promise<{sections?: Array<Section>, questions?: Array<Question>}>>();
    promises.push(this.getSections(auditable).then(sections => { return { sections }; }));
    promises.push(this.questionService.getQuestions(auditable).then(questions => { return { questions }; }));
    return Promise.all(promises)
      .then(values => {
        let sections = new Array<Section>();
        let questions = new Array<Question>();
        values.forEach(value => {
          sections = value.sections || sections;
          questions = value.questions || questions;
        });

        questions.forEach(question => {
          const section = _.find(sections, {id: question.sectionId});
          if (section) {
            section.questions.push(question);
          }
        });
        return sections;
      });
  }

  getCustomSections(sections: Array<Section>): Array<Section> {
    return sections.filter(section => section.custom);
  }

  getSections(composable: Audit | AuditedArea | Questionnaire): Promise<Array<Section>> {
    let params = new HttpParams();
    params = params.set('composable_id', composable.id);
    params = params.set('composable_type', composable._type);

    return this.http.get(this.apiUrlService.sectionApi, { params: params })
      .toPromise()
      .then((data: any) => {
        const sectionsResponse = new Array<SectionResponse>();
        const sections = new Array<Section>();
        data.ordered_sections.forEach((section: SectionResponse) => {
          sectionsResponse.push(section);
          sections.push(this.convertSectionToGet(section));
        });
        this.updateSectionsInOfflineStore(composable, sectionsResponse);
        return sections;
      })
      .catch(error => {
        console.error('Error while getting sections', error);
        throw error;
      });
  }

  reorderSections(sections: Array<Section>, composable: Audit | AuditedArea | Questionnaire): Promise<Array<Section>> {
    const requestBody = convertSectionsToReorder();

    return this.http.put(this.apiUrlService.sectionReorderApi, requestBody)
      .toPromise()
      .then((data: any) => {
        this.reorderSectionsInOfflineStore(composable, requestBody.ids_in_order);
        return data;
      })
      .catch(error => {
        console.error('Error while reordering sections', error);
        throw error;
      });

    function convertSectionsToReorder(): any {
      const sectionsIds = new Array<string>();
      for (const section of sections) {
        sectionsIds.push(section.id);
      }
      return {
        composable_id: composable.id,
        composable_type: composable._type,
        ids_in_order: sectionsIds
      };
    }
  }

  createSection(section: Section, composable: Audit | AuditedArea | Questionnaire): Promise<Section> {
    return this.http.post(this.apiUrlService.sectionApi, this.convertSectionToPost(section, composable))
      .toPromise()
      .then((data: any) => {
        this.updateSectionInOfflineStore(composable, data);
        return this.convertSectionToGet(data);
      })
      .catch(error => {
        console.error('Error while creating section', error);
        throw error;
      });
  }

  updateSection(section: Section, composable: Audit | AuditedArea | Questionnaire): Promise<Section> {
    return this.http.put(`${this.apiUrlService.sectionApi}/${section.id}`, this.convertSectionToPut(section, composable))
      .toPromise()
      .then((data: any) => {
        this.updateSectionInOfflineStore(composable, data);
        return this.convertSectionToGet(data);
      })
      .catch(error => {
        console.error('Error while updating section', error);
        throw error;
      });
  }

  archiveSection(section: Section, composable: Audit | AuditedArea | Questionnaire, archive = true): Promise<Section> {
    return this.http.put(
      `${this.apiUrlService.sectionApi}/${section.id}/archive`,
      this.convertSectionToArchive(section, composable, archive)
    )
      .toPromise()
      .then((data: any) => this.convertSectionToGet(data))
      .catch(error => {
        console.error('Error while archiving section', error);
        throw error;
      });
  }

  removeSection(section: Section, composable: Audit | AuditedArea | Questionnaire): Promise<any> {
    return this.http.delete(`${this.apiUrlService.sectionApi}/${section.id}`, { params: this.convertSectionToDelete(section, composable) })
      .toPromise()
      .then((data: any) => {
        if (composable) {
          this.removeSectionFromOfflineStore(composable, section.id);
        }
        return data;
      })
      .catch(error => {
        console.error('Error while removing section', error);
        throw error;
      });
  }

  checkAuditablesSection(auditableId: string, auditableType: string, sectionId: string): Promise<any> {
    return this.http.post(`${this.apiUrlService.sectionApi}/add_to_auditable`, {
      auditable_id: auditableId,
      auditable_type: auditableType,
      section_id: sectionId
    })
      .toPromise()
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('Error while checking section', error);
        throw error;
      });
  }

  uncheckAuditablesSection(auditableId: string, auditableType: string, sectionId: string): Promise<any> {
    return this.http.post(`${this.apiUrlService.sectionApi}/remove_from_auditable`, {
      auditable_id: auditableId,
      auditable_type: auditableType,
      section_id: sectionId
    })
      .toPromise()
      .then(data => data)
      .catch(error => {
        console.error('Error while unchecking section', error);
        throw error;
      });
  }

  private updateSectionsInOfflineStore(composable: Audit | AuditedArea | Questionnaire, sections: Array<SectionResponse>): void {
    switch (composable._type) {
      case 'Audit':
        const audit = new Audit(composable);
        if (audit.auditedAreas) {
          audit.auditedAreas.forEach((auditedArea: AuditedArea) => {
            this.updateAuditedAreaSectionsInOfflineStore(auditedArea, sections);
          });
        }
        break;
      case 'AuditedArea':
        const auditedArea = new AuditedArea(composable);
        this.updateAuditedAreaSectionsInOfflineStore(auditedArea, sections);
        break;
    }
  }

  private updateAuditedAreaSectionsInOfflineStore(auditedArea: AuditedArea, sections: Array<SectionResponse>): void {
    const updateSections = (data: SectionsResponse): any => {
      sections.forEach(section => {
        const index = _.findIndex(data.ordered_sections, {id: section.id});
        if (~index) {
          section.ordered_questions = data.ordered_sections[index].ordered_questions || new Array<QuestionResponse>();
          section.total_questions = section.ordered_questions.length;
          data.ordered_sections[index] = section;
        } else {
          data.ordered_sections.push(section);
          data.total_sections++;
        }
      });
      return data;
    };

    this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id)
      .then((data: SectionsResponse) => {
        if (data !== null) {
          const updatedData = updateSections(data);
          this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id, updatedData);
        }
      });
  }

  private updateSectionInOfflineStore(composable: Audit | AuditedArea | Questionnaire, section: SectionResponse): void {
    switch (composable._type) {
      case 'Audit':
        const audit = new Audit(composable);
        if (audit.auditedAreas) {
          audit.auditedAreas.forEach((auditedArea: AuditedArea) => {
            this.updateAuditedAreaSectionInOfflineStore(auditedArea, section);
          });
        }
        break;
      case 'AuditedArea':
        const auditedArea = new AuditedArea(composable);
        this.updateAuditedAreaSectionInOfflineStore(auditedArea, section);
        break;
    }
  }

  private updateAuditedAreaSectionInOfflineStore(auditedArea: AuditedArea, section: SectionResponse): void {
    const updateSection = (data: SectionsResponse): any => {
      const index = _.findIndex(data.ordered_sections, {id: section.id});
      if (~index) {
        section.ordered_questions = data.ordered_sections[index].ordered_questions || new Array<QuestionResponse>();
        section.total_questions = section.ordered_questions.length;
        data.ordered_sections[index] = section;
      } else {
        data.ordered_sections.push(section);
        data.total_sections++;
      }
      return data;
    };

    this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id)
      .then((data: SectionsResponse) => {
        if (data !== null) {
          const updatedData = updateSection(data);
          this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id, updatedData);
        }
      });
  }

  removeSectionsFromOfflineStore(composable: Audit | AuditedArea | Questionnaire): void {
    switch (composable._type) {
      case 'Audit':
        const audit = new Audit(composable);
        if (audit.auditedAreas) {
          audit.auditedAreas.forEach((auditedArea: AuditedArea) => {
            this.removeAuditedAreaSectionsFromOfflineStore(auditedArea);
          });
        }
        break;
      case 'AuditedArea':
        const auditedArea = new AuditedArea(composable);
        this.removeAuditedAreaSectionsFromOfflineStore(auditedArea);
        break;
    }
  }

  private removeAuditedAreaSectionsFromOfflineStore(auditedArea: AuditedArea): void {
    this.storageService.removeFromOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id);
  }

  private removeSectionFromOfflineStore(composable: Audit | AuditedArea | Questionnaire, sectionId: string): void {
    switch (composable._type) {
      case 'Audit':
        const audit = new Audit(composable);
        if (audit.auditedAreas) {
          audit.auditedAreas.forEach((auditedArea: AuditedArea) => {
            this.removeAuditedAreaSectionFromOfflineStore(auditedArea, sectionId);
          });
        }
        break;
      case 'AuditedArea':
        const auditedArea = new AuditedArea(composable);
        this.removeAuditedAreaSectionFromOfflineStore(auditedArea, sectionId);
        break;
    }
  }

  private removeAuditedAreaSectionFromOfflineStore(auditedArea: AuditedArea, sectionId: string): void {
    const removeSection = (data: SectionsResponse): any => {
      const index = _.findIndex(data.ordered_sections, {id: sectionId});
      if (~index) {
        data.ordered_sections.splice(index, 1);
      }
      return data;
    };

    this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id)
      .then((data: SectionsResponse) => {
        if (data !== null) {
          const updatedData = removeSection(data);
          this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + auditedArea.id, updatedData);
        }
      });
  }

  private reorderSectionsInOfflineStore(composable: Audit | AuditedArea | Questionnaire, sectionsIds: Array<string>): void {
    const reorderSections = (data: SectionsResponse): any => {
      const newSections = new Array<SectionResponse>();
      data.ordered_sections.forEach(section => {
        const index = sectionsIds.indexOf(section.id);
        newSections[index] = section;
      });
      data.ordered_sections = newSections;
      return data;
    };

    switch (composable._type) {
      case 'AuditedArea':
        this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaSections + composable.id)
          .then((data: SectionsResponse) => {
            if (data !== null) {
              const updatedData = reorderSections(data);
              this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + composable.id, updatedData);
            }
          });
      break;
    }
  }

  convertSectionToGet(section: SectionResponse): Section {
    const questions = new Array<Question>();
    if (section.ordered_questions) {
      section.ordered_questions.forEach(question => {
        questions.push(this.questionService.convertQuestionToGet(question));
      });
    }
    const sectionRequest = {
      id: section.id,
      name: section.name,
      questions: questions,
      totalQuestions: section.total_questions || 0,
      archived: section.archived,
      custom: section.is_custom,
      addedToParent: section.belongs_to_auditable === false
    };
    return new Section(sectionRequest);
  }

  private convertSectionToPost(section: Section, composable: Audit | AuditedArea | Questionnaire): any {
    const sectionRequest = {
      name: section.name,
      composable_id: composable.id,
      composable_type: composable._type
    };
    return sectionRequest;
  }

  private convertSectionToPut(section: Section, composable: Audit | AuditedArea | Questionnaire): any {
    const sectionRequest = {
      id: section.id,
      name: section.name,
      composable_id: composable.id,
      composable_type: composable._type
    };
    return sectionRequest;
  }

  private convertSectionToArchive(section: Section, composable: Audit | AuditedArea | Questionnaire, archive: boolean): any {
    const sectionRequest = {
      id: section.id,
      archive: archive ? 'true' : 'false',
      composable_id: composable.id,
      composable_type: composable._type
    };
    return sectionRequest;
  }

  private convertSectionToDelete(section: Section, composable: Audit | AuditedArea | Questionnaire): HttpParams {
    let sectionRequest = new HttpParams();
    sectionRequest = sectionRequest.set('id', section.id);
    sectionRequest = sectionRequest.set('composable_id', composable.id);
    sectionRequest = sectionRequest.set('composable_type', composable._type);
    return sectionRequest;
  }
}

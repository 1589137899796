
<div class="add-template">
  <form class="add-template__form" *ngIf="templateForm" [formGroup]="templateForm" novalidate="novalidate" autocomplete="off">
    <div class="add-template__fieldset">
      <div class="row add-template__fields">
        <div class="columns small-12 small-centered">
          <div class="form__field--lower add-template__field" [class.form__field--error]="isFieldInvalid(templateForm.controls['name'])">
            <label class="label" for="name">{{ 'AUDITS_LIST.ADD_TEMPLATE_MODAL.FORM.LABEL.NAME' | translate }}</label>
            <div class="form-field__error">{{ 'AUDITS_LIST.ADD_TEMPLATE_MODAL.FORM.ERROR.NAME_EMPTY' | translate }}</div>
            <input class="input" placeholder="{{ 'AUDITS_LIST.ADD_TEMPLATE_MODAL.FORM.PLACEHOLDER.NAME' | translate }}" type="text" id="name" name="name" tabindex="1" formControlName="name"/>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
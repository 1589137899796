import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { sortBy } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import {
  DialogService,
  Employee,
  EmployeeExtended,
  ModalIds,
  OverlayService,
  PermissionCategory,
  Role,
  SearchTheme,
  UserModalComponent,
  UserModalData,
  UserModalResponse,
  UserRolesModalComponent,
  UserRolesModalData,
  UserRolesModalResponse
} from 'gutwin-shared';

import { UserRoles } from '@gutwin-audit/shared/interfaces/role.interface';

import { FilterModuleService } from '@gutwin-audit/shared/services/filter-module.service';
import { RoleService } from '@gutwin-audit/shared/services/role.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';

@Component({
  selector: 'gw-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  employees: Array<EmployeeExtended>;
  roles: Array<Role>;
  permissionCategories: Array<PermissionCategory>;
  savingUser: boolean;
  savingUserRoles: boolean;
  searchQuery: string;
  totalEmployees: number;
  translation = {
    notify: {
      error: {
        title: '',
        loadUsers: '',
        createUser: '',
        updateUser: '',
        removeUser: '',
        resetPassword: '',
        loadRoles: '',
        updateRoles: '',
        loadPermissionCategories: ''
      },
      success: {
        title: '',
        createUser: '',
        updateUser: '',
        removeUser: '',
        resetPassword: '',
        updateRoles: ''
      }
    },
    removeDialog: {
      title: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    cancelUserRolesDialog: {
      title: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    resetPasswordDialog: {
      title: '',
      content: {
        beforeName: '',
        afterName: ''
      },
      cancel: '',
      confirm: ''
    }
  };

  destroy$ = new Subject<void>();

  readonly SEARCH_THEME_STRONG_BORDER = SearchTheme.StrongBorder;

  constructor(
    private dialogService: DialogService,
    private filterService: FilterModuleService,
    private notificationsService: NotificationsService,
    private overlayService: OverlayService,
    private roleService: RoleService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.employees = this.route.snapshot.data['employees'];
    this.totalEmployees = this.userService.totalEmployees;
    this.sortEmployees();
    this.observeFilters();
    this.fetchTranslation();
    this.fetchRoles();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  observeFilters(): Subscription {
    return this.filterService.filterObservable
      .pipe(takeUntil(this.destroy$), debounceTime(200))
      .subscribe(({ page, limit, filters }) => {
        this.fetchEmployees(page, filters, limit);
      });
  }

  async fetchEmployees(
    page = this.filterService.page,
    filters = this.filterService.auditsFilters,
    limit = this.filterService.limit
  ): Promise<void> {
    await this.userService
      .getEmployeesExtended(page, filters, limit)
      .then(res => {
        this.employees = res.list;
        this.totalEmployees = res.total;
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.loadUsers);
        return [];
      });
    this.sortEmployees();
  }

  async fetchRoles(): Promise<void> {
    this.roles = await this.roleService.getRoles().catch(error => {
      this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.loadRoles);
      return [];
    });
    await this.fetchPermissionCategories();
  }

  async fetchPermissionCategories(): Promise<void> {
    const rolesIds = this.roles ? this.roles.map(role => role.id) : [];
    this.permissionCategories = await this.roleService.getPermissionCategoriesInRoles(rolesIds).catch(error => {
      this.notificationsService.error(
        this.translation.notify.error.title,
        this.translation.notify.error.loadPermissionCategories
      );
      return [];
    });
  }

  sortEmployees(): void {
    this.employees = sortBy(this.employees, (employee: EmployeeExtended) => {
      return [!employee.access, employee.lastname, employee.name];
    });
  }

  showUserModal(employee?: EmployeeExtended): void {
    const userModal = this.overlayService.open<boolean, UserModalData, UserModalResponse>(
      UserModalComponent,
      {
        employee,
        roles: this.roles,
        permissionCategories: this.permissionCategories
      },
      {
        small: true
      }
    );

    userModal.submit$.pipe(takeUntil(this.destroy$)).subscribe(async response => {
      let savedEmployee: Employee;
      try {
        switch (response.data.status) {
          case 'create':
            savedEmployee = await this.createUser(response.data.employee);
            break;
          case 'update':
            savedEmployee = await this.updateUser(response.data.employee);
            break;
        }
        if (savedEmployee.access) {
          await this.updateUserRoles(savedEmployee, response.data.employee.roles);
        }
        this.overlayService.confirm(ModalIds.userModal);
      } catch (error) {
        this.overlayService.discard(ModalIds.userModal);
      }
    });

    userModal.afterClosed$.pipe(takeUntil(this.destroy$)).subscribe(wasAnythingSaved => {
      if (wasAnythingSaved) {
        this.fetchEmployees();
      }
    });
  }

  showRemoveDialog(employee: EmployeeExtended): void {
    this.dialogService
      .confirm(
        this.translation.removeDialog.title,
        this.translation.removeDialog.content,
        this.translation.removeDialog.cancel,
        this.translation.removeDialog.confirm
      )
      .then(() => {
        this.archiveUser(employee);
      })
      .catch(error => {});
  }

  showUserRolesModal(employee: EmployeeExtended): void {
    const userRolesModal = this.overlayService.open<UserRolesModalResponse, UserRolesModalData, UserRolesModalResponse>(
      UserRolesModalComponent,
      {
        employee,
        roles: this.roles,
        permissionCategories: this.permissionCategories,
        selectedRoles: employee.roles,
        standalone: true
      },
      {
        small: true
      }
    );

    userRolesModal.submit$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      this.updateUserRoles(employee, response.data.roles)
        .then(() => {
          this.overlayService.confirm(ModalIds.userRolesModal);
        })
        .catch(() => {
          this.overlayService.discard(ModalIds.userRolesModal);
        });
    });

    userRolesModal.afterClosed$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.fetchEmployees();
    });
  }

  showResetPasswordModal(employee: EmployeeExtended): void {
    const contentTranslation = {
      beforeName: this.translation.resetPasswordDialog.content.beforeName,
      afterName: this.translation.resetPasswordDialog.content.afterName
    };
    this.dialogService
      .confirm(
        this.translation.resetPasswordDialog.title,
        `${contentTranslation.beforeName}${employee.name} ${employee.lastname}${contentTranslation.afterName}`,
        this.translation.resetPasswordDialog.cancel,
        this.translation.resetPasswordDialog.confirm
      )
      .then(() => {
        this.resetPassword(employee);
      })
      .catch(error => {});
  }

  // TODO: Use this dialog before closing modal to edit roles when user changed something but clicked Cancel or Close button instead of Save button
  showCancelUserRolesDialog(): Promise<void> {
    return this.dialogService.confirm(
      this.translation.cancelUserRolesDialog.title,
      this.translation.cancelUserRolesDialog.content,
      this.translation.cancelUserRolesDialog.cancel,
      this.translation.cancelUserRolesDialog.confirm
    );
  }

  createUser(employee: Employee): Promise<Employee> {
    return this.userService
      .createEmployee(employee)
      .then(createdEmployee => {
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.createUser
        );
        return createdEmployee;
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.createUser);
        throw error;
      });
  }

  updateUser(employee: Employee): Promise<Employee> {
    return this.userService
      .updateEmployee(employee)
      .then(updatedEmployee => {
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.updateUser
        );
        return updatedEmployee;
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.updateUser);
        throw error;
      });
  }

  archiveUser(employee: EmployeeExtended): void {
    this.userService
      .archiveEmployee(employee)
      .then(async () => {
        await this.fetchEmployees();
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.removeUser
        );
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.removeUser);
      });
  }

  resetPassword(employee: EmployeeExtended): void {
    this.userService
      .resetPassword(employee)
      .then(() => {
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.resetPassword
        );
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.title,
          this.translation.notify.error.resetPassword
        );
      });
  }

  updateUserRoles(employee: Employee, roles: Array<Role>): Promise<UserRoles> {
    return this.roleService
      .updateUserRoles(employee.userId, roles)
      .then(response => {
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.updateRoles
        );
        return response;
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.updateRoles);
        throw error;
      });
  }

  onAddUser(): void {
    this.showUserModal();
  }

  onEditUser(employee: EmployeeExtended): void {
    this.showUserModal(employee);
  }

  onRemoveUser(employee: EmployeeExtended): void {
    this.showRemoveDialog(employee);
  }

  onResetPassword(employee: EmployeeExtended): void {
    this.showResetPasswordModal(employee);
  }

  onEditUserRoles(employee: EmployeeExtended): void {
    this.showUserRolesModal(employee);
  }

  onSearch(searchQuery: string): void {
    this.filterService.setFilter('usersSearch', searchQuery);
  }

  changePage(page: number): void {
    this.filterService.setPage(page);
  }

  changeLimit(limit: number): void {
    this.filterService.setLimit(limit);
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ACTION.SUCCESS',
        'GLOBAL.ACTION.CONFIRM',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'ADMIN_SETTINGS.USERS.ERROR.LOAD_USERS',
        'ADMIN_SETTINGS.USERS.ERROR.CREATE_USER',
        'ADMIN_SETTINGS.USERS.ERROR.UPDATE_USER',
        'ADMIN_SETTINGS.USERS.ERROR.REMOVE_USER',
        'ADMIN_SETTINGS.USERS.ERROR.RESET_PASSWORD',
        'ADMIN_SETTINGS.USERS.ERROR.LOAD_ROLES',
        'ADMIN_SETTINGS.USERS.ERROR.UPDATE_USER_ROLES',
        'ADMIN_SETTINGS.USERS.ERROR.LOAD_PERMISSION_CATEGORIES',
        'ADMIN_SETTINGS.USERS.SUCCESS.CREATE_USER_TEXT',
        'ADMIN_SETTINGS.USERS.SUCCESS.UPDATE_USER_TEXT',
        'ADMIN_SETTINGS.USERS.SUCCESS.REMOVE_USER_TEXT',
        'ADMIN_SETTINGS.USERS.SUCCESS.RESET_PASSWORD_TEXT',
        'ADMIN_SETTINGS.USERS.SUCCESS.UPDATE_USER_ROLES',
        'ADMIN_SETTINGS.USERS.REMOVE_USER_MODAL.HEADER',
        'ADMIN_SETTINGS.USERS.REMOVE_USER_MODAL.CONTENT',
        'ADMIN_SETTINGS.USERS.CANCEL_USER_ROLES_DIALOG.HEADER',
        'ADMIN_SETTINGS.USERS.CANCEL_USER_ROLES_DIALOG.CONTENT',
        'ADMIN_SETTINGS.USERS.RESET_PASSWORD_DIALOG.HEADER',
        'ADMIN_SETTINGS.USERS.RESET_PASSWORD_DIALOG.CONTENT.BEFORE_NAME',
        'ADMIN_SETTINGS.USERS.RESET_PASSWORD_DIALOG.CONTENT.AFTER_NAME'
      ])
      .toPromise();
    this.translation.notify.error.title = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.loadUsers = translation['ADMIN_SETTINGS.USERS.ERROR.LOAD_USERS'];
    this.translation.notify.error.createUser = translation['ADMIN_SETTINGS.USERS.ERROR.CREATE_USER'];
    this.translation.notify.error.updateUser = translation['ADMIN_SETTINGS.USERS.ERROR.UPDATE_USER'];
    this.translation.notify.error.removeUser = translation['ADMIN_SETTINGS.USERS.ERROR.REMOVE_USER'];
    this.translation.notify.error.resetPassword = translation['ADMIN_SETTINGS.USERS.ERROR.RESET_PASSWORD'];
    this.translation.notify.error.loadRoles = translation['ADMIN_SETTINGS.USERS.ERROR.LOAD_ROLES'];
    this.translation.notify.error.updateRoles = translation['ADMIN_SETTINGS.USERS.ERROR.UPDATE_USER_ROLES'];
    this.translation.notify.error.loadPermissionCategories =
      translation['ADMIN_SETTINGS.USERS.ERROR.LOAD_PERMISSION_CATEGORIES'];

    this.translation.notify.success.title = translation['GLOBAL.ACTION.SUCCESS'];
    this.translation.notify.success.createUser = translation['ADMIN_SETTINGS.USERS.SUCCESS.CREATE_USER_TEXT'];
    this.translation.notify.success.updateUser = translation['ADMIN_SETTINGS.USERS.SUCCESS.UPDATE_USER_TEXT'];
    this.translation.notify.success.removeUser = translation['ADMIN_SETTINGS.USERS.SUCCESS.REMOVE_USER_TEXT'];
    this.translation.notify.success.resetPassword = translation['ADMIN_SETTINGS.USERS.SUCCESS.RESET_PASSWORD_TEXT'];
    this.translation.notify.success.updateRoles = translation['ADMIN_SETTINGS.USERS.SUCCESS.UPDATE_USER_ROLES'];

    this.translation.removeDialog.title = translation['ADMIN_SETTINGS.USERS.REMOVE_USER_MODAL.HEADER'];
    this.translation.removeDialog.content = translation['ADMIN_SETTINGS.USERS.REMOVE_USER_MODAL.CONTENT'];
    this.translation.removeDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];

    this.translation.cancelUserRolesDialog.title = translation['ADMIN_SETTINGS.USERS.CANCEL_USER_ROLES_DIALOG.HEADER'];
    this.translation.cancelUserRolesDialog.content =
      translation['ADMIN_SETTINGS.USERS.CANCEL_USER_ROLES_DIALOG.CONTENT'];
    this.translation.cancelUserRolesDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.cancelUserRolesDialog.confirm = translation['GLOBAL.ACTION.CONFIRM'];

    this.translation.resetPasswordDialog.title = translation['ADMIN_SETTINGS.USERS.RESET_PASSWORD_DIALOG.HEADER'];
    this.translation.resetPasswordDialog.content.beforeName =
      translation['ADMIN_SETTINGS.USERS.RESET_PASSWORD_DIALOG.CONTENT.BEFORE_NAME'];
    this.translation.resetPasswordDialog.content.afterName =
      translation['ADMIN_SETTINGS.USERS.RESET_PASSWORD_DIALOG.CONTENT.AFTER_NAME'];
    this.translation.resetPasswordDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.resetPasswordDialog.confirm = translation['GLOBAL.ACTION.CONFIRM'];
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { RelatedLaw, RelatedLaws } from '../../interfaces/related-law.interface';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-requirement-sidebar-related-law',
  templateUrl: './requirement-sidebar-related-law.component.html',
  styleUrls: ['./requirement-sidebar-related-law.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequirementSidebarRelatedLawComponent {
  @Input() isEditMode: boolean;
  @Input() selectedRelatedLaws: RelatedLaws;
  @Input() userType: UserType;
  @Output() openAddPrimaryRelatedLawModal = new EventEmitter<void>();
  @Output() openAddSecondaryRelatedLawModal = new EventEmitter<void>();
  @Output() removeRelatedLaw = new EventEmitter<RelatedLaw>();
  @Output() selectRelatedLaw = new EventEmitter<RelatedLaw>();

  relatedLawsExpanded: boolean;

  constructor(private changeDetector: ChangeDetectorRef) {}

  toggleExpandAll(): void {
    this.relatedLawsExpanded = !this.relatedLawsExpanded;
    this.changeDetector.markForCheck();
  }

  onOpenAddPrimaryRelatedLawModal(): void {
    this.openAddPrimaryRelatedLawModal.emit();
  }

  onOpenAddSecondaryRelatedLawModal(): void {
    this.openAddSecondaryRelatedLawModal.emit();
  }

  onSelectRelatedLaw(selectedRelatedLaw: RelatedLaw): void {
    if (this.isEditMode) {
      this.selectRelatedLaw.emit(selectedRelatedLaw);
    }
  }

  onRemoveRelatedLaw(relatedLaw: RelatedLaw): void {
    this.removeRelatedLaw.emit(relatedLaw);
  }
}

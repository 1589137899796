import { Pipe, PipeTransform } from '@angular/core';

import { FilterType } from '../enums/filter-type.enum';
import { LcFilterListName } from '../enums/lc-filter-list.enum';
import { UserType } from '../enums/user-type.enum';

import { SHOW_FILTERS_CONDITIONS } from '../constants/show-filters-conditions.constants';

@Pipe({
  name: 'showFilter'
})
export class ShowFilterPipe implements PipeTransform {
  transform(filtersGroup: LcFilterListName, filterType: FilterType, userType?: UserType): boolean {
    return !!SHOW_FILTERS_CONDITIONS[filterType][userType]?.includes(filtersGroup);
  }
}

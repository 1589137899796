import { ExtraFieldSimple } from './extra-field-simple.model';

import { FilterType } from '../enums/filter-type.enum';
import { InactiveId } from '../enums/inactive-id.enum';
import { RelevanceRatingId } from '../enums/relevance-rating-id.enum';

export class LawFilters {
  [FilterType.legalCategories]?: Array<string>;
  [FilterType.hideNotRelevant]?: boolean;
  [FilterType.relevanceRatings]?: Array<RelevanceRatingId>;
  [FilterType.inactive]?: InactiveId;
  [FilterType.products]?: Array<string>;
  [FilterType.extraFields]?: Array<ExtraFieldSimple>;

  constructor(lawFilters?: Partial<LawFilters>) {
    Object.assign(this, lawFilters);
  }
}

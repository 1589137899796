import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { noop } from 'rxjs';

import { StoragesNames } from '../../../../services/storage.service';
import { SsoService } from '../../shared/services/sso.service';

@Component({
  selector: 'gw-token',
  templateUrl: './token.component.html'
})
export class TokenComponent implements OnInit {
  constructor(private ssoService: SsoService, private route: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    if (this.isOriginValid()) {
      const token = this.ssoService.getToken();
      const isValid = !token ? false : await this.ssoService.validateToken(token).catch(noop);
      if (isValid) {
        this.redirectWithToken(this.getRedirectUrl(), token);
      } else {
        this.logOut();
      }
    } else {
      this.logOut(false);
    }
  }

  getRedirectUrl(): string {
    return this.route.snapshot.queryParamMap.get('redirect_url');
  }

  redirectWithToken(redirectUrl: string, token: string): void {
    const hasQueryParams = ~redirectUrl.indexOf('?');
    const tokenQueryParamSign = hasQueryParams ? '&' : '?';
    const tokenQueryParam = token ? `${tokenQueryParamSign}${StoragesNames.token}=${token}` : '';
    window.location.href = `${redirectUrl}${tokenQueryParam}`;
  }

  logOut(withRedirectUrl = true): void {
    this.ssoService.logOut(
      withRedirectUrl,
      withRedirectUrl ? this.getRedirectUrl() : undefined,
      undefined,
      withRedirectUrl
    );
  }

  isOriginValid(): boolean {
    const redirectUrl = this.getRedirectUrl();
    return this.ssoService.isOriginValid(redirectUrl);
  }
}

import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { FacilityFilter } from '../../../shared/models/facility-filter.model';

@Component({
  selector: 'gw-select-facility',
  templateUrl: './select-facility.component.html',
  styleUrls: ['./select-facility.component.scss'],
  exportAs: 'gwSelectFacility'
})
export class SelectFacilityComponent implements OnInit {
  @Output() submitFacilities = new EventEmitter<any>();
  @Input() chosenFacility: FacilityFilter;
  @Input() facilities: Array<FacilityFilter>;
  selectedFacility = { id: '' };

  constructor() {}

  ngOnInit() {
    if (this.chosenFacility) {
      this.selectedFacility = this.chosenFacility;
    }
  }

  findFacility(selectedFacility, facilityTree: Array<FacilityFilter>): FacilityFilter {
    for (const facility of facilityTree) {
      if (facility.id === selectedFacility.id) {
        return facility;
      } else if (facility.children && facility.children.length) {
        const findedFacility = this.findFacility(selectedFacility, facility.children);
        if (findedFacility) {
          return findedFacility;
        }
      }
    }
  }

  changeSelectedFacility(facility: any) {
    this.selectedFacility = facility;
  }

  submitSelectedFacilities(): void {
    if (this.selectedFacility.id.length) {
      this.submitFacilities.emit(this.selectedFacility);
    }
  }

  @HostListener('keydown', ['$event'])
  keydown(event) {
    switch (event.key) {
      case 'Tab':
        event.preventDefault();
        return false;
      case 'Enter':
        event.preventDefault();
        this.submitSelectedFacilities();
        return false;
    }
  }
}

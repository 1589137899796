import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from 'gutwin-shared';

@Component({
  selector: 'gw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  notificationOptions = {
    theClass: 'notification',
    position: ['bottom', 'right'],
    timeOut: 2000
  };

  constructor(private translate: TranslateService, private i18nService: I18nService) {
    this.translate.addLangs(['en', 'de']);
    this.translate.setDefaultLang('en');
    this.translate.use(this.i18nService.getCurrentLanguage());
  }

  ngOnInit() {}
}

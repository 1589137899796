import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'isFieldInvalid',
  pure: false
})
export class IsFieldInvalidPipe implements PipeTransform {
  transform(field: AbstractControl, submitted?: boolean): boolean {
    return field.invalid && submitted;
  }
}


<div class="add-facilities">
  <form class="add-facilities__form" [formGroup]="addFacilityForm" novalidate="novalidate" autocomplete="off">
    <div class="add-facilities__fieldset">
      <div class="row add-facilities__fields">
        <div class="columns small-12 small-centered">
          <div class="form__field--lower add-facilities__field" [class.form__field--error]="isFieldInvalid(addFacilityForm.controls['name'])">
            <label class="label add-facilities__field-label" for="name">{{ 'ADMIN_SETTINGS.FACILITIES.MODAL.FORM.LABEL.NAME' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.FACILITIES.MODAL.FORM.ERROR.NAME_EMPTY' | translate }}</div>
            <input class="input" placeholder="{{ 'ADMIN_SETTINGS.FACILITIES.MODAL.FORM.PLACEHOLDER.NAME' | translate }}" type="text" name="name" [formControl]="addFacilityForm.controls['name']"/>
          </div>
          <div class="form__field--lower add-facilities__field">
            <label class="label add-facilities__field-label">
              <gw-check-box class="add-facilities__check-box" [(ngModel)]="addAnother" [ngModelOptions]="{standalone: true}"></gw-check-box><span class="link add-facilities__check-box-label">{{ 'ADMIN_SETTINGS.FACILITIES.MODAL.FORM.LABEL.ADD_ANOTHER' | translate }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
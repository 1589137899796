import { Finding } from './finding.model';
import { FindingsAmount } from './findings-amount.model';

export class FindingsResponse {
  findingsAmount: FindingsAmount;
  findings: Array<Finding>;
  areRequests?: boolean;

  constructor(findingsResponse?: any) {
    this.findingsAmount = findingsResponse ? new FindingsAmount(findingsResponse.findingsAmount) : new FindingsAmount();
    this.findings = findingsResponse ? findingsResponse.findings || [] : [];
  }
}

import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Injectable, Injector, TemplateRef, Type } from '@angular/core';

import { Subject } from 'rxjs';
import { DialogOverlayRef } from '../components/overlay/dialog-overlay-ref';

import { DialogConfig } from '../interfaces/dialog-config.interface';

import { OverlayComponent } from '../components/overlay/overlay.component';

import { BodyFreezerService } from './body-freezer.service';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  config: DialogConfig;
  confirm$ = new Subject<string>();
  discard$ = new Subject<string>();
  showError$ = new Subject<boolean>();

  constructor(private overlay: Overlay, private injector: Injector, private bodyFreezerService: BodyFreezerService) {}

  open<R = any, T = any, S = any>(
    content: string | TemplateRef<any> | Type<any>,
    data?: T,
    config?: DialogConfig
  ): DialogOverlayRef<R, T, S> {
    this.config =
      config ||
      new OverlayConfig({
        hasBackdrop: true,
        backdropClass: 'modal-background'
      });

    this.bodyFreezerService.freezeBody();
    const overlayRef = this.overlay.create(this.config);

    const myOverlayRef = new DialogOverlayRef<R, T, S>(overlayRef, content, data, config, this.bodyFreezerService);

    const injector = this.createInjector(myOverlayRef, this.injector);
    overlayRef.attach(new ComponentPortal(OverlayComponent, null, injector));

    return myOverlayRef;
  }

  createInjector(dialogOverlayRef: DialogOverlayRef, injector: Injector): PortalInjector {
    const injectorTokens = new WeakMap([[DialogOverlayRef, dialogOverlayRef]]);
    return new PortalInjector(injector, injectorTokens);
  }

  confirm(id: string): void {
    this.confirm$.next(id);
  }

  discard(id: string): void {
    this.discard$.next(id);
  }

  showError(value: boolean): void {
    this.showError$.next(value);
  }
}

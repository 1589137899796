import { Injectable } from '@angular/core';
import * as d3 from 'd3';
// Models
import { FindingsStatsRating } from './../models/findings-stats.model';
import { QuestionStatsRating } from './../models/questions-stats.model';

export type d3Selection = d3.Selection<d3.BaseType, {}, HTMLElement, any>;

@Injectable()
export class ChartService {
  colors = [
    '#1464F6', '#FEBA21', '#FF7F7F', '#81D886', '#6E00E0',
    '#0083B6', '#FE8E02', '#FF4B4B', '#62CF68', '#481F9A',
    '#01B8FE', '#FEE402', '#FFA0A0', '#96EFAA', '#934BFF',
    '#67C6FE', '#DDDBDB', '#E54BFF', '#17E5D2', '#9013FE',
    '#658CC6', '#9B9B9B', '#8CA2C2', '#1E3B68'
  ];

  constructor() { }

  generateCircleChart(chartContainer: d3Selection, chartDataset: any, totalCount: number): void {
    const width = 240;
    const height = 240;
    const radius = 230 / 2;
    const padding = 8;
    const innerRadius = radius - padding;
    const scaleFactor = 12;
    const fillColor = 'transparent';
    const strokeColor = '#E6E6E6';

    chartContainer.select("svg").remove();

    const donut = chartContainer
      .append('svg')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('class', 'graph')
      .attr('transform', 'translate(' + (width / 2) +  ',' + (height / 2) + ')')
      .attr('fill', fillColor);

    const arc: any = d3.arc()
      .outerRadius((d: any, i: any) => {
        return radius - i * scaleFactor;
      })
      .innerRadius((d: any, i: any) => {
        return innerRadius - i * scaleFactor;
      })
      .cornerRadius(2);

    const pie: any = d3.pie()
      .value((s: any) => {
        return s.count;
      })
      .sort(null);

    const cirle: any = donut.selectAll('circle')
      .data(chartDataset)
      .enter()
      .append('circle')
      .attr('r', (d: any, i: any) => {
        return radius - padding / 2 - i * scaleFactor;
      })
      .attr('class', 'circle')
      .attr('cy', 0)
      .attr('cx', 0)
      .attr('stroke-dasharray', '1.5')
      .attr('stroke', strokeColor)
      .attr('fill', 'white');

    const path: any = donut.selectAll('path')
      .data(pie(chartDataset))
      .enter()
      .append('path')
      .attr('d', arc)
      .style('fill', (d: any, i: any) => {
        return d.data.color;
      });
  }

  generateWholeCircleChart(
    chartContainer: d3Selection,
    chartDataset: Array<QuestionStatsRating>,
    totalCount: number,
    labels: { header: string, total: string }
  ): any {
    const itemsInColumn = 5;
    const pieWidth = 240;
    const pieHeight = 240;
    const pieX = 16;
    const pieY = 50;
    const legendX = chartDataset.length > itemsInColumn ? pieX : pieX + pieWidth + 40;
    const legendY = chartDataset.length > itemsInColumn ? pieY + pieHeight + 40 : pieY + 10;
    const legendItemWidth = 200;
    const legendItemHeight = 46;
    const legendWidth = Math.ceil(chartDataset.length / itemsInColumn) * legendItemWidth;
    const legendHeight = (chartDataset.length > itemsInColumn ? itemsInColumn : chartDataset.length) * legendItemHeight;
    const chartWidth = chartDataset.length > itemsInColumn ?
      Math.max(pieX + pieWidth, legendX + legendWidth) :
      legendX + legendWidth;
    const chartHeight = chartDataset.length > itemsInColumn ?
      legendY + legendHeight :
      Math.max(pieY + pieHeight, legendY + legendHeight);

    this.generateCircleChart(chartContainer, chartDataset, totalCount);

    const chart = chartContainer.select('svg')
      .attr('width', chartWidth)
      .attr('height', chartHeight);

    // Title
    const title = chart.insert('text', 'g')
      .text(labels.header)
      .attr('x', pieX)
      .attr('y', 26)
      .style('fill', '#355990')
      .style('font-family', 'Trebuchet MS, Gadget, sans-serif')
      .style('font-size', 24)
      .attr('alignment-baseline', 'baseline');

    // Pie
    chart.select('g')
      .selectAll('circle')
      .attr('cx', pieX)
      .attr('cy', pieY);

    chart.select('g')
      .selectAll('path')
      .attr('transform', `translate(${pieX}, ${pieY})`);

    // Pie text
    chart.select('g')
      .append('text')
      .text(totalCount)
      .attr('class', 'circle-text--big')
      .attr('x', pieX)
      .attr('y', pieY - 4)
      .style('font-family', 'Trebuchet MS, Gadget, sans-serif')
      .style('font-size', 24)
      .style('fill', '#8B8B8B')
      .attr('text-anchor', 'middle');

    const pieText = chart.select('g')
      .append('text')
      .attr('class', 'circle-text')
      .attr('x', pieX)
      .attr('y', pieY + 14)
      .style('font-family', 'Trebuchet MS, Gadget, sans-serif')
      .style('font-size', 14)
      .style('fill', '#8B8B8B')
      .attr('text-anchor', 'middle');

    if (chartDataset.length > itemsInColumn) {
      const textArray = labels.total.split(' ');
      pieText.text(textArray[0]);

      for (let i = 1; i < textArray.length; i++) {
        chart.select('g')
          .append('text')
          .text(textArray[i])
          .attr('class', 'circle-text')
          .attr('x', pieX)
          .attr('y', pieY + 14 + 16 * i)
          .style('font-family', 'Trebuchet MS, Gadget, sans-serif')
          .style('font-size', 14)
          .style('fill', '#8B8B8B')
          .attr('text-anchor', 'middle');
      }
    } else {
      pieText.text(labels.total);
    }

    // Legend
    const legend = chart.append('g')
      .attr('class', 'legend');

    legend.selectAll('circle')
      .data(chartDataset)
      .enter()
      .append('circle')
      .attr('class', 'legend__item-circle')
      .attr('r', 4)
      .attr('cx', (d: any, i: any) => {
        return legendX + Math.floor(i / itemsInColumn) * legendItemWidth;
      })
      .attr('cy', (d: any, i: any) => {
        return legendY + (i % itemsInColumn) * legendItemHeight - 4;
      })
      .style('fill', (d: any, i: any) => {
        return d.color;
      });

    legend.selectAll('text.legend__item-value')
      .data(chartDataset)
      .enter()
      .append('text')
      .text((d: any, i: any) => {
        return Math.round(d.count * 100 / totalCount) + '%';
      })
      .attr('class', 'legend__item-value')
      .attr('r', 4)
      .attr('x', (d: any, i: any) => {
        return legendX + 16 + Math.floor(i / itemsInColumn) * legendItemWidth;
      })
      .attr('y', (d: any, i: any) => {
        return legendY + (i % itemsInColumn) * legendItemHeight;
      })
      .style('font-family', 'Trebuchet MS, Gadget, sans-serif')
      .style('font-size', 14)
      .style('font-weight', 600)
      .style('fill', '#8B8B8B');

    legend.selectAll('text.legend__item-name')
      .data(chartDataset)
      .enter()
      .append('text')
      .text((d: any, i: any) => {
        return `${d.count} ${d.name}`;
      })
      .attr('class', 'legend__item-name')
      .attr('r', 4)
      .attr('x', (d: any, i: any) => {
        return legendX + 16 + Math.floor(i / itemsInColumn) * legendItemWidth;
      })
      .attr('y', (d: any, i: any) => {
        return legendY + (i % itemsInColumn) * legendItemHeight + 20;
      })
      .style('font-family', 'Trebuchet MS, Gadget, sans-serif')
      .style('font-size', 14)
      .style('fill', '#8B8B8B');

    return chartContainer.html();
  }

  generateBarChart(chartContainer: d3Selection, chartDataset: Array<FindingsStatsRating>): void {
    const getMax = (data: Array<any>, key: string): any => {
      let maxData;
      data.forEach(element => {
        if (!maxData || maxData[key] < element[key]) {
          maxData = element;
        }
      });
      return maxData;
    };

    const countTicks = (max: any): number => {
      if (max.count > 10) {
        return 10;
      } else {
        return max.count;
      }
    };

    const customYAxis = (g: d3Selection, yAxis: d3.Axis<number | { valueOf(): number }>): d3Selection => {
      g.call(yAxis);
      g.select('.domain')
        .remove();
      g.selectAll('.tick line')
        .attr('x1', 25)
        .attr('class', 'axis');
      g.selectAll('.tick:not(:last-of-type) line')
        .attr('stroke-dasharray', '1');
      g.selectAll('.tick text')
        .attr('x', 4)
        .attr('dy', 3);
      return g;
    };

    const dataMax = getMax(chartDataset, 'count');
    const itemWidth = chartDataset.length > 9 ? 30 : 45;
    const width = 20 + itemWidth * chartDataset.length;
    const height = 240;

    const xScale = d3.scaleBand()
      .domain(chartDataset.map((d: any) => {
        return d.name;
      }))
      .range([20, width])
      .paddingOuter(0.4)
      .paddingInner(0.8);

    const yScale = d3.scaleLinear()
      .domain([dataMax.count, 0])
      .range([10, height - 10]);

    const yAxis = d3.axisRight(yScale)
      .tickSize(width)
      .ticks(countTicks(dataMax));

    chartContainer.select("svg").remove();

    const chart = chartContainer
      .append('svg')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('width', width)
      .attr('height', height);

    chart.append('g')
      .call((g) => customYAxis(g, yAxis));

    chart.selectAll('rect')
      .data(chartDataset)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d: any, i: any) => {
        return xScale(d.name);
      })
      .attr('y', (d: any) => {
        return yScale(d.count);
      })
      .attr('width', xScale.bandwidth())
      .attr('height', (d: any) => {
        return height - yScale(d.count) - 10;
      })
      .style('fill', (d, i) => this.colors[i % this.colors.length])
      .attr('rx', 2)
      .attr('ry', 2);
  }

  generateWholeBarChart(
    chartContainer: d3Selection,
    chartDataset: Array<FindingsStatsRating>,
    totalCount: number,
    labels: { header: string }
  ): any {
    const itemsInColumn = 5;
    const itemWidth = chartDataset.length > 9 ? 30 : 45;
    const barWidth = 20 + itemWidth * chartDataset.length;
    const barHeight = 240;
    const barX = 16;
    const barY = 50;
    const legendX = chartDataset.length > itemsInColumn ? barX : barX + barWidth + 40;
    const legendY = chartDataset.length > itemsInColumn ? barY + barHeight + 40 : barY + 20;
    const legendItemWidth = 200;
    const legendItemHeight = 46;
    const legendWidth = Math.ceil(chartDataset.length / itemsInColumn) * legendItemWidth;
    const legendHeight = (chartDataset.length > itemsInColumn ? itemsInColumn : chartDataset.length) * legendItemHeight;
    const chartWidth = chartDataset.length > itemsInColumn ?
      Math.max(barX + barWidth, legendX + legendWidth) :
      legendX + legendWidth;
    const chartHeight = chartDataset.length > itemsInColumn ?
      legendY + legendHeight :
      Math.max(barY + barHeight, legendY + legendHeight);

    this.generateBarChart(chartContainer, chartDataset);

    const chart = chartContainer.select('svg')
      .attr('width', chartWidth)
      .attr('height', chartHeight);

    // Bar
    chart.select('g')
      .attr('transform', `translate(${barX}, ${barY})`);

    chart.selectAll('rect')
      .attr('transform', `translate(${barX}, ${barY})`);

    chart.selectAll('.tick line')
      .style('stroke', '#8B8B8B');

    chart.selectAll('.tick text')
      .style('font-family', 'Trebuchet MS, Gadget, sans-serif')
      .style('font-size', 14)
      .style('fill', '#8B8B8B');

    // Title
    const title = chart.insert('text', 'g')
      .text(labels.header)
      .attr('x', barX)
      .attr('y', 26)
      .style('fill', '#355990')
      .style('font-family', 'Trebuchet MS, Gadget, sans-serif')
      .style('font-size', 24)
      .attr('alignment-baseline', 'baseline');

    // Legend
    const legend = chart.append('g')
      .attr('class', 'legend');

    legend.selectAll('circle')
      .data(chartDataset)
      .enter()
      .append('circle')
      .attr('class', 'legend__item-circle')
      .attr('r', 4)
      .attr('cx', (d: any, i: any) => {
        return legendX + Math.floor(i / itemsInColumn) * legendItemWidth;
      })
      .attr('cy', (d: any, i: any) => {
        return legendY + (i % itemsInColumn) * legendItemHeight - 4;
      })
      .style('fill', (d: any, i: any) => this.colors[i % this.colors.length]);

    legend.selectAll('.legend__item-value')
      .data(chartDataset)
      .enter()
      .append('text')
      .text((d: any, i: any) => {
        return Math.round(d.count * 100 / totalCount) + '%';
      })
      .attr('class', 'legend__item-value')
      .attr('r', 4)
      .attr('x', (d: any, i: any) => {
        return legendX + 16 + Math.floor(i / itemsInColumn) * legendItemWidth;
      })
      .attr('y', (d: any, i: any) => {
        return legendY + (i % itemsInColumn) * legendItemHeight;
      })
      .style('font-family', 'Trebuchet MS, Gadget, sans-serif')
      .style('font-size', 14)
      .style('font-weight', 600)
      .style('fill', '#8B8B8B');

    legend.selectAll('.legend__item-name')
      .data(chartDataset)
      .enter()
      .append('text')
      .text((d: any, i: any) => {
        return `${d.count} ${d.name}`;
      })
      .attr('class', 'legend__item-name')
      .attr('r', 4)
      .attr('x', (d: any, i: any) => {
        return legendX + 16 + Math.floor(i / itemsInColumn) * legendItemWidth;
      })
      .attr('y', (d: any, i: any) => {
        return legendY + (i % itemsInColumn) * legendItemHeight + 20;
      })
      .style('font-family', 'Trebuchet MS, Gadget, sans-serif')
      .style('font-size', 14)
      .style('fill', '#8B8B8B');

    return chartContainer.html();
  }
}

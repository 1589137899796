import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleDropdownDirective } from '../../directives/simple-dropdown.directive';

@Component({
  selector: 'gw-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true
    }
  ],
  exportAs: 'gwColorPicker'
})
export class ColorPickerComponent implements OnInit, ControlValueAccessor {
  @ViewChild('colorDropdown', { static: true }) colorDropdown: SimpleDropdownDirective;
  @Input() tabindex: number;
  @Input() fitContainer = true;
  @Input() hasLabel: boolean;
  @Input() hasEmptyIcon: boolean;
  @Input() center: boolean;
  @Input() selectClasses = [];
  @Input() clearButton: boolean;
  _choosenColor: string;
  colors = [
    '#FEBA21',
    '#FE8E02',
    '#FF8E1D',
    '#FFA0A0',
    '#FF7F7F',
    '#FF4B4B',
    '#FEE402',
    '#67C6FE',
    '#02A0FD',
    '#1464F6',
    '#33D0FF',
    '#E54BFF',
    '#ECEC0D',
    '#94D2F0',
    '#DDDBDB',
    '#D5D5D5',
    '#01B8FE',
    '#934BFF',
    '#D7EC0E',
    '#AFC2CC',
    '#9BACB5',
    '#9B9B9B',
    '#03B9E6',
    '#9013FE',
    '#96EFAA',
    '#81D886',
    '#62CF68',
    '#17E5D2',
    '#54DAFB',
    '#6E00E0',
    '#1E3B68',
    '#355990',
    '#658CC6',
    '#8CA2C2',
    '#0083B6',
    '#481F9A'
  ];
  focusedColor = {
    index: 0,
    color: this.colors[0]
  };
  onChange: Function;

  constructor() {}

  ngOnInit(): void {}

  get choosenColor(): string {
    return this._choosenColor;
  }

  set choosenColor(color: string) {
    this._choosenColor = color;
    this.onChange(this._choosenColor);
  }

  writeValue(value: string): void {
    this._choosenColor = value;
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setColor(color: string): void {
    this.choosenColor = color;
    this.initFocusedColor();
  }

  initFocusedColor(): void {
    if (this.choosenColor) {
      this.focusedColor = {
        index: this.colors.indexOf(this.choosenColor),
        color: this.choosenColor
      };
    } else {
      this.focusedColor = {
        index: 0,
        color: this.colors[0]
      };
    }
  }

  goToColor(direction): void {
    if (this.focusedColor) {
      let index = this.focusedColor.index;
      switch (direction) {
        case 'up':
          index = index - 6;
          if (index < 0) {
            index = this.colors.length + index;
          }
          break;
        case 'down':
          index = index + 6;
          if (index > this.colors.length - 1) {
            index = index - this.colors.length;
          }
          break;
        case 'left':
          index = index - 1;
          if (index < 0) {
            index = this.colors.length - 1;
          }
          break;
        case 'right':
          index = index + 1;
          if (index > this.colors.length - 1) {
            index = 0;
          }
          break;
      }
      this.focusedColor = {
        index: index,
        color: this.colors[index]
      };
    }
  }

  focusDropdown(): void {
    this.colorDropdown.focusOnDropdown();
  }

  keyDownColorPicker(event: any): void {
    switch (event.key) {
      case 'Enter':
        if (!this.colorDropdown.opened) {
          this.choosenColor = this.focusedColor.color;
        }
        break;
      case 'ArrowLeft':
        if (this.colorDropdown.opened) {
          event.preventDefault();
          this.goToColor('left');
        }
        break;
      case 'ArrowUp':
        if (this.colorDropdown.opened) {
          event.preventDefault();
          this.goToColor('up');
        }
        break;
      case 'ArrowRight':
        if (this.colorDropdown.opened) {
          event.preventDefault();
          this.goToColor('right');
        }
        break;
      case 'ArrowDown':
        if (this.colorDropdown.opened) {
          event.preventDefault();
          this.goToColor('down');
        }
        break;
    }
  }

  clearColor(): void {
    this.choosenColor = undefined;
  }
}

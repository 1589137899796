import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { map } from 'lodash';

import { CompanyUnit } from '../models/company-unit.model';

import { ApiUnit, ApiUnitType } from '../interfaces/company-structure.interface';
import { UnitType } from '../interfaces/unit-type.interface';

import { UnitTypeKey } from '../enums/unit-type-key.enum';

import { convertAttachmentToGet } from '../converters/attachment.convert';
import { convertComplianceRatingsProgress, convertRelevanceRatingsProgress } from './rating.convert';
import { convertListToTree } from './shared.convert';

export const convertCompanyStructureToGet = (units: Array<ApiUnit>): Array<CompanyUnit> => {
  const convertedUnits = units.map(convertUnitToGet);

  return convertListToTree<CompanyUnit>(convertedUnits);
};

export const convertRootCompanyUnitsToGet = (units: Array<ApiUnit>): Array<CompanyUnit> => {
  return units.map(convertRootUnitToGet);
};

export const convertSimpleCompanyStructureToGet = (units: Array<ApiUnit>): Array<CompanyUnit> => {
  const convertedUnits = units.map(convertSimpleUnitToGet);
  return convertListToTree<CompanyUnit>(convertedUnits);
};

export const convertUnitToGet = (unit: ApiUnit): CompanyUnit => {
  return new CompanyUnit({
    ...convertSimpleUnitToGet(unit),
    linkedRequirementsCount: unit.linkedRequirementsCount,
    type: convertUnitTypeToGet(unit.type),
    backgroundImage: unit.backgroundImage
      ? convertAttachmentToGet({
          id: unit.backgroundImage.id,
          ...unit.backgroundImage.backgroundImage
        })
      : null
  });
};

export const convertRootUnitToGet = (unit: ApiUnit): CompanyUnit => {
  return new CompanyUnit({
    ...convertUnitToGet(unit),
    complianceRatingProgress: convertComplianceRatingsProgress(unit.complianceRatingStats),
    relevanceRatingProgress: convertRelevanceRatingsProgress(unit.relevanceRatingStats)
  });
};

export const convertCreatedUnitToGet = (unit: ApiUnit): CompanyUnit => {
  return new CompanyUnit({
    ...convertUnitToGet(unit),
    ancestorsIds: map(unit.ancestors, 'id')
  });
};

export const convertUnitTypeToGet = (unitType: ApiUnitType): UnitType => ({
  id: unitType.id,
  key: unitType.name,
  name: getUnitTypeNameTranslationKey(unitType.name),
  icon: unitType?.icon && convertAttachmentToGet(unitType.icon)
});

export const convertSimpleUnitToGet = (unit: ApiUnit): CompanyUnit => {
  const children = unit.descendants?.length ? convertCompanyStructureToGet(unit.descendants) : [];
  return new CompanyUnit({
    children,
    id: unit.id,
    name: unit.name,
    path: unit.path
  });
};

export const getUnitTypeNameTranslationKey = (name: UnitTypeKey) => {
  return {
    [UnitTypeKey.OrganizationalUnits]: marker('GUTWIN_SHARED.UNIT_TYPES.ORGANIZATIONAL_UNIT'),
    [UnitTypeKey.Process]: marker('GUTWIN_SHARED.UNIT_TYPES.PROCESS'),
    [UnitTypeKey.Site]: marker('GUTWIN_SHARED.UNIT_TYPES.SITE'),
    [UnitTypeKey.Building]: marker('GUTWIN_SHARED.UNIT_TYPES.BUILDING'),
    [UnitTypeKey.Workplace]: marker('GUTWIN_SHARED.UNIT_TYPES.WORKPLACE'),
    [UnitTypeKey.Facility]: marker('GUTWIN_SHARED.UNIT_TYPES.FACILITY')
  }[name];
};

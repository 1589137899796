import { Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { PermissionsNames } from 'gutwin-shared';

import { AuthenticationService } from '@gutwin-audit/shared/services/authentication.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';

@Component({
  selector: 'gw-admin-tabs',
  templateUrl: './admin-tabs.component.html',
  styleUrls: ['./admin-tabs.component.scss']
})
export class AdminTabsComponent implements OnInit, OnDestroy {
  tabs = {
    questionnaires: {
      url: '/admin/questionnaires',
      title: '',
      visible: false
    },
    users: {
      url: '/admin/users',
      title: '',
      visible: false
    },
    roles: {
      url: '/admin/roles',
      title: '',
      visible: false
    },
    auditTypes: {
      url: '/admin/audit-types',
      title: '',
      visible: false
    },
    findingTypes: {
      url: '/admin/finding-types',
      title: '',
      visible: false
    },
    ratingScale: {
      url: '/admin/rating-scales',
      title: '',
      visible: false
    },
    facilities: {
      url: '/admin/facilities',
      title: '',
      visible: false
    },
    companySettings: {
      url: '/admin/company-settings',
      title: '',
      visible: false
    },
    company: {
      url: '/admin/company',
      title: '',
      visible: false
    },
    reports: {
      url: '/admin/reports',
      title: '',
      visible: false
    }
  };
  tabsArray: Array<any>;
  userSubscription: Subscription;
  translateSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.markVisbilityOfTabs();
    this.userSubscription = this.userService.storedUserObservable.subscribe(user => {
      this.markVisbilityOfTabs();
    });
    this.fetchTranslation();
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
    this.translateSubscription.unsubscribe();
  }

  convertTabsToArray(): Array<any> {
    const tabsArray = new Array<any>();
    for (const tabKey of Object.keys(this.tabs)) {
      tabsArray.push(this.tabs[tabKey]);
    }
    return tabsArray;
  }

  markVisbilityOfTabs(): void {
    const currentUser = this.userService.storedUser;
    this.tabs.questionnaires.visible = currentUser
      ? currentUser.hasPermission(PermissionsNames.manageQuestionnaires)
      : false;
    this.tabs.users.visible = currentUser ? currentUser.hasPermission(PermissionsNames.manageUsers) : false;
    this.tabs.roles.visible = currentUser ? currentUser.hasPermission(PermissionsNames.manageUserRoles) : false;
    this.tabs.auditTypes.visible = currentUser ? currentUser.hasPermission(PermissionsNames.manageAuditTypes) : false;
    this.tabs.findingTypes.visible = currentUser
      ? currentUser.hasPermission(PermissionsNames.manageFindingTypes)
      : false;
    this.tabs.ratingScale.visible = currentUser
      ? currentUser.hasPermission(PermissionsNames.manageQuestionRatings)
      : false;
    this.tabs.facilities.visible = currentUser ? currentUser.hasPermission(PermissionsNames.manageFacilities) : false;
    this.tabs.companySettings.visible = currentUser ? currentUser.hasPermission(PermissionsNames.manageCompany) : false;
    this.tabs.company.visible = currentUser ? this.authenticationService.isUserSuperAdmin(currentUser) : false;
    this.tabs.reports.visible = currentUser ? currentUser.hasPermission(PermissionsNames.manageReportTemplates) : false;

    this.tabsArray = this.convertTabsToArray();
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService
      .stream([
        'ADMIN_SETTINGS.TAB.REFERENCE_DOCUMENTS',
        'ADMIN_SETTINGS.TAB.USERS',
        'ADMIN_SETTINGS.TAB.ROLES_AND_PERMISSIONS',
        'ADMIN_SETTINGS.TAB.AUDIT_TYPES',
        'ADMIN_SETTINGS.TAB.FINDING_TYPES',
        'ADMIN_SETTINGS.TAB.RATING_SCALES',
        'ADMIN_SETTINGS.TAB.FACILITIES',
        'ADMIN_SETTINGS.TAB.COMPANY_SETTINGS',
        'ADMIN_SETTINGS.TAB.CREATE_COMPANY',
        'ADMIN_SETTINGS.TAB.REPORTS'
      ])
      .subscribe(translation => {
        this.tabs.questionnaires.title = translation['ADMIN_SETTINGS.TAB.REFERENCE_DOCUMENTS'];
        this.tabs.users.title = translation['ADMIN_SETTINGS.TAB.USERS'];
        this.tabs.roles.title = translation['ADMIN_SETTINGS.TAB.ROLES_AND_PERMISSIONS'];
        this.tabs.auditTypes.title = translation['ADMIN_SETTINGS.TAB.AUDIT_TYPES'];
        this.tabs.findingTypes.title = translation['ADMIN_SETTINGS.TAB.FINDING_TYPES'];
        this.tabs.ratingScale.title = translation['ADMIN_SETTINGS.TAB.RATING_SCALES'];
        this.tabs.facilities.title = translation['ADMIN_SETTINGS.TAB.FACILITIES'];
        this.tabs.companySettings.title = translation['ADMIN_SETTINGS.TAB.COMPANY_SETTINGS'];
        this.tabs.company.title = translation['ADMIN_SETTINGS.TAB.CREATE_COMPANY'];
        this.tabs.reports.title = translation['ADMIN_SETTINGS.TAB.REPORTS'];

        this.tabsArray = this.convertTabsToArray();
      });
  }
}

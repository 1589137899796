import { Component, Input } from '@angular/core';
// Services
import { ProgressGroup, ProgressStatus } from './../../../services/progress.service';

@Component({
  selector: 'gw-progress-groups',
  templateUrl: './progress-groups.component.html',
  styleUrls: ['./progress-groups.component.scss']
})
export class ProgressGroupsComponent {
  @Input() groups: Array<ProgressGroup>;
  @Input() root = true;

  constructor() { }

  getGroupClass(status: string): Array<string> {
    switch (status) {
      case ProgressStatus.inProgress:
        return ['gw-clock', 'progress-bar__status-icon--in-progress'];
      case ProgressStatus.success:
        return ['gw-check', 'progress-bar__status-icon--success'];
      case ProgressStatus.error:
        return ['gw-close', 'progress-bar__status-icon--error'];
    }
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// Gutwin Shared Library
import { ModalService, OfflineService } from 'gutwin-shared';
// Models
import { Finding } from './../../../shared/models/finding.model';
// Services
import { PermissionService } from './../../../shared/services/permission.service';
import { UserService } from './../../../shared/services/user.service';

@Component({
  selector: 'gw-finding-box',
  templateUrl: './finding-box.component.html',
  styleUrls: ['./finding-box.component.scss'],
  exportAs: 'gwFindingBox'
})
export class FindingBoxComponent implements OnInit {
  @Input() finding: Finding;
  @Input() isAuditDisabled = false;
  @Output() removeFinding = new EventEmitter<Finding>();
  canManageFinding = true;

  constructor(
    private modalService: ModalService,
    private permissionService: PermissionService,
    private userService: UserService,
    public offlineService: OfflineService
  ) { }

  ngOnInit() {
    this.canManageFinding = this.permissionService.canFindingBeManaged(this.finding, this.userService.storedUser);
  }

  showUpdateFindingModal(finding: Finding): void {
    this.modalService.open('findingModal', { finding });
  }

  showRemoveFindingModal(finding: Finding): void {
    this.removeFinding.emit(finding);
  }
}

import { Component, Input } from '@angular/core';

import { UserProfile } from '../../models/old-api-user-profile.model';

import { LoginOldApiService } from '../../services/login-old-api.service';

@Component({
  selector: 'gw-old-api-header',
  templateUrl: './old-api-header.component.html',
  styleUrls: ['./old-api-header.component.scss']
})
export class OldApiHeaderComponent {
  @Input() user: UserProfile;

  constructor(private loginOldApiService: LoginOldApiService) {}

  isLogOutVisible(): boolean {
    return this.loginOldApiService.isLoggedIn();
  }

  logOut(): void {
    this.loginOldApiService.logOut();
  }
}

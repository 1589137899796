
<gw-lightbox id="lightboxModal" #lightboxModal="gwLightbox"></gw-lightbox>
<gw-audit-top-bar></gw-audit-top-bar>
<gw-page-header>
  <ng-container page-header-left="page-header-left">
    <ul class="header__menu header__menu--left">
      <li class="header__item"><a class="button button--link header__button--transparent header__button--menu" [routerLink]="['/findings']"><i class="button__icon gw-arrow--left"></i></a></li>
      <li class="header__item header__item--title">{{ 'FINDING_VIEW.PAGE_HEADER' | translate }}</li>
    </ul>
  </ng-container>
</gw-page-header>
<div class="row">
  <div class="columns small-12 content finding-view">
    <div class="row">
      <div class="columns small-12" #solvedFindingBox="" *ngIf="finding.status === 'solved'">
        <gw-solving [findingSolvingData]="findingSolvingData" (afterContentChecked)="solvedFindingBoxChecked()"></gw-solving>
      </div>
      <div class="columns small-12 large-8">
        <gw-finding-view-details [finding]="finding" [canManageFinding]="permissions.manageFinding" [canManageCauseAndSolution]="permissions.manageCauseAndSolution" (findingUpdated)="updateFinding($event)"></gw-finding-view-details>
      </div>
      <div class="columns small-12 medium-6 large-4">
        <gw-files-section [finding]="finding" [canManageFinding]="permissions.manageFinding"></gw-files-section>
      </div>
      <div class="columns small-12 finding-view__solve" *ngIf="finding.status !== 'solved' &amp;&amp; permissions.solveFinding">
        <button class="button--primary" type="button" *ngIf="!solveSectionOpened" (click)="toggleSolveSection()">{{ 'FINDING_VIEW.SOLVED.ACTION.SOLVE_FINDING' | translate }}</button>
        <gw-solve-finding *ngIf="solveSectionOpened" [savingSolving]="savingSolving" (solveFinding)="solveFinding($event)" (toggleSolveSection)="toggleSolveSection($event)"></gw-solve-finding>
      </div>
      <div class="columns small-12 finding-view__source">
        <div class="row">
          <div class="columns small-12 medium-6">
            <gw-finding-source [finding]="finding"></gw-finding-source>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
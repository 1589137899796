import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';

import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';

import { getErrorHandler } from '../../services/sentry-error-handler.service';

import { getApolloOptions } from './shared/utils/apollo.util';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, HttpLinkModule]
})
export class SessionModule {
  static forRoot(environment: any): ModuleWithProviders<SessionModule> {
    return {
      ngModule: SessionModule,
      providers: [
        {
          provide: 'dsn',
          useValue: environment.sentryUrl
        },
        {
          provide: 'prod',
          useValue: environment.production
        },
        { provide: ErrorHandler, useFactory: getErrorHandler, deps: ['dsn', 'prod'] },
        {
          provide: 'httpLink',
          useClass: HttpLink
        },
        {
          provide: 'apiUrl',
          useValue: `${environment.api}/graphql`
        },
        {
          provide: APOLLO_OPTIONS,
          useFactory: getApolloOptions,
          deps: ['httpLink', 'apiUrl']
        }
      ]
    };
  }
}


<div class="content findings-list">
  <div class="row">
    <div class="columns small-12 findings-list__header-buttons">
      <button class="link findings-list__header-button" type="button" [disabled]="isExportingFindings" (click)="exportFindings()">{{ 'FINDINGS_LIST.ACTION.EXPORT_AS_XLSX' | translate }}<i class="button__icon--after button__icon--spinner" *ngIf="isExportingFindings">
          <div class="spinner"></div></i></button>
      <div class="button-switch findings-list__switch findings-list__header-button">
        <button class="button--quaternary button--small button--start-case" (click)="viewStyle = 'table'" [class.button--active]="viewStyle === 'table'"><i class="gw-list button__icon--before"></i>{{ 'FINDINGS_LIST.ACTION.LIST_VIEW' | translate }}</button>
        <button class="button--quaternary button--small button--start-case" (click)="viewStyle = 'cards'" [class.button--active]="viewStyle === 'cards'"><i class="gw-calendar button__icon--before"></i>{{ 'FINDINGS_LIST.ACTION.CARD_LAYOUTS' | translate }}</button>
      </div>
    </div>
    <div class="columns small-12">
      <ng-container *ngIf="viewStyle === 'cards'">
        <gw-loader id="findingsLoader" [position]="'relative'"></gw-loader>
        <div class="blankslate" *ngIf="findings.length === 0">{{ 'FINDINGS_LIST.BLANKSLATE' | translate }}.</div>
        <gw-finding class="findings-list__card" *ngFor="let finding of findings" [finding]="finding" [withDetails]="true" [disabled]="{update: true}" [detailsLink]="true" (remove)="showRemoveFindingDialog(finding)"></gw-finding>
      </ng-container>
      <ng-container *ngIf="viewStyle === 'table'">
        <div class="flex-table findings-table">
          <div class="flex-table__row show-for-large">
            <div class="flex-table__group findings-table__col-group--1">
              <div class="flex-table__section findings-table__col-subgroup--1">
                <div class="flex-table__cell--th findings-table__col--1">{{ 'GLOBAL.TABLE_LABEL.STATUS' | translate }}</div>
                <div class="flex-table__cell--th findings-table__col--2">{{ 'GLOBAL.TABLE_LABEL.DUE_DATE' | translate }}</div>
                <div class="flex-table__cell--th findings-table__col--3">{{ 'GLOBAL.TABLE_LABEL.PROBLEM' | translate }}</div>
              </div>
              <div class="flex-table__section findings-table__col-subgroup--2">
                <div class="flex-table__cell--th findings-table__col--4">{{ 'GLOBAL.TABLE_LABEL.TYPE' | translate }}</div>
                <div class="flex-table__cell--th findings-table__col--5">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREA' | translate }}</div>
                <div class="flex-table__cell--th findings-table__col--6">{{ 'GLOBAL.TABLE_LABEL.RESPONSIBLE_PERSON' | translate }}</div>
              </div>
            </div>
            <div class="flex-table__group findings-table__col-group--2">
              <div class="flex-table__section findings-table__col-subgroup--3">
                <div class="flex-table__cell--th findings-table__col--7">&nbsp;</div>
              </div>
            </div>
          </div>
          <gw-loader id="findingsLoader"></gw-loader>
          <div class="blankslate flex-table__blankslate" *ngIf="findings.length === 0">{{ 'FINDINGS_LIST.BLANKSLATE' | translate }}.</div>
          <ng-container *ngFor="let finding of findings; even as evenFinding">
            <gw-expand-box class="findings-table__box" #findingExpandBox="gwExpandBox" [hasMask]="true" [hasShadow]="true">
              <ng-container expand-box-header="expand-box-header">
                <div class="expand-mask__content">
                  <div class="flex-table__row flex-table__row--indirect flex-table__row--independent findings-table__row" [class.flex-table__row--even]="evenFinding">
                    <div class="flex-table__group findings-table__col-group--1 findings-table__col-group--1" (click)="findingExpandBox.toggle()">
                      <div class="flex-table__section findings-table__col-subgroup--1">
                        <div class="flex-table__cell findings-table__col--1" [ngClass]="'finding__status--' + finding.status"><span class="findings-table__label">{{ 'GLOBAL.TABLE_LABEL.STATUS' | translate }}:&nbsp;</span>{{ findingStatusNames ? findingStatusNames[finding.status] : '' }}</div>
                        <div class="flex-table__cell findings-table__col--2"><span class="findings-table__label">{{ 'GLOBAL.TABLE_LABEL.DUE_DATE' | translate }}:&nbsp;</span>
                          <ng-container *ngIf="finding.deadline">{{ finding.deadline | dateFormat: 'D.MM.YYYY' }}</ng-container>
                        </div>
                        <div class="flex-table__cell findings-table__col--3"><span class="findings-table__label">{{ 'GLOBAL.TABLE_LABEL.PROBLEM' | translate }}:&nbsp;</span>{{ finding.problem }}</div>
                      </div>
                      <div class="flex-table__section findings-table__col-subgroup--2">
                        <div class="flex-table__cell findings-table__col--4"><span class="findings-table__label">{{ 'GLOBAL.TABLE_LABEL.TYPE' | translate }}:&nbsp;</span>{{ finding.relationships.findingType.name }}</div>
                        <div class="flex-table__cell findings-table__col--5"><span class="findings-table__label">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREA' | translate }}:&nbsp;</span>{{ finding.relationships.auditedArea.facility?.name }}</div>
                        <div class="flex-table__cell findings-table__col--6 flex-table__cell--avatar"><span class="findings-table__label">{{ 'GLOBAL.TABLE_LABEL.RESPONSIBLE_PERSON' | translate }}:&nbsp;</span>
                          <ng-container *ngIf="finding.relationships.employee">
                            <gw-avatar class="findings-table__avatar" [employee]="finding.relationships.employee"></gw-avatar><span class="text--nowrap">{{ finding.relationships.employee.lastname }}, {{ finding.relationships.employee.name }}</span>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <div class="flex-table__group findings-table__col-group--1 findings-table__col-group--2">
                      <div class="flex-table__section findings-table__col-subgroup--3">
                        <div class="flex-table__cell findings-table__col--7 flex-table__options">
                          <div class="flex-table__options-content">
                            <button class="flex-table__option flex-table__option--slide" type="button" (click)="findingExpandBox.toggle()"><i class="gw-angle--down expand-mask__toggle-icon"></i></button><span class="dropdown dropdown--menu flex-table__option flex-table__option--dropdown" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
                              <button class="flex-table__option flex-table__option--more" type="button"><i class="gw-more"></i></button>
                              <div class="dropdown__content jsDropdownContent">
                                <ul class="dropdown-menu__list">
                                  <li class="dropdown-menu__item">
                                    <button class="link dropdown-menu__link" type="button" (click)="showRemoveFindingDialog(finding)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                                  </li>
                                </ul>
                              </div></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container expand-box-content="expand-box-content">
                <gw-finding-details [finding]="finding" [isEven]="evenFinding"></gw-finding-details>
              </ng-container>
            </gw-expand-box>
          </ng-container>
        </div>
      </ng-container>
      <gw-pagination class="findings-list__pagination" *ngIf="findings &amp;&amp; findings.length &gt; 0" [totalElementsAmount]="totalFindings" [currentPage]="filterService?.page" [currentLimit]="filterService?.limit" (changePage)="changePage($event)" (changeLimit)="changeLimit($event)"></gw-pagination>
    </div>
  </div>
</div>
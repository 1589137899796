import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

// Models
import { Audit } from '@gutwin-audit/shared/models/audit.model';
import { Template } from '@gutwin-audit/shared/models/template.model';

// Services
import { TemplateService } from '@gutwin-audit/shared/services/template.service';

@Component({
  selector: 'gw-add-template',
  templateUrl: './add-template.component.html',
  exportAs: 'gwAddTemplate'
})
export class AddTemplateComponent implements OnInit {
  @Input() audit: Audit;
  @Input() template: Template;
  templateForm: FormGroup;
  submitted = false;
  notify = {
    error: {
      connectionTitle: '',
      markAsTemplate: '',
      updateTemplate: ''
    },
    success: {
      title: '',
      markAsTemplate: '',
      updateTemplate: ''
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private templateService: TemplateService,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.fetchTranslation();
    this.initForm();
    this.resetForm();
  }

  initForm(): void {
    this.templateForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
  }

  resetForm(): void {
    this.setControlValue(this.template ? this.template.name : '', 'name');
  }

  setControlValue(data: any, key: string): void {
    this.templateForm.controls[key].setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return (!field || !field.valid) && this.submitted;
  }

  createTemplate(): Promise<void> {
    const templateToCreate = this.templateForm.value;
    templateToCreate.auditId = this.audit.id;
    return this.templateService.createTemplate(templateToCreate)
      .then(() => {
        this.notificationsService.success(this.notify.success.title, this.notify.success.markAsTemplate);
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.markAsTemplate);
        throw error;
      });
  }

  updateTemplate(): Promise<void> {
    const templateToUpdate = this.templateForm.value;
    templateToUpdate.auditId = this.template.audit.id;
    return this.templateService.updateTemplate(templateToUpdate)
      .then(() => {
        this.notificationsService.success(this.notify.success.title, this.notify.success.updateTemplate);
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.updateTemplate);
        throw error;
      });
  }

  submitAddTemplateForm(): Promise<void> {
    this.submitted = true;
    if (this.templateForm.valid) {
        return this.template ? this.updateTemplate() : this.createTemplate()
          .then(() => {
            this.submitted = false;
            this.resetForm();
          })
          .catch(error => Promise.reject(error));
    } else {
      return Promise.reject({ invalid: true });
    }
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ACTION.SUCCESS',
      'AUDITS_LIST.ERROR.MARK_AS_TEMPLATE',
      'TEMPLATES_LIST.ERROR.UPDATE_TEMPLATE',
      'AUDITS_LIST.SUCCESS.MARK_AS_TEMPLATE',
      'TEMPLATES_LIST.SUCCESS.UPDATE_TEMPLATE'
    ]).toPromise();
    this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.notify.error.markAsTemplate = translation['AUDITS_LIST.ERROR.MARK_AS_TEMPLATE'];
    this.notify.error.updateTemplate = translation['TEMPLATES_LIST.ERROR.UPDATE_TEMPLATE'];
    this.notify.success.title = translation['GLOBAL.ACTION.SUCCESS'];
    this.notify.success.markAsTemplate = translation['AUDITS_LIST.SUCCESS.MARK_AS_TEMPLATE'];
    this.notify.success.updateTemplate = translation['TEMPLATES_LIST.SUCCESS.UPDATE_TEMPLATE'];
  }
}

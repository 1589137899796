import { AuditType } from '@gutwin-audit/shared/models/audit-type.model';
import { Rating } from '@gutwin-audit/shared/models/rating.model';

export class RatingScale {
  id: string;
  name: string;
  default: boolean;
  usesNumericAverages: boolean;
  usesPercentageAverages: boolean;
  ratings: Array<Rating>;
  auditTypes: Array<AuditType>;

  constructor(scale: Partial<RatingScale>) {
    this.id = scale.id;
    this.name = scale.name;
    this.default = scale.default;
    this.usesNumericAverages = scale.usesNumericAverages;
    this.usesPercentageAverages = scale.usesPercentageAverages;
    this.ratings = this.generateRatings(scale.ratings);
    this.auditTypes = this.generateAuditTypes(scale.auditTypes);
  }

  private generateRatings(ratingsRequest: Array<Rating>): Array<Rating> {
    const ratings = new Array<Rating>();
    if (ratingsRequest) {
      ratingsRequest.forEach(rating => {
        ratings.push(new Rating(rating));
      });
    }
    return ratings;
  }

  private generateAuditTypes(auditTypesRequest: Array<AuditType>): Array<AuditType> {
    const auditTypes = new Array<AuditType>();
    if (auditTypesRequest) {
      auditTypesRequest.forEach(auditType => {
        auditTypes.push(new AuditType(auditType));
      });
    }
    return auditTypes;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { find, findIndex } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EmployeeExtended } from '../../models/employee-extended.model';
import { PermissionCategory } from '../../models/permission-category.model';
import { Permission } from '../../models/permission.model';
import { Role } from '../../models/role.model';

import { PermissionCategoryModalData } from '../../interfaces/permission-category-modal.interface';
import { UserRolesModalResponse } from '../../interfaces/user-roles-modal.interface';

import { PermissionCategoryModalComponent } from '../permission-category-modal/permission-category-modal.component';

import { OverlayService } from '../../services/overlay.service';

@Component({
  selector: 'gw-user-roles-form',
  templateUrl: './user-roles-form.component.html',
  styleUrls: ['./user-roles-form.component.scss'],
  exportAs: 'gwUserRolesForm',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRolesFormComponent implements OnInit, OnDestroy {
  @Input() employee: EmployeeExtended;
  @Input() roles: Array<Role>;
  @Input() permissionCategories: Array<PermissionCategory>;
  @Input() selectedRoles: Array<Role>;
  @Input() standalone = true;
  @Input() showMultiselect?: boolean;

  @Output() hideModal = new EventEmitter<void>();
  @Output() showModal = new EventEmitter<void>();

  currentPermissionCategories: Array<PermissionCategory>;
  rolesNotAdded: Array<Role>;
  rolesToShow: Array<Role>;
  rolesForm: FormGroup;
  chosenRole: Role;
  chosenRoles: Array<Role>;
  changed = false;
  formFlags = {
    inEditMode: false,
    isChoosing: false
  };
  destroy$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private overlayService: OverlayService) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
    this.initRolesToShow();
    this.initFormFlags();
    this.updateView();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initForm(): void {
    this.rolesForm = this.formBuilder.group({
      roles: [[]]
    });
  }

  resetForm(): void {
    this.rolesForm.reset({
      roles: this.copyRoles(this.selectedRoles)
    });
  }

  getControlValue(key: string): any {
    return this.rolesForm.controls[key].value;
  }

  setControlValue(data: any, key: string): void {
    this.rolesForm.controls[key].setValue(data);
  }

  copyRoles(roles: Array<Role>): Array<Role> {
    if (roles) {
      return roles.map(role => new Role(role));
    }
    return [];
  }

  concatRoles(...roles: Array<Role>): Array<Role> {
    const concatRoles = new Array<Role>();
    if (roles) {
      roles.forEach(role => {
        const existingRole = find(concatRoles, { id: role.id });
        if (!existingRole) {
          concatRoles.push(role);
        }
      });
    }
    return concatRoles;
  }

  updateView(): void {
    this.markRolesActions();
    this.filterRolesNotAdded();
    this.initPermissionCategories();
    this.markPermissionsActions();
  }

  initPermissionCategories(): void {
    this.currentPermissionCategories = this.permissionCategories.map(permissionCategory => {
      return new PermissionCategory({
        ...permissionCategory,
        permissions: permissionCategory.permissions.map(permission => {
          const rolesIds = this.rolesToShow
            .filter(selectedRole => permission.rolesIds.find(roleId => roleId === selectedRole.id))
            .map(role => role.id);
          return new Permission({
            ...permission,
            added: !!rolesIds.length,
            rolesIds
          });
        })
      });
    });
  }

  markPermissionsActions(): void {
    const generatePermissionAction = (permission: Permission): { add: boolean; remove: boolean } => {
      const wasPermissionSelected = permission.rolesIds
        .map(roleId => !!find(this.employee.roles, { id: roleId }))
        .reduce((previousValue: boolean, currentValue: boolean) => previousValue || currentValue, false);

      const isPermissionSelectedNow = permission.rolesIds
        .map(roleId => !!find(this.getControlValue('roles'), { id: roleId }))
        .reduce((previousValue: boolean, currentValue: boolean) => previousValue || currentValue, false);

      return {
        add: !wasPermissionSelected && isPermissionSelectedNow,
        remove: wasPermissionSelected && !isPermissionSelectedNow
      };
    };

    this.currentPermissionCategories.forEach(permissionCategory => {
      if (permissionCategory.permissions) {
        permissionCategory.permissions = permissionCategory.permissions.map(permission => {
          permission.action = generatePermissionAction(permission);
          return permission;
        });
      }
    });
  }

  markRolesActions(): void {
    const generateRoleAction = (role: Role): { add: boolean; remove: boolean } => {
      const wasRoleSelected = !!find(this.employee.roles, { id: role.id });
      const isRoleSelectedNow = !!find(this.getControlValue('roles'), { id: role.id });

      return {
        add: !wasRoleSelected && isRoleSelectedNow,
        remove: wasRoleSelected && !isRoleSelectedNow
      };
    };

    this.rolesToShow.forEach(role => {
      role.action = generateRoleAction(role);
    });
  }

  initRolesToShow(): void {
    this.rolesToShow = this.copyRoles(this.concatRoles(...this.employee.roles, ...this.selectedRoles));
  }

  filterRolesNotAdded(): void {
    this.rolesNotAdded = this.roles ? this.roles.filter(role => !find(this.rolesToShow, { id: role.id })) : [];
  }

  initFormFlags(): void {
    this.formFlags.inEditMode = !this.rolesToShow.length;
    this.formFlags.isChoosing = false;
  }

  showRolesForm(): void {
    this.formFlags.isChoosing = true;
  }

  showAddRoleContainer(): void {
    this.formFlags.inEditMode = true;
  }

  appendRoles(roles: Array<Role>): void {
    const newRoles = [];
    const newRolesToShow = [];
    const addedRoles: Array<Role> = this.getControlValue('roles');
    roles.forEach(role => {
      if (!find(addedRoles, role)) newRoles.push(role);
      if (!find(this.rolesToShow, role)) newRolesToShow.push(role);
    });

    this.setControlValue([...addedRoles, ...newRoles], 'roles');
    this.rolesToShow = [...this.rolesToShow, ...newRolesToShow];
  }

  spliceRole(role: Role): void {
    const roles: Array<Role> = this.getControlValue('roles');
    const index = findIndex(roles, { id: role.id });
    if (~index) {
      roles.splice(index, 1);
      this.setControlValue(roles, 'roles');
    }

    const isSaved = !!find(this.employee.roles, { id: role.id });
    if (!isSaved) {
      const indexToShow = findIndex(this.rolesToShow, { id: role.id });
      if (~indexToShow) {
        this.rolesToShow.splice(indexToShow, 1);
      }
    }
  }

  addRolesToUser({ roles }: { roles: Array<Role> }): void {
    this.appendRoles(roles);
    this.updateView();
    this.changed = true;
  }

  removeRoleFromUser(role: Role): void {
    this.spliceRole(role);
    this.updateView();
    this.changed = true;
  }

  async submitRolesForm(): Promise<UserRolesModalResponse> {
    return this.rolesForm.value;
  }

  showPermissionCategoryModal(permissionCategory: PermissionCategory): void {
    this.hideModal.emit();
    this.overlayService
      .open<PermissionCategory, PermissionCategoryModalData>(
        PermissionCategoryModalComponent,
        {
          permissionCategory,
          roles: this.rolesToShow,
          withRoles: true
        },
        {
          small: true
        }
      )
      .afterClosed$.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.showModal.emit();
      });
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gw-findings-in-progress',
  templateUrl: './findings-in-progress.component.html',
  styleUrls: ['./findings-in-progress.component.scss']
})
export class FindingsInProgressComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}

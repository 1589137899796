import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertLinksInText'
})
export class ConvertLinksInTextPipe implements PipeTransform {
  transform(value: string): string {
    let regex = new RegExp('<\s*a[^>]*(>.*?)<\s*/\s*a>', 'g');  
    let link;
  
    while((link = regex.exec(value)) !== null) {
      value = value.replace(link[1], ` target="_blank" rel="noopener"${link[1]}`);
    }
  
    return value;
  }
}


<div class="progress-bar" *ngIf="total" [class.progress-bar--big]="big" [class.progress-bar--green]="status === 'in_progress' || status === 'success'" [class.progress-bar--red]="status === 'error'">
  <div class="progress-bar__bar" [class.progress-bar__bar--value-outside]="showPercents &amp;&amp; !big" [class.progress-bar__bar--status]="groups?.length">
    <div class="progress-bar__progress" [style.width]="percents + '%'"></div>
    <div class="progress-bar__label">
      <ng-container *ngIf="label">{{ label }}</ng-container>
      <ng-container *ngIf="!label">
        <ng-container *ngIf="status === 'in_progress'">{{ 'GLOBAL.ACTION.ACTIONS_IN_PROGRESS' | translate }}</ng-container>
        <ng-container *ngIf="status === 'success'">{{ 'GLOBAL.ACTION.SUCCESS' | translate }}</ng-container>
        <ng-container *ngIf="status === 'error'">{{ 'GLOBAL.ACTION.ERROR' | translate }}</ng-container>
      </ng-container>
    </div>
    <div class="progress-bar__value" *ngIf="showPercents &amp;&amp; big">{{ percents | number: '1.0-0' }}%</div>
  </div>
  <div class="progress-bar__value" *ngIf="showPercents &amp;&amp; !big">{{ percents | number: '1.0-0' }}%</div>
  <div class="progress-bar__icon progress-bar__status" *ngIf="groups?.length"><span class="dropdown progress-bar__status-dropdown" gwSimpleDropdown="gwSimpleDropdown" #dropdownList="simpleDropdown" [class.dropdown--opened]="dropdownList.opened" [class.dropdown--closed]="!dropdownList.opened &amp;&amp; !dropdownList.firstDrop">
      <button class="progress-bar__icon-button" type="button"><i class="gw-info"></i></button>
      <div class="dropdown__content jsDropdownContent progress-bar__status-dropdown-content">
        <button class="button--small button--primary progress-bar__status-sync-button" *ngIf="status === 'error'" type="button" (click)="onSyncGroups()"><i class="gw-cloud--sync"></i></button>
        <gw-progress-groups [groups]="groups"></gw-progress-groups>
      </div></span></div>
  <div class="progress-bar__icon progress-bar__close" *ngIf="close">
    <button class="progress-bar__icon-button" type="button" [disabled]="status === 'in_progress'" (click)="onCloseProgressBar()"><i class="gw-close"></i></button>
  </div>
</div>
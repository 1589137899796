import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gw-findings-overdue',
  templateUrl: './findings-overdue.component.html',
  styleUrls: ['./findings-overdue.component.scss']
})
export class FindingsOverdueComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';

// Gutwin Shared Library
import { Employee } from 'gutwin-shared';

import { AuditType } from '../../shared/models/audit-type.model';
// Models
import { Audit } from '../../shared/models/audit.model';
import { FacilityFilter } from '../../shared/models/facility-filter.model';

import { AuditTypeService } from '../../shared/services/audit-type.service';
import { AuditService } from '../../shared/services/audit.service';
import { FacilityService } from '../../shared/services/facility.service';
// Services
import { StorageModuleService, StoragesNamesModule } from '../../shared/services/storage-module.service';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'gw-audit-creation',
  templateUrl: './audit-creation.component.html',
  styleUrls: ['./audit-creation.component.scss']
})
export class AuditCreationComponent implements OnInit, OnDestroy {
  audit: Audit;
  auditSource = new Subject<Audit>();
  auditObservable = this.auditSource.asObservable();
  storedAudit: any;
  storage = StoragesNamesModule.auditCreation;
  auditTypes: Array<AuditType>;
  employees = Array<Employee>();
  facilities = Array<FacilityFilter>();
  header: string;
  translateSubscription: Subscription;
  notify = {
    error: {
      connection: '',
      loadAudit: '',
      loadAuditTypes: '',
      loadEmployees: '',
      loadFacilities: ''
    }
  };

  constructor(
    private storageService: StorageModuleService,
    private auditService: AuditService,
    private auditTypeService: AuditTypeService,
    private userService: UserService,
    private facilityService: FacilityService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.fetchTranslation();
    this.fetchStorage();
    this.initAudit();
    this.getAuditTypes().then(auditTypes => {
      this.auditTypes = auditTypes;
    });
    this.getEmployees().then(employees => {
      this.employees = employees;
      this.appendAuditEmployees();
    });
  }

  ngOnDestroy() {
    this.storageService.removeFromStorage(this.storage);
    this.translateSubscription.unsubscribe();
  }

  initAudit(): void {
    if (this.route.snapshot.data['audit']) {
      this.updateAudit(this.route.snapshot.data['audit']);
    } else if (this.storedAudit.auditId) {
      this.getAudit(this.storedAudit.auditId).then(audit => {
        this.updateAudit(audit);
      });
    }
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'AUDIT_CREATION.ERROR.LOAD_AUDIT',
      'AUDIT_CREATION.ERROR.LOAD_AUDIT_TYPES',
      'AUDIT_CREATION.ERROR.LOAD_EMPLOYEES',
      'AUDIT_CREATION.ERROR.LOAD_FACILITIES',
      'AUDIT_CREATION.PAGE_HEADER.CREATE',
      'AUDIT_CREATION.PAGE_HEADER.EDIT'
    ])
    .subscribe((translation: any) => {
      this.notify.error.connection = translation['GLOBAL.ERROR.CONNECTION'];
      this.notify.error.loadAudit = translation['AUDIT_CREATION.ERROR.LOAD_AUDIT'];
      this.notify.error.loadAuditTypes = translation['AUDIT_CREATION.ERROR.LOAD_AUDIT_TYPES'];
      this.notify.error.loadEmployees = translation['AUDIT_CREATION.ERROR.LOAD_EMPLOYEES'];
      this.notify.error.loadFacilities = translation['AUDIT_CREATION.ERROR.LOAD_FACILITIES'];

      let headerTranslationId = 'AUDIT_CREATION.PAGE_HEADER';
      headerTranslationId += this.route.snapshot.params['auditId'] ? '.EDIT' : '.CREATE';
      this.header = translation[headerTranslationId];
    });
  }

  fetchStorage(): void {
    this.storedAudit = this.storageService.getStorage(this.storage);
    if (!this.storedAudit) {
      this.storedAudit = {
        'auditId': this.route.snapshot.params.auditId,
        'currentStep': 0,
        'stepsData': [
          {
            'id': 0,
            'valid': false
          },
          {
            'id': 1,
            'formData': {},
            'valid': false
          },
          {
            'id': 2,
            'valid': false
          },
          {
            'id': 3,
            'valid': false
          }
        ]
      };
    }
  }

  initStep2(): void {
    if (!(this.facilities && this.facilities.length > 0)) {
      this.getFacilities().then(facilities => {
        this.facilities = facilities;
      });
    }
  }

  getAudit(auditId: string): Promise<Audit> {
    return this.auditService.getAudit(auditId)
      .then(audit => {
        return audit;
      })
      .catch(error => {
        if (error.status === 404) {
          this.storedAudit.auditId = undefined;
        }
        this.notificationsService.error(this.notify.error.connection, this.notify.error.loadAudit);
        throw error;
      });
  }

  getAuditTypes(): Promise<Array<AuditType>> {
    return this.auditTypeService.getAuditTypes()
    .then(auditTypes => {
      return auditTypes;
    })
    .catch(error => {
      this.notificationsService.error(this.notify.error.connection, this.notify.error.loadAuditTypes);
      throw error;
    });
  }

  getEmployees(): Promise<Array<Employee>> {
    return this.userService.getEmployees()
    .then(employees => {
      return employees;
    })
    .catch(error => {
      this.notificationsService.error(this.notify.error.connection, this.notify.error.loadEmployees);
      throw error;
    });
  }

  getFacilities(): Promise<Array<FacilityFilter>> {
    return this.facilityService.getFacilitiesForFilter()
    .then(facilities => {
      return facilities;
    })
    .catch(error => {
      this.notificationsService.error(this.notify.error.connection, this.notify.error.loadFacilities);
      throw error;
    });
  }

  appendAuditEmployees(): void {
    if (this.audit && this.audit.employees) {
      for (const groupName in this.audit.employees) {
        if (this.audit.employees.hasOwnProperty(groupName)) {
          this.audit.employees[groupName].forEach(employee => {
            const index = _.findIndex(this.employees, {id: employee.id});
            if (index === -1) {
              this.employees.push(employee);
            }
          });
        }
      }
      this.sortEmployees();
    }
  }

  sortEmployees(): void {
    this.employees = _.sortBy(this.employees, (employee: Employee) => {
      return [employee.lastname, employee.name];
    });
  }

  checkStepsValidation(): void {
    this.storedAudit.stepsData[0].valid = this.isStep1Valid();
    this.storedAudit.stepsData[1].valid = this.isStep2Valid();
    this.storedAudit.stepsData[2].valid = this.isStep1Valid() && this.isStep2Valid(); // TODO: Validation for step3
    this.storedAudit.stepsData[3].valid = this.isStep1Valid() && this.isStep2Valid(); // TODO: Validation for step4
  }

  isStep1Valid(): boolean {
    return this.audit && this.audit.name && this.audit.type
      && this.audit.employees && this.audit.employees.auditor && this.audit.employees.auditor.length > 0
      && this.audit.employees.organizer && this.audit.employees.organizer.length > 0;
  }

  isStep2Valid(): boolean {
    return this.audit && this.audit.auditedAreas && this.audit.auditedAreas.length > 0;
  }

  updateAudit(audit: Audit): void {
    this.audit = audit;
    this.auditSource.next(this.audit);
    if (this.audit) {
      this.storedAudit.auditId = this.audit.id;
      this.updateStorage();
    }
  }

  updateStorage(step?: any): void {
    if (step) {
      this.storedAudit.stepsData[step.stepId].formData = step.stepData;
      this.storedAudit.stepsData[step.stepId].valid = step.stepValid;
    }
    this.checkStepsValidation();
    if (!this.storedAudit.currentStep) {
      this.storedAudit.currentStep = 0;
    }
    this.storageService.setStorage(this.storage, this.storedAudit);
  }

  submitStep(step: any): void {
    this.goToStep(step);
  }

  goToStep(step: any): void {
    if (step.goToStepId < 4) {
      this.storedAudit.currentStep = step.goToStepId;
      this.updateStorage();
    } else {
      this.goToListView();
    }
  }

  getBackNavigation(): Array<any> {
    let state = '';
    if (this.audit) {
      switch (this.audit.state) {
        case 'draft':
          state = 'drafts';
          break;
        case 'in_progress':
          state = 'in-progress';
          break;
        case 'finished':
          state = 'finished';
          break;
      }
    }
    return [`/audits/${state}`];
  }

  goToListView(): void {
    this.router.navigate(this.getBackNavigation());
  }
}

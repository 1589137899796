import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { DialogOverlayRef } from '../overlay/dialog-overlay-ref';

import { PermissionCategory } from '../../models/permission-category.model';

import { PermissionCategoryModalData } from '../../interfaces/permission-category-modal.interface';

import { PermissionCategoryComponent } from '../permission-category/permission-category.component';

@Component({
  selector: 'gw-permission-category-modal',
  templateUrl: './permission-category-modal.component.html',
  styleUrls: ['./permission-category-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionCategoryModalComponent implements OnInit {
  @ViewChild('permissionCategoryComponent', { static: true }) permissionCategoryComponent: PermissionCategoryComponent;
  permissionCategory: PermissionCategory;

  constructor(
    private changeDetector: ChangeDetectorRef,
    public dialogOverlayRef: DialogOverlayRef<PermissionCategory, PermissionCategoryModalData>
  ) {}

  ngOnInit(): void {
    this.initPermissionCategory();
  }

  initPermissionCategory(): void {
    this.permissionCategory = this.dialogOverlayRef.data.permissionCategory;
  }

  submitPermissionCategoryForm(): void {
    if (this.dialogOverlayRef.data.withForm) {
      const response = this.permissionCategoryComponent.submitPermissionsForm();
      this.close(response);
    } else {
      this.close();
    }
  }

  onSubmitPermissionCategory(permissionCategory: PermissionCategory): void {
    this.permissionCategory = permissionCategory;
    this.changeDetector.detectChanges();
  }

  close(response?: PermissionCategory): void {
    this.dialogOverlayRef.close(response);
  }
}

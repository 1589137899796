import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as d3 from 'd3';

// Gutwin Shared Library
import { DialogService, OfflineService } from 'gutwin-shared';

// Models
import { Audit, ModifiedReport } from '../../../shared/models/audit.model';
import { FindingsStats } from '../../../shared/models/findings-stats.model';
import { QuestionsStats } from '../../../shared/models/questions-stats.model';

import { AuditSummaryService } from '../../../shared/services/audit-summary.service';
// Services
import { AuditService } from '../../../shared/services/audit.service';
import { ChartService } from '../../../shared/services/chart.service';

@Component({
  selector: 'gw-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements OnInit {
  @ViewChild('circleChartReport') circleChartReport: ElementRef;
  @ViewChild('barChartReport') barChartReport: ElementRef;
  @Input() audit: Audit;
  exportReportForm: FormGroup;
  exportingReport = false;
  uploadingReport = false;
  submittedExportReportForm: boolean;
  translation = {
    removeModifiedReportDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    cancelSyncDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    charts: {
      headerFindings: '',
      headerQuestions: '',
      totalQuestions: ''
    },
    notify: {
      error: {
        connectionTitle: '',
        generateReport: '',
        uploadModifiedReport: '',
        removeModifiedReport: '',
        generateQuestionsChartTitle: '',
        generateQuestionsChartText: '',
        generateFindingsChartTitle: '',
        generateFindingsChartText: '',
        loadQuestionsStats: '',
        loadFindingsStats: ''
      },
      success: {
        uploadModifiedReportTitle: '',
        uploadModifiedReportText: '',
        removeModifiedReportTitle: '',
        removeModifiedReportText: ''
      }
    }
  };

  constructor(
    private auditService: AuditService,
    private auditSummaryService: AuditSummaryService,
    private chartService: ChartService,
    private dialogService: DialogService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    public offlineService: OfflineService
  ) {}

  ngOnInit(): void {
    this.initReportForm();
    this.fetchTranslation();
  }

  getQuestionsStats(audit: Audit, withRequests?: boolean): Promise<QuestionsStats> {
    return this.auditSummaryService.getQuestionsStats(audit, withRequests).catch(() => {
      this.notificationsService.error(
        this.translation.notify.error.connectionTitle,
        this.translation.notify.error.loadQuestionsStats
      );
      return undefined;
    });
  }

  getFindingsStats(audit: Audit, withRequests?: boolean): Promise<FindingsStats> {
    return this.auditSummaryService.getFindingsStats(audit, withRequests).catch(() => {
      this.notificationsService.error(
        this.translation.notify.error.connectionTitle,
        this.translation.notify.error.loadFindingsStats
      );
      return undefined;
    });
  }

  initReportForm(): void {
    this.exportReportForm = this.formBuilder.group({
      company: '',
      facility: '',
      questions: false,
      pictures: true
    });

    this.submittedExportReportForm = false;
  }

  isReportFieldInvalid(field): boolean {
    return !field.valid && this.submittedExportReportForm;
  }

  chartsToImages(): Promise<any> {
    const promises = new Array<Promise<any>>();
    const charts = {
      questions: undefined,
      findings: undefined
    };

    promises.push(
      this.getQuestionsStats(this.audit)
        .then(async (questionsStats: QuestionsStats) => {
          if (questionsStats && questionsStats.ratings.length > 0) {
            const circleChartElement = d3.select(this.circleChartReport.nativeElement);
            const chartTranslations = {
              header: this.translation.charts.headerQuestions,
              total: this.translation.charts.totalQuestions
            };

            charts.questions = this.chartService.generateWholeCircleChart(
              circleChartElement,
              questionsStats.ratings,
              questionsStats.totalQuestions,
              chartTranslations
            );
          }
          return charts;
        })
        .catch(error => {
          this.notificationsService.error(
            this.translation.notify.error.generateQuestionsChartTitle,
            this.translation.notify.error.generateQuestionsChartText
          );
          throw error;
        })
    );

    promises.push(
      this.getFindingsStats(this.audit)
        .then((findingsStats: FindingsStats) => {
          if (findingsStats.findingTypes && findingsStats.findingTypes.length > 0) {
            const barChartElement = d3.select(this.barChartReport.nativeElement);

            charts.findings = this.chartService.generateWholeBarChart(
              barChartElement,
              findingsStats.findingTypes,
              findingsStats.totalFindings,
              { header: this.translation.charts.headerFindings }
            );
          }
          return charts;
        })
        .catch(error => {
          this.notificationsService.error(
            this.translation.notify.error.generateFindingsChartTitle,
            this.translation.notify.error.generateFindingsChartText
          );
          throw error;
        })
    );

    return Promise.all(promises).then(() => {
      return charts;
    });
  }

  async submitExportReportForm(form: FormGroup): Promise<void> {
    this.submittedExportReportForm = true;
    if (form.valid) {
      const hasAuditDataToSync = await this.hasAuditDataToSync();
      if (hasAuditDataToSync) {
        this.showCancelSyncModal()
          .then(() => {
            this.exportReport(form);
          })
          .catch(() => {});
      } else {
        this.exportReport(form);
      }
    }
  }

  exportReport(form: FormGroup): void {
    this.exportingReport = true;
    const report = {
      auditId: this.audit.id,
      company: form.value.company,
      facility: form.value.facility,
      charts: undefined
    };

    this.chartsToImages()
      .then(charts => {
        report.charts = charts;
        this.auditSummaryService
          .generateReport(report)
          .then(reportFile => {
            this.auditSummaryService.downloadReport(reportFile, this.audit.name);
            this.exportingReport = false;
          })
          .catch(error => {
            this.notificationsService.error(
              this.translation.notify.error.connectionTitle,
              this.translation.notify.error.generateReport
            );
            this.exportingReport = false;
          });
      })
      .catch(() => {
        this.exportingReport = false;
      });
  }

  uploadModifiedReport(event: any): void {
    const fileList: FileList = event.target.files;
    const file = fileList[0];

    if (file) {
      this.audit.modifiedReport = new ModifiedReport({ file: file });
      this.audit.modifiedReport.name = file.name;

      this.updateAuditModifiedReport(this.audit);
    }
  }

  removeModifiedReport(): void {
    this.audit.modifiedReport.destroy = true;
    this.updateAuditModifiedReport(this.audit);
  }

  showRemoveModifiedReportModal(): void {
    this.dialogService
      .confirm(
        this.translation.removeModifiedReportDialog.header,
        this.translation.removeModifiedReportDialog.content,
        this.translation.removeModifiedReportDialog.cancel,
        this.translation.removeModifiedReportDialog.confirm
      )
      .then(() => this.removeModifiedReport())
      .catch(() => {});
  }

  updateAuditModifiedReport(audit: Audit): void {
    this.uploadingReport = true;
    this.auditService
      .updateAuditModifiedReport(audit.id, audit.modifiedReport)
      .then((modifiedReport: ModifiedReport) => {
        if (audit.modifiedReport.destroy) {
          this.notificationsService.success(
            this.translation.notify.success.removeModifiedReportTitle,
            this.translation.notify.success.removeModifiedReportText
          );
        } else {
          this.notificationsService.success(
            this.translation.notify.success.uploadModifiedReportTitle,
            this.translation.notify.success.uploadModifiedReportText
          );
        }
        this.audit.modifiedReport = modifiedReport;
        this.uploadingReport = false;
      })
      .catch(error => {
        if (audit.modifiedReport.destroy) {
          this.notificationsService.error(
            this.translation.notify.error.connectionTitle,
            this.translation.notify.error.removeModifiedReport
          );
        } else {
          this.notificationsService.error(
            this.translation.notify.error.connectionTitle,
            this.translation.notify.error.uploadModifiedReport
          );
        }
        this.uploadingReport = false;
      });
  }

  hasAuditDataToSync(): Promise<boolean> {
    return this.auditService.hasAuditRequestsOffline(this.audit);
  }

  showCancelSyncModal(): Promise<void> {
    return this.dialogService.confirm(
      this.translation.cancelSyncDialog.header,
      this.translation.cancelSyncDialog.content,
      this.translation.cancelSyncDialog.cancel,
      this.translation.cancelSyncDialog.confirm
    );
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'AUDIT_SUMMARY.ERROR.GENERATE_REPORT',
        'AUDIT_SUMMARY.ERROR.GENERATE_QUESTIONS_CHART_TITLE',
        'AUDIT_SUMMARY.ERROR.GENERATE_QUESTIONS_CHART_TEXT',
        'AUDIT_SUMMARY.ERROR.GENERATE_FINDINGS_CHART_TITLE',
        'AUDIT_SUMMARY.ERROR.GENERATE_FINDINGS_CHART_TEXT',
        'AUDIT_SUMMARY.ERROR.UPLOAD_MODIFIED_REPORT',
        'AUDIT_SUMMARY.ERROR.REMOVE_MODIFIED_REPORT',
        'AUDIT_SUMMARY.ERROR.LOAD_QUESTIONS_STATS',
        'AUDIT_SUMMARY.ERROR.LOAD_FINDINGS_STATS',
        'AUDIT_SUMMARY.SUCCESS.UPLOAD_MODIFIED_REPORT_TITLE',
        'AUDIT_SUMMARY.SUCCESS.UPLOAD_MODIFIED_REPORT_TEXT',
        'AUDIT_SUMMARY.SUCCESS.REMOVE_MODIFIED_REPORT_TITLE',
        'AUDIT_SUMMARY.SUCCESS.REMOVE_MODIFIED_REPORT_TEXT',
        'AUDIT_SUMMARY.REMOVE_MODIFIED_REPORT_DIALOG.HEADER',
        'AUDIT_SUMMARY.REMOVE_MODIFIED_REPORT_DIALOG.CONTENT',
        'AUDIT_SUMMARY.SYNC_DIALOG.HEADER',
        'AUDIT_SUMMARY.SYNC_DIALOG.CONTENT',
        'AUDIT_SUMMARY.SYNC_DIALOG.CANCEL',
        'AUDIT_SUMMARY.SYNC_DIALOG.CONFIRM',
        'AUDIT_SUMMARY.SUBHEADER.FINDINGS',
        'AUDIT_SUMMARY.SUBHEADER.QUESTIONS',
        'AUDIT_SUMMARY.TOTAL_QUESTIONS'
      ])
      .toPromise();
    this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.generateReport = translation['AUDIT_SUMMARY.ERROR.GENERATE_REPORT'];
    this.translation.notify.error.generateQuestionsChartTitle =
      translation['AUDIT_SUMMARY.ERROR.GENERATE_QUESTIONS_CHART_TITLE'];
    this.translation.notify.error.generateQuestionsChartText =
      translation['AUDIT_SUMMARY.ERROR.GENERATE_QUESTIONS_CHART_TEXT'];
    this.translation.notify.error.generateFindingsChartTitle =
      translation['AUDIT_SUMMARY.ERROR.GENERATE_FINDINGS_CHART_TITLE'];
    this.translation.notify.error.generateFindingsChartText =
      translation['AUDIT_SUMMARY.ERROR.GENERATE_FINDINGS_CHART_TEXT'];
    this.translation.notify.error.uploadModifiedReport = translation['AUDIT_SUMMARY.ERROR.UPLOAD_MODIFIED_REPORT'];
    this.translation.notify.error.removeModifiedReport = translation['AUDIT_SUMMARY.ERROR.REMOVE_MODIFIED_REPORT'];
    this.translation.notify.error.loadQuestionsStats = translation['AUDIT_SUMMARY.ERROR.LOAD_QUESTIONS_STATS'];
    this.translation.notify.error.loadFindingsStats = translation['AUDIT_SUMMARY.ERROR.LOAD_FINDINGS_STATS'];
    this.translation.notify.success.uploadModifiedReportTitle =
      translation['AUDIT_SUMMARY.SUCCESS.UPLOAD_MODIFIED_REPORT_TITLE'];
    this.translation.notify.success.uploadModifiedReportText =
      translation['AUDIT_SUMMARY.SUCCESS.UPLOAD_MODIFIED_REPORT_TEXT'];
    this.translation.notify.success.removeModifiedReportTitle =
      translation['AUDIT_SUMMARY.SUCCESS.REMOVE_MODIFIED_REPORT_TITLE'];
    this.translation.notify.success.removeModifiedReportText =
      translation['AUDIT_SUMMARY.SUCCESS.REMOVE_MODIFIED_REPORT_TEXT'];
    this.translation.removeModifiedReportDialog.header =
      translation['AUDIT_SUMMARY.REMOVE_MODIFIED_REPORT_DIALOG.HEADER'];
    this.translation.removeModifiedReportDialog.content =
      translation['AUDIT_SUMMARY.REMOVE_MODIFIED_REPORT_DIALOG.CONTENT'];
    this.translation.removeModifiedReportDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeModifiedReportDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
    this.translation.cancelSyncDialog.header = translation['AUDIT_SUMMARY.SYNC_DIALOG.HEADER'];
    this.translation.cancelSyncDialog.content = translation['AUDIT_SUMMARY.SYNC_DIALOG.CONTENT'];
    this.translation.cancelSyncDialog.cancel = translation['AUDIT_SUMMARY.SYNC_DIALOG.CANCEL'];
    this.translation.cancelSyncDialog.confirm = translation['AUDIT_SUMMARY.SYNC_DIALOG.CONFIRM'];
    this.translation.charts.headerFindings = translation['AUDIT_SUMMARY.SUBHEADER.FINDINGS'];
    this.translation.charts.headerQuestions = translation['AUDIT_SUMMARY.SUBHEADER.QUESTIONS'];
    this.translation.charts.totalQuestions = translation['AUDIT_SUMMARY.TOTAL_QUESTIONS'];
  }

  keyDownUploadReport(event: any, fileInput: any): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      fileInput.click(event);
    }
  }
}

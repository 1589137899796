import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import moment from 'moment';

@Component({
  selector: 'gw-display-date',
  templateUrl: './display-date.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayDateComponent {
  @Input() date: moment.Moment;
  @Input() isEditMode: boolean;
  @Input() label: string;
  @Input() popoverLabel?: string;
  @Input() isUserTypeClient: boolean;

  @Output() editDate = new EventEmitter<moment.Moment>();
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// Models
import { AuditType } from './../models/audit-type.model';
// Mocks
import { AuditTypesMock } from '../mocks/audit-types';
// Services
import { ApiUrlService } from './api-url.service';

@Injectable()
export class AuditTypeService {
  private auditTypesMock = new AuditTypesMock();

  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService
  ) { }

  getAuditTypes(): Promise<Array<AuditType>> {
    return this.http.get(this.apiUrlService.auditTypeApi)
      .toPromise()
      .then((data: any) => {
        const auditTypes = new Array<AuditType>();
        data.forEach(element => {
          auditTypes.push(new AuditType(element));
        });
        return auditTypes;
      })
      .catch(error => {
        console.error('Error while getting audit types', error);
        throw error;
      });
  }

  createAuditType(auditType: AuditType): Promise<AuditType> {
    return this.http.post(this.apiUrlService.auditTypeApi, auditType)
      .toPromise()
      .then(data => new AuditType(data))
      .catch(error => {
        console.error('Error while creating audit type', error);
        throw error;
      });
  }

  updateAuditType(auditType: AuditType): Promise<AuditType> {
    return this.http.put(this.apiUrlService.auditTypeApi + '/' + auditType.id, auditType)
      .toPromise()
      .then(data => new AuditType(data))
      .catch(error => {
        console.error('Error while creating audit type', error);
        throw error;
      });
  }

  archiveAuditType(auditType: AuditType, archive = true): Promise<AuditType> {
    return this.http.put(this.apiUrlService.auditTypeApi + '/' + auditType.id, convertAuditTypeToArchive())
      .toPromise()
      .then(data => new AuditType(data))
      .catch(error => {
        console.error('Error while creating audit type', error);
        throw error;
      });

    function convertAuditTypeToArchive(): any {
      return {
        id: auditType.id,
        archived: archive ? 'true' : 'false'
      };
    }
  }

  removeAuditType(auditType: AuditType): Promise<any> {
    return this.http.delete(this.apiUrlService.auditTypeApi + '/' + auditType.id)
      .toPromise()
      .then(data => data)
      .catch(error => {
        console.error('Error while removing audit type', error);
        throw error;
      });
  }
}

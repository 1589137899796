import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Employee, RouterService } from 'gutwin-shared';

import { AuditType } from '@gutwin-audit/shared/models/audit-type.model';
import { Facility } from '@gutwin-audit/shared/models/facility.model';
import { AuditsFilters } from '@gutwin-audit/shared/models/filters-audits.model';

import { AuditTypeService } from '@gutwin-audit/shared/services/audit-type.service';
import { FacilityService } from '@gutwin-audit/shared/services/facility.service';
import { FilterModuleService } from '@gutwin-audit/shared/services/filter-module.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';

@Component({
  selector: 'gw-audits-filters',
  templateUrl: './audits-filters.component.html',
  styleUrls: ['./audits-filters.component.scss']
})
export class AuditsFiltersComponent implements OnInit, OnDestroy {
  assignedFilters: AuditsFilters;
  hasFilters: boolean;
  assignedAuditors: Array<Employee>;
  assignedAuditTypes: Array<AuditType>;
  assignedFacilities: Array<Facility>;
  users: Array<Employee>;
  facilities: Array<Facility>;
  auditTypes: Array<AuditType>;
  isTemplates: boolean;
  errors = {
    connectionTitle: '',
    fetchAuditTypesText: '',
    fetchEmployeesText: '',
    fetchFacilitiesText: ''
  };
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private auditTypeService: AuditTypeService,
    private facilityService: FacilityService,
    private filterService: FilterModuleService,
    private notificationsService: NotificationsService,
    private router: Router,
    private routerService: RouterService,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.initFilters(this.filterService.auditsFilters);
    this.fetchEmployees();
    this.fetchAuditTypes();
    this.fetchFacilities();
    this.fetchTranslations();
    this.observeFilters();
    this.observeIfTemplates();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initFilters(filters: AuditsFilters): void {
    this.assignedFilters = filters;
    this.checkIfHasFilters();
  }

  observeFilters(): Subscription {
    return this.filterService.filterObservable.pipe(takeUntil(this.destroy$)).subscribe(params => {
      this.initFilters(params.filters);
      this.assignedAuditors = this.convertAuditors(this.users);
      this.assignedAuditTypes = this.convertAuditTypes(this.auditTypes);
      this.assignedFacilities = this.convertFacilities(this.facilities);
    });
  }

  observeIfTemplates(): Subscription {
    const templatesUrl = '/audits/templates';
    this.isTemplates = this.routerService.isLinkActive(templatesUrl);
    return this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isTemplates = this.routerService.isLinkActive(templatesUrl);
        this.checkIfHasFilters();
      }
    });
  }

  checkIfHasFilters(): void {
    const filtersToSkip = this.isTemplates ? ['name', 'dateFrom', 'dateTo'] : ['templateName'];
    this.hasFilters = this.filterService.isAnyFilter(filtersToSkip);
  }

  fetchEmployees(onInit = false): void {
    if ((this.isLeadAuditorsFilterAssigned() || this.isCoAuditorsFilterAssigned() || onInit) && !this.users) {
      this.userService
        .getEmployees()
        .then((employees: Array<Employee>) => {
          this.users = employees;
          this.assignedAuditors = this.convertAuditors(employees);
        })
        .catch(() => {
          this.notificationsService.error(this.errors.connectionTitle, this.errors.fetchEmployeesText);
        });
    }
  }

  fetchAuditTypes(onInit = false): void {
    if ((this.isAuditTypesFilterAssigned() || onInit) && !this.auditTypes) {
      this.auditTypeService
        .getAuditTypes()
        .then((auditTypes: Array<AuditType>) => {
          this.auditTypes = auditTypes;
          this.assignedAuditTypes = this.convertAuditTypes(auditTypes);
        })
        .catch(() => {
          this.notificationsService.error(this.errors.connectionTitle, this.errors.fetchAuditTypesText);
        });
    }
  }

  fetchFacilities(onInit = false): void {
    if ((this.isFacilitiesFilterAssigned() || onInit) && !this.facilities) {
      this.facilityService
        .getFacilitiesForFilter()
        .then((facilities: Array<Facility>) => {
          this.facilities = facilities;
          this.assignedFacilities = this.convertFacilities(facilities);
        })
        .catch(() => {
          this.notificationsService.error(this.errors.connectionTitle, this.errors.fetchFacilitiesText);
        });
    }
  }

  isLeadAuditorsFilterAssigned(): boolean {
    return this.assignedFilters && !!this.assignedFilters.leadAuditors;
  }

  isCoAuditorsFilterAssigned(): boolean {
    return this.assignedFilters && !!this.assignedFilters.coAuditors;
  }

  isAuditTypesFilterAssigned(): boolean {
    return this.assignedFilters && this.assignedFilters.types && !!this.assignedFilters.types.length;
  }

  isFacilitiesFilterAssigned(): boolean {
    return this.assignedFilters && this.assignedFilters.facilities && !!this.assignedFilters.facilities.facilities;
  }

  convertAuditors(users: Array<Employee>): Array<Employee> {
    const assignedAuditors = new Array<Employee>();
    if (this.isLeadAuditorsFilterAssigned() && this.users) {
      this.assignedFilters.leadAuditors.forEach(auditor => {
        assignedAuditors.push(_.find(users, user => user.id === auditor));
      });
    } else if (this.isCoAuditorsFilterAssigned() && this.users) {
      this.assignedFilters.coAuditors.forEach(auditor => {
        assignedAuditors.push(_.find(users, user => user.id === auditor));
      });
    }
    return assignedAuditors;
  }

  convertAuditTypes(auditTypes: Array<AuditType>): Array<AuditType> {
    const assignedAuditTypes = new Array<AuditType>();
    if (this.isAuditTypesFilterAssigned()) {
      this.assignedFilters.types.forEach(typeId => {
        assignedAuditTypes.push(_.find(auditTypes, type => type.id === typeId));
      });
    }
    return assignedAuditTypes;
  }

  convertFacilities(facilities: Array<Facility>): Array<Facility> {
    const assignedFacilities = new Array<Facility>();
    if (this.isFacilitiesFilterAssigned()) {
      this.assignedFilters.facilities.facilities.forEach(facilityId => {
        const facility = this.findFacility(facilities, facilityId);
        if (facility) {
          assignedFacilities.push(facility);
        }
      });
    }
    return assignedFacilities;
  }

  fillAssignedFacilities(): void {
    this.assignedFacilities = new Array<Facility>();
    this.assignedFilters.facilities.facilities.forEach(facilityId => {
      const facility = this.findFacility(this.facilities, facilityId);
      if (facility) {
        this.assignedFacilities.push(facility);
      }
    });
  }

  findFacility(facilities: Array<Facility>, facilityId: string): Facility {
    for (const facility of facilities) {
      if (facility.id === facilityId) {
        return facility;
      } else if (facility.children && facility.children.length) {
        const childrenFacility = this.findFacility(facility.children, facilityId);
        if (childrenFacility) {
          return childrenFacility;
        }
      }
    }
    return undefined;
  }

  deselectAuditor(index: number): void {
    if (this.isLeadAuditorsFilterAssigned()) {
      this.assignedFilters.leadAuditors.splice(index, 1);
      this.filterService.setFilter('leadAuditors', this.assignedFilters.leadAuditors);
    }
    if (this.isCoAuditorsFilterAssigned()) {
      this.assignedFilters.coAuditors.splice(index, 1);
      this.filterService.setFilter('coAuditors', this.assignedFilters.coAuditors);
    }
  }

  deselectType(index: number): void {
    this.assignedFilters.types.splice(index, 1);
    this.filterService.setFilter('types', this.assignedFilters.types);
  }

  deselectFacility(index: number): void {
    this.assignedFilters.facilities.facilities.splice(index, 1);
    this.filterService.setFilter('facilities', {
      multiSelect: this.assignedFilters.facilities.multiSelect,
      subFolders: this.assignedFilters.facilities.subFolders,
      facilities: this.assignedFilters.facilities.facilities
    });
  }

  removeNameFilter(): void {
    this.filterService.setFilter('name', '');
  }

  removeTemplateNameFilter(): void {
    this.filterService.setFilter('templateName', '');
  }

  removeDateFilter(): void {
    this.filterService.setFilter('dateFrom', '');
    this.filterService.setFilter('dateTo', '');
  }

  removeLocationFilter(): void {
    this.filterService.setFilter('location', '');
  }

  async fetchTranslations(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'AUDITS_LIST.FILTER.ERROR.FETCH_FINDING_TYPES',
        'AUDITS_LIST.FILTER.ERROR.FETCH_EMPLOYEES',
        'AUDITS_LIST.FILTER.ERROR.FETCH_FACILITIES'
      ])
      .toPromise();
    this.errors.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.errors.fetchAuditTypesText = translation['AUDITS_LIST.FILTER.ERROR.FETCH_FINDING_TYPES'];
    this.errors.fetchEmployeesText = translation['AUDITS_LIST.FILTER.ERROR.FETCH_EMPLOYEES'];
    this.errors.fetchFacilitiesText = translation['AUDITS_LIST.FILTER.ERROR.FETCH_FACILITIES'];
  }
}

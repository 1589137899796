import gql from 'graphql-tag';

export const ComplianceRatingStats = gql`
  fragment ComplianceRatingStats on ComplianceRatingStats {
    compliantRequirementsCount
    notCompliantRequirementsCount
    notRatedRequirementsCount
    partlyCompliantRequirementsCount
  }
`;

export const RelevanceRatingStats = gql`
  fragment RelevanceRatingStats on RelevanceRatingStats {
    notRatedRequirementsCount
    notRelevantRequirementsCount
    relevantRequirementsCount
    toClarifyRequirementsCount
    toMonitorRequirementsCount
  }
`;

import { Pipe, PipeTransform } from '@angular/core';

import { UserType } from '../enums/user-type.enum';

@Pipe({
  name: 'disableCleanFormButton'
})
export class DisableCleanFormButtonPipe implements PipeTransform {
  public transform(isFormDirty: boolean, userType: UserType = UserType.client): boolean {
    return userType === UserType.client && !isFormDirty;
  }
}

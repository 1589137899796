import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { findIndex, sortBy } from 'lodash';
import { noop, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  DialogService,
  Employee,
  ExpandBoxComponent,
  ModalIds,
  OverlayService,
  PermissionCategory,
  PermissionCategoryModalComponent,
  PermissionCategoryModalData,
  Role,
  RoleModalComponent,
  RoleModalData,
  RoleModalResponse,
  UserType
} from 'gutwin-shared';

import { RoleService } from '@gutwin-audit/shared/services/role.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';

@Component({
  selector: 'gw-roles',
  templateUrl: './roles.component.html'
})
export class RolesComponent implements OnInit, OnDestroy {
  roles: Array<Role>;
  users: Array<Employee>;
  permissionCategories: Array<PermissionCategory>;
  savingRole: boolean;
  translation = {
    notify: {
      error: {
        title: '',
        loadRoleUsers: '',
        loadUsers: '',
        loadRole: '',
        createRole: '',
        updateRole: '',
        removeRole: '',
        loadPermissionCategories: '',
        addUserToRole: '',
        removeUserFromRole: ''
      },
      success: {
        title: '',
        createRole: '',
        updateRole: '',
        removeRole: '',
        addUserToRole: '',
        removeUserFromRole: ''
      }
    },
    removeRoleDialog: {
      title: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    removeUserFromRoleDialog: {
      title: '',
      content: '',
      cancel: '',
      confirm: ''
    }
  };
  destroy$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private dialogService: DialogService,
    private notificationsService: NotificationsService,
    private roleService: RoleService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private userService: UserService,
    private overlayService: OverlayService
  ) {}

  ngOnInit(): void {
    this.roles = this.route.snapshot.data['roles'];
    this.fetchTranslation();
    this.fetchUsers();
    this.initPermissionCategories();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  async fetchRole(role: Role): Promise<Role> {
    if (role) {
      role = await this.roleService.getRole(role.id).catch(() => {
        this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.loadRole);
        return role;
      });
      role.permissionCategories = await this.getPermissionCategories([role.id]);
    } else {
      role = new Role();
      role.permissionCategories = await this.getPermissionCategories([]);
    }
    return role;
  }

  async initPermissionCategories(): Promise<void> {
    this.permissionCategories = await this.getPermissionCategories([]);
  }

  getPermissionCategories(rolesIds: Array<string>): Promise<Array<PermissionCategory>> {
    return this.roleService.getPermissionCategoriesInRoles(rolesIds).catch(() => {
      this.notificationsService.error(
        this.translation.notify.error.title,
        this.translation.notify.error.loadPermissionCategories
      );
      return [];
    });
  }

  async fetchRoleUsers(role: Role): Promise<void> {
    const usersResponse = await this.roleService.getRoleUsers(role.id).catch(() => {
      this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.loadRoleUsers);
      return {
        totalUsers: 0,
        users: []
      };
    });
    role.users = this.sortUsers(usersResponse.users);
    role.usersCount = usersResponse.totalUsers;
    this.changeDetector.detectChanges();
  }

  async fetchUsers(): Promise<void> {
    const users = await this.userService.getUsers().catch(error => {
      this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.loadUsers);
      return [];
    });
    this.users = this.sortUsers(users);
  }

  sortRoles(): void {
    this.roles = sortBy(this.roles, (role: Role) => role.name);
  }

  appendRoleToList(role: Role): void {
    this.roles.push(role);
  }

  updateRoleOnList(role: Role): void {
    const index = this.roles.findIndex(roleOnList => roleOnList.id === role.id);
    if (index !== -1) {
      const roleOnList = this.roles[index];
      role.users = roleOnList.users;
      role.usersCount = roleOnList.usersCount;
      this.roles[index] = role;
    } else {
      this.appendRoleToList(role);
    }
  }

  spliceRoleFromList(role: Role): void {
    const index = findIndex(this.roles, { id: role.id });
    if (~index) {
      this.roles.splice(index, 1);
    }
  }

  sortUsers(users: Array<Employee>): Array<Employee> {
    return sortBy(users, ['lastname', 'name']);
  }

  createRole(role: Role): Promise<Role> {
    return this.roleService
      .createRole(role)
      .then(response => {
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.createRole
        );
        return response;
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.createRole);
        throw error;
      });
  }

  updateRole(role: Role): Promise<Role> {
    return this.roleService
      .updateRole(role)
      .then(async response => {
        await this.userService.getCurrentUser(true);
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.updateRole
        );
        return response;
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.updateRole);
        throw error;
      });
  }

  removeRole(role: Role): Promise<void> {
    return this.roleService
      .removeRole(role.id)
      .then(() => {
        this.spliceRoleFromList(role);
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.removeRole
        );
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.title, this.translation.notify.error.removeRole);
      });
  }

  addUserToRole(role: Role, user: Employee): Promise<void> {
    return this.roleService
      .addRoleUser(role.id, user.id)
      .then(() => {
        this.fetchRoleUsers(role);
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.addUserToRole
        );
      })
      .catch(() => {
        this.notificationsService.error(
          this.translation.notify.error.title,
          this.translation.notify.error.addUserToRole
        );
      });
  }

  removeUserFromRole(role: Role, user: Employee): Promise<void> {
    return this.roleService
      .removeRoleUser(role.id, user.id)
      .then(() => {
        this.fetchRoleUsers(role);
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.removeUserFromRole
        );
      })
      .catch(() => {
        this.notificationsService.error(
          this.translation.notify.error.title,
          this.translation.notify.error.removeUserFromRole
        );
      });
  }

  async showRoleModal(role?: Role): Promise<void> {
    const fetchedRole = await this.fetchRole(role);
    const roleModal = this.overlayService.open<boolean, RoleModalData, RoleModalResponse>(
      RoleModalComponent,
      {
        role: fetchedRole,
        allPermissionCategories: this.permissionCategories,
        isAudit: true,
        userType: UserType.auditor
      },
      {
        small: true
      }
    );

    roleModal.submit$.pipe(takeUntil(this.destroy$)).subscribe(response => {
      const roleToSave = response.data.role;
      const promise = response.data.status === 'update' ? this.updateRole(roleToSave) : this.createRole(roleToSave);
      promise
        .then(response => {
          this.updateRoleOnList(response);
          this.sortRoles();
          this.overlayService.confirm(ModalIds.roleModal);
        })
        .catch(() => {
          this.overlayService.discard(ModalIds.roleModal);
        });
    });
  }

  openPermissionsCategoryModal(permissionCategory: PermissionCategory, role: Role): void {
    this.overlayService
      .open<PermissionCategory, PermissionCategoryModalData>(
        PermissionCategoryModalComponent,
        {
          permissionCategory,
          roles: [role],
          withForm: true
        },
        {
          small: true
        }
      )
      .afterClosed$.pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        this.overlayService.open(
          RoleModalComponent,
          {
            role,
            permissionCategory: response.data,
            userType: UserType.auditor
          },
          {
            small: true
          }
        );
      });
  }

  showRemoveRoleModal(role: Role): Promise<void> {
    return this.dialogService
      .confirm(
        this.translation.removeRoleDialog.title,
        this.translation.removeRoleDialog.content,
        this.translation.removeRoleDialog.cancel,
        this.translation.removeRoleDialog.confirm
      )
      .then(() => {
        return this.removeRole(role);
      })
      .catch(noop);
  }

  showRemoveUserFromRoleModal(role: Role, user: Employee): Promise<void> {
    return this.dialogService
      .confirm(
        this.translation.removeUserFromRoleDialog.title,
        this.translation.removeUserFromRoleDialog.content,
        this.translation.removeUserFromRoleDialog.cancel,
        this.translation.removeUserFromRoleDialog.confirm
      )
      .then(() => {
        return this.removeUserFromRole(role, user);
      })
      .catch(noop);
  }

  async onOpenRole({ role, expandBox }: { role: Role; expandBox: ExpandBoxComponent }): Promise<void> {
    await this.fetchRoleUsers(role);
    expandBox.toggle();
  }

  onAddRole(): void {
    this.showRoleModal();
  }

  onEditRole(role: Role): void {
    this.showRoleModal(role);
  }

  onRemoveRole(role: Role): void {
    this.showRemoveRoleModal(role);
  }

  onAddUserToRole({ role, users }: { role: Role; users: Array<Employee> }): void {
    this.addUserToRole(role, users[0]);
  }

  onRemoveUserFromRole({ role, user }: { role: Role; user: Employee }): void {
    this.showRemoveUserFromRoleModal(role, user);
  }

  async fetchTranslation(): Promise<void> {
    const translations = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ACTION.SUCCESS',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'ADMIN_SETTINGS.ROLES.ERROR.LOAD_ROLE_USERS',
        'ADMIN_SETTINGS.ROLES.ERROR.LOAD_USERS',
        'ADMIN_SETTINGS.ROLES.ERROR.LOAD_ROLE',
        'ADMIN_SETTINGS.ROLES.ERROR.CREATE_ROLE',
        'ADMIN_SETTINGS.ROLES.ERROR.UPDATE_ROLE',
        'ADMIN_SETTINGS.ROLES.ERROR.REMOVE_ROLE',
        'ADMIN_SETTINGS.ROLES.ERROR.LOAD_PERMISSION_CATEGORIES',
        'ADMIN_SETTINGS.ROLES.ERROR.ADD_USER',
        'ADMIN_SETTINGS.ROLES.ERROR.REMOVE_USER',
        'ADMIN_SETTINGS.ROLES.SUCCESS.CREATE_ROLE',
        'ADMIN_SETTINGS.ROLES.SUCCESS.UPDATE_ROLE',
        'ADMIN_SETTINGS.ROLES.SUCCESS.REMOVE_ROLE',
        'ADMIN_SETTINGS.ROLES.SUCCESS.ADD_USER',
        'ADMIN_SETTINGS.ROLES.SUCCESS.REMOVE_USER',
        'ADMIN_SETTINGS.ROLES.REMOVE_ROLE_MODAL.TITLE',
        'ADMIN_SETTINGS.ROLES.REMOVE_ROLE_MODAL.CONTENT',
        'ADMIN_SETTINGS.ROLES.REMOVE_USER_ROLE_MODAL.TITLE',
        'ADMIN_SETTINGS.ROLES.REMOVE_USER_ROLE_MODAL.CONTENT'
      ])
      .toPromise();
    this.translation.notify.error.title = translations['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.loadRoleUsers = translations['ADMIN_SETTINGS.ROLES.ERROR.LOAD_ROLE_USERS'];
    this.translation.notify.error.loadUsers = translations['ADMIN_SETTINGS.ROLES.ERROR.LOAD_USERS'];
    this.translation.notify.error.loadRole = translations['ADMIN_SETTINGS.ROLES.ERROR.LOAD_ROLE'];
    this.translation.notify.error.createRole = translations['ADMIN_SETTINGS.ROLES.ERROR.CREATE_ROLE'];
    this.translation.notify.error.updateRole = translations['ADMIN_SETTINGS.ROLES.ERROR.UPDATE_ROLE'];
    this.translation.notify.error.removeRole = translations['ADMIN_SETTINGS.ROLES.ERROR.REMOVE_ROLE'];
    this.translation.notify.error.loadPermissionCategories =
      translations['ADMIN_SETTINGS.ROLES.ERROR.LOAD_PERMISSION_CATEGORIES'];
    this.translation.notify.error.addUserToRole = translations['ADMIN_SETTINGS.ROLES.ERROR.ADD_USER'];
    this.translation.notify.error.removeUserFromRole = translations['ADMIN_SETTINGS.ROLES.ERROR.REMOVE_USER'];
    this.translation.notify.success.title = translations['GLOBAL.ACTION.SUCCESS'];
    this.translation.notify.success.createRole = translations['ADMIN_SETTINGS.ROLES.SUCCESS.CREATE_ROLE'];
    this.translation.notify.success.updateRole = translations['ADMIN_SETTINGS.ROLES.SUCCESS.UPDATE_ROLE'];
    this.translation.notify.success.removeRole = translations['ADMIN_SETTINGS.ROLES.SUCCESS.REMOVE_ROLE'];
    this.translation.notify.success.addUserToRole = translations['ADMIN_SETTINGS.ROLES.SUCCESS.ADD_USER'];
    this.translation.notify.success.removeUserFromRole = translations['ADMIN_SETTINGS.ROLES.SUCCESS.REMOVE_USER'];
    this.translation.removeRoleDialog.title = translations['ADMIN_SETTINGS.ROLES.REMOVE_ROLE_MODAL.TITLE'];
    this.translation.removeRoleDialog.content = translations['ADMIN_SETTINGS.ROLES.REMOVE_ROLE_MODAL.CONTENT'];
    this.translation.removeRoleDialog.cancel = translations['GLOBAL.ACTION.CANCEL'];
    this.translation.removeRoleDialog.confirm = translations['GLOBAL.ACTION.REMOVE'];
    this.translation.removeRoleDialog.title = translations['ADMIN_SETTINGS.ROLES.REMOVE_ROLE_MODAL.TITLE'];
    this.translation.removeRoleDialog.content = translations['ADMIN_SETTINGS.ROLES.REMOVE_ROLE_MODAL.CONTENT'];
    this.translation.removeRoleDialog.cancel = translations['GLOBAL.ACTION.CANCEL'];
    this.translation.removeRoleDialog.confirm = translations['GLOBAL.ACTION.REMOVE'];
    this.translation.removeUserFromRoleDialog.title = translations['ADMIN_SETTINGS.ROLES.REMOVE_USER_ROLE_MODAL.TITLE'];
    this.translation.removeUserFromRoleDialog.content =
      translations['ADMIN_SETTINGS.ROLES.REMOVE_USER_ROLE_MODAL.CONTENT'];
    this.translation.removeUserFromRoleDialog.cancel = translations['GLOBAL.ACTION.CANCEL'];
    this.translation.removeUserFromRoleDialog.confirm = translations['GLOBAL.ACTION.REMOVE'];
  }
}

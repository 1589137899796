import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { find, findIndex } from 'lodash';
import { Subject } from 'rxjs';

import { Audit } from '../models/audit.model';

export enum ProgressStatus {
  inProgress = 'in_progress',
  success = 'success',
  error = 'error'
}

export enum ProgressType {
  ratings = 'Ratings',
  ratingScale = 'RatingScale',
  findingTypes = 'FindingTypes',
  employees = 'Employees',
  findings = 'Findings',
  sections = 'Sections',
  questionMedia = 'QuestionMedia',
  audit = 'Audit',
  auditedArea = 'AuditedArea',
  questionnaire = 'Questionnaire',
  attachment = 'Attachment'
}

export interface ProgressGroup {
  id: string;
  progress?: Progress;
  type: ProgressType;
  name: string;
  status: ProgressStatus;
  standaloneStatus?: boolean; // Status should be considered not only by children
  action: string;
  children?: Array<ProgressGroup>;
}

export class AuditChunks {
  audit?: boolean;
  sections?: boolean;
  sectionsAuditedAreaIds?: Array<string>;
  findings?: boolean;
  findingsAuditedAreaIds?: Array<string>;
  questionMedia?: boolean;
  questionMediaAuditedAreaIds?: Array<string>;

  constructor(auditChunks?: any) {
    this.audit = auditChunks && auditChunks.audit !== undefined ? auditChunks.audit : true;
    this.sections = auditChunks && auditChunks.sections !== undefined ? auditChunks.sections : true;
    this.sectionsAuditedAreaIds = auditChunks ? auditChunks.sectionsAuditedAreaIds : undefined;
    this.findings = auditChunks && auditChunks.findings !== undefined ? auditChunks.findings : true;
    this.findingsAuditedAreaIds = auditChunks ? auditChunks.findingsAuditedAreaIds : undefined;
    this.questionMedia = auditChunks && auditChunks.questionMedia !== undefined ? auditChunks.questionMedia : true;
    this.questionMediaAuditedAreaIds = auditChunks ? auditChunks.questionMediaAuditedAreaIds : undefined;
  }
}

export class Progress {
  total: number;
  done: number;

  constructor(progress?: any) {
    this.total = progress ? progress.total || 0 : 0;
    this.done = progress ? progress.done || 0 : 0;
  }

  isComplete(): boolean {
    return this.done === this.total;
  }
}

export class ProgressBar {
  progress: Progress;
  action: string;
  status: ProgressStatus;
  label: string;
  groups: Array<ProgressGroup>;

  constructor(progress?: Partial<ProgressBar>) {
    this.progress = progress ? progress.progress || new Progress() : new Progress();
    this.action = progress ? progress.action : undefined;
    this.status = progress ? progress.status : undefined;
    this.label = progress ? progress.label : undefined;
    this.groups = progress ? progress.groups : new Array<ProgressGroup>();
  }
}

@Injectable()
export class ProgressService {
  progress = new ProgressBar();
  progressSource = new Subject<ProgressBar>();
  progressObservable = this.progressSource.asObservable();
  action = new Subject<any>();
  actionObservable = this.action.asObservable();
  translations = {
    audit: '',
    employees: '',
    findings: '',
    findingTypes: '',
    ratings: '',
    ratingScale: '',
    questionMedia: '',
    sections: ''
  };

  constructor(private translateService: TranslateService) {}

  setProgress(progress: ProgressBar): void {
    this.progress = progress;
    this.progressSource.next(progress);
  }

  setProgressAction(action: string): void {
    this.progress.action = action;
    this.progressSource.next(this.progress);
  }

  setProgressStatus(status: ProgressStatus): void {
    this.progress.status = status;
    this.progressSource.next(this.progress);
  }

  setProgressLabel(label: string): void {
    this.progress.label = label;
    this.progressSource.next(this.progress);
  }

  updateProgressGroup(group: ProgressGroup): void {
    const index = findIndex(this.progress.groups, { id: group.id });
    if (index !== -1) this.progress.groups[index] = group;
    else this.progress.groups.push(group);
    this.progressSource.next(this.progress);
  }

  removeProgressGroup(groupId: string): void {
    const index = findIndex(this.progress.groups, { id: groupId });
    if (~index) {
      this.progress.groups.splice(index, 1);
    }
    this.progressSource.next(this.progress);
  }

  countProgress(): void {
    const progress = new Progress();
    if (this.progress.groups) {
      this.progress.groups.forEach(group => {
        const groupProgress = this.countGroupProgress(group);
        progress.total += groupProgress.total;
        progress.done += groupProgress.done;
      });
    }
    this.progress.progress = progress;
    this.progressSource.next(this.progress);
  }

  countGroupProgress(group: ProgressGroup): Progress {
    const progress = new Progress();
    if (group.children) {
      group.children.forEach(child => {
        const childProgress = this.countGroupProgress(child);
        progress.total += childProgress.total;
        progress.done += childProgress.done;
      });
    } else {
      if (group.progress) {
        progress.total += group.progress.total;
        progress.done += group.progress.done;
      } else {
        progress.total++;
        progress.done += group.status === ProgressStatus.success ? 1 : 0;
      }
    }
    return progress;
  }

  checkProgressStatus(): void {
    let status = ProgressStatus.success;
    if (this.progress.groups) {
      this.progress.groups.forEach(group => {
        const groupProgressStatus = this.checkGroupProgressStatus(group);
        status =
          status === ProgressStatus.error || groupProgressStatus === ProgressStatus.error
            ? ProgressStatus.error
            : status === ProgressStatus.inProgress || groupProgressStatus === ProgressStatus.inProgress
            ? ProgressStatus.inProgress
            : ProgressStatus.success;
      });
    }
    this.progress.status = status;
    this.progressSource.next(this.progress);
  }

  checkGroupProgressStatus(group: ProgressGroup): string {
    let status = ProgressStatus.success;
    if (group.children) {
      if (group.standaloneStatus) {
        status = group.status;
      }
      group.children.forEach(child => {
        const groupProgressStatus = this.checkGroupProgressStatus(child);
        status =
          status === ProgressStatus.error || groupProgressStatus === ProgressStatus.error
            ? ProgressStatus.error
            : status === ProgressStatus.inProgress || groupProgressStatus === ProgressStatus.inProgress
            ? ProgressStatus.inProgress
            : ProgressStatus.success;
      });
    } else {
      status = group.status;
    }
    return status;
  }

  convertAuditToProgressGroup(audit: Audit, chunks: AuditChunks, action: 'download' | 'sync'): Promise<ProgressGroup> {
    return this.translateService
      .get([
        'GLOBAL.TABLE_LABEL.AUDIT',
        'GLOBAL.TABLE_LABEL.FINDINGS',
        'GLOBAL.TABLE_LABEL.QUESTION_MEDIA',
        'GLOBAL.TABLE_LABEL.SECTIONS'
      ])
      .toPromise()
      .then(translation => {
        this.translations.audit = translation['GLOBAL.TABLE_LABEL.AUDIT'];
        this.translations.findings = translation['GLOBAL.TABLE_LABEL.FINDINGS'];
        this.translations.questionMedia = translation['GLOBAL.TABLE_LABEL.QUESTION_MEDIA'];
        this.translations.sections = translation['GLOBAL.TABLE_LABEL.SECTIONS'];

        const auditGroup = {
          id: audit.id,
          type: ProgressType.audit,
          name: audit.name,
          status: ProgressStatus.inProgress,
          standaloneStatus: true,
          action,
          children: new Array<ProgressGroup>()
        };
        if (chunks.audit) {
          auditGroup.children.push({
            id: audit.id,
            type: ProgressType.audit,
            name: this.translations.audit,
            status: ProgressStatus.inProgress,
            action
          });
        }
        if (audit.auditedAreas) {
          audit.auditedAreas.forEach(auditedArea => {
            const auditedAreaProgress = {
              id: auditedArea.id,
              type: ProgressType.auditedArea,
              name: auditedArea.facility.name,
              status: ProgressStatus.inProgress,
              action,
              children: new Array<ProgressGroup>()
            };
            if (chunks.sections) {
              auditedAreaProgress.children.push({
                id: auditedArea.id + '-sections',
                type: ProgressType.sections,
                name: this.translations.sections,
                status: ProgressStatus.inProgress,
                action
              });
            }
            if (chunks.findings) {
              auditedAreaProgress.children.push({
                id: auditedArea.id + '-findings',
                type: ProgressType.findings,
                name: this.translations.findings,
                status: ProgressStatus.inProgress,
                action
              });
            }
            if (chunks.questionMedia) {
              auditedAreaProgress.children.push({
                id: auditedArea.id + '-question-media',
                type: ProgressType.questionMedia,
                name: this.translations.questionMedia,
                status: ProgressStatus.inProgress,
                action
              });
            }
            if (chunks.sections || chunks.findings || chunks.questionMedia) {
              auditGroup.children.push(auditedAreaProgress);
            }
          });
        }
        return auditGroup;
      });
  }

  updateAuditProgressGroup(auditId: string, status: ProgressStatus): void {
    if (this.progress.groups) {
      this.progress.groups.forEach(group => {
        if (group.id === auditId) {
          let allSaved = true;
          let hasError = false;
          group.children.forEach(child => {
            if (child.id === auditId) {
              child.status = status;
            }
            allSaved = allSaved && child.status === ProgressStatus.success;
            hasError = hasError || child.status === ProgressStatus.error;
          });
          group.status = hasError
            ? ProgressStatus.error
            : allSaved
            ? ProgressStatus.success
            : ProgressStatus.inProgress;
        }
      });
      this.progressSource.next(this.progress);
    }
  }

  updateAuditedAreaChunkProgressGroup(
    auditId: string,
    auditedAreaId: string,
    type: string,
    value: { status?: ProgressStatus; progress?: Progress }
  ): void {
    if (this.progress.groups) {
      this.progress.groups.forEach(group => {
        if (group.id === auditId) {
          let hasSuccess = true;
          let hasError = false;
          group.children.forEach(child => {
            if (child.id === auditedAreaId) {
              let auditedAreaHasSuccess = true;
              let auditedAreaHasError = false;
              child.children.forEach(auditedAreaChild => {
                if (auditedAreaChild.type === type) {
                  if (value.progress) {
                    auditedAreaChild.progress = value.progress;
                  }
                  if (value.status) {
                    auditedAreaChild.status = value.status;
                  }
                }
                auditedAreaHasSuccess = auditedAreaHasSuccess && auditedAreaChild.status === ProgressStatus.success;
                auditedAreaHasError = auditedAreaHasError || auditedAreaChild.status === ProgressStatus.error;
              });
              child.status = auditedAreaHasError
                ? ProgressStatus.error
                : auditedAreaHasSuccess
                ? ProgressStatus.success
                : ProgressStatus.inProgress;
            }
            hasSuccess = hasSuccess && child.status === ProgressStatus.success;
            hasError = hasError || child.status === ProgressStatus.error;
          });
          group.status = hasError
            ? ProgressStatus.error
            : hasSuccess
            ? ProgressStatus.success
            : ProgressStatus.inProgress;
        }
      });
      this.progressSource.next(this.progress);
    }
  }

  hasRunningRatingsGroup(): boolean {
    return this.hasRunningProgressGroup('ratings');
  }

  async updateRatingsProgressGroup(
    status = ProgressStatus.inProgress,
    action = 'download',
    create?: boolean
  ): Promise<any> {
    if (create) {
      const translation = await this.translateService.get(['GLOBAL.TABLE_LABEL.RATINGS']).toPromise();
      this.translations.ratings = translation['GLOBAL.TABLE_LABEL.RATINGS'];
    }

    this.updateProgressGroupStatus(
      { id: 'ratings', name: this.translations.ratings, type: ProgressType.ratings },
      status,
      action
    );
  }

  hasRunningRatingScaleGroup(): boolean {
    return this.hasRunningProgressGroup('rating-scale');
  }

  async updateRatingScaleProgressGroup(
    status = ProgressStatus.inProgress,
    action = 'download',
    create?: boolean
  ): Promise<any> {
    if (create) {
      const translation = await this.translateService.get(['GLOBAL.TABLE_LABEL.RATING_SCALE']).toPromise();
      this.translations.ratingScale = translation['GLOBAL.TABLE_LABEL.RATING_SCALE'];
    }

    this.updateProgressGroupStatus(
      { id: 'rating-scale', name: this.translations.ratingScale, type: ProgressType.ratingScale },
      status,
      action
    );
  }

  hasRunningFindingTypesGroup(): boolean {
    return this.hasRunningProgressGroup('finding-types');
  }

  async updateFindingTypesProgressGroup(
    status = ProgressStatus.inProgress,
    action = 'download',
    create?: boolean
  ): Promise<any> {
    if (create) {
      const translation = await this.translateService.get(['GLOBAL.TABLE_LABEL.FINDING_TYPES']).toPromise();
      this.translations.findingTypes = translation['GLOBAL.TABLE_LABEL.FINDING_TYPES'];
    }

    this.updateProgressGroupStatus(
      { id: 'finding-types', name: this.translations.findingTypes, type: ProgressType.findingTypes },
      status,
      action
    );
  }

  hasRunningEmployeesGroup(): boolean {
    return this.hasRunningProgressGroup('employees');
  }

  async updateEmployeesProgressGroup(
    status = ProgressStatus.inProgress,
    action = 'download',
    create?: boolean
  ): Promise<any> {
    if (create) {
      const translation = await this.translateService.get(['GLOBAL.TABLE_LABEL.EMPLOYEES']).toPromise();
      this.translations.employees = translation['GLOBAL.TABLE_LABEL.EMPLOYEES'];
    }

    this.updateProgressGroupStatus(
      { id: 'employees', name: this.translations.employees, type: ProgressType.employees },
      status,
      action
    );
  }

  hasRunningProgressGroup(groupId: string): boolean {
    const group = find(this.progress.groups, { id: groupId });
    return group && group.status === ProgressStatus.inProgress;
  }

  updateProgressGroupStatus(
    group: { id: string; name: string; type: ProgressType },
    status = ProgressStatus.inProgress,
    action = 'download'
  ): void {
    const existingGroup = find(this.progress.groups, { id: group.id });
    if (!existingGroup) {
      this.progress.groups.push({
        id: group.id,
        name: group.name,
        type: group.type,
        status,
        action
      });
    } else {
      existingGroup.status = status;
    }
    this.progressSource.next(this.progress);
  }

  clearProgressGroups(cleanAll?: boolean): void {
    this.progress.groups = this.clearGroups(undefined, cleanAll);
    this.progressSource.next(this.progress);
  }

  clearGroups(groups = this.progress.groups, cleanAll?: boolean): Array<ProgressGroup> {
    if (groups) {
      groups = groups.filter(group => {
        if (cleanAll || group.status === ProgressStatus.success) {
          return false;
        } else {
          if (group.children) {
            group.children = this.clearGroups(group.children);
          }
          return true;
        }
      });
    }
    return groups;
  }

  syncGroups(): void {
    this.action.next(this.progress.groups);
  }
}

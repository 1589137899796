import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
// Gutwin Shared Library
import { Attachment, DialogService, FileService, LightboxEvent, LightboxOption, LightboxService, LoaderService, ModalService } from 'gutwin-shared';
// Models
import { Finding } from './../../../shared/models/finding.model';
// Services
import { FindingService } from './../../../shared/services/finding.service';

@Component({
  selector: 'gw-files-section',
  templateUrl: './files-section.component.html',
  styleUrls: ['./files-section.component.scss']
})
export class FilesSectionComponent implements OnInit, OnDestroy {
  @Input() finding: Finding;
  @Input() canManageFinding = false;
  attachments = new Array<Attachment>();
  separatedAttachments = {
    boxes: new Array<Attachment>(),
    documents: new Array<Attachment>()
  };
  duplications = new Array<Attachment>();
  attachmentOptions: Array<LightboxOption> = [{
    id: 'remove',
    name: ''
  }];
  uploadingFile: boolean;
  lightboxSubscription: Subscription;
  translation = {
    notify: {
      error: {
        connectionTitle: '',
        uploadAttachment: '',
        uploadAttachments: '',
        existsAttachmentTitle: '',
        existsAttachmentText: '',
        removeAttachment: '',
      },
      success: {
        uploadAttachmentTitle: '',
        uploadAttachmentText: '',
        uploadAttachmentsTitle: '',
        uploadAttachmentsText: '',
        removeAttachmentTitle: '',
        removeAttachmentText: '',
      }
    },
    removeDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    }
  };

  constructor(
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private lightboxService: LightboxService,
    private findingService: FindingService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private fileService: FileService,
    private modalService: ModalService,
  ) { }

  ngOnInit() {
    this.fetchTranslation();
    this.attachments = this.finding.attachments;
    this.splitAttachments();
    this.listenLightboxEvents();
  }

  ngOnDestroy() {
    this.lightboxSubscription.unsubscribe();
  }

  splitAttachments(): void {
    this.separatedAttachments = {
      boxes: new Array<Attachment>(),
      documents: new Array<Attachment>()
    };
    this.attachments.forEach(attachment => {
      if (attachment.isImage) {
        this.separatedAttachments.boxes.push(attachment);
      } else {
        this.separatedAttachments.documents.push(attachment);
      }
    });
  }

  showLightbox(attachment: Attachment): void {
    if (this.separatedAttachments.boxes && this.separatedAttachments.boxes.length > 0) {
      this.lightboxService.show(
        attachment,
        this.separatedAttachments.boxes,
        this.attachmentOptions
      );
    }
  }

  listenLightboxEvents(): void {
    this.lightboxSubscription = this.lightboxService.eventObservable.subscribe(event => {
      this.onLightboxEvent(event);
    });
  }

  attachFiles(event: any) {
    this.duplications = new Array<Attachment>();
    const fileList: FileList = event.target.files;
    let filesCounter = 0;
    let uploadedFilesCounter = 0;

    if (fileList && fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const file: File = fileList[i];
        this.fileService.readFile(file).then(({ hash, rotation }) => {
          filesCounter++;
          const attachment = new Attachment({ file });
          attachment.checksum = hash;
          attachment.rotation = rotation;
          if (!this.isDuplicated(attachment)) {
            uploadedFilesCounter++;
            this.attachments.push(attachment);
          }
          if (filesCounter === fileList.length) {
            if (this.duplications.length) {
              this.modalService.open('duplicationsModal');
            }
            if (uploadedFilesCounter > 0) {
              this.splitAttachments();
              this.uploadAttachments(this.attachments);
            }
          }
        });
      }
    }
  }

  uploadAttachments(attachments: Array<Attachment>): void {
    this.uploadingFile = true;

    this.findingService.uploadFindingAttachments(this.finding, attachments)
      .then((finding: Finding) => {
        this.finding.attachments = finding.attachments;
        this.attachments = finding.attachments;
        this.splitAttachments();
        this.uploadingFile = false;

        const notify = {
          title: attachments.length > 1 ? this.translation.notify.success.uploadAttachmentsTitle : this.translation.notify.success.uploadAttachmentTitle,
          text: attachments.length > 1 ? this.translation.notify.success.uploadAttachmentsText : this.translation.notify.success.uploadAttachmentText
        };
        this.notificationsService.success(notify.title, notify.text);
      })
      .catch(error => {
        this.uploadingFile = false;

        const notifyText = attachments.length > 1 ? this.translation.notify.error.uploadAttachments : this.translation.notify.error.uploadAttachment;
        this.notificationsService.error(this.translation.notify.error.connectionTitle, notifyText);
      });
  }

  isDuplicated(checkedAttachment: Attachment): boolean {
    let isDuplicated = false;

    this.attachments.forEach((attachment: Attachment, index: number) => {
      const attachmentChecksum = attachment.checksum;
      if (!attachment.toRemove && checkedAttachment.checksum === attachmentChecksum) {
        this.duplications.push(checkedAttachment);
        isDuplicated = true;
      }
    });
    return isDuplicated;
  }

  showRemoveModal(attachment: Attachment): Promise<any> {
    return this.dialogService.confirm(
      this.translation.removeDialog.header,
      this.translation.removeDialog.content,
      this.translation.removeDialog.cancel,
      this.translation.removeDialog.confirm
    )
      .then(() => {
        return this.removeAttachment(attachment);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  removeAttachment(attachment: Attachment): Promise<Finding> {
    if (this.finding && attachment.url) {
      this.loaderService.show('navigationLoader');
      return this.findingService.removeAttachment(this.finding, attachment)
        .then(finding => {
          this.finding = finding;
          this.attachments = finding.attachments;
          this.splitAttachments();
          this.loaderService.hide('navigationLoader');
          this.notificationsService.success(this.translation.notify.success.removeAttachmentTitle, this.translation.notify.success.removeAttachmentText);
          return finding;
        })
        .catch(error => {
          this.loaderService.hide('navigationLoader');
          this.notificationsService.error(this.translation.notify.error.connectionTitle, this.translation.notify.error.removeAttachment);
          throw error;
        });
    }
    return Promise.reject(new Error('Cannot remove attachment'));
  }

  clickRemoveAttachment(attachment: Attachment): void {
    this.showRemoveModal(attachment).catch(() => {});
  }

  onLightboxEvent(event: LightboxEvent): void {
    if (event.option.id === 'remove') {
      let attachmentIndex = _.findIndex(this.separatedAttachments.boxes, {url: event.attachment.url});
      if (~attachmentIndex) {
        this.showRemoveModal(event.attachment)
          .then(() => {
            if (this.separatedAttachments.boxes.length > 0) {
              attachmentIndex = attachmentIndex < this.separatedAttachments.boxes.length ?
                attachmentIndex : attachmentIndex - 1;
              this.showLightbox(this.separatedAttachments.boxes[attachmentIndex]);
            } else {
              this.lightboxService.close();
            }
          })
          .catch(() => {});
      }
    }
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ACTION.CANCEL',
      'GLOBAL.ACTION.REMOVE',
      'FINDING_VIEW.FILES_SECTION.ERROR.UPLOAD_ATTACHMENT',
      'FINDING_VIEW.FILES_SECTION.ERROR.UPLOAD_ATTACHMENTS',
      'FINDING_VIEW.FILES_SECTION.ERROR.REMOVE_ATTACHMENT',
      'FINDING_VIEW.FILES_SECTION.SUCCESS.UPLOAD_ATTACHMENT_TITLE',
      'FINDING_VIEW.FILES_SECTION.SUCCESS.UPLOAD_ATTACHMENT_TEXT',
      'FINDING_VIEW.FILES_SECTION.SUCCESS.UPLOAD_ATTACHMENTS_TITLE',
      'FINDING_VIEW.FILES_SECTION.SUCCESS.UPLOAD_ATTACHMENTS_TEXT',
      'FINDING_VIEW.FILES_SECTION.SUCCESS.REMOVE_ATTACHMENT_TITLE',
      'FINDING_VIEW.FILES_SECTION.SUCCESS.REMOVE_ATTACHMENTS_TEXT',
      'FINDING_VIEW.FILES_SECTION.REMOVE_MODAL.HEADER',
      'FINDING_VIEW.FILES_SECTION.REMOVE_MODAL.CONTENT'
    ]).toPromise();
    this.attachmentOptions[0].name = translation['GLOBAL.ACTION.REMOVE'];
    this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.uploadAttachment = translation['FINDING_VIEW.FILES_SECTION.ERROR.UPLOAD_ATTACHMENT'];
    this.translation.notify.error.uploadAttachments = translation['FINDING_VIEW.FILES_SECTION.ERROR.UPLOAD_ATTACHMENTS'];
    this.translation.notify.error.removeAttachment = translation['FINDING_VIEW.FILES_SECTION.ERROR.REMOVE_ATTACHMENT'];
    this.translation.notify.success.uploadAttachmentTitle = translation['FINDING_VIEW.FILES_SECTION.SUCCESS.UPLOAD_ATTACHMENT_TITLE'];
    this.translation.notify.success.uploadAttachmentText = translation['FINDING_VIEW.FILES_SECTION.SUCCESS.UPLOAD_ATTACHMENT_TEXT'];
    this.translation.notify.success.uploadAttachmentsTitle = translation['FINDING_VIEW.FILES_SECTION.SUCCESS.UPLOAD_ATTACHMENTS_TITLE'];
    this.translation.notify.success.uploadAttachmentsText = translation['FINDING_VIEW.FILES_SECTION.SUCCESS.UPLOAD_ATTACHMENTS_TEXT'];
    this.translation.notify.success.removeAttachmentTitle = translation['FINDING_VIEW.FILES_SECTION.SUCCESS.REMOVE_ATTACHMENT_TITLE'];
    this.translation.notify.success.removeAttachmentText = translation['FINDING_VIEW.FILES_SECTION.SUCCESS.REMOVE_ATTACHMENTS_TEXT'];
    this.translation.removeDialog.header = translation['FINDING_VIEW.FILES_SECTION.REMOVE_MODAL.HEADER'];
    this.translation.removeDialog.content = translation['FINDING_VIEW.FILES_SECTION.REMOVE_MODAL.CONTENT'];
    this.translation.removeDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
  }
}

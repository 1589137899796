import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';

import Cropper from 'cropperjs';

@Component({
  selector: 'gw-image-cropper-view',
  exportAs: 'gwImageCropperView',
  templateUrl: './image-cropper-view.component.html',
  styleUrls: ['./image-cropper-view.component.scss']
})
export class ImageCropperViewComponent implements OnChanges {
  @ViewChild('cropper', { static: true }) cropperElement: ElementRef;
  @ViewChild('cropperPreview', { static: true }) cropperPreviewElement: ElementRef;
  @Input() base64: string;
  @Input() presetCropperOptions: Cropper.Options = new Object();
  @Input() displayOptions = {
    rounded: false,
    rectangled: false
  };
  cropper: Cropper;
  cropperOptions: Cropper.Options = new Object();
  cropperSettings: any;
  croppedPicture;

  constructor() {}

  ngOnChanges(): void {
    if (this.cropper) {
      this.cropper.destroy();
    }
    this.croppedPicture = this.cropperElement.nativeElement;
    this.resetCropperOptions();

    if (this.base64) {
      this.croppedPicture.src = this.base64;
      this.cropper = new Cropper(this.croppedPicture, this.cropperOptions);
    }
  }

  resetCropperOptions(): void {
    const getPresetOption = (key: string, defaultValue: any): any => {
      return this.presetCropperOptions && this.presetCropperOptions[key]
        ? this.presetCropperOptions[key]
        : defaultValue;
    };

    this.cropperOptions.preview = this.cropperPreviewElement.nativeElement;
    this.cropperOptions.aspectRatio = getPresetOption('aspectRatio', this.displayOptions.rectangled ? 3 / 1 : 1 / 1);
    this.cropperOptions.viewMode = getPresetOption('viewMode', 0);
    this.cropperOptions.zoomable = getPresetOption('zoomable', false);
    this.cropperOptions.scalable = getPresetOption('scalable', false);
    this.cropperOptions.autoCropArea = getPresetOption('autoCropArea', 1);
    this.cropperOptions.dragMode = getPresetOption('dragMode', 'move');
  }

  preparePicture(): string {
    return this.cropper.getCroppedCanvas().toDataURL();
  }
}

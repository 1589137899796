import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable()
export class PageStatusService {
  constructor(
    private router: Router
  ) { }

  goTo403(message?: string): void {
    this.goTo('403', message);
  }

  goTo404(message?: string): void {
    this.goTo('404', message);
  }

  private goTo(statusUrl: string, message?: string): void {
    const navigationExtras: NavigationExtras = {
      queryParams: { 'message': message }
    };
    this.router.navigate([statusUrl], navigationExtras);
  }
}

import { Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { Role } from '../../models/role.model';

import { RoleStyle } from '../../interfaces/role-style.interface';

@Component({
  selector: 'gw-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit, OnChanges, DoCheck {
  @Input() role: Role;
  @Input() active = false;
  @Input() edit = false;
  @Output() remove = new EventEmitter();
  @Output() undo = new EventEmitter();
  styles: RoleStyle;
  toRemove: boolean;

  constructor() {}

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(): void {
    this.init();
  }

  ngDoCheck(): void {
    if (this.toRemove !== this.role.action.remove) {
      this.init();
    }
  }

  init(): void {
    this.styles = this.getRoleStyles(this.role, this.active);
    this.toRemove = this.role.action.remove;
  }

  getRoleStyles(role: Role, active: boolean): RoleStyle {
    const hexToRgb = (hex: string) => {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          }
        : null;
    };

    if (role.color) {
      const rgb = hexToRgb(role.color);
      if (rgb) {
        const color = !(active || role.action.remove) ? role.color : undefined;
        const borderColor = !role.action.remove ? role.color : undefined;
        const opacity = active ? 1 : 0.15;
        const backgroundColor = !role.action.remove ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})` : undefined;

        return {
          color: color,
          'border-color': borderColor,
          'background-color': backgroundColor
        };
      }
    }
  }
}

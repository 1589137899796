import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { LoginOldApiService } from '../services/login-old-api.service';

@Injectable()
export class LoginOldApiGuard implements CanActivate {
  constructor(private loginOldApiService: LoginOldApiService, private router: Router) {}

  canActivate(): boolean {
    if (this.loginOldApiService.isLoggedIn()) {
      return true;
    }

    this.router.navigate(['login']);
    return false;
  }
}

import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

@Injectable()
export class ApiUrlService {
  api = environment.api;
  tokenApi = this.api + '/oauth';
  socketTokenApi = this.api + '/api/v1/sockets/token';
  userApi = this.api + '/api/v1/users';
  currentUserApi = this.api + '/api/v1/employees/current_employee';
  employeeApi = this.api + '/api/v1/employees';
  auditApi = this.api + '/api/v1/audits';
  auditedAreaApi = this.api + '/api/v1/audited_areas';
  auditSummariesApi = this.api + '/api/v1/audits_summaries/batch_update';
  auditTypeApi = this.api + '/api/v1/audit_types';
  facilityApi = this.api + '/api/v1/facilities';
  findingApi = this.api + '/api/v1/findings';
  findingTypeApi = this.api + '/api/v1/finding_types';
  questionnaireApi = this.api + '/api/v1/questionnaires';
  auditablesQuestionnaireApi = this.api + '/api/v1/questionnaire_auditables';
  sectionApi = this.api + '/api/v1/sections';
  sectionWithQuestionsApi = this.api + '/api/v1/sections_with_questions';
  sectionReorderApi = this.api + '/api/v1/composable_orderable/reorder_sections';
  questionApi = this.api + '/api/v1/questions';
  questionReorderApi = this.api + '/api/v1/composable_orderable/reorder_questions';
  statsApi = this.api + '/api/v1/statistics';
  solvingsApi = this.api + '/api/v1/solvings';
  ratingsApi = this.api + '/api/v1/ratings';
  ratingScalesApi = this.api + '/api/v1/rating_scales';
  questionRatingsApi = this.api + '/api/v1/question_ratings';
  questionMediaApi = this.api + '/api/v1/question_response_media';
  emailApi = this.api + '/api/v1/emails';
  reportApi = this.api + '/api/v1/reports';
  companyApi = this.api + '/api/v1/companies';
  roleApi = this.api + '/api/v1/roles';
  permissionCategoryApi = this.api + '/api/v1/permission_categories';
  userRolesApi = this.api + '/api/v1/user_roles';
  versionApi = this.api + '/api/v1/version';
  templateApi = this.api + '/api/v1/templates';
  dashboardApi = this.api + '/api/v1/dashboard';

  socket = environment.socket;
  webSocket = this.socket + '/cable/';
  webSocketAttachmentsChannel = 'PromoteAttachmentJobChannel';

  constructor() {}
}

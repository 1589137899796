import { Country } from './country.model';
import { ExtraFieldSimple } from './extra-field-simple.model';
import { Product } from './product.model';

export class ExtraField extends ExtraFieldSimple {
  changeProducts: Array<Product>;
  lawProducts: Array<Product>;
  requirementProducts: Array<Product>;
  countries?: Array<Country>;
  productsCount?: number;

  constructor(extraField: Partial<ExtraField>) {
    super(extraField);
    this.changeProducts = extraField ? extraField.changeProducts : undefined;
    this.lawProducts = extraField ? extraField.lawProducts : undefined;
    this.requirementProducts = extraField ? extraField.requirementProducts : undefined;
    this.countries = extraField ? extraField.countries : undefined;
    this.productsCount = extraField ? extraField.productsCount : undefined;
  }
}

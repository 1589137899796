import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuditCreationComponent } from './audit-creation/audit-creation.component';
import { AuditSummaryViewComponent } from './audit-summary/audit-summary-view.component';
import { AuditSummaryComponent } from './audit-summary/audit-summary/audit-summary.component';
import { AuditsListComponent } from './audits/audits-list/audits-list.component';
import { AuditsDraftsComponent } from './audits/audits-list/views/audits-drafts/audits-drafts.component';
import { AuditsFinishedComponent } from './audits/audits-list/views/audits-finished/audits-finished.component';
import { AuditsInProgressComponent } from './audits/audits-list/views/audits-in-progress/audits-in-progress.component';
import { TemplatesComponent } from './audits/audits-list/views/templates/templates.component';
import { AuditsComponent } from './audits/audits.component';
import { FindingsListComponent } from './audits/findings-list/findings-list.component';
import { FindingsAllComponent } from './audits/findings-list/views/findings-all/findings-all.component';
import { FindingsInProgressComponent } from './audits/findings-list/views/findings-in-progress/findings-in-progress.component';
import { FindingsNotAssignedComponent } from './audits/findings-list/views/findings-not-assigned/findings-not-assigned.component';
import { FindingsOverdueComponent } from './audits/findings-list/views/findings-overdue/findings-overdue.component';
import { FindingsResolvedComponent } from './audits/findings-list/views/findings-resolved/findings-resolved.component';
import { ConductAuditComponent } from './conduct-audit/conduct-audit.component';
import { FindingViewComponent } from './finding-view/finding-view.component';

import { CreateAuditGuard } from '../shared/guards/create-audit.guard';
import { LoginChildGuard } from '../shared/guards/login.child.guard';
import { OfflineChildGuard } from '../shared/guards/offline.child.guard';
import { OfflineGuard } from '../shared/guards/offline.guard';

import { AuditResolve } from './resolvers/audit.resolve';
import { AuditsAmountResolve } from './resolvers/audits.amount.resolve';
import { AuditsDraftsResolve } from './resolvers/audits.drafts.resolve';
import { AuditsFinishedResolve } from './resolvers/audits.finished.resolve';
import { AuditsInProgressResolve } from './resolvers/audits.in-progress.resolve';
import { EmployeesResolve } from './resolvers/employees.resolve';
import { FindingTypesResolve } from './resolvers/finding-types.resolve';
import { FindingResolve } from './resolvers/finding.resolve';
import { FindingsInProgressResolve } from './resolvers/findings-list.in-progress.resolve';
import { FindingsNotAssignedResolve } from './resolvers/findings-list.not-assigned.resolve';
import { FindingsOverdueResolve } from './resolvers/findings-list.overdue.resolve';
import { FindingsListResolve } from './resolvers/findings-list.resolve';
import { FindingsResolvedResolve } from './resolvers/findings-list.resolved.resolve';
import { SectionsWithQuestionsResolve } from './resolvers/sections-questions.resolve';
import { TemplatesResolve } from './resolvers/templates.resolve';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [
      LoginChildGuard
    ],
    children: [
      {
        path: '',
        component: AuditsComponent,
        children: [
          {
            path: '',
            redirectTo: 'audits',
            pathMatch: 'full'
          },
          {
            path: 'audits',
            component: AuditsListComponent,
            resolve: {
              auditsAmount: AuditsAmountResolve
            },
            children: [
              {
                path: '',
                redirectTo: 'in-progress',
                pathMatch: 'full'
              },
              {
                path: 'in-progress',
                component: AuditsInProgressComponent,
                resolve: {
                  auditsInProgress: AuditsInProgressResolve
                }
              },
              {
                path: 'drafts',
                component: AuditsDraftsComponent,
                resolve: {
                  auditsDrafts: AuditsDraftsResolve
                }
              },
              {
                path: 'finished',
                component: AuditsFinishedComponent,
                resolve: {
                  auditsFinished: AuditsFinishedResolve
                }
              },
              {
                path: 'templates',
                component: TemplatesComponent,
                resolve: {
                  templates: TemplatesResolve
                }
              }
            ]
          },
          {
            path: 'findings',
            component: FindingsListComponent,
            canActivateChild: [
              OfflineChildGuard
            ],
            children: [
              {
                path: '',
                component: FindingsAllComponent,
                resolve: {
                  findings: FindingsListResolve
                }
              },
              {
                path: 'in-progress',
                component: FindingsInProgressComponent,
                resolve: {
                  findings: FindingsInProgressResolve
                }
              },
              {
                path: 'overdue',
                component: FindingsOverdueComponent,
                resolve: {
                  findings: FindingsOverdueResolve
                }
              },
              {
                path: 'not-assigned',
                component: FindingsNotAssignedComponent,
                resolve: {
                  findings: FindingsNotAssignedResolve
                }
              },
              {
                path: 'resolved',
                component: FindingsResolvedComponent,
                resolve: {
                  findings: FindingsResolvedResolve
                }
              }
            ]
          },
        ]
      },
      {
        path: 'create-audit',
        component: AuditCreationComponent,
        canActivate: [
          OfflineGuard,
          CreateAuditGuard
        ]
      },
      {
        path: 'edit-audit/:auditId',
        component: AuditCreationComponent,
        resolve: {
          audit: AuditResolve
        },
        canActivate: [
          OfflineGuard
        ]
      },
      {
        path: 'conduct-audit/:auditId/:auditedAreaId',
        component: ConductAuditComponent,
        resolve: {
          audit: AuditResolve,
          sections: SectionsWithQuestionsResolve
        }
      },
      {
        path: 'audit-summary/:auditId',
        component: AuditSummaryViewComponent,
        resolve: {
          audit: AuditResolve
        },
        children: [
          {
            path: '',
            component: AuditSummaryComponent,
            resolve: {
              findingTypes: FindingTypesResolve,
              employees: EmployeesResolve
            }
          },
          {
            path: ':auditedAreaId',
            component: AuditSummaryComponent,
            resolve: {
              sections: SectionsWithQuestionsResolve,
              findingTypes: FindingTypesResolve,
              employees: EmployeesResolve
            }
          },
        ]
      },
      {
        path: 'finding/:id',
        component: FindingViewComponent,
        resolve: {
          finding: FindingResolve
        },
        canActivate: [
          OfflineGuard
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuditRoutingModule { }

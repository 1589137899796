import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { TreeNodeZip } from '../../models/tree-node.model';

import { Size } from '../../types/size.type';

import { sortTreeByName } from '../../utils/tree.util';

@Component({
  selector: 'gw-preview-nested-elements',
  templateUrl: './preview-nested-elements.component.html',
  styleUrls: ['./preview-nested-elements.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewNestedElementsComponent {
  @HostBinding('class.with-margin-bottom') @Input() withMarginBottom = true;

  @Input() fontSize?: Size = 'small';
  @Input() isUnit?: boolean;
  @Input() disabled?: boolean;
  @Input() isEditMode = true;
  @Input() totalTrees = 0;
  @Input() withSquare?: boolean;
  @Input() boldMainItem?: boolean;
  @Input() defaultSquareColor?: string;
  @Input() isClientUserType?: boolean;
  @Input() set selectedTrees(selectedTrees: Array<TreeNodeZip>) {
    if (selectedTrees) {
      this._selectedTrees = sortTreeByName([...selectedTrees]);
      this.firstSelectedTree = this.selectedTrees?.[0];
    }
  }
  get selectedTrees(): Array<TreeNodeZip> {
    return this._selectedTrees;
  }
  _selectedTrees: Array<TreeNodeZip>;

  firstSelectedTree: TreeNodeZip;
}

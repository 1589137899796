import { Employee } from './employee.model';
import { PermissionCategory } from './permission-category.model';

export class Role {
  id: string;
  name: string;
  color: string;
  usersCount: number;
  users?: Array<Employee>;
  permissionCategories?: Array<PermissionCategory>;
  action: {
    add: boolean;
    remove: boolean;
  };

  constructor(role?: Partial<Role>) {
    this.id = role ? role.id : undefined;
    this.name = role ? role.name : undefined;
    this.color = role ? role.color : undefined;
    this.usersCount = role ? role.usersCount : undefined;
    this.users = role ? this.generatUsers(role.users) : undefined;
    this.permissionCategories = role ? this.generatePermissionCategories(role.permissionCategories) : undefined;
    this.action = role ? this.generateActions(role.action) : { add: false, remove: false };
  }

  private generatUsers(users: Array<any>): Array<Employee> {
    if (users) {
      return users.map(user => new Employee(user));
    }
  }

  private generatePermissionCategories(permissionCategories: Array<any>): Array<PermissionCategory> {
    if (permissionCategories) {
      return permissionCategories.map(permissionCategory => new PermissionCategory(permissionCategory));
    }
  }

  private generateActions(action: { add: boolean; remove: boolean }): { add: boolean; remove: boolean } {
    return {
      add: action ? action.add : false,
      remove: action ? action.remove : false
    };
  }

  getPermissionIds(onlyAdded = true): Array<string> {
    if (this.permissionCategories) {
      return this.permissionCategories
        .map(category => {
          if (category.permissions) {
            return category.permissions
              .filter(permission => !onlyAdded || permission.added)
              .map(permission => permission.id);
          }
        })
        .reduce((previous, current) => previous.concat(current), []);
    }
    return [];
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { Requirement } from '../../models/requirement.model';
import { StatusLCConsultant } from '../../models/status-lc-consultant.model';

import { StatusId } from '../../enums/status-id.enum';
import { UserType } from '../../enums/user-type.enum';

import { ExpandBoxComponent } from '../expand-box/expand-box.component';

import { HelperUtil } from '../../utils/helper.util';

@Component({
  selector: 'gw-requirement-box',
  templateUrl: './requirement-box.component.html',
  styleUrls: ['./requirement-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequirementBoxComponent implements OnInit {
  @ViewChild('requirementExpandBox', { static: true }) expandBox: ExpandBoxComponent;
  @Input() requirement: Requirement;
  @Input() isSelected = false;
  @Input() isExpanded: boolean;
  @Input() isFirst: boolean;
  @Input() isEven: boolean;
  @Input() checkBoxTooltip?: string;
  @Input() isRemovable?: boolean;
  @Input() hasHistory = false;
  @Input() hasUnitIcon?: boolean;
  @Input() selectable = false;
  @Input() comparable = false;
  @Input() noMargin = false;
  @Input() removeLabel?: string;
  @Input() changeableStatus = false;
  @Input() statuses?: Array<StatusLCConsultant>;
  @Input() userType: UserType;
  @Input() detailsRoute = '/laws/requirements';
  @Input() defaultCategoryColor?: string;
  @Input() hasActionMenu?: boolean;
  @Input() hasRatingBigHeight?: boolean;

  @Output() selectRequirement = new EventEmitter<{ requirement: Requirement; value: boolean }>();
  @Output() statusChange = new EventEmitter<StatusLCConsultant>();
  @Output() removeRequirement = new EventEmitter<Requirement>();
  @Output() goToDetails = new EventEmitter<Requirement>();

  disabled: boolean;
  showContainerIcon: boolean;
  showTopSectionInDetails: boolean;
  showComparison: boolean;

  readonly TRANSLATION_KEYS = {
    TITLE: marker('GUTWIN_SHARED.LABEL.TITLE'),
    SUMMARY: marker('GUTWIN_SHARED.LABEL.SUMMARY'),
    EXPLANATION: marker('GUTWIN_SHARED.LABEL.EXPLANATION'),
    DIFFERENCES: marker('GUTWIN_SHARED.LABEL.DIFFERENCES')
  };

  ngOnInit(): void {
    if (this.selectable && this.userType === UserType.consultant) {
      this.disabled = this.checkIfDisabled();
      this.showContainerIcon = !!this.requirement.containerId && this.requirement.status.id !== StatusId.published;
      this.showComparison = this.checkIfShowComparison();
      this.showTopSectionInDetails = this.checkIfShowTopSectionInDetails();
    }
  }

  checkIfDisabled(): boolean {
    return this.requirement.containerId && this.requirement.status.id !== StatusId.published;
  }

  checkIfShowComparison(): boolean {
    return (
      this.comparable &&
      HelperUtil.areValuesDifferent<Requirement>(this.requirement, this.requirement.previousVersion, [
        'displayName',
        'summary',
        'explanation'
      ])
    );
  }

  checkIfShowTopSectionInDetails(): boolean {
    return this.hasHistory || this.checkIfShowComparison();
  }

  onToggleSelect(): void {
    this.selectRequirement.emit({ requirement: this.requirement, value: this.isSelected });
  }

  onChangeStatus(status: StatusLCConsultant): void {
    this.statusChange.emit(status);
  }

  onRemoveRequirement(): void {
    this.removeRequirement.emit(this.requirement);
  }

  showDetails(): void {
    this.goToDetails.emit(this.requirement);
  }
}

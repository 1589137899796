import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gw-findings-resolved',
  templateUrl: './findings-resolved.component.html',
  styleUrls: ['./findings-resolved.component.scss']
})
export class FindingsResolvedComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}

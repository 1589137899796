import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { TreeFilterOption } from '../../models/tree-filter-option.model';
import { TreeNodeZip } from '../../models/tree-node.model';

import { MultiselectTheme } from '../../enums/multiselect-theme.enum';
import { SelectIconTheme } from '../../enums/select-icon-theme.enum';

import { TreeNodesIds } from '../../types/tree-nodes-ids.type';

import { TreeService } from '../../services/tree.service';

@Component({
  selector: 'gw-searchable-flat-tree-multiselect',
  templateUrl: './searchable-flat-tree-multiselect.component.html',
  styleUrls: ['./searchable-flat-tree-multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchableFlatTreeMultiselectComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchableFlatTreeMultiselectComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() set data(data: Array<any>) {
    this._data = data;
    this.initConvertedData();
  }
  get data(): Array<any> {
    return this._data;
  }
  _data: Array<any>;
  @Input() display: Array<string>;
  @Input() uniqueKey: string;
  @Input() placeholder: string;
  @Input() tabindex: number;
  @Input() splitter = ' ';
  @Input() small = false;
  @Input() containWidth = true;
  @Input() markSelectedChildren = false;
  @Input() showConnectedElements?: boolean;
  @Input() theme?: MultiselectTheme;
  @Input() iconTheme?: SelectIconTheme;
  @Input() lastNodeMargin?: boolean;
  @Input() withBorder?: boolean;
  @Input() tooltipTemplateRef?: TemplateRef<any>;
  @Input() withCloseButton?: boolean;
  @Input() withSearchIcon?: boolean;
  @Input() withTreeMarginTop?: boolean;

  @Output() closeDropdown = new EventEmitter<void>();

  selected: TreeNodesIds;
  selectedNodes: Array<TreeNodeZip>;
  nodes: Array<TreeFilterOption>;
  hasVisibleNodes: boolean;
  searchQuery: string;
  disabled: boolean;
  searchChanged = new Subject<string>();

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private treeService: TreeService, private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initConvertedData();
    this.observeSearch();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  writeValue(value: TreeNodesIds): void {
    this.initSelectedData(value);
  }

  onChange: (_: TreeNodesIds) => void = () => {};

  registerOnChange(fn: (_: TreeNodesIds) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  initConvertedData(): void {
    this.nodes = this.display && this.uniqueKey ? this.convertData(this.data) : [];
    this.initSelectedData(this.selected);
    this.initSearchQueryData();
    this.changeDetector.markForCheck();
  }

  initSelectedData(selected: TreeNodesIds): void {
    this.selected = selected || {};
    this.changeDetector.markForCheck();
  }

  initSearchQueryData(): void {
    this.nodes = this.clearNodes(this.nodes);
    if (this.searchQuery) {
      const { hasFilteredNodes, filteredNodes } = this.filterNodes(this.searchQuery, this.nodes);
      this.nodes = this.markFilteredNodes(this.nodes, filteredNodes);
      this.hasVisibleNodes = hasFilteredNodes;
    } else {
      this.hasVisibleNodes = !!this.nodes.length;
    }
    this.changeDetector.markForCheck();
  }

  convertData(data: Array<any>): Array<TreeFilterOption> {
    return data
      ? data.map(item => {
          return new TreeFilterOption({
            id: item[this.uniqueKey],
            name: this.getDisplay(item),
            isBlocked: item?.blocked ?? false,
            isSelected: item?.selected ?? false,
            isOpened: item?.isOpened ?? false,
            onPathToSelected: false,
            chosenElements: item?.chosenElements,
            connectedElements: item?.connectedElements ?? 0,
            question: item?.question,
            children: this.convertData(item.children)
          });
        })
      : [];
  }

  checkSelected(nodes: Array<TreeFilterOption>, selected: TreeNodesIds): Array<TreeFilterOption> {
    return this.treeService.markNodes<TreeFilterOption>(nodes, selected, 'isSelected', 'onPathToSelected');
  }

  filterNodes(
    query = '',
    nodes: Array<TreeFilterOption>,
    parentFitsQuery?: boolean,
    currentPath = [],
    filteredNodes: TreeNodesIds = {}
  ): { hasFilteredNodes: boolean; filteredNodes: TreeNodesIds } {
    let hasFilteredNodes = false;
    query = query.toLowerCase();
    if (nodes) {
      nodes.forEach(node => {
        const searchKey = node.name.toLowerCase();
        const fitQuery = !!(searchKey.indexOf(query) !== -1);
        const childrenResponse = this.filterNodes(
          query,
          node.children,
          fitQuery || parentFitsQuery,
          [...currentPath, node.id],
          filteredNodes
        );
        node.hidden = !parentFitsQuery && !fitQuery && !childrenResponse.hasFilteredNodes;

        if (!node.hidden) {
          hasFilteredNodes = true;
        }
        if (fitQuery) {
          filteredNodes[node.id] = currentPath;
        }
      });
    }
    return { hasFilteredNodes, filteredNodes };
  }

  markFilteredNodes(nodes: Array<TreeFilterOption>, filteredNodes: TreeNodesIds): Array<TreeFilterOption> {
    return this.treeService.markNodes<TreeFilterOption>(nodes, filteredNodes, 'isFiltered', 'onPathToFiltered');
  }

  clearNodes(nodes: Array<TreeFilterOption>): Array<TreeFilterOption> {
    return this.treeService.clearNodes<TreeFilterOption>(nodes, ['hidden', 'isFiltered', 'onPathToFiltered']);
  }

  getDisplay(item: any): string {
    let display = '';
    this.display.forEach(displayKey => {
      const displayValue = displayKey.split('.').reduce((object, key) => (object ? object[key] : undefined), item);
      if (displayValue) {
        display += (display.length ? this.splitter : '') + displayValue;
      }
    });
    return display;
  }

  onUpdateSelectedNodes(selected: TreeNodesIds): void {
    this.selected = selected;
    this.onChange(this.selected);
    this.changeDetector.detectChanges();
  }

  observeSearch(): void {
    this.searchChanged.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(search => {
      this.searchQuery = search;
      this.initSearchQueryData();
    });
  }

  changeSearch(search: string): void {
    this.searchChanged.next(search);
  }
}

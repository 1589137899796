import { Company } from '../../../../models/company.model';
import { EmployeeExtended } from '../../../../models/employee-extended.model';
import { Employee } from '../../../../models/employee.model';

export class CurrentUser extends Employee {
  id: string;
  employees: Array<EmployeeExtended>;
  company: Company;

  constructor(currentUser: Partial<CurrentUser>) {
    super(currentUser);
    Object.assign(this, currentUser);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Employee } from '../../models/employee.model';

import { MultiselectTheme } from '../../enums/multiselect-theme.enum';

@Component({
  selector: 'gw-role-users',
  templateUrl: './role-users.component.html',
  styleUrls: ['./role-users.component.scss'],
  exportAs: 'gwRoleUsers',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleUsersComponent implements OnInit {
  @Input() set allUsers(allUsers: Array<Employee>) {
    this._allUsers = allUsers;
    this.initUnassignedUsers();
  }
  get allUsers(): Array<Employee> {
    return this._allUsers;
  }
  _allUsers: Array<Employee>;
  @Input() set selectedUsers(selectedUsers: Array<Employee>) {
    this._selectedUsers = selectedUsers;
    this.initUnassignedUsers();
  }
  get selectedUsers(): Array<Employee> {
    return this._selectedUsers;
  }
  _selectedUsers: Array<Employee>;
  @Input() even: boolean;
  @Input() showMultiselect?: boolean;
  @Input() noInputBorder = true;

  @Output() addUserToRole = new EventEmitter<Array<Employee>>();
  @Output() removeUserFromRole = new EventEmitter<Employee>();

  usersNotAssigned: Array<Employee>;
  addUserRoleForm: FormGroup;
  userRoleFormFlags = {
    visible: false,
    submitted: false
  };

  readonly MULTISELECT_THEME_CLASSIC = MultiselectTheme.classic;

  constructor(private changeDetector: ChangeDetectorRef, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initUnassignedUsers(): void {
    this.usersNotAssigned =
      this.selectedUsers && this.allUsers
        ? this.allUsers.filter(({ id }) => !this.selectedUsers.find(selectedUser => id === selectedUser.id))
        : this.allUsers;
    this.changeDetector.markForCheck();
  }

  initForm(): void {
    this.addUserRoleForm = this.formBuilder.group({
      user: ['', Validators.required]
    });
  }

  resetForm(): void {
    this.addUserRoleForm.reset({ user: '' });
    this.userRoleFormFlags.submitted = false;
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.userRoleFormFlags.submitted;
  }

  submitAddUserForm(form: FormGroup): void {
    this.userRoleFormFlags.submitted = true;
    if (form.valid) {
      this.addUserToRole.emit([].concat(form.value.user));
      this.toggleAddUserForm();
      this.resetForm();
    }
  }

  cancelAddUserForm(): void {
    this.resetForm();
    this.toggleAddUserForm();
  }

  toggleAddUserForm(): void {
    this.userRoleFormFlags.visible = !this.userRoleFormFlags.visible;
  }
}

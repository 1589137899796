import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TreeFilterOption } from '../../models/tree-filter-option.model';

import { FlatTreeNode } from '../../interfaces/flat-tree-node.interface';

import { TreeNodesIds } from '../../types/tree-nodes-ids.type';

import { FLAT_TREE_DEFAULT_HEIGHT } from '../../constants/flat-tree-height.constants';

@Component({
  selector: 'gw-flat-tree-select',
  templateUrl: 'flat-tree-select.component.html',
  styleUrls: ['flat-tree-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlatTreeSelectComponent),
      multi: true
    }
  ]
})
export class FlatTreeSelectComponent implements ControlValueAccessor {
  @Input() markSelectedChildren?: boolean;
  @Input() data: Array<TreeFilterOption>;
  @Input() height = FLAT_TREE_DEFAULT_HEIGHT;

  set selectedNodesIds(selectedNodesIds: TreeNodesIds) {
    this._selectedNodesIds = selectedNodesIds;
    this.selectedNodeId = selectedNodesIds ? Object.keys(selectedNodesIds)[0] : undefined;
  }
  get selectedNodesIds(): TreeNodesIds {
    return this._selectedNodesIds;
  }
  _selectedNodesIds?: TreeNodesIds;
  selectedNodeId?: string;

  constructor(private changeDetector: ChangeDetectorRef) {}

  onChange: (_: TreeNodesIds) => void = () => {};

  writeValue(value: TreeNodesIds): void {
    this.selectedNodesIds = value;
    this.changeDetector.markForCheck();
  }

  registerOnChange(fn: (_: TreeNodesIds) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  onSelectNode(node: FlatTreeNode, selected: boolean): void {
    this.selectedNodesIds = selected ? { [node.id]: node.path } : undefined;
    this.onChange(this.selectedNodesIds);
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { groupBy, values } from 'lodash';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';

// Gutwin Shared Library
// Service
import { ModalService } from 'gutwin-shared';

// Models
import { Audit } from '../../../shared/models/audit.model';
import { AuditedArea } from '../../../shared/models/audited-area.model';
import { Question } from '../../../shared/models/question.model';
import { Section } from '../../../shared/models/section.model';

import { AddQuestionComponent } from '../../../shared/components/add-question/add-question.component';
// Components
import { AddSectionComponent } from '../../../shared/components/add-section/add-section.component';
import { EditQuestionsComponent } from '../edit-questions/edit-questions.component';

import { QuestionService } from '../../../shared/services/question.service';
// Services
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';
import { SectionService } from '../../../shared/services/section.service';

@Component({
  selector: 'gw-step-4-audit-questions',
  templateUrl: './step-4-audit-questions.component.html',
  styleUrls: ['./step-4-audit-questions.component.scss']
})
export class Step4AuditQuestionsComponent implements OnInit, OnDestroy {
  @ViewChild('addSection') addSection: AddSectionComponent;
  @ViewChild('addQuestion') addQuestion: AddQuestionComponent;
  @ViewChild('editQuestions') editQuestions: EditQuestionsComponent;
  @Output() submitStep = new EventEmitter<any>();
  @Output() updateAudit = new EventEmitter<any>();
  @Output() updateStorage = new EventEmitter<any>();
  @Input() audit: Audit;
  @Input() auditObservable: Observable<Audit>;
  groupedAuditedArea: Array<Array<AuditedArea>>;
  auditableForModal: Audit | AuditedArea;
  isGeneralQuestionVisible = false;
  translateSubscription: Subscription;
  notify = {
    error: {
      connectionTitle: '',
      loadQuestionnaire: '',
      loadSelectedSections: ''
    }
  };

  constructor(
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    public modalService: ModalService,
    private questionnaireService: QuestionnaireService,
    private sectionService: SectionService,
    private questionService: QuestionService
  ) {}

  ngOnInit(): void {
    this.fetchTranslation();
    this.initData();
    this.auditObservable.subscribe(audit => {
      this.audit = audit;
      this.initData();
    });
  }

  ngOnDestroy(): void {
    this.translateSubscription.unsubscribe();
  }

  async initData(): Promise<void> {
    this.groupedAuditedArea = this.getGroupedAuditedAreas();
    await this.fetchAuditSelectedSections();
    this.isGeneralQuestionVisible = this.shouldGeneralQuestionBeVisible();
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'AUDIT_CREATION.ERROR.LOAD_QUESTIONNAIRE',
        'AUDIT_CREATION.ERROR.LOAD_SELECTED_SECTIONS'
      ])
      .subscribe((translation: any) => {
        this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.loadQuestionnaire = translation['AUDIT_CREATION.ERROR.LOAD_QUESTIONNAIRE'];
        this.notify.error.loadSelectedSections = translation['AUDIT_CREATION.ERROR.LOAD_SELECTED_SECTIONS'];
      });
  }

  showModalForSection(section?: Section): void {
    this.modalService.open('sectionModal', section);
  }

  showModalForQuestion(section?: Section, question?: Question): void {
    this.modalService.open('questionModal', {
      question,
      section
    });
  }

  getGroupedAuditedAreas(): Array<Array<AuditedArea>> {
    const auditedAreas = this.audit ? this.audit.auditedAreas || [] : [];
    this.sortByDate(auditedAreas);
    return this.groupByDate(auditedAreas);
  }

  fetchAuditSelectedSections(): void {
    if (this.audit) {
      this.fetchSelectedSections(this.audit);
      if (this.audit.auditedAreas) {
        this.audit.auditedAreas.forEach(auditedArea => {
          this.fetchSelectedSections(auditedArea);
        });
      }
    }
  }

  async fetchSelectedSections(auditable: Audit | AuditedArea): Promise<void> {
    auditable.selectedSections = await this.sectionService.getSelectedSectionsWithQuestions(auditable).catch(error => {
      this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.loadSelectedSections);
      return [];
    });
    auditable.customSections = this.sectionService.getCustomSections(auditable.selectedSections);
    auditable.selectedQuestionsAmount = auditable.countSelectedQuestions();
  }

  shouldGeneralQuestionBeVisible(): boolean {
    return (
      this.audit &&
      (this.audit.selectedQuestionsAmount > 0 || (this.audit.auditedAreas && this.audit.auditedAreas.length > 1))
    );
  }

  isDateEqual(date1: moment.Moment, date2: moment.Moment): boolean {
    const groupDate = moment(date1).format('YYYY-MM-DD');
    const auditDate = moment(date2).format('YYYY-MM-DD');
    return groupDate === auditDate;
  }

  groupByDate(auditedAreas: Array<AuditedArea>): Array<Array<AuditedArea>> {
    const groupsObject = values(
      groupBy(auditedAreas, (auditedArea: AuditedArea) => {
        return moment(auditedArea.startTime).format('YYYY-MM-DD'); // ISO format;
      })
    );
    return groupsObject;
  }

  sortByDate(collection: any, isNested?: boolean): void {
    collection.sort((a, b) => {
      const objectA = isNested ? a[0] : a;
      const objectB = isNested ? b[0] : b;
      const dateA = moment(objectA.startDate, 'YYYY-MM-DD HH:mm');
      const dateB = moment(objectB.startDate, 'YYYY-MM-DD HH:mm');
      if (dateA.isBefore(dateB)) {
        return -1;
      }
      if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    });
  }

  hasAuditedAreaQuestions(auditedArea: AuditedArea): boolean {
    return auditedArea && auditedArea.selectedSections && auditedArea.countSelectedQuestions() > 0;
  }

  addGeneralQuestions(): void {
    this.auditableForModal = this.audit;
    this.modalService.open('questionsCanvas');
  }

  addAuditedAreaQuestions(auditedArea: AuditedArea): void {
    this.auditableForModal = auditedArea;
    this.modalService.open('questionsCanvas');
  }

  resetAllQuestions(): void {
    this.editQuestions.clearAll();
    this.modalService.close('questionsResetModal');
  }

  mergeData(formData, auditedAreas): any {
    const mergedData = Object.assign({}, formData);
    mergedData.auditedAreas = auditedAreas;
    return mergedData;
  }

  saveQuestionnaireModal(): void {
    this.fetchAuditSelectedSections();
    this.modalService.close('questionsCanvas');
  }

  saveSectionModal(): void {
    this.addSection
      .submitAddSectionForm()
      .then(sectionResponse => {
        if (!sectionResponse.addAnother) {
          this.modalService.close('sectionModal');
        }
        this.fetchSelectedSections(this.auditableForModal);
      })
      .catch(error => {});
  }

  saveQuestionModal(): void {
    this.addQuestion
      .submitAddQuestionForm()
      .then(questionResponse => {
        if (!questionResponse.addAnother) {
          this.modalService.close('questionModal');
        }
        this.fetchSelectedSections(this.auditableForModal);
      })
      .catch(error => {});
  }

  saveStep(): void {
    this.submitStep.emit({
      goToStepId: 4
    });
  }
}

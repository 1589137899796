import { Pipe, PipeTransform } from '@angular/core';

import { LegalContainer } from '../models/legal-container.model';

import { LegalChangeDetailsTabs } from '../enums/legal-change-details-tabs.enum';

@Pipe({
  name: 'getContainerDetailsPath'
})
export class GetContainerDetailsPathPipe implements PipeTransform {
  public transform(detailsRoute: string, container: LegalContainer, isUserTypeClient: boolean): string {
    const { hasMajor, hasMinor } = container;
    const baseUrl = `${detailsRoute}/${container.id}`;
    const tabToDisplay = !hasMajor && hasMinor ? LegalChangeDetailsTabs.minor : LegalChangeDetailsTabs.major;

    return isUserTypeClient ? `${baseUrl}/${tabToDisplay}` : baseUrl;
  }
}

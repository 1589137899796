import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
// Models
import { Audit } from '../models/audit.model';
import { AuditedArea } from './../models/audited-area.model';
// Mocks
import { AuditeAreaMock } from './../mocks/audited-area';
// Services
import { ApiUrlService } from './api-url.service';
import { AuditConvertService, AuditedAreaResponse, AuditResponse } from './audit.convert.service';
import { AuditOfflineService } from './audit.offline.service';
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';

@Injectable()
export class AuditedAreaService {
  private auditeAreaMock = new AuditeAreaMock();

  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private auditConvertService: AuditConvertService,
    private auditOfflineService: AuditOfflineService,
    private storageService: StorageModuleService
  ) { }

  getAuditedArea(id: string, auditId?: string): Promise<AuditedArea> {
    return this.http.get(`${this.apiUrlService.auditedAreaApi}/${id}`)
      .toPromise()
      .then((data: AuditedAreaResponse) => {
        return this.auditConvertService.convertAuditedAreaToGet(data);
      })
      .catch(async(error) => {
        if (error.status === 0 && auditId) {
          const data = await this.getAuditedAreaFromOfflineStore(auditId, id);
          return this.auditConvertService.convertAuditedAreaToGet(data);
        }
        console.error('Error while getting audited area', error);
        throw error;
      });
  }

  createAuditedArea(parentAuditId: string, auditedArea: AuditedArea): Promise<AuditedArea> {
    if (!auditedArea) {
      return new Promise((resolve, reject) => reject());
    }
    return this.http.post(
      this.apiUrlService.auditedAreaApi,
      this.auditConvertService.convertedAuditedAreaToPost(parentAuditId, auditedArea)
    )
      .toPromise()
      .then((data: any) => {
        this.updateAuditedAreaInOfflineStore(data, 'append');
        return this.auditConvertService.convertAuditedAreaToGet(data);
      })
      .catch(error => {
        console.error('Error while creating audited area', error);
        throw error;
      });
  }

  updateAuditedArea(auditedArea: AuditedArea, oldAuditedArea?: AuditedArea): Promise<AuditedArea> {
    if (!auditedArea) {
      return new Promise((resolve, reject) => reject());
    }
    return this.http.put(
      `${this.apiUrlService.auditedAreaApi}/${auditedArea.id}`,
      this.auditConvertService.convertedAuditedAreaToPut(auditedArea, oldAuditedArea)
    )
      .toPromise()
      .then((data: any) => {
        this.updateAuditedAreaInOfflineStore(data, 'update');
        return this.auditConvertService.convertAuditedAreaToGet(data);
      })
      .catch(error => {
        console.error('Error while updating audited area', error);
        throw error;
      });
  }

  updateAuditedAreaSummary(auditId: string, auditedArea: AuditedArea): Promise<{data: AuditedArea, offline?: boolean}> {
    return this.http.put(`${this.apiUrlService.auditedAreaApi}/${auditedArea.id}`, {summary: auditedArea.summary})
      .toPromise()
      .then((data: any) => {
        this.updateAuditedAreaInOfflineStore(data, 'update');
        this.auditOfflineService.removeAuditedAreaRequestFromOfflineStore(auditId, auditedArea.id);
        return { data: this.auditConvertService.convertAuditedAreaToGet(data) };
      })
      .catch(error => {
        if (error.status === 0) {
          this.auditOfflineService.updateAuditedAreaSummaryRequestInOfflineStore(auditId, auditedArea.id, auditedArea.summary);
          auditedArea.summaryOffline = auditedArea.summaryOffline !== undefined ? auditedArea.summaryOffline : auditedArea.summary;
          return { data: auditedArea, offline: true };
        }
        console.error('Error while updating audited area summary', error);
        throw error;
      });
  }

  removeAuditedArea(auditId: string, auditedArea: AuditedArea): Promise<any> {
    if (!auditedArea) {
      return new Promise((resolve, reject) => reject());
    }
    return this.http.delete(`${this.apiUrlService.auditedAreaApi}/${auditedArea.id}`)
      .toPromise()
      .then((data: any) => {
        const auditedAreaToRemove = {
          id: auditedArea.id,
          audit_id: auditId
        };
        this.updateAuditedAreaInOfflineStore(auditedAreaToRemove, 'remove');
        return data;
      })
      .catch(error => {
        console.error('Error while removing audited area', error);
        throw error;
      });
  }

  private getAuditedAreaFromOfflineStore(auditId: string, auditedAreaId: string): Promise<AuditedAreaResponse> {
    const getAuditedArea = (data: AuditResponse): AuditedAreaResponse => {
      return _.find(data.audited_areas, {id: auditedAreaId});
    };

    return this.storageService.getOfflineStore(StoragesNamesModule.audit + auditId)
      .then((data: AuditResponse) => {
        if (data !== null) {
          return getAuditedArea(data);
        }
      });
  }

  private updateAuditedAreaInOfflineStore(auditedArea: AuditedAreaResponse, action: string): Promise<any> {
    const updateAuditedArea = (data: AuditResponse): AuditResponse => {
      const auditedAreaIndex = _.findIndex(data.audited_areas, {id: auditedArea.id});
      if (~auditedAreaIndex) {
        switch (action) {
          case 'append':
          case 'update':
            data.audited_areas[auditedAreaIndex] = auditedArea;
            break;
          case 'remove':
            data.audited_areas.splice(auditedAreaIndex, 1);
            break;
        }
      } else if (action === 'append') {
        data.audited_areas.push(auditedArea);
      }
      return data;
    };

    return this.storageService.getOfflineStore(StoragesNamesModule.audit + auditedArea.audit_id)
      .then((data: AuditResponse) => {
        if (data !== null) {
          const updatedData = updateAuditedArea(data);
          this.storageService.setOfflineStore(StoragesNamesModule.audit + auditedArea.audit_id, updatedData);
          this.auditOfflineService.updateAuditInOfflineStore(updatedData);
        }
      });
  }
}

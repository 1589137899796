import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { TreeFilterOption } from '../../../models/tree-filter-option.model';

import { ListTreeFilter } from '../../../interfaces/filter.interface';
import { NestedObjectOfIds } from '../../../interfaces/nested-object-of-ids.interface';

import { ExpandBoxComponent } from '../../expand-box/expand-box.component';

import { getSelectedNodeIds } from '../../../utils/tree.util';

@Component({
  selector: 'gw-tree-multiselect-filter',
  templateUrl: './tree-multiselect-filter.component.html',
  styleUrls: ['./tree-multiselect-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeMultiselectFilterComponent {
  @ViewChild('expandBox', { static: true }) expandBox: ExpandBoxComponent;

  @Input() label: string;
  @Input() options: Array<TreeFilterOption>;
  @Input() subnodesButton: { label: string; value?: boolean };
  @Input() isParentFullyClosed?: boolean;

  @Output() updateFilter = new EventEmitter<ListTreeFilter>();

  updateFilters(options: Array<TreeFilterOption>): void {
    this.updateFilter.emit({
      value: getSelectedNodeIds(options, 'isSelected') as NestedObjectOfIds<string>,
      includeSubnodes: this.subnodesButton ? this.subnodesButton.value : false
    });
  }
}

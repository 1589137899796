import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogOverlayRef } from '../overlay/dialog-overlay-ref';

@Component({
  selector: 'gw-crop-avatar-modal',
  templateUrl: './crop-avatar-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CropAvatarModalComponent {
  constructor(public dialogOverlayRef: DialogOverlayRef) {}

  close(image?: string): void {
    this.dialogOverlayRef.close(image);
  }

  save(image: string): void {
    this.close(image);
  }
}

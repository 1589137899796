import { Status, StatusAssigned } from './status.model';

import { StatusId } from '../enums/status-id.enum';

export class StatusLCConsultant extends Status {
  id: StatusId;

  constructor(status: StatusLCConsultant) {
    super(status);
    Object.assign(this, status);
  }
}

export class AssignedStatusLCConsultant extends StatusAssigned {
  id: StatusId;

  constructor(status: AssignedStatusLCConsultant) {
    super(status);
  }
}

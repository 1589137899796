import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gw-findings-all',
  templateUrl: './findings-all.component.html',
  styleUrls: ['./findings-all.component.scss']
})
export class FindingsAllComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}

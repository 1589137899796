import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { Subject } from 'rxjs';

import { ChosenFilter, ChosenFilters } from '../../interfaces/chosen-filter.interface';

import { SearchTheme } from '../../enums/search-theme.enum';
import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-filters-bar',
  templateUrl: './filters-bar.component.html',
  styleUrls: ['../../../scss/components/_filters-bar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersBarComponent implements OnDestroy {
  @Input() isFiltersSection = false;
  @Input() underline = true;
  @Input() hasSearch = false;
  @Input() searchTheme: SearchTheme;
  @Input() searchFoldable?: boolean;
  @Input() chosenFilters: ChosenFilters;
  @Input() translateOption = false;
  @Input() userType = UserType.consultant;

  @Output() updateSearch = new EventEmitter<string>();
  @Output() removeFilter = new EventEmitter<ChosenFilter>();
  @Output() removeAllFilters = new EventEmitter<void>();

  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  clearFilters(): void {
    this.removeAllFilters.emit();
  }

  onRemoveFilter(filter: ChosenFilter): void {
    this.removeFilter.emit(filter);
  }

  onSearch(searchQuery: string): void {
    this.updateSearch.emit(searchQuery);
  }
}

import { FormGroup } from '@angular/forms';

export const isPasswordConfirmed = (
  passwordControlKey: string,
  passwordConfirmationControlKey: string
): ((form: FormGroup) => { passwordNotConfirmed: boolean }) => {
  return (form: FormGroup): { passwordNotConfirmed: boolean } => {
    const password = form.controls[passwordControlKey].value;
    const passwordConfirmed = form.controls[passwordConfirmationControlKey].value;

    if (password !== passwordConfirmed) {
      return {
        passwordNotConfirmed: true
      };
    }
  };
};

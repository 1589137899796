import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'areIdsIncluded'
})
export class AreIdsIncludedPipe implements PipeTransform {
  public transform(idsToCheck: Array<string | number> = [], values: Array<{ id: string | number }>): boolean {
    const valuesIds = values.map(value => value.id);
    return idsToCheck.some(id => valuesIds.includes(id));
  }
}

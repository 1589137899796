import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

// Gutwin Shared Library
import { Attachment, I18nService, LanguageCode } from 'gutwin-shared';

// Models
import { Company } from '../models/company.model';

// Services
import { ApiUrlService } from './api-url.service';

export interface CompanyResponse {
  id: string;
  name: string;
  language: LanguageCode;
  logo: { name: string; url: string };
  uses_numeric_averages: boolean;
  uses_percentage_averages: boolean;
}

@Injectable()
export class CompanyService {
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService, private i18nService: I18nService) {}

  getCompany(companyId: string): Promise<Company> {
    return this.http
      .get(`${this.apiUrlService.companyApi}/${companyId}`)
      .toPromise()
      .then((data: CompanyResponse) => {
        return this.convertCompanyToGet(data);
      })
      .catch(error => {
        console.error('Error while getting company', error);
        throw error;
      });
  }

  createCompany(company: Company, seeds: boolean): Promise<any> {
    return this.http
      .post(this.apiUrlService.companyApi, this.convertCompanyToPost(company, seeds))
      .toPromise()
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('Error while creating company', error);
        throw error;
      });
  }

  uploadCompanyLogo(company: Company): Promise<Company> {
    return this.http
      .put(`${this.apiUrlService.companyApi}/${company.id}`, this.convertCompanyLogoToUpload(company))
      .toPromise()
      .then((data: CompanyResponse) => {
        return this.convertCompanyToGet(data);
      })
      .catch(error => {
        console.error('Error while uploading company\'s logo', error);
        throw error;
      });
  }

  updateCompanyRatings(company: Company): Promise<Company> {
    return this.http
      .put(`${this.apiUrlService.companyApi}/${company.id}`, this.convertCompanyRatingsToUpdate(company))
      .toPromise()
      .then((data: CompanyResponse) => {
        return this.convertCompanyToGet(data);
      })
      .catch(error => {
        console.error('Error while uploading company\'s logo', error);
        throw error;
      });
  }

  private convertCompanyToGet(company: CompanyResponse): Company {
    const logo = company.logo ? new Attachment(company.logo) : undefined;
    if (logo) {
      logo.isImage = true;
    }

    return new Company({
      id: company.id,
      name: company.name,
      language: this.i18nService.getLanguage(company.language),
      logo,
      hasNumericAverages: company.uses_numeric_averages,
      hasPercentageAverages: company.uses_percentage_averages
    });
  }

  private convertCompanyToPost(company: Company, seeds?: boolean): any {
    return {
      name: company.name,
      description: company.description,
      subdomain: company.subdomain,
      user_name: company.employee.name,
      user_lastname: company.employee.lastname,
      password: company.employee.password,
      email: company.employee.email,
      language: company.language.id,
      populate_with_seeds: seeds ? 'true' : 'false'
    };
  }

  private convertCompanyLogoToUpload(company: Company): FormData {
    const formData = new FormData();
    formData.append('id', company.id);
    formData.append('logo', company.logo ? company.logo.file : '');
    return formData;
  }

  private convertCompanyRatingsToUpdate(
    company: Company
  ): { uses_numeric_averages: string; uses_percentage_averages: string } {
    return {
      uses_numeric_averages: company.hasNumericAverages ? 'true' : 'false',
      uses_percentage_averages: company.hasPercentageAverages ? 'true' : 'false'
    };
  }
}

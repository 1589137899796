import { Pipe, PipeTransform } from '@angular/core';

import { DateRange } from '../interfaces/date-range.interface';

import { convertToFormatDate } from '../converters/shared.convert';

@Pipe({
  name: 'formatDateRange'
})
export class FormatDateRangePipe implements PipeTransform {
  transform(dateRange: DateRange, dateFormat = 'DD MMM YYYY'): string {
    const startDate = convertToFormatDate(dateRange.startDate, dateFormat);
    const endDate = convertToFormatDate(dateRange.endDate, dateFormat);

    return `${startDate}${startDate && endDate && ' - '}${endDate}`;
  }
}

import { OverlayRef } from '@angular/cdk/overlay';
import { ChangeDetectorRef, TemplateRef, Type } from '@angular/core';

import { Subject } from 'rxjs';

import { DialogConfig } from '../../interfaces/dialog-config.interface';
import { OverlayEvent } from '../../interfaces/overlay.interface';

import { BodyFreezerService } from '../../services/body-freezer.service';

// R = Response Data Type, T = Data passed to Modal Type, S = Submited Data Type
export class DialogOverlayRef<R = any, T = any, S = any> {
  submit$ = new Subject<OverlayEvent<S>>();
  afterClosed$ = new Subject<OverlayEvent<R>>();
  hide$ = new Subject<void>();
  show$ = new Subject<void>();

  constructor(
    public overlay: OverlayRef,
    public content: string | TemplateRef<any> | Type<any>,
    public data: T,
    public config: DialogConfig,
    private bodyFreezerService: BodyFreezerService
  ) {}

  submit(data: S): void {
    this.submit$.next({
      data
    });
  }

  hide(): void {
    this.hide$.next();
    this.overlay.addPanelClass('overlay--hidden');
  }

  setConfig(config: DialogConfig): void {
    this.config = { ...this.config, ...config };
  }

  show(): void {
    this.show$.next();
    this.overlay.removePanelClass('overlay--hidden');
  }

  close(data?: R): void {
    this._close(data);
    this.bodyFreezerService.unfreezeBody();
  }

  private _close(data: R): void {
    this.overlay.dispose();
    this.afterClosed$.next({
      data
    });

    this.afterClosed$.complete();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Employee, TopBarLink } from 'gutwin-shared';

import { ProgressBar } from '@gutwin-audit/shared/services/progress.service';

@Component({
  selector: 'gw-top-bar-auditor',
  templateUrl: './top-bar-auditor.component.html',
  styleUrls: ['./top-bar-auditor.component.scss']
})
export class TopBarAuditorComponent {
  /**
   * Currently logged user
   *
   * @required
   */
  @Input() currentUser: Employee;
  /**
   * Version of the app
   *
   * @required
   */
  @Input() version: string;
  /**
   * Is the app online?
   *
   * @required
   */
  @Input() online: boolean;
  /**
   * Links in dropdown
   */
  @Input() links?: { [name: string]: TopBarLink };
  /**
   * Currently visited page/component.
   * Links with this component will not be visible in dropdown.
   */
  @Input() component?: 'audit' | 'admin' | 'profile';
  /**
   * Values for progress bar
   */
  @Input() progressBar?: ProgressBar;

  /**
   * Callback to logout user
   */
  @Output() logOut = new EventEmitter<void>();
  /**
   * Callback to open version modal
   */
  @Output() openVersionModal = new EventEmitter<void>();
  /**
   * Callback to sync groups from progress bar
   */
  @Output() syncGroups = new EventEmitter<void>();
  /**
   * Callback to close progress bar
   */
  @Output() closeProgressBar = new EventEmitter<void>();

  onOpenVersionModal(): void {
    this.openVersionModal.emit();
  }

  onLogOut(): void {
    this.logOut.emit();
  }

  onSyncGroups(): void {
    this.syncGroups.emit();
  }

  onCloseProgressBar(): void {
    this.closeProgressBar.emit();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertBytesToMegabytes'
})
export class ConvertBytesToMegabytesPipe implements PipeTransform {
  transform(sizeInBytes: string): string {
    return `${(+sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  }
}

import { Attachment } from '../../../../../models/attachment.model';
import { EmployeeExtended } from '../../../../../models/employee-extended.model';
import { CurrentUser } from '../../models/current-user.model';

export class SetLoggedUser {
  static readonly type = '[Session] Set logged user';
  constructor(public user: CurrentUser, public noFiltersUpdate?: boolean) {}
}

export class GetLoggedUser {
  static readonly type = '[Session] Get logged user';
  constructor() {}
}

export class SetAvailableCompanies {
  static readonly type = '[Session] Set available companies';
  constructor(public currentUser: CurrentUser) {}
}

export class ClearSession {
  static readonly type = '[Session] Clear session';
  constructor() {}
}

export class UpdateLoggedUserEmail {
  static readonly type = '[Session] Update logged user email';
  constructor(public email: string) {}
}

export class UpdateLoggedUserPassword {
  static readonly type = '[Session] Update logged user password';
  constructor(public password: string, public passwordConfirmation: string) {}
}

export class UpdateCompanyContext {
  static readonly type = '[Session] Update company context';
  constructor(public companyId: string) {}
}

export class UpdateProfile {
  static readonly type = '[Session] Update user profile';
  constructor(public employee: EmployeeExtended) {}
}

export class CreateProfileAvatar {
  static readonly type = '[Session] Create user profile avatar';
  constructor(public employeeId: string, public avatar: Attachment) {}
}

export class UpdateProfileAvatar {
  static readonly type = '[Session] Update user profile avatar';
  constructor(public employeeId: string, public avatar: Attachment) {}
}

export class RemoveProfileAvatar {
  static readonly type = '[Session] Remove user profile avatar';
  constructor(public employeeId: string) {}
}

export class GetLCLicenses {
  static readonly type = '[Session] Get legal compliance licenses';
}

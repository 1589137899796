import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as d3 from 'd3';

// Models
import { QuestionsStats } from '../../../shared/models/questions-stats.model';

// Services
import { ChartService } from '../../../shared/services/chart.service';

@Component({
  selector: 'gw-circle-chart',
  templateUrl: './circle-chart.component.html',
  styleUrls: ['./circle-chart.component.scss'],
  exportAs: 'gwCircleChart'
})
export class CircleChartComponent implements OnInit, OnChanges {
  @ViewChild('circleChart', { static: true }) circleChart: ElementRef;
  @Input() chartDataset: any;
  @Input() totalCount: number;
  firstrun = true;
  columns = 1;

  constructor(
    private chartService: ChartService,
    private translateService: TranslateService,
    public element: ElementRef
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    if (!this.firstrun) {
      this.removeChart();
    }
    if (this.chartDataset && this.totalCount) {
      this.generateCircleChart();
      this.columns = Math.floor((this.chartDataset.length - 1) / 5) + 1;
      this.firstrun = false;
    }
  }

  removeChart(selector = this.circleChart.nativeElement): void {
    d3.select(selector)
      .select('svg')
      .remove();
  }

  generateCircleChart(
    selector = this.circleChart.nativeElement,
    chartDataset = this.chartDataset,
    totalCount = this.totalCount
  ): void {
    const chart = d3.select(this.circleChart.nativeElement);
    this.chartService.generateCircleChart(chart, chartDataset, totalCount);
  }
}

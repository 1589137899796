import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

import { HasMorePagesFlag, Page, PageIndex, PageElementsCount } from '../../interfaces/page.interface';

import { PageLimit } from '../../enums/page-limit.enum';

import { DEFAULT_NUMBER_OF_PAGES_TO_SHOW, DEFAULT_PAGE_NUMBER } from '../../constants/pagination.constants';

@Component({
  selector: 'gw-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() totalElementsAmount: number;
  @Input() numberOfPagesToShow = DEFAULT_NUMBER_OF_PAGES_TO_SHOW;
  @Input() showPaginationCount: boolean;
  @Input() set currentPage(currentPage: number) {
    this._currentPage = currentPage;
    this.updateShow();
  }
  get currentPage(): number {
    return this._currentPage;
  }
  _currentPage = DEFAULT_PAGE_NUMBER;
  @Input() set currentLimit(limit: number) {
    if (limit) {
      this.limit.value = limit;
      this.updateShow();
    }
  }
  get currentLimit(): number {
    return this.limit.value;
  }

  @Input() light: boolean;

  @Output() changePage = new EventEmitter<number>();
  @Output() changeLimit = new EventEmitter<number>();

  pages: Array<Page>;
  limit = { value: PageLimit.min };
  limitSelect = [{ value: PageLimit.min }, { value: PageLimit.medium }, { value: PageLimit.max }];
  hasMore: HasMorePagesFlag;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  get firstPage(): number {
    return DEFAULT_PAGE_NUMBER;
  }

  get lastPage(): number {
    return Math.ceil(this.totalElementsAmount / this.limit.value) - 1;
  }

  updateShow(): void {
    const pagesToShow = this.getPagesToShow();
    this.pages = pagesToShow.pages;
    this.hasMore = pagesToShow.hasMore;
    this.changeDetectorRef.markForCheck();
  }

  getPagesToShow(): { hasMore: HasMorePagesFlag; pages: Array<Page> } {
    const index = this.countIndex();
    const hasMore = this.checkIfHasMore(index);
    const pages = this.generatePages(index, hasMore);

    return { hasMore, pages };
  }

  countIndex(): PageIndex {
    const amountOfPages = Math.ceil(this.totalElementsAmount / this.limit.value);
    const min = 0;
    const max = amountOfPages - 1;

    let start = this.currentPage - Math.floor(this.numberOfPagesToShow / 2);
    start = Math.min(start, amountOfPages - this.numberOfPagesToShow);
    start = Math.max(start, min);

    let end = Math.min(start + this.numberOfPagesToShow - 1, max);

    const hasMore = this.checkIfHasMore({ start, end });
    start = hasMore.previous ? start + 1 : start;
    end = hasMore.next ? end - 1 : end;

    return { start, end };
  }

  checkIfHasMore(index: PageIndex): HasMorePagesFlag {
    return {
      previous: index.start > this.firstPage,
      next: index.end < this.lastPage
    };
  }

  generatePages(index: PageIndex, hasMore: HasMorePagesFlag): Array<Page> {
    const pages = new Array<Page>();
    if (hasMore.previous) {
      pages.push({
        value: index.start - 1,
        text: '...'
      });
    }
    for (let i = index.start; i <= index.end; i++) {
      pages.push({
        value: i,
        text: i + 1
      });
    }
    if (hasMore.next) {
      pages.push({
        value: index.end + 1,
        text: '...'
      });
    }
    return pages;
  }

  get currentPageElementsCount(): PageElementsCount {
    const start = this._currentPage * this.currentLimit + 1;
    let end = this._currentPage * this.currentLimit + this.currentLimit;
    const lastPageElementsCount = this.totalElementsAmount % this.currentLimit;
    const isLastPage = this.countIndex().end === this._currentPage;

    if (isLastPage && lastPageElementsCount !== 0) {
      end = start + lastPageElementsCount - 1;
    }
    return { start, end, total: this.totalElementsAmount };
  }

  setCurrentPage(page: number): void {
    if (page < this.firstPage) {
      page = this.firstPage;
    } else if (page > this.lastPage) {
      page = this.lastPage;
    }
    if (page !== this.currentPage) {
      this.currentPage = page;
      this.changePage.emit(page);
    }
  }

  goToFirstPage(): void {
    this.setCurrentPage(0);
  }

  goToLastPage(): void {
    this.setCurrentPage(this.lastPage);
  }

  setLimitValue(limit: number): void {
    this.limit.value = limit;
    this.changeLimit.emit(limit);
  }
}

export enum CompanyStructureGraphQLActionType {
  ArchiveCompanyUnit = 'archiveCompanyUnit',
  ChangeCompanyUnitParent = 'changeCompanyUnitParent',
  CompanyStructure = 'companyStructure',
  RootUnits = 'rootUnits',
  UnitTypes = 'unitTypes',
  CreateCompanyUnit = 'createCompanyUnit',
  UpdateCompanyUnit = 'updateCompanyUnit',
  UploadUnitBackgroundImage = 'uploadUnitBackgroundImage'
}

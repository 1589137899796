import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
// Models
import { Finding, FindingStatus } from './../../shared/models/finding.model';
import { FindingsAmount } from './../../shared/models/findings-amount.model';
import { FindingsFilters } from '@gutwin-audit/shared/models/filters-findings.model';
import { FindingsResponse } from './../../shared/models/findings-response.model';
// Services
import { FilterModuleService } from '../../shared/services/filter-module.service';
import { FindingService } from './../../shared/services/finding.service';

@Injectable()
export class FindingsNotAssignedResolve implements Resolve<any> {
  constructor(
    private findingService: FindingService,
    private filterService: FilterModuleService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) { }

  resolve (route: ActivatedRouteSnapshot): Promise<FindingsResponse> {
    this.filterService.getQueryParams(route.queryParams);

    const filters = this.filterService.filters;
    const page = this.filterService.page;
    const limit = this.filterService.limit;

    return this.findingService.getFindingsByStatus(FindingStatus.notAssigned, page, filters as FindingsFilters, limit)
      .catch(error => {
        this.fetchErrorTranslation()
          .then((translation: any) => {
            this.notificationsService.error(translation.title, translation.text);
          });
        return {
          findingsAmount: new FindingsAmount(),
          findings: new Array<Finding>()
        };
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ERROR.LOAD_FINDINGS'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['GLOBAL.ERROR.LOAD_FINDINGS']
        };
      });
  }
}

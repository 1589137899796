import { Pipe, PipeTransform } from '@angular/core';

import { TreeNodeZip } from '../models/tree-node.model';

@Pipe({
  name: 'displayNestedElementsPath',
  pure: true
})
export class DisplayNestedElementsPathPipe implements PipeTransform {
  transform(tree: TreeNodeZip): string {
    const path = tree?.path.join('/');
    return path ? `${path}/` : '';
  }
}

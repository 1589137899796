import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { Link } from '../../models/link.model';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-add-link',
  templateUrl: './add-link.component.html',
  styleUrls: ['./add-link.component.scss'],
  exportAs: 'gwAddLink'
})
export class AddLinkComponent implements OnInit {
  @Input() alignRight = true;
  @Input() isUserTypeClient?: boolean;

  @Output() submitAddLinkForm = new EventEmitter<Link>();
  @Output() togglePopoverStatus = new EventEmitter<void>();

  form: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      url: ['', Validators.required]
    });
  }

  resetForm(): void {
    this.form.get('name').setValue('');
    this.form.get('url').setValue('');
    this.submitted = false;
  }

  setControlValue(data: any, key: string): void {
    this.form.get(key).setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  submitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.submitAddLinkForm.emit(this.form.value);
    }
  }

  increaseOpenedEdits(): void {
    this.togglePopoverStatus.emit();
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.togglePopoverStatus.emit();
    this.store.dispatch(new DecreaseOpenedEdits());
  }
}

import { AttachmentAction } from '../types/attachment-action.type';

export class AttachmentLC {
  id?: string;
  action?: AttachmentAction;
  name: string;
  displayName: string;
  checksum: string;
  url: string;
  size: string;
  file?: any;
  rotation?: number;
  isDuplicated?: boolean;
  isImage?: boolean;
  mimeType?: string;

  constructor(attachment: Partial<AttachmentLC>) {
    Object.assign(this, attachment);
    this.isImage = this.checkIfImage();
  }

  private checkIfImage?(): boolean {
    return this.mimeType ? !!this.mimeType.startsWith('image/') : false;
  }
}

import { PageParams } from '../../../../interfaces/page-params.interface';

import { LIMIT } from '../../../../constants/pagination.constants';

export class Filters {
  component: string;

  constructor(filters?: Partial<Filters>) {
    this.component = filters ? filters.component : undefined;
  }
}

export class PaginationParams {
  pagination?: PageParams;

  constructor(params?: PaginationParams) {
    this.pagination = params ? params.pagination : { page: 0, limit: LIMIT };
  }
}

export class FiltersParamsModel<T> {
  filters?: T;
  pagination?: PageParams;

  constructor(TCreator: new () => T, params?: FiltersParamsModel<T>) {
    this.filters = params ? params.filters : new TCreator();
    this.pagination = params ? params.pagination : { page: 0, limit: LIMIT };
  }
}

export class InitFiltersModel {
  search?: string;

  constructor(filters?: InitFiltersModel) {
    this.search = filters ? filters.search : '';
  }
}

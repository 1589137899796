
<div class="row date-filter__content">
  <div class="columns small-12 medium-6 date-filter__column">
    <div class="date-filter__header"><span class="date-filter__label">{{ 'AUDITS_LIST.FILTER.DATE_FILTER.DATE_FROM' | translate }}:&nbsp;</span>{{ dateRangeFrom | dateFormat: 'D MMMM YYYY' }}</div>
    <gw-date-picker [(date)]="dateRangeFrom" (setDate)="setDateFrom($event)"></gw-date-picker>
  </div>
  <div class="columns small-12 medium-6 date-filter__column">
    <div class="date-filter__header"><span class="date-filter__label">{{ 'AUDITS_LIST.FILTER.DATE_FILTER.DATE_TO' | translate }}:&nbsp;</span>{{ dateRangeTo | dateFormat: 'D MMMM YYYY' }}</div>
    <gw-date-picker [(date)]="dateRangeTo" (setDate)="setDateTo($event)" [after]="dateRangeFrom"></gw-date-picker>
  </div>
</div>
<div class="filter-content__action">
  <button class="button--secondary button--small button--start-case filter-content__button" type="button" (click)="applyDateFilter(dateRangeFrom, dateRangeTo)">{{ 'GLOBAL.ACTION.CONFIRM' | translate }}</button>
</div>
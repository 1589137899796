import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
// Gutwin Shared Library
import { RouterService } from 'gutwin-shared';
// Models
import { FindingsAmount } from './../../../../shared/models/findings-amount.model';
// Services
import { FindingService } from './../../../../shared/services/finding.service';
import { FilterModuleService } from '../../../../shared/services/filter-module.service';

@Component({
  selector: 'gw-findings-tabs',
  templateUrl: './findings-tabs.component.html',
  styleUrls: ['./findings-tabs.component.scss']
})
export class FindingsTabsComponent implements OnInit, OnDestroy {
  findingsAmount: FindingsAmount;
  hasFilters: boolean;
  findingsAmountSubscription: Subscription;
  filterSubscription: Subscription;
  translateSubscription: Subscription;
  tabs = {
    all: {
      url: '/findings',
      title: '',
      amountId: {
        total: 'total',
        filtered: 'filtered'
      }
    },
    inProgress: {
      url: '/findings/in-progress',
      title: '',
      amountId: {
        total: 'totalInProgress',
        filtered: 'filteredInProgress'
      }
    },
    // TODO: [GW-654] Hide elements before MVP
    // overdue: {
    //   url: '/findings/overdue',
    //   title: '',
    //   amountId: {
    //     total: 'totalOverdue',
    //     filtered: 'filteredOverdue'
    //   }
    // },
    notAssigned: {
      url: '/findings/not-assigned',
      title: '',
      amountId: {
        total: 'totalNotAssigned',
        filtered: 'filteredNotAssigned'
      }
    },
    resolved: {
      url: '/findings/resolved',
      title: '',
      amountId: {
        total: 'totalSolved',
        filtered: 'filteredSolved'
      }
    }
  };
  tabsArray: Array<any>;

  constructor(
    private findingService: FindingService,
    private filterService: FilterModuleService,
    private routerService: RouterService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.findingsAmount = this.findingService.getFindingsAmount();
    this.findingsAmountSubscription = this.findingService.findingsAmountObservable.subscribe(findingsAmount => {
      this.findingsAmount = findingsAmount;
    });
    this.hasFilters = this.filterService.isAnyFilter();
    this.filterSubscription = this.filterService.filterObservable.pipe(debounceTime(200)).subscribe(params => {
      this.hasFilters = this.filterService.isAnyFilter();
    });
    this.fetchTranslation();
  }

  ngOnDestroy() {
    this.findingsAmountSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
    this.translateSubscription.unsubscribe();
  }

  isLinkActive(url: string): boolean {
    return this.routerService.isLinkActive(url);
  }

  convertTabsToArray(): Array<any> {
    const tabsArray = new Array<any>();
    for (const tabKey of Object.keys(this.tabs)) {
      tabsArray.push(this.tabs[tabKey]);
    }
    return tabsArray;
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService.get([
      'FINDINGS_LIST.TAB.ALL',
      'FINDINGS_LIST.TAB.IN_PROGRESS',
      'FINDINGS_LIST.TAB.OVERDUE',
      'FINDINGS_LIST.TAB.NOT_ASSIGNED',
      'FINDINGS_LIST.TAB.RESOLVED'
    ]).subscribe(translation => {
      this.tabs.all.title = translation['FINDINGS_LIST.TAB.ALL'];
      this.tabs.inProgress.title = translation['FINDINGS_LIST.TAB.IN_PROGRESS'];
      // TODO: [GW-654] Hide elements before MVP
      // this.tabs[2].title = translation['FINDINGS_LIST.TAB.OVERDUE'];
      this.tabs.notAssigned.title = translation['FINDINGS_LIST.TAB.NOT_ASSIGNED'];
      this.tabs.resolved.title = translation['FINDINGS_LIST.TAB.RESOLVED'];

      this.tabsArray = this.convertTabsToArray();
    });
  }
}

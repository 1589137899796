import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DialogOverlayRef } from '../overlay/dialog-overlay-ref';

import { UserModalData, UserModalResponse } from '../../interfaces/user-modal.interface';

import { ModalIds } from '../../enums/modal-ids.enum';

import { UserFormComponent } from '../user-form/user-form.component';

import { OverlayService } from '../../services/overlay.service';

@Component({
  selector: 'gw-user-modal',
  templateUrl: './user-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserModalComponent implements OnInit, OnDestroy {
  @ViewChild('userForm', { static: true }) userForm: UserFormComponent;

  savingUser = false;
  wasAnythingSaved = false;
  isEditMode = false;

  destroy$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private overlayService: OverlayService,
    public dialogOverlayRef: DialogOverlayRef<boolean, UserModalData, UserModalResponse>
  ) {}

  ngOnInit(): void {
    this.setEditMode();
    this.observeConfirmingSavingUser();
    this.observeDiscardingSavingUser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  async submitUserForm(): Promise<void> {
    this.savingUser = true;
    await this.userForm
      .submitForm()
      .then((response: UserModalResponse) => {
        this.submit(response);
      })
      .catch(() => {
        this.savingUser = false;
        this.changeDetector.detectChanges();
      });
  }

  observeConfirmingSavingUser(): void {
    this.overlayService.confirm$
      .pipe(
        takeUntil(this.destroy$),
        filter(id => id === ModalIds.userModal)
      )
      .subscribe(() => {
        this.savingUser = false;
        this.wasAnythingSaved = true;
        if (this.userForm.addAnother) {
          this.isEditMode = false;
          this.userForm.clearComponent();
          this.changeDetector.detectChanges();
        } else {
          this.close();
        }
      });
  }

  observeDiscardingSavingUser(): void {
    this.overlayService.discard$
      .pipe(
        takeUntil(this.destroy$),
        filter(id => id === ModalIds.userModal)
      )
      .subscribe(() => {
        this.savingUser = false;
        this.changeDetector.detectChanges();
      });
  }

  setEditMode(): void {
    this.isEditMode = !!this.dialogOverlayRef.data.employee;
  }

  submit(response: UserModalResponse): void {
    this.dialogOverlayRef.submit(response);
  }

  close(): void {
    this.dialogOverlayRef.close(this.wasAnythingSaved);
  }

  onHideModal(): void {
    this.dialogOverlayRef.hide();
  }

  onShowModal(): void {
    this.dialogOverlayRef.show();
  }
}


<div class="content">
  <div class="row audit-summary__section">
    <div class="columns small-12 large-6 chart-columns" *ngIf="questionsStats" [class.hide]="!(questionsStats &amp;&amp; questionsStats.totalQuestions &gt; 0)">
      <div class="chart-container">
        <h1 class="chart-container__title" [class.chart-container__title--with-icon]="questionsStats.offline">{{ 'AUDIT_SUMMARY.SUBHEADER.QUESTIONS' | translate }}</h1>
        <div class="chart-container__icon" *ngIf="questionsStats.offline">
          <div class="offline-icon offline-icon--small"><i class="gw-cloud--off offline-icon__icon"></i></div>
        </div>
        <gw-circle-chart #questionsChart="gwCircleChart" [chartDataset]="questionsStats.ratings" [totalCount]="questionsStats.totalQuestions"></gw-circle-chart>
      </div>
    </div>
    <div class="columns small-12 large-6 chart-columns" *ngIf="findingsStats" [class.hide]="!(findingsStats &amp;&amp; findingsStats.totalFindings)">
      <div class="chart-container">
        <h1 class="chart-container__title" [class.chart-container__title--with-icon]="findingsStats.offline">{{ 'AUDIT_SUMMARY.SUBHEADER.FINDINGS' | translate }}</h1>
        <div class="chart-container__icon" *ngIf="findingsStats.offline">
          <div class="offline-icon offline-icon--small"><i class="gw-cloud--off offline-icon__icon"></i></div>
        </div>
        <gw-bar-chart #findingsChart="gwBarChart" [chartDataset]="findingsStats.findingTypes" [totalCount]="findingsStats.totalFindings"></gw-bar-chart>
      </div>
    </div>
  </div>
  <div class="row audit-summary__section">
    <gw-audit-findings class="columns" [audit]="audit" [auditedArea]="auditedArea" (findingsUpdate)="updateFindingsStats()"></gw-audit-findings>
  </div>
  <div class="row audit-summary__section">
    <gw-audit-summary-section class="columns small-12" [audit]="audit" [auditable]="auditable"></gw-audit-summary-section>
  </div>
  <div class="row audit-summary__section" *ngIf="audit?.state !== 'draft'">
    <gw-audit-report class="columns small-12" [audit]="audit"></gw-audit-report>
  </div>
  <div class="row audit-summary__section">
    <div class="columns small-12">
      <div class="audit-summary__button-group audit-summary__button-group--inline"><a class="button--secondary audit-summary__button" routerLink="/audits"><i class="gw-arrow--left button__icon--before"></i>{{ 'AUDIT_SUMMARY.ACTION.GO_TO_AUDITS' | translate }}</a><a class="button--secondary audit-summary__button" *ngIf="auditedArea &amp;&amp; !audit.disabled" [routerLink]="['/audits/conduct-audit', audit.id, auditedArea.id]">{{ 'AUDIT_SUMMARY.ACTION.SHOW_QUESTIONS' | translate }}</a>
        <button class="button--primary audit-summary__button" (click)="showFinishDialog()">{{ 'AUDIT_SUMMARY.ACTION.FINISH_AUDIT' | translate }}</button>
      </div>
    </div>
  </div>
</div>
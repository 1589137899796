import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { NotificationsService } from 'angular2-notifications';

import { CompanyUnit } from '../models/company-unit.model';

import { ErrorTranslationValues } from '../interfaces/error-translation-values.interface';

import { GetCompanyStructure } from '../state/company-structure/company-structure.actions';
import { CompanyStructureState } from '../state/company-structure/company-structure.state';

@Injectable()
export class CompanyStructureResolve implements Resolve<Array<CompanyUnit>> {
  constructor(
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private store: Store
  ) {}

  resolve(): Promise<Array<CompanyUnit>> {
    return this.store
      .dispatch(new GetCompanyStructure())
      .toPromise()
      .then(() => {
        return this.store.selectSnapshot(CompanyStructureState.units);
      })
      .catch(() => {
        this.fetchErrorTranslation().then((translation: ErrorTranslationValues) => {
          this.notificationsService.error(translation.title, translation.text);
        });
        return [];
      });
  }

  async fetchErrorTranslation(): Promise<ErrorTranslationValues> {
    const translation = await this.translateService
      .get(['GLOBAL.ERROR.CONNECTION', 'GUTWIN_SHARED.ERROR.LOAD_COMPANY_STRUCTURE'])
      .toPromise();
    return {
      title: translation['GLOBAL.ERROR.CONNECTION'],
      text: translation['GUTWIN_SHARED.ERROR.LOAD_COMPANY_STRUCTURE']
    };
  }
}


<div class="finding-box">
  <div class="offline-icon offline-icon--small finding-box__offline-mark" *ngIf="finding.offlineRequest"><i class="gw-cloud--off offline-icon__icon"></i></div>
  <gw-expand-box #findingExpandBox="gwExpandBox">
    <ng-container expand-box-header="expand-box-header">
      <div class="finding-box__content--clickable">
        <div (click)="findingExpandBox.toggle()">
          <p class="finding__item--type finding__item--light">{{ finding.getLatestValue('relationships.findingType')?.name }}</p>
          <p class="finding__item--problem">{{ finding.getLatestValue('problem') }}</p>
        </div>
        <button class="button--icon finding__action--toggle" type="button" (click)="findingExpandBox.toggle()"><i class="gw-angle--down expand-box__toggle-icon"></i></button>
        <div class="dropdown dropdown--menu finding__action--dropdown" *ngIf="canManageFinding" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
          <button class="button--icon" type="button"><i class="gw-more"></i></button>
          <div class="dropdown__content jsDropdownContent">
            <ul class="dropdown-menu__list">
              <li class="dropdown-menu__item">
                <button class="link dropdown-menu__link" type="button" (click)="showUpdateFindingModal(finding)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
              </li>
              <li class="dropdown-menu__item" *ngIf="offlineService.online &amp;&amp; !isAuditDisabled">
                <button class="link dropdown-menu__link" type="button" (click)="showRemoveFindingModal(finding)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container expand-box-content="expand-box-content">
      <div class="finding-box__content--non-top-padding" *ngIf="finding.getLatestValue('cause') || finding.getLatestValue('solution')">
        <p class="finding__item--cause" *ngIf="finding.getLatestValue('cause')"><i class="gw-questionmark finding-icon"></i>{{ finding.getLatestValue('cause') }}</p>
        <p class="finding__item--solution" *ngIf="finding.getLatestValue('solution')"><i class="gw-info finding-icon"></i>{{ finding.getLatestValue('solution') }}</p>
      </div>
      <div class="finding-box__content">
        <ng-container *ngIf="finding.getLatestValue('relationships.findable')._type === 'Question'">
          <p class="finding__item finding__item--light" *ngIf="finding.getLatestValue('relationships.section')">{{ finding.getLatestValue('relationships.section') }}</p>
          <p class="finding__item" *ngIf="finding.getLatestValue('relationships.question')">{{ finding.getLatestValue('relationships.question') }}</p>
        </ng-container>
        <p class="finding__item" *ngIf="finding.getLatestValue('relationships.findable')._type === 'AuditedArea'">{{ finding.getLatestValue('relationships.auditedArea')?.facility.name }}</p>
      </div>
    </ng-container>
  </gw-expand-box>
</div>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { PermissionsNames } from 'gutwin-shared';

import { UserService } from '@gutwin-audit/shared/services/user.service';

@Injectable()
export class CreateAuditGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUser = await this.userService.getCurrentUser();
    if (currentUser.hasPermission(PermissionsNames.createAudits)) {
      return true;
    }

    this.router.navigate(['audits']);
    return false;
  }
}

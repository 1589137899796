import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { LIMIT } from 'gutwin-shared';

import { AuditsFilters } from '@gutwin-audit/shared/models/filters-audits.model';
import { Template } from '@gutwin-audit/shared/models/template.model';

import { ApiUrlService } from '@gutwin-audit/shared/services/api-url.service';
import { AuditConvertService, AuditResponse } from '@gutwin-audit/shared/services/audit.convert.service';

export interface TemplatesAmount {
  total: number;
  filtered: number;
}

export interface TemplatesAmountResponse {
  total_count: number;
  total_filtered_count: number;
}

export interface TemplatesListResponse {
  counters: TemplatesAmountResponse;
  templates: Array<TemplateResponse>;
}

export interface TemplateResponse {
  name: string;
  audit: AuditResponse;
}

export interface TemplateRequest {
  name: string;
  audit_id: string;
}

@Injectable()
export class TemplateService {
  templatesAmount: TemplatesAmount = {
    total: 0,
    filtered: 0
  };
  templatesAmountSource = new Subject<TemplatesAmount>();
  templatesAmountObservable = this.templatesAmountSource.asObservable();

  constructor(
    private auditConvertService: AuditConvertService,
    private apiUrlService: ApiUrlService,
    private http: HttpClient
  ) { }

  setTemplatesAmount(counters: TemplatesAmount): void {
    this.templatesAmount = counters;
    this.templatesAmountSource.next(this.templatesAmount);
  }

  getTemplates(page = 0, filters?: AuditsFilters, limit = LIMIT): Promise<{ templates: Array<Template> }> {
    const preparePageParams = (params: HttpParams): HttpParams => {
      if (page < 0) {
        page = 0;
      }
      params = params.set('page', page.toString());
      params = params.set('limit', limit.toString());
      return params;
    };

    const prepareFiltersParams = (filters: AuditsFilters, params: HttpParams): HttpParams => {
      if (filters.templateName) {
        params = params.set('name', filters.templateName || undefined);
      }
      if (filters.location) {
        params = params.set('location', filters.location || undefined);
      }
      if (filters.types && filters.types.length) {
        for (const typeId of filters.types) {
          params = params.append('audit_types_ids[]', typeId);
        }
      }
      if (filters.facilities && filters.facilities.facilities.length) {
        for (const facilityId of filters.facilities.facilities) {
          params = params.append('facilities_ids[]', facilityId);
        }
        params = params.set('include_children_facilities', filters.facilities.subFolders.toString() || 'false');
      }
      if (filters.leadAuditors && filters.leadAuditors.length) {
        for (const auditorId of filters.leadAuditors) {
          params = params.append('lead_auditors_ids[]', auditorId);
        }
      }
      if (filters.coAuditors && filters.coAuditors.length) {
        for (const auditorId of filters.coAuditors) {
          params = params.append('co_auditors_ids[]', auditorId);
        }
      }
      return params;
    };

    let params = new HttpParams();
    params = preparePageParams(params);
    if (filters) {
      params = prepareFiltersParams(filters, params);
    }

    return this.http.get(this.apiUrlService.templateApi, { params: params })
      .toPromise()
      .then((data: TemplatesListResponse) => {
        this.setTemplatesAmount(this.convertTemplatessAmountToGet(data.counters));
        return {
          templates: data.templates.map(template => this.convertTemplateToGet(template))
        };
      }).catch(error => {
        console.error('Error while getting templates list', error);
        throw error;
      });
  }

  createTemplate(template: { name: string, auditId: string }): Promise<void> {
    return this.http.post(this.apiUrlService.templateApi, this.convertTemplateToPost(template))
      .toPromise()
      .then(() => {})
      .catch(error => {
        console.error('Error while creating a template', error);
        throw error;
      });
  }

  updateTemplate(template: { name: string, auditId: string }): Promise<void> {
    return this.http.put(this.apiUrlService.templateApi, this.convertTemplateToPost(template))
      .toPromise()
      .then(() => {})
      .catch(error => {
        console.error('Error while updating a template', error);
        throw error;
      });
  }

  removeTemplate(auditId: string): Promise<void> {
    let params = new HttpParams();
    params = params.set('audit_id', auditId);

    return this.http.delete(this.apiUrlService.templateApi, { params })
      .toPromise()
      .then(() => {})
      .catch(error => {
        console.error('Error while removing a template', error);
        throw error;
      });
  }

  convertTemplateToGet(template: TemplateResponse): Template {
    return new Template({
      name: template.name,
      audit: this.auditConvertService.convertAuditToGet(template.audit)
    });
  }

  convertTemplatessAmountToGet(counters: TemplatesAmountResponse): TemplatesAmount {
    return {
      total: counters.total_count || 0,
      filtered: counters.total_filtered_count || 0
    };
  }

  convertTemplateToPost(template: { name: string, auditId: string }): TemplateRequest {
    return {
      name: template.name,
      audit_id: template.auditId
    };
  }
}


<div class="admin-page">
  <gw-audit-top-bar [component]="'admin'"></gw-audit-top-bar>
  <gw-page-header>
    <ng-container page-header-left="page-header-left">
      <ul class="header__menu header__menu--left">
        <li class="header__item header__item--title">{{ 'ADMIN_SETTINGS.PAGE_HEADER' | translate }}</li>
      </ul>
    </ng-container>
  </gw-page-header>
  <div class="row admin-settings">
    <div class="columns">
      <div class="row">
        <div class="columns columns--without-right-padding small-2">
          <gw-admin-tabs></gw-admin-tabs>
        </div>
        <div class="columns columns--without-left-padding small-10">
          <div class="admin-settings__content">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PermissionCategory } from '../../models/permission-category.model';
import { Permission } from '../../models/permission.model';
import { Role } from '../../models/role.model';

import { PermissionCategoryModalData } from '../../interfaces/permission-category-modal.interface';
import { RoleModalResponse } from '../../interfaces/role-modal.interface';

import { UserType } from '../../enums/user-type.enum';

import { PermissionCategoryModalComponent } from '../permission-category-modal/permission-category-modal.component';

import { OverlayService } from '../../services/overlay.service';

import { ColorValidator } from '../../validators/color.validator';
import { noWhitespaceValidator } from '../../validators/no-whitespace.validator';

import { PermissionCategoryUtil } from '../../utils/permission-category.util';

@Component({
  selector: 'gw-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss'],
  exportAs: 'gwRoleForm',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleFormComponent implements OnInit, OnDestroy {
  @Input() role: Role;
  @Input() allPermissionCategories: Array<PermissionCategory>;
  @Input() isAudit: boolean;
  @Input() isEditMode?: boolean;
  @Input() userType?: UserType;

  @Output() hideModal = new EventEmitter<void>();
  @Output() showModal = new EventEmitter<void>();

  roleForm: FormGroup;
  addAnother = false;
  submitted = false;
  destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private overlayService: OverlayService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initRole();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initRole(): void {
    this.role = new Role({
      ...this.role
    });

    if (!this.isAudit) {
      this.role.permissionCategories = this.initPermissionCategories();
    }
  }

  initPermissionCategories(): Array<PermissionCategory> {
    const fullPermissionCategories = PermissionCategoryUtil.getPermissionCategoriesFromRoles(
      this.role ? [this.role] : undefined,
      this.allPermissionCategories
    );
    return fullPermissionCategories.map(permissionCategory => {
      return new PermissionCategory({
        ...permissionCategory,
        permissions: permissionCategory.permissions.map(permission => {
          const isAdded = !!permission.rolesIds.find(roleId => roleId === this.role?.id);
          return new Permission({
            ...permission,
            added: isAdded
          });
        })
      });
    });
  }

  initForm(): void {
    this.roleForm = this.formBuilder.group({
      name: [this.role?.name || '', [Validators.required, noWhitespaceValidator]],
      color: [this.role?.color || '', [Validators.required, ColorValidator.colorValidator]],
      permissionsIds: [this.role?.getPermissionIds() || []]
    });
  }

  resetForm(): void {
    this.roleForm.reset({
      name: this.role?.name || '',
      color: this.role?.color || '',
      permissionsIds: this.role?.getPermissionIds() || []
    });
    this.submitted = false;
    this.changeDetector.detectChanges();
  }

  clearComponent(): void {
    this.role = new Role({
      permissionCategories: PermissionCategoryUtil.clearPermissionCategories(this.allPermissionCategories)
    });
    this.resetForm();
  }

  setControlValue(data: any, key: string): void {
    this.roleForm.controls[key].setValue(data);
  }

  convertRoleToCreate(): RoleModalResponse {
    const roleToCreate = new Role({
      ...this.roleForm.value,
      permissionCategories: this.role.permissionCategories
    });

    return {
      status: 'create',
      role: roleToCreate,
      permissionsIds: this.roleForm.value.permissionsIds
    };
  }

  convertRoleToUpdate(): RoleModalResponse {
    const roleToUpdate = new Role({
      ...this.roleForm.value,
      id: this.role.id,
      permissionCategories: this.role.permissionCategories
    });

    return {
      status: 'update',
      role: roleToUpdate,
      permissionsIds: this.roleForm.value.permissionsIds
    };
  }

  async submitRoleForm(): Promise<RoleModalResponse> {
    this.submitted = true;
    if (this.roleForm.valid) {
      return this.role?.id ? this.convertRoleToUpdate() : this.convertRoleToCreate();
    } else {
      this.changeDetector.detectChanges();
      return Promise.reject({ invalid: true });
    }
  }

  showPermissionCategoryModal(permissionCategory: PermissionCategory): void {
    this.hideModal.emit();
    this.overlayService
      .open<PermissionCategory, PermissionCategoryModalData>(
        PermissionCategoryModalComponent,
        {
          permissionCategory,
          roles: [this.role],
          withForm: true
        },
        {
          small: true
        }
      )
      .afterClosed$.pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response.data) {
          this.setPermissionCategory(response.data);
        }
        this.showModal.emit();
      });
  }

  setPermissionCategory(permissionCategory: PermissionCategory): void {
    const permissionCategoryIndex = this.role.permissionCategories.findIndex(
      rolePermissionCategory => rolePermissionCategory.id === permissionCategory.id
    );
    if (permissionCategoryIndex !== -1) {
      this.role.permissionCategories[permissionCategoryIndex] = permissionCategory;
    } else {
      this.role.permissionCategories.push(permissionCategory);
    }
    this.setControlValue(this.role.getPermissionIds(), 'permissionsIds');
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Attachment, Employee, PermissionCategory, Role } from 'gutwin-shared';

import { PermissionCategoryResponse } from '@gutwin-audit/shared/interfaces/permission-category.interface';
import {
  PermissionCategoriesForRolesResponse,
  RoleRequest,
  RoleResponse,
  RoleUserResponse,
  RoleUsersResponse,
  UserRoles,
  UserRolesResponse
} from '@gutwin-audit/shared/interfaces/role.interface';

import { ApiUrlService } from '@gutwin-audit/shared/services/api-url.service';
import { PermissionCategoryService } from '@gutwin-audit/shared/services/permission-category.service';

@Injectable()
export class RoleService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private permissionCategoryService: PermissionCategoryService
  ) {}

  getRoles(): Promise<Array<Role>> {
    return this.http
      .get(this.apiUrlService.roleApi)
      .toPromise()
      .then((data: Array<RoleResponse>) => data.map(role => this.convertRoleToGet(role)))
      .catch(error => {
        console.error('Error while getting roles', error);
        throw error;
      });
  }

  getRole(id: string): Promise<Role> {
    return this.http
      .get(`${this.apiUrlService.roleApi}/${id}`)
      .toPromise()
      .then((data: RoleResponse) => this.convertRoleToGet(data))
      .catch(error => {
        console.error('Error while getting role', error);
        throw error;
      });
  }

  getPermissionCategoriesInRoles(rolesIds: Array<string>): Promise<Array<PermissionCategory>> {
    let params = new HttpParams();
    for (const roleId of rolesIds) {
      params = params.append('roles_ids[]', roleId);
    }

    return this.http
      .get(`${this.apiUrlService.roleApi}/permissions_per_category_for_roles`, { params })
      .toPromise()
      .then((data: PermissionCategoriesForRolesResponse) => {
        const permissionCategoriesIds = data.permission_categories.map(permissionCategory => permissionCategory.id);
        return this.getFullPermissionCategories(permissionCategoriesIds, rolesIds);
      })
      .catch(error => {
        console.error('Error while getting permission categories for roles', error);
        throw error;
      });
  }

  getFullPermissionCategories(
    permissionCategoriesIds: Array<string>,
    rolesIds: Array<string>
  ): Promise<Array<PermissionCategory>> {
    return Promise.all(
      permissionCategoriesIds.map(permissionCategoryId =>
        this.permissionCategoryService.getPermissionCategory(permissionCategoryId, rolesIds)
      )
    );
  }

  createRole(role: Role): Promise<Role> {
    return this.http
      .post(this.apiUrlService.roleApi, this.convertRoleToPost(role))
      .toPromise()
      .then((data: RoleResponse) => this.convertRoleToGet(data))
      .catch(error => {
        console.error('Error while creating role', error);
        throw error;
      });
  }

  updateRole(role: Role): Promise<Role> {
    return this.http
      .put(`${this.apiUrlService.roleApi}/${role.id}`, this.convertRoleToPost(role))
      .toPromise()
      .then((data: RoleResponse) => this.convertRoleToGet(data))
      .catch(error => {
        console.error('Error while updating role', error);
        throw error;
      });
  }

  removeRole(id: string): Promise<any> {
    return this.http
      .delete(`${this.apiUrlService.roleApi}/${id}`)
      .toPromise()
      .catch(error => {
        console.error('Error while removing role', error);
        throw error;
      });
  }

  getRoleUsers(roleId: string): Promise<{ totalUsers: number; users: Array<Employee> }> {
    let params = new HttpParams();
    params = params.set('role_id', roleId);

    return this.http
      .get(`${this.apiUrlService.userRolesApi}/find_users`, { params })
      .toPromise()
      .then((data: RoleUsersResponse) => {
        return {
          users: data.users.map(user => this.convertUserToGet(user)),
          totalUsers: data.total_users
        };
      })
      .catch(error => {
        console.error('Error while getting users in role', error);
        throw error;
      });
  }

  addRoleUser(roleId: string, userId: string): Promise<any> {
    return this.http
      .post(`${this.apiUrlService.userRolesApi}`, this.convertUserToPost(roleId, userId))
      .toPromise()
      .catch(error => {
        console.error('Error while adding user to role', error);
        throw error;
      });
  }

  removeRoleUser(roleId: string, userId: string): Promise<any> {
    let params = new HttpParams();
    params = params.set('role_id', roleId);
    params = params.set('user_id', userId);

    return this.http
      .delete(`${this.apiUrlService.userRolesApi}`, { params })
      .toPromise()
      .catch(error => {
        console.error('Error while removing user from role', error);
        throw error;
      });
  }

  updateUserRoles(userId: string, roles: Array<Role>): Promise<UserRoles> {
    const convertEmployee = (data: UserRolesResponse): Employee => {
      return new Employee({
        id: data.id,
        email: data.email,
        name: data.name,
        lastname: data.lastname
      });
    };

    const convertRoles = (data: UserRolesResponse): Array<Role> => {
      return data.roles_with_permissions.roles.map(role => this.convertRoleToGet(role));
    };

    return this.http
      .put(`${this.apiUrlService.userApi}/${userId}`, this.convertUserRolesToPut(userId, roles))
      .toPromise()
      .then((data: UserRolesResponse) => {
        return {
          employee: convertEmployee(data),
          roles: convertRoles(data),
          permissionCategories: this.generatePermissionCategories(data.roles_with_permissions.permission_categories)
        };
      })
      .catch(error => {
        console.error('Error while updating roles for user', error);
        throw error;
      });
  }

  convertRoleToGet(role: RoleResponse): Role {
    return new Role({
      id: role.id,
      name: role.name,
      color: role.color,
      usersCount: role.users_count,
      permissionCategories: this.generatePermissionCategories(role.permission_categories)
    });
  }

  convertRoleToPost(role: Role): RoleRequest {
    return {
      id: role.id,
      name: role.name,
      color: role.color,
      permissions_ids: role.getPermissionIds()
    };
  }

  convertUserToGet(user: RoleUserResponse): Employee {
    return new Employee({
      id: user.id,
      name: user.name,
      lastname: user.lastname,
      avatar: user.avatar ? new Attachment(user.avatar) : undefined
    });
  }

  convertUserToPost(roleId: string, userId: string): any {
    return {
      role_id: roleId,
      user_id: userId
    };
  }

  convertUserRolesToPut(userId: string, roles: Array<Role>): any {
    return {
      user_id: userId,
      roles_ids: roles ? roles.map(role => role.id) : []
    };
  }

  generatePermissionCategories(permissionCategories: Array<PermissionCategoryResponse>): Array<PermissionCategory> {
    if (permissionCategories) {
      return permissionCategories.map(permissionCategory =>
        this.permissionCategoryService.convertPermissionCategoryToGet(permissionCategory, true)
      );
    }
  }
}

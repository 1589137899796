
<div class="filter-content__section">
  <gw-toggle-switch class="filter-content__switch" [labelRight]="'GLOBAL.FILTER.AREA_FILTER.MULTI_SELECT' | translate" [(ngModel)]="multiSelect" (ngModelChange)="changeMultiSelect()" [small]="true" [light]="true"></gw-toggle-switch>
</div>
<div class="filter-content__section filter-content__scroll">
  <div class="blankslate" *ngIf="!facilities?.length">{{ 'GLOBAL.ERROR.SEARCH_NO_RESULTS' | translate }}.</div>
  <form #form="ngForm">
    <gw-facility-tree-view *ngIf="facilities" [facilityTree]="facilities" [selectedFacility]="selectedFacility" [multiSelect]="multiSelect" [subFolders]="subFolders" [small]="true" (changeSelectedFacility)="changeSelectedFacility($event)"></gw-facility-tree-view>
  </form>
</div>
<div class="filter-content__section">
  <label class="filter-content__label">
    <gw-check-box class="filter-content__checkbox" name="subFolders" [(ngModel)]="subFolders" [ngModelOptions]="{standalone: true}"></gw-check-box>{{ 'GLOBAL.FILTER.AREA_FILTER.INCLUDE_SUB_FOLDERS' | translate }}
  </label>
</div>
<div class="filter-content__action">
  <button class="button--secondary button--small button--start-case filter-content__button" type="button" (click)="submitFacilitiesFilter()">{{ 'GLOBAL.ACTION.CONFIRM' | translate }}</button>
</div>
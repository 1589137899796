import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as _ from 'lodash';
// Gutwin Shared Library
import { FileService } from 'gutwin-shared';
// Models
import { Audit } from '../models/audit.model';
import { AuditedArea } from './../models/audited-area.model';
import { Section } from './../models/section.model';
import { Question } from './../models/question.model';
import { Questionnaire } from './../models/questionnaire.model';
// Providers
import { ApiUrlService } from './api-url.service';
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';
import { SectionService } from './section.service';

@Injectable()
export class QuestionnaireService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private fileService: FileService,
    private storageService: StorageModuleService,
    private sectionService: SectionService
  ) { }

  getQuestionnaires(): Promise<Array<Questionnaire>> {
    return this.http.get(this.apiUrlService.questionnaireApi)
      .toPromise()
      .then((data: any) => {
        const questionnaires = new Array<Questionnaire>();
        data.questionnaires.forEach(questionnaire => {
          questionnaires.push(this.convertQuestionnaireToGet(questionnaire));
        });
        return questionnaires;
      })
      .catch(error => {
        console.error('Error while getting questionnaires', error);
        throw error;
      });
  }

  getQuestionnaire(id: string): Promise<Questionnaire> {
    return this.http.get(`${this.apiUrlService.questionnaireApi}/${id}`)
      .toPromise()
      .then(data => this.convertQuestionnaireToGet(data))
      .catch(error => {
        console.error('Error while getting questionnaire', error);
        throw error;
      });
  }

  createQuestionnaire(questionnaire: Questionnaire): Promise<Questionnaire> {
    return this.http.post(this.apiUrlService.questionnaireApi, this.convertQuestionnaireToPost(questionnaire))
      .toPromise()
      .then(data => this.convertQuestionnaireToGet(data))
      .catch(error => {
        console.error('Error while creating questionnaire', error);
        throw error;
      });
  }

  updateQuestionnaire(questionnaire: Questionnaire): Promise<Questionnaire> {
    return this.http.put(`${this.apiUrlService.questionnaireApi}/${questionnaire.id}`, this.convertQuestionnaireToPost(questionnaire))
      .toPromise()
      .then(data => this.convertQuestionnaireToGet(data))
      .catch(error => {
        console.error('Error while updating questionnaire', error);
        throw error;
      });
  }

  archiveQuestionnaire(questionnaire: Questionnaire, archive = true): Promise<Questionnaire> {
    return this.http.put(
      `${this.apiUrlService.questionnaireApi}/${questionnaire.id}/archive`,
      this.convertQuestionnaireToArchive(questionnaire, archive)
    )
      .toPromise()
      .then(data => this.convertQuestionnaireToGet(data))
      .catch(error => {
        console.error('Error while archiving questionnaire', error);
        throw error;
      });
  }

  removeQuestionnaire(questionnaire: Questionnaire): Promise<any> {
    return this.http.delete(`${this.apiUrlService.questionnaireApi}/${questionnaire.id}`)
      .toPromise()
      .then(data => data)
      .catch(error => {
        console.error('Error while removing questionnaire', error);
        throw error;
      });
  }

  importQuestionnaire(questionnaireFile): Promise<{}> {
    return this.http.post(`${this.apiUrlService.questionnaireApi}/import`, convertDataToPost(questionnaireFile), { withCredentials: true })
      .toPromise()
      .then(data => data)
      .catch(error => {
        console.error('Error while impoting questionnaire', error);
        return new Promise((resolve, reject) => reject(error));
      });

    function convertDataToPost(file) {
      const formData = new FormData();
      formData.append('questionnaire_file', file);
      return formData;
    }
  }

  exportQuestionnaire(questionnaire: Questionnaire): Promise<Blob> {
    return this.http.get(`${this.apiUrlService.questionnaireApi}/${questionnaire.id}/export`, { responseType: 'blob' as 'blob' })
      .toPromise()
      .then(async (data: Blob) => {
        await this.fileService.downloadFile(data, `${questionnaire.name}.xlsx`);
        return data;
      })
      .catch(error => {
        throw error;
      });
  }

  getAuditablesQuestionnaires(
    auditableId: string, auditableType: string, withoutEmptyQuestionnaires = true
  ): Promise<Array<Questionnaire>> {
    let params = new HttpParams();
    params = params.set('auditable_id', auditableId);
    params = params.set('auditable_type', auditableType);
    params = params.set('without_empty_questionnaires', withoutEmptyQuestionnaires.toString());

    return this.http.get(this.apiUrlService.auditablesQuestionnaireApi, { params: params })
      .toPromise()
      .then((data: any) => {
        const questionnaires = new Array<Questionnaire>();
        data.questionnaires.forEach(questionnaire => {
          questionnaires.push(this.convertQuestionnaireToGet(questionnaire));
        });
        return questionnaires;
      })
      .catch(error => {
        console.error('Error while getting questionnaires', error);
        throw error;
      });
  }

  checkAuditablesQuestionnaire(auditableId: string, auditableType: string, questionnaireId: string): Promise<any> {
    return this.http.post(this.apiUrlService.auditablesQuestionnaireApi, {
      auditable_id: auditableId,
      auditable_type: auditableType,
      questionnaire_id: questionnaireId
    })
      .toPromise()
      .then(data => data)
      .catch(error => {
        console.error('Error while checking questionnaire', error);
        throw error;
      });
  }

  uncheckAuditablesQuestionnaire(auditableId: string, auditableType: string, questionnaireId: string): Promise<any> {
    return this.http.delete(this.apiUrlService.auditablesQuestionnaireApi, {
      params: {
        auditable_id: auditableId,
        auditable_type: auditableType,
        questionnaire_id: questionnaireId
      }
    }).toPromise()
      .then(data => data)
      .catch(error => {
        console.error('Error while unchecking questionnaire', error);
        throw error;
      });
  }

  private convertQuestionnaireToGet(questionnaire: any): Questionnaire {
    const sections = new Array<Section>();
    if (questionnaire.sections) {
      questionnaire.sections.forEach(section => {
        sections.push(this.sectionService.convertSectionToGet(section));
      });
    }
    const questionnaireRequest = {
      id: questionnaire.id,
      name: questionnaire.name,
      orderedSectionsIds: questionnaire.ordered_sections_ids,
      added: questionnaire.belongs_to_auditable,
      addedToParent: questionnaire.belongs_to_auditable_parent,
      totalSections: questionnaire.ordered_sections_ids.length || 0,
      totalQuestions: questionnaire.total_questions || 0,
      totalQuestionsSelected: questionnaire.total_selected_questions || 0,
      sections: sections
    };
    return new Questionnaire(questionnaireRequest);
  }

  private convertQuestionnaireToPost(questionnaire: Questionnaire): any {
    const questionnaireRequest = {
      id: questionnaire.id,
      name: questionnaire.name
    };
    return questionnaireRequest;
  }

  private convertQuestionnaireToArchive(questionnaire: Questionnaire, archive: boolean): any {
    const questionRequest = {
      id: questionnaire.id,
      archive: archive ? 'true' : 'false'
    };
    return questionRequest;
  }
}

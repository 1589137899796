
<div class="choose-audited-area">
  <gw-expand-box class="audited-areas-group" *ngFor="let auditedAreas of auditedAreaGroups; let a = index" #auditExpandBox="gwExpandBox" [opened]="a === 0">
    <ng-container expand-box-header="expand-box-header">
      <div class="row audited-areas-group__title-container">
        <div class="columns">
          <h3 class="audited-areas-group__title" [class.audited-areas-group__title--opened]="auditExpandBox.opened" (click)="auditExpandBox.toggle()"><i class="gw-caret--right h__icon--before audited-areas-group-title__icon"></i>{{ auditedAreas[0].startTime | dateFormat: 'dddd, ' }}<small>{{ auditedAreas[0].startTime | dateFormat: 'D MMMM YYYY' }}</small></h3>
        </div>
      </div>
    </ng-container>
    <ng-container expand-box-content="expand-box-content">
      <div class="row">
        <div class="columns">
          <div class="flex-table audited-areas-group__table">
            <div class="flex-table__row audited-area-table__row">
              <div class="flex-table__cell--th audited-area-table__col--1">{{ 'GLOBAL.TABLE_LABEL.TIME' | translate }}</div>
              <div class="flex-table__cell--th audited-area-table__col--2">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREA' | translate }}</div>
              <div class="flex-table__cell--th audited-area-table__col--3">{{ 'GLOBAL.TABLE_LABEL.LOCATION' | translate }}</div>
              <div class="flex-table__cell--th audited-area-table__col--4">{{ 'GLOBAL.TABLE_LABEL.AUDITOR' | translate }}</div>
              <div class="flex-table__cell--th audited-area-table__col--5">{{ 'GLOBAL.TABLE_LABEL.AUDITEE' | translate }}</div>
              <div class="flex-table__cell--th audited-area-table__col--6"></div>
            </div>
            <div class="flex-table__row audited-area-table__row" *ngFor="let auditedArea of auditedAreas">
              <div class="flex-table__cell audited-area-table__col--1">{{ auditedArea.startTime | dateFormat: 'HH:mm' }} — {{ auditedArea.endTime | dateFormat: 'HH:mm' }}</div>
              <div class="flex-table__cell audited-area-table__col--2"><span *ngIf="auditedArea.facility">{{ auditedArea.facility.name }}</span></div>
              <div class="flex-table__cell audited-area-table__col--3">{{ auditedArea.location }}</div>
              <div class="flex-table__cell audited-area-table__col--4"><span *ngFor="let auditor of auditedArea.employees.auditor; last as lastEmployee">{{ auditor.lastname }}, {{ auditor.name }}{{ !lastEmployee ? ';&nbsp;' : '' }}</span></div>
              <div class="flex-table__cell audited-area-table__col--5"><span *ngFor="let auditee of auditedArea.employees.auditee; last as lastEmployee">{{ auditee.lastname }}, {{ auditee.name }}{{ !lastEmployee ? ';&nbsp;' : '' }}</span></div>
              <div class="flex-table__cell audited-area-table__col--6 audited-area-table__buttons text-right"><a class="button--tiny button--tertiary audited-area__button" [routerLink]="['/audits/conduct-audit', audit.id, auditedArea.id]" (click)="goToAuditedArea(auditedArea)">{{ 'GLOBAL.ACTION.GO_TO_AUDIT' | translate }}</a></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </gw-expand-box>
</div>
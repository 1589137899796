export enum LcFilterListName {
  shared = 'shared',
  legalQuestions = 'legalQuestions',
  legalRegister = 'legalRegister',
  requirements = 'requirements',
  topicRequirements = 'topicRequirements',
  unitRequirements = 'unitRequirements',
  legalChanges = 'legalChanges',
  permits = 'permits',
  users = 'users'
}

import { Pipe, PipeTransform } from '@angular/core';

import { UserType } from '../enums/user-type.enum';

@Pipe({
  name: 'isConsultantUserType'
})
export class IsConsultantUserTypePipe implements PipeTransform {
  transform(userType: UserType): boolean {
    return userType === UserType.consultant;
  }
}

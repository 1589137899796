import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-requirement-versions-panel',
  templateUrl: './requirement-versions-panel.component.html',
  styleUrls: ['./requirement-versions-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequirementVersionsPanelComponent<R> {
  @Input() currentVersionId: string;
  @Input() opened: boolean;
  @Input() removeLabel?: string;
  @Input() userType: UserType;
  @Input() versions: Array<R>;
  @Output() removeRequirementVersion = new EventEmitter<R>();
  @Output() initRemoveLabel = new EventEmitter<void>();

  readonly USER_TYPE = UserType;

  onRemoveRequirementVersion(requirement: R): void {
    this.removeRequirementVersion.emit(requirement);
  }
}

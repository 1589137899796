
<gw-modal id="questionsCanvas" #modal="gwModal" [offCanvas]="true">
  <ng-container modal-header="modal-header" *ngIf="auditableForModal">
    <div class="modal__heading-title">
      <ng-container *ngIf="auditableForModal === audit">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.HEADER_GENERAL' | translate }}</ng-container>
      <ng-container *ngIf="auditableForModal !== audit">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.HEADER' | translate }} {{ auditableForModal.facility.name }}</ng-container>
    </div>
    <div class="modal__heading-button-group">
      <gw-button-dropdown class="single-reference-document-header__button" #buttonDropdown="buttonDropdown" [title]="'GLOBAL.ACTION.ADD' | translate" [buttonClasses]="['button--primary', 'button--small']">
        <ng-container button-dropdown-list="button-dropdown-list">
          <li class="button-dropdown-list__item" (click)="showModalForSection(); buttonDropdown.closeDropdown(); ">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ACTION.CUSTOM_CHAPTER' | translate }}</li>
          <li class="button-dropdown-list__item" (click)="showModalForQuestion(); buttonDropdown.closeDropdown(); ">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ACTION.CUSTOM_QUESTION' | translate }}</li>
        </ng-container>
      </gw-button-dropdown>
    </div>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="modal.opened">
    <gw-edit-questions #editQuestions="gwEditQuestions" [auditable]="auditableForModal"></gw-edit-questions>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="resetModal.open()">{{ 'GLOBAL.ACTION.RESET_ALL' | translate }}</button>
    <button class="button--primary" type="button" (click)="saveQuestionnaireModal()">{{ 'GLOBAL.ACTION.CLOSE' | translate }}</button>
  </ng-container>
</gw-modal>
<gw-modal id="questionsResetModal" #resetModal="gwModal">
  <ng-container modal-header="modal-header">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.RESET_QUESTIONS_DIALOG.HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content" *ngIf="resetModal.opened">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.RESET_QUESTIONS_DIALOG.CONTENT' | translate }}</ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="resetAllQuestions()">{{ 'GLOBAL.ACTION.YES' | translate }}</button>
    <button class="button--primary" type="button" (click)="resetModal.close()">{{ 'GLOBAL.ACTION.NO' | translate }}</button>
  </ng-container>
</gw-modal>
<gw-modal id="sectionModal" #sectionModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="sectionModal.data">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.CUSTOM_SECTION_MODAL.HEADER.EDIT' | translate }}</ng-container>
    <ng-container *ngIf="!sectionModal.data">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.CUSTOM_SECTION_MODAL.HEADER.ADD' | translate }}</ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="sectionModal.opened">
    <gw-add-section #addSection="gwAddSection" [composable]="auditableForModal" [section]="sectionModal.data"></gw-add-section>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="sectionModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="saveSectionModal()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<gw-modal id="questionModal" #questionModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="questionModal.data?.question">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.CUSTOM_QUESTION_MODAL.HEADER.EDIT' | translate }}</ng-container>
    <ng-container *ngIf="!questionModal.data?.question">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.CUSTOM_QUESTION_MODAL.HEADER.ADD' | translate }}</ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="questionModal.opened">
    <gw-add-question #addQuestion="gwAddQuestion" [composable]="auditableForModal" [sections]="auditableForModal.customSections" [section]="questionModal.data?.section" [question]="questionModal.data?.question"></gw-add-question>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" tabindex="5" (click)="questionModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" tabindex="6" (click)="saveQuestionModal()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="audit-questions" *ngIf="audit">
  <div class="audit-questions__box" *ngIf="isGeneralQuestionVisible">
    <button class="button button--secondary audit-questions__box-button" type="button" (click)="addGeneralQuestions()">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.ACTIONS.GENERAL_QUESTIONS' | translate }}</button>
    <h3 class="audit-questions__box-label">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.GENERAL_QUESTIONS_ACTION_DESC' | translate }}.</h3>
  </div>
  <h2>{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.AUDIT_QUESTIONS_HEADER' | translate }}</h2>
  <gw-expand-box class="audited-areas-group" *ngFor="let group of groupedAuditedArea" #auditExpandBox="gwExpandBox" [opened]="true">
    <ng-container expand-box-header="expand-box-header">
      <div class="row audited-areas-group__title-container">
        <div class="columns">
          <h3 class="audited-areas-group__title" (click)="auditExpandBox.toggle()"><i class="gw-caret--right h__icon--before expand-box__toggle-icon expand-box__toggle-icon--half audited-areas-group-title__icon"></i>{{ group[0].startTime | dateFormat: 'dddd' }},&nbsp;<small>{{ group[0].startTime | dateFormat: 'D MMMM YYYY' }}</small></h3>
        </div>
      </div>
    </ng-container>
    <ng-container expand-box-content="expand-box-content">
      <div class="flex-table audit-plan-table">
        <div class="flex-table__row audit-plan-table__header">
          <div class="flex-table__cell--th audit-plan__col--1">{{ 'GLOBAL.TABLE_LABEL.TIME' | translate }}</div>
          <div class="flex-table__section audit-plan-table__section">
            <div class="flex-table__cell--th audit-plan__col--2">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREA' | translate }}</div>
            <div class="flex-table__cell--th audit-plan__col--3">{{ 'GLOBAL.TABLE_LABEL.LOCATION' | translate }}</div>
            <div class="flex-table__cell--th audit-plan__col--4">{{ 'GLOBAL.TABLE_LABEL.AUDITOR' | translate }}</div>
            <div class="flex-table__cell--th audit-plan__col--5">{{ 'GLOBAL.TABLE_LABEL.AUDITEES' | translate }}</div>
          </div>
          <div class="flex-table__cell--th audit-plan__col--6">{{ 'GLOBAL.TABLE_LABEL.QUESTIONS' | translate }}</div>
        </div>
        <div class="flex-table__body">
          <div class="flex-table__row flex-table__row--indirect flex-table__row--independent audit-plan-table__row" *ngFor="let auditedArea of group; even as evenGroup" [class.flex-table__row--even]="evenGroup">
            <div class="flex-table__cell audit-plan__col--1 audit-table__status">{{ auditedArea.startTime | dateFormat: 'HH:mm' }} — {{ auditedArea.endTime | dateFormat: 'HH:mm' }}<i class="gw-clock audit-table__status-icon"></i></div>
            <div class="flex-table__section audit-plan-table__section">
              <div class="flex-table__cell audit-plan__col--2"><span class="audit-plan-table__label">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREA' | translate }}:&nbsp;</span>{{ auditedArea.facility.name }}</div>
              <div class="flex-table__cell audit-plan__col--3"><span class="audit-plan-table__label">{{ 'GLOBAL.TABLE_LABEL.LOCATION' | translate }}:&nbsp;</span>{{ auditedArea.location }}</div>
              <div class="flex-table__cell audit-plan__col--4 flex-table__cell--avatar"><span class="audit-plan-table__label">{{ 'GLOBAL.TABLE_LABEL.AUDITOR' | translate }}:&nbsp;</span>
                <gw-avatar class="auditors-cell__avatar" *ngFor="let auditor of auditedArea.employees.auditor" [employee]="auditor"></gw-avatar><span class="auditors-cell__name" *ngFor="let auditor of auditedArea.employees.auditor; last as lastEmployee">{{ auditor.lastname }}, {{ auditor.name }}{{ !lastEmployee ? ';&nbsp;' : '' }}</span>
              </div>
              <div class="flex-table__cell audit-plan__col--5 flex-table__cell--avatar"><span class="audit-plan-table__label">{{ 'GLOBAL.TABLE_LABEL.AUDITEES' | translate }}:&nbsp;</span>
                <gw-avatar class="auditors-cell__avatar" *ngFor="let auditee of auditedArea.employees.auditee" [employee]="auditee"></gw-avatar><span *ngFor="let auditee of auditedArea.employees.auditee; last as lastEmployee">{{ auditee.lastname }}, {{ auditee.name }}{{ !lastEmployee ? ';&nbsp;' : '' }}</span>
              </div>
              <div class="flex-table__cell audit-plan__col--tablet audit-plan-table__questions"><span class="audit-plan-table__label">{{ 'GLOBAL.TABLE_LABEL.QUESTIONS_IN_TOTALS' | translate }}:&nbsp;</span>{{ auditedArea.selectedQuestionsAmount }}</div>
            </div>
            <div class="flex-table__cell audit-plan__col--6 flex-table__options audit-questions__options">
              <div class="flex-table__options-content audit-questions__options-content"><span class="audit-plan-table__option--tablet" *ngIf="auditedArea.selectedQuestionsAmount &gt; 0">{{ auditedArea.selectedQuestionsAmount }}</span>
                <button class="link" type="button" *ngIf="auditedArea.selectedQuestionsAmount &gt; 0" (click)="addAuditedAreaQuestions(auditedArea)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                <button class="link--primary" type="button" *ngIf="auditedArea.selectedQuestionsAmount &lt;= 0" (click)="addAuditedAreaQuestions(auditedArea)">{{ 'GLOBAL.ACTION.ADD_QUESTION' | translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </gw-expand-box>
</div>
<div class="button-group" *ngIf="audit">
  <button class="button button--primary" (click)="saveStep()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
</div>
<div class="blankslate" *ngIf="!audit">{{ 'AUDIT_CREATION.ACTION.LOADING_AUDIT' | translate }}</div>
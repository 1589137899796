import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';

// Services
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'gw-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() position = 'absolute';
  @Input() blanket?: boolean;
  loading: boolean;

  constructor(public changeDetector: ChangeDetectorRef, private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.loaderService.add(this);
  }

  ngOnDestroy(): void {
    this.loaderService.remove(this.id);
  }
}


<gw-modal id="facilityModal" #modal="gwModal">
  <ng-container modal-header="modal-header">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.MODAL_HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content" *ngIf="modal.opened">
    <gw-select-facility #selectFacility="gwSelectFacility" [facilities]="facilities" [chosenFacility]="auditPlanForm.controls['auditedArea'].value" (submitFacilities)="chooseFacility($event); modal.close();"></gw-select-facility>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="modal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="selectFacility.submitSelectedFacilities()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="audit-plan" *ngIf="audit">
  <form class="audit-plan__form" *ngIf="auditPlanForm" [class.audit-plan__form--pristine]="!auditPlans?.length" [formGroup]="auditPlanForm" (ngSubmit)="submitAuditedArea(auditPlanForm)" novalidate="novalidate" autocomplete="off">
    <div class="row audit-step__fieldset">
      <div class="columns small-12 large-8">
        <div class="row audit-step__fields">
          <div class="columns small-12 medium-6 form__field audit-step__field" [class.form__field--error]="isFieldInvalid(auditPlanForm.controls['startTime']) || !!auditPlanForm.errors?.incorrectDateOrder &amp;&amp; submitted">
            <label class="label" for="startTime" (click)="firstDatePicker.focusOnDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.START_DATE' | translate }}</label>
            <div class="form-field__error">
              <ng-container *ngIf="auditPlanForm.controls['startTime'].errors?.required">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.START_DATE_EMPTY' | translate }}</ng-container>
              <ng-container *ngIf="!auditPlanForm.controls['startTime'].errors?.required">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.WRONG_DATE' | translate }}</ng-container>
            </div>
            <div class="dropdown dropdown--date-time-picker" gwSimpleDropdown="gwSimpleDropdown" #firstDatePicker="simpleDropdown" tabindex="1" [class.dropdown--opened]="firstDatePicker.opened" [class.dropdown--closed]="!firstDatePicker.opened &amp;&amp; !firstDatePicker.firstDrop" (keydown)="keyDownFirstDatePicker($event)"><span class="input" [class.input--placeholder]="!auditPlanForm.controls['startTime'].value">{{ auditPlanForm.controls['startTime'].value | dateFormat: 'D MMMM YYYY, HH:mm' }}<span *ngIf="!auditPlanForm.controls['startTime'].value">{{ 'AUDIT_CREATION.FORM.PLACEHOLDER.START_DATE' | translate }}</span></span>
              <div class="dropdown__content jsDropdownContent" [class.dropdown__content--horizontal-overflow]="firstDatePicker.isRightOverflow">
                <gw-date-time-picker *ngIf="firstDatePicker.opened" #startTimePicker="gwDateTimePicker" [date]="auditPlanForm.controls['startTime'].value" [label]="'AUDIT_CREATION.FORM.LABEL.START_DATE' | translate" (applyDate)="applyStartTime($event)"></gw-date-time-picker>
              </div>
            </div>
          </div>
          <div class="columns small-12 medium-6 form__field audit-step__field" [class.form__field--error]="isFieldInvalid(auditPlanForm.controls['endTime']) || !!auditPlanForm.errors?.incorrectDateOrder &amp;&amp; submitted">
            <label class="label" for="endTime" (click)="secondDatePicker.focusOnDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.END_DATE' | translate }}</label>
            <div class="form-field__error">
              <ng-container *ngIf="auditPlanForm.controls['endTime'].errors?.required">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.END_DATE_EMPTY' | translate }}</ng-container>
              <ng-container *ngIf="!auditPlanForm.controls['endTime'].errors?.required">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.WRONG_DATE' | translate }}</ng-container>
            </div>
            <div class="dropdown dropdown--date-time-picker" gwSimpleDropdown="gwSimpleDropdown" #secondDatePicker="simpleDropdown" tabindex="2" [class.dropdown--opened]="secondDatePicker.opened" [class.dropdown--closed]="!secondDatePicker.opened &amp;&amp; !secondDatePicker.firstDrop" (keydown)="keyDownSecondDatePicker($event)"><span class="input" [class.input--placeholder]="!auditPlanForm.controls['endTime'].value">{{ auditPlanForm.controls['endTime'].value | dateFormat: 'D MMMM YYYY, HH:mm' }}<span *ngIf="!auditPlanForm.controls['endTime'].value">{{ 'AUDIT_CREATION.FORM.PLACEHOLDER.END_DATE' | translate }}</span></span>
              <div class="dropdown__content jsDropdownContent" [class.dropdown__content--horizontal-overflow]="secondDatePicker.isRightOverflow">
                <gw-date-time-picker *ngIf="secondDatePicker.opened" #endTimePicker="gwDateTimePicker" [date]="auditPlanForm.controls['endTime'].value" [label]="'AUDIT_CREATION.FORM.LABEL.END_DATE' | translate" (applyDate)="applyEndTime($event)"></gw-date-time-picker>
              </div>
            </div>
          </div>
          <div class="columns small-12 medium-6 form__field audit-step__field" [class.form__field--error]="isFieldInvalid(auditPlanForm.controls['auditedArea'])">
            <label class="label" for="auditedArea" (click)="auditedAreaElement.focus()">{{ 'AUDIT_CREATION.FORM.LABEL.AUDITED_AREA' | translate }}</label>
            <div class="form-field__error">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.AUDITED_AREA_EMPTY' | translate }}</div>
            <div class="audit-plan-form__area" #auditedAreaElement="" tabindex="3" (click)="openFacilitiesModal()" (keydown)="keyDownFacilityControl($event)"><span class="input audit-plan-area__input" [class.audit-plan-area__input--placeholder]="!auditPlanForm.controls['auditedArea'].value">{{ auditPlanForm.controls['auditedArea'].value.name }}
                <ng-container *ngIf="!auditPlanForm.controls['auditedArea'].value">{{ 'AUDIT_CREATION.FORM.PLACEHOLDER.AUDITED_AREA' | translate }}</ng-container></span>
              <button class="button--secondary button--small audit-plan-area__action" *ngIf="auditPlanForm.controls['auditedArea'].value" type="button">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ACTION.AUDITED_AREA_EDIT' | translate }}</button>
            </div>
          </div>
          <div class="columns small-12 medium-6 form__field audit-step__field">
            <label class="label" for="location">{{ 'AUDIT_CREATION.FORM.LABEL.LOCATION' | translate }}</label>
            <input class="input" placeholder="{{ 'AUDIT_CREATION.FORM.PLACEHOLDER.LOCATION' | translate }}" type="text" id="location" name="location" tabindex="4" [formControl]="auditPlanForm.controls['location']"/>
          </div>
          <div class="columns small-12 medium-6 form__field audit-step__field" [class.form__field--error]="isFieldInvalid(auditPlanForm.controls['auditor'])">
            <label class="label" for="auditor" (click)="auditorSelect.focusDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.AUDITOR' | translate }}</label>
            <div class="form-field__error">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.AUDITOR_EMPTY' | translate }}</div>
            <gw-multiselect #auditorSelect="gwMultiselect" [formControl]="auditPlanForm.controls['auditor']" [tabindex]="5" [placeholder]="'AUDIT_CREATION.FORM.PLACEHOLDER.AUDITOR' | translate" [data]="auditorsList" [display]="['lastname', 'name']" [splitter]="', '" [separator]="'; '" [uniqueKey]="'id'" (focus)="auditorSelect.focusDropdown()"></gw-multiselect>
          </div>
          <div class="columns small-12 medium-6 form__field audit-step__field" [class.form__field--error]="isFieldInvalid(auditPlanForm.controls['auditees'])">
            <label class="label" for="auditee" (click)="auditeeSelect.focusDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.AUDITEES' | translate }}</label>
            <div class="form-field__error">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.AUDITEES_EMPTY' | translate }}</div>
            <gw-multiselect #auditeeSelect="gwMultiselect" [formControl]="auditPlanForm.controls['auditees']" [tabindex]="6" [placeholder]="'AUDIT_CREATION.FORM.PLACEHOLDER.AUDITEES' | translate" [data]="employees" [display]="['lastname', 'name']" [splitter]="', '" [separator]="'; '" [uniqueKey]="'id'" (focus)="auditeeSelect.focusDropdown()"></gw-multiselect>
          </div>
        </div>
      </div>
      <div class="columns small-12 audit-step__fields audit-plan-form__action" [class.large-4]="auditPlans?.length" [class.large-12]="!auditPlans?.length">
        <div class="button-group audit-step__field audit-step__field--button-group">
          <ng-container *ngIf="editedAuditedArea">
            <button class="link audit-step__button" type="button" tabindex="8" (click)="cancelEdition(); firstDatePicker.focusOnDropdown();">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
            <button class="button--secondary audit-step__button" type="submit" tabindex="7" (click)="firstDatePicker.focusOnDropdown()">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ACTION.SAVE_THE_AUDIT' | translate }}</button>
          </ng-container>
          <ng-container *ngIf="!editedAuditedArea">
            <button class="button--primary audit-step__button" *ngIf="!auditPlans?.length" type="submit" tabindex="7" (click)="firstDatePicker.focusOnDropdown()">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ACTION.PLAN_THE_AUDIT' | translate }}</button>
            <button class="button--secondary audit-step__button" *ngIf="auditPlans?.length" type="submit" tabindex="7" (click)="firstDatePicker.focusOnDropdown()">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ACTION.PLAN_NEXT_AUDIT' | translate }}</button>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
  <ng-container *ngIf="auditPlans?.length">
    <h2>{{ 'AUDIT_CREATION.S_AUDIT_PLAN.AUDIT_PLAN_HEADER' | translate }}</h2>
    <gw-expand-box class="audited-areas-group" *ngFor="let group of groupedAuditPlans" #auditExpandBox="gwExpandBox" [opened]="true">
      <ng-container expand-box-header="expand-box-header">
        <div class="row audited-areas-group__title-container">
          <div class="columns">
            <h3 class="audited-areas-group__title" (click)="auditExpandBox.toggle()"><i class="gw-caret--right h__icon--before expand-box__toggle-icon expand-box__toggle-icon--half audited-areas-group-title__icon"></i>{{ group[0].startTime | dateFormat: 'dddd' }},&nbsp;<small>{{ group[0].startTime | dateFormat: 'D MMMM YYYY' }}</small></h3>
          </div>
        </div>
      </ng-container>
      <ng-container expand-box-content="expand-box-content">
        <div class="flex-table audit-plan-table">
          <div class="flex-table__row audit-plan-table__header">
            <div class="flex-table__cell--th audit-plan__col--1">{{ 'GLOBAL.TABLE_LABEL.TIME' | translate }}</div>
            <div class="flex-table__section audit-plan-table__section">
              <div class="flex-table__cell--th audit-plan__col--2">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREA' | translate }}</div>
              <div class="flex-table__cell--th audit-plan__col--3">{{ 'GLOBAL.TABLE_LABEL.LOCATION' | translate }}</div>
              <div class="flex-table__cell--th audit-plan__col--4">{{ 'GLOBAL.TABLE_LABEL.AUDITOR' | translate }}</div>
              <div class="flex-table__cell--th audit-plan__col--5">{{ 'GLOBAL.TABLE_LABEL.AUDITEES' | translate }}</div>
            </div>
            <div class="flex-table__cell--th audit-plan__col--6">&nbsp;</div>
          </div>
          <div class="flex-table__body">
            <div class="flex-table__row flex-table__row--indirect flex-table__row--independent audit-plan-table__row" *ngFor="let auditPlan of group; even as evenGroup" [class.flex-table__row--even]="evenGroup">
              <div class="flex-table__cell audit-plan__col--1 audit-table__status">{{ auditPlan.startTime | dateFormat: 'HH:mm' }} — {{ auditPlan.endTime | dateFormat: 'HH:mm' }}<i class="gw-clock audit-table__status-icon"></i></div>
              <div class="flex-table__section audit-plan-table__section">
                <div class="flex-table__cell audit-plan__col--2"><span class="audit-plan-table__label">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREA' | translate }}:&nbsp;</span>
                  <ng-container *ngIf="auditPlan.facility">{{ auditPlan.facility.name }}</ng-container>
                </div>
                <div class="flex-table__cell audit-plan__col--3"><span class="audit-plan-table__label">{{ 'GLOBAL.TABLE_LABEL.LOCATION' | translate }}:&nbsp;</span>{{ auditPlan.location }}</div>
                <div class="flex-table__cell audit-plan__col--4 flex-table__cell--avatar"><span class="audit-plan-table__label">{{ 'GLOBAL.TABLE_LABEL.AUDITOR' | translate }}:&nbsp;</span>
                  <gw-avatar class="auditors-cell__avatar" *ngFor="let auditor of auditPlan.employees.auditor" [employee]="auditor"></gw-avatar><span class="auditors-cell__name" *ngFor="let auditor of auditPlan.employees.auditor; last as lastEmployee">{{ auditor.lastname }}, {{ auditor.name }}{{ !lastEmployee ? ';&nbsp;' : '' }}</span>
                </div>
                <div class="flex-table__cell audit-plan__col--5 flex-table__cell--avatar"><span class="audit-plan-table__label">{{ 'GLOBAL.TABLE_LABEL.AUDITEES' | translate }}:&nbsp;</span>
                  <gw-avatar class="auditors-cell__avatar" *ngFor="let auditee of auditPlan.employees.auditee" [employee]="auditee"></gw-avatar><span class="auditors-cell__name" *ngFor="let auditee of auditPlan.employees.auditee; last as lastEmployee">{{ auditee.lastname }}, {{ auditee.name }}{{ !lastEmployee ? ';&nbsp;' : '' }}</span>
                </div>
              </div>
              <div class="flex-table__cell audit-plan__col--6 flex-table__options">
                <div class="flex-table__options-content"><span class="dropdown dropdown--menu flex-table__option--dropdown" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
                    <button class="flex-table__option flex-table__option--more gw-more" type="button"></button>
                    <div class="dropdown__content jsDropdownContent">
                      <ul class="dropdown-menu__list">
                        <li class="dropdown-menu__item">
                          <button class="link dropdown-menu__link" type="button" (click)="editAuditedArea(auditPlan)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                        </li>
                        <li class="dropdown-menu__item">
                          <button class="link dropdown-menu__link" type="button" (click)="removeAuditedArea(auditPlan)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                        </li>
                      </ul>
                    </div></span></div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </gw-expand-box>
  </ng-container>
</div>
<div class="button-group" *ngIf="auditPlans?.length">
  <button class="button button--primary" tabindex="10" (click)="submitAudit()">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ACTION.GO_TO_SCHEDULE_AUDIT' | translate }}<i class="gw-icon gw-arrow--right button__icon--after"></i></button>
</div>
<div class="blankslate" *ngIf="!audit">{{ 'AUDIT_CREATION.ACTION.LOADING_AUDIT' | translate }}</div>
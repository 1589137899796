import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
// Models
import { Questionnaire } from './../../../../shared/models/questionnaire.model';
// Services
import { QuestionnaireService } from './../../../../shared/services/questionnaire.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gw-add-reference-document',
  templateUrl: './add-reference-document.component.html',
  styleUrls: ['./add-reference-document.component.scss'],
  exportAs: 'gwAddReferenceDocument'
})
export class AddReferenceDocumentComponent implements OnInit, OnDestroy {
  @Input() questionnaire: Questionnaire;
  addQuestionnaireForm: FormGroup;
  submitted = false;
  translateSubscription: Subscription;
  notify = {
    error: {
      connectionTitle: '',
      createReferenceDocument: '',
      updateReferenceDocument: ''
    },
    success: {
      createReferenceDocumentTitle: '',
      createReferenceDocumentText: '',
      updateReferenceDocumentTitle: '',
      updateReferenceDocumentText: ''
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private questionnaireService: QuestionnaireService
  ) { }

  ngOnInit() {
    this.translateSubscription = this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.CREATE_REFERENCE_DOCUMENT',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.UPDATE_REFERENCE_DOCUMENT',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_REFERENCE_DOCUMENT_TITLE',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_REFERENCE_DOCUMENT_TEXT',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_REFERENCE_DOCUMENT_TITLE',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_REFERENCE_DOCUMENT_TEXT'
      ])
      .subscribe((translation: any) => {
        this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.createReferenceDocument = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.CREATE_REFERENCE_DOCUMENT'];
        this.notify.error.updateReferenceDocument = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.UPDATE_REFERENCE_DOCUMENT'];
        this.notify.success.createReferenceDocumentTitle = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_REFERENCE_DOCUMENT_TITLE'];
        this.notify.success.createReferenceDocumentText = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.CREATE_REFERENCE_DOCUMENT_TEXT'];
        this.notify.success.updateReferenceDocumentTitle = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_REFERENCE_DOCUMENT_TITLE'];
        this.notify.success.updateReferenceDocumentText = translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.SUCCESS.UPDATE_REFERENCE_DOCUMENT_TEXT'];
    });

    this.addQuestionnaireForm = this.formBuilder.group({
      name: ['', Validators.required]
    });
    if (this.questionnaire) {
      this.setControlValue(this.questionnaire.name, 'name');
    }
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
  }

  resetForm(): void {
    if (this.questionnaire) {
      this.setControlValue(this.questionnaire.name, 'name');
    } else {
      this.setControlValue('', 'name');
    }
  }

  setControlValue(data: any, key: string): void {
    this.addQuestionnaireForm.controls[key].setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  createQuestionnaire(): Promise<any> {
    const questionnaireToCreate = new Questionnaire(this.addQuestionnaireForm.value);
    return this.questionnaireService.createQuestionnaire(questionnaireToCreate)
      .then(questionnaire => {
        this.resetForm();
        this.submitted = false;
        this.notificationsService.success(
          this.notify.success.createReferenceDocumentTitle,
          this.notify.success.createReferenceDocumentText
        );
        return {
          status: 'create',
          questionnaire
        };
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.createReferenceDocument);
        throw error;
      });
  }

  updateQuestionnaire(): Promise<any> {
    const questionnaireToCreate = new Questionnaire(this.addQuestionnaireForm.value);
    questionnaireToCreate.id = this.questionnaire.id;
    return this.questionnaireService.updateQuestionnaire(questionnaireToCreate)
      .then(questionnaire => {
        this.resetForm();
        this.submitted = false;
        this.notificationsService.success(
          this.notify.success.updateReferenceDocumentTitle,
          this.notify.success.updateReferenceDocumentText
        );
        return {
          status: 'update',
          questionnaire
        };
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.updateReferenceDocument);
        throw error;
      });
  }

  submitAddQuestionnaireForm(): Promise<any> {
    this.submitted = true;
    if (this.addQuestionnaireForm.valid) {
      if (this.questionnaire) {
        return this.updateQuestionnaire();
      } else {
        return this.createQuestionnaire();
      }
    } else {
      return new Promise((resolve, reject) => reject({ invalid: true }));
    }
  }
}

import { Injectable } from '@angular/core';
import { CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras } from '@angular/router';
// Gutwin Shared Library
import { LoginService } from 'gutwin-shared';

@Injectable()
export class LoginChildGuard implements CanActivateChild {

  constructor(private loginService: LoginService, private router: Router) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const navigationExtras: NavigationExtras = {
      queryParams: { 'redirect_url': state.url }
    };

    if (this.loginService.isLoggedIn()) {
      return true;
    }

    this.router.navigate(['login'], navigationExtras);
    return false;
  }

}

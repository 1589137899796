import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isEqual'
})
export class IsEqualPipe implements PipeTransform {
  transform(value: any, secondValue: any): boolean {
    return value === secondValue;
  }
}

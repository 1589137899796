import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
// Services
import { NotificationsService } from 'angular2-notifications';

import { AuditType } from '@gutwin-audit/shared/models/audit-type.model';
// Models
import { DocumentPreview, Report } from '@gutwin-audit/shared/models/report.model';

import { AuditTypeService } from '@gutwin-audit/shared/services/audit-type.service';
import { ReportsService } from '@gutwin-audit/shared/services/reports.service';

@Component({
  selector: 'gw-add-report',
  templateUrl: './add-report.component.html',
  styleUrls: ['./add-report.component.scss'],
  exportAs: 'gwAddReport'
})
export class AddReportComponent implements OnInit {
  @Input() report?: Report;
  addReportForm: FormGroup;
  submitted = false;
  auditTypes: Array<AuditType> = [];
  reportImportLabel: string;
  documentPreview: DocumentPreview;
  errors = {
    connectionTitle: '',
    fetchAuditTypesText: '',
    createReport: '',
    updateReport: ''
  };
  success = {
    createReportTitle: '',
    createReportText: '',
    updateReportTitle: '',
    updateReportText: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private auditTypeService: AuditTypeService,
    private notificationsService: NotificationsService,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {
    this.fetchTranslations();
    this.fetchAuditTypes();
    this.initForm();

    if (this.report) {
      this.setFormValues();
    }
  }

  initForm(): void {
    const auditTypesValidator = this.report && this.report.default ? null : Validators.required;

    this.addReportForm = this.formBuilder.group({
      name: ['', Validators.required],
      auditTypes: [[], auditTypesValidator],
      document: [{}],
      companyLogoWidth: ['', Validators.required],
      questionPhotoWidth: ['', Validators.required],
      findingPhotoWidth: ['', Validators.required]
    });
  }

  setFormValues(): void {
    this.setControlValue(this.report.name, 'name');
    this.setControlValue(this.report.auditTypes, 'auditTypes');
    this.setControlValue(this.report.companyLogoWidth, 'companyLogoWidth');
    this.setControlValue(this.report.questionPhotoWidth, 'questionPhotoWidth');
    this.setControlValue(this.report.findingPhotoWidth, 'findingPhotoWidth');
  }

  fetchAuditTypes(): void {
    this.auditTypeService
      .getAuditTypes()
      .then((auditTypes: Array<AuditType>) => {
        this.auditTypes = auditTypes;
      })
      .catch(() => {
        this.notificationsService.error(this.errors.connectionTitle, this.errors.fetchAuditTypesText);
      });
  }

  setControlValue(data: any, key: string): void {
    this.addReportForm.controls[key].setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  fileAdded(event: Event): void {
    this.addReportForm.value.document.file = event.target['files'][0];
    this.documentPreview = {
      name: this.addReportForm.value.document.file.name,
      url: window.URL.createObjectURL(this.addReportForm.value.document.file)
    };
    this.addReportForm.controls['document'].setErrors(null);
  }

  createReport(): Promise<{ status: string; report: Report }> {
    const reportToCreate = new Report(this.addReportForm.value);
    return this.reportsService
      .createReport(reportToCreate, this.addReportForm.value.document.file)
      .then(response => {
        this.notificationsService.success(this.success.createReportTitle, this.success.createReportText);
        return { status: 'create', report: response };
      })
      .catch(error => {
        this.notificationsService.error(this.errors.connectionTitle, this.errors.createReport);
        throw error;
      });
  }

  updateReport(): Promise<{ status: string; report: Report }> {
    const reportToUpdate = new Report({ ...this.report, ...this.addReportForm.value });
    return this.reportsService
      .updateReport(reportToUpdate, this.addReportForm.value.document.file)
      .then(response => {
        this.notificationsService.success(this.success.updateReportTitle, this.success.updateReportText);
        return Promise.resolve({ status: 'update', report: response });
      })
      .catch(error => {
        this.notificationsService.error(this.errors.connectionTitle, this.errors.updateReport);
        throw error;
      });
  }

  submitAddReportForm(): Promise<{ status: string; report: Report }> {
    this.submitted = true;
    if (!this.addReportForm.value.document.hasOwnProperty('file') && !this.report) {
      this.addReportForm.controls.document.setErrors({ required: true });
    }
    if (this.addReportForm.valid) {
      if (this.report) {
        return this.updateReport();
      } else {
        return this.createReport();
      }
    }
    return Promise.reject({ invalid: true });
  }

  async fetchTranslations(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'ADMIN_SETTINGS.REPORTS.MODAL.ERROR.FETCH_AUDIT_TYPES',
        'ADMIN_SETTINGS.REPORTS.SUCCESS.CREATE_REPORT_TITLE',
        'ADMIN_SETTINGS.REPORTS.SUCCESS.CREATE_REPORT_TEXT',
        'ADMIN_SETTINGS.REPORTS.ERROR.CREATE_REPORT',
        'ADMIN_SETTINGS.REPORTS.ERROR.UPDATE_REPORT',
        'ADMIN_SETTINGS.REPORTS.SUCCESS.UPDATE_REPORT_TITLE',
        'ADMIN_SETTINGS.REPORTS.SUCCESS.UPDATE_REPORT_TEXT'
      ])
      .toPromise();
    this.errors.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.errors.fetchAuditTypesText = translation['ADMIN_SETTINGS.REPORTS.MODAL.ERROR.FETCH_AUDIT_TYPES'];
    this.errors.createReport = translation['ADMIN_SETTINGS.REPORTS.ERROR.CREATE_REPORT'];
    this.errors.updateReport = translation['ADMIN_SETTINGS.REPORTS.ERROR.UPDATE_REPORT'];
    this.success.createReportText = translation['ADMIN_SETTINGS.REPORTS.SUCCESS.CREATE_REPORT_TEXT'];
    this.success.createReportTitle = translation['ADMIN_SETTINGS.REPORTS.SUCCESS.CREATE_REPORT_TITLE'];
    this.success.updateReportText = translation['ADMIN_SETTINGS.REPORTS.SUCCESS.UPDATE_REPORT_TEXT'];
    this.success.updateReportTitle = translation['ADMIN_SETTINGS.REPORTS.SUCCESS.UPDATE_REPORT_TITLE'];
  }
}

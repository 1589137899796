import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
// Gutwin Shared Library
import { Attachment, FileService, ModalService } from 'gutwin-shared';
// Models
import { AuditedArea } from './../../models/audited-area.model';
import { Finding } from './../../models/finding.model';
import { Question, QUESTION_TYPE } from './../../models/question.model';
import { Section } from './../../models/section.model';
// Services
import { FindingService } from './../../services/finding.service';

@Component({
  selector: 'gw-add-finding',
  exportAs: 'gwAddFinding',
  templateUrl: './add-finding.component.html',
  styleUrls: ['./add-finding.component.scss']
})
export class AddFindingComponent implements OnInit, OnDestroy {
  @Input() finding: Finding;
  @Input() findingTypes: Array<Finding>;
  @Input() auditedArea: AuditedArea;
  @Input() sections: Array<Section>;
  @Input() defaultFindable: AuditedArea | Question;
  @Input() offlineSupport: boolean;
  findableData: Array<any>;
  findableAuditedAreaName: string;
  addFindingForm: FormGroup;
  submitted = false;
  attachments = new Array<Attachment>();
  duplications = new Array<Attachment>();
  attachmentsAmount: number;
  translateSubscription: Subscription;
  fileHashSubscription;

  notify = {
    error: {
      connectionTitle: '',
      createFinding: '',
      updateFinding: ''
    },
    success: {
      createFindingTitle: '',
      createFindingText: '',
      updateFindingTitle: '',
      updateFindingText: ''
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private findingService: FindingService,
    private fileService: FileService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    if (this.finding) {
      if (this.finding.getLatestValue('relationships.findable')) {
        this.defaultFindable = this.getFindableById(this.finding.getLatestValue('relationships.findable').id);
      }
      this.fillAttachments();
      this.attachmentsAmount = this.getAttachmentsAmount();
    }
    this.initForm();
    this.fetchTranslation();
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
  }

  initForm(): void {
    this.addFindingForm = this.formBuilder.group({
      type: [this.finding ? this.finding.getLatestValue('relationships.findingType') || '' : '', Validators.required],
      findable: [this.getFindableControlValue() || '', Validators.required],
      problem: [this.finding ? this.finding.getLatestValue('problem') || '' : '', Validators.required],
      cause: [this.finding ? this.finding.getLatestValue('cause') || '' : ''],
      solution: [this.finding ? this.finding.getLatestValue('solution') || '' : '']
    });
    this.findableData = this.getFindableData();
  }

  resetForm(): void {
    this.setControlValue('', 'type');
    this.setControlValue('', 'findable');
    this.setControlValue('', 'problem');
    this.setControlValue('', 'cause');
    this.setControlValue('', 'solution');
  }

  getFindableControlValue(): any {
    if (this.defaultFindable && this.defaultFindable.id === this.auditedArea.id) {
      return Object.assign({ name: this.findableAuditedAreaName }, this.defaultFindable);
    } else {
      return this.defaultFindable;
    }
  }

  updateFindableControlValue(): void {
    if (this.addFindingForm.controls['findable'].value
      && this.addFindingForm.controls['findable'].value.id === this.defaultFindable.id) {
      this.setControlValue(this.getFindableControlValue(), 'findable');
    }
  }

  setControlValue(data: any, key: string): void {
    this.addFindingForm.controls[key].setValue(data);
  }

  isFieldInvalid(field): boolean {
    return !field.valid && this.submitted;
  }

  getFindableById(findableId: string): AuditedArea | Question {
    if (this.auditedArea.id === findableId) {
      return this.auditedArea;
    } else if (this.sections) {
      for (const section of this.sections) {
        for (const question of section.questions) {
          if (question.id === findableId) {
            return question;
          }
        }
      }
    }
  }

  getFindableData(): Array<any> {
    let findableData = new Array<any>();
    const auditedAreaFindable = Object.assign({ name: this.findableAuditedAreaName }, this.auditedArea);
    findableData.push(auditedAreaFindable);
    if (this.sections) {
      findableData = findableData.concat(this.sections);
    }
    return findableData;
  }

  getSectionByQuestionId(questionId: string): Section {
    for (const section of this.sections) {
      const index = _.findIndex(section.questions, {id: questionId});
      if (~index) {
        return section;
      }
    }
  }

  createFinding(finding: Finding): Promise<Finding> {
    return this.findingService.createFinding(finding, this.auditedArea, this.offlineSupport)
      .then(findingResponse => {
        this.notificationsService.success(this.notify.success.createFindingTitle, this.notify.success.createFindingText);
        return findingResponse;
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.createFinding);
        throw error;
      });
  }

  updateFinding(finding: Finding): Promise<Finding> {
    return this.findingService.updateFinding(finding, this.finding, this.offlineSupport)
      .then(findingResponse => {
        this.notificationsService.success(this.notify.success.updateFindingTitle, this.notify.success.updateFindingText);
        return findingResponse;
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.updateFinding);
        throw error;
      });
  }

  convertFindingForm(addFindingFormValue: any): Finding {
    const question = addFindingFormValue.findable._type === QUESTION_TYPE ? addFindingFormValue.findable as Question : undefined;
    const section = question ? this.getSectionByQuestionId(question.id) : undefined;
    const findingRequest = {
      changes: this.finding ? {
        employee: this.finding.changes.employee ||
          this.finding.offlineRequest && this.finding.offlineRequest.changes.employee ||
          this.finding.temporaryFinding && this.finding.temporaryFinding.changes.employee,
        deadline: this.finding.changes.deadline ||
          this.finding.offlineRequest && this.finding.offlineRequest.changes.deadline ||
          this.finding.temporaryFinding && this.finding.temporaryFinding.changes.deadline,
      } : undefined,
      relationships: {
        auditedArea: this.auditedArea,
        findable: addFindingFormValue.findable,
        findingType: addFindingFormValue.type,
        section: section ? section.name : undefined,
        question: question ? question.content : undefined,
        employee: this.finding ? this.finding.getLatestValue('relationships.employee') : undefined
      },
      id: this.finding ? this.finding.id : undefined,
      status: this.finding ? this.finding.getLatestValue('status') : undefined,
      problem: addFindingFormValue.problem,
      cause: addFindingFormValue.cause,
      solution: addFindingFormValue.solution,
      attachments: this.attachments,
      deadline: this.finding ? this.finding.getLatestValue('deadline') : undefined,
    };
    return new Finding(findingRequest);
  }

  submitAddFindingForm(): Promise<Finding> {
    this.submitted = true;
    if (this.addFindingForm.valid) {
      const findingForRequest = this.convertFindingForm(this.addFindingForm.value);

      const findingPromise = this.finding ? this.updateFinding(findingForRequest) : this.createFinding(findingForRequest);
      return findingPromise
        .then(finding => {
          this.resetForm();
          this.submitted = false;
          return finding;
        });
    } else {
      return Promise.reject({ invalid: true });
    }
  }

  fillAttachments(): void {
    this.attachments = new Array<Attachment>();
    if (this.finding) {
      if (this.finding.attachments) {
        this.attachments.push(...this.finding.attachments);
      }
      if (this.finding.offlineRequest && this.finding.offlineRequest.attachments) {
        this.attachments.push(...this.finding.offlineRequest.attachments);
      }
    }
  }

  keyDownAttachmentControl(event: any, fileInput: any): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      fileInput.click(event);
    }
  }

  attachFiles(event) {
    this.duplications = new Array<Attachment>();
    const fileList: FileList = event.target.files;
    let fileCounter = 0;

    if (fileList && fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const file: File = fileList[i];

        this.fileService.readFile(file)
          .then(({ hash, rotation }) => {
            const attachment = new Attachment({ file });
            attachment.checksum = hash;
            attachment.rotation = rotation;
            fileCounter++;

            if (!this.fileService.isDuplicated(attachment, this.attachments, this.duplications)) {
              this.attachments.push(attachment);
              this.attachmentsAmount = this.getAttachmentsAmount();
            }

            if (fileCounter === fileList.length) {
              if (this.duplications.length) {
                this.modalService.open('findingDuplicationsModal');
              }
            }
          });
      }
    }
  }

  removeAttachment(attachment: Attachment): void {
    if (this.finding && (attachment.url || attachment.offline)) {
      this.spliceAttachment(attachment, true);
    } else {
      this.spliceAttachment(attachment);
    }
    this.attachmentsAmount = this.getAttachmentsAmount();
  }

  spliceAttachment(attachment: Attachment, viewOnly?: boolean): void {
    if (viewOnly) {
      attachment.toRemove = true;
    } else {
      const index = this.attachments.indexOf(attachment);
      this.attachments.splice(index, 1);
    }
  }

  getAttachmentsAmount(): number {
    let amount = 0;
    if (this.attachments) {
      this.attachments.forEach(attachment => {
        if (!attachment.toRemove) {
          amount++;
        }
      });
    }
    return amount;
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ERROR.PERMISSIONS',
      'AUDIT_CONDUCT.FINDINGS_MODAL.ERROR.CREATE_FINDING',
      'AUDIT_CONDUCT.FINDINGS_MODAL.ERROR.UPDATE_FINDING',
      'AUDIT_CONDUCT.FINDINGS_MODAL.SUCCESS.CREATE_FINDING_TITLE',
      'AUDIT_CONDUCT.FINDINGS_MODAL.SUCCESS.CREATE_FINDING_TEXT',
      'AUDIT_CONDUCT.FINDINGS_MODAL.SUCCESS.UPDATE_FINDING_TITLE',
      'AUDIT_CONDUCT.FINDINGS_MODAL.SUCCESS.UPDATE_FINDING_TEXT',
      'AUDIT_CONDUCT.FINDINGS_MODAL.FORM.FIELD.AUDITED_AREA_FINDABLE'
    ])
      .subscribe((translation: string) => {
        this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.createFinding = translation['AUDIT_CONDUCT.FINDINGS_MODAL.ERROR.CREATE_FINDING'];
        this.notify.error.updateFinding = translation['AUDIT_CONDUCT.FINDINGS_MODAL.ERROR.UPDATE_FINDING'];
        this.notify.success.createFindingTitle = translation['AUDIT_CONDUCT.FINDINGS_MODAL.SUCCESS.CREATE_FINDING_TITLE'];
        this.notify.success.createFindingText = translation['AUDIT_CONDUCT.FINDINGS_MODAL.SUCCESS.CREATE_FINDING_TEXT'];
        this.notify.success.updateFindingTitle = translation['AUDIT_CONDUCT.FINDINGS_MODAL.SUCCESS.UPDATE_FINDING_TITLE'];
        this.notify.success.updateFindingText = translation['AUDIT_CONDUCT.FINDINGS_MODAL.SUCCESS.UPDATE_FINDING_TEXT'];
        this.findableAuditedAreaName = translation['AUDIT_CONDUCT.FINDINGS_MODAL.FORM.FIELD.AUDITED_AREA_FINDABLE'];
        this.updateFindableControlValue();
        this.findableData = this.getFindableData();
    });
  }
}

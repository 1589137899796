import { Injectable } from '@angular/core';

import { find, without } from 'lodash';

import { PopoverComponent } from '../components/popover/popover.component';

@Injectable({
  providedIn: 'root'
})
export class PopoverService {
  public popovers = new Array<PopoverComponent>();

  find(id: string): PopoverComponent {
    return find(this.popovers, { id });
  }

  findAll(id: string): Array<PopoverComponent> {
    return this.popovers.filter(popover => popover.id === id);
  }

  add(popover: PopoverComponent): void {
    this.popovers.push(popover);
  }

  remove(id: string): void {
    const popoverToRemove = this.find(id);
    this.popovers = without(this.popovers, popoverToRemove);
  }

  open(id: string): void {
    const popover = this.find(id);
    popover.open();
  }

  close(id: string): void {
    const popovers = this.findAll(id);
    popovers.forEach(popover => popover.close());
  }
}

import { Component, OnInit } from '@angular/core';
// Services
import { AuditNavigationService } from './../../services/audit-navigation.service';

@Component({
  selector: 'gw-reference-documents',
  templateUrl: './reference-documents.component.html',
  styleUrls: ['./reference-documents.component.scss']
})
export class ReferenceDocumentsComponent implements OnInit {

  constructor(public auditNavigationService: AuditNavigationService) { }

  ngOnInit() { }
}

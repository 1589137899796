import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SimpleNotificationsModule } from 'angular2-notifications';

import { getErrorHandler, GutwinSharedModule, HttpClientInterceptor, LoginService } from 'gutwin-shared';

import { environment } from '../environments/environment';

import { AdminModule } from './admin/admin.module';
// Modules
import { AppRoutingModule } from './app.routing.module';
import { AuditModule } from './audit/audit.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { SharedModule } from './shared/shared.module';

// Components
import { AppComponent } from './app.component';

// Services
import { ApiUrlService } from './shared/services/api-url.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { LoginModuleService } from './shared/services/login-module.service';
import { StorageModuleService } from './shared/services/storage-module.service';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AuthenticationModule,
    AuditModule,
    AdminModule,
    AppRoutingModule,
    SharedModule.forRoot(),
    GutwinSharedModule.forRoot(environment),
    SimpleNotificationsModule.forRoot(),
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    ApiUrlService,
    AuthenticationService,
    StorageModuleService,
    { provide: LoginService, useClass: LoginModuleService },
    {
      provide: 'dsn',
      useValue: environment.sentryUrl
    },
    {
      provide: 'prod',
      useValue: environment.production
    },
    { provide: ErrorHandler, useFactory: getErrorHandler, deps: ['dsn', 'prod'] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpClientInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

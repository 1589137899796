import { Injectable } from '@angular/core';

import * as _ from 'lodash';

// Gutwin Shared Library
import { Attachment, FileService } from 'gutwin-shared';

import { Finding } from '../models/finding.model';
import { QUESTION_TYPE } from '../models/question.model';

import { FindingsRequests } from './finding.offline.service';
// Services
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';

export interface SimpleAttachment {
  base64?: string;
  contentType?: string;
  url?: string;
  name: string;
  checksum: string;
  rotation?: number;
  toRemove?: boolean;
}

export interface QuestionMediaAttachmentRequest {
  questionId: string;
  questionMediaId: string;
  attachment: Attachment;
}

export class QuestionMediaAttachment {
  questionId: string;
  questionMediaId: string;
  attachment: SimpleAttachment;

  constructor(questionMediaAttachment: any) {
    this.questionId = questionMediaAttachment.questionId;
    this.questionMediaId = questionMediaAttachment.questionMediaId;
    this.attachment = {
      base64: questionMediaAttachment.attachment.base64,
      contentType: questionMediaAttachment.attachment.contentType,
      name: questionMediaAttachment.attachment.name,
      checksum: questionMediaAttachment.attachment.checksum,
      rotation: questionMediaAttachment.attachment.rotation
    };
  }
}

export interface FindingAttachmentRequest {
  questionId?: string;
  findingId: string;
  attachment: Attachment;
}

export class FindingAttachment {
  questionId?: string;
  findingId: string;
  attachment: SimpleAttachment;

  constructor(findingAttachment: any) {
    this.questionId = findingAttachment.questionId;
    this.findingId = findingAttachment.findingId;
    this.attachment = {
      base64: findingAttachment.attachment.base64,
      contentType: findingAttachment.attachment.contentType,
      name: findingAttachment.attachment.name,
      checksum: findingAttachment.attachment.checksum,
      rotation: findingAttachment.attachment.rotation
    };
  }
}

export const attachmentCacheName = 'gutwin-attachments';

@Injectable()
export class AttachmentService {
  constructor(private fileService: FileService, private storageService: StorageModuleService) {}

  getSimplifyAttachment(attachment: Attachment): Promise<SimpleAttachment> {
    if (attachment.file) {
      return this.fileService.readFile(attachment.file).then(({ base64, contentType, hash, rotation }) => {
        return {
          name: attachment.file.name,
          base64,
          contentType,
          checksum: hash,
          rotation,
          toRemove: attachment.toRemove
        };
      });
    } else {
      return new Promise((resolve, reject) =>
        resolve({
          name: attachment.name,
          url: attachment.url,
          checksum: attachment.checksum,
          toRemove: attachment.toRemove
        })
      );
    }
  }

  getQuestionsMediaAttachmentsRequestsFromOfflineStore(
    auditedAreaId: string
  ): Promise<Array<QuestionMediaAttachmentRequest>> {
    const getQuestionMediaAttachments = (
      data: Array<QuestionMediaAttachment>
    ): Array<QuestionMediaAttachmentRequest> => {
      return data.map(questionMediaAttachment => {
        return {
          questionId: questionMediaAttachment.questionId,
          questionMediaId: questionMediaAttachment.questionMediaId,
          attachment: new Attachment({
            name: questionMediaAttachment.attachment.name,
            checksum: questionMediaAttachment.attachment.checksum,
            rotation: questionMediaAttachment.attachment.rotation,
            url: questionMediaAttachment.attachment.url,
            file: questionMediaAttachment.attachment.base64
              ? this.fileService.base64ToBlob(
                  questionMediaAttachment.attachment.base64,
                  questionMediaAttachment.attachment.contentType
                )
              : undefined,
            toRemove: questionMediaAttachment.attachment.toRemove,
            offline: true
          })
        };
      });
    };

    return this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsQuestionMediaRequests + auditedAreaId)
      .then((data: Array<QuestionMediaAttachment>) => {
        if (data) {
          return getQuestionMediaAttachments(data);
        }
      });
  }

  getQuestionMediaAttachmentsRequestFromOfflineStore(
    auditedAreaId: string,
    questionId: string
  ): Promise<Array<Attachment>> {
    const getQuestionMediaAttachments = (data: Array<QuestionMediaAttachment>): Array<Attachment> => {
      return data
        .filter(questionMediaAttachment => questionMediaAttachment.questionId === questionId)
        .map(
          questionMediaAttachment =>
            new Attachment({
              name: questionMediaAttachment.attachment.name,
              checksum: questionMediaAttachment.attachment.checksum,
              rotation: questionMediaAttachment.attachment.rotation,
              url: questionMediaAttachment.attachment.url,
              file: questionMediaAttachment.attachment.base64
                ? this.fileService.base64ToBlob(
                    questionMediaAttachment.attachment.base64,
                    questionMediaAttachment.attachment.contentType
                  )
                : undefined,
              toRemove: questionMediaAttachment.attachment.toRemove,
              offline: true
            })
        );
    };

    return this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsQuestionMediaRequests + auditedAreaId)
      .then((data: Array<QuestionMediaAttachment>) => {
        if (data) {
          return getQuestionMediaAttachments(data);
        }
      });
  }

  /**
   * Append attachments to questions media offline store
   *
   * @param auditedAreaId
   * @param questionMediaAttachments
   */
  private appendQuestionMediaAttachmentRequestToOfflineStoreData(
    data: Array<QuestionMediaAttachment>,
    attachment: Attachment,
    questionId: string,
    questionMediaId?: string
  ): Promise<Array<QuestionMediaAttachment>> {
    if (attachment.file) {
      return this.getSimplifyAttachment(attachment).then((simpleAttachment: SimpleAttachment) => {
        const index = _.findIndex(data, storedAttachment => {
          return (
            storedAttachment.questionId === questionId && storedAttachment.attachment.checksum === attachment.checksum
          );
        });
        if (index === -1) {
          const questionMediaAttachment = new QuestionMediaAttachment({
            questionId,
            questionMediaId,
            attachment: simpleAttachment
          });
          data.push(questionMediaAttachment);
        }
        return data;
      });
    } else {
      return new Promise(resolve => resolve(data));
    }
  }

  addQuestionMediaAttachmentRequestToOfflineStore(
    attachment: Attachment,
    auditedAreaId: string,
    questionId: string,
    questionMediaId?: string
  ): void {
    this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsQuestionMediaRequests + auditedAreaId)
      .then((data: Array<QuestionMediaAttachment>) => {
        if (!data) {
          data = new Array<QuestionMediaAttachment>();
        }
        this.appendQuestionMediaAttachmentRequestToOfflineStoreData(data, attachment, questionId, questionMediaId).then(
          updatedData => {
            this.storageService.setOfflineStore(
              StoragesNamesModule.attachmentsQuestionMediaRequests + auditedAreaId,
              updatedData
            );
          }
        );
      });
  }

  addQuestionMediaAttachmentsRequestsToOfflineStore(
    attachments: Array<Attachment>,
    auditedAreaId: string,
    questionId: string,
    questionMediaId?: string
  ): void {
    this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsQuestionMediaRequests + auditedAreaId)
      .then((data: Array<QuestionMediaAttachment>) => {
        if (!data) {
          data = new Array<QuestionMediaAttachment>();
        }
        const promises = new Array<Promise<Array<QuestionMediaAttachment>>>();
        attachments.forEach(attachment => {
          promises.push(
            this.appendQuestionMediaAttachmentRequestToOfflineStoreData(
              data,
              attachment,
              questionId,
              questionMediaId
            ).then(updatedData => {
              data = updatedData;
              return data;
            })
          );
        });
        Promise.all(promises).then(() => {
          this.storageService.setOfflineStore(
            StoragesNamesModule.attachmentsQuestionMediaRequests + auditedAreaId,
            data
          );
        });
      });
  }

  removeQuestionMediaAttachmentRequestFromOfflineStore(
    auditedAreaId: string,
    questionId: string,
    attachmentChecksum: string
  ): void {
    const removeAttachment = (data: Array<QuestionMediaAttachment>): Array<QuestionMediaAttachment> => {
      const index = _.findIndex(data, questionMediaAttachment => {
        return (
          questionMediaAttachment.questionId === questionId &&
          questionMediaAttachment.attachment.checksum === attachmentChecksum
        );
      });
      if (~index) {
        data.splice(index, 1);
      }
      return data;
    };

    this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsQuestionMediaRequests + auditedAreaId)
      .then((data: Array<QuestionMediaAttachment>) => {
        if (!data) {
          data = new Array<QuestionMediaAttachment>();
        }
        data = removeAttachment(data);
        this.storageService.setOfflineStore(StoragesNamesModule.attachmentsQuestionMediaRequests + auditedAreaId, data);
      });
  }

  removeQuestionMediaAttachmentsRequestsFromOfflineStore(auditedAreaId: string, questionId: string): void {
    const removeAttachments = (data: Array<QuestionMediaAttachment>): Array<QuestionMediaAttachment> => {
      data = data.filter(questionMediaAttachment => {
        return questionMediaAttachment.questionId !== questionId;
      });
      return data;
    };

    this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsQuestionMediaRequests + auditedAreaId)
      .then((data: Array<QuestionMediaAttachment>) => {
        if (!data) {
          data = new Array<QuestionMediaAttachment>();
        }
        data = removeAttachments(data);
        this.storageService.setOfflineStore(StoragesNamesModule.attachmentsQuestionMediaRequests + auditedAreaId, data);
      });
  }

  removeQuestionsMediaAttachmentsRequestsFromOfflineStore(auditedAreaId: string): Promise<any> {
    return this.storageService.removeFromOfflineStore(
      StoragesNamesModule.attachmentsQuestionMediaRequests + auditedAreaId
    );
  }

  getFindingsAttachmentsRequestFromOfflineStore(
    auditedAreaId: string,
    questionId?: string
  ): Promise<Array<FindingAttachmentRequest>> {
    const getFindingsAttachments = (data: Array<FindingAttachment>): Array<FindingAttachmentRequest> => {
      return data
        .filter(findingAttachment => !questionId || findingAttachment.questionId === questionId)
        .map(findingAttachment => {
          return {
            questionId: findingAttachment.questionId,
            findingId: findingAttachment.findingId,
            attachment: new Attachment({
              name: findingAttachment.attachment.name,
              checksum: findingAttachment.attachment.checksum,
              rotation: findingAttachment.attachment.rotation,
              url: findingAttachment.attachment.url,
              file: findingAttachment.attachment.base64
                ? this.fileService.base64ToBlob(
                    findingAttachment.attachment.base64,
                    findingAttachment.attachment.contentType
                  )
                : undefined,
              toRemove: findingAttachment.attachment.toRemove,
              offline: true
            })
          };
        });
    };

    return this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsFindingsRequests + auditedAreaId)
      .then((data: Array<FindingAttachment>) => {
        if (data) {
          return getFindingsAttachments(data);
        }
      });
  }

  private appendFindingAttachmentRequestToOfflineStoreData(
    data: Array<FindingAttachment>,
    attachment: Attachment,
    findingId: string,
    questionId?: string
  ): Promise<Array<FindingAttachment>> {
    if (attachment.file) {
      if (attachment.toRemove && attachment.offline) {
        const index = _.findIndex(data, storedAttachment => {
          return (
            storedAttachment.findingId === findingId && storedAttachment.attachment.checksum === attachment.checksum
          );
        });
        if (index === -1) {
          data.splice(index, 1);
        }
      } else if (!attachment.offline) {
        return this.getSimplifyAttachment(attachment).then((simpleAttachment: SimpleAttachment) => {
          const index = _.findIndex(data, storedAttachment => {
            return (
              storedAttachment.findingId === findingId && storedAttachment.attachment.checksum === attachment.checksum
            );
          });
          if (index === -1) {
            const findingAttachment = new FindingAttachment({
              questionId,
              findingId,
              attachment: simpleAttachment
            });
            data.push(findingAttachment);
          }
          return data;
        });
      }
    }
    return new Promise(resolve => resolve(data));
  }

  addFindingAttachmentsRequestsToOfflineStore(
    attachments: Array<Attachment>,
    auditedAreaId: string,
    findingId: string,
    questionId?: string
  ): Promise<Array<Attachment>> {
    return this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsFindingsRequests + auditedAreaId)
      .then((data: Array<FindingAttachment>) => {
        if (!data) {
          data = new Array<FindingAttachment>();
        }
        const promises = new Array<Promise<Array<FindingAttachment>>>();
        attachments.forEach(attachment => {
          promises.push(
            this.appendFindingAttachmentRequestToOfflineStoreData(data, attachment, findingId, questionId).then(
              updatedData => {
                data = updatedData;
                return data;
              }
            )
          );
          attachment.offline = !!attachment.file || !!attachment.offline;
        });
        return Promise.all(promises).then(() => {
          return this.storageService
            .setOfflineStore(StoragesNamesModule.attachmentsFindingsRequests + auditedAreaId, data)
            .then(() => attachments);
        });
      });
  }

  updateFindingAttachmentsRequestsSourceInOfflineStore(
    auditedAreaId: string,
    findingId: string,
    newQuestionId?: string
  ): Promise<any> {
    const updateFindingAttachmentsRequestsSource = (data: Array<FindingAttachment>): Array<FindingAttachment> => {
      data.forEach(findingAttachment => {
        if (findingAttachment.findingId === findingId) {
          findingAttachment.questionId = newQuestionId ? newQuestionId : undefined;
        }
      });
      return data;
    };

    return this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsFindingsRequests + auditedAreaId)
      .then((data: Array<FindingAttachment>) => {
        if (data) {
          data = updateFindingAttachmentsRequestsSource(data);
          return this.storageService.setOfflineStore(
            StoragesNamesModule.attachmentsFindingsRequests + auditedAreaId,
            data
          );
        }
      });
  }

  updateFindingsAttachmentsRequestsInOfflineStore(findingsRequests: FindingsRequests): Promise<FindingsRequests> {
    const updateFindingsAttachmentsRequests = (
      data: Array<FindingAttachment>,
      findings: Array<Finding>
    ): Promise<Array<FindingAttachment>> => {
      const promises = new Array<Promise<any>>();
      if (findings) {
        findings.forEach(finding => {
          this.removeFindingAttachmentsRequestsFromOfflineStoreData(data, finding, true);
          const questionId =
            finding.relationships.findable._type === QUESTION_TYPE ? finding.relationships.findable.id : undefined;
          finding.attachments.forEach(attachment => {
            promises.push(
              this.appendFindingAttachmentRequestToOfflineStoreData(data, attachment, finding.id, questionId).then(
                updatedData => {
                  data = updatedData;
                  return data;
                }
              )
            );
            attachment.offline = !!attachment.file || !!attachment.offline;
          });
        });
      }
      return Promise.all(promises).then(() => data);
    };

    return this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsFindingsRequests + findingsRequests.auditedAreaId)
      .then((data: Array<FindingAttachment>) => {
        if (!data) {
          data = new Array<FindingAttachment>();
        }
        const promises = new Array<Promise<Array<FindingAttachment>>>();
        promises.push(
          updateFindingsAttachmentsRequests(data, findingsRequests.create).then(updatedData => (data = updatedData))
        );
        promises.push(
          updateFindingsAttachmentsRequests(data, findingsRequests.update).then(updatedData => (data = updatedData))
        );
        return Promise.all(promises).then(() => {
          return this.storageService
            .setOfflineStore(StoragesNamesModule.attachmentsFindingsRequests + findingsRequests.auditedAreaId, data)
            .then(() => findingsRequests);
        });
      });
  }

  removeFindingAttachmentRequestFromOfflineStore(
    auditedAreaId: string,
    findingId: string,
    attachmentChecksum: string
  ): void {
    const removeAttachment = (data: Array<FindingAttachment>): Array<FindingAttachment> => {
      const index = _.findIndex(data, findingAttachment => {
        return (
          findingAttachment.findingId === findingId && findingAttachment.attachment.checksum === attachmentChecksum
        );
      });
      if (~index) {
        data.splice(index, 1);
      }
      return data;
    };

    this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsFindingsRequests + auditedAreaId)
      .then((data: Array<FindingAttachment>) => {
        if (!data) {
          data = new Array<FindingAttachment>();
        }
        data = removeAttachment(data);
        this.storageService.setOfflineStore(StoragesNamesModule.attachmentsFindingsRequests + auditedAreaId, data);
      });
  }

  removeFindingAttachmentsRequestsFromOfflineStore(
    auditedAreaId: string,
    finding: Finding,
    force?: boolean
  ): Promise<any> {
    return this.storageService
      .getOfflineStore(StoragesNamesModule.attachmentsFindingsRequests + auditedAreaId)
      .then((data: Array<FindingAttachment>) => {
        if (!data) {
          data = new Array<FindingAttachment>();
        }
        data = this.removeFindingAttachmentsRequestsFromOfflineStoreData(data, finding, force);
        return this.storageService.setOfflineStore(
          StoragesNamesModule.attachmentsFindingsRequests + auditedAreaId,
          data
        );
      });
  }

  removeFindingsAttachmentsRequestsFromOfflineStore(auditedAreaId: string): Promise<void> {
    return this.storageService.removeFromOfflineStore(StoragesNamesModule.attachmentsFindingsRequests + auditedAreaId);
  }

  removeFindingAttachmentsRequestsFromOfflineStoreData(
    data: Array<FindingAttachment>,
    finding: Finding,
    force?: boolean
  ): Array<FindingAttachment> {
    if (force) {
      return data.filter(findingAttachment => findingAttachment.findingId !== finding.id);
    } else {
      const attachments = this.getAttachmentsToRemove(finding.attachments);
      if (attachments) {
        attachments.forEach(attachment => {
          const index = _.findIndex(data, findingAttachment => {
            return (
              findingAttachment.findingId === finding.id &&
              findingAttachment.attachment.checksum === attachment.checksum
            );
          });
          if (~index) {
            data.splice(index, 1);
          }
        });
      }
      return data;
    }
  }

  getAttachmentsToRemove(attachments: Array<Attachment>): Array<Attachment> {
    if (attachments) {
      return attachments.filter(attachment => attachment.offline && attachment.toRemove);
    }
  }

  // Files for attachments
  /**
   * Save responses for attachments files in offline store
   *
   * @param attachments
   */
  async updateAttachmentsFilesInOfflineStore(attachments: Array<Attachment>): Promise<void> {
    const fetchAttachmentToCache = async (cache: Cache, attachment: Attachment): Promise<void> => {
      const url = attachment.url.split('?')[0];
      const cacheResponse = await cache.match(url);
      if (!cacheResponse) {
        const response = await fetch(attachment.url, { method: 'GET', cache: 'no-cache' });
        if (response.ok) {
          return cache.put(url, response.clone());
        }
        throw new Error(`Cannot fetch attachment ${url}`);
      }
    };

    if (typeof caches !== 'undefined' && attachments) {
      const cache = await caches.open(attachmentCacheName);
      for (const attachment of attachments) {
        if (attachment.url) {
          await fetchAttachmentToCache(cache, attachment);
        }
      }
    }
  }

  /**
   * Remove responses for attachments files from offline store
   *
   * @param attachments
   */
  async removeAttachmentsFilesFromOfflineStore(attachments: Array<Attachment>): Promise<void> {
    if (typeof caches !== 'undefined' && attachments) {
      const cache = await caches.open(attachmentCacheName);
      for (const attachment of attachments) {
        if (attachment.url) {
          const url = attachment.url.split('?')[0];
          await cache.delete(url);
        }
      }
    }
  }
}

import moment from 'moment';

import { Employee } from './employee.model';

import { Activity } from '../interfaces/activity.interface';

import { CommentActivityType } from '../types/comment-activity.type';

export class CommentActivity implements Activity {
  readonly _type: CommentActivityType = 'CommentActivity';

  id?: string;
  text: string;
  executionTime: moment.Moment;
  employee: Employee;

  constructor(comment: Partial<CommentActivity>) {
    Object.assign(this, comment);
  }
}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { noop } from 'lodash';
import { from, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { LoginConsultantService } from './login-consultant.service';
import { StorageService, StoragesNames } from './storage.service';

@Injectable()
export class HttpClientLCConsultantInterceptor implements HttpInterceptor {
  constructor(private loginConsultantService: LoginConsultantService, private storageService: StorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  async handle(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = await this.getToken();

    const authRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    const requestToSend = token ? authRequest : request;

    return next
      .handle(requestToSend)
      .pipe(
        filter(event => event instanceof HttpResponse),
        map((event: HttpResponse<any>) => this.checkIfAuthorized(event)),
        catchError(error => this.handleErrors(error))
      )
      .toPromise();
  }

  async getToken(): Promise<string> {
    let token = this.storageService.getLocalStorage(StoragesNames.token);
    if (this.loginConsultantService.shouldRefreshToken()) {
      const refreshToken = this.storageService.getLocalStorage(StoragesNames.refreshToken);
      token = await this.loginConsultantService.refreshToken(refreshToken).catch(noop);
    }
    return token;
  }

  checkIfAuthorized(event: HttpResponse<any>): HttpResponse<any> {
    const is401 = event.status === 401 || event.body?.errors?.find(error => error.extensions?.code === 'UNAUTHORIZED');
    if (is401) {
      throw new HttpErrorResponse({ status: 401, error: event.body.errors });
    }
    return event;
  }

  handleErrors(error: any): Observable<never> {
    if (error.status === 401) {
      this.loginConsultantService.logOut();
    }

    return observableThrowError(error);
  }
}

export enum PermissionsNames {
  manageQuestionnaires = 'manageQuestionnaires',
  manageUsers = 'manageUsers',
  manageFacilities = 'manageFacilities',
  manageAuditTypes = 'manageAuditTypes',
  manageFindingTypes = 'manageFindingTypes',
  manageQuestionRatings = 'manageQuestionRatings',
  manageUserRoles = 'manageUserRoles',
  manageCompany = 'manageCompany',
  companyCreation = 'companyCreation',
  createAudits = 'createAudits',
  accessAllAudits = 'accessAllAudits',
  manageFinishedAudits = 'manageFinishedAudits',
  manageSolvedFindings = 'manageSolvedFindings',
  manageReportTemplates = 'manageReportTemplates',
  manageCauseAndSolution = 'manageCauseAndSolution'
}

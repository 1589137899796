import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { TopBarSidePanel } from '../../interfaces/top-bar-side-panel.interface';

import { SidePanelService } from '../../services/side-panel.service';

@Component({
  selector: 'gw-top-bar-extended',
  templateUrl: './top-bar-extended.component.html',
  styleUrls: ['./top-bar-extended.component.scss'],
  animations: [
    trigger('slide', [
      state(
        'out',
        style({
          width: 0,
          'min-width': 'max-content'
        })
      ),
      state(
        'in',
        style({
          width: '{{ width }}',
          'min-width': '*'
        }),
        { params: { width: '300px' } }
      ),
      transition('in => out', [animate('0.3s ease-in')]),
      transition('out => in', [animate('0.3s ease-in')])
    ])
  ]
})
export class TopBarExtendedComponent implements OnInit, OnDestroy {
  /**
   * Show customer logo insted of gutwin if passed
   */
  @Input() customerLogo = '';
  @ViewChild('topBarLeft', { static: true }) topBarLeft: ElementRef;
  /**
   * Is animation of side panel disabled?
   */
  disabledAnimation = false;
  /**
   * Parameters of side panel
   *
   * @required
   */
  sidePanel: TopBarSidePanel = {
    opened: false,
    width: 0,
    maxWidth: this.sidePanelService.maxWidth,
    animationParams: {
      value: 'in',
      params: { width: '300px' }
    }
  };

  destroy$ = new Subject<void>();

  constructor(private sidePanelService: SidePanelService, private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.observeSidePanel();
    this.setSidePanelMaxWidth();
    this.observeDisabledAnimation();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  observeDisabledAnimation(): void {
    this.sidePanelService.disabledAnimation$.pipe(takeUntil(this.destroy$)).subscribe(disabledAnimation => {
      this.disabledAnimation = disabledAnimation;
    });
  }

  observeSidePanel(): void {
    this.sidePanel.width = this.sidePanelService.width;
    this.sidePanelService.widthObservable.pipe(takeUntil(this.destroy$)).subscribe(width => {
      this.sidePanel.width = width;
      this.setSidePanelAnimationParams();
      this.changeDetector.markForCheck();
    });
    this.sidePanel.opened = this.sidePanelService.opened;
    this.sidePanelService.openedObservable.pipe(takeUntil(this.destroy$)).subscribe(opened => {
      this.sidePanel.opened = opened;
      this.setSidePanelAnimationParams();
      this.changeDetector.markForCheck();
    });
    this.setSidePanelAnimationParams();
    this.changeDetector.markForCheck();
  }

  setSidePanelMaxWidth(): void {
    const topBarLeftRect = this.topBarLeft.nativeElement.getBoundingClientRect();
    this.sidePanel.maxWidth = topBarLeftRect.width;
    this.sidePanelService.maxWidth = topBarLeftRect.width;
  }

  setSidePanelAnimationParams(): void {
    this.sidePanel.animationParams = {
      value: this.sidePanel.opened ? 'in' : 'out',
      params: { width: this.getSidePanelWidth() }
    };
  }

  toggleSidePanel(): void {
    this.sidePanelService.opened = !this.sidePanelService.opened;
  }

  getSidePanelWidth(): string {
    return this.sidePanel.opened && this.sidePanel.width ? `${this.sidePanel.width}px` : '0px';
  }

  @HostListener('window:resize')
  onWindowResize(): void {
    this.setSidePanelMaxWidth();
  }
}

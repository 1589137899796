import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { TreeFilterOption } from '../../models/tree-filter-option.model';
import { TreeNodeZip } from '../../models/tree-node.model';

import { MultiselectTheme } from '../../enums/multiselect-theme.enum';
import { SelectIconTheme } from '../../enums/select-icon-theme.enum';

import { TreeNodesIds } from '../../types/tree-nodes-ids.type';

import { SimpleDropdownDirective } from '../../directives/simple-dropdown.directive';

import { TreeService } from '../../services/tree.service';

import { getSelectedNodeIds, getZipNodes } from '../../utils/tree.util';

@Component({
  selector: 'gw-tree-multiselect-input',
  templateUrl: './tree-multiselect-input.component.html',
  styleUrls: ['./tree-multiselect-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TreeMultiselectInputComponent),
      multi: true
    }
  ],
  exportAs: 'gwTreeMultiselectInput',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeMultiselectInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('selectDropdown', { static: true }) selectDropdown: SimpleDropdownDirective;

  @Input() set data(data: Array<any>) {
    this._data = data;
    this.initConvertedData();
  }
  get data(): Array<any> {
    return this._data;
  }
  _data: Array<any>;
  @Input() display: Array<string>;
  @Input() uniqueKey: string;
  @Input() placeholder: string;
  @Input() tabindex: number;
  @Input() splitter = ' ';
  @Input() small = false;
  @Input() containWidth = true;
  @Input() markSelectedChildren = false;
  @Input() theme?: MultiselectTheme;
  @Input() iconTheme?: SelectIconTheme;

  selected: TreeNodesIds;
  selectedNodes: Array<TreeNodeZip>;
  nodes: Array<TreeFilterOption>;
  hasVisibleNodes: boolean;
  searchQuery: string;
  disabled: boolean;
  searchChanged = new Subject<string>();
  destroy$: Subject<boolean> = new Subject<boolean>();

  readonly MULTISELECT_THEME = MultiselectTheme;
  readonly MULTISELECT_ICON_THEME = SelectIconTheme;

  constructor(private treeService: TreeService, private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.initConvertedData();
    this.observeSearch();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  writeValue(value: TreeNodesIds): void {
    this.initSelectedData(value);
  }

  onChange: (_: TreeNodesIds) => void = () => {};

  registerOnChange(fn: (_: TreeNodesIds) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  initConvertedData(): void {
    this.nodes = this.display && this.uniqueKey ? this.convertData(this.data) : [];
    this.initSelectedData(this.selected);
    this.initSearchQueryData();
    this.changeDetector.markForCheck();
  }

  initSelectedData(selected: TreeNodesIds): void {
    this.selected = selected || {};
    this.nodes = this.checkSelected(this.nodes, this.selected);
    this.selectedNodes = this.getSelectedNodes(this.nodes, this.selected);
    this.changeDetector.markForCheck();
  }

  initSearchQueryData(): void {
    if (this.searchQuery) {
      const { hasFilteredNodes, filteredNodes } = this.filterNodes(this.searchQuery, this.nodes);
      this.nodes = this.markFilteredNodes(this.nodes, filteredNodes);
      this.hasVisibleNodes = hasFilteredNodes;
    } else {
      this.nodes = this.clearNodes(this.nodes);
      this.hasVisibleNodes = !!this.nodes.length;
    }
    this.changeDetector.markForCheck();
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  convertData(data: Array<any>): Array<TreeFilterOption> {
    return data
      ? data.map(item => {
          return new TreeFilterOption({
            id: item[this.uniqueKey],
            name: this.getDisplay(item),
            isSelected: item?.selected ?? false,
            onPathToSelected: false,
            children: this.convertData(item.children)
          });
        })
      : [];
  }

  getSelectedNodes(nodes: Array<TreeFilterOption>, selected: TreeNodesIds): Array<TreeNodeZip> {
    return getZipNodes(nodes, selected);
  }

  checkSelected(nodes: Array<TreeFilterOption>, selected: TreeNodesIds): Array<TreeFilterOption> {
    return this.treeService.markNodes<TreeFilterOption>(nodes, selected, 'isSelected', 'onPathToSelected');
  }

  filterNodes(
    query = '',
    nodes: Array<TreeFilterOption>,
    parentFitsQuery?: boolean,
    currentPath = [],
    filteredNodes: TreeNodesIds = {}
  ): { hasFilteredNodes: boolean; filteredNodes: TreeNodesIds } {
    let hasFilteredNodes = false;
    query = query.toLowerCase();
    if (nodes) {
      nodes.forEach(node => {
        const searchKey = node.name.toLowerCase();
        const fitQuery = !!~searchKey.indexOf(query);
        const childrenResponse = this.filterNodes(
          query,
          node.children,
          fitQuery || parentFitsQuery,
          [...currentPath, node.id],
          filteredNodes
        );
        node.hidden = !parentFitsQuery && !fitQuery && !childrenResponse.hasFilteredNodes;

        if (!node.hidden) {
          hasFilteredNodes = true;
        }
        if (fitQuery) {
          filteredNodes[node.id] = currentPath;
        }
      });
    }
    return { hasFilteredNodes, filteredNodes };
  }

  markFilteredNodes(nodes: Array<TreeFilterOption>, filteredNodes: TreeNodesIds): Array<TreeFilterOption> {
    return this.treeService.markNodes<TreeFilterOption>(nodes, filteredNodes, 'isFiltered', 'onPathToFiltered');
  }

  clearNodes(nodes: Array<TreeFilterOption>): Array<TreeFilterOption> {
    return this.treeService.clearNodes<TreeFilterOption>(nodes, ['isFiltered', 'onPathToFiltered']);
  }

  getDisplay(item: any): string {
    let display = '';
    this.display.forEach(displayKey => {
      const displayValue = displayKey.split('.').reduce((object, key) => (object ? object[key] : undefined), item);
      if (displayValue) {
        display += (display.length ? this.splitter : '') + displayValue;
      }
    });
    return display;
  }

  openDropdown(): void {
    this.selectDropdown.openDropdown();
  }

  focusDropdown(): void {
    this.selectDropdown.focusOnDropdown();
  }

  submitSelected(nodes: Array<TreeFilterOption>): void {
    this.selected = getSelectedNodeIds(nodes, 'isSelected') as TreeNodesIds;
    this.selectedNodes = this.getSelectedNodes(this.nodes, this.selected);
    this.onChange(this.selected);
  }

  updateNodes(nodes: Array<TreeFilterOption>): void {
    this.nodes = nodes;
  }

  observeSearch(): Subscription {
    return this.searchChanged
      .pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(search => {
        this.searchQuery = search;
        this.initSearchQueryData();
      });
  }

  changeSearch(search: string): void {
    this.searchChanged.next(search);
  }
}

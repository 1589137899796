import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcTabIndex'
})
export class CalcTabIndexPipe implements PipeTransform {
  transform(value: number, isRecurring, formIndex, index?: number): number {
    const startFrom = isRecurring ? 9 : 2;
    const numberOfFields = isRecurring ? 11 : 6;

    if (isRecurring) {
      return formIndex * numberOfFields + (startFrom + value);
    } else {
      return index ? formIndex * numberOfFields + (startFrom + index) : null;
    }
  }
}

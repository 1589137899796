import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { NgxFilesizeModule } from 'ngx-filesize';

import { SessionModule } from './modules/session/session.module';

import { ActivityTextComponent } from './components/activity-text/activity-text.component';
import { ActivityComponent } from './components/activity/activity.component';
import { AddAttachmentComponent } from './components/add-attachment/add-attachment.component';
import { AddLinkComponent } from './components/add-link/add-link.component';
import { AdminHeaderComponent } from './components/admin-header/admin-header.component';
import { AttachmentPreviewComponent } from './components/attachment-preview/attachment-preview.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { ButtonDropdownComponent } from './components/button-dropdown/button-dropdown.component';
import { ChangeDetailsMainPartComponent } from './components/change-details-main-part/change-details-main-part.component';
import { CheckBoxComponent } from './components/check-box/check-box.component';
import { ChosenFiltersComponent } from './components/chosen-filters/chosen-filters.component';
import { ClientTopBarComponent } from './components/client-top-bar/client-top-bar.component';
import { ColorPickerComponent } from './components/color-picker/color-picker.component';
import { ContainerBoxComponent } from './components/container-box/container-box.component';
import { CropAvatarModalComponent } from './components/crop-avatar-modal/crop-avatar-modal.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { DetailsPageHeaderComponent } from './components/details-page-header/details-page-header.component';
import { DiffComponent } from './components/diff/diff.component';
import { DisplayDateComponent } from './components/display-date/display-date.component';
import { DuplicationsModalComponent } from './components/duplications-modal/duplications-modal.component';
import { EditAttachmentComponent } from './components/edit-attachment/edit-attachment.component';
import { EditCategoriesComponent } from './components/edit-categories/edit-categories.component';
import { EditIntervalComponent } from './components/edit-interval/edit-interval.component';
import { LawDetailsEditLawNumberComponent } from './components/edit-law-number/edit-law-number.component';
import { EditLegalOriginComponent } from './components/edit-legal-origin/edit-legal-origin.component';
import { EditLegalTypeComponent } from './components/edit-legal-type/edit-legal-type.component';
import { EditLinkComponent } from './components/edit-link/edit-link.component';
import { EditProductsComponent } from './components/edit-products/edit-products.component';
import { EditRemoveItemComponent } from './components/edit-remove-item/edit-remove-item.component';
import { EditSingleDateComponent } from './components/edit-single-date/edit-single-date.component';
import { EditStatusComponent } from './components/edit-status/edit-status.component';
import { EditTitlesComponent } from './components/edit-titles/edit-titles.component';
import { EditValidityComponent } from './components/edit-validity/edit-validity.component';
import { EnclosureContainerComponent } from './components/enclosure-container/enclosure-container.component';
import { ExpandBoxComponent } from './components/expand-box/expand-box.component';
import { FiltersBarComponent } from './components/filters-bar/filters-bar.component';
import { DateRangeFilterComponent } from './components/filters/date-range-filter/date-range-filter.component';
import { MultiselectFilterComponent } from './components/filters/multiselect-filter/multiselect-filter.component';
import { RadioGroupFilterComponent } from './components/filters/radio-group-filter/radio-group-filter.component';
import { TreeMultiselectFilterComponent } from './components/filters/tree-multiselect-filter/tree-multiselect-filter.component';
import { FlatTreeFilterComponent } from './components/flat-tree-filter/flat-tree-filter.component';
import { FlatTreeMultiselectInputComponent } from './components/flat-tree-multiselect-input/flat-tree-multiselect-input.component';
import { FlatTreeMultiselectComponent } from './components/flat-tree-multiselect/flat-tree-multiselect.component';
import { FlatTreeNodeComponent } from './components/flat-tree-node/flat-tree-node.component';
import { FlatTreeSelectComponent } from './components/flat-tree-select/flat-tree-select.component';
import { FlatTreeComponent } from './components/flat-tree/flat-tree.component';
import { FroalaEditorComponent } from './components/froala-editor/froala-editor.component';
import { HamburgerIconComponent } from './components/hamburger-icon/hamburger-icon.component';
import { ImageCropperModalComponent } from './components/image-cropper-modal/image-cropper-modal.component';
import { ImageCropperViewComponent } from './components/image-cropper-view/image-cropper-view.component';
import { InputWithLabelComponent } from './components/input-with-label/input-with-label.component';
import { LawBoxComponent } from './components/law-box/law-box.component';
import { LawDetailsMainPartComponent } from './components/law-details-main-part/law-details-main-part.component';
import { LawDetailsTabContentComponent } from './components/law-details-tab-content/law-details-tab-content.component';
import { LegalQuestionBoxComponent } from './components/legal-question-box/legal-question-box.component';
import { SidebarLegalTypeAndOriginComponent } from './components/legal-type-and-origin/legal-type-and-origin.component';
import { ListBoxParagraphTextComponent } from './components/list-box/list-box-paragraph-text/list-box-paragraph-text.component';
import { ListBoxParagraphComponent } from './components/list-box/list-box-paragraph/list-box-paragraph.component';
import { ListFiltersComponent } from './components/list-filters/list-filters.component';
import { ListHeaderComponent } from './components/list-header/list-header.component';
import { ListComponent } from './components/list/list.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginOldApiComponent } from './components/login-old-api/login-old-api.component';
import { LoginComponent } from './components/login/login.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { ModalDialogComponent } from './components/modal-dialog/modal-dialog.component';
import { ModalLightboxComponent } from './components/modal-lightbox/modal-lightbox.component';
import { ModalComponent } from './components/modal/modal.component';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NotLicensedModuleTooltipComponent } from './components/not-licensed-module-tooltip/not-licensed-module-tooltip.component';
import { OldApiHeaderComponent } from './components/old-api-header/old-api-header.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageStatusComponent } from './components/page-status/page-status.component';
import { PaginationCountComponent } from './components/pagination-count/pagination-count.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PermissionCategoryModalComponent } from './components/permission-category-modal/permission-category-modal.component';
import { PermissionCategoryComponent } from './components/permission-category/permission-category.component';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { PopoverComponent } from './components/popover/popover.component';
import { PreviewNestedElementsComponent } from './components/preview-nested-elements/preview-nested-elements.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { RequirementBoxComponent } from './components/requirement-box/requirement-box.component';
import { RequirementContentTabComponent } from './components/requirement-content-tab/requirement-content-tab.component';
import { RequirementDetailsMainPartComponent } from './components/requirement-details-main-part/requirement-details-main-part.component';
import { RequirementSidebarNumericIdComponent } from './components/requirement-sidebar-numeric-id/requirement-sidebar-numeric-id.component';
import { RequirementSidebarRelatedLawBoxComponent } from './components/requirement-sidebar-related-law-box/requirement-sidebar-related-law-box.component';
import { RequirementSidebarRelatedLawComponent } from './components/requirement-sidebar-related-law/requirement-sidebar-related-law.component';
import { RequirementVersionsPanelComponent } from './components/requirement-versions-panel/requirement-versions-panel.component';
import { RequirementsDetailsVersionBoxComponent } from './components/requirements-details-version-box/requirements-details-version-box.component';
import { RoleFormComponent } from './components/role-form/role-form.component';
import { RoleModalComponent } from './components/role-modal/role-modal.component';
import { RoleUsersComponent } from './components/role-users/role-users.component';
import { RoleComponent } from './components/role/role.component';
import { RolesListComponent } from './components/roles-list/roles-list.component';
import { SearchComponent } from './components/search/search.component';
import { SearchableFlatTreeMultiselectComponent } from './components/searchable-flat-tree-multiselect/searchable-flat-tree-multiselect.component';
import { SelectComponent } from './components/select/select.component';
import { ShowRequirementVersionsButtonComponent } from './components/show-requirement-versions-button/show-requirement-versions-button.component';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { SidebarAttachmentsComponent } from './components/sidebar-attachments/sidebar-attachments.component';
import { SidebarDecisionDatesComponent } from './components/sidebar-decision-dates/sidebar-decision-dates.component';
import { SidebarExtraFieldComponent } from './components/sidebar-extra-field/sidebar-extra-field.component';
import { SidebarLinksComponent } from './components/sidebar-links/sidebar-links.component';
import { SidebarProductComponent } from './components/sidebar-product/sidebar-product.component';
import { SidebarValidityAndTransitionPeriodComponent } from './components/sidebar-validity-and-transition-period/sidebar-validity-and-transition-period.component';
import { SortComponent } from './components/sort/sort.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TopBarExtendedComponent } from './components/top-bar-extended/top-bar-extended.component';
import { TopBarProfileComponent } from './components/top-bar-profile/top-bar-profile.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { TreeControlComponent } from './components/tree-control/tree-control.component';
import { TreeFilterComponent } from './components/tree-filter/tree-filter.component';
import { TreeMultiselectInputComponent } from './components/tree-multiselect-input/tree-multiselect-input.component';
import { TreeMultiselectComponent } from './components/tree-multiselect/tree-multiselect.component';
import { TreeSelectComponent } from './components/tree-select/tree-select.component';
import { TreeComponent } from './components/tree/tree.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UserModalComponent } from './components/user-modal/user-modal.component';
import { UserRolesFormComponent } from './components/user-roles-form/user-roles-form.component';
import { UserRolesModalComponent } from './components/user-roles-modal/user-roles-modal.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { WarningModalComponent } from './components/warning-modal/warning-modal.component';
import { LogoutComponent } from './modules/sso/views/logout/logout.component';
import { TokenComponent } from './modules/sso/views/token/token.component';

import { AutosizeDirective } from './directives/autosize.directive';
import { SimpleDropdownDirective } from './directives/simple-dropdown.directive';
import { SimpleTooltipDirective } from './directives/simple-tooltip.directive';
import { StopPropagationDirective } from './directives/stop-propagation.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { ValidateEmailDirective } from './directives/validate-email.directive';

import { AreIdsIncludedPipe } from './pipes/are-ids-included.pipe';
import { ConvertBytesToMegabytesPipe } from './pipes/convert-bytes-to-megabytes.pipe';
import { ConvertLinksInTextPipe } from './pipes/convert-links-in-text.pipe';
import { ConvertMimeTypeToDisplayPipe } from './pipes/convert-mime-type-to-display.pipe';
import { CountNavigationGroupPaddingPipe } from './pipes/count-navigation-group-padding.pipe';
import { CountryAbbreviationPipe } from './pipes/country-abbreviation.pipe';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { DisableCleanFormButtonPipe } from './pipes/disable-clean-form-button.pipe';
import { DisplayNestedAttributesPipe } from './pipes/display-nested-attributes.pipe';
import { DisplayNestedElementsPathPipe } from './pipes/display-nested-elements-path.pipe';
import { DisplayNestedElementsPipe } from './pipes/display-nested-elements.pipe';
import { DisplayUserNamePipe } from './pipes/display-user-name.pipe';
import { ExternalUrlPipe } from './pipes/external-url.pipe';
import { FilterFlatTreePipe } from './pipes/filter-flat-tree.pipe';
import { FormatDateRangePipe } from './pipes/format-date-range.pipe';
import { GetConnectedElementsNumberPipe } from './pipes/get-connected-elements-number.pipe';
import { GetContainerDetailsPathPipe } from './pipes/get-container-details-path.pipe';
import { GetCountriesAbbreviationsPipe } from './pipes/get-countries-abbreviations.pipe';
import { GetFormControlPipe } from './pipes/get-form-control.pipe';
import { GetRequirementDetailsPathPipe } from './pipes/get-requirement-details-path.pipe';
import { GetSidePanelWidthPipe } from './pipes/get-side-panel-width.pipe';
import { IsAddRoleVisiblePipe } from './pipes/is-add-role-visible.pipe';
import { IsCheckedPipe } from './pipes/is-checked.pipe';
import { IsClientUserTypePipe } from './pipes/is-client-user-type.pipe';
import { IsConsultantUserTypePipe } from './pipes/is-consultant-user-type.pipe';
import { IsDifferentPipe } from './pipes/is-different.pipe';
import { IsEqualPipe } from './pipes/is-equal.pipe';
import { IsFieldInvalidPipe } from './pipes/is-field-invalid.pipe';
import { IsPasswordInvalidPipe } from './pipes/is-password-invalid.pipe';
import { IsPublishedPipe } from './pipes/is-published.pipe';
import { IsTabActivePipe } from './pipes/is-tab-active.pipe';
import { ObjectToArrayPipe } from './pipes/object-to-array.pipe';
import { RemoveFileExtensionPipe } from './pipes/remove-file-extension.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ShowFilterPipe } from './pipes/show-filter.pipe';
import { ShowLawReferencePipe } from './pipes/show-law-reference.pipe';
import { ShowPaginationPipe } from './pipes/show-pagination.pipe';
import { TrackByPropertyPipe } from './pipes/track-by-property.pipe';
import { TranslateMultiplePipe } from './pipes/translate-multiple.pipe';

import { AlertService } from './services/alert.service';
import { BodyFreezerService } from './services/body-freezer.service';
import { DialogService } from './services/dialog.service';
import { DropdownService } from './services/dropdown.service';
import { FileService } from './services/file.service';
import { GridService } from './services/grid.service';
import { HttpClientInterceptor } from './services/http-client-interceptor.service';
import { HttpClientLCInterceptor } from './services/http-client-lc-interceptor.service';
import { HttpCredentialsInterceptor } from './services/http-credentials-interceptor.service';
import { I18nService } from './services/i18n.service';
import { LightboxService } from './services/lightbox.service';
import { LoaderService } from './services/loader.service';
import { LoginOldApiService } from './services/login-old-api.service';
import { LoginService } from './services/login.service';
import { ModalService } from './services/modal.service';
import { OfflineService } from './services/offline.service';
import { OverlayService } from './services/overlay.service';
import { PopoverService } from './services/popover.service';
import { RouterService } from './services/router.service';
import { getErrorHandler } from './services/sentry-error-handler.service';
import { StorageService } from './services/storage.service';
import { TreeService } from './services/tree.service';
import { UserProfileOldApiService } from './services/user-profile-old-api.service';

import { LoginOldApiGuard } from './guards/login-old-api.guard';
import { LoginGuard } from './guards/login.guard';
import { LogoutOldApiGuard } from './guards/logout-old-api.guard';
import { LogoutGuard } from './guards/logout.guard';

export const GutwinSharedModuleDependencides = {
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    ScrollingModule,
    SessionModule,
    RouterModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    NgxFilesizeModule,
    TranslateModule.forChild()
  ],
  declarations: [
    ActivityComponent,
    ActivityTextComponent,
    AddAttachmentComponent,
    AddLinkComponent,
    AdminHeaderComponent,
    AttachmentComponent,
    AttachmentPreviewComponent,
    AvatarComponent,
    ButtonDropdownComponent,
    ChangeDetailsMainPartComponent,
    CheckBoxComponent,
    ChosenFiltersComponent,
    ClientTopBarComponent,
    ColorPickerComponent,
    ContainerBoxComponent,
    CropAvatarModalComponent,
    DatePickerComponent,
    DateRangeFilterComponent,
    DateRangePickerComponent,
    DateTimePickerComponent,
    DetailsPageHeaderComponent,
    DiffComponent,
    DisplayDateComponent,
    DuplicationsModalComponent,
    EditAttachmentComponent,
    EditCategoriesComponent,
    EditIntervalComponent,
    EditLegalOriginComponent,
    EditLegalTypeComponent,
    EditLinkComponent,
    EditProductsComponent,
    EditRemoveItemComponent,
    EditSingleDateComponent,
    EditStatusComponent,
    EditTitlesComponent,
    EditValidityComponent,
    EnclosureContainerComponent,
    ExpandBoxComponent,
    FiltersBarComponent,
    FlatTreeComponent,
    FlatTreeNodeComponent,
    FlatTreeMultiselectInputComponent,
    FlatTreeMultiselectComponent,
    FlatTreeSelectComponent,
    FlatTreeFilterComponent,
    FroalaEditorComponent,
    HamburgerIconComponent,
    ImageCropperModalComponent,
    ImageCropperViewComponent,
    InputWithLabelComponent,
    LawBoxComponent,
    LawDetailsEditLawNumberComponent,
    LawDetailsMainPartComponent,
    LawDetailsTabContentComponent,
    LegalQuestionBoxComponent,
    ListComponent,
    ListBoxParagraphComponent,
    ListBoxParagraphTextComponent,
    ListFiltersComponent,
    ListHeaderComponent,
    LoaderComponent,
    LoginComponent,
    LoginOldApiComponent,
    LogoutComponent,
    ModalComponent,
    ModalContentComponent,
    ModalDialogComponent,
    ModalLightboxComponent,
    MultiselectComponent,
    MultiselectFilterComponent,
    NavigationComponent,
    NotLicensedModuleTooltipComponent,
    OldApiHeaderComponent,
    OverlayComponent,
    PageHeaderComponent,
    PageStatusComponent,
    PaginationComponent,
    PaginationCountComponent,
    PermissionCategoryComponent,
    PermissionCategoryModalComponent,
    PermissionsComponent,
    PopoverComponent,
    PopoverComponent,
    PreviewNestedElementsComponent,
    RadioButtonComponent,
    RadioGroupFilterComponent,
    RequirementBoxComponent,
    RequirementContentTabComponent,
    RequirementDetailsMainPartComponent,
    RequirementsDetailsVersionBoxComponent,
    RequirementSidebarNumericIdComponent,
    RequirementSidebarRelatedLawBoxComponent,
    RequirementSidebarRelatedLawComponent,
    RequirementVersionsPanelComponent,
    RoleComponent,
    RoleFormComponent,
    RoleModalComponent,
    RolesListComponent,
    RoleUsersComponent,
    SearchableFlatTreeMultiselectComponent,
    SearchComponent,
    SelectComponent,
    ShowRequirementVersionsButtonComponent,
    SidebarExtraFieldComponent,
    SidebarProductComponent,
    SidebarAttachmentsComponent,
    SidebarDecisionDatesComponent,
    SidebarExtraFieldComponent,
    SidebarLegalTypeAndOriginComponent,
    SidebarLinksComponent,
    SidebarProductComponent,
    SidebarValidityAndTransitionPeriodComponent,
    SidePanelComponent,
    SortComponent,
    TabsComponent,
    ToggleSwitchComponent,
    TokenComponent,
    TooltipComponent,
    TopBarComponent,
    TopBarExtendedComponent,
    TopBarProfileComponent,
    TreeComponent,
    TreeControlComponent,
    TreeFilterComponent,
    TreeMultiselectComponent,
    TreeMultiselectFilterComponent,
    TreeMultiselectInputComponent,
    TreeSelectComponent,
    UserFormComponent,
    UserModalComponent,
    UserRolesFormComponent,
    UserRolesModalComponent,
    UsersListComponent,
    WarningModalComponent,
    AutosizeDirective,
    SimpleDropdownDirective,
    SimpleTooltipDirective,
    StopPropagationDirective,
    TooltipDirective,
    ValidateEmailDirective,
    AreIdsIncludedPipe,
    ConvertBytesToMegabytesPipe,
    ConvertMimeTypeToDisplayPipe,
    ConvertLinksInTextPipe,
    CountryAbbreviationPipe,
    DateFormatPipe,
    DisableCleanFormButtonPipe,
    DisplayNestedElementsPipe,
    DisplayNestedElementsPathPipe,
    DisplayNestedAttributesPipe,
    DisplayUserNamePipe,
    ExternalUrlPipe,
    FilterFlatTreePipe,
    FormatDateRangePipe,
    GetConnectedElementsNumberPipe,
    GetContainerDetailsPathPipe,
    GetCountriesAbbreviationsPipe,
    GetRequirementDetailsPathPipe,
    GetFormControlPipe,
    GetSidePanelWidthPipe,
    CountNavigationGroupPaddingPipe,
    IsAddRoleVisiblePipe,
    IsCheckedPipe,
    IsClientUserTypePipe,
    IsConsultantUserTypePipe,
    IsDifferentPipe,
    IsEqualPipe,
    IsFieldInvalidPipe,
    IsPasswordInvalidPipe,
    IsPublishedPipe,
    IsTabActivePipe,
    ObjectToArrayPipe,
    RemoveFileExtensionPipe,
    SafeHtmlPipe,
    ShowFilterPipe,
    ShowLawReferencePipe,
    ShowPaginationPipe,
    TrackByPropertyPipe,
    TranslateMultiplePipe
  ],
  providers: [
    AlertService,
    BodyFreezerService,
    DialogService,
    DropdownService,
    FileService,
    GridService,
    HttpClientInterceptor,
    HttpClientLCInterceptor,
    HttpCredentialsInterceptor,
    I18nService,
    LightboxService,
    LoaderService,
    LoginService,
    LoginOldApiService,
    ModalService,
    OfflineService,
    OverlayService,
    PopoverService,
    RouterService,
    StorageService,
    TreeService,
    UserProfileOldApiService,
    LoginGuard,
    LogoutGuard,
    LoginOldApiGuard,
    LogoutOldApiGuard
  ],
  entryComponents: [
    CropAvatarModalComponent,
    DuplicationsModalComponent,
    ImageCropperModalComponent,
    OverlayComponent,
    PageStatusComponent,
    PermissionCategoryModalComponent,
    RoleModalComponent,
    TooltipComponent,
    UserModalComponent,
    UserRolesModalComponent
  ]
};

@NgModule({
  imports: [GutwinSharedModuleDependencides.imports],
  declarations: [GutwinSharedModuleDependencides.declarations],
  exports: [GutwinSharedModuleDependencides.declarations],
  entryComponents: [GutwinSharedModuleDependencides.entryComponents]
})
export class GutwinSharedModule {
  static forRoot(environment: any): ModuleWithProviders<GutwinSharedModule> {
    return {
      ngModule: GutwinSharedModule,
      providers: [
        {
          provide: 'dsn',
          useValue: 'https://b7f6b076f3e44a54b7eb680e6365491d@sentry.io/226835'
        },
        {
          provide: 'prod',
          useValue: environment.production
        },
        { provide: ErrorHandler, useFactory: getErrorHandler, deps: ['dsn', 'prod'] },
        SessionModule,
        {
          provide: 'env',
          useValue: environment
        }
      ]
    };
  }
}

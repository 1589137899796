import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

import { Store } from '@ngxs/store';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-edit-law-number',
  templateUrl: './edit-law-number.component.html',
  styleUrls: ['./edit-law-number.component.scss']
})
export class LawDetailsEditLawNumberComponent<L extends { number: string; version: string }> implements OnInit {
  @Input() law: L;
  @Input() isEditMode: boolean;
  @Input() isUserTypeClient: boolean;
  @Output() submitChange = new EventEmitter();
  form: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      number: [''],
      version: ['']
    });
  }

  resetForm(): void {
    this.form.get('number').setValue(this.law.number || '');
    this.form.get('version').setValue(this.law.version || '');
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  submitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.submitChange.emit(this.form.value);
    }
  }

  increaseOpenedEdits(): void {
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.store.dispatch(new DecreaseOpenedEdits());
  }
}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
// Models
import { Questionnaire } from './../../shared/models/questionnaire.model';
import { Section } from './../../shared/models/section.model';
// Providers
import { SectionService } from '../../shared/services/section.service';
import { PageStatusService } from './../../shared/services/page-status.service';

@Injectable()
export class SectionsResolve implements Resolve<any> {
  errors = {
    connection: '',
    getData: ''
  };

  constructor(
    private sectionService: SectionService,
    private pageStatusService: PageStatusService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) { }

  resolve (route: ActivatedRouteSnapshot): Promise<Array<Section>> {
    const questionnaire = new Questionnaire({
      id: route.params['id'],
    });
    return this.sectionService.getSections(questionnaire)
      .catch(error => {
        switch (error.status) {
          case 404:
            this.fetchMessage404Translation()
              .then((translation: string) => {
                this.pageStatusService.goTo404(translation);
              });
            break;
        }
        this.fetchErrorTranslation()
          .then((translation: any) => {
            this.notificationsService.error(translation.title, translation.text);
          });
        return [];
      });
  }

  fetchMessage404Translation(): Promise<string> {
    return this.translateService.get('GLOBAL.ERROR.NOT_FOUND.QUESTIONNAIRE')
      .toPromise()
      .then((translation: string) => {
        return translation;
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.LOAD_SECTIONS'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ERROR.LOAD_SECTIONS']
        };
      });
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { EmployeeExtended, PermissionsNames } from 'gutwin-shared';

import { PermissionService } from '@gutwin-audit/shared/services/permission.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';

@Injectable()
export class AdminFindingTypesGuard implements CanActivate {
  constructor(private permissionService: PermissionService, private router: Router, private userService: UserService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUser: EmployeeExtended = await this.userService.getCurrentUser().catch(error => undefined);
    if (currentUser && currentUser.hasPermission(PermissionsNames.manageFindingTypes)) {
      return true;
    }

    const isDefaultAdminRouteChanged = this.permissionService.initDefaultAdminRoute(currentUser);

    this.router.navigate([isDefaultAdminRouteChanged ? 'admin' : 'audits']);
    return false;
  }
}

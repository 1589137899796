import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { find, findIndex } from 'lodash';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';

import { EmployeeExtended, FileService, LIMIT } from 'gutwin-shared';

import { Audit, AUDIT_TYPE, ModifiedReport } from '@gutwin-audit/shared/models/audit.model';
import { AuditsAmount } from '@gutwin-audit/shared/models/audits-amount.model';
import { AuditsFilters } from '@gutwin-audit/shared/models/filters-audits.model';
import { Finding } from '@gutwin-audit/shared/models/finding.model';
import { FindingsResponse } from '@gutwin-audit/shared/models/findings-response.model';
import { QuestionMedia } from '@gutwin-audit/shared/models/question-media.model';
import { Section } from '@gutwin-audit/shared/models/section.model';

import { ApiUrlService } from '@gutwin-audit/shared/services/api-url.service';
import { AuditConvertService, AuditResponse } from '@gutwin-audit/shared/services/audit.convert.service';
import { AuditOfflineService, AuditRequest } from '@gutwin-audit/shared/services/audit.offline.service';
import { FindingOfflineService, FindingsRequests } from '@gutwin-audit/shared/services/finding.offline.service';
import { FindingService } from '@gutwin-audit/shared/services/finding.service';
import {
  AuditChunks,
  Progress,
  ProgressGroup,
  ProgressService,
  ProgressStatus,
  ProgressType
} from '@gutwin-audit/shared/services/progress.service';
import { QuestionMediaService } from '@gutwin-audit/shared/services/question-media.service';
import { RateRequest, RatingsService } from '@gutwin-audit/shared/services/ratings.service';
import { SectionService } from '@gutwin-audit/shared/services/section.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';
import { WebSocketService } from '@gutwin-audit/shared/services/web-socket.service';

export interface AuditSyncData {
  audit: Audit;
  auditedAreasData: Array<AuditedAreaSyncData>;
}

export interface AuditedAreaSyncData {
  auditedAreaId: string;
  sections?: Array<Section>;
  questionsMedia?: Array<QuestionMedia>;
  findings?: Array<Finding>;
}

export interface WebSocketAttachmentMessage {
  model_name: 'finding' | 'question_response_media';
  model_id: string;
  status: 'success' | 'error';
  attachment_url: string;
}

@Injectable()
export class AuditService {
  auditsLimit = LIMIT;
  auditsAmount = new AuditsAmount();
  auditsAmountSource = new Subject<any>();
  auditsAmountObservable = this.auditsAmountSource.asObservable();

  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private auditConvertService: AuditConvertService,
    private auditOfflineService: AuditOfflineService,
    private fileService: FileService,
    private findingService: FindingService,
    private findingOfflineService: FindingOfflineService,
    private sectionService: SectionService,
    private questionMediaService: QuestionMediaService,
    private progressService: ProgressService,
    private ratingsService: RatingsService,
    private userService: UserService,
    private webSocketService: WebSocketService
  ) {
    this.progressService.actionObservable.subscribe((groups: Array<ProgressGroup>) => {
      if (groups) {
        const groupsCopy = groups.slice(0);
        for (const group of groupsCopy) {
          if (group.type === AUDIT_TYPE) {
            this.syncAuditOfflineByProgressGroup(group).catch(error => error);
          }
        }
      }
    });
  }

  getAuditsAmount(): AuditsAmount {
    return this.auditsAmount;
  }

  setAuditsAmount(counters: AuditsAmount): void {
    this.auditsAmount = counters;
    this.auditsAmountSource.next(this.auditsAmount);
  }

  prepareFiltersParams(params: HttpParams, filters: AuditsFilters): HttpParams {
    if (filters.dateFrom) {
      params = params.set('start_date', filters.dateFrom || undefined);
    }
    if (filters.dateTo) {
      params = params.set('end_date', filters.dateTo || undefined);
    }
    if (filters.name) {
      params = params.set('name', filters.name || undefined);
    }
    if (filters.location) {
      params = params.set('location', filters.location || undefined);
    }
    if (filters.types && filters.types.length) {
      for (const typeId of filters.types) {
        params = params.append('audit_types_ids[]', typeId);
      }
    }
    if (filters.facilities && filters.facilities.facilities.length) {
      for (const facilityId of filters.facilities.facilities) {
        params = params.append('facilities_ids[]', facilityId);
      }
      params = params.set('include_children_facilities', filters.facilities.subFolders.toString() || 'false');
    }
    if (filters.leadAuditors && filters.leadAuditors.length) {
      for (const auditorId of filters.leadAuditors) {
        params = params.append('lead_auditors_ids[]', auditorId);
      }
    }
    if (filters.coAuditors && filters.coAuditors.length) {
      for (const auditorId of filters.coAuditors) {
        params = params.append('co_auditors_ids[]', auditorId);
      }
    }

    return params;
  }

  private getAudits(
    params: HttpParams,
    page = 0,
    filters?: AuditsFilters,
    limit = this.auditsLimit
  ): Promise<{ audits: Array<Audit> }> {
    if (page < 0) {
      page = 0;
    }
    params = params.set('page', page.toString());
    params = params.set('limit', limit.toString());
    if (filters) {
      params = this.prepareFiltersParams(params, filters);
    }
    return this.http
      .get(this.apiUrlService.auditApi, { params })
      .toPromise()
      .then((data: any) => {
        this.setAuditsAmount(this.auditConvertService.convertAuditsAmountToGet(data.counters));
        return this.auditConvertService.convertAuditsToGet(data);
      })
      .catch(error => {
        if (error.status === 0) {
          return this.auditOfflineService.getAuditsFromOfflineStore(params.get('state'), page, limit).then(data => {
            if (!data) {
              console.error('Error while getting audits list', error);
              throw error;
            }
            this.setAuditsAmount(data.counters);
            return this.auditConvertService.convertAuditsToGet(data);
          });
        }
        console.error('Error while getting audits list', error);
        throw error;
      });
  }

  getAuditsInProgress(page = 0, filters?: AuditsFilters, limit?: number): Promise<{ audits: Array<Audit> }> {
    let params = new HttpParams();
    params = params.set('state', 'in_progress');
    return this.getAudits(params, page, filters, limit);
  }

  getAuditsDrafts(page = 0, filters?: AuditsFilters, limit?: number): Promise<{ audits: Array<Audit> }> {
    let params = new HttpParams();
    params = params.set('state', 'draft');
    return this.getAudits(params, page, filters, limit);
  }

  getAuditsFinished(page = 0, filters?: AuditsFilters, limit?: number): Promise<{ audits: Array<Audit> }> {
    let params = new HttpParams();
    params = params.set('state', 'finished');
    return this.getAudits(params, page, filters, limit);
  }

  getAudit(id: string, saveOffline?: boolean): Promise<Audit> {
    return this.http
      .get(this.apiUrlService.auditApi + '/' + id)
      .toPromise()
      .then((data: any) => {
        if (saveOffline) {
          this.auditOfflineService.updateAuditInOfflineStore(data);
        }
        return this.auditConvertService.convertAuditToGet(data);
      })
      .catch(async error => {
        if (error.status === 0 && !saveOffline) {
          const data = await this.auditOfflineService.getAuditFromOfflineStore(id);
          if (data) {
            return this.auditConvertService.convertAuditToGet(data);
          }
        }
        console.error('Error while getting audit', error);
        throw error;
      });
  }

  createAudit(audit: Audit): Promise<Audit> {
    return this.http
      .post(this.apiUrlService.auditApi, this.auditConvertService.convertAuditToPost(audit))
      .toPromise()
      .then((data: AuditResponse) => {
        return this.auditConvertService.convertAuditToGet(data);
      })
      .catch(error => {
        console.error('Error while creating audit', error);
        throw error;
      });
  }

  createAuditFromTemplate(audit: Audit): Promise<Audit> {
    return this.http
      .post(
        `${this.apiUrlService.auditApi}/create_from_template`,
        this.auditConvertService.convertAuditFromTemplateToPost(audit)
      )
      .toPromise()
      .then((data: AuditResponse) => {
        return this.auditConvertService.convertAuditToGet(data);
      })
      .catch(error => {
        console.error('Error while creating audit from template', error);
        throw error;
      });
  }

  updateAudit(audit: Audit, oldAudit?: Audit): Promise<Audit> {
    return this.http
      .put(this.apiUrlService.auditApi + '/' + audit.id, this.auditConvertService.convertAuditToPost(audit, oldAudit))
      .toPromise()
      .then((data: AuditResponse) => {
        this.auditOfflineService.updateAuditInOfflineStore(data);
        return this.auditConvertService.convertAuditToGet(data);
      })
      .catch(error => {
        console.error('Error while updating audit', error);
        throw error;
      });
  }

  updateAuditState(audit: Audit, state: string): Promise<Audit> {
    const convertAuditToPutState = (audit: Audit, state: string): { id: string; state: string } => {
      return {
        id: audit.id,
        state
      };
    };

    return this.http
      .put(this.apiUrlService.auditApi + '/' + audit.id, convertAuditToPutState(audit, state))
      .toPromise()
      .then((data: any) => {
        this.auditOfflineService.updateAuditInOfflineStore(data);
        return this.auditConvertService.convertAuditToGet(data);
      })
      .catch(error => {
        console.error('Error while updating audit', error);
        throw error;
      });
  }

  removeAudit(audit: Audit): Promise<Audit> {
    return this.http
      .delete(`${this.apiUrlService.auditApi}/${audit.id}`)
      .toPromise()
      .then((data: any) => {
        this.auditOfflineService.removeAuditFromOfflineStore(data);
        return data;
      })
      .catch(error => {
        console.error('Error while removing audit', error);
        throw error;
      });
  }

  archiveAudit(audit: Audit): Promise<Audit> {
    const convertAuditToArchive = (audit: Audit): { id: string; archived: string } => {
      return {
        id: audit.id,
        archived: 'true'
      };
    };

    return this.http
      .put(`${this.apiUrlService.auditApi}/${audit.id}`, convertAuditToArchive(audit))
      .toPromise()
      .then((data: any) => {
        this.auditOfflineService.updateAuditInOfflineStore(data);
        return data;
      })
      .catch(error => {
        console.error('Error while archiving audit', error);
        throw error;
      });
  }

  updateAuditSummary(audit: Audit): Promise<{ data: Audit; offline?: boolean }> {
    return this.http
      .put(this.apiUrlService.auditApi + '/' + audit.id, { summary: audit.summary })
      .toPromise()
      .then((data: any) => {
        this.auditOfflineService.updateAuditInOfflineStore(data);
        this.auditOfflineService.removeAuditRequestFromOfflineStore(audit.id);
        return { data: this.auditConvertService.convertAuditToGet(data) };
      })
      .catch(error => {
        if (error.status === 0) {
          this.auditOfflineService.updateAuditSummaryRequestInOfflineStore(audit.id, audit.summary);
          audit.summaryOffline = audit.summaryOffline || audit.summary;
          return { data: audit, offline: true };
        }
        console.error('Error while updating audit summary', error);
        throw error;
      });
  }

  updateAuditSummaries(auditRequest: AuditRequest): Promise<any> {
    const convertAuditSummariesToPut = (): { audit_id: string; summary: string; audited_areas: Array<any> } => {
      const request = {
        audit_id: auditRequest.summary ? auditRequest.auditId : undefined,
        summary: auditRequest.summary,
        audited_areas: undefined
      };
      if (auditRequest.auditedAreas && auditRequest.auditedAreas.length > 0) {
        request.audited_areas = new Array();
        auditRequest.auditedAreas.forEach(auditedAreaRequest => {
          request.audited_areas.push({
            audited_area_id: auditedAreaRequest.auditedAreaId,
            summary: auditedAreaRequest.summary
          });
        });
      }
      return request;
    };

    return this.http
      .put(this.apiUrlService.auditSummariesApi, convertAuditSummariesToPut())
      .toPromise()
      .then((data: any) => {
        this.auditOfflineService.removeAuditAndAuditedAreasRequestsFromOfflineStore(auditRequest.auditId);
        return data;
      })
      .catch(error => {
        console.error('Error while updating audit summaries', error);
        throw error;
      });
  }

  updateAuditModifiedReport(auditId: string, report: ModifiedReport): Promise<ModifiedReport> {
    const auditModifiedReportRequest = new FormData();

    auditModifiedReportRequest.append('id', auditId);
    if (report.destroy) {
      auditModifiedReportRequest.append('modified_report', 'destroy');
    } else if (report.file) {
      auditModifiedReportRequest.append('modified_report', report.file);
    }

    return this.http
      .put(`${this.apiUrlService.auditApi}/update_custom_report`, auditModifiedReportRequest)
      .toPromise()
      .then((data: ModifiedReport) => {
        this.auditOfflineService.updateAuditFieldInOfflineStore(auditId, { key: 'modified_report', value: data });
        return report.destroy ? undefined : new ModifiedReport(data);
      })
      .catch(error => {
        console.error('Error while updating modified report', error);
        return Promise.reject(error);
      });
  }

  /**
   * Offline
   */

  async hasAuditRequestsOffline(audit: Audit): Promise<boolean> {
    const promises = new Array<Promise<boolean>>();

    promises.push(this.auditOfflineService.getAuditRequestFromOfflineStore(audit.id).then(data => !!data));
    promises.push(this.ratingsService.getAuditRatesRequestsFromOfflineStore(audit).then(data => !!data.length));
    promises.push(
      this.findingOfflineService
        .getAuditFindingsRequestsListFromOfflineStore(audit)
        .then(findingsResponse => !!findingsResponse.findings.length)
    );

    for (const auditedArea of audit.auditedAreas) {
      promises.push(
        this.auditOfflineService.getAuditedAreaRequestFromOfflineStore(audit.id, auditedArea.id).then(data => !!data)
      );
      promises.push(
        this.questionMediaService.getQuestionsMediaRequestsFromOfflineStore(auditedArea.id).then(data => !!data.length)
      );
    }

    return Promise.all(promises).then(values =>
      values.reduce((previous: boolean, current: boolean) => previous || current)
    );
  }

  saveWholeAuditOffline(
    audit: Audit,
    chunks = new AuditChunks(),
    action: 'download' | 'sync' = 'download'
  ): Promise<AuditSyncData> {
    const convertDataToAudit = (values: Array<any>): AuditSyncData => {
      const auditSync = {
        audit: undefined,
        auditedAreasData: new Array<AuditedAreaSyncData>()
      };
      values.forEach(data => {
        if (data.audit) {
          auditSync.audit = data.audit;
        } else {
          const auditedAreaIndex = findIndex(auditSync.auditedAreasData, { auditedAreaId: data.auditedAreaId });
          const auditedAreaSync = ~auditedAreaIndex
            ? auditSync.auditedAreasData[auditedAreaIndex]
            : { auditedAreaId: data.auditedAreaId };
          for (const chunkName of ['findings', 'sections', 'questionsMedia']) {
            if (data[chunkName]) {
              auditedAreaSync[chunkName] = data[chunkName];
            }
          }
          if (auditedAreaIndex === -1) {
            auditSync.auditedAreasData.push(auditedAreaSync);
          }
        }
      });
      return auditSync;
    };

    const promises = new Array<Promise<any>>();
    this.removeAuditFromOfflineProgress(audit);
    this.addAuditToOfflineProgress(audit, chunks, action).then(() => {
      this.progressService.checkProgressStatus();
    });

    if (chunks.audit) {
      promises.push(this.saveAuditOffline(audit.id, action));
    }

    if (chunks.sections) {
      audit.auditedAreas.forEach(auditedArea => {
        if (chunks.sectionsAuditedAreaIds ? ~chunks.sectionsAuditedAreaIds.indexOf(auditedArea.id) : true) {
          promises.push(this.saveAuditedAreaSectionsOffline(audit.id, auditedArea.id));
        }
      });
    }

    if (chunks.findings) {
      audit.auditedAreas.forEach(auditedArea => {
        if (chunks.findingsAuditedAreaIds ? ~chunks.findingsAuditedAreaIds.indexOf(auditedArea.id) : true) {
          promises.push(this.saveAuditedAreaFindingsOffline(audit.id, auditedArea.id));
        }
      });
    }

    if (chunks.questionMedia) {
      audit.auditedAreas.forEach(auditedArea => {
        if (chunks.questionMediaAuditedAreaIds ? ~chunks.questionMediaAuditedAreaIds.indexOf(auditedArea.id) : true) {
          promises.push(this.saveAuditedAreaQuestionMediaOffline(audit.id, auditedArea.id));
        }
      });
    }

    return Promise.all(promises)
      .then((values: Array<any>) => {
        audit.offline = true;
        this.auditOfflineService.appendAuditToAuditsIdsInOfflineStore(audit.id);
        this.progressService.checkProgressStatus();
        return convertDataToAudit(values);
      })
      .catch(error => {
        this.progressService.checkProgressStatus();
        throw error;
      });
  }

  syncWholeAuditOffline(audit: Audit, chunks = new AuditChunks()): Promise<AuditSyncData> {
    const promises = new Array<Promise<any>>();
    this.removeAuditFromOfflineProgress(audit);
    this.addAuditToOfflineProgress(audit, chunks, 'sync').then(() => {
      this.progressService.checkProgressStatus();
    });

    if (chunks.audit) {
      promises.push(this.syncAuditSummary(audit.id));
    }

    if (chunks.sections) {
      audit.auditedAreas.forEach(auditedArea => {
        if (chunks.sectionsAuditedAreaIds ? ~chunks.sectionsAuditedAreaIds.indexOf(auditedArea.id) : true) {
          promises.push(this.syncAuditedAreaRatings(audit.id, auditedArea.id));
        }
      });
    }

    if (chunks.findings || chunks.questionMedia) {
      promises.push(
        this.initWebSocket().then(() => {
          const childPromises = new Array<Promise<any>>();

          if (chunks.findings) {
            audit.auditedAreas.forEach(auditedArea => {
              if (chunks.findingsAuditedAreaIds ? ~chunks.findingsAuditedAreaIds.indexOf(auditedArea.id) : true) {
                childPromises.push(
                  this.syncAuditedAreaFindings(audit.id, auditedArea.id).then(() => {
                    return this.findingOfflineService.removeAuditedAreaFindingsRequestsFromOfflineStore(auditedArea.id);
                  })
                );
              }
            });
          }

          if (chunks.questionMedia) {
            audit.auditedAreas.forEach(auditedArea => {
              if (
                chunks.questionMediaAuditedAreaIds ? ~chunks.questionMediaAuditedAreaIds.indexOf(auditedArea.id) : true
              ) {
                childPromises.push(
                  this.syncAuditedAreaQuestionsMedia(audit.id, auditedArea.id).then(() => {
                    return this.questionMediaService.removeQuestionsMediaRequestsFromOfflineStore(auditedArea.id);
                  })
                );
              }
            });
          }

          return Promise.all(childPromises);
        })
      );
    }

    return Promise.all(promises)
      .then(response => {
        this.webSocketService.removeSubscriptionToWebSocket(this.apiUrlService.webSocketAttachmentsChannel);
        this.progressService.checkProgressStatus();
        return this.saveWholeAuditOffline(audit, undefined, 'sync').then((auditSyncData: AuditSyncData) => {
          this.auditOfflineService.setSyncedAudit(auditSyncData);
          return auditSyncData;
        });
      })
      .catch(error => {
        this.progressService.checkProgressStatus();
        console.error(error);
        throw error;
      });
  }

  syncAuditOfflineByProgressGroup(group: ProgressGroup): Promise<any> {
    const convertProgressGroupToAudit = (): Audit => {
      const audit = {
        id: group.id,
        name: group.name,
        auditedAreas: new Array()
      };
      group.children.forEach(child => {
        if (child.type === ProgressType.auditedArea) {
          const auditedArea = {
            id: child.id,
            facility: { name: child.name }
          };
          audit.auditedAreas.push(auditedArea);
        }
      });
      return new Audit(audit);
    };

    const chunks = new AuditChunks({
      audit: false,
      sections: false,
      findings: false,
      questionMedia: false
    });
    if (find(group.children, { type: ProgressType.audit })) {
      chunks.audit = true;
    }

    group.children.forEach(child => {
      if (child.type === ProgressType.auditedArea) {
        if (child.children) {
          child.children.forEach(grandChild => {
            switch (grandChild.type) {
              case ProgressType.sections:
                chunks.sections = true;
                if (!chunks.sectionsAuditedAreaIds) {
                  chunks.sectionsAuditedAreaIds = new Array<string>();
                }
                chunks.sectionsAuditedAreaIds.push(child.id);
                break;
              case ProgressType.findings:
                chunks.findings = true;
                if (!chunks.findingsAuditedAreaIds) {
                  chunks.findingsAuditedAreaIds = new Array<string>();
                }
                chunks.findingsAuditedAreaIds.push(child.id);
                break;
              case ProgressType.questionMedia:
                chunks.questionMedia = true;
                if (!chunks.questionMediaAuditedAreaIds) {
                  chunks.questionMediaAuditedAreaIds = new Array<string>();
                }
                chunks.questionMediaAuditedAreaIds.push(child.id);
                break;
            }
          });
        }
      }
    });

    const audit = convertProgressGroupToAudit();
    return group.action === 'download'
      ? this.saveWholeAuditOffline(audit, chunks)
      : this.syncWholeAuditOffline(audit, chunks);
  }

  saveAuditOffline(auditId: string, action: 'download' | 'sync' = 'download'): Promise<any> {
    this.progressService.updateAuditProgressGroup(auditId, ProgressStatus.inProgress);
    return this.getAudit(auditId, true)
      .then(audit => {
        this.progressService.updateAuditProgressGroup(auditId, ProgressStatus.success);
        this.progressService.countProgress();
        audit.offline = true;
        return {
          audit
        };
      })
      .catch(error => {
        this.progressService.updateAuditProgressGroup(auditId, ProgressStatus.error);
        this.progressService.countProgress();
        throw error;
      });
  }

  saveAuditedAreaSectionsOffline(auditId: string, auditedAreaId: string): Promise<any> {
    const updateSectionsProgressGroupStatus = (status: ProgressStatus) => {
      this.progressService.updateAuditedAreaChunkProgressGroup(auditId, auditedAreaId, ProgressType.sections, {
        status
      });
    };

    updateSectionsProgressGroupStatus(ProgressStatus.inProgress);
    return this.sectionService
      .getSectionsWithQuestions(auditedAreaId, true)
      .then(sections => {
        updateSectionsProgressGroupStatus(ProgressStatus.success);
        this.progressService.countProgress();
        return {
          auditId,
          auditedAreaId,
          sections
        };
      })
      .catch(error => {
        updateSectionsProgressGroupStatus(ProgressStatus.error);
        this.progressService.countProgress();
        throw error;
      });
  }

  saveAuditedAreaFindingsOffline(auditId: string, auditedAreaId: string): Promise<any> {
    const updateFindingsProgressGroupStatus = (status: ProgressStatus) => {
      this.progressService.updateAuditedAreaChunkProgressGroup(auditId, auditedAreaId, ProgressType.findings, {
        status
      });
    };

    updateFindingsProgressGroupStatus(ProgressStatus.inProgress);
    return this.findingService
      .getAuditedAreaFindings(auditedAreaId, 0, undefined, 100, true)
      .then((findingsResponse: FindingsResponse) => {
        updateFindingsProgressGroupStatus(ProgressStatus.success);
        this.progressService.countProgress();
        return {
          auditId,
          auditedAreaId,
          findings: findingsResponse.findings
        };
      })
      .catch(error => {
        updateFindingsProgressGroupStatus(ProgressStatus.error);
        this.progressService.countProgress();
        throw error;
      });
  }

  saveAuditedAreaQuestionMediaOffline(auditId: string, auditedAreaId: string): Promise<any> {
    const updateQuestionMediaProgressGroupStatus = (status: ProgressStatus) => {
      this.progressService.updateAuditedAreaChunkProgressGroup(auditId, auditedAreaId, ProgressType.questionMedia, {
        status
      });
    };

    updateQuestionMediaProgressGroupStatus(ProgressStatus.inProgress);
    return this.questionMediaService
      .fetchAuditedAreaMedia(auditedAreaId)
      .then(questionsMedia => {
        updateQuestionMediaProgressGroupStatus(ProgressStatus.success);
        this.progressService.countProgress();
        return {
          auditId,
          auditedAreaId,
          questionsMedia
        };
      })
      .catch(error => {
        updateQuestionMediaProgressGroupStatus(ProgressStatus.error);
        this.progressService.countProgress();
        throw error;
      });
  }

  syncAuditSummary(auditId: string): Promise<any> {
    this.progressService.updateAuditProgressGroup(auditId, ProgressStatus.inProgress);
    return this.auditOfflineService.getAuditRequestFromOfflineStore(auditId).then((auditRequest: AuditRequest) => {
      if (auditRequest) {
        return this.updateAuditSummaries(auditRequest)
          .then(() => {
            this.progressService.updateAuditProgressGroup(auditId, ProgressStatus.success);
            this.progressService.countProgress();
            return;
          })
          .catch(error => {
            this.progressService.updateAuditProgressGroup(auditId, ProgressStatus.error);
            this.progressService.countProgress();
            throw error;
          });
      }
      return;
    });
  }

  syncAuditedAreaRatings(auditId: string, auditedAreaId: string): Promise<any> {
    const updateSectionsProgressGroupStatus = (status: ProgressStatus) => {
      this.progressService.updateAuditedAreaChunkProgressGroup(auditId, auditedAreaId, ProgressType.sections, {
        status
      });
    };

    updateSectionsProgressGroupStatus(ProgressStatus.inProgress);
    return this.ratingsService
      .getAuditedAreaRatesRequestsFromOfflineStore(auditedAreaId)
      .then((ratings: Array<RateRequest>) => {
        return this.ratingsService
          .rateQuestions(auditedAreaId, ratings)
          .then(() => {
            updateSectionsProgressGroupStatus(ProgressStatus.success);
            this.progressService.countProgress();
          })
          .catch(error => {
            updateSectionsProgressGroupStatus(ProgressStatus.error);
            this.progressService.countProgress();
            throw error;
          });
      })
      .catch(error => {
        updateSectionsProgressGroupStatus(ProgressStatus.error);
        this.progressService.countProgress();
        throw error;
      });
  }

  syncAuditedAreaFindings(auditId: string, auditedAreaId: string): Promise<void> {
    const updateFindingsProgressGroupStatus = (status: ProgressStatus, progress?: Progress) => {
      this.progressService.updateAuditedAreaChunkProgressGroup(auditId, auditedAreaId, ProgressType.findings, {
        status,
        progress
      });
      this.progressService.countProgress();
    };

    const countTotalProgress = (findingsRequests: FindingsRequests) => {
      let total = 0;
      const arrays = [findingsRequests.create, findingsRequests.update];
      arrays.forEach(array => {
        if (array) {
          array.forEach(finding => {
            if (finding.attachments) {
              total += finding.attachments.length;
            }
          });
        }
      });
      return total;
    };

    const subscribeWebSocket = (
      progress: Progress,
      hasError: { value: boolean },
      callback: { resolve: () => void; reject: () => void }
    ): Subscription => {
      return this.webSocketService
        .subscribeWebSocket(this.apiUrlService.webSocketAttachmentsChannel)
        .subscribe((message: WebSocketAttachmentMessage) => {
          if (message.model_name === 'finding') {
            let status: ProgressStatus;
            if (message.status === 'error') {
              progress.done++;
              hasError.value = true;
              status = ProgressStatus.error;
            } else {
              progress.done++;
              status = hasError.value
                ? ProgressStatus.error
                : progress.isComplete()
                ? ProgressStatus.success
                : ProgressStatus.inProgress;
            }
            updateFindingsProgressGroupStatus(status, progress);
            if (progress.isComplete()) {
              return hasError.value ? callback.reject() : callback.resolve();
            }
          }
        });
    };

    updateFindingsProgressGroupStatus(ProgressStatus.inProgress);
    return this.findingOfflineService
      .getAuditedAreaFindingsRequestsFromOfflineStore(auditedAreaId)
      .then((findingsRequests: FindingsRequests) => {
        if (findingsRequests) {
          return new Promise<void>((resolve, reject) => {
            const progress = new Progress({
              done: 0,
              total: countTotalProgress(findingsRequests)
            });
            const hasError = { value: false };

            const subscription = subscribeWebSocket(progress, hasError, { resolve, reject });

            this.findingService
              .updateFindings(findingsRequests)
              .then(() => {
                const status = hasError.value
                  ? ProgressStatus.error
                  : progress.isComplete()
                  ? ProgressStatus.success
                  : ProgressStatus.inProgress;
                updateFindingsProgressGroupStatus(status);
                if (progress.isComplete()) {
                  return hasError.value ? reject() : resolve();
                }
              })
              .catch(error => {
                hasError.value = true;
                updateFindingsProgressGroupStatus(ProgressStatus.error);
                if (progress.isComplete()) {
                  return hasError.value ? reject() : resolve();
                }
              });
          });
        }
        updateFindingsProgressGroupStatus(ProgressStatus.success);
        this.progressService.countProgress();
      })
      .catch(error => {
        updateFindingsProgressGroupStatus(ProgressStatus.error);
        this.progressService.countProgress();
        throw error;
      });
  }

  syncAuditedAreaQuestionsMedia(auditId: string, auditedAreaId: string): Promise<void> {
    const updateQuestionMediaProgressGroupStatus = (status: ProgressStatus, progress?: Progress) => {
      this.progressService.updateAuditedAreaChunkProgressGroup(auditId, auditedAreaId, ProgressType.questionMedia, {
        status,
        progress
      });
      this.progressService.countProgress();
    };

    const countTotalProgress = (questionsMedia: Array<QuestionMedia>) => {
      let total = 0;
      if (questionsMedia) {
        questionsMedia.forEach(questionMedia => {
          if (questionMedia.attachments) {
            total += questionMedia.attachments.length;
          }
        });
      }
      return total;
    };

    const subscribeWebSocket = (
      progress: Progress,
      hasError: { value: boolean },
      callback: { resolve: () => void; reject: () => void }
    ): Subscription => {
      return this.webSocketService
        .subscribeWebSocket(this.apiUrlService.webSocketAttachmentsChannel)
        .subscribe((message: WebSocketAttachmentMessage) => {
          if (message.model_name === 'question_response_media') {
            let status: ProgressStatus;
            if (message.status === 'error') {
              progress.done++;
              hasError.value = true;
              status = ProgressStatus.error;
            } else {
              progress.done++;
              status = hasError.value
                ? ProgressStatus.error
                : progress.isComplete()
                ? ProgressStatus.success
                : ProgressStatus.inProgress;
            }
            updateQuestionMediaProgressGroupStatus(status, progress);
            if (progress.isComplete()) {
              return hasError.value ? callback.reject() : callback.resolve();
            }
          }
        });
    };

    updateQuestionMediaProgressGroupStatus(ProgressStatus.inProgress);
    return this.questionMediaService
      .getQuestionsMediaRequestsFromOfflineStore(auditedAreaId)
      .then((questionsMedia: Array<QuestionMedia>) => {
        return new Promise<void>((resolve, reject) => {
          const progress = new Progress({
            done: 0,
            total: countTotalProgress(questionsMedia)
          });
          const hasError = { value: false };

          const subscription = subscribeWebSocket(progress, hasError, { resolve, reject });

          this.questionMediaService
            .updateQuestionsMedia(auditedAreaId, questionsMedia)
            .then(() => {
              const status = hasError.value
                ? ProgressStatus.error
                : progress.isComplete()
                ? ProgressStatus.success
                : ProgressStatus.inProgress;
              updateQuestionMediaProgressGroupStatus(status);
              if (progress.isComplete()) {
                return hasError.value ? reject() : resolve();
              }
            })
            .catch(error => {
              hasError.value = true;
              updateQuestionMediaProgressGroupStatus(ProgressStatus.error);
              if (progress.isComplete()) {
                return hasError.value ? reject() : resolve();
              }
            });
        });
      })
      .catch(error => {
        updateQuestionMediaProgressGroupStatus(ProgressStatus.error);
        this.progressService.countProgress();
        throw error;
      });
  }

  removeAuditOffline(audit: Audit): Audit {
    this.auditOfflineService.removeAuditFromOfflineStore(audit.id);
    this.sectionService.removeSectionsFromOfflineStore(audit);
    this.findingOfflineService.removeAuditFindingsFromOfflineStore(audit);
    this.questionMediaService.removeAuditQuestionsMediaFromOfflineStore(audit);

    this.auditOfflineService.removeAuditAndAuditedAreasRequestsFromOfflineStore(audit.id);
    this.ratingsService.removeAuditRatesRequestFromOfflineStore(audit);
    this.findingOfflineService.removeAuditFindingsRequestsFromOfflineStore(audit);
    this.questionMediaService.removeAuditQuestionsMediaRequestsFromOfflineStore(audit);

    audit.offline = false;
    return audit;
  }

  addAuditToOfflineProgress(audit: Audit, chunks: AuditChunks, action: 'download' | 'sync'): Promise<any> {
    return this.progressService.convertAuditToProgressGroup(audit, chunks, action).then(auditProgress => {
      this.progressService.updateProgressGroup(auditProgress);
      this.progressService.countProgress();
    });
  }

  removeAuditFromOfflineProgress(audit: Audit): void {
    this.progressService.removeProgressGroup(audit.id);
    this.progressService.countProgress();
  }

  initWebSocket(): Promise<any> {
    const promises = new Array<Promise<any>>();
    let currentUser: EmployeeExtended;
    let webSocketToken: string;

    promises.push(
      this.userService.getCurrentUser().then((user: EmployeeExtended) => {
        currentUser = user;
      })
    );

    promises.push(
      this.webSocketService.getSocketToken().then((token: string) => {
        webSocketToken = token;
      })
    );

    return Promise.all(promises).then(() => {
      this.webSocketService.createConsumer(currentUser, webSocketToken);
    });
  }

  exportAudits(status: 'in_progress' | 'finished', filters?: AuditsFilters): Promise<Blob> {
    let params = new HttpParams();

    params = params.set('status', status);

    if (filters) {
      params = this.prepareFiltersParams(params, filters);
    }

    const options = {
      params,
      responseType: 'blob' as 'blob'
    };

    return this.http
      .get(`${this.apiUrlService.auditApi}/export`, options)
      .toPromise()
      .then(async (data: Blob) => {
        const todayDate = moment().format('DD.MM.YYYY');
        await this.fileService.downloadFile(data, `audits-${todayDate}.xlsx`);
        return data;
      })
      .catch(error => {
        throw error;
      });
  }
}

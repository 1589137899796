import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { noop } from 'rxjs';
// Gutwin Shared Library
import { DialogService, ModalService } from 'gutwin-shared';
//Models
import { Report } from "./../../../shared/models/report.model";
// Services
import { ReportsService } from '@gutwin-audit/shared/services/reports.service';
import { NotificationsService } from 'angular2-notifications';
//Compoponents
import { AddReportComponent } from "./add-report/add-report.component";

@Component({
  selector: 'gw-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  @ViewChild('addReport') addReport: AddReportComponent;
  reports: Array<Report>;
  
  translation = {
    notify: {
      error: {
        connectionTitle: '',
        removeReport: ''
      },
      success: {
        removeReportTitle: '',
        removeReportText: ''
      }
    },
    removeDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    setAsDefault: {
      successHeader: '',
      successText: '',
      errorText: '',
      errorTitle: ''
    }
  };

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
    private reportsService: ReportsService,
  ) { }

  ngOnInit() {
    this.reports = this.route.snapshot.data['reports'];
    this.fetchTranslation();
  }

  fetchReports(): Promise<Array<Report>> {
    return this.reportsService.getReports()
      .then((reports: Array<Report>) => {
        return this.reports = reports;
      })
  }

  saveReport() {
    this.addReport.submitAddReportForm()
      .then(addReportResponse => {
        this.fetchReports()
        this.modalService.close('reportModal');
      })
      .catch(() => {})
  }

  removeReport(report: Report): void {
    this.reportsService.removeReport(report)
      .then(() => {
        this.fetchReports();
        this.notificationsService.success(this.translation.notify.success.removeReportTitle, this.translation.notify.success.removeReportText);
      })
  }

  showUpdateReportModal(report: Report): void {
    this.modalService.open('reportModal', report);
  }

  showRemoveModal(report: Report): void {
    this.dialogService.confirm(
      this.translation.removeDialog.header,
      this.translation.removeDialog.content,
      this.translation.removeDialog.cancel,
      this.translation.removeDialog.confirm
    )
      .then(() => {
        this.removeReport(report);
      })
      .catch(noop);
  }

  setReportAsDefault(report: Report): void {
    const reportToUpdate = report;
    reportToUpdate.default = true;
    this.reportsService.setReportAsDefault(reportToUpdate)
      .then(() => {
        this.fetchReports()
        this.notificationsService.success(this.translation.setAsDefault.successHeader, this.translation.setAsDefault.successText);
      })
      .catch(() => {
        this.notificationsService.error(this.translation.setAsDefault.errorTitle, this.translation.setAsDefault.errorText)
      })
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ACTION.CANCEL',
      'GLOBAL.ACTION.REMOVE',
      'ADMIN_SETTINGS.REPORTS.ERROR.REMOVE_REPORT',
      'ADMIN_SETTINGS.REPORTS.SUCCESS.REMOVE_REPORT_TITLE',
      'ADMIN_SETTINGS.REPORTS.SUCCESS.REMOVE_REPORT_TEXT',
      'ADMIN_SETTINGS.REPORTS.REMOVE_MODAL.HEADER',
      'ADMIN_SETTINGS.REPORTS.REMOVE_MODAL.CONTENT',
      'ADMIN_SETTINGS.REPORTS.SET_AS_DEFAULT.ERROR_TEXT',
      'ADMIN_SETTINGS.REPORTS.SET_AS_DEFAULT.SUCCESS_TEXT',
      'ADMIN_SETTINGS.REPORTS.SET_AS_DEFAULT.SUCCESS_HEADER'
    ]).toPromise();
    this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.removeReport = translation['ADMIN_SETTINGS.REPORTS.ERROR.REMOVE_REPORT'];
    this.translation.notify.success.removeReportTitle = translation['ADMIN_SETTINGS.REPORTS.SUCCESS.REMOVE_REPORT_TITLE'];
    this.translation.notify.success.removeReportText = translation['ADMIN_SETTINGS.REPORTS.SUCCESS.REMOVE_REPORT_TEXT'];
    this.translation.removeDialog.header = translation['ADMIN_SETTINGS.REPORTS.REMOVE_MODAL.HEADER'];
    this.translation.removeDialog.content = translation['ADMIN_SETTINGS.REPORTS.REMOVE_MODAL.CONTENT'];
    this.translation.removeDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
    this.translation.setAsDefault.successHeader = translation['ADMIN_SETTINGS.REPORTS.SET_AS_DEFAULT.SUCCESS_HEADER'];
    this.translation.setAsDefault.successText = translation['ADMIN_SETTINGS.REPORTS.SET_AS_DEFAULT.SUCCESS_TEXT'];
    this.translation.setAsDefault.errorText = translation['ADMIN_SETTINGS.REPORTS.SET_AS_DEFAULT.ERROR_TEXT'];
    this.translation.setAsDefault.errorTitle = translation['GLOBAL.ERROR.CONNECTION'];
  }

}
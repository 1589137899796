import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Requirement } from '../../models/requirement.model';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-requirements-details-version-box',
  templateUrl: './requirements-details-version-box.component.html',
  styleUrls: ['./requirements-details-version-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequirementsDetailsVersionBoxComponent {
  @Input() active: boolean;
  @Input() userType: UserType;
  @Input() removeLabel: string;
  @Input() requirement: Requirement;
  @Output() removeRequirementVersion = new EventEmitter<Requirement>();

  onRemoveRequirementVersion(): void {
    this.removeRequirementVersion.emit(this.requirement);
  }
}

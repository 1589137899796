
<gw-lightbox id="lightboxModal" #lightboxModal="gwLightbox"></gw-lightbox>
<gw-modal id="cropLogoModal" #cropLogoModal="gwModal">
  <ng-container modal-header="modal-header">{{ 'USER_PROFILE.MODAL.HEADER.CROP_AVATAR' | translate }}</ng-container>
  <ng-container modal-content="modal-content">
    <gw-image-cropper-view #imageCropper="gwImageCropperView" [base64]="cropLogoModal.data" [displayOptions]="{ rectangled: true }"></gw-image-cropper-view>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="cropLogoModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="acceptImage(imageCropper.preparePicture())">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="row company">
  <div class="columns small-12 company-header__title-container">
    <h1 class="company-header__title">{{ 'ADMIN_SETTINGS.COMPANY_SETTINGS.HEADER' | translate }}</h1>
  </div>
  <div class="columns small-12">
    <form class="company__form" *ngIf="companyForm" [formGroup]="companyForm" novalidate="novalidate" autocomplete="off">
      <div class="form__fieldset company__fieldset">
        <div class="company__fields">
          <div class="form__field company__field">
            <label class="label">{{ 'ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.LABEL.LOGO' | translate }}</label>
            <div class="company__logo-container">
              <div class="company__logo--blankslate" *ngIf="!uploadedImage?.preview &amp;&amp; !company.logo">{{ 'ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.PLACEHOLDER.LOGO' | translate }}</div>
              <gw-attachment class="attachment-container company__logo" *ngIf="!uploadedImage?.preview &amp;&amp; company.logo" [attachment]="company.logo" [imageSize]="'contain'" (removed)="removeCompanyLogo()"></gw-attachment>
              <gw-attachment class="attachment-container company__logo--preview" *ngIf="uploadedImage?.preview" [attachment]="{ url: uploadedImage?.preview, isImage: true }" [imageSize]="'contain'" [viewOnly]="true"></gw-attachment>
              <label class="button--secondary" for="upload" tabindex="1" [class.button--disabled]="saving" (keydown)="keyDownFileControl($event, fileInput)">{{ 'ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.ACTION.CHANGE_LOGO' | translate }}<i class="button__icon--after button__icon--spinner" *ngIf="saving">
                  <div class="spinner"></div></i>
                <input class="hide" #fileInput="" type="file" id="upload" placeholder="Upload logo" accept=".jpg, .jpeg, .png, .gif" [disabled]="saving" (change)="attachFiles($event)"/>
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
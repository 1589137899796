import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

// Gutwin Shared Library
import { DialogService, HelperUtil, ModalService, OfflineService, SimpleDropdownDirective } from 'gutwin-shared';

import { RatingScale } from '@gutwin-audit/shared/models/rating-scale.model';
// Models
import { Audit } from '../../shared/models/audit.model';
import { AuditedArea } from '../../shared/models/audited-area.model';
import { FindingType } from '../../shared/models/finding-type.model';
import { Finding } from '../../shared/models/finding.model';
import { FindingsResponse } from '../../shared/models/findings-response.model';
import { Question, QUESTION_TYPE } from '../../shared/models/question.model';
import { Rating } from '../../shared/models/rating.model';
import { Section } from '../../shared/models/section.model';

// Components
import { AddFindingComponent } from '../../shared/components/add-finding/add-finding.component';
import { AddQuestionComponent } from '../../shared/components/add-question/add-question.component';
import { AddSectionComponent } from '../../shared/components/add-section/add-section.component';
import { ChooseAuditedAreaComponent } from './choose-audited-area/choose-audited-area.component';
import { FindingsBrowserComponent } from './findings-browser/findings-browser.component';

import { RatingScalesService } from '@gutwin-audit/shared/services/raiting-scales.service';
import { AuditId, AuditOfflineService } from '../../shared/services/audit.offline.service';
// Services
import { AuditService, AuditSyncData } from '../../shared/services/audit.service';
import { FindingOfflineService } from '../../shared/services/finding.offline.service';
import { FindingService } from '../../shared/services/finding.service';
import { PermissionService } from '../../shared/services/permission.service';
import { QuestionMediaService } from '../../shared/services/question-media.service';
import { QuestionService } from '../../shared/services/question.service';
import { RatingsService } from '../../shared/services/ratings.service';
import { SectionService } from '../../shared/services/section.service';
import { StorageModuleService, StoragesNamesModule } from '../../shared/services/storage-module.service';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'gw-conduct-audit',
  templateUrl: './conduct-audit.component.html',
  styleUrls: ['./conduct-audit.component.scss']
})
export class ConductAuditComponent implements OnInit, OnDestroy {
  @ViewChild('addFinding') addFinding: AddFindingComponent;
  @ViewChild('addSection') addSection: AddSectionComponent;
  @ViewChild('addQuestion') addQuestion: AddQuestionComponent;
  @ViewChild('dropdownAuditedAreas') dropdownAuditedAreas: SimpleDropdownDirective;
  @ViewChild('chooseAuditedArea') chooseAuditedArea: ChooseAuditedAreaComponent;
  @ViewChild('findingsBrowser') findingsBrowser: FindingsBrowserComponent;
  audit: Audit;
  auditedArea: AuditedArea;
  sections: Array<Section>;
  customSections: Array<Section>;
  questions: Array<Question>;
  findingTypes: Array<FindingType>;
  ratings: Array<Rating>;
  auditableType: string;
  totalQuestions: number;
  totalRatedQuestions: number;
  openedAuditedAreas = false;
  loadingPage = false;
  openedFindingsSection = false;
  savingFinding: boolean;
  savingSection: boolean;
  savingQuestion: boolean;
  savingAuditOffline: boolean;
  syncingAuditOffline: boolean;
  isMobileOrTablet: boolean;
  paramsSubscription: Subscription;
  findingsSection = 'findings';
  translateSubscription: Subscription;
  auditsIdsOfflineSubscription: Subscription;
  syncedAuditSubscription: Subscription;
  userSubscription: Subscription;
  translation = {
    notify: {
      error: {
        connectionTitle: '',
        connectionOfflineTitle: '',
        permissions: '',
        loadSectionsWithQuestions: '',
        loadFindings: '',
        loadFindingTypes: '',
        removeFindingText: '',
        removeQuestionText: '',
        removeSectionText: '',
        removeRatingText: '',
        removeRatingPermissionText: '',
        updateRatingText: '',
        updateRatingPermissionText: '',
        makeOfflineTitle: '',
        makeOfflineText: '',
        syncOfflineTitle: '',
        syncOfflineText: ''
      },
      success: {
        removeFindingTitle: '',
        removeFindingText: '',
        removeQuestionTitle: '',
        removeQuestionText: '',
        removeSectionTitle: '',
        removeSectionText: '',
        makeOfflineTitle: '',
        makeOfflineText: '',
        syncOfflineTitle: '',
        syncOfflineText: ''
      }
    },
    removeFindingDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    removeQuestionDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    removeSectionDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    }
  };

  constructor(
    private route: ActivatedRoute,
    private auditService: AuditService,
    private auditOfflineService: AuditOfflineService,
    private dialogService: DialogService,
    private findingService: FindingService,
    private findingOfflineService: FindingOfflineService,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private permissionService: PermissionService,
    private ratingsService: RatingsService,
    private questionService: QuestionService,
    private questionMediaService: QuestionMediaService,
    private sectionService: SectionService,
    private storageService: StorageModuleService,
    private translateService: TranslateService,
    private userService: UserService,
    private ratingScalesService: RatingScalesService,
    public offlineService: OfflineService
  ) {}

  ngOnInit(): void {
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.init();
    });
    this.syncedAuditSubscription = this.auditOfflineService.syncedAuditObservable.subscribe(
      (auditSyncData: AuditSyncData) => {
        if (this.audit && this.audit.id === auditSyncData.audit.id) {
          this.initAuditData(auditSyncData);
        }
      }
    );
    this.userSubscription = this.userService.storedUserObservable.subscribe(() => {
      this.markAuditDisabled();
    });
    this.fetchTranslations();
    this.isMobileOrTablet = HelperUtil.isMobileOrTablet();
  }

  ngOnDestroy(): void {
    this.paramsSubscription.unsubscribe();
    this.auditsIdsOfflineSubscription.unsubscribe();
    this.syncedAuditSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  init(): void {
    this.initAuditData();
    this.fetchFindingTypes();
    this.openedAuditedAreas = false;
    this.loadingPage = false;
  }

  fetchRatings(ratingScaleId: string): void {
    this.ratingScalesService.getRatingScale(ratingScaleId).then((ratingScale: RatingScale) => {
      this.ratings = ratingScale.ratings;
    });
  }

  initAuditData(auditSyncData?: AuditSyncData): void {
    this.audit = auditSyncData ? auditSyncData.audit : this.route.snapshot.data['audit'];
    this.auditedArea = this.getAuditedArea(this.route.snapshot.params.auditedAreaId);
    this.fetchRatings(this.audit.ratingScale.id);
    this.checkAuditOffline();
    this.markAuditDisabled();
    if (auditSyncData) {
      const currentAuditedAreaSyncData = _.find(auditSyncData.auditedAreasData, { auditedAreaId: this.auditedArea.id });
      this.sections =
        currentAuditedAreaSyncData && currentAuditedAreaSyncData.sections
          ? currentAuditedAreaSyncData.sections
          : this.route.snapshot.data['sections'];
    } else {
      this.sections = this.route.snapshot.data['sections'];
    }
    this.fetchCustomSections();
    this.fetchQuestions();
    if (this.auditedArea && !this.audit.disabled) {
      this.mergeOfflineRatings(this.auditedArea.id);
      this.mergeOfflineQuestionsMedia(this.auditedArea.id);
      this.mergeOfflineFindings(this.auditedArea.id);
    }
    this.countTotalQuestions();
  }

  checkAuditOffline(): void {
    this.auditOfflineService.getAuditsIdsFromOfflineStore().then(auditsIds => {
      this.markAuditOffline(auditsIds);
    });
    this.auditsIdsOfflineSubscription = this.auditOfflineService.auditsIdsOfflineObservable.subscribe(auditsIds => {
      this.markAuditOffline(auditsIds);
    });
  }

  markAuditOffline(auditsIds: Array<AuditId>): void {
    if (auditsIds && this.audit) {
      const auditIdOffline = _.find(auditsIds, { id: this.audit.id });
      if (auditIdOffline) {
        this.audit.offline = true;
        this.audit.offlineDate = auditIdOffline.date;
      }
    }
  }

  markAuditDisabled(): void {
    this.audit.disabled = !this.permissionService.canAuditBeManaged(this.audit, this.userService.storedUser);
  }

  fetchFindingTypes(): void {
    this.findingService
      .getFindingTypes()
      .then((findingTypes: Array<FindingType>) => {
        this.findingTypes = findingTypes;
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.loadFindingTypes
        );
      });
  }

  fetchCustomSections(): void {
    this.customSections = this.sections.filter(section => {
      return section.custom;
    });
  }

  fetchQuestions(): void {
    this.questions = new Array<Question>();
    this.sections.forEach(section => {
      this.questions.push(...section.questions);
    });
  }

  getAuditedArea(id: string): AuditedArea {
    if (this.audit) {
      return _.find(this.audit.auditedAreas, { id });
    }
  }

  mergeOfflineRatings(auditedAreaId: string): void {
    this.ratingsService.getAuditedAreaRatesRequestsFromOfflineStore(auditedAreaId).then(ratings => {
      if (!ratings) {
        ratings = [];
      }
      this.questions.forEach((question: Question) => {
        const rating = _.find(ratings, { questionId: question.id });
        if (rating) {
          question.ratingIdOffline = rating.ratingId;
        } else {
          question.ratingIdOffline = undefined;
        }
        question.offline = question.isOffline();
      });
      this.countTotalQuestions();
    });
  }

  mergeOfflineQuestionsMedia(auditedAreaId: string): void {
    this.questionMediaService.getQuestionsMediaRequestsFromOfflineStore(auditedAreaId).then(questionsMedia => {
      questionsMedia = questionsMedia ? questionsMedia : [];
      this.questions.forEach((question: Question) => {
        const media = _.find(questionsMedia, { questionId: question.id });
        question.responseMediaOffline = !!media;
        question.offline = question.isOffline();
      });
    });
  }

  mergeOfflineFindings(auditedAreaId: string): Promise<void> {
    return this.findingOfflineService
      .getAuditedAreaFindingsRequestsListFromOfflineStore(auditedAreaId)
      .then((findingsResponse: FindingsResponse) => {
        const findings = findingsResponse && findingsResponse.findings ? findingsResponse.findings : [];
        this.questions.forEach((question: Question) => {
          const finding = _.find(
            findings,
            findingInList =>
              findingInList.relationships.findable._type === QUESTION_TYPE &&
              findingInList.relationships.findable.id === question.id
          );
          const findingToRemove = _.find(
            findings,
            findingInList =>
              findingInList.relationships.oldFindable &&
              findingInList.relationships.oldFindable._type === QUESTION_TYPE &&
              findingInList.relationships.oldFindable.id === question.id
          );
          question.hasFindingsOffline.all = !!finding || !!findingToRemove;
          question.hasFindingsOffline.toRemove = !!findingToRemove;
          question.offline = question.isOffline();
        });
      });
  }

  getColorOfRating(question: Question): string {
    const ratingId = question.ratingIdOffline ? question.ratingIdOffline : question.ratingId;
    const rating = _.find(this.ratings, { id: ratingId });
    return rating ? rating.color : '';
  }

  appendSection(section: Section): void {
    this.sections.push(section);
    this.fetchCustomSections();
  }

  updateSection(section: Section): void {
    const index = _.findIndex(this.sections, { id: section.id });
    if (~index) {
      this.sections[index].name = section.name;
    } else {
      this.sections.push(section);
    }
    this.fetchCustomSections();
  }

  spliceSection(section: Section): void {
    const index = _.findIndex(this.sections, { id: section.id });
    if (~index) {
      this.sections.splice(index, 1);
    }
    this.fetchCustomSections();
  }

  appendQuestion(question: Question, sectionId: string): void {
    const section = _.find(this.sections, { id: sectionId });
    if (section) {
      if (!section.questions) {
        section.questions = new Array<Question>();
      }
      section.questions.push(question);
    }
    this.questions.push(question);
  }

  updateQuestion(question: Question, sectionId: string): void {
    const section = _.find(this.sections, { id: sectionId });
    if (section) {
      if (!section.questions) {
        section.questions = new Array<Question>();
      }
      const index = _.findIndex(section.questions, { id: question.id });
      if (~index) {
        section.questions[index].content = question.content;
        section.questions[index].description = question.description;
        section.questions[index]['updateQuestion'] = true;
      } else {
        section.questions.push(question);
        this.questions.push(question);
      }
    }
  }

  spliceQuestion(section: Section, question: Question): void {
    if (section && section.questions) {
      const index = _.findIndex(section.questions, { id: question.id });
      if (~index) {
        section.questions.splice(index, 1);
      }
    }
  }

  saveFinding(oldFinding: Finding): void {
    const oldOfflineFindable =
      oldFinding && oldFinding.offlineRequest ? oldFinding.offlineRequest.relationships.findable : undefined;
    this.savingFinding = true;
    this.addFinding
      .submitAddFindingForm()
      .then((finding: Finding) => {
        this.modalService.close('findingModal');
        this.savingFinding = false;
        this.mergeOfflineFindings(this.auditedArea.id);
        this.refreshView(finding, oldFinding, oldOfflineFindable);
      })
      .catch(error => {
        this.savingFinding = false;
      });
  }

  removeFinding(finding: Finding): void {
    this.findingService
      .removeFinding(finding)
      .then(() => {
        this.notificationsService.success(
          this.translation.notify.success.removeFindingTitle,
          this.translation.notify.success.removeFindingText
        );
        this.mergeOfflineFindings(this.auditedArea.id);
        this.refreshView(finding, undefined, undefined, true);
      })
      .catch(() => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.removeFindingText
        );
      });
  }

  refreshView(finding: Finding, oldFinding?: Finding, previousFindable?: Question | AuditedArea, remove = false): void {
    const basedFindable = oldFinding ? oldFinding.relationships.findable : undefined;
    const newFindable = finding.getLatestValue('relationships.findable');
    const hasFindableChanged = previousFindable && newFindable.id !== previousFindable.id;

    if (this.openedFindingsSection && this.findingsSection === 'findings') {
      const page =
        remove && this.findingsBrowser.findings.length <= 1 && this.findingsBrowser.findingsPage > 0
          ? this.findingsBrowser.findingsPage - 1
          : this.findingsBrowser.findingsPage;
      this.findingsBrowser.fetchFindings(page);
    }

    if (newFindable._type === 'Question') {
      const question = _.find(this.questions, { id: newFindable.id }) as Question;
      if (question) {
        question['updateFindings'] = true;
        question['decreaseFindingsPage'] = remove && question.findings && question.findings.length <= 1;
      }
    }

    if (hasFindableChanged && previousFindable._type === 'Question') {
      const question = _.find(this.questions, { id: previousFindable.id });
      if (question) {
        question['updateFindings'] = true;
        if (newFindable._type === 'Question') {
          const destinationQuestion = _.find(this.questions, { id: newFindable.id }) as Question;
          destinationQuestion.hasFindings = true;
        }
        question['decreaseFindingsPage'] = question.findings && question.findings.length <= 1;
      }
    }

    if (
      basedFindable &&
      basedFindable.id !== newFindable.id &&
      (!hasFindableChanged || (hasFindableChanged && basedFindable.id !== previousFindable.id))
    ) {
      const basedQuestion = _.find(this.questions, { id: basedFindable.id });
      if (basedQuestion) {
        basedQuestion['updateFindings'] = true;
        basedQuestion['decreaseFindingsPage'] = basedQuestion.findings && basedQuestion.findings.length <= 1;
      }
    }
  }

  private removeOfflineRating(question: Question): Promise<void> {
    return this.ratingsService
      .removeRateRequestFromOfflineStore({
        auditedAreaId: this.auditedArea.id,
        questionId: question.id,
        ratingId: question.ratingIdOffline
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.connectionOfflineTitle,
          this.translation.notify.error.removeRatingText
        );
        throw error;
      });
  }

  private removeOnlineRating(question: Question): Promise<void> {
    return this.ratingsService.removeQuestionRating(this.auditedArea.id, question.id).catch(error => {
      switch (error.status) {
        case 403:
          this.notificationsService.error(
            this.translation.notify.error.permissions,
            this.translation.notify.error.removeRatingPermissionText
          );
          break;
        default:
          this.notificationsService.error(
            this.translation.notify.error.connectionTitle,
            this.translation.notify.error.removeRatingText
          );
      }
      throw error;
    });
  }

  private updateOnlineRating(question: Question, newRatingId: string): Promise<{ data?: any; offline?: boolean }> {
    return this.ratingsService
      .rateQuestion(this.auditedArea.id, question.id, newRatingId, question.ratingId)
      .catch(error => {
        switch (error.status) {
          case 403:
            this.notificationsService.error(
              this.translation.notify.error.permissions,
              this.translation.notify.error.updateRatingPermissionText
            );
            break;
          default:
            this.notificationsService.error(
              this.translation.notify.error.connectionTitle,
              this.translation.notify.error.updateRatingText
            );
        }
        throw error;
      });
  }

  async updateRating(newRatingId: string, question: Question): Promise<void> {
    /**
     * Function is needed to force ControlValueAccessor to run writeValue function
     * inside instantion of RadioButtonComponent that changed question's rating value
     */
    const cleanQuestionRatings = () => {
      question.ratingId = undefined;
      question.ratingIdOffline = undefined;
    };

    const oldQuestion = new Question(question);
    cleanQuestionRatings();

    let promise: Promise<any>;
    if (oldQuestion.ratingIdOffline === newRatingId) {
      promise = this.removeOfflineRating(oldQuestion).then(() => {
        question.ratingIdOffline = undefined;
      });
    } else if (!oldQuestion.ratingIdOffline && oldQuestion.ratingId === newRatingId) {
      promise = this.removeOnlineRating(oldQuestion).then(() => {
        question.ratingId = undefined;
        question.ratingIdOffline = undefined;
      });
    } else {
      promise = this.updateOnlineRating(oldQuestion, newRatingId).then(response => {
        question.ratingId = response.offline ? question.ratingId : newRatingId;
        question.ratingIdOffline = response.offline ? newRatingId : undefined;
      });
    }
    await promise.catch(() => {
      question.ratingId = oldQuestion.ratingId;
      question.ratingIdOffline = oldQuestion.ratingIdOffline;
    });

    question.offline = question.isOffline();
    this.countTotalQuestions();
  }

  countTotalQuestions(): void {
    let totalQuestionsCounter = 0;
    let totalRatedQuestionsCounter = 0;
    this.sections.forEach((section: Section) => {
      section.questions.forEach((question: Question) => {
        totalQuestionsCounter++;
        if (question.ratingId) {
          totalRatedQuestionsCounter++;
        }
      });
    });
    this.totalQuestions = totalQuestionsCounter;
    this.totalRatedQuestions = totalRatedQuestionsCounter;
  }

  hasAuditedAreasToChoose(): boolean {
    return this.audit && this.audit.auditedAreas && this.audit.auditedAreas.length > 1;
  }

  changeAuditedArea(): void {
    this.loadingPage = true;
    this.closeAuditedAreas();
  }

  closeAuditedAreas(): void {
    this.dropdownAuditedAreas.closeDropdown();
  }

  toggleFindingsSection(): void {
    this.openedFindingsSection = !this.openedFindingsSection;
    if (this.isMobileOrTablet) {
      this.modalService.open('sidebarModal');
    }
  }

  saveRedirect(): void {
    this.storageService.setStorage(StoragesNamesModule.auditSummaryRedirect, this.auditedArea.id);
  }

  showFindingModal(findable: any): void {
    this.modalService.open('findingModal', { findable });
  }

  showRemoveFindingModal(finding: Finding): void {
    this.dialogService
      .confirm(
        this.translation.removeFindingDialog.header,
        this.translation.removeFindingDialog.content,
        this.translation.removeFindingDialog.cancel,
        this.translation.removeFindingDialog.confirm
      )
      .then(() => this.removeFinding(finding))
      .catch(() => {});
  }

  showSectionModal(section?: Section): void {
    this.modalService.open('sectionModal', section);
  }

  showRemoveSectionModal(section: Section): void {
    this.dialogService
      .confirm(
        this.translation.removeSectionDialog.header,
        this.translation.removeSectionDialog.content,
        this.translation.removeSectionDialog.cancel,
        this.translation.removeSectionDialog.confirm
      )
      .then(() => {
        this.removeSection(section);
      })
      .catch(() => {});
  }

  showQuestionModal(section?: Section, question?: Question): void {
    this.modalService.open('questionModal', {
      question,
      section
    });
  }

  showRemoveQuestionModal(section: Section, question: Question): void {
    this.dialogService
      .confirm(
        this.translation.removeQuestionDialog.header,
        this.translation.removeQuestionDialog.content,
        this.translation.removeQuestionDialog.cancel,
        this.translation.removeQuestionDialog.confirm
      )
      .then(() => {
        this.removeQuestion(section, question);
      })
      .catch(() => {});
  }

  saveSectionModal(): void {
    this.savingSection = true;
    this.addSection
      .submitAddSectionForm()
      .then(sectionResponse => {
        if (!sectionResponse.addAnother) {
          this.modalService.close('sectionModal');
        }
        this.savingSection = false;
        if (sectionResponse.status === 'create') {
          this.appendSection(sectionResponse.section);
        } else {
          this.updateSection(sectionResponse.section);
        }
      })
      .catch(error => {
        this.savingSection = false;
      });
  }

  saveQuestionModal(): void {
    this.savingQuestion = true;
    this.addQuestion
      .submitAddQuestionForm()
      .then(questionResponse => {
        if (!questionResponse.addAnother) {
          this.modalService.close('questionModal');
        }
        this.savingQuestion = false;
        if (questionResponse.status === 'create') {
          this.updateSection(questionResponse.section);
          this.appendQuestion(questionResponse.question, questionResponse.section.id);
        } else {
          this.updateQuestion(questionResponse.question, questionResponse.section.id);
        }
      })
      .catch(error => {
        this.savingQuestion = false;
      });
  }

  removeSection(section: Section): void {
    this.sectionService
      .removeSection(section, this.auditedArea)
      .then(() => {
        this.spliceSection(section);
        this.notificationsService.success(
          this.translation.notify.success.removeSectionTitle,
          this.translation.notify.success.removeSectionText
        );
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.removeSectionText
        );
      });
  }

  removeQuestion(section: Section, question: Question): void {
    this.questionService
      .removeQuestion(question, section.id, this.auditedArea)
      .then(() => {
        this.spliceQuestion(section, question);
        this.notificationsService.success(
          this.translation.notify.success.removeQuestionTitle,
          this.translation.notify.success.removeQuestionText
        );
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.removeQuestionText
        );
      });
  }

  makeOffline(audit: Audit): void {
    this.savingAuditOffline = true;
    const promises = new Array<Promise<any>>();
    promises.push(
      this.auditService.saveWholeAuditOffline(audit).then((auditSyncData: AuditSyncData) => {
        this.initAuditData(auditSyncData);
      })
    );
    promises.push(this.ratingScalesService.saveRatingScaleOffline(audit));
    promises.push(this.findingService.saveFindingTypesOffline());
    promises.push(this.userService.saveEmployeesOffline());
    Promise.all(promises)
      .then(response => {
        this.savingAuditOffline = false;
        this.notificationsService.success(
          this.translation.notify.success.makeOfflineTitle,
          this.translation.notify.success.makeOfflineText
        );
      })
      .catch(error => {
        this.savingAuditOffline = false;
        this.notificationsService.error(
          this.translation.notify.error.makeOfflineTitle,
          this.translation.notify.error.makeOfflineText
        );
      });
  }

  syncAudit(audit: Audit): void {
    this.syncingAuditOffline = true;
    const promises = new Array<Promise<any>>();
    promises.push(this.auditService.syncWholeAuditOffline(audit));
    promises.push(this.ratingScalesService.saveRatingScaleOffline(audit));
    promises.push(this.findingService.saveFindingTypesOffline());
    promises.push(this.userService.saveEmployeesOffline());
    Promise.all(promises)
      .then(() => {
        this.syncingAuditOffline = false;
        this.notificationsService.success(
          this.translation.notify.success.syncOfflineTitle,
          this.translation.notify.success.syncOfflineText
        );
      })
      .catch(error => {
        this.syncingAuditOffline = false;
        this.notificationsService.error(
          this.translation.notify.error.syncOfflineTitle,
          this.translation.notify.error.syncOfflineText
        );
      });
  }

  async fetchTranslations(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ERROR.CONNECTION_OFFLINE',
        'GLOBAL.ERROR.PERMISSIONS',
        'GLOBAL.ERROR.LOAD_FINDING_TYPES',
        'GLOBAL.ERROR.LOAD_CHAPTER_WITH_QUESTIONS',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'AUDIT_CONDUCT.FINDINGS_BROWSER.ERROR.AUDITED_AREA.LOAD_FINDINGS',
        'AUDIT_CONDUCT.ERROR.REMOVE_FINDING_TEXT',
        'AUDIT_CONDUCT.ERROR.REMOVE_QUESTION_TEXT',
        'AUDIT_CONDUCT.ERROR.REMOVE_SECTION_TEXT',
        'AUDIT_CONDUCT.ERROR.REMOVE_RATING_TEXT',
        'AUDIT_CONDUCT.ERROR.REMOVE_RATING_PERMISSIONS_TEXT',
        'AUDIT_CONDUCT.ERROR.UPDATE_RATING_TEXT',
        'AUDIT_CONDUCT.ERROR.UPDATE_RATING_PERMISSIONS_TEXT',
        'AUDITS_LIST.ERROR.MAKE_AUDIT_OFFLINE_TITLE',
        'AUDITS_LIST.ERROR.MAKE_AUDIT_OFFLINE_TEXT',
        'AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TITLE',
        'AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TEXT',
        'AUDITS_LIST.SUCCESS.MAKE_AUDIT_OFFLINE_TITLE',
        'AUDITS_LIST.SUCCESS.MAKE_AUDIT_OFFLINE_TEXT',
        'AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TITLE',
        'AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TEXT',
        'AUDIT_CONDUCT.SUCCESS.REMOVE_FINDING_TITLE',
        'AUDIT_CONDUCT.SUCCESS.REMOVE_FINDING_TEXT',
        'AUDIT_CONDUCT.SUCCESS.REMOVE_QUESTION_TITLE',
        'AUDIT_CONDUCT.SUCCESS.REMOVE_QUESTION_TEXT',
        'AUDIT_CONDUCT.SUCCESS.REMOVE_SECTION_TITLE',
        'AUDIT_CONDUCT.SUCCESS.REMOVE_SECTION_TEXT',
        'AUDIT_CONDUCT.REMOVE_FINDING_DIALOG.HEADER',
        'AUDIT_CONDUCT.REMOVE_FINDING_DIALOG.CONTENT',
        'AUDIT_CONDUCT.REMOVE_QUESTION_DIALOG.HEADER',
        'AUDIT_CONDUCT.REMOVE_QUESTION_DIALOG.CONTENT',
        'AUDIT_CONDUCT.REMOVE_SECTION_DIALOG.HEADER',
        'AUDIT_CONDUCT.REMOVE_SECTION_DIALOG.CONTENT'
      ])
      .toPromise();
    this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.connectionOfflineTitle = translation['GLOBAL.ERROR.CONNECTION_OFFLINE'];
    this.translation.notify.error.permissions = translation['GLOBAL.ERROR.PERMISSIONS'];
    this.translation.notify.error.loadSectionsWithQuestions = translation['GLOBAL.ERROR.LOAD_CHAPTER_WITH_QUESTIONS'];
    this.translation.notify.error.loadFindings =
      translation['AUDIT_CONDUCT.FINDINGS_BROWSER.ERROR.AUDITED_AREA.LOAD_FINDINGS'];
    this.translation.notify.error.loadFindingTypes = translation['GLOBAL.ERROR.LOAD_FINDING_TYPES'];
    this.translation.notify.error.removeFindingText = translation['AUDIT_CONDUCT.ERROR.REMOVE_FINDING_TEXT'];
    this.translation.notify.error.removeQuestionText = translation['AUDIT_CONDUCT.ERROR.REMOVE_QUESTION_TEXT'];
    this.translation.notify.error.removeSectionText = translation['AUDIT_CONDUCT.ERROR.REMOVE_SECTION_TEXT'];
    this.translation.notify.error.removeRatingText = translation['AUDIT_CONDUCT.ERROR.REMOVE_RATING_TEXT'];
    this.translation.notify.error.removeRatingPermissionText =
      translation['AUDIT_CONDUCT.ERROR.REMOVE_RATING_PERMISSIONS_TEXT'];
    this.translation.notify.error.updateRatingText = translation['AUDIT_CONDUCT.ERROR.UPDATE_RATING_TEXT'];
    this.translation.notify.error.updateRatingPermissionText =
      translation['AUDIT_CONDUCT.ERROR.UPDATE_RATING_PERMISSIONS_TEXT'];
    this.translation.notify.error.makeOfflineTitle = translation['AUDITS_LIST.ERROR.MAKE_AUDIT_OFFLINE_TITLE'];
    this.translation.notify.error.makeOfflineText = translation['AUDITS_LIST.ERROR.MAKE_AUDIT_OFFLINE_TEXT'];
    this.translation.notify.error.syncOfflineTitle = translation['AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TITLE'];
    this.translation.notify.error.syncOfflineText = translation['AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TEXT'];
    this.translation.notify.success.makeOfflineTitle = translation['AUDITS_LIST.SUCCESS.MAKE_AUDIT_OFFLINE_TITLE'];
    this.translation.notify.success.makeOfflineText = translation['AUDITS_LIST.SUCCESS.MAKE_AUDIT_OFFLINE_TEXT'];
    this.translation.notify.success.syncOfflineTitle = translation['AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TITLE'];
    this.translation.notify.success.syncOfflineText = translation['AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TEXT'];
    this.translation.notify.success.removeFindingTitle = translation['AUDIT_CONDUCT.SUCCESS.REMOVE_FINDING_TITLE'];
    this.translation.notify.success.removeFindingText = translation['AUDIT_CONDUCT.SUCCESS.REMOVE_FINDING_TEXT'];
    this.translation.notify.success.removeQuestionTitle = translation['AUDIT_CONDUCT.SUCCESS.REMOVE_QUESTION_TITLE'];
    this.translation.notify.success.removeQuestionText = translation['AUDIT_CONDUCT.SUCCESS.REMOVE_QUESTION_TEXT'];
    this.translation.notify.success.removeSectionTitle = translation['AUDIT_CONDUCT.SUCCESS.REMOVE_SECTION_TITLE'];
    this.translation.notify.success.removeSectionText = translation['AUDIT_CONDUCT.SUCCESS.REMOVE_SECTION_TEXT'];
    this.translation.removeFindingDialog.header = translation['AUDIT_CONDUCT.REMOVE_FINDING_DIALOG.HEADER'];
    this.translation.removeFindingDialog.content = translation['AUDIT_CONDUCT.REMOVE_FINDING_DIALOG.CONTENT'];
    this.translation.removeFindingDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeFindingDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
    this.translation.removeQuestionDialog.header = translation['AUDIT_CONDUCT.REMOVE_QUESTION_DIALOG.HEADER'];
    this.translation.removeQuestionDialog.content = translation['AUDIT_CONDUCT.REMOVE_QUESTION_DIALOG.CONTENT'];
    this.translation.removeQuestionDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeQuestionDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
    this.translation.removeSectionDialog.header = translation['AUDIT_CONDUCT.REMOVE_SECTION_DIALOG.HEADER'];
    this.translation.removeSectionDialog.content = translation['AUDIT_CONDUCT.REMOVE_SECTION_DIALOG.CONTENT'];
    this.translation.removeSectionDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeSectionDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
  }
}

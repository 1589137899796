
<div class="edit-questions__content" (dragenter)="$event.preventDefault()" (dragover)="dragOver($event)" (touchover)="dragOver($event)">
  <p class="edit-questions__header">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.REFERENCE_DOCUMENTS' | translate }}<small>
      {{ auditable.countSelectedQuestions() }}
      {{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.QUESTIONS_SELECTED' | translate }}</small></p>
  <ul class="edit-questions__list edit-questions__list--level-1">
    <li class="edit-questions-list__item" *ngFor="let questionnaire of auditable.questionnaires">
      <gw-expand-box #questonnaireExpandBox="gwExpandBox">
        <ng-container expand-box-header="expand-box-header">
          <div class="item" [class.item--opened]="questonnaireExpandBox.opened">
            <label class="item__label">
              <gw-check-box class="item__checkbox" #checkBox="gwCheckBox" [ngModel]="questionnaire.added" [ngModelOptions]="{standalone: true}" (ngModelChange)="checkQuestionnaire(questionnaire, checkBox)" [disabled]="questionnaire.addedToParent"></gw-check-box><span class="item__name">{{ questionnaire.name }}</span><span class="item__counter">{{ questionnaire.totalQuestionsSelected }}/{{ questionnaire.totalQuestions }}</span>
            </label>
            <button class="button--icon item__toggle" type="button" (click)="toggleSections(questionnaire, questonnaireExpandBox)"><i class="gw-angle--down item-toggle__icon"></i></button>
          </div>
        </ng-container>
        <ng-container expand-box-content="expand-box-content">
          <ul class="edit-questions__list edit-questions__list--level-2">
            <li class="edit-questions-list__item" *ngFor="let section of questionnaire.sections">
              <gw-expand-box #sectionExpandBox="gwExpandBox">
                <ng-container expand-box-header="expand-box-header">
                  <div class="item" [class.item--opened]="sectionExpandBox.opened">
                    <label class="item__label">
                      <gw-check-box class="item__checkbox" #checkBox="gwCheckBox" [ngModel]="section.added" [ngModelOptions]="{standalone: true}" (ngModelChange)="checkSection(section, questionnaire, checkBox)" [disabled]="section.addedToParent"></gw-check-box><span class="item__name">{{ section.name }}</span>
                    </label>
                    <button class="button--icon item__toggle" *ngIf="section.totalQuestions &gt; 0" type="button" (click)="toggleQuestions(questionnaire, section, sectionExpandBox)"><i class="gw-angle--down item-toggle__icon"></i></button>
                  </div>
                </ng-container>
                <ng-container expand-box-content="expand-box-content">
                  <ul class="edit-questions__list edit-questions__list--level-3">
                    <li class="edit-questions-list__item" *ngFor="let question of section.questions">
                      <div class="item">
                        <label class="item__label">
                          <gw-check-box class="item__checkbox" #checkBox="gwCheckBox" [ngModel]="question.added" [ngModelOptions]="{standalone: true}" (ngModelChange)="checkQuestion(question, section, questionnaire, checkBox)" [disabled]="question.addedToParent"></gw-check-box><span class="item__name">{{ question.content }}</span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </ng-container>
              </gw-expand-box>
            </li>
          </ul>
        </ng-container>
      </gw-expand-box>
    </li>
  </ul>
  <p class="edit-questions__header" *ngIf="auditable.selectedSections?.length">{{ 'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SELECTED_QUESTIONS' | translate }}</p>
  <ul class="edit-questions__list edit-questions__list--level-2 edit-questions__list--with-drag">
    <li class="edit-questions-list__item" *ngFor="let section of auditable.selectedSections; let i = index" [class.edit-questions-list__item--with-options]="section.custom" [class.edit-questions-list__item--collapsable]="section.totalQuestions &gt; 0">
      <div class="edit-questions-list__drop-position" *ngIf="i === 0" [class.edit-questions-list__drop-position--over]="draggedSection?.dragging &amp;&amp; dropPosition === i" [class.edit-questions-list__drop-position--visible]="draggedSection?.dragging" (drop)="drop(i)" (dragenter)="dropPosition = i" (dragleave)="dropPosition = undefined" (dragover)="$event.preventDefault()" (touchenter)="dropPosition = i" (touchleave)="dropPosition = undefined" (touchover)="$event.preventDefault()"></div>
      <gw-expand-box #sectionExpandBox="gwExpandBox">
        <ng-container expand-box-header="expand-box-header">
          <div class="item" [class.item--opened]="sectionExpandBox.opened">
            <label class="item__label" #sectionElement="" for="section-name-{{ section.id }}">
              <ng-container *ngIf="section.custom">
                <button class="button--icon item__status" (click)="sectionName.focus()"><i class="gw-pen"></i></button>
                <div class="textarea-container item__name item__name--input">
                  <textarea class="textarea--small textarea--autosize item__name-textarea" gwAutosize="gwAutosize" rows="1" name="section-name-{{ section.id }}" #sectionName="" [class.textarea--error]="section.newName?.length === 0" [ngModel]="section.newName" (ngModelChange)="changeSectionName(section, $event)" (focus)="makeSectionEditable(section)"></textarea>
                  <div class="textarea-border"></div>
                </div>
              </ng-container>
              <ng-container *ngIf="!section.custom">
                <div class="item__status"></div>
                <div class="item__name item__name--with-padding">{{ section.name }}</div>
              </ng-container>
            </label>
            <div class="button--icon item__drag" [draggable]="true" (dragstart)="dragStart($event, sectionElement, section)" (dragend)="dragEnd()" (touchstart)="dragStart($event, sectionElement, section)" (touchend)="dragEnd(); drop(dropPosition);"><i class="gw-hamburger"></i></div>
            <button class="button--icon item__toggle" *ngIf="section.totalQuestions &gt; 0" type="button" (click)="toggleQuestions(auditable, section, sectionExpandBox)"><i class="gw-angle--down item-toggle__icon"></i></button><span class="dropdown dropdown--menu" *ngIf="section.custom" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
              <button class="button--icon item__options" type="button"><i class="gw-more item-options__icon"></i></button>
              <div class="dropdown__content jsDropdownContent">
                <ul class="dropdown-menu__list">
                  <li class="dropdown-menu__item">
                    <button class="link dropdown-menu__link" type="button" (click)="showEditSectionModal(section)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                  </li>
                  <li class="dropdown-menu__item" *ngIf="section.custom">
                    <button class="link dropdown-menu__link" type="button" (click)="showRemoveSectionModal(section)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                  </li>
                </ul>
              </div></span>
            <div class="item__button-group" *ngIf="section.unsaved">
              <button class="link button--small item__button" type="button" (click)="resetSectionName(section)">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
              <button class="button--primary button--small item__button" type="button" [disabled]="section.newName?.length === 0" (click)="saveSectionName(section)">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
            </div>
          </div>
        </ng-container>
        <ng-container expand-box-content="expand-box-content">
          <ul class="edit-questions__list edit-questions__list--level-3 edit-questions__list--with-drag">
            <li class="edit-questions-list__item" *ngFor="let question of section.questions; let q = index" [class.edit-questions-list__item--with-options]="question.custom">
              <div class="edit-questions-list__drop-position" *ngIf="q === 0" [class.edit-questions-list__drop-position--over]="draggedQuestion?.dragging &amp;&amp; draggedQuestion?.section?.id === section.id &amp;&amp; dropPosition === q" [class.edit-questions-list__drop-position--visible]="draggedQuestion?.dragging &amp;&amp; draggedQuestion?.section?.id === section.id" (drop)="drop(q, true)" (dragenter)="dropPosition = q" (dragleave)="dropPosition = undefined" (dragover)="$event.preventDefault()" (touchenter)="dropPosition = q" (touchleave)="dropPosition = undefined" (touchover)="$event.preventDefault()"></div>
              <div class="item" [class.item--bordered]="draggedQuestion?.dragging &amp;&amp; draggedQuestion?.section?.id === section.id">
                <label class="item__label" #questionElement="">
                  <ng-container *ngIf="question.custom">
                    <button class="button--icon item__status" (click)="questionContent.focus()"><i class="gw-pen"></i></button>
                    <div class="textarea-container item__name item__name--input">
                      <textarea class="textarea--small textarea--autosize item__name-textarea" gwAutosize="gwAutosize" rows="1" name="question-content-{{ question.id }}" #questionContent="" [class.textarea--error]="question.newContent?.length === 0" [ngModel]="question.newContent" (ngModelChange)="changeQuestionContent(question, $event)" (focus)="makeQuestionEditable(question)"></textarea>
                      <div class="textarea-border"></div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!question.custom">
                    <div class="item__status"></div><span class="item__name item__name--with-padding">{{ question.content }}</span>
                  </ng-container>
                </label>
                <div class="button--icon item__drag" [draggable]="true" (dragstart)="dragStart($event, questionElement, section, question)" (dragend)="dragEnd()" (touchstart)="dragStart($event, questionElement, section, question)" (touchend)="dragEnd(); drop(dropPosition, true);"><i class="gw-hamburger"></i></div><span class="dropdown dropdown--menu" *ngIf="question.custom" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
                  <button class="button--icon item__options" type="button"><i class="gw-more item-options__icon"></i></button>
                  <div class="dropdown__content jsDropdownContent">
                    <ul class="dropdown-menu__list">
                      <li class="dropdown-menu__item">
                        <button class="link dropdown-menu__link" type="button" (click)="showEditQuestionModal(section, question)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                      </li>
                      <li class="dropdown-menu__item" *ngIf="question.custom">
                        <button class="link dropdown-menu__link" type="button" (click)="showRemoveQuestionModal(section, question)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                      </li>
                    </ul>
                  </div></span>
                <div class="item__button-group" *ngIf="question.unsaved">
                  <button class="link button--small item__button" type="button" (click)="resetQuestionContent(question)">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
                  <button class="button--primary button--small item__button" type="button" [disabled]="question.newContent?.length === 0" (click)="saveQuestionContent(section, question)">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
                </div>
              </div>
              <div class="edit-questions-list__drop-position" [class.edit-questions-list__drop-position--over]="draggedQuestion?.dragging &amp;&amp; draggedQuestion?.section?.id === section.id &amp;&amp; dropPosition === q + 1" [class.edit-questions-list__drop-position--visible]="draggedQuestion?.dragging &amp;&amp; draggedQuestion?.section?.id === section.id" (drop)="drop(q + 1, true)" (dragenter)="dropPosition = q + 1" (dragleave)="dropPosition = undefined" (dragover)="$event.preventDefault()" (touchenter)="dropPosition = q + 1" (touchleave)="dropPosition = undefined" (touchover)="$event.preventDefault()"></div>
            </li>
          </ul>
        </ng-container>
      </gw-expand-box>
      <div class="edit-questions-list__drop-position" [class.edit-questions-list__drop-position--over]="draggedSection?.dragging &amp;&amp; dropPosition === i + 1" [class.edit-questions-list__drop-position--visible]="draggedSection?.dragging" (drop)="drop(i + 1)" (dragenter)="dropPosition = i + 1" (dragleave)="dropPosition = undefined" (dragover)="$event.preventDefault()" (touchenter)="dropPosition = i + 1" (touchleave)="dropPosition = undefined" (touchover)="$event.preventDefault()"></div>
    </li>
  </ul>
</div>
import { oldApiEnvironment } from '../environments/old-api-environment';

export class OldApiUrlClass {
  api = `${oldApiEnvironment.api}`;
  authApi = `${this.api}/apis/authenticate`;
  userApi = `${this.api}/apis/user`;
  passwordRessetApi = `${this.api}/Common/PasswordReset.aspx`;
}

export const ApiUrl = new OldApiUrlClass();

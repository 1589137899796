
<gw-modal id="addQuestionnaireModal" #questionnaireModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="questionnaireModal.data">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTIONNAIRE_MODAL.HEADER.EDIT' | translate }}</ng-container>
    <ng-container *ngIf="!questionnaireModal.data">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTIONNAIRE_MODAL.HEADER.ADD' | translate }}</ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="questionnaireModal.opened">
    <gw-add-reference-document #addReferenceDocument="gwAddReferenceDocument" [questionnaire]="questionnaireModal.data"></gw-add-reference-document>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="questionnaireModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="saveQuestionnaire()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<gw-modal id="importQuestionnaireModal" #importQuestionnaireModal="gwModal">
  <ng-container modal-header="modal-header">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.IMPORT_QUESTIONNAIRE_MODAL.HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content" *ngIf="importQuestionnaireModal.opened">
    <gw-import-reference-document #importReferenceDocument="gwImportReferenceDocument" (importQuestionnaire)="afterImportQuestionnaire()"></gw-import-reference-document>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <div class="import-questionnaire-modal__actions">
      <button class="import-questionnaire-modal__action link" type="button" (click)="importQuestionnaireModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button><a class="import-questionnaire-modal__action button--secondary" href="/assets/files/questionnaire.xlsx" download="">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.IMPORT_QUESTIONNAIRE_MODAL.ACTION.DOWNLOAD' | translate }}</a>
      <label class="import-questionnaire-modal__action button--primary" for="upload">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.IMPORT_QUESTIONNAIRE_MODAL.ACTION.IMPORT' | translate }}
        <input class="hide" #questionnaireFileInput="" type="file" id="upload" (change)="importReferenceDocument.uploadQuestionnaire($event)"/>
      </label>
    </div>
  </ng-container>
</gw-modal>
<div class="row reference-documents">
  <div class="columns">
    <div class="row reference-documents__header">
      <div class="columns small-12 large-6 reference-documents-header__title-container">
        <h1 class="reference-documents-header__title">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.HEADER' | translate }}</h1>
      </div>
      <div class="columns small-12 large-6 reference-documents-header__button-container">
        <div class="button-switch reference-documents-header__button">
          <button class="button--primary" type="button" (click)="importQuestionnaireModal.open()">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ACTION.IMPORT' | translate }}</button>
          <gw-button-dropdown class="single-reference-document-header__button" #buttonDropdown="buttonDropdown" [buttonClasses]="['button--primary']">
            <ng-container button-dropdown-list="button-dropdown-list">
              <li class="button-dropdown-list__item"><a href="/assets/files/questionnaire.xlsx" download="">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ACTION.DOWNLOAD' | translate }}</a></li>
            </ng-container>
          </gw-button-dropdown>
        </div>
        <button class="button--primary reference-documents-header__button" type="button" (click)="questionnaireModal.open()">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ACTION.ADD_REFERENCE_DOCUMENT' | translate }}</button>
      </div>
    </div>
    <div class="reference-documents__content">
      <ul class="reference-documents__list reference-documents__list--level-1">
        <li class="reference-documents-list__item" *ngFor="let questionnaire of questionnaires">
          <div class="reference-documents-list-item__content">
            <div class="reference-documents-list-item__group reference-documents-list-item__group--link" [routerLink]="['/admin/questionnaires', questionnaire.id]">
              <div class="reference-documents-list-item__name">{{ questionnaire.name }}</div>
              <div class="reference-documents-list-item__counters">
                <div class="reference-documents-list-item__counter">
                  {{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.LABEL.CHAPTERS' | translate }}:
                  {{ questionnaire.totalSections || 0 }}
                </div>
                <div class="reference-documents-list-item__counter">
                  {{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.LABEL.QUESTIONS' | translate }}:
                  {{ questionnaire.totalQuestions || 0 }}
                </div>
              </div>
            </div>
            <div class="reference-documents-list-item__options"><span class="dropdown dropdown--menu reference-documents-list-item__option--dropdown" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
                <button class="reference-documents-list-item__option reference-documents-list-item__option--more" type="button"><i class="gw-more"></i></button>
                <div class="dropdown__content jsDropdownContent">
                  <ul class="dropdown-menu__list">
                    <li class="dropdown-menu__item">
                      <button class="link dropdown-menu__link" type="button" (click)="showUpdateQuestionnaireModal(questionnaire)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                    </li>
                    <li class="dropdown-menu__item">
                      <button class="link dropdown-menu__link" type="button" (click)="showRemoveModal(questionnaire)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                    </li>
                    <li class="dropdown-menu__item">
                      <button class="link dropdown-menu__link" type="button" (click)="exportQuestionnaire(questionnaire)">{{ 'GLOBAL.ACTION.EXPORT' | translate }}</button>
                    </li>
                  </ul>
                </div></span></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  KeyValueDiffers,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { findIndex, uniqBy } from 'lodash';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// Gutwin Shared Library
import { Attachment, FileService, ModalService, OfflineService } from 'gutwin-shared';

// Models
import { AuditedArea } from '../../../shared/models/audited-area.model';
import { Finding } from '../../../shared/models/finding.model';
import { FindingsResponse } from '../../../shared/models/findings-response.model';
import { QuestionMedia } from '../../../shared/models/question-media.model';
import { Question } from '../../../shared/models/question.model';

// Services
import { AttachmentService } from '../../../shared/services/attachment.service';
import { FindingService } from '../../../shared/services/finding.service';
import { QuestionMediaService } from '../../../shared/services/question-media.service';

@Component({
  selector: 'gw-question-details',
  templateUrl: './question-details.component.html',
  styleUrls: ['./question-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionDetailsComponent implements OnInit, DoCheck, OnDestroy {
  @Input() question: Question;
  @Input() auditedArea: AuditedArea;
  @Input() isAuditDisabled = false;
  @Output() removeFinding = new EventEmitter<Finding>();
  questionMedia: QuestionMedia;
  attachments = new Array<Attachment>();
  attachmentsGroups = {
    images: new Array<Attachment>(),
    others: new Array<Attachment>()
  };
  updatingAttachments = false;
  findingsAmount = 0;
  findingsPage = 0;
  questionForm: FormGroup;
  updateStatus = false;
  hasResponse = false;
  blur = false;
  translateSubscription: Subscription;
  questionFormSubscription: Subscription;
  duplications: Array<Attachment>;
  differ: any;
  notify = {
    error: {
      fetchFindingsTitle: '',
      fetchFindingsText: '',
      fetchMediaTitle: '',
      fetchMediaText: '',
      updateCommentTitle: '',
      updateCommentText: '',
      updateAttachmentsTitle: '',
      updateAttachmentsText: ''
    },
    success: {
      updateCommentTitle: '',
      updateCommentText: '',
      updateAttachmentsTitle: '',
      updateAttachmentsText: ''
    }
  };

  constructor(
    private attachmentService: AttachmentService,
    private changeDetector: ChangeDetectorRef,
    private differs: KeyValueDiffers,
    private fileService: FileService,
    private findingService: FindingService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private offlineService: OfflineService,
    private questionMediaService: QuestionMediaService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.initQuestionForm();
    this.fetchFindings();
    this.fetchMedia();
    this.fetchTranslations();
    this.differ = this.differs.find({}).create();
    this.offlineService.onlineObservable.subscribe(() => {
      this.changeDetector.markForCheck();
    });
  }

  ngDoCheck() {
    const changes = this.differ.diff(this.question);
    if (changes && this.question['updateFindings']) {
      this.findingsPage =
        this.question['decreaseFindingsPage'] && this.findingsPage > 0 ? this.findingsPage - 1 : this.findingsPage;
      delete this.question['updateFindings'];
      delete this.question['decreaseFindingsPage'];
      this.fetchFindings(this.findingsPage, true);
    }
    if (changes && this.question['updateQuestion']) {
      delete this.question['updateQuestion'];
      this.changeDetector.markForCheck();
    }
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
    if (this.questionFormSubscription) {
      this.questionFormSubscription.unsubscribe();
    }
  }

  initQuestionForm() {
    this.questionForm = this.formBuilder.group({
      comment: [{ value: '', disabled: this.isAuditDisabled }]
    });

    this.questionFormSubscription = this.questionForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((formValues: any) => {
        const areCommentsEmpty = this.questionMedia && !formValues.comment && !this.questionMedia.comment;
        const areCommentsDifferent = this.questionMedia && formValues.comment !== this.questionMedia.comment;
        if ((!this.questionMedia && formValues.comment) || (areCommentsDifferent && !areCommentsEmpty)) {
          this.saveQuestionMedia(formValues.comment, this.attachments);
        }
      });
  }

  fetchFindings(page = this.findingsPage, force?: boolean): void {
    if (this.question.hasFindings || this.question.hasFindingsOffline.all || force) {
      this.findingsPage = page;
      this.findingService
        .getQuestionFindings(this.question.id, this.auditedArea.id, page, undefined, !this.isAuditDisabled)
        .then((data: FindingsResponse) => {
          this.question.findings = data.findings;
          this.question.hasFindings = !!this.question.findings.length;
          this.findingsAmount = data.findingsAmount.total;
          this.changeDetector.markForCheck();
        })
        .catch(() => {
          this.notificationsService.error(this.notify.error.fetchFindingsTitle, this.notify.error.fetchFindingsText);
        });
    }
  }

  changeFindingsPage(page: number): void {
    this.findingsPage = page;
    this.fetchFindings(page);
  }

  fetchMedia(): void {
    const promises = new Array<Promise<any>>();
    if (this.question.responseMediaId) {
      promises.push(
        this.questionMediaService
          .fetchMedia(this.auditedArea.id, this.question.responseMediaId)
          .then((data: QuestionMedia) => {
            const commentOffline = this.questionMedia ? this.questionMedia.commentOffline : undefined;
            const attachments = this.questionMedia ? this.questionMedia.attachments : [];
            this.questionMedia = data;
            this.questionMedia.commentOffline = commentOffline;
            this.questionMedia.attachments.push(...attachments);
          })
          .catch(() => {
            this.notificationsService.error(this.notify.error.fetchMediaTitle, this.notify.error.fetchMediaText);
          })
      );
    }
    if (!this.isAuditDisabled) {
      promises.push(
        this.questionMediaService
          .getQuestionMediaRequestFromOfflineStore(this.auditedArea.id, this.question.id)
          .then((data: QuestionMedia) => {
            if (data) {
              if (!this.questionMedia) {
                this.questionMedia = new QuestionMedia();
              }
              this.questionMedia.commentOffline = data.commentOffline;
              this.questionMedia.attachments.push(...data.attachments);
            }
          })
      );
    }

    Promise.all(promises).then(() => {
      if (this.questionMedia) {
        const comment =
          this.questionMedia.commentOffline !== undefined
            ? this.questionMedia.commentOffline
            : this.questionMedia.comment;
        this.questionForm.controls['comment'].setValue(comment);
        this.fillAttachments(this.questionMedia.attachments);
        this.changeDetector.markForCheck();
      }
    });
  }

  convertAttachments(attachments: Array<Attachment>): Array<Attachment> {
    return attachments && attachments.length
      ? attachments.map(attachment => new Attachment(attachment))
      : new Array<Attachment>();
  }

  fillAttachments(attachments: Array<Attachment>): void {
    const filteredAttachments = this.removeDuplicatedAttachments(attachments);
    this.attachments = this.convertAttachments(filteredAttachments);
    this.fillAttachmentsGroups(filteredAttachments);
  }

  removeDuplicatedAttachments(attachments: Array<Attachment>): Array<Attachment> {
    return uniqBy(attachments, 'checksum');
  }

  fillAttachmentsGroups(attachments: Array<Attachment>): void {
    this.attachmentsGroups.images = this.convertAttachments(attachments).filter(
      attachment => !attachment.toRemove && attachment.isImage
    );
    this.attachmentsGroups.others = this.convertAttachments(attachments).filter(
      attachment => !attachment.toRemove && !attachment.isImage
    );
  }

  fillAttachmentsFromGroups(attachmentsGroups: { images: Array<Attachment>; others: Array<Attachment> }): void {
    this.attachments = [...attachmentsGroups.images, ...attachmentsGroups.others];
  }

  showFindingModal(findable: AuditedArea | Question): void {
    this.modalService.open('findingModal', { findable });
  }

  showUpdateFindingModal(finding: Finding): void {
    this.modalService.open('findingModal', { finding });
  }

  showRemoveFindingModal(finding: Finding): void {
    this.removeFinding.emit(finding);
  }

  attachFiles(event: any, fileInput: any): void {
    const convertFileToAttachment = (file: File, hash: string, rotation: number): Attachment => {
      const attachment = new Attachment({ file });
      attachment.checksum = hash;
      attachment.rotation = rotation;
      return attachment;
    };

    const submitFiles = (toUpload: boolean) => {
      if (this.duplications.length) {
        this.modalService.open('duplicationsModal', this.duplications);
        this.changeDetector.detectChanges();
      }
      if (toUpload) {
        this.blur = true;
        this.updatingAttachments = true;
        this.saveQuestionMedia(undefined, this.attachments);
      }

      this.fileService.resetHTMLFileInput(fileInput);
    };

    this.duplications = new Array<Attachment>();
    const fileList: FileList = event.target.files;
    let fileCounter = 0;
    let toUpload = false;

    if (fileList && fileList.length > 0) {
      Array.from(fileList).forEach(file => {
        this.fileService.readFile(file).then(({ hash, rotation }) => {
          const attachment = convertFileToAttachment(file, hash, rotation);

          if (this.isDuplicated(attachment)) {
            this.duplications.push(attachment);
          } else {
            toUpload = true;
            this.attachments.push(attachment);
            this.fillAttachmentsGroups(this.attachments);
          }
          this.changeDetector.markForCheck();

          fileCounter++;
          if (fileCounter === fileList.length) {
            submitFiles(toUpload);
          }
        });
      });
    }
  }

  spliceAttachment(attachment: Attachment): void {
    const index = findIndex(this.attachments, { checksum: attachment.checksum });
    if (~index) {
      this.attachments.splice(index, 1);
      this.fillAttachmentsGroups(this.attachments);
      this.changeDetector.markForCheck();
    }
  }

  removeAttachment(attachment: Attachment): void {
    if (attachment.offline) {
      this.attachmentService.removeQuestionMediaAttachmentRequestFromOfflineStore(
        this.auditedArea.id,
        this.question.id,
        attachment.checksum
      );
      this.spliceAttachment(attachment);
    } else {
      attachment.toRemove = true;
      this.blur = true;
      this.updatingAttachments = true;
      this.fillAttachmentsFromGroups(this.attachmentsGroups);
      this.saveQuestionMedia(undefined, this.attachments);
    }
  }

  isDuplicated(checkedAttachment: Attachment): boolean {
    for (const attachment of this.attachments) {
      if (!attachment.toRemove && checkedAttachment.checksum === attachment.checksum) {
        return true;
      }
    }
    return false;
  }

  saveQuestionMedia(comment: string, attachments: Array<Attachment>): Promise<void> {
    const shouldBeRemoved = () => {
      const attachmentsStillInMedia = attachments.filter(attachment => !attachment.toRemove);
      const commentToUpdate = comment || this.questionForm.value.comment;
      return (!commentToUpdate || commentToUpdate.length === 0) && attachmentsStillInMedia.length === 0;
    };

    const updateData = (data: QuestionMedia, offline?: boolean) => {
      this.questionMedia = data || new QuestionMedia();
      this.questionMedia.commentOffline = offline ? this.questionMedia.comment : undefined;

      this.question.responseMediaId = data ? data.id : undefined;
      this.question.responseMediaOffline = !!offline;
      this.question.offline = this.question.isOffline();
    };

    const questionMedia = new QuestionMedia({
      id: this.question.responseMediaId,
      comment: comment || this.questionForm.value.comment,
      attachments,
      auditedAreaId: this.auditedArea.id,
      questionId: this.question.id
    });
    let questionMediaPromise: Promise<any>;
    if (questionMedia.id) {
      questionMediaPromise = shouldBeRemoved()
        ? this.questionMediaService.removeMedia(questionMedia)
        : this.questionMediaService.updateMedia(questionMedia);
    } else {
      questionMediaPromise = this.questionMediaService.createMedia(questionMedia);
    }

    return questionMediaPromise
      .then(({ data, offline }) => {
        updateData(data, offline);
        if (this.updatingAttachments) {
          this.fillAttachments(data ? data.attachments : []);
        }
        this.updateStatus = true;
      })
      .catch(() => {
        this.updateStatus = false;
      })
      .finally(() => {
        this.hasResponse = true;
        this.updatingAttachments = false;
        this.showNotify();
        this.changeDetector.markForCheck();
      });
  }

  showNotify(): void {
    if (this.hasResponse && this.blur) {
      if (this.updateStatus) {
        this.notificationsService.success(
          this.updatingAttachments
            ? this.notify.success.updateAttachmentsTitle
            : this.notify.success.updateCommentTitle,
          this.updatingAttachments ? this.notify.success.updateAttachmentsText : this.notify.success.updateCommentText
        );
      } else {
        this.notificationsService.error(
          this.updatingAttachments ? this.notify.error.updateAttachmentsTitle : this.notify.error.updateCommentTitle,
          this.updatingAttachments ? this.notify.error.updateAttachmentsText : this.notify.error.updateCommentText
        );
      }
      this.blur = false;
      this.hasResponse = false;
    }
  }

  commentFieldBlur(): void {
    this.blur = true;
    this.showNotify();
  }

  areAttachmentOptionsVisible(attachment: Attachment): boolean {
    return attachment && (attachment.url || attachment.offline) && !this.isAuditDisabled;
  }

  fetchTranslations(): void {
    this.translateSubscription = this.translateService
      .get([
        'AUDIT_CONDUCT.QUESTION.ERROR.FETCH_FINDINGS_TITLE',
        'AUDIT_CONDUCT.QUESTION.ERROR.FETCH_FINDINGS_TEXT',
        'AUDIT_CONDUCT.QUESTION.ERROR.FETCH_MEDIA_TITLE',
        'AUDIT_CONDUCT.QUESTION.ERROR.FETCH_MEDIA_TEXT',
        'AUDIT_CONDUCT.QUESTION.ERROR.UPDATE_COMMENT_TITLE',
        'AUDIT_CONDUCT.QUESTION.ERROR.UPDATE_COMMENT_TEXT',
        'AUDIT_CONDUCT.QUESTION.ERROR.UPDATE_ATTACHMENTS_TITLE',
        'AUDIT_CONDUCT.QUESTION.ERROR.UPDATE_ATTACHMENTS_TEXT',
        'AUDIT_CONDUCT.QUESTION.SUCCESS.UPDATE_COMMENT_TITLE',
        'AUDIT_CONDUCT.QUESTION.SUCCESS.UPDATE_COMMENT_TEXT',
        'AUDIT_CONDUCT.QUESTION.SUCCESS.UPDATE_ATTACHMENTS_TITLE',
        'AUDIT_CONDUCT.QUESTION.SUCCESS.UPDATE_ATTACHMENTS_TEXT'
      ])
      .subscribe((translation: any) => {
        this.notify.error.fetchFindingsTitle = translation['AUDIT_CONDUCT.QUESTION.ERROR.FETCH_FINDINGS_TITLE'];
        this.notify.error.fetchFindingsText = translation['AUDIT_CONDUCT.QUESTION.ERROR.FETCH_FINDINGS_TEXT'];
        this.notify.error.fetchMediaTitle = translation['AUDIT_CONDUCT.QUESTION.ERROR.FETCH_MEDIA_TITLE'];
        this.notify.error.fetchMediaText = translation['AUDIT_CONDUCT.QUESTION.ERROR.FETCH_MEDIA_TEXT'];
        this.notify.error.updateCommentTitle = translation['AUDIT_CONDUCT.QUESTION.ERROR.UPDATE_COMMENT_TITLE'];
        this.notify.error.updateCommentText = translation['AUDIT_CONDUCT.QUESTION.ERROR.UPDATE_COMMENT_TEXT'];
        this.notify.error.updateAttachmentsTitle = translation['AUDIT_CONDUCT.QUESTION.ERROR.UPDATE_ATTACHMENTS_TITLE'];
        this.notify.error.updateAttachmentsText = translation['AUDIT_CONDUCT.QUESTION.ERROR.UPDATE_ATTACHMENTS_TEXT'];
        this.notify.success.updateCommentTitle = translation['AUDIT_CONDUCT.QUESTION.SUCCESS.UPDATE_COMMENT_TITLE'];
        this.notify.success.updateCommentText = translation['AUDIT_CONDUCT.QUESTION.SUCCESS.UPDATE_COMMENT_TEXT'];
        this.notify.success.updateAttachmentsTitle =
          translation['AUDIT_CONDUCT.QUESTION.SUCCESS.UPDATE_ATTACHMENTS_TITLE'];
        this.notify.success.updateAttachmentsText =
          translation['AUDIT_CONDUCT.QUESTION.SUCCESS.UPDATE_ATTACHMENTS_TEXT'];
      });
  }
}

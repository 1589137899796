import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
// Models
import { Audit } from './../../../shared/models/audit.model';
import { AuditedArea, AUDITED_AREA_TYPE } from './../../../shared/models/audited-area.model';
// Services
import { AuditService } from './../../../shared/services/audit.service';
import { AuditOfflineService, AuditRequest, AuditedAreaRequest } from './../../../shared/services/audit.offline.service';
import { AuditedAreaService } from './../../../shared/services/audited-area.service';

@Component({
  selector: 'gw-audit-summary-section',
  templateUrl: './audit-summary-section.component.html',
  styleUrls: ['./audit-summary-section.component.scss']
})
export class AuditSummarySectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() audit: Audit;
  @Input() auditable: Audit | AuditedArea;
  notify = {
    error: {
      connectionTitle: '',
      updateAuditSummary: '',
    },
    success: {
      updateAuditSummaryTitle: '',
      updateAuditSummaryText: ''
    }
  };
  translateSubscription: Subscription;
  summaryFormSubscription: Subscription;
  hasError = false;
  hasResponse = false;
  blur = false;
  summaryForm: FormGroup;

  constructor(
    private auditService: AuditService,
    private auditOfflineService: AuditOfflineService,
    private auditedAreaService: AuditedAreaService,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.initData();
    this.fetchTranslation();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.auditable) {
      this.initData();
    }
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
    if (this.summaryFormSubscription) this.summaryFormSubscription.unsubscribe();
  }

  async initData(): Promise<any> {
    if (!this.audit.disabled) {
      await this.mergeAuditableRequest();
    }
    this.initSummaryForm();
  }

  async mergeAuditableRequest(): Promise<any> {
    const auditableRequestPromise: Promise<AuditRequest | AuditedAreaRequest> = this.auditable._type === AUDITED_AREA_TYPE ?
      this.auditOfflineService.getAuditedAreaRequestFromOfflineStore(this.audit.id, this.auditable.id) :
      this.auditOfflineService.getAuditRequestFromOfflineStore(this.audit.id);

    const auditableRequest = await auditableRequestPromise;
    if (auditableRequest) {
      this.auditable.summaryOffline = auditableRequest.summary;
    }
  }

  initSummaryForm(): void {
    const summary = this.auditable.summaryOffline !== undefined ? this.auditable.summaryOffline : this.auditable.summary;
    this.summaryForm = this.formBuilder.group({
      summary: [{value: summary || '', disabled: this.audit.disabled}]
    });

    this.summaryFormSubscription = this.summaryForm.valueChanges.pipe(debounceTime(1000)).subscribe((formValue: any) => {
      this.saveSummary(formValue);
    });
  }

  saveSummary(formValue: any): void {
    const auditableRequest = { id: this.auditable.id, summary: formValue.summary };
    const summaryPromise: Promise<{data: Audit | AuditedArea, offline?: boolean}> = this.auditable._type === AUDITED_AREA_TYPE ?
      this.auditedAreaService.updateAuditedAreaSummary(this.audit.id, new AuditedArea(auditableRequest)) :
      this.auditService.updateAuditSummary(new Audit(auditableRequest));
    summaryPromise
      .then(({data, offline}) => {
        if (offline) {
          this.auditable.summaryOffline = data.summaryOffline;
        } else {
          this.auditable.summary = data.summary;
          this.auditable.summaryOffline = undefined;
        }
        this.hasError = true;
        this.hasResponse = true;
        this.showNotify();
      })
      .catch(error => {
        this.hasError = false;
        this.hasResponse = true;
      });
  }

  showNotify(): void {
    if (this.hasResponse && this.blur) {
      if (this.hasError) {
        this.notificationsService.success(this.notify.success.updateAuditSummaryTitle, this.notify.success.updateAuditSummaryText);
      } else {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.updateAuditSummary);
      }
      this.blur = false;
      this.hasResponse = false;
    }
  }

  summaryFieldBlur(): void {
    this.blur = true;
    this.showNotify();
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'AUDIT_SUMMARY.ERROR.UPDATE_AUDIT_SUMMARY',
      'AUDIT_SUMMARY.SUCCESS.UPDATE_AUDIT_SUMMARY_TITLE',
      'AUDIT_SUMMARY.SUCCESS.UPDATE_AUDIT_SUMMARY_TEXT'
    ])
    .subscribe((translation: any) => {
      this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
      this.notify.error.updateAuditSummary = translation['AUDIT_SUMMARY.ERROR.UPDATE_AUDIT_SUMMARY'];
      this.notify.success.updateAuditSummaryTitle = translation['AUDIT_SUMMARY.SUCCESS.UPDATE_AUDIT_SUMMARY_TITLE'];
      this.notify.success.updateAuditSummaryText = translation['AUDIT_SUMMARY.SUCCESS.UPDATE_AUDIT_SUMMARY_TEXT'];
    });
  }
}

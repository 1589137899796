import { Pipe, PipeTransform } from '@angular/core';

import { Requirement } from '../models/requirement.model';

@Pipe({
  name: 'getRequirementDetailsPath'
})
export class GetRequirementDetailsPathPipe implements PipeTransform {
  public transform(detailsRoute: string, requirement: Requirement): string {
    return `${detailsRoute}/${requirement.id}${requirement?.isCustom ? '' : `/version/${requirement.versionId}`}`;
  }
}

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

// Gutwin Shared Library
import { OfflineService, RouterService } from 'gutwin-shared';

// Services
import { AuditNavigationService } from '../../services/audit-navigation.service';

@Component({
  selector: 'gw-audits-navigation',
  templateUrl: './audits-navigation.component.html',
  styleUrls: ['./audits-navigation.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => *', [
        style({transform: 'translateX(-100%)'}),
        animate('0.3s ease-out')
      ]),
      transition('* => void', [
        animate('0.3s ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class AuditsNavigationComponent implements OnInit, OnDestroy {
  auditsTabs = {
    inProgress: {
      url: '/audits/in-progress',
      title: ''
    },
    drafts: {
      url: '/audits/drafts',
      title: ''
    },
    finished: {
      url: '/audits/finished',
      title: ''
    },
    templates: {
      url: '/audits/templates',
      title: ''
    }
  };
  auditsTabsArray = Array<any>();
  findingsTabs = {
    all: {
      url: '/findings',
      title: '',
      icon: 'gw-reference-documents',
      children: {
        inProgress: {
          url: '/findings/in-progress',
          title: ''
        },
        // TODO: [GW-654] Hide elements before MVP
        // overdue: {
        //   url: '/findings/overdue',
        //   title: ''
        // },
        notAssigned: {
          url: '/findings/not-assigned',
          title: ''
        },
        resolved: {
          url: '/findings/resolved',
          title: ''
        }
      }
    }
  };
  dashboardTabs = {
    all: {
      url: '/dashboard',
      title: '',
      icon: 'gw-analytics',
    }
  };
  findingsTabsArray = Array<any>();
  dashboardTabsArray = Array<any>();
  currentComponent: string;
  routerSubscription: Subscription;
  translateSubscription: Subscription;

  constructor(
    private router: Router,
    private routerService: RouterService,
    private translateService: TranslateService,
    public auditNavigationService: AuditNavigationService,
    public offlineService: OfflineService
  ) { }

  ngOnInit(): void {
    this.fetchTranslation();
    this.currentComponent = this.getCurrentComponent();
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentComponent = this.getCurrentComponent();
      }
    });
  }

  ngOnDestroy(): void {
    this.translateSubscription.unsubscribe();
  }

  toggle(): void {
    this.auditNavigationService.toggleNavigation();
  }

  isLinkActive(url: string): boolean {
    return this.routerService.isLinkActive(url);
  }

  getCurrentComponent(): string {
    for (const auditTab of this.auditsTabsArray) {
      if (this.routerService.currentPath === auditTab.url) {
        return 'audits';
      }
    }
    for (const findingTab of this.findingsTabsArray) {
      if (this.routerService.currentPath === findingTab.url) {
        return 'findings';
      }
      if (findingTab.children) {
        for (const findingChildTab of findingTab.children) {
          if (this.routerService.currentPath === findingChildTab.url) {
            return 'findings';
          }
        }
      }
    }
  }

  convertTabsToArray(tabs: any): Array<any> {
    const tabsArray = new Array<any>();
    for (const tabKey of Object.keys(tabs)) {
      const tab = Object.assign({}, tabs[tabKey]);
      if (tab.children) {
        tab.children = Object.assign({}, tab.children);
        tab.children = this.convertTabsToArray(tab.children);
      }
      tabsArray.push(tab);
    }
    return tabsArray;
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService.stream([
      'AUDITS_LIST.TAB.IN_PROGRESS',
      'AUDITS_LIST.TAB.DRAFTS',
      'AUDITS_LIST.TAB.FINISHED',
      'AUDITS_LIST.TAB.TEMPLATES',
      'FINDINGS_LIST.PAGE_HEADER',
      'FINDINGS_LIST.TAB.IN_PROGRESS',
      'FINDINGS_LIST.TAB.OVERDUE',
      'FINDINGS_LIST.TAB.NOT_ASSIGNED',
      'FINDINGS_LIST.TAB.RESOLVED',
      'DASHBOARD.PAGE_HEADER',
    ]).subscribe(translation => {
      this.auditsTabs.inProgress.title = translation['AUDITS_LIST.TAB.IN_PROGRESS'];
      this.auditsTabs.drafts.title = translation['AUDITS_LIST.TAB.DRAFTS'];
      this.auditsTabs.finished.title = translation['AUDITS_LIST.TAB.FINISHED'];
      this.auditsTabs.templates.title = translation['AUDITS_LIST.TAB.TEMPLATES'];
      this.findingsTabs.all.title = translation['FINDINGS_LIST.PAGE_HEADER'];
      this.findingsTabs.all.children.inProgress.title = translation['FINDINGS_LIST.TAB.IN_PROGRESS'];
      // TODO: [GW-654] Hide elements before MVP
      // this.findingsTabs.all.children.overdue.title = translation['FINDINGS_LIST.TAB.OVERDUE'];
      this.findingsTabs.all.children.notAssigned.title = translation['FINDINGS_LIST.TAB.NOT_ASSIGNED'];
      this.findingsTabs.all.children.resolved.title = translation['FINDINGS_LIST.TAB.RESOLVED'];
      this.dashboardTabs.all.title = translation['DASHBOARD.PAGE_HEADER'];

      this.auditsTabsArray = this.convertTabsToArray(this.auditsTabs);
      this.findingsTabsArray = this.convertTabsToArray(this.findingsTabs);
      this.dashboardTabsArray = this.convertTabsToArray(this.dashboardTabs);
    });
  }
}

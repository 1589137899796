import { LanguageCode } from '../../../../enums/language-code.enum';
import { ApiLanguage } from '../enums/language.enum';

export const convertLanguageCodeToGet = (language: ApiLanguage): LanguageCode => {
  const converter: Record<ApiLanguage, LanguageCode> = {
    [ApiLanguage.at]: LanguageCode.at,
    [ApiLanguage.de]: LanguageCode.de,
    [ApiLanguage.en]: LanguageCode.en
  };
  return converter[language];
};

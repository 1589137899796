export class FilterOption {
  id: number | string;
  name: string;
  isSelected: boolean;
  abbreviations?: Array<string>;

  constructor(option: Partial<FilterOption>) {
    this.id = option.id;
    this.name = option.name;
    this.isSelected = option.isSelected;
    this.abbreviations = option.abbreviations ? option.abbreviations : [];
  }
}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
// Models
import { Facility } from './../../shared/models/facility.model';
// Providers
import { FacilityService } from '../../shared/services/facility.service';

@Injectable()
export class FacilitiesResolve implements Resolve<any> {
  constructor(
    private facilityService: FacilityService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) { }

  resolve (route: ActivatedRouteSnapshot): Promise<Array<Facility>> {
    return this.facilityService.getFacilities()
      .catch(error => {
        this.fetchErrorTranslation()
          .then((translation: any) => {
            this.notificationsService.error(translation.title, translation.text);
          });
        return [];
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'ADMIN_SETTINGS.FACILITIES.ERROR.LOAD_FACILITIES'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['ADMIN_SETTINGS.FACILITIES.ERROR.LOAD_FACILITIES']
        };
      });
  }
}

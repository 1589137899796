// Models
import { Attachment } from './attachment.model';
import { Language } from './language.model';

export class Employee {
  id?: string;
  email: string;
  name: string;
  lastname: string;
  avatar?: Attachment;
  access?: boolean;
  language?: Language;
  password?: string;
  userId?: string;
  companyId?: string;

  constructor(employee?: Partial<Employee>) {
    this.id = employee ? employee.id : undefined;
    this.email = employee ? employee.email : undefined;
    this.name = employee ? employee.name : undefined;
    this.lastname = employee ? employee.lastname : undefined;
    this.avatar = employee ? employee.avatar : undefined;
    this.access = employee ? employee.access : undefined;
    this.language = employee ? employee.language : undefined;
    this.password = employee ? employee.password : undefined;
    this.userId = employee ? employee.userId : undefined;
    this.companyId = employee ? employee.companyId : undefined;
  }
}

import { Question } from './question.model';

export class Section {
  id: string;
  name: string;
  totalQuestions: number;
  questions: Array<Question>;
  archived: boolean;
  custom: boolean;
  added: boolean;
  addedToParent: boolean;

  _newName: string;
  _type = 'Section';

  constructor(section: any) {
    this.id = section.id;
    this.name = section.name;
    this.totalQuestions = section.totalQuestions;
    this.questions = this.generateQuestions(section.questions);
    this.archived = section.archived;
    this.custom = section.custom;
    this.added = section.added;
    this.addedToParent = section.addedToParent;
  }

  generateQuestions(questionsRequest: Array<any>): Array<Question> {
    const questions = new Array<Question>();
    if (questionsRequest) {
      questionsRequest.forEach(question => {
        questions.push(new Question(question));
      });
    }
    return questions;
  }

  get newName(): string {
    return this._newName !== undefined ? this._newName : this.name;
  }

  set newName(value: string) {
    this._newName = value;
  }

  countSelectedQuestions(): number {
    let amount = 0;
    if (this.questions) {
      this.questions.forEach(question => {
        amount += (question.added ? 1 : 0);
      });
    }
    return amount;
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import moment from 'moment';

// Gutwin Shared Library
import { Employee, ModalService } from 'gutwin-shared';

// Models
import { FacilityFilter } from '../../../shared/models/facility-filter.model';
import { FindingType } from '../../../shared/models/finding-type.model';
import { Finding, FindingStatus } from '../../../shared/models/finding.model';
import { Question } from '../../../shared/models/question.model';
import { Section } from '../../../shared/models/section.model';

// Components
import { SelectFacilityComponent } from '../../audit-creation/select-facility/select-facility.component';

// Services
import { FacilityService } from '../../../shared/services/facility.service';
import { FindingService } from '../../../shared/services/finding.service';
import { SectionService } from '../../../shared/services/section.service';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'gw-finding-view-details',
  templateUrl: './finding-view-details.component.html',
  styleUrls: ['./finding-view-details.component.scss']
})
export class FindingViewDetailsComponent implements OnInit {
  @ViewChild('problemControl') problemControl: ElementRef;
  @ViewChild('causeControl') causeControl: ElementRef;
  @ViewChild('solutionControl') solutionControl: ElementRef;
  @ViewChild('selectFacility') selectFacility: SelectFacilityComponent;
  @Input() finding: Finding;
  @Input() canManageFinding = false;
  @Input() canManageCauseAndSolution = false;
  @Output() findingUpdated = new EventEmitter();
  employees: Array<Employee>;
  facilities: Array<FacilityFilter>;
  findingTypes: Array<FindingType>;
  sections: Array<Section>;
  notify = {
    error: {
      connection: '',
      loadEmployees: '',
      loadFacilities: '',
      loadFindingTypes: '',
      updateFinding: ''
    },
    success: {
      updateFindingTitle: '',
      updateFindingText: ''
    }
  };
  editing = {
    findingType: false,
    question: false,
    problem: false,
    cause: false,
    solution: false,
    responsiblePerson: false
  };
  control = {};
  newDeadline: moment.Moment;
  newResponsiblePerson: Employee;
  newFindingType: FindingType;
  newQuestion: Question;
  nowDate = moment();

  constructor(
    private facilityService: FacilityService,
    private findingService: FindingService,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private sectionService: SectionService,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.fetchTranslation();
    this.initControls();
  }

  initControls(): void {
    this.control = {
      problem: this.problemControl ? this.problemControl.nativeElement : undefined,
      cause: this.causeControl ? this.causeControl.nativeElement : undefined,
      solution: this.solutionControl ? this.solutionControl.nativeElement : undefined
    };
  }

  getEmployees(): void {
    this.userService
      .getEmployees()
      .then((employees: Array<Employee>) => {
        this.employees = employees;
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connection, this.notify.error.loadEmployees);
      });
  }

  getFacilities(): void {
    this.facilityService
      .getFacilitiesForFilter()
      .then((facilities: Array<FacilityFilter>) => {
        this.facilities = facilities;
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connection, this.notify.error.loadFacilities);
      });
  }

  getFindingTypes(): void {
    this.findingService
      .getFindingTypes()
      .then((findingTypes: Array<FindingType>) => {
        this.findingTypes = findingTypes;
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connection, this.notify.error.loadFindingTypes);
      });
  }

  getSectionsWithQuestions(): void {
    this.sectionService
      .getSectionsWithQuestions(this.finding.relationships.auditedArea.id)
      .then((sections: Array<Section>) => {
        this.sections = sections;
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connection, this.notify.error.loadFindingTypes);
      });
  }

  toggleEdition(item: string): void {
    this.editing[item] = !this.editing[item];
    if (this.editing[item] && this.control[item]) {
      this.control[item].focus();
    }
  }

  cancelEdition(form: FormGroup, item: string): void {
    form.controls[item].setValue(this.finding[item]);
    this.editing[item] = false;
  }

  submitForm(form: FormGroup, item: string): void {
    if (form.valid) {
      if (form.value[item] !== this.finding[item]) {
        const updatedFinding = Object.assign({}, this.finding);
        updatedFinding[item] = form.value[item];
        this.updateFinding(updatedFinding, item);
      } else {
        this.editing[item] = false;
      }
    }
  }

  toggleDeadlineEdition(): void {
    this.newDeadline = undefined;
  }

  setDeadline(newDeadline: moment.Moment): void {
    this.newDeadline = newDeadline;
  }

  getDateForDatepicker(): moment.Moment {
    return this.newDeadline || this.finding.deadline || this.nowDate;
  }

  submitDeadline(): void {
    this.newDeadline = this.newDeadline || this.finding.deadline || this.nowDate;

    if (!this.newDeadline.isSame(this.finding.deadline, 'day') || !this.finding.deadline) {
      const updatedFinding = Object.assign({}, this.finding);
      updatedFinding.deadline = this.newDeadline;
      this.updateFinding(updatedFinding, 'deadline');
    } else {
      this.newDeadline = undefined;
    }
  }

  toggleResponsiblePersonEdition(): void {
    this.getEmployees();
    this.newResponsiblePerson = undefined;
    this.editing.responsiblePerson = !this.editing.responsiblePerson;
  }

  cancelResponsiblePersonEdition(): void {
    this.newResponsiblePerson = undefined;
    this.editing.responsiblePerson = false;
  }

  setResponsiblePerson(newEmployee: Employee): void {
    this.newResponsiblePerson = newEmployee;
  }

  submitResponsiblePerson(): void {
    if (
      this.newResponsiblePerson &&
      ((this.finding.relationships.employee &&
        this.newResponsiblePerson.id !== this.finding.relationships.employee.id) ||
        !this.finding.relationships.employee)
    ) {
      const updatedFinding = Object.assign({}, this.finding);
      const updatedFindingRelationships = Object.assign({}, this.finding.relationships);
      updatedFinding.relationships = updatedFindingRelationships;
      updatedFinding.relationships.employee = this.newResponsiblePerson;
      updatedFinding.status =
        updatedFinding.status === FindingStatus.notAssigned ? FindingStatus.inProgress : updatedFinding.status;
      this.updateFinding(updatedFinding, 'responsiblePerson');
    } else {
      this.editing.responsiblePerson = false;
    }
  }

  toggleFacilityEdition(): void {
    this.getFacilities();
    this.modalService.open('facilityModal');
  }

  toggleFindingTypeEdition(): void {
    this.getFindingTypes();
    this.newFindingType = undefined;
    this.editing.findingType = !this.editing.findingType;
  }

  cancelFindingTypeEdition(): void {
    this.newFindingType = undefined;
    this.editing.findingType = false;
  }

  setFindingType(newFindingType: FindingType): void {
    this.newFindingType = newFindingType;
  }

  submitFindingType(): void {
    if (this.newFindingType && this.finding.relationships.findingType.id !== this.newFindingType.id) {
      const updatedFinding = Object.assign({}, this.finding);
      const updatedFindingRelationships = Object.assign({}, this.finding.relationships);
      updatedFinding.relationships = updatedFindingRelationships;
      updatedFinding.relationships.findingType = this.newFindingType;
      this.updateFinding(updatedFinding, 'findingType');
    } else {
      this.editing.findingType = !this.editing.findingType;
    }
  }

  toggleQuestionEdition(): void {
    this.finding.relationships.findable['content'] = this.finding.relationships.question;
    this.getSectionsWithQuestions();
    this.newQuestion = undefined;
    this.editing.question = !this.editing.question;
  }

  cancelQuestionEdition(): void {
    this.newQuestion = undefined;
    this.editing.question = false;
  }

  setQuestion(newQuestion: Question): void {
    this.newQuestion = newQuestion;
  }

  submitQuestion(): void {
    if (this.newQuestion) {
      const updatedFinding = Object.assign({}, this.finding);
      const updatedFindingRelationships = Object.assign({}, this.finding.relationships);
      updatedFinding.relationships = updatedFindingRelationships;
      updatedFinding.relationships.findable = this.newQuestion;
      updatedFinding.relationships.question = this.newQuestion.content;
      this.updateFinding(updatedFinding, 'question');
    } else {
      this.editing.question = !this.editing.question;
    }
  }

  chooseFacility($event): void {
    // TODO: Waiting for BE.
  }

  updateFinding(updatedFinding: Finding, item: string): void {
    this.findingService
      .updateFinding(updatedFinding, this.finding)
      .then((newFinding: Finding) => {
        this.finding = newFinding;
        this.notificationsService.success(
          this.notify.success.updateFindingTitle,
          this.notify.success.updateFindingText
        );
        this.editing[item] = false;
        this.findingUpdated.emit(this.finding);
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connection, this.notify.error.updateFinding);
      });
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ERROR.LOAD_EMPLOYEES',
        'FINDING_VIEW.DETAIL.ERROR.LOAD_FACILITIES',
        'FINDING_VIEW.DETAIL.ERROR.UPDATE_FINDING',
        'FINDING_VIEW.DETAIL.ERROR.LOAD_FINDING_TYPES',
        'FINDING_VIEW.DETAIL.SUCCESS.UPDATE_FINDING_TITLE',
        'FINDING_VIEW.DETAIL.SUCCESS.UPDATE_FINDING_TEXT'
      ])
      .toPromise();
    this.notify.error.connection = translation['GLOBAL.ERROR.CONNECTION'];
    this.notify.error.loadEmployees = translation['GLOBAL.ERROR.LOAD_EMPLOYEES'];
    this.notify.error.loadFacilities = translation['FINDING_VIEW.DETAIL.ERROR.LOAD_FACILITIES'];
    this.notify.error.loadFindingTypes = translation['FINDING_VIEW.DETAIL.ERROR.LOAD_FINDING_TYPES'];
    this.notify.error.updateFinding = translation['FINDING_VIEW.DETAIL.ERROR.UPDATE_FINDING'];
    this.notify.success.updateFindingTitle = translation['FINDING_VIEW.DETAIL.SUCCESS.UPDATE_FINDING_TITLE'];
    this.notify.success.updateFindingText = translation['FINDING_VIEW.DETAIL.SUCCESS.UPDATE_FINDING_TEXT'];
  }
}

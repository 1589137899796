import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subject } from 'rxjs';

import { Product } from '../../models/product.model';

@Component({
  selector: 'gw-edit-products',
  templateUrl: './edit-products.component.html',
  styleUrls: ['./edit-products.component.scss'],
  exportAs: 'gwEditProducts'
})
export class EditProductsComponent implements OnInit, OnDestroy {
  @Input() set chosenProducts(chosenProducts: Array<Product>) {
    this._chosenProducts = chosenProducts;
    this.resetForm();
  }
  get chosenProducts(): Array<Product> {
    return this._chosenProducts;
  }
  @Input() allProducts: Array<Product>;
  @Input() isEditMode: boolean;
  @Input() alignPopoverRight = true;
  @Input() buttonPlaceholder = false;
  @Input() isUserTypeClient = false;
  @Output() submit = new EventEmitter<Product[]>();
  @Output() increaseOpenedEdits = new EventEmitter<void>();
  @Output() decreaseOpenedEdits = new EventEmitter<void>();

  form: FormGroup;
  _chosenProducts: Array<Product>;
  submitted = false;
  destroy$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  async ngOnInit(): Promise<void> {
    this.initForm();
    this.resetForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      product: ['', Validators.required]
    });
  }

  resetForm(): void {
    if (this.form) {
      this.form.controls['product'].setValue(this.chosenProducts || '');
    }
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  submitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.submit.emit(this.form.value.product);
    }
  }

  onIncreaseOpenedEdits(): void {
    this.increaseOpenedEdits.emit();
  }

  onDecreaseOpenedEdits(): void {
    this.decreaseOpenedEdits.emit();
  }
}

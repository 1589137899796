import moment from 'moment';
import * as _ from 'lodash';

import { Employee } from 'gutwin-shared';

import { AuditType } from './audit-type.model';
import { AuditedArea } from './audited-area.model';
import { Questionnaire } from './questionnaire.model';
import { Section } from './section.model';
import { RatingScale } from './rating-scale.model';

import { EmployeesGroups } from '../interfaces/employeesGroups.interface';

export const AUDIT_TYPE = 'Audit';

export enum AuditState {
  inProgress = 'in_progress',
  draft = 'draft',
  finished = 'finished'
}

export class ModifiedReport {
  name: string;
  url: string;
  file: File;
  destroy: boolean;

  constructor(modifiedReport: any) {
    this.name = modifiedReport.name;
    this.url = modifiedReport.url;
    this.file = modifiedReport.file;
    this.destroy = modifiedReport.destroy || false;
  }
}

export class Audit {
  id: string;
  status: number;
  state: AuditState;
  name: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
  facility: any;
  type: AuditType;
  summary: string;
  summaryOffline: string;
  employees: EmployeesGroups;
  auditedAreas: Array<AuditedArea>;
  questionnaires: Array<Questionnaire>;
  selectedSections: Array<Section>;
  selectedQuestionsAmount: number;
  customSections: Array<Section>;
  modifiedReport: ModifiedReport;
  offline: boolean;
  offlineDate: moment.Moment;
  disabled: boolean;
  isTemplate: boolean;
  ratingScale: RatingScale;

  readonly _type = AUDIT_TYPE;

  constructor(audit: any) {
    this.id = audit.id;
    this.status = audit.status;
    this.state = audit.state;
    this.name = audit.name;
    this.startTime = moment(audit.startTime);
    this.endTime = moment(audit.endTime);
    this.facility = audit.facility;
    this.summary = audit.summary;
    this.summaryOffline = audit.summaryOffline;
    this.employees = this.generateEmployees(audit.employees);
    if (audit.type) {
      this.type = new AuditType(audit.type);
    }
    if (audit.auditedAreas) {
      this.auditedAreas = this.generateAuditedAreas(audit.auditedAreas);
    }
    this.questionnaires = audit.questionnaires;
    this.selectedSections = audit.selectedSections;
    this.selectedQuestionsAmount = audit.selectedQuestionsAmount || this.countSelectedQuestions();
    this.customSections = audit.customSections;
    this.modifiedReport = audit.modifiedReport;
    this.offline = audit.offline;
    this.offlineDate = audit.offlineDate;
    this.disabled = audit.disabled;
    this.isTemplate = audit.isTemplate;
    this.ratingScale = audit.ratingScale;
  }

  generateEmployees(employees: EmployeesGroups): EmployeesGroups {
    const newEmployees = {
      creator: new Array<Employee>(),
      organizer: new Array<Employee>(),
      auditor: new Array<Employee>(),
      leadAuditor: new Array<Employee>(),
      coAuditor: new Array<Employee>(),
      auditee: new Array<Employee>()
    };
    for (const role in employees) {
      let formatedRole = _.camelCase(role);
      if (employees.hasOwnProperty(role)) {
        employees[role].forEach(employee => {
          newEmployees[formatedRole].push(new Employee(employee));
        });
      }
    }
    return newEmployees;
  }

  generateAuditedAreas(auditedAreas: Array<any>): Array<AuditedArea> {
    const newAuditedAreas = new Array<AuditedArea>();
    auditedAreas.forEach(auditedArea => {
      newAuditedAreas.push(new AuditedArea(auditedArea));
    });
    return newAuditedAreas;
  }

  countSelectedQuestions(): number {
    let amount = 0;
    if (this.selectedSections) {
      this.selectedSections.forEach(section => {
        amount += section.totalQuestions;
      });
    } else if (this.questionnaires) {
      this.questionnaires.forEach(questionnaire => {
        amount += questionnaire.totalQuestionsSelected;
      });
    }
    return amount;
  }
}

import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';

import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

import { Filters } from '../modules/session/shared/models/filters.model';

import { RouterService } from './router.service';

import { HelperUtil } from '../utils/helper.util';

import { LIMIT } from '../constants/pagination.constants';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  filters: any;
  page: number;
  limit = LIMIT;
  filterSource = new Subject<any>();
  filterObservable: Observable<any> = this.filterSource.asObservable();

  constructor(
    protected ngZone: NgZone,
    protected route: ActivatedRoute,
    protected router: Router,
    protected routerService: RouterService
  ) {
    this.observeQueryParams();
  }

  observeQueryParams(): void {
    this.route.queryParams.subscribe((params: Params) => {
      const convertedParams = this.getQueryParams(params);
      this.setFilters(convertedParams.page, convertedParams.filters, convertedParams.limit, false);
    });
  }

  getQueryParams(params: Params): { page: number; limit: number; filters: any } {
    const filters = this.createFilters(params);
    const page = params['page'] && params['page'] > 0 ? parseInt(params['page'], 10) - 1 : 0;
    const limit = params['limit'] ? parseInt(params['limit'], 10) : LIMIT;

    return { page, limit, filters };
  }

  createFilters(params: Params): any {
    return {
      component: params['component']
    };
  }

  isAnyFilter(filtersToSkip: Array<string> = []): boolean {
    for (const filter in this.filters) {
      if (this.filters.hasOwnProperty(filter) && filter !== 'component' && !~filtersToSkip.indexOf(filter)) {
        if (!_.isEmpty(this.filters[filter])) {
          return true;
        }
      }
    }
    return false;
  }

  buildUrl(
    component?: string,
    filters = this.filters,
    pagination = { page: this.page, limit: this.limit }
  ): NavigationExtras {
    const navigationExtras: NavigationExtras = {
      queryParams: {}
    };
    this.addPageToQueryParams(navigationExtras.queryParams, pagination);
    if (component) this.addFilterToQueryParams(navigationExtras.queryParams, 'component', false, { component });
    return navigationExtras;
  }

  addFilterToQueryParams(queryParams: Params, param: string, isNumber?: boolean, filters = this.filters): void {
    if (filters && !HelperUtil.isNullable(filters[param]) && (Object.keys(filters[param]).length || isNumber)) {
      const toJson = typeof filters[param] === 'object';
      const filterValue = toJson ? JSON.stringify(filters[param]) : filters[param];
      queryParams[param] = filterValue;
    }
  }

  addObjectToQueryParams(queryParams: Params, param: string, filters = this.filters): void {
    if (filters && filters[param] && Object.keys(filters[param]).length) {
      for (const key in filters[param]) {
        if (filters[param].hasOwnProperty(key)) {
          const toJson = typeof filters[param][key] === 'object';
          const filterValue = toJson ? JSON.stringify(filters[param][key]) : filters[param][key];
          queryParams[`${param}-${key}`] = filterValue;
        }
      }
    }
  }

  addPageToQueryParams(queryParams: Params, pagination = { page: this.page, limit: this.limit }): void {
    if (pagination.page !== undefined) {
      queryParams['page'] = pagination.page + 1;
    }
    if (pagination.limit !== undefined) {
      queryParams['limit'] = pagination.limit;
    }
  }

  setUrl(component?: string, filters = this.filters, pagination = { page: this.page, limit: this.limit }): void {
    const path = this.routerService.getCurrentPath();
    this.ngZone.run(() => this.router.navigate([path], this.buildUrl(component, filters, pagination))).then();
  }

  setFilter(key: string, value: any, changeUrl = true, component?: string): void {
    this.filters = this.filters ? this.filters : new Filters();
    if (component && this.filters.component !== component) {
      this.filters.component = component;
    }
    this.filters[key] = value;
    this.page = 0;
    this.filterSource.next({ page: 0, filters: this.filters });
    if (changeUrl) {
      this.setUrl(component);
    }
  }

  setFilters(page: number, filters?: any, limit?: number, changeUrl = true): void {
    this.page = page;
    if (limit) {
      this.limit = limit;
    }
    if (filters) {
      this.filters = filters;
    }
    this.filterSource.next({ page: this.page, limit: this.limit, filters: this.filters });
    if (changeUrl) {
      this.setUrl(this.filters?.component);
    }
  }

  setPage(page: number): void {
    this.setFilters(page);
  }

  setLimit(limit: number): void {
    this.setFilters(0, undefined, limit);
  }
}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
// Models
import { AuditType } from './../../shared/models/audit-type.model';
// Providers
import { AuditTypeService } from '../../shared/services/audit-type.service';

@Injectable()
export class AuditTypesResolve implements Resolve<any> {
  constructor(
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private auditTypeService: AuditTypeService
  ) { }

  resolve (route: ActivatedRouteSnapshot): Promise<Array<AuditType>> {
    return this.auditTypeService.getAuditTypes()
      .catch(error => {
        this.fetchErrorTranslation()
          .then((translation: any) => {
            this.notificationsService.error(translation.title, translation.text);
          });
        return [];
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ERROR.LOAD_AUDIT_TYPES'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['GLOBAL.ERROR.LOAD_AUDIT_TYPES']
        };
      });
  }
}

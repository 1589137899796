import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
// Models
import { Facility } from './../../../../shared/models/facility.model';
// Services
import { FacilityService } from './../../../../shared/services/facility.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gw-add-facility',
  templateUrl: './add-facility.component.html',
  styleUrls: ['./add-facility.component.scss'],
  exportAs: 'gwAddFacility'
})
export class AddFacilityComponent implements OnInit, OnDestroy {
  @Input() facility: Facility;
  @Input() parent: Facility;
  addFacilityForm: FormGroup;
  addAnother = false;
  submitted = false;
  translateSubscription: Subscription;
  errors = {
    connectionTitle: '',
    createFacility: '',
    updateFacility: ''
  };
  success = {
    createFacilityTitle: '',
    createFacilityText: '',
    updateFacilityTitle: '',
    updateFacilityText: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private facilityService: FacilityService
  ) { }

  ngOnInit() {
    this.fetchTranslation();
    this.initForm();
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'ADMIN_SETTINGS.FACILITIES.ERROR.CREATE_FACILITY',
      'ADMIN_SETTINGS.FACILITIES.ERROR.UPDATE_FACILITY',
      'ADMIN_SETTINGS.FACILITIES.SUCCESS.CREATE_FACILITY_TITLE',
      'ADMIN_SETTINGS.FACILITIES.SUCCESS.CREATE_FACILITY_TEXT',
      'ADMIN_SETTINGS.FACILITIES.SUCCESS.UPDATE_FACILITY_TITLE',
      'ADMIN_SETTINGS.FACILITIES.SUCCESS.UPDATE_FACILITY_TEXT'
      ])
      .subscribe((translation: any) => {
        this.errors.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.errors.createFacility = translation['ADMIN_SETTINGS.FACILITIES.ERROR.CREATE_FACILITY'];
        this.errors.updateFacility = translation['ADMIN_SETTINGS.FACILITIES.ERROR.UPDATE_FACILITY'];
        this.success.createFacilityTitle = translation['ADMIN_SETTINGS.FACILITIES.SUCCESS.CREATE_FACILITY_TITLE'];
        this.success.createFacilityText = translation['ADMIN_SETTINGS.FACILITIES.SUCCESS.CREATE_FACILITY_TEXT'];
        this.success.updateFacilityTitle = translation['ADMIN_SETTINGS.FACILITIES.SUCCESS.UPDATE_FACILITY_TITLE'];
        this.success.updateFacilityText = translation['ADMIN_SETTINGS.FACILITIES.SUCCESS.UPDATE_FACILITY_TEXT'];
    });
  }

  initForm(): void {
    this.addFacilityForm = this.formBuilder.group({
      name: [this.facility ? this.facility.name : '', Validators.required],
    });
  }

  resetForm(): void {
    this.setControlValue(this.facility ? this.facility.name : '', 'name');
  }

  clearComponent(): void {
    this.facility = undefined;
    this.resetForm();
  }

  setControlValue(data: any, key: string): void {
    this.addFacilityForm.controls[key].setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  createFacility(): Promise<any> {
    const facilityToCreate = new Facility(this.addFacilityForm.value);
    facilityToCreate.parentFacility = this.parent;
    return this.facilityService.createFacility(facilityToCreate)
      .then(facility => {
        this.notificationsService.success(this.success.createFacilityTitle, this.success.createFacilityText);
        facility.parentFacility = this.parent;
        return {
          status: 'create',
          facility
        };
      })
      .catch(error => {
        this.notificationsService.error(this.errors.connectionTitle, this.errors.createFacility);
        throw error;
      });
  }

  updateFacility(): Promise<any> {
    const facilityToUpdate = new Facility(this.addFacilityForm.value);
    facilityToUpdate.id = this.facility.id;
    return this.facilityService.updateFacility(facilityToUpdate)
      .then(facility => {
        this.notificationsService.success(this.success.updateFacilityTitle, this.success.updateFacilityText);
        return {
          status: 'update',
          facility
        };
      })
      .catch(error => {
        this.notificationsService.error(this.errors.connectionTitle, this.errors.updateFacility);
        throw error;
      });
  }

  submitAddFacilityForm(): Promise<any> {
    this.submitted = true;
    if (this.addFacilityForm.valid) {
      let facilityPromise: Promise<any>;
      if (this.facility) {
        facilityPromise = this.updateFacility();
      } else {
        facilityPromise = this.createFacility();
      }
      return facilityPromise.then(response => {
        this.submitted = false;
        this.addAnother ? this.clearComponent() : this.resetForm();
        return response;
      });
    } else {
      return new Promise((resolve, reject) => reject({ invalid: true }));
    }
  }
}

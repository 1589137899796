
<div class="import-reference-document">
  <div class="import-reference-document__description" [innerHTML]="'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.IMPORT_REFERENCE_DOCUMENT.DESCRIPTION' | translate"></div>
  <div class="video import-reference-document__video" [class.video--playing]="isVideoPlaying">
    <button class="video__button video__button--play" *ngIf="!isVideoPlaying" type="button" (click)="playVideo()"><i class="gw-play video__button-icon"></i></button>
    <button class="video__button video__button--pause" *ngIf="isVideoPlaying" type="button" (click)="pauseVideo()"><i class="gw-pause video__button-icon"></i></button>
    <video class="video__player" #videoPlayer="" [attr.controls]="isVideoEnded ? null : true" (play)="onVideoPlay()" (pause)="onVideoPause()" (ended)="onVideoEnd()">
      <source [src]="videoSource" type="video/mp4"/>
    </video>
  </div>
</div>
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gw-activity-text',
  templateUrl: './activity-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityTextComponent {
  @Input() activity?: any; // CommentActivity | StatusActivity
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DialogOverlayRef } from '../overlay/dialog-overlay-ref';

@Component({
  selector: 'gw-duplications-modal',
  templateUrl: './duplications-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DuplicationsModalComponent {
  constructor(public dialogOverlayRef: DialogOverlayRef) {}

  close(): void {
    this.dialogOverlayRef.close();
  }
}

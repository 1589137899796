import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogOverlayRef } from '../overlay/dialog-overlay-ref';

import { UserRolesModalData, UserRolesModalResponse } from '../../interfaces/user-roles-modal.interface';

import { UserRolesFormComponent } from '../user-roles-form/user-roles-form.component';

import { OverlayService } from '../../services/overlay.service';

@Component({
  selector: 'gw-user-roles-modal',
  templateUrl: './user-roles-modal.component.html',
  styleUrls: ['./user-roles-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRolesModalComponent implements OnInit, OnDestroy {
  @ViewChild('userRolesForm', { static: true }) userRolesForm: UserRolesFormComponent;

  showMultiselect?: boolean;
  savingUserRoles = false;
  isEditMode = false;
  savedResponse: UserRolesModalResponse;

  destroy$ = new Subject<void>();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private overlayService: OverlayService,
    public dialogOverlayRef: DialogOverlayRef<UserRolesModalResponse, UserRolesModalData, UserRolesModalResponse>
  ) {}

  ngOnInit(): void {
    this.setEditMode();
    this.observeConfirmingSavingUser();
    this.observeDiscardingSavingUser();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  async submitUserRolesForm(): Promise<void> {
    this.savingUserRoles = true;
    await this.userRolesForm
      .submitRolesForm()
      .then((response: UserRolesModalResponse) => {
        if (this.dialogOverlayRef.data.standalone) {
          this.submit(response);
          this.savedResponse = response;
          this.changeDetector.detectChanges();
        } else {
          this.close(response);
        }
      })
      .catch(() => {
        this.savingUserRoles = false;
        this.changeDetector.detectChanges();
      });
  }

  observeConfirmingSavingUser(): void {
    this.overlayService.confirm$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.savingUserRoles = false;
      this.close(this.savedResponse);
    });
  }

  observeDiscardingSavingUser(): void {
    this.overlayService.discard$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.savingUserRoles = false;
      this.changeDetector.detectChanges();
    });
  }

  setEditMode(): void {
    this.isEditMode = !!this.dialogOverlayRef.data.employee;
  }

  submit(response: UserRolesModalResponse): void {
    this.dialogOverlayRef.submit(response);
  }

  close(response?: UserRolesModalResponse): void {
    this.dialogOverlayRef.close(response);
  }

  onHideModal(): void {
    this.dialogOverlayRef.hide();
  }

  onShowModal(): void {
    this.dialogOverlayRef.show();
  }
}

import { FullAttachment } from '../interfaces/full-attachment.interface';

export class LegalCategory {
  id: string;
  name: string;
  children?: Array<LegalCategory>;
  archived?: boolean;
  color?: string;
  backgroundImage?: FullAttachment;
  icon?: FullAttachment;
  description?: string;

  constructor(legalCategory: Partial<LegalCategory>) {
    Object.assign(this, legalCategory);
  }
}

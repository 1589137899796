import { Component, Input, OnInit } from '@angular/core';

import { SimpleDropdownDirective } from 'gutwin-shared';

import { FilterComponent } from '@gutwin-audit/shared/enums/filter-component.enum';

import { FilterModuleService } from '../../services/filter-module.service';

@Component({
  selector: 'gw-auditor-filter',
  templateUrl: './auditor-filter.component.html',
  styleUrls: ['./auditor-filter.component.scss'],
  exportAs: 'auditorFilter'
})
export class AuditorFilterComponent implements OnInit {
  @Input() dropdown: SimpleDropdownDirective;
  @Input() dataCollection: Array<any>;
  @Input() display: Array<string>;
  @Input() component: FilterComponent;
  @Input() splitter = ' ';
  filteredDataCollection = [];
  filterSearchQuery: string;
  asLeadAuditorSelected: boolean;
  asCoAuditorSelected: boolean;

  constructor(private filterService: FilterModuleService) {}

  ngOnInit() {
    this.filterItems('');
    this.selectChoosenFilters();
  }

  selectChoosenFilters(filters = this.filterService.auditsFilters): void {
    if (filters) {
      if (filters.leadAuditors && filters.coAuditors) {
        this.asLeadAuditorSelected = true;
        this.asCoAuditorSelected = true;
        this.selectCommonData(filters.leadAuditors, this.dataCollection);
      } else if (filters.leadAuditors) {
        this.asLeadAuditorSelected = true;
        this.selectCommonData(filters.leadAuditors, this.dataCollection);
      } else if (filters.coAuditors) {
        this.asCoAuditorSelected = true;
        this.selectCommonData(filters.coAuditors, this.dataCollection);
      } else {
        this.deselectAll(this.dataCollection);
      }
    }
  }

  selectCommonData(filters, dataCollection): void {
    for (const data of dataCollection) {
      let selected = false;
      for (const id of filters) {
        if (id === data.id) {
          selected = true;
          break;
        }
      }
      data.selected = selected;
    }
  }

  deselectAll(dataCollection): void {
    dataCollection.forEach(element => {
      element.selected = false;
    });
  }

  filterItems(query: string): void {
    if (this.dataCollection) {
      query = query.toLowerCase();
      this.filteredDataCollection = this.dataCollection.filter((item: any) => {
        let searchKey = '';
        for (const key of this.display) {
          searchKey = searchKey.concat(item[key], ' ');
        }
        searchKey = searchKey.toLowerCase();
        return ~searchKey.indexOf(query);
      });
    }
  }

  applyFilter(): void {
    const filterIds = new Array<string>();
    this.dataCollection.forEach((item: any) => {
      if (item.selected) {
        filterIds.push(item.id);
      }
    });

    if (this.asLeadAuditorSelected === this.asCoAuditorSelected) {
      this.filterService.setFilter('leadAuditors', filterIds, true, this.component);
      this.filterService.setFilter('coAuditors', filterIds, true, this.component);
    } else if (this.asLeadAuditorSelected) {
      this.filterService.setFilter('leadAuditors', filterIds, true, this.component);
      this.filterService.setFilter('coAuditors', [], true, this.component);
    } else if (this.asCoAuditorSelected) {
      this.filterService.setFilter('leadAuditors', [], true, this.component);
      this.filterService.setFilter('coAuditors', filterIds, true, this.component);
    }
    this.dropdown.closeDropdown();
  }
}


<div class="filters">
  <div class="row">
    <div class="columns filters__content">
      <div class="dropdown filter-dropdown" gwSimpleDropdown="gwSimpleDropdown" #searchDropdown="simpleDropdown" [class.filter-dropdown--opened]="searchDropdown.opened" [class.filter-dropdown--closed]="!searchDropdown.opened &amp;&amp; !searchDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!searchDropdown.openedWithDelay" [class.filter-dropdown__content--overflow]="searchDropdown.isHorizontalOverflow">
          <gw-search-filter *ngIf="searchDropdown.openedWithDelay" #findingSearchFilter="findingSearchFilter" [dropdown]="searchDropdown"></gw-search-filter>
        </div>
        <button class="filter-dropdown__button" type="button"><span class="filter-dropdown__button-text">{{ 'FINDINGS_LIST.FILTER.SEARCH' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
      <div class="dropdown filter-dropdown" gwSimpleDropdown="gwSimpleDropdown" #dueDateDropdown="simpleDropdown" [class.filter-dropdown--opened]="dueDateDropdown.opened" [class.filter-dropdown--closed]="!dueDateDropdown.opened &amp;&amp; !dueDateDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!dueDateDropdown.openedWithDelay" [class.filter-dropdown__content--overflow]="dueDateDropdown.isRightOverflow">
          <gw-date-filter *ngIf="dueDateDropdown.openedWithDelay" [dropdown]="dueDateDropdown" [component]="'findings'"></gw-date-filter>
        </div>
        <button class="filter-dropdown__button" type="button"><span class="filter-dropdown__button-text">{{ 'FINDINGS_LIST.FILTER.DUE_DATE' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
      <div class="dropdown filter-dropdown" gwSimpleDropdown="gwSimpleDropdown" #findingTypeDropdown="simpleDropdown" [class.filter-dropdown--opened]="findingTypeDropdown.opened" [class.filter-dropdown--closed]="!findingTypeDropdown.opened &amp;&amp; !findingTypeDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!findingTypeDropdown.openedWithDelay" [class.filter-dropdown__content--overflow]="findingTypeDropdown.isRightOverflow">
          <gw-select-filter *ngIf="findingTypeDropdown.openedWithDelay &amp;&amp; findingTypes" [dropdown]="findingTypeDropdown" [dataCollection]="findingTypes" [display]="['name']" [filterName]="'types'" [component]="'findings'"></gw-select-filter>
        </div>
        <button class="filter-dropdown__button" type="button" (click)="fetchFindingTypes(true)"><span class="filter-dropdown__button-text">{{ 'FINDINGS_LIST.FILTER.TYPE' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
      <div class="dropdown filter-dropdown" gwSimpleDropdown="gwSimpleDropdown" #responsiblePersonDropdown="simpleDropdown" [class.filter-dropdown--opened]="responsiblePersonDropdown.opened" [class.filter-dropdown--closed]="!responsiblePersonDropdown.opened &amp;&amp; !responsiblePersonDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!responsiblePersonDropdown.openedWithDelay" [class.filter-dropdown__content--overflow]="responsiblePersonDropdown.isRightOverflow">
          <gw-select-filter *ngIf="responsiblePersonDropdown.openedWithDelay &amp;&amp; employees" [dropdown]="responsiblePersonDropdown" [dataCollection]="employees" [display]="['lastname', 'name']" [splitter]="[', ']" [filterName]="'responsiblePersons'" [component]="'findings'"></gw-select-filter>
        </div>
        <button class="filter-dropdown__button" type="button" (click)="fetchEmployees(true)"><span class="filter-dropdown__button-text">{{ 'FINDINGS_LIST.FILTER.RESPONSIBLE_PERSON' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
      <div class="dropdown filter-dropdown" gwSimpleDropdown="gwSimpleDropdown" #auditAreaDropdown="simpleDropdown" [class.filter-dropdown--opened]="auditAreaDropdown.opened" [class.filter-dropdown--closed]="!auditAreaDropdown.opened &amp;&amp; !auditAreaDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!auditAreaDropdown.openedWithDelay" [class.filter-dropdown__content--overflow]="auditAreaDropdown.isRightOverflow">
          <gw-area-filter *ngIf="auditAreaDropdown.openedWithDelay" #auditAreaFilters="auditAreaFilters" [dropdown]="auditAreaDropdown" [facilities]="facilities" [component]="'findings'"></gw-area-filter>
        </div>
        <button class="filter-dropdown__button" type="button" (click)="fetchFacilities(true)"><span class="filter-dropdown__button-text">{{ 'AUDITS_LIST.FILTER.AUDITED_AREA' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
      <div class="dropdown filter-dropdown" gwSimpleDropdown="gwSimpleDropdown" #auditTypeDropdown="simpleDropdown" [class.filter-dropdown--opened]="auditTypeDropdown.opened" [class.filter-dropdown--closed]="!auditTypeDropdown.opened &amp;&amp; !auditTypeDropdown.firstDrop">
        <div class="dropdown__content filter-dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!auditTypeDropdown.openedWithDelay" [class.filter-dropdown__content--overflow]="auditTypeDropdown.isHorizontalOverflow">
          <gw-select-filter *ngIf="auditTypeDropdown.openedWithDelay &amp;&amp; auditTypes" [dropdown]="auditTypeDropdown" [dataCollection]="auditTypes" [display]="['title']" [filterName]="'auditTypes'" [component]="'findings'"></gw-select-filter>
        </div>
        <button class="filter-dropdown__button" type="button" (click)="fetchAuditTypes(true)"><span class="filter-dropdown__button-text">{{ 'FINDINGS_LIST.FILTER.AUDIT_TYPE' | translate }}</span><i class="gw-angle--down filter-dropdown__caret"></i></button>
      </div>
    </div>
  </div>
</div>
<div class="chosen-filters" *ngIf="hasFilters">
  <div class="row">
    <div class="columns"><span class="chosen-filters__label">{{ 'FINDINGS_LIST.FILTER.FILTER_BY' | translate }}:</span><span class="chosen-filters__filter" *ngIf="assignedFilters.search">{{ 'FINDINGS_LIST.FILTER.SEARCH' | translate }}:&nbsp;<span class="chosen-filter__value">{{ assignedFilters.search }}</span>
        <button class="chosen-filter__action" type="button" (click)="removeSearchFilter()">⨯</button></span><span class="chosen-filters__filter" *ngIf="assignedFilters.dateFrom &amp;&amp; assignedFilters.dateTo">{{ 'FINDINGS_LIST.FILTER.DUE_DATE' | translate }}:&nbsp;<span class="chosen-filter__value">{{ assignedFilters.dateFrom | dateFormat: 'D MMMM YYYY' }} – {{ assignedFilters.dateTo | dateFormat: 'D MMMM YYYY' }}</span>
        <button class="chosen-filter__action" type="button" (click)="removeDateFilter()">⨯</button></span><span class="chosen-filters__filter" *ngFor="let type of assignedFindingTypes; let i = index">{{ 'FINDINGS_LIST.FILTER.TYPE' | translate }}:&nbsp;<span class="chosen-filter__value">{{ type?.name }}</span>
        <button class="chosen-filter__action" type="button" (click)="deselectType(i)">⨯</button></span><span class="chosen-filters__filter" *ngFor="let responsiblePerson of assignedResponsiblePersons; let i = index">{{ 'FINDINGS_LIST.FILTER.RESPONSIBLE_PERSON' | translate }}:&nbsp;<span class="chosen-filter__value">{{ responsiblePerson.lastname }}, {{ responsiblePerson.name }}</span>
        <button class="chosen-filter__action" type="button" (click)="deselectResponsiblePerson(i)">⨯</button></span><span class="chosen-filters__filter" *ngFor="let facility of assignedFacilities; let i = index">{{ 'AUDITS_LIST.FILTER.AREA_FILTER.FACILITY' | translate }}:&nbsp;<span class="chosen-filter__value">{{ facility.name }}
          <ng-container *ngIf="assignedFilters.facilities.subFolders">&nbsp;({{ 'GLOBAL.LABEL.FACILITY_WITH_SUBFOLDERS' | translate }})</ng-container></span>
        <button class="chosen-filter__action" type="button" (click)="deselectFacility(i)">⨯</button></span><span class="chosen-filters__filter" *ngFor="let type of assignedAuditTypes; let i = index">{{ 'FINDINGS_LIST.FILTER.AUDIT_TYPE' | translate }}:&nbsp;<span class="chosen-filter__value">{{ type?.title }}</span>
        <button class="chosen-filter__action" type="button" (click)="deselectAuditType(i)">⨯</button></span></div>
  </div>
</div>
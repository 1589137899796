import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
// Models
import { Finding } from './../../shared/models/finding.model';
// Providers
import { FindingService } from './../../shared/services/finding.service';
import { PageStatusService } from './../../shared/services/page-status.service';

@Injectable()
export class FindingResolve implements Resolve<Finding> {
  constructor(
    private findingService: FindingService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private pageStatusService: PageStatusService
  ) { }

  resolve (route: ActivatedRouteSnapshot): Promise<Finding> {
    return this.findingService.getFinding(route.params['id'])
      .catch(async(error) => {
        switch (error.status) {
          case 403:
            const translation403 = await this.fetchMessage403Translation();
            this.pageStatusService.goTo403(translation403);
            break;
          case 404:
            const translation404 = await this.fetchMessage404Translation();
            this.pageStatusService.goTo404(translation404);
            break;
        }
        this.fetchErrorTranslation()
          .then((translation: any) => {
            this.notificationsService.error(translation.title, translation.text);
          });
        return undefined;
      });
  }

  fetchMessage404Translation(): Promise<string> {
    return this.translateService.get('GLOBAL.ERROR.NOT_FOUND.FINDING')
      .toPromise()
      .then((translation: string) => {
        return translation;
      });
  }

  fetchMessage403Translation(): Promise<string> {
    return this.translateService.get('GLOBAL.ERROR.NOT_PERMITTED.FINDING')
      .toPromise()
      .then((translation: string) => {
        return translation;
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ERROR.LOAD_FINDING'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['GLOBAL.ERROR.LOAD_FINDING']
        };
      });
  }
}

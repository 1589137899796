import moment from 'moment';

import { StatusAssigned } from '../models/status.model';
import { AttachmentLC } from './attachment-lc.model';
import { CommentActivity } from './comment-activity.model';
import { DefaultList } from './default-list.model';
import { Employee } from './employee.model';
import { ExtraFieldSimple } from './extra-field-simple.model';
import { LegalOrigin } from './legal-origin.model';
import { LegalType } from './legal-type.model';
import { Link } from './link.model';
import { Product } from './product.model';

import { NestedElementsToPreview } from '../interfaces/nested-elements-to-preview.interface';
import { Validity } from '../interfaces/validity.interface';

export type LAW_TYPE = 'Law';

export class SharedLaw {
  readonly _type: LAW_TYPE = 'Law';

  attachments?: Array<AttachmentLC>;
  deadline: moment.Moment | '';
  definitions: string;
  description: string;
  displayName: string;
  employee: Employee;
  extraFields?: DefaultList<ExtraFieldSimple>;
  fullname: string;
  id: string;
  inactive?: boolean;
  lastComment?: CommentActivity;
  lastUpdate: moment.Moment;
  lawScope: string;
  legalCategories: NestedElementsToPreview;
  legalOrigin: LegalOrigin;
  legalType: LegalType;
  links?: Array<Link>;
  number: string;
  products: Array<Product>;
  remark: string;
  status: StatusAssigned;
  transitionPeriod: moment.Moment | '';
  validity: Validity;
  version: string;

  constructor(law: Partial<SharedLaw>) {
    Object.assign(this, law);
  }
}

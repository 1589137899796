export class FindingsAmount {
  total: number;
  totalInProgress: number;
  totalOverdue: number;
  totalNotAssigned: number;
  totalSolved: number;
  filtered: number;
  filteredInProgress: number;
  filteredOverdue: number;
  filteredNotAssigned: number;
  filteredSolved: number;

  constructor(findingsAmount?: any) {
    this.total = findingsAmount ? findingsAmount.total || 0 : 0;
    this.totalInProgress = findingsAmount ? findingsAmount.totalInProgress || 0 : 0;
    this.totalOverdue = findingsAmount ? findingsAmount.totalOverdue || 0 : 0;
    this.totalNotAssigned = findingsAmount ? findingsAmount.totalNotAssigned || 0 : 0;
    this.totalSolved = findingsAmount ? findingsAmount.totalSolved || 0 : 0;
    this.filtered = findingsAmount ? findingsAmount.filtered || 0 : 0;
    this.filteredInProgress = findingsAmount ? findingsAmount.filteredInProgress || 0 : 0;
    this.filteredOverdue = findingsAmount ? findingsAmount.filteredOverdue || 0 : 0;
    this.filteredNotAssigned = findingsAmount ? findingsAmount.filteredNotAssigned || 0 : 0;
    this.filteredSolved = findingsAmount ? findingsAmount.filteredSolved || 0 : 0;
  }

  merge(findingsAmount: FindingsAmount): void {
    this.total += findingsAmount.total;
    this.totalInProgress += findingsAmount.totalInProgress;
    this.totalOverdue += findingsAmount.totalOverdue;
    this.totalNotAssigned += findingsAmount.totalNotAssigned;
    this.totalSolved += findingsAmount.totalSolved;
    this.filtered += findingsAmount.filtered;
    this.filteredInProgress += findingsAmount.filteredInProgress;
    this.filteredOverdue += findingsAmount.filteredOverdue;
    this.filteredNotAssigned += findingsAmount.filteredNotAssigned;
    this.filteredSolved += findingsAmount.filteredSolved;
  }
}

import { FiltersParamsModel, PaginationParams } from '../models/filters.model';

export class FiltersParamsSections<T, S extends string> extends FiltersParamsModel<T> {
  sections?: Record<S, PaginationParams>;

  constructor(TCreator: new () => T, params?: FiltersParamsModel<T>, sections?: Record<S, PaginationParams>) {
    super(TCreator, params);
    this.sections = sections;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { RelatedLawReference } from '../models/related-law-reference.model';

@Pipe({
  name: 'showLawReference',
  pure: false
})
export class ShowLawReferencePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: RelatedLawReference, args?: any): string {
    const articleShortcut = this.translateService.instant('GUTWIN_SHARED.LABEL.ARTICLE_SHORTCUT');
    const sectionShortcut = '§';
    const paragraphShortcut = this.translateService.instant('GUTWIN_SHARED.LABEL.PARAGRAPH_SHORTCUT');
    const numberShortcut = this.translateService.instant('GUTWIN_SHARED.LABEL.NUMBER_SHORTCUT');
    const literaShortcut = this.translateService.instant('GUTWIN_SHARED.LABEL.LITERA_SHORTCUT');

    const attachment = value.attachment ? value.attachment : '';
    const article = this.transformShortcutAndValueToFullName(articleShortcut, value.article);
    const section = this.transformShortcutAndValueToFullName(sectionShortcut, value.section);
    const paragraph = this.transformShortcutAndValueToFullName(paragraphShortcut, value.paragraph);
    const number = this.transformShortcutAndValueToFullName(numberShortcut, value.number);
    const litera = this.transformShortcutAndValueToFullName(literaShortcut, value.litera);

    return `${attachment} ${article} ${section} ${paragraph} ${number} ${litera}`;
  }

  transformShortcutAndValueToFullName(shortcut, value): string {
    return value ? shortcut + ' ' + value : '';
  }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import moment from 'moment';

// Gutwin Shared Library
import {
  Attachment,
  Employee,
  FileService,
  LightboxService,
  OfflineService,
  SimpleDropdownDirective
} from 'gutwin-shared';

// Models
import { AuditedArea } from '../../models/audited-area.model';
import { Finding, FindingRelationships, FindingStatus } from '../../models/finding.model';
import { Question } from '../../models/question.model';

// Services
import { FindingService } from '../../services/finding.service';
import { PermissionService } from '../../services/permission.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'gw-finding',
  templateUrl: './finding.component.html',
  styleUrls: ['./finding.component.scss']
})
export class FindingComponent implements OnInit {
  @ViewChild('deadlineDropdown') deadlineDropdown: SimpleDropdownDirective;
  @Input() finding: Finding;
  @Input() source: AuditedArea | Question;
  @Input() employees: Array<Employee>;
  @Input() employeesAssigns: Array<any>;
  @Input() withAssign: boolean;
  @Input() withDetails: boolean;
  @Input() withFindable: boolean;
  @Input() detailsLink: boolean;
  @Input() disabled = {
    update: false,
    remove: false
  };
  @Output() findingChanged = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() remove = new EventEmitter();
  canManageFinding = true;
  toMove: boolean;
  findingStatusNames: Array<string>;
  image: {
    styles: any;
    url: string;
  };
  nowDate = moment();

  constructor(
    private changeDetector: ChangeDetectorRef,
    private lightboxService: LightboxService,
    private findingService: FindingService,
    private fileService: FileService,
    private permissionService: PermissionService,
    private userService: UserService,
    public offlineService: OfflineService
  ) {}

  async ngOnInit(): Promise<void> {
    this.canManageFinding = this.permissionService.canFindingBeManaged(this.finding, this.userService.storedUser);
    this.toMove = this.hasFindingDifferentOfflineFindable();
    await this.createPreviewImage();
    this.findingStatusNames = await this.findingService.getFindingStatusNames();
    this.changeDetector.markForCheck();
  }

  hasFindingDifferentOfflineFindable(): boolean {
    return (
      this.finding.offlineRequest &&
      this.finding.offlineRequest.relationships.findable &&
      this.source &&
      this.finding.offlineRequest.relationships.findable.id !== this.source.id
    );
  }

  async createPreviewImage(): Promise<any> {
    const imageAttachment = this.getFirstImageAttachment();
    if (imageAttachment) {
      const url = imageAttachment.file
        ? await this.fileService.generateImagePreview(imageAttachment.file)
        : imageAttachment.url;
      this.image = {
        url,
        styles: {
          'background-image': 'url(' + url + ')',
          'background-size': 'cover'
        }
      };
    }
  }

  getFirstImageAttachment(): Attachment {
    const getFirstImage = (attachments: Array<Attachment>): Attachment => {
      if (attachments) {
        for (const attachment of attachments) {
          if (attachment.isImage) {
            return attachment;
          }
        }
      }
    };

    const firstImageAttachment = getFirstImage(this.finding.attachments);
    return firstImageAttachment
      ? firstImageAttachment
      : this.finding.offlineRequest
      ? getFirstImage(this.finding.offlineRequest.attachments)
      : undefined;
  }

  setEmployee(finding: Finding, employee: Employee): void {
    this.finding.temporaryFinding = this.finding.temporaryFinding || new Finding();
    this.finding.temporaryFinding.changes = this.finding.temporaryFinding.changes || {};
    this.finding.temporaryFinding.changes.employee = true;
    this.finding.temporaryFinding.relationships =
      this.finding.temporaryFinding.relationships || new FindingRelationships({});
    this.finding.temporaryFinding.relationships.employee = employee;
    this.finding.temporaryFinding.status =
      !finding.status || finding.status === FindingStatus.notAssigned ? FindingStatus.inProgress : finding.status;
    this.findingChanged.emit(finding);
  }

  async removeEmployee(finding: Finding): Promise<void> {
    if (finding.temporaryFinding && finding.temporaryFinding.changes && finding.temporaryFinding.changes.employee) {
      delete finding.temporaryFinding.changes.employee;
      delete finding.temporaryFinding.relationships.employee;
      delete finding.temporaryFinding.status;
    } else if (finding.offlineRequest && finding.offlineRequest.changes && finding.offlineRequest.changes.employee) {
      delete finding.offlineRequest.changes.employee;
      delete finding.offlineRequest.relationships.employee;
      delete finding.offlineRequest.status;
    }
    this.findingChanged.emit(finding);
  }

  setDeadline(finding: Finding, deadline: moment.Moment): void {
    finding['newDeadline'] = deadline;
  }

  saveDeadline(finding: Finding): void {
    this.finding.temporaryFinding = this.finding.temporaryFinding || new Finding();
    this.finding.temporaryFinding.changes = this.finding.temporaryFinding.changes || {};
    this.finding.temporaryFinding.changes.deadline = true;
    this.finding.temporaryFinding.deadline = finding['newDeadline'] || this.nowDate;
    delete finding['newDeadline'];
    this.findingChanged.emit(finding);
  }

  clickUpdate(): void {
    this.update.emit();
  }

  clickRemove(): void {
    this.remove.emit();
  }

  async showLightbox(): Promise<void> {
    const getImagesFromFinding = (finding: Finding): Array<Attachment> => {
      return finding && finding.attachments ? finding.attachments.filter(attachment => attachment.isImage) : [];
    };

    const preparePreviewForImages = (attachments: Array<Attachment>): Promise<Array<Attachment>> => {
      return Promise.all(
        attachments.map(async attachment => {
          if (!attachment.preview && attachment.file) {
            attachment.preview = await this.fileService.generateImagePreview(attachment.file);
          }
          return attachment;
        })
      );
    };

    const imageAttachment = this.getFirstImageAttachment();
    if (imageAttachment) {
      const offlineGallery = await preparePreviewForImages(getImagesFromFinding(this.finding.offlineRequest));
      const gallery = [...getImagesFromFinding(this.finding), ...offlineGallery];
      this.lightboxService.show(imageAttachment, gallery);
    }
  }
}

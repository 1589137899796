import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isChecked'
})
export class IsCheckedPipe implements PipeTransform {
  transform(value: { id: string }, selectedItems: Array<any>): any {
    return !!selectedItems?.find(item => item.id === value.id);
  }
}

import { Injectable, OnInit } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, ActivatedRoute, RouterStateSnapshot, Params } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
// Models
import { Section } from './../../shared/models/section.model';
// Providers
import { SectionService } from '../../shared/services/section.service';

@Injectable()
export class SectionsWithQuestionsResolve implements Resolve<any> {
  constructor(
    private sectionService: SectionService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Promise<Array<Section>> {
    const id = route.params['auditedAreaId'] ? route.params['auditedAreaId'] : route.parent.params['auditId'];
    if (route.params['auditedAreaId']) {
      return this.sectionService.getSectionsWithQuestions(id)
        .catch(error => {
          this.fetchErrorTranslation()
            .then((translation: any) => {
              this.notificationsService.error(translation.title, translation.text);
            });
          return [];
        });
    }
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ERROR.LOAD_CHAPTER_WITH_QUESTIONS'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['GLOBAL.ERROR.LOAD_CHAPTER_WITH_QUESTIONS']
        };
      });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'externalUrl'
})
export class ExternalUrlPipe implements PipeTransform {
  constructor() {}

  transform(value: string): string {
    const isExternal = value.startsWith('https://') || value.startsWith('http://') || value.startsWith('//');
    return isExternal ? value : `//${value}`;
  }
}

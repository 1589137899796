import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { CompanySubmodulesNames } from '../enums/company-submodules.enum';

export const getSubmoduleTranslationKeys = (
  submodule: CompanySubmodulesNames
): { titleKey: string; contentKey: string } => {
  return {
    [CompanySubmodulesNames.legalChanges]: {
      titleKey: marker(`GUTWIN_SHARED.NON_LICENSED_TOOLTIP.CHANGES.TITLE`),
      contentKey: marker(`GUTWIN_SHARED.NON_LICENSED_TOOLTIP.CHANGES.TEXT`)
    },
    [CompanySubmodulesNames.legalRegister]: {
      titleKey: marker(`GUTWIN_SHARED.NON_LICENSED_TOOLTIP.LAWS.TITLE`),
      contentKey: marker(`GUTWIN_SHARED.NON_LICENSED_TOOLTIP.LAWS.TEXT`)
    },
    [CompanySubmodulesNames.legalRequirements]: {
      titleKey: marker(`GUTWIN_SHARED.NON_LICENSED_TOOLTIP.REQUIREMENTS.TITLE`),
      contentKey: marker(`GUTWIN_SHARED.NON_LICENSED_TOOLTIP.REQUIREMENTS.TEXT`)
    }
  }[submodule];
};

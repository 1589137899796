import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';

// Gutwin Shared Library
import { CheckBoxComponent, DialogService, ExpandBoxComponent, HelperUtil, ModalService } from 'gutwin-shared';

// Models
import { Audit } from '../../../shared/models/audit.model';
import { AuditedArea } from '../../../shared/models/audited-area.model';
import { Question } from '../../../shared/models/question.model';
import { Questionnaire } from '../../../shared/models/questionnaire.model';
import { Section } from '../../../shared/models/section.model';

import { QuestionService } from '../../../shared/services/question.service';
// Providers
import { QuestionnaireService } from '../../../shared/services/questionnaire.service';
import { SectionService } from '../../../shared/services/section.service';

@Component({
  selector: 'gw-edit-questions',
  exportAs: 'gwEditQuestions',
  templateUrl: './edit-questions.component.html',
  styleUrls: ['./edit-questions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditQuestionsComponent implements OnInit {
  @Input() auditable: Audit | AuditedArea;
  translation = {
    notify: {
      error: {
        connection: '',
        loadQuestions: '',
        loadSections: '',
        loadSelectedSections: '',
        loadQuestionnaires: '',
        checkQuestionnaire: '',
        uncheckQuestionnaire: '',
        checkSection: '',
        uncheckSection: '',
        checkQuestion: '',
        uncheckQuestion: '',
        removeQuestion: '',
        updateQuestion: '',
        reorderQuestions: '',
        removeSection: '',
        updateSection: '',
        reorderSections: '',
        permissions: {
          title: '',
          checkQuestionnaire: '',
          uncheckQuestionnaire: '',
          checkSection: '',
          uncheckSection: '',
          checkQuestion: '',
          uncheckQuestion: '',
          removeQuestion: '',
          updateQuestion: '',
          reorderQuestions: '',
          removeSection: '',
          updateSection: '',
          reorderSections: ''
        }
      },
      success: {
        removeQuestionTitle: '',
        removeQuestionText: '',
        updateQuestionTitle: '',
        updateQuestionText: '',
        removeSectionTitle: '',
        removeSectionText: '',
        updateSectionTitle: '',
        updateSectionText: '',
      }
    },
    removeQuestion: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    removeSection: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    }
  };
  dropPosition: number;
  draggedSection = {
    dragging: false,
    section: undefined
  };
  draggedQuestion = {
    dragging: false,
    section: undefined,
    question: undefined
  };
  scrolling: boolean;

  constructor(
    private dialogService: DialogService,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private questionnaireService: QuestionnaireService,
    private sectionService: SectionService,
    private questionService: QuestionService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.fetchQuestionnaires();
    this.fetchTranslation();
  }

  async toggleSections(questionnaire: Questionnaire, expandBox: ExpandBoxComponent): Promise<void> {
    if (!(questionnaire.sections && questionnaire.sections.length)) {
      await this.fetchSections(questionnaire);
    }
    expandBox.toggle();
    this.changeDetector.detectChanges();
  }

  async toggleQuestions(composable: Audit | AuditedArea | Questionnaire, section: Section, expandBox: ExpandBoxComponent): Promise<void> {
    if (!(section.questions && section.questions.length)) {
      await this.fetchQuestions(composable, section);
    }
    expandBox.toggle();
    this.changeDetector.detectChanges();
  }

  async fetchSelectedSections(): Promise<void> {
    this.auditable.selectedSections = await this.sectionService.getSelectedSectionsWithQuestions(this.auditable)
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.loadSelectedSections);
        return [];
      });
    this.auditable.customSections = this.sectionService.getCustomSections(this.auditable.selectedSections);
    this.auditable.selectedQuestionsAmount = this.auditable.countSelectedQuestions();
    this.changeDetector.detectChanges();
  }

  async fetchQuestionnaires(): Promise<void> {
    await this.questionnaireService.getAuditablesQuestionnaires(this.auditable.id, this.auditable._type)
      .then(questionnaires => {
        this.auditable.questionnaires = questionnaires;
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.loadQuestionnaires);
        this.auditable.questionnaires = [];
      });
    this.changeDetector.detectChanges();
  }

  async fetchSections(questionnaire: Questionnaire): Promise<void> {
    await this.sectionService.getSections(questionnaire)
      .then((sections: Array<Section>) => {
        questionnaire.sections = sections;
        questionnaire.sections.forEach(section => {
          const isSelected = this.checkSectionIfSelected(section.id);
          section.added = isSelected.added;
          section.addedToParent = isSelected.addedToParent;
        });
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.loadSections);
        questionnaire.sections = [];
      });
    this.changeDetector.detectChanges();
  }

  async fetchQuestions(composable: Audit | AuditedArea | Questionnaire, section: Section): Promise<void> {
    await this.questionService.getQuestions(composable, section.id)
      .then((questions: Array<Question>) => {
        questions.forEach(question => {
          question.custom = section.custom;
          const isSelected = this.checkQuestionIfSelected(section.id, question.id);
          question.added = isSelected.added;
          question.addedToParent = isSelected.addedToParent;
        });
        section.questions = questions;
      })
      .catch(error => {
        this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.loadQuestions);
        section.questions = [];
      });
    this.changeDetector.detectChanges();
  }

  checkSectionIfSelected(sectionId: string): any {
    if (this.auditable.selectedSections) {
      const selectedSection = _.find(this.auditable.selectedSections, {id: sectionId});
      return {
        added: !!selectedSection,
        addedToParent: selectedSection ? selectedSection.addedToParent : false
      };
    }
    return {
      added: false,
      addedToParent: false
    };
  }

  checkQuestionIfSelected(sectionId: string, questionId: string): any {
    if (this.auditable.selectedSections) {
      const selectedSection = _.find(this.auditable.selectedSections, {id: sectionId});
      if (selectedSection && selectedSection.questions) {
        const selectedQuestion = _.find(selectedSection.questions, {id: questionId});
        return {
          added: !!selectedQuestion,
          addedToParent: selectedQuestion ? selectedQuestion.addedToParent : false
        };
      }
    }
    return {
      added: false,
      addedToParent: false
    };
  }

  countSelectedQuestionsInQuestionnaire(questionnaire: Questionnaire): number {
    let amount = 0;
    if (questionnaire.sections) {
      questionnaire.sections.forEach(section => {
        amount += this.countSelectedQuestionsInSection(section.id);
      });
    }
    return amount;
  }

  countSelectedQuestionsInSection(sectionId: string): number {
    const selectedSection = _.find(this.auditable.selectedSections, {id: sectionId});
    if (selectedSection && selectedSection.questions) {
      return selectedSection.questions.length;
    }
    return 0;
  }

  async checkQuestion(question: Question, section: Section, questionnaire: Questionnaire, checkbox: CheckBoxComponent): Promise<void> {
    await this.selectQuestion(question, section, questionnaire, true).catch(error => {
      checkbox.writeValue(question.added);
    });
    this.changeDetector.detectChanges();
  }

  selectQuestion(question: Question, section: Section, questionnaire: Questionnaire, updateParent: boolean): Promise<void> {
    const selected = !question.added;
    let promise: Promise<any>;
    if (selected) {
      promise = this.questionService.checkAuditablesQuestion(this.auditable.id, this.auditable._type, question.id)
        .catch(error => {
          switch (error.status) {
            case 403:
              this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.checkQuestion);
              break;
            default:
              this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.checkQuestion);
          }
          throw error;
        });
    } else {
      promise = this.questionService.uncheckAuditablesQuestion(this.auditable.id, this.auditable._type, question.id)
        .catch(error => {
          switch (error.status) {
            case 403:
              this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.uncheckQuestion);
              break;
            default:
              this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.uncheckQuestion);
          }
          throw error;
        });
    }
    return promise
      .then(async() => {
        await this.fetchSelectedSections();
        this.updateQuestion(question, selected);
        if (updateParent) {
          this.updateSection(section, this.checkSectionIfSelected(section.id).added, false);

          const selectedQuestionsInQuestionnaire = this.countSelectedQuestionsInQuestionnaire(questionnaire);
          const checkQuestionnaire = selectedQuestionsInQuestionnaire > 0;
          this.updateQuestionnaire(questionnaire, checkQuestionnaire, selectedQuestionsInQuestionnaire, false);
        }
      });
  }

  async checkSection(section: Section, questionnaire: Questionnaire, checkbox: CheckBoxComponent): Promise<void> {
    await this.selectSection(section, questionnaire, true).catch(error => {
      checkbox.writeValue(section.added);
    });
    this.changeDetector.detectChanges();
  }

  selectSection(section: Section, questionnaire: Questionnaire, updateParent: boolean): Promise<any> {
    const selected = !section.added;
    let promise: Promise<any>;
    if (selected) {
      promise = this.sectionService.checkAuditablesSection(this.auditable.id, this.auditable._type, section.id)
        .catch(error => {
          switch (error.status) {
            case 403:
              this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.checkSection);
              break;
            default:
              this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.checkSection);
          }
          throw error;
        });
    } else {
      promise = this.sectionService.uncheckAuditablesSection(this.auditable.id, this.auditable._type, section.id)
        .catch(error => {
          switch (error.status) {
            case 403:
              this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.uncheckSection);
              break;
            default:
              this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.uncheckSection);
          }
          throw error;
        });
    }
    return promise
      .then(async() => {
        await this.fetchSelectedSections();
        this.updateSection(section, selected, true);
        if (updateParent) {
          const selectedQuestions = this.countSelectedQuestionsInQuestionnaire(questionnaire);
          const check = selectedQuestions > 0;
          this.updateQuestionnaire(questionnaire, check, selectedQuestions, false);
        }
      });
  }

  async checkQuestionnaire(questionnaire: Questionnaire, checkbox: CheckBoxComponent): Promise<void> {
    await this.selectQuestionnaire(questionnaire).catch(error => {
      checkbox.writeValue(questionnaire.added);
    });
    this.changeDetector.detectChanges();
  }

  selectQuestionnaire(questionnaire: Questionnaire, value?: boolean): Promise<void> {
    const selected = value !== undefined ? value : !questionnaire.added;
    let promise: Promise<any>;
    if (selected) {
      promise = this.questionnaireService.checkAuditablesQuestionnaire(this.auditable.id, this.auditable._type, questionnaire.id)
        .catch(error => {
          switch (error.status) {
            case 403:
              this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.checkQuestionnaire);
              break;
            default:
              this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.checkQuestionnaire);
          }
          throw error;
        });
    } else {
      promise = this.questionnaireService.uncheckAuditablesQuestionnaire(this.auditable.id, this.auditable._type, questionnaire.id)
        .catch(error => {
          switch (error.status) {
            case 403:
              this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.uncheckQuestionnaire);
              break;
            default:
              this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.uncheckQuestionnaire);
          }
          throw error;
        });
    }
    return promise
      .then(() => {
        this.fetchSelectedSections();
        const totalQuestions = selected ? questionnaire.totalQuestions : 0;
        this.updateQuestionnaire(questionnaire, selected, totalQuestions);
      });
  }

  updateQuestionnaire(questionnaire: Questionnaire, checked: boolean, selectedQuestions: number, withChildren = true): void {
    questionnaire.added = checked;
    questionnaire.totalQuestionsSelected = selectedQuestions;
    if (withChildren && questionnaire.sections) {
      questionnaire.sections.forEach(section => {
        this.updateSection(section, checked, withChildren);
      });
    }
  }

  updateSection(section: Section, checked: boolean, withChildren = true): void {
    section.added = checked;
    if (withChildren && section.questions) {
      section.questions.forEach(question => {
        this.updateQuestion(question, checked);
      });
    }
  }

  updateQuestion(question: Question, checked: boolean): void {
    question.added = checked;
  }

  showEditSectionModal(section: Section): void {
    this.modalService.open('sectionModal', section);
  }

  showRemoveSectionModal(section: Section): void {
    this.dialogService.confirm(
      this.translation.removeSection.header,
      this.translation.removeSection.content,
      this.translation.removeSection.cancel,
      this.translation.removeSection.confirm
    )
      .then(() => {
        this.removeSection(section);
      })
      .catch(() => {});
  }

  showEditQuestionModal(section: Section, question?: Question): void {
    this.modalService.open('questionModal', {
      question,
      section
    });
  }

  showRemoveQuestionModal(section: Section, question: Question): void {
    this.dialogService.confirm(
      this.translation.removeQuestion.header,
      this.translation.removeQuestion.content,
      this.translation.removeQuestion.cancel,
      this.translation.removeQuestion.confirm
    )
      .then(() => {
        this.removeQuestion(section, question);
      })
      .catch(() => {});
  }

  async removeSection(section: Section): Promise<void> {
    await this.sectionService.removeSection(section, this.auditable)
      .then(() => {
        this.spliceSection(section);
        this.changeDetector.detectChanges();
        this.notificationsService.success(this.translation.notify.success.removeSectionTitle, this.translation.notify.success.removeSectionText);
      })
      .catch(error => {
        switch (error.status) {
          case 403:
            this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.removeSection);
            break;
          default:
            this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.removeSection);
        }
      });
    this.changeDetector.detectChanges();
  }

  async removeQuestion(section: Section, question: Question): Promise<void> {
    await this.questionService.removeQuestion(question, section.id, this.auditable)
      .then(() => {
        this.spliceQuestion(section, question);
        this.changeDetector.detectChanges();
        this.notificationsService.success(this.translation.notify.success.removeQuestionTitle, this.translation.notify.success.removeQuestionText);
      })
      .catch(error => {
        switch (error.status) {
          case 403:
            this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.removeQuestion);
            break;
          default:
            this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.removeQuestion);
        }
      });
    this.changeDetector.detectChanges();
  }

  insertSection(index: number, section: Section, sections = this.auditable.selectedSections): void {
    if (sections) {
      sections.splice(index, 0, section);
    }
  }

  spliceSection(section: Section, sections = this.auditable.selectedSections): void {
    if (sections) {
      const index = _.findIndex(sections, {id: section.id});
      if (~index) {
        sections.splice(index, 1);
      }
    }
  }

  insertQuestion(index: number, section: Section, question: Question): void {
    if (section.questions) {
      section.totalQuestions++;
      section.questions.splice(index, 0, question);
    }
  }

  spliceQuestion(section: Section, question: Question): void {
    const index = _.findIndex(section.questions, {id: question.id});
    if (~index) {
      section.totalQuestions--;
      section.questions.splice(index, 1);
    }
  }

  async clearAll(): Promise<void> {
    if (this.auditable.questionnaires) {
      const promises = new Array<Promise<void>>();
      this.auditable.questionnaires.forEach(questionnaire => {
        if (questionnaire.added && !questionnaire.addedToParent) {
          promises.push(this.selectQuestionnaire(questionnaire, false));
        }
      });
      await Promise.all(promises);
      this.changeDetector.detectChanges();
    }
  }

  makeSectionEditable(section: Section): void {
    if (!section['unsaved']) {
      section.newName = section.name;
    }
  }

  changeSectionName(section: Section, name: string): void {
    section.newName = name;
    section['unsaved'] = section.name !== section.newName;
  }

  resetSectionName(section: Section): void {
    section.newName = section.name;
    section['unsaved'] = false;
  }

  async saveSectionName(section: Section): Promise<void> {
    section.name = section.newName;
    await this.sectionService.updateSection(section, this.auditable)
      .then(sectionResponse => {
        section.name = sectionResponse.name;
        section['unsaved'] = false;
        this.notificationsService.success(
          this.translation.notify.success.updateSectionTitle,
          this.translation.notify.success.updateSectionText
        );
      })
      .catch(error => {
        switch (error.status) {
          case 403:
            this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.updateSection);
            break;
          default:
            this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.updateSection);
        }
      });
    this.changeDetector.detectChanges();
  }

  makeQuestionEditable(question: Question): void {
    if (!question['unsaved']) {
      question.newContent = question.content;
    }
  }

  changeQuestionContent(question: Question, content: string): void {
    question.newContent = content;
    question['unsaved'] = question.content !== question.newContent;
  }

  resetQuestionContent(question: Question): void {
    question.newContent = question.content;
    question['unsaved'] = false;
  }

  async saveQuestionContent(section: Section, question: Question): Promise<void> {
    question.content = question.newContent;
    await this.questionService.updateQuestion(question, section, this.auditable)
      .then(questionResponse => {
        question.content = questionResponse.content;
        question['unsaved'] = false;
        this.notificationsService.success(
          this.translation.notify.success.updateQuestionTitle,
          this.translation.notify.success.updateQuestionText
        );
      })
      .catch(error => {
        switch (error.status) {
          case 403:
            this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.updateQuestion);
            break;
          default:
            this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.updateQuestion);
        }
      });
    this.changeDetector.detectChanges();
  }

  reorderSections(index: number, section: Section, sections = this.auditable.selectedSections): void {
    const oldIndex = _.findIndex(sections, {id: section.id});
    if (oldIndex !== index) {
      this.spliceSection(section, sections);
      if (oldIndex < index) {
        index--;
      }
      this.insertSection(index, section, sections);
    }
  }

  async changeSectionOrder(index: number, section: Section): Promise<void> {
    const tempSections = this.auditable.selectedSections.slice(0);
    this.reorderSections(index, section, tempSections);
    await this.sectionService.reorderSections(tempSections, this.auditable)
      .then(() => {
        this.reorderSections(index, section);
      })
      .catch(error => {
        switch (error.status) {
          case 403:
            this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.reorderSections);
            break;
          default:
            this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.reorderSections);
        }
      });
    this.changeDetector.detectChanges();
  }

  reorderQuestions(index: number, section: Section, question: Question): void {
    const oldIndex = _.findIndex(section.questions, {id: question.id});
    if (oldIndex !== index) {
      this.spliceQuestion(section, question);
      if (oldIndex < index) {
        index--;
      }
      this.insertQuestion(index, section, question);
    }
  }

  async changeQuestionOrder(index: number, section: Section, question: Question): Promise<void> {
    const tempSection = new Section(section);
    tempSection.questions = tempSection.questions.slice(0);
    this.reorderQuestions(index, tempSection, question);
    await this.questionService.reorderQuestions(tempSection.questions, this.auditable, section.id)
      .then(() => {
        section = _.find(this.auditable.selectedSections, {id: section.id});
        this.reorderQuestions(index, section, question);
      })
      .catch(error => {
        switch (error.status) {
          case 403:
            this.notificationsService.error(this.translation.notify.error.permissions.title, this.translation.notify.error.permissions.reorderQuestions);
            break;
          default:
            this.notificationsService.error(this.translation.notify.error.connection, this.translation.notify.error.reorderQuestions);
        }
      });
    this.changeDetector.detectChanges();
  }

  dragStart(event: any, element: HTMLElement, section: Section, question?: Question): void {
    event.dataTransfer.setData('text', event.target.id);
    if (event.dataTransfer.setDragImage) {
      event.dataTransfer.setDragImage(element, event.offsetX, event.offsetY);
    }
    if (question) {
      this.draggedQuestion = {
        dragging: true,
        section: section,
        question: question
      };
    } else {
      this.draggedSection = {
        dragging: true,
        section: section
      };
    }
  }

  dragEnd(): void {
    this.draggedSection.dragging = false;
    this.draggedQuestion.dragging = false;
  }

  drop(index: number, dropQuestion?: boolean): void {
    this.dropPosition = undefined;
    if (dropQuestion) {
      const question = new Question(this.draggedQuestion.question);
      const section = new Section(this.draggedQuestion.section);
      this.changeQuestionOrder(index, section, question);
      this.draggedQuestion = {
        dragging: false,
        section: undefined,
        question: undefined
      };
    } else {
      const section = new Section(this.draggedSection.section);
      this.changeSectionOrder(index, section);
      this.draggedSection = {
        dragging: false,
        section: undefined
      };
    }
  }

  dragOver(event: any): void {
    const scrollContainer = this.modalService.getContent('questionsCanvas');
    if (scrollContainer && scrollContainer.nativeElement) {
      const scrollElement = scrollContainer.nativeElement;
      const scrollElementBounds = scrollElement.getBoundingClientRect();
      if (!this.scrolling) {
        if (event.pageY - HelperUtil.windowScrollY > scrollElementBounds.top + scrollElementBounds.height - 50 &&
          scrollElement.scrollHeight - scrollElement.scrollTop > scrollElementBounds.height) {
          this.scrollSelected('down');
        } else if (event.pageY - HelperUtil.windowScrollY < scrollElementBounds.top + 50 && scrollElement.scrollTop > 0) {
          this.scrollSelected('up');
        }
      }
    }
  }

  scrollSelected(direction: string): void {
    const scrollContainer = this.modalService.getContent('questionsCanvas');
    if (scrollContainer && scrollContainer.nativeElement) {
      this.scrolling = true;
      HelperUtil.scrollView(direction, scrollContainer).then(() => {
        this.scrolling = false;
      });
    }
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ERROR.PERMISSIONS',
      'GLOBAL.ACTION.CANCEL',
      'GLOBAL.ACTION.REMOVE',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.LOAD_QUESTIONS',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.LOAD_SECTIONS',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.LOAD_SELECTED_SECTIONS',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.LOAD_QUESTIONNAIRES',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.CHECK_QUESTIONNAIRE',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.UNCHECK_QUESTIONNAIRE',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.CHECK_SECTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.UNCHECK_SECTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.CHECK_QUESTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.UNCHECK_QUESTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.REMOVE_QUESTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.UPDATE_QUESTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.REORDER_QUESTIONS',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.REMOVE_SECTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.UPDATE_SECTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.REORDER_SECTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.CHECK_QUESTIONNAIRE',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.UNCHECK_QUESTIONNAIRE',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.CHECK_SECTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.UNCHECK_SECTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.CHECK_QUESTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.UNCHECK_QUESTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.REMOVE_QUESTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.UPDATE_QUESTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.REORDER_QUESTIONS',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.REMOVE_SECTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.UPDATE_SECTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.REORDER_SECTION',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.REMOVE_SECTION_TITLE',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.REMOVE_SECTION_TEXT',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.UPDATE_SECTION_TITLE',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.UPDATE_SECTION_TEXT',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.REMOVE_QUESTION_TITLE',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.REMOVE_QUESTION_TEXT',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.UPDATE_QUESTION_TITLE',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.UPDATE_QUESTION_TEXT',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.REMOVE_SECTION_MODAL.HEADER',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.REMOVE_SECTION_MODAL.CONTENT',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.REMOVE_QUESTION_MODAL.HEADER',
      'AUDIT_CREATION.S_AUDIT_QUESTIONS.REMOVE_QUESTION_MODAL.CONTENT'
    ]).toPromise();
    this.translation.notify.error.connection = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.loadQuestions = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.LOAD_QUESTIONS'];
    this.translation.notify.error.loadSections = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.LOAD_SECTIONS'];
    this.translation.notify.error.loadSelectedSections = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.LOAD_SELECTED_SECTIONS'];
    this.translation.notify.error.loadQuestionnaires = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.LOAD_QUESTIONNAIRES'];
    this.translation.notify.error.checkQuestionnaire = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.CHECK_QUESTIONNAIRE'];
    this.translation.notify.error.uncheckQuestionnaire = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.UNCHECK_QUESTIONNAIRE'];
    this.translation.notify.error.checkSection = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.CHECK_SECTION'];
    this.translation.notify.error.uncheckSection = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.UNCHECK_SECTION'];
    this.translation.notify.error.checkQuestion = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.CHECK_QUESTION'];
    this.translation.notify.error.uncheckQuestion = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.UNCHECK_QUESTION'];
    this.translation.notify.error.removeQuestion = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.REMOVE_QUESTION'];
    this.translation.notify.error.updateQuestion = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.UPDATE_QUESTION'];
    this.translation.notify.error.reorderQuestions = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.REORDER_QUESTIONS'];
    this.translation.notify.error.removeSection = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.REMOVE_SECTION'];
    this.translation.notify.error.updateSection = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.UPDATE_SECTION'];
    this.translation.notify.error.reorderSections = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.REORDER_SECTIONS'];
    this.translation.notify.error.permissions.title = translation['GLOBAL.ERROR.PERMISSIONS'];
    this.translation.notify.error.permissions.checkQuestionnaire =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.CHECK_QUESTIONNAIRE'];
    this.translation.notify.error.permissions.uncheckQuestionnaire =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.UNCHECK_QUESTIONNAIRE'];
    this.translation.notify.error.permissions.checkSection =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.CHECK_SECTION'];
    this.translation.notify.error.permissions.uncheckSection =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.UNCHECK_SECTION'];
    this.translation.notify.error.permissions.checkQuestion =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.CHECK_QUESTION'];
    this.translation.notify.error.permissions.uncheckQuestion =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.UNCHECK_QUESTION'];
    this.translation.notify.error.permissions.removeQuestion =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.REMOVE_QUESTION'];
    this.translation.notify.error.permissions.updateQuestion =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.UPDATE_QUESTION'];
    this.translation.notify.error.permissions.reorderQuestions =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.REORDER_QUESTIONS'];
    this.translation.notify.error.permissions.removeSection =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.REMOVE_SECTION'];
    this.translation.notify.error.permissions.updateSection =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.UPDATE_SECTION'];
    this.translation.notify.error.permissions.reorderSections =
      translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.ERROR.PERMISSIONS.REORDER_SECTIONS'];
    this.translation.notify.success.removeSectionTitle = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.REMOVE_SECTION_TITLE'];
    this.translation.notify.success.removeSectionText = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.REMOVE_SECTION_TEXT'];
    this.translation.notify.success.updateSectionTitle = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.UPDATE_SECTION_TITLE'];
    this.translation.notify.success.updateSectionText = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.UPDATE_SECTION_TEXT'];
    this.translation.notify.success.removeQuestionTitle = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.REMOVE_QUESTION_TITLE'];
    this.translation.notify.success.removeQuestionText = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.REMOVE_QUESTION_TEXT'];
    this.translation.notify.success.updateQuestionTitle = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.UPDATE_QUESTION_TITLE'];
    this.translation.notify.success.updateQuestionText = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.MODAL.SUCCESS.UPDATE_QUESTION_TEXT'];
    this.translation.removeQuestion.header = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.REMOVE_QUESTION_MODAL.HEADER'];
    this.translation.removeQuestion.content = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.REMOVE_QUESTION_MODAL.CONTENT'];
    this.translation.removeQuestion.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeQuestion.confirm = translation['GLOBAL.ACTION.REMOVE'];
    this.translation.removeSection.header = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.REMOVE_SECTION_MODAL.HEADER'];
    this.translation.removeSection.content = translation['AUDIT_CREATION.S_AUDIT_QUESTIONS.REMOVE_SECTION_MODAL.CONTENT'];
    this.translation.removeSection.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeSection.confirm = translation['GLOBAL.ACTION.REMOVE'];
  }
}

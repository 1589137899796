import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'gw-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true
    }
  ]
})
export class RadioButtonComponent implements ControlValueAccessor {
  @Input() value: any;
  @Input() color?: string;
  @Input() empty = false;
  @Input() parentChecked = false;
  @Input() tabindex?: number;
  ngModel: any;
  disabled = false;

  constructor(private changeDetector: ChangeDetectorRef) {}

  onChange = (_: boolean): void => {};

  writeValue(ngModel) {
    this.ngModel = ngModel;
    this.changeDetector.markForCheck();
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  click(): void {
    this.ngModel = this.value;
    this.onChange(this.value);
  }

  setStyle(): any {
    if (this.color && this.value === this.ngModel) {
      return { 'background-color': this.color, 'border-color': this.color };
    }
  }
}

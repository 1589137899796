import { Pipe, PipeTransform } from '@angular/core';

import { FlatTreeNode } from '../interfaces/flat-tree-node.interface';

@Pipe({
  name: 'getConnectedElementsNumber'
})
export class GetConnectedElementsNumberPipe implements PipeTransform {
  public transform(node: FlatTreeNode): string {
    const count = node?.isSelected ? node.chosenElements : node.connectedElements;
    return !!count ? `(${count})` : '';
  }
}

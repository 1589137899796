import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { find, sortBy } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { PermissionCategory } from '../../models/permission-category.model';
import { Permission } from '../../models/permission.model';
import { Role } from '../../models/role.model';

@Component({
  selector: 'gw-permission-category',
  templateUrl: './permission-category.component.html',
  styleUrls: ['./permission-category.component.scss'],
  exportAs: 'gwPermissionCategory',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionCategoryComponent implements OnInit, OnDestroy {
  @Input() permissionCategory: PermissionCategory;
  @Input() roles: Array<Role>;
  @Input() withForm: boolean;
  @Input() withRoles: boolean;
  @Output() submit = new EventEmitter<PermissionCategory>();
  permissions: Array<Permission>;
  permissionsForm: FormGroup;
  destroy$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initPermissions();
    if (this.withForm) {
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initPermissions(): void {
    if (this.withRoles && this.roles) {
      this.permissions = this.permissionCategory.permissions.map(permission => {
        return new Permission({
          ...permission,
          roles: permission.rolesIds
            ? sortBy(
                permission.rolesIds.map(roleId => this.getRole(roleId)),
                'name'
              )
            : []
        });
      });
    } else {
      this.permissions = this.permissionCategory.permissions.map(permission => permission);
    }
  }

  initForm(): void {
    this.permissionsForm = this.generateFormControls();
    this.permissionsForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.submit.emit(this.submitPermissionsForm());
    });
  }

  generateFormControls(): FormGroup {
    const permissionControls = this.formBuilder.group({});
    this.permissionCategory.permissions.forEach(permission => {
      permissionControls.addControl(permission.id, new FormControl(permission.added));
    });
    return permissionControls;
  }

  getRole(roleId: string): Role {
    if (this.roles) {
      return find(this.roles, { id: roleId });
    }
  }

  convertFormToPermissionCategory(formValue: { [key: string]: boolean }): PermissionCategory {
    const permissions = this.permissionCategory.permissions.map(permission => {
      permission.added = formValue[permission.id];
      return permission;
    });
    return new PermissionCategory({
      ...this.permissionCategory,
      permissions
    });
  }

  submitPermissionsForm(): PermissionCategory {
    return this.convertFormToPermissionCategory(this.permissionsForm.value);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { SortOption } from '../../interfaces/sort-option.interface';

import { SelectTheme } from '../../enums/select-theme.enum';
import { Sort } from '../../enums/sort.enum';

@Component({
  selector: 'gw-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortComponent {
  @Input() options: Array<SortOption>;
  @Input() selectedOption: SortOption;
  @Input() sortOrder = Sort.asc;
  @Input() selectTheme?: SelectTheme;
  @Input() isUserTypeClient = false;
  @Input() singleSortOption = false;
  @Output() toggleSortOrder = new EventEmitter<Sort>();
  @Output() changeSortOption = new EventEmitter<SortOption>();

  sort = Sort;

  submitChange(selectedOption: SortOption): void {
    this.changeSortOption.emit(selectedOption);
  }

  onSortOrderToggle(): void {
    const sortOrder = this.sortOrder === Sort.asc ? Sort.desc : Sort.asc;
    this.toggleSortOrder.emit(sortOrder);
  }
}

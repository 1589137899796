import { Permissions } from './permissions.model';

export class EmployeePermissions {
  accessToAdminPanel: boolean;
  ownedPermissions: Permissions;

  constructor(employeePermissions?: EmployeePermissions) {
    this.accessToAdminPanel = employeePermissions ? employeePermissions.accessToAdminPanel : false;
    this.ownedPermissions = employeePermissions
      ? new Permissions(employeePermissions.ownedPermissions)
      : new Permissions();
  }
}

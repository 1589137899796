import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as ActionCable from 'actioncable';
import { Observable } from 'rxjs';

import { EmployeeExtended } from 'gutwin-shared';

import { ApiUrlService } from '@gutwin-audit/shared/services/api-url.service';

@Injectable()
export class WebSocketService {
  actionCable = ActionCable;
  cable: any;

  constructor(private apiUrlService: ApiUrlService, private http: HttpClient) {}

  createConsumer(currentUser: EmployeeExtended, token: string): void {
    this.cable = this.actionCable.createConsumer(
      `${this.apiUrlService.webSocket}?user_id=${currentUser.userId}&token=${token}`
    );
  }

  subscribeWebSocket(channel: string): Observable<any> {
    return new Observable(observer => {
      this.cable.subscriptions.create(channel, {
        received: (message: any) => {
          observer.next(message);
        }
      });
    });
  }

  removeSubscriptionToWebSocket(channel: string): void {
    if (this.cable && this.cable.baseCable) {
      this.cable.baseCable.subscriptions.remove(channel);
    }
  }

  getSocketToken(): Promise<string> {
    return this.http
      .get(this.apiUrlService.socketTokenApi)
      .toPromise()
      .then((data: any) => {
        return data.token;
      })
      .catch(error => {
        console.error('Error while getting web socket token', error);
        throw error;
      });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-change-details-main-part',
  templateUrl: './change-details-main-part.component.html',
  styleUrls: ['./change-details-main-part.component.scss']
})
export class ChangeDetailsMainPartComponent<L> {
  @Input() container: L;
  @Input() isEditMode: boolean;
  @Input() openedEdits?: number;
  @Input() userType: UserType;
  @Output() updateTitle = new EventEmitter<Partial<L>>();
  @Output() toggleEditMode = new EventEmitter<void>();

  onToggleEditMode(): void {
    this.toggleEditMode.emit();
  }

  onUpdateTitle(payload: Partial<L>): void {
    this.updateTitle.emit(payload);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { HelperUtil } from '../utils/helper.util';

@Pipe({
  name: 'displayNestedAttributes',
  pure: true
})
export class DisplayNestedAttributesPipe implements PipeTransform {
  transform(item: any, displayKeys: Array<string>, splitter: string): string {
    return HelperUtil.getNestedAttributesToDisplay(displayKeys, item, splitter);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { CommentActivity } from '../../models/comment-activity.model';
import { StatusActivity } from '../../models/status-activity.model';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityComponent {
  @Input() activity?: CommentActivity | StatusActivity;
  @Input() withoutTop = false;
  @Input() box = false;
  @Input() vertical = false;
  @Input() first = false;
  @Input() grey = false;
  @Input() userType = UserType.consultant;
  @Input() slim?: boolean;

  readonly USER_TYPE_CLIENT = UserType.client;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { SharedLaw } from '../../models/shared-law.model';

import { UserType } from '../../enums/user-type.enum';

import { ExpandBoxComponent } from '../expand-box/expand-box.component';

export interface LawBox {
  law: SharedLaw;
  userType?: UserType;
  isExpanded: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  isEven?: boolean;
  isRemovable?: boolean;
  remove: EventEmitter<SharedLaw>;
  goToDetails: EventEmitter<SharedLaw>;
}

@Component({
  selector: 'gw-law-box',
  templateUrl: './law-box.component.html',
  styleUrls: ['./law-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LawBoxComponent implements LawBox {
  @ViewChild('lawExpandBox', { static: true }) expandBox: ExpandBoxComponent;
  @Input() law: SharedLaw;
  @Input() userType: UserType;
  @Input() isFirst: boolean;
  @Input() isLast: boolean;
  @Input() isEven: boolean;
  @Input() isExpanded: boolean;
  @Input() isRemovable = true;
  @Input() detailsRoute = '/laws/legal-register';
  @Input() defaultCategoryColor?: string;

  @Output() remove = new EventEmitter<SharedLaw>();
  @Output() goToDetails = new EventEmitter<SharedLaw>();

  removeLaw(): void {
    this.remove.emit(this.law);
  }

  showDetails(): void {
    this.goToDetails.emit(this.law);
  }
}

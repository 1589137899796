import { Location } from '@angular/common';

import { StorageService, StoragesNames } from '../../../../services/storage.service';

export const initToken = (
  location: Location,
  storageService: StorageService,
  includeRefreshToken?: boolean
): (() => Promise<void>) => {
  const checkToken = (): Promise<void> => {
    const currentPath = location.path();
    const token = getQueryParamValue(currentPath, StoragesNames.token);
    if (token) {
      updateToken(token);
      if (includeRefreshToken) getAndUpdateRefreshToken(currentPath);
      clearQueryParams();
    }
    return Promise.resolve();
  };

  const getAndUpdateRefreshToken = (currentPath: string): void => {
    const refreshToken = getQueryParamValue(currentPath, StoragesNames.refreshToken);
    const tokenExpiryTime = getQueryParamValue(currentPath, StoragesNames.tokenExpiryTime);
    storageService.setLocalStorage(StoragesNames.refreshToken, refreshToken);
    storageService.setLocalStorage(StoragesNames.tokenExpiryTime, tokenExpiryTime);
  };

  const updateToken = (token: string): void => {
    storageService.setLocalStorage(StoragesNames.token, token);
  };

  const clearQueryParams = (): void => {
    const keysToClear = includeRefreshToken
      ? [StoragesNames.token, StoragesNames.refreshToken, StoragesNames.tokenExpiryTime]
      : [StoragesNames.token];
    location.replaceState(removeQueryParam(location.path(), keysToClear));
  };

  const getSplittedPath = (fullPath: string): { route: string; queryParams: string[] } => {
    const splittedPath = fullPath.split('?');
    return {
      route: splittedPath[0],
      queryParams: splittedPath[1] && splittedPath[1].split('&')
    };
  };

  const getQueryParamValue = (path: string, key: string): string => {
    const { queryParams } = getSplittedPath(path);
    const filteredQueryParam =
      queryParams && queryParams.map(param => param.split('=')).find(param => param[0] === key);
    return filteredQueryParam ? filteredQueryParam[1] : undefined;
  };

  const removeQueryParam = (path: string, keys: Array<string>): string => {
    const { route, queryParams } = getSplittedPath(path);
    const filteredQueryParams = queryParams && queryParams.filter(param => !keys.includes(param.split('=')[0]));
    const queryParamsString = filteredQueryParams.length ? '?' + filteredQueryParams.join('&') : '';
    return `${route}${queryParamsString}`;
  };

  return () => checkToken();
};

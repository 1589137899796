import { AttachmentFile } from '../types/attachment-file.type';

export class Attachment {
  url: string;
  file: AttachmentFile;
  name: string;
  size: number;
  extension: string;
  isImage: boolean;
  isDuplicated: boolean;
  toRemove: boolean;
  checksum: string;
  rotation: number;
  offline: boolean;
  preview: string;
  mimeType: string;

  constructor(attachment: Partial<Attachment>) {
    this.url = attachment.url;
    this.file = attachment.file;
    this.name = attachment.name;
    this.size = attachment.size;
    this.extension = this.getExtension() || '';
    this.isImage = this.isFileImage() || attachment.isImage;
    this.isDuplicated = attachment.isDuplicated || false;
    this.toRemove = attachment.toRemove || false;
    this.checksum = attachment.checksum;
    this.rotation = attachment.rotation;
    this.offline = attachment.offline;
    this.preview = attachment.preview;
    this.mimeType = attachment.mimeType;
  }

  getExtension(): string {
    const name = this.url || this.file?.name || this.name;
    const nameWithoutDots = name?.split(/\./);
    if (nameWithoutDots?.length > 1) {
      const extension = nameWithoutDots[nameWithoutDots.length - 1].split(/\?/)[0];
      return extension;
    }
  }

  isFileImage(): boolean {
    const checkByMimeType = () => !!this.mimeType.startsWith('image/');
    const checkByExt = () => ['jpg', 'jpeg', 'png', 'gif', 'svg'].includes(this.extension.toLowerCase());
    return this.mimeType ? checkByMimeType() : checkByExt();
  }
}

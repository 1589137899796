import { EmployeeExtended } from '../../../../models/employee-extended.model';
import { Employee } from '../../../../models/employee.model';

import { ApiUpdateProfileVariables } from '../interfaces/current-user.interface';
import { ApiGraphQLEmployeeResponse } from '../interfaces/user.interface';

import { ApiLanguage } from '../enums/language.enum';

export const convertSimpleEmployeeToGet = (employee: ApiGraphQLEmployeeResponse): Employee => {
  return (
    employee &&
    new EmployeeExtended({
      email: employee.email,
      id: employee.id,
      lastname: employee.lastName,
      name: employee.name
    })
  );
};

export const convertVariablesToUpdateUserProfile = (employee: EmployeeExtended): ApiUpdateProfileVariables => {
  return {
    id: employee.id,
    name: employee.name,
    lastName: employee.lastname,
    language: ApiLanguage[employee.language?.id]
  };
};

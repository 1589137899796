import gql from 'graphql-tag';

import { AvatarGraphQLActionType } from '../../enums/avatar-graphql.enum';

import { EmployeeFragment } from '../fragments/employee.fragment';

export const CreateEmployeeAvatar = gql`
  mutation ${AvatarGraphQLActionType.createEmployeeAvatar}($attributes: CreateEmployeeAvatarAttributes!) {
    ${AvatarGraphQLActionType.createEmployeeAvatar}(attributes: $attributes) {
      employee {
        ...EmployeeFragment
      }
    }
  }
  ${EmployeeFragment}
`;

export const UpdateEmployeeAvatar = gql`
  mutation ${AvatarGraphQLActionType.updateEmployeeAvatar}($attributes: UpdateEmployeeAvatarAttributes!) {
    ${AvatarGraphQLActionType.updateEmployeeAvatar}(attributes: $attributes) {
      employee {
        ...EmployeeFragment
      }
    }
  }
  ${EmployeeFragment}
`;

export const RemoveEmployeeAvatar = gql`
  mutation ${AvatarGraphQLActionType.removeEmployeeAvatar}($attributes: RemoveAvatarAttributes!) {
    ${AvatarGraphQLActionType.removeEmployeeAvatar}(attributes: $attributes) {
      employee {
        ...EmployeeFragment
      }
    }
  }
  ${EmployeeFragment}
`;

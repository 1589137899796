import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gw-findings-summary-switch',
  templateUrl: './findings-summary-switch.component.html',
  styleUrls: ['./findings-summary-switch.component.scss']
})
export class FindingsSummarySwitchComponent implements OnInit {
  @Input() currentSection: string;
  @Input() disabled = false;
  @Output() toggleSection = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  goTo(section: string): void {
    this.toggleSection.emit(section);
  }
}

import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
// Models
import { Report } from '@gutwin-audit/shared/models/report.model';
// Providers
import { ReportsService } from '@gutwin-audit/shared/services/reports.service';
// import { RoleService } from '../../shared/services/role.service';

@Injectable()
export class ReportsResolve implements Resolve<Array<Report>> {
  constructor(
    private reportsService: ReportsService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) { }

  resolve(route: ActivatedRouteSnapshot): Promise<Array<Report>> {
    return this.reportsService.getReports()
      .catch(error => {
        this.fetchErrorTranslation()
          .then((translation: any) => {
            this.notificationsService.error(translation.title, translation.text);
          });
        return [];
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'ADMIN_SETTINGS.REPORTS.ERROR.LOAD_REPORTS'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['ADMIN_SETTINGS.REPORTS.ERROR.LOAD_REPORTS']
        };
      });
  }
}

export enum IntervalId {
  daily = 'daily',
  everySecondDay = 'everySecondDay',
  everyWeek = 'everyWeek',
  everyTwoWeeks = 'everyTwoWeeks',
  monthly = 'monthly',
  quarterly = 'quarterly',
  halfYearly = 'halfYearly',
  yearly = 'yearly',
  everyTwoYears = 'everyTwoYears',
  everyThreeYears = 'everyThreeYears',
  everyFourYears = 'everyFourYears',
  everyFiveYears = 'everyFiveYears',
  everySixYears = 'everySixYears',
  everySevenYears = 'everySevenYears',
  everyEightYears = 'everyEightYears',
  everyNineYears = 'everyNineYears',
  everyTenYears = 'everyTenYears'
}

export enum IntervalApi {
  daily = 'daily',
  monthly = 'monthly',
  quarterly = 'quarterly',
  halfYearly = 'half-yearly',
  everyWeek = 'weekly',
  yearly = 'yearly',
  everySecondDay = 'days.every_2nd',
  everyTwoWeeks = 'weeks.every_2nd',
  everyTwoYears = 'years.every_2nd',
  everyThreeYears = 'years.every_3rd',
  everyFourYears = 'years.every_4th',
  everyFiveYears = 'years.every_5th',
  everySixYears = 'years.every_6th',
  everySevenYears = 'years.every_7th',
  everyEightYears = 'years.every_8th',
  everyNineYears = 'years.every_9th',
  everyTenYears = 'years.every_10th'
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { Link } from '../../models/link.model';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-edit-link',
  templateUrl: './edit-link.component.html',
  styleUrls: ['./edit-link.component.scss'],
  exportAs: 'gwEditLink'
})
export class EditLinkComponent implements OnInit {
  @Input() link: Link;
  @Input() isEditMode: boolean;
  @Input() isUserTypeClient?: boolean;

  @Output() submitEditLinkForm = new EventEmitter();
  @Output() togglePopoverStatus = new EventEmitter<void>();

  form: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      url: ['', Validators.required]
    });
  }

  resetForm(): void {
    this.form.get('name').setValue(this.link.name || '');
    this.form.get('url').setValue(this.link.url || '');
    this.submitted = false;
  }

  setControlValue(data: any, key: string): void {
    this.form.get(key).setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  submitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.submitEditLinkForm.emit(this.form.value);
    }
  }

  increaseOpenedEdits(): void {
    this.togglePopoverStatus.emit();
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.togglePopoverStatus.emit();
    this.store.dispatch(new DecreaseOpenedEdits());
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
// Gutwin Shared Library
import { OfflineService } from 'gutwin-shared';
// Models
import { Audit } from './../../shared/models/audit.model';
// Services
import { AuditService } from './../../shared/services/audit.service';
import { AuditOfflineService, AuditId } from './../../shared/services/audit.offline.service';
import { FindingService } from './../../shared/services/finding.service';
import { PermissionService } from './../../shared/services/permission.service';
import { RatingScalesService } from './../../shared/services/raiting-scales.service';
import { StoragesNamesModule, StorageModuleService } from './../../shared/services/storage-module.service';
import { UserService } from './../../shared/services/user.service';

@Component({
  selector: 'gw-audit-summary-view',
  templateUrl: './audit-summary-view.component.html',
  styleUrls: ['./audit-summary-view.component.scss']
})
export class AuditSummaryViewComponent implements OnInit, OnDestroy {
  audit: Audit;
  auditedAreaIdToBack: string;
  syncingAuditOffline: boolean;
  notify = {
    error: {
      syncOfflineTitle: '',
      syncOfflineText: ''
    },
    success: {
      syncOfflineTitle: '',
      syncOfflineText: ''
    }
  };
  translateSubscription: Subscription;
  auditsIdsOfflineSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private auditService: AuditService,
    private auditOfflineService: AuditOfflineService,
    private notificationsService: NotificationsService,
    private findingService: FindingService,
    private permissionService: PermissionService,
    private ratingScalesService: RatingScalesService,
    private userService: UserService,
    private storageService: StorageModuleService,
    private translateService: TranslateService,
    public offlineService: OfflineService
  ) { }

  ngOnInit() {
    this.initData();
    this.fetchTranslations();
  }

  ngOnDestroy() {
    this.storageService.removeFromStorage(StoragesNamesModule.auditSummaryRedirect);
    this.translateSubscription.unsubscribe();
  }

  initData(): void {
    this.audit = this.route.snapshot.data['audit'];
    this.markAuditDisabled();
    this.checkAuditOffline();
    this.auditedAreaIdToBack = this.storageService.getStorage(StoragesNamesModule.auditSummaryRedirect);
  }

  checkAuditOffline(): void {
    this.auditOfflineService.getAuditsIdsFromOfflineStore()
      .then(auditsIds => {
        this.markAuditOffline(auditsIds);
      });
    this.auditsIdsOfflineSubscription = this.auditOfflineService.auditsIdsOfflineObservable
      .subscribe(auditsIds => {
        this.markAuditOffline(auditsIds);
      });
  }

  markAuditOffline(auditsIds: Array<AuditId>): void {
    if (auditsIds && this.audit) {
      const auditIdOffline = _.find(auditsIds, {id: this.audit.id});
      if (auditIdOffline) {
        this.audit.offline = true;
        this.audit.offlineDate = auditIdOffline.date;
      }
    }
  }

  markAuditDisabled(): void {
    this.audit.disabled = !this.permissionService.canAuditBeManaged(this.audit, this.userService.storedUser);
  }

  getBackNavigation(): Array<any> {
    if (this.auditedAreaIdToBack) {
      return ['/audits/conduct-audit', this.audit.id, this.auditedAreaIdToBack];
    } else {
      let state = '';
      switch (this.audit.state) {
        case 'draft':
          state = 'drafts';
          break;
        case 'in_progress':
          state = 'in-progress';
          break;
        case 'finished':
          state = 'finished';
          break;
      }
      return [`/audits/${state}`];
    }
  }

  syncAudit(audit: Audit): void {
    this.syncingAuditOffline = true;
    const promises = new Array<Promise<any>>();
    promises.push(this.auditService.syncWholeAuditOffline(audit));
    promises.push(this.ratingScalesService.saveRatingScaleOffline(audit));
    promises.push(this.findingService.saveFindingTypesOffline());
    promises.push(this.userService.saveEmployeesOffline());
    Promise.all(promises)
      .then(() => {
        this.syncingAuditOffline = false;
        this.notificationsService.success(this.notify.success.syncOfflineTitle, this.notify.success.syncOfflineText);
      })
      .catch(error => {
        this.syncingAuditOffline = false;
        this.notificationsService.error(this.notify.error.syncOfflineTitle, this.notify.error.syncOfflineText);
      });
  }

  fetchTranslations(): void {
    this.translateSubscription = this.translateService.get([
      'AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TITLE',
      'AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TEXT',
      'AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TITLE',
      'AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TEXT'
      ])
      .subscribe((translation: any) => {
        this.notify.error.syncOfflineTitle = translation['AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TITLE'];
        this.notify.error.syncOfflineText = translation['AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TEXT'];
        this.notify.success.syncOfflineTitle = translation['AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TITLE'];
        this.notify.success.syncOfflineText = translation['AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TEXT'];
      });
  }
}

import gql from 'graphql-tag';

export const FileFragment = gql`
  fragment FileFragment on File {
    url
    name
    size
    mimeType
  }
`;

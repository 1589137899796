import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
// Models
import { Audit } from './../../shared/models/audit.model';
// Providers
import { AuditService } from '../../shared/services/audit.service';
import { PageStatusService } from './../../shared/services/page-status.service';

@Injectable()
export class AuditResolve implements Resolve<any> {
  constructor(
    private auditService: AuditService,
    private pageStatusService: PageStatusService,
    private translateService: TranslateService,
    private notificationsService: NotificationsService
  ) { }

  resolve (route: ActivatedRouteSnapshot): Promise<Audit> {
    return this.auditService.getAudit(route.params['auditId'])
      .then(async(audit) => {
        if (route.params['auditedAreaId']) {
          if (!this.hasAuditedArea(audit, route.params['auditedAreaId'])) {
            const translation404 = await this.fetchMessage404Translation('AuditedArea');
            this.pageStatusService.goTo404(translation404);
            return undefined;
          }
        }
        return audit;
      })
      .catch(async(error) => {
        switch (error.status) {
          case 403:
            const translation403 = await this.fetchMessage403Translation();
            this.pageStatusService.goTo403(translation403);
            break;
          case 404:
            const translation404 = await this.fetchMessage404Translation('Audit');
            this.pageStatusService.goTo404(translation404);
            break;
        }
        this.fetchErrorTranslation()
          .then((translation: any) => {
            this.notificationsService.error(translation.title, translation.text);
          });
        return undefined;
      });
  }

  hasAuditedArea(audit: Audit, auditedAreaId: string): boolean {
    if (audit.auditedAreas) {
      return !!~_.findIndex(audit.auditedAreas, {id: auditedAreaId});
    }
    return false;
  }

  fetchMessage404Translation(auditableType: 'Audit' | 'AuditedArea'): Promise<string> {
    const observable = auditableType === 'Audit' ?
      this.translateService.get('GLOBAL.ERROR.NOT_FOUND.AUDIT') :
      this.translateService.get('GLOBAL.ERROR.NOT_FOUND.AUDITED_AREA');

    return observable
      .toPromise()
      .then((translation: string) => {
        return translation;
      });
  }

  fetchMessage403Translation(): Promise<string> {
    return this.translateService.get('GLOBAL.ERROR.NOT_PERMITTED.AUDIT')
      .toPromise()
      .then((translation: string) => {
        return translation;
      });
  }

  fetchErrorTranslation(): Promise<any> {
    return this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ERROR.LOAD_AUDIT'
    ])
      .toPromise()
      .then((translation: Array<string>) => {
        return {
          title: translation['GLOBAL.ERROR.CONNECTION'],
          text: translation['GLOBAL.ERROR.LOAD_AUDIT']
        };
      });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Link } from '../../models/link.model';

import { LinkToUpdate } from '../../interfaces/link-to-update.interface';

import { SingleChangeEditableFields } from '../../enums/single-change-editable-fields.enum';
import { UserType } from '../../enums/user-type.enum';

import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'gw-sidebar-links',
  templateUrl: './sidebar-links.component.html'
})
export class SidebarLinksComponent implements OnInit {
  @Input() alignTitle?: boolean;
  @Input() isEditMode: boolean;
  @Input() links: Array<Link>;
  @Input() inlineHeader = false;
  @Input() popoverTitle?: string;
  @Input() titleMarginBottom?: boolean;
  @Input() userType: UserType;

  @Output() addLink = new EventEmitter<Link>();
  @Output() changeLink = new EventEmitter<LinkToUpdate>();
  @Output() removeLink = new EventEmitter<Link>();
  @Output() togglePopoverStatus = new EventEmitter<SingleChangeEditableFields>();

  singleChangeEditableFields = SingleChangeEditableFields;
  translation = {
    removeDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    }
  };

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.fetchTranslation();
  }

  async showRemoveLinkModal(link: Link): Promise<void> {
    try {
      await this.dialogService.confirm(
        this.translation.removeDialog.header,
        this.translation.removeDialog.content,
        this.translation.removeDialog.cancel,
        this.translation.removeDialog.confirm
      );
      await this.removeLink.emit(link);
    } catch (error) {}
  }

  onAddLink(link: Link): void {
    this.addLink.emit(link);
  }

  onChangeLink(oldLink: Link, newLink: Link): void {
    this.changeLink.emit({ oldLink, newLink });
  }

  onTogglePopoverStatus(status: SingleChangeEditableFields): void {
    this.togglePopoverStatus.emit(status);
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GUTWIN_SHARED.ACTION.CANCEL',
        'GUTWIN_SHARED.ACTION.CONFIRM',
        'GUTWIN_SHARED.LABEL.WARNING',
        'GUTWIN_SHARED.LAW_DETAILS.WARNING.REMOVE_LINK'
      ])
      .toPromise();
    this.translation.removeDialog.header = translation['GUTWIN_SHARED.LABEL.WARNING'];
    this.translation.removeDialog.content = translation['GUTWIN_SHARED.LAW_DETAILS.WARNING.REMOVE_LINK'];
    this.translation.removeDialog.cancel = translation['GUTWIN_SHARED.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GUTWIN_SHARED.ACTION.CONFIRM'];
  }
}

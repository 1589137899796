export interface FindingsStatsRating {
  id: string;
  name: string;
  count: number;
}

export class FindingsStats {
  totalFindings: number;
  findingTypes: Array<FindingsStatsRating>;
  offline?: boolean;

  constructor(findingsStats: any) {
    this.totalFindings = findingsStats.totalFindings;
    this.findingTypes = findingsStats.findingTypes;
    this.offline = findingsStats.offline;
  }
}

import { Injectable } from '@angular/core';

import * as _ from 'lodash';

// Gutwin Shared Library
import { Attachment, Employee } from 'gutwin-shared';

// Models
import { Audit, ModifiedReport } from '../models/audit.model';
import { AuditedArea } from '../models/audited-area.model';
import { AuditsAmount } from '../models/audits-amount.model';
import { RatingScale } from '../models/rating-scale.model';

// Interfaces
import { EmployeesGroups } from '../interfaces/employeesGroups.interface';

// Services
import { Progress } from './progress.service';

export interface AuditsResponse {
  counters: any;
  audits: Array<AuditResponse>;
  page: number;
}

export interface AuditResponse {
  id: string;
  name: string;
  start_time: string;
  end_time: string;
  state: string;
  summary: string;
  types: Array<any>;
  type: Array<string>;
  roles: Array<any>;
  auditors: Array<any>;
  status: number;
  archived: boolean;
  audited_areas: Array<AuditedAreaResponse>;
  created_at: string;
  modified_report: Attachment;
  is_template: boolean;
  rating_scale: RatingScale;
}

export interface AuditedAreaResponse {
  id: string;
  audit_id: string;
  start_time?: string;
  end_time?: string;
  facility?: any;
  facility_name?: string;
  roles?: Array<any>;
  summary?: string;
  location?: string;
}

export interface AuditFromTemplateRequest {
  id: string;
  name: string;
  lead_auditors_ids: Array<string>;
  co_auditors_ids: Array<string>;
  audited_areas?: Array<AuditedAreaFromTemplateRequest>;
}

export interface AuditedAreaFromTemplateRequest {
  id: string;
  facility_id: string;
  location: string;
  start_time: string;
  end_time: string;
  auditors_ids: Array<string>;
  auditees_ids: Array<string>;
}

export class AuditProgress {
  id: string;
  status: string;
  audit: Progress;
  sections: Progress;
  findings: Progress;

  constructor(auditProgress: any) {
    this.id = auditProgress.id;
    this.audit = new Progress(auditProgress.audit);
    this.sections = new Progress(auditProgress.sections);
    this.findings = new Progress(auditProgress.findings);
  }
}

@Injectable()
export class AuditConvertService {
  constructor() {}

  convertAuditToPost(audit: Audit, oldAudit?: Audit): any {
    const auditTypes = audit.type ? [audit.type.id] : [];
    const auditRequest = {
      name: audit.name,
      audit_types: auditTypes,
      state: audit.state,
      summary: audit.summary,
      roles: new Array()
    };
    const oldEmployees = oldAudit ? oldAudit.employees : {};
    auditRequest.roles = this.getUngroupedEmployees(audit.employees, oldEmployees);
    return auditRequest;
  }

  convertAuditFromTemplateToPost(audit: Audit): AuditFromTemplateRequest {
    const convertEmployeesToIds = (employees: Array<Employee>): Array<string> => {
      if (employees) {
        return employees.map(employee => employee.id);
      }
    };

    const convertAuditedAreas = (auditedAreas: Array<AuditedArea>): Array<AuditedAreaFromTemplateRequest> => {
      if (auditedAreas) {
        return auditedAreas.map(auditedArea => ({
          id: auditedArea.id,
          location: auditedArea.location,
          facility_id: auditedArea.facility.id,
          start_time: auditedArea.startTime.format(),
          end_time: auditedArea.endTime.format(),
          auditors_ids: convertEmployeesToIds(auditedArea.employees.auditor),
          auditees_ids: convertEmployeesToIds(auditedArea.employees.auditee)
        }));
      }
      return [];
    };

    return {
      id: audit.id,
      name: audit.name,
      lead_auditors_ids: convertEmployeesToIds(audit.employees.leadAuditor),
      co_auditors_ids: convertEmployeesToIds(audit.employees.coAuditor),
      audited_areas: convertAuditedAreas(audit.auditedAreas)
    };
  }

  convertAuditsToGet(data: AuditsResponse): any {
    const auditsList = new Array<Audit>();
    if (data) {
      data.audits.forEach((audit: AuditResponse) => {
        auditsList.push(this.convertAuditToGet(audit));
      });
      return {
        audits: auditsList,
        page: data.page
      };
    }
  }

  convertAuditsAmountToGet(counters: any): AuditsAmount {
    return new AuditsAmount({
      totalInProgress: counters.total_in_progress_count,
      filteredInProgress: counters.filtered_in_progress_count,
      totalDrafts: counters.total_drafts_count,
      filteredDrafts: counters.filtered_drafts_count,
      totalFinished: counters.total_finished_count,
      filteredFinished: counters.filtered_finished_count
    });
  }

  convertAuditToGet(audit: AuditResponse): Audit {
    const employees = audit.roles || audit.auditors;
    const type = audit.types ? audit.types[0] : audit.type ? { title: audit.type } : undefined;
    const auditRequest = {
      id: audit.id,
      name: audit.name,
      status: audit.status,
      state: audit.state,
      startTime: audit.start_time,
      endTime: audit.end_time,
      summary: audit.summary,
      modifiedReport: audit.modified_report ? new ModifiedReport(audit.modified_report) : undefined,
      type,
      employees: this.getGroupedEmployees(employees),
      auditedAreas: this.convertAuditedAreasToGet(audit.audited_areas),
      isTemplate: audit.is_template,
      ratingScale: audit.rating_scale
    };
    return new Audit(auditRequest);
  }

  convertedAuditedAreaToPost(parentAuditId: string, auditedArea: AuditedArea, oldAuditedArea?: AuditedArea): any {
    const auditRequest = this.convertedAuditedAreaToPut(auditedArea, oldAuditedArea);
    auditRequest.audit_id = parentAuditId;
    return auditRequest;
  }

  convertedAuditedAreaToPut(auditedArea: AuditedArea, oldAuditedArea?: AuditedArea): any {
    const auditRequest = {
      location: auditedArea.location,
      facility_id: auditedArea.facility.id,
      start_time: auditedArea.startTime.format(),
      end_time: auditedArea.endTime.format(),
      roles: new Array()
    };
    const oldEmployees = oldAuditedArea ? oldAuditedArea.employees : {};
    auditRequest.roles = this.getUngroupedEmployees(auditedArea.employees, oldEmployees);
    return auditRequest;
  }

  convertAuditedAreaToGet(auditedArea: AuditedAreaResponse): AuditedArea {
    const facility = auditedArea.facility
      ? auditedArea.facility
      : auditedArea.facility_name
      ? { name: auditedArea.facility_name }
      : undefined;
    const auditRequest = {
      id: auditedArea.id,
      auditId: auditedArea.audit_id,
      location: auditedArea.location,
      startTime: auditedArea.start_time,
      endTime: auditedArea.end_time,
      facility,
      summary: auditedArea.summary,
      employees: this.getGroupedEmployees(auditedArea.roles)
    };
    return new AuditedArea(auditRequest);
  }

  convertAuditedAreasToGet(auditedAreas: Array<any>): Array<AuditedArea> {
    const auditedAreasRequest = new Array<AuditedArea>();
    if (auditedAreas) {
      auditedAreas.forEach(auditedArea => {
        auditedAreasRequest.push(this.convertAuditedAreaToGet(auditedArea));
      });
    }
    return auditedAreasRequest;
  }

  private getUngroupedEmployees(employees: EmployeesGroups, oldEmployees = {}): any {
    const roles = new Array();
    for (const role in employees) {
      if (employees[role]) {
        const oldEmployeesIds = oldEmployees ? this.getEmployeeIds(oldEmployees[role]) : [];
        const newEmployeesIds = this.getEmployeeIds(employees[role]);
        oldEmployeesIds.forEach(employeeId => {
          if (newEmployeesIds.indexOf(employeeId) === -1) {
            roles.push({
              status: 'delete',
              role: _.snakeCase(role),
              employee_id: employeeId
            });
          }
        });
        newEmployeesIds.forEach(employeeId => {
          if (oldEmployeesIds.indexOf(employeeId) === -1) {
            roles.push({
              status: 'create',
              role: _.snakeCase(role),
              employee_id: employeeId
            });
          }
        });
      }
    }
    return roles;
  }

  private getGroupedEmployees(roles: any): EmployeesGroups {
    const employees = {
      creator: new Array<Employee>(),
      organizer: new Array<Employee>(),
      auditor: new Array<Employee>(),
      leadAuditor: new Array<Employee>(),
      coAuditor: new Array<Employee>(),
      auditee: new Array<Employee>()
    };
    if (roles) {
      roles.forEach(role => {
        employees[_.camelCase(role.role)].push(role.employee);
      });
    }
    return employees;
  }

  private getEmployeeIds(employees: Array<Employee>): Array<string> {
    const employeesIds = new Array<string>();
    if (employees) {
      employees.forEach(employee => {
        employeesIds.push(employee.id);
      });
    }
    return employeesIds;
  }
}

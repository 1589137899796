import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { SearchTheme } from '../../enums/search-theme.enum';

@Component({
  selector: 'gw-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  @Input() theme: SearchTheme = SearchTheme.Classic;
  @Input() small = false;
  @Input() rounded = false;
  @Input() minCharacters = 0;
  @Input() foldable: boolean;

  searchValue: string;
  searchChanged = new BehaviorSubject<string>('');
  unfolded: boolean;

  onChange: (_: string) => void = () => {};

  destroy$ = new Subject<void>();

  readonly SEARCH_THEME = SearchTheme;

  constructor(private changeDetector: ChangeDetectorRef, private element: ElementRef) {}

  ngOnInit(): void {
    this.observeSearch();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  writeValue(search: string): void {
    this.changeSearch(search);
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  changeSearch(search: string): void {
    this.searchChanged.next(search);
    this.searchValue = search;
  }

  observeSearch(): Subscription {
    return this.searchChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        filter(search => search?.length >= this.minCharacters || !search?.length),
        takeUntil(this.destroy$)
      )
      .subscribe((search: string) => {
        this.onChange(search);
        this.changeDetector.markForCheck();
      });
  }

  clearSearchInput(): void {
    this.changeSearch('');
    this.searchInput.nativeElement.focus();
  }

  toggleInputUnfold(): void {
    if (this.foldable && !this.searchValue) {
      this.unfolded = !this.unfolded;

      // setTimeout is needed in order not to break the search input animation
      if (this.unfolded) setTimeout(() => this.searchInput.nativeElement.focus(), 300);
    }
  }

  @HostListener('document:click', ['$event'])
  clickOutside({ target }: Event): void {
    if (this.foldable) {
      const canFold = this.unfolded && !this.element.nativeElement.contains(target);
      if (canFold) this.toggleInputUnfold();
    }
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TreeNode } from '../../models/tree-node.model';

import { DropPosition } from '../../interfaces/drop-position.interface';

import { TreeDragEvent } from '../../types/tree-drag-event.type';

import { ExpandBoxComponent } from '../expand-box/expand-box.component';

import { TreeService } from '../../services/tree.service';

@Component({
  selector: 'gw-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeComponent implements OnInit {
  @Input() set nodes(nodes: Array<TreeNode>) {
    this.treeNodes = nodes;
  }
  @Input() draggable = true;
  @Input() draggableRoot = true;
  @Input() gray: boolean;
  @Input() draggedParent: boolean;
  @Input() singleLine = false;
  @Input() isParentFullyClosed?: boolean;
  @Input() labelWithIcon?: boolean;
  @Input() boldActionButtons?: boolean;
  @Input() set nodeDropPosition(nodeDropPosition: DropPosition) {
    this.setDropPosition(nodeDropPosition);
  }
  @Input() set movedNode(movedNode: TreeNode) {
    this.draggedNode = movedNode;
  }
  @Input() set parentNodeElement(parentNodeElement: TreeNode) {
    this.parentNode = parentNodeElement;
  }
  @Input() set nodesIdsToOpen(nodesIdsToOpen: Array<string>) {
    this.markNodesToOpen(nodesIdsToOpen);
  }

  @Output() dragStartNode = new EventEmitter<TreeNode>();
  @Output() dragEndNode = new EventEmitter<void>();
  @Output() changeDropPosition = new EventEmitter<DropPosition>();
  @Output() moveNode = new EventEmitter<{ index: number; node: TreeNode; parentNode: TreeNode }>();
  @Output() addNode = new EventEmitter<TreeNode>();
  @Output() updateNode = new EventEmitter<{ node: TreeNode; parent: TreeNode }>();
  @Output() removeNode = new EventEmitter<TreeNode>();
  @Output() toggleNode = new EventEmitter<TreeNode>();

  dragEnterCounter = 0;
  draggedNode: TreeNode;
  dropPosition: DropPosition;
  parentNode: TreeNode = { id: 'root', name: 'root' };
  treeNodes: Array<TreeNode>;

  constructor(private changeDetectionRef: ChangeDetectorRef, private treeService: TreeService) {}

  ngOnInit(): void {
    if (this.parentNode.id === 'root') this.parentNode.children = this.nodes;
  }

  markNodesToOpen(nodesIdsToOpen: Array<string>): void {
    this.treeNodes = this.treeService.markNodes(this.treeNodes, nodesIdsToOpen, 'isOpened');
    this.changeDetectionRef.markForCheck();
  }

  onExpandBoxClick(expandBox: ExpandBoxComponent, node: TreeNode): void {
    expandBox.toggle();
    this.toggleNode.emit({ ...node, isOpened: expandBox.opened });
  }

  onToggleNode(node: TreeNode): void {
    this.toggleNode.emit(node);
  }

  isElementParent(element: Element, parentNode: Node & { id?: string }): boolean {
    if (element.id === parentNode.id) return true;
    else if (parentNode.parentNode) return this.isElementParent(element, parentNode.parentNode);
    return false;
  }

  changeNodePlace(index: number, node: TreeNode, parentNode: TreeNode): void {
    this.moveNode.emit({ index, node, parentNode });
    this.changeDetectionRef.detectChanges();
  }

  startDragging(node: TreeNode): void {
    this.draggedNode = node;
    this.dragStartNode.emit(node);
  }

  endDragging(): void {
    this.draggedNode = undefined;
    this.dragEndNode.emit();
    this.dragEnterCounter = 0;
    this.setDropPosition(undefined);
  }

  dragStart(event: TreeDragEvent, element: Element, node: TreeNode): void {
    if (event.dataTransfer) {
      event.dataTransfer.setData('text', event.target.id);
      if (event.dataTransfer.setDragImage) {
        event.dataTransfer.setDragImage(element, event.offsetX, event.offsetY);
      }
    }
    this.startDragging(node);
  }

  dragEnd(): void {
    this.endDragging();
  }

  dragEnter(event: TreeDragEvent, node: TreeNode, index?: number): void {
    this.dragEnterCounter = this.dragEnterCounter + 1;
    this.setDropPosition({ node, index });
    event.preventDefault();
  }

  dragLeave(event: TreeDragEvent, node: TreeNode, index?: number): void {
    this.dragEnterCounter = this.dragEnterCounter - 1;
    if (this.dragEnterCounter <= 0) {
      if (this.dropPosition && this.dropPosition.node.id === node.id && this.dropPosition.index === index) {
        this.setDropPosition(undefined);
      }
    }
    event.preventDefault();
  }

  drop(parentNode: TreeNode, index?: number): void {
    if (this.draggedNode && !this.draggedParent && parentNode && parentNode.id !== this.draggedNode.id) {
      if (!index) index = parentNode.children ? parentNode.children.length - 1 : 0;
      parentNode = parentNode.id === 'root' ? undefined : parentNode;
      this.changeNodePlace(index, this.draggedNode, parentNode);
    }
    this.dragEnterCounter = 0;
    this.setDropPosition(undefined);
    this.endDragging();
  }

  setDropPosition(dropPosition: DropPosition): void {
    this.dropPosition = dropPosition;
    this.changeDropPosition.emit(dropPosition);
  }
}

export class Permissions {
  manageQuestionnaires?: boolean;
  manageUsers?: boolean;
  manageFacilities?: boolean;
  manageAuditTypes?: boolean;
  manageFindingTypes?: boolean;
  manageQuestionRatings?: boolean;
  manageUserRoles?: boolean;
  manageCompany?: boolean;
  companyCreation?: boolean;
  createAudits?: boolean;
  accessAllAudits?: boolean;
  manageFinishedAudits?: boolean;
  manageSolvedFindings?: boolean;
  manageReportTemplates?: boolean;
  manageCauseAndSolution?: boolean;

  constructor(permissions?: Permissions) {
    this.manageQuestionnaires = permissions ? permissions.manageQuestionnaires : false;
    this.manageUsers = permissions ? permissions.manageUsers : false;
    this.manageFacilities = permissions ? permissions.manageFacilities : false;
    this.manageAuditTypes = permissions ? permissions.manageAuditTypes : false;
    this.manageFindingTypes = permissions ? permissions.manageFindingTypes : false;
    this.manageQuestionRatings = permissions ? permissions.manageQuestionRatings : false;
    this.manageUserRoles = permissions ? permissions.manageUserRoles : false;
    this.manageCompany = permissions ? permissions.manageCompany : false;
    this.companyCreation = permissions ? permissions.companyCreation : false;
    this.createAudits = permissions ? permissions.createAudits : false;
    this.accessAllAudits = permissions ? permissions.accessAllAudits : false;
    this.manageFinishedAudits = permissions ? permissions.manageFinishedAudits : false;
    this.manageSolvedFindings = permissions ? permissions.manageSolvedFindings : false;
    this.manageReportTemplates = permissions ? permissions.manageReportTemplates : false;
    this.manageCauseAndSolution = permissions ? permissions.manageCauseAndSolution : false;
  }
}

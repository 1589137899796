
<gw-modal id="cropAvatarModal" #cropAvatarModal="gwModal">
  <ng-container modal-header="modal-header">{{ 'USER_PROFILE.MODAL.HEADER.CROP_AVATAR' | translate }}</ng-container>
  <ng-container modal-content="modal-content">
    <gw-image-cropper-view #imageCropper="gwImageCropperView" [base64]="cropAvatarModal.data" [displayOptions]="{ rounded: true }"></gw-image-cropper-view>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="cropAvatarModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="acceptImage(imageCropper.preparePicture())">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="user-page">
  <gw-audit-top-bar [component]="'profile'" [currentUser]="user"></gw-audit-top-bar>
  <gw-page-header>
    <ng-container page-header-left="page-header-left">
      <ul class="header__menu header__menu--left">
        <li class="header__item"><a class="button button--link header__button--transparent header__button--menu" (click)="location.back()"><i class="button__icon gw-arrow--left"></i></a></li>
        <li class="header__item header__item--title">{{ 'USER_PROFILE.PAGE_HEADER' | translate }}</li>
      </ul>
    </ng-container>
  </gw-page-header>
  <div class="content">
    <div class="row">
      <div class="columns small-12 medium-6 large-5">
        <div class="user-profile">
          <h1 class="user-profile__header">{{ 'USER_PROFILE.HEADER' | translate }}</h1>
          <div class="row">
            <div class="columns small-12 medium-12 large-10">
              <div class="user-profile__content">
                <form class="user-profile__form" *ngIf="userForm" [formGroup]="userForm" novalidate="novalidate" autocomplete="off">
                  <div class="user-profile__fieldset">
                    <div class="user-profile__fields">
                      <div class="form__field user-profile__field">
                        <gw-avatar class="user-profile__avatar" *ngIf="!uploadedImage?.preview" [employee]="userForm.value" [big]="true"></gw-avatar>
                        <gw-avatar class="user-profile__avatar--preview" *ngIf="uploadedImage?.preview" [employee]="{ avatar: { url: this.uploadedImage?.preview } }" [big]="true" [preview]="true"></gw-avatar>
                        <div class="button-switch user-profile__avatar-button">
                          <label class="button--tertiary" for="upload" tabindex="1" (keydown)="keyDownAvatarControl($event, fileInput)">{{ 'USER_PROFILE.FORM.ACTION.CHANGE_AVATAR' | translate }}
                            <input class="hide" #fileInput="" type="file" id="upload" (change)="attachFiles($event)" placeholder="Upload file" accept=".jpg, .jpeg, .png, .gif"/>
                          </label>
                          <gw-button-dropdown #buttonDropdown="buttonDropdown" [buttonClasses]="['button--tertiary']">
                            <ng-container button-dropdown-list="button-dropdown-list">
                              <li class="button-dropdown-list__item">
                                <button class="link user-profile__dropdown-button" type="button" (click)="removeAvatar(); buttonDropdown.closeDropdown()">{{ 'USER_PROFILE.FORM.ACTION.REMOVE_AVATAR' | translate }}</button>
                              </li>
                            </ng-container>
                          </gw-button-dropdown>
                        </div>
                      </div>
                      <div class="form__field user-profile__field" [class.form__field--error]="isFieldInvalid(userForm.controls['name'])">
                        <label class="label" for="name">{{ 'USER_PROFILE.FORM.LABEL.NAME' | translate }}</label>
                        <div class="form-field__error">{{ 'USER_PROFILE.FORM.ERROR.NAME_EMPTY' | translate }}</div>
                        <input class="input" placeholder="{{ 'USER_PROFILE.FORM.PLACEHOLDER.NAME' | translate }}" type="text" id="name" name="name" tabindex="2" [formControl]="userForm.controls['name']"/>
                      </div>
                      <div class="form__field user-profile__field" [class.form__field--error]="isFieldInvalid(userForm.controls['lastname'])">
                        <label class="label" for="lastname">{{ 'USER_PROFILE.FORM.LABEL.LASTNAME' | translate }}</label>
                        <div class="form-field__error">{{ 'USER_PROFILE.FORM.ERROR.LASTNAME_EMPTY' | translate }}</div>
                        <input class="input" placeholder="{{ 'USER_PROFILE.FORM.PLACEHOLDER.LASTNAME' | translate }}" type="text" id="lastname" name="lastname" tabindex="3" [formControl]="userForm.controls['lastname']"/>
                      </div>
                      <div class="form__field user-profile__field" [class.form__field--error]="isFieldInvalid(userForm.controls['email'])">
                        <label class="label" for="email">{{ 'USER_PROFILE.FORM.LABEL.EMAIL' | translate }}</label>
                        <div class="form-field__error" *ngIf="isFieldEmpty(userForm.controls['email'])">{{ 'USER_PROFILE.FORM.ERROR.EMAIL_EMPTY' | translate }}</div>
                        <div class="form-field__error" *ngIf="!isFieldEmpty(userForm.controls['email']) &amp;&amp; isEmailInvalid(userForm.controls['email'])">{{ 'USER_PROFILE.FORM.ERROR.EMAIL_PATTERN' | translate }}</div>
                        <input class="input" placeholder="{{ 'USER_PROFILE.FORM.PLACEHOLDER.EMAIL' | translate }}" type="email" id="email" name="email" tabindex="4" [formControl]="userForm.controls['email']"/>
                      </div>
                      <div class="form__field small-6 user-profile__field" [class.form__field--error]="isFieldInvalid(userForm.controls['language'])">
                        <label class="label" for="language" (click)="languageControl.focusDropdown()">{{ 'USER_PROFILE.FORM.LABEL.LANGUAGE' | translate }}</label>
                        <div class="form-field__error">{{ 'USER_PROFILE.FORM.ERROR.LANGUAGE_EMPTY' | translate }}</div>
                        <gw-select #languageControl="gwSelect" [formControl]="userForm.controls['language']" [placeholder]="'USER_PROFILE.FORM.PLACEHOLDER.LANGUAGE' | translate" [data]="languages" [display]="['name']" [uniqueKey]="'id'" [small]="true" [virtualScroll]="false" [tabindex]="7"></gw-select>
                      </div>
                      <div *ngIf="passwordChanging">
                        <div class="form__field user-profile__field" [class.form__field--error]="isNewPasswordFieldInvalid(userForm, userForm.controls['newPassword'])">
                          <label class="label" for="newPassword">{{ 'USER_PROFILE.FORM.LABEL.NEW_PASSWORD' | translate }}</label>
                          <div class="form-field__error" *ngIf="userForm.controls['newPassword'].errors?.minlength">{{ 'USER_PROFILE.FORM.ERROR.PASSWORD_TO_SHORT' | translate }}</div>
                          <div class="form-field__error" *ngIf="userForm.controls['newPassword'].errors?.maxlength">{{ 'USER_PROFILE.FORM.ERROR.PASSWORD_TO_LONG' | translate }}</div>
                          <input class="input" placeholder="{{ 'USER_PROFILE.FORM.PLACEHOLDER.NEW_PASSWORD' | translate }}" type="password" id="newPassword" name="newPassword" tabindex="5" [formControl]="userForm.controls['newPassword']"/>
                        </div>
                        <div class="form__field user-profile__field" [class.form__field--error]="isNewPasswordFieldInvalid(userForm, userForm.controls['newPasswordConfirmed'])">
                          <label class="label" for="newPasswordConfirmed">{{ 'USER_PROFILE.FORM.LABEL.CONFIRM_NEW_PASSWORD' | translate }}</label>
                          <div class="form-field__error" *ngIf="userForm.errors?.passwordNotConfirmed">{{ 'USER_PROFILE.FORM.ERROR.PASSWORD_NOT_MATCH' | translate }}</div>
                          <input class="input" placeholder="{{ 'USER_PROFILE.FORM.PLACEHOLDER.CONFIRM_NEW_PASSWORD' | translate }}" type="password" id="newPasswordConfirmed" name="newPasswordConfirmed" tabindex="6" [formControl]="userForm.controls['newPasswordConfirmed']"/>
                        </div>
                      </div>
                      <div class="form__field user-profile__field">
                        <label class="label" *ngIf="!passwordChanging">{{ 'USER_PROFILE.FORM.LABEL.PASSWORD' | translate }}</label>
                        <div class="user-profile__password">
                          <button class="button--tertiary button--small" type="button" (click)="togglePasswordChanging()">
                            <ng-container *ngIf="!passwordChanging">{{ 'USER_PROFILE.FORM.ACTION.CHANGE_PASSWORD' | translate }}</ng-container>
                            <ng-container *ngIf="passwordChanging">{{ 'USER_PROFILE.FORM.ACTION.CANCEL_CHANGING_PASSWORD' | translate }}</ng-container>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="button-group user-profile__button-group">
            <button class="link user-profile__button" type="button" tabindex="8" (click)="resetForm()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
            <button class="button--primary user-profile__button" type="button" tabindex="9" [disabled]="saving" (click)="submitUserForm(userForm)"><i class="button__icon--before button__icon--spinner" *ngIf="saving">
                <div class="spinner"></div></i>{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
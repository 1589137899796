import { FilterOption } from './filter-option.model';
import { LegalQuestion } from './legal-question.model';

export class TreeFilterOption extends FilterOption {
  children?: Array<TreeFilterOption>;
  connectedElements?: number;
  chosenElements?: number;
  hidden?: boolean;
  onPathToSelected?: boolean;
  isFiltered?: boolean;
  onPathToFiltered?: boolean;
  color?: string;
  isDisabled?: boolean;
  isBlocked?: boolean;
  isParentExpanded?: boolean;
  isOpened?: boolean;
  isVisible?: boolean;
  question?: LegalQuestion;

  constructor(option: Partial<TreeFilterOption>) {
    super(option);
    this.children = option.children;
    this.connectedElements = option.connectedElements;
    this.chosenElements = option.chosenElements;
    this.hidden = option.hidden;
    this.onPathToSelected = option.onPathToSelected;
    this.isFiltered = option.isFiltered;
    this.onPathToFiltered = option.onPathToFiltered;
    this.color = option.color;
    this.isDisabled = option.isDisabled;
    this.isBlocked = option.isBlocked;
    this.isOpened = option.isOpened;
    this.isParentExpanded = option.isParentExpanded;
    this.isVisible = option.isVisible;
    this.question = option.question;
  }
}


<div class="tabs">
  <div class="row">
    <div class="columns">
      <ul class="tabs__menu">
        <li class="tabs__item"><a class="tabs__link" [routerLink]="['/audits/audit-summary', audit.id]" [class.tabs__link--active]="currentPath === '/audits/audit-summary/' + audit.id">
            <div class="tabs__link-content">{{ 'AUDIT_SUMMARY.TAB.OVERALL' | translate }}</div></a><a class="tabs__link" *ngFor="let auditedArea of audit.auditedAreas" [routerLink]="['/audits/audit-summary', audit.id, auditedArea.id]" [class.tabs__link--active]="currentPath === '/audits/audit-summary/' + audit.id + '/' + auditedArea.id">
            <div class="tabs__link-content">{{ auditedArea.facility.name }}</div></a></li>
      </ul>
    </div>
  </div>
</div>
import { CompanyLicense } from './company-license.model';

export class Company {
  id: string;
  license?: CompanyLicense;
  name: string;

  constructor(company: Partial<Company>) {
    Object.assign(this, company);
  }
}

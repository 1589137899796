import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import * as _ from 'lodash';

// Gutwin Shared Library
import { HelperUtil } from 'gutwin-shared';

import { Audit } from '../models/audit.model';
import { RatingScale } from '../models/rating-scale.model';
// Models
import { Rating } from '../models/rating.model';

import { BatchResponse } from '../interfaces/batch-response.interface';

// Services
import { ApiUrlService } from './api-url.service';
import { ProgressGroup, ProgressService, ProgressStatus, ProgressType } from './progress.service';
// Interfaces
import { SectionsResponse } from './section.service';
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';

export interface Rate {
  questionId: string;
  ratingId: string;
}

export interface RateRequest extends Rate {
  auditedAreaId: string;
  oldRatingId?: string;
}

export interface RatingApiInterface {
  id?: string;
  name: string;
  color: string;
  abbreviation: string;
  percentage_for_average?: string;
  numeric_for_average?: string;
}

export interface RatingResponse extends RatingApiInterface {
  ignore_in_averages: boolean;
  percentage_for_average: string;
  numeric_for_average: string;
}

export interface RatingRequest extends RatingApiInterface {
  ignore_in_averages: string;
  rating_scale_id: string;
}

@Injectable()
export class RatingsService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private progressService: ProgressService,
    private storageService: StorageModuleService
  ) {
    this.progressService.actionObservable.subscribe((groups: Array<ProgressGroup>) => {
      if (groups) {
        groups.forEach(group => {
          if (group.type === ProgressType.ratings) {
            switch (group.action) {
              case 'download':
                this.saveRatingsOffline();
                break;
            }
          }
        });
      }
    });
  }

  getRatings(saveOffline?: boolean): Promise<Array<Rating>> {
    const handleResponse = (data: Array<RatingResponse>): Array<Rating> => {
      return data.map(rating => this.convertRatingToGet(rating));
    };

    return this.http
      .get(this.apiUrlService.ratingsApi)
      .toPromise()
      .then((data: Array<RatingResponse>) => {
        this.storageService.setOfflineStore(StoragesNamesModule.ratings, data);
        return handleResponse(data);
      })
      .catch(async error => {
        if (error.status === 0 && !saveOffline) {
          const data = await this.storageService.getOfflineStore(StoragesNamesModule.ratings);
          if (data) {
            return handleResponse(data);
          }
        }
        console.error('Error while getting ratings', error);
        throw error;
      });
  }

  rateQuestion(
    auditedAreaId: string,
    questionId: string,
    ratingId: string,
    oldRatingId?: string
  ): Promise<{ data?: any; offline?: boolean }> {
    return this.http
      .post(this.apiUrlService.questionRatingsApi, {
        audited_area_id: auditedAreaId,
        question_id: questionId,
        rating_id: ratingId
      })
      .toPromise()
      .then(data => {
        this.updateRateInOfflineStore(auditedAreaId, questionId, ratingId);
        this.removeRateRequestFromOfflineStore({ auditedAreaId, questionId, ratingId });
        return { data };
      })
      .catch(error => {
        if (error.status === 0) {
          this.updateRateRequestInOfflineStore({ auditedAreaId, questionId, ratingId, oldRatingId });
          return { offline: true };
        }
        console.error('Error while rating question', error);
        throw error;
      });
  }

  rateQuestions(auditedAreaId: string, rateIds: Array<Rate>): Promise<void> {
    const convertRatingsToPost = (): any => {
      const request = {
        audited_area_id: auditedAreaId,
        ratings: new Array<any>()
      };
      rateIds.forEach(rate => {
        request.ratings.push({
          question_id: rate.questionId,
          rating_id: rate.ratingId
        });
      });
      return request;
    };

    if (rateIds && rateIds.length) {
      return this.http
        .post(`${this.apiUrlService.questionRatingsApi}/batch_update`, convertRatingsToPost())
        .toPromise()
        .then((data: BatchResponse) => {
          if (data.invalid && data.invalid.count > 0) {
            console.error('Error while rating questions', data.invalid.errors);
            throw new Error(data.invalid.count + '');
          } else {
            this.updateQuestionsRatesInOfflineStore(auditedAreaId, rateIds);
            return this.removeQuestionsRatesRequestFromOfflineStore(auditedAreaId, rateIds);
          }
        })
        .catch(error => {
          console.error('Error while rating questions', error);
          throw error;
        });
    }
    return new Promise((resolve, reject) => resolve());
  }

  removeQuestionRating(auditedAreaId: string, questionId: string): Promise<any> {
    let params = new HttpParams();
    params = params.set('audited_area_id', auditedAreaId);
    params = params.set('question_id', questionId);

    return this.http
      .delete(this.apiUrlService.questionRatingsApi, { params })
      .toPromise()
      .then(() => {
        this.removeRateRequestFromOfflineStore({ auditedAreaId, questionId, ratingId: undefined });
      })
      .catch(error => {
        console.error('Error while removing question rating', error);
        return new Promise((resolve, reject) => reject(error));
      });
  }

  createRating(rating: Partial<Rating>, ratingScale: RatingScale): Promise<Rating> {
    return this.http
      .post(this.apiUrlService.ratingsApi, this.convertRatingToPost(rating, ratingScale))
      .toPromise()
      .then((data: RatingResponse) => {
        this.updateRatingInOfflineStore(data);
        return this.convertRatingToGet(data);
      })
      .catch(error => {
        console.error('Error while creating rating', error);
        throw error;
      });
  }

  updateRating(rating: Rating, ratingScale: RatingScale): Promise<Rating> {
    return this.http
      .put(this.apiUrlService.ratingsApi + '/' + rating.id, this.convertRatingToPut(rating, ratingScale))
      .toPromise()
      .then((data: RatingResponse) => {
        this.updateRatingInOfflineStore(data);
        return this.convertRatingToGet(data);
      })
      .catch(error => {
        console.error('Error while updating rating', error);
        throw error;
      });
  }

  removeRating(ratingId: string): Promise<void> {
    return this.http
      .delete(this.apiUrlService.ratingsApi + '/' + ratingId)
      .toPromise()
      .then(() => {
        this.removeRatingFromOfflineStore(ratingId);
      })
      .catch(error => {
        console.error('Error while deleting rating', error);
        throw error;
      });
  }

  convertRatingToGet(ratingResponse: RatingResponse): Rating {
    return new Rating({
      id: ratingResponse.id,
      name: ratingResponse.name,
      color: ratingResponse.color,
      abbreviation: ratingResponse.abbreviation,
      percentageValue: !HelperUtil.isNullable(ratingResponse.percentage_for_average)
        ? +ratingResponse.percentage_for_average
        : undefined,
      numericValue: !HelperUtil.isNullable(ratingResponse.numeric_for_average)
        ? +ratingResponse.numeric_for_average
        : undefined,
      skipForAverage: ratingResponse.ignore_in_averages
    });
  }

  private convertRatingToPost(rating: Partial<Rating>, ratingScale: RatingScale): RatingRequest {
    const request: RatingRequest = {
      name: rating.name,
      color: rating.color,
      abbreviation: rating.abbreviation,
      ignore_in_averages: rating.skipForAverage ? 'true' : 'false',
      rating_scale_id: ratingScale.id
    };
    if (!rating.skipForAverage) {
      request.numeric_for_average = HelperUtil.isNullable(rating.numericValue) ? '' : rating.numericValue + '';
      request.percentage_for_average = HelperUtil.isNullable(rating.percentageValue) ? '' : rating.percentageValue + '';
    }
    return request;
  }

  private convertRatingToPut(rating: Rating, ratingScale: RatingScale): RatingRequest {
    const request = this.convertRatingToPost(rating, ratingScale);
    request.id = rating.id;
    return request;
  }

  /**
   * Offline support
   */

  private updateRatingInOfflineStore(rating: any): void {
    const updateRating = (data: Array<Rating>): Array<Rating> => {
      const ratingIndex = _.findIndex(data, { id: rating.id });
      if (ratingIndex !== -1) {
        data[ratingIndex] = rating;
      } else {
        data.push(rating);
      }
      return data;
    };

    this.storageService.getOfflineStore(StoragesNamesModule.ratings).then((data: Array<Rating>) => {
      if (data !== null) {
        const updatedData = updateRating(data);
        this.storageService.setOfflineStore(StoragesNamesModule.ratings, updatedData);
      }
    });
  }

  private removeRatingFromOfflineStore(ratingId: string): void {
    const removeRating = (data: Array<Rating>): Array<Rating> => {
      const ratingIndex = _.findIndex(data, { id: ratingId });
      if (ratingIndex !== -1) {
        data.splice(ratingIndex, 1);
      }
      return data;
    };

    this.storageService.getOfflineStore(StoragesNamesModule.ratings).then((data: Array<Rating>) => {
      if (data !== null) {
        const updatedData = removeRating(data);
        this.storageService.setOfflineStore(StoragesNamesModule.ratings, updatedData);
      }
    });
  }

  private changeRateInOfflineStoreData(data: SectionsResponse, questionId: string, ratingId: string): SectionsResponse {
    for (const section of data.ordered_sections) {
      if (section.ordered_questions) {
        for (const question of section.ordered_questions) {
          if (question.id === questionId) {
            question.rating_id = ratingId;
            return data;
          }
        }
      }
    }
  }

  private updateRateInOfflineStore(auditedAreaId: string, questionId: string, ratingId: string): void {
    this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedAreaId)
      .then((data: SectionsResponse) => {
        if (data !== null) {
          const updatedData = this.changeRateInOfflineStoreData(data, questionId, ratingId);
          this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + auditedAreaId, updatedData);
        }
      });
  }

  private updateQuestionsRatesInOfflineStore(auditedAreaId: string, rateIds: Array<Rate>): void {
    this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedAreaId)
      .then((data: SectionsResponse) => {
        if (data !== null) {
          let updatedData = data;
          for (const rate of rateIds) {
            updatedData = this.changeRateInOfflineStoreData(data, rate.questionId, rate.ratingId);
          }
          this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + auditedAreaId, updatedData);
        }
      });
  }

  private removeRateFromOfflineStoreData(data: SectionsResponse, questionId: string): SectionsResponse {
    for (const section of data.ordered_sections) {
      if (section.ordered_questions) {
        for (const question of section.ordered_questions) {
          if (question.id === questionId) {
            question.rating_id = undefined;
            return data;
          }
        }
      }
    }
  }

  private removeRateFromOfflineStore(auditedAreaId: string, questionId: string): void {
    this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaSections + auditedAreaId)
      .then((data: SectionsResponse) => {
        if (data !== null) {
          const updatedData = this.removeRateFromOfflineStoreData(data, questionId);
          this.storageService.setOfflineStore(StoragesNamesModule.auditedAreaSections + auditedAreaId, updatedData);
        }
      });
  }

  getAuditRatesRequestsFromOfflineStore(audit: Audit): Promise<Array<RateRequest>> {
    const promises = new Array<Promise<Array<RateRequest>>>();
    if (audit.auditedAreas) {
      audit.auditedAreas.forEach(auditedArea => {
        promises.push(this.getAuditedAreaRatesRequestsFromOfflineStore(auditedArea.id));
      });
    }

    return Promise.all(promises).then(values => {
      const ratesRequests = new Array<RateRequest>();
      if (values) {
        values.forEach(value => {
          if (value) {
            ratesRequests.push(...value);
          }
        });
      }
      return ratesRequests;
    });
  }

  getAuditedAreaRatesRequestsFromOfflineStore(auditedAreaId: string): Promise<Array<RateRequest>> {
    return this.storageService.getOfflineStore(StoragesNamesModule.auditedAreaRatings + auditedAreaId);
  }

  private updateRateRequestInOfflineStore(rateRequest: RateRequest): void {
    const changeRate = (data: Array<RateRequest>): Array<RateRequest> => {
      const ratingIndex = _.findIndex(data, { questionId: rateRequest.questionId });
      if (ratingIndex !== -1) {
        data[ratingIndex] = rateRequest;
      } else {
        data.push(rateRequest);
      }
      return data;
    };

    this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaRatings + rateRequest.auditedAreaId)
      .then((data: Array<RateRequest>) => {
        if (!data) {
          data = new Array<RateRequest>();
        }
        const updatedData = changeRate(data);
        this.storageService.setOfflineStore(
          StoragesNamesModule.auditedAreaRatings + rateRequest.auditedAreaId,
          updatedData
        );
      });
  }

  private removeRateRequestFromOfflineStoreData(data: Array<RateRequest>, questionId: string): Array<RateRequest> {
    const ratingIndex = _.findIndex(data, { questionId });
    if (~ratingIndex) {
      data.splice(ratingIndex, 1);
    }
    return data;
  }

  removeRateRequestFromOfflineStore(rateRequest: RateRequest): Promise<any> {
    return this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaRatings + rateRequest.auditedAreaId)
      .then((data: Array<RateRequest>) => {
        if (data !== null) {
          const updatedData = this.removeRateRequestFromOfflineStoreData(data, rateRequest.questionId);
          this.storageService.setOfflineStore(
            StoragesNamesModule.auditedAreaRatings + rateRequest.auditedAreaId,
            updatedData
          );
        }
        return;
      });
  }

  removeQuestionsRatesRequestFromOfflineStore(auditedAreaId: string, rateIds: Array<Rate>): Promise<any> {
    return this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaRatings + auditedAreaId)
      .then((data: Array<RateRequest>) => {
        if (data !== null) {
          let updatedData = data;
          for (const rate of rateIds) {
            updatedData = this.removeRateRequestFromOfflineStoreData(data, rate.questionId);
          }
          return this.storageService.setOfflineStore(
            StoragesNamesModule.auditedAreaRatings + auditedAreaId,
            updatedData
          );
        }
      });
  }

  removeAuditRatesRequestFromOfflineStore(audit: Audit): void {
    if (audit.auditedAreas) {
      audit.auditedAreas.forEach(auditedArea => {
        this.removeAuditedAreaRatesRequestFromOfflineStore(auditedArea.id);
      });
    }
  }

  private removeAuditedAreaRatesRequestFromOfflineStore(auditedAreaId: string): void {
    this.storageService.removeFromOfflineStore(StoragesNamesModule.auditedAreaRatings + auditedAreaId);
  }

  async saveRatingsOffline(): Promise<Array<Rating> | void> {
    if (!this.progressService.hasRunningRatingsGroup()) {
      await this.progressService.updateRatingsProgressGroup(undefined, undefined, true);
      return this.getRatings(true)
        .then(async ratings => {
          await this.progressService.updateRatingsProgressGroup(ProgressStatus.success);
          this.progressService.countProgress();
          this.progressService.checkProgressStatus();
          return ratings;
        })
        .catch(async error => {
          await this.progressService.updateRatingsProgressGroup(ProgressStatus.error);
          this.progressService.countProgress();
          this.progressService.checkProgressStatus();
          throw error;
        });
    } else {
      return new Promise((resolve, reject) => resolve());
    }
  }
}

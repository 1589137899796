
<div class="row">
  <div class="columns small-12 audits-list__header-buttons">
    <button class="link audits-list__header-button" type="button" [disabled]="isExportingAudits" (click)="exportAudits()">{{ 'AUDITS_LIST.ACTION.EXPORT_AS_XLSX' | translate }}<i class="button__icon--after button__icon--spinner" *ngIf="isExportingAudits">
        <div class="spinner"></div></i></button>
  </div>
</div>
<div class="row">
  <div class="columns">
    <gw-audits-group class="audits-list__group" [audits]="audits" (auditCreated)="loadAudits()" (auditRemoved)="onRemoveAudit()"></gw-audits-group>
    <gw-pagination class="audits-list__pagination" *ngIf="audits &amp;&amp; audits.length &gt; 0" [totalElementsAmount]="totalAudits" [currentPage]="filterService?.page" [currentLimit]="filterService?.limit" (changePage)="changePage($event)" (changeLimit)="changeLimit($event)"></gw-pagination>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
// Models
import { FacilityFilter } from './../../shared/models/facility-filter.model';
// Providers
import { FacilityService } from '../../shared/services/facility.service';

@Injectable()
export class FacilitiesFilterResolve implements Resolve<any> {

  constructor(
    private facilityService: FacilityService
  ) { }

  resolve (route: ActivatedRouteSnapshot): Promise<Array<FacilityFilter>> {
    return this.facilityService.getFacilitiesForFilter().catch(error => error);
  }
}

export class Facility {
  id: string;
  name: string;
  description: string;
  parentFacility: Facility;
  children: Array<Facility>;
  archived: boolean;

  constructor(facility: any) {
    this.id = facility.id;
    this.name = facility.name;
    this.description = facility.description;
    this.archived = facility.archived;
    if (facility.parentFacility) {
      this.parentFacility = new Facility(facility.parentFacility);
    }
    if (facility.children) {
      this.children = this.generateChildFicilities(facility.children);
    }
  }

  generateChildFicilities(facilities: Array<any>): Array<Facility> {
    const newFacilities = new Array<Facility>();
    facilities.forEach(facility => {
      newFacilities.push(new Facility(facility));
    });
    return newFacilities;
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  closeAllSource$ = new Subject<void>();
  closeAll$ = this.closeAllSource$.asObservable();

  constructor() {}

  closeAll(): void {
    this.closeAllSource$.next();
  }
}

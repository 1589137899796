import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

// Models
import { Employee } from '../../models/employee.model';

@Component({
  selector: 'gw-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./../../../scss/components/_avatar.scss']
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() employee: Employee;
  @Input() medium: boolean;
  @Input() big: boolean;
  @Input() huge: boolean;
  @Input() preview: boolean;
  hasAvatar: boolean;
  initials: string;

  @HostBinding('class.avatar-component--medium') mediumStyle = false;
  @HostBinding('class.avatar-component--big') bigStyle = false;
  @HostBinding('class.avatar-component--huge') hugeStyle = false;

  constructor() {}

  ngOnInit(): void {
    this.initAvatar();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.employee) {
      this.initAvatar();
    }
    if (changes.medium) {
      this.mediumStyle = changes.medium.currentValue;
    }
    if (changes.big) {
      this.bigStyle = changes.big.currentValue;
    }
    if (changes.huge) {
      this.hugeStyle = changes.huge.currentValue;
    }
  }

  initAvatar(): void {
    this.hasAvatar = !!(this.employee.avatar && this.employee.avatar.url);
    if (!this.hasAvatar) {
      this.initials = this.getInitials();
    }
  }

  getInitials(): string {
    let initials = '';
    if (this.employee.name) {
      initials += this.employee.name.charAt(0);
    }
    if (this.employee.lastname) {
      initials += this.employee.lastname.charAt(0);
    }
    return initials;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { LoaderService } from 'gutwin-shared';

import { Audit } from '@gutwin-audit/shared/models/audit.model';

import { AuditService } from '@gutwin-audit/shared/services/audit.service';
import { FilterModuleService } from '@gutwin-audit/shared/services/filter-module.service';
import { TemplateService } from '@gutwin-audit/shared/services/template.service';

@Component({
  selector: 'gw-audits-drafts',
  templateUrl: './audits-drafts.component.html',
  styleUrls: ['./audits-drafts.component.scss']
})
export class AuditsDraftsComponent implements OnInit, OnDestroy {
  audits: Array<Audit>;
  totalAudits: number;
  filterSubscription: Subscription;
  translateSubscription: Subscription;
  notify = {
    error: {
      connectionTitle: '',
      loadAudits: '',
      loadTemplates: ''
    }
  };
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private auditService: AuditService,
    private loaderService: LoaderService,
    private notificationsService: NotificationsService,
    private templateService: TemplateService,
    private translateService: TranslateService,
    public filterService: FilterModuleService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.data['auditsDrafts']) {
      this.updateData(this.route.snapshot.data['auditsDrafts']);
    }
    this.loadTemplatesAmount();
    this.observeFilters();
    this.fetchTranslation();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  observeFilters(): Subscription {
    return this.filterService.filterObservable
      .pipe(takeUntil(this.destroy$), debounceTime(200))
      .subscribe(({ page, limit, filters }) => {
        this.loadAudits(page, filters, limit);
        this.loadTemplatesAmount(page, filters, limit);
      });
  }

  updateData(data: any): void {
    this.audits = data.audits;
    this.totalAudits = this.auditService.auditsAmount.filteredDrafts;
  }

  loadAudits(
    page = this.filterService.page,
    filters = this.filterService.auditsFilters,
    limit = this.filterService.limit
  ): void {
    this.loaderService.show('auditsLoader');
    this.auditService
      .getAuditsDrafts(page, filters, limit)
      .then((response: any) => {
        this.updateData(response);
        this.loaderService.hide('auditsLoader');
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.loadAudits);
        this.loaderService.hide('auditsLoader');
      });
  }

  loadTemplatesAmount(
    page = this.filterService.page,
    filters = this.filterService.auditsFilters,
    limit = this.filterService.limit
  ): void {
    this.templateService.getTemplates(page, filters, limit).catch(error => {
      this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.loadTemplates);
    });
  }

  changePage(page: number): void {
    this.filterService.setPage(page);
  }

  changeLimit(limit: number): void {
    this.filterService.setLimit(limit);
  }

  onRemoveAudit(): void {
    if (this.audits.length > 1) {
      this.loadAudits();
    } else {
      this.filterService.setFilters(this.filterService.page - 1);
    }
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get(['GLOBAL.ERROR.CONNECTION', 'AUDITS_LIST.ERROR.LOAD_DRAFTS', 'TEMPLATES_LIST.ERROR.LOAD_TEMPLATES_AMOUNT'])
      .toPromise();
    this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.notify.error.loadAudits = translation['AUDITS_LIST.ERROR.LOAD_DRAFTS'];
    this.notify.error.loadTemplates = translation['TEMPLATES_LIST.ERROR.LOAD_TEMPLATES_AMOUNT'];
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-show-requirement-versions-button',
  templateUrl: './show-requirement-versions-button.component.html',
  styleUrls: ['./show-requirement-versions-button.component.scss']
})
export class ShowRequirementVersionsButtonComponent {
  @Input() isVersionPanelOpened: boolean;
  @Input() userType: UserType;
  @Output() toggleVersionPanel = new EventEmitter<void>();

  readonly USER_TYPE = UserType;

  togglePanel(): void {
    this.toggleVersionPanel.emit();
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UserType } from '../../../enums/user-type.enum';

@Component({
  selector: 'gw-list-box-paragraph-text',
  templateUrl: './list-box-paragraph-text.component.html',
  styleUrls: ['./list-box-paragraph-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoxParagraphTextComponent {
  @Input() label: string;
  @Input() text?: string;
  @Input() userType?: UserType;
  @Input() inline?: boolean;
}

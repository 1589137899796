import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  IterableDiffer,
  IterableDiffers,
  OnInit,
  OnChanges,
  Output,
  ViewChild,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';

import moment from 'moment';

import { DateTimePickerComponent, Employee, ModalService, SimpleDropdownDirective } from 'gutwin-shared';

import { FacilityFilter } from '@gutwin-audit/shared/models/facility-filter.model';

import { RecurringType, RecurringMonthDayType } from '@gutwin-audit/shared/types';

@Component({
  selector: 'gw-audited-area-form',
  templateUrl: './audited-area-form.component.html',
  styleUrls: ['./audited-area-form.component.scss']
})
export class AuditedAreaFormComponent implements OnInit, OnChanges, DoCheck {
  @ViewChild('firstDatePicker', { static: true }) startDatePicker: SimpleDropdownDirective;
  @ViewChild('secondDatePicker', { static: true }) endDatePicker: SimpleDropdownDirective;
  @ViewChild('startTimePicker') startTimePicker: DateTimePickerComponent;
  @ViewChild('endTimePicker') endTimePicker: DateTimePickerComponent;
  @ViewChild('facilityElement', { static: true }) facilityElement: ElementRef;

  @Input() weekDays: Array<{ id: string; name: string }>;
  @Input() auditedArea: FormGroup;
  @Input() formIndex: number;
  @Input() submitted: boolean;
  @Input() auditors: Array<Employee>;
  @Input() employees: Array<Employee>;
  @Input() facilities: Array<FacilityFilter>;
  @Input() isRecurring: boolean;
  @Input() recurringType: RecurringType;
  @Output() keyDownFacility = new EventEmitter();

  private iterableDiffer: IterableDiffer<Employee>;

  constructor(private iterableDiffers: IterableDiffers, private modalService: ModalService) {}

  ngOnInit(): void {
    this.iterableDiffer = this.iterableDiffers.find(this.auditors).create();
    this.updateValidatorsForRecurring();
    this.auditedArea.get('recurringMonthDayType').valueChanges.subscribe(() => {
      this.updateValidatorsForRecurring();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isRecurring || changes.recurringType) {
      this.updateValidatorsForRecurring();
    }
  }

  updateValidatorsForRecurring(): void {
    const recurringMonthDayType = this.auditedArea.get('recurringMonthDayType').value;
    const dayOfMonth = this.auditedArea.get('dayOfMonth');
    const numberWeekOfMonth = this.auditedArea.get('numberWeekOfMonth');

    const clearAllValidators = () => {
      dayOfMonth.clearValidators();
      dayOfMonth.updateValueAndValidity();
      numberWeekOfMonth.clearValidators();
      numberWeekOfMonth.updateValueAndValidity();
    };

    if (this.isRecurring && this.recurringType === 'monthly') {
      if (recurringMonthDayType === 'dayOfMonth') {
        dayOfMonth.setValidators([Validators.required, Validators.min(1), Validators.max(31)]);
        dayOfMonth.updateValueAndValidity();
        numberWeekOfMonth.clearValidators();
        numberWeekOfMonth.updateValueAndValidity();
      } else if (recurringMonthDayType === 'weekDayOfMonth') {
        numberWeekOfMonth.setValidators([Validators.required, Validators.min(1), Validators.max(5)]);
        numberWeekOfMonth.updateValueAndValidity();
        dayOfMonth.clearValidators();
        dayOfMonth.updateValueAndValidity();
      }
    } else {
      clearAllValidators();
    }
  }

  ngDoCheck(): void {
    const changes = this.iterableDiffer.diff(this.auditors);
    if (changes) {
      this.filterAuditors();
    }
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  isFieldEmpty(field: AbstractControl): boolean {
    return this.submitted && field.errors && field.errors.required;
  }

  isFieldMinNumberInvalid(field: AbstractControl): boolean {
    return this.submitted && field.errors && field.errors.min;
  }

  isFieldMaxNumberInvalid(field: AbstractControl): boolean {
    return this.submitted && field.errors && field.errors.max;
  }

  isSelectedRecurringMonthDayType(type: RecurringMonthDayType): boolean {
    return this.auditedArea.get('recurringMonthDayType').value === type;
  }

  filterAuditors(): void {
    const auditorsControl = this.auditedArea.get('auditor');
    const filteredAuditors = auditorsControl.value.filter(
      auditor => !!this.auditors.find(employee => employee.id === auditor.id)
    );
    auditorsControl.setValue(filteredAuditors);
  }

  keyDownFirstDatePicker(event: any): void {
    if (this.startTimePicker) {
      this.startTimePicker.keydown(event);
    }
  }

  keyDownSecondDatePicker(event: any): void {
    if (this.endTimePicker) {
      this.endTimePicker.keydown(event);
    }
  }

  applyStartTime(momentDate: moment.Moment): void {
    this.auditedArea.get('startTime').setValue(momentDate);
    if (!this.auditedArea.get('endTime').value) {
      const prefiledEndDateMoment = moment(momentDate).add('1', 'hour');
      this.applyEndTime(prefiledEndDateMoment);
    }
    if (this.startDatePicker) {
      this.startDatePicker.closeDropdown();
    }
  }

  applyEndTime(momentDate: moment.Moment): void {
    this.auditedArea.get('endTime').setValue(momentDate);
    if (this.endDatePicker) {
      this.endDatePicker.closeDropdown();
    }
  }

  keyDownFacilityControl(event: any): void {
    if (this.modalService.find('facilityModal') && this.modalService.find('facilityModal').opened) {
      this.keyDownFacility.emit(event);
    } else if (event.key === 'Enter') {
      this.showFacilityModal();
    }
  }

  showFacilityModal(): void {
    this.modalService.open('facilityModal', { auditedAreaForm: this.auditedArea, selectElement: this.facilityElement });
  }

  setRecurringMonthDayType(type: RecurringMonthDayType): void {
    this.auditedArea.get('recurringMonthDayType').setValue(type);
  }

  setRecurringWeekDay(day: any): void {
    this.auditedArea.get('weekDayOfMonth').setValue(day);
  }

  setRecurringWeeklyDay(day: any): void {
    this.auditedArea.get('weeklyDayOfMonth').setValue(day);
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { diff_match_patch } from 'diff-match-patch';

import { convertHTMLToPlainText } from '../../converters/shared.convert';

export interface Diff {
  text: string;
  wasAdded?: boolean;
  wasRemoved?: boolean;
}

@Component({
  selector: 'gw-diff',
  templateUrl: './diff.component.html',
  styleUrls: ['./diff.component.scss']
})
export class DiffComponent implements OnInit {
  @Input() firstText: string;
  @Input() secondText: string;
  comparedText: Array<Diff>;

  constructor() {}

  ngOnInit(): void {
    this.comparedText = this.compareText();
  }

  compareText(): Array<Diff> {
    const comparedText = [];
    const firstPlainText = convertHTMLToPlainText(this.firstText || '');
    const secondPlainText = convertHTMLToPlainText(this.secondText || '');

    const dmp = new diff_match_patch();
    dmp.Diff_EditCost = 10;
    const diffs = dmp.diff_main(firstPlainText, secondPlainText);
    dmp.diff_cleanupEfficiency(diffs);

    diffs.forEach(diff => {
      comparedText.push({
        text: diff[1],
        wasAdded: diff[0] === 1,
        wasRemoved: diff[0] === -1
      });
    });
    return comparedText;
  }
}

import { Injectable } from '@angular/core';

import { ResizeObserver } from 'resize-observer';

export enum ResponsiveGridClass {
  small = 'grid--small',
  medium = 'grid--medium',
  large = 'grid--large',
  xlarge = 'grid--xlarge',
  xxlarge = 'grid--xxlarge',
  xxxlarge = 'grid--xxxlarge'
}

export enum ResponsiveGridSize {
  small = 0,
  medium = 640,
  large = 1024,
  xlarge = 1200,
  xxlarge = 1440,
  xxxlarge = 1920
}

export interface Breakpoint {
  name: string;
  value: number;
}

@Injectable({
  providedIn: 'root'
})
export class GridService {
  breakpoints = [
    {
      name: ResponsiveGridClass.small,
      value: ResponsiveGridSize.small
    },
    {
      name: ResponsiveGridClass.medium,
      value: ResponsiveGridSize.medium
    },
    {
      name: ResponsiveGridClass.large,
      value: ResponsiveGridSize.large
    },
    {
      name: ResponsiveGridClass.xlarge,
      value: ResponsiveGridSize.xlarge
    },
    {
      name: ResponsiveGridClass.xxlarge,
      value: ResponsiveGridSize.xxlarge
    },
    {
      name: ResponsiveGridClass.xxxlarge,
      value: ResponsiveGridSize.xxxlarge
    }
  ];
  resizeObserver: ResizeObserver;

  constructor() {
    this.resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        this.breakpoints.forEach((breakpoint: Breakpoint) => {
          const minWidth = breakpoint.value;
          if (entry.contentRect.width > minWidth) {
            entry.target.classList.add(breakpoint.name);
          } else {
            entry.target.classList.remove(breakpoint.name);
          }
        });
      });
    });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import moment from 'moment';

import { DateRange } from '../../interfaces/date-range.interface';

import { DatePickerComponent } from '../date-picker/date-picker.component';

import { SimpleDropdownDirective } from '../../directives/simple-dropdown.directive';

@Component({
  selector: 'gw-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['../../../scss/components/_filters.scss', './date-range-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangePickerComponent implements OnInit, ControlValueAccessor {
  @ViewChild('datePicker') datePicker: DatePickerComponent;
  @ViewChild('dateDropdown') dateDropdown: SimpleDropdownDirective;

  @Input() visibleContent = false;
  @Input() disabled: boolean;

  startDate: moment.Moment;
  endDate: moment.Moment;

  onChange: (_?: DateRange) => void = () => {};

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {}

  emitChange(): void {
    this.onChange({ startDate: this.startDate, endDate: this.endDate });
  }

  writeValue(value: DateRange): void {
    if (value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
      this.changeDetector.detectChanges();
    }
  }

  registerOnChange(fn: (_: DateRange) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  setDisabledState(isDisabled: boolean): void {}

  setStartDate(date: moment.Moment): void {
    this.startDate = date;
    this.emitChange();
  }

  setEndDate(date: moment.Moment): void {
    this.endDate = date;
    this.emitChange();
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { ChosenFilter, ChosenFilters } from '../../interfaces/chosen-filter.interface';

@Component({
  selector: 'gw-chosen-filters',
  templateUrl: './chosen-filters.component.html',
  styleUrls: ['./chosen-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChosenFiltersComponent {
  @Input() chosenFilters: ChosenFilters;
  @Input() translateOption = false;
  @Input() isUserTypeClient = false;
  @Output() removeFilter = new EventEmitter<ChosenFilter>();

  emitRemoveFilter(filter: ChosenFilter): void {
    this.removeFilter.emit(filter);
  }
}

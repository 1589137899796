import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AttachmentLC } from '../../models/attachment-lc.model';

import { SingleChangeEditableFields } from '../../enums/single-change-editable-fields.enum';
import { UserType } from '../../enums/user-type.enum';

import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'gw-sidebar-attachments',
  templateUrl: './sidebar-attachments.component.html'
})
export class SidebarAttachmentsComponent implements OnInit {
  @Input() acceptedFileTypes?: string;
  @Input() convertSizeToMB?: boolean;
  @Input() isDocument?: boolean;
  @Input() isEditMode: boolean;
  @Input() attachments: Array<AttachmentLC>;
  @Input() hideHeader?: boolean;
  @Input() inlineHeader = false;
  @Input() titleMarginBottom?: boolean;
  @Input() alignTitle?: boolean;
  @Input() popoverTitle?: string;
  @Input() userType: UserType;
  @Input() showSize?: boolean;
  @Input() showMimeType?: boolean;
  @Input() removeFileExtension?: boolean;

  @Output() addAttachments = new EventEmitter<AttachmentLC[]>();
  @Output() changeAttachment = new EventEmitter<AttachmentLC>();
  @Output() removeAttachment = new EventEmitter<AttachmentLC>();
  @Output() togglePopoverStatus = new EventEmitter<SingleChangeEditableFields>();

  singleChangeEditableFields = SingleChangeEditableFields;
  translation = {
    removeDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    }
  };

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.fetchTranslation();
  }

  async showRemoveAttachmentModal(attachment: AttachmentLC): Promise<void> {
    try {
      await this.dialogService.confirm(
        this.translation.removeDialog.header,
        this.translation.removeDialog.content,
        this.translation.removeDialog.cancel,
        this.translation.removeDialog.confirm
      );
      await this.removeAttachment.emit(new AttachmentLC({ ...attachment, action: 'remove' }));
    } catch (error) {}
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GUTWIN_SHARED.ACTION.CANCEL',
        'GUTWIN_SHARED.ACTION.CONFIRM',
        'GUTWIN_SHARED.LABEL.WARNING',
        'GUTWIN_SHARED.LAW_DETAILS.WARNING.REMOVE_ATTACHMENT'
      ])
      .toPromise();
    this.translation.removeDialog.header = translation['GUTWIN_SHARED.LABEL.WARNING'];
    this.translation.removeDialog.content = translation['GUTWIN_SHARED.LAW_DETAILS.WARNING.REMOVE_ATTACHMENT'];
    this.translation.removeDialog.cancel = translation['GUTWIN_SHARED.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GUTWIN_SHARED.ACTION.CONFIRM'];
  }

  onAddAttachment(attachments: AttachmentLC[]): void {
    this.addAttachments.emit(attachments);
  }

  onEditSubmit(attachments: AttachmentLC): void {
    this.changeAttachment.emit(attachments);
  }

  onTogglePopoverStatus(status: SingleChangeEditableFields): void {
    this.togglePopoverStatus.emit(status);
  }
}

import moment from 'moment';

import { AttachmentLC } from './attachment-lc.model';
import { CommentActivity } from './comment-activity.model';
import { DefaultList } from './default-list.model';
import { Employee } from './employee.model';
import { ExtraFieldSimple } from './extra-field-simple.model';
import { LegalQuestion } from './legal-question.model';
import { Link } from './link.model';
import { Product } from './product.model';
import { SharedLaw } from './shared-law.model';
import { SingleChange } from './single-change.model';
import { AssignedStatusLCConsultant } from './status-lc-consultant.model';

import { FullAttachment } from '../interfaces/full-attachment.interface';
import { History } from '../interfaces/history.interface';
import { LegalContainerChanges } from '../interfaces/legal-container-changes.interface';
import { NestedElementsToPreview } from '../interfaces/nested-elements-to-preview.interface';
import { RequirementsList } from '../interfaces/requirements.interface';

import { CONTAINER_TYPE } from '../types/container.type';

export class LegalContainer<C = SingleChange, L = SharedLaw, Q = LegalQuestion> {
  readonly _type: CONTAINER_TYPE = 'LegalContainer';

  id: string;
  name: string;
  summary: string;
  hasMajor: boolean;
  hasMinor: boolean;
  issuedOn: moment.Moment | '';
  validFrom: moment.Moment | '';
  decisionDate: moment.Moment | '';
  deadline: moment.Moment | '';
  laws: Array<L>;
  employee?: Employee;
  status?: AssignedStatusLCConsultant;
  lastComment: CommentActivity;
  lastUpdate: moment.Moment;
  attachments: Array<AttachmentLC>;
  flowChart: FullAttachment;
  legalCategories: NestedElementsToPreview;
  history: History;
  products: Array<Product>;
  requirements: RequirementsList;
  changes: LegalContainerChanges<C>;
  links?: Array<Link>;
  extraFields?: DefaultList<ExtraFieldSimple>;
  questions?: DefaultList<Q>;
  hasQuestion?: boolean;

  constructor(legalContainer: Partial<LegalContainer>) {
    Object.assign(this, legalContainer);
  }
}

import { Employee } from './employee.model';
import { StatusAssigned } from './status.model';

import { Activity } from '../interfaces/activity.interface';

export class StatusActivity extends StatusAssigned implements Activity {
  readonly _type = 'StatusActivity';

  employee: Employee;

  constructor(statusActivity: Partial<StatusActivity>) {
    super(statusActivity);
    Object.assign(this, statusActivity);
  }
}

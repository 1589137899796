import { AuditedAreaDashboardChartModel } from '@gutwin-audit/shared/models/audited-area-dashboard-chart.model';

import { DashboardCounter } from '@gutwin-audit/shared/interfaces/dashboard-counter.interface';
import { FindingsByStatus } from '@gutwin-audit/shared/interfaces/findings-by-status.interface';
import { ProgressBarChart } from '@gutwin-audit/shared/interfaces/progress-bar-chart.interface';

export class DashboardModel {
  counter: DashboardCounter;
  auditedArea: Array<AuditedAreaDashboardChartModel>;
  mostActiveAuditors: Array<ProgressBarChart>;
  findingsByType: Array<ProgressBarChart>;
  findingsByStatus: FindingsByStatus;

  constructor(data: Partial<DashboardModel>) {
    Object.assign(this, data);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

// Gutwin Shared Library
import { RouterService } from 'gutwin-shared';

import { AuditsAmount } from '@gutwin-audit/shared/models/audits-amount.model';

// Services
import { AuditService } from '@gutwin-audit/shared/services/audit.service';
import { FilterModuleService } from '@gutwin-audit/shared/services/filter-module.service';
import { TemplateService } from '@gutwin-audit/shared/services/template.service';

export interface TabCounters extends AuditsAmount {
  totalTemplates: number;
  filteredTemplates: number;
}

@Component({
  selector: 'gw-audits-tabs',
  templateUrl: './audits-tabs.component.html',
  styleUrls: ['./audits-tabs.component.scss']
})
export class AuditsTabsComponent implements OnInit, OnDestroy {
  counters: TabCounters;
  hasFilters: boolean;
  tabs = {
    inProgress: {
      url: '/audits/in-progress',
      title: '',
      amountId: {
        total: 'totalInProgress',
        filtered: 'filteredInProgress'
      }
    },
    drafts: {
      url: '/audits/drafts',
      title: '',
      amountId: {
        total: 'totalDrafts',
        filtered: 'filteredDrafts'
      }
    },
    finished: {
      url: '/audits/finished',
      title: '',
      amountId: {
        total: 'totalFinished',
        filtered: 'filteredFinished'
      }
    },
    templates: {
      url: '/audits/templates',
      title: '',
      amountId: {
        total: 'totalTemplates',
        filtered: 'filteredTemplates'
      }
    }
  };
  tabsArray: Array<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private auditService: AuditService,
    private filterService: FilterModuleService,
    private routerService: RouterService,
    private templateService: TemplateService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.observeAuditsAmount();
    this.observeTemplatesAmount();
    this.observeIfHasFilters();
    this.fetchTranslation();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  observeAuditsAmount(): Subscription {
    this.counters = { ...this.counters, ...this.auditService.auditsAmount };
    return this.auditService.auditsAmountObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe(auditsAmount => {
        this.counters = { ...this.counters, ...auditsAmount };
      });
  }

  observeTemplatesAmount(): Subscription {
    this.counters = {
      ...this.counters,
      totalTemplates: this.templateService.templatesAmount.total,
      filteredTemplates: this.templateService.templatesAmount.filtered
    };
    return this.templateService.templatesAmountObservable
      .pipe(takeUntil(this.destroy$))
      .subscribe(templatesAmount => {
        this.counters = {
          ...this.counters,
          totalTemplates: templatesAmount.total,
          filteredTemplates: templatesAmount.filtered
        };
      });
  }

  observeIfHasFilters(): Subscription {
    this.hasFilters = this.filterService.isAnyFilter();
    return this.filterService.filterObservable
      .pipe(takeUntil(this.destroy$), debounceTime(200))
      .subscribe(() => {
        this.hasFilters = this.filterService.isAnyFilter();
      });
  }

  isLinkActive(url: string): boolean {
    return this.routerService.isLinkActive(url);
  }

  convertTabsToArray(): Array<any> {
    return Object.keys(this.tabs).map(tabKey => this.tabs[tabKey]);
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService.get([
      'AUDITS_LIST.TAB.IN_PROGRESS',
      'AUDITS_LIST.TAB.DRAFTS',
      'AUDITS_LIST.TAB.FINISHED',
      'AUDITS_LIST.TAB.TEMPLATES'
    ]).toPromise();
    this.tabs.inProgress.title = translation['AUDITS_LIST.TAB.IN_PROGRESS'];
    this.tabs.drafts.title = translation['AUDITS_LIST.TAB.DRAFTS'];
    this.tabs.finished.title = translation['AUDITS_LIST.TAB.FINISHED'];
    this.tabs.templates.title = translation['AUDITS_LIST.TAB.TEMPLATES'];

    this.tabsArray = this.convertTabsToArray();
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gw-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarningModalComponent {
  @Input() title: string;
  @Input() content: string;

  @Output() closeModal = new EventEmitter<void>();
  @Output() confirmAction = new EventEmitter<void>();
}

import { FilterOption } from '../models/filter-option.model';

import { StaticFilter } from '../interfaces/static-filter.interface';

import { InactiveId } from '../enums/inactive-id.enum';
import { IntervalId } from '../enums/interval-id.enum';

export const convertStaticFilterToFilterOption = (
  staticFilter: StaticFilter<IntervalId | InactiveId>
): FilterOption => {
  return {
    id: staticFilter.id,
    name: staticFilter.translatedName,
    isSelected: false
  };
};

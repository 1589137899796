import { Injectable } from '@angular/core';
import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';

import { ActionsExecuting } from '@ngxs-labs/actions-executing';
import { find, without } from 'lodash';
import { Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

// Components
import { LoaderComponent } from '../components/loader/loader.component';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loaders = new Array<LoaderComponent>();
  lastUrl: string;
  isActionExecuting: boolean;

  constructor(private router: Router) {
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  add(loader: any): void {
    this.loaders.push(loader);
  }

  remove(id: string): void {
    const loaderToRemove = find(this.loaders, { id });
    if (loaderToRemove) {
      this.loaders = without(this.loaders, loaderToRemove);
    }
  }

  show(id: string): void {
    const loader = find(this.loaders, { id });
    if (loader) {
      loader.loading = true;
      loader.changeDetector.detectChanges();
    }
  }

  hide(id: string): void {
    const loader = find(this.loaders, { id });
    if (loader) {
      loader.loading = false;
      loader.changeDetector.detectChanges();
    }
  }

  navigationInterceptor(event: RouterEvent): void {
    switch (true) {
      case event instanceof NavigationStart:
        this.show('navigationLoader');
        break;
      case event instanceof NavigationEnd:
        if (!this.isActionExecuting) this.hide('navigationLoader');
        const url = this.getClearUrl(event['url']);
        if (url !== this.lastUrl) {
          window.scrollTo(0, 0);
        }
        this.lastUrl = url;
        break;
      case event instanceof NavigationCancel || event instanceof NavigationError:
        this.hide('navigationLoader');
        break;
    }
  }

  getClearUrl(url: string): string {
    const queryParamsIndex = url.indexOf('?');
    return ~queryParamsIndex ? url.substring(0, queryParamsIndex) : url;
  }

  watchForLoader(action$: Observable<ActionsExecuting>, destroy$: Subject<any>, loaderId: string): Subscription {
    return action$.pipe(distinctUntilChanged(), takeUntil(destroy$)).subscribe(val => {
      if (val) {
        this.isActionExecuting = true;
        this.show(loaderId);
      } else {
        this.isActionExecuting = false;
        this.hide(loaderId);
      }
    });
  }
}

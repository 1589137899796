import { Pipe, PipeTransform } from '@angular/core';

import { HelperUtil } from '../utils/helper.util';

@Pipe({
  name: 'isDifferent'
})
export class IsDifferentPipe implements PipeTransform {
  public transform<T>(firstObject: T, secondObject: T, keys: keyof T | Array<keyof T>): boolean {
    return HelperUtil.areValuesDifferent(firstObject, secondObject, keys);
  }
}

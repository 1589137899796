import { OverlayRef } from '@angular/cdk/overlay';
import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

import { TooltipService } from '../services/tooltip.service';

@Directive({
  selector: '[gwTooltip]'
})
export class TooltipDirective implements OnDestroy {
  @Input('gwTooltip') text = '';
  @Input() hideTooltip = false;
  @Input() detectTextLength = false;

  shouldHideTooltip: boolean;
  private overlayRef: OverlayRef;

  constructor(private tooltipService: TooltipService, private elementRef: ElementRef) {}

  ngOnDestroy(): void {
    this.hide();
  }

  setHideTooltipFlag(): boolean {
    const { offsetWidth, scrollWidth } = this.elementRef.nativeElement;
    const textTooLong = offsetWidth < scrollWidth;
    return this.hideTooltip || (this.detectTextLength && !textTooLong);
  }

  @HostListener('mouseenter')
  show(): void {
    this.shouldHideTooltip = this.setHideTooltipFlag();
    if (this.shouldHideTooltip) return;
    this.tooltipService.closeAllTooltips();
    this.overlayRef = this.tooltipService.showTooltip(this.text, this.elementRef);
  }

  @HostListener('mouseleave')
  hide(): void {
    if (this.shouldHideTooltip || !this.overlayRef) return;
    this.tooltipService.closeTooltip(this.overlayRef);
  }
}

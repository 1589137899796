import gql from 'graphql-tag';

import { CurrentUserGraphQLActionType } from '../../enums/user-actions.enum';

import { ProfileFragment } from '../fragments/profile.fragment';

export const LoggedUserQuery = gql`
  query ${CurrentUserGraphQLActionType.profile} {
    ${CurrentUserGraphQLActionType.profile} {
      ...ProfileFragment
    }
  }
  ${ProfileFragment}
`;

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginPageComponent } from '@gutwin-audit/authentication/login-page/login-page.component';
import { StatusPageComponent } from '@gutwin-audit/authentication/status-page/status-page.component';
import { UserProfileComponent } from '@gutwin-audit/authentication/user-profile/user-profile.component';

import { LoginGuard } from '@gutwin-audit/shared/guards/login.guard';
import { LogoutGuard } from '@gutwin-audit/shared/guards/logout.guard';
import { OfflineGuard } from '@gutwin-audit/shared/guards/offline.guard';

import { UserResolve } from '@gutwin-audit/authentication/resolvers/user.resolve';
import { CurrentUserResolve } from '@gutwin-audit/shared/resolvers/current-user.resolve';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate: [LogoutGuard]
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    resolve: {
      user: CurrentUserResolve
    },
    canActivate: [LoginGuard, OfflineGuard]
  },
  {
    path: 'user-profile/:userId',
    component: UserProfileComponent,
    resolve: {
      user: UserResolve,
      currentUser: CurrentUserResolve
    },
    canActivate: [LoginGuard, OfflineGuard]
  },
  {
    path: '404',
    component: StatusPageComponent
  },
  {
    path: '403',
    component: StatusPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuthenticationRoutingModule {}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Employee } from '../../models/employee.model';
import { Role } from '../../models/role.model';

import { ExpandBoxComponent } from '../expand-box/expand-box.component';

@Component({
  selector: 'gw-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrls: ['./roles-list.component.scss'],
  exportAs: 'gwRolesList',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RolesListComponent {
  @Input() roles: Array<Role>;
  @Input() users: Array<Employee>;
  @Input() showMultiselect?: boolean;
  @Input() noInputBorder?: boolean;

  @Output() editRole = new EventEmitter<Role>();
  @Output() removeRole = new EventEmitter<Role>();
  @Output() openRole = new EventEmitter<{ role: Role; expandBox: ExpandBoxComponent }>();
  @Output() addUserToRole = new EventEmitter<{ role: Role; users: Array<Employee> }>();
  @Output() removeUserFromRole = new EventEmitter<{ role: Role; user: Employee }>();

  onToggleRole(role: Role, expandBox: ExpandBoxComponent): void {
    if (!expandBox.opened) this.openRole.emit({ role, expandBox });
    else expandBox.toggle();
  }
}


<form [formGroup]="defineAuditForm" novalidate="novalidate" autocomplete="off" (ngSubmit)="submitDefineAuditForm(defineAuditForm)">
  <div class="audit-step__fieldset">
    <div class="row audit-step__fields">
      <div class="columns small-12 medium-6 large-4 small-centered large-uncentered">
        <div class="audit-step__field" [class.form__field--error]="isFieldInvalid(defineAuditForm.controls['name'])">
          <label class="label" for="name">{{ 'AUDIT_CREATION.FORM.LABEL.NAME' | translate }}</label>
          <div class="form-field__error">{{ 'AUDIT_CREATION.S_DEFINE_AUDIT.ERROR.NAME_EMPTY' | translate }}</div>
          <input class="input" placeholder="{{ 'AUDIT_CREATION.FORM.PLACEHOLDER.AUDIT_NAME' | translate }}" type="text" id="name" name="name" tabindex="1" [formControl]="defineAuditForm.controls['name']"/>
        </div>
        <div class="audit-step__field" [class.form__field--error]="isFieldInvalid(defineAuditForm.controls['type'])">
          <label class="label" for="type" (click)="typeSelect.focusDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.TYPE' | translate }}</label>
          <div class="form-field__error">{{ 'AUDIT_CREATION.S_DEFINE_AUDIT.ERROR.TYPE_EMPTY' | translate }}</div>
          <gw-select #typeSelect="gwSelect" [tabindex]="2" [placeholder]="'AUDIT_CREATION.FORM.PLACEHOLDER.SELECT_TYPE' | translate" [data]="auditTypes" [formControl]="defineAuditForm.controls['type']" [display]="['title']" [uniqueKey]="'id'" (focus)="typeSelect.focusDropdown()"></gw-select>
        </div>
        <div class="audit-step__field" [class.form__field--error]="isFieldInvalid(defineAuditForm.controls['leadAuditor'])">
          <label class="label" for="leadAuditor" (click)="leadAuditorSelect.focusDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.LEAD_AUDITOR' | translate }}</label>
          <div class="form-field__error">{{ 'AUDIT_CREATION.S_DEFINE_AUDIT.ERROR.AUDITOR_EMPTY' | translate }}</div>
          <gw-multiselect #leadAuditorSelect="gwMultiselect" [formControl]="defineAuditForm.controls['leadAuditor']" [tabindex]="3" [placeholder]="'AUDIT_CREATION.FORM.PLACEHOLDER.LEAD_AUDITOR' | translate" [data]="employees" [display]="['lastname', 'name']" [splitter]="', '" [separator]="'; '" [uniqueKey]="'id'" (focus)="leadAuditorSelect.focusDropdown()"></gw-multiselect>
        </div>
        <div class="audit-step__field">
          <ng-container *ngIf="!defineAuditForm.controls['coAuditor'].value.length &amp;&amp; !coAuditorsVisible">
            <button class="button--tertiary button--small" type="button" tabindex="4" (click)="showCoAuditors()">{{ 'AUDIT_CREATION.FORM.LABEL.ADD_CO_AUDITOR' | translate }}</button>
          </ng-container>
          <ng-container *ngIf="defineAuditForm.controls['coAuditor'].value.length || coAuditorsVisible">
            <label class="label" for="coAuditor" (click)="auditorSelect.focusDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.CO_AUDITOR' | translate }}</label>
            <gw-multiselect #auditorSelect="gwMultiselect" [formControl]="defineAuditForm.controls['coAuditor']" [tabindex]="4" [placeholder]="'AUDIT_CREATION.FORM.PLACEHOLDER.CO_AUDITOR' | translate" [data]="employees" [display]="['lastname', 'name']" [splitter]="', '" [separator]="'; '" [uniqueKey]="'id'" (focus)="auditorSelect.focusDropdown()"></gw-multiselect>
          </ng-container>
        </div>
        <div class="audit-step__field" [class.form__field--error]="isFieldInvalid(defineAuditForm.controls['organizer'])">
          <label class="label" for="organizer" (click)="organizerSelect.focusDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.ORGANIZER' | translate }}</label>
          <div class="form-field__error">{{ 'AUDIT_CREATION.S_DEFINE_AUDIT.ERROR.ORGANIZER_EMPTY' | translate }}</div>
          <gw-multiselect #organizerSelect="gwMultiselect" [formControl]="defineAuditForm.controls['organizer']" [tabindex]="5" [placeholder]="'AUDIT_CREATION.FORM.PLACEHOLDER.ORGANIZER' | translate" [data]="employees" [display]="['lastname', 'name']" [splitter]="', '" [separator]="'; '" [uniqueKey]="'id'" (focus)="organizerSelect.focusDropdown()"></gw-multiselect>
        </div>
      </div>
    </div>
  </div>
  <div class="button-group audit-step__button-group">
    <button class="button--primary audit-step__button" type="submit" tabindex="6">{{ 'AUDIT_CREATION.S_DEFINE_AUDIT.ACTION.GO_TO_NEXT_STEP' | translate }}<i class="gw-icon gw-arrow--right button__icon--after"></i></button>
  </div>
</form>
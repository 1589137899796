import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Employee, ModalService, OfflineService, TopBarLink } from 'gutwin-shared';

import { ProgressBar, ProgressService } from '@gutwin-audit/shared/services/progress.service';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'gw-audit-top-bar',
  templateUrl: './audit-top-bar.component.html',
  styleUrls: ['./audit-top-bar.component.scss']
})
export class AuditTopBarComponent implements OnInit, OnDestroy {
  @Input() component: 'audit' | 'admin' | 'profile' = 'audit';
  @Input() currentUser: Employee;
  version: string;
  links: { [name: string]: TopBarLink } = {
    profile: {
      title: '',
      url: `/profile`,
      component: 'profile'
    },
    admin: {
      title: '',
      url: `/admin`,
      component: 'admin'
    },
    audits: {
      title: '',
      url: `/audits`,
      component: 'audit'
    }
  };
  progressBar: ProgressBar;

  destroy$ = new Subject<void>();

  constructor(
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private userService: UserService,
    private translateService: TranslateService,
    private progressService: ProgressService,
    public offlineService: OfflineService
  ) {}

  ngOnInit(): void {
    if (!this.currentUser) {
      this.fetchCurrentUser();
    }
    this.fetchVersion();
    this.fetchTranslations();
    this.observeProgressBar();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  fetchCurrentUser(): void {
    this.userService.getCurrentUser().then((currentUser: Employee) => {
      this.currentUser = currentUser;
    });
  }

  fetchVersion(): void {
    this.userService.getAppVersion().then((version: string) => {
      this.version = version;
    });
  }

  observeProgressBar(): void {
    this.progressService.progressObservable.pipe(takeUntil(this.destroy$)).subscribe((progressBar: ProgressBar) => {
      this.progressBar = progressBar;
    });
  }

  onOpenVersionModal(): void {
    this.modalService.open('versionModal');
  }

  onLogOut(): void {
    this.authenticationService.logOut();
  }

  onSyncGroups(): void {
    this.progressService.clearProgressGroups();
    this.progressService.syncGroups();
  }

  onCloseProgressBar(): void {
    this.progressService.clearProgressGroups(true);
    this.progressService.countProgress();
  }

  async fetchTranslations(): Promise<void> {
    const translation = await this.translateService
      .get(['GLOBAL.ACTION.ADMIN', 'GLOBAL.ACTION.AUDITS', 'GLOBAL.ACTION.PROFILE'])
      .toPromise();
    this.links['profile'].title = translation['GLOBAL.ACTION.PROFILE'];
    this.links['admin'].title = translation['GLOBAL.ACTION.ADMIN'];
    this.links['audits'].title = translation['GLOBAL.ACTION.AUDITS'];
  }
}

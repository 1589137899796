import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

// Gutwin Shared Library
import { DialogService, HelperUtil, ModalService } from 'gutwin-shared';

// Models
import { Facility } from '../../../shared/models/facility.model';

// Components
import { AddFacilityComponent } from './add-facility/add-facility.component';

// Services
import { FacilityService } from '../../../shared/services/facility.service';

@Component({
  selector: 'gw-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.scss']
})
export class FacilitiesComponent implements OnInit {
  @ViewChild('addFacility') addFacility: AddFacilityComponent;
  facilities: Array<Facility>;
  scrolling: boolean;
  translation = {
    notify: {
      error: {
        connectionTitle: '',
        moveFacility: '',
        removeFacility: ''
      },
      success: {
        moveFacilityTitle: '',
        moveFacilityText: '',
        removeFacilityTitle: '',
        removeFacilityText: ''
      }
    },
    removeDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    }
  };

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private facilityService: FacilityService,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.facilities = this.route.snapshot.data['facilities'];
    this.sortFacilities(this.facilities);
    this.fetchTranslation();
  }

  moveFacility(event: any): void {
    this.facilityService
      .moveFacility(event.node, event.parentNode)
      .then(facility => {
        facility.children = event.node.children;
        this.replaceFacility(facility);
        this.notificationsService.success(
          this.translation.notify.success.moveFacilityTitle,
          this.translation.notify.success.moveFacilityText
        );
      })
      .catch(error => {
        this.moveBackFacility(event.node);
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.moveFacility
        );
      });
  }

  moveBackFacility(facility: Facility): void {
    this.spliceFacility(facility, this.facilities);
    this.appendFacility(facility, this.facilities);
    this.forceUpdateFacilities();
  }

  removeFacility(facility: Facility): void {
    this.facilityService
      .archiveFacility(facility)
      .then(facilityResponse => {
        this.spliceFacility(facility, this.facilities);
        this.notificationsService.success(
          this.translation.notify.success.removeFacilityTitle,
          this.translation.notify.success.removeFacilityText
        );
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.removeFacility
        );
      });
  }

  saveFacility(): void {
    this.addFacility
      .submitAddFacilityForm()
      .then(facilityResponse => {
        if (facilityResponse.status === 'create') {
          this.appendFacility(facilityResponse.facility, this.facilities);
        } else if (facilityResponse.status === 'update') {
          this.updateFacility(facilityResponse.facility, this.facilities);
        }
        this.forceUpdateFacilities();
        if (!this.addFacility.addAnother) {
          this.modalService.close('facilityModal');
        }
      })
      .catch(error => {});
  }

  forceUpdateFacilities(): void {
    const facilities = this.facilities;
    this.facilities = new Array<Facility>();
    this.facilities = this.facilities.concat(facilities);
  }

  appendFacility(facility: Facility, facilities: Array<Facility>): boolean {
    if (!(facility.parentFacility && facility.parentFacility.id)) {
      this.facilities.push(facility);
      this.sortFacilities(facilities);
      return true;
    }
    for (let i = 0; i < facilities.length; i++) {
      if (facilities[i].id === facility.parentFacility.id) {
        if (!facilities[i].children) {
          facilities[i].children = new Array<Facility>();
        }
        facilities[i].children.push(facility);
        this.sortFacilities(facilities[i].children);
        return true;
      } else if (facilities[i].children) {
        const wasAppend = this.appendFacility(facility, facilities[i].children);
        if (wasAppend) {
          return true;
        }
      }
    }
    return false;
  }

  updateFacility(facility: Facility, facilities: Array<Facility>): boolean {
    for (let i = 0; i < facilities.length; i++) {
      if (facilities[i].id === facility.id) {
        facilities[i].name = facility.name;
        this.sortFacilities(facilities);
        return true;
      } else if (facilities[i].children) {
        const wasUpdated = this.updateFacility(facility, facilities[i].children);
        if (wasUpdated) {
          return true;
        }
      }
    }
    return false;
  }

  replaceFacility(facility: Facility): void {
    this.spliceFacility(facility, this.facilities);
    this.appendFacility(facility, this.facilities);
  }

  sortFacilities(facilities: Array<Facility>): void {
    facilities.sort((facilityA: Facility, facilityB: Facility) => {
      return facilityA.name > facilityB.name ? 1 : -1;
    });
  }

  spliceFacility(facility: Facility, facilities: Array<Facility>): boolean {
    for (let i = 0; i < facilities.length; i++) {
      if (facilities[i].id === facility.id) {
        facilities.splice(i, 1);
        return true;
      } else if (facilities[i].children) {
        const wasSplice = this.spliceFacility(facility, facilities[i].children);
        if (wasSplice) {
          return true;
        }
      }
    }
    return false;
  }

  showAddModal(facility: Facility): void {
    this.modalService.open('facilityModal', { parent: facility });
  }

  showUpdateModal(data: { node: Facility; parent: Facility }): void {
    this.modalService.open('facilityModal', { facility: data.node });
  }

  showRemoveModal(facility: Facility): void {
    this.dialogService
      .confirm(
        this.translation.removeDialog.header,
        this.translation.removeDialog.content,
        this.translation.removeDialog.cancel,
        this.translation.removeDialog.confirm
      )
      .then(() => {
        this.removeFacility(facility);
      })
      .catch(() => {});
  }

  dragFacility(event: any): void {
    if (!this.scrolling) {
      if (event.pageY > HelperUtil.windowScrollY + window.innerHeight - 50) {
        this.scrollFacilities('down');
      } else if (event.pageY < HelperUtil.windowScrollY + 50) {
        this.scrollFacilities('up');
      }
    }
  }

  scrollFacilities(direction: string): void {
    this.scrolling = true;
    HelperUtil.scrollView(direction).then(() => {
      this.scrolling = false;
    });
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'ADMIN_SETTINGS.FACILITIES.ERROR.MOVE_FACILITY',
        'ADMIN_SETTINGS.FACILITIES.ERROR.REMOVE_FACILITY',
        'ADMIN_SETTINGS.FACILITIES.SUCCESS.MOVE_FACILITY_TITLE',
        'ADMIN_SETTINGS.FACILITIES.SUCCESS.MOVE_FACILITY_TEXT',
        'ADMIN_SETTINGS.FACILITIES.SUCCESS.REMOVE_FACILITY_TITLE',
        'ADMIN_SETTINGS.FACILITIES.SUCCESS.REMOVE_FACILITY_TEXT',
        'ADMIN_SETTINGS.FACILITIES.REMOVE_MODAL.HEADER',
        'ADMIN_SETTINGS.FACILITIES.REMOVE_MODAL.CONTENT'
      ])
      .toPromise();
    this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.moveFacility = translation['ADMIN_SETTINGS.FACILITIES.ERROR.MOVE_FACILITY'];
    this.translation.notify.error.removeFacility = translation['ADMIN_SETTINGS.FACILITIES.ERROR.REMOVE_FACILITY'];
    this.translation.notify.success.moveFacilityTitle =
      translation['ADMIN_SETTINGS.FACILITIES.SUCCESS.MOVE_FACILITY_TITLE'];
    this.translation.notify.success.moveFacilityText =
      translation['ADMIN_SETTINGS.FACILITIES.SUCCESS.MOVE_FACILITY_TEXT'];
    this.translation.notify.success.removeFacilityTitle =
      translation['ADMIN_SETTINGS.FACILITIES.SUCCESS.REMOVE_FACILITY_TITLE'];
    this.translation.notify.success.removeFacilityText =
      translation['ADMIN_SETTINGS.FACILITIES.SUCCESS.REMOVE_FACILITY_TEXT'];
    this.translation.removeDialog.header = translation['ADMIN_SETTINGS.FACILITIES.REMOVE_MODAL.HEADER'];
    this.translation.removeDialog.content = translation['ADMIN_SETTINGS.FACILITIES.REMOVE_MODAL.CONTENT'];
    this.translation.removeDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
  }
}

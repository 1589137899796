
<gw-expand-box class="audited-areas-group" *ngIf="!isTemplate" #auditedAreaExpandBox="gwExpandBox" [class.audited-areas-group--even]="isEven" [class.audited-areas-group--template]="isTemplate" [opened]="isFirst">
  <ng-container expand-box-header="expand-box-header">
    <div class="row audited-areas-group__title-container">
      <div class="columns">
        <h3 class="audited-areas-group__title" [class.audited-areas-group__title--opened]="auditedAreaExpandBox.opened" (click)="auditedAreaExpandBox.toggle()"><i class="gw-caret--right h__icon--before audited-areas-group-title__icon"></i>{{ date | dateFormat: 'dddd, ' }}<small>{{ date | dateFormat: 'D MMMM YYYY' }}</small></h3>
      </div>
    </div>
  </ng-container>
  <ng-container expand-box-content="expand-box-content">
    <ng-container *ngTemplateOutlet="auditedAreasTemplate"></ng-container>
  </ng-container>
</gw-expand-box>
<div class="audited-areas-group" *ngIf="isTemplate" [class.audited-areas-group--even]="isEven" [class.audited-areas-group--template]="isTemplate">
  <ng-container *ngTemplateOutlet="auditedAreasTemplate"></ng-container>
</div>
<ng-template #auditedAreasTemplate="">
  <div class="row">
    <div class="columns">
      <div class="flex-table audited-areas-group__table" [class.audited-areas-group--template__table]="isTemplate">
        <div class="flex-table__row audited-area-table__row">
          <div class="flex-table__cell--th audited-area-table__col--1" *ngIf="!isTemplate">{{ 'GLOBAL.TABLE_LABEL.TIME' | translate }}</div>
          <div class="flex-table__cell--th audited-area-table__col--2">{{ 'GLOBAL.TABLE_LABEL.AUDITED_AREA' | translate }}</div>
          <div class="flex-table__cell--th audited-area-table__col--3">{{ 'GLOBAL.TABLE_LABEL.LOCATION' | translate }}</div>
          <div class="flex-table__cell--th audited-area-table__col--4">{{ 'GLOBAL.TABLE_LABEL.AUDITOR' | translate }}</div>
          <div class="flex-table__cell--th audited-area-table__col--5">{{ 'GLOBAL.TABLE_LABEL.AUDITEE' | translate }}</div>
          <div class="flex-table__cell--th audited-area-table__col--6" *ngIf="!isTemplate"></div>
        </div>
        <div class="flex-table__row audited-area-table__row" *ngFor="let auditedArea of subaudits" [class.flex-table__row--even]="!isEven" [class.audited-area-table__row--disabled]="subauditsDisabled &amp;&amp; subauditsDisabled[auditedArea.id]">
          <div class="flex-table__cell audited-area-table__col--1" *ngIf="!isTemplate">{{ auditedArea.startTime | dateFormat: 'HH:mm' }} — {{ auditedArea.endTime | dateFormat: 'HH:mm' }}</div>
          <div class="flex-table__cell audited-area-table__col--2">{{ auditedArea.facility.name }}</div>
          <div class="flex-table__cell audited-area-table__col--3">{{ auditedArea.location }}</div>
          <div class="flex-table__cell audited-area-table__col--4 flex-table__cell--avatar">
            <div class="audited-area-table__auditors-content">
              <gw-avatar class="auditors-cell__avatar" *ngFor="let auditor of auditedArea.employees.auditor" [employee]="auditor"></gw-avatar><span class="auditors-cell__name" *ngFor="let auditor of auditedArea.employees.auditor; last as lastEmployee">{{ auditor.lastname }}, {{ auditor.name }}{{ !lastEmployee ? ';&nbsp;': '' }}</span>
            </div>
          </div>
          <div class="flex-table__cell audited-area-table__col--5 flex-table__cell--avatar">
            <div class="audited-area-table__auditors-content">
              <gw-avatar class="auditors-cell__avatar" *ngFor="let auditee of auditedArea.employees.auditee" [employee]="auditee"></gw-avatar><span class="auditors-cell__name" *ngFor="let auditee of auditedArea.employees.auditee; last as lastEmployee">{{ auditee.lastname }}, {{ auditee.name }}{{ !lastEmployee ? ';&nbsp;': '' }}</span>
            </div>
          </div>
          <ng-container *ngIf="!isTemplate">
            <div class="flex-table__cell audited-area-table__col--6 audited-area-table__buttons text-right" *ngIf="audit.state !== 'finished'"><a class="button--tiny button--tertiary audited-area__button" [routerLink]="['/audits/conduct-audit', audit.id, auditedArea.id]">{{ 'GLOBAL.ACTION.START_AUDIT' | translate }}<i class="gw-caret--right button__icon--after audited-area-button__icon"></i></a></div>
            <div class="flex-table__cell audited-area-table__col--6 audited-area-table__buttons text-right" *ngIf="audit.state === 'finished'"><a class="button--tiny button--tertiary audited-area-buttons__button" *ngIf="audit.disabled" [routerLink]="['/audits/audit-summary', audit.id, auditedArea.id]">{{ 'GLOBAL.ACTION.GO_TO_SUMMARY' | translate }}</a>
              <div class="audited-area__button-box" *ngIf="!audit.disabled">
                <div class="button--tiny button--tertiary audited-area__button audited-area__button--switch"><a class="audited-area-button__base" [routerLink]="['/audits/audit-summary', audit.id, auditedArea.id]">{{ 'GLOBAL.ACTION.GO_TO_SUMMARY' | translate }}</a>
                  <gw-button-dropdown class="audited-area-button__dropdown" #buttonDropdown="buttonDropdown" [customContent]="true" [relative]="false">
                    <ng-container button-dropdown-content="button-dropdown-content"><i class="gw-caret--down audited-area-button__icon audited-area-button__dropdown-icon" [class.audited-area-button__dropdown-icon--opened]="buttonDropdown.simpleDropdown.opened"></i></ng-container>
                    <ng-container button-dropdown-list="button-dropdown-list">
                      <li class="button-dropdown-list__item"><a class="audited-area-button__dropdown-item" [routerLink]="['/audits/conduct-audit', audit.id, auditedArea.id]">{{ 'GLOBAL.ACTION.SHOW_QUESTIONS' | translate }}</a></li>
                    </ng-container>
                  </gw-button-dropdown>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
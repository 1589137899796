import { sortBy } from 'lodash';
import moment from 'moment';

import { ApiTree } from '../interfaces/tree.interface';

export const convertToDate = (date: string | moment.Moment): moment.Moment => {
  return date ? moment(date) : undefined;
};

export const convertToFormatDate = (date: string | moment.Moment, format = 'YYYY-MM-DD'): string => {
  return date ? moment(date).format(format) : '';
};

export const convertHTMLToPlainText = (html = ''): string => {
  const newLineCode = '&nl;';
  return (
    html
      // Fix image tag
      .replace(/<\s*img([^>]*)>/gi, '<img$1/>')

      // Remove list elements but preserve what's inside of them
      .replace(/<\s*(ul|ol)[^>]*>.*?$/gim, convertHtmlListToPlainText)

      // Remove table elements but preserve what's inside of them
      .replace(/<table[^>]*>(.*?)<\s*\/\s*table>/gi, convertHtmlTableToPlainText)

      // Remove br tags and replace them with line break
      .replace(/<br>/gi, newLineCode)
      .replace(/<br\s\/>/gi, newLineCode)
      .replace(/<br\/>/gi, newLineCode)

      // Remove paragraphs and headers but preserve what's inside of them
      .replace(/<\s*\/\s*p>/gi, newLineCode)
      .replace(/<\s*\/\s*h[^>]*>/gi, newLineCode)

      // Remove sub and sup but preserve what's inside of them
      .replace(/<\s*sub[^>]*>(.*?)<\s*\/\s*sub>/gi, '&sub;$1&sub_end;')
      .replace(/<\s*sup[^>]*>(.*?)<\s*\/\s*sup>/gi, '&sup;$1&sup_end;')

      // Remove anchors, images and videos but preserve what's inside of them
      .replace(/<\s*a[^>]*href="([^"]*)"[^>]*>(.*?)<\s*\/\s*a>/gi, '($2)[$1]')
      .replace(/<\s*img[^>]*src="([^"]*)"[^>]*>/gi, `${newLineCode}![$1]${newLineCode}`)
      .replace(/<\s*video[^>]*src="([^"]*)"[^>]*>.*?<\s*\/\s*video>/gi, `${newLineCode}![$1]${newLineCode}`)
      .replace(/<\s*iframe[^>]*src="([^"]*)"[^>]*>.*?<\s*\/\s*iframe>/gi, `${newLineCode}![$1]${newLineCode}`)

      // Remove list elements but preserve what's inside of them
      .replace(/<\s*li[^>]*>/gi, '- ')
      .replace(/<\s*\/\s*li>/gi, newLineCode)

      // Remove all inside script and style tags
      .replace(/<script.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/script>/gi, '')
      .replace(/<style.*>[\w\W]{1,}(.*?)[\w\W]{1,}<\/style>/gi, '')

      // Remove all else
      .replace(/<(?:.|\s)*?>/g, '')

      // Remove text line breaks and replace with line break
      .replace(/(?:\r\n|\r|\n)/g, newLineCode)

      // Remove more than 2 spaces
      .replace(/ +(?= )/g, '')

      // Remove html-encoded characters
      // .replace(/&nbsp;/gi, ' ')
      .replace(/&amp;/gi, '&')
      .replace(/&quot;/gi, '"')
      .replace(/&lt;/gi, '<')
      .replace(/&gt;/gi, '>')

      // Add custom elements for proper format
      .replace(/&nl;/gi, '<br>')
      .replace(/&sub;(.*?)&sub_end;/gi, '<sub>$1</sub>')
      .replace(/&sup;(.*?)&sup_end;/gi, '<sup>$1</sup>')
  );
};

export const convertHtmlTableToPlainText = (table: string): string => {
  const convertRowToPlainText = (row: string): string => {
    const columns = row.match(/<(th|td)[^>]*>(.*?)<\s*\/\s*(th|td)>/gi);
    return columns ? columns.map(convertColumnToPlainText).join('') : '';
  };

  const convertColumnToPlainText = (column: string, index: number): string => {
    return column.replace(/<(th|td)[^>]*>(.*?)<\s*\/\s*(th|td)>/gi, (element, elementName, content) => {
      const formattedContent = content
        .replace(/<br>/gi, ' ')
        .replace(/<br\s\/>/gi, ' ')
        .replace(/<br\/>/gi, ' ');
      return `${index === 0 ? '| ' : ''} ${formattedContent && formattedContent !== ' ' ? formattedContent : '---'} |`;
    });
  };

  const rows = table.match(/<tr[^>]*>(.*?)<\s*\/\s*tr>/gi);
  return rows ? `<br />${rows.map(convertRowToPlainText).join('<br>')}<br>` : '';
};

export const convertHtmlListToPlainText = (html: string): string => {
  const convertListItems = (listItems: Array<Element>, level: number): string => {
    let text = '';
    for (const listItem of listItems) {
      const listItemText = Array.from(listItem.childNodes)
        .filter(node => node.nodeName === '#text')
        .map(node => node.textContent || '')
        .reduce((previousValue, currentValue) => previousValue + currentValue, '');
      const spaceBefore = '&nbsp;&nbsp;'.repeat(level);

      const childrenLists = Array.from(listItem.children);
      const childrenText = convertList(childrenLists, level + 1);

      text += `${spaceBefore}- ${listItemText}<br>${childrenText}`;
    }
    return text;
  };

  const convertList = (lists: Array<Element>, level: number): string => {
    let text = '';
    for (const list of lists) {
      const children = Array.from(list.children);
      text += `${!level ? '<br>' : ''}${convertListItems(children, level)}`;
    }
    return text;
  };

  const getRootLists = (html: string): Array<HTMLUListElement> => {
    const doc = new DOMParser().parseFromString(`<div>${html}</div>`, 'text/html');
    const lists = [...Array.from(doc.getElementsByTagName('ul')), ...Array.from(doc.getElementsByTagName('ol'))];
    const rootLists = lists.filter(list => {
      return list.parentNode.nodeName.toLowerCase() !== 'li';
    });
    return rootLists;
  };

  const rootLists = getRootLists(html);
  for (const rootList of rootLists) {
    const convertedList = convertList([rootList], 0);
    html = html.replace(rootList.outerHTML, convertedList);
  }

  return html;
};

export const convertListToTree = <T extends ApiTree<T>>(list: Array<T>): Array<T> => {
  const getNestedItem = (path: Array<string>, tree: Array<T>): T => {
    const nestedItem = tree.find(nestedItem => nestedItem.id === path[0]);
    const newPath = path.slice(1);
    if (!newPath.length) {
      return nestedItem;
    } else if (nestedItem?.children) {
      return getNestedItem(newPath, nestedItem.children);
    } else {
      return getNestedItem(newPath, tree);
    }
  };

  const sortListByPathLength = (list: Array<T>): Array<T> => {
    return sortBy(list, (item: T) => item.path?.length);
  };

  const addRootItem = (item: T, tree: Array<T>): void => {
    tree.push(item);
  };

  const addChildrenItem = (item: T, tree: Array<T>): void => {
    const parent = getNestedItem(item.path, tree);
    if (parent) {
      parent.children.push(item);
    } else {
      addRootItem(item, tree);
    }
  };

  const tree = [];
  if (list?.length) {
    const sortedList = sortListByPathLength(list);
    sortedList.forEach(item => {
      if (!item.path?.length) {
        addRootItem(item, tree);
      } else {
        addChildrenItem(item, tree);
      }
    });
  }
  return tree;
};


<div class="nav" *ngIf="auditNavigationService.opened" [@flyInOut]="">
  <div class="row nav__row">
    <div class="columns nav__columns">
      <div class="nav__container">
        <div class="nav__close">
          <button class="button--link nav__close-button" type="button" (click)="toggle()"><i class="gw-close button__icon"></i></button>
        </div>
        <ul class="nav__list">
          <li class="nav__item hide"><a class="nav__link" [class.nav__link--active]="isLinkActive('/dashboard')" (click)="toggle()" routerLink="/dashboard"><i class="gw-dashboard nav__icon"></i>{{ 'DASHBOARD.PAGE_HEADER' | translate }}</a></li>
          <li class="nav__item"><a class="nav__link" (click)="toggle()" routerLink="/audits"><i class="gw-audits-list nav__icon"></i>{{ 'AUDITS_LIST.PAGE_HEADER' | translate }}</a>
            <ul class="nav__list">
              <li class="nav__item" *ngFor="let tab of auditsTabsArray"><a class="nav__link" [class.nav__link--active]="isLinkActive(tab.url)" [routerLink]="[tab.url]" [queryParams]="{ page: 1 }" [queryParamsHandling]="currentComponent === 'audits' ? 'merge' : null" (click)="toggle()">{{ tab.title }}</a></li>
            </ul>
          </li>
          <li class="nav__item hide"><a class="nav__link" [class.nav__link--active]="isLinkActive('/reference-documents')" (click)="toggle()" routerLink="/reference-documents"><i class="gw-reference-documents nav__icon"></i>{{ 'REFERENCE_DOCS.PAGE_HEADER' | translate }}</a></li>
          <ng-template #onlineNavLink="" let-tab="tab" let-withQuery="withQuery"><a class="nav__link" *ngIf="offlineService.online" [class.nav__link--active]="isLinkActive(tab.url)" [routerLink]="[tab.url]" [queryParams]="withQuery ?  { page: 1 } : null" [queryParamsHandling]="withQuery &amp;&amp; currentComponent === 'findings' ? 'merge' : null" (click)="toggle()"><i class="nav__icon" [ngClass]="tab.icon"></i>{{ tab.title }}</a>
            <div class="nav__link nav__link--disabled" *ngIf="!offlineService.online"><i class="nav__icon" [ngClass]="tab.icon"></i>{{ tab.title }}</div>
          </ng-template>
          <li class="nav__item" *ngFor="let tab of findingsTabsArray">
            <ng-container *ngTemplateOutlet="onlineNavLink; context: { tab: tab, withQuery: true }"></ng-container>
            <ul class="nav__list">
              <li class="nav__item" *ngFor="let childTab of tab.children">
                <ng-container *ngTemplateOutlet="onlineNavLink; context: { tab: childTab, withQuery: true }"></ng-container>
              </li>
            </ul>
          </li>
          <li class="nav__item" *ngFor="let tab of dashboardTabsArray">
            <ng-container *ngTemplateOutlet="onlineNavLink; context: { tab: tab, withQuery: false }"></ng-container>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
import { Pipe, PipeTransform } from '@angular/core';

import { StatusId } from '../enums/status-id.enum';

@Pipe({
  name: 'isPublished',
  pure: true
})
export class IsPublishedPipe implements PipeTransform {
  public transform(statusId: StatusId): boolean {
    return statusId === StatusId.published;
  }
}

import { ElementRef, Injectable } from '@angular/core';

import * as _ from 'lodash';

import { ModalDialogComponent } from '../components/modal-dialog/modal-dialog.component';
import { ModalLightboxComponent } from '../components/modal-lightbox/modal-lightbox.component';
// Components
import { ModalComponent } from '../components/modal/modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public modals = new Array<ModalComponent | ModalDialogComponent | ModalLightboxComponent>();

  find(id: string): ModalComponent | ModalDialogComponent | ModalLightboxComponent {
    return _.find(this.modals, { id: id });
  }

  add(modal: any): void {
    this.modals.push(modal);
  }

  remove(id: string): void {
    const modalToRemove = this.find(id);
    this.modals = _.without(this.modals, modalToRemove);
  }

  open(id: string, data?: any): void {
    const modal = this.find(id);
    modal.open(data);
  }

  close(id: string): void {
    const modal = this.find(id);
    modal.close();
  }

  show(id: string, data?: any): void {
    const modal = this.find(id);
    modal.show();
  }

  hide(id: string): void {
    const modal = this.find(id);
    modal.hide();
  }

  getContent(id: string): ElementRef {
    const modal = this.find(id);
    return modal.modalContent;
  }

  cleanData(id: string): void {
    const modal = this.find(id);
    modal.data = undefined;
  }
}

import moment from 'moment';

import { AuditedAreaDashboardChartModel } from '@gutwin-audit/shared/models/audited-area-dashboard-chart.model';
import { DashboardModel } from '@gutwin-audit/shared/models/dashboard.model';

import { ApiAuditedAreaDashboardChart } from '@gutwin-audit/shared/interfaces/audited-area-dashboard-chart.interface';
import {
  ApiDashboardAuditorData,
  ApiDashboardData,
  ApiDashboardFindingTypeData,
  ApiDashboardProgressBarData
} from '@gutwin-audit/shared/interfaces/dashboard-data.interface';
import { ProgressBarChart } from '@gutwin-audit/shared/interfaces/progress-bar-chart.interface';

export const convertDashboardDataToGet = (data: ApiDashboardData): DashboardModel => {
  return new DashboardModel({
    counter: {
      completedAuditsCount: data.completed_audits_count,
      auditorsCount: data.auditors_count,
      overdueFindingsCounter: data.overdue_findings_counter,
      overdueAuditsCounter: data.overdue_audits_counter,
      findingsCount: data.findings_count,
      findingsAverage: Math.floor(data.findings_average * 100) / 100
    },
    auditedArea: data.audited_areas?.map(area => convertAuditedAreaDashboardDataToGet(area)),
    mostActiveAuditors: data.most_active_auditors?.map(auditorData => convertAuditorDashboardDataToGet(auditorData)),
    findingsByStatus: data.findings_by_status,
    findingsByType: data.findings_by_type?.map(findingTypeData => convertFindingTypeDashboardDataToGet(findingTypeData))
  });
};

export const convertAuditedAreaDashboardDataToGet = (
  data: ApiAuditedAreaDashboardChart
): AuditedAreaDashboardChartModel => {
  return new AuditedAreaDashboardChartModel({
    averageNumeric: data.average_numeric,
    averagePercentage: data.average_percentage,
    auditId: data.audit_id,
    auditedAreaId: data.audited_area_id,
    auditName: data.audit_name,
    auditType: data.audit_type,
    startTime: moment(data.start_time),
    endTime: moment(data.end_time),
    auditors: data.auditors,
    facilityName: data.facility_name,
    auditees: data.auditees
  });
};

export const convertProgressBarDashboardDataToGet = (data: ApiDashboardProgressBarData): ProgressBarChart => {
  return {
    name: data.name,
    count: data.count,
    total: data.total
  };
};

export const convertAuditorDashboardDataToGet = (data: ApiDashboardAuditorData): ProgressBarChart => {
  return {
    ...convertProgressBarDashboardDataToGet(data),
    id: data.auditor_id
  };
};

export const convertFindingTypeDashboardDataToGet = (data: ApiDashboardFindingTypeData): ProgressBarChart => {
  return {
    ...convertProgressBarDashboardDataToGet(data),
    id: data.id
  };
};

export class FindingType {
  id: string;
  name: string;
  description: string;
  archived: boolean;

  constructor(findingType: any) {
    this.id = findingType.id;
    this.name = findingType.name;
    this.description = findingType.description;
    this.archived = findingType.archived;
  }
}

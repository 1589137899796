import { AuditType } from "./audit-type.model";

export interface ApiReportResponse {
  id: string;
  name: string;
  company_logo_width: string;
  question_photo_width: string;
  finding_photo_width: string;
  created_at: string;
  updated_at: string;
  audit_types: Array<AuditType>;
  default: boolean;
  document: {
    name: string,
    url: string
  }
}

export interface ApiReportToPost {
  name: string;
  company_logo_width: string;
  question_photo_width: string;
  finding_photo_width: string;
  document: ApiDocumentResponse;
  audit_type_ids: Array<string>;
}

export interface ApiReportToUpdate {
  name: string;
  company_logo_width: string;
  question_photo_width: string;
  finding_photo_width: string;
  document: ApiDocumentResponse;
  audit_type_ids: Array<string>;
}

export interface ApiDocumentResponse {
  name: string;
  url: string;
}

export interface ApiJoinedCreateRespone {
  report: Report;
  filePromise: Promise<ApiDocumentResponse>;
}

export interface ApiReportToDefault {
  id: string;
  default: string;
}

export interface DocumentPreview {
  name: string;
  url: string;
}

export class Report {
  id: string;
  name: string;
  auditTypes: Array<AuditType>;
  companyLogoWidth: number;
  questionPhotoWidth: number;
  findingPhotoWidth: number;
  default: boolean;
  document: DocumentPreview;

  constructor(report: Partial<Report>) {
    this.id = report.id;
    this.name = report.name;
    this.auditTypes = report.auditTypes;
    this.companyLogoWidth = report.companyLogoWidth;
    this.questionPhotoWidth = report.questionPhotoWidth;
    this.findingPhotoWidth = report.findingPhotoWidth;
    this.default = report.default;
    this.document = report.document;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import moment from 'moment';
// Gutwin Shared Library
import { Employee, LoginService } from 'gutwin-shared';
// Services
import { ApiUrlService } from './api-url.service';
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';
import { UserService } from './user.service';

@Injectable()
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private storageService: StorageModuleService,
    private router: Router,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private userService: UserService,
    private loginService: LoginService
  ) {}

  getToken(accessData: Partial<Employee>): Promise<string> {
    return this.http
      .post(`${this.apiUrlService.tokenApi}/token`, {
        grant_type: 'password',
        username: accessData.email,
        password: accessData.password
      })
      .toPromise()
      .then((data: any) => {
        const token = data.access_token;
        this.storageService.setLocalStorage(StoragesNamesModule.token, token);
        this.userService.getCurrentUser(true);
        this.notificationsService.remove();
        return token;
      })
      .catch(error => {
        this.fetchGetTokenErrorTranslation().then(translation => {
          this.notificationsService.remove();
          this.notificationsService.error(
            translation['AUTHORIZATION.MESSAGE.ERROR.HEADER'],
            translation['AUTHORIZATION.MESSAGE.ERROR.CONTENT']
          );
        });
        throw error;
      });
  }

  async logOut(): Promise<void> {
    const token = this.storageService.getLocalStorage(StoragesNamesModule.token);
    await this.http.post(`${this.apiUrlService.tokenApi}/revoke`, { token });
    this.loginService.logOut(false);
  }

  isUserSuperAdmin(user: Employee): boolean {
    return user ? user.email === 'lgutwinski@gutwinski.at' : false;
  }

  fetchGetTokenErrorTranslation(): Promise<Array<string>> {
    return this.translateService
      .get(['AUTHORIZATION.MESSAGE.ERROR.HEADER', 'AUTHORIZATION.MESSAGE.ERROR.CONTENT'])
      .toPromise()
      .then((translation: Array<string>) => {
        return translation;
      });
  }
}

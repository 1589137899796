import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
// Models
import { AuditType } from './../../../../shared/models/audit-type.model';
// Services
import { AuditTypeService } from './../../../../shared/services/audit-type.service';
import { NotificationsService } from 'angular2-notifications';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gw-add-audit-type',
  templateUrl: './add-audit-type.component.html',
  styleUrls: ['./add-audit-type.component.scss'],
  exportAs: 'gwAddAuditType'
})
export class AddAuditTypeComponent implements OnInit, OnDestroy {
  @Input() auditType: AuditType;
  addAuditTypeForm: FormGroup;
  submitted = false;
  translateSubscription: Subscription;
  errors = {
    connectionTitle: '',
    createAuditType: '',
    updateAuditType: ''
  };
  success = {
    createAuditTypeTitle: '',
    createAuditTypeText: '',
    updateAuditTypeTitle: '',
    updateAuditTypeText: ''
  };

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private auditTypeService: AuditTypeService
  ) { }

  ngOnInit() {
    this.translateSubscription = this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'ADMIN_SETTINGS.AUDIT_TYPES.ERROR.CREATE_AUDIT_TYPE',
      'ADMIN_SETTINGS.AUDIT_TYPES.ERROR.UPDATE_AUDIT_TYPE',
      'ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.CREATE_AUDIT_TYPE_TITLE',
      'ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.CREATE_AUDIT_TYPE_TEXT',
      'ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.UPDATE_AUDIT_TYPE_TITLE',
      'ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.UPDATE_AUDIT_TYPE_TEXT'
    ])
      .subscribe((translation: any) => {
        this.errors.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.errors.createAuditType = translation['ADMIN_SETTINGS.AUDIT_TYPES.ERROR.CREATE_AUDIT_TYPE'];
        this.errors.updateAuditType = translation['ADMIN_SETTINGS.AUDIT_TYPES.ERROR.UPDATE_AUDIT_TYPE'];
        this.success.createAuditTypeTitle = translation['ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.CREATE_AUDIT_TYPE_TITLE'];
        this.success.createAuditTypeText = translation['ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.CREATE_AUDIT_TYPE_TEXT'];
        this.success.updateAuditTypeTitle = translation['ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.UPDATE_AUDIT_TYPE_TITLE'];
        this.success.updateAuditTypeText = translation['ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.UPDATE_AUDIT_TYPE_TEXT'];
      });

    this.addAuditTypeForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['']
    });
    if (this.auditType) {
      this.setControlValue(this.auditType.title, 'title');
      this.setControlValue(this.auditType.description, 'description');
    }
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
  }

  resetForm() {
    if (this.auditType) {
      this.setControlValue(this.auditType.title, 'title');
      this.setControlValue(this.auditType.description, 'description');
    } else {
      this.setControlValue('', 'title');
      this.setControlValue('', 'description');
    }
  }

  setControlValue(data: any, key: string): void {
    this.addAuditTypeForm.controls[key].setValue(data);
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  createAuditType(): Promise<any> {
    const auditTypeToCreate = new AuditType(this.addAuditTypeForm.value);
    return this.auditTypeService.createAuditType(auditTypeToCreate)
      .then(auditType => {
        this.resetForm();
        this.submitted = false;
        this.notificationsService.success(this.success.createAuditTypeTitle, this.success.createAuditTypeText);
        return {
          status: 'create',
          auditType
        };
      })
      .catch(error => {
        this.notificationsService.error(this.errors.connectionTitle, this.errors.createAuditType);
        throw error;
      });
  }

  updateAuditType(): Promise<any> {
    const auditTypeToUpdate = new AuditType(this.addAuditTypeForm.value);
    auditTypeToUpdate.id = this.auditType.id;
    return this.auditTypeService.updateAuditType(auditTypeToUpdate)
      .then(auditType => {
        this.resetForm();
        this.submitted = false;
        this.notificationsService.success(this.success.updateAuditTypeTitle, this.success.updateAuditTypeText);
        return {
          status: 'update',
          auditType
        };
      })
      .catch(error => {
        this.notificationsService.error(this.errors.connectionTitle, this.errors.updateAuditType);
        throw error;
      });
  }

  submitAddFindingTypeForm(): Promise<any> {
    this.submitted = true;
    if (this.addAuditTypeForm.valid) {
      if (this.auditType) {
        return this.updateAuditType();
      } else {
        return this.createAuditType();
      }
    } else {
      return new Promise((resolve, reject) => reject({ invalid: true }));
    }
  }
}

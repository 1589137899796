import gql from 'graphql-tag';

import { BackgroundImageFragment } from './background-image.fragment';
import { ComplianceRatingStats, RelevanceRatingStats } from './rating-stats.fragment';
import { UnitTypeFragment } from './unit-type.fragment';

export const UnitFragment = gql`
  fragment UnitFragment on CompanyUnit {
    id
    name
    path
    linkedRequirementsCount
    type {
      ...UnitTypeFragment
    }
    backgroundImage {
      ...BackgroundImageFragment
    }
  }
  ${BackgroundImageFragment}
  ${UnitTypeFragment}
`;

export const RootUnitFragment = gql`
  fragment RootUnitFragment on CompanyUnit {
    ...UnitFragment
    complianceRatingStats {
      ...ComplianceRatingStats
    }
    relevanceRatingStats {
      ...RelevanceRatingStats
    }
  }
  ${UnitFragment}
  ${ComplianceRatingStats}
  ${RelevanceRatingStats}
`;

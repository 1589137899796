import gql from 'graphql-tag';

import { CompanyStructureGraphQLActionType } from '../../enums/company-structure-graphql.enum';

import { BackgroundImageFragment } from '../fragments/background-image.fragment';
import { UnitFragment } from '../fragments/unit.fragment';

export const ArchiveCompanyUnitMutation = gql`
  mutation ${CompanyStructureGraphQLActionType.ArchiveCompanyUnit}($id: ID!) {
    ${CompanyStructureGraphQLActionType.ArchiveCompanyUnit}(id: $id) {
      status
    }
  }
`;

export const CreateCompanyUnitMutation = gql`
  mutation ${CompanyStructureGraphQLActionType.CreateCompanyUnit}($attributes: CompanyUnitAttributes!) {
    ${CompanyStructureGraphQLActionType.CreateCompanyUnit}(attributes: $attributes) {
      status
      unit {
        ...UnitFragment
        ancestors {
          id
        }
      }
    }
  }
  ${UnitFragment}
`;

export const UpdateCompanyUnitMutation = gql`
  mutation ${CompanyStructureGraphQLActionType.UpdateCompanyUnit}($attributes: CompanyUnitUpdateAttributes!) {
    ${CompanyStructureGraphQLActionType.UpdateCompanyUnit}(attributes: $attributes) {
      status
      unit {
        ...UnitFragment
      }
    }
  }
  ${UnitFragment}
`;

export const ChangeCompanyUnitParentMutation = gql`
  mutation ${CompanyStructureGraphQLActionType.ChangeCompanyUnitParent}($attributes: CompanyUnitChangeParentAttributes!) {
    ${CompanyStructureGraphQLActionType.ChangeCompanyUnitParent}(attributes: $attributes) {
      status
      unit {
        ...UnitFragment
        descendants {
          ...UnitFragment
        }
      }
    }
  }
  ${UnitFragment}
`;

export const UploadCompanyUnitBackgroundImageMutation = gql`
  mutation ${CompanyStructureGraphQLActionType.UploadUnitBackgroundImage}($backgroundImage: Upload!) {
    ${CompanyStructureGraphQLActionType.UploadUnitBackgroundImage}(backgroundImage: $backgroundImage) {
      status
      backgroundImage {
        ...BackgroundImageFragment
      }
    }
  }
  ${BackgroundImageFragment}
`;

import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';

import { EmployeeExtended } from '../../../../models/employee-extended.model';

import { ApiData } from '../../../../interfaces/api.interface';
import {
  ApiCreateEmployeeAvatarResponse,
  ApiCreateUpdateEmployeeAvatarVariables,
  ApiRemoveEmployeeAvatarResponse,
  ApiRemoveEmployeeAvatarVariables,
  ApiUpdateEmployeeAvatarResponse
} from '../interfaces/avatar.interface';

import { AttachmentFile } from '../../../../types/attachment-file.type';

import { UserConverterService } from './user.convert.service';

import { CreateEmployeeAvatar, RemoveEmployeeAvatar, UpdateEmployeeAvatar } from '../graphql/mutations/avatar.mutation';

@Injectable({
  providedIn: 'root'
})
export class AvatarService {
  constructor(private apollo: Apollo, private userConverterService: UserConverterService) {}

  createUserAvatar(employeeId: string, avatar: AttachmentFile): Promise<EmployeeExtended> {
    return this.apollo
      .mutate<ApiCreateEmployeeAvatarResponse, ApiCreateUpdateEmployeeAvatarVariables>({
        mutation: CreateEmployeeAvatar,
        variables: {
          attributes: {
            employeeId,
            avatar
          }
        },
        context: {
          useMultipart: true
        }
      })
      .toPromise()
      .then(({ data }: ApiData<ApiCreateEmployeeAvatarResponse>) => {
        return this.userConverterService.convertEmployeeToGet(data.createEmployeeAvatar.employee);
      })
      .catch(error => {
        console.error('Error while creating user avatar', error);
        throw error;
      });
  }

  updateUserAvatar(employeeId: string, avatar: AttachmentFile): Promise<EmployeeExtended> {
    return this.apollo
      .mutate<ApiUpdateEmployeeAvatarResponse, ApiCreateUpdateEmployeeAvatarVariables>({
        mutation: UpdateEmployeeAvatar,
        variables: {
          attributes: {
            employeeId,
            avatar
          }
        },
        context: {
          useMultipart: true
        }
      })
      .toPromise()
      .then(({ data }: ApiData<ApiUpdateEmployeeAvatarResponse>) => {
        return this.userConverterService.convertEmployeeToGet(data.updateEmployeeAvatar.employee);
      })
      .catch(error => {
        console.error('Error while updating user avatar', error);
        throw error;
      });
  }

  removeUserAvatar(employeeId: string): Promise<EmployeeExtended> {
    return this.apollo
      .mutate<ApiRemoveEmployeeAvatarResponse, ApiRemoveEmployeeAvatarVariables>({
        mutation: RemoveEmployeeAvatar,
        variables: {
          attributes: {
            employeeId
          }
        }
      })
      .toPromise()
      .then(({ data }: ApiData<ApiRemoveEmployeeAvatarResponse>) => {
        return this.userConverterService.convertEmployeeToGet(data.removeEmployeeAvatar.employee);
      })
      .catch(error => {
        console.error('Error while removing user avatar', error);
        throw error;
      });
  }
}

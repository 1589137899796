
<div class="audit-summary__box" *ngIf="exportReportForm">
  <div class="row">
    <div class="columns small-12">
      <div class="audit-summary__header audit-summary__header--with-subheader">
        <h1 class="audit-summary__header-title">{{ 'AUDIT_SUMMARY.REPORT.HEADER' | translate }}</h1>
      </div>
    </div>
    <div class="columns small-12 medium-6 audit-summary__subsection">
      <div class="audit-summary__subheader">{{ 'AUDIT_SUMMARY.REPORT.FORM.HEADER' | translate }}</div>
      <form class="audit-summary__form" [formGroup]="exportReportForm">
        <div class="audit-summary__form-group">
          <label class="label" for="company">{{ 'AUDIT_SUMMARY.REPORT.FORM.LABEL.COMPANY' | translate }}</label>
          <input class="input audit-summary-form__input" id="company" name="company" placeholder="{{ 'AUDIT_SUMMARY.REPORT.FORM.PLACEHOLDER.COMPANY' | translate }}" [formControl]="exportReportForm.controls.company" [gwDisableControl]="!offlineService.online"/>
        </div>
        <div class="audit-summary__form-group">
          <label class="label" for="facility">{{ 'AUDIT_SUMMARY.REPORT.FORM.LABEL.FACILITY' | translate }}</label>
          <input class="input audit-summary-form__input" id="facility" name="facility" placeholder="{{ 'AUDIT_SUMMARY.REPORT.FORM.PLACEHOLDER.FACILITY' | translate }}" [formControl]="exportReportForm.controls.facility" [gwDisableControl]="!offlineService.online"/>
        </div>
      </form>
    </div>
    <div class="columns small-12 medium-4 medium-offset-2 audit-summary__subsection">
      <div class="audit-summary__subheader">{{ 'AUDIT_SUMMARY.REPORT.ACTIONS_SECTION.HEADER' | translate }}</div>
      <div class="audit-summary__actions-section">
        <button class="button--tertiary audit-summary__action-button" type="button" [disabled]="exportingReport || !offlineService.online" (click)="submitExportReportForm(exportReportForm)"><i class="button__icon--before button__icon--spinner" *ngIf="exportingReport">
            <div class="spinner"></div></i>{{ 'AUDIT_SUMMARY.REPORT.ACTIONS_SECTION.IMPORT_AS_DOCX' | translate }}</button>
        <div class="audit-summary__action-button" [class.audit-summary__action-button--split]="audit?.modifiedReport">
          <div class="button--tertiary audit-summary__main-action" [class.button--disabled]="uploadingReport || !offlineService.online">
            <label class="audit-summary__action-button-text" for="upload" (keydown)="keyDownUploadReport($event, fileInput)"><i class="button__icon--before button__icon--spinner" *ngIf="uploadingReport">
                <div class="spinner"></div></i>
              <ng-container *ngIf="!audit?.modifiedReport">{{ 'AUDIT_SUMMARY.REPORT.ACTIONS_SECTION.UPLOAD_MODIFIED_REPORT' | translate }}</ng-container>
              <ng-container *ngIf="audit?.modifiedReport">{{ 'AUDIT_SUMMARY.REPORT.ACTIONS_SECTION.MODIFIED_REPORT' | translate }}</ng-container>
            </label>
            <input class="hide" #fileInput="" type="file" id="upload" (change)="uploadModifiedReport($event)" [disabled]="uploadingReport || !offlineService.online" placeholder="Upload modified report"/><a class="audit-summary__attachment" *ngIf="audit?.modifiedReport" [href]="audit.modifiedReport.url" download=""><i class="gw-attach audit-summary__attachment-icon"></i><span class="audit-summary__attachment-name">{{ audit.modifiedReport.name }}</span></a>
          </div>
          <div class="audit-summary__additional-action" *ngIf="audit?.modifiedReport">
            <button class="link" type="button" (click)="showRemoveModifiedReportModal()" [disabled]="uploadingReport || !offlineService.online"><i class="gw-close"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="offline-blanket" *ngIf="!offlineService.online">
    <div class="offline-blanket__content">{{ 'GLOBAL.LABEL.AVAILABLE_ONLINE' | translate }}</div>
  </div>
  <div class="audit-summary__charts--report">
    <div class="circle-chart--report" #circleChartReport=""></div>
    <div class="bar-chart--report" #barChartReport=""></div>
  </div>
</div>
import { FroalaEditorOptions } from '../interfaces/froala-editor-options.interface';

import { LanguageCode } from '../enums/language-code.enum';

export const DEFAULT_FROALA_OPTIONS: FroalaEditorOptions = {
  attribution: false,
  toolbarButtons: [
    'bold',
    'italic',
    'underline',
    'clearFormatting',
    '|',
    'paragraphFormat',
    'align',
    'formatOL',
    'formatUL',
    'outdent',
    'indent',
    '|',
    'subscript',
    'superscript',
    'insertLink',
    'insertImage',
    'insertVideo',
    'insertTable',
    '|',
    'undo',
    'redo',
    'fullscreen'
  ],
  imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],
  quickInsertEnabled: false,
  charCounterCount: false,
  linkAlwaysBlank: true,
  zIndex: 7,
  videoUpload: false,
  language: LanguageCode.de
};

export const SMALL_FROALA_OPTIONS: FroalaEditorOptions = {
  ...DEFAULT_FROALA_OPTIONS,
  toolbarButtons: [
    'bold',
    'italic',
    'underline',
    'clearFormatting',
    '|',
    'paragraphFormat',
    'align',
    '|',
    'formatOL',
    'formatUL',
    '|',
    'help'
  ]
};

export const CLIENT_FROALA_OPTIONS: FroalaEditorOptions = {
  attribution: false,
  toolbarButtons: [
    'bold',
    'italic',
    'underline',
    'clearFormatting',
    '|',
    'paragraphFormat',
    'align',
    'formatOL',
    'formatUL',
    '|',
    'outdent',
    'indent',
    '|',
    'subscript',
    'superscript',
    'insertLink',
    '|',
    'undo',
    'redo',
    'fullscreen'
  ],
  charCounterCount: false,
  linkAlwaysBlank: true,
  quickInsertEnabled: false,
  zIndex: 7,
  language: LanguageCode.de
};

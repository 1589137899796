import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// Providers
import { AuthenticationService } from './../services/authentication.service';
import { UserService } from './../services/user.service';

@Injectable()
export class LoginGuardSuperAdmin implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticationService.isUserSuperAdmin(this.userService.storedUser)) {
      return true;
    }

    this.router.navigate(['admin']);
    return false;
  }

}

import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, ElementRef, Injectable } from '@angular/core';

import { TooltipComponent } from '../components/tooltip/tooltip.component';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  tooltipsOverlay: Array<OverlayRef> = [];

  constructor(private overlay: Overlay, private overlayPositionBuilder: OverlayPositionBuilder) {}

  showTooltip(text: string, elementRef: ElementRef): OverlayRef {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(elementRef).withPositions([
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: 8
      }
    ]);
    const overlayRef = this.overlay.create({ positionStrategy });
    const tooltipPortal = new ComponentPortal(TooltipComponent);
    const tooltipRef: ComponentRef<TooltipComponent> = overlayRef.attach(tooltipPortal);
    tooltipRef.instance.text = text;
    this.tooltipsOverlay.push(overlayRef);
    return overlayRef;
  }

  closeTooltip(tooltipOverlay: OverlayRef): void {
    tooltipOverlay.detach();
  }

  closeAllTooltips(): void {
    this.tooltipsOverlay.forEach(tooltip => tooltip.detach());
    this.tooltipsOverlay = [];
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogService, ModalService, OfflineService } from 'gutwin-shared';

import { Template } from '@gutwin-audit/shared/models/template.model';

import { AddTemplateComponent } from '@gutwin-audit/audit/audits/audits-list/add-template/add-template.component';
import { CopyAuditComponent } from '@gutwin-audit/audit/audits/audits-list/copy-audit/copy-audit.component';

import { FilterModuleService } from '@gutwin-audit/shared/services/filter-module.service';
import { PermissionService } from '@gutwin-audit/shared/services/permission.service';
import { TemplateService } from '@gutwin-audit/shared/services/template.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';

@Component({
  selector: 'gw-templates-group',
  templateUrl: './templates-group.component.html',
  styleUrls: ['./templates-group.component.scss']
})
export class TemplatesGroupComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('addTemplate') addTemplateComponent: AddTemplateComponent;
  @ViewChild('copyAudit') copyAuditComponent: CopyAuditComponent;
  @Input() templates: Array<Template>;
  @Output() templateUpdated = new EventEmitter();
  @Output() templateRemoved = new EventEmitter();
  @Output() auditCreated = new EventEmitter();
  filters: any;
  savingTemplate: boolean;
  savingAudit: boolean;
  userSubscription: Subscription;
  translation = {
    removeDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    notify: {
      error: {
        connectionTitle: '',
        permissions: '',
        removeTemplateText: '',
        removeTemplatePermissionsText: ''
      },
      success: {
        title: '',
        removeTemplateText: ''
      }
    }
  };
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialogService: DialogService,
    private filterService: FilterModuleService,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private permissionService: PermissionService,
    private templateService: TemplateService,
    private translateService: TranslateService,
    private userService: UserService,
    public offlineService: OfflineService
  ) {}

  ngOnInit(): void {
    this.fetchTranslations();
    this.markTemplatesDisabled();
    this.filters = this.filterService.filters;
    this.observeCurrentUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.audits) {
      this.markTemplatesDisabled();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  observeCurrentUser(): Subscription {
    return this.userService.storedUserObservable.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.markTemplatesDisabled();
    });
  }

  markTemplatesDisabled(): void {
    if (this.templates) {
      this.templates.forEach(template => {
        template.audit.disabled = !this.permissionService.canAuditBeManaged(
          template.audit,
          this.userService.storedUser
        );
      });
    }
  }

  showRemoveTemplateDialog(template: Template): void {
    this.dialogService
      .confirm(
        this.translation.removeDialog.header,
        this.translation.removeDialog.content,
        this.translation.removeDialog.cancel,
        this.translation.removeDialog.confirm
      )
      .then(() => {
        this.removeTemplate(template);
      })
      .catch(() => {});
  }

  removeTemplate(template: Template): void {
    const showErrorNotification = (error: any): void => {
      switch (error.status) {
        case 403:
          this.notificationsService.error(
            this.translation.notify.error.permissions,
            this.translation.notify.error.removeTemplatePermissionsText
          );
          break;
        default:
          this.notificationsService.error(
            this.translation.notify.error.connectionTitle,
            this.translation.notify.error.removeTemplateText
          );
      }
    };

    this.templateService
      .removeTemplate(template.audit.id)
      .then(() => {
        this.templateRemoved.emit();
        this.notificationsService.success(
          this.translation.notify.success.title,
          this.translation.notify.success.removeTemplateText
        );
      })
      .catch(error => {
        showErrorNotification(error);
      });
  }

  showUpdateTemplateModal(template: Template): void {
    this.modalService.open('templateModal', { audit: template.audit, template });
  }

  saveTemplate(): void {
    this.addTemplateComponent
      .submitAddTemplateForm()
      .then(() => {
        this.templateUpdated.emit();
        this.modalService.close('templateModal');
      })
      .catch(error => {});
  }

  showCopyAuditModal(template: Template): void {
    const lastTabindex = template.audit.auditedAreas ? template.audit.auditedAreas.length * 6 + 1 : 1;
    this.modalService.open('copyAuditModal', { audit: template.audit, auditName: template.name, lastTabindex });
  }

  saveAudit(): void {
    this.savingAudit = true;
    this.copyAuditComponent
      .submitAuditForm()
      .then(() => {
        this.modalService.close('copyAuditModal');
        this.savingAudit = false;
        this.auditCreated.emit();
      })
      .catch(error => {
        this.savingAudit = false;
      });
  }

  async fetchTranslations(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ERROR.PERMISSIONS',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'GLOBAL.ACTION.SUCCESS',
        'TEMPLATES_LIST.ERROR.REMOVE_TEMPLATE_TEXT',
        'TEMPLATES_LIST.ERROR.REMOVE_TEMPLATE_PERMISSIONS_TEXT',
        'TEMPLATES_LIST.SUCCESS.REMOVE_TEMPLATE_TEXT',
        'TEMPLATES_LIST.REMOVE_TEMPLATE_DIALOG.HEADER',
        'TEMPLATES_LIST.REMOVE_TEMPLATE_DIALOG.CONTENT'
      ])
      .toPromise();
    this.translation.removeDialog.header = translation['TEMPLATES_LIST.REMOVE_TEMPLATE_DIALOG.HEADER'];
    this.translation.removeDialog.content = translation['TEMPLATES_LIST.REMOVE_TEMPLATE_DIALOG.CONTENT'];
    this.translation.removeDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
    this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.permissions = translation['GLOBAL.ERROR.PERMISSIONS'];
    this.translation.notify.error.removeTemplateText = translation['TEMPLATES_LIST.ERROR.REMOVE_TEMPLATE_TEXT'];
    this.translation.notify.error.removeTemplatePermissionsText =
      translation['TEMPLATES_LIST.ERROR.REMOVE_TEMPLATE_PERMISSIONS_TEXT'];
    this.translation.notify.success.title = translation['GLOBAL.ACTION.SUCCESS'];
    this.translation.notify.success.removeTemplateText = translation['TEMPLATES_LIST.SUCCESS.REMOVE_TEMPLATE_TEXT'];
  }
}

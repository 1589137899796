import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { StorageService, StoragesNames } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private loginUrl = '/login';

  constructor(
    protected router: Router,
    protected storageService: StorageService,
    protected activatedRoute: ActivatedRoute,
    protected location: Location
  ) {}

  setLoginUrl(loginUrl: string): void {
    this.loginUrl = loginUrl;
  }

  isLoggedIn(): boolean {
    const isToken = !!this.storageService.getLocalStorage(StoragesNames.token);
    return isToken;
  }

  logOut(redirect = true, redirectUrl?: string, externalAuthUrl?: string, fromExternal?: boolean): void {
    this.cleanLocalStorage();
    redirectUrl = this.generateRedirectUrl(redirect, redirectUrl);
    this.navigateToAuthPage(redirectUrl, externalAuthUrl, fromExternal);
  }

  cleanLocalStorage(): void {
    this.storageService.removeFromLocalStorage(StoragesNames.token);
    this.storageService.removeFromLocalStorage(StoragesNames.currentUser);
    this.storageService.removeFromLocalStorage(StoragesNames.language);
    this.storageService.removeFromLocalStorage(StoragesNames.state);
  }

  generateRedirectUrl(redirect = true, redirectUrl?: string): string {
    if (!redirectUrl && redirect) {
      const url = this.separatePath(this.location.path());
      if (url === this.loginUrl) {
        redirectUrl = '';
      } else {
        redirectUrl = this.location.path();
      }
    }
    return redirectUrl;
  }

  navigateToAuthPage(redirectUrl?: string, externalAuthUrl?: string, fromExternal?: boolean): void {
    if (externalAuthUrl) {
      const redirectQueryParam = redirectUrl ? `?redirect_url=${window.location.origin}${redirectUrl}` : '';
      window.location.href = `${externalAuthUrl}${redirectQueryParam}`;
    } else {
      const queryParams = {
        redirect_url: redirectUrl || null,
        external_service: fromExternal ? true : null
      };
      this.router.navigate([this.loginUrl], { queryParams });
    }
  }

  separatePath(url: string): string {
    const index = url.indexOf('?');
    if (~index) {
      return url.slice(0, index);
    }
    return url;
  }
}

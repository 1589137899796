
<div class="findings-browser">
  <div class="findings-browser__box">
    <div class="form__field">
      <ng-content select="[findings-browser-nav]"></ng-content>
    </div>
    <div class="form__field">
      <label class="label" for="facility" (click)="facilitySelect.focusDropdown()">{{ 'AUDIT_CONDUCT.FINDINGS_BROWSER.FORM.LABEL.AUDITED_AREA' | translate }}</label>
      <gw-select #facilitySelect="gwSelect" [ngModel]="currentAuditable" [placeholder]="'AUDIT_CONDUCT.FINDINGS_BROWSER.FORM.PLACEHOLDER.AUDITED_AREA' | translate" [data]="displayedAuditedAreas" [display]="['facility.name']" [uniqueKey]="'id'" (ngModelChange)="changeAuditable($event)"></gw-select>
    </div>
    <div class="form__field">
      <label class="label" for="search">{{ 'AUDIT_CONDUCT.FINDINGS_BROWSER.FORM.LABEL.SEARCH_FINDINGS' | translate }}</label>
      <div class="input-icon-group--after">
        <input class="input" placeholder="{{ 'AUDIT_CONDUCT.FINDINGS_BROWSER.FORM.PLACEHOLDER.SEARCH_FINDINGS' | translate }}" type="text" id="search" name="search" [(ngModel)]="findingSearchQuery" [ngModelOptions]="{standalone: true}" (ngModelChange)="searchFindings()"/><i class="gw-search input-icon-group__icon"></i>
      </div>
    </div>
  </div>
  <div class="blankslate findings-browser__blankslate" *ngIf="!findings?.length &amp;&amp; !findingSearchQuery &amp;&amp; !loadingPage">{{ 'AUDIT_CONDUCT.FINDINGS_BROWSER.BLANKSLATE.NO_FINDINGS' | translate }}</div>
  <div class="blankslate findings-browser__blankslate" *ngIf="!findings?.length &amp;&amp; findingSearchQuery &amp;&amp; !loadingPage">{{ 'AUDIT_CONDUCT.FINDINGS_BROWSER.BLANKSLATE.NO_SEARCH_RESULTS' | translate }}</div>
  <gw-finding-box class="findings-browser__finding" *ngFor="let finding of findings" [finding]="finding" [isAuditDisabled]="isAuditDisabled" (removeFinding)="showRemoveFindingModal($event)"></gw-finding-box>
  <div class="findings-browser__pagination">
    <gw-pagination *ngIf="findingsAmount.filtered &gt; findingService.findingsLimit" [light]="true" [currentPage]="findingsPage" [currentLimit]="findingService.findingsLimit" [totalElementsAmount]="findingsAmount.filtered" [numberOfPagesToShow]="3" (changePage)="changeFindingsPage($event)"></gw-pagination>
  </div>
</div>

<div class="add-rating-scale">
  <form class="add-rating-scale__form" [formGroup]="addRatingScaleForm" novalidate="novalidate" autocomplete="off">
    <div class="add-rating-scale__fieldset">
      <div class="row add-rating-scale__fields">
        <div class="columns small-12 small-centered">
          <div class="form__field--lower add-rating-scale__field" [class.form__field--error]="isFieldInvalid(addRatingScaleForm.controls['name'])">
            <label class="label add-rating-scale__field-label" for="name">{{ 'ADMIN_SETTINGS.RATING_SCALES.MODAL.FORM.LABEL.NAME' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.RATING_SCALES.MODAL.FORM.ERROR.NAME_EMPTY' | translate }}</div>
            <input class="input" placeholder="{{ 'ADMIN_SETTINGS.RATING_SCALES.MODAL.FORM.PLACEHOLDER.NAME' | translate }}" type="text" id="name" name="name" [formControl]="addRatingScaleForm.controls['name']"/>
          </div>
          <div class="form__field--lower add-rating-scale__field" *ngIf="!ratingScale?.default" [class.form__field--error]="isFieldInvalid(addRatingScaleForm.controls['auditTypes'])">
            <label class="label" for="auditTypes" (click)="auditTypesSelect.focusDropdown()">{{ 'ADMIN_SETTINGS.RATING_SCALES.MODAL.FORM.LABEL.AUDIT_TYPES' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.RATING_SCALES.MODAL.FORM.ERROR.AUDIT_TYPES' | translate }}</div>
            <div class="add-report__multiselect-wrapper">
              <gw-multiselect #auditTypesSelect="gwMultiselect" [formControl]="addRatingScaleForm.controls['auditTypes']" [placeholder]="'ADMIN_SETTINGS.RATING_SCALES.MODAL.FORM.PLACEHOLDER.AUDIT_TYPES' | translate" [data]="auditTypes" [display]="['title']" [splitter]="', '" [uniqueKey]="'id'" (focus)="auditTypesSelect.focusDropdown()"></gw-multiselect>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { GutwinSharedModule } from 'gutwin-shared';

import { SharedModule } from '../shared/shared.module';
import { AuditRoutingModule } from './audit.routing.module';

import { AuditCreationHeaderComponent } from './audit-creation/audit-creation-header/audit-creation-header.component';
import { AuditCreationComponent } from './audit-creation/audit-creation.component';
import { EditQuestionsComponent } from './audit-creation/edit-questions/edit-questions.component';
import { SelectFacilityComponent } from './audit-creation/select-facility/select-facility.component';
import { Step1DefineAuditComponent } from './audit-creation/step-1-define-audit/step-1-define-audit.component';
import { Step2AuditPlanComponent } from './audit-creation/step-2-audit-plan/step-2-audit-plan.component';
import { Step3ScheduleAuditComponent } from './audit-creation/step-3-schedule-audit/step-3-schedule-audit.component';
import { Step4AuditQuestionsComponent } from './audit-creation/step-4-audit-questions/step-4-audit-questions.component';
import { AuditFindingsComponent } from './audit-summary/audit-findings/audit-findings.component';
import { AuditReportComponent } from './audit-summary/audit-report/audit-report.component';
import { AuditSummarySectionComponent } from './audit-summary/audit-summary-section/audit-summary-section.component';
import { AuditSummaryViewComponent } from './audit-summary/audit-summary-view.component';
import { AuditSummaryComponent } from './audit-summary/audit-summary/audit-summary.component';
import { BarChartComponent } from './audit-summary/bar-chart/bar-chart.component';
import { CircleChartComponent } from './audit-summary/circle-chart/circle-chart.component';
import { SummaryTabsComponent } from './audit-summary/summary-tabs/summary-tabs.component';
import { AddTemplateComponent } from './audits/audits-list/add-template/add-template.component';
import { AuditsFiltersComponent } from './audits/audits-list/audits-filters/audits-filters.component';
import { DateFilterComponent } from './audits/audits-list/audits-filters/date-filter/date-filter.component';
import { LocationFilterComponent } from './audits/audits-list/audits-filters/location-filter/location-filter.component';
import { NameFilterComponent } from './audits/audits-list/audits-filters/name-filter/name-filter.component';
import { AuditsGroupComponent } from './audits/audits-list/audits-group/audits-group.component';
import { AuditsListComponent } from './audits/audits-list/audits-list.component';
import { AuditsTabsComponent } from './audits/audits-list/audits-tabs/audits-tabs.component';
import { AuditedAreaFormComponent } from './audits/audits-list/copy-audit/audited-area-form/audited-area-form.component';
import { CopyAuditComponent } from './audits/audits-list/copy-audit/copy-audit.component';
import { SubauditsGroupComponent } from './audits/audits-list/subaudits-group/subaudits-group.component';
import { TemplatesGroupComponent } from './audits/audits-list/templates-group/templates-group.component';
import { AuditsDraftsComponent } from './audits/audits-list/views/audits-drafts/audits-drafts.component';
import { AuditsFinishedComponent } from './audits/audits-list/views/audits-finished/audits-finished.component';
import { AuditsInProgressComponent } from './audits/audits-list/views/audits-in-progress/audits-in-progress.component';
import { TemplatesComponent } from './audits/audits-list/views/templates/templates.component';
import { AuditsNavigationComponent } from './audits/audits-navigation/audits-navigation.component';
import { AuditsComponent } from './audits/audits.component';
import { DashboardComponent } from './audits/dashboard/dashboard.component';
import { FindingDetailsComponent } from './audits/findings-list/finding-details/finding-details.component';
import { FindingsFiltersComponent } from './audits/findings-list/findings-filters/findings-filters.component';
import { SearchFilterComponent } from './audits/findings-list/findings-filters/search-filter/search-filter.component';
import { FindingsListComponent } from './audits/findings-list/findings-list.component';
import { FindingsTableComponent } from './audits/findings-list/findings-table/findings-table.component';
import { FindingsTabsComponent } from './audits/findings-list/findings-tabs/findings-tabs.component';
import { FindingsAllComponent } from './audits/findings-list/views/findings-all/findings-all.component';
import { FindingsInProgressComponent } from './audits/findings-list/views/findings-in-progress/findings-in-progress.component';
import { FindingsNotAssignedComponent } from './audits/findings-list/views/findings-not-assigned/findings-not-assigned.component';
import { FindingsOverdueComponent } from './audits/findings-list/views/findings-overdue/findings-overdue.component';
import { FindingsResolvedComponent } from './audits/findings-list/views/findings-resolved/findings-resolved.component';
import { ReferenceDocumentsComponent } from './audits/reference-documents/reference-documents.component';
import { ChooseAuditedAreaComponent } from './conduct-audit/choose-audited-area/choose-audited-area.component';
import { ConductAuditComponent } from './conduct-audit/conduct-audit.component';
import { FindingBoxComponent } from './conduct-audit/finding-box/finding-box.component';
import { FindingsBrowserComponent } from './conduct-audit/findings-browser/findings-browser.component';
import { FindingsSummarySwitchComponent } from './conduct-audit/findings-summary-switch/findings-summary-switch.component';
import { QuestionDetailsComponent } from './conduct-audit/question-details/question-details.component';
import { SummaryComponent } from './conduct-audit/summary/summary.component';
import { FilesSectionComponent } from './finding-view/files-section/files-section.component';
import { FindingSourceComponent } from './finding-view/finding-source/finding-source.component';
import { FindingViewDetailsComponent } from './finding-view/finding-view-details/finding-view-details.component';
import { FindingViewComponent } from './finding-view/finding-view.component';
import { SolveFindingComponent } from './finding-view/solve-finding/solve-finding.component';
import { SolvingComponent } from './finding-view/solving/solving.component';

import { AuditNavigationService } from './services/audit-navigation.service';

import { AuditTypesResolve } from './resolvers/audit-types.resolve';
import { AuditResolve } from './resolvers/audit.resolve';
import { AuditedAreaResolve } from './resolvers/audited-area.resolve';
import { AuditsAmountResolve } from './resolvers/audits.amount.resolve';
import { AuditsDraftsResolve } from './resolvers/audits.drafts.resolve';
import { AuditsFinishedResolve } from './resolvers/audits.finished.resolve';
import { AuditsInProgressResolve } from './resolvers/audits.in-progress.resolve';
import { EmployeesResolve } from './resolvers/employees.resolve';
import { FacilitiesFilterResolve } from './resolvers/facilities-filter.resolve';
import { FindingTypesResolve } from './resolvers/finding-types.resolve';
import { FindingResolve } from './resolvers/finding.resolve';
import { FindingsInProgressResolve } from './resolvers/findings-list.in-progress.resolve';
import { FindingsNotAssignedResolve } from './resolvers/findings-list.not-assigned.resolve';
import { FindingsOverdueResolve } from './resolvers/findings-list.overdue.resolve';
import { FindingsListResolve } from './resolvers/findings-list.resolve';
import { FindingsResolvedResolve } from './resolvers/findings-list.resolved.resolve';
import { SectionsWithQuestionsResolve } from './resolvers/sections-questions.resolve';
import { TemplatesResolve } from './resolvers/templates.resolve';

import { CalcTabIndexPipe } from './audits/audits-list/copy-audit/audited-area-form/calc-tabindex.pipe';

export const AuditModuleDependencides = {
  declarations: [
    AddTemplateComponent,
    AuditCreationComponent,
    AuditCreationHeaderComponent,
    AuditFindingsComponent,
    AuditReportComponent,
    AuditSummaryComponent,
    AuditSummarySectionComponent,
    AuditSummaryViewComponent,
    AuditedAreaFormComponent,
    AuditsComponent,
    AuditsDraftsComponent,
    AuditsFiltersComponent,
    AuditsFiltersComponent,
    AuditsFinishedComponent,
    AuditsGroupComponent,
    AuditsInProgressComponent,
    AuditsListComponent,
    AuditsNavigationComponent,
    AuditsTabsComponent,
    BarChartComponent,
    ChooseAuditedAreaComponent,
    CircleChartComponent,
    ConductAuditComponent,
    CopyAuditComponent,
    DashboardComponent,
    DateFilterComponent,
    EditQuestionsComponent,
    FilesSectionComponent,
    FindingBoxComponent,
    FindingDetailsComponent,
    FindingSourceComponent,
    FindingViewComponent,
    FindingViewDetailsComponent,
    FindingsAllComponent,
    FindingsBrowserComponent,
    FindingsFiltersComponent,
    FindingsInProgressComponent,
    FindingsListComponent,
    FindingsNotAssignedComponent,
    FindingsOverdueComponent,
    FindingsResolvedComponent,
    FindingsSummarySwitchComponent,
    FindingsTableComponent,
    FindingsTabsComponent,
    LocationFilterComponent,
    NameFilterComponent,
    QuestionDetailsComponent,
    ReferenceDocumentsComponent,
    SearchFilterComponent,
    SelectFacilityComponent,
    SolveFindingComponent,
    SolvingComponent,
    Step1DefineAuditComponent,
    Step2AuditPlanComponent,
    Step3ScheduleAuditComponent,
    Step4AuditQuestionsComponent,
    SubauditsGroupComponent,
    SummaryComponent,
    SummaryTabsComponent,
    TemplatesComponent,
    TemplatesGroupComponent,
    CalcTabIndexPipe
  ],
  providers: [
    AuditNavigationService,
    AuditResolve,
    AuditTypesResolve,
    AuditedAreaResolve,
    AuditsAmountResolve,
    AuditsDraftsResolve,
    AuditsFinishedResolve,
    AuditsInProgressResolve,
    EmployeesResolve,
    FacilitiesFilterResolve,
    FindingResolve,
    FindingTypesResolve,
    FindingsInProgressResolve,
    FindingsListResolve,
    FindingsNotAssignedResolve,
    FindingsOverdueResolve,
    FindingsResolvedResolve,
    SectionsWithQuestionsResolve,
    TemplatesResolve
  ]
};

@NgModule({
  imports: [CommonModule, SharedModule, GutwinSharedModule, AuditRoutingModule],
  declarations: AuditModuleDependencides.declarations,
  providers: AuditModuleDependencides.providers
})
export class AuditModule {}

import { PermissionCategory } from '../models/permission-category.model';
import { Permission } from '../models/permission.model';
import { Role } from '../models/role.model';

export namespace PermissionCategoryUtil {
  export function getPermissionCategoriesFromPermissionIds(
    permissionsIds: Array<string>,
    permissionCategories: Array<PermissionCategory>
  ): Array<PermissionCategory> {
    return permissionCategories.map(permissionCategory => {
      const permissions = permissionCategory.permissions.map(permission => {
        const newPermission = new Permission(permission);
        newPermission.added = !!~permissionsIds.indexOf(permission.id);
        return newPermission;
      });
      const newPermissionCategory = new PermissionCategory(permissionCategory);
      newPermissionCategory.permissions = permissions;
      return newPermissionCategory;
    });
  }

  export function clearPermissionCategories(
    permissionCategories: Array<PermissionCategory>
  ): Array<PermissionCategory> {
    return permissionCategories.map(permissionCategory => {
      return new PermissionCategory({
        ...permissionCategory,
        permissions: permissionCategory.permissions.map(permission => {
          return new Permission({
            ...permission,
            added: false,
            rolesIds: []
          });
        })
      });
    });
  }

  export function mergeDuplicatedPermissionCategories(
    permissionCategories: Array<PermissionCategory>
  ): Array<PermissionCategory> {
    return permissionCategories.reduce((reducedPermissionCategories, currentPermissionCategory) => {
      const duplicatedCategoryIndex = reducedPermissionCategories.findIndex(
        permissionCategory => permissionCategory.id === currentPermissionCategory.id
      );

      if (duplicatedCategoryIndex >= 0) {
        reducedPermissionCategories[
          duplicatedCategoryIndex
        ].permissions = PermissionCategoryUtil.mergeDuplicatedPermissions([
          ...reducedPermissionCategories[duplicatedCategoryIndex].permissions,
          ...currentPermissionCategory.permissions
        ]);
      } else {
        reducedPermissionCategories = [...reducedPermissionCategories, currentPermissionCategory];
      }
      return reducedPermissionCategories;
    }, []);
  }

  export function mergeDuplicatedPermissions(permissions: Array<Permission>): Array<Permission> {
    return permissions.reduce((reducedPermissions: Array<Permission>, currentPermission: Permission) => {
      const duplicatedPermissionIndex = reducedPermissions.findIndex(
        permission => permission.id === currentPermission.id
      );
      if (duplicatedPermissionIndex >= 0) {
        reducedPermissions[duplicatedPermissionIndex].roles = [
          ...(reducedPermissions[duplicatedPermissionIndex].roles ?? []),
          ...(currentPermission.roles ?? [])
        ];
        reducedPermissions[duplicatedPermissionIndex].rolesIds = [
          ...(reducedPermissions[duplicatedPermissionIndex].rolesIds ?? []),
          ...(currentPermission.rolesIds ?? [])
        ];
      } else {
        reducedPermissions = [...reducedPermissions, currentPermission];
      }

      return reducedPermissions;
    }, []);
  }

  export function getPermissionCategoriesFromRoles(
    roles: Array<Role>,
    allPermissionCategories: Array<PermissionCategory>
  ): Array<PermissionCategory> {
    const permissionCategories = [];
    if (roles) {
      roles.forEach(role => {
        const rolePermissionCategories = role.permissionCategories.map(permissionCategory => {
          return new PermissionCategory({
            ...permissionCategory,
            permissions: permissionCategory.permissions.map(permission => {
              return new Permission({
                ...permission,
                rolesIds: [role.id],
                roles: [role]
              });
            })
          });
        });
        permissionCategories.push(...rolePermissionCategories);
      });
    }
    return PermissionCategoryUtil.mergeDuplicatedPermissionCategories([
      ...PermissionCategoryUtil.clearPermissionCategories(allPermissionCategories),
      ...permissionCategories
    ]);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-requirement-sidebar-numeric-id',
  templateUrl: './requirement-sidebar-numeric-id.component.html',
  styleUrls: ['./requirement-sidebar-numeric-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequirementSidebarNumericIdComponent {
  @Input() id: string;
  @Input() userType: UserType;

  readonly USER_TYPE = UserType;
}

import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import { noop, Observable, Subscription } from 'rxjs';

// Gutwin Shared Library
import { Employee } from 'gutwin-shared';

import { AuditType } from '../../../shared/models/audit-type.model';
// Models
import { Audit } from '../../../shared/models/audit.model';

// Services
import { AuditService } from '../../../shared/services/audit.service';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'gw-step-1-define-audit',
  templateUrl: './step-1-define-audit.component.html',
  styleUrls: ['./step-1-define-audit.component.scss']
})
export class Step1DefineAuditComponent implements OnInit, OnChanges, OnDestroy {
  @Output() submitStep = new EventEmitter<any>();
  @Output() updateAudit = new EventEmitter<any>();
  @Output() updateStorage = new EventEmitter<any>();
  @Input() audit: Audit;
  @Input() auditObservable: Observable<Audit>;
  @Input() auditTypes: Array<AuditType>;
  @Input() employees: Array<Employee>;
  defineAuditForm: FormGroup;
  submitted = false;
  coAuditorsVisible = false;
  storedUserSubscription: Subscription;
  translateSubscription: Subscription;
  notify = {
    error: {
      connection: '',
      permissions: '',
      forbidden: '',
      createAudit: '',
      createAuditPermissions: '',
      updateAudit: '',
      updateAuditPermissions: ''
    },
    success: {
      createAuditTitle: '',
      createAuditText: '',
      updateAuditTitle: '',
      updateAuditText: ''
    }
  };

  constructor(
    private auditService: AuditService,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.fetchTranslation();
    this.initForm();
    this.auditObservable.subscribe(audit => {
      this.audit = audit;
      this.updateForm();
    });
    this.storedUserSubscription = this.userService.storedUserObservable.subscribe(storedUser => {
      this.setControlValue(this.getOrganizerControlValue() || [], 'organizer');
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['employees'] || changes['auditTypes']) {
      this.updateForm();
    }
  }

  ngOnDestroy(): void {
    this.translateSubscription.unsubscribe();
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ERROR.PERMISSIONS',
        'GLOBAL.ERROR.FORBIDDEN',
        'AUDIT_CREATION.ERROR.CREATE_AUDIT',
        'AUDIT_CREATION.ERROR.CREATE_AUDIT_PERMISSIONS',
        'AUDIT_CREATION.ERROR.UPDATE_AUDIT',
        'AUDIT_CREATION.ERROR.UPDATE_AUDIT_PERMISSIONS',
        'AUDIT_CREATION.SUCCESS.CREATE_AUDIT_TITLE',
        'AUDIT_CREATION.SUCCESS.CREATE_AUDIT_TEXT',
        'AUDIT_CREATION.SUCCESS.UPDATE_AUDIT_TITLE',
        'AUDIT_CREATION.SUCCESS.UPDATE_AUDIT_TEXT'
      ])
      .subscribe((translation: any) => {
        this.notify.error.connection = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.permissions = translation['GLOBAL.ERROR.PERMISSIONS'];
        this.notify.error.forbidden = translation['GLOBAL.ERROR.FORBIDDEN'];
        this.notify.error.createAudit = translation['AUDIT_CREATION.ERROR.CREATE_AUDIT'];
        this.notify.error.createAuditPermissions = translation['AUDIT_CREATION.ERROR.CREATE_AUDIT_PERMISSIONS'];
        this.notify.error.updateAudit = translation['AUDIT_CREATION.ERROR.UPDATE_AUDIT'];
        this.notify.error.updateAuditPermissions = translation['AUDIT_CREATION.ERROR.UPDATE_AUDIT_PERMISSIONS'];
        this.notify.success.createAuditTitle = translation['AUDIT_CREATION.SUCCESS.CREATE_AUDIT_TITLE'];
        this.notify.success.createAuditText = translation['AUDIT_CREATION.SUCCESS.CREATE_AUDIT_TEXT'];
        this.notify.success.updateAuditTitle = translation['AUDIT_CREATION.SUCCESS.UPDATE_AUDIT_TITLE'];
        this.notify.success.updateAuditText = translation['AUDIT_CREATION.SUCCESS.UPDATE_AUDIT_TEXT'];
      });
  }

  initForm(): void {
    this.defineAuditForm = this.formBuilder.group({
      name: [this.getControlValue('name') || '', Validators.required],
      type: [this.getControlValue('type') || '', Validators.required],
      leadAuditor: [this.getControlValue('employees', 'leadAuditor') || [], Validators.required],
      coAuditor: [this.getControlValue('employees', 'coAuditor') || []],
      organizer: [this.getOrganizerControlValue() || [], Validators.required]
    });
  }

  updateForm(): void {
    if (this.defineAuditForm) {
      this.setControlValue(this.getControlValue('name') || '', 'name');
      this.setControlValue(this.getControlValue('type') || '', 'type');
      this.setControlValue(this.getControlValue('employees', 'leadAuditor') || [], 'leadAuditor');
      this.setControlValue(this.getControlValue('employees', 'coAuditor') || [], 'coAuditor');
      this.setControlValue(this.getOrganizerControlValue() || [], 'organizer');
    }
  }

  getControlValue(control: string, variable?: string): any {
    if (this.audit) {
      if (variable) {
        if (this.audit[control]) {
          return this.audit[control][variable];
        }
      } else {
        return this.audit[control];
      }
    }
  }

  getOrganizerControlValue(): Array<Employee> {
    const formValue = this.getControlValue('employees', 'organizer');
    if (!formValue && this.userService.storedUser) {
      const currentEmployee = _.find(this.employees, { id: this.userService.storedUser.id });
      if (currentEmployee) {
        return [currentEmployee];
      }
    }
    return formValue;
  }

  setControlValue(data: any, key: string): void {
    this.defineAuditForm.controls[key].setValue(data);
  }

  isFieldInvalid(field): boolean {
    return !field.valid && this.submitted;
  }

  updateData(form: FormGroup): Promise<Audit> {
    const audit = Object.assign({}, form.value);
    audit.employees = {};
    audit.employees['leadAuditor'] = form.value.leadAuditor;
    audit.employees['coAuditor'] = form.value.coAuditor;
    audit.employees['organizer'] = form.value.organizer;
    audit.employees['creator'] = [this.userService.storedUser];
    let auditPromise: Promise<Audit>;
    if (this.audit) {
      audit.id = this.audit.id;
      auditPromise = this.auditService.updateAudit(new Audit(audit), this.audit);
    } else {
      auditPromise = this.auditService.createAudit(new Audit(audit));
    }
    return auditPromise
      .then(auditResponse => {
        const notify = {
          title: this.audit ? this.notify.success.updateAuditTitle : this.notify.success.createAuditTitle,
          description: this.audit ? this.notify.success.updateAuditText : this.notify.success.createAuditText
        };
        this.notificationsService.success(notify.title, notify.description);
        return auditResponse;
      })
      .catch(error => {
        this.showErrorNotifications(error);
        throw error;
      });
  }

  submitDefineAuditForm(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      this.updateData(form)
        .then(audit => {
          this.updateAudit.emit(audit);
          this.updateStorage.emit({
            stepId: 0,
            stepValid: true,
            submit: true
          });
          this.submitStep.emit({
            goToStepId: 1
          });
        })
        .catch(noop);
    }
  }

  showCoAuditors(): void {
    this.coAuditorsVisible = true;
  }

  showErrorNotifications(error: HttpErrorResponse): void {
    switch (error.status) {
      case 403:
        this.notificationsService.error(this.notify.error.permissions, this.notify.error.createAuditPermissions);
        break;
      default:
        if (error.error?.errors) {
          this.notificationsService.error(this.notify.error.forbidden, error.error?.errors);
        } else {
          const content = this.audit ? this.notify.error.updateAudit : this.notify.error.createAudit;
          this.notificationsService.error(this.notify.error.connection, content);
        }
    }
  }
}

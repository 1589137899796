import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'gw-enclosure-container',
  templateUrl: './enclosure-container.component.html',
  styleUrls: ['./enclosure-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnclosureContainerComponent<T> {
  @Input() alignTitle?: boolean;
  @Input() inlineHeader?: boolean;
  @Input() label?: string;
  @Input() titleMarginBottom?: boolean;
  @Input() hideHeader?: boolean;
  @Input() isEditMode?: boolean;
  @Input() popoverTitle?: string;
  @Input() isUserTypeClient?: boolean;
  @Input() editTemplate: TemplateRef<any>;
  @Input() enclosures?: Array<T>;

  @Output() removeModalOpen = new EventEmitter<T>();
}

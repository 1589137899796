import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  translation = {
    error: {
      login: {
        title: '',
        description: ''
      },
      report: {
        title: '',
        description: ''
      }
    },
    success: {
      login: {
        title: '',
        description: ''
      },
      report: {
        title: '',
        description: ''
      }
    }
  };

  constructor(private notificationsService: NotificationsService, private translateService: TranslateService) {
    this.fetchTranslation();
  }

  success(): void {
    this.fetchTranslation().then(() => {
      this.notificationsService.success(
        this.translation.success.report.title,
        this.translation.success.report.description
      );
    });
  }

  error(type: string = ''): void {
    this.fetchTranslation().then(() => {
      switch (type) {
        case 'login':
          this.notificationsService.error(this.translation.error.login.title, this.translation.error.login.description);
          break;
        default:
          this.notificationsService.error(
            this.translation.error.report.title,
            this.translation.error.report.description
          );
          break;
      }
    });
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GUTWIN_SHARED.ALERT.ERROR_LOGIN',
        'GUTWIN_SHARED.ALERT.ERROR_LOGIN_DESCRIPTION',
        'GUTWIN_SHARED.ALERT.ERROR_REPORT',
        'GUTWIN_SHARED.ALERT.ERROR_REPORT_DESCRIPTION',
        'GUTWIN_SHARED.ALERT.SUCCESS_REPORT',
        'GUTWIN_SHARED.ALERT.SUCCESS_REPORT_DESCRIPTION'
      ])
      .toPromise();
    this.translation.error.login.title = translation['GUTWIN_SHARED.ALERT.ERROR_LOGIN'];
    this.translation.error.login.description = translation['GUTWIN_SHARED.ALERT.ERROR_LOGIN_DESCRIPTION'];
    this.translation.error.report.title = translation['GUTWIN_SHARED.ALERT.ERROR_REPORT'];
    this.translation.error.report.description = translation['GUTWIN_SHARED.ALERT.ERROR_REPORT_DESCRIPTION'];
    this.translation.success.report.title = translation['GUTWIN_SHARED.ALERT.SUCCESS_REPORT'];
    this.translation.success.report.description = translation['GUTWIN_SHARED.ALERT.SUCCESS_REPORT_DESCRIPTION'];
  }
}

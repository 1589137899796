import { Injectable } from '@angular/core';

import * as _ from 'lodash';
import moment from 'moment';

// Gutwin Shared Library
import { Employee, LIMIT } from 'gutwin-shared';

// Models
import { Audit } from '../models/audit.model';
import { AuditedArea } from '../models/audited-area.model';
import { FindingsFilters } from '../models/filters-findings.model';
import { FindingType } from '../models/finding-type.model';
import { Finding, FindingStatus } from '../models/finding.model';
import { FindingsAmount } from '../models/findings-amount.model';
import { FindingsResponse } from '../models/findings-response.model';
import { Question, QUESTION_TYPE } from '../models/question.model';

import { AttachmentService, FindingAttachmentRequest } from './attachment.service';
// Services
import { AuditOfflineService } from './audit.offline.service';
import { SectionsResponse } from './section.service';
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';

export interface FindingsRequests {
  auditedAreaId: string;
  create?: Array<Finding>;
  update?: Array<Finding>;
}

@Injectable()
export class FindingOfflineService {
  constructor(
    private auditOfflineService: AuditOfflineService,
    private attachmentService: AttachmentService,
    private storageService: StorageModuleService
  ) {}

  countFindingsAmount(findings: Array<Finding>, onlyNew?: boolean, questionId?: string): FindingsAmount {
    const findingsAmount = new FindingsAmount();
    findings.forEach(finding => {
      if (
        !onlyNew ||
        finding.action.create ||
        (finding.action.changeSource && finding.relationships.findable.id === questionId)
      ) {
        findingsAmount.total++;
        if (finding.deadline && moment(finding.deadline).isBefore(moment())) {
          findingsAmount.totalOverdue++;
        }
        switch (finding.status) {
          case FindingStatus.inProgress:
            findingsAmount.totalInProgress++;
            break;
          case FindingStatus.notAssigned:
            findingsAmount.totalNotAssigned++;
            break;
          case FindingStatus.solved:
            findingsAmount.totalSolved++;
            break;
        }
      }
    });
    return findingsAmount;
  }

  convertFindingsFromOfflineStore(
    data: FindingsResponse,
    page = 0,
    filters?: FindingsFilters,
    limit = LIMIT,
    countOnlyNew?: boolean,
    questionId?: string
  ): FindingsResponse {
    const countAmount = (findings: Array<Finding>, filteredFindings: Array<Finding>): FindingsAmount => {
      const findingsAmount = this.countFindingsAmount(findings, countOnlyNew, questionId);
      const filteredFindingsAmount = this.countFindingsAmount(filteredFindings, countOnlyNew, questionId);
      findingsAmount.filtered = filteredFindingsAmount.total;
      findingsAmount.filteredInProgress = filteredFindingsAmount.totalInProgress;
      findingsAmount.filteredNotAssigned = filteredFindingsAmount.totalNotAssigned;
      findingsAmount.filteredOverdue = filteredFindingsAmount.totalOverdue;
      findingsAmount.filteredSolved = filteredFindingsAmount.totalSolved;
      return findingsAmount;
    };

    const filterFindings = (findings: Array<Finding>): Array<Finding> => {
      let filteredFindings = findings;
      if (filters && filters.search) {
        const searchFilter = filters.search.toLowerCase();
        filteredFindings = filteredFindings.filter((finding: Finding) => {
          return (
            ~finding.problem.toLowerCase().indexOf(searchFilter) ||
            ~finding.cause.toLowerCase().indexOf(searchFilter) ||
            ~finding.solution.toLowerCase().indexOf(searchFilter)
          );
        });
      }
      return filteredFindings;
    };

    const paginateFindings = (findings: Array<Finding>): Array<Finding> => {
      if (~limit) {
        const index = {
          start: page * limit,
          end: page * limit + limit
        };
        return findings.slice(index.start, index.end);
      }
      return findings;
    };

    let findings = data.findings.map(finding => new Finding(finding));
    findings = filterFindings(findings);
    const findingsAmount = countAmount(data.findings, findings);

    return {
      findingsAmount,
      findings: paginateFindings(findings)
    };
  }

  async getFindingFromOfflineStore(auditedAreaId: string, findingId: string): Promise<Finding> {
    const findingsResponse = await this.getAuditedAreaFindingsFromOfflineStore(auditedAreaId, 0, undefined, -1);
    return _.find(findingsResponse.findings, { id: findingId });
  }

  getFindingsFromOfflineStore(
    auditable: Audit | AuditedArea,
    page = 0,
    filters?: FindingsFilters,
    limit = LIMIT
  ): Promise<FindingsResponse> {
    return auditable._type === 'Audit'
      ? this.getAuditFindingsFromOfflineStore(auditable.id, page, filters, limit)
      : this.getAuditedAreaFindingsFromOfflineStore(auditable.id, page, filters, limit);
  }

  getAuditFindingsFromOfflineStore(
    auditId: string,
    page = 0,
    filters?: FindingsFilters,
    limit = LIMIT
  ): Promise<FindingsResponse> {
    return this.auditOfflineService.getAuditFromOfflineStore(auditId).then(data => {
      const promises = new Array<Promise<FindingsResponse>>();
      if (data.audited_areas) {
        data.audited_areas.forEach(auditedArea => {
          promises.push(this.getAuditedAreaFindingsFromOfflineStore(auditedArea.id, 0, undefined, -1));
        });
      }
      return Promise.all(promises).then(values => {
        const findingsResponse = {
          findingsAmount: new FindingsAmount(),
          findings: new Array<Finding>()
        };
        values.forEach(value => {
          if (value) {
            findingsResponse.findingsAmount.merge(value.findingsAmount);
            findingsResponse.findings.push(...value.findings);
          }
        });
        return this.convertFindingsFromOfflineStore(findingsResponse, page, filters, limit);
      });
    });
  }

  getAuditedAreaFindingsFromOfflineStore(
    auditedAreaId: string,
    page = 0,
    filters?: FindingsFilters,
    limit = LIMIT
  ): Promise<FindingsResponse> {
    return this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaFindings + auditedAreaId)
      .then((data: string) => {
        if (data) {
          return this.convertFindingsFromOfflineStore(JSON.parse(data), page, filters, limit);
        }
      });
  }

  getQuestionFindingsFromOfflineStore(
    questionId: string,
    auditedAreaId: string,
    page = 0,
    limit = LIMIT
  ): Promise<FindingsResponse> {
    const filterFindings = (data: FindingsResponse): FindingsResponse => {
      data.findings = data.findings.filter((finding: Finding) => {
        return finding.relationships.findable._type === 'Question' && finding.relationships.findable.id === questionId;
      });
      return data;
    };

    return this.getAuditedAreaFindingsFromOfflineStore(auditedAreaId, 0, undefined, -1).then(data => {
      if (data) {
        data = filterFindings(data);
        data.findings = data.findings.map(finding => new Finding(finding));
        data = this.convertFindingsFromOfflineStore(data, page, undefined, limit);
      }
      return data;
    });
  }

  async getFindingsFromOfflineStoreByIds(findingsIds: Array<string>, auditedAreaId: string): Promise<Array<Finding>> {
    const filterFindings = (findings: Array<Finding>): Array<Finding> => {
      return findings.filter((finding: Finding) => ~findingsIds.indexOf(finding.id));
    };

    if (findingsIds && findingsIds.length) {
      return await this.storageService
        .getOfflineStore(StoragesNamesModule.auditedAreaFindings + auditedAreaId)
        .then(data => {
          data = data ? JSON.parse(data).findings : new Array<Finding>();
          return filterFindings(data);
        })
        .catch(error => []);
    }
    return [];
  }

  async saveAuditedAreaFindingsInOfflineStore(auditedAreaId: string, data: FindingsResponse): Promise<void> {
    const saveFindingsAttachments = (findings: Array<Finding>): Promise<void> => {
      const promises = new Array<Promise<void>>();
      if (findings) {
        for (const finding of findings) {
          promises.push(this.attachmentService.updateAttachmentsFilesInOfflineStore(finding.attachments));
        }
      }
      return Promise.all(promises).then(() => {});
    };

    await this.storageService.setOfflineStore(
      StoragesNamesModule.auditedAreaFindings + auditedAreaId,
      JSON.stringify(data)
    );
    await saveFindingsAttachments(data.findings);
  }

  updateFindingsAmount(findingsAmount: FindingsAmount, finding: Finding, action: 'add' | 'remove'): FindingsAmount {
    const operator = action === 'add' ? 1 : -1;
    switch (finding.status) {
      case FindingStatus.inProgress:
        findingsAmount.totalInProgress += operator;
        break;
      case FindingStatus.notAssigned:
        findingsAmount.totalNotAssigned += operator;
        break;
      case FindingStatus.overdue:
        findingsAmount.totalOverdue += operator;
        break;
      case FindingStatus.solved:
        findingsAmount.totalSolved += operator;
        break;
    }
    return findingsAmount;
  }

  async updateFindingInOfflineStore(finding: Finding, oldFinding?: Finding): Promise<any> {
    const updateFinding = (data: FindingsResponse): FindingsResponse => {
      const findingIndex = _.findIndex(data.findings, { id: finding.id });
      if (~findingIndex) {
        data.findingsAmount = this.updateFindingsAmount(data.findingsAmount, data.findings[findingIndex], 'remove');
        data.findings[findingIndex] = this.clearFindingForOfflineStorage(finding);
      } else {
        data.findings.push(this.clearFindingForOfflineStorage(finding));
      }
      data.findingsAmount = this.updateFindingsAmount(data.findingsAmount, finding, 'add');
      return data;
    };

    await this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaFindings + finding.relationships.auditedArea.id)
      .then(async data => {
        if (!data) {
          data = {
            findingsAmount: new FindingsAmount(),
            findings: new Array<Finding>()
          };
          data.findingsAmount = this.updateFindingsAmount(data.findingsAmount, finding, 'add');
        } else {
          data = JSON.parse(data);
        }
        if (oldFinding && finding.relationships.findable.id !== oldFinding.relationships.findable.id) {
          this.removeFindingFromOfflineStore(oldFinding);
          await this.removeFindingFromSectionsOfflineStore(oldFinding, data.findings);
        }
        const updatedData = updateFinding(data);
        return this.storageService.setOfflineStore(
          StoragesNamesModule.auditedAreaFindings + finding.relationships.auditedArea.id,
          JSON.stringify(updatedData)
        );
      });
    await this.updateFindingInSectionsOfflineStore(finding);
    await this.attachmentService.updateAttachmentsFilesInOfflineStore(finding.attachments);
  }

  updateFindingsInOfflineStore(findingsRequests: FindingsRequests): Promise<any> {
    const updateFindings = (data: FindingsResponse, findings: Array<Finding>): FindingsResponse => {
      if (findings) {
        findings.forEach(finding => {
          if (finding.id) {
            const findingIndex = _.findIndex(data.findings, { id: finding.id });
            if (~findingIndex) {
              data.findingsAmount = this.updateFindingsAmount(
                data.findingsAmount,
                data.findings[findingIndex],
                'remove'
              );
              data.findings[findingIndex] = this.clearFindingForOfflineStorage(finding);
              data.findingsAmount = this.updateFindingsAmount(data.findingsAmount, finding, 'add');
            } else {
              data.findings.push(this.clearFindingForOfflineStorage(finding));
              data.findingsAmount = this.updateFindingsAmount(data.findingsAmount, finding, 'add');
            }
          }
        });
      }
      return data;
    };

    const updateFindingsAttachments = (findings: Array<Finding>): Promise<void> => {
      const promises = new Array<Promise<void>>();
      if (findings) {
        for (const finding of findings) {
          promises.push(this.attachmentService.updateAttachmentsFilesInOfflineStore(finding.attachments));
        }
      }
      return Promise.all(promises).then(() => {});
    };

    const promises = new Array<Promise<any>>();
    promises.push(
      this.storageService
        .getOfflineStore(StoragesNamesModule.auditedAreaFindings + findingsRequests.auditedAreaId)
        .then(async data => {
          if (!data) {
            data = {
              findingsAmount: new FindingsAmount(),
              findings: new Array<Finding>()
            };
          } else {
            data = JSON.parse(data);
          }
          data = updateFindings(data, findingsRequests.create);
          data = updateFindings(data, findingsRequests.update);
          this.storageService.setOfflineStore(
            StoragesNamesModule.auditedAreaFindings + findingsRequests.auditedAreaId,
            JSON.stringify(data)
          );

          updateFindingsAttachments(findingsRequests.create);
          updateFindingsAttachments(findingsRequests.update);
        })
    );
    promises.push(this.updateFindingsInSectionsOfflineStore(findingsRequests));
    return Promise.all(promises);
  }

  clearFindingForOfflineStorage(finding: Finding): Finding {
    delete finding.offlineRequest;
    delete finding.changes;
    delete finding.oldDeadline;
    delete finding.oldStatus;
    delete finding.relationships.oldFindable;
    return new Finding(finding);
  }

  private updateFindingInSectionsOfflineStoreData(data: SectionsResponse, questionId: string): SectionsResponse {
    for (const section of data.ordered_sections) {
      if (section.ordered_questions) {
        for (const question of section.ordered_questions) {
          if (question.id === questionId) {
            question.has_findings = true;
            return data;
          }
        }
      }
    }
  }

  private updateFindingInSectionsOfflineStore(finding: Finding): void {
    if (finding.relationships.findable._type === QUESTION_TYPE) {
      this.storageService
        .getOfflineStore(StoragesNamesModule.auditedAreaSections + finding.relationships.auditedArea.id)
        .then((data: SectionsResponse) => {
          if (data) {
            let updatedData = data;
            updatedData = this.updateFindingInSectionsOfflineStoreData(updatedData, finding.relationships.findable.id);
            this.storageService.setOfflineStore(
              StoragesNamesModule.auditedAreaSections + finding.relationships.auditedArea.id,
              updatedData
            );
          }
        });
    }
  }

  private updateFindingsInSectionsOfflineStore(findingsRequests: FindingsRequests): Promise<any> {
    const updateFindingsInSections = (data: SectionsResponse, findings: Array<Finding>): SectionsResponse => {
      if (findings) {
        findings.forEach(finding => {
          if (finding.relationships.findable._type === QUESTION_TYPE) {
            data = this.updateFindingInSectionsOfflineStoreData(data, finding.relationships.findable.id);
          }
        });
      }
      return data;
    };

    return this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaSections + findingsRequests.auditedAreaId)
      .then((data: SectionsResponse) => {
        if (data) {
          let updatedData = updateFindingsInSections(data, findingsRequests.create);
          updatedData = updateFindingsInSections(data, findingsRequests.update);
          this.storageService.setOfflineStore(
            StoragesNamesModule.auditedAreaSections + findingsRequests.auditedAreaId,
            updatedData
          );
        }
      });
  }

  removeFindingFromOfflineStore(finding: Finding): Promise<any> {
    const removeFinding = (data: FindingsResponse): FindingsResponse => {
      const findingIndex = _.findIndex(data.findings, { id: finding.id });
      if (~findingIndex) {
        data.findings.splice(findingIndex, 1);
        data.findingsAmount = this.updateFindingsAmount(data.findingsAmount, finding, 'remove');
      }
      return data;
    };

    return this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaFindings + finding.relationships.auditedArea.id)
      .then(async data => {
        if (data) {
          const updatedData = removeFinding(JSON.parse(data));
          this.storageService.setOfflineStore(
            StoragesNamesModule.auditedAreaFindings + finding.relationships.auditedArea.id,
            JSON.stringify(updatedData)
          );
          await this.attachmentService.removeAttachmentsFilesFromOfflineStore(finding.attachments);
          await this.removeFindingFromSectionsOfflineStore(finding, updatedData.findings);
        }
      });
  }

  private removeFindingFromSectionsOfflineStoreData(
    data: SectionsResponse,
    findings: Array<Finding>,
    finding: Finding
  ): SectionsResponse {
    for (const section of data.ordered_sections) {
      if (section.ordered_questions) {
        for (const question of section.ordered_questions) {
          if (question.id === finding.relationships.findable.id) {
            question.has_findings = this.hasQuestionMoreFinding(question.id, findings, finding.id);
            return data;
          }
        }
      }
    }
  }

  private hasQuestionMoreFinding(questionId: string, findings: Array<Finding>, findingId: string): boolean {
    for (const finding of findings) {
      if (
        finding.relationships.findable._type === QUESTION_TYPE &&
        finding.relationships.findable.id === questionId &&
        finding.id !== findingId
      ) {
        return true;
      }
    }
    return false;
  }

  private removeFindingFromSectionsOfflineStore(finding: Finding, findings: Array<Finding>): Promise<void> {
    if (finding.relationships.findable._type === QUESTION_TYPE) {
      return this.storageService
        .getOfflineStore(StoragesNamesModule.auditedAreaSections + finding.relationships.auditedArea.id)
        .then((data: SectionsResponse) => {
          if (data) {
            let updatedData = data;
            updatedData = this.removeFindingFromSectionsOfflineStoreData(updatedData, findings, finding);
            this.storageService.setOfflineStore(
              StoragesNamesModule.auditedAreaSections + finding.relationships.auditedArea.id,
              updatedData
            );
          }
        });
    }
    return new Promise((resolve, reject) => resolve());
  }

  removeAuditFindingsFromOfflineStore(audit: Audit): void {
    if (audit.auditedAreas) {
      audit.auditedAreas.forEach(auditedArea => {
        this.removeAuditedAreaFindingsFromOfflineStore(auditedArea.id);
      });
    }
  }

  async removeAuditedAreaFindingsFromOfflineStore(auditedAreaId: string): Promise<void> {
    const removeFindingsAttachments = async (data: FindingsResponse): Promise<void> => {
      if (data.findings) {
        for (const finding of data.findings) {
          await this.attachmentService.removeAttachmentsFilesFromOfflineStore(finding.attachments);
        }
      }
    };

    await this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaFindings + auditedAreaId)
      .then(async (data: string) => {
        if (data) {
          await removeFindingsAttachments(JSON.parse(data));
        }
      });
    await this.storageService.removeFromOfflineStore(StoragesNamesModule.auditedAreaFindings + auditedAreaId);
  }

  /*
   * Findings Requests
   */

  /**
   * Get findings requests without attachments from offline store for audit
   *
   * @param audit
   */
  getAuditFindingsRequestsFromOfflineStore(audit: Audit): Promise<Array<Finding>> {
    const promises = new Array<Promise<Array<Finding>>>();
    if (audit.auditedAreas) {
      audit.auditedAreas.forEach(auditedArea => {
        promises.push(this.getFindingsRequestsFromOfflineStore(auditedArea.id));
      });
    }

    return Promise.all(promises)
      .then(values => {
        const findings = new Array<Finding>();
        if (values) {
          values.forEach(value => {
            if (value) {
              findings.push(...value);
            }
          });
        }
        return findings;
      })
      .catch(error => []);
  }

  /**
   * Get findings requests without attachments from offline store for audited area or question
   *
   * @param auditedAreaId
   * @param questionId
   */
  getFindingsRequestsFromOfflineStore(auditedAreaId: string, questionId?: string): Promise<Array<Finding>> {
    return this.storageService
      .getOfflineStore(`${StoragesNamesModule.auditedAreaFindingsRequests}${auditedAreaId}`)
      .then((data: string) => {
        return JSON.parse(data);
      })
      .then((data: FindingsRequests) => {
        if (data) {
          if (data.create) {
            data.create = data.create.map(finding => new Finding(finding));
          }
          if (data.update) {
            data.update = data.update.map(finding => new Finding(finding));
          }
          let findings = new Array<Finding>();
          findings.push(...data.create);
          findings.push(...data.update);
          if (questionId) {
            findings = findings.filter(
              finding =>
                (finding.relationships.findable._type === QUESTION_TYPE &&
                  finding.relationships.findable.id === questionId) ||
                (finding.relationships.oldFindable &&
                  finding.relationships.oldFindable._type === QUESTION_TYPE &&
                  finding.relationships.oldFindable.id === questionId)
            );
          }
          return findings;
        }
      })
      .catch(error => []);
  }

  /**
   * Get findings attachments requests from offline store
   *
   * @param auditedAreaId
   * @param questionId
   */
  getFindingsAttachmentsRequestsFromOfflineStore(auditedAreaId: string, questionId?: string): Promise<Array<Finding>> {
    return this.attachmentService
      .getFindingsAttachmentsRequestFromOfflineStore(auditedAreaId, questionId)
      .then(data => {
        const findings = new Array<Finding>();
        if (data) {
          data.forEach((findingAttachmentRequest: FindingAttachmentRequest) => {
            const finding = _.find(findings, { id: findingAttachmentRequest.findingId });
            if (finding) {
              finding.attachments.push(findingAttachmentRequest.attachment);
            } else {
              findings.push(
                new Finding({
                  id: findingAttachmentRequest.findingId,
                  relationships: {
                    auditedArea: new AuditedArea({ id: auditedAreaId }),
                    findable: findingAttachmentRequest.questionId
                      ? new Question({ id: findingAttachmentRequest.questionId })
                      : new AuditedArea({ id: auditedAreaId })
                  },
                  attachments: [findingAttachmentRequest.attachment]
                })
              );
            }
          });
        }
        return findings;
      })
      .catch(error => []);
  }

  /**
   * Merge requests with findings without attachments and with attachments into one array
   *
   * @param arrays
   */
  mergeFindingsRequests(arrays: Array<Array<Finding>>): Array<Finding> {
    const findings = new Array<Finding>();
    arrays.forEach(data => {
      if (data && findings.length === 0) {
        findings.push(...data);
      } else if (data) {
        data.forEach(finding => {
          const index = _.findIndex(findings, { id: finding.id });
          if (~index) {
            if (finding.attachments) {
              findings[index].attachments.push(...finding.attachments);
            } else if (findings[index].attachments) {
              const attachments = findings[index].attachments;
              findings[index] = finding;
              findings[index].attachments = attachments;
            }
          } else {
            findings.push(finding);
          }
        });
      }
    });
    return findings;
  }

  getAuditFindingsRequestsListFromOfflineStore(audit: Audit): Promise<FindingsResponse> {
    const promises = new Array<Promise<FindingsResponse>>();
    if (audit.auditedAreas) {
      audit.auditedAreas.forEach(auditedArea => {
        const auditedAreaPromises = new Array<Promise<Array<Finding>>>();
        auditedAreaPromises.push(this.getFindingsRequestsFromOfflineStore(auditedArea.id));
        auditedAreaPromises.push(this.getFindingsAttachmentsRequestsFromOfflineStore(auditedArea.id));
        promises.push(
          Promise.all(auditedAreaPromises).then(values => {
            const findings = this.mergeFindingsRequests(values);
            return {
              findingsAmount: this.countFindingsAmount(findings, true),
              findings
            };
          })
        );
      });
    }
    return Promise.all(promises).then((values: Array<FindingsResponse>) => {
      const findingsResponse = {
        findingsAmount: new FindingsAmount(),
        findings: new Array<Finding>(),
        areRequests: true
      };
      values.forEach(auditedAreaFindingsResponse => {
        if (auditedAreaFindingsResponse) {
          findingsResponse.findingsAmount.merge(auditedAreaFindingsResponse.findingsAmount);
          findingsResponse.findings.push(...auditedAreaFindingsResponse.findings);
        }
      });
      return findingsResponse;
    });
  }

  getAuditedAreaFindingsRequestsListFromOfflineStore(auditedAreaId: string): Promise<FindingsResponse> {
    const promises = new Array<Promise<Array<Finding>>>();
    promises.push(this.getFindingsRequestsFromOfflineStore(auditedAreaId));
    promises.push(this.getFindingsAttachmentsRequestsFromOfflineStore(auditedAreaId));

    return Promise.all(promises).then(values => {
      const findings = this.mergeFindingsRequests(values);
      return {
        findingsAmount: this.countFindingsAmount(findings, true),
        findings,
        areRequests: true
      };
    });
  }

  getQuestionFindingsRequestsListFromOfflineStore(
    questionId: string,
    auditedAreaId: string
  ): Promise<FindingsResponse> {
    const filterByQuestion = (findings: Array<Finding>): Array<Finding> => {
      return findings.filter(
        finding =>
          (finding.relationships.findable._type === QUESTION_TYPE &&
            finding.relationships.findable.id === questionId) ||
          (finding.relationships.oldFindable &&
            finding.relationships.oldFindable._type === QUESTION_TYPE &&
            finding.relationships.oldFindable.id === questionId)
      );
    };

    const promises = new Array<Promise<Array<Finding>>>();
    promises.push(this.getFindingsRequestsFromOfflineStore(auditedAreaId, questionId).then(data => data));
    promises.push(this.getFindingsAttachmentsRequestsFromOfflineStore(auditedAreaId).then(data => data));

    return Promise.all(promises).then(values => {
      const findings = filterByQuestion(this.mergeFindingsRequests(values));

      return {
        findingsAmount: this.countFindingsAmount(findings, true, questionId),
        findings,
        areRequests: true
      };
    });
  }

  getAuditedAreaFindingsRequestsFromOfflineStore(auditedAreaId: string): Promise<FindingsRequests> {
    const separateFindings = (findings: Array<Finding>): FindingsRequests => {
      const findingsRequests = {
        auditedAreaId,
        create: new Array<Finding>(),
        update: new Array<Finding>()
      };
      if (findings) {
        findings.forEach(finding => {
          if (finding.action && finding.action.create) {
            findingsRequests.create.push(finding);
          } else {
            findingsRequests.update.push(finding);
          }
        });
      }
      return findingsRequests;
    };

    const promises = new Array<Promise<Array<Finding>>>();
    promises.push(this.getFindingsRequestsFromOfflineStore(auditedAreaId));
    promises.push(this.getFindingsAttachmentsRequestsFromOfflineStore(auditedAreaId));

    return Promise.all(promises).then(values => {
      const findings = this.mergeFindingsRequests(values);
      return separateFindings(findings);
    });
  }

  updateFindingRequestInOfflineStoreData(
    findingsRequests: FindingsRequests,
    finding: Finding,
    oldFinding: Finding,
    action: string
  ): FindingsRequests {
    const generateTemporaryId = () => {
      return `${findingsRequests.auditedAreaId}-${finding.relationships.findable.id}-${
        finding.problem
      }-${moment().toString()}`;
    };

    const attachments = finding.attachments;
    finding.attachments = undefined;

    const indexInCreate = _.findIndex(findingsRequests.create, findingInArray => findingInArray.id === finding.id);
    const toCreate = !!~indexInCreate || action === 'create';
    const array = toCreate ? findingsRequests.create : findingsRequests.update;

    let findingIndex = indexInCreate;
    if (toCreate) {
      if (!~indexInCreate) {
        finding.id = generateTemporaryId();
      }
      finding.action.create = true;
    } else {
      findingIndex = _.findIndex(array, findingInArray => findingInArray.id === finding.id);
      const hasFindableChanged =
        oldFinding && finding.relationships.findable.id !== oldFinding.relationships.findable.id;
      if (hasFindableChanged) {
        finding.relationships.oldFindable = oldFinding.relationships.oldFindable || oldFinding.relationships.findable;
      }
      finding.action.update = true;
      finding.action.changeSource = hasFindableChanged;
    }
    if (findingIndex !== -1) {
      array[findingIndex] = new Finding(finding);
    } else {
      array.push(new Finding(finding));
    }

    finding.attachments = attachments;

    return findingsRequests;
  }

  async updateAuditedAreaFindingRequestInOfflineStore(
    auditedAreaId: string,
    action: 'create' | 'update',
    finding: Finding,
    oldFinding?: Finding
  ): Promise<Finding> {
    const updateFindingRequest = async (): Promise<Finding> => {
      let findingsRequests: FindingsRequests = await this.storageService
        .getOfflineStore(StoragesNamesModule.auditedAreaFindingsRequests + auditedAreaId)
        .then(data =>
          data ? JSON.parse(data) : { auditedAreaId, create: new Array<Finding>(), update: new Array<Finding>() }
        );

      findingsRequests = this.updateFindingRequestInOfflineStoreData(findingsRequests, finding, oldFinding, action);

      return this.storageService
        .setOfflineStore(
          StoragesNamesModule.auditedAreaFindingsRequests + auditedAreaId,
          JSON.stringify(findingsRequests)
        )
        .then(() => finding);
    };

    const updateAttachmentsRequests = async (): Promise<Finding> => {
      const questionId =
        finding.relationships.findable._type === QUESTION_TYPE ? finding.relationships.findable.id : undefined;
      const oldFindingRequest = oldFinding && oldFinding.offlineRequest ? oldFinding.offlineRequest : oldFinding;
      const oldQuestionId =
        oldFindingRequest && oldFindingRequest.relationships.findable._type === QUESTION_TYPE
          ? oldFindingRequest.relationships.findable.id
          : undefined;
      const hasFindableChanged = oldFindingRequest && questionId !== oldQuestionId;

      if (oldFinding && questionId !== oldQuestionId) {
        await this.attachmentService.updateFindingAttachmentsRequestsSourceInOfflineStore(
          auditedAreaId,
          finding.id,
          questionId
        );
      }

      if (finding.attachments && finding.attachments.length) {
        const storedAttachments = await this.attachmentService.addFindingAttachmentsRequestsToOfflineStore(
          finding.attachments,
          auditedAreaId,
          finding.id,
          questionId
        );

        if (storedAttachments) {
          storedAttachments.forEach(storedAttachment => {
            const index = _.findIndex(finding.attachments, { checksum: storedAttachment.checksum });
            if (~index) {
              finding.attachments[index] = storedAttachment;
            }
          });
        }
      }

      return finding;
    };

    finding = await updateFindingRequest();
    finding = await updateAttachmentsRequests();
    return finding;
  }

  updateEmployeeInAuditedAreaFindingRequestInOfflineStore(
    auditedAreaId: string,
    findingId: string,
    employee: Employee
  ): Promise<void> {
    const updateEmployeeInFindingRequest = (data: FindingsRequests): FindingsRequests => {
      let array: Array<Finding>;
      let finding: Finding;
      const arrayNames = ['create', 'update'];
      arrayNames.forEach(arrayName => {
        array = data[arrayName];
        if (array) {
          finding = _.find(array, { id: findingId });
        }
      });
      if (finding) {
        finding.relationships.employee = employee;
      }
      return data;
    };

    return this.storageService
      .getOfflineStore(StoragesNamesModule.auditedAreaFindingsRequests + auditedAreaId)
      .then((data: string) => {
        if (data) {
          const updatedData = updateEmployeeInFindingRequest(JSON.parse(data));
          return this.storageService.setOfflineStore(
            StoragesNamesModule.auditedAreaFindingsRequests + auditedAreaId,
            JSON.stringify(updatedData)
          );
        }
      });
  }

  async updateAuditedAreaFindingRequestsInOfflineStore(findingsRequests: FindingsRequests): Promise<FindingsRequests> {
    const updateFindingsRequests = async (): Promise<FindingsRequests> => {
      let storedFindingsRequests: FindingsRequests = await this.storageService
        .getOfflineStore(StoragesNamesModule.auditedAreaFindingsRequests + findingsRequests.auditedAreaId)
        .then(data =>
          data
            ? JSON.parse(data)
            : {
                auditedAreaId: findingsRequests.auditedAreaId,
                create: new Array<Finding>(),
                update: new Array<Finding>()
              }
        );

      if (findingsRequests.create) {
        findingsRequests.create.forEach(finding => {
          storedFindingsRequests = this.updateFindingRequestInOfflineStoreData(
            storedFindingsRequests,
            finding,
            undefined,
            'create'
          );
        });
      }
      if (findingsRequests.update) {
        findingsRequests.update.forEach(finding => {
          storedFindingsRequests = this.updateFindingRequestInOfflineStoreData(
            storedFindingsRequests,
            finding,
            undefined,
            'update'
          );
        });
      }

      return this.storageService
        .setOfflineStore(
          StoragesNamesModule.auditedAreaFindingsRequests + findingsRequests.auditedAreaId,
          JSON.stringify(storedFindingsRequests)
        )
        .then(() => storedFindingsRequests);
    };

    findingsRequests = await updateFindingsRequests();
    findingsRequests = await this.attachmentService.updateFindingsAttachmentsRequestsInOfflineStore(findingsRequests);
    return findingsRequests;
  }

  removeAuditFindingsRequestsFromOfflineStore(audit: Audit): void {
    if (audit.auditedAreas) {
      audit.auditedAreas.forEach(auditedArea => {
        this.removeAuditedAreaFindingsRequestsFromOfflineStore(auditedArea.id);
      });
    }
  }

  removeAuditedAreaFindingsRequestsFromOfflineStore(auditedAreaId: string): Promise<any> {
    const promises = new Array<Promise<any>>();
    promises.push(
      this.storageService.removeFromOfflineStore(StoragesNamesModule.auditedAreaFindingsRequests + auditedAreaId)
    );
    promises.push(this.attachmentService.removeFindingsAttachmentsRequestsFromOfflineStore(auditedAreaId));
    return Promise.all(promises);
  }

  removeFindingRequestFromOfflineStore(auditedAreaId: string, findingId: string): Promise<any> {
    const removeFinding = (data: FindingsRequests): FindingsRequests => {
      const arraysKeys = ['create', 'update'];
      for (const key of arraysKeys) {
        if (data[key]) {
          const index = _.findIndex(data[key], { id: findingId });
          if (~index) {
            data[key].splice(index, 1);
          }
        }
      }
      return data;
    };

    const promises = new Array<Promise<any>>();
    promises.push(
      this.attachmentService.removeFindingAttachmentsRequestsFromOfflineStore(
        auditedAreaId,
        new Finding({ id: findingId }),
        true
      )
    );
    promises.push(
      this.storageService
        .getOfflineStore(StoragesNamesModule.auditedAreaFindingsRequests + auditedAreaId)
        .then(data => {
          if (data) {
            data = removeFinding(JSON.parse(data));
            this.storageService.setOfflineStore(
              StoragesNamesModule.auditedAreaFindingsRequests + auditedAreaId,
              JSON.stringify(data)
            );
          }
        })
    );
    return Promise.all(promises);
  }

  /*
   * Findings Types
   */

  saveFindingTypesInOfflineStore(data: Array<any>): void {
    this.storageService.setOfflineStore(StoragesNamesModule.findingTypes, data);
  }

  getFindingTypesFromOfflineStore(): Promise<Array<FindingType>> {
    return this.storageService.getOfflineStore(StoragesNamesModule.findingTypes).then((findingTypes: Array<any>) => {
      if (findingTypes) {
        return findingTypes.map(findingType => new FindingType(findingType));
      }
    });
  }

  updateFindingTypeInOfflineStore(findingType: any): void {
    const updateFindingType = (data: Array<any>): Array<any> => {
      const findingTypeIndex = _.findIndex(data, { id: findingType.id });
      if (findingTypeIndex !== -1) {
        data[findingTypeIndex] = findingType;
      } else {
        data.push(findingType);
      }
      return data;
    };

    this.storageService.getOfflineStore(StoragesNamesModule.findingTypes).then(data => {
      if (data !== null) {
        const updatedData = updateFindingType(data);
        this.storageService.setOfflineStore(StoragesNamesModule.findingTypes, updatedData);
      }
    });
  }

  removeFindingTypeFromOfflineStore(findingType: FindingType): void {
    const removeFindingType = (data: Array<FindingType>): Array<FindingType> => {
      const findingTypeIndex = _.findIndex(data, { id: findingType.id });
      if (~findingTypeIndex) {
        data.splice(findingTypeIndex, 1);
      }
      return data;
    };

    this.storageService.getOfflineStore(StoragesNamesModule.findingTypes).then(data => {
      if (data !== null) {
        const updatedData = removeFindingType(data);
        this.storageService.setOfflineStore(StoragesNamesModule.findingTypes, updatedData);
      }
    });
  }
}

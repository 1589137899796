import { ExtraFieldSimple } from './extra-field-simple.model';

import { DateRangeFilter } from '../interfaces/date-range.interface';

import { FilterType } from '../enums/filter-type.enum';
import { LegalChangesRatingId } from '../enums/legal-change-rating-id.enum';

export class LegalChangeFilters {
  [FilterType.legalCategories]?: Array<string>;
  [FilterType.legalOrigins]?: Array<string>;
  [FilterType.ratings]?: Array<LegalChangesRatingId>;
  [FilterType.hideDone]?: boolean;
  [FilterType.extraFields]?: Array<ExtraFieldSimple>;
  [FilterType.publication]?: DateRangeFilter;
  [FilterType.issuedOn]?: DateRangeFilter;
  [FilterType.validFrom]?: DateRangeFilter;

  constructor(legalChangeFilters?: Partial<LegalChangeFilters>) {
    Object.assign(this, legalChangeFilters);
  }
}

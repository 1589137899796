import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { Company } from '../../../../../models/company.model';
import { EmployeeExtended } from '../../../../../models/employee-extended.model';
import { LegalComplianceLicenses } from '../../../../../models/legal-compliance-licenses.model';
import { CurrentUser } from '../../models/current-user.model';

import { I18nService } from '../../../../../services/i18n.service';
import { StorageService, StoragesNames } from '../../../../../services/storage.service';
import { AvatarService } from '../../services/avatar.service';
import { CompanyService } from '../../services/company.service';
import { SessionService } from '../../services/session.service';

import { SetGlobalFilter } from '../filters/filters.actions';
import {
  ClearSession,
  CreateProfileAvatar,
  GetLCLicenses,
  GetLoggedUser,
  RemoveProfileAvatar,
  SetAvailableCompanies,
  SetLoggedUser,
  UpdateCompanyContext,
  UpdateLoggedUserEmail,
  UpdateLoggedUserPassword,
  UpdateProfile,
  UpdateProfileAvatar
} from './session.actions';

import { CLIENT_FROALA_OPTIONS } from '../../../../../constants/froala.constants';

export interface SessionStateModel {
  loggedUser?: CurrentUser;
  availableCompanies: Array<Company>;
  licenses?: LegalComplianceLicenses;
}

@State<SessionStateModel>({
  name: 'session',
  defaults: {
    availableCompanies: []
  }
})
@Injectable()
export class SessionState {
  constructor(
    private avatarService: AvatarService,
    private companyService: CompanyService,
    private i18nService: I18nService,
    private storageService: StorageService,
    private store: Store,
    private sessionService: SessionService
  ) {}

  @Selector()
  static loggedUser(state: SessionStateModel): CurrentUser {
    return state.loggedUser;
  }

  @Selector()
  static availableCompanies(state: SessionStateModel): Array<Company> {
    return state.availableCompanies;
  }

  @Selector()
  static licenses(state: SessionStateModel): LegalComplianceLicenses {
    return state.licenses;
  }

  @Action(SetLoggedUser)
  setLoggedUser(context: StateContext<SessionStateModel>, { user, noFiltersUpdate }: SetLoggedUser): void {
    this.storageService.setLocalStorage(StoragesNames.currentUser, user);
    context.patchState({
      loggedUser: user
    });
    if (!noFiltersUpdate) this.store.dispatch(new SetGlobalFilter('companies', [user.company.id]));
    this.i18nService.setLanguage(user.language.id);
    CLIENT_FROALA_OPTIONS.language = user.language.id;
  }

  @Action(GetLoggedUser)
  async getLoggedUser(context: StateContext<SessionStateModel>): Promise<void> {
    const loggedUser = context.getState()?.loggedUser;

    if (!loggedUser?.id) {
      const currentUser = await this.sessionService.fetchLoggedUser();
      context.dispatch(new SetLoggedUser(currentUser)).toPromise();
      context.dispatch(new SetAvailableCompanies(currentUser)).toPromise();
    }
  }

  @Action(SetAvailableCompanies)
  setAvailableCompanies(context: StateContext<SessionStateModel>, { currentUser }: SetAvailableCompanies): void {
    const availableCompanies = this.companyService.getAvailableCompaniesFromCurrentUser(currentUser);
    context.patchState({
      availableCompanies
    });
  }

  @Action(ClearSession)
  clearSession(context: StateContext<SessionStateModel>): void {
    context.patchState({
      loggedUser: undefined,
      availableCompanies: []
    });
  }

  @Action(UpdateLoggedUserEmail)
  updateLoggedUserEmail(context: StateContext<SessionStateModel>, action: UpdateLoggedUserEmail): void {
    this.sessionService.updateLoggedUserEmail(action.email).then(currentUser => {
      context.dispatch(new SetLoggedUser(currentUser, true));
    });
  }

  @Action(UpdateLoggedUserPassword)
  updateLoggedUserPassword(context: StateContext<SessionStateModel>, action: UpdateLoggedUserPassword): void {
    this.sessionService.updateLoggedUserPassword(action.password, action.passwordConfirmation).then(currentUser => {
      context.dispatch(new SetLoggedUser(currentUser, true));
    });
  }

  @Action(UpdateCompanyContext)
  updateCompanyContext(context: StateContext<SessionStateModel>, action: UpdateCompanyContext): void {
    this.sessionService.updateCompanyContext(action.companyId).then(currentUser => {
      context.dispatch(new SetLoggedUser(currentUser));
    });
  }

  @Action(UpdateProfile)
  updateProfile(context: StateContext<SessionStateModel>, { employee }: UpdateProfile): Promise<void> {
    return this.sessionService.updateUserProfile(employee).then(currentUser => {
      context.dispatch(new SetLoggedUser(currentUser, true));
    });
  }

  @Action(CreateProfileAvatar)
  createProfileAvatar(
    context: StateContext<SessionStateModel>,
    { employeeId, avatar }: CreateProfileAvatar
  ): Promise<void> {
    if (!avatar.file) {
      throw new Error('[Session] Create user profile avatar: Undefined file for avatar');
    }
    const loggedUser = context.getState().loggedUser;
    return this.avatarService.createUserAvatar(employeeId, avatar.file).then(employee => {
      context.dispatch(new SetLoggedUser(this.updateEmployeeKeyInLoggedUser(loggedUser, employee, 'avatar'), true));
    });
  }

  @Action(UpdateProfileAvatar)
  updateProfileAvatar(
    context: StateContext<SessionStateModel>,
    { employeeId, avatar }: UpdateProfileAvatar
  ): Promise<void> {
    if (!avatar.file) {
      throw new Error('[Session] Update user profile avatar: Undefined file for avatar');
    }
    const loggedUser = context.getState().loggedUser;
    return this.avatarService.updateUserAvatar(employeeId, avatar.file).then(employee => {
      context.dispatch(new SetLoggedUser(this.updateEmployeeKeyInLoggedUser(loggedUser, employee, 'avatar'), true));
    });
  }

  @Action(RemoveProfileAvatar)
  removeProfileAvatar(context: StateContext<SessionStateModel>, { employeeId }: RemoveProfileAvatar): Promise<void> {
    const loggedUser = context.getState().loggedUser;
    return this.avatarService.removeUserAvatar(employeeId).then(employee => {
      context.dispatch(new SetLoggedUser(this.updateEmployeeKeyInLoggedUser(loggedUser, employee, 'avatar'), true));
    });
  }

  @Action(GetLCLicenses)
  getLicenses(context: StateContext<SessionStateModel>): void {
    this.sessionService.getLicenses().then(({ legalCompliance }) => {
      context.patchState({
        licenses: legalCompliance
      });
    });
  }

  updateEmployeeKeyInLoggedUser(
    loggedUser: CurrentUser,
    employee: EmployeeExtended,
    key: keyof EmployeeExtended
  ): CurrentUser {
    const updatedEmployees = loggedUser.employees.map(loggedUserEmployee =>
      loggedUserEmployee.id === employee.id
        ? new EmployeeExtended({ ...loggedUserEmployee, [key]: employee[key] })
        : loggedUserEmployee
    );
    const currentEmployee = updatedEmployees.find(employee => employee.company.id === loggedUser.company.id);
    return {
      ...loggedUser,
      [key]: currentEmployee[key],
      employees: updatedEmployees
    };
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import moment from 'moment';
import { Subject } from 'rxjs';

// Gutwin Shared Library
import { Attachment, Employee, FileService, HelperUtil, LIMIT } from 'gutwin-shared';

// Models
import { Audit } from '../models/audit.model';
import { AuditedArea } from '../models/audited-area.model';
import { FindingsFilters } from '../models/filters-findings.model';
import { FindingType } from '../models/finding-type.model';
import { Finding, FindingRelationships, FindingStatus } from '../models/finding.model';
import { FindingsAmount } from '../models/findings-amount.model';
import { FindingsResponse } from '../models/findings-response.model';
import { QUESTION_TYPE } from '../models/question.model';
import { Solving } from '../models/solving.model';

// Interfaces
import { BatchResponse } from '../interfaces/batch-response.interface';

// Services
import { ApiUrlService } from './api-url.service';
import { AttachmentService, FindingAttachmentRequest } from './attachment.service';
import { AuditConvertService } from './audit.convert.service';
import { FindingOfflineService, FindingsRequests } from './finding.offline.service';
import { ProgressGroup, ProgressService, ProgressStatus, ProgressType } from './progress.service';

import { getAttachmentsWithFile } from '@gutwin-audit/shared/converters/attachment.convert';

@Injectable()
export class FindingService {
  findingsLimit = LIMIT;
  findingsAmount = new FindingsAmount();
  findingsAmountSource = new Subject<any>();
  findingsAmountObservable = this.findingsAmountSource.asObservable();

  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private auditConvertService: AuditConvertService,
    private attachmentService: AttachmentService,
    private fileService: FileService,
    private findingOfflineService: FindingOfflineService,
    private progressService: ProgressService,
    private translateService: TranslateService
  ) {
    this.progressService.actionObservable.subscribe((groups: Array<ProgressGroup>) => {
      if (groups) {
        groups.forEach(group => {
          if (group.type === ProgressType.findingTypes) {
            switch (group.action) {
              case 'download':
                this.saveFindingTypesOffline();
                break;
            }
          }
        });
      }
    });
  }

  async getFindingStatusNames(): Promise<Array<string>> {
    const statusNames = new Array<string>();
    const translations = await this.translateService
      .get([
        'GLOBAL.FINDING.STATUS.IN_PROGRESS',
        'GLOBAL.FINDING.STATUS.NOT_ASSIGNED',
        'GLOBAL.FINDING.STATUS.SOLVED',
        'GLOBAL.FINDING.STATUS.OVERDUE'
      ])
      .toPromise();
    statusNames[FindingStatus.inProgress] = translations['GLOBAL.FINDING.STATUS.IN_PROGRESS'];
    statusNames[FindingStatus.notAssigned] = translations['GLOBAL.FINDING.STATUS.NOT_ASSIGNED'];
    statusNames[FindingStatus.solved] = translations['GLOBAL.FINDING.STATUS.SOLVED'];
    statusNames[FindingStatus.overdue] = translations['GLOBAL.FINDING.STATUS.OVERDUE'];
    return statusNames;
  }

  getFindingsAmount(): FindingsAmount {
    return this.findingsAmount;
  }

  setFindingsAmount(counters: FindingsAmount): void {
    this.findingsAmount = counters;
    this.findingsAmountSource.next(this.findingsAmount);
  }

  convertFindingsAmount(counters: any): FindingsAmount {
    const amount = {
      total: 0,
      totalInProgress: counters.total_in_progress || 0,
      totalOverdue: counters.total_overdue || 0,
      totalNotAssigned: counters.total_not_assigned || 0,
      totalSolved: counters.total_solved || 0,
      filtered: 0,
      filteredInProgress: counters.filtered_in_progress || 0,
      filteredOverdue: counters.filtered_overdue || 0,
      filteredNotAssigned: counters.filtered_not_assigned || 0,
      filteredSolved: counters.filtered_solved || 0
    };
    amount.total = amount.totalInProgress + amount.totalNotAssigned + amount.totalSolved;
    amount.filtered = amount.filteredInProgress + amount.filteredNotAssigned + amount.filteredSolved;
    return new FindingsAmount(amount);
  }

  async mergeFindings(
    onlineFindings: Array<Finding>,
    offlineFindings: Array<Finding>,
    questionId?: string
  ): Promise<Array<Finding>> {
    const mergedFindings = new Array<Finding>();
    mergedFindings.push(...onlineFindings);
    for (const finding of offlineFindings) {
      const index = _.findIndex(mergedFindings, { id: finding.id });
      if (~index) {
        mergedFindings[index].offlineRequest = finding;
      } else {
        if (finding.action.create) {
          const onlineFinding = new Finding({ id: finding.id, offlineRequest: finding });
          mergedFindings.push(onlineFinding);
        } else if (questionId && finding.action.changeSource && finding.relationships.findable.id === questionId) {
          const auditedAreaId = finding.relationships.auditedArea.id;
          const onlineFinding =
            (await this.findingOfflineService.getFindingFromOfflineStore(auditedAreaId, finding.id)) ||
            new Finding({ id: finding.id });
          onlineFinding.offlineRequest = finding;
          mergedFindings.push(onlineFinding);
        }
      }
    }
    return mergedFindings;
  }

  async mergeFindingsResponses(
    onlineFindingsResponses: FindingsResponse,
    offlineFindingsResponses: FindingsResponse,
    questionId?: string
  ): Promise<FindingsResponse> {
    const mergeAmounts = (): FindingsAmount => {
      const findingsAmount = new FindingsAmount();
      findingsAmount.merge(onlineFindingsResponses.findingsAmount);
      findingsAmount.merge(offlineFindingsResponses.findingsAmount);
      return findingsAmount;
    };

    const sortFindings = (findings: Array<Finding>): void => {
      findings.sort((findingA: Finding, findingB: Finding) => {
        if (findingA.offlineRequest && !findingB.offlineRequest) {
          return findingA.offlineRequest.action.create ? 1 : 0;
        } else if (!findingA.offlineRequest && findingB.offlineRequest) {
          return findingB.offlineRequest.action.create ? -1 : 0;
        } else if (findingA.offlineRequest && findingB.offlineRequest) {
          if (findingA.offlineRequest.action.create !== findingB.offlineRequest.action.create) {
            return findingA.offlineRequest.action.create ? 1 : -1;
          } else if (
            findingA.offlineRequest.relationships.auditedArea.id !==
            findingB.offlineRequest.relationships.auditedArea.id
          ) {
            return findingA.offlineRequest.relationships.auditedArea.id <
              findingB.offlineRequest.relationships.auditedArea.id
              ? 1
              : -1;
          }
          return 0;
        }
        if (findingA.relationships.auditedArea.id !== findingB.relationships.auditedArea.id) {
          return findingA.relationships.auditedArea.id < findingB.relationships.auditedArea.id ? 1 : -1;
        }
      });
    };

    const response = new FindingsResponse();
    response.findingsAmount = mergeAmounts();
    response.findings = await this.mergeFindings(
      onlineFindingsResponses.findings,
      offlineFindingsResponses.findings,
      questionId
    );
    sortFindings(response.findings);
    return response;
  }

  paginateFindings(
    findings: Array<Finding>,
    onlineFindingsAmount: number,
    page: number,
    limit: number
  ): Array<Finding> {
    let findingsResult: Array<Finding>;
    const currentMinIndex = page * limit;
    if (currentMinIndex >= onlineFindingsAmount) {
      const index = {
        start: currentMinIndex - onlineFindingsAmount,
        end: currentMinIndex - onlineFindingsAmount + limit
      };
      findingsResult = findings.slice(index.start, index.end);
    } else {
      findingsResult = findings.slice(0, limit);
    }
    return findingsResult;
  }

  getAuditFindings(
    auditId: string,
    page = 0,
    filters?: FindingsFilters,
    limit = this.findingsLimit,
    withRequests?: boolean,
    auditedAreas?: Array<AuditedArea>
  ): Promise<FindingsResponse> {
    const promises = new Array<Promise<{ data: FindingsResponse; requests?: boolean }>>();
    let params = new HttpParams();
    params = params.set('audit_id', auditId);
    promises.push(
      this.getFindings(params, page, filters, limit)
        .then(data => {
          return { data };
        })
        .catch(async error => {
          if (error.status === 0) {
            const data = await this.findingOfflineService.getFindingsFromOfflineStore(
              new Audit({ id: auditId }),
              page,
              filters,
              limit
            );
            if (data) {
              return { data };
            }
          }
          console.error('Error while getting findings', error);
          throw error;
        })
    );

    if (withRequests) {
      promises.push(
        this.findingOfflineService
          .getAuditFindingsRequestsListFromOfflineStore(new Audit({ auditedAreas }))
          .then((findingsResponse: FindingsResponse) => {
            return {
              data: this.findingOfflineService.convertFindingsFromOfflineStore(
                findingsResponse,
                0,
                filters,
                limit,
                true
              ),
              requests: true
            };
          })
      );
    }

    return Promise.all(promises).then(async (values: Array<{ data: FindingsResponse; requests?: boolean }>) => {
      let findingsResponse = _.find(values, value => !value.requests).data;
      const offlineFindingsResponses = _.find(values, { requests: true }).data;
      if (offlineFindingsResponses) {
        const onlineFindingsAmount = findingsResponse.findingsAmount.filtered;
        findingsResponse = await this.mergeFindingsResponses(findingsResponse, offlineFindingsResponses);
        findingsResponse.findings = this.paginateFindings(findingsResponse.findings, onlineFindingsAmount, page, limit);
      }
      return findingsResponse;
    });
  }

  getAuditedAreaFindings(
    auditedAreaId: string,
    page = 0,
    filters?: FindingsFilters,
    limit = this.findingsLimit,
    saveOffline?: boolean,
    withRequests?: boolean
  ): Promise<FindingsResponse> {
    const promises = new Array<Promise<{ data: FindingsResponse; requests?: boolean }>>();
    let params = new HttpParams();
    params = params.set('audited_area_id', auditedAreaId);
    promises.push(
      this.getFindings(params, page, filters, limit)
        .then(data => {
          if (saveOffline) {
            return this.findingOfflineService.saveAuditedAreaFindingsInOfflineStore(auditedAreaId, data).then(() => {
              return { data };
            });
          }
          return { data };
        })
        .catch(async error => {
          if (error.status === 0 && !saveOffline) {
            const data = await this.findingOfflineService.getFindingsFromOfflineStore(
              new AuditedArea({ id: auditedAreaId }),
              page,
              filters,
              limit
            );
            if (data) {
              return { data };
            }
          }
          console.error('Error while getting findings', error);
          throw error;
        })
    );

    if (withRequests) {
      promises.push(
        this.findingOfflineService
          .getAuditedAreaFindingsRequestsListFromOfflineStore(auditedAreaId)
          .then((findingsResponse: FindingsResponse) => {
            return {
              data: this.findingOfflineService.convertFindingsFromOfflineStore(
                findingsResponse,
                0,
                filters,
                limit,
                true
              ),
              requests: true
            };
          })
      );
    }

    return Promise.all(promises).then(async (values: Array<{ data: FindingsResponse; requests?: boolean }>) => {
      const onlineResponse = _.find(values, value => !value.requests);
      let findingsResponse = onlineResponse ? onlineResponse.data : new FindingsResponse();
      const offlineResponse = _.find(values, { requests: true });
      const offlineFindingsResponses = offlineResponse ? offlineResponse.data : undefined;
      if (offlineFindingsResponses) {
        const onlineFindingsAmount = findingsResponse.findingsAmount.filtered;
        findingsResponse = await this.mergeFindingsResponses(findingsResponse, offlineFindingsResponses);
        findingsResponse.findings = this.paginateFindings(findingsResponse.findings, onlineFindingsAmount, page, limit);
      }
      return findingsResponse;
    });
  }

  getQuestionFindings(
    questionId: string,
    auditedAreaId: string,
    page = 0,
    limit = this.findingsLimit,
    withRequests?: boolean
  ): Promise<FindingsResponse> {
    const promises = new Array<Promise<{ data: FindingsResponse; requests?: boolean }>>();
    let params = new HttpParams();
    params = params.set('question_id', questionId);
    params = params.set('audited_area_id', auditedAreaId);
    promises.push(
      this.getFindings(params, page, undefined, limit)
        .then(data => {
          return { data };
        })
        .catch(async error => {
          if (error.status === 0) {
            const data = await this.findingOfflineService.getQuestionFindingsFromOfflineStore(
              questionId,
              auditedAreaId,
              page,
              limit
            );
            if (data) {
              return { data };
            }
          }
          console.error('Error while getting findings', error);
          throw error;
        })
    );

    if (withRequests) {
      promises.push(
        this.findingOfflineService
          .getQuestionFindingsRequestsListFromOfflineStore(questionId, auditedAreaId)
          .then((findingsResponse: FindingsResponse) => {
            return {
              data: this.findingOfflineService.convertFindingsFromOfflineStore(
                findingsResponse,
                0,
                undefined,
                limit,
                true,
                questionId
              ),
              requests: true
            };
          })
      );
    }

    return Promise.all(promises).then(async (values: Array<{ data: FindingsResponse; requests?: boolean }>) => {
      let findingsResponse = _.find(values, value => !value.requests).data;
      const offlineFindingsResponses = _.find(values, { requests: true }).data;
      if (offlineFindingsResponses) {
        const onlineFindingsAmount = findingsResponse.findingsAmount.filtered;
        findingsResponse = await this.mergeFindingsResponses(findingsResponse, offlineFindingsResponses, questionId);
        findingsResponse.findings = this.paginateFindings(findingsResponse.findings, onlineFindingsAmount, page, limit);
      }
      return findingsResponse;
    });
  }

  getFindingsByStatus(status: string, page = 0, filters?: FindingsFilters, limit?: number): Promise<FindingsResponse> {
    let params = new HttpParams();
    params = params.set('status', status);
    return this.getFindings(params, page, filters, limit).catch(error => {
      console.error('Error while getting findings', error);
      throw error;
    });
  }

  getFindings(
    params?: HttpParams,
    page = 0,
    filters?: FindingsFilters,
    limit = this.findingsLimit
  ): Promise<FindingsResponse> {
    params = params || new HttpParams();
    if (page < 0) {
      page = 0;
    }
    params = params.set('page', page.toString());
    params = params.set('limit', limit.toString());
    if (filters) {
      params = this.addFindingsFiltersParams(params, filters);
    }

    return this.http
      .get(this.apiUrlService.findingApi, params ? { params } : undefined)
      .toPromise()
      .then((data: any) => {
        const findingsAmount = this.convertFindingsAmount(data.counters);
        this.setFindingsAmount(findingsAmount);
        return {
          findingsAmount,
          findings: this.convertFindingsToGet(data.findings)
        };
      })
      .catch(error => {
        throw error;
      });
  }

  getFinding(id: string): Promise<Finding> {
    return this.http
      .get(`${this.apiUrlService.findingApi}/${id}`)
      .toPromise()
      .then(data => {
        return this.convertFindingToGet(data);
      })
      .catch(error => {
        console.error('Error while getting finding', error);
        throw error;
      });
  }

  createFinding(finding: Finding, auditedArea: AuditedArea, saveOffline?: boolean): Promise<Finding> {
    return this.http
      .post(this.apiUrlService.findingApi, this.convertedFindingToPost(finding, auditedArea))
      .toPromise()
      .then(data => {
        const findingResponse = this.convertFindingToGet(data);
        this.findingOfflineService.updateFindingInOfflineStore(findingResponse);
        return findingResponse;
      })
      .catch(error => {
        if (error.status === 0 && saveOffline) {
          return this.findingOfflineService.updateAuditedAreaFindingRequestInOfflineStore(
            auditedArea.id,
            'create',
            finding
          );
        }
        console.error('Error while creating finding', error);
        throw error;
      });
  }

  updateFinding(finding: Finding, oldFinding: Finding, saveOffline?: boolean): Promise<Finding> {
    if (this.canUpdateFinding(finding, oldFinding)) {
      return this.http
        .put(`${this.apiUrlService.findingApi}/${finding.id}`, this.convertedFindingToPut(finding, oldFinding))
        .toPromise()
        .then(async data => {
          const findingResponse = this.convertFindingToGet(data);
          await this.findingOfflineService.updateFindingInOfflineStore(findingResponse, oldFinding);
          await this.findingOfflineService.removeFindingRequestFromOfflineStore(
            oldFinding.relationships.auditedArea.id,
            finding.id
          );
          return findingResponse;
        })
        .catch(async error => {
          if (error.status === 0 && saveOffline) {
            const offlineRequest = await this.findingOfflineService.updateAuditedAreaFindingRequestInOfflineStore(
              finding.relationships.auditedArea.id,
              'update',
              finding,
              oldFinding
            );
            oldFinding.offlineRequest = offlineRequest;
            return oldFinding;
          }
          console.error('Error while updating finding', error);
          throw error;
        });
    } else {
      return new Promise((resolve, reject) => resolve(finding));
    }
  }

  updateFindings(
    findingsRequests: FindingsRequests,
    saveRequestsOffline?: boolean
  ): Promise<{ data: FindingsRequests; offline?: boolean }> {
    if (
      (findingsRequests.create && findingsRequests.create.length) ||
      (findingsRequests.update && findingsRequests.update.length)
    ) {
      return this.http
        .post(`${this.apiUrlService.findingApi}/batch_update`, this.convertedFindingsToMassPost(findingsRequests))
        .toPromise()
        .then((data: BatchResponse) => {
          if (data.invalid && data.invalid.count > 0) {
            console.error('Error while updating findings', data.invalid.errors);
            throw new Error(data.invalid.count + '');
          } else {
            const promises = new Array<Promise<any>>();
            promises.push(this.findingOfflineService.updateFindingsInOfflineStore(findingsRequests));
            promises.push(
              this.findingOfflineService.removeAuditedAreaFindingsRequestsFromOfflineStore(
                findingsRequests.auditedAreaId
              )
            );
            return Promise.all(promises).then(() => {
              return { data: findingsRequests };
            });
          }
        })
        .catch(error => {
          if (error.status === 0 && saveRequestsOffline) {
            return this.findingOfflineService
              .updateAuditedAreaFindingRequestsInOfflineStore(findingsRequests)
              .then((response: FindingsRequests) => {
                return { data: response, offline: true };
              });
          }
          console.error('Error while updating findings', error);
          throw error;
        });
    } else {
      return new Promise((resolve, reject) => resolve({ data: findingsRequests }));
    }
  }

  removeFinding(finding: Finding): Promise<Finding> {
    return this.http
      .put(`${this.apiUrlService.findingApi}/${finding.id}`, convertFindingToArchive(finding))
      .toPromise()
      .then(data => {
        return this.findingOfflineService
          .removeFindingFromOfflineStore(finding)
          .then(() => this.convertFindingToGet(data))
          .catch(() => this.convertFindingToGet(data));
      })
      .catch(error => {
        console.error('Error while removing finding', error);
        throw error;
      });

    function convertFindingToArchive(finding: Finding): { finding_id: string; archived: 'true' } {
      return {
        finding_id: finding.id,
        archived: 'true'
      };
    }
  }

  exportFindingsByStatus(status: string, filters?: FindingsFilters): Promise<Blob> {
    let params = new HttpParams();
    params = params.set('status', status);
    return this.exportFindings(params, filters).catch(error => {
      console.error('Error while exporting findings', error);
      throw error;
    });
  }

  exportFindings(params?: HttpParams, filters?: FindingsFilters): Promise<Blob> {
    params = params || new HttpParams();
    if (filters) {
      params = this.addFindingsFiltersParams(params, filters);
    }
    const options = {
      params,
      responseType: 'blob' as 'blob'
    };

    return this.http
      .get(`${this.apiUrlService.findingApi}/export`, options)
      .toPromise()
      .then(async (data: Blob) => {
        const todayDate = moment().format('DD.MM.YYYY');
        await this.fileService.downloadFile(data, `findings-${todayDate}.xlsx`);
        return data;
      })
      .catch(error => {
        throw error;
      });
  }

  assignEmployee(finding: Finding, employee: Employee): Promise<Finding> {
    return this.http
      .put(`${this.apiUrlService.findingApi}/${finding.id}`, convertEmployeeToPut(employee))
      .toPromise()
      .then(data => {
        const findingResponse = this.convertFindingToGet(data);
        this.findingOfflineService.updateFindingInOfflineStore(findingResponse);
        return findingResponse;
      })
      .catch(error => {
        console.error('Error while assigning employee to finding', error);
        throw error;
      });

    function convertEmployeeToPut(employee: Employee): any {
      const employeeRequest: any = {
        employee_id: employee.id,
        status: 'in_progress'
      };
      return employeeRequest;
    }
  }

  uploadFindingAttachments(finding: Finding, attachments: Array<Attachment>): Promise<Finding> {
    const convertFindingAttachmentsToUpload = (attachments: Array<Attachment>): FormData => {
      const formData = new FormData();
      if (attachments) {
        attachments.forEach(attachment => {
          if (attachment.file && !attachment.url) {
            formData.append('attachments[][file]', attachment.file);
            formData.append('attachments[][checksum]', attachment.checksum);
          }
        });
      }
      return formData;
    };

    return this.http
      .put(`${this.apiUrlService.findingApi}/${finding.id}`, convertFindingAttachmentsToUpload(attachments))
      .toPromise()
      .then(data => {
        const findingResponse = this.convertFindingToGet(data, attachments);
        this.findingOfflineService.updateFindingInOfflineStore(findingResponse);
        return findingResponse;
      })
      .catch(error => {
        console.error('Error while assigning employee to finding', error);
        throw error;
      });
  }

  uploadFindingsAttachments(
    auditedAreaId: string,
    findingsAttachments: Array<FindingAttachmentRequest>
  ): Promise<void> {
    const convertAttachmentsToPost = (): FormData => {
      const formData = new FormData();
      formData.append('audited_area_id', auditedAreaId);
      findingsAttachments.forEach(findingAttachment => {
        formData.append('attachments[][question_id]', findingAttachment.questionId);
        formData.append('attachments[][finding_id]', findingAttachment.findingId);
        formData.append('attachments[][file]', findingAttachment.attachment.file);
        formData.append('attachments[][checksum]', findingAttachment.attachment.checksum);
      });
      return formData;
    };

    if (findingsAttachments && findingsAttachments.length) {
      return this.http
        .post(`${this.apiUrlService.questionMediaApi}/batch_update`, convertAttachmentsToPost())
        .toPromise()
        .then(data => {
          // TODO: Update attachments in findings in offline store
          return this.attachmentService.removeFindingsAttachmentsRequestsFromOfflineStore(auditedAreaId);
        })
        .catch(error => {
          console.error('Error while updating attachments', error);
          throw error;
        });
    }
    return new Promise((resolve, reject) => resolve());
  }

  removeAttachment(finding: Finding, attachment: Attachment): Promise<Finding> {
    // To remove attachment send attachment with url attribute
    if (attachment.url && attachment.name) {
      return this.http
        .put(`${this.apiUrlService.findingApi}/${finding.id}`, {
          attachments: [{ file: attachment.name, checksum: attachment.checksum }]
        })
        .toPromise()
        .then(data => {
          const findingResponse = this.convertFindingToGet(data);
          this.findingOfflineService.updateFindingInOfflineStore(findingResponse);
          return findingResponse;
        })
        .catch(error => {
          console.error('Error while removing attachment from finding', error);
          throw error;
        });
    } else {
      return Promise.reject(new Error('Attachment doesn\'t have required attributes'));
    }
  }

  getFindingTypes(saveOffline?: boolean): Promise<Array<FindingType>> {
    const mapResponse = (data: any): Array<FindingType> => {
      if (!data) {
        data = new Array<FindingType>();
      }
      data = data.map(findingType => new FindingType(findingType));
      return data;
    };

    return this.http
      .get(this.apiUrlService.findingTypeApi)
      .toPromise()
      .then((data: Array<any>) => {
        if (saveOffline) {
          this.findingOfflineService.saveFindingTypesInOfflineStore(data);
        }
        return mapResponse(data);
      })
      .catch(async error => {
        if (error.status === 0 && !saveOffline) {
          const data = await this.findingOfflineService.getFindingTypesFromOfflineStore();
          if (data) {
            return mapResponse(data);
          }
        }
        console.error('Error while getting findings types', error);
        throw error;
      });
  }

  createFindingType(findingType: FindingType): Promise<FindingType> {
    return this.http
      .post(this.apiUrlService.findingTypeApi, findingType)
      .toPromise()
      .then(data => {
        this.findingOfflineService.updateFindingTypeInOfflineStore(data);
        return new FindingType(data);
      })
      .catch(error => {
        console.error('Error while creating finding type', error);
        throw error;
      });
  }

  updateFindingType(findingType: FindingType): Promise<FindingType> {
    return this.http
      .put(`${this.apiUrlService.findingTypeApi}/${findingType.id}`, findingType)
      .toPromise()
      .then((data: any) => {
        this.findingOfflineService.updateFindingTypeInOfflineStore(data);
        return new FindingType(data);
      })
      .catch(error => {
        console.error('Error while updating finding type', error);
        throw error;
      });
  }

  archiveFindingType(findingType: FindingType, archive = true): Promise<FindingType> {
    return this.http
      .put(`${this.apiUrlService.findingTypeApi}/${findingType.id}`, convertFindingTypeToArchive())
      .toPromise()
      .then(data => {
        this.findingOfflineService.removeFindingTypeFromOfflineStore(findingType);
        return new FindingType(data);
      })
      .catch(error => {
        console.error('Error while updating finding type', error);
        throw error;
      });

    function convertFindingTypeToArchive(): any {
      return {
        id: findingType.id,
        archived: archive ? 'true' : 'false'
      };
    }
  }

  removeFindingType(findingType: FindingType): Promise<any> {
    return this.http
      .delete(`${this.apiUrlService.findingTypeApi}/${findingType.id}`)
      .toPromise()
      .then(data => {
        this.findingOfflineService.removeFindingTypeFromOfflineStore(findingType);
        return data;
      })
      .catch(error => {
        console.error('Error while removing finding type', error);
        throw error;
      });
  }

  solveFinding(solvingData: Solving): Promise<Solving> {
    return this.http
      .post(this.apiUrlService.solvingsApi, this.convertSolvingToPost(solvingData))
      .toPromise()
      .then(data => {
        return this.convertSolvingToGet(data);
      })
      .catch(error => {
        console.error('Error while solving findings', error);
        throw error;
      });
  }

  getSolving(finding: Finding): Promise<Solving> {
    let params = new HttpParams();
    params = params.set('finding_id', finding.id);

    return this.http
      .get(this.apiUrlService.solvingsApi, { params })
      .toPromise()
      .then(data => {
        return this.convertSolvingToGet(data);
      })
      .catch(error => {
        console.error('Error while getting solving', error);
        throw error;
      });
  }

  private addFindingsFiltersParams(params: HttpParams, filters: FindingsFilters): HttpParams {
    if (filters.search) {
      params = params.set('problem_cause_solution', filters.search);
    }
    if (filters.dateFrom) {
      params = params.set('start_date', filters.dateFrom);
    }
    if (filters.dateTo) {
      params = params.set('end_date', filters.dateTo);
    }
    if (filters.types && filters.types.length) {
      for (const typeId of filters.types) {
        params = params.append('finding_types_ids[]', typeId);
      }
    }
    if (filters.auditTypes && filters.auditTypes.length) {
      for (const typeId of filters.auditTypes) {
        params = params.append('audit_types_ids[]', typeId);
      }
    }
    if (filters.responsiblePersons && filters.responsiblePersons.length) {
      for (const responsiblePersonId of filters.responsiblePersons) {
        params = params.append('employees_ids[]', responsiblePersonId);
      }
    }
    if (filters.facilities && filters.facilities.facilities.length) {
      for (const facilityId of filters.facilities.facilities) {
        params = params.append('facilities_ids[]', facilityId);
      }
      params = params.set('include_children_facilities', filters.facilities.subFolders.toString() || 'false');
    }
    return params;
  }

  private convertFindingsToGet(findings: Array<any>): Array<Finding> {
    const findingsList = new Array<Finding>();
    if (findings) {
      findings.forEach((finding: any) => {
        findingsList.push(this.convertFindingToGet(finding));
      });
    }
    return findingsList;
  }

  private convertFindingToGet(finding: any, attachmentsRequest?: Array<Attachment>): Finding {
    const findingRequest = {
      id: finding.id,
      problem: finding.problem,
      cause: finding.cause,
      solution: finding.solution,
      status: finding.status,
      attachments: attachmentsRequest
        ? getAttachmentsWithFile(finding.attachments, attachmentsRequest)
        : finding.attachments,
      deadline: finding.deadline,
      relationships: new FindingRelationships({
        audit: finding.relationships.audit
          ? this.auditConvertService.convertAuditToGet(finding.relationships.audit)
          : { id: finding.relationships.audit_id },
        auditedArea: finding.relationships.audited_area
          ? this.auditConvertService.convertAuditedAreaToGet(finding.relationships.audited_area)
          : {
              id: finding.relationships.audited_area_id,
              facility: {
                name: finding.relationships.audited_area_facility_name
              }
            },
        employee: finding.relationships.employee,
        findable: {
          id: finding.relationships.findable_id,
          _type: finding.relationships.findable_type
        },
        findingType: finding.relationships.finding_type,
        question: finding.relationships.question_content,
        section: finding.relationships.section_name
      })
    };
    return new Finding(findingRequest);
  }

  private convertedFindingToPost(finding: Finding, auditedArea: AuditedArea): FormData {
    const formData = new FormData();
    formData.append('audited_area_id', auditedArea.id);
    formData.append('findable_id', finding.relationships.findable.id);
    formData.append('findable_type', finding.relationships.findable._type);
    formData.append('problem', finding.problem);
    if (finding.cause && finding.cause.length > 0) {
      formData.append('cause', finding.cause);
    }
    if (finding.solution && finding.solution.length > 0) {
      formData.append('solution', finding.solution);
    }
    if (finding.relationships.findingType) {
      formData.append('finding_type_id', finding.relationships.findingType.id);
    }
    if (finding.attachments) {
      finding.attachments.forEach(attachment => {
        if (attachment.file && !attachment.toRemove) {
          formData.append('attachments[][file]', attachment.file, attachment.offline ? attachment.name : undefined);
          formData.append('attachments[][checksum]', attachment.checksum);
        }
      });
    }
    return formData;
  }

  private canUpdateFinding(finding: Finding, oldFinding?: Finding): boolean {
    let attachmentsToUpdate = false;
    if (finding.attachments) {
      finding.attachments.forEach(attachment => {
        if (attachment.file) {
          attachmentsToUpdate = true;
        }
        if (attachment.toRemove) {
          attachmentsToUpdate = true;
        }
      });
    }
    return (
      !oldFinding ||
      !!oldFinding.offlineRequest ||
      (finding.problem && finding.problem !== oldFinding.problem) ||
      finding.cause !== oldFinding.cause ||
      finding.solution !== oldFinding.solution ||
      (finding.status && finding.status !== oldFinding.status) ||
      !!finding.deadline ||
      (!!finding.relationships.employee && !!finding.relationships.employee.id) ||
      (finding.relationships.findable &&
        (!oldFinding.relationships.findable ||
          finding.relationships.findable._type !== oldFinding.relationships.findable._type)) ||
      (finding.relationships.findable &&
        (!oldFinding.relationships.findable ||
          finding.relationships.findable.id !== oldFinding.relationships.findable.id)) ||
      (finding.relationships.findingType &&
        (!oldFinding.relationships.findingType ||
          finding.relationships.findingType.id !== oldFinding.relationships.findingType.id)) ||
      attachmentsToUpdate
    );
  }

  private convertedFindingToPut(finding: Finding, oldFinding?: Finding): FormData {
    const formData = new FormData();
    if (finding.relationships.auditedArea) {
      formData.append('audited_area_id', finding.relationships.auditedArea.id);
    }
    if (finding.relationships.findable && finding.relationships.findable.id) {
      formData.append('findable_id', finding.relationships.findable.id);
    }
    if (finding.relationships.findable && finding.relationships.findable._type) {
      formData.append('findable_type', finding.relationships.findable._type);
    }
    if (!HelperUtil.isNullable(finding.problem) && (!oldFinding || finding.problem !== oldFinding.problem)) {
      formData.append('problem', finding.problem);
    }
    if (!HelperUtil.isNullable(finding.cause) && (!oldFinding || finding.cause !== oldFinding.cause)) {
      formData.append('cause', finding.cause);
    }
    if (!HelperUtil.isNullable(finding.solution) && (!oldFinding || finding.solution !== oldFinding.solution)) {
      formData.append('solution', finding.solution);
    }
    if (finding.status && (!oldFinding || finding.status !== oldFinding.status)) {
      formData.append('status', finding.status);
    }
    if (finding.relationships.employee) {
      formData.append('employee_id', finding.relationships.employee.id);
    }
    if (finding.deadline) {
      formData.append('deadline', moment(finding.deadline).format());
    }
    const newFindingType = finding.relationships.findingType;
    const oldFindingType = oldFinding ? oldFinding.relationships.findingType : undefined;
    if (newFindingType && (!oldFindingType || newFindingType.id !== oldFindingType.id)) {
      formData.append('finding_type_id', finding.relationships.findingType.id);
    }
    if (finding.attachments) {
      finding.attachments.forEach(attachment => {
        if (attachment.file && !attachment.toRemove) {
          formData.append('attachments[][file]', attachment.file, attachment.offline ? attachment.name : undefined);
          formData.append('attachments[][checksum]', attachment.checksum);
        }
        if (attachment.toRemove && !attachment.offline) {
          formData.append('attachments[][file]', attachment.name);
          formData.append('attachments[][checksum]', attachment.checksum);
        }
      });
    }
    return formData;
  }

  private convertedFindingsToMassPost(findingsRequests: FindingsRequests): FormData {
    const appendFindingsToFormData = (
      formData: FormData,
      findings: Array<Finding>,
      action: 'create' | 'update'
    ): void => {
      const isToUpdate = action === 'update';
      const arrayName = isToUpdate ? 'update_findings' : 'create_findings';
      if (findings && findings.length) {
        findings.forEach(finding => {
          if (isToUpdate) {
            formData.append(`${arrayName}[][finding_id]`, finding.id);
          }
          formData.append(`${arrayName}[][finding_type_id]`, finding.relationships.findingType.id);
          formData.append(`${arrayName}[][problem]`, finding.problem);
          if (!HelperUtil.isNullable(finding.cause)) {
            formData.append(`${arrayName}[][cause]`, finding.cause);
          }
          if (!HelperUtil.isNullable(finding.solution)) {
            formData.append(`${arrayName}[][solution]`, finding.solution);
          }
          if (finding.deadline) {
            formData.append(`${arrayName}[][deadline]`, finding.deadline.format());
          }
          if (finding.relationships.employee) {
            formData.append(`${arrayName}[][employee_id]`, finding.relationships.employee.id);
          }
          if (finding.relationships.findable._type === QUESTION_TYPE) {
            formData.append(`${arrayName}[][question_id]`, finding.relationships.findable.id);
          }
          formData.append(
            `${arrayName}[][status]`,
            finding.status ||
              (finding.relationships.employee ? finding.relationships.employee.id : FindingStatus.notAssigned)
          );
          if (finding.attachments) {
            finding.attachments.forEach(attachment => {
              if (attachment.file) {
                formData.append(`${arrayName}[][attachments[][file]]`, attachment.file, attachment.name);
                formData.append(`${arrayName}[][attachments[][checksum]]`, attachment.checksum);
              }
            });
          }
        });
      }
    };

    const formData = new FormData();
    formData.append('audited_area_id', findingsRequests.auditedAreaId);
    appendFindingsToFormData(formData, findingsRequests.create, 'create');
    appendFindingsToFormData(formData, findingsRequests.update, 'update');
    return formData;
  }

  private convertSolvingToPost(solvingData: Solving): FormData {
    const formData = new FormData();
    formData.append('finding_id', solvingData.findingId);
    formData.append('solved_by', solvingData.solvedBy);
    formData.append('solved_at', solvingData.solvedAt.format());
    if (solvingData.note) {
      formData.append('note', solvingData.note);
    }
    if (solvingData.attachments && solvingData.attachments.length) {
      solvingData.attachments.forEach(attachment => {
        if (attachment.file) {
          formData.append('attachments[][file]', attachment.file);
          formData.append('attachments[][checksum]', attachment.checksum);
        }
      });
    }
    return formData;
  }

  private convertSolvingToGet(solvingData: any): Solving {
    const solvingRequest = {
      findingId: solvingData.finding_id,
      solvedBy: solvingData.solved_by,
      solvedAt: solvingData.solved_at,
      note: solvingData.note,
      attachments: solvingData.attachments
    };
    return new Solving(solvingRequest);
  }

  async saveFindingTypesOffline(): Promise<Array<FindingType>> {
    if (!this.progressService.hasRunningFindingTypesGroup()) {
      await this.progressService.updateFindingTypesProgressGroup(undefined, undefined, true);
      return this.getFindingTypes(true)
        .then(async findingTypes => {
          await this.progressService.updateFindingTypesProgressGroup(ProgressStatus.success);
          this.progressService.countProgress();
          this.progressService.checkProgressStatus();
          return findingTypes;
        })
        .catch(async error => {
          await this.progressService.updateFindingTypesProgressGroup(ProgressStatus.error);
          this.progressService.countProgress();
          this.progressService.checkProgressStatus();
          throw error;
        });
    } else {
      return new Promise((resolve, reject) => resolve([]));
    }
  }
}

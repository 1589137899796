
<gw-modal id="findingModal" #modal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.MODAL_HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content" *ngIf="modal.opened">
    <gw-add-finding #addFinding="gwAddFinding" [auditedArea]="modalAuditedArea" [sections]="modalSections" [findingTypes]="findingTypes" [defaultFindable]="modal.data.findable" [finding]="modal.data.finding" [offlineSupport]="true"></gw-add-finding>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="button--primary" type="button" tabindex="7" [disabled]="savingFinding" (click)="saveFinding(modal.data.finding)"><i class="button__icon--before button__icon--spinner" *ngIf="savingFinding">
        <div class="spinner"></div></i>{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="audit-summary__header">
  <h1 class="audit-summary__header-title">{{ 'AUDIT_SUMMARY.FINDINGS.HEADER' | translate }}</h1>
  <button class="button--primary audit-summary__header-button" *ngIf="auditedArea &amp;&amp; !audit.disabled" (click)="showAddFindingModal()">{{ 'AUDIT_SUMMARY.FINDINGS.ADD_FINDING' | translate }}</button>
</div>
<div class="audit-summary__progress">
  <div class="audit-summary__progress-labels">
    <div class="audit-summary__progress-label" [class.audit-summary__progress-label--light]="!totals || !totals?.findings">{{ 'AUDIT_SUMMARY.FINDINGS.TOTAL' | translate }}:&nbsp;<span class="audit-summary__progress-amount">{{ totals.findings }}</span></div>
    <div class="audit-summary__progress-label" [class.audit-summary__progress-label--light]="!totals || !totals?.notAssignedFindings">{{ 'AUDIT_SUMMARY.FINDINGS.NOT_ASSIGNED' | translate }}:&nbsp;<span class="audit-summary__progress-amount">{{ totals.notAssignedFindings }}</span></div>
    <div class="audit-summary__progress-label" [class.audit-summary__progress-label--light]="!totals || !totals?.resolvedFindings">{{ 'AUDIT_SUMMARY.FINDINGS.RESOLVED' | translate }}:&nbsp;<span class="audit-summary__progress-amount">{{ totals.resolvedFindings }}</span></div>
    <div class="audit-summary__progress-label" [class.audit-summary__progress-label--light]="!totals || !totals?.overdueFindings">{{ 'AUDIT_SUMMARY.FINDINGS.OVERDUE' | translate }}:&nbsp;<span class="audit-summary__progress-amount">{{ totals.overdueFindings }}</span></div>
  </div>
  <gw-progress-bar [total]="totals.findings" [progress]="totals.findings - totals.notAssignedFindings" [showPercents]="false"></gw-progress-bar>
</div>
<gw-finding class="audit-summary__finding" *ngFor="let finding of findings" [finding]="finding" [employees]="employees" [employeesAssigns]="employeesAssigns" [withAssign]="true" [withFindable]="true" [disabled]="{remove: audit.disabled}" (findingChanged)="findingChanged($event)" (update)="showUpdateFindingModal(finding)" (remove)="showRemoveFindingDialog(finding)"></gw-finding>
<gw-pagination class="audit-summary__pagination" *ngIf="findings &amp;&amp; findings.length &gt; 0" [totalElementsAmount]="totals.findings" [currentPage]="filterService?.page" [currentLimit]="filterService?.limit" (changePage)="changePage($event)" (changeLimit)="changeLimit($event)"></gw-pagination>
<div class="audit-summary__button-group">
  <button class="button--primary audit-summary__button" (click)="saveFindings()">{{ 'AUDIT_SUMMARY.FINDINGS.CONFIRM_ASSIGNS' | translate }}</button>
  <button class="link--primary audit-summary__button" *ngIf="employeesAssigns &amp;&amp; getObjectSize(employeesAssigns)">{{ 'AUDIT_SUMMARY.FINDINGS.SEND_NOTIFICATIONS' | translate }}({{ getObjectSize(employeesAssigns) }})</button>
</div>
import { Component, Input } from '@angular/core';

import { PageElementsCount } from '../../interfaces/page.interface';

@Component({
  selector: 'gw-pagination-count',
  templateUrl: './pagination-count.component.html',
  styleUrls: ['./pagination-count.component.scss']
})
export class PaginationCountComponent {
  @Input() count: PageElementsCount;
}

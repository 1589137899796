import gql from 'graphql-tag';

import { FileFragment } from '../../../../../graphQl/fragments/file.fragment';

export const EmployeeFragment = gql`
  fragment EmployeeFragment on Employee {
    email
    id
    lastName
    name
    language
    company {
      id
      name
    }
    avatar {
      ...FileFragment
    }
  }
  ${FileFragment}
`;

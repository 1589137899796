import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit {

  @Input() percent = 0;
  rotate = {
    left: 180,
    right: 0
  };

  constructor() { }

  ngOnInit() {
    this.countRotate();
  }

  countRotate() {
    if (this.percent <= 50) {
      this.rotate.right = this.percent * 3.6;
    } else {
      this.rotate.right = 180;
      this.rotate.left = this.percent * 3.6;
    }
  }

}

import { Component, OnDestroy, OnInit, TemplateRef, Type } from '@angular/core';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogOverlayRef } from './dialog-overlay-ref';

@Component({
  selector: 'gw-overlay',
  templateUrl: './overlay.component.html'
})
export class OverlayComponent implements OnInit, OnDestroy {
  contentType: 'template' | 'string' | 'component';
  content: string | TemplateRef<any> | Type<any>;
  context: { close: () => {} };
  destroy$ = new Subject<void>();

  constructor(public dialogOverlayRef: DialogOverlayRef, private router: Router) {}

  ngOnInit(): void {
    this.setContentType();
    this.observeRouter();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  setContentType(): any {
    this.content = this.dialogOverlayRef.content;

    if (typeof this.content === 'string') {
      this.contentType = 'string';
    } else if (this.content instanceof TemplateRef) {
      this.contentType = 'template';
      this.context = {
        close: this.dialogOverlayRef.close.bind(this.dialogOverlayRef)
      };
    } else {
      this.contentType = 'component';
    }
  }

  observeRouter(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.close();
    });
  }

  close(): void {
    this.dialogOverlayRef.close(null);
  }
}

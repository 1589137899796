import { Attachment } from 'gutwin-shared';

export const getAttachmentsWithFile = (
  attachments: Array<Attachment>,
  attachmentsRequests: Array<Attachment>
): Array<Attachment> => {
  return attachments?.map(attachment => {
    const attachmentRequest = attachmentsRequests.find(
      attachmentRequest => attachmentRequest.checksum === attachment.checksum
    );
    return attachmentRequest
      ? new Attachment({
          ...attachment,
          file: attachmentRequest.file
        })
      : attachment;
  });
};

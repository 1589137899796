import { Company } from './company.model';
import { EmployeePermissions } from './employee-permissions.model';
import { Employee } from './employee.model';
import { Role } from './role.model';

import { PermissionsNames } from '../enums/permissions-names.enum';

export class EmployeeExtended extends Employee {
  company: Company;
  roles: Array<Role>;
  permissions?: EmployeePermissions;

  constructor(employee?: Partial<EmployeeExtended>) {
    super(employee);
    this.company = employee?.company;
    this.roles = employee ? this.generateRoles(employee.roles) : [];
    this.permissions = employee ? new EmployeePermissions(employee.permissions) : undefined;
  }

  generateRoles(roles: Array<Partial<Role>>): Array<Role> {
    if (roles) {
      return roles.map(role => new Role(role));
    }
  }

  hasPermission?(permission: PermissionsNames): boolean {
    return this.permissions && this.permissions.ownedPermissions && this.permissions.ownedPermissions[permission];
  }
}

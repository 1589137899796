import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeFileExtension'
})
export class RemoveFileExtensionPipe implements PipeTransform {
  public transform(fileName: string): string {
    return fileName.split('.').shift();
  }
}

import moment from 'moment';

export class AuditedAreaDashboardChartModel {
  averageNumeric: number;
  averagePercentage: number;
  auditId: string;
  auditedAreaId: string;
  auditName: string;
  auditType: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
  facilityName: string;
  auditors: string[];
  auditees: string[];

  constructor(data: Partial<AuditedAreaDashboardChartModel>) {
    Object.assign(this, data);
  }
}

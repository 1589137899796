import { Component, HostListener, Input, OnInit } from '@angular/core';

// Gutwin Shared Library
import { SimpleDropdownDirective } from 'gutwin-shared';

// Services
import { FilterModuleService } from '@gutwin-audit/shared/services/filter-module.service';

@Component({
  selector: 'gw-name-filter',
  templateUrl: './name-filter.component.html',
  styleUrls: ['./name-filter.component.scss'],
  exportAs: 'nameFilter'
})
export class NameFilterComponent implements OnInit {
  @Input() dropdown: SimpleDropdownDirective;
  @Input() filterKey = 'name';
  nameSearchQuery: string;

  constructor(private filterService: FilterModuleService) { }

  ngOnInit() {
    if (this.filterService.filters && this.filterService.filters[this.filterKey]) {
      this.nameSearchQuery = this.filterService.filters[this.filterKey];
    }
  }

  submitNameFilter() {
    this.filterService.setFilter(this.filterKey, this.nameSearchQuery);
    this.dropdown.closeDropdown();
  }

  @HostListener('keydown', ['$event'])
  keydown(event) {
    if (event.key === 'Enter' && this.dropdown.openedWithDelay) {
      event.preventDefault();
      this.dropdown.closeDropdown();
      this.submitNameFilter();
      return false;
    }
  }
}

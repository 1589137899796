import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { find, findIndex } from 'lodash';

import { EmployeeExtended, Permission, PermissionsNames } from 'gutwin-shared';

import { Audit, AuditState } from '@gutwin-audit/shared/models/audit.model';
import { Finding, FindingStatus } from '@gutwin-audit/shared/models/finding.model';

import { PermissionResponse } from '@gutwin-audit/shared/interfaces/permission.interface';

@Injectable()
export class PermissionService {
  private permissionsRoutes = [
    {
      path: 'questionnaires',
      permission: PermissionsNames.manageQuestionnaires
    },
    {
      path: 'users',
      permission: PermissionsNames.manageUsers
    },
    {
      path: 'roles',
      permission: PermissionsNames.manageUserRoles
    },
    {
      path: 'audit-types',
      permission: PermissionsNames.manageAuditTypes
    },
    {
      path: 'finding-types',
      permission: PermissionsNames.manageFindingTypes
    },
    {
      path: 'rating-scale',
      permission: PermissionsNames.manageQuestionRatings
    },
    {
      path: 'facilities',
      permission: PermissionsNames.manageFacilities
    },
    {
      path: 'company-settings',
      permission: PermissionsNames.manageCompany
    },
    {
      path: 'reports',
      permission: PermissionsNames.manageReportTemplates
    }
  ];

  constructor(private router: Router) {}

  canAuditBeManaged(audit: Audit, currentUser: EmployeeExtended): boolean {
    return audit.state !== AuditState.finished || currentUser.hasPermission(PermissionsNames.manageFinishedAudits);
  }

  canFindingBeManaged(finding: Finding, currentUser: EmployeeExtended, checkRole?: boolean): boolean {
    const hasRole = (role: string): boolean => {
      const audit = finding.relationships.audit;
      if (audit.employees[role]) {
        return !!~findIndex(audit.employees[role], { id: currentUser.id });
      }
    };

    const canManageFinding =
      finding.status !== FindingStatus.solved || currentUser.hasPermission(PermissionsNames.manageSolvedFindings);
    const canManageAudit = checkRole
      ? hasRole('auditor') || hasRole('creator') || currentUser.hasPermission(PermissionsNames.accessAllAudits)
      : true;

    return canManageFinding && canManageAudit;
  }

  canCauseAndSolutionBeManaged(currentUser: EmployeeExtended): boolean {
    return currentUser.hasPermission(PermissionsNames.manageCauseAndSolution);
  }

  canFindingBeSolved(finding: Finding, currentUser: EmployeeExtended): boolean {
    return finding.relationships.employee && finding.relationships.employee.id === currentUser.id;
  }

  initDefaultAdminRoute(currentUser: EmployeeExtended): boolean {
    if (currentUser && currentUser.permissions) {
      const defaultPath = this.getFirstAvailableRoutePath(currentUser);
      if (defaultPath) {
        this.setDefaultAdminRoute(defaultPath);
        return true;
      }
    }
    return false;
  }

  private setDefaultAdminRoute(routePath: string): void {
    const adminRouter = find(this.router.config, { path: 'admin' });
    if (adminRouter && adminRouter['_loadedConfig']) {
      const adminRouterChildren = adminRouter['_loadedConfig'].routes[0].children;
      const redirectionRouteIndex = findIndex(adminRouterChildren, { path: '' });
      if (~redirectionRouteIndex) {
        adminRouterChildren[redirectionRouteIndex] = {
          path: '',
          redirectTo: routePath,
          pathMatch: 'full'
        };
      }
    }
  }

  getFirstAvailableRoutePath(currentUser: EmployeeExtended): string {
    for (const permissionRoute of this.permissionsRoutes) {
      if (currentUser.hasPermission(permissionRoute.permission)) {
        return permissionRoute.path;
      }
    }
  }

  convertPermissionToGet(permission: PermissionResponse, added?: boolean): Permission {
    return new Permission({
      id: permission.id,
      name: permission.name,
      title: permission.title,
      description: permission.description,
      added: added || !!(permission.added_to && permission.added_to.length),
      rolesIds: permission.added_to
    });
  }
}

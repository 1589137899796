import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Pipe({
  name: 'isPasswordInvalid',
  pure: false
})
export class IsPasswordInvalidPipe implements PipeTransform {
  transform(control: AbstractControl, form: FormGroup, submitted: boolean): any {
    return submitted && (form.errors?.passwordNotConfirmed || control.errors?.required || control.errors?.minlength);
  }
}

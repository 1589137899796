import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { findIndex, groupBy, values } from 'lodash';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// Gutwin Shared Library
import { DateTimePickerComponent, Employee, ModalService, SimpleDropdownDirective } from 'gutwin-shared';

// Models
import { Audit } from '@gutwin-audit/shared/models/audit.model';
import { AuditedArea } from '@gutwin-audit/shared/models/audited-area.model';
import { FacilityFilter } from '@gutwin-audit/shared/models/facility-filter.model';

// Components
import { SelectFacilityComponent } from '@gutwin-audit/audit/audit-creation/select-facility/select-facility.component';

// Services
import { AuditService } from '@gutwin-audit/shared/services/audit.service';
import { AuditedAreaService } from '@gutwin-audit/shared/services/audited-area.service';
import { StorageModuleService, StoragesNamesModule } from '@gutwin-audit/shared/services/storage-module.service';

// Validators
import { DateValidator } from '@gutwin-audit/shared/validators/date.validator';

@Component({
  selector: 'gw-step-2-audit-plan',
  templateUrl: './step-2-audit-plan.component.html',
  styleUrls: ['./step-2-audit-plan.component.scss']
})
export class Step2AuditPlanComponent implements OnInit, OnDestroy {
  @ViewChild('firstDatePicker') startDatePicker: SimpleDropdownDirective;
  @ViewChild('secondDatePicker') endDatePicker: SimpleDropdownDirective;
  @ViewChild('startTimePicker') startTimePicker: DateTimePickerComponent;
  @ViewChild('endTimePicker') endTimePicker: DateTimePickerComponent;
  @ViewChild('auditedAreaElement') auditedAreaElement: ElementRef;
  @ViewChild('selectFacility') selectFacility: SelectFacilityComponent;
  @Output() submitStep = new EventEmitter<any>();
  @Output() updateAudit = new EventEmitter<any>();
  @Output() updateStorage = new EventEmitter<any>();
  @Output() init = new EventEmitter<any>();
  @Input() audit: Audit;
  @Input() auditObservable: Observable<Audit>;
  @Input() storedAudit;
  @Input() employees: Array<Employee>;
  @Input() facilities: Array<FacilityFilter>;
  chosenFacility;
  auditPlanForm: FormGroup;
  submitted = false;
  auditPlans: Array<AuditedArea>;
  groupedAuditPlans: Array<Array<AuditedArea>>;
  openedDropdownId: Array<number> = [];
  editedAuditedArea: AuditedArea;
  storage = StoragesNamesModule.auditCreation;
  auditorsList: Array<Employee>;
  notify = {
    error: {
      connection: '',
      permissions: '',
      createAuditedArea: '',
      createAuditedAreaPermissions: '',
      updateAuditedArea: '',
      updateAuditedAreaPermissions: '',
      removeAuditedArea: '',
      removeAuditedAreaPermissions: '',
      updateAuditState: '',
      updateAuditStatePermissions: ''
    },
    success: {
      createAuditedAreaTitle: '',
      createAuditedAreaText: '',
      updateAuditedAreaTitle: '',
      updateAuditedAreaText: '',
      removeAuditedAreaTitle: '',
      removeAuditedAreaText: '',
      updateAuditStateTitle: '',
      updateAuditStateText: ''
    }
  };
  destroy$ = new Subject<void>();

  constructor(
    private auditedAreaService: AuditedAreaService,
    private auditService: AuditService,
    private changeDetector: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private storageService: StorageModuleService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.init.emit();
    this.fetchTranslation();
    this.generateAuditorsList();
    this.initForm();
    this.updateAuditPlans();
    this.setFormStartDate();
    this.auditObservable.pipe(takeUntil(this.destroy$)).subscribe(audit => {
      this.audit = audit;
      this.generateAuditorsList();
      this.updateAuditPlans();
      this.resetForm();
      this.setFormStartDate();
    });
    this.storageService.storesChangedObservable.subscribe(changes => {
      if (changes.indexOf(this.storage) !== -1) {
        this.storedAudit = this.storageService.getStorage(this.storage);
        this.setFormStartDate();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  initForm(): void {
    this.auditPlanForm = this.formBuilder.group(
      {
        startTime: [this.getControlValue('startTime') || '', Validators.required],
        endTime: [this.getControlValue('endTime') || '', Validators.required],
        auditedArea: [this.getControlValue('auditedArea') || '', Validators.required],
        location: [this.getControlValue('location') || ''],
        auditor: [this.getControlValue('employees', 'auditor') || [], Validators.required],
        auditees: [this.getControlValue('employees', 'auditee') || [], Validators.required]
      },
      { validator: DateValidator.isCorrectDateOrder('startTime', 'endTime') }
    );

    this.setControlDisabledState('auditor', this.isAuditorControlDisable());

    this.changeDetector.detectChanges();
  }

  setForm(auditedArea: AuditedArea): void {
    this.setControlValue(auditedArea.startTime, 'startTime');
    this.setControlValue(auditedArea.endTime, 'endTime');
    this.setControlValue(auditedArea.facility, 'auditedArea');
    this.setControlValue(auditedArea.location, 'location');
    this.setControlValue(auditedArea.employees.auditor, 'auditor');
    this.setControlValue(auditedArea.employees.auditee, 'auditees');

    this.setControlDisabledState('auditor', this.isAuditorControlDisable());

    this.auditPlanForm.updateValueAndValidity();

    this.changeDetector.detectChanges();
  }

  generateAuditorsList(): void {
    if (this.audit && this.audit.employees) {
      this.auditorsList = [...this.audit.employees.leadAuditor, ...this.audit.employees.coAuditor];
    }
  }

  resetForm(): void {
    this.setControlValue(this.getControlValue('startTime') || '', 'startTime');
    this.setControlValue(this.getControlValue('endTime') || '', 'endTime');
    this.setControlValue(this.getControlValue('auditedArea') || '', 'auditedArea');
    this.setControlValue(this.getControlValue('location') || '', 'location');
    this.setControlValue(this.getControlValue('employees', 'auditor') || [], 'auditor');
    this.setControlValue(this.getControlValue('employees', 'auditee') || [], 'auditees');

    this.setControlDisabledState('auditor', this.isAuditorControlDisable());

    this.submitted = false;

    this.auditPlanForm.updateValueAndValidity();

    this.changeDetector.detectChanges();
  }

  getControlValue(control: string, variable?: string): any {
    switch (control) {
      case 'startTime':
        return this.getFormStartDate();
      case 'endTime':
        const startTime = this.getFormStartDate();
        if (startTime) {
          return moment(startTime).add('1', 'hour');
        }
        break;
      case 'employees':
        if (variable === 'auditor') {
          if (this.auditorsList?.length === 1) {
            return this.auditorsList;
          }
        }
        break;
    }
  }

  setControlValue(data: any, key: string): void {
    this.auditPlanForm.controls[key].setValue(data);
  }

  setControlDisabledState(key: string, disabled: boolean): void {
    if (disabled) {
      this.auditPlanForm.get(key).disable();
    } else {
      this.auditPlanForm.get(key).enable();
    }
  }

  isAuditorControlDisable(): boolean {
    const auditorsInForm = this.auditPlanForm?.get('auditor').value;
    const auditorsInAudit = this.getControlValue('employees', 'auditor');
    return (
      auditorsInForm?.length === 1 && auditorsInAudit?.length === 1 && auditorsInForm[0].id === auditorsInAudit[0].id
    );
  }

  getFormStartDate(): moment.Moment {
    let lastStartTime: any;
    if (this.auditPlans && this.auditPlans.length > 0) {
      lastStartTime = this.auditPlans[this.auditPlans.length - 1].endTime;
    } else if (
      this.storedAudit &&
      this.storedAudit.stepsData[1].formData &&
      this.storedAudit.stepsData[1].formData.startTime
    ) {
      lastStartTime = this.storedAudit.stepsData[1].formData.endTime;
    } else {
      return;
    }
    return lastStartTime;
  }

  setFormStartDate(): void {
    const lastStartTime = this.getFormStartDate();
    if (lastStartTime) {
      this.applyStartTime(lastStartTime);
    }
  }

  applyStartTime(momentDate: moment.Moment): void {
    const previousStartTimeString = this.auditPlanForm.controls['startTime'].value;
    const previousStartTime = previousStartTimeString && moment(previousStartTimeString);

    const startTimeString = moment(momentDate).format('YYYY-MM-DD HH:mm'); // ISO format
    this.auditPlanForm.controls['startTime'].setValue(startTimeString);

    this.applyPrefilledEndTime(momentDate, previousStartTime);

    if (this.startDatePicker) {
      this.startDatePicker.closeDropdown();
    }
  }

  applyPrefilledEndTime(currentStartTime: moment.Moment, previousStartTime: moment.Moment): void {
    const endTimeString = this.auditPlanForm.controls['endTime'].value;
    const endTime = endTimeString && moment(endTimeString);
    if (!endTime || (endTime && previousStartTime)) {
      const timeDifference = endTime && previousStartTime ? endTime.diff(previousStartTime, 'minutes') : 60;
      const prefilledEndDateMoment = moment(currentStartTime).add(timeDifference, 'minutes');
      this.applyEndTime(prefilledEndDateMoment);
    }
  }

  applyEndTime(momentDate: moment.Moment): void {
    const date = moment(momentDate).format('YYYY-MM-DD HH:mm'); // ISO format
    this.auditPlanForm.controls['endTime'].setValue(date);
    if (this.endDatePicker) {
      this.endDatePicker.closeDropdown();
    }
  }

  isDateEqual(date1: moment.Moment, date2: moment.Moment): boolean {
    const groupDate = moment(date1).format('YYYY-MM-DD');
    const auditDate = moment(date2).format('YYYY-MM-DD');
    return groupDate === auditDate;
  }

  groupByDate(auditedAreas: Array<AuditedArea>): Array<Array<AuditedArea>> {
    const groupsObject = values(
      groupBy(auditedAreas, (auditedArea: AuditedArea) => {
        return moment(auditedArea.startTime).format('YYYY-MM-DD'); // ISO format;
      })
    );
    return groupsObject;
  }

  updateAuditPlans(): void {
    this.auditPlans = this.audit ? this.audit.auditedAreas || [] : [];
    this.sortByDate(this.auditPlans);
    this.groupedAuditPlans = this.groupByDate(this.auditPlans);
  }

  removeAuditedArea(auditedArea: AuditedArea): void {
    this.auditedAreaService
      .removeAuditedArea(this.audit.id, auditedArea)
      .then(() => {
        this.spliceAuditedArea(auditedArea);
        this.updateAuditPlans();
        this.notificationsService.success(
          this.notify.success.removeAuditedAreaTitle,
          this.notify.success.removeAuditedAreaText
        );
      })
      .catch(error => {
        switch (error.status) {
          case 403:
            this.notificationsService.error(
              this.notify.error.permissions,
              this.notify.error.removeAuditedAreaPermissions
            );
            break;
          default:
            this.notificationsService.error(this.notify.error.connection, this.notify.error.removeAuditedArea);
        }
        throw error;
      });
  }

  hideAuditedArea(auditedArea: AuditedArea): void {
    this.spliceAuditedArea(auditedArea);
    this.updateAuditPlans();
  }

  editAuditedArea(auditedArea: AuditedArea): void {
    this.editedAuditedArea = auditedArea;
    this.setForm(auditedArea);
    this.hideAuditedArea(auditedArea);
  }

  cancelEdition(): void {
    this.appendAuditedArea(this.editedAuditedArea);
    this.editedAuditedArea = undefined;
    this.updateAuditPlans();
    this.resetForm();
  }

  appendAuditedArea(auditedArea: AuditedArea): void {
    this.audit.auditedAreas.push(auditedArea);
  }

  replaceAuditedArea(auditedArea: AuditedArea): void {
    this.spliceAuditedArea(auditedArea);
    this.appendAuditedArea(auditedArea);
  }

  spliceAuditedArea(auditedArea: AuditedArea): void {
    const index = findIndex(this.audit.auditedAreas, { id: auditedArea.id });
    if (index !== -1) {
      this.audit.auditedAreas.splice(index, 1);
    }
  }

  sortByDate(collection: any, isNested?: boolean): void {
    collection.sort((a, b) => {
      const objectA = isNested ? a[0] : a;
      const objectB = isNested ? b[0] : b;
      const dateA = moment(objectA.startTime, 'YYYY-MM-DD HH:mm');
      const dateB = moment(objectB.startTime, 'YYYY-MM-DD HH:mm');
      if (dateA.isBefore(dateB)) {
        return -1;
      }
      if (dateA.isAfter(dateB)) {
        return 1;
      }
      return 0;
    });
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return field.invalid && this.submitted;
  }

  chooseFacility(selectedFacility: FacilityFilter): void {
    this.auditPlanForm.controls['auditedArea'].setValue(selectedFacility);
    if (this.auditedAreaElement) {
      this.auditedAreaElement.nativeElement.focus();
    }
  }

  keyDownFirstDatePicker(event: any): void {
    if (this.startTimePicker) {
      this.startTimePicker.keydown(event);
    }
  }

  keyDownSecondDatePicker(event: any): void {
    if (this.endTimePicker) {
      this.endTimePicker.keydown(event);
    }
  }

  keyDownFacilityControl(event: any): void {
    if (this.selectFacility) {
      this.selectFacility.keydown(event);
    } else if (event.key === 'Enter') {
      this.modalService.open('facilityModal');
    }
  }

  createAuditedArea(auditedArea: AuditedArea): Promise<AuditedArea> {
    return this.auditedAreaService
      .createAuditedArea(this.audit.id, auditedArea)
      .then(auditedAreaResponse => {
        this.appendAuditedArea(auditedAreaResponse);
        this.notificationsService.success(
          this.notify.success.createAuditedAreaTitle,
          this.notify.success.createAuditedAreaText
        );
        return auditedAreaResponse;
      })
      .catch(error => {
        switch (error.status) {
          case 403:
            this.notificationsService.error(
              this.notify.error.permissions,
              this.notify.error.createAuditedAreaPermissions
            );
            break;
          default:
            this.notificationsService.error(this.notify.error.connection, this.notify.error.createAuditedArea);
        }
        throw error;
      });
  }

  updateAuditedArea(auditedArea: AuditedArea): Promise<AuditedArea> {
    return this.auditedAreaService
      .updateAuditedArea(auditedArea, this.editedAuditedArea)
      .then(auditedAreaResponse => {
        this.editedAuditedArea = undefined;
        this.replaceAuditedArea(auditedAreaResponse);
        this.notificationsService.success(
          this.notify.success.updateAuditedAreaTitle,
          this.notify.success.updateAuditedAreaText
        );
        return auditedAreaResponse;
      })
      .catch(error => {
        switch (error.status) {
          case 403:
            this.notificationsService.error(
              this.notify.error.permissions,
              this.notify.error.updateAuditedAreaPermissions
            );
            break;
          default:
            this.notificationsService.error(this.notify.error.connection, this.notify.error.updateAuditedArea);
        }
        throw error;
      });
  }

  saveAuditedArea(formData: any): Promise<AuditedArea> {
    const convertAuditedArea = (): AuditedArea => {
      const auditedArea = Object.assign({}, formData);
      auditedArea.id = this.editedAuditedArea ? this.editedAuditedArea.id : undefined;
      auditedArea.facility = auditedArea.auditedArea;
      auditedArea.employees = {
        auditor: formData.auditor,
        auditee: formData.auditees
      };
      auditedArea.startTime = moment(formData.startTime, 'YYYY-MM-DD HH:mm').format();
      auditedArea.endTime = moment(formData.endTime, 'YYYY-MM-DD HH:mm').format();
      return new AuditedArea(auditedArea);
    };

    const auditedArea = convertAuditedArea();
    return this.editedAuditedArea ? this.updateAuditedArea(auditedArea) : this.createAuditedArea(auditedArea);
  }

  updateAuditState(): Promise<Audit> {
    return this.auditService
      .updateAuditState(this.audit, 'in_progress')
      .then(audit => {
        this.audit = audit;
        this.generateAuditorsList();
        this.notificationsService.success(
          this.notify.success.updateAuditStateTitle,
          this.notify.success.updateAuditStateText
        );
        return audit;
      })
      .catch(error => {
        switch (error.status) {
          case 403:
            this.notificationsService.error(
              this.notify.error.permissions,
              this.notify.error.updateAuditStatePermissions
            );
            break;
          default:
            this.notificationsService.error(this.notify.error.connection, this.notify.error.updateAuditState);
        }
        throw error;
      });
  }

  async submitAuditedArea(form: FormGroup): Promise<void> {
    this.submitted = true;
    if (form.valid) {
      await this.saveAuditedArea(form.getRawValue())
        .then(auditedArea => {
          this.updateAuditPlans();
          this.resetForm();
          this.updateAudit.emit(this.audit);
          this.updateStorage.emit({
            stepId: 1,
            stepData: auditedArea,
            stepValid: this.auditPlans && this.auditPlans.length > 0
          });
        })
        .catch(() => {});
    }
  }

  submitAudit(): void {
    this.updateAuditState()
      .then(() => {
        this.updateAudit.emit(this.audit);
        this.submitStep.emit({
          goToStepId: 2
        });
      })
      .catch(() => {});
  }

  openFacilitiesModal(): void {
    this.modalService.open('facilityModal');
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ERROR.PERMISSIONS',
        'AUDIT_CREATION.ERROR.CREATE_AUDITED_AREA',
        'AUDIT_CREATION.ERROR.CREATE_AUDITED_AREA_PERMISSIONS',
        'AUDIT_CREATION.ERROR.UPDATE_AUDITED_AREA',
        'AUDIT_CREATION.ERROR.UPDATE_AUDITED_AREA_PERMISSIONS',
        'AUDIT_CREATION.ERROR.REMOVE_AUDITED_AREA',
        'AUDIT_CREATION.ERROR.REMOVE_AUDITED_AREA_PERMISSIONS',
        'AUDIT_CREATION.ERROR.UPDATE_AUDIT_STATE',
        'AUDIT_CREATION.ERROR.UPDATE_AUDIT_STATE_PERMISSIONS',
        'AUDIT_CREATION.SUCCESS.CREATE_AUDITED_AREA_TITLE',
        'AUDIT_CREATION.SUCCESS.CREATE_AUDITED_AREA_TEXT',
        'AUDIT_CREATION.SUCCESS.UPDATE_AUDITED_AREA_TITLE',
        'AUDIT_CREATION.SUCCESS.UPDATE_AUDITED_AREA_TEXT',
        'AUDIT_CREATION.SUCCESS.REMOVE_AUDITED_AREA_TITLE',
        'AUDIT_CREATION.SUCCESS.REMOVE_AUDITED_AREA_TEXT',
        'AUDIT_CREATION.SUCCESS.UPDATE_AUDIT_STATE_TITLE',
        'AUDIT_CREATION.SUCCESS.UPDATE_AUDIT_STATE_TEXT'
      ])
      .toPromise();
    this.notify.error.connection = translation['GLOBAL.ERROR.CONNECTION'];
    this.notify.error.permissions = translation['GLOBAL.ERROR.PERMISSIONS'];
    this.notify.error.createAuditedArea = translation['AUDIT_CREATION.ERROR.CREATE_AUDITED_AREA'];
    this.notify.error.createAuditedAreaPermissions =
      translation['AUDIT_CREATION.ERROR.CREATE_AUDITED_AREA_PERMISSIONS'];
    this.notify.error.updateAuditedArea = translation['AUDIT_CREATION.ERROR.UPDATE_AUDITED_AREA'];
    this.notify.error.updateAuditedAreaPermissions =
      translation['AUDIT_CREATION.ERROR.UPDATE_AUDITED_AREA_PERMISSIONS'];
    this.notify.error.removeAuditedArea = translation['AUDIT_CREATION.ERROR.REMOVE_AUDITED_AREA'];
    this.notify.error.removeAuditedAreaPermissions =
      translation['AUDIT_CREATION.ERROR.REMOVE_AUDITED_AREA_PERMISSIONS'];
    this.notify.error.updateAuditState = translation['AUDIT_CREATION.ERROR.UPDATE_AUDIT_STATE'];
    this.notify.error.updateAuditStatePermissions = translation['AUDIT_CREATION.ERROR.UPDATE_AUDIT_STATE_PERMISSIONS'];
    this.notify.success.createAuditedAreaTitle = translation['AUDIT_CREATION.SUCCESS.CREATE_AUDITED_AREA_TITLE'];
    this.notify.success.createAuditedAreaText = translation['AUDIT_CREATION.SUCCESS.CREATE_AUDITED_AREA_TEXT'];
    this.notify.success.updateAuditedAreaTitle = translation['AUDIT_CREATION.SUCCESS.UPDATE_AUDITED_AREA_TITLE'];
    this.notify.success.updateAuditedAreaText = translation['AUDIT_CREATION.SUCCESS.UPDATE_AUDITED_AREA_TEXT'];
    this.notify.success.removeAuditedAreaTitle = translation['AUDIT_CREATION.SUCCESS.REMOVE_AUDITED_AREA_TITLE'];
    this.notify.success.removeAuditedAreaText = translation['AUDIT_CREATION.SUCCESS.REMOVE_AUDITED_AREA_TEXT'];
    this.notify.success.updateAuditStateTitle = translation['AUDIT_CREATION.SUCCESS.UPDATE_AUDIT_STATE_TITLE'];
    this.notify.success.updateAuditStateText = translation['AUDIT_CREATION.SUCCESS.UPDATE_AUDIT_STATE_TEXT'];
  }
}

import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as dataURLtoBlob from 'blueimp-canvas-to-blob';
import { Subscription } from 'rxjs';

import {
  Attachment,
  EmailValidator,
  Employee,
  FileService,
  I18nService,
  isPasswordConfirmed,
  Language,
  LanguageCode,
  ModalService
} from 'gutwin-shared';

import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'gw-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  user: Employee;
  languages: Array<Language>;
  userForm: FormGroup;
  submitted: boolean;
  saving: boolean;
  uploadedImage = { preview: '' };
  avatarSourceFile: File;
  translateSubscription: Subscription;
  passwordChanging = false;
  notify = {
    error: {
      connectionTitle: '',
      updateProfileText: ''
    },
    success: {
      updateProfileTitle: '',
      updateProfileText: ''
    }
  };

  constructor(
    public location: Location,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private i18nService: I18nService,
    private userService: UserService,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private fileService: FileService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.user = this.route.snapshot.data['user'];
    this.languages = this.generateLanguages();
    this.initForm();

    this.translateSubscription = this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'USER_PROFILE.FORM.ERROR.UPDATE_PROFILE_TEXT',
        'USER_PROFILE.FORM.SUCCESS.UPDATE_PROFILE_TITLE',
        'USER_PROFILE.FORM.SUCCESS.UPDATE_PROFILE_TEXT'
      ])
      .subscribe((translation: any) => {
        this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.updateProfileText = translation['USER_PROFILE.FORM.ERROR.UPDATE_PROFILE_TEXT'];
        this.notify.success.updateProfileTitle = translation['USER_PROFILE.FORM.SUCCESS.UPDATE_PROFILE_TITLE'];
        this.notify.success.updateProfileText = translation['USER_PROFILE.FORM.SUCCESS.UPDATE_PROFILE_TEXT'];
      });
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
  }

  initForm(): void {
    this.userForm = this.formBuilder.group(
      {
        id: [this.user.id || '', Validators.required],
        access: [this.user.access || '', Validators.required],
        name: [this.getControlValue('name') || '', Validators.required],
        lastname: [this.getControlValue('lastname') || '', Validators.required],
        email: [this.getControlValue('email') || '', [Validators.required, EmailValidator.extendedEmailValidator]],
        newPassword: ['', [Validators.minLength(8), Validators.maxLength(72)]],
        newPasswordConfirmed: ['', [Validators.minLength(8), Validators.maxLength(72)]],
        language: [this.getControlValue('language') || ''],
        avatar: [this.getControlValue('avatar')]
      },
      { validator: isPasswordConfirmed('newPassword', 'newPasswordConfirmed') }
    );
    this.submitted = false;
  }

  getControlValue(control: string, variable?: string): any {
    if (this.user) {
      if (variable) {
        if (this.user[control]) {
          return this.user[control][variable];
        }
      } else {
        return this.user[control];
      }
    }
  }

  setControlValue(data: any, key: string): void {
    this.userForm.controls[key].setValue(data);
  }

  isFieldInvalid(field: FormControl): boolean {
    return !field.valid && this.submitted;
  }

  isEmailInvalid(field: FormControl): boolean {
    return field.errors && field.errors.email;
  }

  isNewPasswordFieldInvalid(form: FormGroup, field: FormControl): boolean {
    return (
      this.submitted &&
      ((form.errors && !!form.errors.passwordNotConfirmed) ||
        (field.errors && (field.errors.minlength || field.errors.maxlength)))
    );
  }

  isFieldEmpty(field: FormControl): boolean {
    return field.errors && field.errors.required;
  }

  togglePasswordChanging(): void {
    if (this.passwordChanging) {
      this.userForm.controls['newPassword'].setValue('');
      this.userForm.controls['newPasswordConfirmed'].setValue('');
    }
    this.passwordChanging = !this.passwordChanging;
  }

  generateLanguages(): Array<Language> {
    const languages = new Array<Language>();
    this.translateService.langs.forEach(lang => {
      languages.push(this.i18nService.getLanguage(lang as LanguageCode));
    });
    return languages;
  }

  attachFiles(event: Event): void {
    this.fileService.attachFiles(event).then((base64: Array<string>) => {
      this.modalService.open('cropAvatarModal', base64[0]);
    });
    this.avatarSourceFile = event.target['files'][0];
  }

  acceptImage(image: string) {
    this.uploadedImage.preview = image;
    const imageBlob = dataURLtoBlob(image);
    imageBlob.name = this.avatarSourceFile.name;
    this.setControlValue(new Attachment({ file: imageBlob }), 'avatar');
    this.modalService.close('cropAvatarModal');
  }

  removeAvatar(): void {
    this.setControlValue(null, 'avatar');
    this.uploadedImage.preview = '';
  }

  resetForm(): void {
    this.initForm();
    this.uploadedImage = { preview: '' };
  }

  checkPassword(userForm: FormGroup): FormGroup {
    if (
      userForm.value.newPassword &&
      userForm.value.newPasswordConfirmed &&
      userForm.value.newPassword === userForm.value.newPasswordConfirmed
    ) {
      userForm.value.password = userForm.value.newPassword;
    }
    delete userForm.value.newPassword;
    delete userForm.value.newPasswordConfirmed;
    return userForm;
  }

  submitUserForm(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      this.saving = true;
      form = this.checkPassword(form);

      this.userService
        .updateEmployee(form.value)
        .then(async () => {
          this.saving = false;
          this.user = await this.userService.getCurrentUser(true);
          this.initForm();
          this.notificationsService.success(
            this.notify.success.updateProfileTitle,
            this.notify.success.updateProfileText
          );
        })
        .catch(() => {
          this.saving = false;
          this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.updateProfileText);
        });
    }
  }

  keyDownAvatarControl(event: any, fileInput: any): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      fileInput.click(event);
    }
  }
}

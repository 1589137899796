import { Audit } from '@gutwin-audit/shared/models/audit.model';

export class Template {
  name: string;
  audit: Audit;

  constructor(template: Template) {
    this.name = template.name;
    this.audit = template.audit;
  }
}

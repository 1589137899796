export class AuditType {
  id: string;
  title: string;
  description: string;
  archived: boolean;

  constructor(auditType: any) {
    this.id = auditType.id;
    this.title = auditType.title;
    this.description = auditType.description;
    this.archived = auditType.archived;
  }
}

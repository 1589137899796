import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

import { Language } from '../models/language.model';

import { LanguageCode } from '../enums/language-code.enum';

import { StorageService, StoragesNames } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class I18nService {
  languages = [LanguageCode.en, LanguageCode.de];

  constructor(private translateService: TranslateService, private storageService: StorageService) {}

  setLanguages(languages: Array<LanguageCode>): void {
    this.languages = languages;
  }

  getLanguageName(lang: LanguageCode): string {
    switch (lang) {
      case LanguageCode.en:
        return 'English';
      case LanguageCode.de:
        return 'Deutsch';
      case LanguageCode.at:
        return 'Austrian';
      default:
        return '';
    }
  }

  getLanguages(): Array<Language> {
    const languages = new Array<Language>();
    this.translateService.langs.forEach(lang => {
      languages.push(this.getLanguage(lang as LanguageCode));
    });
    return languages;
  }

  getLanguage(lang: LanguageCode): Language {
    const language = {
      id: lang,
      name: this.getLanguageName(lang)
    };
    return new Language(language);
  }

  getCurrentLanguage(): LanguageCode {
    const language = this.storageService.getLocalStorage(StoragesNames.language);
    return language || this.getBrowserLanguage() || this.translateService.getDefaultLang();
  }

  getDefaultLanguage(currentLanguage = this.storageService.getLocalStorage(StoragesNames.language)): LanguageCode {
    const selectedLanguage = currentLanguage || this.getBrowserLanguage();
    switch (selectedLanguage) {
      case LanguageCode.at:
      case LanguageCode.de:
        return LanguageCode.de;
      case LanguageCode.en:
        return LanguageCode.en;
      default:
        return LanguageCode.en;
    }
  }

  setLanguage(lang: LanguageCode): void {
    this.translateService.setDefaultLang(this.getDefaultLanguage(lang));
    this.translateService.use(lang);
    this.storageService.setLocalStorage(StoragesNames.language, lang);
    moment.locale(lang);
  }

  getBrowserLanguage(): LanguageCode {
    const navigatorLanguage = navigator.language?.toLowerCase();
    switch (true) {
      case /^de-at\b/.test(navigatorLanguage):
        return this.hasLanguage(LanguageCode.at) ? LanguageCode.at : LanguageCode.de;
      case /^de\b/.test(navigatorLanguage):
        return LanguageCode.de;
      case /^en\b/.test(navigatorLanguage):
        return LanguageCode.en;
    }
  }

  hasLanguage(lang: LanguageCode): boolean {
    return this.languages.includes(lang);
  }

  convertLanguageCode(language: string): LanguageCode {
    switch (language) {
      case 'en-US':
      case 'en-EN':
        return LanguageCode.en;
      case 'de-DE':
        return LanguageCode.de;
      case 'de-AT':
        return LanguageCode.at;
    }
  }
}

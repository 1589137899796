import { AbstractControl, FormGroup } from '@angular/forms';

// @dynamic
export class EmailValidator {
  static extendedEmailValidator(control: AbstractControl): { [key: string]: any } {
    return !control.value ? null : control.value.match(/^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/gi) ? null : { email: true };
  }

  static requiredEmailValidator(): { [key: string]: any } {
    return (form: FormGroup): {[key: string]: any} => {
      if (form.controls['access'].value && (!form.controls['email'].value || form.controls['email'].value.length === 0)) {
        return { 'requiredEmail': true };
      } else {
        return null;
      }
    };
  }
}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gw-audit-creation-header',
  templateUrl: './audit-creation-header.component.html',
  styleUrls: ['./audit-creation-header.component.scss']
})
export class AuditCreationHeaderComponent implements OnInit {
  @Output() goToStep = new EventEmitter<any>();
  @Input() title: string;
  @Input() heading: string;
  @Input() id: number;
  @Input() storedAudit: any;

  constructor() { }

  ngOnInit() { }

  badgeStatus() {
    return this.id < this.storedAudit.currentStep || this.storedAudit.stepsData[this.id].valid;
  }

  isPreviousStep(): boolean {
    return this.id < this.storedAudit.currentStep;
  }

  isStepToGoValid(): boolean {
    return this.storedAudit.stepsData[this.id].valid;
  }

  isCurrentStepValid(): boolean {
    return this.storedAudit.stepsData[this.storedAudit.currentStep]
      && this.storedAudit.stepsData[this.storedAudit.currentStep].valid;
  }

  isStepBeforeStepToGoValid(): boolean {
    return this.id !== 0 && this.storedAudit.stepsData[this.id - 1].valid;
  }

  allowedToGoToStep(): boolean {
    return this.isPreviousStep()
    || this.isCurrentStepValid()
    && (this.isStepToGoValid() || this.isStepBeforeStepToGoValid());
  }

  goTo(): void {
    if (this.allowedToGoToStep()) {
      this.goToStep.emit({'goToStepId': this.id});
    }
  }
}

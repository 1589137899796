import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { PageStatusComponentData } from '../../interfaces/page-status-component.interface';

import { BodyFreezerService } from '../../services/body-freezer.service';

@Component({
  selector: 'gw-page-status',
  templateUrl: './page-status.component.html',
  styleUrls: ['./page-status.component.scss']
})
export class PageStatusComponent implements OnInit, OnDestroy, PageStatusComponentData {
  @Input() status: string;
  @Input() message?: string;
  @Input() description?: string;
  @Input() buttonLabel?: string;
  @Input() buttonUrl?: string;
  @Input() isButtonUrlExternal?: boolean;
  @Input() coverPage = false;

  constructor(private bodyFreezer: BodyFreezerService) {}

  ngOnInit(): void {
    if (this.coverPage) {
      this.bodyFreezer.freezeBody();
    }
  }

  ngOnDestroy(): void {
    if (this.coverPage) {
      this.bodyFreezer.unfreezeBody();
    }
  }
}

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

// Gutwin Shared Library
import { GutwinSharedModule } from 'gutwin-shared';

// Components
import { AddFindingComponent } from './components/add-finding/add-finding.component';
import { AddQuestionComponent } from './components/add-question/add-question.component';
import { AddSectionComponent } from './components/add-section/add-section.component';
import { AreaFilterComponent } from './components/area-filter/area-filter.component';
import { AuditTopBarComponent } from './components/audit-top-bar/audit-top-bar.component';
import { AuditorFilterComponent } from './components/auditor-filter/auditor-filter.component';
import { FacilityTreeViewComponent } from './components/facility-tree-view/facility-tree-view.component';
import { FindingComponent } from './components/finding/finding.component';
import { PieComponent } from './components/pie/pie.component';
import { ProgressBarSharedComponent } from './components/progress-bar-shared/progress-bar-shared.component';
import { ProgressGroupsComponent } from './components/progress-bar-shared/progress-groups/progress-groups.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SelectFilterComponent } from './components/select-filter/select-filter.component';
import { TopBarAuditorComponent } from './components/top-bar-auditor/top-bar-auditor.component';

// Directives
import { DisableControlDirective } from './directives/disable-control.directive';
import { MaxDirective } from './directives/max.directive';
import { MinDirective } from './directives/min.directive';

// Pipes
import { KeysPipe } from './pipes/keys.pipe';

import { DashboardChosenFiltersService } from '@gutwin-audit/shared/services/dashboard-chosen-filters.service';
// Services
import { DashboardService } from '@gutwin-audit/shared/services/dashboard.service';
import { AttachmentService } from './services/attachment.service';
import { AuditSummaryService } from './services/audit-summary.service';
import { AuditTypeService } from './services/audit-type.service';
import { AuditConvertService } from './services/audit.convert.service';
import { AuditOfflineService } from './services/audit.offline.service';
import { AuditService } from './services/audit.service';
import { AuditedAreaService } from './services/audited-area.service';
import { AuthenticationService } from './services/authentication.service';
import { ChartService } from './services/chart.service';
import { CompanyService } from './services/company.service';
import { EmailService } from './services/email.service';
import { FacilityService } from './services/facility.service';
import { FilterModuleService } from './services/filter-module.service';
import { FindingOfflineService } from './services/finding.offline.service';
import { FindingService } from './services/finding.service';
import { PageStatusService } from './services/page-status.service';
import { PermissionCategoryService } from './services/permission-category.service';
import { PermissionService } from './services/permission.service';
import { ProgressService } from './services/progress.service';
import { QuestionMediaService } from './services/question-media.service';
import { QuestionService } from './services/question.service';
import { QuestionnaireService } from './services/questionnaire.service';
import { RatingScalesService } from './services/raiting-scales.service';
import { RatingsService } from './services/ratings.service';
import { ReportsService } from './services/reports.service';
import { RoleService } from './services/role.service';
import { SectionService } from './services/section.service';
import { TemplateService } from './services/template.service';
import { UserService } from './services/user.service';
import { WebSocketService } from './services/web-socket.service';

// Guards
import { AdminGuard } from './guards/admin.guard';
import { CreateAuditGuard } from './guards/create-audit.guard';
import { LoginChildGuard } from './guards/login.child.guard';
import { LoginGuard } from './guards/login.guard';
import { LogoutGuard } from './guards/logout.guard';
import { OfflineChildGuard } from './guards/offline.child.guard';
import { OfflineGuard } from './guards/offline.guard';
import { LoginGuardSuperAdmin } from './guards/super-admin.guard';

// Resolvers
import { CurrentUserResolve } from './resolvers/current-user.resolve';
import { RatingScaleResolve } from './resolvers/rating-scale.resolve';

export const SharedModuleDependencides = {
  imports: [FormsModule, ReactiveFormsModule, GutwinSharedModule],
  declarations: [
    AddFindingComponent,
    AddQuestionComponent,
    AddSectionComponent,
    AreaFilterComponent,
    AuditorFilterComponent,
    AuditTopBarComponent,
    FacilityTreeViewComponent,
    FindingComponent,
    PieComponent,
    ProgressBarComponent,
    ProgressBarSharedComponent,
    ProgressGroupsComponent,
    SelectFilterComponent,
    TopBarAuditorComponent,
    DisableControlDirective,
    MinDirective,
    MaxDirective,
    KeysPipe
  ],
  providers: [
    AdminGuard,
    CreateAuditGuard,
    LoginGuard,
    LoginChildGuard,
    LogoutGuard,
    LoginGuardSuperAdmin,
    OfflineGuard,
    OfflineChildGuard,
    AttachmentService,
    AuditService,
    AuditConvertService,
    AuditedAreaService,
    AuditOfflineService,
    AuditSummaryService,
    AuditTypeService,
    AuthenticationService,
    ChartService,
    CompanyService,
    EmailService,
    FacilityService,
    FilterModuleService,
    FindingService,
    FindingOfflineService,
    PageStatusService,
    PermissionService,
    PermissionCategoryService,
    ProgressService,
    RatingsService,
    RatingScalesService,
    RoleService,
    ReportsService,
    QuestionMediaService,
    QuestionnaireService,
    QuestionService,
    SectionService,
    TemplateService,
    UserService,
    WebSocketService,
    RatingScaleResolve,
    CurrentUserResolve,
    DashboardService,
    DashboardChosenFiltersService
  ]
};

@NgModule({
  imports: [CommonModule, RouterModule, TranslateModule.forChild(), SharedModuleDependencides.imports],
  declarations: SharedModuleDependencides.declarations,
  exports: [TranslateModule, SharedModuleDependencides.imports, SharedModuleDependencides.declarations]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: SharedModuleDependencides.providers
    };
  }
}

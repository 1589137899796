
<div class="finding" [class.finding--3-columns]="withAssign || withDetails" [class.finding--link]="detailsLink" [class.finding--offline]="finding.offlineRequest" [class.finding--disabled]="toMove">
  <div class="finding__section finding__section--main">
    <div class="finding__aside">
      <button class="finding-aside__image" *ngIf="image" type="button" [ngStyle]="image.styles" (click)="showLightbox()"></button>
      <div class="finding-aside__image" *ngIf="!image"></div>
      <div class="finding-aside__files">{{ 'GLOBAL.FINDING.FILES' | translate }}<small>({{ finding.attachments?.length + finding.offlineRequest?.attachments?.length }})</small></div>
    </div>
    <ng-template #findingContentTemplate="">
      <p class="finding__item--type finding__item--light" *ngIf="finding.getLatestValue('relationships.findingType')">{{ finding.getLatestValue('relationships.findingType.name') }}
        <ng-container *ngIf="finding.getLatestValue('relationships.question')">&nbsp;– {{ finding.getLatestValue('relationships.question') }}</ng-container>
      </p>
      <p class="finding__item--problem">{{ finding.getLatestValue('problem') }}</p>
      <p class="finding__item--cause" *ngIf="finding.getLatestValue('cause')?.length"><i class="gw-questionmark finding-icon"></i>{{ finding.getLatestValue('cause') }}</p>
      <p class="finding__item--solution" *ngIf="finding.getLatestValue('solution')?.length"><i class="gw-info finding-icon"></i>{{ finding.getLatestValue('solution') }}</p>
    </ng-template>
    <div class="finding__content" *ngIf="detailsLink" [routerLink]="['/finding', finding.id]">
      <ng-container *ngTemplateOutlet="findingContentTemplate"></ng-container>
    </div>
    <div class="finding__content" *ngIf="!detailsLink">
      <ng-container *ngTemplateOutlet="findingContentTemplate"></ng-container>
    </div>
    <div class="finding__offline" *ngIf="finding.offlineRequest &amp;&amp; !withAssign">
      <div class="offline-icon finding__offline-icon"><i class="gw-cloud--off offline-icon__icon"></i></div>
      <div class="offline-icon finding__offline-icon" *ngIf="toMove"><i class="gw-arrow--right offline-icon__icon"></i></div>
    </div>
  </div>
  <div class="finding__section finding__section--additional" *ngIf="withDetails">
    <ng-template #findingDetailsTemplate="">
      <p class="finding-assign__section finding-assign__section--status" [ngClass]="'finding__status--' + finding.getLatestValue('status')">{{ findingStatusNames ? findingStatusNames[finding.getLatestValue('status')] : '' }}</p>
      <p class="finding-assign__section finding-assign__section--employee"><i class="gw-employee finding-icon"></i>
        <ng-container *ngIf="finding.getLatestValue('relationships.employee')">{{ finding.getLatestValue('relationships.employee').lastname }}, {{ finding.getLatestValue('relationships.employee').name }}</ng-container>
        <ng-container *ngIf="!finding.getLatestValue('relationships.employee')">-</ng-container>
      </p>
      <p class="finding-assign__section"><i class="gw-clock finding-icon"></i>{{ 'GLOBAL.FINDING.DUE_DATE' | translate }}:&nbsp;
        <ng-container *ngIf="finding.getLatestValue('deadline')">{{ finding.getLatestValue('deadline') | dateFormat: 'D MMMM YYYY' }}</ng-container>
        <ng-container *ngIf="!finding.getLatestValue('deadline')">-</ng-container>
      </p>
    </ng-template>
    <div class="finding__assign" *ngIf="detailsLink" [routerLink]="['/finding', finding.id]">
      <ng-container *ngTemplateOutlet="findingDetailsTemplate"></ng-container>
    </div>
    <div class="finding__assign" *ngIf="!detailsLink">
      <ng-container *ngTemplateOutlet="findingDetailsTemplate"></ng-container>
    </div>
  </div>
  <div class="finding__section finding__section--additional" *ngIf="withAssign">
    <ng-template #findingAssignTemplate="">
      <p class="finding-assign__section finding-assign__section--status" [ngClass]="'finding__status--' + finding.getLatestValue('status')">{{ findingStatusNames ? findingStatusNames[finding.getLatestValue('status')] : '' }}</p>
      <div class="finding-assign__section finding-assign__section--employee"><i class="gw-employee finding-icon finding-assign-section__element" [class.finding-icon--offline]="finding.offlineRequest?.changes?.employee &amp;&amp; !finding.temporaryFinding?.changes?.employee"></i>
        <gw-select class="finding-assign-section__element finding-assign-section__element--select" *ngIf="canManageFinding" [ngModel]="finding.getLatestValue('relationships.employee')" [white]="true" [small]="true" [search]="true" [placeholder]="'AUDIT_SUMMARY.FINDINGS.PLACEHOLDER.EMPLOYEE' | translate" [data]="employees" [display]="['lastname', 'name']" [splitter]="', '" [uniqueKey]="'id'" (ngModelChange)="setEmployee(finding, $event)"></gw-select>
        <div class="finding-assign-section__element finding-assign-section__element--text" *ngIf="!canManageFinding">
          <ng-container *ngIf="finding.getLatestValue('relationships.employee')">{{ finding.getLatestValue('relationships.employee').name }} {{ finding.getLatestValue('relationships.employee').lastname }}</ng-container>
          <ng-container *ngIf="!finding.getLatestValue('relationships.employee')">-</ng-container>
        </div>
        <ng-container *ngIf="finding.getLatestValue('relationships.employee') &amp;&amp; finding.getLatestValue('changes.employee') &amp;&amp; employeesAssigns">
          <p class="finding-assign-section__element finding-assign-section__element--amount">{{ employeesAssigns[finding.getLatestValue('relationships.employee').id] }}
            <ng-container *ngIf="employeesAssigns[finding.getLatestValue('relationships.employee').id] &gt; 1">&nbsp;{{ 'GLOBAL.FINDING.FINDINGS_ASSIGNED' | translate }}</ng-container>
            <ng-container *ngIf="employeesAssigns[finding.getLatestValue('relationships.employee').id] === 1">&nbsp;{{ 'GLOBAL.FINDING.FINDING_ASSIGNED' | translate }}</ng-container>
          </p>
          <button class="icon finding-assign-section__element finding-assign-section__element--remove--button" type="button" (click)="removeEmployee(finding)"><i class="gw-close"></i></button>
        </ng-container>
      </div>
      <div class="finding-assign__section finding-assign__section--deadline"><i class="gw-clock finding-icon finding-assign-section__element" [class.finding-icon--offline]="finding.offlineRequest?.changes?.deadline &amp;&amp; !finding.temporaryFinding?.changes?.deadline"></i>
        <div class="dropdown dropdown--single-date-picker finding-assign-section__element" gwSimpleDropdown="gwSimpleDropdown" #deadlineDropdown="simpleDropdown" [class.dropdown--opened]="deadlineDropdown.opened" [class.dropdown--closed]="!deadlineDropdown.opened &amp;&amp; !deadlineDropdown.firstDrop" [disabled]="!canManageFinding">
          <ng-container *ngIf="!finding.getLatestValue('deadline')">
            <button class="button--tertiary button--small" *ngIf="canManageFinding" type="button">{{ 'GLOBAL.FINDING.SET_DEADLINE' | translate }}</button>
            <div class="finding-assign-section__element-text" *ngIf="!canManageFinding">-</div>
          </ng-container>
          <div class="finding-assign-section__element-text" *ngIf="finding.getLatestValue('deadline')">
            <div class="finding-assign-section__element-label jsDropdownLabel">{{ 'GLOBAL.FINDING.DUE_DATE' | translate }}:&nbsp;</div>
            <div class="finding-assign-section__element-deadline" [class.link]="canManageFinding">{{ finding.getLatestValue('deadline') | dateFormat: 'D MMMM YYYY' }}</div>
          </div>
          <div class="dropdown__content jsDropdownContent" [class.dropdown__content--hidden]="!deadlineDropdown.openedWithDelay" [class.dropdown__content--horizontal-overflow]="deadlineDropdown.isRightOverflow">
            <gw-date-picker [after]="nowDate" *ngIf="deadlineDropdown.openedWithDelay" [(date)]="finding.getLatestValue('deadline') || nowDate" (setDate)="setDeadline(finding, $event)"></gw-date-picker>
            <div class="dropdown__actions button-group">
              <button class="link" type="button" (click)="deadlineDropdown.closeDropdown()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
              <button class="button--primary button--small" type="button" (click)="saveDeadline(finding); deadlineDropdown.closeDropdown();">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <div class="finding__assign" *ngIf="detailsLink" [routerLink]="['/finding', finding.id]">
      <ng-container *ngTemplateOutlet="findingAssignTemplate"></ng-container>
    </div>
    <div class="finding__assign" *ngIf="!detailsLink">
      <ng-container *ngTemplateOutlet="findingAssignTemplate"></ng-container>
    </div>
    <div class="finding__offline finding__offline--assign" *ngIf="finding.offlineRequest &amp;&amp; withAssign">
      <div class="offline-icon finding__offline-icon"><i class="gw-cloud--off offline-icon__icon"></i></div>
      <div class="offline-icon finding__offline-icon" *ngIf="toMove"><i class="gw-arrow--right offline-icon__icon"></i></div>
    </div>
  </div>
  <div class="dropdown dropdown--menu finding__action" *ngIf="canManageFinding" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop" [disabled]="toMove">
    <button class="button--icon" type="button"><i class="gw-more"></i></button>
    <div class="dropdown__content jsDropdownContent">
      <ul class="dropdown-menu__list">
        <li class="dropdown-menu__item" *ngIf="!disabled.update">
          <button class="link dropdown-menu__link" type="button" (click)="clickUpdate()">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
        </li>
        <li class="dropdown-menu__item" *ngIf="offlineService.online &amp;&amp; !disabled.remove">
          <button class="link dropdown-menu__link" type="button" (click)="clickRemove()">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
        </li>
      </ul>
    </div>
  </div>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FacilityFilter } from '@gutwin-audit/shared/models/facility-filter.model';
import { Facility } from '@gutwin-audit/shared/models/facility.model';

import { ApiFacility } from '@gutwin-audit/shared/interfaces/facility.interface';

import { ApiUrlService } from '@gutwin-audit/shared/services/api-url.service';

import {
  convertFacilityToArchive,
  convertFacilityToCreate,
  convertFacilityToFilter,
  convertFacilityToGet,
  convertFacilityToMove,
  convertFacilityToUpdate
} from '@gutwin-audit/shared/converters/facility.convert';

@Injectable()
export class FacilityService {
  constructor(private http: HttpClient, private apiUrlService: ApiUrlService) {}

  getFacilities(): Promise<Array<Facility>> {
    return this.http
      .get(this.apiUrlService.facilityApi)
      .toPromise()
      .then((data: Array<ApiFacility>) => {
        return data.map(facility => convertFacilityToGet(facility));
      })
      .catch(error => {
        console.error('Error while getting facilities', error);
        throw error;
      });
  }

  getFacilitiesForFilter(): Promise<Array<FacilityFilter>> {
    return this.http
      .get(this.apiUrlService.facilityApi)
      .toPromise()
      .then((data: Array<ApiFacility>) => {
        return data.map(facility => convertFacilityToFilter(facility));
      })
      .catch(error => {
        console.error('Error while getting facilities for filter', error);
        throw error;
      });
  }

  createFacility(facility: Facility): Promise<Facility> {
    return this.http
      .post(this.apiUrlService.facilityApi, convertFacilityToCreate(facility))
      .toPromise()
      .then((data: ApiFacility) => convertFacilityToGet(data))
      .catch(error => {
        console.error('Error while creating facilities', error);
        throw error;
      });
  }

  updateFacility(facility: Facility): Promise<Facility> {
    return this.http
      .put(`${this.apiUrlService.facilityApi}/${facility.id}`, convertFacilityToUpdate(facility))
      .toPromise()
      .then((data: ApiFacility) => convertFacilityToGet(data))
      .catch(error => {
        console.error('Error while updating facilities', error);
        throw error;
      });
  }

  moveFacility(facility: Facility, parentFacility?: Facility): Promise<Facility> {
    return this.http
      .put(`${this.apiUrlService.facilityApi}/${facility.id}`, convertFacilityToMove(facility, parentFacility))
      .toPromise()
      .then((data: ApiFacility) => convertFacilityToGet(data))
      .catch(error => {
        console.error('Error while moving facilities', error);
        throw error;
      });
  }

  archiveFacility(facility: Facility, archive = true): Promise<Facility> {
    return this.http
      .put(`${this.apiUrlService.facilityApi}/${facility.id}/archive`, convertFacilityToArchive(facility, archive))
      .toPromise()
      .then((data: ApiFacility) => convertFacilityToGet(data))
      .catch(error => {
        console.error('Error while archiving facility', error);
        throw error;
      });
  }
}

import { ElementRef, Injectable } from '@angular/core';

import * as Crypto from 'crypto-js';

// Gutwin Shared Library
import { Attachment } from '../models/attachment.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor() {}

  attachFiles(event: Event, returnAttachments = false): Promise<Array<Attachment> | Array<string>> {
    const fileList: FileList = event.target['files'];
    const uploadedFiles = [];
    let fileCount = 0;

    return new Promise(resolve => {
      if (fileList && fileList.length > 0) {
        Array.from(fileList).forEach(file => {
          fileCount++;
          if (returnAttachments) {
            uploadedFiles.push(new Attachment({ file }));
            if (fileList.length === fileCount) {
              resolve(uploadedFiles);
            }
          } else {
            this.generateImagePreview(file).then(image => {
              uploadedFiles.push(image);
              if (fileList.length === fileCount) {
                resolve(uploadedFiles);
              }
            });
          }
        });
      } else {
        resolve(uploadedFiles);
      }
    });
  }

  generateImagePreview(file: Blob): Promise<string> {
    const reader = new FileReader();
    const imageType = /^image\//;

    return new Promise(resolve => {
      if (imageType.test(file.type)) {
        reader.onload = (() => {
          return element => {
            resolve(element.target.result);
          };
        })();

        reader.readAsDataURL(file);
      } else {
        resolve('');
      }
    });
  }

  readFile(file: Blob | File): Promise<{ base64: string; contentType: string; hash: string; rotation: number }> {
    const generateBase64 = (element: { target: { result: any } }): string => {
      let binary = '';
      const bytes = new Uint8Array(element.target.result);
      for (let i = 0; i < bytes.byteLength; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    };

    const generateHashSha256 = (element: { target: { result: any } }): string => {
      const arrayBuffer = element.target.result;
      const wordArray = arrayBufferToWordArray(arrayBuffer);
      const hash = Crypto.SHA256(wordArray);
      return hash.toString();
    };

    const arrayBufferToWordArray = (arrayBuffer: any): any => {
      const i8a = new Uint8Array(arrayBuffer);
      const a = [];
      for (let i = 0; i < i8a.length; i += 4) {
        a.push((i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3]);
      }
      return Crypto.lib.WordArray.create(a, i8a.length);
    };

    const getRotation = (element: any): number => {
      let rotation = -1;
      const view = new DataView(element.target.result);
      if (view.getUint16(0, false) !== 0xffd8) {
        rotation = -2;
      }
      const length = view.byteLength;
      let offset = 2;
      while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            rotation = -1;
          }
          const little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              rotation = view.getUint16(offset + i * 12 + 8, little);
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return translateRotation(rotation);
    };

    const translateRotation = (rotation: number): number => {
      switch (rotation) {
        case 1:
          return 0;
        case 2:
          return 0;
        case 3:
          return 180;
        case 4:
          return 180;
        case 5:
          return 90;
        case 6:
          return 90;
        case 7:
          return 270;
        case 8:
          return 270;
        default:
          return 0;
      }
    };

    return new Promise(resolve => {
      const reader = new FileReader();

      reader.onloadend = (img => {
        return element => {
          const base64 = generateBase64(element);
          const hash = generateHashSha256(element);
          const rotation = getRotation(element);
          resolve({
            base64,
            contentType: file.type,
            hash,
            rotation
          });
        };
      })(file);

      reader.readAsArrayBuffer(file);
    });
  }

  downloadFile(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);

    const msSaveOrOpenBlobSupported = (): boolean => {
      return !!window.navigator.msSaveOrOpenBlob;
    };

    if (msSaveOrOpenBlobSupported()) {
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      link.style.display = 'none';
      link.download = fileName;
      link.href = url;
      document.body.appendChild(link);
      link.click();
    }
  }

  base64ToBlob(base64: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  isDuplicated(
    checkedAttachment: Attachment,
    attachments: Array<Attachment>,
    duplications: Array<Attachment>
  ): boolean {
    let isDuplicated = false;

    attachments.forEach((attachment: Attachment) => {
      const attachmentChecksum = attachment.checksum;
      if (!attachment.toRemove && checkedAttachment.checksum === attachmentChecksum) {
        duplications.push(checkedAttachment);
        isDuplicated = true;
      }
    });
    return isDuplicated;
  }

  resetFileInput(inputRef: ElementRef): void {
    if (inputRef) {
      const input = inputRef.nativeElement;
      this.resetHTMLFileInput(input);
    }
  }

  resetHTMLFileInput(input: any): void {
    input.value = '';
    if (!/safari/i.test(navigator.userAgent)) {
      input.type = '';
      input.type = 'file';
    }
  }
}

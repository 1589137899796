import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { OldApiAccessData } from '../interfaces/old-api-authentication.interface';

import { ApiUrl } from '../enums/old-api-url.enum';

import { AlertService } from './alert.service';
import { I18nService } from './i18n.service';
import { UserProfileOldApiService } from './user-profile-old-api.service';

@Injectable()
export class LoginOldApiService {
  ApiUrl = ApiUrl;

  constructor(
    protected alertService: AlertService,
    protected http: HttpClient,
    protected i18nService: I18nService,
    protected router: Router,
    protected userService: UserProfileOldApiService
  ) {}

  isLoggedIn(): boolean {
    return !!this.userService.getCurrentUserProfile() && !!this.userService.getCurrentUserProfile().username;
  }

  logIn(accessData: OldApiAccessData): Promise<void> {
    return this.http
      .post(
        `${this.ApiUrl.authApi}`,
        {
          CustomerID: accessData.clientId,
          Username: accessData.username,
          Password: accessData.password
        },
        { responseType: 'text' }
      )
      .toPromise()
      .then(async () => {
        const userProfile = await this.userService.fetchUserProfile(accessData.clientId);
        this.i18nService.setLanguage(userProfile.language);
        this.router.navigate(['']);
      })
      .catch((error: Error) => {
        this.alertService.error('login');
        throw error;
      });
  }

  logOut(): Promise<void> {
    return this.http
      .post(`${this.ApiUrl.userApi}/logout`, {})
      .toPromise()
      .then(() => {
        this.cleanCurrentUser();
      })
      .catch((error: Error) => {
        this.alertService.error();
        throw error;
      });
  }

  cleanCurrentUser(): void {
    this.userService.deleteCurrentUserProfile();
    this.i18nService.setLanguage(this.i18nService.getCurrentLanguage());
    this.router.navigate(['login']);
  }
}

import gql from 'graphql-tag';

import { FileFragment } from './file.fragment';

export const BackgroundImageFragment = gql`
  fragment BackgroundImageFragment on BackgroundImage {
    id
    backgroundImage {
      ...FileFragment
    }
  }
  ${FileFragment}
`;

import { Component, OnInit } from '@angular/core';
// Services
import { AuditNavigationService } from './../../services/audit-navigation.service';

@Component({
  selector: 'gw-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(public auditNavigationService: AuditNavigationService) { }

  ngOnInit() { }
}

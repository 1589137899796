import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ExtraFieldSimple } from '../../models/extra-field-simple.model';

import { ExtraFieldValue } from '../../interfaces/extra-field-value.interface';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-sidebar-extra-field',
  templateUrl: './sidebar-extra-field.component.html',
  styleUrls: ['./sidebar-extra-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarExtraFieldComponent implements OnInit {
  @Input() extraField: ExtraFieldSimple;
  @Input() isEditMode: boolean;
  @Input() extraFieldAvailableValues: Array<ExtraFieldValue>;
  @Input() userType: UserType;
  @Output() submitExtraField = new EventEmitter<ExtraFieldValue[]>();
  @Output() openExtraFields = new EventEmitter<string>();
  @Output() closeExtraFields = new EventEmitter<void>();

  form: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      extraFieldValues: [this.extraField.values ? this.extraField.values : []]
    });
  }

  resetForm(): void {
    this.form.controls['extraFieldValues'].setValue(this.extraField.values || []);
  }

  submitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.submitExtraField.emit(this.form.value.extraFieldValues);
    }
  }

  onOpened(): void {
    this.openExtraFields.emit(this.extraField.id);
  }

  onClosed(): void {
    this.closeExtraFields.emit();
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UserType } from '../../../enums/user-type.enum';

@Component({
  selector: 'gw-list-box-paragraph',
  templateUrl: './list-box-paragraph.component.html',
  styleUrls: ['./list-box-paragraph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListBoxParagraphComponent {
  @Input() label: string;
  @Input() userType?: UserType;
  @Input() inline?: boolean;
}

import { Permission } from './permission.model';

export class PermissionCategory {
  id: string;
  name: string;
  title: string;
  description: string;
  _permissions: Array<Permission>;
  totalPermissionsCount: number;
  selectedPermissionsCount: number;
  permissionsToRemoveCount: number;

  get permissions(): Array<Permission> {
    return this._permissions;
  }

  set permissions(permissions: Array<Permission>) {
    this._permissions = permissions;
    this.selectedPermissionsCount = this.countSelectedPermissions();
    this.permissionsToRemoveCount = this.countPermissionsToRemove();
  }

  constructor(permissionCategory?: Partial<PermissionCategory>) {
    this.id = permissionCategory ? permissionCategory.id : undefined;
    this.name = permissionCategory ? permissionCategory.name : undefined;
    this.title = permissionCategory ? permissionCategory.title : undefined;
    this.description = permissionCategory ? permissionCategory.description : undefined;
    this.totalPermissionsCount = permissionCategory ? permissionCategory.totalPermissionsCount : undefined;
    this.permissions = permissionCategory ? this.generatePermissions(permissionCategory.permissions) : undefined;
  }

  private generatePermissions(permissions: Array<any>): Array<Permission> {
    if (permissions) {
      return permissions.map(permission => new Permission(permission));
    }
  }

  private countSelectedPermissions(): number {
    if (this.permissions) {
      return this.permissions
        .map(permission => (permission.added && !permission.action?.remove ? 1 : 0))
        .reduce((previous, current) => previous + current, 0);
    }
  }

  private countPermissionsToRemove(): number {
    if (this.permissions) {
      return this.permissions
        .map(permission => (permission.action?.remove ? 1 : 0))
        .reduce((previous, current) => previous + current, 0);
    }
  }
}

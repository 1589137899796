import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { noop } from 'rxjs';

import { CompanyUnit } from '../models/company-unit.model';

import { ApiData } from '../interfaces/api.interface';
import {
  ApiChangeCompanyUnitParentResponse,
  ApiCompanyStructureResponse,
  ApiCreateCompanyUnitResponse,
  ApiRootCompanyUnitsResponse,
  ApiUnitsTypesResponse,
  ApiUpdateCompanyUnitResponse,
  ApiUploadCompanyUnitBackgroundImageResponse,
  ChangeCompanyUnitParentAttributes,
  CreateCompanyUnitAttributes,
  CreateCompanyUnitParams,
  UpdateCompanyUnitAttributes,
  UpdateCompanyUnitParams
} from '../interfaces/company-structure.interface';
import { UnitType } from '../interfaces/unit-type.interface';

import { AttachmentFile } from '../types/attachment-file.type';

import {
  convertCompanyStructureToGet,
  convertCreatedUnitToGet,
  convertRootCompanyUnitsToGet,
  convertUnitToGet,
  convertUnitTypeToGet
} from '../converters/company-structure.convert';

import { CompanyStructureQuery, CompanyUnitsTypesQuery, RootCompanyUnitsQuery } from '../graphQl/queries/company-structure.query';

import {
  ArchiveCompanyUnitMutation,
  ChangeCompanyUnitParentMutation,
  CreateCompanyUnitMutation,
  UpdateCompanyUnitMutation,
  UploadCompanyUnitBackgroundImageMutation
} from '../graphQl/mutations/company-structure.mutation';

@Injectable({
  providedIn: 'root'
})
export class CompanyStructureService {
  constructor(private apollo: Apollo) {}

  getCompanyStructure(): Promise<Array<CompanyUnit>> {
    return this.apollo
      .query<ApiCompanyStructureResponse>({
        query: CompanyStructureQuery
      })
      .toPromise()
      .then(({ data }: ApiData<ApiCompanyStructureResponse>) =>
        convertCompanyStructureToGet(data.companyStructure.units)
      )
      .catch(error => {
        console.error('Error while getting company structure', error);
        throw error;
      });
  }

  getRootCompanyUnits(): Promise<Array<CompanyUnit>> {
    return this.apollo
      .query<ApiRootCompanyUnitsResponse>({
        query: RootCompanyUnitsQuery
      })
      .toPromise()
      .then(({ data }: ApiData<ApiRootCompanyUnitsResponse>) => convertRootCompanyUnitsToGet(data.rootUnits))
      .catch(error => {
        console.error('Error while getting root company units', error);
        throw error;
      });
  }

  archiveCompanyUnit(id: string): Promise<void> {
    return this.apollo
      .mutate({
        mutation: ArchiveCompanyUnitMutation,
        variables: { id }
      })
      .toPromise()
      .then(noop)
      .catch(error => {
        console.error('Error while archiving company unit', error);
      });
  }

  changeCompanyUnitParent(attributes: ChangeCompanyUnitParentAttributes): Promise<CompanyUnit> {
    return this.apollo
      .mutate<ApiChangeCompanyUnitParentResponse>({
        mutation: ChangeCompanyUnitParentMutation,
        variables: { attributes }
      })
      .toPromise()
      .then(({ data }: ApiData<ApiChangeCompanyUnitParentResponse>) =>
        convertUnitToGet(data.changeCompanyUnitParent.unit)
      )
      .catch(error => {
        console.error('Error while changing company unit parent', error);
        throw error;
      });
  }

  createCompanyUnit(attributes: CreateCompanyUnitAttributes): Promise<CompanyUnit> {
    return this.apollo
      .mutate<ApiCreateCompanyUnitResponse, CreateCompanyUnitParams>({
        mutation: CreateCompanyUnitMutation,
        variables: { attributes }
      })
      .toPromise()
      .then(({ data }: ApiData<ApiCreateCompanyUnitResponse>) => convertCreatedUnitToGet(data.createCompanyUnit.unit))
      .catch(error => {
        console.error('Error while creating company unit', error);
        throw error;
      });
  }

  updateCompanyUnit(attributes: UpdateCompanyUnitAttributes): Promise<CompanyUnit> {
    return this.apollo
      .mutate<ApiUpdateCompanyUnitResponse, UpdateCompanyUnitParams>({
        mutation: UpdateCompanyUnitMutation,
        variables: { attributes }
      })
      .toPromise()
      .then(({ data }: ApiData<ApiUpdateCompanyUnitResponse>) => convertUnitToGet(data.updateCompanyUnit.unit))
      .catch(error => {
        console.error('Error while updating company unit', error);
        throw error;
      });
  }

  getCompanyUnitsTypes(): Promise<Array<UnitType>> {
    return this.apollo
      .query<ApiUnitsTypesResponse>({
        query: CompanyUnitsTypesQuery
      })
      .toPromise()
      .then(({ data }: ApiData<ApiUnitsTypesResponse>) => data.unitTypes?.map(convertUnitTypeToGet))
      .catch(error => {
        console.error('Error while getting company units types', error);
        throw error;
      });
  }

  uploadCompanyUnitBackgroundImage(backgroundImage: AttachmentFile): Promise<string> {
    return this.apollo
      .mutate<ApiUploadCompanyUnitBackgroundImageResponse>({
        mutation: UploadCompanyUnitBackgroundImageMutation,
        variables: { backgroundImage },
        context: {
          useMultipart: true
        }
      })
      .toPromise()
      .then(
        ({ data }: ApiData<ApiUploadCompanyUnitBackgroundImageResponse>) =>
          data.uploadUnitBackgroundImage.backgroundImage.id
      )
      .catch(error => {
        console.error('Error while uploading company unit background image', error);
        throw error;
      });
  }
}

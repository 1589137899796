import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';

import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';

import { TreeFilterOption } from '../../models/tree-filter-option.model';
import { TreeNodeZip } from '../../models/tree-node.model';

import { NestedElementsToPreview } from '../../interfaces/nested-elements-to-preview.interface';

import { TreeService } from '../../services/tree.service';

import { ObjectValidators } from '../../validators/object.validator';

import { getIdsFromTreeNodesZip, getZipNodes } from '../../utils/tree.util';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-edit-categories',
  templateUrl: './edit-categories.component.html',
  styleUrls: ['./edit-categories.component.scss']
})
export class EditCategoriesComponent<L extends { id: string; name: string; children: Array<L> }>
  implements OnInit, OnDestroy {
  @Input() selectedCategories: NestedElementsToPreview;
  @Input() set allLegalCategories(allLegalCategories: Array<L>) {
    this.legalCategories = this.mapLegalCategories(allLegalCategories);
  }
  @Input() isEditMode: boolean;
  @Input() withSquare?: boolean;
  @Input() isRequired = true;
  @Output() submitChange = new EventEmitter<Array<TreeNodeZip>>();

  legalCategories: Array<TreeFilterOption>;
  form: FormGroup;
  submitted = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private formBuilder: FormBuilder, private store: Store, private treeService: TreeService) {}

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      legalCategories: this.isRequired ? [{}, ObjectValidators.required] : [{}]
    });
  }

  resetForm(): void {
    if (this.selectedCategories) {
      const selectedCategoriesIds = getIdsFromTreeNodesZip(this.selectedCategories.list);
      this.form.get('legalCategories').setValue(selectedCategoriesIds);
    } else {
      this.form.get('legalCategories').setValue([]);
    }
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  submitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      const selectedCategoriesIds = this.form.value.legalCategories;
      const selectedCategoriesToDisplay = getZipNodes(this.legalCategories, selectedCategoriesIds);
      this.submitChange.emit(selectedCategoriesToDisplay);
    }
  }

  mapLegalCategories(legalCategories: Array<L>): Array<TreeFilterOption> {
    return legalCategories ? legalCategories.map(legalCategory => this.mapLegalCategory(legalCategory)) : [];
  }

  mapLegalCategory(legalCategory: L): TreeFilterOption {
    return new TreeFilterOption({
      id: legalCategory.id,
      name: legalCategory.name,
      children: legalCategory.children
        ? legalCategory.children.map(legalCategory => this.mapLegalCategory(legalCategory))
        : []
    });
  }

  onPopoverOpened(): void {
    this.increaseOpenedEdits();
    this.resetForm();
  }

  increaseOpenedEdits(): void {
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.store.dispatch(new DecreaseOpenedEdits());
  }
}

import { ElementRef } from '@angular/core';

import { find } from 'lodash';

class HelperUtilClass {
  userAgent = navigator.userAgent || navigator.vendor || window['opera'];

  constructor() {}

  get windowScrollY(): number {
    const supportPageOffset = window.pageXOffset !== undefined;
    const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
    const windowScrollY = supportPageOffset
      ? window.pageYOffset
      : isCSS1Compat
      ? document.documentElement.scrollTop
      : document.body.scrollTop;
    return windowScrollY;
  }

  scrollView(direction: string, scrollContainer?: ElementRef): Promise<void> {
    const scrollInterval = direction === 'down' ? 5 : -5;
    let scrollValue = 0;
    const scrollFunction =
      scrollContainer && scrollContainer.nativeElement
        ? () => {
            scrollContainer.nativeElement.scrollTop += scrollInterval;
          }
        : () => {
            window.scrollBy(0, scrollInterval);
          };
    return new Promise(resolve => {
      const timerID = setInterval(() => {
        scrollFunction();
        scrollValue++;
        if (scrollValue >= 10) {
          clearInterval(timerID);
          resolve();
        }
      }, 16);
    });
  }

  isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(this.userAgent) && !window['MSStream'];
  }

  isMobileOrTablet(): boolean {
    return (
      // eslint-disable-next-line max-len
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        this.userAgent
      ) ||
      // eslint-disable-next-line max-len
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        this.userAgent.substr(0, 4)
      )
    );
  }

  mergeObjects(objectOne: any, objectTwo: any): any {
    const removeUndefined = (object: any) => {
      for (const key in object) {
        if (object[key] === undefined) {
          delete object[key];
        }
      }
      return object;
    };

    return Object.assign({}, objectOne, removeUndefined(objectTwo));
  }

  mergeArrays<T>(arrayOne: Array<T>, arrayTwo: Array<T>, key = 'id'): Array<T> {
    return arrayOne.map(itemOne => ({
      ...arrayTwo.find(itemTwo => itemOne[key] === itemTwo[key] && itemTwo),
      ...itemOne
    }));
  }

  isNullable(value: any): boolean {
    return value === undefined || value === null;
  }

  areArraysDifferent(firstArray: Array<any>, secondArray: Array<any>, key?: string): boolean {
    if (!firstArray && !secondArray) {
      return false;
    } else if (
      (firstArray && !secondArray) ||
      (!firstArray && secondArray) ||
      firstArray.length !== secondArray.length
    ) {
      return true;
    } else {
      for (const firstItem of firstArray) {
        const exists = !!find(secondArray, secondItem =>
          key ? secondItem[key] === firstItem[key] : secondItem === firstItem
        );
        if (!exists) {
          return true;
        }
      }
      return false;
    }
  }

  objectToFormData(data: any, formData = new FormData(), prefix: string = ''): FormData {
    const appendObject = (data: any, prefix: string) => {
      if (typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        if (!!data && data.constructor === Array) {
          prefix += '[]';
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let i = 0; i < data.length; i++) {
            appendObject(data[i], prefix);
          }
        } else {
          this.objectToFormData(data, formData, prefix);
        }
      } else {
        formData.append(prefix, data);
      }
    };

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const AUX_P = prefix === '' ? key : `${prefix}[${key}]`;
        appendObject(data[key], AUX_P);
      }
    }
    return formData;
  }

  getNestedAttributeToDisplay(displayKeysInPath: string, itemToDisplay: any, separator = ', '): string {
    const getNestedAttribute = (displayKeys: Array<string>, item: any): string => {
      const key = displayKeys?.[0];
      if (key) {
        if (Array.isArray(item)) {
          const values = item.map(child => getNestedAttribute(displayKeys.slice(1), child[key]));
          return values.join(separator);
        } else {
          return item ? getNestedAttribute(displayKeys.slice(1), item[key]) : undefined;
        }
      } else {
        return item;
      }
    };

    return getNestedAttribute(displayKeysInPath.split('.'), itemToDisplay);
  }

  getNestedAttributesToDisplay(displayKeys: Array<string>, itemToDisplay: any, splitter: string): string {
    let display = '';
    displayKeys.forEach(displayKey => {
      const displayValue = HelperUtil.getNestedAttributeToDisplay(displayKey, itemToDisplay);
      if (displayValue) {
        display += (display.length ? splitter : '') + displayValue;
      }
    });
    return display;
  }

  mapArray<T = any>(list: Array<T>, attribute: keyof T): Array<T[keyof T]> {
    return list?.map(item => item[attribute]) ?? [];
  }

  getFieldsFromObject<T>(data: T, keys: Array<keyof T>): Partial<T> {
    const fields: Partial<T> = {};
    keys.forEach(key => !this.isNullable(data[key]) && (fields[key] = data[key]));
    return fields;
  }

  roundNumber(value: number, precision?: number): number {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  setQueryParamsInUrl(url: string, queryParams: Record<string, string | number>): string {
    const urlObject = new URL(url);
    Object.entries(queryParams).forEach(([key, value]) => urlObject.searchParams.set(key, `${value}`));
    return urlObject.toString();
  }

  areValuesDifferent<T>(firstObject: T, secondObject: T, keys: keyof T | Array<keyof T>): boolean {
    if (firstObject && secondObject) {
      if (Array.isArray(keys)) {
        return keys
          .map(key => firstObject[key] !== secondObject[key])
          .reduce((isAnyDifferent, isDifferent) => isAnyDifferent || isDifferent);
      } else {
        return firstObject[keys] !== secondObject[keys];
      }
    }
  }
}

export const HelperUtil = new HelperUtilClass();

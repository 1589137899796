import { Component, OnInit, Input } from '@angular/core';
// Gutwin Shared Library
import { Attachment } from 'gutwin-shared';
// Models
import { Finding } from './../../../../shared/models/finding.model';

@Component({
  selector: 'gw-finding-details',
  templateUrl: './finding-details.component.html',
  styleUrls: ['./finding-details.component.scss']
})
export class FindingDetailsComponent implements OnInit {
  @Input() finding: Finding;
  @Input() isEven: boolean;
  attachments = {
    documents: new Array<Attachment>(),
    boxes: new Array<Attachment>()
  };
  gallery = new Array<Attachment>();

  constructor() { }

  ngOnInit() {
    this.splitAttachments();
    this.gallery = this.prepareGallery(this.attachments.boxes);
  }

  splitAttachments(): void {
    this.finding.attachments.forEach(attachment => {
      if (attachment.isImage) {
        this.attachments.boxes.push(attachment);
      } else {
        this.attachments.documents.push(attachment);
      }
    });
  }

  prepareGallery(attachments: Array<Attachment>): Array<Attachment> {
    const gallery = new Array<Attachment>();

    if (this.attachments) {
      for (const attachment of attachments) {
        if (attachment.isImage) {
          gallery.push(attachment);
        }
      }
    }
    return gallery;
  }
}

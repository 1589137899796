import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'objectToArray'})
export class ObjectToArrayPipe implements PipeTransform {
  transform(value: Object): Array<any> {
    const entries = [];
    if (value) {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          entries.push(value[key]);
        }
      }
    }
    return entries;
  }
}

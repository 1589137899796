import moment from 'moment';

import { Employee } from './employee.model';

import { StatusId } from '../enums/status-id.enum';

export class Status {
  id: StatusId;
  name: string;
  color?: string;

  constructor(status: Partial<Status>) {
    Object.assign(this, status);
  }
}

export class StatusAssigned extends Status {
  executionTime: moment.Moment;
  employee?: Employee;

  constructor(status: Partial<StatusAssigned>) {
    super(status);
    Object.assign(this, status);
  }
}

import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { CompanyUnit } from '../../models/company-unit.model';

import { UnitType } from '../../interfaces/unit-type.interface';

import { CompanyStructureService } from '../../services/company-structure.service';
import { TreeService } from '../../services/tree.service';

import { updateNodeInTree } from '../../utils/tree.util';

import {
  ArchiveCompanyUnit,
  ChangeCompanyUnitParent,
  ClearCompanyUnitBackgroundImage,
  CreateCompanyUnit,
  GetCompanyStructure,
  GetCompanyUnitsTypes,
  GetRootCompanyUnits,
  UpdateCompanyUnit,
  UploadCompanyUnitBackgroundImage
} from './company-structure.actions';

export interface CompanyStructureStateModel {
  units: Array<CompanyUnit>;
  rootUnits: Array<CompanyUnit>;
  unitsTypes: Array<UnitType>;
  unitBackgroundImageId?: string;
  defaultUnitColor: string;
  createdUnit?: CompanyUnit;
}

@State<CompanyStructureStateModel>({
  name: 'companyStructure',
  defaults: {
    units: [],
    rootUnits: [],
    unitsTypes: [],
    defaultUnitColor: '#CED7E4'
  }
})
@Injectable()
export class CompanyStructureState {
  constructor(private companyStructureService: CompanyStructureService, private treeService: TreeService) {}

  @Selector()
  static units(state: CompanyStructureStateModel): Array<CompanyUnit> {
    return state.units;
  }

  @Selector()
  static rootUnits(state: CompanyStructureStateModel): Array<CompanyUnit> {
    return state.rootUnits;
  }

  @Selector()
  static unitsTypes(state: CompanyStructureStateModel): Array<UnitType> {
    return state.unitsTypes;
  }

  @Selector()
  static unitBackgroundImageId(state: CompanyStructureStateModel): string {
    return state.unitBackgroundImageId;
  }

  @Selector()
  static defaultUnitColor(state: CompanyStructureStateModel): string {
    return state.defaultUnitColor;
  }

  @Selector()
  static createdUnit(state: CompanyStructureStateModel): CompanyUnit {
    return state.createdUnit;
  }

  @Action(GetCompanyStructure)
  getCompanyStructure(context: StateContext<CompanyStructureStateModel>): Promise<void> {
    return this.companyStructureService.getCompanyStructure().then(units => {
      context.patchState({
        units
      });
    });
  }

  @Action(GetRootCompanyUnits)
  getRootCompanyUnits(context: StateContext<CompanyStructureStateModel>): Promise<void> {
    return this.companyStructureService.getRootCompanyUnits().then(rootUnits => {
      context.patchState({
        rootUnits
      });
    });
  }

  @Action(GetCompanyUnitsTypes)
  getCompanyUnitsTypes(context: StateContext<CompanyStructureStateModel>): Promise<void> {
    return this.companyStructureService.getCompanyUnitsTypes().then(unitsTypes => {
      context.patchState({
        unitsTypes
      });
    });
  }

  @Action(ArchiveCompanyUnit)
  archiveCompanyUnit(context: StateContext<CompanyStructureStateModel>, { id }: ArchiveCompanyUnit): Promise<void> {
    return this.companyStructureService.archiveCompanyUnit(id).then(() => {
      const units = context.getState().units;
      const unitToRemove = new CompanyUnit({ id });
      const updatedUnits = this.treeService.spliceNode<CompanyUnit>(units, unitToRemove);

      context.patchState({
        units: updatedUnits
      });
    });
  }

  @Action(CreateCompanyUnit)
  createCompanyUnit(
    context: StateContext<CompanyStructureStateModel>,
    { attributes }: CreateCompanyUnit
  ): Promise<void> {
    return this.companyStructureService.createCompanyUnit(attributes).then(unit => {
      const units = context.getState().units;
      const parentNode = attributes?.parentId && new CompanyUnit({ id: attributes.parentId });
      const updatedUnits = this.treeService.appendNode<CompanyUnit>(units, unit, parentNode);

      context.patchState({
        createdUnit: unit,
        units: updatedUnits
      });
    });
  }

  @Action(UpdateCompanyUnit)
  updateCompanyUnit(
    context: StateContext<CompanyStructureStateModel>,
    { attributes }: UpdateCompanyUnit
  ): Promise<void> {
    return this.companyStructureService.updateCompanyUnit(attributes).then(({ id, name, type, backgroundImage }) => {
      const units = context.getState().units;
      const updatedUnits = updateNodeInTree(units, { id, name, type, backgroundImage }, true);

      context.patchState({
        units: updatedUnits
      });
    });
  }

  @Action(ChangeCompanyUnitParent)
  changeCompanyUnitParent(
    context: StateContext<CompanyStructureStateModel>,
    { attributes }: ChangeCompanyUnitParent
  ): Promise<void> {
    return this.companyStructureService.changeCompanyUnitParent(attributes).then(unit => {
      const parentNode = attributes.parentId && new CompanyUnit({ id: attributes.parentId });
      const units = this.treeService.cloneTree<CompanyUnit>(context.getState().units);
      const updatedUnits = this.treeService.replaceNode<CompanyUnit>(units, unit, parentNode);

      context.patchState({
        units: updatedUnits
      });
    });
  }

  @Action(UploadCompanyUnitBackgroundImage)
  async uploadCompanyUnitBackgroundImage(
    context: StateContext<CompanyStructureStateModel>,
    { backgroundImage }: UploadCompanyUnitBackgroundImage
  ): Promise<void> {
    const unitBackgroundImageId = await this.companyStructureService.uploadCompanyUnitBackgroundImage(backgroundImage);

    context.patchState({
      unitBackgroundImageId
    });
  }

  @Action(ClearCompanyUnitBackgroundImage)
  ClearCompanyUnitBackgroundImage(context: StateContext<CompanyStructureStateModel>): void {
    context.patchState({
      unitBackgroundImageId: undefined
    });
  }
}

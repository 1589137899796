
<div class="add-reference-document">
  <form class="add-reference-document__form" [formGroup]="addQuestionnaireForm" novalidate="novalidate" autocomplete="off">
    <div class="add-reference-document__fieldset">
      <div class="row add-reference-document__fields">
        <div class="columns small-12 small-centered">
          <div class="form__field--lower add-reference-document__field" [class.form__field--error]="isFieldInvalid(addQuestionnaireForm.controls['name'])">
            <label class="label add-reference-document__field-label" for="name">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTIONNAIRE_MODAL.FORM.LABEL.NAME' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTIONNAIRE_MODAL.FORM.ERROR.NAME_EMPTY' | translate }}</div>
            <input class="input" placeholder="{{ 'ADMIN_SETTINGS.REFERENCE_DOCUMENTS.ADD_QUESTIONNAIRE_MODAL.FORM.PLACEHOLDER.NAME' | translate }}" type="text" id="name" name="name" [formControl]="addQuestionnaireForm.controls['name']"/>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
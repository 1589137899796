import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { Interval } from '../interfaces/interval.interface';

import { IntervalId } from '../enums/interval-id.enum';

export const IntervalList: Interval[] = [
  {
    id: IntervalId.daily,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.DAILY')
  },
  {
    id: IntervalId.everySecondDay,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_SECOND_DAY')
  },
  {
    id: IntervalId.everyWeek,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_WEEK')
  },
  {
    id: IntervalId.everyTwoWeeks,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_TWO_WEEKS')
  },
  {
    id: IntervalId.monthly,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.MONTHLY')
  },
  {
    id: IntervalId.quarterly,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.QUARTERLY')
  },
  {
    id: IntervalId.halfYearly,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.HALF_YEARLY')
  },
  {
    id: IntervalId.yearly,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.YEARLY')
  },
  {
    id: IntervalId.everyTwoYears,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_TWO_YEARS')
  },
  {
    id: IntervalId.everyThreeYears,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_THREE_YEARS')
  },
  {
    id: IntervalId.everyFourYears,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_FOUR_YEARS')
  },
  {
    id: IntervalId.everyFiveYears,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_FIVE_YEARS')
  },
  {
    id: IntervalId.everySixYears,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_SIX_YEARS')
  },
  {
    id: IntervalId.everySevenYears,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_SEVEN_YEARS')
  },
  {
    id: IntervalId.everyEightYears,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_EIGHT_YEARS')
  },
  {
    id: IntervalId.everyNineYears,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_NINE_YEARS')
  },
  {
    id: IntervalId.everyTenYears,
    translationKey: marker('GUTWIN_SHARED.INTERVALS.EVERY_TEN_YEARS')
  }
];

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';

import { DialogService, ModalService, OfflineService } from 'gutwin-shared';

import { Audit } from '@gutwin-audit/shared/models/audit.model';
import { AuditedArea } from '@gutwin-audit/shared/models/audited-area.model';

import { AddTemplateComponent } from '@gutwin-audit/audit/audits/audits-list/add-template/add-template.component';
import { CopyAuditComponent } from '@gutwin-audit/audit/audits/audits-list/copy-audit/copy-audit.component';

import { AuditId, AuditOfflineService } from '@gutwin-audit/shared/services/audit.offline.service';
import { AuditService } from '@gutwin-audit/shared/services/audit.service';
import { FilterModuleService } from '@gutwin-audit/shared/services/filter-module.service';
import { FindingService } from '@gutwin-audit/shared/services/finding.service';
import { PermissionService } from '@gutwin-audit/shared/services/permission.service';
import { RatingScalesService } from '@gutwin-audit/shared/services/raiting-scales.service';
import { RatingsService } from '@gutwin-audit/shared/services/ratings.service';
import { StorageModuleService, StoragesNamesModule } from '@gutwin-audit/shared/services/storage-module.service';
import { UserService } from '@gutwin-audit/shared/services/user.service';

@Component({
  selector: 'gw-audits-group',
  templateUrl: './audits-group.component.html',
  styleUrls: ['./audits-group.component.scss']
})
export class AuditsGroupComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('addTemplate') addTemplateComponent: AddTemplateComponent;
  @ViewChild('copyAudit') copyAuditComponent: CopyAuditComponent;
  @Input() audits: Array<Audit>;
  @Input() isTemplate = false;
  @Output() auditCreated = new EventEmitter();
  @Output() auditRemoved = new EventEmitter();
  auditedAreaGroups = new Array<Array<Array<AuditedArea>>>();
  auditsIdsOffline: Array<AuditId>;
  auditsDisabled: Array<Audit>;
  filters: any;
  savingAudit: boolean;
  auditsIdsOfflineSubscription: Subscription;
  userSubscription: Subscription;
  savingTemplate: boolean;
  translation = {
    removeDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    notify: {
      error: {
        connectionTitle: '',
        permissions: '',
        removeAuditText: '',
        removeAuditPermissionsText: '',
        makeOfflineTitle: '',
        makeOfflineText: '',
        syncOfflineTitle: '',
        syncOfflineText: ''
      },
      success: {
        makeOfflineTitle: '',
        makeOfflineText: '',
        syncOfflineTitle: '',
        syncOfflineText: '',
        removeOfflineTitle: '',
        removeOfflineText: '',
        removeAuditTitle: '',
        removeAuditText: ''
      }
    }
  };

  constructor(
    private auditService: AuditService,
    private auditOfflineService: AuditOfflineService,
    private dialogService: DialogService,
    private filterService: FilterModuleService,
    private findingService: FindingService,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private permissionService: PermissionService,
    private ratingsService: RatingsService,
    private ratingScalesService: RatingScalesService,
    private router: Router,
    private storageService: StorageModuleService,
    private translateService: TranslateService,
    private userService: UserService,
    public offlineService: OfflineService
  ) {}

  ngOnInit(): void {
    this.fetchTranslations();
    this.updateAudits();
    this.fetchAuditsIdsOffline();
    this.filters = this.filterService.filters;
    this.userSubscription = this.userService.storedUserObservable.subscribe(() => {
      this.markAuditsDisabled();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.audits) {
      this.updateAudits();
    }
  }

  ngOnDestroy(): void {
    this.auditsIdsOfflineSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  async fetchAuditsIdsOffline(): Promise<void> {
    const auditsIds = await this.auditOfflineService.getAuditsIdsFromOfflineStore().catch(error => []);
    this.auditsIdsOffline = auditsIds;
    this.markAuditsOffline();

    this.auditsIdsOfflineSubscription = this.auditOfflineService.auditsIdsOfflineObservable.subscribe(auditsIds => {
      this.auditsIdsOffline = auditsIds;
      this.markAuditsOffline();
    });
  }

  updateAudits(): void {
    if (this.audits) {
      this.audits.forEach(audit => {
        this.auditedAreaGroups[audit.id] = this.groupByDate(audit.auditedAreas);
      });
      this.markAuditsOffline();
      this.markAuditsDisabled();
    }
  }

  markAuditsOffline(): void {
    if (this.audits && this.auditsIdsOffline) {
      this.audits.forEach(audit => {
        const auditIdOffline: any = _.find(this.auditsIdsOffline, { id: audit.id });
        if (auditIdOffline) {
          audit.offline = true;
          audit.offlineDate = auditIdOffline.date;
        }
      });
    }
  }

  markAuditsDisabled(): void {
    if (this.audits) {
      this.audits.forEach(audit => {
        audit.disabled = !this.permissionService.canAuditBeManaged(audit, this.userService.storedUser);
      });
    }
  }

  groupByDate(auditedAreas: Array<AuditedArea>): Array<Array<AuditedArea>> {
    const groupsObject = _.values(
      _.groupBy(auditedAreas, (auditedArea: AuditedArea) => {
        return moment(auditedArea.startTime).format('YYYY-MM-DD'); // ISO format;
      })
    );
    return groupsObject;
  }

  showRemoveAuditDialog(audit: Audit): void {
    this.dialogService
      .confirm(
        this.translation.removeDialog.header,
        this.translation.removeDialog.content,
        this.translation.removeDialog.cancel,
        this.translation.removeDialog.confirm
      )
      .then(() => {
        this.removeAudit(audit);
      })
      .catch(() => {});
  }

  removeAudit(audit: Audit): void {
    const showErrorNotification = (error: any): void => {
      switch (error.status) {
        case 403:
          this.notificationsService.error(
            this.translation.notify.error.permissions,
            this.translation.notify.error.removeAuditPermissionsText
          );
          break;
        default:
          this.notificationsService.error(
            this.translation.notify.error.connectionTitle,
            this.translation.notify.error.removeAuditText
          );
      }
    };

    const removeAuditPromise =
      audit.state === 'draft' ? this.auditService.removeAudit(audit) : this.auditService.archiveAudit(audit);
    removeAuditPromise
      .then(() => {
        this.auditRemoved.emit();
        this.notificationsService.success(
          this.translation.notify.success.removeAuditTitle,
          this.translation.notify.success.removeAuditText
        );
      })
      .catch(error => {
        showErrorNotification(error);
      });
  }

  showMarkAsTemplateModal(audit: Audit): void {
    this.modalService.open('addTemplateModal', { audit });
  }

  saveTemplate(): void {
    this.addTemplateComponent
      .submitAddTemplateForm()
      .then(() => {
        this.modalService.close('addTemplateModal');
        this.router.navigate(['/audits/templates'], { queryParams: { page: 1 }, queryParamsHandling: 'merge' });
      })
      .catch(error => {});
  }

  showCopyAuditModal(audit: Audit): void {
    const lastTabindex = audit.auditedAreas ? audit.auditedAreas.length * 6 + 1 : 1;
    this.modalService.open('copyAuditModal', { audit, lastTabindex });
  }

  saveAudit(): void {
    this.savingAudit = true;
    this.copyAuditComponent
      .submitAuditForm()
      .then(() => {
        this.modalService.close('copyAuditModal');
        this.savingAudit = false;
        this.auditCreated.emit();
      })
      .catch(error => {
        this.savingAudit = false;
      });
  }

  clearAuditStorage(): void {
    this.storageService.removeFromStorage(StoragesNamesModule.auditCreation);
  }

  makeOffline(audit: Audit): void {
    const promises = new Array<Promise<any>>();
    promises.push(this.auditService.saveWholeAuditOffline(audit));
    promises.push(this.ratingScalesService.saveRatingScaleOffline(audit));
    promises.push(this.findingService.saveFindingTypesOffline());
    promises.push(this.userService.saveEmployeesOffline());
    Promise.all(promises)
      .then(() => {
        this.notificationsService.success(
          this.translation.notify.success.makeOfflineTitle,
          this.translation.notify.success.makeOfflineText
        );
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.makeOfflineTitle,
          this.translation.notify.error.makeOfflineText
        );
      });
  }

  removeOffline(audit: Audit): void {
    this.auditService.removeAuditOffline(audit);
    this.notificationsService.success(
      this.translation.notify.success.removeOfflineTitle,
      this.translation.notify.success.removeOfflineText
    );
  }

  syncAudit(audit: Audit): void {
    const promises = new Array<Promise<any>>();
    promises.push(this.auditService.syncWholeAuditOffline(audit));
    promises.push(this.ratingScalesService.saveRatingScaleOffline(audit));
    promises.push(this.findingService.saveFindingTypesOffline());
    promises.push(this.userService.saveEmployeesOffline());
    Promise.all(promises)
      .then(() => {
        this.notificationsService.success(
          this.translation.notify.success.syncOfflineTitle,
          this.translation.notify.success.syncOfflineText
        );
      })
      .catch(error => {
        this.notificationsService.error(
          this.translation.notify.error.syncOfflineTitle,
          this.translation.notify.error.syncOfflineText
        );
      });
  }

  async fetchTranslations(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ERROR.PERMISSIONS',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'AUDITS_LIST.ERROR.REMOVE_AUDIT_TEXT',
        'AUDITS_LIST.ERROR.REMOVE_AUDIT_PERMISSIONS_TEXT',
        'AUDITS_LIST.ERROR.MAKE_AUDIT_OFFLINE_TITLE',
        'AUDITS_LIST.ERROR.MAKE_AUDIT_OFFLINE_TEXT',
        'AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TITLE',
        'AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TEXT',
        'AUDITS_LIST.SUCCESS.MAKE_AUDIT_OFFLINE_TITLE',
        'AUDITS_LIST.SUCCESS.MAKE_AUDIT_OFFLINE_TEXT',
        'AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TITLE',
        'AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TEXT',
        'AUDITS_LIST.SUCCESS.REMOVE_AUDIT_OFFLINE_TITLE',
        'AUDITS_LIST.SUCCESS.REMOVE_AUDIT_OFFLINE_TEXT',
        'AUDITS_LIST.SUCCESS.REMOVE_AUDIT_TITLE',
        'AUDITS_LIST.SUCCESS.REMOVE_AUDIT_TEXT',
        'AUDITS_LIST.REMOVE_AUDIT_DIALOG.HEADER',
        'AUDITS_LIST.REMOVE_AUDIT_DIALOG.CONTENT'
      ])
      .toPromise();
    this.translation.removeDialog.header = translation['AUDITS_LIST.REMOVE_AUDIT_DIALOG.HEADER'];
    this.translation.removeDialog.content = translation['AUDITS_LIST.REMOVE_AUDIT_DIALOG.CONTENT'];
    this.translation.removeDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
    this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.permissions = translation['GLOBAL.ERROR.PERMISSIONS'];
    this.translation.notify.error.removeAuditText = translation['AUDITS_LIST.ERROR.REMOVE_AUDIT_TEXT'];
    this.translation.notify.error.removeAuditPermissionsText =
      translation['AUDITS_LIST.ERROR.REMOVE_AUDIT_PERMISSIONS_TEXT'];
    this.translation.notify.error.makeOfflineTitle = translation['AUDITS_LIST.ERROR.MAKE_AUDIT_OFFLINE_TITLE'];
    this.translation.notify.error.makeOfflineText = translation['AUDITS_LIST.ERROR.MAKE_AUDIT_OFFLINE_TEXT'];
    this.translation.notify.error.syncOfflineTitle = translation['AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TITLE'];
    this.translation.notify.error.syncOfflineText = translation['AUDITS_LIST.ERROR.SYNC_AUDIT_OFFLINE_TEXT'];
    this.translation.notify.success.makeOfflineTitle = translation['AUDITS_LIST.SUCCESS.MAKE_AUDIT_OFFLINE_TITLE'];
    this.translation.notify.success.makeOfflineText = translation['AUDITS_LIST.SUCCESS.MAKE_AUDIT_OFFLINE_TEXT'];
    this.translation.notify.success.syncOfflineTitle = translation['AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TITLE'];
    this.translation.notify.success.syncOfflineText = translation['AUDITS_LIST.SUCCESS.SYNC_AUDIT_OFFLINE_TEXT'];
    this.translation.notify.success.removeOfflineTitle = translation['AUDITS_LIST.SUCCESS.REMOVE_AUDIT_OFFLINE_TITLE'];
    this.translation.notify.success.removeOfflineText = translation['AUDITS_LIST.SUCCESS.REMOVE_AUDIT_OFFLINE_TEXT'];
    this.translation.notify.success.removeAuditTitle = translation['AUDITS_LIST.SUCCESS.REMOVE_AUDIT_TITLE'];
    this.translation.notify.success.removeAuditText = translation['AUDITS_LIST.SUCCESS.REMOVE_AUDIT_TEXT'];
  }
}

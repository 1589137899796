import { TreeFilterOption } from '../models/tree-filter-option.model';
import { TreeNode, TreeNodeZip } from '../models/tree-node.model';

import { NestedObjectOfIds } from '../interfaces/nested-object-of-ids.interface';

import { MapApiTree } from '../types/map-tree.type';
import { TreeNodesIds } from '../types/tree-nodes-ids.type';

export const getNodesSingleValue = <T extends { id: string | number; children?: Array<T> } = TreeNode>(
  nodes: Array<T>,
  valueName: keyof T = 'id',
  nodesValues: Array<T[keyof T]> = []
): Array<T[keyof T]> => {
  for (const node of nodes) {
    nodesValues.push(node[valueName]);
    if (node.children?.length) getNodesSingleValue(node.children, valueName, nodesValues);
  }
  return nodesValues;
};

export const getNodesIdsWithFlag = <T extends { id: string | number; children?: Array<T> } = TreeNode>(
  nodes: Array<T>,
  flag: keyof T
): Array<string> => {
  const nodesIds = [];
  nodes?.forEach(node => {
    if (node[flag]) nodesIds.push(node.id);
    nodesIds.push(...getNodesIdsWithFlag(node.children, flag));
  });

  return nodesIds;
};

export const mergeTrees = <T extends MapApiTree<T>>(trees: Array<T>): Array<T> => {
  const addToMap = (tree: T, map: Map<string, T>): void => {
    let newNode = map.get(tree.id);
    if (!newNode) map.set(tree.id, (newNode = { ...tree, children: new Map<string, T>() }));
    for (const child of tree.children ?? []) addToMap(child as T, newNode.children as Map<string, T>);
  };

  const convertToArray = (map: Map<string, T>): Array<T> => {
    const convertedTree = (tree: T) => {
      return Object.assign(tree, { children: convertToArray(tree.children as Map<string, T>) });
    };
    return Array.from(map.values(), convertedTree);
  };

  const mergedTree = new Map<string, T>();
  for (const tree of trees) addToMap(tree, mergedTree);

  return convertToArray(mergedTree);
};

export const sortTreeByName = (tree: Array<TreeNodeZip>): Array<TreeNodeZip> => {
  tree.sort((nodeOne, nodeTwo) => {
    return getTreeNodeFullName(nodeOne) > getTreeNodeFullName(nodeTwo) ? 1 : -1;
  });
  return tree;
};

export const getTreeNodeFullName = (node: TreeNodeZip): string => {
  const path = node?.path.join('/');
  return path ? `${path}/${node.name}` : node.name;
};

export const convertArrayToNestedIdsObject = (ids: Array<string>): NestedObjectOfIds<string> => {
  const nestedIds = {};
  ids?.forEach(id => (nestedIds[id] = []));
  return nestedIds;
};

export const filterNodesByName = (
  query = '',
  nodes: Array<TreeFilterOption>,
  parentFitsQuery?: boolean,
  currentPath = []
): { hasFilteredNodes: boolean; filteredNodes: Array<TreeFilterOption>; filteredNodesIds: TreeNodesIds } => {
  let hasFilteredNodes = false;
  let filteredNodesIds: TreeNodesIds = {};
  query = query.toLowerCase();

  const filteredNodes = nodes?.map(node => {
    const searchKey = node.name.toLowerCase();
    const fitQuery = searchKey.includes(query);
    const childrenResponse = filterNodesByName(query, node.children, fitQuery || parentFitsQuery, [
      ...currentPath,
      node.id
    ]);
    const isHidden = !parentFitsQuery && !fitQuery && !childrenResponse.hasFilteredNodes;

    if (!isHidden) hasFilteredNodes = true;

    if (fitQuery) filteredNodesIds[node.id] = currentPath;

    filteredNodesIds = { ...filteredNodesIds, ...childrenResponse.filteredNodesIds };

    return { ...node, hidden: isHidden, children: childrenResponse.filteredNodes };
  });

  return { hasFilteredNodes, filteredNodes, filteredNodesIds };
};

export const getZipNodes = (nodes: Array<TreeNode>, selectedNodesIds: NestedObjectOfIds): Array<TreeNodeZip> => {
  if (nodes && selectedNodesIds) {
    const zipNodes = [];
    for (const id in selectedNodesIds) {
      if (selectedNodesIds.hasOwnProperty(id)) {
        zipNodes.push(getZipNode(nodes, id, selectedNodesIds[id]));
      }
    }
    return zipNodes.filter(zipNode => zipNode);
  }
};

export const getZipNode = (
  nodes: Array<TreeNode>,
  nodeId: string,
  idsOnPath: Array<string>
): TreeNodeZip | undefined => {
  const getNode = (id: string): TreeNode => nodes.find(node => node.id === id);

  const convertToNodeZip = (node: TreeNode): TreeNodeZip => {
    return {
      id: node.id,
      name: node.name,
      color: node.color,
      path: [],
      ancestors: []
    };
  };

  if (idsOnPath.length) {
    const currentNode = getNode(idsOnPath[0]);
    if (currentNode) {
      idsOnPath = idsOnPath.slice(1);
      if (currentNode.children) {
        const currentNodeZip = convertToNodeZip(currentNode);
        const nodeZip = getZipNode(currentNode.children, nodeId, idsOnPath);
        if (nodeZip) {
          nodeZip.path.unshift(currentNodeZip.name);
          nodeZip.ancestors.unshift(currentNodeZip);
          return nodeZip;
        }
        return currentNodeZip;
      }
    }
  } else {
    const currentNode = getNode(nodeId);
    return currentNode ? convertToNodeZip(currentNode) : undefined;
  }
};

export const getIdsFromTreeFilterOptions = (
  initialValues: Array<TreeFilterOption>,
  selectedNodesIds: NestedObjectOfIds
): Array<string> | undefined => {
  if (!selectedNodesIds) return;
  const treeNodeZip = getZipNodes(initialValues, selectedNodesIds);

  return getNodesSingleValue(treeNodeZip) as Array<string>;
};

export const getIdsFromTreeNodesZip = (
  treeNodesZip: Array<TreeNodeZip>
): NestedObjectOfIds<string | number> | undefined => {
  if (!treeNodesZip?.length) return;
  const ids = {};
  treeNodesZip.forEach(node => (ids[node.id] = node.ancestors?.map(ancestor => ancestor.id) ?? []));

  return ids;
};

export const getSelectedNodeIds = (
  nodes: Array<TreeNode>,
  flagName: string,
  parent?: TreeNode
): NestedObjectOfIds<string | number> => {
  let selectedNodes: NestedObjectOfIds<string | number> = {};
  if (nodes) {
    for (const node of nodes) {
      if (node[flagName]) {
        selectedNodes[node.id] = [];
      }
      const selectedChildren = getSelectedNodeIds(node.children, flagName, node);
      selectedNodes = { ...selectedNodes, ...selectedChildren };
    }
  }
  if (parent) {
    for (const key in selectedNodes) {
      if (selectedNodes.hasOwnProperty(key)) {
        const value = selectedNodes[key];
        value.unshift(parent.id);
      }
    }
  }
  return selectedNodes;
};

export const updateNodeInTree = <T extends { id: string; children?: Array<T> }>(
  tree: Array<T>,
  nodeToUpdate: Partial<T>,
  updateAttributes?: boolean
): Array<T> => {
  return tree?.map(node => {
    if (node.id === nodeToUpdate.id) {
      const updatedNode = { ...node, ...nodeToUpdate };
      return !updateAttributes ? (nodeToUpdate as T) : updatedNode;
    } else {
      return {
        ...node,
        children: updateNodeInTree(node.children, nodeToUpdate, updateAttributes)
      };
    }
  });
};

import { Component, OnInit } from '@angular/core';

import { SsoService } from '../../shared/services/sso.service';

@Component({
  selector: 'gw-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {
  constructor(private ssoService: SsoService) {}

  ngOnInit(): void {
    this.logOut();
  }

  logOut(): void {
    this.ssoService.logOut(false);
  }
}


<gw-modal id="facilityModal" #modal="gwModal">
  <ng-container modal-header="modal-header">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.MODAL_HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content" *ngIf="modal.opened">
    <gw-select-facility #selectFacility="gwSelectFacility" [facilities]="facilities" [chosenFacility]="modal.data?.auditedAreaForm?.get('facility')?.value" (submitFacilities)="chooseFacility(modal.data?.auditedAreaForm, $event, modal.data?.selectElement); modal.close();"></gw-select-facility>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="modal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" (click)="selectFacility.submitSelectedFacilities()">{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="copy-audit">
  <gw-tabs [tabs]="tabs" (changeTab)="setSelectedTab($event)">
    <form class="copy-audit__form" *ngIf="auditForm" [formGroup]="auditForm" (ngSubmit)="submitAuditForm()" novalidate="novalidate" autocomplete="off">
      <div class="copy-audit__fieldset copy-audit__fieldset--no-margin">
        <div class="row copy-audit__fields" formGroupName="audit">
          <div class="columns small-12 medium-6">
            <div class="copy-audit__field" [class.form__field--error]="isFieldInvalid(auditForm.get('audit').get('leadAuditor'))">
              <label class="label" for="auditAuditors" (click)="auditAuditorsSelect.focusDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.LEAD_AUDITOR' | translate }}</label>
              <div class="form-field__error">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.AUDITOR_EMPTY' | translate }}</div>
              <gw-multiselect #auditAuditorsSelect="gwMultiselect" formControlName="leadAuditor" uniqueKey="id" tabindex="1" [placeholder]="'AUDIT_CREATION.FORM.PLACEHOLDER.LEAD_AUDITOR' | translate" [data]="employees" [display]="['lastname', 'name']" [splitter]="', '" [separator]="'; '" (focus)="auditAuditorsSelect.focusDropdown()"></gw-multiselect>
            </div>
          </div>
          <div class="columns small-12 medium-6">
            <div class="copy-audit__field">
              <ng-container *ngIf="!auditForm.get('audit').get('coAuditor').value.length &amp;&amp; !coAuditorsVisible">
                <button class="button--quaternary button--small" type="button" tabindex="2" (click)="showCoAuditors()">{{ 'AUDIT_CREATION.FORM.LABEL.ADD_CO_AUDITOR' | translate }}</button>
              </ng-container>
              <ng-container *ngIf="auditForm.get('audit').get('coAuditor').value.length || coAuditorsVisible">
                <label class="label" for="coAuditor" (click)="auditorSelect.focusDropdown()">{{ 'AUDIT_CREATION.FORM.LABEL.CO_AUDITOR' | translate }}</label>
                <gw-multiselect #auditorSelect="gwMultiselect" formControlName="coAuditor" tabindex="3" [placeholder]="'AUDIT_CREATION.FORM.PLACEHOLDER.CO_AUDITOR' | translate" [data]="employees" [display]="['lastname', 'name']" [splitter]="', '" [separator]="'; '" [uniqueKey]="'id'" (focus)="auditorSelect.focusDropdown()"></gw-multiselect>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="row recurring__row" *ngIf="selectedTab?.id === 'recurring'" formGroupName="recurring">
          <div class="recurring__type columns small-12 large-6" [class.recurring__type--enabled]="isSelectedRecurringType('monthly')">
            <label class="label label--without-margin">
              <gw-radio-button tabindex="4" [value]="'monthly'" [ngModel]="auditForm.get('recurring.type').value" [ngModelOptions]="{ standalone: true }" (ngModelChange)="setSelectedRecurringType('monthly')"></gw-radio-button><span class="recurring__type-label">&nbsp; {{ 'AUDIT_CREATION.FORM.LABEL.MONTHLY_EVERY' | translate }}</span>
              <input class="input input--small recurring__input" tabindex="5" type="number" id="months" name="months" formControlName="months"/><span class="recurring__type-label--small">
                {{ 'AUDIT_CREATION.FORM.LABEL.MONTHS' | translate }}
                -</span>
              <input class="input input--small recurring__input" tabindex="6" type="number" id="monthsDates" name="monthsDates" formControlName="monthsDates"/>&nbsp; {{ 'AUDIT_CREATION.FORM.LABEL.DATES' | translate }}
            </label>
            <div class="recurring__errors form__field--error">
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldEmpty(auditForm.get('recurring').get('months'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.MONTHS_EMPTY' | translate }}</div>
              </div>
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldMinNumberInvalid(auditForm.get('recurring').get('months'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.MONTHS_MIN_NUMBER' | translate }}</div>
              </div>
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldMaxNumberInvalid(auditForm.get('recurring').get('months'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.MONTHS_MAX_NUMBER' | translate }}</div>
              </div>
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldEmpty(auditForm.get('recurring').get('monthsDates'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.MONTHS_DATES_EMPTY' | translate }}</div>
              </div>
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldMinNumberInvalid(auditForm.get('recurring').get('monthsDates'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.MONTHS_DATES_MIN_NUMBER' | translate }}</div>
              </div>
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldMaxNumberInvalid(auditForm.get('recurring').get('monthsDates'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.MONTHS_DATES_MAX_NUMBER' | translate }}</div>
              </div>
            </div>
          </div>
          <div class="recurring__type columns small-12 large-6" [class.recurring__type--enabled]="isSelectedRecurringType('weekly')">
            <label class="label label--without-margin">
              <gw-radio-button tabindex="7" [value]="'weekly'" [ngModel]="auditForm.get('recurring.type').value" [ngModelOptions]="{ standalone: true }" (ngModelChange)="setSelectedRecurringType('weekly')"></gw-radio-button><span class="recurring__type-label">&nbsp; {{ 'AUDIT_CREATION.FORM.LABEL.WEEKLY_EVERY' | translate }}</span>
              <input class="input input--small recurring__input" tabindex="8" type="number" id="weeks" name="weeks" formControlName="weeks"/><span class="recurring__type-label--small">
                {{ 'AUDIT_CREATION.FORM.LABEL.WEEKS' | translate }}
                -</span>
              <input class="input input--small recurring__input" tabindex="9" type="number" id="weeksDates" name="weeksDates" formControlName="weeksDates"/>&nbsp; {{ 'AUDIT_CREATION.FORM.LABEL.DATES' | translate }}
            </label>
            <div class="recurring__errors form__field--error">
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldEmpty(auditForm.get('recurring').get('weeks'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.WEEKS_EMPTY' | translate }}</div>
              </div>
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldMinNumberInvalid(auditForm.get('recurring').get('weeks'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.WEEKS_MIN_NUMBER' | translate }}</div>
              </div>
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldMaxNumberInvalid(auditForm.get('recurring').get('weeks'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.WEEKS_MAX_NUMBER' | translate }}</div>
              </div>
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldEmpty(auditForm.get('recurring').get('weeksDates'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.WEEKS_DATES_EMPTY' | translate }}</div>
              </div>
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldMinNumberInvalid(auditForm.get('recurring').get('weeksDates'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.WEEKS_DATES_MIN_NUMBER' | translate }}</div>
              </div>
              <div class="recurring__error">
                <div class="form-field__error" *ngIf="isFieldMaxNumberInvalid(auditForm.get('recurring').get('weeksDates'))">{{ 'AUDIT_CREATION.S_AUDIT_PLAN.ERROR.WEEKS_DATES_MAX_NUMBER' | translate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copy-audit__audited-areas">
        <gw-audited-area-form class="copy-audit__audited-area" *ngFor="let auditedArea of auditForm.get('auditedAreas').controls; index as formIndex" [isRecurring]="isRecurring" [recurringType]="selectedRecurringType" [weekDays]="weekDays" [auditedArea]="auditedArea" [formIndex]="formIndex" [submitted]="submitted" [auditors]="auditorsList" [employees]="employees" [facilities]="facilities" (keyDownFacility)="keyDownFacilityControl($event)"></gw-audited-area-form>
      </div>
    </form>
  </gw-tabs>
</div>
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AttachmentLC } from '../../models/attachment-lc.model';
import { Attachment } from '../../models/attachment.model';

import { LightboxService } from '../../services/lightbox.service';

@Component({
  selector: 'gw-attachment-preview',
  templateUrl: './attachment-preview.component.html',
  styleUrls: ['./attachment-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentPreviewComponent {
  @Input() attachment: AttachmentLC;
  @Input() convertSizeToMB?: boolean;
  @Input() isDocument?: boolean;
  @Input() isEditMode = false;
  @Input() isUserTypeClient = false;
  @Input() showSize?: boolean;
  @Input() showMimeType?: boolean;
  @Input() removeFileExtension?: boolean;

  constructor(private lightboxService: LightboxService) {}

  showImagePreview(): void {
    const attachmentPreview = new Attachment({
      ...this.attachment,
      size: NaN
    });
    this.lightboxService.show(attachmentPreview);
  }
}

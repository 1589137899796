import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import moment from 'moment';

import { ApiTokenConsultantResponse, TokenConsultant } from '../interfaces/token.interface';

import { LoginService } from './login.service';
import { StorageService, StoragesNames } from './storage.service';

import { convertTokenConsultantToGet } from '../converters/token.convert';

@Injectable({
  providedIn: 'root'
})
export class LoginConsultantService extends LoginService {
  protected refreshTokenUrl = '/token';
  private isRefreshingToken = false;

  constructor(
    protected http: HttpClient,
    protected router: Router,
    protected storageService: StorageService,
    protected location: Location,
    protected activatedRoute: ActivatedRoute
  ) {
    super(router, storageService, activatedRoute, location);
  }

  shouldRefreshToken(): boolean {
    const token = this.getFullToken();
    if (!this.isRefreshingToken && token.token) {
      const minutesToExpire = moment.unix(token.tokenExpiryTime).diff(moment(), 'minutes');
      // Token should be refreshed 5 minutes before it expires
      return minutesToExpire > 0 && minutesToExpire <= 5;
    }
  }

  refreshToken(refreshToken: string): Promise<string> {
    this.isRefreshingToken = true;
    return this.http
      .post(this.refreshTokenUrl, {
        grant_type: 'refresh_token',
        refresh_token: refreshToken
      })
      .toPromise()
      .then((data: ApiTokenConsultantResponse) => {
        const token = convertTokenConsultantToGet(data);
        this.saveTokenInLocalStorage(token);
        return token.token;
      })
      .finally(() => {
        this.isRefreshingToken = false;
      });
  }

  getFullToken(): TokenConsultant {
    const token = this.storageService.getLocalStorage(StoragesNames.token);
    const refreshToken = this.storageService.getLocalStorage(StoragesNames.refreshToken);
    const tokenExpiryTime = this.storageService.getLocalStorage(StoragesNames.tokenExpiryTime);
    return {
      token,
      refreshToken,
      tokenExpiryTime
    };
  }

  saveTokenInLocalStorage(token: TokenConsultant): void {
    this.storageService.setLocalStorage(StoragesNames.token, token.token);
    this.storageService.setLocalStorage(StoragesNames.refreshToken, token.refreshToken);
    this.storageService.setLocalStorage(StoragesNames.tokenExpiryTime, token.tokenExpiryTime);
  }

  cleanLocalStorage(): void {
    super.cleanLocalStorage();
    this.storageService.removeFromLocalStorage(StoragesNames.refreshToken);
    this.storageService.removeFromLocalStorage(StoragesNames.tokenExpiryTime);
  }
}

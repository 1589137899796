import { Pipe, PipeTransform } from '@angular/core';

import { UserType } from '../enums/user-type.enum';

@Pipe({
  name: 'isClientUserType'
})
export class IsClientUserTypePipe implements PipeTransform {
  transform(userType: UserType): boolean {
    return userType === UserType.client;
  }
}

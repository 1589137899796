import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';

// Gutwin Shared Library
import { DialogService, ModalService } from 'gutwin-shared';

// Models
import { AuditType } from '../../../shared/models/audit-type.model';

// Components
import { AddAuditTypeComponent } from './add-audit-type/add-audit-type.component';

// Service
import { AuditTypeService } from '../../../shared/services/audit-type.service';

@Component({
  selector: 'gw-audit-types',
  templateUrl: './audit-types.component.html',
  styleUrls: ['./audit-types.component.scss']
})
export class AuditTypesComponent implements OnInit {
  @ViewChild('addAuditType') addAuditType: AddAuditTypeComponent;
  auditTypes: Array<AuditType>;
  translation = {
    notify: {
      error: {
        connectionTitle: '',
        permissions: '',
        removeAuditType: ''
      },
      success: {
        removeAuditTypeTitle: '',
        removeAuditTypeText: ''
      }
    },
    removeDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    }
  };

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private modalService: ModalService,
    private auditTypeService: AuditTypeService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.auditTypes = this.route.snapshot.data['auditTypes'];
    this.fetchTranslation();
  }

  appendAuditType(auditType: AuditType): void {
    this.auditTypes.push(auditType);
  }

  updateAuditType(auditType: AuditType): void {
    this.spliceAuditType(auditType);
    this.appendAuditType(auditType);
  }

  spliceAuditType(auditType: AuditType): void {
    const index = _.findIndex(this.auditTypes, { id: auditType.id });
    if (~index) {
      this.auditTypes.splice(index, 1);
    }
  }

  saveAuditType(): void {
    this.addAuditType
      .submitAddFindingTypeForm()
      .then(auditTypeResponse => {
        if (auditTypeResponse.status === 'create') {
          this.appendAuditType(auditTypeResponse.auditType);
        } else if (auditTypeResponse.status === 'update') {
          this.updateAuditType(auditTypeResponse.auditType);
        }
        this.modalService.close('auditTypeModal');
      })
      .catch(error => {});
  }

  showUpdateAuditTypeModal(auditType: AuditType): void {
    this.modalService.open('auditTypeModal', auditType);
  }

  showRemoveModal(auditType: AuditType): void {
    this.dialogService
      .confirm(
        this.translation.removeDialog.header,
        this.translation.removeDialog.content,
        this.translation.removeDialog.cancel,
        this.translation.removeDialog.confirm
      )
      .then(() => {
        this.removeAuditType(auditType);
      })
      .catch(() => {});
  }

  removeAuditType(auditType: AuditType): void {
    this.auditTypeService
      .archiveAuditType(auditType)
      .then(() => {
        this.spliceAuditType(auditType);
        this.notificationsService.success(
          this.translation.notify.success.removeAuditTypeTitle,
          this.translation.notify.success.removeAuditTypeText
        );
      })
      .catch(error => {
        const errorBody = JSON.parse(error._body);
        if (errorBody && errorBody.errors) {
          this.notificationsService.error(this.translation.notify.error.permissions, errorBody.errors);
        } else {
          this.notificationsService.error(
            this.translation.notify.error.connectionTitle,
            this.translation.notify.error.removeAuditType
          );
        }
      });
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ERROR.PERMISSIONS',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'ADMIN_SETTINGS.AUDIT_TYPES.ERROR.REMOVE_AUDIT_TYPE',
        'ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.REMOVE_AUDIT_TYPE_TITLE',
        'ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.REMOVE_AUDIT_TYPE_TEXT',
        'ADMIN_SETTINGS.AUDIT_TYPES.REMOVE_MODAL.HEADER',
        'ADMIN_SETTINGS.AUDIT_TYPES.REMOVE_MODAL.CONTENT'
      ])
      .toPromise();
    this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.permissions = translation['GLOBAL.ERROR.PERMISSIONS'];
    this.translation.notify.error.removeAuditType = translation['ADMIN_SETTINGS.AUDIT_TYPES.ERROR.REMOVE_AUDIT_TYPE'];
    this.translation.notify.success.removeAuditTypeTitle =
      translation['ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.REMOVE_AUDIT_TYPE_TITLE'];
    this.translation.notify.success.removeAuditTypeText =
      translation['ADMIN_SETTINGS.AUDIT_TYPES.SUCCESS.REMOVE_AUDIT_TYPE_TEXT'];
    this.translation.removeDialog.header = translation['ADMIN_SETTINGS.AUDIT_TYPES.REMOVE_MODAL.HEADER'];
    this.translation.removeDialog.content = translation['ADMIN_SETTINGS.AUDIT_TYPES.REMOVE_MODAL.CONTENT'];
    this.translation.removeDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
  }
}

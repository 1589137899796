import { Component, Input } from '@angular/core';

@Component({
  selector: 'gw-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() set total(total: number) {
    this._total = total;
    this.countChanges();
  }
  get total(): number {
    return this._total;
  }
  _total: number;
  @Input() set progress(progress: number) {
    this._progress = progress;
    this.countChanges();
  }
  get progress(): number {
    return this._progress;
  }
  _progress: number;
  @Input() showPercents = true;
  @Input() big: boolean;
  percents: number;

  countChanges(): void {
    if (this.total > 0) {
      this.percents = (this.progress / this.total) * 100;
      this.percents = this.percents > 100 ? 100 : this.percents;
    } else {
      this.percents = 0;
    }
  }
}

import gql from 'graphql-tag';

export const LicenseFragment = gql`
  fragment LicenseFragment on License {
    legalCompliance {
      submodules {
        legalRegister {
          isEnabled
        }
        legalRequirements {
          isEnabled
        }
        legalChanges {
          isEnabled
        }
      }
    }
  }
`;

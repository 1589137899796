import gql from 'graphql-tag';

import { CurrentUserGraphQLActionType } from '../../enums/user-actions.enum';

import { ProfileFragment } from '../fragments/profile.fragment';

export const UpdateCurrentUserEmail = gql`
  mutation ${CurrentUserGraphQLActionType.updateLoginEmail}($email: String!) {
    ${CurrentUserGraphQLActionType.updateLoginEmail}(email: $email) {
      profile {
        ...ProfileFragment
      }
      errors
    }
  }
  ${ProfileFragment}
`;

export const UpdateCurrentUserPassword = gql`
  mutation ${CurrentUserGraphQLActionType.updateLoginPassword}($password: String!, $passwordConfirmation: String!) {
    ${CurrentUserGraphQLActionType.updateLoginPassword}(password: $password, passwordConfirmation: $passwordConfirmation) {
      profile {
        ...ProfileFragment
      }
      errors
    }
  }
  ${ProfileFragment}
`;

export const UpdateCompanyContext = gql`
  mutation ${CurrentUserGraphQLActionType.selectCurrentCompany}(
    $companyId: String!
  ) {
    ${CurrentUserGraphQLActionType.selectCurrentCompany}(companyId: $companyId) {
      profile {
        ...ProfileFragment
      }
      errors
    }
  }
  ${ProfileFragment}
`;

export const UpdateProfile = gql`
  mutation ${CurrentUserGraphQLActionType.updateProfile}(
    $id: ID!, $name: String!, $lastName: String!, $language: String!
  ) {
    ${CurrentUserGraphQLActionType.updateProfile}(id: $id, name: $name, lastName: $lastName, language: $language) {
      profile {
        ...ProfileFragment
      }
      errors
    }
  }
  ${ProfileFragment}
`;

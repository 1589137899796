import { Component, Input, OnInit, ViewChild } from '@angular/core';

// Directives
import { SimpleDropdownDirective } from '../../directives/simple-dropdown.directive';

@Component({
  selector: 'gw-button-dropdown',
  templateUrl: './button-dropdown.component.html',
  styleUrls: ['./../../../scss/components/_button-dropdown.scss'],
  exportAs: 'buttonDropdown'
})
export class ButtonDropdownComponent implements OnInit {
  @Input() title: string;
  @Input() buttonClasses = ['button--primary'];
  @Input() customContent = false;
  @Input() relative = true;
  @ViewChild('simpleDropdown', { static: true }) simpleDropdown: SimpleDropdownDirective;

  constructor() {}

  ngOnInit(): void {}

  closeDropdown(): void {
    this.simpleDropdown.closeDropdown();
  }
}

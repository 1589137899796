import { RestApiMethods } from '../enums/rest-api-methods.enum';

export class RelatedLawReference {
  article?: string;
  attachment?: string;
  id?: string;
  litera?: string;
  number?: string;
  paragraph?: string;
  section?: string;
  action?: RestApiMethods;

  constructor(relatedLawReference?: Partial<RelatedLawReference>) {
    this.article = relatedLawReference ? relatedLawReference.article : '';
    this.attachment = relatedLawReference ? relatedLawReference.attachment : '';
    this.litera = relatedLawReference ? relatedLawReference.litera : '';
    this.paragraph = relatedLawReference ? relatedLawReference.paragraph : '';
    this.number = relatedLawReference ? relatedLawReference.number : null;
    this.section = relatedLawReference ? relatedLawReference.section : '';
    this.action = relatedLawReference && relatedLawReference.action ? relatedLawReference.action : RestApiMethods.add;
  }
}

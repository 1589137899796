import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
// Models
import { Audit } from './../../../shared/models/audit.model';

@Component({
  selector: 'gw-summary-tabs',
  templateUrl: './summary-tabs.component.html',
  styleUrls: ['./summary-tabs.component.scss']
})
export class SummaryTabsComponent implements OnInit {
  @Input() audit: Audit;
  currentPath: string;
  routerSubscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.currentPath = this.getCurrentPath();
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentPath = this.getCurrentPath();
      }
    });
  }

  getCurrentPath(): string {
    let path = '';
    this.route.snapshot['_urlSegment'].segments.forEach(segment => {
      path += '/' + segment.path;
    });
    return path;
  }
}

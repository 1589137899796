export class FacilitiesFilter {
  facilities: Array<string>;
  multiSelect: boolean;
  subFolders: boolean;

  constructor(facilitiesFilters?: any) {
    if (facilitiesFilters) {
      this.facilities = facilitiesFilters.facilities;
      this.multiSelect = facilitiesFilters.multiSelect;
      this.subFolders = facilitiesFilters.subFolders;
    }
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
// Services
import { NotificationsService } from 'angular2-notifications';
import moment from 'moment';
import { Subscription } from 'rxjs';

// Gutwin Shared Library
import {
  Attachment,
  DatePickerComponent,
  DialogService,
  Employee,
  FileService,
  ModalService,
  SimpleDropdownDirective
} from 'gutwin-shared';

import { Solving } from '../../../shared/models/solving.model';

import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'gw-solve-finding',
  templateUrl: './solve-finding.component.html',
  styleUrls: ['./solve-finding.component.scss']
})
export class SolveFindingComponent implements OnInit, OnDestroy {
  @ViewChild('datePicker') datePicker: DatePickerComponent;
  @ViewChild('dateDropdown', { static: true }) dateDropdown: SimpleDropdownDirective;
  @Output() toggleSolveSection = new EventEmitter<any>();
  @Output() solveFinding = new EventEmitter<any>();
  @Input() savingSolving = false;
  employees: Array<Employee>;
  translateSubscription: Subscription;
  solveFindingForm: FormGroup;
  submitted: boolean;
  attachments = new Array<Attachment>();
  duplications = new Array<Attachment | any>();
  notify = {
    error: {
      connection: '',
      loadEmployees: ''
    }
  };
  solveDialog = {
    header: '',
    content: '',
    cancel: '',
    confirm: ''
  };

  constructor(
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private fileService: FileService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.fetchTranslation();
    this.getEmployees();
    this.initForm();
  }

  ngOnDestroy(): void {
    this.translateSubscription.unsubscribe();
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ERROR.LOAD_EMPLOYEES',
        'FINDING_VIEW.SOLVE_FINDING.DIALOG.HEADER',
        'FINDING_VIEW.SOLVE_FINDING.DIALOG.CONTENT',
        'FINDING_VIEW.SOLVE_FINDING.DIALOG.CANCEL',
        'FINDING_VIEW.SOLVE_FINDING.DIALOG.CONFIRM'
      ])
      .subscribe((translation: any) => {
        this.notify.error.connection = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.loadEmployees = translation['GLOBAL.ERROR.LOAD_EMPLOYEES'];
        this.solveDialog.header = translation['FINDING_VIEW.SOLVE_FINDING.DIALOG.HEADER'];
        this.solveDialog.content = translation['FINDING_VIEW.SOLVE_FINDING.DIALOG.CONTENT'];
        this.solveDialog.cancel = translation['FINDING_VIEW.SOLVE_FINDING.DIALOG.CANCEL'];
        this.solveDialog.confirm = translation['FINDING_VIEW.SOLVE_FINDING.DIALOG.CONFIRM'];
      });
  }

  getEmployees(): void {
    this.userService
      .getEmployees()
      .then((employees: Array<Employee>) => {
        this.employees = employees;
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connection, this.notify.error.loadEmployees);
      });
  }

  initForm(): void {
    this.solveFindingForm = this.formBuilder.group({
      employee: [this.getEmployeeValue() || '', Validators.required],
      date: [this.getDateValue() || '', Validators.required],
      note: ''
    });
  }

  isFieldInvalid(field): boolean {
    return !field.valid && this.submitted;
  }

  setControlValue(data: any, key: string): void {
    this.solveFindingForm.controls[key].setValue(data);
  }

  getEmployeeValue(): Employee {
    return this.userService.storedUser;
  }

  getDateValue(): moment.Moment {
    return moment();
  }

  keyDownDatePicker(event: any): void {
    switch (event.key) {
      case 'Enter':
        if (this.datePicker && this.dateDropdown.openedWithDelay) {
          this.setControlValue(this.datePicker.date, 'date');
        }
    }
  }

  keyDownAttachmentControl(event: any, fileInput: any): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      fileInput.click(event);
    }
  }

  attachFiles(event: any): void {
    this.duplications = new Array<Attachment>();
    const fileList: FileList = event.target.files;
    let fileCounter = 0;

    if (fileList && fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const file: File = fileList[i];
        this.fileService.readFile(file).then(({ hash, rotation }) => {
          const attachment = new Attachment({ file });
          attachment.checksum = hash;
          attachment.rotation = rotation;
          if (!this.isDuplicated(attachment)) {
            this.attachments.push(attachment);
          }
          fileCounter++;
          if (fileCounter === fileList.length) {
            if (this.duplications.length) {
              this.modalService.open('solvingDuplicationsModal');
            }
          }
        });
      }
    }
  }

  isDuplicated(checkedAttachment: Attachment): boolean {
    let isDuplicated = false;

    this.attachments.forEach((attachment: Attachment, index: number) => {
      const attachmentChecksum = attachment.checksum;
      if (!attachment.toRemove && checkedAttachment.checksum === attachmentChecksum) {
        this.duplications.push(checkedAttachment);
        isDuplicated = true;
      }
    });
    return isDuplicated;
  }

  removeAttachment(attachment: Attachment): void {
    const index = this.attachments.indexOf(attachment);
    this.attachments.splice(index, 1);
  }

  showSolveDialog(): Promise<any> {
    return this.dialogService.confirm(
      this.solveDialog.header,
      this.solveDialog.content,
      this.solveDialog.cancel,
      this.solveDialog.confirm
    );
  }

  submitFindingForm(solveFindingForm: FormGroup): void {
    this.submitted = true;
    if (solveFindingForm.valid) {
      this.showSolveDialog()
        .then(() => {
          this.solveFinding.emit(
            new Solving({
              solvedBy: `${solveFindingForm.value.employee.lastname}, ${solveFindingForm.value.employee.name}`,
              solvedAt: solveFindingForm.value.date,
              note: solveFindingForm.value.note,
              attachments: this.attachments
            })
          );
        })
        .catch(() => {});
    }
  }

  cancel(): void {
    this.toggleSolveSection.emit();
  }
}

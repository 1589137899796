import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';

import { Employee } from '../../models/employee.model';
import { StatusLCConsultant } from '../../models/status-lc-consultant.model';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-edit-status',
  templateUrl: './edit-status.component.html',
  styleUrls: ['./edit-status.component.scss']
})
export class EditStatusComponent {
  @Input() status: StatusLCConsultant;
  @Input() statuses: StatusLCConsultant[];
  @Input() isEditMode: boolean;
  @Output() changeStatus = new EventEmitter();
  users: Array<Employee>;
  destroy$: Subject<void> = new Subject<void>();
  selectedStatus: StatusLCConsultant;

  constructor(private store: Store) {}

  statusChange(status): void {
    this.selectedStatus = status;
  }

  submitForm(): void {
    this.changeStatus.emit(this.selectedStatus);
  }

  increaseOpenedEdits(): void {
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.store.dispatch(new DecreaseOpenedEdits());
  }
}

import { LanguageCode } from '../enums/language-code.enum';

export class Language {
  id: LanguageCode;
  name: string;

  constructor(language: any) {
    this.id = language.id;
    this.name = language.name;
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
// Models
import { Finding } from './../../../shared/models/finding.model';

@Component({
  selector: 'gw-finding-source',
  templateUrl: './finding-source.component.html',
  styleUrls: ['./finding-source.component.scss']
})
export class FindingSourceComponent implements OnInit {
  @Input() finding: Finding;

  constructor() { }

  ngOnInit() { }

  isAuditedAreaInOneDay(): boolean {
    return this.finding.relationships.auditedArea &&
      this.finding.relationships.auditedArea.startTime.isSame(this.finding.relationships.auditedArea.endTime, 'day');
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { EmployeeExtended } from 'gutwin-shared';

import { UserService } from '@gutwin-audit/shared/services/user.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const currentUser: EmployeeExtended = await this.userService.getCurrentUser().catch(error => undefined);
    if (currentUser && currentUser.permissions.accessToAdminPanel) {
      return true;
    }

    this.router.navigate(['audits']);
    return false;
  }
}

export enum SortByOptions {
  'title' = 'title',
  'deadline' = 'deadline',
  'validFrom' = 'validFrom',
  'validTo' = 'validTo',
  'publicationDate' = 'publicationDate',
  'issuedOn' = 'issuedOn',
  'createAt' = 'createAt',
  'lastStatus' = 'lastStatus'
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngxs/store';

import { LegalType } from '../../models/legal-type.model';

import { UserType } from '../../enums/user-type.enum';

import { DecreaseOpenedEdits, IncreaseOpenedEdits } from '../../state/details-page/details-page.actions';

@Component({
  selector: 'gw-edit-legal-type',
  templateUrl: './edit-legal-type.component.html',
  styleUrls: ['./edit-legal-type.component.scss'],
  exportAs: 'gwEditLegalTypes'
})
export class EditLegalTypeComponent implements OnInit {
  @Input() chosenLegalType: LegalType;
  @Input() isEditMode: boolean;
  @Input() userType: UserType;
  @Input() legalTypes: Array<LegalType>;

  @Output() submit = new EventEmitter();

  form: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder, private store: Store) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group({
      legalType: ['', Validators.required]
    });
  }

  resetForm(): void {
    this.form.controls['legalType'].setValue(this.chosenLegalType || '');
  }

  isFieldInvalid(field: AbstractControl): boolean {
    return !field.valid && this.submitted;
  }

  submitForm(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.submit.emit(this.form.value.legalType);
    }
  }

  increaseOpenedEdits(): void {
    this.store.dispatch(new IncreaseOpenedEdits());
  }

  decreaseOpenedEdits(): void {
    this.store.dispatch(new DecreaseOpenedEdits());
  }
}

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Gutwin Shared Library
import { LoginService } from 'gutwin-shared';

// Services
import { StorageModuleService, StoragesNamesModule } from './storage-module.service';

@Injectable()
export class LoginModuleService extends LoginService {
  constructor(
    protected router: Router,
    protected storageService: StorageModuleService,
    protected activatedRoute: ActivatedRoute,
    protected location: Location
  ) {
    super(router, storageService, activatedRoute, location);
  }

  logOut(redirect = true, redirectUrl?: string): void {
    this.storageService.removeFromOfflineStore(StoragesNamesModule.currentUser, true);
    super.logOut(redirect, redirectUrl);
  }
}


<div class="add-audit-type">
  <form class="add-audit-type__form" [formGroup]="addAuditTypeForm" novalidate="novalidate" autocomplete="off">
    <div class="add-audit-type__fieldset">
      <div class="row add-audit-type__fields">
        <div class="columns small-12 small-centered">
          <div class="form__field--lower add-audit-type__field" [class.form__field--error]="isFieldInvalid(addAuditTypeForm.controls['title'])">
            <label class="label add-audit-type__field-label" for="title">{{ 'ADMIN_SETTINGS.AUDIT_TYPES.ADD_MODAL.FORM.LABEL.NAME' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.AUDIT_TYPES.ADD_MODAL.FORM.ERROR.NAME_EMPTY' | translate }}</div>
            <input class="input" placeholder="{{ 'ADMIN_SETTINGS.AUDIT_TYPES.ADD_MODAL.FORM.PLACEHOLDER.NAME' | translate }}" type="text" id="title" name="title" [formControl]="addAuditTypeForm.controls['title']"/>
          </div>
          <div class="form__field--lower add-audit-type__field" [class.form__field--error]="isFieldInvalid(addAuditTypeForm.controls['description'])">
            <label class="label add-audit-type__field-label" for="description">{{ 'ADMIN_SETTINGS.AUDIT_TYPES.ADD_MODAL.FORM.LABEL.DESCRIPTION' | translate }}</label>
            <div class="form-field__error">{{ 'ADMIN_SETTINGS.AUDIT_TYPES.ADD_MODAL.FORM.ERROR.DESCRIPTION_EMPTY' | translate }}</div>
            <div class="textarea-container">
              <textarea class="textarea--small textarea--autosize" gwAutosize="gwAutosize" rows="1" id="description" name="description" placeholder="{{ 'ADMIN_SETTINGS.AUDIT_TYPES.ADD_MODAL.FORM.PLACEHOLDER.DESCRIPTION' | translate }}" [formControl]="addAuditTypeForm.controls['description']">{{ addAuditTypeForm.controls['description'].value }}</textarea>
              <div class="textarea-border"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
import { Component, HostListener, Input, OnInit } from '@angular/core';

// Gutwin Shared Library
import { SimpleDropdownDirective } from 'gutwin-shared';

// Services
import { FilterModuleService } from '../../../../../shared/services/filter-module.service';

@Component({
  selector: 'gw-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.scss'],
  exportAs: 'auditLocationFilter'
})
export class LocationFilterComponent implements OnInit {
  @Input() dropdown: SimpleDropdownDirective;
  auditLocationSearchQuery: string;

  constructor(private filterService: FilterModuleService) {}

  ngOnInit() {
    if (this.filterService.auditsFilters?.location) {
      this.auditLocationSearchQuery = this.filterService.auditsFilters.location;
    }
  }

  submitAuditLocationFilter() {
    this.filterService.setFilter('location', this.auditLocationSearchQuery);
    this.dropdown.closeDropdown();
  }

  @HostListener('keydown', ['$event'])
  keydown(event) {
    if (event.key === 'Enter' && this.dropdown.openedWithDelay) {
      event.preventDefault();
      this.dropdown.closeDropdown();
      this.submitAuditLocationFilter();
      return false;
    }
  }
}

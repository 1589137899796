import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import moment from 'moment';
import { timeout } from 'rxjs/operators';

// Gutwin Shared Library
import { FileService } from 'gutwin-shared';

// Models
import { Audit } from '../models/audit.model';
import { AUDITED_AREA_TYPE, AuditedArea } from '../models/audited-area.model';
import { FindingType } from '../models/finding-type.model';
import { FindingsStats, FindingsStatsRating } from '../models/findings-stats.model';
import {
  NOT_ANSWERED_COLOR,
  NOT_ANSWERED_ID,
  QuestionsStats,
  QuestionStatsRating
} from '../models/questions-stats.model';
import { Rating } from '../models/rating.model';

// Providers
import { ApiUrlService } from './api-url.service';
import { FindingOfflineService } from './finding.offline.service';
import { QuestionService } from './question.service';
import { RateRequest, RatingsService } from './ratings.service';

export interface QuestionsStatsResponse {
  audit_id?: string;
  audited_area_id?: string;
  total_questions: { count: number };
  ratings: Array<QuestionStatsRating>;
  not_answered: QuestionStatsRating;
}

export interface FindingsStatsResponse {
  audit_id?: string;
  audited_area_id?: string;
  total_findings: number;
  finding_types: Array<FindingsStatsRating>;
}

@Injectable()
export class AuditSummaryService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService,
    private fileService: FileService,
    private findingOfflineService: FindingOfflineService,
    private questionService: QuestionService,
    private ratingsService: RatingsService,
    private translateService: TranslateService
  ) {}

  getQuestionsStats(auditable: Audit | AuditedArea, withRequests?: boolean): Promise<QuestionsStats> {
    const convertQuestionsStats = async (data: QuestionsStatsResponse): Promise<QuestionsStats> => {
      const ratings = data.ratings;
      if (data.not_answered && data.not_answered.count > 0) {
        const translation = await this.translateService.get('AUDIT_SUMMARY.CHARTS.LABEL.NOT_ANSWERED').toPromise();
        data.not_answered.id = NOT_ANSWERED_ID;
        data.not_answered.name = translation;
        ratings.push(data.not_answered);
      }
      return new QuestionsStats({
        totalQuestions: data.total_questions.count,
        ratings
      });
    };

    const addValueToRatingStat = (stats: QuestionsStats, ratingId: string, value: number): void => {
      const oldRatingStat = _.find(stats.ratings, { id: ratingId ? ratingId : NOT_ANSWERED_ID });
      if (oldRatingStat) {
        oldRatingStat.count += value;
        oldRatingStat.count = oldRatingStat.count < 0 ? 0 : oldRatingStat.count;
      }
    };

    const mergeWithRequests = (stats: QuestionsStats, ratingsRequests: Array<RateRequest>): void => {
      if (ratingsRequests) {
        ratingsRequests.forEach(rating => {
          if (rating.oldRatingId !== rating.ratingId) {
            addValueToRatingStat(stats, rating.oldRatingId, -1);
            addValueToRatingStat(stats, rating.ratingId, 1);

            stats.offline = true;
          }
        });
      }
    };

    const convertResponse = async (data: QuestionsStatsResponse): Promise<QuestionsStats> => {
      const questionsStats = await convertQuestionsStats(data);
      if (withRequests) {
        const ratingsRequests =
          auditable._type === AUDITED_AREA_TYPE
            ? await this.ratingsService.getAuditedAreaRatesRequestsFromOfflineStore(auditable.id)
            : await this.ratingsService.getAuditRatesRequestsFromOfflineStore(auditable as Audit);
        mergeWithRequests(questionsStats, ratingsRequests);
      }
      return questionsStats;
    };

    const getParams = (): HttpParams => {
      let params = new HttpParams();
      if (auditable._type === AUDITED_AREA_TYPE) {
        params = params.set('audited_area_id', auditable.id);
      } else {
        params = params.set('audit_id', auditable.id);
      }
      return params;
    };

    return this.http
      .get(`${this.apiUrlService.statsApi}/questions`, { params: getParams() })
      .toPromise()
      .then(async (data: QuestionsStatsResponse) => convertResponse(data))
      .catch(async error => {
        if (error.status === 0) {
          const data = await this.getQuestionsStatsFromOfflineStore(auditable);
          return convertResponse(data);
        }
        console.error('Error while getting questions statistics', error);
        throw error;
      });
  }

  getFindingsStats(auditable: Audit | AuditedArea, withRequests?: boolean): Promise<FindingsStats> {
    const convertFindingsStats = async (data: FindingsStatsResponse): Promise<FindingsStats> => {
      return new FindingsStats({
        totalFindings: data.total_findings,
        findingTypes: data.finding_types
      });
    };

    const getStatsFromOfflineRequests = async (): Promise<{
      newFindings: number;
      findings: Array<FindingsStatsRating>;
    }> => {
      const stats = {
        newFindings: 0,
        findings: new Array<FindingsStatsRating>()
      };
      const findingsResponse = await this.findingOfflineService.getFindingsFromOfflineStore(
        auditable,
        0,
        undefined,
        -1
      );
      const findingsRequests =
        auditable._type === AUDITED_AREA_TYPE
          ? await this.findingOfflineService.getFindingsRequestsFromOfflineStore(auditable.id)
          : await this.findingOfflineService.getAuditFindingsRequestsFromOfflineStore(auditable as Audit);
      if (findingsResponse && findingsResponse.findings && findingsRequests) {
        findingsRequests.forEach(finding => {
          this.increaseFindingStatByFindingType(stats.findings, finding.relationships.findingType);
          const oldFinding = _.find(findingsResponse.findings, { id: finding.id });
          if (oldFinding) {
            this.increaseFindingStatByFindingType(stats.findings, oldFinding.relationships.findingType, -1);
          } else {
            stats.newFindings++;
          }
        });
      }
      return stats;
    };

    const mergeWithRequestsStats = (
      findingsStats: FindingsStats,
      requestsStats: { newFindings: number; findings: Array<FindingsStatsRating> }
    ): void => {
      findingsStats.totalFindings += requestsStats.newFindings;
      if (requestsStats.findings) {
        requestsStats.findings.forEach(findingStat => {
          if (findingStat.count !== 0) {
            this.increaseFindingStat(findingsStats.findingTypes, findingStat);
            findingsStats.offline = true;
          }
        });
      }
    };

    const convertResponse = async (data: FindingsStatsResponse): Promise<FindingsStats> => {
      const findingsStats = await convertFindingsStats(data);
      if (withRequests) {
        const findingsRequestsStats = await getStatsFromOfflineRequests();
        mergeWithRequestsStats(findingsStats, findingsRequestsStats);
      }
      return findingsStats;
    };

    const getParams = (): HttpParams => {
      let params = new HttpParams();
      if (auditable._type === AUDITED_AREA_TYPE) {
        params = params.set('audited_area_id', auditable.id);
      } else {
        params = params.set('audit_id', auditable.id);
      }
      return params;
    };

    return this.http
      .get(`${this.apiUrlService.statsApi}/findings`, { params: getParams() })
      .toPromise()
      .then((findingsStats: FindingsStatsResponse) => convertResponse(findingsStats))
      .catch(async error => {
        if (error.status === 0) {
          const data = await this.getFindingsStatsFromOfflineStore(auditable);
          return convertResponse(data);
        }
        console.error('Error while getting findings statistics', error);
        throw error;
      });
  }

  generateReport(report: any): Promise<any> {
    return this.http
      .post(`${this.apiUrlService.reportApi}/download_audit_report`, convertReportToPost(), { responseType: 'blob' })
      .pipe(timeout(300000))
      .toPromise()
      .then((data: Blob) => data)
      .catch(error => {
        console.error('Error while generating report', error);
        throw error;
      });

    function convertReportToPost(): any {
      return {
        audit_id: report.auditId,
        company_name: report.company,
        facility_address: report.facility,
        questions_statistics: report.charts ? report.charts.questions : undefined,
        findings_statistics: report.charts ? report.charts.findings : undefined
      };
    }
  }

  downloadReport(blob: Blob, auditName: string): void {
    const fileName = `${moment().format('DD-MM-YYYY')}_${auditName.replace(' ', '_')}.docx`;
    this.fileService.downloadFile(blob, fileName);
  }

  /**
   * Offline support
   */

  async getQuestionsStatsFromOfflineStore(auditable: Audit | AuditedArea): Promise<QuestionsStatsResponse> {
    const getRatingsStats = (ratings: Array<Rating>): Array<QuestionStatsRating> => {
      const ratingsStats = new Array<QuestionStatsRating>();
      if (ratings) {
        ratings.forEach(rating => {
          ratingsStats.push({
            id: rating.id,
            name: rating.name,
            color: rating.color,
            count: 0
          });
        });
      }
      return ratingsStats;
    };

    const incrementRatingStat = (
      ratingsStats: Array<QuestionStatsRating>,
      rating: Rating
    ): Array<QuestionStatsRating> => {
      const ratingStats = _.find(ratingsStats, { id: rating.id });
      if (ratingStats) {
        ratingStats.count++;
      } else {
        ratingsStats.push({
          id: rating.id,
          name: rating.name,
          color: rating.color,
          count: 1
        });
      }
      return ratingsStats;
    };

    const questions = await this.questionService.getQuestionsFromOfflineStore(auditable);
    const ratings = await this.ratingsService.getRatings();
    const questionsStats = {
      total_questions: { count: 0 },
      ratings: getRatingsStats(ratings),
      not_answered: {
        id: NOT_ANSWERED_ID,
        name: '',
        count: 0,
        color: NOT_ANSWERED_COLOR
      }
    };

    if (questions && ratings) {
      questions.forEach(question => {
        questionsStats.total_questions.count++;
        if (question.ratingId) {
          const rating = _.find(ratings, { id: question.ratingId });
          if (rating) {
            incrementRatingStat(questionsStats.ratings, rating);
          }
        } else {
          questionsStats.not_answered.count++;
        }
      });
    }
    return questionsStats;
  }

  async getFindingsStatsFromOfflineStore(auditable: Audit | AuditedArea): Promise<FindingsStatsResponse> {
    const findingsResponse = await this.findingOfflineService.getFindingsFromOfflineStore(auditable, 0, undefined, -1);
    const findingTypes = await this.findingOfflineService.getFindingTypesFromOfflineStore();
    const findingsStats = {
      total_findings: 0,
      finding_types: new Array<FindingsStatsRating>()
    };

    if (findingsResponse && findingsResponse.findings) {
      findingsResponse.findings.forEach(finding => {
        findingsStats.total_findings++;
        if (finding.relationships.findingType) {
          const findingType = _.find(findingTypes, { id: finding.relationships.findingType.id });
          if (findingType) {
            this.increaseFindingStatByFindingType(findingsStats.finding_types, findingType);
          }
        }
      });
    }
    return findingsStats;
  }

  private increaseFindingStatByFindingType(
    findingsStats: Array<FindingsStatsRating>,
    findingType: FindingType,
    value = 1
  ): Array<FindingsStatsRating> {
    const findingStat = {
      id: findingType.id,
      name: findingType.name,
      count: value
    };
    this.increaseFindingStat(findingsStats, findingStat);
    return findingsStats;
  }

  private increaseFindingStat(
    findingsStats: Array<FindingsStatsRating>,
    findingsStatsRating: FindingsStatsRating
  ): Array<FindingsStatsRating> {
    const index = _.findIndex(findingsStats, { id: findingsStatsRating.id });
    if (index !== -1) {
      findingsStats[index].count += findingsStatsRating.count;
      if (findingsStats[index].count === 0) {
        findingsStats.splice(index, 1);
      }
    } else {
      if (findingsStatsRating.count !== 0) {
        findingsStats.push(findingsStatsRating);
      }
    }
    return findingsStats;
  }
}

import moment from 'moment';

import { AttachmentLC } from '../models/attachment-lc.model';
import { Link } from '../models/link.model';

import { RequirementsList } from '../interfaces/requirements.interface';

export type SINGLE_CHANGE_TYPE = 'SingleChange';

export class SingleChange {
  readonly _type: SINGLE_CHANGE_TYPE = 'SingleChange';

  id: string;
  name: string;
  summary: string;
  validFrom: moment.Moment;
  isMajor: boolean;
  attachments: Array<AttachmentLC>;
  links: Array<Link>;
  requirements: RequirementsList;

  constructor(singleChange: Partial<SingleChange>) {
    Object.assign(this, singleChange);
  }
}

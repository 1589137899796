import { ExtraFieldSimple } from './extra-field-simple.model';

import { FilterType } from '../enums/filter-type.enum';
import { RelevanceRatingId } from '../enums/relevance-rating-id.enum';

export class RequirementFilters {
  [FilterType.extraFields]?: Array<ExtraFieldSimple>;
  [FilterType.hideNotRelevant]?: boolean;
  [FilterType.includeSubnodes]?: boolean;
  [FilterType.laws]?: Array<string>;
  [FilterType.legalCategories]?: Array<string>;
  [FilterType.products]?: Array<string>;
  [FilterType.relevanceRatings]?: Array<RelevanceRatingId>;
  [FilterType.search]?: string;
  [FilterType.units]?: Array<string>;

  constructor(requirementFilters?: Partial<RequirementFilters>) {
    Object.assign(this, requirementFilters);
  }
}

import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { DecreaseOpenedEdits, IncreaseOpenedEdits, ResetOpenedEdits, SetEditMode } from './details-page.actions';

export interface DetailsPageModel {
  openedEdits: number;
  isEditMode: boolean;
}

@State<DetailsPageModel>({
  name: 'detailsPage',
  defaults: {
    openedEdits: 0,
    isEditMode: true
  }
})
@Injectable()
export class DetailsPageState {
  constructor() {}

  @Selector()
  static openedEdits(state: DetailsPageModel): number {
    return state.openedEdits;
  }

  @Selector()
  static isEditMode(state: DetailsPageModel): boolean {
    return state.isEditMode;
  }

  @Action(ResetOpenedEdits)
  async resetOpenedEdits(context: StateContext<DetailsPageModel>, action: ResetOpenedEdits): Promise<void> {
    const state = context.getState();
    context.patchState({
      openedEdits: 0
    });
  }

  @Action(IncreaseOpenedEdits)
  async increaseOpenedEdits(context: StateContext<DetailsPageModel>, action: IncreaseOpenedEdits): Promise<void> {
    const state = context.getState();
    context.patchState({
      openedEdits: state.openedEdits + 1
    });
  }

  @Action(DecreaseOpenedEdits)
  async decreaseOpenedEdits(context: StateContext<DetailsPageModel>, action: DecreaseOpenedEdits): Promise<void> {
    const state = context.getState();
    context.patchState({
      openedEdits: state.openedEdits ? state.openedEdits - 1 : 0
    });
  }

  @Action(SetEditMode)
  async setEditMode(context: StateContext<DetailsPageModel>, action: SetEditMode): Promise<void> {
    context.patchState({
      isEditMode: action.value
    });
  }
}

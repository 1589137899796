export class AuditTypesMock {
  getAuditTypes() {
    return [
      {
        id: 'ca22bc20-4fba-47a9-83c6-160a1f3067c2',
        title: 'Environmental audit',
        archived: false,
        description: ''
      },
      {
        id: '5f6139ca-7e36-48e3-b13e-a368c9571176',
        title: 'Marketing audit',
        archived: false,
        description: ''
      },
      {
        id: '7a13d743-e178-454c-bf8e-84b29628015e',
        title: 'Management audit',
        archived: false,
        description: ''
      }
    ];
  }
}

import moment from 'moment';

// Gutwin Shared Library
import { Employee } from 'gutwin-shared';

// Models
import { AuditType } from './audit-type.model';
import { Facility } from './facility.model';
import { Finding } from './finding.model';
import { Questionnaire } from './questionnaire.model';
import { Section } from './section.model';

// Interface
import { EmployeesGroups } from '../interfaces/employeesGroups.interface';

export const AUDITED_AREA_TYPE = 'AuditedArea';

export class AuditedArea {
  id: string;
  auditId: string;
  location: string;
  startTime: moment.Moment;
  endTime: moment.Moment;
  facility: Facility;
  questions: Array<any>;
  employees: EmployeesGroups;
  questionnaires: Array<Questionnaire>;
  selectedSections: Array<Section>;
  selectedQuestionsAmount: number;
  customSections: Array<Section>;
  findings: Array<Finding>;
  summary: string;
  summaryOffline: string;

  _type = AUDITED_AREA_TYPE;

  constructor(audit: any) {
    this.id = audit.id;
    this.auditId = audit.auditId;
    this.location = audit.location;
    this.startTime = moment(audit.startTime);
    this.endTime = moment(audit.endTime);
    if (audit.facility) {
      this.facility = new Facility(audit.facility);
    }
    this.employees = this.generateEmployees(audit.employees);
    this.questionnaires = audit.questionnaires;
    this.selectedSections = audit.selectedSections;
    this.selectedQuestionsAmount = audit.selectedQuestionsAmount || this.countSelectedQuestions();
    this.customSections = audit.customSections;
    this.findings = audit.findings;
    this.summary = audit.summary;
    this.summaryOffline = audit.summaryOffline;
  }

  generateEmployees(employees: EmployeesGroups): EmployeesGroups {
    const newEmployees = {
      creator: new Array<Employee>(),
      organizer: new Array<Employee>(),
      auditor: new Array<Employee>(),
      auditee: new Array<Employee>()
    };
    for (const role in employees) {
      if (employees.hasOwnProperty(role)) {
        employees[role].forEach(employee => {
          newEmployees[role].push(new Employee(employee));
        });
      }
    }
    return newEmployees;
  }

  countSelectedQuestions(): number {
    let amount = 0;
    if (this.selectedSections) {
      this.selectedSections.forEach(section => {
        amount += section.totalQuestions;
      });
    } else if (this.questionnaires) {
      this.questionnaires.forEach(questionnaire => {
        amount += questionnaire.totalQuestionsSelected;
      });
    }
    return amount;
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Requirement } from '../../models/requirement.model';

import { Interval } from '../../interfaces/interval.interface';
import { Titles } from '../../interfaces/titles.interface';

import { UserType } from '../../enums/user-type.enum';

@Component({
  selector: 'gw-requirement-details-main-part',
  templateUrl: './requirement-details-main-part.component.html',
  styleUrls: ['./requirement-details-main-part.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequirementDetailsMainPartComponent {
  @Input() isEditMode: boolean;
  @Input() requirement: Requirement;
  @Input() intervals: Array<Interval>;
  @Input() userType: UserType;

  @Output() updateTitles = new EventEmitter<Titles>();
  @Output() updateInterval = new EventEmitter<Interval>();

  onUpdateTitles(titles: Titles): void {
    this.updateTitles.emit(titles);
  }

  onUpdateInterval(interval: Interval): void {
    this.updateInterval.emit(interval);
  }
}


<gw-lightbox id="lightboxModal" #lightboxModal="gwLightbox"></gw-lightbox>
<gw-modal *ngIf="isMobileOrTablet" id="sidebarModal" #sidebarModal="gwModal" [elements]="{ content: true }" [small]="true" [offCanvas]="true" [noPaddingContent]="true" [backgroundAlabaster]="true" (modalClose)="openedFindingsSection = false">
  <ng-container modal-content="modal-content" *ngIf="sidebarModal.opened">
    <gw-findings-browser *ngIf="findingsSection === 'findings'" #findingsBrowser="gwFindingsBrowser" [auditable]="auditedArea" [auditedAreas]="audit.auditedAreas" [isAuditDisabled]="audit.disabled" (removeFinding)="showRemoveFindingModal($event)">
      <ng-container findings-browser-nav="findings-browser-nav">
        <gw-findings-summary-switch [currentSection]="findingsSection" (toggleSection)="findingsSection = $event"></gw-findings-summary-switch>
      </ng-container>
    </gw-findings-browser>
    <gw-summary *ngIf="findingsSection === 'summary'" #summaryComponent="gwSummary" [auditable]="auditedArea" [auditedAreas]="audit.auditedAreas" [isAuditDisabled]="audit.disabled">
      <ng-container findings-browser-nav="findings-browser-nav">
        <gw-findings-summary-switch [currentSection]="findingsSection" (toggleSection)="findingsSection = $event" [disabled]="summaryComponent.blur"></gw-findings-summary-switch>
      </ng-container>
    </gw-summary>
  </ng-container>
</gw-modal>
<gw-modal id="findingModal" #findingModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">{{ 'AUDIT_CONDUCT.FINDINGS_MODAL.MODAL_HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content" *ngIf="findingModal.opened">
    <gw-add-finding #addFinding="gwAddFinding" [auditedArea]="auditedArea" [sections]="sections" [findingTypes]="findingTypes" [defaultFindable]="findingModal.data.findable" [finding]="findingModal.data.finding" [offlineSupport]="true"></gw-add-finding>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="findingModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" tabindex="7" [disabled]="savingFinding" (click)="saveFinding(findingModal.data.finding)"><i class="button__icon--before button__icon--spinner" *ngIf="savingFinding">
        <div class="spinner"></div></i>{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<gw-modal *ngIf="!audit.disabled" id="sectionModal" #sectionModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="sectionModal.data">{{ 'AUDIT_CONDUCT.CUSTOM_SECTION_MODAL.HEADER.EDIT' | translate }}</ng-container>
    <ng-container *ngIf="!sectionModal.data">{{ 'AUDIT_CONDUCT.CUSTOM_SECTION_MODAL.HEADER.ADD' | translate }}</ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="sectionModal.opened">
    <gw-add-section #addSection="gwAddSection" [composable]="auditedArea" [section]="sectionModal.data"></gw-add-section>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" (click)="sectionModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" [disabled]="savingSection || !offlineService.online" (click)="saveSectionModal()"><i class="button__icon--before button__icon--spinner" *ngIf="savingSection">
        <div class="spinner"></div></i>{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<gw-modal *ngIf="!audit.disabled" id="questionModal" #questionModal="gwModal" [small]="true">
  <ng-container modal-header="modal-header">
    <ng-container *ngIf="questionModal.data?.question">{{ 'AUDIT_CONDUCT.CUSTOM_QUESTION_MODAL.HEADER.EDIT' | translate }}</ng-container>
    <ng-container *ngIf="!questionModal.data?.question">{{ 'AUDIT_CONDUCT.CUSTOM_QUESTION_MODAL.HEADER.ADD' | translate }}</ng-container>
  </ng-container>
  <ng-container modal-content="modal-content" *ngIf="questionModal.opened">
    <gw-add-question #addQuestion="gwAddQuestion" [composable]="auditedArea" [sections]="customSections" [section]="questionModal.data?.section" [question]="questionModal.data?.question"></gw-add-question>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="link" type="button" tabindex="5" (click)="questionModal.close()">{{ 'GLOBAL.ACTION.CANCEL' | translate }}</button>
    <button class="button--primary" type="button" tabindex="6" [disabled]="savingQuestion || !offlineService.online" (click)="saveQuestionModal()"><i class="button__icon--before button__icon--spinner" *ngIf="savingQuestion">
        <div class="spinner"></div></i>{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
  </ng-container>
</gw-modal>
<gw-modal id="duplicationsModal" #duplicationsModal="gwModal">
  <ng-container modal-header="modal-header">{{ 'AUDIT_CONDUCT.QUESTION.DUPLICATIONS_MODAL.HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content">
    <p>{{ 'AUDIT_CONDUCT.QUESTION.DUPLICATIONS_MODAL.LEAD' | translate }}:</p>
    <div class="duplicated-attachments">
      <ng-container *ngFor="let attachment of duplicationsModal.data">
        <gw-attachment class="attachment-container" *ngIf="attachment.isImage" [attachment]="attachment" [viewOnly]="true"></gw-attachment>
      </ng-container>
    </div>
    <div class="duplicated-attachments">
      <ng-container *ngFor="let attachment of duplicationsModal.data">
        <gw-attachment class="attachment-container" *ngIf="!attachment.isImage" [attachment]="attachment" [viewOnly]="true"></gw-attachment>
      </ng-container>
    </div>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="button--primary" type="button" (click)="duplicationsModal.close()">{{ 'GLOBAL.ACTION.CONFIRM' | translate }}</button>
  </ng-container>
</gw-modal>
<gw-audit-top-bar></gw-audit-top-bar>
<gw-page-header>
  <ng-container page-header-left="page-header-left">
    <ul class="header__menu header__menu--left">
      <li class="header__item"><a class="button button--link header__button--transparent header__button--menu" [routerLink]="['/audits']"><i class="button__icon gw-arrow--left"></i></a></li>
      <li class="header__item header__item--title" *ngIf="audit">{{ audit.name }}</li>
      <li class="header__item header__item--offline" *ngIf="audit?.offline &amp;&amp; !audit?.disabled"><span class="offline-icon show-for-medium"><i class="gw-cloud--off offline-icon__icon"></i></span>
        <div class="dropdown header__dropdown hide-for-medium" gwSimpleDropdown="gwSimpleDropdown" #dropdownOfflineIcon="simpleDropdown" [class.dropdown--opened]="dropdownOfflineIcon.opened" [class.dropdown--closed]="!dropdownOfflineIcon.opened &amp;&amp; !dropdownOfflineIcon.firstDrop"><span class="offline-icon"><i class="gw-cloud--off offline-icon__icon"></i></span>
          <div class="dropdown__content jsDropdownContent header-dropdown__content" [style.white-space]="dropdownOfflineIcon.isRightOverflow ? 'pre' : 'nowrap'">
            {{ 'AUDIT_CONDUCT.HEADER.LAST_SYNCED' | translate }}:&nbsp;
            {{ audit.offlineDate | dateFormat: 'MMM D, h:mm a' }}
          </div>
        </div>
      </li>
    </ul>
  </ng-container>
  <ng-container page-header-right="page-header-right">
    <ul class="header__menu header__menu--right header__menu--medium-horizontal">
      <ng-container *ngIf="!audit.disabled">
        <li class="header__item" *ngIf="!audit?.offline">
          <button class="button--tertiary header__button" type="button" (click)="makeOffline(audit)" [disabled]="savingAuditOffline || !offlineService.online"><i class="gw-cloud--off button__icon--before header-button__icon--before"></i><span class="show-for-medium">{{ 'AUDIT_CONDUCT.ACTION.MAKE_AVAILABLE_OFFLINE' | translate }}</span><i class="button__icon--after button__icon--spinner" *ngIf="savingAuditOffline">
              <div class="spinner"></div></i></button>
        </li>
        <li class="header__item header__item--text" *ngIf="audit?.offline"><span class="show-for-medium">
            {{ 'AUDIT_CONDUCT.HEADER.LAST_SYNCED' | translate }}:&nbsp;
            {{ audit.offlineDate | dateFormat: 'MMM D, h:mm a' }}</span>
          <button class="header__button--with-label" type="button" (click)="syncAudit(audit)" [disabled]="syncingAuditOffline || !offlineService.online" [class.button--primary]="offlineService.online" [class.header__button--custom]="offlineService.online" [class.button--tertiary]="!offlineService.online" [class.header__button]="!offlineService.online"><i class="gw-cloud--sync button__icon"></i><i class="button__icon--after button__icon--spinner" *ngIf="syncingAuditOffline">
              <div class="spinner"></div></i></button>
        </li>
      </ng-container>
      <li class="header__item">
        <button class="button--tertiary header__button" type="button" (click)="toggleFindingsSection()" [class.header__button--active]="openedFindingsSection"><i class="gw-findings button__icon--before header-button__icon--before"></i><span class="show-for-medium">{{ 'AUDIT_CONDUCT.ACTION.TOGGLE_FINDINGS_AND_SUMMARY' | translate }}</span></button>
      </li>
    </ul>
  </ng-container>
</gw-page-header>
<div class="row" *ngIf="audit">
  <div class="columns small-12 large-8 content" [class.content--disabled]="loadingPage">
    <div class="conduct-audit__header">
      <div class="row">
        <div class="columns small-12 medium-4 conduct-audit-header__param"><span class="conduct-audit-header__label">{{ 'GLOBAL.TABLE_LABEL.TYPE' | translate }}:&nbsp;</span>
          <ng-container *ngIf="audit?.type">{{ audit.type.title }}</ng-container>
        </div>
        <div class="columns small-12 medium-8 conduct-audit-header__param"><span class="conduct-audit-header__label">{{ 'GLOBAL.TABLE_LABEL.AUDITOR' | translate }}:&nbsp;</span>
          <ng-container *ngFor="let auditor of auditedArea.employees.auditor; last as lastEmployee">{{ auditor.lastname }}, {{ auditor.name }}{{ !lastEmployee ? ';&nbsp;' : '' }}</ng-container>
        </div>
        <div class="columns small-12 conduct-audit-header__param"><span class="conduct-audit-header__label">{{ 'GLOBAL.TABLE_LABEL.QUESTION_RATED' | translate }}:&nbsp;</span>{{ totalRatedQuestions }}/{{ totalQuestions }}</div>
      </div>
      <gw-progress-bar [total]="totalQuestions" [progress]="totalRatedQuestions"></gw-progress-bar>
      <div class="dropdown conduct-audit-header__heading conduct-audit-header__heading--dropdown" *ngIf="hasAuditedAreasToChoose()" gwSimpleDropdown="gwSimpleDropdown" #dropdownAuditedAreas="simpleDropdown" [class.dropdown--opened]="dropdownAuditedAreas.opened" [class.dropdown--closed]="!dropdownAuditedAreas.opened &amp;&amp; !dropdownAuditedAreas.firstDrop">
        <h1 class="conduct-audit-header-heading__title">{{ auditedArea.facility.name }}<i class="gw-caret--down conduct-audit-header-heading__icon" [class.conduct-audit-header-heading__icon--opened]="dropdownAuditedAreas.opened"></i></h1>
        <div class="dropdown__content jsDropdownContent conduct-audit-header__audited-areas">
          <gw-choose-audited-area class="small-12" #chooseAuditedArea="gwChooseAuditedArea" [audit]="audit" [currentAuditedArea]="auditedArea" (chooseAuditedArea)="changeAuditedArea()"></gw-choose-audited-area>
        </div>
      </div>
      <div class="conduct-audit-header__heading" *ngIf="!hasAuditedAreasToChoose()">
        <h1 class="conduct-audit-header-heading__title">{{ auditedArea.facility.name }}</h1>
      </div>
      <div class="row">
        <div class="columns small-12 medium-3 conduct-audit-header__param"><span class="conduct-audit-header__label">{{ 'GLOBAL.TABLE_LABEL.TIME' | translate }}:&nbsp;</span>{{ auditedArea.startTime | dateFormat: 'HH:mm' }} — {{ auditedArea.endTime | dateFormat: 'HH:mm' }}</div>
        <div class="columns small-12 medium-4 conduct-audit-header__param"><span class="conduct-audit-header__label">{{ 'GLOBAL.TABLE_LABEL.LOCATION' | translate }}:&nbsp;</span>{{ auditedArea.location }}</div>
        <div class="columns small-12 medium-4 conduct-audit-header__param"><span class="conduct-audit-header__label">{{ 'GLOBAL.TABLE_LABEL.AUDITEES' | translate }}:&nbsp;</span>
          <ng-container *ngFor="let auditee of auditedArea.employees.auditee; last as lastEmployee">{{ auditee.lastname }}, {{ auditee.name }}{{ !lastEmployee ? ';&nbsp;' : '' }}</ng-container>
        </div>
      </div>
    </div>
    <div>
      <div class="flex-table conduct-table">
        <div class="flex-table__row conduct-table__header-row show-for-medium">
          <div class="flex-table__cell conduct-table__cell--2 conduct-table__radio-header"><span class="radio-header__label" *ngFor="let rating of ratings">{{ rating.abbreviation }}</span></div>
          <div class="flex-table__cell conduct-table__cell--3 hide-for-large"></div>
        </div>
        <ng-container *ngFor="let section of sections">
          <div class="flex-table__row">
            <div class="flex-table__cell conduct-table__section">
              <div class="conduct-table__column">{{ section.name }}</div>
              <div class="conduct-table__column" *ngIf="section.custom &amp;&amp; !audit.disabled"><span class="dropdown dropdown--menu" gwSimpleDropdown="gwSimpleDropdown" #sectionDropdownMenu="simpleDropdown" [class.dropdown--opened]="sectionDropdownMenu.opened" [class.dropdown--closed]="!sectionDropdownMenu.opened &amp;&amp; !sectionDropdownMenu.firstDrop">
                  <button class="button--icon item__options conduct-table__dropdown-button" type="button"><i class="gw-more item-options__icon"></i></button>
                  <div class="dropdown__content jsDropdownContent">
                    <ul class="dropdown-menu__list">
                      <li class="dropdown-menu__item">
                        <button class="link dropdown-menu__link" type="button" (click)="showSectionModal(section)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                      </li>
                      <li class="dropdown-menu__item">
                        <button class="link dropdown-menu__link" type="button" (click)="showRemoveSectionModal(section)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                      </li>
                    </ul>
                  </div></span></div>
            </div>
          </div>
          <ng-container *ngFor="let question of section.questions">
            <gw-expand-box #questionExpandBox="gwExpandBox">
              <ng-container expand-box-header="expand-box-header">
                <div class="question__mask" [class.question__mask--opened]="questionExpandBox.opened" (click)="questionExpandBox.toggle()"></div>
                <div class="flex-table__row conduct-table__question" [class.conduct-table__question--opened]="questionExpandBox.opened" [class.conduct-table__question--answered]="question.ratingId || question.ratingIdOffline">
                  <div class="flex-table__cell conduct-table__cell--1" [ngClass]="'conduct-table__cell--ratings-' + ratings?.length" [style.border-left-color]="getColorOfRating(question)">
                    <div class="conduct-table-cell__header" (click)="questionExpandBox.toggle()"><i class="gw-caret--down h__icon--before expand-mask__toggle-icon conduct-table__caret"></i>{{ question.content }}<i class="gw-warning conduct-table__icon" *ngIf="question.hasFindings || question.hasFindingsOffline.all" [class.conduct-table__icon--light]="question.hasFindingsOffline.toRemove"></i><i class="gw-comment conduct-table__icon" *ngIf="question.responseMediaId || question.responseMediaOffline"></i></div>
                  </div>
                  <div class="flex-table__cell conduct-table__cell--2">
                    <div class="conduct-audit__radio-button" *ngFor="let rating of ratings">
                      <label class="conduct-audit-radio-button__label hide-for-medium">{{ rating.abbreviation }}</label>
                      <div class="conduct-audit-radio-button__undo-flag" *ngIf="question.ratingIdOffline === rating.id"><i class="gw-arrow--undo"></i></div>
                      <gw-radio-button *ngIf="question.ratingIdOffline" [value]="rating.id" [color]="rating.color" [ngModel]="question.ratingIdOffline" [ngModelOptions]="{standalone: true}" [disabled]="audit.disabled" (ngModelChange)="updateRating(rating.id, question)"></gw-radio-button>
                      <gw-radio-button *ngIf="!question.ratingIdOffline" [value]="rating.id" [color]="rating.color" [ngModel]="question.ratingId" [ngModelOptions]="{standalone: true}" [disabled]="audit.disabled" (ngModelChange)="updateRating(rating.id, question)"></gw-radio-button>
                    </div>
                    <div class="conduct-audit__rating-offline-icon offline-icon hide-for-medium" *ngIf="question.offline"><i class="gw-cloud--off offline-icon__icon"></i></div>
                  </div>
                  <div class="flex-table__cell conduct-table__cell--3 show-for-medium" [class.conduct-table__cell--bordered]="question.offline">
                    <div class="offline-icon" *ngIf="question.offline"><i class="gw-cloud--off offline-icon__icon"></i></div>
                  </div>
                </div>
              </ng-container>
              <ng-container expand-box-content="expand-box-content">
                <div class="conduct-table-question__details"><span class="dropdown dropdown--menu conduct-table-question__dropdown" *ngIf="question.custom &amp;&amp; !audit.disabled" gwSimpleDropdown="gwSimpleDropdown" #questionDropdownMenu="simpleDropdown" [class.dropdown--opened]="questionDropdownMenu.opened" [class.dropdown--closed]="!questionDropdownMenu.opened &amp;&amp; !questionDropdownMenu.firstDrop">
                    <button class="button--icon" type="button"><i class="gw-more item-options__icon"></i></button>
                    <div class="dropdown__content jsDropdownContent">
                      <ul class="dropdown-menu__list">
                        <li class="dropdown-menu__item">
                          <button class="link dropdown-menu__link" type="button" (click)="showQuestionModal(section, question)">{{ 'GLOBAL.ACTION.EDIT' | translate }}</button>
                        </li>
                        <li class="dropdown-menu__item">
                          <button class="link dropdown-menu__link" type="button" (click)="showRemoveQuestionModal(section, question)">{{ 'GLOBAL.ACTION.REMOVE' | translate }}</button>
                        </li>
                      </ul>
                    </div></span>
                  <gw-question-details *ngIf="questionExpandBox.firstOpened" [question]="question" [auditedArea]="auditedArea" [isAuditDisabled]="audit.disabled" (removeFinding)="showRemoveFindingModal($event)"></gw-question-details>
                </div>
              </ng-container>
            </gw-expand-box>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="text-right conduct-audit__actions"><a class="button--primary" [routerLink]="['/audits/audit-summary', audit.id, auditedArea.id]" (click)="saveRedirect()">{{ 'AUDIT_CONDUCT.ACTION.GO_TO_SUMMARY' | translate }}</a></div>
  </div>
  <div class="columns small-12 large-1 text-center">
    <div class="conduct-audit__fixed-container" *ngIf="!audit.disabled"><span class="dropdown dropdown--menu conduct-audit__fixed-dropdown" gwSimpleDropdown="gwSimpleDropdown" #dropdownMenu="simpleDropdown" [class.dropdown--opened]="dropdownMenu.opened" [class.dropdown--closed]="!dropdownMenu.opened &amp;&amp; !dropdownMenu.firstDrop">
        <button class="conduct-audit__fixed-button" type="button"><i class="gw-plus--bold"></i></button>
        <div class="dropdown__content jsDropdownContent conduct-audit__fixed-dropdown-content">
          <ul class="dropdown-menu__list">
            <li class="dropdown-menu__item" *ngIf="offlineService.online">
              <button class="link dropdown-menu__link conduct-audit__fixed-dropdown-link" type="button" (click)="showSectionModal()">{{ 'AUDIT_CONDUCT.ACTION.ADD_CHAPTER' | translate }}</button>
            </li>
            <li class="dropdown-menu__item" *ngIf="offlineService.online">
              <button class="link dropdown-menu__link conduct-audit__fixed-dropdown-link" type="button" (click)="showQuestionModal()">{{ 'AUDIT_CONDUCT.ACTION.ADD_QUESTION' | translate }}</button>
            </li>
            <li class="dropdown-menu__item">
              <button class="link dropdown-menu__link conduct-audit__fixed-dropdown-link" type="button" (click)="showFindingModal(auditedArea)">{{ 'AUDIT_CONDUCT.ACTION.ADD_FINDING' | translate }}</button>
            </li>
            <li class="dropdown-menu__item">
              <button class="link dropdown-menu__link conduct-audit__fixed-dropdown-link--close" type="button" (click)="dropdownMenu.closeDropdown()"><i class="gw-close"></i></button>
            </li>
          </ul>
        </div></span></div>
  </div>
  <div class="columns small-12 large-3 conduct-audit__findings-browser" *ngIf="openedFindingsSection &amp;&amp; !isMobileOrTablet">
    <gw-findings-browser *ngIf="findingsSection === 'findings'" #findingsBrowser="gwFindingsBrowser" [auditable]="auditedArea" [auditedAreas]="audit.auditedAreas" [isAuditDisabled]="audit.disabled" (removeFinding)="showRemoveFindingModal($event)">
      <ng-container findings-browser-nav="findings-browser-nav">
        <gw-findings-summary-switch [currentSection]="findingsSection" (toggleSection)="findingsSection = $event"></gw-findings-summary-switch>
      </ng-container>
    </gw-findings-browser>
    <gw-summary *ngIf="findingsSection === 'summary'" #summaryComponent="gwSummary" [auditable]="auditedArea" [auditedAreas]="audit.auditedAreas" [isAuditDisabled]="audit.disabled">
      <ng-container findings-browser-nav="findings-browser-nav">
        <gw-findings-summary-switch [currentSection]="findingsSection" (toggleSection)="findingsSection = $event" [disabled]="summaryComponent.blur"></gw-findings-summary-switch>
      </ng-container>
    </gw-summary>
  </div>
</div>
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'gw-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalContentComponent {
  /**
   * Set modal content full height
   */
  @HostBinding('class.modal-content--full-height') @Input() fullHeight = true;

  /**
   * Use larger header font
   */
  @Input() bigHeader = false;
  /**
   * Show modal's header
   */
  @Input() header = true;
  /**
   * Show modal's content
   */
  @Input() content = true;
  /**
   * Show modal's actions
   */
  @Input() actions = true;
  /**
   * Set smaller button top space
   */
  @Input() smallerButtonTopSpace?: boolean;
}

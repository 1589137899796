import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output
} from '@angular/core';

@Component({
  selector: 'gw-expand-box',
  templateUrl: './expand-box.component.html',
  styleUrls: ['./expand-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'gwExpandBox',
  animations: [
    trigger('expand', [
      state('out', style({ height: 0 })),
      state('in', style({ height: '*' })),
      transition('in <=> out', [animate('0.3s ease-in')])
    ])
  ]
})
export class ExpandBoxComponent implements OnDestroy {
  @HostBinding('@.disabled')
  @Input()
  animationsDisabled = false;
  @Input() hideHeader = false;
  @Input() hasMask = false;
  @Input() hasShadow: boolean | 'always' = false;
  @Input() isParentFullyClosed = true;
  @Input() set opened(opened: boolean) {
    if (opened !== this.opened) {
      if (opened) {
        this.open();
      } else {
        this.close();
      }
    }
  }
  get opened(): boolean {
    return this._opened;
  }
  _opened = false;

  @Output() openExpandBox = new EventEmitter<void>();

  openedWithDelay = false;
  firstOpened = false;
  closed = true;
  fullyClosed = true;
  inAction = false;

  destroyed = false;

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.destroyed = true;
    this.changeDetector.detach();
  }

  open(): void {
    this.fullyClosed = false;
    this.closed = false;
    this._opened = true;
    this.firstOpened = true;
    this.changeDetector.detectChanges();
    this.openExpandBox.emit();
  }

  close(): void {
    this._opened = false;
    this.closed = true;
    this.openedWithDelay = false;
    this.changeDetector.detectChanges();
  }

  toggle(): void {
    if (!this.inAction) {
      this.inAction = true;
      if (this.opened) {
        this.close();
      } else {
        this.open();
      }
    }
  }

  doneToggle(event: any): void {
    this.inAction = false;
    if (event.toState === 'in') {
      this.openedWithDelay = true;
    } else if (event.toState === 'out') {
      this.fullyClosed = true;
    }

    if (!this.destroyed) {
      // Has to be called here to prevent detecting changes after component destroy
      // Issue: https://github.com/angular/angular/issues/21546
      this.changeDetector.detectChanges();
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { EmployeeExtended } from '../../models/employee-extended.model';

@Component({
  selector: 'gw-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UsersListComponent {
  @Input() employees: Array<EmployeeExtended>;
  @Output() editUser = new EventEmitter<EmployeeExtended>();
  @Output() removeUser = new EventEmitter<EmployeeExtended>();
  @Output() resetPassword = new EventEmitter<EmployeeExtended>();
  @Output() editUserRoles = new EventEmitter<EmployeeExtended>();
  activeEmployeeRoles: string;

  onEditUser(employee: EmployeeExtended): void {
    this.editUser.emit(employee);
  }

  onRemoveUser(employee: EmployeeExtended): void {
    this.removeUser.emit(employee);
  }

  onResetPassword(employee: EmployeeExtended): void {
    this.resetPassword.emit(employee);
  }

  onEditUserRoles(employee: EmployeeExtended): void {
    this.editUserRoles.emit(employee);
  }

  setActiveEmployeeRoles(employeeId: string): void {
    this.activeEmployeeRoles = employeeId;
  }

  clearActiveEmployeeRoles(): void {
    this.activeEmployeeRoles = undefined;
  }
}

import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { ListControls } from '../../interfaces/list-controls.interface';
import { SortOption } from '../../interfaces/sort-option.interface';

import { PageLimit } from '../../enums/page-limit.enum';
import { Sort } from '../../enums/sort.enum';
import { UserType } from '../../enums/user-type.enum';

import { GridService } from '../../services/grid.service';

@Component({
  selector: 'gw-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  @ViewChild('gridList', { static: true }) gridList: ElementRef;

  @Input() classicSearch?: boolean;
  @Input() hideHeader = false;
  @Input() hideMatchingCount = false;
  @Input() heading?: string;
  @Input() boldedHeading?: string;
  @Input() bigHeading = false;
  @Input() greyHeading = false;
  @Input() hideElementsLabel: string;
  @Input() noTopMargin = false;
  @Input() noBottomMargin = false;
  @Input() marginListOut = false;
  @Input() buttonLabel: string;
  @Input() secondButtonLabel: string;
  @Input() buttonStyle: string;
  @Input() secondButtonStyle: string;
  @Input() total: number;
  @Input() currentPage: number;
  @Input() currentLimit: number;
  @Input() isPagination = true;
  @Input() droppable: boolean;
  @Input() hasOwnGrid = true;
  @Input() sortOptions?: Array<SortOption>;
  @Input() selectedSortOption?: SortOption;
  @Input() searchValue?: string;
  @Input() sortOrder?: Sort;
  @Input() withLightToggleSwitch = true;
  @Input() controls: ListControls;
  @Input() userType: UserType;
  @Input() areElementsHidden: boolean;
  @Input() showPaginationCount?: boolean;
  @Input() showIfLessThanPageLimit = false;

  @Output() changePage = new EventEmitter<number>();
  @Output() changeLimit = new EventEmitter<number>();
  @Output() toggleExpandAll = new EventEmitter<boolean>();
  @Output() clickButton = new EventEmitter<void>();
  @Output() clickSecondButton = new EventEmitter<void>();
  @Output() toggleSortOrder = new EventEmitter<Sort>();
  @Output() changeSortOption = new EventEmitter<SortOption>();
  @Output() scrollToTopOfList = new EventEmitter<void>();
  @Output() selectMultiple = new EventEmitter<boolean>();
  @Output() hideElements = new EventEmitter<boolean>();
  @Output() updateSearch = new EventEmitter<string>();

  readonly PAGE_LIMIT_MIN = PageLimit.min;

  constructor(private gridService: GridService) {}

  ngOnInit(): void {
    if (this.hasOwnGrid) {
      this.gridService.resizeObserver.observe(this.gridList.nativeElement);
    }
  }

  ngOnDestroy(): void {
    if (this.hasOwnGrid) {
      this.gridService.resizeObserver.unobserve(this.gridList.nativeElement);
    }
  }

  emitToggleExpandAll(value: boolean): void {
    this.toggleExpandAll.emit(value);
  }

  emitChangePage(page: number): void {
    this.changePage.emit(page);
    this.onScrollToTopOfList();
  }

  emitChangeLimit(limit: number): void {
    this.changeLimit.emit(limit);
    this.onScrollToTopOfList();
  }

  onScrollToTopOfList(): void {
    this.scrollToTopOfList.emit();
  }

  onChangeSortOption(sortOption: SortOption): void {
    this.changeSortOption.emit(sortOption);
  }

  onToggleSortOrder(sortOrder: Sort): void {
    this.toggleSortOrder.emit(sortOrder);
  }

  onClickButton(): void {
    this.clickButton.emit();
  }

  onClickSecondButton(): void {
    this.clickSecondButton.emit();
  }

  onSelectMultiple(selectMultiple: boolean): void {
    this.selectMultiple.emit(selectMultiple);
  }
}

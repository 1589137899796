import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as dataURLtoBlob from 'blueimp-canvas-to-blob';

// Gutwin Shared Library
import { Attachment, FileService, ModalService } from 'gutwin-shared';

// Models
import { Company } from '../../../shared/models/company.model';

// Services
import { CompanyService } from '../../../shared/services/company.service';

@Component({
  selector: 'gw-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.scss']
})
export class CompanySettingsComponent implements OnInit {
  @ViewChild('fileInput') logoFileInput: ElementRef;
  company: Company;
  companyForm: FormGroup;
  uploadedImage = { preview: '' };
  saving: boolean;
  notify = {
    error: {
      connection: '',
      uploadCompanyLogo: '',
      removeCompanyLogo: '',
      wrongCompanyLogoRatioTitle: '',
      wrongCompanyLogoRatioText: ''
    },
    success: {
      title: '',
      uploadCompanyLogo: '',
      removeCompanyLogo: ''
    }
  };

  constructor(
    private companyService: CompanyService,
    private fileService: FileService,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.company = this.route.snapshot.data['company'];
    this.fetchTranslation();
    if (this.company) {
      this.initForm();
    }
  }

  initForm(): void {
    this.companyForm = this.formBuilder.group({
      logo: [this.company.logo ? this.company.logo : '']
    });
  }

  setControlValue(data: any, key: string): void {
    this.companyForm.controls[key].setValue(data);
  }

  async attachFiles(event: Event): Promise<void> {
    this.fileService.attachFiles(event).then((base64: Array<string>) => {
      this.modalService.open('cropLogoModal', base64[0]);
      this.fileService.resetFileInput(this.logoFileInput);
    });
  }

  async acceptImage(imageUrl: string): Promise<void> {
    const setLogoInForm = () => {
      const imageBlob = dataURLtoBlob(imageUrl);
      imageBlob.name = this.company.name;
      const logo = new Attachment({
        file: imageBlob,
        name: this.company.name
      });
      this.setControlValue(logo, 'logo');
    };

    this.modalService.close('cropLogoModal');
    this.uploadedImage.preview = imageUrl;
    setLogoInForm();
    await this.submitCompanyForm(this.companyForm);
  }

  async submitCompanyForm(form: FormGroup): Promise<void> {
    if (form.valid) {
      this.saving = true;
      const companyToUpdate = new Company({
        id: this.company.id,
        logo: form.value.logo
      });

      await this.companyService
        .uploadCompanyLogo(companyToUpdate)
        .then((company: Company) => {
          this.company = company;
          this.initForm();
          this.notificationsService.success(this.notify.success.title, this.notify.success.uploadCompanyLogo);
        })
        .catch(error => {
          this.notificationsService.error(this.notify.error.connection, this.notify.error.uploadCompanyLogo);
        });

      this.uploadedImage.preview = '';
      this.saving = false;
    }
  }

  async removeCompanyLogo(): Promise<void> {
    const companyToUpdate = new Company({
      id: this.company.id,
      logo: null
    });

    await this.companyService
      .uploadCompanyLogo(companyToUpdate)
      .then((company: Company) => {
        this.company = company;
        this.initForm();
        this.notificationsService.success(this.notify.success.title, this.notify.success.removeCompanyLogo);
      })
      .catch(error => {
        this.uploadedImage.preview = '';
        this.notificationsService.error(this.notify.error.connection, this.notify.error.removeCompanyLogo);
      });
  }

  keyDownFileControl(event: any, fileInput: any): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      fileInput.click(event);
    }
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.ERROR.UPLOAD_COMPANY_LOGO',
        'ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.ERROR.REMOVE_COMPANY_LOGO',
        'ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.ERROR.WRONG_COMPANY_LOGO_RATIO.TITLE',
        'ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.ERROR.WRONG_COMPANY_LOGO_RATIO.TEXT',
        'GLOBAL.ACTION.SUCCESS',
        'ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.SUCCESS.UPLOAD_COMPANY_LOGO',
        'ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.SUCCESS.REMOVE_COMPANY_LOGO'
      ])
      .toPromise();
    this.notify.error.connection = translation['GLOBAL.ERROR.CONNECTION'];
    this.notify.error.uploadCompanyLogo = translation['ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.ERROR.UPLOAD_COMPANY_LOGO'];
    this.notify.error.removeCompanyLogo = translation['ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.ERROR.REMOVE_COMPANY_LOGO'];
    this.notify.error.wrongCompanyLogoRatioTitle =
      translation['ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.ERROR.WRONG_COMPANY_LOGO_RATIO.TITLE'];
    this.notify.error.wrongCompanyLogoRatioText =
      translation['ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.ERROR.WRONG_COMPANY_LOGO_RATIO.TEXT'];
    this.notify.success.title = translation['GLOBAL.ACTION.SUCCESS'];
    this.notify.success.uploadCompanyLogo =
      translation['ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.SUCCESS.UPLOAD_COMPANY_LOGO'];
    this.notify.success.removeCompanyLogo =
      translation['ADMIN_SETTINGS.COMPANY_SETTINGS.FORM.SUCCESS.REMOVE_COMPANY_LOGO'];
  }
}

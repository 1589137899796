import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
// Gutwin Shared Library
import { Employee } from 'gutwin-shared';
// Models
import { Audit } from '../models/audit.model';
// Interfaces
import { EmployeesGroups } from './../interfaces/employeesGroups.interface';
// Services
import { ApiUrlService } from './api-url.service';

@Injectable()
export class EmailService {
  constructor(
    private http: HttpClient,
    private apiUrlService: ApiUrlService
  ) { }

  sendEmailsForConductAudit(audit: Audit, employees: EmployeesGroups): Promise<any> {
    let params = new HttpParams();
    params = params.set('audit_id', audit.id);
    if (employees) {
      setAuditorsIdsParams(employees.auditor);
      setAuditorsIdsParams(employees.creator);
      setAuditorsIdsParams(employees.organizer);
      setAuditeesIdsParams(employees.auditee);
    }

    return this.http.get(`${this.apiUrlService.emailApi}/send_audit_conduct_emails`, { params: params })
      .toPromise()
      .then(data => data)
      .catch(error => {
        console.error('Error while sending emails for conduct audit', error);
        throw error;
      });

    function setAuditorsIdsParams(auditors: Array<Employee>): void {
      if (auditors) {
        for (const auditor of auditors) {
          params = params.append('auditors_ids[]', auditor.id);
        }
      }
    }

    function setAuditeesIdsParams(auditees: Array<Employee>): void {
      if (auditees) {
        for (const auditee of auditees) {
          params = params.append('auditees_ids[]', auditee.id);
        }
      }
    }
  }
}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { LoginService } from './login.service';
import { StorageService, StoragesNames } from './storage.service';

@Injectable()
export class HttpClientInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService, private loginService: LoginService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.getToken();
    const authRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    const requestToSend = token ? authRequest : request;

    return next.handle(requestToSend).pipe(
      filter(event => event instanceof HttpResponse),
      map((event: HttpResponse<any>) => this.checkIfAuthorized(event)),
      catchError(error => this.handleErrors(error))
    );
  }

  getToken(): Promise<string> {
    return this.storageService.getLocalStorage(StoragesNames.token);
  }

  checkIfAuthorized(event: HttpResponse<any>): HttpResponse<any> {
    const is401 = event.status === 401 || event.body?.errors?.find(error => error.extensions?.code === 'UNAUTHORIZED');
    if (is401) {
      throw new HttpErrorResponse({ status: 401, error: event.body.errors });
    }
    return event;
  }

  handleErrors(error: any): Observable<never> {
    if (error.status === 401) {
      this.loginService.logOut();
    }

    return observableThrowError(error);
  }
}

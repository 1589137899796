import { Country } from './country.model';

export class Product {
  id: string;
  name: string;
  description?: string;
  archived?: boolean;
  country?: Country;

  constructor(product: Partial<Product>) {
    this.id = product.id;
    this.name = product.name;
    this.description = product.description;
    this.archived = product.archived;
    this.country = product.country ? new Country(product.country) : undefined;
  }
}

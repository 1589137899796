import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Subscription } from 'rxjs';

// Models
import { Finding, FindingStatus } from '../../shared/models/finding.model';
import { Solving } from '../../shared/models/solving.model';

// Services
import { FindingService } from '../../shared/services/finding.service';
import { PermissionService } from '../../shared/services/permission.service';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'gw-finding-view',
  templateUrl: './finding-view.component.html',
  styleUrls: ['./finding-view.component.scss']
})
export class FindingViewComponent implements OnInit, OnDestroy {
  @ViewChild('solvedFindingBox') solvedFindingBox: ElementRef;
  finding: Finding;
  permissions = {
    manageFinding: false,
    manageCauseAndSolution: false,
    solveFinding: false
  };
  solveSectionOpened = false;
  findingSolvingData: Solving;
  scrollToSolvedFindingBox: boolean;
  savingSolving = false;
  notify = {
    error: {
      connection: '',
      solvingText: '',
      getSolvingText: '',
      loadAudit: '',
      loadAuditedArea: ''
    },
    success: {
      solvingTitle: '',
      solvingText: ''
    }
  };
  translateSubscription: Subscription;
  userSubscription: Subscription;

  constructor(
    private findingService: FindingService,
    private notificationsService: NotificationsService,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.fetchTranslation();

    this.finding = this.route.snapshot.data['finding'];
    if (this.finding.status === 'solved') {
      this.fetchSolving(this.finding);
    }

    this.initPermissions();
    this.userSubscription = this.userService.storedUserObservable.subscribe(() => {
      this.initPermissions();
    });
  }

  ngOnDestroy(): void {
    this.translateSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }

  fetchSolving(finding: Finding): void {
    this.findingService
      .getSolving(finding)
      .then((data: Solving) => {
        this.findingSolvingData = data;
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connection, this.notify.error.getSolvingText);
      });
  }

  initPermissions(): void {
    this.permissions.manageFinding = this.permissionService.canFindingBeManaged(
      this.finding,
      this.userService.storedUser,
      true
    );
    this.permissions.manageCauseAndSolution = this.permissionService.canCauseAndSolutionBeManaged(
      this.userService.storedUser
    );
    this.permissions.solveFinding =
      this.permissionService.canFindingBeSolved(this.finding, this.userService.storedUser) ||
      this.permissions.manageFinding;
  }

  updateFinding(finding: Finding): void {
    this.finding.relationships.employee = finding.relationships.employee;
    this.finding.status = finding.status;
    this.initPermissions();
  }

  solveFinding(solvingData: Solving): void {
    this.savingSolving = true;
    solvingData.findingId = this.finding.id;

    this.findingService
      .solveFinding(solvingData)
      .then((data: Solving) => {
        this.findingSolvingData = data;
        this.finding.status = FindingStatus.solved;
        this.scrollToSolvedFindingBox = true;
        this.notificationsService.success(this.notify.success.solvingTitle, this.notify.success.solvingText);
        this.savingSolving = false;
      })
      .catch(error => {
        this.notificationsService.error(this.notify.error.connection, this.notify.error.solvingText);
        this.savingSolving = false;
      });
  }

  toggleSolveSection(): void {
    this.solveSectionOpened = !this.solveSectionOpened;
  }

  solvedFindingBoxChecked(): void {
    if (this.scrollToSolvedFindingBox && this.solvedFindingBox) {
      this.scrollToSolvedFindingBox = false;
      this.solvedFindingBox.nativeElement.scrollIntoView(true);
    }
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ERROR.LOAD_AUDIT',
        'GLOBAL.ERROR.LOAD_AUDITED_AREA',
        'FINDING_VIEW.SOLVE_FINDING.ERROR.SOLVING_TEXT',
        'FINDING_VIEW.SOLVE_FINDING.ERROR.GET_SOLVING_TEXT',
        'FINDING_VIEW.SOLVE_FINDING.SUCCESS.SOLVING_TITLE',
        'FINDING_VIEW.SOLVE_FINDING.SUCCESS.SOLVING_TEXT'
      ])
      .subscribe((translation: any) => {
        this.notify.error.connection = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.loadAudit = translation['GLOBAL.ERROR.LOAD_AUDIT'];
        this.notify.error.loadAuditedArea = translation['GLOBAL.ERROR.LOAD_AUDITED_AREA'];
        this.notify.error.solvingText = translation['FINDING_VIEW.SOLVE_FINDING.ERROR.SOLVING_TEXT'];
        this.notify.error.getSolvingText = translation['FINDING_VIEW.SOLVE_FINDING.ERROR.GET_SOLVING_TEXT'];
        this.notify.success.solvingTitle = translation['FINDING_VIEW.SOLVE_FINDING.SUCCESS.SOLVING_TITLE'];
        this.notify.success.solvingText = translation['FINDING_VIEW.SOLVE_FINDING.SUCCESS.SOLVING_TEXT'];
      });
  }
}

import { Section } from './section.model';

export class Questionnaire {
  id: string;
  name: string;
  orderedSectionsIds: Array<any>;
  totalSections: number;
  totalQuestions: number;
  totalQuestionsSelected: number;
  sections: Array<Section>;
  added: boolean;
  addedToParent: boolean;

  _type = 'Questionnaire';

  constructor(questionnaire: any) {
    this.id = questionnaire.id;
    this.name = questionnaire.name;
    this.orderedSectionsIds = questionnaire.orderedSectionsIds;
    this.totalSections = questionnaire.totalSections;
    this.totalQuestions = questionnaire.totalQuestions;
    this.totalQuestionsSelected = questionnaire.totalQuestionsSelected;
    this.sections = this.generateSections(questionnaire.sections);
    this.added = questionnaire.added;
    this.addedToParent = questionnaire.addedToParent;
  }

  generateSections(sectionsRequest: Array<any>): Array<Section> {
    const sections = new Array<Section>();
    if (sectionsRequest) {
      sectionsRequest.forEach(section => {
        sections.push(new Section(section));
      });
    }
    return sections;
  }
}

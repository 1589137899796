import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { AdminComponent } from './admin.component';
import { AuditTypesComponent } from './views/audit-types/audit-types.component';
import { CompanySettingsComponent } from './views/company-settings/company-settings.component';
import { CompanyComponent } from './views/company/company.component';
import { FacilitiesComponent } from './views/facilities/facilities.component';
import { FindingTypesComponent } from './views/finding-types/finding-types.component';
import { RatingScalesComponent } from './views/rating-scales/rating-scales.component';
import { ReferenceDocumentsComponent } from './views/reference-documents/reference-documents.component';
import { ReportsComponent } from './views/reports/reports.component';
import { RolesComponent } from './views/roles/roles.component';
import { SingleRatingScaleComponent } from './views/single-rating-scale/single-rating-scale.component';
import { SingleReferenceDocumentComponent } from './views/single-reference-document/single-reference-document.component';
import { UsersComponent } from './views/users/users.component';

// Guards
import { AdminReportsGuard } from '@gutwin-audit/admin/guards/admin.reports.guard';
import { LoginChildGuard } from '../shared/guards/login.child.guard';
import { OfflineChildGuard } from '../shared/guards/offline.child.guard';
import { LoginGuardSuperAdmin } from '../shared/guards/super-admin.guard';
import { AdminAuditTypesGuard } from './guards/admin.audit-types.guard';
import { AdminCompanyGuard } from './guards/admin.company.guard';
import { AdminFacilitiesGuard } from './guards/admin.facilities.guard';
import { AdminFindingTypesGuard } from './guards/admin.finding-types.guard';
import { AdminQustionnairesGuard } from './guards/admin.questionnaires.guard';
import { AdminRatingScaleGuard } from './guards/admin.rating-scale.guard';
import { AdminRolesGuard } from './guards/admin.roles.guard';
import { AdminUsersGuard } from './guards/admin.users.guard';

// Resolvers
import { RatingScaleResolve } from '../shared/resolvers/rating-scale.resolve';
import { AuditTypesResolve } from './resolvers/audit-types.resolve';
import { CompanyResolve } from './resolvers/company.resolve';
import { EmployeesResolve } from './resolvers/employees.resolve';
import { FacilitiesResolve } from './resolvers/facilities.resolve';
import { FindingTypesResolve } from './resolvers/finding-types.resolve';
import { QuestionnaireResolve } from './resolvers/questionnaire.resolve';
import { QuestionnairesResolve } from './resolvers/questionnaires.resolve';
import { RatingScalesResolve } from './resolvers/rating-scales.resolve';
import { ReportsResolve } from './resolvers/reports.resolve';
import { RolesResolve } from './resolvers/roles.resolve';
import { SectionsResolve } from './resolvers/sections.resolve';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivateChild: [LoginChildGuard, OfflineChildGuard],
    children: [
      {
        path: '',
        redirectTo: 'questionnaires',
        pathMatch: 'full'
      },
      {
        path: 'questionnaires',
        canActivate: [AdminQustionnairesGuard],
        children: [
          {
            path: '',
            component: ReferenceDocumentsComponent,
            resolve: {
              questionnaires: QuestionnairesResolve
            }
          },
          {
            path: ':id',
            component: SingleReferenceDocumentComponent,
            resolve: {
              questionnaire: QuestionnaireResolve,
              sections: SectionsResolve
            }
          }
        ]
      },
      {
        path: 'audit-types',
        component: AuditTypesComponent,
        resolve: {
          auditTypes: AuditTypesResolve
        },
        canActivate: [AdminAuditTypesGuard]
      },
      {
        path: 'finding-types',
        component: FindingTypesComponent,
        resolve: {
          findingTypes: FindingTypesResolve
        },
        canActivate: [AdminFindingTypesGuard]
      },
      {
        path: 'rating-scales',
        canActivate: [AdminRatingScaleGuard],
        children: [
          {
            path: '',
            component: RatingScalesComponent,
            resolve: {
              ratingScales: RatingScalesResolve,
              auditTypes: AuditTypesResolve
            }
          },
          {
            path: ':id',
            component: SingleRatingScaleComponent,
            resolve: {
              ratingScale: RatingScaleResolve
            }
          }
        ]
      },
      {
        path: 'users',
        component: UsersComponent,
        resolve: {
          employees: EmployeesResolve
        },
        canActivate: [AdminUsersGuard]
      },
      {
        path: 'facilities',
        component: FacilitiesComponent,
        resolve: {
          facilities: FacilitiesResolve
        },
        canActivate: [AdminFacilitiesGuard]
      },
      {
        path: 'company-settings',
        component: CompanySettingsComponent,
        resolve: {
          company: CompanyResolve
        },
        canActivate: [AdminCompanyGuard]
      },
      {
        path: 'reports',
        component: ReportsComponent,
        resolve: {
          reports: ReportsResolve
        },
        canActivate: [AdminReportsGuard]
      },
      {
        path: 'company',
        component: CompanyComponent,
        canActivate: [LoginGuardSuperAdmin]
      },
      {
        path: 'roles',
        component: RolesComponent,
        resolve: {
          roles: RolesResolve
        },
        canActivate: [AdminRolesGuard]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AdminRoutingModule {}

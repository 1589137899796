import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// Gutwin Shared Library
import { DialogService, Employee, HelperUtil, ModalService } from 'gutwin-shared';

// Models
import { Audit } from '../../../shared/models/audit.model';
import { AuditedArea } from '../../../shared/models/audited-area.model';
import { FindingType } from '../../../shared/models/finding-type.model';
import { Finding } from '../../../shared/models/finding.model';
import { FindingsAmount } from '../../../shared/models/findings-amount.model';
import { FindingsResponse } from '../../../shared/models/findings-response.model';
import { Question } from '../../../shared/models/question.model';
import { Section } from '../../../shared/models/section.model';

// Components
import { AddFindingComponent } from '../../../shared/components/add-finding/add-finding.component';

import { FilterModuleService } from '../../../shared/services/filter-module.service';
import { FindingsRequests } from '../../../shared/services/finding.offline.service';
// Services
import { FindingService } from '../../../shared/services/finding.service';
import { SectionService } from '../../../shared/services/section.service';

@Component({
  selector: 'gw-audit-findings',
  templateUrl: './audit-findings.component.html',
  styleUrls: ['./audit-findings.component.scss']
})
export class AuditFindingsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('addFinding') addFinding: AddFindingComponent;
  @Input() audit: Audit;
  @Input() auditedArea: AuditedArea;
  @Output() findingsUpdate = new EventEmitter();
  modalAuditedArea: AuditedArea;
  sections: Array<Section>;
  modalSections: Array<Section>;
  findingTypes: Array<FindingType>;
  findings: Array<Finding>;
  findingsAmount: FindingsAmount;
  findingsChanged = new Array<Finding>();
  loadingFindings: boolean;
  savingFinding = false;
  employees: Array<Employee>;
  employeesAssigns: Array<number>;
  totals = {
    findings: 0,
    notAssignedFindings: 0,
    resolvedFindings: 0,
    overdueFindings: 0
  };
  translation = {
    notify: {
      error: {
        connectionTitle: '',
        fetchSectionsWithQuestions: '',
        updateFinding: '',
        removeFindingText: '',
        audit: {
          loadFindings: ''
        },
        auditedArea: {
          loadFindings: ''
        }
      },
      success: {
        updateFindingsTitle: '',
        updateFindingsText: '',
        removeFindingTitle: '',
        removeFindingText: ''
      }
    },
    removeFindingDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    }
  };
  filterSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private findingService: FindingService,
    private modalService: ModalService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService,
    private sectionService: SectionService,
    public filterService: FilterModuleService
  ) {}

  ngOnInit(): void {
    this.initData();
    this.filterSubscription = this.filterService.filterObservable
      .pipe(debounceTime(200))
      .subscribe(({ page, limit }) => {
        this.fetchFindings(page, limit);
      });
    this.fetchTranslation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.audit || changes.auditedArea) {
      this.initData();
    }
  }

  ngOnDestroy(): void {
    this.filterSubscription.unsubscribe();
  }

  initData(): void {
    this.sections = this.route.snapshot.data['sections'];
    this.findingTypes = this.route.snapshot.data['findingTypes'];
    this.employees = this.route.snapshot.data['employees'];
    this.fetchFindings(this.filterService.page, this.filterService.limit);
  }

  getAuditedArea(auditedAreaId: string): AuditedArea {
    if (this.audit && this.audit.auditedAreas) {
      return _.find(this.audit.auditedAreas, { id: auditedAreaId });
    }
  }

  fetchFindings(page: number, limit: number): void {
    this.loadingFindings = true;
    const findingsPromise = this.auditedArea
      ? this.findingService.getAuditedAreaFindings(
          this.auditedArea.id,
          page,
          undefined,
          limit,
          false,
          !this.audit.disabled
        )
      : this.findingService.getAuditFindings(
          this.audit.id,
          page,
          undefined,
          limit,
          !this.audit.disabled,
          this.audit.auditedAreas
        );

    findingsPromise
      .then(async (data: FindingsResponse) => {
        this.findings = data.findings;
        this.findingsAmount = data.findingsAmount;
        this.mergeFindingsWithChanges();
        this.updateView();
        this.loadingFindings = false;
      })
      .catch(error => {
        this.findings = [];
        this.findingsAmount = new FindingsAmount();
        const text = this.auditedArea
          ? this.translation.notify.error.auditedArea.loadFindings
          : this.translation.notify.error.audit.loadFindings;
        this.notificationsService.error(this.translation.notify.error.connectionTitle, text);
      });
  }

  mergeFindingsWithChanges(): void {
    this.findingsChanged.forEach((findingChanged: Finding) => {
      const finding = _.find(this.findings, { id: findingChanged.id });
      if (finding) {
        finding.temporaryFinding = finding.temporaryFinding || new Finding({});
        finding.temporaryFinding.relationships.employee = findingChanged.relationships.employee;
        finding.temporaryFinding.changes.employee =
          finding.changes.employee ||
          (!finding.relationships.employee && !!findingChanged.relationships.employee) ||
          (finding.relationships.employee &&
            findingChanged.relationships.employee &&
            findingChanged.relationships.employee.id !== finding.relationships.employee.id);
        finding.temporaryFinding.deadline = findingChanged.deadline ? findingChanged.deadline : finding.deadline;
        finding.temporaryFinding.changes.deadline =
          finding.changes.deadline || finding.deadline !== findingChanged.deadline;
      }
    });
  }

  clearFindingsChanges(findingsRequests: FindingsRequests): void {
    findingsRequests.update.forEach((finding: Finding) => {
      const findingIndex = _.findIndex(this.findingsChanged, { id: finding.id });
      if (~findingIndex) {
        delete finding.changes;
        this.findingsChanged.splice(findingIndex, 1);
      }
    });
  }

  removeFindingChanges(findingId: string): void {
    const index = _.findIndex(this.findingsChanged, { id: findingId });
    if (~index) {
      this.findingsChanged.splice(index, 1);
    }
  }

  findingChanged(finding: Finding): void {
    const findingIndex = _.findIndex(this.findingsChanged, { id: finding.id });
    let findingChanges = finding.temporaryFinding;
    if (
      findingChanges &&
      findingChanges.changes &&
      (findingChanges.changes.employee || findingChanges.changes.deadline)
    ) {
      const findingToMerge = finding.offlineRequest || finding;
      findingChanges = HelperUtil.mergeObjects(findingToMerge, findingChanges);
      findingChanges.relationships = HelperUtil.mergeObjects(
        findingToMerge.relationships,
        findingChanges.relationships
      );
      ~findingIndex ? (this.findingsChanged[findingIndex] = findingChanges) : this.findingsChanged.push(findingChanges);
    } else {
      this.findingsChanged.splice(findingIndex, 1);
    }
    this.updateView();
  }

  getEmployeesAssigns(): Array<number> {
    const appendEmployee = (employeesAssigns: Array<number>, finding: Finding): Array<number> => {
      if (finding.changes.employee && finding.relationships.employee) {
        if (employeesAssigns[finding.relationships.employee.id]) {
          employeesAssigns[finding.relationships.employee.id]++;
        } else {
          employeesAssigns[finding.relationships.employee.id] = 1;
        }
      }
      return employeesAssigns;
    };

    const employeesAssigns = new Array<number>();
    if (this.findingsChanged) {
      this.findingsChanged.forEach(finding => {
        appendEmployee(employeesAssigns, finding);
      });
    }
    this.findings.forEach(finding => {
      if (finding.offlineRequest) {
        appendEmployee(employeesAssigns, finding.offlineRequest);
      }
    });
    return employeesAssigns;
  }

  getFindableById(findable: AuditedArea | Question): AuditedArea | Question {
    if (this.auditedArea && this.auditedArea.id === findable.id) {
      return this.auditedArea;
    } else if (this.audit.auditedAreas) {
      for (const auditedArea of this.audit.auditedAreas) {
        if (auditedArea.id === findable.id) {
          return auditedArea;
        }
      }
    }
    if (this.sections) {
      for (const section of this.sections) {
        for (const question of section.questions) {
          if (question.id === findable.id) {
            return question;
          }
        }
      }
    }
    return findable;
  }

  countTotals(): void {
    const totals = {
      findings: this.findingsAmount.total,
      notAssignedFindings: this.findingsAmount.totalNotAssigned,
      resolvedFindings: this.findingsAmount.totalSolved,
      overdueFindings: this.findingsAmount.totalOverdue
    };
    if (this.findingsChanged) {
      const todayDate = moment();
      this.findingsChanged.forEach((finding: Finding) => {
        if (finding.relationships.employee && finding.oldStatus === 'not_assigned') {
          totals.notAssignedFindings--;
        }
        if (
          finding.changes.deadline &&
          finding.deadline &&
          finding.deadline.isBefore(todayDate, 'day') &&
          (!finding.oldDeadline || !finding.oldDeadline.isBefore(todayDate, 'day'))
        ) {
          totals.overdueFindings++;
        }
      });
    }
    this.totals = totals;
  }

  changePage(page: number): void {
    this.filterService.setPage(page);
  }

  changeLimit(limit: number): void {
    this.filterService.setLimit(limit);
  }

  showAddFindingModal(findable: AuditedArea | Question): void {
    this.modalAuditedArea = this.auditedArea;
    this.modalSections = this.sections;
    this.modalService.open('findingModal', { findable });
  }

  async showUpdateFindingModal(finding: Finding): Promise<any> {
    this.modalAuditedArea = this.auditedArea || this.getAuditedArea(finding.relationships.auditedArea.id);
    if (!this.auditedArea) {
      this.modalSections = await this.getSectionsForFindingEditing(this.modalAuditedArea);
    } else {
      this.modalSections = this.sections;
    }
    this.modalService.open('findingModal', { finding });
  }

  getSectionsForFindingEditing(editedAuditedArea: AuditedArea): Promise<Array<Section>> {
    return this.sectionService.getSectionsWithQuestions(editedAuditedArea.id).catch(error => {
      this.notificationsService.error(
        this.translation.notify.error.connectionTitle,
        this.translation.notify.error.fetchSectionsWithQuestions
      );
      return [];
    });
  }

  showRemoveFindingDialog(finding: Finding): void {
    this.dialogService
      .confirm(
        this.translation.removeFindingDialog.header,
        this.translation.removeFindingDialog.content,
        this.translation.removeFindingDialog.cancel,
        this.translation.removeFindingDialog.confirm
      )
      .then(() => this.removeFinding(finding))
      .catch(() => {});
  }

  updateView(): void {
    this.employeesAssigns = this.getEmployeesAssigns();
    this.countTotals();
  }

  removeFinding(finding: Finding): void {
    this.findingService
      .removeFinding(finding)
      .then(() => {
        if (this.findings.length > 1) {
          this.fetchFindings(this.filterService.page, this.filterService.limit);
        } else {
          this.filterService.setFilters(this.filterService.page - 1);
        }
        this.notificationsService.success(
          this.translation.notify.success.removeFindingTitle,
          this.translation.notify.success.removeFindingText
        );
      })
      .catch(() => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.removeFindingText
        );
      });
  }

  saveFinding(oldFinding?: Finding): void {
    this.savingFinding = true;
    this.addFinding
      .submitAddFindingForm()
      .then(finding => {
        if (oldFinding) {
          this.removeFindingChanges(oldFinding.id);
        }
        this.fetchFindings(this.filterService.page, this.filterService.limit);
        this.findingsUpdate.emit();
        this.modalService.close('findingModal');
        this.savingFinding = false;
      })
      .catch(error => {
        this.savingFinding = false;
      });
  }

  saveFindings(): void {
    const countFlags = (flags: Array<{ offline?: boolean; error?: boolean }>): { offline: number; errors: number } => {
      const amount = {
        offline: 0,
        errors: 0
      };
      if (flags) {
        flags.forEach(flag => {
          amount.offline += flag.offline ? 1 : 0;
          amount.errors += flag.error ? 1 : 0;
        });
      }
      return amount;
    };

    const promises = new Array<Promise<{ offline?: boolean; error?: boolean }>>();
    const findingsRequests = this.convertFindingsToRequests();
    for (const auditedAreaId in findingsRequests) {
      if (findingsRequests.hasOwnProperty(auditedAreaId)) {
        promises.push(
          this.findingService
            .updateFindings(findingsRequests[auditedAreaId], true)
            .then((response: { data: FindingsRequests; offline?: boolean }) => {
              this.clearFindingsChanges(response.data);
              return { offline: response.offline };
            })
            .catch(error => {
              return { error: true };
            })
        );
      }
    }

    Promise.all(promises).then((statusFlags: Array<{ offline?: boolean; error?: boolean }>) => {
      this.fetchFindings(this.filterService.page, this.filterService.limit);
      this.findingsUpdate.emit();
      const offlineResponsesAmount = countFlags(statusFlags);
      if (offlineResponsesAmount.errors > 0) {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.updateFinding
        );
      } else {
        this.notificationsService.success(
          this.translation.notify.success.updateFindingsTitle,
          this.translation.notify.success.updateFindingsText
        );
      }
    });
  }

  convertFindingsToRequests(): Array<FindingsRequests> {
    const appendFindingToRequests = (findingsRequests: Array<FindingsRequests>, finding: Finding): void => {
      if (!findingsRequests[finding.relationships.auditedArea.id]) {
        findingsRequests[finding.relationships.auditedArea.id] = {
          auditedAreaId: finding.relationships.auditedArea.id,
          create: new Array<Finding>(),
          update: new Array<Finding>()
        };
      }
      const auditedAreaRequests = findingsRequests[finding.relationships.auditedArea.id];
      const findingsArray = finding.action.create ? auditedAreaRequests.create : auditedAreaRequests.update;
      const findingRequest = _.find(findingsArray, { id: finding.id }) as Finding;
      if (findingRequest) {
        findingRequest.changes = {
          employee: findingRequest.changes.employee || finding.changes.employee,
          deadline: findingRequest.changes.deadline || finding.changes.deadline
        };
        findingRequest.relationships.employee = findingRequest.relationships.employee || finding.relationships.employee;
        findingRequest.deadline = findingRequest.deadline || finding.deadline;
      } else {
        findingsArray.push(finding);
      }
    };

    const findingsRequests = new Array<FindingsRequests>();
    if (this.findingsChanged) {
      this.findingsChanged.forEach((finding: Finding) => {
        appendFindingToRequests(findingsRequests, finding);
      });
    }
    this.findings.forEach((finding: Finding) => {
      if (finding.offlineRequest) {
        appendFindingToRequests(findingsRequests, finding.offlineRequest);
      }
    });
    return findingsRequests;
  }

  getObjectSize(object): number {
    return Object.keys(object).length;
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'GLOBAL.ERROR.LOAD_CHAPTER_WITH_QUESTIONS',
        'AUDIT_SUMMARY.ERROR.UPDATE_FINDING',
        'AUDIT_SUMMARY.ERROR.AUDIT.LOAD_FINDINGS',
        'AUDIT_SUMMARY.ERROR.AUDITED_AREA.LOAD_FINDINGS',
        'AUDIT_SUMMARY.ERROR.REMOVE_FINDING_TEXT',
        'AUDIT_SUMMARY.SUCCESS.UPDATE_FINDINGS_TITLE',
        'AUDIT_SUMMARY.SUCCESS.UPDATE_FINDINGS_TEXT',
        'AUDIT_SUMMARY.SUCCESS.REMOVE_FINDING_TITLE',
        'AUDIT_SUMMARY.SUCCESS.REMOVE_FINDING_TEXT',
        'AUDIT_SUMMARY.REMOVE_FINDING_DIALOG.HEADER',
        'AUDIT_SUMMARY.REMOVE_FINDING_DIALOG.CONTENT'
      ])
      .toPromise();
    this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.fetchSectionsWithQuestions = translation['GLOBAL.ERROR.LOAD_CHAPTER_WITH_QUESTIONS'];
    this.translation.notify.error.updateFinding = translation['AUDIT_SUMMARY.ERROR.UPDATE_FINDING'];
    this.translation.notify.error.audit.loadFindings = translation['AUDIT_SUMMARY.ERROR.AUDIT.LOAD_FINDINGS'];
    this.translation.notify.error.auditedArea.loadFindings =
      translation['AUDIT_SUMMARY.ERROR.AUDITED_AREA.LOAD_FINDINGS'];
    this.translation.notify.error.removeFindingText = translation['AUDIT_SUMMARY.ERROR.REMOVE_FINDING_TEXT'];
    this.translation.notify.success.updateFindingsTitle = translation['AUDIT_SUMMARY.SUCCESS.UPDATE_FINDINGS_TITLE'];
    this.translation.notify.success.updateFindingsText = translation['AUDIT_SUMMARY.SUCCESS.UPDATE_FINDINGS_TEXT'];
    this.translation.notify.success.removeFindingTitle = translation['AUDIT_SUMMARY.SUCCESS.REMOVE_FINDING_TITLE'];
    this.translation.notify.success.removeFindingText = translation['AUDIT_SUMMARY.SUCCESS.REMOVE_FINDING_TEXT'];
    this.translation.removeFindingDialog.header = translation['AUDIT_SUMMARY.REMOVE_FINDING_DIALOG.HEADER'];
    this.translation.removeFindingDialog.content = translation['AUDIT_SUMMARY.REMOVE_FINDING_DIALOG.CONTENT'];
    this.translation.removeFindingDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeFindingDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
  }

  keyDownUploadReport(event: any, fileInput: any): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      fileInput.click(event);
    }
  }
}

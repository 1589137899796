import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';

import { DialogOverlayRef } from '../overlay/dialog-overlay-ref';

import { ImageCropperModalData } from '../../interfaces/image-cropper-modal.interface';

import { ImageCropperViewComponent } from '../image-cropper-view/image-cropper-view.component';

@Component({
  selector: 'gw-image-cropper-modal',
  templateUrl: './image-cropper-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperModalComponent {
  @ViewChild('imageCropper', { static: true }) imageCropper: ImageCropperViewComponent;

  constructor(public dialogOverlayRef: DialogOverlayRef<ImageCropperModalData, ImageCropperModalData>) {}

  submitImageCropper(): void {
    const croppedImage = this.imageCropper.preparePicture();
    this.close(croppedImage);
  }

  close(croppedImage?: string): void {
    this.dialogOverlayRef.close({ base64: croppedImage });
  }
}

import { Injectable } from '@angular/core';

import * as _ from 'lodash';

import { ModalDialogTheme } from '../interfaces/modal-dialog-theme.interface';

import { ModalDialogComponent } from '../components/modal-dialog/modal-dialog.component';

import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialogModal: ModalDialogComponent;
  public dialogConfirmation: () => void;
  public dialogRejection: () => void;

  constructor(private modalService: ModalService) {}

  confirm(
    title?: string,
    content?: string,
    cancel?: string,
    confirm?: string,
    withCloseButton?: boolean,
    icon?: string,
    theme?: ModalDialogTheme
  ): Promise<void> {
    this.modalService.open('dialogModal');
    this.dialogModal = _.find(this.modalService.modals, { id: 'dialogModal' }) as ModalDialogComponent;
    this.dialogModal.title = title;
    this.dialogModal.content = content;
    this.dialogModal.cancelText = cancel;
    this.dialogModal.confirmText = confirm;
    this.dialogModal.withCloseButton = withCloseButton;
    this.dialogModal.icon = icon;
    if (theme) this.dialogModal.theme = theme;

    return new Promise((resolve, reject) => {
      this.dialogConfirmation = () => resolve();
      this.dialogRejection = () => reject();
    });
  }
}

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { TreeFilterOption } from '../../../models/tree-filter-option.model';

import { ExpandBoxComponent } from '../../expand-box/expand-box.component';

@Component({
  selector: 'gw-radio-group-filter',
  templateUrl: './radio-group-filter.component.html',
  styleUrls: ['./radio-group-filter.component.scss']
})
export class RadioGroupFilterComponent {
  @ViewChild('sectionExpandBox', { static: true }) expandBox: ExpandBoxComponent;
  @Input() label: string;
  @Input() translateOption = false;
  @Input() options: Array<TreeFilterOption>;
  @Output() updateFilter = new EventEmitter<string>();

  get selectedOption(): string {
    const selectedOption = this.options.find(option => option.isSelected);

    return selectedOption ? selectedOption.id.toString() : '';
  }

  updateFilters(option: TreeFilterOption): void {
    if (option) {
      if (option.id === this.selectedOption) {
        this.updateFilter.emit('');
      } else {
        this.updateFilter.emit(option.id.toString());
      }
    }
  }
}

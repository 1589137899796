import { Pipe, PipeTransform } from '@angular/core';

import { Role } from '../models/role.model';

@Pipe({
  name: 'isAddRoleVisible'
})
export class IsAddRoleVisiblePipe implements PipeTransform {
  transform(isEditMode: boolean, rolesNotAdded: Array<Role>): boolean {
    return isEditMode && !!rolesNotAdded?.length;
  }
}


<gw-modal id="duplicationsModal" #duplicationsModal="gwModal">
  <ng-container modal-header="modal-header">{{ 'AUDIT_CONDUCT.QUESTION.DUPLICATIONS_MODAL.HEADER' | translate }}</ng-container>
  <ng-container modal-content="modal-content">
    <p>{{ 'AUDIT_CONDUCT.QUESTION.DUPLICATIONS_MODAL.LEAD' | translate }}:</p>
    <div class="duplicated-attachments">
      <ng-container *ngFor="let attachment of duplications">
        <gw-attachment class="attachment-container" *ngIf="!attachment.toRemove &amp;&amp; attachment.isImage" [attachment]="attachment" [viewOnly]="true" (removed)="removeAttachment(attachment)"></gw-attachment>
      </ng-container>
    </div>
    <div class="question-attachments">
      <ng-container *ngFor="let attachment of duplications">
        <gw-attachment class="attachment-container" *ngIf="!attachment.toRemove &amp;&amp; !attachment.isImage" [attachment]="attachment" [viewOnly]="true" (removed)="removeAttachment(attachment)"></gw-attachment>
      </ng-container>
    </div>
  </ng-container>
  <ng-container modal-actions="modal-actions">
    <button class="button--primary" type="button" (click)="duplicationsModal.close()">{{ 'GLOBAL.ACTION.CONFIRM' | translate }}</button>
  </ng-container>
</gw-modal>
<div class="files-section" *ngIf="finding.attachments">
  <div class="files-section__header files-section__header--main">
    <div class="files-section__header-part">{{ 'FINDING_VIEW.FILES_SECTION.HEADER.ATTACHMENTS' | translate }}&nbsp;<small>({{ attachments.length }})</small></div>
    <div class="files-section__header-part" *ngIf="canManageFinding">
      <label class="button--small button--primary files-section__header-button" for="upload" [class.button--disabled]="uploadingFile"><i class="button__icon--before button__icon--spinner" *ngIf="uploadingFile">
          <div class="spinner"></div></i>{{ 'FINDING_VIEW.FILES_SECTION.ACTION.ADD_ATTACHMENT' | translate }}
        <input class="hide" type="file" multiple="multiple" id="upload" [disabled]="uploadingFile" (change)="attachFiles($event)"/>
      </label>
    </div>
  </div>
  <div class="files-section__group files-section__group--first" *ngIf="separatedAttachments.boxes?.length">
    <div class="files-section__header">
      <div class="files-section__header-part">{{ 'FINDING_VIEW.FILES_SECTION.HEADER.IMAGES' | translate }}&nbsp;<small>({{ separatedAttachments.boxes.length }})</small></div>
    </div>
    <div class="row files-section__attachments">
      <div class="columns small-9 files-section__subgroup" [class.small-12]="separatedAttachments.boxes.length === 1">
        <gw-attachment class="attachment-container attachment-container--big" [attachment]="separatedAttachments.boxes[0]" [gallery]="separatedAttachments.boxes" [viewOnly]="true" [lightboxOptions]="canManageFinding ? attachmentOptions : []"></gw-attachment>
      </div>
      <div class="columns small-3 files-section__subgroup" *ngIf="separatedAttachments.boxes.length &gt; 1">
        <gw-attachment class="attachment-container" *ngFor="let attachment of separatedAttachments.boxes | slice:1:(separatedAttachments.boxes.length &gt; 4 ? 3 : 4)" [attachment]="attachment" [gallery]="separatedAttachments.boxes" [viewOnly]="true" [lightboxOptions]="canManageFinding ? attachmentOptions : []"></gw-attachment>
        <gw-attachment class="attachment-container" *ngIf="separatedAttachments.boxes.length &gt; 4" [attachment]="separatedAttachments.boxes[3]" [gallery]="separatedAttachments.boxes" [viewOnly]="true" [coverText]="'+' + (separatedAttachments.boxes.length - 3)" [lightboxOptions]="canManageFinding ? attachmentOptions : []"></gw-attachment>
      </div>
    </div>
  </div>
  <div class="files-section__group" *ngIf="separatedAttachments.documents?.length" [class.files-section__group--first]="!separatedAttachments.boxes?.length">
    <div class="files-section__header">
      <div class="files-section__header-part">{{ 'FINDING_VIEW.FILES_SECTION.HEADER.FILES' | translate }}&nbsp;<small>({{ separatedAttachments.documents.length }})</small></div>
    </div>
    <div class="row files-section__attachments">
      <div class="columns small-12">
        <gw-attachment class="attachment-container" *ngFor="let attachment of separatedAttachments.documents" [attachment]="attachment" [viewOnly]="!attachment.url || !canManageFinding" (removed)="clickRemoveAttachment(attachment)"></gw-attachment>
      </div>
    </div>
  </div>
</div>
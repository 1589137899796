import { ActivatedRoute } from '@angular/router';

export namespace LoginHelper {
  export function isFromExternalService(route: ActivatedRoute): boolean {
    return !!route.queryParams['value'].external_service;
  }

  export function getRedirectUrl(route: ActivatedRoute): string {
    return route.queryParams['value'].redirect_url;
  }

  export function separatePath(url: string): string {
    const index = url.indexOf('?');
    if (~index) {
      return url.slice(0, index);
    }
    return url;
  }

  export function separateQueryParams(url: string): any {
    const index = url.indexOf('?');
    if (~index) {
      const params = url.slice(index + 1);
      return JSON.parse('{"' + params.replace(/&/g, '","').replace(/=/g, '":"') + '"}', (key, value) =>
        key === '' ? value : decodeURIComponent(value)
      );
    }
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Modules
import { SharedModule } from '../shared/shared.module';
import { AdminRoutingModule } from './admin.routing.module';

import { UsersComponent } from '@gutwin-audit/admin/views/users/users.component';
// Components
import { AdminTabsComponent } from './admin-tabs/admin-tabs.component';
import { AdminComponent } from './admin.component';
import { AddAuditTypeComponent } from './views/audit-types/add-audit-type/add-audit-type.component';
import { AuditTypesComponent } from './views/audit-types/audit-types.component';
import { CompanySettingsComponent } from './views/company-settings/company-settings.component';
import { CompanyComponent } from './views/company/company.component';
import { AddFacilityComponent } from './views/facilities/add-facility/add-facility.component';
import { FacilitiesComponent } from './views/facilities/facilities.component';
import { AddFindingTypeComponent } from './views/finding-types/add-finding-type/add-finding-type.component';
import { FindingTypesComponent } from './views/finding-types/finding-types.component';
import { AddRatingScaleComponent } from './views/rating-scales/add-rating-scale/add-rating-scale.component';
import { RatingScalesComponent } from './views/rating-scales/rating-scales.component';
import { AddReferenceDocumentComponent } from './views/reference-documents/add-reference-document/add-reference-document.component';
import { ImportReferenceDocumentComponent } from './views/reference-documents/import-reference-document/import-reference-document.component';
import { ReferenceDocumentsComponent } from './views/reference-documents/reference-documents.component';
import { AddReportComponent } from './views/reports/add-report/add-report.component';
import { ReportsComponent } from './views/reports/reports.component';
import { RolesComponent } from './views/roles/roles.component';
import { SingleRatingScaleComponent } from './views/single-rating-scale/single-rating-scale.component';
import { SingleReferenceDocumentComponent } from './views/single-reference-document/single-reference-document.component';

// Guards
import { AdminReportsGuard } from '@gutwin-audit/admin/guards/admin.reports.guard';
import { AdminAuditTypesGuard } from './guards/admin.audit-types.guard';
import { AdminCompanyGuard } from './guards/admin.company.guard';
import { AdminFacilitiesGuard } from './guards/admin.facilities.guard';
import { AdminFindingTypesGuard } from './guards/admin.finding-types.guard';
import { AdminQustionnairesGuard } from './guards/admin.questionnaires.guard';
import { AdminRatingScaleGuard } from './guards/admin.rating-scale.guard';
import { AdminRolesGuard } from './guards/admin.roles.guard';
import { AdminUsersGuard } from './guards/admin.users.guard';

// Resolvers
import { AuditTypesResolve } from './resolvers/audit-types.resolve';
import { CompanyResolve } from './resolvers/company.resolve';
import { EmployeesResolve } from './resolvers/employees.resolve';
import { FacilitiesResolve } from './resolvers/facilities.resolve';
import { FindingTypesResolve } from './resolvers/finding-types.resolve';
import { QuestionnaireResolve } from './resolvers/questionnaire.resolve';
import { QuestionnairesResolve } from './resolvers/questionnaires.resolve';
import { RatingScalesResolve } from './resolvers/rating-scales.resolve';
import { ReportsResolve } from './resolvers/reports.resolve';
import { RolesResolve } from './resolvers/roles.resolve';
import { SectionsResolve } from './resolvers/sections.resolve';

@NgModule({
  imports: [CommonModule, SharedModule, AdminRoutingModule],
  declarations: [
    AdminComponent,
    AdminTabsComponent,
    AddAuditTypeComponent,
    AddReportComponent,
    AddFindingTypeComponent,
    AddRatingScaleComponent,
    AddFacilityComponent,
    AddReferenceDocumentComponent,
    AuditTypesComponent,
    CompanyComponent,
    CompanySettingsComponent,
    FacilitiesComponent,
    FindingTypesComponent,
    ImportReferenceDocumentComponent,
    SingleRatingScaleComponent,
    RatingScalesComponent,
    ReferenceDocumentsComponent,
    RolesComponent,
    ReportsComponent,
    SingleReferenceDocumentComponent,
    UsersComponent
  ],
  providers: [
    AdminAuditTypesGuard,
    AdminCompanyGuard,
    AdminFacilitiesGuard,
    AdminFindingTypesGuard,
    AdminRatingScaleGuard,
    AdminRolesGuard,
    AdminQustionnairesGuard,
    AdminUsersGuard,
    AdminReportsGuard,
    AuditTypesResolve,
    CompanyResolve,
    EmployeesResolve,
    FacilitiesResolve,
    FindingTypesResolve,
    RolesResolve,
    ReportsResolve,
    QuestionnairesResolve,
    QuestionnaireResolve,
    SectionsResolve,
    RatingScalesResolve
  ]
})
export class AdminModule {}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertMimeTypeToDisplay'
})
export class ConvertMimeTypeToDisplayPipe implements PipeTransform {
  public transform(type: string): string {
    return type
      .split('/')
      .pop()
      .toUpperCase();
  }
}

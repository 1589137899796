import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';

import { EmailValidator, Employee, I18nService, isPasswordConfirmed, Language, LanguageCode } from 'gutwin-shared';

import { Company } from '../../../shared/models/company.model';

import { CompanyService } from '../../../shared/services/company.service';

@Component({
  selector: 'gw-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  languages: Array<Language>;
  companyForm: FormGroup;
  submitted: boolean;
  saving: boolean;
  notify = {
    error: {
      connectionTitle: '',
      createCompanyText: ''
    },
    success: {
      createCompanyTitle: '',
      createCompanyText: ''
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private i18nService: I18nService,
    private companyService: CompanyService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.languages = this.generateLanguages();
    this.initForm();
    this.fetchTranslation();
  }

  initForm(): void {
    this.companyForm = this.formBuilder.group(
      {
        name: ['', Validators.required],
        description: ['', Validators.required],
        subdomain: ['', Validators.required],
        language: [
          this.i18nService.getLanguage(this.translateService.currentLang as LanguageCode) || '',
          Validators.required
        ],
        userName: ['', Validators.required],
        userLastname: ['', Validators.required],
        userEmail: ['', [Validators.required, EmailValidator.extendedEmailValidator]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        passwordConfirmed: ['', [Validators.required, Validators.minLength(8)]],
        seeds: [false, Validators.required]
      },
      { validator: isPasswordConfirmed('password', 'passwordConfirmed') }
    );
    this.submitted = false;
  }

  setControlValue(data: any, key: string): void {
    this.companyForm.controls[key].setValue(data);
  }

  isEmailInvalid(field: FormControl): boolean {
    return field.errors?.email;
  }

  isFieldEmpty(field: FormControl): boolean {
    return field.errors && field.errors.required;
  }

  generateLanguages(): Array<Language> {
    const languages = new Array<Language>();
    this.translateService.langs.forEach(lang => {
      languages.push(this.i18nService.getLanguage(lang as LanguageCode));
    });
    return languages;
  }

  resetForm(): void {
    this.initForm();
  }

  submitCompanyForm(form: FormGroup): void {
    this.submitted = true;
    if (form.valid) {
      this.saving = true;
      form = this.checkPassword(form);
      const company = this.convertFormToCompany(form);

      this.companyService
        .createCompany(company, form.value.seeds)
        .then(() => {
          this.saving = false;
          this.initForm();
          this.notificationsService.success(
            this.notify.success.createCompanyTitle,
            this.notify.success.createCompanyText
          );
        })
        .catch(() => {
          this.saving = false;
          this.notificationsService.error(this.notify.error.connectionTitle, this.notify.error.createCompanyText);
        });
    }
  }

  checkPassword(form: FormGroup): FormGroup {
    if (form.value.password && form.value.passwordConfirmed && form.value.password === form.value.passwordConfirmed) {
      form.value.password = form.value.password;
    }
    delete form.value.passwordConfirmed;
    return form;
  }

  convertFormToCompany(form: FormGroup): Company {
    const user = new Employee({
      name: form.value.userName,
      lastname: form.value.userLastname,
      email: form.value.userEmail,
      password: form.value.password
    });
    const company = new Company({
      name: form.value.name,
      description: form.value.description,
      subdomain: form.value.subdomain,
      language: form.value.language,
      employee: user
    });
    return company;
  }

  keyDownAvatarControl(event: any, fileInput: any): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      fileInput.click(event);
    }
  }

  async fetchTranslation(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'ADMIN_SETTINGS.COMPANY.FORM.ERROR.CREATE_COMPANY_TEXT',
        'ADMIN_SETTINGS.COMPANY.FORM.SUCCESS.CREATE_COMPANY_TITLE',
        'ADMIN_SETTINGS.COMPANY.FORM.SUCCESS.CREATE_COMPANY_TEXT'
      ])
      .toPromise();
    this.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.notify.error.createCompanyText = translation['ADMIN_SETTINGS.COMPANY.FORM.ERROR.CREATE_COMPANY_TEXT'];
    this.notify.success.createCompanyTitle = translation['ADMIN_SETTINGS.COMPANY.FORM.SUCCESS.CREATE_COMPANY_TITLE'];
    this.notify.success.createCompanyText = translation['ADMIN_SETTINGS.COMPANY.FORM.SUCCESS.CREATE_COMPANY_TEXT'];
  }
}

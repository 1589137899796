
<div class="row company">
  <div class="columns">
    <div class="row company__header">
      <div class="columns small-12 company-header__title-container">
        <h1 class="company-header__title">{{ 'ADMIN_SETTINGS.COMPANY.HEADER' | translate }}</h1>
      </div>
    </div>
    <div class="row company__content">
      <div class="columns columns--without-padding small-12 medium-8 large-6 medium-uncentered">
        <form class="company__form" *ngIf="companyForm" [formGroup]="companyForm" novalidate="novalidate" autocomplete="off">
          <div class="form__fieldset company__fieldset">
            <h3 class="company-fieldset__header">{{ 'ADMIN_SETTINGS.COMPANY.FORM.HEADER.BASIC_INFORMATIONS' | translate }}</h3>
            <div class="company__fields">
              <div class="form__field company__field" [class.form__field--error]="companyForm | getFormControl : 'name' | isFieldInvalid : submitted">
                <label class="label" for="name">{{ 'ADMIN_SETTINGS.COMPANY.FORM.LABEL.NAME' | translate }}</label>
                <div class="form-field__error">{{ 'ADMIN_SETTINGS.COMPANY.FORM.ERROR.NAME_EMPTY' | translate }}</div>
                <input class="input" placeholder="{{ 'ADMIN_SETTINGS.COMPANY.FORM.PLACEHOLDER.NAME' | translate }}" type="text" id="name" name="name" tabindex="1" [formControl]="companyForm.controls['name']"/>
              </div>
              <div class="form__field company__field" [class.form__field--error]="companyForm | getFormControl : 'description' | isFieldInvalid : submitted">
                <label class="label" for="description">{{ 'ADMIN_SETTINGS.COMPANY.FORM.LABEL.DESCRIPTION' | translate }}</label>
                <div class="form-field__error">{{ 'ADMIN_SETTINGS.COMPANY.FORM.ERROR.DESCRIPTION_EMPTY' | translate }}</div>
                <input class="input" placeholder="{{ 'ADMIN_SETTINGS.COMPANY.FORM.PLACEHOLDER.DESCRIPTION' | translate }}" type="text" id="description" name="description" tabindex="2" [formControl]="companyForm.controls['description']"/>
              </div>
              <div class="form__field company__field" [class.form__field--error]="companyForm | getFormControl : 'subdomain' | isFieldInvalid : submitted">
                <label class="label" for="subdomain">{{ 'ADMIN_SETTINGS.COMPANY.FORM.LABEL.SUBDOMAIN' | translate }}</label>
                <div class="form-field__error">{{ 'ADMIN_SETTINGS.COMPANY.FORM.ERROR.SUBDOMAIN_EMPTY' | translate }}</div>
                <input class="input" placeholder="{{ 'ADMIN_SETTINGS.COMPANY.FORM.PLACEHOLDER.SUBDOMAIN' | translate }}" type="text" id="subdomain" name="subdomain" tabindex="3" [formControl]="companyForm.controls['subdomain']"/>
              </div>
              <div class="form__field company__field" [class.form__field--error]="companyForm | getFormControl : 'language' | isFieldInvalid : submitted">
                <label class="label" for="language" (click)="languageControl.focusDropdown()">{{ 'ADMIN_SETTINGS.COMPANY.FORM.LABEL.LANGUAGE' | translate }}</label>
                <div class="form-field__error">{{ 'ADMIN_SETTINGS.COMPANY.FORM.ERROR.LANGUAGE_EMPTY' | translate }}</div>
                <gw-select #languageControl="gwSelect" [formControl]="companyForm.controls['language']" [placeholder]="'ADMIN_SETTINGS.COMPANY.FORM.PLACEHOLDER.LANGUAGE' | translate" [data]="languages" [display]="['name']" [uniqueKey]="'id'" [small]="true" [virtualScroll]="false" [tabindex]="4"></gw-select>
              </div>
              <div class="form__field company__field">
                <label class="label" for="seeds" (click)="seedsControl.focus()">{{ 'ADMIN_SETTINGS.COMPANY.FORM.LABEL.SEEDS' | translate }}</label>
                <gw-toggle-switch class="company-field__toggle-switch" #seedsControl="gwToggleSwitch" [tabindex]="5" [labelLeft]="'ADMIN_SETTINGS.COMPANY.FORM.LABEL.SEEDS_NO' | translate" [labelRight]="'ADMIN_SETTINGS.COMPANY.FORM.LABEL.SEEDS_YES' | translate" formControlName="seeds"></gw-toggle-switch>
              </div>
            </div>
          </div>
          <div class="form__fieldset company__fieldset">
            <h3 class="company-fieldset__header">{{ 'ADMIN_SETTINGS.COMPANY.FORM.HEADER.FIRST_USER' | translate }}</h3>
            <div class="company__fields">
              <div class="form__field company__field" [class.form__field--error]="companyForm | getFormControl : 'userEmail' | isFieldInvalid : submitted">
                <label class="label" for="userEmail">{{ 'ADMIN_SETTINGS.COMPANY.FORM.LABEL.USER_EMAIL' | translate }}</label>
                <div class="form-field__error" *ngIf="isFieldEmpty(companyForm.controls['userEmail'])">{{ 'ADMIN_SETTINGS.COMPANY.FORM.ERROR.USER_EMAIL_EMPTY' | translate }}</div>
                <div class="form-field__error" *ngIf="!isFieldEmpty(companyForm.controls['userEmail']) &amp;&amp; isEmailInvalid(companyForm.controls['userEmail'])">{{ 'ADMIN_SETTINGS.COMPANY.FORM.ERROR.USER_EMAIL_PATTERN' | translate }}</div>
                <input class="input" placeholder="{{ 'ADMIN_SETTINGS.COMPANY.FORM.PLACEHOLDER.USER_EMAIL' | translate }}" type="email" id="userEmail" name="userEmail" tabindex="6" [formControl]="companyForm.controls['userEmail']"/>
              </div>
              <div class="form__field company__field" [class.form__field--error]="companyForm | getFormControl : 'userName' | isFieldInvalid : submitted">
                <label class="label" for="userName">{{ 'ADMIN_SETTINGS.COMPANY.FORM.LABEL.USER_NAME' | translate }}</label>
                <div class="form-field__error">{{ 'ADMIN_SETTINGS.COMPANY.FORM.ERROR.USER_NAME_EMPTY' | translate }}</div>
                <input class="input" placeholder="{{ 'ADMIN_SETTINGS.COMPANY.FORM.PLACEHOLDER.USER_NAME' | translate }}" type="text" id="userName" name="userName" tabindex="7" [formControl]="companyForm.controls['userName']"/>
              </div>
              <div class="form__field company__field" [class.form__field--error]="companyForm | getFormControl : 'userLastname' | isFieldInvalid : submitted">
                <label class="label" for="userLastname">{{ 'ADMIN_SETTINGS.COMPANY.FORM.LABEL.USER_LASTNAME' | translate }}</label>
                <div class="form-field__error">{{ 'ADMIN_SETTINGS.COMPANY.FORM.ERROR.USER_LASTNAME_EMPTY' | translate }}</div>
                <input class="input" placeholder="{{ 'ADMIN_SETTINGS.COMPANY.FORM.PLACEHOLDER.USER_LASTNAME' | translate }}" type="text" id="userLastname" name="userLastname" tabindex="8" [formControl]="companyForm.controls['userLastname']"/>
              </div>
              <div class="form__field company__field" [class.form__field--error]="companyForm.controls['password'] | isPasswordInvalid : companyForm : submitted">
                <label class="label" for="password">{{ 'ADMIN_SETTINGS.COMPANY.FORM.LABEL.PASSWORD' | translate }}</label>
                <div class="form-field__error" *ngIf="companyForm.controls['password'].errors?.required">{{ 'ADMIN_SETTINGS.COMPANY.FORM.ERROR.PASSWORD_EMPTY' | translate }}</div>
                <div class="form-field__error" *ngIf="companyForm.controls['password'].errors?.minlength">{{ 'ADMIN_SETTINGS.COMPANY.FORM.ERROR.PASSWORD_LENGTH' | translate }}</div>
                <input class="input" placeholder="{{ 'ADMIN_SETTINGS.COMPANY.FORM.PLACEHOLDER.PASSWORD' | translate }}" type="password" id="password" name="password" tabindex="9" [formControl]="companyForm.controls['password']"/>
              </div>
              <div class="form__field company__field" [class.form__field--error]="companyForm.controls['passwordConfirmed'] | isPasswordInvalid : companyForm : submitted">
                <label class="label" for="passwordConfirmed">{{ 'ADMIN_SETTINGS.COMPANY.FORM.LABEL.CONFIRM_PASSWORD' | translate }}</label>
                <div class="form-field__error" *ngIf="companyForm.errors?.passwordNotConfirmed">{{ 'ADMIN_SETTINGS.COMPANY.FORM.ERROR.PASSWORD_NOT_MATCH' | translate }}</div>
                <input class="input" placeholder="{{ 'ADMIN_SETTINGS.COMPANY.FORM.PLACEHOLDER.CONFIRM_PASSWORD' | translate }}" type="password" id="passwordConfirmed" name="passwordConfirmed" tabindex="10" [formControl]="companyForm.controls['passwordConfirmed']"/>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="columns columns--without-padding small-12">
        <div class="button-group company__button-group">
          <button class="link company__button" type="button" tabindex="11" (click)="resetForm()">{{ 'GLOBAL.ACTION.RESET' | translate }}</button>
          <button class="button--primary company__button" type="button" tabindex="12" [disabled]="saving" (click)="submitCompanyForm(companyForm)"><i class="button__icon--before button__icon--spinner" *ngIf="saving">
              <div class="spinner"></div></i>{{ 'GLOBAL.ACTION.SAVE' | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</div>
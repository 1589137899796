import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationExtras } from '@angular/router';
// Gutwin Shared Library
import { OfflineService } from 'gutwin-shared';

@Injectable()
export class OfflineGuard implements CanActivate {

  constructor(private offlineService: OfflineService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.offlineService.online) {
      return true;
    }

    this.router.navigate(['audits']);
    return false;
  }

}

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// Gutwin Shared Library
import { ModalService, OfflineService } from 'gutwin-shared';

// Models
import { Audit } from '../../../shared/models/audit.model';
import { AuditedArea } from '../../../shared/models/audited-area.model';
import { FindingsFilters } from '../../../shared/models/filters-findings.model';
import { Finding } from '../../../shared/models/finding.model';
import { FindingsAmount } from '../../../shared/models/findings-amount.model';
import { FindingsResponse } from '../../../shared/models/findings-response.model';

// Services
import { FindingService } from '../../../shared/services/finding.service';

@Component({
  selector: 'gw-findings-browser',
  templateUrl: './findings-browser.component.html',
  styleUrls: ['./findings-browser.component.scss'],
  exportAs: 'gwFindingsBrowser'
})
export class FindingsBrowserComponent implements OnInit, OnChanges, OnDestroy {
  @Input() auditable: Audit | AuditedArea;
  @Input() auditedAreas: Array<AuditedArea>;
  @Input() isAuditDisabled = false;
  @Output() removeFinding = new EventEmitter<any>();
  findings: Array<Finding>;
  findingsAmount = new FindingsAmount();
  currentAuditable: Audit | AuditedArea;
  displayedAuditedAreas = new Array();
  loadingPage: boolean;
  auditId: string;
  findingsPage = 0;
  findingSearchQuery = '';
  findingSearchQuerySource = new Subject<string>();
  translateSubscription: Subscription;
  notify = {
    error: {
      connection: '',
      audit: {
        loadFindings: ''
      },
      auditedArea: {
        loadFindings: ''
      }
    }
  };
  allAuditedAreasTranslation = '';

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private notificationsService: NotificationsService,
    private modalService: ModalService,
    public findingService: FindingService,
    public offlineService: OfflineService
  ) {}

  ngOnInit() {
    this.auditId = this.route.snapshot.params.auditId;
    this.fetchTranslation();
    this.watchSearchQuery();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.auditId = this.route.snapshot.params.auditId;
    this.fetchAuditedAreas();
    this.changeAuditable(this.auditable);
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
  }

  changeAuditable(auditable: Audit | AuditedArea): void {
    this.currentAuditable = auditable;
    this.findingsPage = 0;
    this.fetchFindings();
  }

  fetchAuditedAreas(): void {
    this.displayedAuditedAreas = new Array();
    if (this.auditedAreas.length > 1) {
      this.displayedAuditedAreas.push({ id: this.auditId, facility: { name: this.allAuditedAreasTranslation } });
    }
    this.auditedAreas.forEach(element => {
      const duplicatedAuditedArea = Object.assign({}, element);
      this.displayedAuditedAreas.push(duplicatedAuditedArea);
    });
  }

  fetchFindings(page = this.findingsPage, filter = this.findingSearchQuery): Promise<void> {
    this.loadingPage = true;
    this.findingsPage = page;
    const filters = new FindingsFilters();
    if (filter && filter.length) {
      filters.search = filter;
    }
    const isAudit = this.currentAuditable.id === this.auditId;
    const findingsPromise = isAudit
      ? this.findingService.getAuditFindings(
          this.auditId,
          page,
          filters,
          undefined,
          !this.isAuditDisabled,
          this.auditedAreas
        )
      : this.findingService.getAuditedAreaFindings(
          this.currentAuditable.id,
          page,
          filters,
          undefined,
          false,
          !this.isAuditDisabled
        );

    return findingsPromise
      .then((data: FindingsResponse) => {
        this.findings = data.findings;
        this.findingsAmount = data.findingsAmount;
        this.loadingPage = false;
      })
      .catch(error => {
        this.findings = [];
        this.findingsAmount = new FindingsAmount();
        const text = isAudit ? this.notify.error.audit.loadFindings : this.notify.error.auditedArea.loadFindings;
        this.notificationsService.error(this.notify.error.connection, text);
      });
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'AUDIT_CONDUCT.FINDINGS_BROWSER.ERROR.AUDIT.LOAD_FINDINGS',
        'AUDIT_CONDUCT.FINDINGS_BROWSER.ERROR.AUDITED_AREA.LOAD_FINDINGS',
        'AUDIT_CONDUCT.FINDINGS_BROWSER.FORM.ALL_AREAS'
      ])
      .subscribe((translation: string) => {
        this.notify.error.connection = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.audit.loadFindings = translation['AUDIT_CONDUCT.FINDINGS_BROWSER.ERROR.AUDIT.LOAD_FINDINGS'];
        this.notify.error.auditedArea.loadFindings =
          translation['AUDIT_CONDUCT.FINDINGS_BROWSER.ERROR.AUDITED_AREA.LOAD_FINDINGS'];
        this.allAuditedAreasTranslation = translation['AUDIT_CONDUCT.FINDINGS_BROWSER.FORM.ALL_AREAS'];
        this.fetchAuditedAreas();
      });
  }

  watchSearchQuery(): void {
    this.findingSearchQuerySource.pipe(debounceTime(500), distinctUntilChanged()).subscribe(query => {
      this.findingsPage = 0;
      this.fetchFindings(this.findingsPage, query);
    });
  }

  changeFindingsPage(page: number): void {
    this.findingsPage = page;
    this.fetchFindings(page);
  }

  searchFindings(): void {
    this.findingSearchQuerySource.next(this.findingSearchQuery);
  }

  showRemoveFindingModal(finding: Finding): void {
    this.removeFinding.emit(finding);
  }
}

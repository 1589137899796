import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { TreeFilterOption } from '../../models/tree-filter-option.model';

@Component({
  selector: 'gw-tree-select',
  templateUrl: './tree-select.component.html',
  styleUrls: ['./tree-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TreeSelectComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeSelectComponent {
  @Input() nodes: Array<TreeFilterOption>;
  @Input() opened = false;
  @Input() markSelectedChildren = false;
  @Input() isParentSelected = false;
  @Input() hasParent = false;
  @Input() isParentFullyClosed?: boolean;

  selectedNodeId: string;

  constructor(private changeDetector: ChangeDetectorRef) {}

  onChange: (_: string) => void = () => {};

  writeValue(selectedNodeId: string): void {
    this.selectedNodeId = selectedNodeId;
    this.changeDetector.detectChanges();
  }

  registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  onSelectedNodeChange(selectedNodeId: string): void {
    this.selectedNodeId = selectedNodeId;
    this.onChange(selectedNodeId);
    this.changeDetector.detectChanges();
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { SimpleDropdownDirective } from 'gutwin-shared';

import { FacilitiesFilter } from '../../models/facilities-filter.model';
import { FacilityFilter } from '../../models/facility-filter.model';

import { FilterComponent } from '@gutwin-audit/shared/enums/filter-component.enum';

import { FilterModuleService } from '../../services/filter-module.service';

@Component({
  selector: 'gw-area-filter',
  templateUrl: './area-filter.component.html',
  styleUrls: ['./area-filter.component.scss'],
  exportAs: 'auditAreaFilters'
})
export class AreaFilterComponent implements OnInit {
  @Input() dropdown: SimpleDropdownDirective;
  @Input() facilities: Array<FacilityFilter>;
  @Input() component: FilterComponent;
  selectedFacility = { id: '' };
  multiSelect: boolean;
  subFolders = true;

  constructor(private filterService: FilterModuleService) {}

  ngOnInit() {
    this.loadFilters();
  }

  loadFilters(): void {
    const facilitiesFilters = this.filterService.filters ? this.filterService.filters.facilities : undefined;
    if (facilitiesFilters) {
      if (facilitiesFilters.facilities && facilitiesFilters.facilities.length) {
        if (facilitiesFilters.multiSelect) {
          this.loadMultiFacilitiesFilters(this.facilities, facilitiesFilters);
        } else {
          this.loadSingleFacilityFilters(facilitiesFilters);
        }
      }
      this.multiSelect = facilitiesFilters.multiSelect;
      this.subFolders = facilitiesFilters.subFolders !== undefined ? facilitiesFilters.subFolders : true;
    }
  }

  loadMultiFacilitiesFilters(facilities: Array<FacilityFilter>, facilitiesFilters: FacilitiesFilter): void {
    facilities.forEach(facility => {
      let selected = false;
      if (facilitiesFilters && facilitiesFilters.facilities) {
        for (const facilityId of facilitiesFilters.facilities) {
          if (facilityId === facility.id) {
            selected = true;
          } else if (facility.children && facility.children.length) {
            this.loadMultiFacilitiesFilters(facility.children, facilitiesFilters);
          }
        }
      } else if (facility.children && facility.children.length) {
        this.loadMultiFacilitiesFilters(facility.children, facilitiesFilters);
      }
      facility.selected = selected;
    });
  }

  loadSingleFacilityFilters(facilitiesFilters: FacilitiesFilter): void {
    this.selectedFacility.id = facilitiesFilters.facilities[0];
  }

  changeMultiSelect(): void {
    if (this.multiSelect) {
      const facilitiesFilters = this.filterService.filters ? this.filterService.filters.facilities : undefined;
      this.loadMultiFacilitiesFilters(this.facilities, facilitiesFilters);
    }
  }

  changeSelectedFacility(facility: FacilityFilter): void {
    this.selectedFacility = facility;
  }

  getSelectedFacilities(facilities: Array<FacilityFilter>): Array<string> {
    let selectedFacilities = new Array<string>();
    facilities.forEach(facility => {
      if (facility.selected) {
        selectedFacilities.push(facility.id);
      } else if (facility.children && facility.children.length) {
        const selectedChildrenFacilities = this.getSelectedFacilities(facility.children);
        selectedFacilities = selectedFacilities.concat(selectedChildrenFacilities);
      }
    });
    return selectedFacilities;
  }

  getFacilitiesToFilter(): Array<string> {
    if (this.multiSelect) {
      return this.getSelectedFacilities(this.facilities);
    } else {
      return [this.selectedFacility.id];
    }
  }

  submitFacilitiesFilter(): void {
    const facilitiesFilters = {
      facilities: this.getFacilitiesToFilter(),
      multiSelect: this.multiSelect,
      subFolders: this.subFolders
    };
    this.filterService.setFilter('facilities', facilitiesFilters, true, this.component);
    this.dropdown.closeDropdown();
  }
}

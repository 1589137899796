import { Role } from './role.model';

export class Permission {
  id: string;
  name: string;
  title: string;
  description: string;
  added: boolean;
  rolesIds: Array<string>;
  roles: Array<Role>;
  action: {
    add: boolean;
    remove: boolean;
  };

  constructor(permission?: Partial<Permission>) {
    this.id = permission ? permission.id : undefined;
    this.name = permission ? permission.name : undefined;
    this.title = permission ? permission.title : undefined;
    this.description = permission ? permission.description : undefined;
    this.added = permission ? permission.added : undefined;
    this.rolesIds = permission ? permission.rolesIds : undefined;
    this.roles = permission ? permission.roles : undefined;
    this.action = permission ? this.generateActions(permission.action) : { add: false, remove: false };
  }

  private generateActions(action: { add: boolean; remove: boolean }): { add: boolean; remove: boolean } {
    return {
      add: action ? action.add : false,
      remove: action ? action.remove : false
    };
  }
}

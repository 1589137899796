
<div class="audit-summary__box" *ngIf="summaryForm">
  <div class="row">
    <div class="columns small-12 medium-10">
      <div class="audit-summary__header">
        <h1 class="audit-summary__header-title" [class.audit-summary__header-title--with-icon]="auditable.summaryOffline !== undefined">{{ 'AUDIT_SUMMARY.GENERAL_IMPRESSION.HEADER' | translate }}</h1>
        <div class="audit-summary__header-icon">
          <div class="offline-icon offline-icon--small" *ngIf="auditable.summaryOffline !== undefined"><i class="gw-cloud--off offline-icon__icon"></i></div>
        </div>
      </div>
      <form class="audit-summary__form" [formGroup]="summaryForm">
        <label class="label" for="summary">{{ 'AUDIT_SUMMARY.GENERAL_IMPRESSION.FORM.LABEL.SUMMARY' | translate }}</label>
        <div class="textarea-container" *ngIf="!audit.disabled">
          <textarea class="textarea--small textarea--autosize audit-summary-form__textarea" gwAutosize="gwAutosize" rows="1" placeholder="{{ 'AUDIT_SUMMARY.GENERAL_IMPRESSION.FORM.PLACEHOLDER.SUMMARY' | translate }}" name="summary" [formControl]="summaryForm.controls['summary']" (blur)="summaryFieldBlur()"></textarea>
          <div class="textarea-border"></div>
        </div>
        <div class="audit-summary-form__text" *ngIf="audit.disabled">{{ auditable.summary ? auditable.summary : '-' }}</div>
      </form>
    </div>
  </div>
</div>
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// Gutwin Shared Library
import { DialogService, LoaderService } from 'gutwin-shared';

import { FindingsFilters } from '../../../../shared/models/filters-findings.model';
// Models
import { Finding, FindingStatus } from '../../../../shared/models/finding.model';
import { FindingsResponse } from '../../../../shared/models/findings-response.model';

import { FilterModuleService } from '../../../../shared/services/filter-module.service';
// Services
import { FindingService } from '../../../../shared/services/finding.service';

@Component({
  selector: 'gw-findings-table',
  templateUrl: './findings-table.component.html',
  styleUrls: ['./findings-table.component.scss']
})
export class FindingsTableComponent implements OnInit, OnDestroy {
  @Input() findingsStatus: string;
  findings: Array<Finding>;
  totalFindings: number;
  findingStatusNames: Array<string>;
  viewStyle = 'table';
  isExportingFindings = false;
  translation = {
    removeDialog: {
      header: '',
      content: '',
      cancel: '',
      confirm: ''
    },
    notify: {
      error: {
        connectionTitle: '',
        permissions: '',
        loadFindingsText: '',
        exportFindingsText: '',
        removeFindingText: '',
        removeFindingPermissionsText: ''
      },
      success: {
        removeFindingTitle: '',
        removeFindingText: ''
      }
    }
  };
  filterSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private dialogService: DialogService,
    private notificationsService: NotificationsService,
    private findingService: FindingService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    public filterService: FilterModuleService
  ) {}

  async ngOnInit() {
    this.findings = this.route.snapshot.data['findings'].findings;
    this.totalFindings = this.getTotalFindings();
    this.findingStatusNames = await this.findingService.getFindingStatusNames();

    this.filterSubscription = this.filterService.filterObservable
      .pipe(debounceTime(200))
      .subscribe(({ page, filters, limit }) => {
        this.fetchFindings(page, filters, limit);
      });

    this.fetchTranslations();
  }

  ngOnDestroy() {
    this.filterSubscription.unsubscribe();
  }

  changePage(page: number): void {
    this.filterService.setPage(page);
  }

  changeLimit(limit: number): void {
    this.filterService.setLimit(limit);
  }

  async fetchFindings(page?: number, filters?: FindingsFilters, limit?: number): Promise<void> {
    this.loaderService.show('findingsLoader');
    const findingsPromise = this.findingsStatus
      ? this.findingService.getFindingsByStatus(this.findingsStatus, page, filters, limit)
      : this.findingService.getFindings(undefined, page, filters, limit);

    await findingsPromise
      .then((data: FindingsResponse) => {
        this.updateData(data.findings);
      })
      .catch(() => {
        this.notificationsService.error(
          this.translation.notify.error.connectionTitle,
          this.translation.notify.error.loadFindingsText
        );
      });
    this.loaderService.hide('findingsLoader');
  }

  async exportFindings(filters = this.filterService.findingsFilters): Promise<void> {
    this.isExportingFindings = true;
    const findingsPromise = this.findingsStatus
      ? this.findingService.exportFindingsByStatus(this.findingsStatus, filters)
      : this.findingService.exportFindings(undefined, filters);

    await findingsPromise.catch(error => {
      this.notificationsService.error(
        this.translation.notify.error.connectionTitle,
        this.translation.notify.error.exportFindingsText
      );
    });
    this.isExportingFindings = false;
  }

  updateData(findings: Array<Finding>): void {
    this.findings = findings;
    this.totalFindings = this.getTotalFindings();
    this.changeDetector.markForCheck();
  }

  getTotalFindings(): number {
    let findingsTotalId = 'filtered';
    switch (this.findingsStatus) {
      case FindingStatus.inProgress:
        findingsTotalId = 'filteredInProgress';
        break;
      case FindingStatus.overdue:
        findingsTotalId = 'filteredOverdue';
        break;
      case FindingStatus.notAssigned:
        findingsTotalId = 'filteredNotAssigned';
        break;
      case FindingStatus.solved:
        findingsTotalId = 'filteredSolved';
        break;
    }
    return this.findingService.getFindingsAmount()[findingsTotalId];
  }

  showRemoveFindingDialog(finding: Finding): void {
    this.dialogService
      .confirm(
        this.translation.removeDialog.header,
        this.translation.removeDialog.content,
        this.translation.removeDialog.cancel,
        this.translation.removeDialog.confirm
      )
      .then(() => this.removeFinding(finding))
      .catch(() => {});
  }

  removeFinding(finding: Finding): void {
    const showErrorNotification = (error: any): void => {
      switch (error.status) {
        case 403:
          this.notificationsService.error(
            this.translation.notify.error.permissions,
            this.translation.notify.error.removeFindingPermissionsText
          );
          break;
        default:
          this.notificationsService.error(
            this.translation.notify.error.connectionTitle,
            this.translation.notify.error.removeFindingText
          );
      }
    };

    this.findingService
      .removeFinding(finding)
      .then(() => {
        this.notificationsService.success(
          this.translation.notify.success.removeFindingTitle,
          this.translation.notify.success.removeFindingText
        );
        if (this.findings.length > 1) {
          this.fetchFindings(this.filterService.page, this.filterService.findingsFilters, this.filterService.limit);
        } else {
          this.changePage(this.filterService.page - 1);
        }
      })
      .catch(error => {
        showErrorNotification(error);
      });
  }

  async fetchTranslations(): Promise<void> {
    const translation = await this.translateService
      .get([
        'GLOBAL.ERROR.CONNECTION',
        'GLOBAL.ERROR.PERMISSIONS',
        'GLOBAL.ACTION.CANCEL',
        'GLOBAL.ACTION.REMOVE',
        'FINDINGS_LIST.ERROR.LOAD_FINDINGS_TEXT',
        'FINDINGS_LIST.ERROR.EXPORT_FINDINGS_TEXT',
        'FINDINGS_LIST.ERROR.REMOVE_FINDING_TEXT',
        'FINDINGS_LIST.ERROR.REMOVE_FINDING_PERMISSIONS_TEXT',
        'FINDINGS_LIST.SUCCESS.REMOVE_FINDING_TITLE',
        'FINDINGS_LIST.SUCCESS.REMOVE_FINDING_TEXT',
        'FINDINGS_LIST.REMOVE_FINDING_DIALOG.HEADER',
        'FINDINGS_LIST.REMOVE_FINDING_DIALOG.CONTENT'
      ])
      .toPromise();
    this.translation.notify.error.connectionTitle = translation['GLOBAL.ERROR.CONNECTION'];
    this.translation.notify.error.permissions = translation['GLOBAL.ERROR.PERMISSIONS'];
    this.translation.notify.error.loadFindingsText = translation['FINDINGS_LIST.ERROR.LOAD_FINDINGS_TEXT'];
    this.translation.notify.error.exportFindingsText = translation['FINDINGS_LIST.ERROR.EXPORT_FINDINGS_TEXT'];
    this.translation.notify.error.removeFindingText = translation['FINDINGS_LIST.ERROR.REMOVE_FINDING_TEXT'];
    this.translation.notify.error.removeFindingPermissionsText =
      translation['FINDINGS_LIST.ERROR.REMOVE_FINDING_PERMISSIONS_TEXT'];
    this.translation.notify.success.removeFindingTitle = translation['FINDINGS_LIST.SUCCESS.REMOVE_FINDING_TITLE'];
    this.translation.notify.success.removeFindingText = translation['FINDINGS_LIST.SUCCESS.REMOVE_FINDING_TEXT'];
    this.translation.removeDialog.header = translation['FINDINGS_LIST.REMOVE_FINDING_DIALOG.HEADER'];
    this.translation.removeDialog.content = translation['FINDINGS_LIST.REMOVE_FINDING_DIALOG.CONTENT'];
    this.translation.removeDialog.cancel = translation['GLOBAL.ACTION.CANCEL'];
    this.translation.removeDialog.confirm = translation['GLOBAL.ACTION.REMOVE'];
  }
}

import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { SelectGroup } from '../../models/select-group.model';
import { SelectItem } from '../../models/select-item.model';

import { SelectIconTheme } from '../../enums/select-icon-theme.enum';
import { SelectTheme } from '../../enums/select-theme.enum';

import { SimpleDropdownDirective } from '../../directives/simple-dropdown.directive';

import { HelperUtil } from '../../utils/helper.util';

@Component({
  selector: 'gw-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],
  exportAs: 'gwSelect',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @ViewChild('selectDropdown', { static: true }) selectDropdown: SimpleDropdownDirective;
  @ViewChild('scrollView') scrollView: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) virtualScrollView: CdkVirtualScrollViewport;
  @ViewChildren('itemElement') itemsElements: QueryList<ElementRef>;

  @Input() data: Array<any>;
  @Input() display: Array<string>;
  @Input() nestedValue: string;
  @Input() uniqueKey: string;
  @Input() colorKey: string;
  @Input() placeholder: string;
  @Input() tabindex: number;
  @Input() white: boolean;
  @Input() small: boolean;
  @Input() search: boolean;
  @Input() error: boolean;
  @Input() colored: boolean;
  @Input() centered: boolean;
  @Input() noWrapHeader?: boolean;
  @Input() noWrapLabel?: boolean;
  @Input() clearOption?: boolean;
  @Input() hiddenOverflowX?: boolean;
  @Input() theme?: SelectTheme;
  @Input() iconTheme?: SelectIconTheme;
  @Input() splitter = ' ';
  @Input() label = '';
  @Input() virtualScroll = true;
  @Input() arrow = true;
  @Input() primary = false;
  @Input() dataTranslated = true;
  @Input() containWidth = true;
  @Input() translationKey = 'name';
  @Input() isUserTypeClient = false;
  @Input() cachePreviousStatus = false;
  @Input() fixOverflowPosition = false;
  @Input() withIcon: boolean;
  @Input() set setBackStatus(setBackStatus: boolean) {
    if (setBackStatus) this.selectItem(this.previouslySelected);
  }

  disabled: boolean;
  selected: SelectItem;
  previouslySelected: SelectItem;
  options: Array<SelectItem | SelectGroup>;
  searchQuery: string;
  focusedOption: SelectItem;

  readonly EMPTY_ITEM = new SelectItem({
    key: null,
    data: '',
    name: ''
  });
  readonly ITEM_HEIGHT = {
    small: 30,
    normal: 44
  };
  readonly SELECT_THEME = SelectTheme;
  readonly SELECT_ICON_THEME = SelectIconTheme;

  constructor(private translateService: TranslateService, private changeDetector: ChangeDetectorRef) {}

  onChange: (_: SelectItem) => void = () => {};

  ngOnInit(): void {
    this.translateEmptyOption();
    this.init();
  }

  writeValue(selected: SelectItem): void {
    this.selected = selected ? this.convertToSelectItem(selected) : undefined;
    if (this.selected) {
      this.focusedOption = this.selected;
    }
    this.changeDetector.detectChanges();
  }

  registerOnChange(fn: (_: SelectItem) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  translateEmptyOption(): void {
    this.EMPTY_ITEM.name = this.translateService.instant('GUTWIN_SHARED.LABEL.NONE');
  }

  init(): void {
    this.generateOptions(this.searchQuery);
    this.setVirtualScroll();
  }

  setVirtualScroll(): void {
    if (this.virtualScroll) {
      this.virtualScroll = this.data?.length >= 5;
    }
  }

  convertToSelectItem(item: any): SelectItem {
    return new SelectItem({
      key: item[this.uniqueKey],
      name: this.getDisplay(item),
      data: item,
      disabled: item.disabled,
      ...(item?.icon?.url && { iconUrl: item.icon.url })
    });
  }

  generateOptions(query = ''): void {
    query = query.toLowerCase();
    this.options = new Array<SelectItem | SelectGroup>();
    if (this.clearOption) {
      this.options.push(this.EMPTY_ITEM);
    }
    if (this.data) {
      this.data.forEach(object => {
        if (object[this.nestedValue]) {
          const selectGroupItems = new Array<SelectItem>();
          object[this.nestedValue].forEach(nestedObject => {
            const optionName = this.getDisplay(nestedObject);
            if (~optionName.toLowerCase().indexOf(query)) {
              const option = this.convertToSelectItem(nestedObject);
              selectGroupItems.push(option);
              if (this.isSelected(option)) {
                this.focusedOption = option;
              }
            }
          });
          this.options.push(
            new SelectGroup({
              name: this.getDisplay(object),
              data: selectGroupItems
            })
          );
        } else {
          const optionName = this.getDisplay(object);
          if (!query || ~optionName.toLowerCase().indexOf(query)) {
            const option = this.convertToSelectItem(object);
            this.options.push(option);
            if (this.isSelected(option)) {
              this.focusedOption = option;
            }
          }
        }
      });
      if (!this.focusedOption && this.options.length) {
        if (this.isGroup(this.options[0])) {
          this.focusedOption = this.options[0].data[0];
        } else {
          this.focusedOption = new SelectItem(this.options[0]);
        }
      }
    }
  }

  openOptions(): void {
    this.init();
    this.selectDropdown.openDropdown();
  }

  focusDropdown(): void {
    this.selectDropdown.focusOnDropdown();
  }

  getDisplay(item: any): string {
    return HelperUtil.getNestedAttributesToDisplay(this.display, item, this.splitter);
  }

  isGroup(item: any): boolean {
    return item._type === 'SelectGroup';
  }

  isSelected(item: SelectItem): boolean {
    return this.selected && this.selected[this.uniqueKey] === item.key;
  }

  isFocused(item: SelectItem): boolean {
    return this.focusedOption && this.focusedOption.key === item.key;
  }

  selectItem(item: SelectItem): void {
    if (!item.disabled) {
      this.setSelected(item);
      this.focusedOption = item;
      this.submit();
      this.selectDropdown.closeDropdown();
    }
  }

  setSelected(item: SelectItem): void {
    if (this.cachePreviousStatus) this.previouslySelected = this.selected;
    this.selected = item;
  }

  cleanSelection(): void {
    this.selectItem(this.EMPTY_ITEM);
  }

  getItemAbsoluteIndex(item: SelectItem): number {
    let index = -1;
    for (const option of this.options) {
      if (!this.isGroup(option)) {
        index++;
        if (option['key'] === item.key) {
          return index;
        }
      } else {
        for (const nestedOption of option.data) {
          index++;
          if (nestedOption['key'] === item.key) {
            return index;
          }
        }
      }
    }
  }

  scrollToItem(item: SelectItem): void {
    const itemIndex = this.getItemAbsoluteIndex(item);
    if (this.virtualScroll) {
      this.virtualScrollView.scrollToIndex(itemIndex);
    } else {
      const itemElement = this.itemsElements.toArray()[itemIndex];
      this.scrollView.nativeElement.scrollTop =
        itemElement.nativeElement.offsetTop - this.scrollView.nativeElement.offsetTop;
    }
  }

  submit(): void {
    this.onChange(this.selected.data);
  }

  getAnotherOption(direction: 'previous' | 'next', currentOption: SelectItem, options = this.options): SelectItem {
    const moveUp = direction === 'previous';
    if (options) {
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (!this.isGroup(option)) {
          if ((option as SelectItem).key === currentOption.key) {
            return this.chooseAnotherOption(i, options, moveUp);
          }
        } else {
          for (let j = 0; j < option.data.length; j++) {
            const nestedOption = option.data[j];
            if (nestedOption.key === currentOption.key) {
              const focusedOption = this.chooseAnotherOption(j, option.data, moveUp, true);
              return focusedOption ? focusedOption : this.chooseAnotherOption(i, options, moveUp);
            }
          }
        }
      }
    }
  }

  chooseAnotherOption(
    index: number,
    options: Array<SelectItem | SelectGroup>,
    moveUp: boolean,
    isChild?: boolean
  ): SelectItem {
    const anotherIndex = moveUp ? index - 1 : index + 1;
    if ((moveUp && anotherIndex >= 0) || (!moveUp && anotherIndex < options.length)) {
      if (!this.isGroup(options[anotherIndex])) {
        return options[anotherIndex] as SelectItem;
      } else {
        const nestedIndex = moveUp ? options[anotherIndex].data.length - 1 : 0;
        return options[anotherIndex].data[nestedIndex];
      }
    } else if (!isChild) {
      const loopIndex = moveUp ? options.length - 1 : 0;
      if (!this.isGroup(options[loopIndex])) {
        return options[loopIndex] as SelectItem;
      } else {
        const nestedIndex = moveUp ? options[loopIndex].data.length - 1 : 0;
        return options[loopIndex].data[nestedIndex];
      }
    } else {
      return;
    }
  }

  onDropdownOpened(): void {
    if (this.selected) {
      this.focusedOption = this.selected;
      setTimeout(() => {
        // Workaround: Keep in setTimeout to scroll after view init
        this.scrollToItem(this.focusedOption);
      });
    }
  }

  @HostListener('keydown', ['$event'])
  keydown(event): boolean {
    switch (event.key) {
      case 'Tab':
        if (this.selectDropdown.openedWithDelay) {
          this.selectDropdown.closeDropdown();
        }
        break;
      case 'Enter':
        if (this.selectDropdown.openedWithDelay) {
          event.preventDefault();
          this.selectDropdown.closeDropdown();
          if (this.focusedOption) {
            this.selectItem(this.focusedOption);
          }
          return false;
        }
        break;
      case 'ArrowUp':
        event.preventDefault();
        if (this.selectDropdown.openedWithDelay) {
          this.focusedOption = this.getAnotherOption('previous', this.focusedOption);
          if (this.focusedOption) {
            this.scrollToItem(this.focusedOption);
          }
        } else {
          this.openOptions();
        }
        return false;
      case 'ArrowDown':
        event.preventDefault();
        if (this.selectDropdown.openedWithDelay) {
          this.focusedOption = this.getAnotherOption('next', this.focusedOption);
          if (this.focusedOption) {
            this.scrollToItem(this.focusedOption);
          }
        } else {
          this.openOptions();
        }
        return false;
    }
  }
}

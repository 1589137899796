import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterFlatTree',
  pure: true
})
export class FilterFlatTreePipe implements PipeTransform {
  public transform(tree: Array<{ isVisible?: boolean }>): Array<{ isVisible?: boolean }> {
    return tree?.filter(node => node.isVisible);
  }
}

export class Country {
  id: string;
  name: string;
  abbreviation: string;
  description?: string;
  archived?: boolean;

  constructor(country: Partial<Country>) {
    this.id = country.id;
    this.name = country.name;
    this.abbreviation = country.abbreviation;
    this.description = country.description;
    this.archived = country.archived;
  }
}


<div class="flex-table__cell question__description" *ngIf="question.description" [innerHTML]="question.description | convertLinksInText"></div>
<div class="flex-table__cell question__content">
  <div class="form__fieldset">
    <div class="form__field">
      <label class="label" for="comment">{{ 'AUDIT_CONDUCT.QUESTION.FORM.LABEL.COMMENT' | translate }}</label>
      <div class="textarea-container" *ngIf="!isAuditDisabled">
        <textarea class="textarea--small textarea--autosize question-content__textarea" gwAutosize="gwAutosize" rows="1" placeholder="{{ 'AUDIT_CONDUCT.QUESTION.FORM.PLACEHOLDER.COMMENT' | translate }}" name="comment" [formControl]="questionForm.controls['comment']" (blur)="commentFieldBlur()"></textarea>
        <div class="textarea-border"></div>
      </div>
      <div class="question-content__text" *ngIf="isAuditDisabled">{{ questionMedia?.comment ? questionMedia?.comment : '-' }}</div>
    </div>
    <div class="form__field text-right button-group">
      <label class="button--tertiary" *ngIf="!isAuditDisabled" [attr.for]="'file-' + question.id"><i class="gw-attach button__icon--before" *ngIf="!updatingAttachments"></i><i class="button__icon--before button__icon--spinner" *ngIf="updatingAttachments">
          <div class="spinner"></div></i>{{ 'AUDIT_CONDUCT.QUESTION.FORM.LABEL.ATTACH_FILES' | translate }}
        <input class="hide" #fileInput="" type="file" multiple="multiple" placeholder="Upload file" [attr.id]="'file-' + question.id" [attr.name]="'file-' + question.id" (change)="attachFiles($event, fileInput)"/>
      </label>
      <button class="button--secondary" *ngIf="!isAuditDisabled" type="button" (click)="showFindingModal(question)">{{ 'AUDIT_CONDUCT.QUESTION.FORM.LABEL.ADD_FINDING' | translate }}</button>
    </div>
  </div>
  <div class="question__attachments" *ngIf="attachments?.length">
    <h4>{{ 'AUDIT_CONDUCT.QUESTION.FORM.LABEL.ATTACHMENT' | translate }}<small>({{ attachments?.length }})</small></h4>
    <div class="question-attachments">
      <ng-container *ngFor="let attachment of attachmentsGroups.images">
        <gw-attachment class="attachment-container" [attachment]="attachment" [gallery]="attachmentsGroups.images" [viewOnly]="!areAttachmentOptionsVisible(attachment)" (removed)="removeAttachment(attachment)"></gw-attachment>
      </ng-container>
    </div>
    <div class="question-attachments">
      <ng-container *ngFor="let attachment of attachmentsGroups.others">
        <gw-attachment class="attachment-container" [attachment]="attachment" [viewOnly]="!areAttachmentOptionsVisible(attachment)" (removed)="removeAttachment(attachment)"></gw-attachment>
      </ng-container>
    </div>
  </div>
  <div class="question__findings" *ngIf="question.findings?.length">
    <h4>{{ 'AUDIT_CONDUCT.QUESTION.FORM.LABEL.FINDINGS' | translate }}<small>({{ findingsAmount }})</small></h4>
    <gw-finding class="question__finding" *ngFor="let finding of question.findings" [finding]="finding" [source]="question" [disabled]="{update: false, remove: isAuditDisabled}" (update)="showUpdateFindingModal(finding)" (remove)="showRemoveFindingModal(finding)"></gw-finding>
    <gw-pagination *ngIf="findingsAmount &gt; findingService.findingsLimit" [currentLimit]="findingService.findingsLimit" [light]="true" [totalElementsAmount]="findingsAmount" (changePage)="changeFindingsPage($event)"></gw-pagination>
  </div>
</div>
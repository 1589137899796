export class Rating {
  id: string;
  name: string;
  color: string;
  abbreviation: string;
  numericValue: number;
  percentageValue: number;
  skipForAverage: boolean;

  constructor(rating: Partial<Rating>) {
    this.id = rating.id;
    this.name = rating.name;
    this.color = rating.color;
    this.abbreviation = rating.abbreviation;
    this.numericValue = rating.numericValue;
    this.percentageValue = rating.percentageValue;
    this.skipForAverage = rating.skipForAverage;
  }
}

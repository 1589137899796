import gql from 'graphql-tag';

import { CompanyStructureGraphQLActionType } from '../../enums/company-structure-graphql.enum';

import { UnitTypeFragment } from '../fragments/unit-type.fragment';
import { RootUnitFragment, UnitFragment } from '../fragments/unit.fragment';

export const CompanyStructureQuery = gql`
  query ${CompanyStructureGraphQLActionType.CompanyStructure} {
    ${CompanyStructureGraphQLActionType.CompanyStructure} {
      units {
        ...UnitFragment
      }
    }
  }
  ${UnitFragment}
`;

export const RootCompanyUnitsQuery = gql`
  query ${CompanyStructureGraphQLActionType.RootUnits} {
    ${CompanyStructureGraphQLActionType.RootUnits} {
      ...RootUnitFragment
    }
  }
  ${RootUnitFragment}
`;

export const CompanyUnitsTypesQuery = gql`
  query ${CompanyStructureGraphQLActionType.UnitTypes} {
    ${CompanyStructureGraphQLActionType.UnitTypes} {
      ...UnitTypeFragment
    }
  }
  ${UnitTypeFragment}
`;

import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy, OnChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import * as _ from 'lodash';
// Models
import { Audit } from './../../../shared/models/audit.model';
import { AuditedArea } from './../../../shared/models/audited-area.model';
// Services
import { AuditService } from './../../../shared/services/audit.service';
import { AuditOfflineService, AuditRequest, AuditedAreaRequest } from './../../../shared/services/audit.offline.service';
import { AuditedAreaService } from './../../../shared/services/audited-area.service';

@Component({
  selector: 'gw-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  exportAs: 'gwSummary'
})
export class SummaryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() auditable: Audit | AuditedArea;
  @Input() auditedAreas: Array<AuditedArea>;
  @Input() isAuditDisabled = false;
  currentAuditable: Audit | AuditedArea;
  auditId: string;
  displayedAuditedAreas = new Array();
  summaryForm: FormGroup;
  updateStatus = false;
  hasResonse = false;
  blur = false;
  translateSubscription: Subscription;
  summaryFormSubscription: Subscription;
  allAuditedAreasTranslation = '';
  notify = {
    error: {
      audit: {
        loadSummaryTitle: '',
        loadSummaryText: '',
        updateSummaryTitle: '',
        updateSummaryText: '',
        getSummaryRequestTitle: '',
        getSummaryRequestText: ''
      },
      auditedArea: {
        loadSummaryTitle: '',
        loadSummaryText: '',
        updateSummaryTitle: '',
        updateSummaryText: '',
        getSummaryRequestTitle: '',
        getSummaryRequestText: ''
      }
    },
    success: {
      audit: {
        updateSummaryTitle: '',
        updateSummaryText: ''
      },
      auditedArea: {
        updateSummaryTitle: '',
        updateSummaryText: ''
      }
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private auditService: AuditService,
    private auditOfflineService: AuditOfflineService,
    private auditedAreaService: AuditedAreaService,
    private notificationsService: NotificationsService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.auditId = this.route.snapshot.params.auditId;
    this.fetchTranslation();
  }

  ngOnChanges() {
    this.auditId = this.route.snapshot.params.auditId;
    this.fetchAuditedAreas();
    this.changeAuditable(this.auditable);
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
    if (this.summaryFormSubscription) this.summaryFormSubscription.unsubscribe();
  }

  fetchTranslation(): void {
    this.translateSubscription = this.translateService.get([
      'GLOBAL.ERROR.CONNECTION',
      'GLOBAL.ERROR.CONNECTION_OFFLINE',
      'AUDIT_CONDUCT.SUMMARY.ERROR.AUDIT.LOAD_SUMMARY_TEXT',
      'AUDIT_CONDUCT.SUMMARY.ERROR.AUDIT.UPDATE_SUMMARY_TEXT',
      'AUDIT_CONDUCT.SUMMARY.ERROR.AUDIT.LOAD_SUMMARY_REQUEST_TEXT',
      'AUDIT_CONDUCT.SUMMARY.ERROR.AUDITED_AREA.LOAD_SUMMARY_TEXT',
      'AUDIT_CONDUCT.SUMMARY.ERROR.AUDITED_AREA.UPDATE_SUMMARY_TEXT',
      'AUDIT_CONDUCT.SUMMARY.ERROR.AUDITED_AREA.LOAD_SUMMARY_REQUEST_TEXT',
      'AUDIT_CONDUCT.SUMMARY.SUCCESS.AUDIT.UPDATE_SUMMARY_TITLE',
      'AUDIT_CONDUCT.SUMMARY.SUCCESS.AUDIT.UPDATE_SUMMARY_TEXT',
      'AUDIT_CONDUCT.SUMMARY.SUCCESS.AUDITED_AREA.UPDATE_SUMMARY_TITLE',
      'AUDIT_CONDUCT.SUMMARY.SUCCESS.AUDITED_AREA.UPDATE_SUMMARY_TEXT',
      'AUDIT_CONDUCT.FINDINGS_BROWSER.FORM.ALL_AREAS'
    ])
      .subscribe((translation: any) => {
        this.notify.error.audit.loadSummaryTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.audit.loadSummaryText = translation['AUDIT_CONDUCT.SUMMARY.ERROR.AUDIT.LOAD_SUMMARY_TEXT'];
        this.notify.error.audit.updateSummaryTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.audit.updateSummaryText = translation['AUDIT_CONDUCT.SUMMARY.ERROR.AUDIT.UPDATE_SUMMARY_TEXT'];
        this.notify.error.audit.getSummaryRequestTitle = translation['GLOBAL.ERROR.CONNECTION_OFFLINE'];
        this.notify.error.audit.getSummaryRequestText = translation['AUDIT_CONDUCT.SUMMARY.ERROR.AUDIT.LOAD_SUMMARY_REQUEST_TEXT'];
        this.notify.success.audit.updateSummaryTitle = translation['AUDIT_CONDUCT.SUMMARY.SUCCESS.AUDIT.UPDATE_SUMMARY_TITLE'];
        this.notify.success.audit.updateSummaryText = translation['AUDIT_CONDUCT.SUMMARY.SUCCESS.AUDIT.UPDATE_SUMMARY_TEXT'];
        this.notify.error.auditedArea.loadSummaryTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.auditedArea.loadSummaryText = translation['AUDIT_CONDUCT.SUMMARY.ERROR.AUDITED_AREA.LOAD_SUMMARY_TEXT'];
        this.notify.error.auditedArea.updateSummaryTitle = translation['GLOBAL.ERROR.CONNECTION'];
        this.notify.error.auditedArea.updateSummaryText = translation['AUDIT_CONDUCT.SUMMARY.ERROR.AUDITED_AREA.UPDATE_SUMMARY_TEXT'];
        this.notify.error.audit.getSummaryRequestTitle = translation['GLOBAL.ERROR.CONNECTION_OFFLINE'];
        this.notify.error.audit.getSummaryRequestText = translation['AUDIT_CONDUCT.SUMMARY.ERROR.AUDITED_AREA.LOAD_SUMMARY_REQUEST_TEXT'];
        this.notify.success.auditedArea.updateSummaryTitle = translation['AUDIT_CONDUCT.SUMMARY.SUCCESS.AUDITED_AREA.UPDATE_SUMMARY_TITLE'];
        this.notify.success.auditedArea.updateSummaryText = translation['AUDIT_CONDUCT.SUMMARY.SUCCESS.AUDITED_AREA.UPDATE_SUMMARY_TEXT'];
        this.allAuditedAreasTranslation = translation['AUDIT_CONDUCT.FINDINGS_BROWSER.FORM.ALL_AREAS'];
        this.fetchAuditedAreas();
      });
  }

  fetchAuditedAreas(): void {
    this.displayedAuditedAreas = new Array();
    if (this.auditedAreas.length > 1) {
      this.displayedAuditedAreas.push({ id: this.auditId, facility: { name: this.allAuditedAreasTranslation } });
    }
    this.auditedAreas.forEach(element => {
      const duplicatedAuditedArea = Object.assign({}, element);
      this.displayedAuditedAreas.push(duplicatedAuditedArea);
    });
  }

  fetchSummary(): Promise<Audit | AuditedArea> {
    const promises = new Array<Promise<Audit | AuditedArea>>();
    const isAudit = this.currentAuditable.id === this.auditId;
    const summaryPromise: Promise<Audit | AuditedArea> = isAudit ?
      this.auditService.getAudit(this.currentAuditable.id) :
      this.auditedAreaService.getAuditedArea(this.currentAuditable.id, this.auditId);

    promises.push(summaryPromise
      .then((auditableResponse: Audit | AuditedArea) => {
        this.currentAuditable.summary = auditableResponse.summary;
        return auditableResponse;
      })
      .catch(error => {
        const notify = {
          title: isAudit ? this.notify.error.audit.loadSummaryTitle : this.notify.error.auditedArea.loadSummaryTitle,
          text: isAudit ? this.notify.error.audit.loadSummaryText : this.notify.error.auditedArea.loadSummaryText
        };
        this.notificationsService.error(notify.title, notify.text);
        return error;
      }));

    if (!this.isAuditDisabled) {
      const summaryOfflinePromise: Promise<AuditRequest | AuditedAreaRequest> = isAudit ?
        this.auditOfflineService.getAuditRequestFromOfflineStore(this.auditId) :
        this.auditOfflineService.getAuditedAreaRequestFromOfflineStore(this.auditId, this.currentAuditable.id);

      promises.push(summaryOfflinePromise
        .then((auditableRequest: AuditRequest | AuditedAreaRequest) => {
          if (auditableRequest) {
            this.currentAuditable.summaryOffline = auditableRequest.summary;
          } else {
            this.currentAuditable.summaryOffline = undefined;
          }
          return this.currentAuditable;
        })
        .catch(error => {
          const notify = {
            title: isAudit ? this.notify.error.audit.getSummaryRequestTitle : this.notify.error.auditedArea.getSummaryRequestTitle,
            text: isAudit ? this.notify.error.audit.getSummaryRequestText : this.notify.error.auditedArea.getSummaryRequestText
          };
          this.notificationsService.error(notify.title, notify.text);
          return this.currentAuditable;
        }));
    }

    return Promise.all(promises).then(() => this.currentAuditable);
  }

  changeAuditable(auditable: Audit | AuditedArea): void {
    this.currentAuditable = auditable;
    this.fetchSummary().then(() => {
      this.initForm();
    });
  }

  initForm(): void {
    const summary = this.currentAuditable.summaryOffline !== undefined ?
      this.currentAuditable.summaryOffline : this.currentAuditable.summary;
    this.summaryForm = this.formBuilder.group({
      summary: [summary || '']
    });

    this.summaryFormSubscription = this.summaryForm.valueChanges.pipe(debounceTime(1000)).subscribe((formValue: any) => {
      this.saveSummary(formValue, this.currentAuditable);
    });
  }

  saveSummary(formValue: any, auditable: Audit | AuditedArea) {
    const auditableRequest = { id: auditable.id, summary: formValue.summary };
    const summaryPromise: Promise<{ data: Audit | AuditedArea, offline?: boolean }> = auditable._type === 'AuditedArea' ?
      this.auditedAreaService.updateAuditedAreaSummary(this.auditId, new AuditedArea(auditableRequest)) :
      this.auditService.updateAuditSummary(new Audit(auditableRequest));

    summaryPromise
      .then(({ data, offline }) => {
        if (offline) {
          auditable.summaryOffline = data.summaryOffline;
        } else {
          auditable.summary = data.summary;
          auditable.summaryOffline = undefined;
        }
        this.updateStatus = true;
        this.hasResonse = true;
        this.showNotify();
      })
      .catch(() => {
        this.updateStatus = false;
        this.hasResonse = true;
        this.showNotify();
      });
  }

  showNotify(): void {
    const isAudit = this.currentAuditable.id === this.auditId;
    if (this.hasResonse && this.blur) {
      if (this.updateStatus) {
        const notify = {
          title: isAudit ? this.notify.success.audit.updateSummaryTitle : this.notify.success.auditedArea.updateSummaryTitle,
          text: isAudit ? this.notify.success.audit.updateSummaryText : this.notify.success.auditedArea.updateSummaryText
        };
        this.notificationsService.success(notify.title, notify.text);
      } else {
        const notify = {
          title: isAudit ? this.notify.error.audit.updateSummaryTitle : this.notify.error.auditedArea.updateSummaryTitle,
          text: isAudit ? this.notify.error.audit.updateSummaryText : this.notify.error.auditedArea.updateSummaryText
        };
        this.notificationsService.error(notify.title, notify.text);
      }
      this.blur = false;
      this.hasResonse = false;
    }
  }

  summaryFieldBlur(): void {
    this.blur = true;
    this.showNotify();
  }
}
